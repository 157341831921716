<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Forecast</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>

            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                     
                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategories==8?'active':''" (click)="toLead()"><a>
                                Leads</a></li>
                        <li [ngClass]="activeCategories==2?'active':''" (click)="toContact()"><a>
                                Contact</a></li>
                        <li [ngClass]="activeCategories==1?'active':''" (click)="toAccount()"><a>
                                Account</a></li>
                        <li [ngClass]="activeCategories==3?'active':''" (click)="toOpportunity()"><a>
                                Opportunity</a></li>
                        <li [ngClass]="activeCategories==9?'active':''" (click)="toTasks()"><a>
                                Tasks</a></li>
                        <li [ngClass]="activeCategories==7?'active':''" (click)="toCases()"><a>
                                Cases</a></li>



                        <li [ngClass]="activeCategories==4?'active':''" (click)="toActivities()"><a>
                                Activities Campaign</a></li>

                        <li [ngClass]="activeCategories==10?'active':''" (click)="toForecast()"><a>
                                Forecast </a></li>
                        <li [ngClass]="activeCategories==11?'active':''" (click)="toDocuments()"><a>
                                Documents </a></li>
                        <li [ngClass]="activeCategories==12?'active':''" (click)="toNotes()"><a>
                                Notes </a></li>
                        <li [ngClass]="activeCategories==13?'active':''" (click)="toCalendar()"><a>
                                Calendar </a></li>
                                <li [ngClass]="activeCategories==14?'active':''" (click)="toEmails()"><a>
                                    Emails </a></li>

                        <li [ngClass]="activeCategories==5?'active':''" (click)="toQuotes()"><a>
                                Quotes </a></li>

                        <li [ngClass]="activeCategories==6?'active':''" (click)="toSalesOrder()"><a>
                                Sales order</a></li> 



                        </ul>
                    </div>
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCard w-100 mt-2">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    <p class="h-text">Find Sales Forecast</p>
                                    <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#createForecastPopup">New Sales Forecast</button>
                                </div>
                                <div class="create-new-leade mt-2">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">

                                        <div class="w3-card-4 w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div
                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                                        <span style="margin-left: 12px!important;"> Search Options</span>
                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 81%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;"></button>									

                                                    </div>

                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Sales Forecast ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns">
                                                                            <input type="text" class="form-control" [(ngModel)]="findForecast.forecastID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="forecastID" aria-describedby="emailHelp" placeholder="Enter Sales Forecast ID">
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Parent Sales Forecast ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns">
                                                                            <input type="text" class="form-control" [(ngModel)]="findForecast.parentForecastID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="parentForecastID" aria-describedby="emailHelp" placeholder="Enter Parent Sales Forecast ID">
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Organization Party ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns">
                                                                            <input type="text" class="form-control" [(ngModel)]="findForecast.organizationPartyID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="organizationPartyID" aria-describedby="emailHelp" placeholder="Enter Organization Party ID">
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Internal Party ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns">
                                                                            <input type="text" class="form-control" [(ngModel)]="findForecast.internalPartyID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="internalPartyID" aria-describedby="emailHelp" placeholder="Enter Internal Party ID">
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Custom Time Period ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns">
                                                                            <input type="text" class="form-control" [(ngModel)]="findForecast.customTimeID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="customTimeID" aria-describedby="emailHelp" placeholder="Enter Custom Time Period ID">
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Currency</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns" >
                                                                            <p-dropdown filter="true" optionlabel="label" [(ngModel)]="findForecast.currency" [ngModelOptions]="{standalone:true}" name="currency" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Currency">
                                                                            </p-dropdown>
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Quota Amount</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns">
                                                                            <input type="text" class="form-control" [(ngModel)]="findForecast.quotaAmount" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="quotaAmount" aria-describedby="emailHelp" placeholder="Enter Quota Amount">
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Forecast Amount</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns">
                                                                            <input type="text" class="form-control" [(ngModel)]="findForecast.forecastAmount" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="forecastAmount" aria-describedby="emailHelp" placeholder="Enter Forecast Amount">
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Best Case Amount</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns">
                                                                            <input type="text" class="form-control" [(ngModel)]="findForecast.bestCaseAmount" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="bestCaseAmount" aria-describedby="emailHelp" placeholder="Enter Best Case Amount">
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Closed Amount</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns">
                                                                            <input type="text" class="form-control" [(ngModel)]="findForecast.closedAmount" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="closedAmount" aria-describedby="emailHelp" placeholder="Enter Closed Amount">
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Percent of Quota Forecast</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns">
                                                                            <input type="text" class="form-control" [(ngModel)]="findForecast.quotaPercent" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="quotaPercent" aria-describedby="emailHelp" placeholder="Enter Percent of Quota Forecast">
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Percent of Quota Closed</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns">
                                                                            <input type="text" class="form-control" [(ngModel)]="findForecast.quotaClosed" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="quotaClosed" aria-describedby="emailHelp" placeholder="Enter Percent of Quota Closed">
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Pipeline Amount</label>
                                                                        </div>
                                                                        <div class="col-lg-2 dropdowns">
                                                                            <input type="text" class="form-control" [(ngModel)]="findForecast.pipelineAmount" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="pipelineAmount" aria-describedby="emailHelp" placeholder="Enter Pipeline Amount">
                                                                        </div>
                                                                    </div>
                                                                </div>
            
                                                                <div class="col-lg-10 main-submit-button">
                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="FindSalesForecast()">Find</button>
                                                                    <button type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>

                                        <!-- <div class="w3-card-4 classCardView w-100">
                                            <div class="header-tabs" >
                                                <h4 class=" common-styling hleft">
                                                    Search Options
                                                </h4>
                                            </div>
                                            <form>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Sales Forecast ID</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns">
                                                                <input type="text" class="form-control" [(ngModel)]="findForecast.forecastID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="forecastID" aria-describedby="emailHelp" placeholder="Enter Sales Forecast ID">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Parent Sales Forecast ID</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns">
                                                                <input type="text" class="form-control" [(ngModel)]="findForecast.parentForecastID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="parentForecastID" aria-describedby="emailHelp" placeholder="Enter Parent Sales Forecast ID">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Organization Party ID</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns">
                                                                <input type="text" class="form-control" [(ngModel)]="findForecast.organizationPartyID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="organizationPartyID" aria-describedby="emailHelp" placeholder="Enter Organization Party ID">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Internal Party ID</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns">
                                                                <input type="text" class="form-control" [(ngModel)]="findForecast.internalPartyID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="internalPartyID" aria-describedby="emailHelp" placeholder="Enter Internal Party ID">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Custom Time Period ID</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns">
                                                                <input type="text" class="form-control" [(ngModel)]="findForecast.customTimeID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="customTimeID" aria-describedby="emailHelp" placeholder="Enter Custom Time Period ID">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Currency</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns" >
                                                                <p-dropdown filter="true" optionlabel="label" [(ngModel)]="findForecast.currency" [ngModelOptions]="{standalone:true}" name="currency" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Currency">
                                                                </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Quota Amount</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns">
                                                                <input type="text" class="form-control" [(ngModel)]="findForecast.quotaAmount" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="quotaAmount" aria-describedby="emailHelp" placeholder="Enter Quota Amount">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Forecast Amount</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns">
                                                                <input type="text" class="form-control" [(ngModel)]="findForecast.forecastAmount" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="forecastAmount" aria-describedby="emailHelp" placeholder="Enter Forecast Amount">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Best Case Amount</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns">
                                                                <input type="text" class="form-control" [(ngModel)]="findForecast.bestCaseAmount" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="bestCaseAmount" aria-describedby="emailHelp" placeholder="Enter Best Case Amount">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Closed Amount</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns">
                                                                <input type="text" class="form-control" [(ngModel)]="findForecast.closedAmount" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="closedAmount" aria-describedby="emailHelp" placeholder="Enter Closed Amount">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Percent of Quota Forecast</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns">
                                                                <input type="text" class="form-control" [(ngModel)]="findForecast.quotaPercent" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="quotaPercent" aria-describedby="emailHelp" placeholder="Enter Percent of Quota Forecast">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Percent of Quota Closed</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns">
                                                                <input type="text" class="form-control" [(ngModel)]="findForecast.quotaClosed" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="quotaClosed" aria-describedby="emailHelp" placeholder="Enter Percent of Quota Closed">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Pipeline Amount</label>
                                                            </div>
                                                            <div class="col-lg-2 dropdowns">
                                                                <input type="text" class="form-control" [(ngModel)]="findForecast.pipelineAmount" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="pipelineAmount" aria-describedby="emailHelp" placeholder="Enter Pipeline Amount">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-10 main-submit-button">
                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="FindSalesForecast()">Find</button>
                                                        <button type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div> -->
                                    </div>
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCard w-100">
                                            <div class="header-tabs" >
                                                <h4 class=" common-styling hleft">
                                                    Search Results
                                                </h4>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="findSalesForecastList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Sales Forecast ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Parent Sales Forecast ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Organization Party ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Internal Party ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Custom Time Period ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Currency
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Quota Amount
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Forecast Amount
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Best Case Amount
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Closed Amount
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span  class="account-button"
                                                                                 (click)="detailPage(product.salesForecastId)">
                                                                                    {{product.salesForecastId}}
                                                                                                        </span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}"> {{product.parentSalesForecastId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}"> {{product.organizationPartyId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}"> {{product.internalPartyId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}"> {{product.customTimePeriodId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}"> {{product.currencyUomId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}"> {{product.quotaAmount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}"> {{product.forecastAmount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}"> {{product.bestCaseAmount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}"> {{product.closedAmount}}
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No Record Found</h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
<div class="modal fade" id="createForecastPopup" tabindex="-1" role="dialog" aria-labelledby="createForecastPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Create Sales Forecast</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Sales Forecast</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createForecast">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Parent Sales Forecast ID</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        [options]="parentSalesForecastIDArray" formControlName="ParentSalesForecastID" 
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Parent Sales Forecast ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Organization Party ID</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="partyIdArray" 
                                                        formControlName="organizationPartyID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Organization Party ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Internal Party ID</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="partyIdArray" 
                                                        formControlName="internalPartyID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Internal Party ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Custom Time Period ID</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="partyIdArray" 
                                                        formControlName="customTimeID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Custom Time Period ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Currency</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="currencyArray" 
                                                        formControlName="currency" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Currency">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quota Amount</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        formControlName="quotaAmount" aria-describedby="emailHelp" placeholder="Enter Quota Amount">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Forecast Amount</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                         formControlName="forecastAmount" aria-describedby="emailHelp" placeholder="Enter Forecast Amount">
                                                    </div>


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Best Case Amount</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        formControlName="bestCaseAmount" aria-describedby="emailHelp" placeholder="Enter Best Case Amount">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Closed Amount</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        formControlName="closedAmount" aria-describedby="emailHelp" placeholder="Enter Closed Amount">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Percent of Quota Forecast</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        formControlName="quotaPercent" aria-describedby="emailHelp" placeholder="Enter Percent of Quota Forecast">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Percent of Quota Closed</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                         formControlName="quotaClosed" aria-describedby="emailHelp" placeholder="Enter Percent of Quota Closed">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Pipeline Amount</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        formControlName="pipelineAmount" aria-describedby="emailHelp" placeholder="Enter Pipeline Amount">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Change Note</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        formControlName="changeNote" aria-describedby="emailHelp" placeholder="Enter Change Note">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createSalesForecast()">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>