import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { URLS } from '../../app.constant';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/api.service';



@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(readonly _ApiService: ApiService, readonly http: HttpClient, readonly toastr: ToastrService
  ) { }
  getCategoryById(id): Observable<any> {
    const categoryId = URLS.getProductCategoryById.replace(":productCategoryId", id);
    return this.http.get(categoryId)
  }
  updateCategory(formData, id): Observable<any> {
    const updateCategory = URLS.updateCategory.replace(":productCategoryId", id);
    return this.http.put(updateCategory, formData)
  }
  createCategory(formData): Observable<any> {
    const createcategory = URLS.createProductCategory;
    return this.http.post(createcategory, formData);
  }
  productCategoryTypeList(): Observable<any> {
    const categoryTypeList = URLS.ProductCategoryTypeList;
    return this.http.get(categoryTypeList);
  }
  getCategoryList(data): Observable<any> {
    const getCategory = URLS.getProductCategoryList + "?data=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getCategory);
  }
  getCategories(pagination: { pageNo: number, pageSize: number }, searchFilter: { categoryId: string, categoryIdSearchType: string, categoryName: string, categoryNameSearchType: string }): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/categories/search?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter).toPromise();
  }
  // attributes
  createCategoryAttribute(formValue: any): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/attribute`, formValue).toPromise();
  }
  updateCreateCategoryAttribute(formValue: any): Promise<any> {
    return this.http.put(`/phyCorp/control/api/v1/catalog/attribute`, formValue).toPromise();
  }
  getCategoryAttribute(productCategoryId: string): Promise<any> {
    return this.http.get(`/phyCorp/control/api/v1/catalog/attribute?productCategoryId=${productCategoryId}`).toPromise();
  }
  deleteCategoryAttribute(productCategoryId: string, attributeName: string): Promise<any> {
    return this.http.delete(`/phyCorp/control/api/v1/catalog/attribute/${productCategoryId}/${attributeName}`).toPromise();
  }
  // Links
  getCategoryLinks(pagination: { pageNo: number, pageSize: number }, productCategoryId: string): Promise<any> {
    return this.http.get(`/phyCorp/control/api/v1/catalog/productCategoryLink?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}&productCategoryId=${productCategoryId}`).toPromise();
  }
  //product list
  getAllProduct(pagination: { pageNo: number, pageSize: number }, searchProductForm): Promise<any> {
  
    return this.http.post(`/phyCorp/control/api/v1/catalog/product/products?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchProductForm).toPromise();
  }
  // getProductFeatureCategory
  getLinkTypeEnum(): Promise<any> {
    return this.http.get(`/phyCorp/control/api/v1/catalog/productCategoryLink/linkTypeEnums`).toPromise();
  }
  createCategoryLink(formData: any): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/productCategoryLink`, formData).toPromise();
  }
  updateCategoryLink(formData: any): Promise<any> {
    return this.http.put(`/phyCorp/control/api/v1/catalog/productCategoryLink`, formData).toPromise();
  }
  deleteCategoryLink(fromDate: string, linkSeqId: string, productCategoryId: string): Promise<any> {
   
    return this.http.delete(`/phyCorp/control/api/v1/catalog/productCategoryLink/${productCategoryId}/${linkSeqId}?fromDate=${fromDate}`).toPromise();
  }
  // Content
  getCategoryContent(productCategoryId: string): Promise<any> {
    return this.http.get(`/phyCorp/control/api/v1/catalog/contentToCategory/${productCategoryId}`).toPromise();
  }
  createCategoryContent(formData: any): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/contentToCategory`, formData).toPromise();
  }
  updateCategoryContent(formData: any): Promise<any> {
   
    return this.http.put(`/phyCorp/control/api/v1/catalog/contentToCategory`, formData).toPromise();
  }
  deleteCategoryContent(productCategoryId: string, prodCategoryTypeId: string, contentId: string, fromDate: string): Promise<any> {
   
    return this.http.delete(`/phyCorp/control/api/v1/catalog/contentToCategory/${productCategoryId}/${prodCategoryTypeId}/${contentId}/${fromDate}`).toPromise();
  }
  getProdCatContentType(): Promise<any> {
    return this.http.get(`/phyCorp/control/api/v1/catalog/contentToCategory/prodCatContentType`).toPromise();
  }
  getCatalogIdList(): Promise<any> {
    return this.http.get(`/phyCorp/control/api/v1/catalog/contentToCategory/ContentId`).toPromise();
  }
  // Product
  getProducts(activeOnly: boolean, productCategoryId: string): Promise<any> {
   
    return this.http.get(`/phyCorp/control/api/v1/catalog/productCategoryMember?activeOnly=${activeOnly}&productCategoryId=${productCategoryId}`).toPromise();
  }
  createCategoryProductMember(formData: any): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/productCategoryMember`, formData).toPromise();
  }
  updateCategoryProductMember(formData: any): Promise<any> {
   
    return this.http.put(`/phyCorp/control/api/v1/catalog/productCategoryMember`, formData).toPromise();
  }
  expireCategoryProductMember(productCategoryId: string, thruDate: string): Promise<any> {
   
    return this.http.put(`/phyCorp/control/api/v1/catalog/productCategoryMember/${productCategoryId}?thruDate=${thruDate}`, {}).toPromise();
  }
  removeExpiredProductMember(productCategoryId: string, validDate: string): Promise<any> {
    return this.http.delete(`/phyCorp/control/api/v1/catalog/productCategoryMember/${productCategoryId}?validDate=${validDate}`).toPromise();
  }
  removeAllProductMember(productCategoryId: string, validDate: string): Promise<any> {
    return this.http.delete(`/phyCorp/control/api/v1/catalog/productCategoryMember/expireAllCategoryProductMembers?productCategoryId=${productCategoryId}&thruDate=${validDate}`).toPromise();
  }
  deleteCategoryProductMember(productCategoryId: string, fromDate: string, productId: string): Promise<any> {
  
    return this.http.delete(`/phyCorp/control/api/v1/catalog/productCategoryMember/${productCategoryId}/${productId}/${fromDate}`).toPromise();
  }
  copyCategoryProductMember(productCategoryId: string, productCategoryIdTo: string, recurse: string, validDate: string): Promise<any> {
    return this.http.put(`/phyCorp/control/api/v1/catalog/productCategoryMember/copyCategoryProductMember?productCategoryId=${productCategoryId}&productCategoryIdTo=${productCategoryIdTo}&recurse=${recurse}&validDate=${validDate}`, {}).toPromise();
  }
  // Features 
  getCategoryFeatureGrpApply(pagination: { pageNo: number, pageSize: number }, productCategoryId: string): Promise<any> {
    return this.http.get(`/phyCorp/control/api/v1/catalog/productCategoryFeature/productFeatureCategoryGrpApply?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}&productCategoryId=${productCategoryId}`).toPromise();
  }
  createCategoryFeatureGrpApply(formData: any): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/productCategoryFeature/productFeatureCategoryGrpApply`, formData).toPromise();
  }
  getProductFeatureGroup(): Promise<any> {
    return this.http.get(`/phyCorp/control/api/v1/catalog/productCategoryFeature/productFeatureGroup`).toPromise();
  }
  updateCategoryFeatureGrpApply(formData: any): Promise<any> {
  
    return this.http.put(`/phyCorp/control/api/v1/catalog/productCategoryFeature/productFeatureCategoryGrpApply`, formData).toPromise();
  }
  deleteProductFeatureGrpApply(productCategoryId: string, productFeatureGroupId: string, fromDate: string): Promise<any> {
    return this.http.delete(`/phyCorp/control/api/v1/catalog/productCategoryFeature/productFeatureCategoryGrpApply/${productCategoryId}/${productFeatureGroupId}?fromDate=${fromDate}`).toPromise();
  }
  getApplyFeaturesToCategory(pagination: { pageNo: number, pageSize: number }, productCategoryId: string): Promise<any> {
    return this.http.get(`/phyCorp/control/api/v1/catalog/productCategoryFeature/applyFeaturesToCategory?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}&productCategoryId=${productCategoryId}`).toPromise();
  }
  createApplyFeatureToCategory(formData: any): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/productCategoryFeature/applyFeaturesToCategory`, formData).toPromise();
  }
  updateApplyFeatureToCategory(formData: any): Promise<any> {
  
    return this.http.put(`/phyCorp/control/api/v1/catalog/productCategoryFeature/applyFeaturesToCategory`, formData).toPromise();
  }
  getProductFeatureCategory(pagination: { pageNo: number, pageSize: number }, searchFilter: { description: string, descriptionSearchType: string, productFeatureCategoryId: string, productFeatureCategoryIdSearchType: string }): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/productCategoryFeature/productFeatureCategory?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter).toPromise();
  }
  deleteCategoryToFeature(productCategoryId: string, categoryFeatureId: string, fromDate: string): Promise<any> {
    return this.http.delete(`/phyCorp/control/api/v1/catalog/productCategoryFeature/applyFeaturesToCategory/${productCategoryId}/${categoryFeatureId}?fromDate=${fromDate}`).toPromise();
  }
  attachedCategoryFeature(productCategoryId: string): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/productCategoryFeature/attachProductFeaturesToCategory?productCategoryId=${productCategoryId}`, {}).toPromise();
  }

  uploadCategoryImage(productCategoryId: string, uploadFileType: string, formValue: any): Promise<any> {
    return this._ApiService.postRequest(`/phyCorp/control/api/v1/imageUpload/uploadCategoryImage?productCategoryId=${productCategoryId}&uploadFileType=${uploadFileType}`, formValue);
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      
      errorMessage = `Error: ${error.error.message}`;
    } else {
    
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
  
    return throwError(errorMessage);
  }
}
