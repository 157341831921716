<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;">Customer Relationship Management</span>

            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                flex-wrap: wrap !important;
                display: flex !important;">
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)"><a>
                  Overview </a></li>

                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)"><a>
                  Comm Event </a></li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)"><a>
                  Event Purpose(s) </a></li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)"><a>
                  Role(s) </a></li>
                            <!-- <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)"><a>
                  Communication Work Efforts </a></li> -->
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)"><a>
                  Content </a></li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)"><a>
                  Email From Unknown Party </a></li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategory(9)"><a>
                  Find Communication By Order </a></li>
                            <li [ngClass]="activeCategory==10?'active':''" (click)="changeactiveCategory(10)"><a>
                  Find Order By Return </a></li>
                            <li [ngClass]="activeCategory==11?'active':''" (click)="changeactiveCategory(11)"><a>
                  Orders</a></li>
                            <li [ngClass]="activeCategory==12?'active':''" (click)="changeactiveCategory(12)"><a>
                    Products</a></li>
                            <li [ngClass]="activeCategory==13?'active':''" (click)="changeactiveCategory(13)"><a>
                      Return</a></li>



                        </ul>
                    </div>
                    <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                       <span class="color-black pl-1 titlePanels">Communication Event ID {{overviewDetail.communicationEventId}} </span>

                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategory==3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Edit Communication Event {{overviewDetail.communicationEventId}}
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="row">
                                                                    <form class="w-100" [formGroup]="createCommEvent">
                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group rightForm">
                                                                                        <label for="exampleInputEmail1">
                                                  Communication Event ID
                
                                                </label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Communication Event ID" formControlName="commEventId" readonly>

                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                        <label for="exampleInputEmail1">
              
                                                  Parent Comm Event ID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="parentCommEventArray" optionlabel="label" placeholder="Parent Comm Event ID" filter="true" formControlName="parCommEventID">

                                                                                        </p-dropdown>

                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group rightForm">
                                                                                        <label for="exampleInputEmail1">
            
                                              Comm.Type ID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown placeholder="Comm.Type ID" [options]="comtypeIdArray" optionlabel="label" filter="true" formControlName="commTypeId">

                                                                                        </p-dropdown>

                                                                                    </div>

                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                        <label for="exampleInputEmail1">
              
                                              Status</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="stausIdArray" optionlabel="label" placeholder="Status" filter="true" formControlName="status">

                                                                                        </p-dropdown>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group rightForm">
                                                                                        <label for="exampleInputEmail1">
            
                                          Party From</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown (onChange)="contactEventMech($event.value)" [options]="partyIdArray" optionlabel="label" placeholder="Party From" filter="true" formControlName="partyFrom">

                                                                                        </p-dropdown>

                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                        <label for="exampleInputEmail1">
              
                                          Party To</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown (onChange)="contactEventMechTo($event.value)" [options]="partyIdArray" optionlabel="label" placeholder="Party To" filter="true" formControlName="partyTo">

                                                                                        </p-dropdown>

                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group rightForm">
                                                                                        <label for="exampleInputEmail1">
            
                                      Contact Mech Type ID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="contactMechTypeArray" optionlabel="label" placeholder="Contact Mech Type ID" filter="true" formControlName="conMechId">

                                                                                        </p-dropdown>


                                                                                    </div>

                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                        <label for="exampleInputEmail1">
              
                                      From Contact Mech</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="contactMechArray" optionlabel="label" placeholder="From Contact Mech" filter="true" formControlName="fromContactMechId">

                                                                                        </p-dropdown>


                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group rightForm">
                                                                                        <label for="exampleInputEmail1">
            
                                  To Contact Mech</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="contactMechToArray" optionlabel="label" placeholder="To Contact Mech" filter="true" formControlName="toContactMechId">

                                                                                        </p-dropdown>


                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                        <label for="exampleInputEmail1">
              
                                  Role Type Id From</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="roleTypeIdArray" optionlabel="label" placeholder="Role Type Id From" filter="true" formControlName="roleTypeFrom">

                                                                                        </p-dropdown>


                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group rightForm">
                                                                                        <label for="exampleInputEmail1">
            
                              Role Type Id To</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="roleTypeIdArray" optionlabel="label" placeholder="Role Type Id To" filter="true" formControlName="roleTypeTo">

                                                                                        </p-dropdown>


                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                        <label for="exampleInputEmail1">
              
                              Contact List ID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="contactListIdArray" optionlabel="label" placeholder="Contact List ID" filter="true" formControlName="contactListId">

                                                                                        </p-dropdown>


                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group rightForm">
                                                                                        <label for="exampleInputEmail1">Start Date
            
                        </label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Start Date" formControlName="startdate">


                                                                                    </div>

                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                        <label for="exampleInputEmail1"> Finish Date/Time
              
                        </label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Finish Date/Time" formControlName="finishDate">

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group rightForm">
                                                                                        <label for="exampleInputEmail1">Subject
            
                    </label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Subject" formControlName="subject">

                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                        <label for="exampleInputEmail1">
            
                      Reason Enum ID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="reasonenumArray" optionlabel="label" placeholder="Reason Enum ID" filter="true" formControlName="reasonEnumId">

                                                                                        </p-dropdown>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group rightForm">
                                                                                        <label for="exampleInputEmail1">
            
                      Content Mime Type ID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown placeholder="Content Mime Type ID" filter="true" [options]="contentMMArray" optionlabel="label" formControlName="contentMimeTypeId">

                                                                                        </p-dropdown>
                                                                                    </div>

                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                        <label for="exampleInputEmail1">Content
            
                    </label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Content" formControlName="content">
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group rightForm">
                                                                                        <label for="exampleInputEmail1">Note
            
                    </label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Note" formControlName="note">
                                                                                    </div>


                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </form>
                                                                    <div class="main-submit-button" style="margin-left: 69%;">
                                                                        <button (click)="onUpdateEvent()" type="submit" class="btn btn-secondary submit-btn">Save</button>


                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==4">

                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Communication Event Purposes


                                                        </h4>
                                                        <span class="edit-buttons">
                                <a (click)="createEventPurpose(overviewDetail.communicationEventId)">Create New Communication Event Purposes</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="eventPurpose" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Communication Event Purpose Type ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>



                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">Action
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td >
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.commEventPurposeTypeDesc}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)=deleteEventPurpose(product.communicationEventId,product.communicationEventPrpTypId) class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                          <defs>
                                            <style>
                                              .a {
                                                fill: #f44336;
                                              }
          
                                              .b {
                                                fill: #fafafa;
                                                margin-left: 20px;
                                              }
                                            </style>
                                          </defs>
                                          <path class="a fill-color"
                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                          <path class="b"
                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                            transform="translate(-147.576 -147.576)" />
                                        </svg>
                                                                                </td>



                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div *ngIf="activeCategory==5">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Communication Event Roles
                                                        </h4>
                                                        <span class="edit-buttons">
                                <a (click)="createEventRole(overviewDetail.communicationEventId)">Create New Communication Event Roles</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="eventRole" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Party ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>



                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">Contact Mech ID
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">Role Type ID
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;"> Status ID
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">Action
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td >
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.partyIdDesc}}
                                                                                    </div>
                                                                                </td>
                                                                                <td> {{product.contactMechEmail}}</td>
                                                                                <td> {{product.roleTypeId}}</td>
                                                                                <td> {{product.statusId}}</td>
                                                                                <td>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)=deleteEventRole(product.communicationEventId,product.partyId,product.roleTypeId) class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                  <defs>
                                    <style>
                                      .a {
                                        fill: #f44336;
                                      }
  
                                      .b {
                                        fill: #fafafa;
                                        margin-left: 20px;
                                      }
                                    </style>
                                  </defs>
                                  <path class="a fill-color"
                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                  <path class="b"
                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                    transform="translate(-147.576 -147.576)" />
                                </svg>
                                                                                </td>




                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div *ngIf="activeCategory==6">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Communication Work Efforts
                                                        </h4>
                                                        <span class="edit-buttons">
                                <a (click)="createEventWorkEffort()">Create</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="relatedContact" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Work Effort
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td >
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.product}}
                                                                                    </div>
                                                                                </td>




                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div *ngIf="activeCategory==7">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Communication Content
                                                        </h4>
                                                        <span class="edit-buttons">
                                <a (click)="createEventContent(overviewDetail.communicationEventId)">Create  Communication Content</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="eventContentList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>



                                                                                        Content Name
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>



                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">Delete
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td >
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.contentName}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteContent(product.communicationEventId,product.contentId,product.fromDate);" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                          <defs>
                                            <style>
                                              .a {
                                                fill: #f44336;
                                              }
          
                                              .b {
                                                fill: #fafafa;
                                                margin-left: 20px;
                                              }
                                            </style>
                                          </defs>
                                          <path class="a fill-color"
                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                          <path class="b"
                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                            transform="translate(-147.576 -147.576)" />
                                        </svg>
                                                                                </td>





                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==8">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Communications from Party origin
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="UnknownPartyComms" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Subject
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Entry Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Status ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Party ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Role Type ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Role Status ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Created
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{activity.communicationEventAndRole.subject}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{activity.communicationEventAndRole.entryDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{activity.communicationEventAndRole.statusId}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{activity.communicationEventAndRole.partyId}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.communicationEventAndRole.roleTypeId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{activity.communicationEventAndRole.roleStatusId}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.communicationEventAndRole.datetimeStarted|date:'yyyy-MM-dd hh:mm:ss'}}</td>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span class="text-center">
                                                    <h4 class="ml-2">No Record Found
                                                    </h4>
                                                </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==9">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Find Communication By Order
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div class="w3-card-4 classCard">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightForm">
                                                                                            <label for="exampleInputEmail1">Order ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2" style="display: none;">
                                                                                            <p-dropdown filter="true" style="display: none;" [(ngModel)]="advanceSearchOrderBy.orderId" [ngModelOptions]="{standalone:true}" name="orderId" [options]="opportunityIdArray" optionlabel="label"></p-dropdown>

                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email" name="orderId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" [(ngModel)]="advanceSearchOrderBy.orderId" [ngModelOptions]="{standalone:true}" name="returnId" placeholder="Order ID">

                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                            <label for="exampleInputEmail1">Communication Event ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2" style="display: none;">
                                                                                            <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" [(ngModel)]="advanceSearchOrderBy.communicationEventIdSearchType" [ngModelOptions]="{standalone:true}" name="communicationEventIdSearchType">

                                                                                            </p-dropdown>

                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email" class="form-control" name="eventId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Communication Event ID" [(ngModel)]="advanceSearchOrderBy.communicationEventId" [ngModelOptions]="{standalone:true}" name="communicationEventId">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightForm">
                                                                                            <label for="exampleInputEmail1">Party ID From</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="partyIdArray" optionlabel="label" filter="true" placeholder="Enter Party ID From" [(ngModel)]="advanceSearchOrderBy.partyIdFrom" [ngModelOptions]="{standalone:true}" name="partyIdFrom">

                                                                                            </p-dropdown>

                                                                                        </div>

                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                            <label for="exampleInputEmail1">Party ID To</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="partyIdArray" optionlabel="label" filter="true" placeholder="Enter Party ID To" [(ngModel)]="advanceSearchOrderBy.partyIdTo" [ngModelOptions]="{standalone:true}" name="partyIdTo">

                                                                                            </p-dropdown>

                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightForm">
                                                                                            <label for="exampleInputEmail1">Party ID (any role)</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="partyIdArray" optionlabel="label" filter="true" placeholder="Enter Party ID" [(ngModel)]="advanceSearchOrderBy.partyId" [ngModelOptions]="{standalone:true}" name="partyId">

                                                                                            </p-dropdown>

                                                                                        </div>


                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                            <label for="exampleInputEmail1">Comm. Type Id</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="comtypeIdArray" optionlabel="label" filter="true" placeholder="Enter Comm Type Id" [(ngModel)]="advanceSearchOrderBy.communicationEventTypeId" [ngModelOptions]="{standalone:true}" name="communicationEventTypeId">

                                                                                            </p-dropdown>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightForm">
                                                                                            <label for="exampleInputEmail1">Status Id</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="stausIdArray" optionlabel="label" filter="true" placeholder="Enter Status Id" [(ngModel)]="advanceSearchOrderBy.statusId" [ngModelOptions]="{standalone:true}" name="statusId">

                                                                                            </p-dropdown>

                                                                                        </div>



                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                            <div class="col-lg-12 col-12 main-submit-button" style="    margin-left: -25%;"><button _ngcontent-cta-c128="" type="submit" (click)="advanceSearchOrder(false)" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="resetAdvanceReset()">Reset</button>
                                                                            </div>
                                                                        </div>

                                                                        <br>
                                                                        <div class="w3-card-4 classCard">
                                                                            <header class="w3-container w3-blue">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling h4Margin">
                                                                                        Search Result
                                                                                    </h4>

                                                                                </div>
                                                                            </header>

                                                                            <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select allTableUser">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">


                                                                                        <div class="card own-account-table">
                                                                                            <p-table [value]="commByOrder" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                <ng-template pTemplate="header">
                                                                                                    <tr style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>


                                                                                                                Communication Event ID
                                                                                                                <p-sortIcon field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;"> Subject
                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                            <div style="color: white;">Type
                                                                                                                <p-sortIcon field="quantity"></p-sortIcon>
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Status Id
                                                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Party Id
                                                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Role Type Id
                                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Role Status Id
                                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Created
                                                                                                                <p-sortIcon field="price">
                                                                                                                </p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Sent
                                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Order Id
                                                                                                                <p-sortIcon field="price">
                                                                                                                </p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>


                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                    <tr>
                                                                                                        <td [ngStyle]="{'width':'180px'}" >
                                                                                                            <div class="checkbox-align">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                {{product.communicationEventId}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.subject}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.communicationEventTypeDesc}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.statusId}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.partyIdDesc}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.roleTypeIdTo}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.entryDate|date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.datetimeStarted|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                                                            {{product.orderId}}
                                                                                                        </td>



                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==10">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Find Communication By Return
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div class="w3-card-4 classCard">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightForm">
                                                                                            <label for="exampleInputEmail1">Return ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2" style="display: none;">
                                                                                            <p-dropdown filter="true" [(ngModel)]="advanceSearchOrderByReturn.returnIdSearchType" [ngModelOptions]="{standalone:true}" name="returnIdSearchType" [options]="opportunityIdArray" optionlabel="label"></p-dropdown>

                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email" name="orderId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" [(ngModel)]="advanceSearchOrderByReturn.returnId" [ngModelOptions]="{standalone:true}" name="returnId" placeholder="Return ID">

                                                                                        </div>

                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                            <label for="exampleInputEmail1">Communication Event ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2" style="display: none;">
                                                                                            <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" [(ngModel)]="advanceSearchOrderByReturn.communicationEventIdSearchType" [ngModelOptions]="{standalone:true}" name="communicationEventIdSearchType">

                                                                                            </p-dropdown>

                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email" class="form-control" name="eventId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Communication Event ID" [(ngModel)]="advanceSearchOrderByReturn.communicationEventId" [ngModelOptions]="{standalone:true}"
                                                                                                name="communicationEventId">

                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightForm">
                                                                                            <label for="exampleInputEmail1">Party ID From</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="partyIdArray" optionlabel="label" filter="true" placeholder="Enter Party ID From" [(ngModel)]="advanceSearchOrderByReturn.partyIdFrom" [ngModelOptions]="{standalone:true}" name="partyIdFrom">

                                                                                            </p-dropdown>

                                                                                        </div>

                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                            <label for="exampleInputEmail1">Party ID To</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="partyIdArray" optionlabel="label" filter="true" placeholder="Enter Party ID To" [(ngModel)]="advanceSearchOrderByReturn.partyIdTo" [ngModelOptions]="{standalone:true}" name="partyIdTo">

                                                                                            </p-dropdown>

                                                                                        </div>



                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightForm">
                                                                                            <label for="exampleInputEmail1">Party ID (any role)</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="partyIdArray" optionlabel="label" filter="true" placeholder="Enter Party ID" [(ngModel)]="advanceSearchOrderByReturn.partyId" [ngModelOptions]="{standalone:true}" name="partyId">

                                                                                            </p-dropdown>

                                                                                        </div>


                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                            <label for="exampleInputEmail1">Comm. Type Id</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="comtypeIdArray" optionlabel="label" filter="true" placeholder="Enter Comm Type Id" [(ngModel)]="advanceSearchOrderByReturn.communicationEventTypeId" [ngModelOptions]="{standalone:true}" name="communicationEventTypeId">

                                                                                            </p-dropdown>

                                                                                        </div>


                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightForm">
                                                                                            <label for="exampleInputEmail1">Status Id</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="stausIdArray" optionlabel="label" filter="true" placeholder="Enter Status Id" [(ngModel)]="advanceSearchOrderByReturn.statusId" [ngModelOptions]="{standalone:true}" name="statusId">

                                                                                            </p-dropdown>

                                                                                        </div>



                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -25%;"><button _ngcontent-cta-c128="" type="submit" (click)="advanceSearchReturnOrder(false)" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="reset()">Reset</button>
                                                                            </div>
                                                                        </div>
                                                                        <br>
                                                                        <div class="w3-card-4 classCard">
                                                                            <header class="w3-container w3-blue">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling h4Margin">
                                                                                        Search Result
                                                                                    </h4>

                                                                                </div>
                                                                            </header>
                                                                            <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select allTableUser">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">

                                                                                        <div class="card own-account-table">
                                                                                            <p-table [value]="evenTByOrder" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                <ng-template pTemplate="header">
                                                                                                    <tr style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>


                                                                                                                Communication Event ID
                                                                                                                <p-sortIcon field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;"> Subject
                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                            </div>

                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                            <div style="color: white;">Type
                                                                                                                <p-sortIcon field="quantity"></p-sortIcon>
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Status Id
                                                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Party Id
                                                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Role Type Id
                                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Role Status Id
                                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Created
                                                                                                                <p-sortIcon field="price">
                                                                                                                </p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;"> Sent
                                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                            <div style="color: white;">Return Id
                                                                                                                <p-sortIcon field="price">
                                                                                                                </p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>


                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                    <tr>
                                                                                                        <td [ngStyle]="{'width':'180px'}" >
                                                                                                            <div class="checkbox-align">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                {{product.communicationEventId}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.subject}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.communicationEventTypeDesc}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.statusId}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.partyIdDesc}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.roleTypeIdTo}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.entryDate|date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.datetimeStarted|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                                                            {{product.returnId}}
                                                                                                        </td>


                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==12">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Communication Event Products
                                                        </h4>
                                                        <span class="edit-buttons">
                                <a (click)="createProduct(overviewDetail.communicationEventId)">Create Communication Event Products</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="eventProductList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>




                                                                                        Product ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>



                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">Internal Name
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">Delete
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td >
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.productId}}
                                                                                    </div>
                                                                                </td>

                                                                                <td> {{product.productName}}</td>
                                                                                <td>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)=deleteCrmEventProductList(product.communicationEventId,product.productId) class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                          <defs>
                                            <style>
                                              .a {
                                                fill: #f44336;
                                              }
          
                                              .b {
                                                fill: #fafafa;
                                                margin-left: 20px;
                                              }
                                            </style>
                                          </defs>
                                          <path class="a fill-color"
                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                          <path class="b"
                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                            transform="translate(-147.576 -147.576)" />
                                        </svg>
                                                                                </td>





                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==2">
                                                    <div style="width: 49%; float:left">
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs">

                                                                <h4 class=" common-styling hCard" style="margin-left: 7px;">
                                                                    Communication Event
                                                                </h4>

                                                            </div>
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="row" *ngIf="this.overviewDetail">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Communication Event Id </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.communicationEventId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Communication Type Id</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.communicationEventTypeId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Parent Comm. Event Id</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.parentCommEventId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">From Party Id</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.partyIdFrom}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">To Party Id</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.partyIdTo}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Status Id</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.statusId}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Last Modified Date </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.lastUpdatedTxStamp|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Send Date </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">End Date</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.datetimeEnded|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Subject</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.subject}}</span>
                                                                                </div>





                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs">

                                                                <h4 class=" common-styling hCard" style="margin-left: 7px;">
                                                                    Content
                                                                </h4>

                                                            </div>
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="row" *ngIf="this.overviewDetail">
                                                                            <div class="article-container">
                                                                                <div class="article" style="display:contents!important;">
                                                                                    <p class="col-lg-2 col-4">Content </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.content}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Note</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.note}}</span>
                                                                                </div>




                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <br>
                                                    </div>
                                                    <div style="width: 49.8%; float:right">
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                                   border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Communication Event Role
                                                                    </h4>

                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select classUser">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="communicationEventRole" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Party ID
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>

                                                                                            <th pSortableColumn="quantity">
                                                                                                <div style="color: white;">Content Mech Id
                                                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th pSortableColumn="price">
                                                                                                <div style="color: white;">Role Type Id
                                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th pSortableColumn="price">
                                                                                                <div style="color: white;"> Status Id
                                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td >
                                                                                                <div class="checkbox-align">
                                                                                                    {{product.partyId}}

                                                                                                </div>
                                                                                            </td>
                                                                                            <td> {{product.contactMechEmail}}</td>
                                                                                            <td> {{product.roleTypeId}}</td>
                                                                                            <td> {{product.statusId}}</td>


                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                              border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Communication Content
                                                                    </h4>

                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select">



                                                                        <div class="form-group" style="width: 100%!important;">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="communicationEventContent" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Content Name
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>

                                                                                            <th pSortableColumn="quantity">
                                                                                                <div style="color: white;">Delete
                                                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>


                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td >
                                                                                                <div class="checkbox-align">
                                                                                                    {{product.contentName}}

                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteContent(product.communicationEventId,product.contentId,product.fromDate);" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                <defs>
                                                  <style>
                                                    .a {
                                                      fill: #f44336;
                                                    }
      
                                                    .b {
                                                      fill: #fafafa;
                                                      margin-left: 20px;
                                                    }
                                                  </style>
                                                </defs>
                                                <path class="a fill-color"
                                                  d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                <path class="b"
                                                  d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                  transform="translate(-147.576 -147.576)" />
                                              </svg>
                                                                                            </td>



                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br>

                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                                    border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Child Communication Events
                                                                    </h4>

                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select">



                                                                        <div class="form-group" style="width: 100%!important;">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="communicationEventChilds" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Subject
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Type
                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                </div>

                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                <div style="color: white;"> Status Id
                                                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Party Id
                                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Role Type Id
                                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Role Status Id
                                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Created
                                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Sent
                                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                                </div>

                                                                                            </th>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}" >
                                                                                                <div class="checkbox-align">

                                                                                                    {{product.subject}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.communicationEventTypeDesc}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.statusDesc}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.partyIdFrom}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.roleTypeIdFrom}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> </td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.datetimeStarted|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.datetimeStarted|date:'yyyy-MM-dd hh:mm:ss'}}</td>



                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                              border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Customer Request List
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="card own-account-table">
                                                                        <p-table [value]="customerRequestList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Cust Request ID
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;"> Cust Request Type ID
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>

                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Product Store ID
                                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Cust Request Name
                                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Priority
                                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">Cust Request Date
                                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Response Required Date
                                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> From Party ID
                                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Status ID
                                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Last Modified Date
                                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Reject
                                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                        </div>
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}" >
                                                                                        <div class="checkbox-align">

                                                                                            {{product.custRequestId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.custRequestTypeDesc}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.productStoreId}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.custRequestName}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.priority}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.custRequestDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.responseRequiredDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.fromPartyId}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.statusId}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.lastModifiedDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">Reject</td>



                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>
                                                        <br>
                                                        <br> <br> <br> <br>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==13">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Communication Event Returns
                                                        </h4>
                                                        <span class="edit-buttons">
                                <a (click)="createReturn(overviewDetail.communicationEventId)">Create Communication Event Returns</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="eventReturnList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>





                                                                                        Return ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>



                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;"> Return Header Type
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;"> Created By
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">Delete
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td >
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.returnId}}
                                                                                    </div>
                                                                                </td>

                                                                                <td> {{product.returnType}}</td>
                                                                                <td> {{product.createdBy}}</td>
                                                                                <td>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)=deleteCrmEventReturnList(product.communicationEventId,product.returnId) class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                          <defs>
                                            <style>
                                              .a {
                                                fill: #f44336;
                                              }
          
                                              .b {
                                                fill: #fafafa;
                                                margin-left: 20px;
                                              }
                                            </style>
                                          </defs>
                                          <path class="a fill-color"
                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                          <path class="b"
                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                            transform="translate(-147.576 -147.576)" />
                                        </svg>
                                                                                </td>





                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="main-submit-button">
                        <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button> 
                    </div> -->
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>