import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-blog-detail-page',
  templateUrl: './blog-detail-page.component.html',
  styleUrls: ['./blog-detail-page.component.css']
})
export class BlogDetailPageComponent implements OnInit {
  activeCategory= 2;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;

  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  
  createblogForm: FormGroup;
  createNewArticleForm: FormGroup;
  show: boolean;
  blogContentId: any;
  updateBlogPat: any;
  EditBlogRes: any;
  EditBlogResGet: any;
  blogContentAll: any;
  updateBlogArticlePat: any;
  templateArray: { label: string; value: string; }[];
  statusIdArray: { label: string; value: string; }[];
  fileData: File;
  view: boolean;
  contentName: any;
  contentdescription: any;
  articleContentId: any;
  ViewBlogArticleAll: any;
  articleContentIdVal: any;
  dataResourceId: any;
  dataResourceTypeId: any;
  dataResourceIds: any;
  dataResourceTypeIds: any;
  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _CategoryService: CategoryService,
    private _location: Location,
  )
  {
    this.templateArray=[
      {
        label: 'Top Left',
        value: 'BLOG_TPL_TOPLEFT'
      },
      {
        label: 'Top Center',
        value: 'BLOG_TPL_TOPCENTER'
      },
    ];
    this.statusIdArray=[
      {
        label: 'Publish',
        value: 'CTNT_PUBLISHED'
      },
      {
        label: 'Preview',
        value: 'CTNT_INITIAL_DRAFT'
      },
    ];
    this.createblogForm = this._FormBuilder.group({
      blogName:"",
      blogDescription:"",
    });

    this.createNewArticleForm = this._FormBuilder.group({
      articleName:"",
      description:"",
      summary:"",
      article:"",
      image:"",
      template:"",
      status:"",
    });
  }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.blogContentId = params["blogContentId"];
      this.contentName = params["contentName"];
      this.contentdescription = params["contentdescription"];
      this.articleContentId = params["articleContentId"];
    });
    this.view=true;
    this.show=true;

    this.EditBlog();
    this.blogContent();
  }
  generatePDFBlog() {
    this.spinner.show();
  
    this.myContactsService.generatePDFBlog(this.blogContentId).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "BLOG.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  contentRolepage(){
    this._Router.navigate(['/content/content-page/content-detail-page'], { queryParams: { contentId: this.blogContentId, matchid: "blog" } });
  }
  MultiRespResetForm(){
    this.show=true;
    this.createNewArticleForm.reset();
   
  }
  referToView(id,name,description){
    this.articleContentIdVal=id;
    this.ViewBlogArticle();
    this.view=false;
    this.activeCategory=4;
  }
  ViewBlogArticle() {
    this.spinner.show();
    this.accountsService.ViewBlogArticle(this.articleContentIdVal,this.blogContentId).subscribe(res => {
      this.ViewBlogArticleAll = res.data;
      this.spinner.hide();
    })
  }
 
  updateBlogArticle(): void {
    this.spinner.show();
   
    const requestData ={
      "articleData": this.createNewArticleForm.value.article,
      "blogContentId": this.blogContentId,
      "contentId": this.updateBlogArticlePat.contentId,
      "contentName": this.createNewArticleForm.value.articleName,
      "description": this.createNewArticleForm.value.description,
      "statusId": this.createNewArticleForm.value.status,
      "summaryData": this.createNewArticleForm.value.summary,
      "templateDataResourceId": this.createNewArticleForm.value.template,
      "uploadedFile": ""
      }
     
    this.accountsService.updateBlogArticle(requestData).subscribe((res: any) => { 
      if (res.success) {
      //this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.dataResourceIds = res.data.dataResourceId;
        this.dataResourceTypeIds = res.data.dataResourceTypeId;
        this.blogContent();
        this.uploadImages();
        //this.show=true;
        //this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateBlogArticlePatch(product) {
    this.show=false;
    this.updateBlogArticlePat  = product;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.createNewArticleForm;
        formValue.patchValue({
            articleName: this.updateBlogArticlePat.contentName,
            description: this.updateBlogArticlePat.description,
            status: this.updateBlogArticlePat.statusId,
            summary: this.updateBlogArticlePat.summaryData,
            template: this.updateBlogArticlePat.dataResourceId,
            article: this.updateBlogArticlePat.articleData,
       
        })
      }, 2000);
      this.spinner.hide();
  }
  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  
  // createBlogArticle() {
  //   this.spinner.show();
  //   let formData = new FormData();
  //   formData.append('file', this.fileData);
  //   const fromActualdate=this.Editform.get('fromDate').value;
  //   this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
  //   const throughActualdate=this.Editform.get('throughDate').value;
  //   this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
  //   const requestData ={
  //     "articleData": this.createNewArticleForm.value.article,
  //     "blogContentId": this.blogContentId,
  //     "contentName": this.createNewArticleForm.value.articleName,
  //     "description": this.createNewArticleForm.value.description,
  //     "statusId": this.createNewArticleForm.value.status,
  //     "summaryData": this.createNewArticleForm.value.summary,
  //     "templateDataResourceId": this.createNewArticleForm.value.template,
  //     "uploadedFile": formData
  //   }
   
    
  //   this.accountsService.createPartyContent(formData,encodeURIComponent(JSON.stringify(requestData))).subscribe((res: any) => {
  //     if (res.success) {
  //       this._ToastrService.success(res.data.successMessage);
  //       this.closebutton.nativeElement.click();
	// 	    this.bsModalRef.hide();
  //       this.spinner.hide();
  //       this._location.back();
  //     }
  //   }, (err) => {
  //     this.spinner.hide();
  //     this._ToastrService.error(err.message);

  //   });
  //   }

  updateBlogArticles(){
    this.updateBlogArticle()
    setTimeout(() => {
      this.uploadImages();
    }, 2000);

  }

  uploadImages() {
    this.spinner.show();
    const req = new FormData();
    req.append("file", this.fileData);

    this.myContactsService.uploadImage(req,"",
      this.dataResourceIds, this.dataResourceTypeIds).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.createNewArticleForm.reset();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  uploadImage() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.myContactsService.uploadImage(formData,""
      ,this.dataResourceId, this.dataResourceTypeId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.createNewArticleForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  createBlogArticles(){
    this.createBlogArticle()
    setTimeout(() => {
      this.uploadImage();
    }, 2000);
  
  }
  createBlogArticle(): void {
    this.spinner.show();
    const formData ={
      "articleData": this.createNewArticleForm.value.article,
      "blogContentId": this.blogContentId,
      "contentName": this.createNewArticleForm.value.articleName,
      "description": this.createNewArticleForm.value.description,
      "statusId": this.createNewArticleForm.value.status,
      "summaryData": this.createNewArticleForm.value.summary,
      "templateDataResourceId": this.createNewArticleForm.value.template,
      "uploadedFile": ""
      }
    this.myContactsService.createBlogArticles(formData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.blogContent();
        this.dataResourceId = res.data.dataResourceId;
        this.dataResourceTypeId = res.data.dataResourceTypeId;
        this.uploadImage();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  blogContent() {
    this.spinner.show();
    this.accountsService.blogContent(this.blogContentId).subscribe(res => {
      this.blogContentAll = res.data.blogContent;
      this.spinner.hide();
    })
  }
  updateBlog(): void {
    this.spinner.show();
    const requestData ={
      "contentAssocTypeId": 'SUB_CONTENT',
      "contentId": this.blogContentId,
      "contentName": this.createblogForm.value.blogName,
      "description": this.createblogForm.value.blogDescription,
      }
     
    this.accountsService.updateBlog(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.EditBlog();
        this.closebutton2.nativeElement.click();
        this.show=true;
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateBlogPatch() {
    this.show=false;

    this.spinner.show();
      setTimeout(() => {
        const formValue = this.createblogForm;
        formValue.patchValue({
          blogName: this.EditBlogResGet.contentName,
          blogDescription: this.EditBlogResGet.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  EditBlog() {
    this.spinner.show();
    this.accountsService.EditBlog(this.blogContentId).subscribe(res => {
      this.EditBlogRes = res.data.EditBlog;
      this.EditBlogResGet = res.data.EditBlog[0];

      this.spinner.hide();
    })
  }

  openList()
  {
    this._Router.navigate(['/content/blog'])
  }
  openEdit()
  {
    this._Router.navigate(['/content/blog/blog-detail-page'])

  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

changeactiveCategory(tab: number)
{
  this.activeCategory = tab;

}

}
