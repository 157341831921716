import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
import { SortEvent } from 'primeng/api';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-work-effort-rates',
  templateUrl: './work-effort-rates.component.html',
  styleUrls: ['./work-effort-rates.component.css']
})
export class WorkEffortRatesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 5;
  addAssignmentRate: FormGroup;
  WorkEffortRate: any;
  WorkEffortRateArray: any[]=[];
  PeriodTypeId: any;
  PeriodTypeIdArray: any[]=[];
  currencyArray: any[]=[];
  WorkEffortRatePagedata: any;
  workEffortId: any;
  total=0;
  rowNumber=0;
  pageSize= 500;
  rows = 50;
  pageNo=1;
  search="";
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    readonly datePipe: DatePipe,
    private _location: Location,
    
    readonly myContactsService: MyContactsService,
    readonly addSkillService: AddSkillService,
        readonly _FormBuilder: FormBuilder,
    private _Router : Router

  ) {
    this.addAssignmentRate = this._FormBuilder.group({
      rateType: [''],
      periodTypeID: [''],
      rateAmount: [''],
      uomId: [''],
    })
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
    });
    this.getWorkEffortRate();
    this.getPeriodTypeId();
    this.getCurrencyRate();
    this.getWorkEffortRatePage();
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  deleteWorkEffortRate(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
  
  //"fromDate": this.datePipe.transform(product.fromDate,"yyyy-MM-dd hh:mm:ss"),
  "periodTypeId": product.periodTypeId,
  "rateAmount": product.rateAmount,
  "rateCurrencyUomId": product.rateCurrencyUomId,
  "rateTypeId": product.rateTypeId,
  "workEffortId": this.workEffortId
        }
        this.myContactsService.deleteWorkEffortRate(req).subscribe(res => {
          this.getWorkEffortRatePage(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  updateWorkEffortRate(): void {
    this.spinner.show();
    
    
     const formData ={
  "periodTypeId": this.addAssignmentRate.value.periodTypeID,
  "rateAmount": this.addAssignmentRate.value.rateAmount,
  "rateCurrencyUomId": this.addAssignmentRate.value.uomId,
  "rateTypeId": this.addAssignmentRate.value.rateType,
  "workEffortId": this.workEffortId
         
     }
     this.myContactsService.updateWorkEffortRate(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.closebutton.nativeElement.click();
         this.addAssignmentRate.reset();
         this.getWorkEffortRatePage();
         
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
  getWorkEffortRatePage(){
    this.spinner.show();
    this.myContactsService.getWorkEffortRatePage(this.workEffortId).subscribe((res:any)=> {
      this.WorkEffortRatePagedata = res.data[0].workEffortRatePage;
      this.spinner.hide();
    
    })   
   }
   customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  editWorkEffort1(){
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"]),{ queryParams: { workEffortId: this.workEffortId} }
  }
  summary(){
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"],{ queryParams: { workEffortId: this.workEffortId} })
  }

  childWorkEfforts(){
    this._Router.navigate(["/psa/work-effort/task-list-view/childWorkEfforts"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  partyAssigns(){
    this._Router.navigate(["/psa/work-effort/task-list-view/PartyAssigns"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  rates(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Rates"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  commEvents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/CommEvents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  shopLists(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ShopLists"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requests(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requests"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requirements(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requirements"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  quotes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Quotes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  orderHeaders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/OrderHeaders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  timeEntries(){
    this._Router.navigate(["/psa/work-effort/task-list-view/TimeEntries"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  notes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Notes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Contents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  products(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Products"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  reviews(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Reviews"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  keywords(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Keywords"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contactMechs(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ContactMechs"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  agreementApplics(){
    this._Router.navigate(["/psa/work-effort/task-list-view/AgreementApplics"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  fixedAssets(){
    this._Router.navigate(["/psa/work-effort/task-list-view/FixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  attributes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Attributes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  eventReminders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/EventReminders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  getWorkEffortRate() {
    this.spinner.show();
    this.addSkillService.getWorkEffortRate().subscribe(res => {
      this.WorkEffortRate = res.data[0].workEffortRate;
      this.spinner.hide();
      for (const value of this.WorkEffortRate) {
        this.WorkEffortRateArray.push({
          label: value.rateTypeId,
          value: value.rateTypeId

        })
      }
    })
  }
  getPeriodTypeId() {
    this.spinner.show();
    this.addSkillService.getPeriodTypeId().subscribe(res => {
      this.PeriodTypeId = res.data;
      this.spinner.hide();
      for (const value of this.PeriodTypeId) {
        this.PeriodTypeIdArray.push({
          label: value.periodTypeId,
          value: value.periodTypeId

        })
      }
    })
  }
  getCurrencyRate() {
    this.spinner.show();
    this.myContactsService.getCurrencyDataList().subscribe(res => {
      const currencyList = res.data.currencyList;
      this.spinner.hide();
      for (const value of currencyList) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }

}
