<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Manage Portal Pages</span>
            </div>

            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCard w-100">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    <p class="h-text">Portal Pages for Applications</p>
                                    <button type="button" class="btn btn-secondary submit-btn ml-2"
                                     data-toggle="modal" data-target="#createPreferencePopup">New Portal Page</button>
                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                    <div class="w3-card-4 classCard w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">
                                                    <div class="card own-account-table">
                                                        <p-table [value]="createPortalPageList" [paginator]="true"
                                                        [rows]="rows" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align"
                                                                        style="color: white;">
                                                                        <p-checkbox styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Edit
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'180px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Name
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'180px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Description
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'180px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Original Page
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                               
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'150px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span (click)="updatePortal(product)"
                                                                             class="account-button"  >
                                                                            Edit
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    {{product.portalPageName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    {{product.description}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    {{product.originalPortalPageId}}
                                                                </td>
                                      

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w3-card-4 classCard w-100 mt-2">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    <p class="h-text"> Edit portal page: {{this.parentPortalPageId}}</p>
                                    <!-- <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addCommAdminpopup">Add Communication Event</button>
                                    <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addCommRolespopup">Add Roles</button>
                                    <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addCommContentpopup">Add Content</button> -->

                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                    <div class="w3-card-4 classCard w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <button type="submit" class="btn btn-secondary submit-btn">Add
                                                            Column</button>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1"> PortalPageId:  {{this.parentPortalPageId}}</label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <button type="submit"
                                                            class="btn btn-danger ">Delete Column</button>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                            (click)="PortletPage(this.parentPortalPageId)">Add
                                                            A Portlet...</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                            data-toggle="modal" data-target="#columnwidth">Width:
                                                            auto</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                    <div class="w3-card-4 classCardView w-100 mt-2">
                                        <div class="header-tabs" >
                                            <h4 class="common-styling hleft">
                                                Portlet(My Communication)
                                            </h4>
                                        </div>
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <p class="h-text">Communications of Party {{communicationEventId}}
                                                <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addCommAdminpopup" (click)="allPage()">All Communications</button>
                                                <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addCommRolespopup" (click)="notePage()">New Message</button>
                                                <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addCommContentpopup" (click)="emailPage()">New Email</button>
                                            </p>
                                        </div>
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100" style="align-items: initial;">
                                            <div style="width: 49%; float: left;">
                                                <div class="w3-card-4 classCardView w-100 mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class=" common-styling hleft">
                                                            From: Admin
                                                        </h4>
                                                    </div>
                                                    <form class="w-100" [formGroup]="sendFrom">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-4 form-group classInput">
                                                                        <label for="exampleInputEmail1">From Email Addr
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-5">
                                                                        <p-dropdown filter="true" optionlabel="label" [options]="party" formControlName="fromEmail" placeholder="Enter From Email Addr">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-4 form-group classInput">
                                                                        <label for="exampleInputEmail1">Send Date
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-5">
                                                                       
                                                                            <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="sendDate" placeholder="Enter Send Date">
                                                                        
                                                                    </div>
                                                                    <div class="col-lg-4 form-group classInput">
                                                                        <label for="exampleInputEmail1">To Party ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-5">
                                                                        <p-dropdown filter="true" optionlabel="label" 
                                                                        [options]="partyIdArray" formControlName="partyTo" placeholder="Enter Party To">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-4 form-group classInput">
                                                                        <label for="exampleInputEmail1">Subject
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-5">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" formControlName="subject"
                                                                         placeholder="Enter Subject">
                                                                    </div>
                                                                    <div class="col-lg-4 form-group classInput">
                                                                        <label for="exampleInputEmail1">Content
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-5">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" formControlName="content" 
                                                                        placeholder="Enter Content">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-10 main-submit-button">
                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="sendCommunicationEvent()">Send</button>
                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="sendCommunicationEventSave()">Save</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div style="width: 49.8%; float: right;">
                                                <div class="w3-card-4 classCardView w-100 mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class=" common-styling hleft">
                                                            Communication Event Roles
                                                            <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                            data-toggle="modal"
                                                            data-target="#createRolePopup">Create New Communication Event Roles</button>
                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="eventRoles" [paginator]="true"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>Party ID
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Contact Mech ID
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Role Type ID
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Status ID
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Remove
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span>{{product.partyId}}</span>
                                                                                    </div>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.contactMechId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.roleTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.statusId}}

                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <button type="button"
                                                                                        class="btn btn-secondary submit-btn ml-2"
                                                                                        (click)="deleteCommunicationEventRole(product)">Remove</button>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>

                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                               
                                                </div>
                                                <div class="w3-card-4 classCardView w-100 mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class=" common-styling hleft">
                                                            Communication Content
                                                            <button type="button"
                                                                class="btn btn-secondary submit-btn ml-2"
                                                                data-toggle="modal"
                                                                data-target="#createUploadPopup">Create New Communication Content</button>
                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="communicationContentUploadById"
                                                                    [paginator]="true" [rows]="rows"
                                                                    scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox
                                                                                        _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>Existing Content Id
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Parent Content Id
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    URL
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox
                                                                                        _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span>{{product.existingContentId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.parentContentId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.url}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <span (click)="deleteUpload(product)">Delete</span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage"
                                                                        let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                    <p class="paginate_data">View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                    <div class="w3-card-4 classCardView w-100 mt-2">
                                        <div class="">
                                            <div class="row text-center d-flex align-items-center"
                                                style="padding-top:20px">
                                                <div class="col-md-4 d-flex" style="padding-left:30px">
                                                    <div class="btn-group">
                                                        <div class="btn btn-primary" mwlCalendarPreviousView
                                                            [view]="view" [(viewDate)]="viewDate"
                                                            (viewDateChange)="closeOpenMonthViewDay()">
                                                            Previous
                                                        </div>
                                                        <div class="btn btn-outline-secondary" mwlCalendarToday
                                                            [(viewDate)]="viewDate">
                                                            Today
                                                        </div>
                                                        <div class="btn btn-primary" mwlCalendarNextView [view]="view"
                                                            [(viewDate)]="viewDate"
                                                            (viewDateChange)="closeOpenMonthViewDay()">
                                                            Next
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <h3 class="mb-0">{{ viewDate | calendarDate:(view +
                                                        'ViewTitle'):'en' }}</h3>
                                                </div>
                                                <div class="col-md-4 d-flex justify-content-end"
                                                    style=" padding-right:40px">
                                                    <div class="btn-group">
                                                        <div class="btn btn-primary"
                                                            (click)="setView(CalendarView.Month)"
                                                            [class.active]="view === CalendarView.Month">
                                                            Month
                                                        </div>
                                                        <div class="btn btn-primary"
                                                            (click)="setView(CalendarView.Week)"
                                                            [class.active]="view === CalendarView.Week">
                                                            Week
                                                        </div>
                                                        <div class="btn btn-primary" (click)="setView(CalendarView.Day)"
                                                            [class.active]="view === CalendarView.Day">
                                                            Day
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div [ngSwitch]="view">
                                                <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
                                                    [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                    [activeDayIsOpen]="activeDayIsOpen"
                                                    (dayClicked)="dayClicked($event.day)"
                                                    (eventClicked)="handleEvent('Clicked', $event.event)"
                                                    (eventTimesChanged)="eventTimesChanged($event)">
                                                </mwl-calendar-month-view>
                                                <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week"
                                                    [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                    (eventClicked)="handleEvent('Clicked', $event.event)"
                                                    (eventTimesChanged)="eventTimesChanged($event)">
                                                </mwl-calendar-week-view>
                                                <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day"
                                                    [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                    (eventClicked)="handleEvent('Clicked', $event.event)"
                                                    (eventTimesChanged)="eventTimesChanged($event)">
                                                </mwl-calendar-day-view>
                                            </div>

                                            <!-- Everything you see below is just for the demo, you don't need to include it in your app -->

                                            <br /><br /><br />

                                            <h3>
                                                <!-- <span class="text-black" *ngIf="isForm">Add/modify a calendar event</span> -->
                                                <button class="btn btn-primary float-right" (click)="addEvent()">
                                                    Add new
                                                </button>
                                                <div class="clearfix"></div>
                                            </h3>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createPreferencePopup" tabindex="-1" role="dialog" aria-labelledby="createPreferencePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add a New Portal Page</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add a New Portal Page</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createPortal">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Portal Page Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="portalPageName"
                                                            placeholder="Enter Portal Page Name">
                                                    </div>

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="description"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4  main-submit-button" style="margin-left: 33%;">
                                                <button type="submit" (click)="createPortalPage()"
                                                    class="btn btn-secondary submit-btn">Create</button>
                                               
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createRolePopup" tabindex="-1" role="dialog" aria-labelledby="createRolePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add a Role</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add a New Portal Page</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100 mt-2" [formGroup]="addRole">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="partyID"
                                                            placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="roleTypeIdPreArray" formControlName="roleTypeID"
                                                            placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 main-submit-button">
                                                <button type="submit" (click)="createCommunicationEventRole()"
                                                    class="btn btn-secondary submit-btn">Add</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createUploadPopup" tabindex="-1" role="dialog" aria-labelledby="createUploadPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add a Content</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Content</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100 mt-2" [formGroup]="addContent">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Existing Content
                                                            ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="existingContentIdsArray"
                                                            formControlName="contentID"
                                                            placeholder="Enter Existing Content ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Uploaded File

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="file" class="form-control"
                                                            (change)="fileProgress($event)" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="file"
                                                            placeholder="Enter file">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Parent Content
                                                            ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="parentContentIdArray"
                                                            formControlName="parentContentID"
                                                            placeholder="Enter Parent Content ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 main-submit-button">
                                                <button type="submit" (click)="createUpload()"
                                                    class="btn btn-secondary submit-btn">Upload</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>