<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Cost Detail : [{{ this.CalcId}}]</span> <span></span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>


                <div>
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">



                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select " style="display: block!important;">

                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="w3-card-4 classCard">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling Hleft">
                                                                        Calc Cost Information
                                                                    </h4>
                                                                    <span class="edit-buttons hCreate">
                               
                                          <a  data-toggle="modal"
                                          data-target="#exampleModalCenter" (click)="getProdCostByIdData()">Update</a>
                                        </span>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select" >
                                                                        <div class="color-black container">
                                                                            <div class="row">
                                                                                <div class="article-container" >
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Cost Component Calc ID</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="calcInfo">{{this.CalcId}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Description</p>
                                                                                        <span style="display: contents;" class="color-grey pl-20 col-lg-10 col-12" *ngIf="calcInfo">{{this.calcInfo.description}}</span>

                                                                                    </div>



                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Fixed Cost</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="calcInfo">{{this.calcInfo.fixedCost}}</span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Variable Cost</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="calcInfo">{{this.calcInfo.variableCost}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Per Milli Second</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="calcInfo">{{this.calcInfo.perMilliSecond}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Currency</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="calcInfo">{{this.calcInfo.currencyUomDesc}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Cost Custom Method ID</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="calcInfo">{{this.calcInfo.costCustomMethodDesc}}</span>
                                                                                    </div>





                                                                                </div>




                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>



                                                </div>
                                            </div>



                                        </div>

                                    </div>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <!-- <div class="main-submit-button">
                    <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
                </div> -->
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Cost Component Calc Entries</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">

                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                        Add Cost Component Calc Entries
    
                                    </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                        Update Cost Component Calc Entries
    
                                    </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCost">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="Description">
                                                    </div>

                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Fixed Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fixed Cost" formControlName="FixedCost">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Variable Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Variable Cost" formControlName="VariableCost">
                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Per Milli Second</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Per Milli Second" formControlName="PerMilliSecond">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Currency</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Currency" filter="true" formControlName="Currency" [options]="currencyArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Cost Custom Method ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Cost Custom Method ID" filter="true" formControlName="CostCustomMethodID" [options]="costCustomMethodIDArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -14% !important;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>