<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12 pad_0">

            <!-- <div class="manager_tab_ul_div bg-white mt-20 mb-20 font-medium" style="margin-left: -15px;">
                <ul style="margin-left: -27px;">
                    <li>
                        <a data-toggle="modal" data-target="#openTerminal">   
                            Open Terminal
                        </a>
                    </li>
                    <li>
                        <a data-toggle="modal" data-target="#closeTerminal">
                            Close Terminal
                        </a>
                    </li>
                    <li>
                        <a data-toggle="modal" data-target="#paidIn">
                             Paid In
                        </a>
                    </li>
                    <li>
                        <a data-toggle="modal" data-target="#paidOut">
                             Paid Out
                        </a>
                    </li>
                    <li>
                        <a data-toggle="modal" data-target="#promo">
                            Promo
                        </a>
                    </li>
                    <li>
                        <a data-toggle="modal" data-target="#voidOrder">
                             Void Order
                        </a>
                    </li>
                    <li>
                        <a (click)="shutDownCode()">
                             Shut Down
                        </a>
                    </li>


                </ul>
            </div> -->


            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
                <span class="color-black pl-1 titlePanels">Keyboard Shortcuts</span>
                 <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toReports()">Home</button>
                     <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
 
                 </span>
             </div>

            <div class="bg-white color-grey w-100" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard" style="border-left-width: 0;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                            <!-- <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                                <a>Main</a>
                            </li> -->
                            <li [ngClass]="activeCat==2?'active':''" (click)="managerOpen()">
                                <a>Manager</a>
                            </li>
                        </ul>
                    </div>
                    <div class="manager_tab_ul_div bg-white mt-20 mb-20 font-medium" style="margin-left: -15px;">
                        <ul style="margin-left: -27px;">
                            <li>
                                <a data-toggle="modal" data-target="#openTerminal">   
                                    Open Terminal
                                </a>
                            </li>
                            <li>
                                <a data-toggle="modal" data-target="#closeTerminal">
                                    Close Terminal
                                </a>
                            </li>
                            <li>
                                <a data-toggle="modal" data-target="#paidIn">
                                     Paid In
                                </a>
                            </li>
                            <li>
                                <a data-toggle="modal" data-target="#paidOut">
                                     Paid Out
                                </a>
                            </li>
                            <li>
                                <a data-toggle="modal" data-target="#promo">
                                    Promo
                                </a>
                            </li>
                            <li>
                                <a data-toggle="modal" data-target="#voidOrder">
                                     Void Order
                                </a>
                            </li>
                            <li>
                                <a (click)="shutDownCode()">
                                     Shut Down
                                </a>
                            </li>
        
        
                        </ul>
                    </div>
                    <div class="container-fluid">
                        <ul class="webpos_nav_view_new tabbing-accordians tabClass" style="
                            width: 100% !important;padding-top: 13px !important;
                    flex-wrap: wrap !important;
                    display: flex !important;">
                            <li (click)="fouc_click1()">
                                <a>
                                    <div>
                                        <p>F1</p>
                                        Product Search
                                    </div>

                                </a>
                            </li>

                            <li (click)="fouc_click2()">
                                <div>
                                    <p>F2</p>
                                    Party Search
                                </div>

                            </li>
                            <li data-toggle="modal" data-target="#exampleModalCenter">
                                <a>
                                    <div>
                                        <p>F3</p>
                                        Pay Cash
                                    </div>

                                </a>
                            </li>
                            <li data-toggle="modal" data-target="#exampleModalCenter1">
                                <a>
                                    <div>
                                        <p>F4</p>
                                        Pay Check
                                    </div>

                                </a>
                            </li>


                        </ul>
                        <ul class="webpos_nav_view_new1 tabbing-accordians tabClass" style="
                            width: 100% !important;padding-top: 13px !important;
                    flex-wrap: wrap !important;
                    display: flex !important;">

                            <li data-toggle="modal" data-target="#exampleModalCenter2">
                                <a>
                                    <div>
                                        <p>F6</p>
                                        Pay Gift Card
                                    </div>

                                </a>
                            </li>
                            <li data-toggle="modal" data-target="#exampleModalCenter3">
                                <a>
                                    <div>
                                        <p>F7</p>
                                        Pay By CC
                                    </div>

                                </a>
                            </li>
                            <li (click)="payFinish()">
                                <a>
                                    <div>
                                        <p>F8</p>
                                        Checkout
                                    </div>

                                </a>
                            </li>
                            <li (click)="fouc_click3()">
                                <a>
                                    <div>
                                        <p>F9</p>
                                        Change Quantity
                                    </div>

                                </a>
                            </li>
                            <li (click)="fouc_click4()">
                                <a>
                                    <div>
                                        <p>F10</p>
                                        ADD Quantity
                                    </div>

                                </a>
                            </li>

                        </ul>
                        <ul class="webpos_nav_view_new2 tabbing-accordians tabClass" style="border-bottom: 2px solid #e1e1e1;
                            margin-bottom: 22px !important;width: 100% !important;padding-top: 13px !important;
                    flex-wrap: wrap !important;
                    display: flex !important;">

                            <li (click)="fouc_click5()" style="background-color: #FB475E;opacity: 0.9;">
                                <a>
                                    <div>
                                        <p>F11</p>
                                        Subtract Quantity
                                    </div>

                                </a>
                            </li>
                            <li style="background-color: #FB475E;opacity: 0.9;" (click)="emptyCart()">
                                <a>
                                    <div>
                                        <p>F12</p>
                                        Empty Cart
                                    </div>

                                </a>
                            </li>
                            <li style="background-color: #9ABA12;opacity: 0.9;">
                                <a>
                                    <div>
                                        <p>UP</p>
                                        Previous Row
                                    </div>

                                </a>
                            </li>
                            <li style="background-color: #9ABA12;opacity: 0.9;">
                                <a>
                                    <div>
                                        <p>Down</p>
                                        Next Row
                                    </div>

                                </a>
                            </li>

                        </ul>
                    </div>
                    <div class="container-fluid">
                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                            <div class="w3-card-4 classCard" style="margin-left: -15px !important; width: 103%; margin-bottom: 2% !important;">
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100">
                                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                    <div class="w-100 d-flex">
                                                        <div class="bg-white color-grey" style="width: 50%;">
                                                            <div class="w3-card-4 classCard" >
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                    <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Billing Address</p>
                                                                    <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Shipping Address</p>
                                                                    <!-- <span>
                                                                    <button type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addCategorypopup">New Category</button>
                                                            </span> -->
                                                                </div>
                                                                <div class="">
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100" [formGroup]="addByName">
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-5 form-group">
                                                                                                <div *ngIf="!this.partyAllData">
                                                                                                No Party Information
                                                                                                </div>
                                                                                                <div *ngIf="this.partyAllData">
                                                                                                    <p> {{partyAllData.firstName}} {{partyAllData.lastName}}</p>
                                                                                                    <p class="pt-2"> {{partyAllData.address1}}</p>
                                                                                                    <p class="pt-2"> {{partyAllData.firstName}}</p>
                                                                                                    <p class="pt-2">{{partyAllData.city}},{{partyAllData.stateProvinceGeoId}} {{partyAllData.postalCode}}</p>
                                                                                                    <p class="pt-2"> {{partyAllData.countryGeoId}}</p>


                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="form-group col-lg-2" style="margin-left: -31px;">
                                                                                                <label style="white-space: nowrap;" for="exampleInputEmail1">Search By
                                                                                            </label>
                                                                                            </div>
                                                                                            <div class="col-lg-5 form-group">
                                                                                                <p-dropdown filter="true" formControlName="searchById" optionlabel="label" 
                                                                                                placeholder="Search" [options]="searchByNameArray">
                                                                                                </p-dropdown>
                                                                                                <div class="d-flex" style="margin-top: 14px;">
                                                                                                    <input type="text" class="webpos_billing_input2 form-control" formControlName="nameValue" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" style="border-radius: 1px !important;
                                                                                                width: 95% !important;
                                                                                                margin-left: -58px;
                                                                                                height: 29px !important;">


                                                                                                    <button type="submit" class="btn btn-info submit-btn" style="border-radius: 1px !important;
                                                                                                height: 29px !important;
                                                                                                margin-left: 9px;
                                                                                                font-weight: 600;" (click)="getFindParties()">Search</button>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <!-- <div class="col-lg-10 col-10 main-submit-button">
                                                                                <button type="submit" class="btn btn-secondary submit-btn">Add</button>
                                                                            </div> -->
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="mt-3 w3-card-4 classCard w-100">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                    <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Party Information</p>
            
                                                                </div>
                                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                    <div class="col-lg-12 col-12">
                                                                        <div class="form-group">
            
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="FindPartiesAll" [paginator]="false" [rows]="3" scrollHeight="150px"
                                                                                scrollWidth="100%" [rowsPerPageOptions]="[5,10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
            
                                                                                                    PartyId
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
            
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    FirstName
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    LastName
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    StatusId
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Address Line1
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    City
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Postal Code
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    State/Province
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Country Geo Id
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                           
                                                                                           
            
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    <span  class="account-button"   (click)="getPartyInformation(product)">
                                                                                                       {{product.partyId}} 
                                                                                                    </span>
            
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.firstName}} 
            
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
            
                                                                                                {{product.lastName}} 
            
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.statusId}} 
            
            
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.address1}} 
            
            
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.city}} 
            
            
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.postalCode}} 
            
            
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.stateProvinceGeoId}} 
            
            
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.countryGeoId}} 
            
            
                                                                                            </td>
                           
                                                                                            <!-- <td [ngStyle]="{'width':'180px'}">
                                                                                                <span>
                                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                                                                        pointer; ">Update</small>
                                                                                                        <small  style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                                                                        pointer; ">Delete</small>
                                                                                                        
                                                                                                    </span>
                                                                                               
                                                                                            </td> -->
            
                                                                                        </tr>
                                                                                    </ng-template>
            
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                <h4
                                                                                                    class="ml-2">
                                                                                                    No Record
                                                                                                    Found</h4>
                                                                                            </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
            
                                                                                <!-- <p class="paginate_data">
                                                                                    View per page </p> -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="bg-white color-grey" style="width: 50%;
                                                                margin-left: 14px;">
                                                            <div class="w3-card-4 classCard w-100">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                    <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Select Sales Reps</p>

                                                                </div>
                                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                    <div class="col-lg-12 col-12">
                                                                        <div class="form-group">

                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="SelectSalesReps" [paginator]="false" [rows]="3" scrollHeight="150px"
                                                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">

                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                <div class="checkbox-align" style="padding: 4px 9px;">

                                                                                                    <span style="cursor: pointer;">
                                                                                                            {{product.lastName}} {{product.firstName}} ({{product.partyId}})
                                                                                                        </span>

                                                                                                </div>
                                                                                            </td>


                                                                                        </tr>
                                                                                    </ng-template>

                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                <h4
                                                                                                    class="ml-2">
                                                                                                    No Record
                                                                                                    Found</h4>
                                                                                            </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>

                                                                                <!-- <p class="paginate_data">
                                                                                    View per page </p> -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="w3-card-4 classCssShadow" style="border:1px solid;
                                            border-color: #0d3769 !important;">
                                                                        <header class="w3-container w3-blue">
                                                                            <div class="header-tabs">
                                                                                <h4 class=" common-styling" style="margin-left: 7px;">
                                                                                    Select Sales Reps
                                                                                </h4>
            
                                                                            </div>
                                                                        </header>
            
                                                                        <div class="w3-container">
                                                                            <div class="panel-body" style="    background: white;">
                                                                                <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select classUser">
                                                                                    <div class="form-group">
                                                                                
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> -->


                                                        </div>
                                                    </div>




                                                </div>
                                                <div class="row mb-5" style="width: 100%;">
                                                    <div class="col-md-3">
                                                        <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;height: 61%;">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Product Search</p>
                                                                <!-- <span>
                                                                <button type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addCategorypopup">New Category</button>
                                                        </span> -->
                                                            </div>
                                                            <div class="">
                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        <form class="w-100" [formGroup]="addByProductSearch">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="form-group">
                                                                                            <label for="exampleInputEmail1">Search By
                                                                                        </label>
                                                                                        </div>
                                                                                        <div class="col-lg-8 form-group">
                                                                                            <p-dropdown filter="true" formControlName="searchByProductSearch" 
                                                                                            optionlabel="label" placeholder="Search" [options]="searchByProductArray">
                                                                                            </p-dropdown>

                                                                                        </div>
                                                                                        <div class="d-flex">
                                                                                            <input type="text" class="webpos_billing_input1 form-control" formControlName="productSearchValue" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" style="border-radius: 1px !important;
                                                                                        width: 54% !important;
                                                                                        margin-left: 15px;
                                                                                        height: 29px !important;">


                                                                                            <button type="submit" class="btn btn-info submit-btn" style="border-radius: 1px !important;
                                                                                        height: 29px !important;
                                                                                        margin-left: 9px;
                                                                                        font-weight: 600;"  data-toggle="modal" data-target="#FindProductNewPopup" (click)="getFindProductNew()">Search</button>
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <!-- <div class="col-lg-10 col-10 main-submit-button">
                                                                            <button type="submit" class="btn btn-secondary submit-btn">Add</button>
                                                                        </div> -->
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="w3-card-4 classCard mt-3" style="margin-left: 6px;margin-right: -18px;">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Card Item Selected</p>
                                                                <!-- <span>
                                                                <button type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addCategorypopup">New Category</button>
                                                        </span> -->
                                                            </div>
                                                            <div class="">
                                                                <div class="container-fluid mb-3">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <p>Photo not available</p>
                                                                        </div>

                                                                        <div *ngIf="this.productIdData" class="col-md-12 mt-2">
                                                                            <!-- <p>SAUCE-CM</p> <br>
                                                                            <span>Chilli Sauce with Mozzarella</span> -->
                                                                            <div *ngIf="this.onloadTable && !this.removeTable && !this.updateTable">
                                                                                <p>{{this.productIdData.productId}}</p>
                                                                                <p class="pt-2">{{this.productIdData.productName}}</p>
                                                                            </div>
                                                                            <div *ngIf="!this.onloadTable && !this.removeTable && this.updateTable">
                                                                                <p>{{this.lastupdateItem.productId}}</p>
                                                                                <p class="pt-2">{{this.lastupdateItem.productName}}</p>
                                                                            </div>
                                                                            <div *ngIf="!this.onloadTable &&  !this.updateTable && this.removeTable">
                                                                                <p>{{this.lastRemoveItem.productId}}</p>
                                                                                <p class="pt-2">{{this.lastRemoveItem.productName}}</p>
                                                                            </div>


                                                                            <form class="w-100" [formGroup]="cardItemSelected">
                                                                                <div class=" font-13 font-medium own-user-select">
                                                                                    <div class="">
                                                                                        <div class="row mt-2">

                                                                                            <div class="col-lg-3 form-group classInputwebpos">
                                                                                                <label style="margin-left: 11px !important;
                                                                                        margin-bottom: 1px !important;" for="exampleInputEmail1">Quantity
                                                                                        </label>
                                                                                            </div>
                                                                                            <div class="col-lg-9 form-group">
                                                                                                <input #uservalue style="
                                                                                        display: initial;margin-right: 6px;
                                                                                        margin-top: -4px;
                                                                                        width: 66% !important;
                                                                                        border-radius: 4px !important;" type="email" class="form-control webpos_billing_input3" formControlName="Quantity" aria-describedby="emailHelp"  type="number">
                                                                                                <span style="    font-size: 14px;
                                                                                    
                                                                                        cursor: pointer;
                                                                                        " (click)="add(uservalue)"><i class="fa fa-plus" aria-hidden="true"></i></span>
                                                                                                <span style="    font-size: 14px;
                                                                                      
                                                                                        margin-left: 6px;
                                                                                        cursor: pointer;" (click)="subs(uservalue)"><i class="fa fa-minus" aria-hidden="true"></i></span>

                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row">

                                                                                            <div class="col-lg-4 form-group classInputwebpos1">
                                                                                                <label style="margin-left: 7px !important;" for="exampleInputEmail1">New price
                                                                                        </label>
                                                                                            </div>
                                                                                            <div class="col-lg-8 form-group">
                                                                                                <input style="margin-top: -1px;
                                                                                        width: 76% !important;
                                                                                        border-radius: 4px !important;
                                                                                        margin-left: -20px;" type="email" class="form-control" formControlName="newprice" aria-describedby="emailHelp" >
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12 col-12">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="updateProductIdS()" style="margin-left: 90px;">Update</button>
                                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="deleteCartItem()" style="display: none;">Delete</button>
                                                                                </div>
                                                                            </form>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5">
                                                        <div class="w3-card-4 classCard w-100">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Cart </p>

                                                            </div>
                                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div *ngIf="!this.checkOutActive" class="form-group">
                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div *ngIf="this.getCartres" class="color-black container">
                                                                                <div class="row">
                                                                                    <div class="col-md-4">
                                                                                        TransactionId
                                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                                            {{getCartres.transactionId}}
                                                                                          
                                                                                          </span>
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                        Cash
                                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                                            {{getCartres.cashAmount}}
                                                                                          
                                                                                          </span>
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                        Total Cart
                                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                                            {{getCartres.totalDue}}
                                                                                       
                                                                                        </span>
                                                                                    </div>
        
        
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-md-4">
                                                                                       Till
                                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                                            {{getCartres.drawerNumber}}
                                                                                          
                                                                                          </span>
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                       Personal Check
                                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                                            {{getCartres.checkAmount}}
                                                                                            </span>
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                        Total Promotions
                                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                                          
                                                                                       
                                                                                        </span>
                                                                                    </div>
        
        
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-md-4">
                                                                                        Terminal
                                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                                            {{getCartres.isOpen}}
                                                                                          
                                                                                          </span>
                                                                                    
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                        Gift Card
                                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                                            {{getCartres.giftAmount}}
                                                                                        
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                        Total Sales Tax
                                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                                          
                                                                                       
                                                                                        </span>
                                                                                    </div>
        
        
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-md-4">
                                                                                      
                                                                                    
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                        Credit Card
                                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                                            {{getCartres.creditAmount}}
                                                                                       
                                                                                        </span>
                                                                                       
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                        Total Shipping
                                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                                         
                                                                                       
                                                                                        </span>
                                                                                    </div>
        
        
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-md-4">
                                                                                        Total Due
                                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                                            {{getCartres.totalDue}}
                                                                                            
                                                                                            </span>
                                                                                    
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                       Total Paid
                                                                                                <span class="color-grey pl-2 font-weight-bold">
                                                                                                    {{getCartres.totalPay}}
                                                                                               
                                                                                                </span>
                                                                                       
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                                Total
                                                                                                <span class="color-grey pl-2 font-weight-bold">
                                                                                                    {{getCartres.totalDue}}
                                                                                                
                                                                                                </span>
                                                                                    </div>
        
        
                                                                                </div>
                                                                               
                                                                            </div>
                                                                        </div>

                                                                        <div class="card own-account-table">
                                                                            <p-table *ngIf="this.onloadTable && !this.removeTable && !this.updateTable" [value]="cartTableData"
                                                                            [paginator]="false" [rows]="3" scrollHeight="150px"
                                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
        
                                                                                                Product
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
        
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Qunatity
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Unit Price
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Adjustments
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Item Total
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Action
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span  class="account-button"  >
                                                                                                    {{product.productName}}
                                                                                                    </span>
        
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            {{product.productQuantity}}
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            ${{product.price}}
        
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            ${{product.adjustment}}
        
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
        
                                                                                            ${{product.itemTotal}}
        
                                                                                        </td>
        
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            <span>
                                                                                                    <small  style="margin-left: 9px;font-weight: 600;cursor: pointer;color:blue " (click)="remove(product)">Delete</small>
                                                                                                    
                                                                                                </span>
                                                                                            <!-- <span  class="account-button"  >
                                                                                                    <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                            </span> -->
                                                                                        </td>
        
                                                                                    </tr>
                                                                                </ng-template>
        
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                            <h4
                                                                                                class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p-table  *ngIf="!this.onloadTable && !this.removeTable && this.updateTable" [value]="updateTableRes" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
        
                                                                                                Product
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
        
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Qunatity
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Unit Price
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Adjustments
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Item Total
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Action
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span  class="account-button"  >
                                                                                                    {{product.productName}}
                                                                                                    </span>
        
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            {{product.productQuantity}}
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            ${{product.price}}
        
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            ${{product.adjustment}}
        
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
        
                                                                                            ${{product.itemTotal}}
        
                                                                                        </td>
        
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            <span>
                                                                                                    <small  style="margin-left: 9px;font-weight: 600;cursor: pointer;color:blue " (click)="remove(product)">Delete</small>
                                                                                                    
                                                                                                </span>
                                                                                            <!-- <span  class="account-button"  >
                                                                                                    <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                            </span> -->
                                                                                        </td>
        
                                                                                    </tr>
                                                                                </ng-template>
        
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                            <h4
                                                                                                class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p-table *ngIf="!this.onloadTable && !this.updateTable && this.removeTable" [value]="removeTableRes"  [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
        
                                                                                                Product
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
        
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Qunatity
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Unit Price
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Adjustments
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Item Total
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Action
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span  class="account-button"  >
                                                                                                    {{product.productName}}
                                                                                                    </span>
        
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            {{product.productQuantity}}
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            ${{product.price}}
        
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            ${{product.adjustment}}
        
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
        
                                                                                            ${{product.itemTotal}}
        
                                                                                        </td>
        
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            <span>
                                                                                                    <small  style="margin-left: 9px;font-weight: 600;cursor: pointer;color:blue " (click)="remove(product)">Delete</small>
                                                                                                    
                                                                                                </span>
                                                                                            <!-- <span  class="account-button"  >
                                                                                                    <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                            </span> -->
                                                                                        </td>
        
                                                                                    </tr>
                                                                                </ng-template>
        
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                            <h4
                                                                                                class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="w3-card-4 classCard" style="margin-left: -19px;margin-right: -6px;">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Browse Categories</p>

                                                            </div>
                                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="BrowseCategoriesData" [paginator]="false" [rows]="3" scrollHeight="150px"
                                                                            scrollWidth="100%" [rowsPerPageOptions]="[5,10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                     
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" style="overflow-y: scroll !important; height: 137px;" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align" style="padding: 4px 9px;">
                                                                                               
                                                                                                <span  style="cursor: pointer;color:#0d3769 !important;" (click)="getCategoriesFirstId(product)">
                                                                                                            {{product.categoryName}}
                                                                                                </span>
        
                                                                                            </div>
                                                                                        </td>
        
        
                                                                                    </tr>
                                                                                </ng-template>
        
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                    <h4
                                                                                                        class="ml-2">
                                                                                                        No Record
                                                                                                        Found</h4>
                                                                                                </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <!-- <p class="paginate_data">
                                                                                View per page </p> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="mt-3 w3-card-4 classCard" style="margin-left: -19px;margin-right: -6px;">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Product From this Category</p>
        
                                                            </div>
                                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
        
                                                                        <div class="card own-account-table">
                                                                           
                                                                            <div class="row">
                                                                                <div class="col-md-4" *ngFor="let CategoriesSecond of BrowseCategoriesSecond">
                                                                                    <div class="mt-1 mb-2" style="background-color: yellow;padding: 12px 6px;">
                                                                                        <div style="
                                                                                        background-color: #fff;
                                                                                        margin: 8px 3px;
                                                                                        padding-left: 7px;"
                                                                                        >
                                                                                        Photo Not Available
                                                                                        </div>
                                                                                    </div>
                                                                                    <span (click)="getProductID(CategoriesSecond.data.productId)">
                                                                                        {{CategoriesSecond.productName}}
                                                                                    </span>
                                                                                 
                                                                                </div>
                                                                           </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="addGeopopup" tabindex="-1" role="dialog" aria-labelledby="addGeopopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Geos</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Geos</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addGeo">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Geo ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="geoID" optionlabel="label" placeholder="Enter Geo ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Geo Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="enumID" optionlabel="label" placeholder="Enter Product Geo Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="desc" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addIDpopup" tabindex="-1" role="dialog" aria-labelledby="addIDpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Good Identifications</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Good Identifications</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addGoodID">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">ID Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="idType" optionlabel="label" placeholder="Enter ID Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">ID Value<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="idValue" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter ID Value">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!--Newcode1-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content  cssModelLengthwebpos">
            <div class="modal-header">


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton>&times;</span>
        </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;height: 1px !important;">
                        <div class="container-fluid">

                        </div>

                        <div class="create-new-leade mt-5">
                            <div class="mt-3" style="text-align: center;">
                                <div *ngIf="this.getCartres">
                                    <strong>Total Due: ${{getCartres.totalDue}}</strong>
                                </div>
                                <div *ngIf="this.getCartres" class="mt-1 mb-1">
                                    <strong>Total Already In Cash: ${{getCartres.totalDue}}</strong>
                                </div>
                                <!-- <div>
                                    Cash Amount: <input style="width: 20%!important;" type="text">
                                </div> -->

                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="payCash">
                                            <div class=" font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row mt-2">

                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Cash amount
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input type="text" style="margin-top: -4px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="amountCash" aria-describedby="emailHelp" placeholder="Enter Cash Amount">
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12">
                                                <button type="submit" (click)="createPayCash()" class="btn btn-secondary submit-btn">Do you confirm?</button>
                                                <button type="submit" class="btn btn-secondary submit-btn">Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end1-->
<!--Newcode2-->
<div class="modal fade" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content  cssModelLengthwebpos">
            <div class="modal-header">


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton2>&times;</span>
        </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;height: 1px !important;">
                        <div class="container-fluid">

                        </div>

                        <div class="create-new-leade mt-5">
                            <div class="mt-3" style="text-align: center;">
                                <div *ngIf="this.getCartres">
                                    <strong>Total Due: ${{getCartres.totalDue}}</strong>
                                </div>
                                <div class="mt-1 mb-1" *ngIf="this.getCartres">
                                    <strong>Total Already In Cash: ${{getCartres.totalDue}}</strong>
                                </div>


                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="payCheck">
                                            <div class=" font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row mt-2">

                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Cash amount
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="amountCheck" aria-describedby="emailHelp" >
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Cash ref no.
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="refNum" aria-describedby="emailHelp" >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12">
                                                <button type="submit" (click)="createpayCheck()" class="btn btn-secondary submit-btn">Do you confirm?</button>
                                                <button type="submit" class="btn btn-secondary submit-btn">Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end2-->
<!--Newcode3-->
<div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content  cssModelLengthwebpos">
            <div class="modal-header">


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton3>&times;</span>
        </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;height: 1px !important;">
                        <div class="container-fluid">

                        </div>

                        <div class="create-new-leade mt-5">
                            <div class="mt-3" style="text-align: center;">
                                <div *ngIf="this.getCartres">
                                    <strong>Total Due: ${{getCartres.totalDue}}</strong>
                                </div>
                                <div class="mt-1 mb-1" *ngIf="this.getCartres">
                                    <strong>Already pay with gift card ${{getCartres.totalDue}}</strong>
                                </div>
                                <!-- <div>
                                    Cash Amount: <input style="width: 20%!important;" type="text">
                                </div> -->

                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="payGiftCard">
                                            <div class=" font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row mt-2">

                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Gift card amount
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="amountGiftCard" aria-describedby="emailHelp" >
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Gift card ref No.
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="refNum" aria-describedby="emailHelp" >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12">
                                                <button type="submit" (click)="createpayGiftCard()" class="btn btn-secondary submit-btn">Do you confirm?</button>
                                                <button type="submit" class="btn btn-secondary submit-btn">Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end3-->
<!--Newcode4-->
<div class="modal fade" id="exampleModalCenter3" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content  cssModelLength">
            <div class="modal-header">


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton4>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">

                        </div>

                        <div class="create-new-leade mt-5">
                            <div class="mt-3" style="text-align: center;">
                                <div *ngIf="this.getCartres">
                                    <strong>Total Due : ${{getCartres.totalDue}}</strong>
                                </div>
                                <div class="mt-1 mb-1">
                                    <strong>Already pay with gift card</strong>
                                </div>
                                <div style="display: flex;justify-content: center;">
                                   
                                    Swipe Credit Card
                                    <input (click)="onChange($event)"  type="checkbox" style="margin-left: 13px;">
                                </div>
                                <!-- <div>
                                    Cash Amount: <input style="width: 20%!important;" type="text">
                                </div> -->

                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="payByCC">
                                            <div class=" font-13 font-medium own-user-select">
                                                <div *ngIf="this.showCCForm" class="col-lg-12">
                                                    <div class="row mt-2">

                                                        <div class="col-lg-3 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Swipe Data
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-9 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 90% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="swipeData" aria-describedby="emailHelp" >
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Credit card type
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <p-dropdown 
                                                            filter="true" formControlName="cardType" optionlabel="label" 
                                                            placeholder="Enter Card Type" [options]="cardTypeDropArray">
                                                            </p-dropdown>
                                                            <!-- <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="cardType" aria-describedby="emailHelp" > -->
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputwebposA">
                                                            <label for="exampleInputEmail1">Credit card security code
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="securityCode" aria-describedby="emailHelp" >
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Credit card amount
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="amountCreditCard" aria-describedby="emailHelp" >
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputwebposA">
                                                            <label for="exampleInputEmail1">postal code
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="postalCode" aria-describedby="emailHelp" >
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Credit card ref. num.
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="creditrefNum" aria-describedby="emailHelp" >
                                                        </div>


                                                    </div>
                                                </div>



                                                <div *ngIf="!this.showCCForm" class="col-lg-12">
                                                    <div class="row mt-2">

                                                        <div class="col-lg-3 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">First Name
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="firstName" aria-describedby="emailHelp" >
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputwebposA">
                                                            <label for="exampleInputEmail1">Last Name
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="lastName" aria-describedby="emailHelp" >
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Credit card number	
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="cCardNo" aria-describedby="emailHelp" >

                                                        </div>

                                                        <div class="col-lg-3 form-group classInputwebposA">
                                                            <label for="exampleInputEmail1">Credit card expiring	
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <p-dropdown  filter="true" formControlName="expMonth" optionlabel="label" 
                                                            placeholder="Enter Month" [options]="monthDropArray">
                                                            </p-dropdown>
                                                            <!-- <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="expMonth" aria-describedby="emailHelp" > -->
                                                           <span>
                                                            <input style="margin-top: 16px;
                                                            margin-left: 5px;
                                                        border-radius: 4px !important;" type="email" class="form-control" formControlName="expYear" aria-describedby="emailHelp" placeholder="Enter Year">
                                                           </span> 
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Credit card type
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <p-dropdown 
                                                            filter="true" formControlName="cardType" optionlabel="label" 
                                                            placeholder="Search" [options]="cardTypeDropArray">
                                                            </p-dropdown>
                                                            <!-- <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="cardType" aria-describedby="emailHelp" > -->
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputwebposA">
                                                            <label for="exampleInputEmail1">Credit card security code
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="securityCode" aria-describedby="emailHelp" >
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Credit card amount
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="amountCreditCard" aria-describedby="emailHelp" >
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputwebposA">
                                                            <label for="exampleInputEmail1">postal code
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="postalCode" aria-describedby="emailHelp" >
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Credit card ref. num.
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input style="margin-top: -4px;
                                                            width: 125% !important;
                                                            border-radius: 4px !important;" type="email" class="form-control" formControlName="creditrefNum" aria-describedby="emailHelp" >
                                                        </div>


                                                    </div>
                                                </div>

                                                
                                            </div>
                                            <div class="col-lg-12 col-12" style="padding-left: 43%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="payCreditCard()">Do you confirm?</button>
                                                <button type="submit" class="btn btn-secondary submit-btn">Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end4-->
<!--Newcode5-->
<div class="modal fade" id="openTerminal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content  cssModelLengthwebpos">
            <div class="modal-header">


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton13>&times;</span>
        </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;height: 1px !important;">
                        <div class="container-fluid">

                        </div>

                        <div class="create-new-leade mt-5">
                            <div class="mt-3" style="text-align: center;">
                               

                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="openTerminal">
                                            <div class=" font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row mt-2">

                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Start Drawer Amount
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input type="text" style="margin-top: 0px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="drawerAmt" aria-describedby="emailHelp" placeholder="Enter Cash Amount">
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12" style="padding-left: 153px;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createOpenTerminal()">Do you confirm?</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end5-->
<!--Newcode6-->
<div class="modal fade" id="closeTerminal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content  cssModelLengthwebpos">
            <div class="modal-header">


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton14>&times;</span>
        </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;height: 1px !important;">
                        <div class="container-fluid">

                        </div>

                        <div class="create-new-leade mt-5">
                            <div class="" style="text-align: center;">
                               

                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="closeTerminal">
                                            <div class=" font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Enter Cash Amount
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input type="text" style="margin-top: 0px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="cashAmt" aria-describedby="emailHelp" placeholder="Enter Cash Amount">
                                                        </div>
                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Enter Check Amount
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input type="text" style="margin-top: 0px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="checkAmt" aria-describedby="emailHelp" placeholder="Enter Check Amount">
                                                        </div>
                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Enter Credit Card Amount
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input type="text" style="margin-top: 0px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="creditCardAmt" aria-describedby="emailHelp" placeholder="Enter CC Amount">
                                                        </div>
                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Enter Gift Card Amount
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input type="text" style="margin-top: 0px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="giftCardAmt" aria-describedby="emailHelp" placeholder="Enter GC Amount">
                                                        </div>
                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Enter Other Payment Amount
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input type="text" style="margin-top: 0px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="otherpayAmt" aria-describedby="emailHelp" placeholder="Enter OP Amount">
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12" style="padding-left: 153px;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="CloseTerminal()">Do you confirm?</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end6-->
<!--Newcode6-->
<div class="modal fade" id="paidIn" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content  cssModelLengthwebpos">
            <div class="modal-header">


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton15>&times;</span>
        </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;height: 1px !important;">
                        <div class="container-fluid">

                        </div>

                        <div class="create-new-leade mt-5">
                            <div class="" style="text-align: center;">
                               

                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="paidIn">
                                            <div class=" font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                
                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Enter Amount To Paid In
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input type="text" style="margin-top: 0px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="paidIn" aria-describedby="emailHelp" placeholder="Paid In Amount">
                                                        </div>
                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">You Have to Choose a Reason
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group" style="max-width: 36%;">
                                                            <p-dropdown  filter="true" formControlName="reason" 
                                                            optionlabel="label" placeholder="Choose reason" [options]="paidInArray">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">You Can Insert a Comment
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input type="text" style="margin-top: 0px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="insertcmt" aria-describedby="emailHelp" placeholder="comment">
                                                        </div>
                                                       
                                                
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12" style="padding-left: 153px;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="PaidIn()">Do you confirm?</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end6-->
<!--Newcode6-->
<div class="modal fade" id="paidOut" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content  cssModelLengthwebpos">
            <div class="modal-header">


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton16>&times;</span>
        </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;height: 1px !important;">
                        <div class="container-fluid">

                        </div>

                        <div class="create-new-leade mt-5">
                            <div class="" style="text-align: center;">
                               

                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="paidOut">
                                            <div class=" font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                
                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Enter Amount To Withdraw
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input type="text" style="margin-top: 0px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="withdrawAmt" aria-describedby="emailHelp" placeholder="Withdraw Amount">
                                                        </div>
                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">You Have to Choose a Reason
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group" style="max-width: 36%;">
                                                            <p-dropdown   filter="true" formControlName="reason" 
                                                            optionlabel="label" placeholder="Choose Reason" [options]="paidOutArray">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">You Can Insert a Comment
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group" style="max-width: 36%;">
                                                            <input type="text" style="margin-top: 0px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="insertcmt" aria-describedby="emailHelp" placeholder="Comment">
                                                        </div>
                                                       
                                                
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12" style="padding-left: 153px;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="PaidOut()">Do you confirm?</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end6-->
<!--Newcode6-->
<div class="modal fade" id="promo" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content  cssModelLengthwebpos">
            <div class="modal-header">


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton17>&times;</span>
        </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;height: 1px !important;">
                        <div class="container-fluid">

                        </div>

                        <div class="create-new-leade mt-5">
                            <div class="" style="text-align: center;">
                               

                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="promo">
                                            <div class=" font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                
                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Enter Promo Code
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input type="text" style="margin-top: 0px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="pormoCode" aria-describedby="emailHelp" placeholder="Enter Promo Code">
                                                        </div>
                                                       
                                                       
                                                
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12" style="padding-left: 153px;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="PromoCode()">Do you confirm?</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end6-->
<!--Newcode6-->
<div class="modal fade" id="voidOrder" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content  cssModelLengthwebpos">
            <div class="modal-header">


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton18>&times;</span>
        </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;height: 1px !important;">
                        <div class="container-fluid">

                        </div>

                        <div class="create-new-leade mt-5">
                            <div class="" style="text-align: center;">
                               

                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="voidOrder">
                                            <div class=" font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                
                                                        <div class="col-lg-6 form-group classInputwebpos">
                                                            <label for="exampleInputEmail1">Enter Order Number To Void
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 form-group">
                                                            <input type="text" style="margin-top: 0px;
                                                            width: 66% !important;
                                                            border-radius: 4px !important;" id="exampleInputEmail1" class="form-control" formControlName="numToVoid" aria-describedby="emailHelp" placeholder="Enter Order Num">
                                                        </div>
                                                       
                                                       
                                                
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12" style="padding-left: 153px;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="VoidOrder()">Do you confirm?</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end6-->

<!--Newcode7-->
<div class="modal fade" id="FindProductNewPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content  cssModelLengthprodcutPopup">
            <div class="modal-header">


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton5>&times;</span>
        </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp w-100">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Product</p>

                    </div>
                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">

                                <div class="card own-account-table">
                                    <p-table [value]="FindProductNew" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[5,10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: white;">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>

                                                        ProductId
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        productName
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Product Description
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                               
                                               
                                        

                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'150px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        <span  class="account-button"   (click)="productIdCart(product)">
                                                           {{product.productId}} 
                                                        </span>

                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">
                                                    {{product.productName}} 

                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">

                                                    {{product.description}} 

                                                </td>
                                               
                                        

                                            </tr>
                                        </ng-template>

                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                    <h4
                                                        class="ml-2">
                                                        No Record
                                                        Found</h4>
                                                </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>

                                    <p class="paginate_data">
                                        View per page </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end7-->