<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Employee Summary ></span>
                <span class="color-black pl-1"> {{editMode? 'Update':'Create'}} Contact</span>
            </div>
            <div class="w3-card-4" style="border:1px solid;
            border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
            <header class="w3-container w3-blue">
                <div class="header-tabs">
                  <h4 class=" common-styling" style="margin-left: 7px;">
                    {{editMode? 'Update':'Create'}} Contact
                  </h4>
               
                </div>
              </header>
           <div class=" bg-white color-grey">
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="contactForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group" style="    text-align: right!important;
                                            padding-right: 15px!important;
                                            margin-left: -7%;
                                        ">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Mech Purpose Type Id<span
                                                        class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="contactPurpose"
                                                placeholder="Mech Purpose Type Id"
                                                formControlName="contactMechPurposeTypeId"></p-dropdown>
                                            <small
                                                *ngIf="!!contactForm.controls.contactMechPurposeTypeId.invalid && !!contactForm.controls.contactMechPurposeTypeId.touched"
                                                class="text-danger">Mech Purpose Type Id is required</small>
                                            </div>

                                            <div class="col-lg-3 form-group" style="text-align: right!important;padding-right: 15px!important;margin-left: 1%;">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Allow Solicitation</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="array" placeholder="Select"
                                                formControlName="allowSolicitation"></p-dropdown>
                                            </div>
                                         
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group" style="    text-align: right!important;
                                            padding-right: 15px!important;
                                            margin-left: -7%;
                                        ">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Country Code</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Country Code"
                                                formControlName="countryCode">
                                            </div>

                                            <div class="col-lg-3 form-group" style="    text-align: right!important;
                                            padding-right: 15px!important;
                                            margin-left: 1%;
                                        ">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Area Code</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Area Code"
                                                formControlName="areaCode">
                                            </div>
                                          
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group"  style="    text-align: right!important;
                                            padding-right: 15px!important;
                                            margin-left: -7%;
                                        ">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Fax Number</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Fax Number"
                                                formControlName="contactNumber">
                                            </div>

                                            <div class="col-lg-3 form-group" style="    text-align: right!important;
                                            padding-right: 15px!important;
                                            margin-left: 1%;
                                        ">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Extension</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Extension"
                                                formControlName="extension">
                                            </div>
                                          
                                        </div>
                                    </div>
                                   
                                  
                                  
                                  
                                  
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: -24%;">
                                <button type="submit" *ngIf="editMode" (click)="update()"
                                    class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" *ngIf="!editMode" (click)="submit()"
                                    class="btn btn-secondary submit-btn">Submit</button>
                                <button type="submit" (click)="cancelSubmit();"
                                    class="btn btn-degault cncl-btn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 