import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-financial-check',
  templateUrl: './financial-check.component.html',
  styleUrls: ['./financial-check.component.css']
})
export class FinancialCheckComponent implements OnInit {
  pastDueInvoices: any[];
  invoiceDueSoon: any[];
  isShown = false; 
  pastDueInvoiceTotal: string;
  activeCategory=2;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  invoiceDueSoonTotal: string;
  agreementType: string;
  activeCategoryMain=2;
  getMainOrg: any;
;
  constructor(
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly _AccountingApService: AccountingApService,
    readonly spinner: NgxSpinnerService,
    private _location: Location,
  ) {
    this.invoiceDueSoon = [];
    this.pastDueInvoices = [];
    this.pastDueInvoiceTotal = '';
    this.invoiceDueSoonTotal = '';
  }
  ngOnInit(): void {
    this.activeCategoryMain=2;
    this.getGlAccountListData();

  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/financial/main']);
  }
  
 
  glOrganizationAccountingSummary(id){
    this.router.navigate(['/financial/financial-check-detail-summary'], { queryParams: { partyId: id } });
  }
  financialUrl(){
    this.router.navigate(["financial/financial-account"])
  }
  CheckURL(){
    this.router.navigate(["financial/financial-check"])
  }
  getGlAccountListData() {
    this.spinner.show();
    this.accountsService.getOrganizationMain().subscribe((res: any) => {
      this.spinner.hide();
      this.getMainOrg = res.data;
    })
   
  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
}
