<div class="modal-header">
    <span class="color-black pl-1">Add Party Tax Authority Info</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>

<div class="row p-15">
    <div class="col-12">
        <!-- <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
           <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Party Tax Authority Info


            </span>
            <span class="color-black pl-1" > >Add Party Tax Authority Info

            </span>

            <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
        </div> -->
        <div class=" bg-white color-grey">
            <div class="w3-card-4 classCard">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">
        
                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show" ><a>
                    Add Party Tax Authority Info

                </a></li>
                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show" ><a>
                    Update Party Tax Authority Info

                </a></li>
                    
                
                </ul>
                </div>
            
            
            <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form  class="w-100" [formGroup]="createContact">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-4 form-group rightForm">
                                            <label for="exampleInputEmail1">
                                                Tax Auth Geo ID<span style="color:red">*</span></label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown *ngIf="!this.show" [options]="getGeoTyArray" filter="true"
                                            formControlName="TaxAuthGeoID"
                                            placeholder="Enter Tax Auth Geo ID"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                        <input type="text" *ngIf="this.show"  formControlName="TaxAuthGeoID"  class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" placeholder="Enter Tax Auth Geo ID" readonly>
                                        
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">
                                                Tax Authority Party<span style="color:red">*</span></label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown *ngIf="!this.show" [options]="partyIdArray" filter="true"
                                                formControlName="TaxAuthorityParty"
                                            placeholder="Enter Tax Authority Party"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                        <input type="text" *ngIf="this.show"  formControlName="TaxAuthorityParty"  class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" placeholder="Enter  Tax Authority Party" readonly>
                                        </div>
                                        
                                    </div>
                                    </div>
                                    <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-4 form-group rightForm">
                                            <label for="exampleInputEmail1">
                                                From Date</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="date" *ngIf="!this.show"  formControlName="FromDate"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Party Id" >
                                            <input type="text" *ngIf="this.show"  formControlName="FromDate"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Party Tax Id"  readonly>
                                        
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">
                                                Through Date</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="date"  formControlName="ThroughDate"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                        </div>
                                        
                                    </div>
                                    </div>
                                    <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-4 form-group rightForm">
                                            <label for="exampleInputEmail1">
                                                Party Tax Id </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text"  formControlName="taxId"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Party Tax Id" >
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">
                                                Is Exempt</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown [options]="exempt" filter="true"
                                                formControlName="IsExempt"
                                            placeholder="Enter Is Exempt"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                        </div>
                                        
                                    </div>
                                    </div>
                                    <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-4 form-group rightForm">
                                            <label for="exampleInputEmail1">
                                                Is Nexus</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown [options]="exempt" filter="true"
                                                formControlName="IsNexus"
                                            placeholder="Enter Is Nexus"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                        </div>
                                        
                                        
                                    </div>
                                    </div>
                                
                                
                                
                                
                            </div>
                            

                        </form>
                        <div class="main-submit-button" style="margin-left: 80%;">
                            
                            <button type="submit" *ngIf="!this.show" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Create</button>
                                
                            <button type="submit" *ngIf="this.show" (click)="onUpdate();"  class="btn btn-secondary submit-btn">Update</button>
                            
                            
                            
                            
                        </div>
                    </div>
                </div>

            </div>
            </div>

        </div>
        
    </div>
</div>






