import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fsm-notifications',
  templateUrl: './fsm-notifications.component.html',
  styleUrls: ['./fsm-notifications.component.css']
})
export class FsmNotificationsComponent implements OnInit {
  
  sendNotificationsForm: FormGroup;
  notificationMessageForm: FormGroup;
  dropdownArray: { label: string; value: string; }[];

  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 
  {
    this.sendNotificationsForm = this._FormBuilder.group({
      unassignedWorkOrder:"",
      assignedWorkWatingForPickup:"",
      pickedUp:"",
      enRoute:"",
      workStarted:"",
      paused:"",
      hold:"",
      completed:"",
      cancelled:"",
    });

    this.notificationMessageForm = this._FormBuilder.group({
      text:"",
      email:"",
      subject:"",
    });
    this.dropdownArray =
    [{
      label: "New Unassigned Work Order Created",
      value: "New_Unassigned_Work_Order_Created",
    },
    {
      label: "Assigned Work Order waiting for pickup",
      value: "Assigned_Work_Order_waiting_for_pickup",
    },
    {
      label: "Picked Up",
      value: "Picked_Up",
    },
    {
      label: "En Route",
      value: "En_Route",
    },
    {
      label: "Work Started",
      value: "Work_Started",
    },
    {
      label: "Paused",
      value: "Paused",
    },
    {
      label: "On Hold",
      value: "On_ Hold",
    },
    {
      label: "Completed",
      value: "Completed",
    },
    {
      label: "Cancelled",
      value: "Cancelled",
    },
    ]
   }

  ngOnInit(): void {
  }

  customerNotification() {
    this.spinner.show();
    this.myContactsService.customerNotification(this.notificationMessageForm.value.email,
      this.notificationMessageForm.value.subject,this.notificationMessageForm.value.text).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.notificationMessageForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error("Mail Sent");
        }
      }
      else {
        this.toastr.error("Mail Sent");
      }
    });
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  

  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
