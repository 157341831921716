import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api.service';

import { Observable } from "rxjs";
import { URLS } from "../../app.constant";

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(readonly http: HttpClient,
    readonly _ApiService: ApiService,
    readonly _HttpClient: HttpClient
  ) { }
  getProductMenu(): Promise<any> {
    return this._HttpClient.get(`/phyCorp/control/api/v1/catalog/product/menues`).toPromise();
  }
  createProduct(formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/product`, formData);
  }
  getProductById(id: string): Promise<any> {
    return this._HttpClient.get(`/phyCorp/control/api/v1/catalog/product/${id}`).toPromise();
  }
  updateProduct(formData: any): Promise<any> {
    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/product`, formData);
  }
  getSupplierByProductId(id, data): Observable<any> {
    let getSupplierByProductId = URLS.getSupplierByProductId.replace(":productId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getSupplierByProductId);
  }
  getSupplierProductInitialData(): Observable<any> {
    const getSupplierProductInitialData = URLS.getSupplierProductInitialData;
    return this.http.get(getSupplierProductInitialData);
  }

  getCustomerList(customer): Observable<any> {
    const getCustomerList = URLS.getCustomerList + "?roleTypeId=" + customer;
    return this.http.get(getCustomerList);
  }
  quantitySuppierUomId(): Observable<any> {
    const quantitySuppierUomId = URLS.quantitySuppierUomId;
    return this.http.get(quantitySuppierUomId);
  }
  postSupplierByProductId(id, data): Observable<any> {
    const getSupplierByProductId = URLS.getSupplierByProductId.replace(":productId", id);
    return this.http.post(getSupplierByProductId, data);
  }
  updateSupplierByProductId(id, data): Observable<any> {
    const getSupplierByProductId = URLS.getSupplierByProductId.replace(":productId", id);
    return this.http.put(getSupplierByProductId, data);
  }

  removeProductSupplier(data): Observable<any> {
    const removeProductSupplier = URLS.removeProductSupplier;
    return this.http.post(removeProductSupplier, data);
  }
  editSupplierProductInitialData(data): Observable<any> {
    const editSupplierProductInitialData = URLS.editSupplierProductInitialData;
    return this.http.post(editSupplierProductInitialData, data);
  }

  // Price Add

  getCustomPrice(): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productPrice/customPriceCalcService`);
  }
  getPriceType(): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productPrice/productPriceType`);
  }
  getProductStoreGroup(): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productPrice/productStoreGroup`);
  }
  getPricePurpose(): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productPrice/productPricePurpose`);
  }
  getProductPriceList(pagination: { pageNo: number, pageSize: number }, productId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productPrice?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}&productId=${productId}`)
  }
  createProductPrice(formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/productPrice`, formData);
  }
  getProductPriceById(formData: {
    currencyUomId: string,
    fromDate: string,
    productId: string,
    productPricePurposeId: string,
    productPriceTypeId: string,
    productStoreGroupId: string
  }): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/productPrice/productPriceById`, formData);
  }
  updateProductPrice(formData: any): Promise<any> {
    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/productPrice`, formData);
  }
  deleteProductPrice(formData: {
    currencyUomId: string,
    fromDate: string,
    productId: string,
    productPricePurposeId: string,
    productPriceTypeId: string,
    productStoreGroupId: string
  }): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/productPrice/delete`, formData);
  }
  // Inventory 
  getProductInventory(productId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/product/inventory?productId=${productId}&showAllFacilities=Y`);
  }
  // Content 
  getContentTypeId(): Promise<any> {

    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/product/content/typeId`);
  }
  prepareAddContent(productContentTypeId: string, productId: string): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/product/content/prepare/${productId}/${productContentTypeId}`, {});
  }
  getContents(productId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/product/content/${productId}`);
  }
  addProductToContent(productId: string, formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/product/content/contentToProduct/${productId}`, formData);
  }
  updateSimpleFields(productId: string, formData: any): Promise<any> {
    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/product/content/contentToProduct/${productId}`, formData);
  }
  uploadAdditionalImage(productId: string, formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/product/content/additionalImages/${productId}`, formData);
  }
  uploadImage(productId: string, uploadFileType: string, file: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/product/content/uploadImage/${productId}?uploadFileType=${uploadFileType}`, file);
  }
  deleteContent(productId: string, fromDate: string, productContentTypeId: string, contentId: string): Promise<any> {
    return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/catalog/product/content/${productId}?contentId=${contentId}&fromDate=${fromDate}&productContentTypeId=${productContentTypeId}`);
  }
  addAdditionalImageContent(formData: any, jsonData: any, productId: string): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/product/content/image?jsonData=${jsonData}&productId=${productId}`, formData);
  }
  createSimpleTextContentForProduct(productId: string, formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/product/content/text?productId=${productId}`, formData);
  }
  getUserRole(roleType: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/partyRoles/roleTypes?roleType=${roleType}`);
  }
  getTaxGeoAndTermUomID(): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productPrice/taxGeoAndTermUomId`);
  }
  getContentById(contentId: string, fromDate: string, productContentTypeId: string, productId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/product/content/api/v1/catalog/product/content/summary/edit?contentId=${contentId}&fromDate=${fromDate}&productContentTypeId=${productContentTypeId}&productId=${productId}`);
  }
  updateSimpleTextContentForProduct(productId: string, formData: any): Promise<any> {
    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/product/content/text?productId=${productId}`, formData);
  }
  getContentIds(): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/contentToCategory/ContentId`);
  }
}


