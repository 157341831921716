import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
    import { Location } from '@angular/common';
    import { SortEvent } from 'primeng/api';

import { AccountsService } from 'src/app/crm/accounts/accounts.service';

import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-bom-simulation',
  templateUrl: './create-bom-simulation.component.html',
  styleUrls: ['./create-bom-simulation.component.css']
})
export class CreateBomSimulationComponent implements OnInit {
  createSimulation: FormGroup;
  total=0;
  activeCategoryMain=1;
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  financialList = [{
    "product": "1"
  }];
  activeCategory = 2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[] = [];
  varianceReasonId: any;
  varianceReasonIdArray: any[] = [];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  budgetId: any;
  personId: any;
  partyIdArray: any[] = [];
  ReviewResultTypes: any;
  ReviewResultTypesArray: any[] = [];
  reviewDate: string;
  bomTypes: any;
  bomTypesArray: any[]=[];
  InitailBomSimualtionType: any;
  InitailBomSimualtionTypeArray: any[]=[];
  currencyArray: any[]=[];
  productListArray: any[]=[];
  MrpFacilities: any;
  MrpFacilitiesArray: any[]=[];
  fromDate: string;
  bomData: any;
  BomDataArray: any[]=[];
  date: Date;
  productId: any;
  constructor(readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly _ActivatedRoute: ActivatedRoute,  private _location: Location,
   
    readonly _ToastrService: ToastrService,) {
    this.createSimulation = this._FormBuilder.group({
      ProductID: [''],
      BomType: [''],
      FromDate: [''],
      Quantity: ['1'],
      Amount: ['0'],
      Type: [''],
      FacilityID: [''],
      CurrencyUomID: ['']


    });

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.productId = params["productId"];
     
     
    });
    const formValue = this.createSimulation;
    formValue.patchValue({
      ProductID:this.productId
     
       })
    this.getBOMInitialData();
    this.getInitailBomSimualtionType();
    this.getCurrency();
    this.getProducts();
    this.getMrpFacilities();
  }
  BomList() {
    this._Router.navigate(["/production/create-bom-simulation"])

  }
  ManufacturingRules() {
    this._Router.navigate(["/production/manufacturing-rules"])

  }
  getMrpFacilities() {
    this.spinner.show();
    this.productionService.getMrpFacilities().subscribe(res => {
      this.MrpFacilities = res.data;
      this.spinner.hide();
      for (const value of this.MrpFacilities) {
        this.MrpFacilitiesArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
    })
   
  }
  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });

    });

  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
      const formValue = this.createSimulation;
      formValue.patchValue({
        CurrencyUomID:'USD',
       
         })
    })
   
  }
  getInitailBomSimualtionType() {
    this.spinner.show();
    this.productionService.getInitailBomSimualtionType().subscribe(res => {
      this.InitailBomSimualtionType = res.data;

      this.spinner.hide();
      for (const value of this.InitailBomSimualtionType) {
        this.InitailBomSimualtionTypeArray.push({
          label: value.value,
          value: value.id
        })
      }
      const formValue = this.createSimulation;
      formValue.patchValue({
        Type:0,
       
         })
     
    })
   
  }
  getBOMInitialData() {
    this.spinner.show();
    this.productionService.getBOMInitialData(this.finSize).subscribe(res => {
      this.bomTypes = res.data.bomTypes;

      this.spinner.hide();
      for (const value of this.bomTypes) {
        this.bomTypesArray.push({
          label: value.description,
          value: value.productAssocTypeId
        })
      }
      this.date=new Date();
      const latest_date =this.datePipe.transform(this.date, 'yyyy-MM-dd');
      const formValue = this.createSimulation;
      formValue.patchValue({
        BomType:'MANUF_COMPONENT',
        FromDate:latest_date
     
        
         })
    }) 
  }
 
  onSubmit() {
    this.spinner.show();
    const FromDate = this.createSimulation.get('FromDate').value;
    this.fromDate = this.datePipe.transform(FromDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "amount": this.createSimulation.value.Amount,
      "bomType": this.createSimulation.value.BomType,
      "currencyUomId": this.createSimulation.value.CurrencyUomID,
      "facilityId": this.createSimulation.value.FacilityID,
      "fromDate":  this.fromDate,
      "productId": this.createSimulation.value.ProductID,
      "quantity": this.createSimulation.value.Quantity,
      "type": this.createSimulation.value.Type,
    }
    this.productionService.runBomSimulation(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.BomDataArray=[];
        this.bomData=res.data;
        this.BomDataArray.push(this.bomData);
        this._ToastrService.success("Created Successfully");
        //this._Router.navigate(['/production/bom-simulation-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  updateBOM(eproductId,eproductAssocTypeId) {
    this._Router.navigate(["production/update-bom-simulation"],{ queryParams: { productId : eproductId,productAssocTypeId:eproductAssocTypeId}})

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  
  homeButton(){
    this._Router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}

