import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class WarehouseShipmentService {

  constructor(readonly http: HttpClient,
    readonly _HttpClient: HttpClient) { }

  getShipmentTypeIdList(): Observable<any> {
    const getShipmentTypeIdList = URLS.getShipmentTypeIdList;
    return this.http.get(getShipmentTypeIdList);
  }

  getStatusId(): Observable<any> {
    const getStatusId = URLS.getStatusId;
    return this.http.get(getStatusId);
  }

  getFacility(): Promise<any> {
    return this._HttpClient.get(`/phyCorp/control/api/v1/facility`).toPromise();
  }

  getFacilityShipments(formData): Observable<any> {
    const getFacilityShipments = URLS.getFacilityShipments;
    return this.http.post(getFacilityShipments, formData);
  }

}
