import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-data-resource',
  templateUrl: './data-resource.component.html',
  styleUrls: ['./data-resource.component.css']
})
export class DataResourceComponent implements OnInit {
  activeCategory = 1;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  dataResourceForm: FormGroup;
  createContentDataResourceForm: FormGroup;
  mimeTypeIdData: any;
  mimeTypeIdDataArray: any[] = [];
  DataResourceType: any;
  DataResourceTypeArray: any[] = [];
  dataCategory: any;
  dataCategoryArray: any[] = [];
  dataResourceStatusId: any;
  dataResourceStatusIdArray: any[] = [];
  CharacterSetId: any;
  CharacterSetIdArray: any[]=[];
  dataTemplateType: any;
  dataTemplateTypeArray: any[]=[];
  localString: any;
  localStringArray: any[]=[];
  yesNoArray: { label: string; value: string; }[];
  findDataResourceData: any;
  dataResourceId: any;

  constructor(
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,

    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  ) {
    this.dataResourceForm = this._FormBuilder.group({
      dataResourceID: "",
      dataResourceName: "",
      dataResourceTypeID: "",
      mimeTypeID: "",
      statusID: "",
      localeString: "",
      createdByUser: "",
      dataCategoryID: "",
    })

    this.createContentDataResourceForm = this._FormBuilder.group({
      dataResourceID: "",
      dataResourceTypeID: "",
      dataTemplateTypeID: "",
      dataCategoryID: "",
      dataSourceID: "",
      dataResourceName: "",
      localeString: "",
      mimeTypeID: "",
      characterSetID: "",
      objectInfo: "",
      surveyID: "",
      surveyResponseID: "",
      relatedDetailID: "",
      isPublic: "",
      createdDate: "",
      createdByUser: "",
      lastModifiedDate: "",
      lastModifiedByUser: "",
    })
    this.yesNoArray =[
      {
        label:"Y",
        value:"Y"
      },
      {
        label:"N",
        value:"N"
      }
    ]


  }

  ngOnInit(): void {
    this.getMimeTypeIdData();
    this.getDataCategory();
    this.getDataResourceType();
    this.getDataResourceStatusId();
    this.getDataTemplateType();
    this.getCharacterSetData();
    this.getLocalString();
    this.getfindDataResource();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  
  detailPage(id){
    this._Router.navigate(["/content/data-resource/dataresource-detail-page"],
    {queryParams:{dataResourceId:id}})
  }
  reset() {
    this.spinner.show();
    this.dataResourceForm.reset();

    const formData = {
      "createdByUserLogin": "",
      "dataCategoryId": "",
      "dataResourceId": "",
      "dataResourceName": "",
      "dataResourceTypeId": "",
      "localeString": "",
      "mimeTypeId": "",
      "statusId": ""
    }

    this.myContactsService.findDataResource(formData).subscribe((res: any) => {
      this.findDataResourceData = res.data;
      this.spinner.hide();
    })
  }
  getfindDataResource() {
    this.spinner.show();
    const formData = {
      "createdByUserLogin": "",
      "dataCategoryId": "",
      "dataResourceId": "",
      "dataResourceName": "",
      "dataResourceTypeId": "",
      "localeString": "",
      "mimeTypeId": "",
      "statusId": ""
    }

    this.myContactsService.findDataResource(formData).subscribe(res => {
      this.findDataResourceData = res.data;
      this.spinner.hide();

    })
  }   
  findDataResource() {
    this.spinner.show();
    const formData = {
      "createdByUserLogin":  this.dataResourceForm.value.createdByUser ? this.dataResourceForm.value.createdByUser : '',
      "dataCategoryId": this.dataResourceForm.value.dataCategoryID ? this.dataResourceForm.value.dataCategoryID : '',
      "dataResourceId":  this.dataResourceForm.value.dataResourceID ? this.dataResourceForm.value.dataResourceID : '',
      "dataResourceName":  this.dataResourceForm.value.dataResourceName ? this.dataResourceForm.value.dataResourceName : '',
      "dataResourceTypeId":  this.dataResourceForm.value.dataResourceTypeID ? this.dataResourceForm.value.dataResourceTypeID : '',
      "localeString": this.dataResourceForm.value.localeString ? this.dataResourceForm.value.localeString : '',
      "mimeTypeId":  this.dataResourceForm.value.mimeTypeID ? this.dataResourceForm.value.mimeTypeID : '',
      "statusId":  this.dataResourceForm.value.statusID ? this.dataResourceForm.value.statusID : '',

    }

    this.myContactsService.findDataResource(formData).subscribe(res => {
      this.findDataResourceData = res.data;
      this.spinner.hide();

    })
  }
  getCharacterSetData() {
    this.spinner.show();
    this.myContactsService.getCharacterSetData().subscribe(res => {
      this.CharacterSetId = res.data.getCharacterSet;
      this.spinner.hide();
      for (const value of this.CharacterSetId) {
        this.CharacterSetIdArray.push({
          label: value.description,
          value: value.characterSetId
        })
      }
    })
  }
  getLocalString() {
    this.spinner.show();
    this.myContactsService.getLocalString().subscribe(res => {
      this.localString = res.data[0].getLocalString;
      this.spinner.hide();
      for (const value of this.localString) {
        this.localStringArray.push({
          label: value.localeString,
          value: value.localeString
        })
      }
    })
  }
  getDataTemplateType() {
    this.spinner.show();
    this.myContactsService.getDataTemplateType().subscribe(res => {
      this.dataTemplateType = res.data.getDataTemplateType;
      this.spinner.hide();
      for (const value of this.dataTemplateType) {
        this.dataTemplateTypeArray.push({
          label: value.description,
          value: value.dataTemplateTypeId
        })
      }
    })
  }
  createDataResource() {
    this.spinner.show();
    const requestData =
    {
      "characterSetId": this.createContentDataResourceForm.value.characterSetID,
      "createdByUserLogin": this.createContentDataResourceForm.value.createdByUser,
      "createdDate": this.datePipe.transform(this.createContentDataResourceForm.value.createdDate, "yyyy-MM-dd HH:mm:ss"),
      "dataCategoryId": this.createContentDataResourceForm.value.dataCategoryID,
      "dataResourceId": this.createContentDataResourceForm.value.dataResourceID,
      "dataResourceName": this.createContentDataResourceForm.value.dataResourceName,
      "dataResourceTypeId": this.createContentDataResourceForm.value.dataResourceTypeID,
      "dataSourceId": this.createContentDataResourceForm.value.dataSourceID,
      "dataTemplateTypeId": this.createContentDataResourceForm.value.dataTemplateTypeID,
      "isPublic": this.createContentDataResourceForm.value.isPublic,
      "lastModifiedByUserLogin": this.createContentDataResourceForm.value.lastModifiedByUser,
      "lastModifiedDate": this.datePipe.transform(this.createContentDataResourceForm.value.lastModifiedDate, "yyyy-MM-dd HH:mm:ss"),
      "localeString": this.createContentDataResourceForm.value.localeString,
      "mimeTypeId": this.createContentDataResourceForm.value.mimeTypeID,
      "objectInfo": this.createContentDataResourceForm.value.objectInfo,
      "relatedDetailId": this.createContentDataResourceForm.value.relatedDetailID,
      "surveyId": this.createContentDataResourceForm.value.surveyID,
      "surveyResponseId": this.createContentDataResourceForm.value.surveyResponseID,
    }
    this.myContactsService.createDataResource(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.createContentDataResourceForm.reset();
        this.dataResourceId = res.data.dataResourceId;
        this._Router.navigate(["/content/data-resource/dataresource-detail-page"],
        {queryParams:{dataResourceId:this.dataResourceId}})
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getDataResourceType() {
    this.spinner.show();
    this.myContactsService.getDataResourceType().subscribe(res => {
      this.DataResourceType = res.data.getDataResourceType;
      this.spinner.hide();
      for (const value of this.DataResourceType) {
        this.DataResourceTypeArray.push({
          label: value.description,
          value: value.dataResourceTypeId
        })
      }
    })
  }
  getDataCategory() {
    this.spinner.show();
    this.myContactsService.getDataCategory().subscribe(res => {
      this.dataCategory = res.data.getDataCategory;
      this.spinner.hide();
      for (const value of this.dataCategory) {
        this.dataCategoryArray.push({
          label: value.categoryName,
          value: value.dataCategoryId
        })
      }
    })
  }
  getDataResourceStatusId() {
    this.spinner.show();
    this.myContactsService.getDataResourceStatusId().subscribe(res => {
      this.dataResourceStatusId = res.data.getDataResourceStatusId;
      this.spinner.hide();
      for (const value of this.dataResourceStatusId) {
        this.dataResourceStatusIdArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  }
  getMimeTypeIdData() {
    this.spinner.show();
    this.myContactsService.getMimeTypeIdData().subscribe(res => {
      this.mimeTypeIdData = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.mimeTypeIdData) {
        this.mimeTypeIdDataArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }
  navigateOpen() {
    this._Router.navigate(['/content/data-resource/dataresource-navigate-page'])
  }


  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });


  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;

  }

}
