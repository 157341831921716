
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EmpPersonalModel } from './emp-personal-model';

@Injectable({
  providedIn: 'root'
})

export class EmpPersonalFormService {
  emp_personal_form: FormGroup;

  constructor(public _formBuilder: FormBuilder,
    public empPersonalModel: EmpPersonalModel
  ) { }

  createEventForm(data: any): void {
    if (!this.emp_personal_form) {
      this.emp_personal_form = this._formBuilder.group(this.empPersonalModel.mapDataToModel(data));

    }

  }

}

