import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PayablePaymentService } from '../accounting-ap/payables-payment/payables-payment.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-main-receivable',
  templateUrl: './main-receivable.component.html',
  styleUrls: ['./main-receivable.component.css']
})
export class MainReceivableComponent implements OnInit {
  total=0;
    activeCategory=4;
    rowNumber=0;
    isShown = false; 
    pageSize = 10;
    rows = 50;
    pageNo = 1;
  
    opportunityIdArray: any = [
        {
            label: 'Equals',
            value: 'Equals'
        },
        {
            label: 'Begins With',
            text: 'Begins With'
        },
        {
            label: 'Contains',
            value: 'Contains'
        },
        {
            label: 'Is Empty',
            value: 'Is Empty'
        },
    ];
    paymentTypeList: any[];
    statusList: any[];
    paymentList: any[];
    partyIds: any[];
    searchPaymentForm: FormGroup;
    agreementType: string;
  getApAccount: any;
  invoicesDueSoon: any;
  pastDueInvoicestotalAmount: any;
    constructor(
        readonly _PayablePaymentService: PayablePaymentService,
        readonly _ToastrService: ToastrService,
        readonly  _Router: Router, readonly spinner: NgxSpinnerService,
        readonly _GlobalResourceService: GlobalResourceService,
        readonly _FormBuilder: FormBuilder,
        readonly accountsService: AccountsService,
        private _location: Location,
    ) {
        this.paymentTypeList = [];
        this.statusList = [];
        this.paymentList = [];
        this.partyIds = [];
        this.searchPaymentForm = this._FormBuilder.group({
            amount: [''],
            comments: [''],
            commentsSearchType: ['Contains'],
            fromPartyId: [''],
            paymentId: [''],
            paymentIdSearchType: ['Contains'],
            paymentType: [''],
            status: [''],
            toPartyId: ['']
        });
    }
  
    ngOnInit(): void {
      this.getAR_AccountInvoice();
      this.getARAccountPastDueInvoice();
       
    }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this._Router.navigate(['/financial/main']);
}

    
    getARAccountPastDueInvoice() {
      this.spinner.show();
    
      this.accountsService.getARAccountPastDueInvoice().subscribe((res: any) => {
  
        this.pastDueInvoicestotalAmount = res.data.pastDueInvoicestotalAmount;
       
        this.spinner.hide();
      
  
  
      })
     
  
    }
    getAR_AccountInvoice() {
      this.spinner.show();
    
      this.accountsService.getAR_AccountInvoice().subscribe((res: any) => {
  
        this.getApAccount = res.data.pastDueInvoices;
        this.invoicesDueSoon = res.data.invoicesDueSoon;
        this.spinner.hide();
      
  
  
      })
     
  
    }
    
    mainReceive(){
        this._Router.navigate(["/financial/main-receivable"])
      }
    enterBills(){
        this._Router.navigate(["/financial/payable/invoices"])
      }
      payableMakePayment(){
        this._Router.navigate(["/financial/payable/payment-payables"])
      }
      payableMakeGroupPayment(){
        this._Router.navigate(["/financial/payable/payment-group"])
      }
      salesInvoice(){
        this._Router.navigate(["/financial/receiveable/invoices"])
      }
      receivePayment(){
        this._Router.navigate(["/financial/receiveable/payments"])
      }
      receiveGroupPayments(){
        this._Router.navigate(["/financial/receiveable/payment-group"])
      }
   

    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
   
} 