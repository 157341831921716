<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">

               
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0 mt-0">
                    Global HR Settings
                    <span class="d-flex">

                        <button type="submit" (click)="homeButton();"
                            class=" btn btn-outline-secondary mr-2">Home</button>
    
                        <button type="submit" (click)="cancelSubmit();"
                            class="btn btn-danger buttonclass ">Back</button>
                    </span>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                                    Skill Type</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                    Responsibility Types</a></li>
                            <li [ngClass]="activeCategory==10?'active':''" (click)="changeactiveCategoryFunction(10)">
                                <a>
                                    Termination Reasons</a></li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategoryFunction(8)"><a>
                                    Termination Types</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                                    Position Types</a></li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                                    Job Interview Type</a></li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                                    Employee Leave Type</a></li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategoryFunction(9)"><a>
                                    Pay Grades</a></li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                                    Training Class Types</a></li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategoryFunction(7)"><a>
                                    Public Holiday</a></li>
                        </ul>
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group">
                                                <div *ngIf="activeCategory==1">



                                                    <div #target></div>
                                                    <!-- <button type="button" (click)="position()"
                                                            style="margin-left: 2%;"
                                                            class="btn btn-outline-secondary active">Position
                                                            Types</button>
                                                        <button type="button" (click)="responsi()" id="resButton"
                                                          
                                                            class="btn btn-outline-secondary">Responsibilities</button>
                                                        <br> -->
                                                    <div class="w3-card-4 classCard mb-5P" *ngIf="positionIf">

                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass">

                                                                <li [ngClass]="activeCategoryMain==2?'active':''">
                                                                    <a>
                                                                        Skill Type</a>
                                                                </li>
                                                                <span class="edit-buttons create_skill">
                                                                    <a data-toggle="modal" (click)=" cancelSkills() "
                                                                        data-target="#skillModal">Create New Skill Type</a>
                                                                </span>

                                                            </ul>

                                                        </div>

                                                        <div #target2></div>
                                                        <!-- <div style="color: black;" *ngIf="positionIf">
                                                                <br>

                                                                <div class="panel-body">
                                                                    <form *ngIf="settingFormService.setting_form"
                                                                        [formGroup]="settingFormService.setting_form">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRight">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Skill
                                                                                            Type
                                                                                            ID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" *ngIf="!show">
                                                                                        <input type="email"
                                                                                            formControlName="skillTypeId"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Skill Type ID">
                                                                                    </div>
                                                                                    <div class="col-lg-2" *ngIf="show">
                                                                                        <input type="email" disabled
                                                                                            formControlName="skillTypeId"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Skill Type ID">
                                                                                    </div>

                                                                                    <div
                                                                                        class="col-lg-3 form-group  classInput__new_changes">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description
                                                                                            <span
                                                                                                style="color:red">*</span></label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"
                                                                                            formControlName="description"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Description">
                                                                                        <small
                                                                                            *ngIf="settingFormService.setting_form.controls['description'].invalid && (settingFormService.setting_form.controls['description'].dirty || settingFormService.setting_form.controls['description'].touched)">
                                                                                            <p class="vaiidate-field"
                                                                                                *ngIf="settingFormService.setting_form.controls['description'].errors.required">
                                                                                                Description is
                                                                                                required!</p>
                                                                                        </small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>




                                                                        </div>
                                                                        <div *ngIf="!show"
                                                                            style="    margin-left: -30%;"
                                                                            class="col-lg-12 col-12 main-submit-button ">
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                                (click)="onSubmit()"
                                                                                class="btn btn-secondary submit-btn">Create</button>
                                                                        </div>
                                                                        <div *ngIf="show" style="    margin-left: -71%;"
                                                                            class="col-lg-12 col-12 main-submit-button ">
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                                (click)="onUpdate()"
                                                                                class="btn btn-secondary submit-btn">Update</button>
                                                                            <button _ngcontent-cta-c128=""
                                                                                type="submit" (click)="cancelSkills()"
                                                                                class="btn btn-danger ">Cancel</button>
                                                                        </div>

                                                                    </form>
                                                                </div>
                                                            </div> -->

                                                        <div class="w3-card-4 classCard border-trans">
                                                            <div
                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select userClass">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="skillTypeList"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Skill Type ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                        </div>
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <!-- <span
                                                                                                 class="account-button"  
                                                                                                (click)="detailPage(product.skillTypeId,product.description)">
                                                                                                {{product.skillTypeId}}
                                                                                            </span> -->
                                                                                            <span
                                                                                           >
                                                                                            {{product.skillTypeId}}
                                                                                        </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.description}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            data-toggle="modal"
                                                                                            data-target="#skillModal"
                                                                                            (click)="updateSkills(product.skillTypeId,product.description); scroll(target)">Update</button>

                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            (click)="onDelete(product)">Delete</button>

                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <!-- <p class="paginate_data">
                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div *ngIf="activeCategory==2">
                                                    <div class="w3-card-4 classCard mb-5P">
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass">

                                                                <li [ngClass]="activeCategoryMain==2?'active':''">
                                                                    <a>
                                                                        Responsibility Types</a>
                                                                </li>
                                                                <span class="edit-buttons create_skill"
                                                                    style="margin-left:82% !important;">
                                                                    <a data-toggle="modal"
                                                                        (click)="cancelResponsibility()"
                                                                        data-target="#respModal">Create New Responsibility Types</a>
                                                                </span>

                                                            </ul>
                                                        </div>

                                                        <div #target1></div>
                                                        <!-- <form
                                                                    *ngIf="responsibilitiesTypeService.responsibility_type__form"
                                                                    [formGroup]="responsibilitiesTypeService.responsibility_type__form">
                                                                    <div class="panel-body">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRightA">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Responsibility
                                                                                            Type Id</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2"
                                                                                        *ngIf="!updateResponsibility">
                                                                                        <input type="email"
                                                                                            formControlName="responsibilityTypeId"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Responsibility Type Id">
                                                                                    </div>
                                                                                    <div class="col-lg-2"
                                                                                        *ngIf="updateResponsibility">
                                                                                        <input type="email"
                                                                                            formControlName="responsibilityTypeId"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Responsibility Type Id"
                                                                                            disabled>
                                                                                    </div>

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRightA">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description
                                                                                            <span
                                                                                                style="color:red">*</span></label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"
                                                                                            formControlName="description"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Description">

                                                                                        <small
                                                                                            *ngIf="responsibilitiesTypeService.responsibility_type__form.controls['description'].invalid && (responsibilitiesTypeService.responsibility_type__form.controls['description'].dirty || responsibilitiesTypeService.responsibility_type__form.controls['description'].touched)">
                                                                                            <p class="vaiidate-field"
                                                                                                *ngIf="responsibilitiesTypeService.responsibility_type__form.controls['description'].errors.required">
                                                                                                Description is
                                                                                                required!</p>
                                                                                        </small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                        <div class="col-lg-12 col-12 main-submit-button "
                                                                            style="margin-left: -31%;">
                                                                            <button
                                                                                _ngcontent-cta-c128=""
                                                                                *ngIf="!updateResponsibility"
                                                                                (click)="onResponsi()" type="submit"
                                                                                class="btn btn-secondary submit-btn">Create</button>
                                                                                <button
                                                                                _ngcontent-cta-c128=""
                                                                                (click)="updateRespon()"
                                                                                *ngIf="updateResponsibility"
                                                                                type="submit"
                                                                                class="btn btn-secondary submit-btn">Update</button>
                                                                            <button _ngcontent-cta-c128=""
                                                                                *ngIf="updateResponsibility"
                                                                                type="submit"
                                                                                (click)="cancelResponsibility()"
                                                                                class="btn btn-danger ">Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form> -->

                                                        <div class="w3-card-4 classCard border-trans">
                                                            <div
                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select userClass">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="responsibilitytype"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Responsibility ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>

                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>


                                                                                    <th pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                style="visibility:hidden;"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span class="account-button"
                                                                                             
                                                                                                (click)="detailResPage(product.responsibilityTypeId,product.description)">
                                                                                                {{product.responsibilityTypeId}}
                                                                                            </span>

                                                                                        </div>
                                                                                    </td>

                                                                                    <td>{{product.description}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            data-toggle="modal"
                                                                                            data-target="#respModal"
                                                                                            (click)="onUpdateRes(product.responsibilityTypeId,product.description); scroll(target1)">Update</button>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            (click)="onDelResponse(product.responsibilityTypeId)">Delete</button>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <!-- <p class="paginate_data">
                                                                    View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div *ngIf="activeCategory==3">



                                                    <!-- <button type="button" (click)="position()"
                                                                style="margin-left: 2%;"
                                                                class="btn btn-outline-secondary active mt-3 mb-3">Position
                                                                Types</button> -->
                                                    <!-- <button type="button" (click)="responsi()" id="resButton"
                                                                class="btn btn-outline-secondary ">Responsibilities</button> -->

                                                    <div class="w3-card-4 classCard mb-5P" *ngIf="positionIf">

                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass">

                                                                <li [ngClass]="activeCategoryMain==2?'active':''">
                                                                    <a>
                                                                        Position Types</a>
                                                                </li>
                                                                <span class="edit-buttons create_skill"
                                                                    style="margin-left:84% !important">
                                                                    <a data-toggle="modal" (click)="cancelPosition()"
                                                                        data-target="#posModal">Create New Position Types</a>
                                                                </span>

                                                            </ul>
                                                        </div>

                                                        <div #target2></div>
                                                        <!-- <div style="color: black;" *ngIf="positionIf">
                                                                    <br>

                                                                    <form
                                                                        *ngIf="positionTypeService.position_type__form"
                                                                        [formGroup]="positionTypeService.position_type__form">
                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group formRightA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Empl
                                                                                                Position Type
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                formControlName="emplPositionTypeId"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Empl Position Type ID">
                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group formRightA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Parent
                                                                                                Type
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                formControlName="parentTypeId"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Parent Type ID">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group formRightA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Has
                                                                                                Table</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                [options]="hashTable"
                                                                                                formControlName="hasTable"
                                                                                                optionlabel="label"
                                                                                                placeholder="Enter Has Table">
                                                                                            </p-dropdown>
                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group formRightA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description
                                                                                                <span
                                                                                                    style="color:red">*</span></label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                formControlName="description"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enetr Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>




                                                                            </div>
                                                                            <div style="    margin-left: -30%;"
                                                                                class="suppliers-wrapper col-lg-12 col-12 text-right mb-20">
                                                                                <button type="button"
                                                                                    *ngIf="!positionIdButton"
                                                                                    (click)="createPositionType()"
                                                                                    class="btn btn-outline-secondary">Create</button>
                                                                                <button type="button"
                                                                                    *ngIf="positionIdButton"
                                                                                    (click)="positionTypeUpdates()"
                                                                                    class="btn btn-outline-secondary">Update</button>
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    *ngIf="positionIdButton"
                                                                                    (click)="cancelPosition()"
                                                                                    class="btn btn-danger ">Cancel</button>

                                                                            </div>

                                                                        </div>
                                                                    </form>
                                                                </div> -->

                                                        <div class="w3-card-4 classCard border-trans"
                                                            *ngIf="positionIf">
                                                            <div
                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select userClass">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="positionData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Position Types
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Action</div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                style="visibility:hidden;"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                              class="account-button"
                                                                                                (click)="detailPositionTypePage(product.emplPositionTypeId,product.description)">
                                                                                                {{product.emplPositionTypeId}}
                                                                                            </span>

                                                                                        </div>
                                                                                    </td>

                                                                                    <td>{{product.description}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            data-toggle="modal"
                                                                                            data-target="#posModal"
                                                                                            (click)="positionTypeUpdate(product.emplPositionTypeId,product.description); scroll(target2)">Update</button>

                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            (click)="positionTypeDel(product.emplPositionTypeId)">Delete</button>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div *ngIf="!positionIf">
                                                            <app-responsibilities></app-responsibilities>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==4">
                                                    <div class="w3-card-4 classCard mb-5P">
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass">

                                                                <li [ngClass]="activeCategoryMain==2?'active':''">
                                                                    <a>
                                                                        Job Interview Type</a>
                                                                </li>
                                                                <span
                                                                    class="edit-buttons create_skill ml-82">
                                                                    <a data-toggle="modal" (click)="cancelInterview()"
                                                                        data-target="#jobModal">Create New Job Interview Type</a>
                                                                </span>

                                                            </ul>
                                                        </div>

                                                        <div #target3></div>

                                                        <!-- <form *ngIf="interviewTypeService.interview_type__form"
                                                                    [formGroup]="interviewTypeService.interview_type__form">
                                                                    <div class="panel-body">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRightA">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Job
                                                                                            Interview
                                                                                            Type ID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2"
                                                                                        *ngIf="!interviewButton">
                                                                                        <input type="email"
                                                                                            formControlName="jobInterviewTypeId"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Job Interview Type ID">
                                                                                    </div>
                                                                                    <div class="col-lg-2"
                                                                                        *ngIf="interviewButton">
                                                                                        <input type="email"
                                                                                            formControlName="jobInterviewTypeId"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Job Interview Type ID"
                                                                                            disabled>
                                                                                    </div>

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRightA">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description
                                                                                            <span
                                                                                                style="color:red">*</span></label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="text"
                                                                                            formControlName="description"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Description">
                                                                                    </div>
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                        <div style="margin-left: -31%;"
                                                                            *ngIf="!interviewButton"
                                                                            (click)="interviewTypeForm()"
                                                                            class="col-lg-12 col-12 main-submit-button ">
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                                class="btn btn-secondary submit-btn">Create</button>
                                                                        </div>
                                                                        <div style="margin-left: -65%;"
                                                                            *ngIf="interviewButton"
                                                                            (click)="updateJobId()"
                                                                            class="col-lg-12 col-12 main-submit-button ">
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                                class="btn btn-secondary submit-btn">Update</button>
                                                                            <button _ngcontent-cta-c128=""
                                                                                *ngIf="interviewButton" type="submit"
                                                                                (click)="cancelInterview()"
                                                                                class="btn btn-danger ">Cancel</button>
                                                                        </div>

                                                                    </div>
                                                                </form> -->

                                                        <div class="w3-card-4 classCard border-trans">
                                                            <div
                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select userClass">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="interviewType"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Job Interview Type ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                style="visibility:hidden;"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                            class="account-button"
                                                                                                (click)="detailJobTypePage(product.jobInterviewTypeId,product.description)">
                                                                                                {{product.jobInterviewTypeId}}
                                                                                            </span>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.description}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            data-toggle="modal"
                                                                                            data-target="#jobModal"
                                                                                            (click)="updateInterview(product.jobInterviewTypeId,product.description); scroll(target3)">Update</button>

                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            (click)="onDeleteId(product.jobInterviewTypeId)">Delete</button>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <!-- <p class="paginate_data">
                                                                    View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==5">
                                                    <!-- <div class="suppliers-wrapper col-lg-12 col-12 mb-20">
                                                                <button type="button" (click)="leaveToggle()" data-toggle="modal"  data-target="#leaveModal"
                                                                    class="btn btn-outline-secondary active" (click)="employeeCancel()">Leave
                                                                    Type</button>
                                                                <button type="button" (click)="leaveReasonToggle()" data-toggle="modal"  data-target="#leaveReasonModal"
                                                                    class="btn btn-outline-secondary">Reason
                                                                    Type</button>
                                                            </div> -->
                                                    <div class="w3-card-4 classCard mb-5P">
                                                        <div #target4></div>
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass">

                                                                <li (click)="leaveToggle()"
                                                                    [ngClass]="leaveToggles?'active':''">
                                                                    <a>
                                                                        Leave Type</a>
                                                                </li>
                                                                <li (click)="leaveReasonToggle()"
                                                                    [ngClass]="!leaveToggles?'active':''">
                                                                    <a>
                                                                        Reason Type</a>
                                                                </li>
                                                                <span class="edit-buttons create_skill"
                                                                    style="margin-left: 77% !important;">
                                                                    <a data-toggle="modal" (click)="employeeCancel()"
                                                                        *ngIf="leaveToggles" data-target="#leaveModal"
                                                                        class="leaveBtn">Create Leave Type</a>
                                                                    <a data-toggle="modal" (click)="leaveReasonToggle()"
                                                                        *ngIf="!leaveToggles"
                                                                        data-target="#leaveReasonModal"
                                                                        class="leaveBtn">Create Leave Reason Type</a>
                                                                </span>


                                                            </ul>
                                                        </div>
                                                        <!-- display none -->
                                                        <div class="w3-card-4 classCard" *ngIf="leaveToggles"
                                                            style="display: none;">
                                                            <div class="container-fluid">
                                                                <ul class="tabbing-section tabbing-accordians tabClass">

                                                                    <li [ngClass]="activeCategoryMain==2?'active':''">
                                                                        <a>
                                                                            Employee Leave Type</a>
                                                                    </li>


                                                                </ul>
                                                            </div>



                                                            <br>

                                                            <div *ngIf="leaveToggles" class="panel-body">
                                                                <br>

                                                                <form *ngIf="leaveTypeService.leave_type__form"
                                                                    [formGroup]="leaveTypeService.leave_type__form">

                                                                    <div
                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <!-- <div class="suppliers-wrapper col-lg-12 col-12 mb-20">
                                                        <button type="button" (click)="leaveToggle()" class="btn btn-outline-secondary active" >Leave Type</button>
                                                        <button type="button" (click)="leaveReasonToggle()" class="btn btn-outline-secondary" >Reason Type</button>
                                                    </div> -->
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div
                                                                                    class="col-lg-3 form-group formRightC">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Leave
                                                                                        Type
                                                                                        ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2"
                                                                                    *ngIf="!leaveIdButton">
                                                                                    <input type="email"
                                                                                        formControlName="leaveTypeId"
                                                                                        class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Enter Leave Type ID">
                                                                                </div>
                                                                                <div class="col-lg-2"
                                                                                    *ngIf="leaveIdButton">
                                                                                    <input type="email" disabled
                                                                                        formControlName="leaveTypeId"
                                                                                        class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Enter Leave Type ID">
                                                                                </div>

                                                                                <div
                                                                                    class="col-lg-3 form-group formRightC">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Description
                                                                                        <span
                                                                                            style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email"
                                                                                        formControlName="description"
                                                                                        class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Enter Description">
                                                                                </div>

                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div style="margin-left: -41%;"
                                                                        class="col-lg-12 col-12 main-submit-button ">
                                                                        <button _ngcontent-cta-c128="" type="submit"
                                                                            *ngIf="!leaveIdButton"
                                                                            (click)="leaveTypeSubmit()"
                                                                            class="btn btn-secondary submit-btn">Create</button>
                                                                        <button _ngcontent-cta-c128="" type="submit"
                                                                            (click)="updateLeaveType()"
                                                                            *ngIf="leaveIdButton"
                                                                            class="btn btn-secondary submit-btn">Update</button>
                                                                        <button _ngcontent-cta-c128="" type="submit"
                                                                            *ngIf="leaveIdButton"
                                                                            (click)="employeeCancel()"
                                                                            class="btn btn-danger ">Cancel</button>

                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>

                                                        <div class="w3-card-4 classCard border-trans"
                                                            *ngIf="leaveToggles">
                                                            <div
                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select userClass">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="leaveType" [paginator]="true"
                                                                            [rows]="rows" scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Leave Type ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <!-- <th pSortableColumn="category">Last Name <p-sortIcon field="category"></p-sortIcon>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="quantity">State/Province <p-sortIcon field="quantity"></p-sortIcon>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="quantity">City <p-sortIcon field="quantity"></p-sortIcon>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price">Phone Number <p-sortIcon field="price"></p-sortIcon>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th> -->
                                                                                    <th pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                style="visibility:hidden;"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                            class="account-button"
                                                                                                (click)="detailLeaveTypePage(product.leaveTypeId,product.description)">
                                                                                                {{product.leaveTypeId}}
                                                                                            </span>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.description}}
                                                                                    </td>
                                                                                    <!-- <td>{{product.category}}</td>
                                                                        <td>{{product.quantity}}</td>
                                                                        <td>{{product.price | currency: 'USD'}}</td>
                                                                        <td>{{product.category}}</td> -->
                                                                                    <td>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            data-toggle="modal"
                                                                                            data-target="#leaveModal"
                                                                                            (click)="updateLeaveTypeId(product.leaveTypeId,product.description); scroll(target4)">Update</button>

                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            (click)="deleteLeaveType(product.leaveTypeId)">Delete</button>

                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <!-- <p class="paginate_data">
                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="w3-card-4 classCard">
                                                            </div>

                                                        </div>

                                                        <div *ngIf="!leaveToggles">
                                                            <app-reason-type [leaveCreate]="leaveCreate">
                                                            </app-reason-type>
                                                        </div>
                                                    </div>



                                                </div>

                                                <div *ngIf="activeCategory==6">
                                                    <div class="w3-card-4 classCard mb-5P">
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass">

                                                                <li [ngClass]="activeCategoryMain==2?'active':''">
                                                                    <a>
                                                                        Training Class Types</a>
                                                                </li>
                                                                <span class="edit-buttons create_skill"
                                                                    style="margin-left: 81% !important;">
                                                                    <a data-toggle="modal"
                                                                        (click)="cancelTrainingType()"
                                                                        data-target="#trainingModal">Create New Training Class Types</a>
                                                                </span>

                                                            </ul>
                                                        </div>

                                                        <div #target6></div>



                                                        <!-- <form *ngIf="trainingTypeService.training_type__form"
                                                                    [formGroup]="trainingTypeService.training_type__form">
                                                                    <div class="panel-body">

                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRightD">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Training
                                                                                            Class
                                                                                            Type <span
                                                                                                style="color:red">*</span></label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"
                                                                                            formControlName="trainingClassTypeId"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Training Class Type">
                                                                                    </div>

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRightD">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Pre
                                                                                            Requisite
                                                                                            Skill</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true"
                                                                                            formControlName="parentTypeId"
                                                                                            placeholder="Enter Pre Requisite Skill"
                                                                                            [options]="trainingTypeArray"
                                                                                            optionlabel="label">
                                                                                        </p-dropdown>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRightD">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description<span
                                                                                                style="color:red">*</span></label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"
                                                                                            formControlName="description"
                                                                                            parentTypeId="description"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Description">

                                                                                        <small
                                                                                            *ngIf="trainingTypeService.training_type__form.controls['description'].invalid && (trainingTypeService.training_type__form.controls['description'].dirty || trainingTypeService.training_type__form.controls['description'].touched)">
                                                                                            <p class="vaiidate-field"
                                                                                                *ngIf="trainingTypeService.training_type__form.controls['description'].errors.required">
                                                                                                Description is
                                                                                                required!</p>
                                                                                        </small>
                                                                                    </div>

                                                                                </div>
                                                                            </div>




                                                                        </div>
                                                                        <div style="    margin-left: -33%; "
                                                                            class="col-lg-12 col-12 main-submit-button ">
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                                *ngIf="!trainingButton"
                                                                                (click)="submitTrainingType()"
                                                                                class="btn btn-secondary submit-btn">Create</button><button
                                                                                _ngcontent-cta-c128="" type="submit"
                                                                                *ngIf="trainingButton"
                                                                                (click)="updateTrainingType()"
                                                                                class="btn btn-secondary submit-btn">Update</button>
                                                                            <button _ngcontent-cta-c128=""
                                                                                *ngIf="trainingButton" type="submit"
                                                                                (click)="cancelTrainingType()"
                                                                                class="btn btn-danger ">Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form> -->

                                                        <div class="w3-card-4 classCard border-trans">
                                                            <div
                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select userClass">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="trainingData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Training Type Id
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <!-- <th pSortableColumn="name">Pre Skil <p-sortIcon field="name"></p-sortIcon>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="category">Description<p-sortIcon field="category"></p-sortIcon>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="quantity">State/Province <p-sortIcon field="quantity"></p-sortIcon>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>-->
                                                                                    <th pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Pre Requisite Skill
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                style="visibility:hidden;"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                            class="account-button"
                                                                                                (click)="detailTrainingClassPage(product.trainingClassTypeId,product.description,product.parentTypeId)">
                                                                                                {{product.trainingClassTypeId}}
                                                                                            </span>

                                                                                        </div>
                                                                                    </td>
                                                                                    <!-- <td>{{product.name}}</td> -->
                                                                                    <!-- <td>{{product.category}}</td>
                                                                            <td>{{product.quantity}}</td>-->
                                                                                    <td>{{product.parentTypeId}}
                                                                                    </td>
                                                                                    <td>{{product.description}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            data-toggle="modal"
                                                                                            data-target="#trainingModal"
                                                                                            (click)="updateTraining(product.trainingClassTypeId,product.description,product.parentTypeId); scroll(target6)">Update</button>

                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            (click)="onDeleteLeave(product.trainingClassTypeId)">Delete</button>

                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <!-- <p class="paginate_data">
                                                                    View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==7">
                                                    <div class="w3-card-4 classCard mb-5P">
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass">

                                                                <li [ngClass]="activeCategoryMain==2?'active':''">
                                                                    <a>
                                                                        Public Holiday</a>
                                                                </li>
                                                                <span class="edit-buttons create_skill"
                                                                    style="margin-left: 84% !important;">
                                                                    <a data-toggle="modal" (click)="cancelHoliday()"
                                                                        data-target="#holidayModal">Create New Public Holiday</a>
                                                                </span>

                                                            </ul>
                                                        </div>

                                                        <div #target7></div>


                                                        <div class="panel-body">
                                                            <div class="suppliers-wrapper col-lg-12 col-12 mb-20">
                                                            </div>


                                                            <!-- <form
                                                                        *ngIf="publicHolidayService.public_holiday_form"
                                                                        [formGroup]="publicHolidayService.public_holiday_form">

                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRightE">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Holiday
                                                                                            Name
                                                                                            <span
                                                                                                style="color:red">*</span></label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"
                                                                                            formControlName="holidayName"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter holiday name">

                                                                                        <small
                                                                                            *ngIf="publicHolidayService.public_holiday_form.controls['holidayName'].invalid && (publicHolidayService.public_holiday_form.controls['holidayName'].dirty || publicHolidayService.public_holiday_form.controls['holidayName'].touched)">
                                                                                            <p class="vaiidate-field"
                                                                                                *ngIf="publicHolidayService.public_holiday_form.controls['holidayName'].errors.required">
                                                                                                Holiday Name is
                                                                                                required!</p>
                                                                                        </small>
                                                                                    </div>

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRightE">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"
                                                                                            formControlName="descriptionHoliday"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Description">

                                                                                        <small
                                                                                            *ngIf="publicHolidayService.public_holiday_form.controls['descriptionHoliday'].invalid && (publicHolidayService.public_holiday_form.controls['descriptionHoliday'].dirty || publicHolidayService.public_holiday_form.controls['descriptionHoliday'].touched)">
                                                                                            <p class="vaiidate-field"
                                                                                                *ngIf="publicHolidayService.public_holiday_form.controls['descriptionHoliday'].errors.required">
                                                                                                Description is
                                                                                                required!</p>
                                                                                        </small>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRightE">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Date <span
                                                                                                style="color:red">*</span></label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="date"
                                                                                            formControlName="fromDate"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            >

                                                                                        <small
                                                                                            *ngIf="publicHolidayService.public_holiday_form.controls['fromDate'].invalid && (publicHolidayService.public_holiday_form.controls['fromDate'].dirty || publicHolidayService.public_holiday_form.controls['fromDate'].touched)">
                                                                                            <p class="vaiidate-field"
                                                                                                *ngIf="publicHolidayService.public_holiday_form.controls['fromDate'].errors.required">
                                                                                                From Date is
                                                                                                required!</p>
                                                                                        </small>
                                                                                    </div>

                                                                                </div>
                                                                            </div>


                                                                            <div style="    margin-left: -41%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    (click)="updateHolidays()"
                                                                                    type="submit" *ngIf="holidayButton"
                                                                                    class="btn btn-secondary submit-btn">Update</button>
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    (click)="cancelHoliday()"
                                                                                    *ngIf="holidayButton"
                                                                                    class="btn btn-danger ">Cancel</button>
                                                                            </div>
                                                                            <div style="    margin-left: -100%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit" *ngIf="!holidayButton"
                                                                                    (click)="submitPublicHolidays()"
                                                                                    class="btn btn-secondary submit-btn">Create</button>
                                                                            </div>

                                                                        </div>
                                                                    </form> -->
                                                        </div>
                                                        <!-- <div class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128="" type="submit" (click)="submitPublicHolidays()" class="btn btn-secondary submit-btn">Submit</button></div> -->

                                                        <!-- </form> -->
                                                        <div class="w3-card-4 classCard border-trans">
                                                            <div
                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select userClass">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="publicHolidaya"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Holiday Name
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            From Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            By
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <!-- <th pSortableColumn="quantity">City <p-sortIcon field="quantity"></p-sortIcon>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="price">Phone Number <p-sortIcon field="price"></p-sortIcon>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th> -->
                                                                                    <th pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                style="visibility:hidden;"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                            class="account-button"
                                                                                                (click)="detailHolPage(product.workEffortName,product.description,product.estimatedStartDate)">
                                                                                                {{product.workEffortName}}
                                                                                            </span>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.description}}
                                                                                    </td>
                                                                                    <!-- <td>{{product.category}}</td>
                                                                            <td>{{product.quantity}}</td> -->
                                                                                    <td>{{product.estimatedStartDate |
                                                                                        date: 'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td>{{product.firstName}}
                                                                                        {{product.lastName}}
                                                                                        {{product.middleName}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            data-toggle="modal"
                                                                                            data-target="#holidayModal"
                                                                                            (click)="updateHoliday(product.workEffortName,product.description,product.estimatedStartDate,product.workEffortId); scroll(target7)">Update</button>

                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            (click)="deleteHoliday(product.workEffortId)">Delete</button>

                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <!-- <p class="paginate_data">
                                                                    View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==8">
                                                    <div class="w3-card-4 classCard mb-5P">
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass">

                                                                <li [ngClass]="activeCategoryMain==2?'active':''">
                                                                    <a>
                                                                        Termination Type</a>
                                                                </li>
                                                                <span class="edit-buttons create_skill"
                                                                    style="margin-left: 83% !important;">
                                                                    <a data-toggle="modal" (click)="cancelType()"
                                                                        data-target="#terminationModal">Create New Termination Type</a>
                                                                </span>

                                                            </ul>
                                                        </div>

                                                        <div #target></div>

                                                        <!-- <div class="panel-body">
                                                                    <form *ngIf="terminationFormService"
                                                                    [formGroup]="terminationFormService">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group formRight"
                                                                                        style="margin-left: -8%;">
                                                                                        <label for="exampleInputEmail1">
                                                                                            Termination Type ID
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" *ngIf="!updateType">
                                                                                        <input type="email"
                                                                                            formControlName="terminationTypeId"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Termination Type ID">
                                                                                    </div>
                                                                                    <div class="col-lg-2" *ngIf="updateType">
                                                                                        <input type="email" disabled
                                                                                            formControlName="terminationTypeId"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Termination Type ID">
                                                                                    </div>

                                                                                    <div
                                                                                        class="col-lg-3 form-group  classInput__new_changes">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description
                                                                                            <span
                                                                                                style="color:red">*</span></label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"
                                                                                            formControlName="description"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Description">
                                                                                        
                                                                                            <small
                                                                                            *ngIf="terminationFormService.controls['description'].invalid && (terminationFormService.controls['description'].dirty || terminationFormService.controls['description'].touched)"
                                                                                          >
                                                                                            <p
                                                                                              class="vaiidate-field"
                                                                                              *ngIf="terminationFormService.controls['description'].errors.required"
                                                                                            >
                                                                                              Description is required!
                                                                                            </p>
                                                                                          </small>

                                                                                    </div>
                                                                                </div>
                                                                            </div>




                                                                        </div>
                                                                        <div *ngIf="!updateType"
                                                                            style="    margin-left: -25%;"
                                                                            class="col-lg-12 col-12 main-submit-button ">
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                                (click)="createTerminationType()"
                                                                                class="btn btn-secondary submit-btn">Create
                                                                            </button>
                                                                        </div>
                                                                        <div *ngIf="updateType"
                                                                            style="    margin-left: -24%;"
                                                                            class="col-lg-12 col-12 main-submit-button ">
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                                (click)="updateTerminationType()"
                                                                                class="btn btn-secondary submit-btn">Update
                                                                            </button>
                                                                            <button _ngcontent-cta-c128=""
                                                                                type="submit"
                                                                                (click)="cancelType()"
                                                                                class="btn btn-danger ">Cancel
                                                                            </button>
                                                                        </div>

                                                                    </form>
                                                                </div> -->

                                                        <div class="w3-card-4 classCard border-trans">
                                                            <div
                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select userClass">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="terminationTypeList"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Termination Type ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                        </div>
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                               >
                                                                                                {{product.terminationTypeId}}
                                                                                            </span>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.description}}
                                                                                        <!-- <input class="input40" type="text" [(ngModel)]="product.description" [ngModelOptions]="{standalone: true}"> -->
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            data-toggle="modal"
                                                                                            data-target="#terminationModal"
                                                                                            (click)="UpdateType(product); scroll(target)">Update</button>

                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            (click)="deleteTerminationType(product)">Delete</button>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <!-- <p class="paginate_data">
                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                    <!-- <div>
                                                                                <button class="btn btn-secondary submit-btn" (click)="submitTerminationType()" >Submit</button>
                                                                        </div> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==9">
                                                    <!-- <button type="button" data-toggle="modal"
                                                                data-target="#exampleModalPayGrade"
                                                                style="margin-left: 2%;"
                                                                class="btn btn-outline-secondary active  mb-3">New
                                                                Pay
                                                                grade</button> -->

                                                    <div class="w3-card-4 classCard mb-5P">
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass">

                                                                <li [ngClass]="activeCategoryMain==2?'active':''">
                                                                    <a>
                                                                        Pay Grades</a>
                                                                </li>
                                                                <span class="edit-buttons create_skill"
                                                                    style="margin-left: 86% !important;">
                                                                    <a data-toggle="modal" (click)="cancelPayGrade()"
                                                                        data-target="#exampleModalPayGrade">Create New Pay Grade</a>
                                                                </span>

                                                            </ul>
                                                        </div>

                                                        <div #target></div>

                                                        <div class="panel-body">
                                                            <!-- <form [formGroup]="payGradeFormService">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRight">
                                                                                        <label for="exampleInputEmail1">
                                                                                            Pay Grade ID
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" *ngIf="!payType">
                                                                                        <input type="email"
                                                                                            formControlName="payGradeTypeId"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Pay Grade ID">
                                                                                    </div>
                                                                                    <div class="col-lg-2" *ngIf="payType">
                                                                                        <input
                                                                                          type="email"
                                                                                          disabled
                                                                                          formControlName="payGradeTypeId"
                                                                                          class="form-control"
                                                                                          id="exampleInputEmail1"
                                                                                          aria-describedby="emailHelp"
                                                                                          placeholder="Enter Pay Grade ID"
                                                                                        />
                                                                                      </div>

                                                                                    <div 
                                                                                        class="col-lg-3 form-group  classInput__new_changes">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Pay
                                                                                            Grade Name
                                                                                            <span
                                                                                                style="color:red">*</span></label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"
                                                                                            formControlName="payGrade"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Pay Grade Name">
                                                                                    </div>


                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div
                                                                                        class="col-lg-3 form-group formRight">
                                                                                        <label for="exampleInputEmail1">
                                                                                            Comments
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"
                                                                                            formControlName="payComment"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Comment">
                                                                                    </div>

                                                                                </div>
                                                                            </div>


                                                                            <div *ngIf="!payType"
                                                                                style="    margin-left: -25%;"
                                                                                class="col-lg-12 col-12 main-submit-button">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit" (click)="findPayGrade()"
                                                                                    class="btn btn-secondary submit-btn">Find
                                                                                </button>
                                                                            </div>
                                                                            <div *ngIf="payType"
                                                                                style="right: 30%;"
                                                                                class="col-lg-12 col-12 main-submit-button">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit" (click)="updatePayGrade()"
                                                                                    class="btn btn-secondary submit-btn">Update
                                                                                </button>
                                                                                <button type="submit" (click)="cancelPayGrade()"
                                                                                    class="btn btn-danger ">Cancel
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </form> -->
                                                        </div>

                                                        <div class="w3-card-4 classCard border-trans">
                                                            <div
                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select userClass">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="payGradeTypeList"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Pay Grade ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Pay Grade Name
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Comments
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                        </div>
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                            class="account-button"
                                                                                                (click)="detailpayGrade(product)">
                                                                                                {{product.payGradeId}}
                                                                                            </span>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.payGradeName}}
                                                                                    </td>

                                                                                    <td>{{product.comments}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            data-toggle="modal"
                                                                                            data-target="#exampleModalPayGrade"
                                                                                            (click)="updatePayGradeForm(product); scroll(target)">Update</button>

                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            (click)="onDeletePayGrade(product)">Delete</button>

                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <!-- <p class="paginate_data">
                                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==10">
                                                    <div class="w3-card-4 classCard mb-5P">
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass">

                                                                <li [ngClass]="activeCategoryMain==2?'active':''">
                                                                    <a>
                                                                        Termination Reason</a>
                                                                </li>
                                                                <span class="edit-buttons create_skill"
                                                                    style="margin-left: 81% !important;">
                                                                    <a data-toggle="modal"
                                                                        (click)="cancelTerminationReason()"
                                                                        data-target="#reasonModal">Create New Termination Reason</a>
                                                                </span>

                                                            </ul>
                                                        </div>

                                                        <div #target></div>
                                                        <!-- <div class="panel-body">
                                                                    <form [formGroup]="terminationReason">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group formRight"
                                                                                        style="margin-left: -8%;">
                                                                                        <label for="exampleInputEmail1">
                                                                                            Termination Reason ID
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" *ngIf="!updateReason">
                                                                                        <input type="email"
                                                                                            formControlName="terminationReasonId"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Termination Reason ID">
                                                                                    </div>
                                                                                    <div class="col-lg-2" *ngIf="updateReason">
                                                                                        <input type="email" disabled
                                                                                            formControlName="terminationReasonId"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Termination Reason ID">
                                                                                    </div>


                                                                                    <div
                                                                                        class="col-lg-3 form-group  classInput__new_changes">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description
                                                                                            <span
                                                                                                style="color:red">*</span></label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"
                                                                                            formControlName="description"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Description">

                                                                                    </div>
                                                                                </div>
                                                                            </div>




                                                                        </div>
                                                                        <div *ngIf="!updateReason"
                                                                            style="    margin-left: -25%;"
                                                                            class="col-lg-12 col-12 main-submit-button ">
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                                (click)="createTerminationReason()"
                                                                                class="btn btn-secondary submit-btn">Create
                                                                            </button>
                                                                        </div>
                                                                        <div *ngIf="updateReason"
                                                                            style="    margin-left: -24%;"
                                                                            class="col-lg-12 col-12 main-submit-button ">
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                                (click)="onUpdateReason()"
                                                                                class="btn btn-secondary submit-btn">Update
                                                                            </button>
                                                                            <button _ngcontent-cta-c128=""
                                                                                type="submit"
                                                                                (click)="cancelTerminationReason()"
                                                                                class="btn btn-danger ">Cancel
                                                                            </button>
                                                                        </div>

                                                                    </form>
                                                                </div> -->

                                                        <div class="w3-card-4 classCard border-trans">
                                                            <div
                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select userClass">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="terminationReasonList"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Termination Reason ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                        </div>
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                        >
                                                                                                {{product.terminationReasonId}}
                                                                                            </span>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {{product.description}}
                                                                                        <!-- <input class="input40" type="text" [(ngModel)]="product.description" [ngModelOptions]="{standalone: true}"> -->
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            data-toggle="modal"
                                                                                            data-target="#reasonModal"
                                                                                            (click)="UpdateReason(product); scroll(target);">Update</button>

                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            (click)="deleteTerminationReason(product)">Delete</button>

                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <!-- <p class="paginate_data">
                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>

                                                                    <!-- <div class="main-submit-button">
                                                                                <button class="btn btn-secondary submit-btn" (click)="submitTerminationReason(reasonId)" >Submit</button>
                                                                                
                                                                            </div> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalPayGrade" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalPayGrade Title" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength ">
            <div class="modal-header">
                <span *ngIf="!payType" class="color-black pl-1"> Create Pay Grade</span>
                <span *ngIf="payType" class="color-black pl-1"> Update Pay Grade</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close"
                    (click)="updateFalse()">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li class="active">
                                    <a>
                                        create Pay Grade
                                    </a>
                                </li>
                                <li>
                                    <!-- <a>
                                        Salary Steps
                                    </a> -->
                                </li>
                            </ul>
                        </div>
                        <div class="panel-group create-new-leade">
                            <div>

                                <div class="panel-body">
                                    <form [formGroup]="payGradeFormService">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Pay Grade ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!payType">
                                                        <input type="email" formControlName="payGradeTypeId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Pay Grade ID">
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="payType">
                                                        <input type="email" disabled formControlName="payGradeTypeId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Pay Grade ID" />
                                                    </div>

                                                    <div class="col-lg-2 form-group  classInput__new_changes">
                                                        <label for="exampleInputEmail1">Pay
                                                            Grade Name
                                                            <span style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="payGrade"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Pay Grade Name">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="payComment"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comment">
                                                    </div>

                                                </div>
                                            </div>


                                            <div *ngIf="!payType" style="margin-left: -25%;"
                                                class="col-lg-12 col-12 main-submit-button">
                                                <button _ngcontent-cta-c128="" type="submit"
                                                    (click)="onCreatePayGrade()"
                                                    class="btn btn-secondary submit-btn">create
                                                </button>
                                            </div>
                                            <div *ngIf="payType" style="margin-left: -25%;"
                                                class="col-lg-12 col-12 main-submit-button">
                                                <button _ngcontent-cta-c128="" type="submit" (click)="updatePayGrade()"
                                                    class="btn btn-secondary submit-btn">Update
                                                </button>
                                                <!-- <button type="submit" (click)="cancelPayGrade()"
                                                    class="btn btn-danger ">Cancel
                                                </button> -->
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="skillModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalPayGrade Title"
    aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content cssModelLength ">
            <div class="modal-header">
                <span *ngIf="!show"> Create Skill Type</span>
                <span *ngIf="show"> Update Skill Type</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close"
                    (click)="updateFalse()">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp card-body">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active">
                                    <a>
                                        Skill Type</a>
                                </li>


                            </ul>
                        </div>
                        <div class="panel-group create-new-leade">
                            <div>

                                <div class="panel-body">
                                    <form *ngIf="settingFormService.setting_form"
                                        [formGroup]="settingFormService.setting_form">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">Skill
                                                            Type
                                                            ID</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!show">
                                                        <input type="email" formControlName="skillTypeId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Skill Type ID">
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="show">
                                                        <input type="email" disabled formControlName="skillTypeId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Skill Type ID">
                                                    </div>

                                                    <div class="col-lg-2 form-group  classInput__new_changes">
                                                        <label for="exampleInputEmail1">Description
                                                            <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="description"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Description">
                                                        <small
                                                            *ngIf="settingFormService.setting_form.controls['description'].invalid && (settingFormService.setting_form.controls['description'].dirty || settingFormService.setting_form.controls['description'].touched)">
                                                            <p class="vaiidate-field"
                                                                *ngIf="settingFormService.setting_form.controls['description'].errors.required">
                                                                Description is
                                                                required!</p>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                        <div *ngIf="!show" style="    margin-left: -24%;"
                                            class="col-lg-12 col-12 main-submit-button ">
                                            <button _ngcontent-cta-c128="" type="submit" (click)="onSubmit()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                        </div>
                                        <div *ngIf="show" style="    margin-left: -24%;"
                                            class="col-lg-12 col-12 main-submit-button ">
                                            <button _ngcontent-cta-c128="" type="submit" (click)="onUpdate()"
                                                class="btn btn-secondary submit-btn">Update</button>
                                            <!-- <button _ngcontent-cta-c128=""
                                                type="submit" (click)="cancelSkills()"
                                                class="btn btn-danger ">Cancel</button> -->
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="respModal" tabindex="-1" role="dialog" aria-labelledby="exampleModal Title"
    aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content cssModelLength ">
            <div class="modal-header">
                <span *ngIf="!updateResponsibility"> Create Responsibility Type</span>
                <span *ngIf="updateResponsibility"> Update Responsibility Type</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close"
                    (click)="updateFalse()">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp card-body" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active">
                                    <a>
                                        Responsibility Type</a>
                                </li>


                            </ul>
                        </div>
                        <div class="panel-group create-new-leade">
                            <div>

                                <div class="panel-body">
                                    <form *ngIf="responsibilitiesTypeService.responsibility_type__form"
                                        [formGroup]="responsibilitiesTypeService.responsibility_type__form">
                                        <div class="panel-body">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group formRightA">
                                                            <label for="exampleInputEmail1">Responsibility
                                                                Type Id</label>
                                                        </div>
                                                        <div class="col-lg-3" *ngIf="!updateResponsibility">
                                                            <input type="email" formControlName="responsibilityTypeId"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Responsibility Type Id">
                                                        </div>
                                                        <div class="col-lg-3" *ngIf="updateResponsibility">
                                                            <input type="email" formControlName="responsibilityTypeId"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Responsibility Type Id" disabled>
                                                        </div>

                                                        <div class="col-lg-3 form-group formRightA">
                                                            <label for="exampleInputEmail1">Description
                                                                <span style="color:red">*</span></label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="description"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Description">

                                                            <small
                                                                *ngIf="responsibilitiesTypeService.responsibility_type__form.controls['description'].invalid && (responsibilitiesTypeService.responsibility_type__form.controls['description'].dirty || responsibilitiesTypeService.responsibility_type__form.controls['description'].touched)">
                                                                <p class="vaiidate-field"
                                                                    *ngIf="responsibilitiesTypeService.responsibility_type__form.controls['description'].errors.required">
                                                                    Description is
                                                                    required!</p>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button "
                                                style="margin-left: -20%;">
                                                <button _ngcontent-cta-c128="" *ngIf="!updateResponsibility"
                                                    (click)="onResponsi()" type="submit"
                                                    class="btn btn-secondary submit-btn">Create</button>
                                                <button _ngcontent-cta-c128="" (click)="updateRespon()"
                                                    *ngIf="updateResponsibility" type="submit"
                                                    class="btn btn-secondary submit-btn">Update</button>
                                                <!-- <button _ngcontent-cta-c128=""
                                                    *ngIf="updateResponsibility"
                                                    type="submit"
                                                    (click)="cancelResponsibility()"
                                                    class="btn btn-danger ">Cancel</button> -->
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="reasonModal" tabindex="-1" role="dialog" aria-labelledby="exampleModal Title"
    aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content cssModelLength ">
            <div class="modal-header">
                <span *ngIf="!updateReason"> Create Termination Reason</span>
                <span *ngIf="updateReason"> Update Termination Reason</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close"
                    (click)="updateFalse()">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp card-body" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active">
                                    <a>
                                        Termination Reason</a>
                                </li>


                            </ul>
                        </div>
                        <div class="panel-group create-new-leade">
                            <div>

                                <div class="panel-body">
                                    <form [formGroup]="terminationReason">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group formRight"
                                                        style="margin-left: -8%;">
                                                        <label for="exampleInputEmail1">
                                                            Termination Reason ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!updateReason">
                                                        <input type="email" formControlName="terminationReasonId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Termination Reason ID">
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="updateReason">
                                                        <input type="email" disabled
                                                            formControlName="terminationReasonId" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Termination Reason ID">
                                                    </div>


                                                    <div class="col-lg-2 form-group  classInput__new_changes">
                                                        <label for="exampleInputEmail1">Description
                                                            <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="description"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Description">

                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                        <div *ngIf="!updateReason" style="    margin-left: -18%;"
                                            class="col-lg-12 col-12 main-submit-button ">
                                            <button _ngcontent-cta-c128="" type="submit" 
                                                (click)="createTerminationReason()"
                                                class="btn btn-secondary submit-btn">Create
                                            </button>
                                        </div>
                                        <div *ngIf="updateReason" style="    margin-left: -18%;"
                                            class="col-lg-12 col-12 main-submit-button ">
                                            <button _ngcontent-cta-c128="" type="submit" (click)="onUpdateReason()"
                                                class="btn btn-secondary submit-btn">Update
                                            </button>
                                            <!-- <button _ngcontent-cta-c128=""
                                                type="submit"
                                                (click)="cancelTerminationReason()"
                                                class="btn btn-danger ">Cancel
                                            </button> -->
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="posModal" tabindex="-1" role="dialog" aria-labelledby="exampleModal Title"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!positionIdButton"> Create Position Types</span>
                <span *ngIf="positionIdButton"> Update Position Types</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close"
                    (click)="updateFalse()">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active">
                                    <a>
                                        Position Types</a>
                                </li>


                            </ul>
                        </div>
                        <div class="panel-group create-new-leade">
                            <div>

                                <div class="panel-body">
                                    <form *ngIf="positionTypeService.position_type__form"
                                        [formGroup]="positionTypeService.position_type__form">
                                        <div class="panel-body">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group formRightA">
                                                            <label for="exampleInputEmail1">Empl
                                                                Position Type
                                                                ID</label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="emplPositionTypeId"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Empl Position Type ID">
                                                        </div>

                                                        <div class="col-lg-2 form-group formRightA">
                                                            <label for="exampleInputEmail1">Parent
                                                                Type
                                                                ID</label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="parentTypeId"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Parent Type ID">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group formRightA">
                                                            <label for="exampleInputEmail1">Has
                                                                Table</label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <p-dropdown filter="true" [options]="hashTable"
                                                                formControlName="hasTable" optionlabel="label"
                                                                placeholder="Enter Has Table">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-2 form-group formRightA">
                                                            <label for="exampleInputEmail1">Description
                                                                <span style="color:red">*</span></label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="description"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enetr Description">
                                                        </div>
                                                    </div>
                                                </div>




                                            </div>
                                            <div style="    margin-left:-24%;"
                                                class="suppliers-wrapper col-lg-12 col-12 text-right mb-20">
                                                <button type="button" style="width: 8% !important;"
                                                    *ngIf="!positionIdButton" (click)="createPositionType()"
                                                    class="btn btn-outline-secondary">Create</button>
                                                <button type="button" style="width: 8% !important;"
                                                    *ngIf="positionIdButton" (click)="positionTypeUpdates()"
                                                    class="btn btn-outline-secondary">Update</button>
                                                <!-- <button _ngcontent-cta-c128=""
                                                type="submit"
                                                *ngIf="positionIdButton"
                                                (click)="cancelPosition()"
                                                class="btn btn-danger ">Cancel</button> -->

                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="terminationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModal Title"
    aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content cssModelLength ">
            <div class="modal-header">
                <span *ngIf="!updateType"> Create Termination Type</span>
                <span *ngIf="updateType"> Update Termination Type</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close"
                    (click)="updateFalse()">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp card-body" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active">
                                    <a>
                                        Termination Type</a>
                                </li>


                            </ul>
                        </div>
                        <div class="panel-group create-new-leade">
                            <div>
                                <div class="panel-body">
                                    <form *ngIf="terminationFormService" [formGroup]="terminationFormService">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group formRight"
                                                        style="margin-left: -8%;">
                                                        <label for="exampleInputEmail1">
                                                            Termination Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!updateType">
                                                        <input type="email" formControlName="terminationTypeId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Termination Type ID">
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="updateType">
                                                        <input type="email" disabled formControlName="terminationTypeId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Termination Type ID">
                                                    </div>

                                                    <div class="col-lg-2 form-group  classInput__new_changes">
                                                        <label for="exampleInputEmail1">Description
                                                            <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="description"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Description">

                                                        <small
                                                            *ngIf="terminationFormService.controls['description'].invalid && (terminationFormService.controls['description'].dirty || terminationFormService.controls['description'].touched)">
                                                            <p class="vaiidate-field"
                                                                *ngIf="terminationFormService.controls['description'].errors.required">
                                                                Description is required!
                                                            </p>
                                                        </small>

                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                        <div *ngIf="!updateType" style="    margin-left: -19%;"
                                            class="col-lg-12 col-12 main-submit-button ">
                                            <button _ngcontent-cta-c128="" type="submit"
                                                (click)="createTerminationType()"
                                                class="btn btn-secondary submit-btn">Create
                                            </button>
                                        </div>
                                        <div *ngIf="updateType" style="    margin-left: -19%;"
                                            class="col-lg-12 col-12 main-submit-button ">
                                            <button _ngcontent-cta-c128="" type="submit"
                                                (click)="updateTerminationType()"
                                                class="btn btn-secondary submit-btn">Update
                                            </button>
                                            <!-- <button _ngcontent-cta-c128=""
                                                type="submit"
                                                (click)="cancelType()"
                                                class="btn btn-danger ">Cancel
                                            </button> -->
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="jobModal" tabindex="-1" role="dialog" aria-labelledby="exampleModal Title"
    aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content cssModelLength ">
            <div class="modal-header">
                <span *ngIf="!interviewButton"> Create Job Interview Type</span>
                <span *ngIf="interviewButton"> Update Job Interview Type</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close"
                    (click)="updateFalse()">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp card-body" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active">
                                    <a>
                                        Job Interview Type</a>
                                </li>


                            </ul>
                        </div>
                        <div class="panel-group create-new-leade">
                            <div>
                                <div class="panel-body" style="margin-left: 3%;">
                                    <form *ngIf="interviewTypeService.interview_type__form"
                                        [formGroup]="interviewTypeService.interview_type__form">
                                        <div class="panel-body">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group formRightA">
                                                            <label for="exampleInputEmail1">Job
                                                                Interview
                                                                Type ID</label>
                                                        </div>
                                                        <div class="col-lg-3" *ngIf="!interviewButton">
                                                            <input type="email" formControlName="jobInterviewTypeId"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Job Interview Type ID">
                                                        </div>
                                                        <div class="col-lg-3" *ngIf="interviewButton">
                                                            <input type="email" formControlName="jobInterviewTypeId"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Job Interview Type ID" disabled>
                                                        </div>

                                                        <div class="col-lg-3 form-group formRightA">
                                                            <label for="exampleInputEmail1">Description
                                                                <span style="color:red">*</span></label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="text" formControlName="description"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Description">
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                            <div style="margin-left: -24%;" *ngIf="!interviewButton"
                                                (click)="interviewTypeForm()"
                                                class="col-lg-12 col-12 main-submit-button ">
                                                <button _ngcontent-cta-c128="" type="submit"
                                                    class="btn btn-secondary submit-btn">Create</button>
                                            </div>
                                            <div style="margin-left: -24%;" *ngIf="interviewButton"
                                                (click)="updateJobId()" class="col-lg-12 col-12 main-submit-button ">
                                                <button _ngcontent-cta-c128="" type="submit"
                                                    class="btn btn-secondary submit-btn">Update</button>
                                                <!-- <button _ngcontent-cta-c128=""
                                                    *ngIf="interviewButton" type="submit"
                                                    (click)="cancelInterview()"
                                                    class="btn btn-danger ">Cancel</button> -->
                                            </div>

                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="leaveModal" tabindex="-1" role="dialog" aria-labelledby="exampleModal Title"
    aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content cssModelLength ">
            <div class="modal-header">
                <span *ngIf="!leaveIdButton"> Create Employee Leave Type</span>
                <span *ngIf="leaveIdButton"> Update Termination Reason</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close"
                    (click)="updateFalse()">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp card-body" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active">
                                    <a>
                                        Employee Leave Type</a>
                                </li>


                            </ul>
                        </div>
                        <div class="panel-group">
                            <div>
                                <div class="panel-body create-new-leade">
                                    <br>

                                    <form *ngIf="leaveTypeService.leave_type__form"
                                        [formGroup]="leaveTypeService.leave_type__form">

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <!-- <div class="suppliers-wrapper col-lg-12 col-12 mb-20">
                        <button type="button" (click)="leaveToggle()" class="btn btn-outline-secondary active" >Leave Type</button>
                        <button type="button" (click)="leaveReasonToggle()" class="btn btn-outline-secondary" >Reason Type</button>
                    </div> -->
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group formRightC">
                                                        <label for="exampleInputEmail1">Leave
                                                            Type
                                                            ID</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!leaveIdButton">
                                                        <input type="email" formControlName="leaveTypeId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Leave Type ID">
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="leaveIdButton">
                                                        <input type="email" disabled formControlName="leaveTypeId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Leave Type ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group formRightC">
                                                        <label for="exampleInputEmail1">Description
                                                            <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="description"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Description">
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        <div style="margin-left: -25%;" class="col-lg-12 col-12 main-submit-button">
                                            <button _ngcontent-cta-c128="" type="submit" *ngIf="!leaveIdButton"
                                                (click)="leaveTypeSubmit()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                            <button _ngcontent-cta-c128="" type="submit" (click)="updateLeaveType()"
                                                *ngIf="leaveIdButton"
                                                class="btn btn-secondary submit-btn">Update</button>
                                            <!-- <button _ngcontent-cta-c128="" type="submit"
                                                *ngIf="leaveIdButton"
                                                (click)="employeeCancel()"
                                                class="btn btn-danger ">Cancel</button> -->

                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="trainingModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalPayGrade Title"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!trainingButton" class="color-black pl-1"> create Training Class Types</span>
                <span *ngIf="trainingButton" class="color-black pl-1"> Update Training Class Types</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close"
                    (click)="updateFalse()">
                    <span aria-hidden="true" #closebutton9>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li class="active">
                                    <a>
                                        Training Class Types
                                    </a>
                                </li>
                                <li>
                                    <!-- <a>
                                        Salary Steps
                                    </a> -->
                                </li>
                            </ul>
                        </div>
                        <div class="panel-group create-new-leade">
                            <div>
                                <form *ngIf="trainingTypeService.training_type__form"
                                    [formGroup]="trainingTypeService.training_type__form">
                                    <div class="panel-body">

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group formRightD">
                                                        <label for="exampleInputEmail1">Training
                                                            Class
                                                            Type <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="trainingClassTypeId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Training Class Type">
                                                    </div>

                                                    <div class="col-lg-3 form-group formRightD">
                                                        <label for="exampleInputEmail1">Pre
                                                            Requisite
                                                            Skill</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="parentTypeId"
                                                            placeholder="Enter Pre Requisite Skill"
                                                            [options]="trainingTypeArray" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group formRightD">
                                                        <label for="exampleInputEmail1">Description<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="description"
                                                            parentTypeId="description" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Description">

                                                        <small
                                                            *ngIf="trainingTypeService.training_type__form.controls['description'].invalid && (trainingTypeService.training_type__form.controls['description'].dirty || trainingTypeService.training_type__form.controls['description'].touched)">
                                                            <p class="vaiidate-field"
                                                                *ngIf="trainingTypeService.training_type__form.controls['description'].errors.required">
                                                                Description is
                                                                required!</p>
                                                        </small>
                                                    </div>

                                                </div>
                                            </div>




                                        </div>
                                        <div style="    margin-left: -18%; "
                                            class="col-lg-12 col-12 main-submit-button ">
                                            <button _ngcontent-cta-c128="" type="submit" *ngIf="!trainingButton"
                                                (click)="submitTrainingType()"
                                                class="btn btn-secondary submit-btn">Create</button><button
                                                _ngcontent-cta-c128="" type="submit" *ngIf="trainingButton"
                                                (click)="updateTrainingType()"
                                                class="btn btn-secondary submit-btn">Update</button>
                                            <!-- <button _ngcontent-cta-c128=""
                                                *ngIf="trainingButton" type="submit"
                                                (click)="cancelTrainingType()"
                                                class="btn btn-danger ">Cancel</button> -->
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="holidayModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalPayGrade Title"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength ">
            <div class="modal-header">
                <span *ngIf="!holidayButton" class="color-black pl-1"> create Public Holiday</span>
                <span *ngIf="holidayButton" class="color-black pl-1"> Update Public Holiday</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close"
                    (click)="updateFalse()">
                    <span aria-hidden="true" #closebuttona>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex create-new-leade">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li class="active">
                                    <a>
                                        Public Holiday
                                    </a>
                                </li>
                                <li>
                                    <!-- <a>
                                        Salary Steps
                                    </a> -->
                                </li>
                            </ul>
                        </div>
                        <form class="create-new-leade" *ngIf="publicHolidayService.public_holiday_form"
                            [formGroup]="publicHolidayService.public_holiday_form">

                            <div class="all-users-infomation font-13 font-medium own-user-select"
                                style="margin-left: 5%;">
                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-3 form-group formRightE">
                                            <label for="exampleInputEmail1">Holiday
                                                Name
                                                <span style="color:red">*</span></label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" formControlName="holidayName" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter holiday name">

                                            <small
                                                *ngIf="publicHolidayService.public_holiday_form.controls['holidayName'].invalid && (publicHolidayService.public_holiday_form.controls['holidayName'].dirty || publicHolidayService.public_holiday_form.controls['holidayName'].touched)">
                                                <p class="vaiidate-field"
                                                    *ngIf="publicHolidayService.public_holiday_form.controls['holidayName'].errors.required">
                                                    Holiday Name is
                                                    required!</p>
                                            </small>
                                        </div>

                                        <div class="col-lg-3 form-group formRightE">
                                            <label for="exampleInputEmail1">Description</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" formControlName="descriptionHoliday"
                                                class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Description">

                                            <small
                                                *ngIf="publicHolidayService.public_holiday_form.controls['descriptionHoliday'].invalid && (publicHolidayService.public_holiday_form.controls['descriptionHoliday'].dirty || publicHolidayService.public_holiday_form.controls['descriptionHoliday'].touched)">
                                                <p class="vaiidate-field"
                                                    *ngIf="publicHolidayService.public_holiday_form.controls['descriptionHoliday'].errors.required">
                                                    Description is
                                                    required!</p>
                                            </small>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-3 form-group formRightE">
                                            <label for="exampleInputEmail1">From
                                                Date <span style="color:red">*</span></label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="date" formControlName="fromDate" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp" >

                                            <small
                                                *ngIf="publicHolidayService.public_holiday_form.controls['fromDate'].invalid && (publicHolidayService.public_holiday_form.controls['fromDate'].dirty || publicHolidayService.public_holiday_form.controls['fromDate'].touched)">
                                                <p class="vaiidate-field"
                                                    *ngIf="publicHolidayService.public_holiday_form.controls['fromDate'].errors.required">
                                                    From Date is
                                                    required!</p>
                                            </small>
                                        </div>

                                    </div>
                                </div>


                                <div style="    margin-left: -25%;" class="col-lg-12 col-12 main-submit-button ">
                                    <button _ngcontent-cta-c128="" (click)="updateHolidays()" type="submit"
                                        *ngIf="holidayButton" class="btn btn-secondary submit-btn">Update</button>
                                    <!-- <button _ngcontent-cta-c128=""
                                    type="submit"
                                    (click)="cancelHoliday()"
                                    *ngIf="holidayButton"
                                    class="btn btn-danger ">Cancel</button> -->
                                </div>
                                <div style="    margin-left: -25%;" class="col-lg-12 col-12 main-submit-button ">
                                    <button _ngcontent-cta-c128="" type="submit" *ngIf="!holidayButton"
                                        (click)="submitPublicHolidays()"
                                        class="btn btn-secondary submit-btn">Create</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>