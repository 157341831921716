import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2'

import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddEmployeeFormService } from '../add-new-employee/add-new-employment-service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { EmployeeService } from '../employees/employess.service';
import { AddEmployeePositionService } from '../add-employee-position/add-employee-position.service';
import { DatePipe } from '@angular/common';
import { dataLoader } from '@amcharts/amcharts4/core';

import { Location } from '@angular/common';

@Component({
  selector: 'app-hrm-qualification',
  templateUrl: './hrm-qualification.component.html',
  styleUrls: ['./hrm-qualification.component.css']
})
export class HrmQualificationComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  products3: any;
  activeCategory = 2;
  total = 0; PartyQualTypeId: any;
  PartyQualTypeIdArray: any[] = [];
  PartyIdArray: any[] = [];
  PartyId: any;
  actualfromdate: string;
  PaymentID: any;
  PaymentIDArray: any[] = [];
  VerifStatusIDArray: any[] = [];
  VerifStatusID: any;
  ;
  rowNumber = 0;
  pageSize = 100;
  employeeList: any[] = [];
  advanceSearch: {

    PartyID: string;
    PartyQualTypeID: string;
    QualificationDesc: string;
    Title: string;
    fromdateFrom: string;
    fromDateTo: string;
    throughDateFrom: string,
    throughDateTo: string,
    StatusID: string,
    VerifStatusID: string,
  };
  pageNo = 1;
  rows = 50;
  search = ""
  private employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search

  }
  show: boolean;
  createQualification: FormGroup;
  constructor(readonly accountsService: AccountsService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly employeeService: EmployeeService,
    readonly _FormBuilder: FormBuilder,
    public addEmployeeFormService: AddEmployeeFormService,
    private _location: Location,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
  ) {
    this.createQualification = this._FormBuilder.group({
      QualificationDesc: [''],
      Title: [''],
      StatusID: [''],
      VerifStatusID: [''],
      ThroughDate: [''],
      PartyID: [''],
      fromDate: [''],
      PartyQualTypeID: [''],
      Comments: ['']

    });
    this.advanceSearch = {

      PartyID: "",
      PartyQualTypeID: "",
      QualificationDesc: "",
      Title: "",
      fromdateFrom: "",
      fromDateTo: "",
      throughDateFrom: "",
      throughDateTo: "",
      StatusID: "",
      VerifStatusID: "",
    };
  }

  ngOnInit(): void {
    this.show = false;

    this.getPartyQualList();
    this.getPartyQualType();
    this.getPartyId();
    this.getPaymentID();
    this.getStatusVerifId();
  }
  
homeButton(){
  this.router.navigate(['/hr/overview-hrm-main'])

}
cancelSubmit(){
  this._location.back();
}

  reset() {
    this.spinner.show();
    this.advanceSearch.PartyID = "";
    this.advanceSearch.PartyQualTypeID = "";
    this.advanceSearch.QualificationDesc = "";
    this.advanceSearch.Title = "";
    this.advanceSearch.fromdateFrom = "";
    this.advanceSearch.fromDateTo = "";
    this.advanceSearch.throughDateFrom = "";
    this.advanceSearch.throughDateTo = "";
    this.advanceSearch.StatusID = "";
    this.advanceSearch.VerifStatusID = "";

    this.accountsService.getPartyQualList().subscribe((res: any) => {
      this.employeeList = res.data;
      this.spinner.hide();


    })
  }
  pageLoad() {
    this.spinner.show();
    this.advanceSearch.PartyID = "";
    this.advanceSearch.PartyQualTypeID = "";
    this.advanceSearch.QualificationDesc = "";
    this.advanceSearch.Title = "";
    this.advanceSearch.fromdateFrom = "";
    this.advanceSearch.fromDateTo = "";
    this.advanceSearch.throughDateFrom = "";
    this.advanceSearch.throughDateTo = "";
    this.advanceSearch.StatusID = "";
    this.advanceSearch.VerifStatusID = "";

    this.accountsService.getPartyQualList().subscribe((res: any) => {
      this.employeeList = res.data;
      this.spinner.hide();


    })
  }

  getFindPartyQual() {
    this.spinner.show();
    if (this.advanceSearch.PartyID || this.advanceSearch.PartyQualTypeID || this.advanceSearch.Title || this.advanceSearch.fromdateFrom 
      || this.advanceSearch.StatusID || this.advanceSearch.QualificationDesc || this.advanceSearch.throughDateFrom || this.advanceSearch.VerifStatusID) {
      const req = {
        "partyId": this.advanceSearch.PartyID ? this.advanceSearch.PartyID : '',
        "partyQualTypeId": this.advanceSearch.PartyQualTypeID ? this.advanceSearch.PartyQualTypeID : '',
        "qualificationDesc": this.advanceSearch.QualificationDesc ? this.advanceSearch.QualificationDesc : '',
        "title": this.advanceSearch.Title ? this.advanceSearch.Title : '',
        "statusId": this.advanceSearch.StatusID ? this.advanceSearch.StatusID : '',
        "verifStatusId": this.advanceSearch.VerifStatusID ? this.advanceSearch.VerifStatusID : '',
        "thruDate": this.datePipe.transform(this.advanceSearch.throughDateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.throughDateFrom, "yyyy-MM-dd hh:mm:ss") : '',
        "fromDate": this.datePipe.transform(this.advanceSearch.fromdateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.fromdateFrom, "yyyy-MM-dd hh:mm:ss") : '',
      }
      this.accountsService.getFindPartyQual(req.partyId, req.partyQualTypeId, req.qualificationDesc,
        req.title, req.statusId, req.verifStatusId, req.thruDate, req.fromDate).subscribe((res: any) => {
          this.employeeList = res.data;
        })
    }
    else {
      this.getPartyQualList();
    }
    this.spinner.hide();

  }


  deletePartyQual(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "fromDate": product.fromDate,
          "partyId": product.data.partyId,
          "partyQualTypeId": product.data.partyQualTypeId,
          "qualificationDesc": product.data.qualificationDesc,
          "statusId": product.data.statusId,
          "thruDate": product.thruDate,
          "title": product.data.title,
          "verifStatusId": product.data.verifStatusId,
        }
        this.accountsService.deletePartyQual(req).subscribe((res: any) => {
          this.getPartyQualList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  getPartyQualList() {
    this.spinner.show();
    this.accountsService.getPartyQualList().subscribe(res => {
      this.employeeList = res.data;
      this.spinner.hide();

    })
  }

  getPaymentID() {
    this.spinner.show();
    this.accountsService.getPaymentID().subscribe(res => {
      this.PaymentID = res.data;
      this.spinner.hide();
      for (const value of this.PaymentID) {
        this.PaymentIDArray.push({
          label: value.data.paymentId,
          value: value.data.paymentId
        })
      }
    })
  }

  onSubmit() {
    this.spinner.show();

    let fromActualdate = this.createQualification.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    let formData = {

      "fromDate": this.createQualification.value.fromDate,
      "partyId": this.createQualification.value.PartyID,
      "partyQualTypeId": this.createQualification.value.PartyQualTypeID,
      "qualificationDesc": this.createQualification.value.QualificationDesc,
      "statusId": "CTNT_PUBLISHED",
      "thruDate": this.createQualification.value.ThroughDate,
      "title": this.createQualification.value.Title,
      "verifStatusId": "IXF_COMPLETE",
    }


    this.accountsService.createPartyQual(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.closebutton.nativeElement.click();
        // this.router.navigate(["/hr/detail-qualification"], { queryParams: { partyID:this.createQualification.value.EmployeePartyID,fromdate:this.actualfromdate} })


      }

      else if (res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      this.spinner.hide();
    })
  }

  getPartyQualType() {
    this.spinner.show();
    this.accountsService.getPartyQualType().subscribe(res => {
      this.PartyQualTypeId = res.data;
      this.spinner.hide();
      for (const value of this.PartyQualTypeId) {
        this.PartyQualTypeIdArray.push({
          label: value.partyQualTypeId,
          value: value.partyQualTypeId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.PartyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getStatusVerifId() {
    this.spinner.show();
    this.accountsService.getStatusVerifId().subscribe(res => {
      this.VerifStatusID = res.data;
      this.spinner.hide();
      for (const value of this.VerifStatusID) {
        this.VerifStatusIDArray.push({
          label: value.statusId,
          value: value.statusId
        })
      }
    })
  }
  employementDetail(partyId, partyQualTypeId, fromDate, qualificationDesc, title, statusId, verifStatusId, thruDate) {
    this.router.navigate(['/hr/detail-qualification'], {
      queryParams: {
        partyId: partyId, partyQualTypeId: partyQualTypeId,
        fromDate: fromDate, qualificationDesc: qualificationDesc, title: title, statusId: statusId,
        verifStatusId: verifStatusId, thruDate: thruDate
      }
    })
  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}


