import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
import { SortEvent } from 'primeng/api';
@Component({
  selector: 'app-work-effort-comm-events',
  templateUrl: './work-effort-comm-events.component.html',
  styleUrls: ['./work-effort-comm-events.component.css']
})
export class WorkEffortCommEventsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 6;
  addCommEvents: FormGroup;
  updatecomm: any;
  CommunicationEventId: any;
  CommunicationEventIdArray: any[]=[];
  CommunicationTypeId: any;
  CommunicationTypeIdArray: any[]=[];
  statusArray: { label: string; value: string; }[];
  EmailStatusReports: any;
  EmailStatusReportsArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  ContactMechType: any;
  ContactMechTypeArray: any[]=[];
  RoleTypeIds: any;
  RoleTypeIdsArray: any[]=[];
  reasonenumidArray: { label: string; value: string; }[];
  MimeTypeIds: any;
  MimeTypeIdsArray: any[]=[];
  ContactListId: any;
  ContactListIdArray: any[]=[];
  workEffortId: any;
  CommunicationEventByIddata: any;
  StartDate: string;
  FinishDate: string;
  show: boolean;
  total=0;
  rowNumber=0;
  pageSize= 500;
  rows = 50;
  pageNo=1;
  search="";
  userPermissions: any;
    Hidebtn: boolean=false;
    HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    readonly datePipe: DatePipe,
    private _location: Location,
    
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly myContactsService: MyContactsService,
    readonly addSkillService: AddSkillService,
        readonly _FormBuilder: FormBuilder,
    private _Router : Router

  ) {
    this.reasonenumidArray = [

      {
        label: 'TEST REASON',
        value: 'COMM_RES_TEST'
      },
    ];
    
    this.addCommEvents = this._FormBuilder.group({
      commEventID: [''],
      description: [''],
      parentCommEventID: [''],
      commTypeID: [''],
      status: [''],
      partyFrom: [''],
      partyTo: [''],
      contactMechTypeID: [''],
      fromContactMech: [''],
      toContactMech: [''],
      roleTypeIDFrom: [''],
      roleTypeIDTo: [''],
      contactListID: [''],
      startDate: [''],
      finishDate: [''],
      subject: [''],
      reasonEnumID: [''],
      contentMimeTypeID: [''],
      content: [''],
      note: [''],
      messageID: [''],
    })
  }

  ngOnInit(): void {
  
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
    });
    this.getCommunicationEventId();
    this.getCommunicationTypeId();
    this.getEmailStatusReport();
    this.getPartyId();
    this.geContactMechType();
    this.getRoleTypeIds();
    this.getMimeTypeIds();
    this.getContactListId();
    this.getCommunicationEventById();
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  deleteCommunicationEventWorkEff(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "communicationEventId": product.communicationEvent[0].communicationEventId,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "workEffortId": this.workEffortId
        }
        this.myContactsService.deleteCommunicationEventWorkEff(req).subscribe(res => {
          this.getCommunicationEventById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  createCommunicationEvent(): void {
    this.spinner.show();
    const startDate=this.addCommEvents.get('startDate').value;
    this.StartDate=this.datePipe.transform(startDate,"yyyy-MM-dd hh:mm:ss");
    const finishDate=this.addCommEvents.get('finishDate').value;
    this.FinishDate=this.datePipe.transform(finishDate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
  "action": "",
  "communicationEventId": this.addCommEvents.value.commEventID,
  "communicationEventTypeId": "",
  "contactListId": this.addCommEvents.value.contactListID,
  "contactMechIdFrom": this.addCommEvents.value.fromContactMech,
  "contactMechIdTo": this.addCommEvents.value.toContactMech,
  "contactMechTypeId": this.addCommEvents.value.contactMechTypeID,
  "content": this.addCommEvents.value.content,
  "contentMimeTypeId": this.addCommEvents.value.contentMimeTypeID,
  "datetimeEnded": this.FinishDate,
  "datetimeStarted": this.StartDate,
  "description": this.addCommEvents.value.description,
  "messageId": this.addCommEvents.value.messageID,
  "my": "",
  "note": this.addCommEvents.value.note,
  "parentCommEventId": this.addCommEvents.value.parentCommEventID,
  "partyIdFrom": this.addCommEvents.value.partyFrom,
  "partyIdTo": this.addCommEvents.value.partyTo,
  "reasonEnumId": this.addCommEvents.value.reasonEnumID,
  "roleTypeIdFrom": this.addCommEvents.value.roleTypeIDFrom,
  "roleTypeIdTo": this.addCommEvents.value.roleTypeIDTo,
  "statusId": this.addCommEvents.value.status,
  "subject": this.addCommEvents.value.subject,
  "workEffortId": ""
   
      }
     
    this.myContactsService.createCommunicationEvent(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.addCommEvents.reset();
        this.getCommunicationEventById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateCommunicationEventWorkEff(): void {
    this.spinner.show();
    const requestData ={
      "communicationEventId": this.addCommEvents.value.commEventID,
  "contactMechTypeId": this.addCommEvents.value.contactMechTypeID,
  "description": this.addCommEvents.value.description,
  "statusId": this.addCommEvents.value.status,
  "workEffortId": this.workEffortId 
   
      }
     
    this.myContactsService.updateCommunicationEventWorkEff(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getCommunicationEventById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  updateEvent(product){
    this.show=true;
    this.addCommEvents.reset();
    this.spinner.show();
   
      
      setTimeout(() => {
        
        const formValue = this.addCommEvents;
        formValue.patchValue({
          commEventID: product.communicationEvent[0].communicationEventId,
      description: product.commuicationData.description,
      status: product.communicationEvent[0].statusId,
      contactMechTypeID: product.communicationEvent[0].contactMechTypeId,
      
        })
      
      }, 2000);
  
  }
  getCommunicationEventById(){
    this.spinner.show();
    this.myContactsService.getCommunicationEventById(this.workEffortId).subscribe((res:any)=> {
      this.CommunicationEventByIddata = res.data;
      this.spinner.hide();
    
    })   
   }
  addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  editWorkEffort1(){
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"]),{ queryParams: { workEffortId: this.workEffortId} }
  }
  summary(){
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"],{ queryParams: { workEffortId: this.workEffortId} })
  }
detailParties(communicationsId)  {
    this._Router.navigate(["/partyQuickLink/communications"],{ queryParams: { communications:communicationsId} })
  }
  childWorkEfforts(){
    this._Router.navigate(["/psa/work-effort/task-list-view/childWorkEfforts"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  partyAssigns(){
    this._Router.navigate(["/psa/work-effort/task-list-view/PartyAssigns"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  rates(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Rates"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  commEvents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/CommEvents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  shopLists(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ShopLists"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requests(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requests"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requirements(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requirements"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  quotes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Quotes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  orderHeaders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/OrderHeaders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  timeEntries(){
    this._Router.navigate(["/psa/work-effort/task-list-view/TimeEntries"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  notes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Notes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Contents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  products(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Products"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  reviews(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Reviews"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  keywords(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Keywords"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contactMechs(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ContactMechs"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  agreementApplics(){
    this._Router.navigate(["/psa/work-effort/task-list-view/AgreementApplics"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  fixedAssets(){
    this._Router.navigate(["/psa/work-effort/task-list-view/FixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  attributes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Attributes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  eventReminders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/EventReminders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
  getCommunicationEventId() {
    this.spinner.show();
    this.addSkillService.getCommunicationEventId().subscribe(res => {
      this.CommunicationEventId = res.data[0].communicationEventId;
      this.spinner.hide();
      for (const value of this.CommunicationEventId) {
        this.CommunicationEventIdArray.push({
          label: value.communicationEventId,
          value: value.statusId
        })
      }
    })
  }
  getCommunicationTypeId() {
    this.spinner.show();
    this.addSkillService.getCommunicationTypeId().subscribe(res => {
      this.CommunicationTypeId = res.data[0].communicationTypeId;
      this.spinner.hide();
      for (const value of this.CommunicationTypeId) {
        this.CommunicationTypeIdArray.push({
          label: value.description,
          value: value.communicationEventTypeId
        })
      }
    })
  }
  getEmailStatusReport() {
    this.spinner.show();
    this.myContactsService.getEmailStatusReport().subscribe(res => {
      this.EmailStatusReports = res.data[0].data;
      this.spinner.hide();
      for (const value of this.EmailStatusReports) {
        this.EmailStatusReportsArray.push({
          label: value.description,
          value: value.statusValue
        })
      }
    })
  }
  geContactMechType() {
    this.spinner.show();
    this.addSkillService.geContactMechType().subscribe(res => {
      this.ContactMechType = res.data[0].geContactMechType;
      this.spinner.hide();
      for (const value of this.ContactMechType) {
        this.ContactMechTypeArray.push({
          label: value.description,
          value: value.contactMechTypeId
        })
      }
    })
  }
  getRoleTypeIds() {
    this.spinner.show();
    this.addSkillService.getRoleTypeIds().subscribe(res => {
      this.RoleTypeIds = res.data[0].data;
      this.spinner.hide();
      for (const value of this.RoleTypeIds) {
        this.RoleTypeIdsArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  getMimeTypeIds() {
    this.spinner.show();
    this.addSkillService.getMimeTypeIds().subscribe(res => {
      this.MimeTypeIds = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.MimeTypeIds) {
        this.MimeTypeIdsArray.push({
          label: value.mimeTypeId,
          value: value.description
        })
      }
    })
  }
  getContactListId() {
    this.spinner.show();
    this.addSkillService.getContactListId().subscribe(res => {
      this.ContactListId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContactListId) {
        this.ContactListIdArray.push({
          label: value.contactListId,
          value: value.contactListName
        })
      }
    })
  }
  

}
