<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Planing</span>

               <span class="">

                <button type="submit" (click)="homeButton();" 
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="openInventory()"><a>
                                Inventory</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="openFindRequirements()" ><a>
                                Find Requirements</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="openRequirements()" ><a>
                                Open Requirements</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="openProductRequirements()"><a>
                                Approved Product Requirements</a></li>
            
                            <li [ngClass]="activeCategory==5?'active':''" (click)="openInternalRequirements()"><a>
                                Approved Internal Requirements</a></li>
            
                            <li [ngClass]="activeCategory==6?'active':''"  (click)="openTransferRequirements()"><a>
                                Approved Transfer Requirements</a></li>
<!--                                         
                            <li [ngClass]="activeCategory==7?'active':''" (click)="openSalesOrderHistory()"><a>
                                Upload Sales Order History</a></li> -->
                                        
                            <li [ngClass]="activeCategory==8?'active':''" (click)="openRunMRP()" ><a>
                                Run MRP</a></li>

                            <li [ngClass]="activeCategory==9?'active':''" (click)="openViewMRP()" ><a>
                                View MRP</a></li>

                            <li [ngClass]="activeCategory==10?'active':''" (click)="openConfigureWarehouses()" ><a>
                                Configure Warehouses</a></li>
                                
                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategory==9">

                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <span class="headingAccords"> View MRP
                                                            </span>
                                                            <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                            data-target="#createSurveyPopup" data-toggle="modal" style="margin-left: 1%;">Lookup</button>
                                        
                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 77%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>									
                
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <form [formGroup]="viewMRPForm">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Facility<span style="color: red;">*</span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                formControlName="facility" [options]="facilityArray"
                                                                                placeholder="Choose Facility" >
                                    
                                                                                </p-dropdown>
                                                                            </div>
                                    
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Supplier
                                                                                </label>
                                                                            </div>
                                    
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                formControlName="supplier"  [options]="suppliers"
                                                                                placeholder="Choose Supplier" >
                                    
                                                                                </p-dropdown>
                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <!-- <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Product Store
                
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                formControlName="productStore"  [options]="facilityArray"
                                                                                placeholder="Choose Product Store">
                                    
                                                                                </p-dropdown>
                                                                            </div> -->
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Product
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                formControlName="product"  [options]="ProductIdArray"
                                                                                placeholder="Choose Product Id">
                                    
                                                                                </p-dropdown>
                                                                                
                                                                            </div>
                                    
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">MRP Store Group
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                formControlName="MRPStoreGroup"  [options]="MrpEventTypeIdArray"
                                                                                placeholder="Choose MRP Store Group">
                                    
                                                                                </p-dropdown>
                
                                                                            </div>
                                    
                                                                        </div>
                                                                    </div>
                
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <!-- <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Product Store
                
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                formControlName="productStore"  [options]="facilityArray"
                                                                                placeholder="Choose Product Store">
                                    
                                                                                </p-dropdown>
                                                                            </div> -->
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Run Mrp Id
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                formControlName="runMrpId"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Run Mrp Id">
                                                                                
                                                                            </div>
                                    
                                                                        </div>
                                                                    </div>
                                    
                                                                </div>
                                                            </form>
    
                                                            <div class="col-lg-10 main-submit-button" style="margin-left: -9% !important;">
                                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="viewMRP()" >Find</button>&nbsp;
                                                                <button type="submit"  class="btn btn-danger" (click)="reset()" >Reset</button>&nbsp;
                
                                                            </div>
    
    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>


                                        <!-- <div class="w3-card-4 classCardView">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                View MRP
                                                    
                                                <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                    data-target="#createSurveyPopup" data-toggle="modal" style="margin-left: 85%;">Lookup</button>
                                        
                                            </div>
                                            <form [formGroup]="viewMRPForm">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Facility<span style="color: red;">*</span>
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="facility" [options]="facilityArray"
                                                                placeholder="Choose Facility" >
                    
                                                                </p-dropdown>
                                                            </div>
                    
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Supplier
                                                                </label>
                                                            </div>
                    
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="supplier"  [options]="suppliers"
                                                                placeholder="Choose Supplier" >
                    
                                                                </p-dropdown>

                                                            </div>
                                                        </div>
                                                    </div>
                    
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                             <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Product Store

                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="productStore"  [options]="facilityArray"
                                                                placeholder="Choose Product Store">
                    
                                                                </p-dropdown>
                                                            </div> 
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Product
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="product"  [options]="ProductIdArray"
                                                                placeholder="Choose Product Id">
                    
                                                                </p-dropdown>
                                                                
                                                            </div>
                    
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">MRP Store Group
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">

                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="MRPStoreGroup"  [options]="MrpEventTypeIdArray"
                                                                placeholder="Choose MRP Store Group">
                    
                                                                </p-dropdown>

                                                            </div>
                    
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                             <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Product Store

                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="productStore"  [options]="facilityArray"
                                                                placeholder="Choose Product Store">
                    
                                                                </p-dropdown>
                                                            </div> 
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Run Mrp Id
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                id="exampleInputEmail1"
                                                                formControlName="runMrpId"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Run Mrp Id">
                                                                
                                                            </div>
                    
                                                        </div>
                                                    </div>
                    
                                                </div>
                                            </form>
                                            <div class="col-lg-10 main-submit-button" style="margin-left: -9% !important;">
                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="viewMRP()" >Find</button>&nbsp;
                                                <button type="submit"  class="btn btn-danger" (click)="reset()" >Reset</button>&nbsp;

                                            </div>
                                        </div> -->
                                         
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs" >
                                                <h4 class="common-styling h4Margin">
                                                    View MRP Lists

                                                </h4>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                
                                                        <div class="card own-account-table">
                                                            <p-table [value]="viewMRPList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                
                                                                                Run Mrp Id 
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Product Id 
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Description 
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Date 
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Facility Id 
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Product Store Id 
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                       
                                                                        <!-- <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Quantity 
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Minimun Order Quantity
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th> -->
                
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Price
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span  class="account-button"    >
                                                                                    {{product.viewMrp.runMrpId}}      
                                                                                </span>
                
                                                                            </div>
                                                                        </td>
                
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.product[0].productId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.product[0].description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.viewMrp.createdStamp | date:"yyyy-MM-dd hh:mm:ss"}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.viewMrp.facilityId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.viewMrp.productStoreId}}
                                                                        </td>
                                                                        
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                           <span *ngIf="product.ProductPrice[0].price">
                                                                            {{product.ProductPrice[0].price}}
                                                                        </span>
                                                                           
                                                                        </td>
                                                                        
                                                                       
                                                                    </tr>
                                                                </ng-template>
                
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                        <h4
                                                                            class="ml-2">
                                                                            No Record
                                                                            Found</h4>
                                                                    </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                
                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 
