import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-new-relationship-type',
  templateUrl: './new-relationship-type.component.html',
  styleUrls: ['./new-relationship-type.component.css']
})
export class NewRelationshipTypeComponent implements OnInit {
  newRelationType:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  ParentTypeIdParty: any;
  ParentTypeIdPartyArray: any[]=[];
  @ViewChild('closebutton') closebutton;
	
  
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    public bsModalRef: BsModalRef) { 
      this.contentIds = [];
      this.newRelationType = this._FormBuilder.group({
        parentTypeId: [''],
        relationName: [''],
        fromRole:[''],
        toRole:[''],
        Description:[''],
        partyRelationType:['']
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show = false;
    this.getContentId();
    this.getRoleAllType();
    this.getParentTypeIdParty();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
     
    });
   
   
 
  }
  
  getParentTypeIdParty(){
    this.spinner.show();
  
      this.accountsService.getParentTypeIdParty().subscribe((res:any)=> {
        this.ParentTypeIdParty = res.data;
        this.spinner.hide();
        for(const value of this.ParentTypeIdParty){
          this.ParentTypeIdPartyArray.push({
            label:value.parentTypeIdData.partyRelationshipName,
            value:value.parentTypeIdData.partyRelationshipTypeId
          })
        }
      
      })
     
     }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  getContentId(): void {
    this.spinner.show();
    this._CategoryService.getCatalogIdList()
        .then(data => {
            this.spinner.hide();
            if (data.success) {
                this.contentIds = data.data.map(content => {
                    return {
                        label: content.contentId,
                        value: content.contentId
                    };
                });
            }
        })
       
}
  onSubmit() {
    this.spinner.show();
 
    const requestData = {

      "description": this.newRelationType.value.Description,
      "parentTypeId":this.newRelationType.value.parentTypeId,
      "partyId":this.party,
      "partyRelationshipName": this.newRelationType.value.relationName,
      "partyRelationshipTypeId": this.newRelationType.value.partyRelationType,
      "roleTypeIdValidFrom": this.newRelationType.value.fromRole,
      "roleTypeIdValidTo":this.newRelationType.value.toRole
  
     
    }
    this.accountsService.createNewRelationshipType(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.modalClose();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
 
  
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
  
  modalClose(): void {
    this.closebutton.nativeElement.click();
  }
}