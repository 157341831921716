<div class="container-fluid main-container-wrapper-new pr-0">
    <div class="row">
        <div class="col-12">
          
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Dashboard</span>
                <span>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
        </div>
    </div>
   
    <div class="row" style="margin-bottom: 9%;">
        <div class="col-md-6 pr-2">
           
            <div class=" mt-2">
                <div class="w3-card-4 classCard w-100" >
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Party</p>
                    </div>
                    <div class="font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/partyQuickLink/find-party">
                                            Find Party
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/partyQuickLink/create-employee">
                                            Create Employee
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/partyQuickLink/create-prospect">
                                            Create Prospect
                                        </span>
                                    </div>
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/partyQuickLink/create-new-person">
                                            Create New Person
                                        </span>
                                        <span style=" cursor: pointer; color: #0000FF;" routerLink="/partyQuickLink/create-customer" class="">
                                            Create Customer
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/partyQuickLink/create-new-party-group">
                                            Create New Party Group
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="mt-2">
                <div class="w3-card-4 classCard w-100" >
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Asset Management</p>
                    </div>
                    <div class="font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/assetMaint/main-assest">
                                           Main
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/assetMaint/task-list">
                                            Task List
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/assetMaint/fixed-asset/fixed-asset">
                                            Fixed Assets
                                         </span>
                                    </div>
                                    <div class="article">
                                      
                                        <span style="cursor: pointer; color: #0000FF;" routerLink="/assetMaint/asset-maintenance" class="">
                                            Maintenances
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/assetMaint/asset-facility">
                                            Facility
                                        </span>
                                    </div>

                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" mt-2">
                <div class="w3-card-4 classCard w-100" >
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Content</p>
                    </div>
                    <div class="font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/main">
                                            Main
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/content/webSite">
                                           Website
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/survey">
                                            Survey
                                        </span>
                                    </div>
                                    <div class="article">
                                     
                                        <span style="cursor: pointer; color: #0000FF;" routerLink="/content/forum" class="">
                                           Forum
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/blog">
                                            Blog
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/content/content-page">
                                           Content
                                        </span>
                                    </div>


                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/data-resource">
                                            Data Resource
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/content/content-setup">
                                            Content Setup
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/data-resource-setup">
                                            Data Resource Setup
                                        </span>
                                    </div>
                                    <div class="article">
                                     
                                        <span style="color: #0000FF;" routerLink="/content/template" class="">
                                            Template
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/cms">
                                            CMS
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/content/compDoc">
                                            CompDoc
                                        </span>
                                    </div>
                                    
                                    
                                    
                              
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            

            <div class="mt-2">
                <div class="w3-card-4 classCard w-100" >
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Security</p>
                    </div>
                    <div class="font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/security/find-user-login">
                                            Find User Login
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/security/visits">
                                            Visits
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/security/find-security-group">
                                            Find Security Group
                                        </span>
                                    </div>
                                    <div class="article">
                                       
                                        <span style="cursor: pointer; color: #0000FF;" routerLink="/security/cert-issuers" class="">
                                            Cert Issuers
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/security/logged-in-users">
                                            Logged-In Users
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/security/classifications">
                                            Classifications
                                        </span>
                                    </div>

                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/security/address-match-map">
                                            Address Match Map
                                        </span>
                                       
                                    </div>

                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
        <div class="col-md-6 Information_card_div">
            <div class="mt-2">
                <div class="w3-card-4 classCard w-100" >
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Information System</p>
                    </div>
                    <div class="font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/ismgr/hardware">
                                           Hardware
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/ismgr/software">
                                            Software
                                        </span>
                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="mt-2">
                <div class="w3-card-4 classCard w-100" >
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Field Service</p>
                    </div>
                    <div class="font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <div class="card own-account-table" style="background-color: #fafafa !important;">
                                        <div class="article">
                                            <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/fsm/main">
                                               Work Orders
                                            </span>
                                            <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/fsm/customer">
                                               Customer
                                            </span>
                                            <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/fsm/invoices">
                                                Invoices
                                             </span>
                                        </div>
                                        <div class="article">
                                          
                                            <span style="cursor: pointer; color: #0000FF;" routerLink="/fsm/timesheet" class="">
                                                Timesheet
                                            </span>
                                            <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/fsm/fsm-setting/work-order-priorites">
                                                Setting
                                            </span>
                                            <span style="cursor: pointer; color: #0000FF;" routerLink="/fsm/technician" class="">
                                               Staffing
                                            </span>
                                        </div>
    
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          

            <div class="mt-2">
                <div class="w3-card-4 classCard w-100" >
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">EDI</p>
                    </div>
                    <div class="font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/issue-created-import">
                                            Import Users
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/content/security-group-importe">
                                            Import Security Group
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/email-configuration-import">
                                            Setup Email
                                        </span>
                                    </div>
                                    <div class="article">
                                      
                                        <span style=" cursor: pointer; color: #0000FF;" routerLink="/content/balance-sheet-headings-import" class="">
                                           Balance Sheet Heading
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/balance-sheet-import">
                                            Balance Sheet
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/content/trial-balance-import">
                                            Trial Balance Headings
                                        </span>
                                    </div>


                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/trial-balance-only-import">
                                            Trial Balance
                                        </span>
                                        <span style=" cursor: pointer; color: #0000FF;" routerLink="/content/cash-flow-import" class="">
                                           Cash Flows
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/gl-accounts-import">
                                            GL Accounts
                                         </span>
                                    </div>
                                    <div class="article">
                                      
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/content/open-customers-invoices-import">
                                            Open Customers Invoices
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/open-vendors-invoices-import">
                                            Open Vendors Invoices
                                        </span>
                                        <span style=" cursor: pointer; color: #0000FF;" routerLink="/content/customers-only-import" class="">
                                          Customers
                                        </span>
                                    </div>

                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/customers-sites-import">
                                          Customers Site
                                            
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/content/vendors-only-import">
                                          Vendors
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/inventory-count-import">
                                            Inventory Count
                                        </span>
                                    </div>

                                    <div class="article">
                                     
                                        <span style=" cursor: pointer; color: #0000FF;" routerLink="/content/warehouses-only-import" class="">
                                           Warehouses
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/content/warehouses-location-import">
                                            Warehouses Location
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/content/shipments-only-import">
                                            Shipments
                                        </span>
                                    </div>


                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
       
    </div>
</div>    


<ngx-spinner></ngx-spinner>