import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-track-distributor',
  templateUrl: './track-distributor.component.html',
  styleUrls: ['./track-distributor.component.css']
})
export class TrackDistributorComponent implements OnInit {
  activeCategories = 1;

  constructor(
  
  ) {

  }
  ngOnInit(): void {
  }

  
}
