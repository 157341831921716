import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../../../accounting-ap.service';

@Component({
    selector: 'app-create-credit-role',
    templateUrl: './create-credit-role.component.html'
})
export class CreateCreditRoleComponent implements OnInit {
    invoiceId: string;
    createRoleForm: FormGroup;
    partyIds: any[];
    roleTypeIds: any[];
    invoiceType: string;
    constructor(
        readonly  _Router: Router,
        readonly _FormBuilder: FormBuilder,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ToastrService: ToastrService,
        readonly spinner: NgxSpinnerService,
        readonly _GlobalResourceService: GlobalResourceService,
        readonly _AccountingApService: AccountingApService
    ) {
        this.partyIds = [];
        this.invoiceId = '';
        this.createRoleForm = this._FormBuilder.group({
            datetimePerformed: [new Date()],
            partyId: [''],
            percentage: [''],
            roleTypeId: ['']
        });
        this.roleTypeIds = [];
        this.invoiceType = 'payable';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.invoiceId = this._ActivatedRoute.snapshot.queryParams.invoiceId;
        this.invoiceType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.getPartyId();
        this.getRoleType();
    }
    getPartyId(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
            this.spinner.hide();
    }
    getRoleType(): void {
        this.spinner.show();
        this._AccountingApService.getRoleType()
            .then(data => {
                this.roleTypeIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.roleTypeId
                    };
                });
            });
            this.spinner.hide();
    }
    submitInvoiceRole(): void {
        this.spinner.show();
        this._AccountingApService.createInvoiceRole(this.invoiceId, {
            ...this.createRoleForm.value, ...{
                datetimePerformed: moment(this.createRoleForm.value.datetimePerformed).format('YYYY-MM-DD HH:mm:ss')
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('created');
                    this.createRoleForm.reset();
                    this.spinner.hide();
                    this._Router.navigate([`/financial/${this.invoiceType}/credit-memo/summary-credit-memo`], { queryParams: { invoiceId: this.invoiceId } });
                }
            });
           
    }
    reset(): void {
        this.createRoleForm.reset();
        this._Router.navigate([`/financial/${this.invoiceType}/credit-memo/summary-credit-memo`], { queryParams: { invoiceId: this.invoiceId } });
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}