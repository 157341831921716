<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
        <span class="color-grey titlePanels" style="cursor: pointer;"> Production | Manufacturing 
        </span>

        <span class="">

          <button type="submit" (click)="homeButton();"
              class=" btn btn-outline-secondary mr-2">Home</button>
  
          <button type="submit" (click)="cancelSubmit();"
              class="btn btn-danger buttonclass ">Back</button>
      </span>

      </div>
      <div class=" bg-white color-grey">
        <div class="container-fluid">

        </div>

        <div>
          <div class="container-fluid">
            <div class="row" >
              <form class="w-100">

                <div class="w-100">
                  <div class="panel-group">
                    <div *ngIf="activeCategoryValue==2">
                      <div style="width: 49%; float:left">
                        <div class="w3-card-4 cardEffect" >
                          <header class="w3-container w3-blue">
                            <div class="header-tabs">
                              <h4 class=" common-styling" style="margin-left: 7px;">
                                JobShop

                              </h4>

                            </div>
                          </header>

                          <div class="w3-container">
                            <div class="panel-body" style="background: white;">
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="color-black container">
                                  <div class="row">
                                    <div class="article-container">
                                      <div class="article">
                                        <p style="cursor: pointer;    color:#0000FF;"
                                          class="color-grey pl-20 col-lg-10 col-12"
                                          routerLink="/production/jobshop-list">Find Production Run
                                        </p>

                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                        <br>
                        <div class="w3-card-4 cardEffect" >
                          <header class="w3-container w3-blue">
                            <div class="header-tabs">
                              <h4 class=" common-styling" style="margin-left: 7px;">
                                Routing

                              </h4>

                            </div>
                          </header>

                          <div class="w3-container">
                            <div class="panel-body" style="background: white;">
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="color-black container">
                                  <div class="row">
                                    <p routerLink="/production/routing" style="cursor: pointer;   
                                                   color:#0000FF;" class="color-grey pl-20 col-lg-10 col-12">Find
                                      Routing
                                    </p>



                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>



                        </div>
                        <br>
                        <div class="w3-card-4 cardEffect" >
                          <header class="w3-container w3-blue">
                            <div class="header-tabs">
                              <h4 class=" common-styling" style="margin-left: 7px;">
                                MRP

                              </h4>

                            </div>
                          </header>

                          <div class="w3-container">
                            <div class="panel-body" style="background: white;">
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="color-black container">
                                  <p style="cursor: pointer;    color:#0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                    routerLink="/production/mrp-log-list">MRP Log List

                                  </p>


                                </div>
                              </div>
                            </div>
                          </div>



                        </div>
                        <br>
                        <div class="w3-card-4 cardEffect" >
                          <header class="w3-container w3-blue">
                            <div class="header-tabs">
                              <h4 class=" common-styling" style="margin-left: 7px;">
                                Routing Task

                              </h4>

                            </div>
                          </header>

                          <div class="w3-container">
                            <div class="panel-body" style="background: white;">
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="color-black container">
                                  <p style="cursor: pointer;    color:#0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                    routerLink="/production/routing-task">Find Routing Task


                                  </p>


                                </div>
                              </div>
                            </div>
                          </div>



                        </div>
                        <br>

                        <div class="w3-card-4 cardEffect" >
                          <header class="w3-container w3-blue">
                            <div class="header-tabs">
                              <h4 class=" common-styling" style="margin-left: 7px;">
                                Shipment Plans

                              </h4>

                            </div>
                          </header>

                          <div class="w3-container">
                            <div class="panel-body" style="background: white;">
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="color-black container">
                                  <div class="row">
                                    <div class="article-container">
                                      <div class="article">
                                        <p style="cursor: pointer;    color:#0000FF;"
                                          class="color-grey pl-20 col-lg-10 col-12"
                                          routerLink="/production/shipment-plan-list">Shipment Plans
                                        </p>

                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>


                        <br>
                        <br>
                        <br>

                      </div>

                      <div style="width: 49.5%; float:right">
                        <div class="w3-card-4 cardEffect" >
                          <header class="w3-container w3-blue">
                            <div class="header-tabs">
                              <h4 class=" common-styling" style="margin-left: 7px;">
                                Calendar
                              </h4>

                            </div>
                          </header>

                          <div class="w3-container">
                            <div class="panel-body" style="background: white;">
                              <div class="all-users-infomation font-13 font-medium own-user-select">

                                <div class="color-black container">
                                  <div class="row">
                                    <div class="article-container">
                                      <div class="article">
                                        <p style="cursor: pointer;    color:#0000FF;"
                                          class="color-grey pl-20 col-lg-10 col-12"
                                          routerLink="/production/calender-list">Calendar</p>
                                        <span routerLink="/production/week-list"
                                          class="color-grey pl-20 col-lg-10 col-12" style="cursor: pointer;  
                                                      color:#0000FF;"> Week </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>


                        </div>
                        <br>
                        <div class="w3-card-4 cardEffect" >
                          <header class="w3-container w3-blue">
                            <div class="header-tabs">
                              <h4 class=" common-styling" style="margin-left: 7px;">
                                Cost
                              </h4>

                            </div>
                          </header>

                          <div class="w3-container">
                            <div class="panel-body" style="background: white;">
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="color-black container">
                                  <div class="row">
                                    <div class="article-container">
                                      <div class="article">
                                        <p style="cursor: pointer;    color:#0000FF;"
                                          class="color-grey pl-20 col-lg-10 col-12"
                                          routerLink="/production/cost-cacls-list"> Cost Component Calc Entries
                                        </p>
                                      </div>



                                    </div>


                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                        <br>
                        <div class="w3-card-4 cardEffect" >
                          <header class="w3-container w3-blue">
                            <div class="header-tabs">
                              <h4 class=" common-styling" style="margin-left: 7px;">
                                Bill Of Materials
                              </h4>

                            </div>
                          </header>

                          <div class="w3-container">
                            <div class="panel-body" style="background: white;">
                              <div class="all-users-infomation font-13 font-medium own-user-select">

                                <div class="color-black container">
                                  <div class="row">
                                    <div class="article-container">
                                      <div class="article">
                                        <p style="cursor: pointer;    color:#0000FF;"
                                          class="color-grey pl-20 col-lg-10 col-12"
                                          routerLink="/production/bill-of-material-list">Bom Simulation
                                          </p>
                                          <span routerLink="/production/manufacturing-rules"
                                            class="color-grey pl-20 col-lg-10 col-12" style="cursor: pointer;  
                                                    color:#0000FF;"> Manufacturing Rules</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>


                        </div>
                        <br>

                        <div class="w3-card-4 cardEffect" >
                          <header class="w3-container w3-blue">
                            <div class="header-tabs">
                              <h4 class=" common-styling" style="margin-left: 7px;">
                                Report
                              </h4>

                            </div>
                          </header>

                          <div class="w3-container">
                            <div class="panel-body" style="background: white;">
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="color-black container">
                                  <div class="row">
                                    <div class="article-container">
                                      <div class="article">
                                        <p style="cursor: pointer;    color:#0000FF;"
                                          class="color-grey pl-20 col-lg-10 col-12" routerLink="/production/report">
                                          Report</p>

                                      </div>







                                    </div>


                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>



                      </div>





                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>


        </div>

      </div>

    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>