<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Shipment Plans </span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            
  
            <div class=" bg-white color-grey">
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <div class="header-tabs tabClass">
                                        <h4 class=" common-styling h4Margin">
                                            Shipment Plans
                                        </h4>
                                    </div>
                                      
                                      <div class="panel-body">
                                      
                                        <div class="all-users-infomation font-13 font-medium own-user-select allUser">
                                          <div class="form-group">
                                            <div class="card own-account-table">
                                              <p-table [value]="shipmentPlans" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                              [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true"
                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                  <tr style="background: #0d3769;">
                                                    <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                      <div class="checkbox-align" style="color: white;">
                                                       
              
                                                        <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                        Shipment ID<p-sortIcon field="code"></p-sortIcon>
                                                      </div>
                                                      <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                        aria-disabled="false" aria-readonly="false">
                                                    </th>
                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;"> Status ID	<p-sortIcon field="name"></p-sortIcon></div>
                                                      <div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                          name="j_idt727:j_idt728:j_idt731:filter"
                                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                          value="" autocomplete="off"
                                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                          aria-disabled="false" aria-readonly="false">
                                                      </div>
                                                    </th>
                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">Est. Ship Date <p-sortIcon field="name"></p-sortIcon></div>
                                                      <div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                          name="j_idt727:j_idt728:j_idt731:filter"
                                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                          value="" autocomplete="off"
                                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                          aria-disabled="false" aria-readonly="false">
                                                      </div>
                                                    </th>
                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">  Action	 <p-sortIcon field="name"></p-sortIcon>
                                                   </div><div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                          name="j_idt727:j_idt728:j_idt731:filter"
                                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                          value="" autocomplete="off"
                                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                          aria-disabled="false" aria-readonly="false">
                                                      </div>
                                                    </th>
                                                  
                                                  
        
              
                                                  </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-activity>
                                                  <tr>
                                                    <td [ngStyle]="{'width':'180px'}">
                                                      <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                        <span (click)="ship(activity.shipmentId)">
                                                        {{activity.shipmentId}}</span>
                                                      </div>
                                                    </td>
                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.statusId}}   </td>
                                                    <td [ngStyle]="{'width':'180px'}">  {{activity.estimatedShipDate|date:'yyyy-MM-dd hh:mm:ss'}}  </td>
                                                    <td [ngStyle]="{'width':'180px'}" style="cursor:pointer" (click)="getshipmentPlansById(activity.shipmentId)"><span>View</span> </td>
                                                   
                                                   
                                                   
                                                   
                                                   
                                                  </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage" let-columns>
                                                  <tr>
                                                      <td>
                                                          <span class="text-center">
                                                              <h4 class="ml-2">No Record Found
                                                              </h4>
                                                          </span>
                                                      </td>
                                                  </tr>
                                              </ng-template>
                                              </p-table>
                                              <p class="paginate_data">
                                                View per page
                                               
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                            </div>
                            <br><div  *ngIf="this.show"
                            class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium classHead">
                                  <div class="submenu" >
                                     
                                          <button _ngcontent-cta-c128=""  style="width: 20%!important;"
                                              type="submit" class="btn btn-secondary submit-btn stylesubmenu"
                                              routerLinkActive="active" (click)="onSubmit()"> Generate Production Runs
                                          </button>&nbsp;
                                              <button _ngcontent-cta-c128=""  (click)="shipmentPlanStockPDF();"
                                              style="width: 20%!important;"
                                              type="submit" class="btn btn-secondary submit-btn stylesubmenu"
                                              routerLinkActive="active" >Shipment Plan Stock Report 
                                          </button>&nbsp;
                                              <button _ngcontent-cta-c128=""  (click)="packageLabelPDF();"
                                              style="width: 20%!important;"
                                              type="submit" class="btn btn-secondary submit-btn stylesubmenu"
                                              routerLinkActive="active" >Package Labels Report
                                          </button>&nbsp;
                                             
                                          
                                  </div>
                              </div>
                            <div class="w3-card-4 classCard" *ngIf="this.show">
                                <div class="header-tabs tabClass">
                                    <h4 class=" common-styling h4Margin">
                                        Shipment ID {{this.shipid}}
                                    </h4>
                                </div>
                                  
                                  <div class="panel-body">
                                  
                                    <div class="all-users-infomation font-13 font-medium own-user-select allUser" style="display: block!important;">
                                      <div class="form-group">
                                        <div class="card own-account-table">
                                          <p-table [value]="shipmentArray" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                          [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true"
                                            (sortFunction)="customSort($event)" [customSort]="true">
                                            <ng-template pTemplate="header">
                                              <tr style="background: #0d3769;">
                                                <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                  <div class="checkbox-align" style="color: white;">
                                                   
                                                    <p-checkbox _ngcontent-egj-c329=""
                                                    styleclass="custom-checkbox" binary="true"
                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div
                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                        <div class="ui-helper-hidden-accessible">
                                                            <input type="checkbox" name="undefined"
                                                                value="undefined"></div>
                                                        <div role="checkbox"
                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                            <span
                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                        </div>
                                                    </div>
                                                    <!---->
                                                </p-checkbox>
                                                   
                                                    Order ID<p-sortIcon field="code"></p-sortIcon>
                                                  </div>
                                                  <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                    value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                    aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;"> Order Item	<p-sortIcon field="name"></p-sortIcon></div>
                                                  <div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                      name="j_idt727:j_idt728:j_idt731:filter"
                                                      class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                      value="" autocomplete="off"
                                                      aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                      aria-disabled="false" aria-readonly="false">
                                                  </div>
                                                </th>
                                                <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">Product ID<p-sortIcon field="name"></p-sortIcon></div>
                                                  <div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                      name="j_idt727:j_idt728:j_idt731:filter"
                                                      class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                      value="" autocomplete="off"
                                                      aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                      aria-disabled="false" aria-readonly="false">
                                                  </div>
                                                </th>
                                                <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">  Quantity	 <p-sortIcon field="name"></p-sortIcon>
                                               </div><div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                      name="j_idt727:j_idt728:j_idt731:filter"
                                                      class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                      value="" autocomplete="off"
                                                      aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                      aria-disabled="false" aria-readonly="false">
                                                  </div>
                                                </th>
                                                <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">  Issued Quantity	 <p-sortIcon field="name"></p-sortIcon>
                                                </div><div>
                                                     <input id="j_idt727:j_idt728:j_idt731:filter"
                                                       name="j_idt727:j_idt728:j_idt731:filter"
                                                       class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                       value="" autocomplete="off"
                                                       aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                       aria-disabled="false" aria-readonly="false">
                                                   </div>
                                                 </th>
                                                 <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">  Tot Ordered Quantity	 <p-sortIcon field="name"></p-sortIcon>
                                                 </div><div>
                                                      <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                        aria-disabled="false" aria-readonly="false">
                                                    </div>
                                                  </th>
                                                  <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">  Not Available	 <p-sortIcon field="name"></p-sortIcon>
                                                  </div><div>
                                                       <input id="j_idt727:j_idt728:j_idt731:filter"
                                                         name="j_idt727:j_idt728:j_idt731:filter"
                                                         class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                         value="" autocomplete="off"
                                                         aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                         aria-disabled="false" aria-readonly="false">
                                                     </div>
                                                   </th>
                                                   <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">  Tot Planned Quantity	 <p-sortIcon field="name"></p-sortIcon>
                                                   </div><div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                          name="j_idt727:j_idt728:j_idt731:filter"
                                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                          value="" autocomplete="off"
                                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                          aria-disabled="false" aria-readonly="false">
                                                      </div>
                                                    </th>
                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">  Tot Issued Quantity	 <p-sortIcon field="name"></p-sortIcon>
                                                    </div><div>
                                                         <input id="j_idt727:j_idt728:j_idt731:filter"
                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                           value="" autocomplete="off"
                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                           aria-disabled="false" aria-readonly="false">
                                                       </div>
                                                     </th>
                                                     <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">  Production Run ID	 <p-sortIcon field="name"></p-sortIcon>
                                                     </div><div>
                                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            value="" autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                            aria-disabled="false" aria-readonly="false">
                                                        </div>
                                                      </th>
                                                      <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">  Production Run Status ID	 <p-sortIcon field="name"></p-sortIcon>
                                                      </div><div>
                                                           <input id="j_idt727:j_idt728:j_idt731:filter"
                                                             name="j_idt727:j_idt728:j_idt731:filter"
                                                             class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                             value="" autocomplete="off"
                                                             aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                             aria-disabled="false" aria-readonly="false">
                                                         </div>
                                                       </th>
                                                       <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">  Production Run Estimated Completion Date	 <p-sortIcon field="name"></p-sortIcon>
                                                       </div><div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                              name="j_idt727:j_idt728:j_idt731:filter"
                                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                              value="" autocomplete="off"
                                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                              aria-disabled="false" aria-readonly="false">
                                                          </div>
                                                        </th>
                                                        <th pSortableColumn="name" [ngStyle]="{'width':'180px'}"><div style="color: white;">  Production Run Quantity Produced	 <p-sortIcon field="name"></p-sortIcon>
                                                        </div><div>
                                                             <input id="j_idt727:j_idt728:j_idt731:filter"
                                                               name="j_idt727:j_idt728:j_idt731:filter"
                                                               class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                               value="" autocomplete="off"
                                                               aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                               aria-disabled="false" aria-readonly="false">
                                                           </div>
                                                         </th>
                                              
                                              
    
          
                                              </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-activity>
                                              <tr>
                                                <td [ngStyle]="{'width':'180px'}">
                                                  <div class="checkbox-align">
                                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                    {{activity.orderId}}
                                                  </div>
                                                </td>
                                               
                                                <td [ngStyle]="{'width':'180px'}"> {{activity.orderItemSeqId}} </td>
                                                <td [ngStyle]="{'width':'180px'}">  {{activity.productId}}   </td>
                                                <td [ngStyle]="{'width':'180px'}">  {{activity.quantity}}   </td>
                                                <td [ngStyle]="{'width':'180px'}"> {{activity.issuedQuantity}}  </td>
                                                <td [ngStyle]="{'width':'180px'}"> {{activity.totOrderedQuantity}}  </td>
                                                <td [ngStyle]="{'width':'180px'}"> {{activity.notAvailableQuantity}}  </td>
                                                <td [ngStyle]="{'width':'180px'}">  {{activity.totPlannedQuantity}}  </td>
                                                <td [ngStyle]="{'width':'180px'}"> {{activity.totIssuedQuantity}}  </td>
                                                <td [ngStyle]="{'width':'180px'}">{{activity.productionRunId}}  </td>
                                                <td [ngStyle]="{'width':'180px'}"> {{activity.productionRunStatusId}}  </td>
                                                <td [ngStyle]="{'width':'180px'}"> {{activity.productionRunEstimatedCompletionDate|date:'yyyy-MM-dd hh:mm:ss'}}   </td>
                                                <td [ngStyle]="{'width':'180px'}">  {{activity.productionRunQuantityProduced}}  </td>
                                               
                                               
                                               
                                               
                                               
                                              </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage" let-columns>
                                              <tr>
                                                  <td>
                                                      <span class="text-center">
                                                          <h4 class="ml-2">No Record Found
                                                          </h4>
                                                      </span>
                                                  </td>
                                              </tr>
                                          </ng-template>
                                          </p-table>
                                          <p class="paginate_data">
                                            View per page
                                           
                                          </p>
                                        </div>
                                      </div>
                                    
                                    </div>
                                  </div>
                        </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 