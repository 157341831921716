import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CreateDeparmentsService {

  constructor(private http:HttpClient) { }
 
   getDepartment(data,dept) {
    const getDepartment = URLS.getDepartments + "?pageSize=" + data.pageSize + "&pageNo=" + data.pageNo;
  return this.http.post(getDepartment,dept);
   }
 companyId() :Observable<any> {
  const compId = URLS.getDepartments;
   return this.http.get(compId);
 }
 createDepartMent(formData):Observable<any> {
  const createDept = URLS.createDepartment;
   return this.http.post(createDept,formData);
 }
  updateDept(formData,id):Observable<any>{
    const updateDept = URLS.updateDepartment.replace(":departmentId",id);
     return this.http.put(updateDept,formData);
  }
 
  getAllAccount(data,dept,all) {
    const getAccountsList = URLS.getAccountsList + "?all=" + all + "&pageSize=" + data.pageSize + "&pageNo=" + data.pageNo ;
  return this.http.post(getAccountsList,dept);
   }
 






  
}
