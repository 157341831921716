import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SkillService {

  constructor(private http:HttpClient) { }

getSkillList(allReq,skillList):Observable<any> {
  const getSkillList = URLS.getSkills  + "?pageNo=" +allReq.pageNo + "&pageSize=" + allReq.pageSize;
  return this.http.post(getSkillList,skillList);
} 
deleteSkills(partyId,skillTypeId):Observable<any> {
  const deleteSkill = URLS.deleteSkill.replace(":partyId",partyId).replace(":skillTypeId",skillTypeId);
  return this.http.post(deleteSkill,"");
}
  
}
