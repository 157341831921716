<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                   <span class="color-black pl-1 titlePanels" *ngIf="Person">The Profile of {{Person.personInformation.personalTitle}}
                        {{Person.personInformation.firstName}}
                        {{Person.personInformation.middleName}}
                        {{Person.personInformation.lastName}}
                        {{"[" + this.partyId + "]"}}</span>

                        <span class="d-flex">

                            <button type="submit" (click)="homeButton();"
                                class=" btn btn-outline-secondary mr-2">Home</button>
                    
                            <button type="submit" (click)="cancelSubmit();"
                                class="btn btn-danger buttonclass ">Back</button>
                        </span>
                    

                </div>
            <div class="">
               
                <div class=" bg-white color-grey">
                    <div class="create-new-leade ">
                        <div class="container-fluid m-0 p-0">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="w3-card-4 classCardA ">

                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                                                        <li [ngClass]="activeCategoryA==1?'active':''" (click)="MyProfile()"><a>
                                                                Profile

                                                            </a></li>
                                                        <li [ngClass]="activeCategoryA==2?'active':''" (click)="Preferences()">
                                                            <a>
                                                                Preferences
                                                            </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==3?'active':''" (click)="Roles()">
                                                            <a>
                                                                Role(s)</a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==4?'active':''" (click)="Project()">
                                                            <a>
                                                                Projects
                                                            </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==5?'active':''" (click)="TasksProfile()">
                                                            <a>
                                                                Tasks
                                                            </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==6?'active':''" (click)="Rates()">
                                                            <a>
                                                                Rates
                                                            </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==7?'active':''" (click)="Revisions()">
                                                            <a>
                                                                Revisions
                                                            </a>
                                                        </li>


                                                    </ul>
                                                </div>
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                    <div class="create-new-leade ">
                                                        <div class="container-fluid">


                                                            <div class="panel-group">


                                                                <div>
                                                                    <div class=" bg-white color-grey">
                                                                        <div class="create-new-leade ">
                                                                            <div class="container-fluid m-0 p-0">
                                                                                <div class="row">
                                                                                    <form class="w-100">


                                                                                        <br>
                                                                                        <div class="create-new-leade mt-2">
                                                                                            <div class="container-fluid">
                                                                                                <div class="row">

                                                                                                    <form class="w-100">

                                                                                                        <div>
                                                                                                            <div class="w3-card-4 classCard">
                                                                                                                <header class="w3-container w3-blue">
                                                                                                                    <div class="header-tabs">
                                                                                                                        <h4 class=" common-styling h4Margin">
                                                                                                                            Member Roles
                                                                                                                        </h4>
                                                                                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#rolePOPup" style="margin-right: 7px;" >Create
                                                                                                                        </button>

                                                                                                                    </div>
                                                                                                                </header>
                                                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                                    <div class="col-lg-12 col-12">
                                                                                                                        <div class="form-group">

                                                                                                                            <div class="card own-account-table">
                                                                                                                                <p-table [value]="roleList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                                    <ng-template pTemplate="header">
                                                                                                                                        <tr style="background: #0d3769;">
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <!---->
                                                                                                                                                    </p-checkbox>


                                                                                                                                                    RoleType ID
                                                                                                                                                    <p-sortIcon field="code">
                                                                                                                                                    </p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Role
                                                                                                                                                    <p-sortIcon field="name">
                                                                                                                                                    </p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Parent Type ID
                                                                                                                                                    <p-sortIcon field="name">
                                                                                                                                                    </p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Action
                                                                                                                                                    <p-sortIcon field="name">
                                                                                                                                                    </p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>


                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                                                        <tr>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                <div class="checkbox-align">
                                                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </p-checkbox>
                                                                                                                                                    {{product.roletypes.roleTypeId}}
                                                                                                                                                </div>
                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.roletype[0].description}}
                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.roletype[0].parentTypeId}}
                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}" style="cursor: pointer;color: #0d3769;">
                                                                                                                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn" (click)="deleteRole(product.roletypes.roleTypeId)">Remove
                                                                                                                                            </button>

                                                                                                                                            </td>



                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                                        <tr>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                <span class="text-center">
                                                                                                                                                    <h4
                                                                                                                                                        class="ml-2">
                                                                                                                                                        No
                                                                                                                                                        Record
                                                                                                                                                        Found
                                                                                                                                                    </h4>
                                                                                                                                                </span>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                </p-table>

                                                                                                                                <p class="paginate_data">
                                                                                                                                    View per page
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </form>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="rolePOPup" tabindex="-1" role="dialog" aria-labelledby="rolePOPup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Role </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add To Role</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="roleForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">
                                                            Role Type ID </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Role Type ID " filter="true" [options]="RoleListArray" id="exampleInputEmail1" formControlName="RoleTypeID">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>



                                        </div>
                                        <div class="col-lg-12 main-submit-button4">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="submit()">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>