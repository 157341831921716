<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            <div class="col-md-12 p-0" >
                <div class="w3-card-4 classCard  w-100">
                    <div class="mt-3" style="margin-bottom: 10px !important;">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 16px!important;">Work Zones</span>
        
                    </div>                    
        
                        
                <div class="col-lg-12">
                    <div class="row " style="margin-top: -7px !important; margin-bottom: -1% !important;margin-left: 0% !important; ">
                        <a class="btn btn-link" style=" margin-left: -12px !important;" data-target="#addNewWorkZonePopup" data-toggle="modal">Add a New Work Zone </a>
                    </div>
        
                </div>
        
                    
                    <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="border: 1px solid lightcyan !important;">
                                    <label style="background-color:rgb(206, 214, 218);color: black !important; border: 1px solid lightcyan !important;
                                     margin: 0px !important ; padding: 3px !important; font-size: 15px ; font-weight: bold;
                                     padding-left: 7px !important;">Work Zones</label>
                                    <p-table [value]="fsmWorkZoneList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background-color:rgb(206, 214, 218); border: 1px solid lightcyan !important;">
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: black;">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
        
                                                        Work Zone 
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Dispatch Center
                                                    </div>
                                                    <div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </div>
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Primary Service Team
                                                    </div>
                                                    <div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </div>
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Time Zone
                                                    </div>
                                                    <div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </div>
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Action
                                                    </div>
                                                    <div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </div>
                                                </th>
                                               
                                            
                                            
        
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.workZone}}</span>
        
                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.dispatchCentre}}
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.primaryServiceTeam}}
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.time}}
                                                </td>
                                               

                                                <td [ngStyle]="{'width':'180px'}">
                                                    <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; " 
                                                    data-target="#editNewWorkZonePopup" data-toggle="modal"  
                                                    (click)="getFsmWorkZoneById(product)" >Update</small>
                                                    <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;
                                                     "  (click)="deleteFsmWorkZone(product)">Delete</small>
                                                </td>
        
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No
                                                            Record Found
                                                        </h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page
        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="addNewWorkZonePopup" tabindex="-1" role="dialog" aria-labelledby="editCancelReasonPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add a New Work Zone</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add a New Work Zone</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addNewWorkZoneForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Work Zone</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                        aria-describedby="" placeholder="Enter Work Zone"
                                                        formControlName="workZone">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Dispatch Center</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                        aria-describedby="" placeholder="Enter Dispatch Center"
                                                        formControlName="dispatchCenter">
                                                    </div>

                                                   
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Primary Service Team</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="" placeholder="Enter Primary Service Team"
                                                        formControlName="primaryServiceTeam">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Time Zone</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                        [options]="timeZoneIdArray"
                                                         placeholder="Choose Time Zone" formControlName="timeZone">

                                                       </p-dropdown>
                                                        
                                                    </div>

                                                   
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Backup Service Team</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="" placeholder="Enter Backup Service Team"
                                                        formControlName="backupServiceTeam">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Service Hours</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="" placeholder="Enter Service Hours"
                                                        formControlName="serviceHours">
                                                    </div>

                                                   
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Work Order Numbering</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                        [options]="workOrderNumArray"
                                                         placeholder="Choose Work Order Numbering" formControlName="workOrderNumbering">

                                                       </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createFsmWorkZone()">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="editNewWorkZonePopup" tabindex="-1" role="dialog" aria-labelledby="editCancelReasonPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Edit a New Work Zone</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Edit a New Work Zone</a>
                                </li>
                            </ul>
                        </div>
                        
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editNewWorkZoneForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Work Zone</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="" placeholder="Enter Work Zone"
                                                        formControlName="workZone">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Dispatch Center</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="" placeholder="Enter Dispatch Center"
                                                        formControlName="dispatchCenter">
                                                    </div>

                                                   
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Primary Service Team</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="" placeholder="Enter Primary Service Team"
                                                        formControlName="primaryServiceTeam">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Time Zone</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                        [options]="timeZoneIdArray"
                                                         placeholder="Choose Time Zone" formControlName="timeZone">

                                                       </p-dropdown>
                                                        
                                                    </div>

                                                   
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Backup Service Team</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="" placeholder="Enter Backup Service Team"
                                                        formControlName="backupServiceTeam">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Service Hours</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="" placeholder="Enter Service Hours"
                                                        formControlName="serviceHours">
                                                    </div>

                                                   
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Work Order Numbering</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                        [options]="workOrderNumArray"
                                                         placeholder="Choose Work Order Numbering"
                                                          formControlName="workOrderNumbering">

                                                       </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 50%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" 
                                                (click)="updateFsmWorkZone()" >Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <ngx-spinner></ngx-spinner> -->