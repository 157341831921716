<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Requirements</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>

            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 102%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Requirements
                                </a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a>Approve Requirements</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                <a> Find Approved Requirements By Supplier</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                <a> Approved Product Requirements</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategory==1" class="w-100">

                                            <div class="w3-card-4">
                                                <div class="accordion classCard" id="myAccordion">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingOne">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion"
                                                            style="white-space: nowrap;" >
                                                                <span class="headingAccords"> Find Requirements</span>
                                                                
                                                                    <button type="submit"
                                                                        class="btn btn-secondary submit-btn ml-2"
                                                                        data-toggle="modal" data-target="#RequirementPopup">New
                                                                        Requirement</button>
                                                            
                                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 66%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;">
                                                                    </button>									
            
                                                            </div>
                        
                                                        </h2>
                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                            <div class="card-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Requirement ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceRequirement.RequirementID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="RequirementID"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Requirement ID">
                                                                            </div>
                
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">Requirement Type
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                    [(ngModel)]="advanceRequirement.RequirementTypeID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    [options]="RequirementTypeArray"
                                                                                    name="RequirementTypeID" id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Requirement Type ID">
                                                                                </p-dropdown>
                                                                            </div>
                
                
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Status ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceRequirement.StatusID"
                                                                                    [options]="statusIdArray"
                                                                                    [ngModelOptions]="{standalone:true}" name="StatusID"
                                                                                    optionlabel="label" placeholder="Choose Status ID">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">Facility ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceRequirement.FacilityID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="FacilityID" optionlabel="label" [options]="facilityRequirementArray"
                                                                                    placeholder="Choose Facility ID">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Product ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceRequirement.ProductID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="ProductID" optionlabel="label" [options]="ProductIdArray"
                                                                                    placeholder="Choose Product ID">
                                                                                </p-dropdown>
                
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="productId">Description
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceRequirement.Description"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="Description"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Description">
                
                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                
                
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                
                
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="productId">Manufacturing Requirement Start
                                                                                    Date
                
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control"
                                                                                    [(ngModel)]="advanceRequirement.StartDate"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="StartDate"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Manufacturing Requirement Start Date">
                
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">Manufacturing
                                                                                    Requirement By Date
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control"
                                                                                    [(ngModel)]="advanceRequirement.ByDate"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="ByDate"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Manufacturing Requirement By Date">
                
                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                
                
                                                                    <div class="col-lg-12 col-12 main-submit-button"
                                                                        style="margin-left: -17%;">
                                                                        <button type="submit"
                                                                            class="btn btn-secondary submit-btn" (click)="findRequirements()">Find</button>
                                                                        <button type="submit" class="btn btn-danger ml-2" (click)="resetRequirement()">Reset</button>
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                            <!-- <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Find Requirements
                                                    </p>
                                                    <span>
                                                        <button type="submit" style="margin: 0 !important;"
                                                            class="btn btn-secondary submit-btn ml-2"
                                                            data-toggle="modal" data-target="#RequirementPopup">New
                                                            Requirement</button>
                                                    </span><span>
                                                        <button type="submit" style="margin: 0 !important;"
                                                            class="btn btn-secondary submit-btn ml-2"
                                                            data-toggle="modal" data-target="#RequirementPopup">New
                                                            Requirement</button>
                                                    </span>
                                                </div>
                                               
                                                <div class="all-users-infomation font-13 font-medium own-user-select">

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Requirement ID</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    [(ngModel)]="advanceRequirement.RequirementID"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    id="exampleInputEmail1" name="RequirementID"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Requirement ID">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Requirement Type
                                                                    ID</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label"
                                                                    [(ngModel)]="advanceRequirement.RequirementTypeID"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    [options]="RequirementTypeArray"
                                                                    name="RequirementTypeID" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Requirement Type ID">
                                                                </p-dropdown>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Status ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true"
                                                                    [(ngModel)]="advanceRequirement.StatusID"
                                                                    [options]="statusIdArray"
                                                                    [ngModelOptions]="{standalone:true}" name="StatusID"
                                                                    optionlabel="label" placeholder="Choose Status ID">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Facility ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true"
                                                                    [(ngModel)]="advanceRequirement.FacilityID"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    name="FacilityID" optionlabel="label" [options]="facilityRequirementArray"
                                                                    placeholder="Choose Facility ID">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Product ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true"
                                                                    [(ngModel)]="advanceRequirement.ProductID"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    name="ProductID" optionlabel="label" [options]="ProductIdArray"
                                                                    placeholder="Choose Product ID">
                                                                </p-dropdown>

                                                            </div>
                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="productId">Description
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    [(ngModel)]="advanceRequirement.Description"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    id="exampleInputEmail1" name="Description"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Description">


                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-lg-12">
                                                        <div class="row">


                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="productId">Manufacturing Requirement Start
                                                                    Date

                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control"
                                                                    [(ngModel)]="advanceRequirement.StartDate"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    id="exampleInputEmail1" name="StartDate"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Manufacturing Requirement Start Date">

                                                            </div>
                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Manufacturing
                                                                    Requirement By Date
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control"
                                                                    [(ngModel)]="advanceRequirement.ByDate"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    id="exampleInputEmail1" name="ByDate"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Manufacturing Requirement By Date">


                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-lg-12 col-12 main-submit-button"
                                                        style="margin-left: -17%;">
                                                        <button type="submit"
                                                            class="btn btn-secondary submit-btn" (click)="findRequirements()">Find</button>
                                                        <button type="submit" class="btn btn-danger ml-2" (click)="resetRequirement()">Reset</button>
                                                    </div>
                                                </div>
                                            </div> -->

                                            


                                                <div class="w3-card-4 classCardView mt-2 w-100">
                                                    <div
                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        Search Results

                                                    </div>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="findRequirementsList" [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'200px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Requirement ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Status ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Requirement Type ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Facility ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Product ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Requirement Start Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Required By Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">Quantity

                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Facility
                                                                                        Quantity On Hand Total

                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">Quantity
                                                                                        On
                                                                                        Hand Total

                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">Requests
                                                                                        Link

                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Orders
                                                                                        Link

                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'200px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span (click)="detailPage(product.requirement.requirementId)"
                                                                                             class="account-button"  >
                                                                                            {{product.requirement.requirementId}}
                                                                                        </span>

                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <div *ngFor="let r of product.status">
                                                                                        {{r.description}}
                                                                                    </div>
                                                                                 
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <div *ngFor="let r of product.requirementType">
                                                                                        {{r.description}}
                                                                                    </div>
                                                                                   
                                                                                </td>
                                                                                
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.requirement.facilityId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.requirement.productId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.requirement.requirementStartDate | date :'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.requirement.requiredByDate | date :'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.requirement.quantity}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                   
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                                    (click)="detailRequestsPage(product.requirement.requirementId)"   >Requests</button>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                                    (click)="detailOrderPage(product.requirement.requirementId)"  >Orders</button>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <button type="submit" class="btn btn-danger "
                                                                                    (click)="deleteRequirement(product)" >Remove</button>
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                           
                                        </div>
                                        <div *ngIf="activeCategory==2" class="w-100">

                                            <div class="w3-card-4">
                                                <div class="accordion classCard" id="myAccordion">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingOne">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                <span class="headingAccords"> Find Not Approved Requirements</span>
                                                                

                                                                    <button type="submit"
                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                    data-toggle="modal" data-target="#RequirementPopup">New
                                                                    Requirement</button>
                                                            
                                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 56%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;">
                                                                    </button>									
            
                                                            </div>
                        
                                                        </h2>
                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                            <div class="card-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Requirement ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceSearch.RequirementID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    
                                                                                    id="exampleInputEmail1" name="RequirementID"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Requirement ID">
                                                                            </div>
                
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">Requirement Type
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                    [(ngModel)]="advanceSearch.RequirementTypeID"
                                                                                    [options]="RequirementTypeArray"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="RequirementTypeID" id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Requirement Type ID">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">Facility ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceSearch.FacilityID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="FacilityID" optionlabel="label"  
                                                                                      [options]="facilityRequirementArray"
                                                                                    placeholder="Choose Facility ID">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Product ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceSearch.ProductID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="ProductID" optionlabel="label" [options]="ProductIdArray"
                                                                                    placeholder="Choose Product ID">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="productId">Description
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceSearch.Description"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="Description"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Description">
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="productId"> Requirement Start
                                                                                    Date
                
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control"
                                                                                    [(ngModel)]="advanceSearch.StartDate"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="StartDate"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Manufacturing Requirement Start Date">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">
                                                                                    Requirement By Date
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control"
                                                                                    [(ngModel)]="advanceSearch.ByDate"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="ByDate"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Manufacturing Requirement By Date">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                
                
                                                                    <div class="col-lg-12 col-12 main-submit-button"
                                                                        style="margin-left: -17%;">
                                                                        <button type="submit"
                                                                            class="btn btn-secondary submit-btn" (click)="findNotApprovedRequirement()">Find</button>
                                                                        <button type="submit" class="btn btn-danger ml-2" (click)="resetApproveRequirement()">Reset</button>
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                          
                                            <!-- <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Find Not Approved Requirements
                                                    </p>
                                                    <span>
                                                        <button type="submit" style="margin: 0 !important;"
                                                            class="btn btn-secondary submit-btn ml-2"
                                                            data-toggle="modal" data-target="#RequirementPopup">New
                                                            Requirement</button>
                                                    </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Requirement ID</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    [(ngModel)]="advanceSearch.RequirementID"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    
                                                                    id="exampleInputEmail1" name="RequirementID"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Requirement ID">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Requirement Type
                                                                    ID</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label"
                                                                    [(ngModel)]="advanceSearch.RequirementTypeID"
                                                                    [options]="RequirementTypeArray"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    name="RequirementTypeID" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Requirement Type ID">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Facility ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true"
                                                                    [(ngModel)]="advanceSearch.FacilityID"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    name="FacilityID" optionlabel="label"  
                                                                      [options]="facilityRequirementArray"
                                                                    placeholder="Choose Facility ID">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Product ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true"
                                                                    [(ngModel)]="advanceSearch.ProductID"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    name="ProductID" optionlabel="label" [options]="ProductIdArray"
                                                                    placeholder="Choose Product ID">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="productId">Description
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    [(ngModel)]="advanceSearch.Description"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    id="exampleInputEmail1" name="Description"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Description">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="productId"> Requirement Start
                                                                    Date

                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control"
                                                                    [(ngModel)]="advanceSearch.StartDate"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    id="exampleInputEmail1" name="StartDate"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Manufacturing Requirement Start Date">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">
                                                                    Requirement By Date
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control"
                                                                    [(ngModel)]="advanceSearch.ByDate"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    id="exampleInputEmail1" name="ByDate"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Manufacturing Requirement By Date">
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-lg-12 col-12 main-submit-button"
                                                        style="margin-left: -17%;">
                                                        <button type="submit"
                                                            class="btn btn-secondary submit-btn" (click)="findNotApprovedRequirement()">Find</button>
                                                        <button type="submit" class="btn btn-danger ml-2" (click)="resetApproveRequirement()">Reset</button>
                                                    </div>
                                                </div>
                                                </div> -->
                                                
                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    Search Results

                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="ApprovedRequirementList" [paginator]="true" [rows]="rows"
                                                                    scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'200px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Requirement ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Requirement Type ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Facility ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Product ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Requirement Start Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Required By Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">Quantity

                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">Select

                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                        (click)="detailPage(product.requirement.requirementId)"   class="account-button"  >
                                                                                        {{product.requirement.requirementId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.requirement.requirementTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.requirement.facilityId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.requirement.productId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.requirement.requirementStartDate | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.requirement.requiredByDate | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.requirement.quantity}}
                                                                            </td>
                                                                            <td>
                                                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" 
                                                                                (click)="approveRequirement(product)"
                                                                                >Submit
                                                                            </button>
                                                                            </td>



                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage"
                                                                        let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                        <div *ngIf="activeCategory==3" class="w-100">
                                          
                                            <!-- <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Find Approved Requirements By Supplier
                                                    </p>
                                                    <span>
                                                        <button type="submit" style="margin: 0 !important;"
                                                            class="btn btn-secondary submit-btn ml-2"
                                                            data-toggle="modal" data-target="#RequirementPopup">New
                                                            Requirement</button>
                                                    </span>
                                                </div>
                                            </div> -->

                                                <div class="w3-card-4 classCardView mt-2 w-100">
                                                    <div
                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper ">
                                                        Find Approved Requirements By Supplier
                                                        <span>
                                                            <button type="submit" style="margin: 5px !important;"
                                                                class="btn btn-secondary submit-btn ml-2"
                                                                data-toggle="modal" data-target="#RequirementPopup">New
                                                                Requirement</button>
                                                        </span>

                                                    </div>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="approvedRequirementsBySupplier" [paginator]="true" [rows]="rows"
                                                                    scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'200px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>Party ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Supplier Currency Uom ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    # Products
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Bill To Customer Party ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Facility ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Prepare Order
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        
                                                                          
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.partyId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                           
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                              
                                                                            </td>
                                                                            
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.productId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                              
                                                                            </td>
                                                                           
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                                                data-toggle="modal" data-target="#prepareOrderPopUp">Prepare Order
                                                                            </button>
                                                                            </td>
                                                                            


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage"
                                                                        let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                           
                                        </div>
                                        <div *ngIf="activeCategory==4" class="w-100">

                                            <div class="w3-card-4">
                                                <div class="accordion classCard" id="myAccordion">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingOne">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                <span class="headingAccords"> Find Approved Product Requirements</span>
                                                                

                                                                    <button type="submit" 
                                                                        class="btn btn-secondary submit-btn ml-2"
                                                                        data-toggle="modal" data-target="#RequirementPopup">New
                                                                        Requirement</button>
                                                            
                                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 54%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;">
                                                                    </button>									
            
                                                            </div>
                        
                                                        </h2>
                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                            <div class="card-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Requirement ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceProSearch.RequirementID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="RequirementID"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Requirement ID">
                                                                            </div>
                
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">Bill To Customer Party ID
                                                                                    </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                    [(ngModel)]="advanceProSearch.CustomerPartyId"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="CustomerPartyId" id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    [options]="billToCustomerPartyArray"
                                                                                    placeholder="Enter Bill To Customer Party ID">
                                                                                </p-dropdown>
                                                                            </div>
                
                
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Supplier
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceProSearch.Supplier"
                                                                                    [ngModelOptions]="{standalone:true}" name="Supplier"
                                                                                    [options]="supplierListArray"
                                                                                    optionlabel="label" placeholder="Choose Supplier">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">Supplier Currency Uom ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceProSearch.CurrencyUomID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="CurrencyUomID" optionlabel="label" [options]="currencyArray"
                                                                                    placeholder="Choose Supplier Currency Uom ID">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Unassigned Requirements
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="checkbox"  name="unassignedRequirements"
                                                                                [(ngModel)]="advanceProSearch.unassignedRequirements"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                            </div>
                
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">
                                                                                    Requirement By Date
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control"
                                                                                    [(ngModel)]="advanceProSearch.ByDate"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="ByDate"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Requirement By Date">
                
                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Facility ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceProSearch.FacilityID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="FacilityID" optionlabel="label" 
                                                                                    [options]="facilityRequirementArray"
                                                                                    placeholder="Choose Facility ID">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">Product ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceProSearch.ProductID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="ProductID" optionlabel="label" 
                                                                                    [options]="ProductIdArray"
                                                                                    placeholder="Choose Product ID">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                  
                
                
                                                                    <div class="col-lg-12 col-12 main-submit-button"
                                                                        style="margin-left: -24%;">
                                                                        <button type="submit"
                                                                            class="btn btn-secondary submit-btn" (click)="FindApprovedProductRequirements()">Find</button>
                                                                        <button type="submit" class="btn btn-danger ml-2" (click)="resetApproveProRequirement()">Reset</button>
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                           
                                            <!-- <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Find Approved Product Requirements
                                                    </p>
                                                    <span>
                                                        <button type="submit" style="margin: 0 !important;"
                                                            class="btn btn-secondary submit-btn ml-2"
                                                            data-toggle="modal" data-target="#RequirementPopup">New
                                                            Requirement</button>
                                                    </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Requirement ID</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    [(ngModel)]="advanceProSearch.RequirementID"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    id="exampleInputEmail1" name="RequirementID"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Requirement ID">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Bill To Customer Party ID
                                                                    </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label"
                                                                    [(ngModel)]="advanceProSearch.CustomerPartyId"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    name="CustomerPartyId" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    [options]="billToCustomerPartyArray"
                                                                    placeholder="Enter Bill To Customer Party ID">
                                                                </p-dropdown>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Supplier
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true"
                                                                    [(ngModel)]="advanceProSearch.Supplier"
                                                                    [ngModelOptions]="{standalone:true}" name="Supplier"
                                                                    [options]="supplierListArray"
                                                                    optionlabel="label" placeholder="Choose Supplier">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Supplier Currency Uom ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true"
                                                                    [(ngModel)]="advanceProSearch.CurrencyUomID"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    name="CurrencyUomID" optionlabel="label" [options]="currencyArray"
                                                                    placeholder="Choose Supplier Currency Uom ID">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Unassigned Requirements
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="checkbox"  name="unassignedRequirements"
                                                                [(ngModel)]="advanceProSearch.unassignedRequirements"
                                                                [ngModelOptions]="{standalone:true}">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">
                                                                    Requirement By Date
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control"
                                                                    [(ngModel)]="advanceProSearch.ByDate"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    id="exampleInputEmail1" name="ByDate"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Requirement By Date">


                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Facility ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true"
                                                                    [(ngModel)]="advanceProSearch.FacilityID"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    name="FacilityID" optionlabel="label" 
                                                                    [options]="facilityRequirementArray"
                                                                    placeholder="Choose Facility ID">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Product ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true"
                                                                    [(ngModel)]="advanceProSearch.ProductID"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    name="ProductID" optionlabel="label" 
                                                                    [options]="ProductIdArray"
                                                                    placeholder="Choose Product ID">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  


                                                    <div class="col-lg-12 col-12 main-submit-button"
                                                        style="margin-left: -24%;">
                                                        <button type="submit"
                                                            class="btn btn-secondary submit-btn" (click)="FindApprovedProductRequirements()">Find</button>
                                                        <button type="submit" class="btn btn-danger ml-2" (click)="resetApproveProRequirement()">Reset</button>
                                                    </div>
                                                </div>
                                                </div> -->

                                                <div class="w3-card-4 classCardView mt-2 w-100">
                                                    <div
                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        Find Approved Product Requirements


                                                        <button type="button" class="btn btn-secondary submit-btn ml-2" (click)="onSubmitPdf()"
                                                        >Print PDF
                                                    </button>

                                                    </div>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="approvedProductRequirementList" [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'200px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Requirement ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                      	Product ID	
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Internal Name
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Facility ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                     	Supplier
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Supplier product ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        UPCA
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Min Order Qty
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Last Price
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Required By Date
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox"
                                                                            aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Quantity
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'180px'}"
                                                                pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    Comments
                                                                    <p-sortIcon field="name">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input
                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox"
                                                                    aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                                             
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'200px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span
                                                                                             class="account-button"  >
                                                                                            {{product.requirementId}}
                                                                                        </span>

                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.productId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}"
                                                                                *ngIf="product.supplierProductName">
                                                                                    {{product.supplierProductName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.facilityId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}" *ngIf="product.supplierProductId">
                                                                                    {{product.supplierProductId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}" *ngIf="product.minimumOrderQuantity">
                                                                                    {{product.minimumOrderQuantity}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}" *ngIf="product.lastPrice">
                                                                                    {{product.lastPrice}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.requiredByDate | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.quantity}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}" *ngIf="product.comments">
                                                                                    {{product.comments}}
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                           
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="RequirementPopup" tabindex="-1" role="dialog" aria-labelledby="RequirementPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Requirement
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                    Edit Requirement
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="RequirementForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Requirement Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="RequirementTypeArray" 
                                                        placeholder="Choose Requirement Type ID" 
                                                        formControlName="RequirementTypeID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Facility ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="facilityRequirementArray" 
                                                        placeholder="Choose Facility ID" 
                                                        formControlName="FacilityID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Deliverable ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                         aria-describedby="emailHelp" placeholder="Enter Deliverable ID" 
                                                         formControlName="DeliverableID" >

                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Fixed Asset ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="fixedAssetIdArray" 
                                                        placeholder="Choose Fixed Asset ID" formControlName="FixedAssetID">

                                                        </p-dropdown>

                                                    </div>
                                                   
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Product ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" placeholder="Choose Product ID "
                                                        [options]="ProductIdArray" formControlName="ProductID">

                                                       </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" placeholder="Choose Status ID "
                                                         [options]="statusIdArray" formControlName="StatusID">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                         placeholder="Enter Description" aria-describedby="emailHelp"
                                                          formControlName="Description" >

                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Requirement Start Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                        placeholder="Enter Requirement Start Date " aria-describedby="emailHelp"
                                                         formControlName="StartDate" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Required By Date	

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                         placeholder="Enter Required By Date" aria-describedby="emailHelp" 
                                                         formControlName="ByDate" >

                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Estimated Budget

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Estimated Budget" 
                                                        formControlName="EstimatedBudget" >

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                         aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                          formControlName="Quantity" >

                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Use Case

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Use Case" 
                                                        formControlName="UseCase" >

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Reason
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Reason" 
                                                        formControlName="Reason" >
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Created Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="date" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Created Date" 
                                                        formControlName="CreatedDate" >

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Created By User

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                         placeholder="Enter Created By User" aria-describedby="emailHelp" 
                                                         formControlName="CreatedByUser" >

                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Last Modified Date

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                         placeholder="Enter Last Modified Date" aria-describedby="emailHelp"
                                                          formControlName="LastModifiedDate">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Last Modified By User

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                         placeholder="Enter Last Modified By User" aria-describedby="emailHelp" 
                                                         formControlName="LastModifiedUser" >

                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Facility ID To

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                         placeholder="Enter Facility ID To" aria-describedby="emailHelp"
                                                          formControlName="FacilityIDTo">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Cust Request ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                         placeholder="Enter Cust Request ID" aria-describedby="emailHelp" 
                                                         formControlName="CustRequestID" >

                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Cust Request Item Seq ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                         placeholder="Enter Cust Request Item Seq ID" aria-describedby="emailHelp"
                                                          formControlName="CustRequestItemSeqID">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -19%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" 
                                               (click)="createRequirement()" >Submit</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="prepareOrderPopUp" tabindex="-1" role="dialog" aria-labelledby="prepareOrderPopUp" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> 	Prepare Order
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                    Prepare Order
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="prepareOrderForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">		Bill To Customer Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="billToCustomerPartyArray" 
                                                        placeholder="Choose	Bill To Customer Party ID" 
                                                        formControlName="BillToCustomerPartyID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Facility ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="facilityRequirementArray" 
                                                        placeholder="Choose Facility ID" 
                                                        formControlName="FacilityID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <!-- <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">
                                                            Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                         placeholder="Enter  Party ID" aria-describedby="emailHelp" 
                                                         formControlName="PartyID" >
                                                    </div>
                                                </div>
                                            </div> -->
                                           
                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -19%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" 
                                               >Prepare Order</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>