<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Tasks </span>

               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        
            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategory==1?'active':''" (click)="Backlog()"><a>
                                Product Backlog </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="Sprint()"><a>
                                Project/Sprint
                            </a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="MyWork()"><a>
                                My Work</a></li>
                        <li [ngClass]="activeCategory==4?'active':''" (click)="Tasks()"><a>
                                Tasks
                            </a></li>
                        <li [ngClass]="activeCategory==5?'active':''" (click)="OpenTest()"><a>
                                Open Test
                            </a></li>
                        <li [ngClass]="activeCategory==6?'active':''" (click)="Revision()"><a>
                                Revision
                            </a></li>
                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="w3-card-4  w-100">
                                        <div class="accordion classCard" id="myAccordion">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                        <span class="headingAccords"> Find Task 

                                                        </span>
                                                       
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            data-toggle="modal" data-target="#exampleModalCenter">Create
                                                            New
                                                            Task</button>
                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 74%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;">
                                                        </button>									
            
                                                    </div>
                
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                    <div class="card-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Task ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.TaskID" [ngModelOptions]="{standalone:true}" name="TaskID" aria-describedby="emailHelp" placeholder="Enter Task ID">
                                                                    </div>
                
                
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Task Name
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.TaskName" [ngModelOptions]="{standalone:true}" name="TaskName" aria-describedby="emailHelp" placeholder="Enter Task Name">
                                                                    </div>
                
                
                
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Task Type
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" name="TaskType" optionlabel="label" [(ngModel)]="advanceSearch.TaskType" [ngModelOptions]="{standalone:true}" [options]="RoleTypeArray" placeholder="Choose Task Type">
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Unplanned
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" name="Unplanned" optionlabel="label" [(ngModel)]="advanceSearch.Unplanned" [ngModelOptions]="{standalone:true}" [options]="UnplannedListArray" placeholder="Enter Unplanned">
                                                                        </p-dropdown>
                                                                    </div>
                
                
                
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Status
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" name="Status" optionlabel="label" [options]="StatusListArray" placeholder="Enter Status" [(ngModel)]="advanceSearch.Status" [ngModelOptions]="{standalone:true}">
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Assigned To
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" name="AssignedTo" optionlabel="label" [(ngModel)]="advanceSearch.AssignedTo" [ngModelOptions]="{standalone:true}" [options]="PartyTimesheetIdArray" placeholder="Enter Assigned To">
                                                                        </p-dropdown>
                                                                    </div>
                
                
                
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Sprint ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.SprintID" [ngModelOptions]="{standalone:true}" name="SprintID" aria-describedby="emailHelp" placeholder="Enter Sprint ID">
                                                                    </div>
                
                
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Sprint Name
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="SprintName" aria-describedby="emailHelp" [(ngModel)]="advanceSearch.SprintName" [ngModelOptions]="{standalone:true}" placeholder="Enter Sprint Name">
                                                                    </div>
                
                
                
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">
                
                                                                            Project ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="ProjectID" aria-describedby="emailHelp" [(ngModel)]="advanceSearch.ProjectID" [ngModelOptions]="{standalone:true}" placeholder="Enter Project ID">
                                                                    </div>
                
                
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Project Name
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="ProjectName" aria-describedby="emailHelp" [(ngModel)]="advanceSearch.ProjectName" [ngModelOptions]="{standalone:true}" placeholder="Enter Project Name">
                                                                    </div>
                
                
                
                                                                </div>
                                                            </div>
                
                
                                                        </div>

                                                        <div class="col-lg-12  main-submit-button" style="
                                                                   margin-left: -15%;">
                                                            <button _ngcontent-cta-c128="" type="submit" (click)="TaskFind()" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>


                                    <!-- <div class="w3-card-4 classCardView">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 102% !important;
                        flex-wrap: wrap !important;
                        display: flex !important;">
                                                <li><a>
                                                        Find Task
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            data-toggle="modal" data-target="#exampleModalCenter">Add
                                                            New
                                                            Task</button>
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>


                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Task ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.TaskID" [ngModelOptions]="{standalone:true}" name="TaskID" aria-describedby="emailHelp" placeholder="Enter Task ID">
                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Task Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.TaskName" [ngModelOptions]="{standalone:true}" name="TaskName" aria-describedby="emailHelp" placeholder="Enter Task Name">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Task Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" name="TaskType" optionlabel="label" [(ngModel)]="advanceSearch.TaskType" [ngModelOptions]="{standalone:true}" [options]="RoleTypeArray" placeholder="Choose Task Type">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Unplanned
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" name="Unplanned" optionlabel="label" [(ngModel)]="advanceSearch.Unplanned" [ngModelOptions]="{standalone:true}" [options]="UnplannedListArray" placeholder="Enter Unplanned">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" name="Status" optionlabel="label" [options]="StatusListArray" placeholder="Enter Status" [(ngModel)]="advanceSearch.Status" [ngModelOptions]="{standalone:true}">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Assigned To
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" name="AssignedTo" optionlabel="label" [(ngModel)]="advanceSearch.AssignedTo" [ngModelOptions]="{standalone:true}" [options]="PartyTimesheetIdArray" placeholder="Enter Assigned To">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sprint ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.SprintID" [ngModelOptions]="{standalone:true}" name="SprintID" aria-describedby="emailHelp" placeholder="Enter Sprint ID">
                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Sprint Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="SprintName" aria-describedby="emailHelp" [(ngModel)]="advanceSearch.SprintName" [ngModelOptions]="{standalone:true}" placeholder="Enter Sprint Name">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">

                                                            Project ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="ProjectID" aria-describedby="emailHelp" [(ngModel)]="advanceSearch.ProjectID" [ngModelOptions]="{standalone:true}" placeholder="Enter Project ID">
                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Project Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="ProjectName" aria-describedby="emailHelp" [(ngModel)]="advanceSearch.ProjectName" [ngModelOptions]="{standalone:true}" placeholder="Enter Project Name">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12  main-submit-button" style="
                                                                   margin-left: -15%;">
                                            <button _ngcontent-cta-c128="" type="submit" (click)="TaskFind()" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>

                                        </div>

                                    </div> -->


                                    <div class="w3-card-4 classCard mt-2">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Error Tasks List

                                                </h4>

                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="findTaskList" *ngIf="SCRUM_TASK_ERROR" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'220px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Task
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'220px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Sprint ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'220px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Project
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Sprint Backlog
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Task Type ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Assigned To
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Created Date
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Planned Hours
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Actual Hours
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Start Date
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'90px'}" pSortableColumn="name">
                                                                        <div style="color: white;">

                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'90px'}" pSortableColumn="name">
                                                                        <div style="color: white;">

                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'220px'}" (click)="detailTaskPage(product.tasks.workEffortName,product.partid,product.tasks.workEffortId ,product.custid,activeCategory)">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span *ngIf="product.tasks.workEffortName"  class="account-button">
                                                                                {{product.tasks.workEffortName}}</span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'220px'}">
                                                                        <span *ngIf="product.tasks.workEffortName">
                                                                            {{product.tasks.workEffortName + "[" +
                                                                            product.tasks.workEffortId + "]"}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'220px'}">
                                                                        <span *ngIf="product.custid[0].description">
                                                                            {{product.custid[0].description + "[" +
                                                                            product.custid[0].workEffortId +
                                                                            "]"}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.tasks.workEffortName">
                                                                            {{product.tasks.workEffortName + "[" +
                                                                            product.tasks.workEffortParentId +
                                                                            "]"}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.tasks.workEffortTypeId">
                                                                            {{product.tasks.workEffortTypeId}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.tasks.currentStatusId">
                                                                            {{product.tasks.currentStatusId}}</span>

                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.partid[0]">
                                                                            {{product.partid[0].partyId}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> <span *ngIf="product.tasks.createdDate">{{product.tasks.createdDate
                                                                            |
                                                                            date:'yyyy-MM-dd HH:mm:ss' }}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> <span *ngIf="product.AssignedTo[0]">
                                                                            {{product.AssignedTo[0].planHours}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.ActualHours">
                                                                            {{product.ActualHours}}</span>

                                                                    </td>

                                                                    <td [ngStyle]="{'width':'90px'}">
                                                                        <span (click)="detailTaskPage(product.tasks.workEffortName ,product.partid, product.tasks.workEffortId , product.custid ,activeCategory)"  class="account-button">
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                            class="btn btn-secondary submit-btn">Edit</button>&nbsp;
                                                                        </span>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'90px'}">
                                                                        <button type="button" class="btn btn-danger">Delete</button>
                                                                    </td>


                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="w3-card-4 classCard mt-2">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Installation Tasks List

                                                </h4>

                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="findTaskList" [paginator]="true" [rows]="rows" *ngIf="SCRUM_TASK_INST" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'220px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Task
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'220px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Sprint ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'220px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Project
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Sprint Backlog
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Task Type ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Assigned To
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Created Date
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Planned Hours
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Actual Hours
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Start Date
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'90px'}" pSortableColumn="name">
                                                                        <div style="color: white;">

                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'90px'}" pSortableColumn="name">
                                                                        <div style="color: white;">

                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'220px'}" (click)="detailTaskOverviewPage(product.tasks.workEffortName,product.partid,product.tasks.workEffortId ,product.custid,activeCategory)">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span *ngIf="product.tasks.workEffortName"  class="account-button">
                                                                            {{product.tasks.workEffortName}}</span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'220px'}">
                                                                        <span *ngIf="product.tasks.workEffortName">
                                                                        {{product.tasks.workEffortName + "[" +
                                                                        product.tasks.workEffortId + "]"}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'220px'}">
                                                                        <span *ngIf="product.custid[0].description">
                                                                        {{product.custid[0].description + "[" +
                                                                        product.custid[0].workEffortId +
                                                                        "]"}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.tasks.workEffortName">
                                                                        {{product.tasks.workEffortName + "[" +
                                                                        product.tasks.workEffortParentId +
                                                                        "]"}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.tasks.workEffortTypeId">
                                                                        {{product.tasks.workEffortTypeId}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.tasks.currentStatusId">
                                                                        {{product.tasks.currentStatusId}}</span>

                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.partid[0]">
                                                                        {{product.partid[0].partyId}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> <span *ngIf="product.tasks.createdDate">{{product.tasks.createdDate
                                                                        |
                                                                        date:'yyyy-MM-dd HH:mm:ss' }}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> <span *ngIf="product.AssignedTo[0]">
                                                                        {{product.AssignedTo[0].planHours}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.ActualHours">
                                                                        {{product.ActualHours}}</span>

                                                                    </td>

                                                                    <td [ngStyle]="{'width':'90px'}">
                                                                        <span (click)="detailTaskPage(product.tasks.workEffortName ,product.partid, product.tasks.workEffortId , product.custid ,activeCategory)">
                                                                        <button _ngcontent-cta-c128="" type="submit"
                                                                        class="btn btn-secondary submit-btn">Edit</button>&nbsp;
                                                                    </span>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'90px'}">
                                                                        <button type="button" class="btn btn-danger">Delete</button>
                                                                    </td>


                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="w3-card-4 classCard mt-2">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Test Tasks List

                                                </h4>

                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="findTaskList" *ngIf="SCRUM_TASK_TEST" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'220px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Task
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'220px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Sprint ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'220px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Project
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Sprint Backlog
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Task Type ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Assigned To
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Created Date
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Planned Hours
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Actual Hours
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Start Date
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'90px'}" pSortableColumn="name">
                                                                        <div style="color: white;">

                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'90px'}" pSortableColumn="name">
                                                                        <div style="color: white;">

                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'220px'}" (click)="detailTaskPage(product.tasks.workEffortName,product.partid,product.tasks.workEffortId ,product.custid,activeCategory)">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span *ngIf="product.tasks.workEffortName"  class="account-button">
                                                                            {{product.tasks.workEffortName}}</span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'220px'}">
                                                                        <span *ngIf="product.tasks.workEffortName">
                                                                        {{product.tasks.workEffortName + "[" +
                                                                        product.tasks.workEffortId + "]"}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'220px'}">
                                                                        <span *ngIf="product.custid[0].description">
                                                                        {{product.custid[0].description + "[" +
                                                                        product.custid[0].workEffortId +
                                                                        "]"}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.tasks.workEffortName">
                                                                        {{product.tasks.workEffortName + "[" +
                                                                        product.tasks.workEffortParentId +
                                                                        "]"}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.tasks.workEffortTypeId">
                                                                        {{product.tasks.workEffortTypeId}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.tasks.currentStatusId">
                                                                        {{product.tasks.currentStatusId}}</span>

                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.partid[0]">
                                                                        {{product.partid[0].partyId}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> <span *ngIf="product.tasks.createdDate">{{product.tasks.createdDate
                                                                        |
                                                                        date:'yyyy-MM-dd HH:mm:ss' }}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> <span *ngIf="product.AssignedTo[0]">
                                                                        {{product.AssignedTo[0].planHours}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.ActualHours">
                                                                        {{product.ActualHours}}</span>

                                                                    </td>

                                                                    <td [ngStyle]="{'width':'90px'}">
                                                                        <span (click)="detailTaskPage(product.tasks.workEffortName ,product.partid, product.tasks.workEffortId , product.custid ,activeCategory)">
                                                                        <button _ngcontent-cta-c128="" type="submit"
                                                                        class="btn btn-secondary submit-btn">Edit</button>&nbsp;
                                                                    </span>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'90px'}">
                                                                        <button type="button" class="btn btn-danger">Delete</button>
                                                                    </td>


                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="w3-card-4 classCard mt-2">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Coding Tasks List

                                                </h4>

                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">
                                                    <div class="card own-account-table">
                                                        <p-table *ngIf="SCRUM_TASK_IMPL" [value]="findTaskList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'220px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Task
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'220px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Sprint ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'220px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Project
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Sprint Backlog
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Task Type ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Assigned To
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Created Date
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Planned Hours
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Actual Hours
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Start Date
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'90px'}" pSortableColumn="name">
                                                                        <div style="color: white;">

                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'90px'}" pSortableColumn="name">
                                                                        <div style="color: white;">

                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'220px'}" (click)="detailTaskPage(product.tasks.workEffortName,product.partid,product.tasks.workEffortId ,product.custid,activeCategory)">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span *ngIf="product.tasks.workEffortName"  class="account-button">
                                                                            {{product.tasks.workEffortName}}</span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'220px'}">
                                                                        <span *ngIf="product.tasks.workEffortName">
                                                                        {{product.tasks.workEffortName + "[" +
                                                                        product.tasks.workEffortId + "]"}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'220px'}">
                                                                        <span *ngIf="product.custid[0].description">
                                                                        {{product.custid[0].description + "[" +
                                                                        product.custid[0].workEffortId +
                                                                        "]"}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.tasks.workEffortName">
                                                                        {{product.tasks.workEffortName + "[" +
                                                                        product.tasks.workEffortParentId +
                                                                        "]"}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.tasks.workEffortTypeId">
                                                                        {{product.tasks.workEffortTypeId}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.tasks.currentStatusId">
                                                                        {{product.tasks.currentStatusId}}</span>

                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.partid[0]">
                                                                        {{product.partid[0].partyId}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> <span *ngIf="product.tasks.createdDate">{{product.tasks.createdDate
                                                                        |
                                                                        date:'yyyy-MM-dd HH:mm:ss' }}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> <span *ngIf="product.AssignedTo[0]">
                                                                        {{product.AssignedTo[0].planHours}}</span>

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span *ngIf="product.ActualHours">
                                                                        {{product.ActualHours}}</span>

                                                                    </td>

                                                                    <td [ngStyle]="{'width':'90px'}">
                                                                        <span (click)="detailTaskPage(product.tasks.workEffortName ,product.partid, product.tasks.workEffortId , product.custid,activeCategory )">
                                                                        <button _ngcontent-cta-c128="" type="submit"
                                                                        class="btn btn-secondary submit-btn">Edit</button>&nbsp;
                                                                    </span>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'90px'}">
                                                                        <button type="button" class="btn btn-danger">Delete</button>
                                                                    </td>


                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Task # Information</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Edit Task # Information</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100 " [formGroup]="addNewTaskForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Project ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="ProjectIDArray"
                                                         formControlName="ProjectID" placeholdder="Enter Project ID">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="productId">Task Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" formControlName="TaskName" placeholder="Enter Task Name">

                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Task Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" formControlName="TaskType" [options]="RoleTypeArray" placeholder="Enter Task Type">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Sprint ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="SprintID" aria-describedby="emailHelp" placeholder="Enter Sprint ID">


                                                    </div>
                                                </div>
                                            </div>

                                            

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Sprint Backlog
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="SprintBacklogListArray" placeholder="Enter Sprint Backlog" formControlName="SprintBacklog">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Plan Hours</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="PlanHours" aria-describedby="emailHelp" placeholder="Enter Plan Hours">
                                                    </div>
                                                </div>
                                            </div>
                                            


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Uploaded
                                                            File</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="file" class="form-control" id="exampleInputEmail1" 
                                                        (change)="fileProgress($event)" formControlName="Uploaded" aria-describedby="emailHelp" 
                                                        placeholder="Choose File">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>


                                                </div>
                                            </div>

                                            

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Assign To
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="AssignToArray" placeholder="Enter Assign To" formControlName="AssignTo">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -10%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="submit()">Create</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>