import { Component, OnInit } from '@angular/core';
import {CreateTimesheetFormService} from "./create-timesheet-service";
import {TimeSheetService} from "../timesheet.service";
import { ToastrService } from 'ngx-toastr';
import {Router,ActivatedRoute} from "@angular/router";
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-create-timesheet',
  templateUrl: './create-timesheet.component.html',
  styleUrls: ['./create-timesheet.component.css']
})
export class CreateTimesheetComponent implements OnInit {
  statusList: any;
  partyId: any;
  activeCategory = 2;
  partyIdArray: any=[];
  statusArray: any=[];
  timeSheetId: any;
  show: boolean=false;
  errorRes: any;
  updateefromdate: any;
  updateetodate: any;
  userLoginArray: any=[];
  pageSize= 100;
  pageNo:number = 1;
  rows = 50;
  public timeSheet = {
    pageSize:this.pageSize,
    pageNo  :this.pageNo
  }
  constructor(public createTimesheetFormService:CreateTimesheetFormService,
    readonly timeSheetService:TimeSheetService,
    readonly toastr:ToastrService,
    readonly activatedRoute:ActivatedRoute,
    readonly router:Router,
    public datePipe: DatePipe,
    readonly spinner:NgxSpinnerService,
    ) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.timeSheetId=params["timesheetId"];
    })
    if(this.timeSheetId) {
      this.show=true
      this.getTimesheetList();
    }
    else {
      this.show=false
    }
    this.getPartyId();
    this.getStatusList();
    this.getUserLoginId();
    this.createTimesheetFormService.create_timesheet_form=null;
    this.createTimesheetFormService.createTimesheetForm(this.createTimesheetFormService.create_timesheet_form);
  }
  getTimesheetList(){
    
    this.spinner.show();
    const timesheet = {
      approvedByUserId: "",
      approvedByUserIdSearchType: "Contains",
      clientPartyId: "",
      clientPartyIdSearchType: "Contains",
      comments: "",
      commentsSearchType: "Contains",
      fromDateFrom: "",
      fromDateFromSearchType: "Contains",
      fromDateTo: "",
      fromDateToSearchType: "Contains",
      partyId: "",
      statusId: "",
      statusIdSearchType: "Contains",
      throughDateFrom: "",
      throughDateTo: "",
      thruDateFromSearchType: "Contains",
      thruDateToSearchType: "Contains",
      timesheetId: this.timeSheetId,
      timesheetIdSearchType: "Contains"
    }
  this.timeSheetService.getApplicationTimesheetList(this.timeSheet,timesheet).subscribe(res=> {
    /* this.timeSheetList=res.data; */
    setTimeout(() => {
      const formValue =  this.createTimesheetFormService.create_timesheet_form;
      let date=this.datePipe.transform(res.data[0].fromDate,"yyyy-MM-dd");
      let Fromdate=this.datePipe.transform(res.data[0].thruDate,"yyyy-MM-dd");
    formValue.patchValue({
      partyId:res.data[0].partyId,
      clientPartyId:res.data[0].clientPartyId,
      statusId:res.data[0].statusId,
      comments: res.data[0].comments,
      approvedByUserLoginId: res.data[0].approvedByUserLoginId,
      fromDate:date,
      thruDate:Fromdate
     
    })
    }, 3000);
    this.spinner.hide();
   })
 
  }
  getUserLoginId(): void {
    this.spinner.show();
    this.timeSheetService.getUserLogin().subscribe((res) => {
      let userLogin = res.data;
      this.spinner.hide();
      for (const value of userLogin) {
        if (value["groupName"] === null) {
          value["groupName"] = "";
        }
        if (value["firstName"] === null) {
          value["firstName"] = "";
        }
        if (value["middleName"] === null) {
          value["middleName"] = "";
        }
        if (value["lastName"] === null) {
          value["lastName"] = "";
        }
        let name =
          value["userLoginId"] +
          " " +
          value["groupName"] +
          " [" +
          value["firstName"] +
          " " +
          value["middleName"] +
          " " +
          value["lastName"] +
          "]";
        this.userLoginArray.push({
          label: name,
          value: value.userLoginId,
        });
      }
    }); 
  
  }
  onSubmit() {
    this.spinner.show();
    let fromdate=this.createTimesheetFormService.create_timesheet_form.get('fromDate').value;
    this.updateefromdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");

    let todate=this.createTimesheetFormService.create_timesheet_form.get('thruDate').value;
    this.updateetodate=this.datePipe.transform(todate,"yyyy-MM-dd hh:mm:ss");
    let formData={
      approvedByUserLoginId: this.createTimesheetFormService.create_timesheet_form.value.approvedByUserLoginId,
      clientPartyId:this.createTimesheetFormService.create_timesheet_form.value.clientPartyId,
      comments: this.createTimesheetFormService.create_timesheet_form.value.comments,
      fromDate:  this.updateefromdate,
      partyId: this.createTimesheetFormService.create_timesheet_form.value.partyId,
      statusId: this.createTimesheetFormService.create_timesheet_form.value.statusId,
      thruDate: this.updateetodate,
    }
 
    this.timeSheetService.createTimeSheet(formData).subscribe((res:any)=>{
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/hr/timesheets'])
      }
      else if(res.success == false) {
        this.spinner.hide();
        this.toastr.error("Error");
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
  }
  cancel(){
    this.router.navigate(['/hr/timesheets'])
  }
  onUpdate(){
    this.spinner.show();
    let fromdate=this.createTimesheetFormService.create_timesheet_form.get('fromDate').value;
    this.updateefromdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");

    let todate=this.createTimesheetFormService.create_timesheet_form.get('thruDate').value;
    this.updateetodate=this.datePipe.transform(todate,"yyyy-MM-dd hh:mm:ss");
    let formData={
      approvedByUserLoginId: this.createTimesheetFormService.create_timesheet_form.value.approvedByUserLoginId,
      clientPartyId:this.createTimesheetFormService.create_timesheet_form.value.clientPartyId,
      comments: this.createTimesheetFormService.create_timesheet_form.value.comments,
      fromDate:  this.updateefromdate,
      partyId: this.createTimesheetFormService.create_timesheet_form.value.partyId,
      statusId: this.createTimesheetFormService.create_timesheet_form.value.statusId,
      thruDate: this.updateetodate,
    }
   
    this.timeSheetService.updateTimesheet(formData, this.timeSheetId).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/hr/timesheets'])
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }

   })
  

  }
  getStatusList(){
    this.spinner.show();
    this.timeSheetService.getStatusList().subscribe(res=> {
      this.statusList = res.data;
      this.spinner.hide();
      for(const data of this.statusList) {
        this.statusArray.push({
          label:data.description,
          value:data.statusId
        })
      }
    })

  }
  getPartyId() {
    this.spinner.show();
    this.timeSheetService.getPartyId().subscribe(res=> {
      this.partyId=res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.partyId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }

}
