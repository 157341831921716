import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';

@Component({
  selector: 'app-create-acc-role',
  templateUrl: './create-acc-role.component.html',
  styleUrls: ['./create-acc-role.component.css']
})
export class CreateAccRoleComponent implements OnInit {
  createbudget:FormGroup;
  activeCategory=2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  varianceReasonId: any;
  varianceReasonIdArray: any[]=[];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  budgetId: any;
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createbudget = this._FormBuilder.group({
        partyId: [''],
        roleId:[''],
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.budgetId = params["budgetId"];

    });
    this.getRoleAllType();
    this. getPartyId();
  }
   
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
     
     onSubmit(): void {

      const requestData = {
        "budgetId": this.budgetId,
        "partyId": this.createbudget.value.partyId,
        "roleTypeId": this.createbudget.value.roleId,
       
      
      }
  
      this.accountsService.postbudgetRole(requestData).subscribe((res: any) => {
      
        if (res.success) {
          this._ToastrService.success("Created Successfully");
          this.spinner.hide();
          this._Router.navigate(['/financial/detail-budget-summary'],{ queryParams: { budgetId:this.budgetId}});
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    
    }
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-budget-summary'],{ queryParams: { budgetId:this.budgetId}});
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
