<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Find Facilities</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords"> Search</span>
                                                    <button type="button" class="btn btn-outline-secondary ml-2" data-toggle="modal" data-target="#exampleModalCenter" >Create New Facilities</button>

                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 75%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>									
        
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Facility ID</label>
                                                                </div>
                
                
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="FacilityID" [(ngModel)]="advanceSearch.FacilityID" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" placeholder="Enter Facility ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Facility Name</label>
                                                                </div>
                
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control" name="FacilityName" [(ngModel)]="advanceSearch.FacilityName" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Facility Name">
                                                                </div>
                
                
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Parent Facility ID</label>
                                                                </div>
                
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control" name="ParentFacilityID" [(ngModel)]="advanceSearch.ParentFacilityID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Parent Facility ID">
                
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1"> Facility Type ID</label>
                                                                </div>
                
                                                                <div class="col-lg-2">
                                                                    <ng-select [items]="facilityType" class="ng-select-width" bindLabel="description" bindValue="facilityTypeId" placeholder="Facility Type ID" [(ngModel)]="advanceSearch.FacilityTypeID" [ngModelOptions]="{standalone:true}">
                                                                    </ng-select>
                
                                                                </div>
                                                            </div>
                                                        </div>
                
                
                
                
                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button" style="    margin-left: -29%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchContacts()">Find</button>&nbsp;
                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                    </div>
                                                

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <!-- <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter">Create Facilities</button>

                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Facility ID</label>
                                                </div>


                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="FacilityID" [(ngModel)]="advanceSearch.FacilityID" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" placeholder="Enter Facility ID">
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Facility Name</label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" name="FacilityName" [(ngModel)]="advanceSearch.FacilityName" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Facility Name">
                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Parent Facility ID</label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" name="ParentFacilityID" [(ngModel)]="advanceSearch.ParentFacilityID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Parent Facility ID">

                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1"> Facility Type ID</label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <ng-select [items]="facilityType" class="ng-select-width" bindLabel="description" bindValue="facilityTypeId" placeholder="Facility Type ID" [(ngModel)]="advanceSearch.FacilityTypeID" [ngModelOptions]="{standalone:true}">
                                                    </ng-select>

                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button" style="    margin-left: -29%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchContacts()">Find</button>&nbsp;
                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                    </div>
                                
                                    </div> -->
                                
                                <br>

                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin" style="margin-left: 11px;">
                                                Search Results
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="dataFacilities" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>



                                                                        Facility ID
                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;"> Facility Name
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>

                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                    <div style="color: white;"> Facility Type
                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Owner
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;">Area
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Description
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>

                                                                    </div>
                                                                </th>





                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'200px'}" routerLink="/assetMaint/fixed-asset/edit-facility" [queryParams]="{facilityId:product.facility.facilityId}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span class="account-button">
                                                                            {{product.facility.facilityId}}

                                                                        </span>
                                                                    </div>
                                                                </td>


                                                                <td [ngStyle]="{'width':'170px'}"> {{product.facility.facilityName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}"> {{product.facility.facilityTypeId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}"> {{product.facility.ownerPartyId}}

                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">

                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.facility.description}}

                                                                </td>



                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> {{editMode?'Update':'Create'}} Facilities</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                            {{editMode?'Update':'Create'}}</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="facilityForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Facility Type ID <span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="facilityType" class="ng-select-width" bindLabel="description" bindValue="facilityTypeId" placeholder="Facility Type ID" formControlName="facilityTypeId">
                                                        </ng-select>
                                                        <small class="validate-field" *ngIf="!!facilityForm.controls.facilityTypeId.invalid && !!facilityForm.controls.facilityTypeId.touched">
                                                Facility Type ID is required!</small>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA" *ngIf="editMode">
                                                        <label for="facilityId">Facility Id:</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="editMode">
                                                        <span style="color: black;">{{facilityId}}</span>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Parent Facility ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="facilityIds" bindLabel="facilityName" placeholder="Parent Facility ID" bindValue="facilityId" formControlName="parentFacilityId"></ng-select>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Owner <span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="partyIds" bindLabel="groupName" bindValue="partyId" placeholder="Owner" formControlName="ownerPartyId"></ng-select>
                                                        <small class="validate-field" *ngIf="!!facilityForm.controls.ownerPartyId.invalid && !!facilityForm.controls.ownerPartyId.touched">
                                                Owner is required!</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Default Weight Unit</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="weightData" class="ng-select-width" placeholder="Default Weight Unit" formControlName="defaultWeightUomId" bindLabel="description" bindValue="uomId">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Default Inventory Item Type</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="inventoryData" class="ng-select-width" placeholder="Default Inventory Item Type" formControlName="defaultInventoryItemTypeId" bindLabel="description" bindValue="inventoryItemTypeId">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Name <span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Name" formControlName="facilityName" aria-describedby="emailHelp">
                                                        <small class="validate-field" *ngIf="!!facilityForm.controls.facilityName.invalid && !!facilityForm.controls.facilityName.touched">
                                                Name is required!</small>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Area</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="facilitySize" aria-describedby="emailHelp" placeholder="Area">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Area Unit</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="areaData" class="ng-select-width" formControlName="facilitySizeUomId" bindLabel="description" bindValue="uomId" placeholder="Area Unit">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Product Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Product Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Default Days To Ship</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="defaultDaysToShip" aria-describedby="emailHelp" placeholder="Default Days To Ship">
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="flexDisplay" style="margin-left: -16%;">
                                            <div class="col-md-11 mr12 main-submit-button"><button _ngcontent-cta-c128="" type="submit" class="btn btn-primary submit-btn update-button" (click)="submit()">{{editMode?'Update':'Create'}}</button></div>
                                            <div class="col-md-1 main-submit-button">
                                            </div>
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>