import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class CreateAccountService {

  constructor(readonly http: HttpClient) { }

  createAccount(req):Observable<any> {
    const createAccountLink = URLS.createAccount;
    return this.http.post(createAccountLink,req)
  }

  
  getAccountById(Id):Observable<any> {
	  const getAccountDetail = URLS.getAccountById.replace(":partyId",Id);
	  return this.http.get(getAccountDetail)
  }

  updateAccount(formData,partyId):Observable<any> {
    const updateAccount = URLS.updateAccount + "?partyId=" + partyId ;
	  return this.http.put(updateAccount,formData)

  }
  getCountry(): Observable<any> {
    return this.http.get(URLS.getCountry)
  }
  getState(data): Observable<any> {
    const getStateList = URLS.getState;
    const getStateLists = getStateList.replace('accState', data.trim())
    return this.http.get(getStateLists)
  }


}
