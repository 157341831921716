<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Job Interview  of {{ this.interviewId}} </span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px;">
                            <li [ngClass]="activeCategory==1?'active':''"><a>
                            Job Interview Information</a></li>

                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group">
                                                <div *ngIf="activeCategory==1">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Job Interview Information
                                                        </h4>
                                                        <span class="edit-buttons hCreate">

                                                    <a  data-toggle="modal" 
                                                        data-target="#exampleModalCenter">Update</a>
                                                    </span>
                                                    </div>
                                                    <div class="panel-body" *ngIf="interviewData">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Job Interview ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{ this.interviewId}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Job Interviewee Party ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{interviewData.jobIntervieweePartyId}}</span>

                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Job Requisition ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{interviewData.jobRequisitionId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Job Interviewer Party ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{interviewData.jobInterviewerPartyId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Job Interview Type ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{interviewData.jobInterviewTypeId}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Grade Secured Enum ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{interviewData.gradeSecuredEnumId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Job Interview Result
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{interviewData.jobInterviewResult}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Job Interview Date</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{interviewData.jobInterviewDate| date :"yyyy-MM-dd HH:mm:ss"}}</span>
                                                                        </div>










                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Job Interview</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid" style="width: 100%;">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!this.show" [ngClass]="activeCategory==1?'active':''"><a>
                                Add new Job interview </a></li>
                                <li *ngIf="this.show" [ngClass]="activeCategory==1?'active':''"><a>
                                    Update Job interview </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" *ngIf="jobInterviewFormService.job_interview_form" [formGroup]="jobInterviewFormService.job_interview_form">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Job Interviewee Party ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="intervieweePartyId" [options]="partyIdArray" filter="true" optionlabel="label" placeholder="Enter Job Interviewee Party ID">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!jobInterviewFormService.job_interview_form.controls.intervieweePartyId.invalid && !!jobInterviewFormService.job_interview_form.controls.intervieweePartyId.touched">  Job Interviewee Party ID   is required!</small>
                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Job Requisition ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="jobRequisitionId" [options]="getJobRequistionArray" filter="true" optionlabel="label" placeholder="Enter Job Requisition ID">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!jobInterviewFormService.job_interview_form.controls.jobRequisitionId.invalid && !!jobInterviewFormService.job_interview_form.controls.jobRequisitionId.touched">  Job Requisition ID  is required!
                                                        </small>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Job Interviewer Party ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="interviewerPartyId" [options]="partyIdArray" filter="true" optionlabel="label" placeholder="Enter Job Interviewer Party ID">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!jobInterviewFormService.job_interview_form.controls.interviewerPartyId.invalid && !!jobInterviewFormService.job_interview_form.controls.interviewerPartyId.touched">   Job Interviewer Party ID is required!</small>

                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Job Interview Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="jobArray" formControlName="interviewTypeId" optionlabel="label" placeholder="Enter Job Interview Type ID ">

                                                        </p-dropdown>


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Grade Secured Enum ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="enumArray" formControlName="gradeSecuredEnum" optionlabel="label" placeholder="Enter Grade Secured Enum ID ">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Job Interview Result</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="resultStatus" formControlName="interviewResult" optionlabel="label" placeholder="Enter Job Interview Result">

                                                        </p-dropdown>


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Job Interview Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="interviewDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last name">
                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>



                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 7%;">
                                         
                                            <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn mr-2">Update</button>


                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->

<ngx-spinner></ngx-spinner>