<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/financial/payable/credit-memo">Credit Memo </span>
                <span class="color-gry cursor-pointer" routerLink="/financial/payable/credit-memo/summary-credit-memo"
                    [queryParams]="{invoiceId:invoiceId}">>Credit Memo Summary </span>
                <span class="color-black pl-1"> > {{editMode ? 'Update':'Create'}} Invoice Term </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <div class="panel panel-default">
                                                <div class="panel-heading" role="tab" id="headingOne">
                                                    <h4 class="panel-title">
                                                        <a role="button" data-toggle="collapse" data-parent="#accordion"
                                                            href="#collapseOne" aria-expanded="true"
                                                            aria-controls="collapseOne">
                                                            {{editMode ? 'Update':'Create'}} Invoice Term
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel"
                                                    aria-labelledby="headingOne">
                                                    <div class="panel-body">
                                                        <form [formGroup]="invoiceTermForm">
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="invoiceTermId">Invoice Term
                                                                            ID</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="invoiceTermId">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="partyId">Term Type ID</label>
                                                                            <p-dropdown filter="true"
                                                                                [options]="invoiceTypeIds"
                                                                                formControlName="termTypeId"
                                                                                optionlabel="label"
                                                                                placeholder="Select Term Type Id">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="itemNo">Item No</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="invoiceItemSeqId">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="termValue">Term Value</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="termValue">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="termDays">Term Days</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="termDays">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="termValue">Text Value</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="textValue">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="termValue">Description</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="description">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="partyId">Uom</label>
                                                                            <p-dropdown filter="true" [options]="uomIds"
                                                                                optionlabel="label"
                                                                                formControlName="uomId"
                                                                                placeholder="Select Term Type Id">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="main-submit-button">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="submit()">{{editMode ?'Update':'Submit'}}</button>
                                                                <button class="btn btn-danger cursor-pointer ml-2"
                                                                    (click)="reset()">Cancel</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 