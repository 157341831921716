import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../../accounting-ap/accounting-ap.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-create-new-commission-invoice',
    templateUrl: './create-new-commission-invoice.component.html',
    styleUrls: ['./create-new-commission-invoice.component.css']
})
export class CreateNewCommissionComponent implements OnInit {
    commissionInvoiceForm: FormGroup;
    partyIds: any[];
    activeCategory = 2;
    currencyUomIds: any[];
    array = [
        {
            label: 'Your Company Name',
            value: 'Company'
        }
    ];
    invoiceId: string;
    editMode: boolean;
    invoiceCommission: any;
    constructor(
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        readonly _AccountingApService: AccountingApService,
        readonly _ToastrService: ToastrService,
        readonly  _Router: Router,
        readonly _GlobalResourceService: GlobalResourceService
    ) {
        this.commissionInvoiceForm = this._FormBuilder.group({
            currencyUomId: [''],
            description: [''],
            dueDate: [''],
            invoiceDate: [''],
            invoiceMessage: [''],
            partyId: ['Company'],
            partyIdFrom: [''],
            referenceNumber: ['']
        });
        this.invoiceId = '';
        this.editMode = false;
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.invoiceId = this._ActivatedRoute.snapshot.queryParams.invoiceId;
        this.getPartyIds();
        this.getCurrency();
        if (this.invoiceId) {
            this.editMode = true;
            this._AccountingApService.getCommissionInvoiceById(this.invoiceId)
                .then(data => {
                    this.invoiceCommission = data.data;
                    this.commissionInvoiceForm.patchValue({
                        ...data.data, ...{
                            invoiceDate: data.data.invoiceDate ? new Date(data.data.invoiceDate) : '',
                            dueDate: data.data.dueDate ? new Date(data.data.dueDate) : ''
                        }
                    });
                });
        }
    }
    reset(): void {
        this.commissionInvoiceForm.reset();
        this._Router.navigate(['/financial/payable/commission-invoice']);
    }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getSupplierList()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
           
    }
    getCurrency(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencyUomIds = data.data.currencyList.map(value => {
                    return {
                        label: value.uomId,
                        value: value.uomId
                    };
                });
            });
          
    }
    update(): void {
        this.spinner.show();
        this._AccountingApService.updateInvoice(this.invoiceId, {
            ...this.invoiceCommission, ...this.commissionInvoiceForm.value, ...{
                dueDate: this.commissionInvoiceForm.value.dueDate ? moment(this.commissionInvoiceForm.value.dueDate).format('YYYY-MM-DD HH:mm:ss') : '',
                invoiceDate: this.commissionInvoiceForm.value.invoiceDate ? moment(this.commissionInvoiceForm.value.invoiceDate).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.commissionInvoiceForm.reset();
                    this._Router.navigate(['/financial/payable/commission-invoice']);
                }
            });
         
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            this._AccountingApService.createCommissionInvoice({
                ...this.commissionInvoiceForm.value, ...{
                    dueDate: this.commissionInvoiceForm.value.dueDate ? moment(this.commissionInvoiceForm.value.dueDate).format('YYYY-MM-DD HH:mm:ss') : '',
                    invoiceDate: this.commissionInvoiceForm.value.invoiceDate ? moment(this.commissionInvoiceForm.value.invoiceDate).format('YYYY-MM-DD HH:mm:ss') : ''
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.commissionInvoiceForm.reset();
                        this._Router.navigate(['/financial/payable/commission-invoice']);
                    }
                });
        }
       
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}