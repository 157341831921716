import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { DropdownModule } from 'primeng/dropdown';
import { OrderManagementService } from '../order-management.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { ToastrService } from 'ngx-toastr';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-sales-order-additional-partys',
  templateUrl: './sales-order-additional-party.component.html',
  styleUrls: ['./sales-order-additional-party.component.css']
})
export class SalesOrderAdditionalPartyComponent implements OnInit {
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  activeCategory = 1;
  config: NgWizardConfig = {
    selected: 7,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],
    }
  };

  partyIds: any[];
  isParty: boolean;
  isRole: boolean;
  additionalPartyForm: {
    additionalPartyId: string,
    additionalRoleTypeId: string
  };
  roleValue = [
    {
      label: 'EMPLOYEE',
      value: 'EMPLOYEE'
    },
    {
      label: 'Not Applicable',
      value: '_NA_'
    }];
  constructor(
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _OrderManagementService: OrderManagementService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService
  ) {
    this.isParty = false;
    this.isRole = false;
    this.partyIds = [];
    this.additionalPartyForm = {
      additionalPartyId: '',
      additionalRoleTypeId: ''
    };
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getPartyIds();
  }

  finalizeOrder(): void {
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      finalizeReqAdditionalParty: false,
      finalizeMode: 'addpty'
    })


      .then(data => {
        if (data.success) {
          this.router.navigate(['/order/sales-order-confirmation']);
        }
      });
  }
  setPartyId(event: any): void {
    this.isParty = event;
    this.isRole = event;
  }

  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
     
  }
  addAdditionalParty(): void {
    this.spinner.show();
    this._OrderManagementService.addRemoveAdditionalParty(true, {
      additionalPartyId: this.additionalPartyForm.additionalPartyId,
      additionalRoleTypeId: [this.additionalPartyForm.additionalRoleTypeId]
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created');
          this.spinner.hide();
        }
      });
      this.spinner.hide();
  }
  removeAdditionParty(additionalPartyId: string, additionalRoleTypeId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._OrderManagementService.addRemoveAdditionalParty(false, {
          additionalPartyId, additionalRoleTypeId: [additionalRoleTypeId]
        })
          .then(data => {
            if (data.success) {
            
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  
  }
}
