
<div class="container-fluid main-container-wrapper">
    <br>
    <div class="row" style="    margin-right: 0px!important;
    margin-left: 0px!important;">
        <div class="w3-card-4 classCard" style="width: 100%!important">
        <div class="col-12">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                  <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                    Add Related Account     </a></li>
                </ul>
              </div>
            <div class="panel-group">

                <div *ngIf="activeCategory==1">
                   
                      <div class="panel-body">
                          <form [formGroup]="fileUploadForm">
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-12">
                                <div class="row">
                                 
                                    <div class="col-lg-3 form-group rightForm">
                                        <label for="exampleInputEmail1">Customer ID </label>
                                    </div>
                                    <div class="col-lg-2">
                                        <p-dropdown formControlName="accountPartyId" [options]="accountArray" optionlabel="label" id="exampleInputEmail1" filter="true" placeholder="Select Customer ID"></p-dropdown>
                                    </div>
                                    <div class="col-lg-3 form-group rightFormA">
                                        <label for="exampleInputEmail1">Comments</label>
                                    </div>
                                    <div class="col-lg-2">
                                        <textarea id="w3review" formControlName="comments"  rows="8" cols="50">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                         

                           
                        </div>
                        <div class="proper">
                        <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                        <button type="submit" (click)="navigate()" class="btn btn-danger">Cancel</button>
                        </div>
                        <br>
                    </form>
                    </div>
                  </div>
                  </div>
                  </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 

