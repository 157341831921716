<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Agreement</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCard w-100">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import  create-lead font-medium mb-0 suppliers-wrapper">
                                    <p class="h-text">Agreement Details</p>
                                </div>
                                <div class="create-new-leade mt-2">
                                    <div class="m-3">
                                      
                                         
                                           <div class="w3-card-4 classCardView w-100 mt-2">
                                                <div class="header-tabs" >
                                                    <h4 class="common-styling hleft">
                                                        Agreements
                                                        <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                            (click)="resetAgree()" data-toggle="modal"
                                                            data-target="#agreementPopup">Create New Agreement</button>
                                                    </h4>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="aggreementsSupplierPartner"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    Agreement
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Status
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Thru
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">

                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">

                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.agreementId}} </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.statusId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.fromDate | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.thruDate | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    data-toggle="modal"
                                                                                    data-target="#agreementPopup"
                                                                                    (click)="updateAgreement(product)">Update</button>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="deleteAgreementsSupplierPartner(product)">Delete</button>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data"> View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 

                                    </div>

                                 
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="editPopup" tabindex="-1" role="dialog" aria-labelledby="editPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Edit Partner</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Edit Partner</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editPartner">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Partner Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="partnerName"
                                                            placeholder="Enter Partner Name">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Local Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="localName"
                                                            placeholder="Enter Local Name">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Site Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="siteName"
                                                            placeholder="Enter Site Name">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Annual Revenue</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="annualRevenue"
                                                            placeholder="Enter Annual Revenue">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Preferred Currency</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="currencyList" formControlName="preferredCurrency"
                                                            placeholder="Enter Preferred Currency">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Industry</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="industryListArray" formControlName="industry"
                                                            placeholder="Enter Industry">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Number of Employees</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="numberOfEmployees"
                                                            placeholder="Enter Number of Employees">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Ownership</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="ownerShipArray" formControlName="ownership"
                                                            placeholder="Enter Ownership">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">SIC Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="sitCode"
                                                            placeholder="Enter SIT Code">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Ticker Symbol</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="tickerSymbol"
                                                            placeholder="Enter Ticker Symbol">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="description"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Important Note</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="note"
                                                            placeholder="Enter Important Note">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="updatePartner()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addressPopup" tabindex="-1" role="dialog" aria-labelledby="addressPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Address</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Address</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAddress">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Purpose</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                        [options]="postalAddressPurposeArray"    formControlName="purpose" placeholder="Enter Purpose">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">To Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="toName"
                                                            placeholder="Enter To Name">
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Attention Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="attentionName"
                                                            placeholder="Enter Attention Name">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Address Line 1</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="addressLine1"
                                                            placeholder="Enter Address Line 1">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                   
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Address Line 2</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="addressLine2"
                                                            placeholder="Enter Address Line 2">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">City</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="city"
                                                            placeholder="Enter City">
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">State/Province</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                        [options]="stateArray"    (ngModelChange)="onStateChange($event)"  formControlName="state" placeholder="Enter State">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                        [options]="array"  (ngModelChange)="onCountryChange($event)"  formControlName="country" placeholder="Enter Country">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Zip/Postal Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="postalCode"
                                                            placeholder="Enter Postal Code">
                                                    </div>
                                                    <div class="col-lg-1">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="postalCodeExt"
                                                            placeholder="Enter Postal Code Ext">
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Directions</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="directions"
                                                            placeholder="Enter Directions">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Allow Solicitations</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                           [options]="yesNoArray" formControlName="solicitation"
                                                            placeholder="Enter Allow Solicitations">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div class="col-lg-9  main-submit-button">
                                                <button type="submit" *ngIf="!this.showPostalAddress"
                                                   (click)="createPostalAddressPartner()" class="btn btn-secondary submit-btn">Create</button>
                                                   <button type="submit" *ngIf="this.showPostalAddress"
                                                   (click)="UpdatePostalAddressPartner()" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="phonePopup" tabindex="-1" role="dialog" aria-labelledby="phonePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Phone Number</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Phone Number</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPhone">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Purpose</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                          [options]="phoneNumberPurposeArray"  formControlName="purpose" placeholder="Enter Purpose">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Phone Number</label>
                                                    </div>
                                                    <div class="col-lg-1">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                          placeholder="Enter Country Code"  aria-describedby="emailHelp" formControlName="phone1">
                                                    </div>
                                                    <div class="col-lg-1">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                        placeholder="Enter Area Code"    aria-describedby="emailHelp" formControlName="phone2">
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                        placeholder="Enter Contact Number"    aria-describedby="emailHelp" formControlName="phone3">
                                                    </div>
                                                    <div class="col-lg-1 form-group classInput">
                                                        <label for="exampleInputEmail1"
                                                            style="display: contents;">ext</label>
                                                    </div>
                                                    <div class="col-lg-1">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                        placeholder="Enter Ext" aria-describedby="emailHelp" formControlName="ext">
                                                    </div>
                                                    <div class="col-lg-4"></div>
                                                    <div class="col-lg-2"></div>
                                                    <div class="col-lg-10">
                                                        <span>[Country Code] [Area Code] [Contact Number] [ext]</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Person To Ask For</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="personToAsk"
                                                            placeholder="Enter Person To Ask For">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Allow Solicitation</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                          [options]="yesNoArray"  formControlName="solicitation"
                                                            placeholder="Enter Allow Solicitation">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" *ngIf="!this.showTelecomNumber"
                                                  (click)="createPhoneNumberPartner()"  class="btn btn-secondary submit-btn">Create</button>
                                                  <button type="submit" *ngIf="this.showTelecomNumber"
                                                  (click)="UpdatePhoneNumberPartner()"  class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="emailPopup" tabindex="-1" role="dialog" aria-labelledby="emailPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Email</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Email</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addEmail">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Purpose</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                         [options]="emailAddressPurposeArray"   formControlName="purpose" placeholder="Enter Purpose">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Email Address</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="email"
                                                            placeholder="Enter Email Address">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Allow Solicitaion</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                       [options]="yesNoArray"    formControlName="solicitation"
                                                            placeholder="Enter Allow Solicitaion">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4  main-submit-button">
                                                <button type="submit"
                                                *ngIf="!this.showEmail"  class="btn btn-secondary submit-btn" (click)="createEmailAddressPartner()">Create</button>
                                                <button type="submit"
                                                *ngIf="this.showEmail"  class="btn btn-secondary submit-btn" (click)="UpdateEmailAddressPartner()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="urlPopup" tabindex="-1" role="dialog" aria-labelledby="urlPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Web URL</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Web URL</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addUrl">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Purpose</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                        [options]="WebUrlPurposeArray"    formControlName="purpose" placeholder="Enter Purpose">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Web/URL Address</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="url"
                                                            placeholder="Enter Web/URL Address">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Allow Solicitaion</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                          [options]="yesNoArray"  formControlName="solicitation"
                                                            placeholder="Enter Allow Solicitaion">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4  main-submit-button">
                                                <button type="submit"
                                                  *ngIf="!this.showWebUrl"  class="btn btn-secondary submit-btn" (click)="createWebUrlPartner()">Create</button>
                                                  <button type="submit"
                                                  *ngIf="this.showWebUrl"  class="btn btn-secondary submit-btn" (click)="UpdateWebUrlPartner()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="skypePopup" tabindex="-1" role="dialog" aria-labelledby="skypePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Skype Contact</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Skype Contact</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addSkype">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Skype</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="skype"
                                                            placeholder="Enter Skype">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Allow Solicitaion</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                        [options]="yesNoArray"     formControlName="solicitation"
                                                            placeholder="Enter Allow Solicitaion">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4  main-submit-button">
                                                <button type="submit"
                                                *ngIf="!showSkype"    (click)="createSkypeContactPartner()"    class="btn btn-secondary submit-btn">Create</button>

                                                <button type="submit"
                                                *ngIf="showSkype"  (click)="UpdateSkypeContactPartner()"  class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="agreementPopup" tabindex="-1" role="dialog" aria-labelledby="agreementPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Agreement</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Agreement</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAgreement">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Agreement Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            name="agreementDate" formControlName="agreementDate"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Agreement Date">
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hour"
                                                            optionlabel="label" formControlName="hour"> :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minute"
                                                            optionlabel="label" formControlName="minute">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="amPm"
                                                            [options]="amPM" name="amPm" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Party ID To</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="partyTo"
                                                            placeholder="Enter Party ID To">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            name="fromDate" formControlName="fromDate"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hourFrom"
                                                            optionlabel="label" formControlName="hourFrom"> :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minuteFrom"
                                                            optionlabel="label" formControlName="minuteFrom">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="amPmFrom"
                                                            [options]="amPM" name="amPmFrom" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Thru Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            name="thruDate" formControlName="thruDate"
                                                            aria-describedby="emailHelp" placeholder="Enter Thru Date">
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hourThru"
                                                            optionlabel="label" formControlName="hourThru"> :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minuteThru"
                                                            optionlabel="label" formControlName="minuteThru">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="amPmThru"
                                                            [options]="amPM" name="amPmThru" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Agreement Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="agreement"
                                                            placeholder="Enter Agreement Name">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Full Text</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="fullText"
                                                            placeholder="Enter Full Text">
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="col-lg-7 main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showAgree"
                                                    (click)="createAgreementsSupplierPartner()">Create</button>

                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showAgree"
                                                    (click)="updateAgreementsSupplierPartner()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="bookmarkPopup" tabindex="-1" role="dialog" aria-labelledby="bookmarkPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Bookmark URL</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Bookmark URL</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addBookmark">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Classification</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="ClassificationBookmarkArray"
                                                            formControlName="classification"
                                                            placeholder="Enter Classification">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">URL Address</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="url"
                                                            placeholder="Enter URL Address" value="http://">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="description"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showBookmark"
                                                    (click)="createBookMarksUrlPartner()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showBookmark"
                                                    (click)="UpdateBookMarksUrlPartner()">Upadte</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="filePopup" tabindex="-1" role="dialog" aria-labelledby="filePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Upload File</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton9>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Upload File</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="fileUpload">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Classification</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="ClassificationBookmarkArray"
                                                            formControlName="classification"
                                                            placeholder="Enter Classification">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Upload File</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="file" class="form-control" id="exampleInputEmail1"
                                                            (change)="fileProgressCom($event)"
                                                            aria-describedby="emailHelp" formControlName="file"
                                                            placeholder="Enter Upload File">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="description"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showUpload"
                                                    (click)="createBookmarkUpload()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showUpload"
                                                    (click)="updateBookmarkUpload()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="notesPopup" tabindex="-1" role="dialog" aria-labelledby="notesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Note</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton10>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Note</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addNote">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Note</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="note"
                                                            placeholder="Enter Note">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createNotePartner()">Create</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="logTaskPopup" tabindex="-1" role="dialog" aria-labelledby="logTaskPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">{{showLogCall ? 'Update Task ': 'Create Task'}} </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton11>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>{{showLogCall ? 'Update Log Task' : 'Create Log Task'}}

                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="logTaskForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        Phone Call
                                                        <!-- <input type="text" class="form-control" id="exampleInputEmail1"
                                                         aria-describedby="emailHelp" formControlName="Type" 
                                                         placeholder="Enter Type"> -->
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Inbound/Outbound</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="OutboundArray" formControlName="Outbound"
                                                            placeholder="Enter Inbound/Outbound">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Internal Party</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="InternalParty"
                                                            placeholder="Enter Internal Party">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">External Party</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="ExternalParty"
                                                            placeholder="Enter External Party">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Opportunity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="SalesOpportunityArray"
                                                            formControlName="Opportunity"
                                                            placeholder="Enter Opportunity">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Case</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="CasesArray" formControlName="Case"
                                                            placeholder="Enter Case">
                                                        </p-dropdown>

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Subject </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Subject"
                                                            placeholder="Enter Subject	">

                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Message</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Message"
                                                            placeholder="Enter Message">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Start Date &amp; Time</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="StartDateandTime"
                                                            placeholder="Enter Start Date and Time">
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hourFrom"
                                                            optionlabel="label" formControlName="hourFrom"> :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minuteFrom"
                                                            optionlabel="label" formControlName="minuteFrom">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="amPmFrom"
                                                            [options]="amPM" name="amPmFrom" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Duration"
                                                            placeholder="Enter Duration">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showLogCall"
                                                    (click)="createLogTaskCallPartner()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showLogCall"
                                                    (click)="UpdateLogTaskCallPartner()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="logEmailPopup" tabindex="-1" role="dialog" aria-labelledby="logEmailPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> {{showLogEmail ? 'Update Email' : 'Create Email'}} </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton12>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>
                                        {{showLogEmail ? 'Update Log Email' : 'Create Log Email'}}

                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="logEmailForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        Email
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Inbound/Outbound</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="OutboundArray" formControlName="Outbound"
                                                            placeholder="Enter Inbound/Outbound">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Internal Party</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="InternalParty"
                                                            placeholder="Enter Internal Party">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">External Party</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="ExternalParty"
                                                            placeholder="Enter External Party">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Opportunity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="SalesOpportunityArray"
                                                            formControlName="Opportunity"
                                                            placeholder="Enter Opportunity">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Case</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="CasesArray" formControlName="Case"
                                                            placeholder="Enter Case">
                                                        </p-dropdown>

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Subject </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Subject"
                                                            placeholder="Enter Subject	">

                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Message</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Message"
                                                            placeholder="Enter Message">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Start Date and Time</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="StartDateandTime"
                                                            placeholder="Enter Start Date and Time">

                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hourEnd"
                                                            optionlabel="label" formControlName="hourFrom"> :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minuteEnd"
                                                            optionlabel="label" formControlName="minuteFrom">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="amPmFrom"
                                                            [options]="amPM" name="amPmEnd" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Duration"
                                                            placeholder="Enter Duration">

                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!showLogEmail"
                                                    (click)="createLogTaskEmailPartner()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="showLogEmail"
                                                    (click)="UpdateLogTaskEmailPartner()">Update</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="eventPopup" tabindex="-1" role="dialog" aria-labelledby="eventPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> {{showActivityEvent ? 'Update Event' :'Create Event' }} </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton13>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a> {{showActivityEvent ? 'Update Event' :'Create Event' }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="eventForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Name"
                                                            placeholder="Enter Name">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Security</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="securityArray" formControlName="Security"
                                                            placeholder="Enter Security">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Related Party</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="RelatedParty"
                                                            placeholder="Enter Related Party">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Opportunity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="SalesOpportunityArray"
                                                            formControlName="Opportunity"
                                                            placeholder="Enter Opportunity">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Case</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="CasesArray" formControlName="Case"
                                                            placeholder="Enter Case">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Scheduled Start</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="StartDateandTime"
                                                            placeholder="Enter Scheduled Start">
                                                    </div>

                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hour"
                                                            optionlabel="label" formControlName="StartDateandHour">
                                                            :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minute"
                                                            optionlabel="label" formControlName="StartDateMinutes">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="StartDateAmPm"
                                                            [options]="amPM" name="amPm" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">End Date and Time</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="CompletionDateandTime"
                                                            placeholder="Enter End Date and Time">
                                                    </div>

                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hour"
                                                            optionlabel="label" formControlName="CompletionDateHour"> :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minute"
                                                            optionlabel="label" formControlName="CompletionDateMinutes">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="CompletionAmPm"
                                                            [options]="amPM" name="amPm" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Duration"
                                                            placeholder="Enter Duration">

                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Location</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Location"
                                                            placeholder="Enter Location">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Description"
                                                            placeholder="Enter Description">

                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Availability</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="AvailabilityArray" formControlName="Availability"
                                                            placeholder="Enter Availability">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Ignore Scheduling
                                                            Conflicts</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="yesNoArray"
                                                            formControlName="IgnoreSchedulingConflicts"
                                                            placeholder="Enter Ignore Scheduling Conflicts">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showActivityEvent"
                                                    (click)="CreatePendingActivityEventPartner()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showActivityEvent"
                                                    (click)="UpdatePendingActivityEventPartner()">Update</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="taskPopup" tabindex="-1" role="dialog" aria-labelledby="taskPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">{{ showActivityTask ? 'Update Task':'Create Task' }}</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton14>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>{{ showActivityTask ? 'Update Task':'Create Task' }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="taskForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Subject</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Subject"
                                                            placeholder="Enter Subject">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Security</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="securityArray" formControlName="Security"
                                                            placeholder="Enter Security">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Related Party</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="RelatedParty"
                                                            placeholder="Enter Related Party">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Opportunity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="SalesOpportunityArray"
                                                            formControlName="Opportunity"
                                                            placeholder="Enter Opportunity">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Case</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="CasesArray" formControlName="Case"
                                                            placeholder="Enter Case">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Purpose</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="PurposeArray" formControlName="Purpose"
                                                            placeholder="Enter Purpose">
                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Start Date and Time</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="StartDateandTime"
                                                            placeholder="Enter Start Date and Time">
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hour"
                                                            optionlabel="label" formControlName="StartDateandHour">
                                                            :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minute"
                                                            optionlabel="label" formControlName="StartDateMinutes">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="StartDateAmPm"
                                                            [options]="amPM" name="amPm" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">End Date and Time</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="CompletionDateandTime"
                                                            placeholder="Enter End Date and Time">
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hour"
                                                            optionlabel="label" formControlName="CompletionDateHour"> :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minute"
                                                            optionlabel="label" formControlName="CompletionDateMinutes">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="CompletionAmPm"
                                                            [options]="amPM" name="amPm" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Duration"
                                                            placeholder="Enter Duration">

                                                    </div>

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Description"
                                                            placeholder="Enter Description">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Availability</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="AvailabilityArray" formControlName="Availability"
                                                            placeholder="Enter Availability">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Ignore Scheduling
                                                            Conflicts</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="yesNoArray"
                                                            formControlName="IgnoreSchedulingConflicts"
                                                            placeholder="Enter Ignore Scheduling Conflicts">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showActivityTask"
                                                    (click)="createPendingActivityTaskPartner()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showActivityTask"
                                                    (click)="UpdatePendingActivityTaskPartner()">Update</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>