import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Injectable({
    providedIn: 'root'
})
export class FeaturesService {
    constructor(
        readonly _HttpClient: HttpClient,
        readonly _ApiService: ApiService
    ) { }
    // feature category
    createFeatureCategory(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory`, formData);
    }
    updateFeatureCategory(formData: any, productFeatureCategoryId: string): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/${productFeatureCategoryId}`, formData);
    }
    getFeatureCategories(pagination: { pageNo: number, pageSize: number }, search: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}&search=${search}`);
    }
    getFeatureCategoriesById(pagination: { pageNo: number, pageSize: number }, productFeatureCategoryId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/${productFeatureCategoryId}?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    getFeatureCategoryById(productFeatureCategoryId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/featureCategory/${productFeatureCategoryId}`);
    }
    getFeatureParentCategory(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/parentCategory`);
    }
    getFeatureCategoriesList(pagination: { pageNo: number, pageSize: number }, searchFilter: { description: string, descriptionSearchType: string, productFeatureCategoryId: string, productFeatureCategoryIdSearchType: string }): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/productCategoryFeature/productFeatureCategory?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter);
    }
    // Detailed summary feature category

    createFeatureMaintenance(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/feature`, formData);
    }
    updateFeatureMaintenance(formData: any, productFeatureId: string): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/${productFeatureId}`, formData);
    }
    getFeatureMaintenance(featureMaintenanceId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/${featureMaintenanceId}`);
    }
    getPriceType(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/price/productPriceType`);
    }
    getCurrency(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/resources/currency`);
    }
    createPrice(formData: any, featureMaintenanceId: string): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/price/${featureMaintenanceId}`, formData);
    }
    updatePrice(formData: any, featureMaintenanceId: string): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/price/${featureMaintenanceId}`, formData);
    }
    getPrice(featureMaintenanceId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/price/${featureMaintenanceId}`);
    }
    deletePrice(featureMaintenanceId: string, fromDate: string, currencyId: string, priceTypeId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/price/${featureMaintenanceId}/${priceTypeId}/${fromDate}/${currencyId}`);
    }
    // feature Type 

    getFeatureType(pagination: { pageNo: number, pageSize: number }, searchFilter: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureType/search?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter);
    }
    createFeatureType(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureType`, formData);
    }
    updateFeatureType(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureType`, formData);
    }
    getProductParentType(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureType/productParentType`);
    }
    deleteFeatureType(featureId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureType/${featureId}`);
    }
    getFeatureTypeById(featureId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureType/${featureId}`);
    }

    // feature Groups 
    getFeatureGroup(pagination: { pageNo: number, pageSize: number }, search: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureGroup?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}&search=${search}`);
    }
    createFeatureGroup(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureGroup`, formData);
    }
    updateFeatureGroup(formData: any, featureGroupId: string): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureGroup/${featureGroupId}`, formData);
    }
    updateProductEmp(formData: any, productFeatureGroupId: string): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureGroupAppl/${productFeatureGroupId}`, formData);
    }
    productFeatureGroupApplication(featureGroupId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureGroupAppl/${featureGroupId}`);
    }
    deleteFeatureGroupApplication(fromDate: string, featureGroupId: string, featureId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureGroupAppl/${featureGroupId}/${featureId}/${fromDate}`);
    }
    createFeatureGroupApplication(formData: any, productFeatureGroupId: string): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureGroupAppl/${productFeatureGroupId}`, formData);
    }
    updateFeatureGroupApplication(formData: any, productFeatureGroupId: string): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureGroupAppl/${productFeatureGroupId}`, formData);
    }
    applyFeatureFromCategory(productFeatureGroupId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureGroupAppl/${productFeatureGroupId}/applyFeatures`, formData);
    }
    getFeatureFormCategory(productFeatureGroupId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/category/${productFeatureGroupId}`);
    }
    getFeatureId(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/productFeatureIds`);
    }
}