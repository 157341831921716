import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-detail-budget-summary',
  templateUrl: './detail-budget-summary.component.html',
  styleUrls: ['./detail-budget-summary.component.css']
})
export class DetailBudgetSummaryComponent implements OnInit {
  activeCategory = 2;
  createbudget:FormGroup;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;

  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  varianceReasonId: any;
  varianceReasonIdArray: any[]=[];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  id: string;
  BudgetType: any;
  BudgetTypeArray: any[]=[];
  timePerod: any;
  timePerodArray: any[]=[];
  budgetId: any;
  products3: any;
  paymentPartyGroup: any;
  downloading: boolean;
  
  FinancialTransaction: any[] = [];

  budgetHeader: any;
  budgetStatus: any;
  budgetRole: any;
  budgetItem: any;
  budgetReview: any;
  budgetItemList: any;
  budgetRoleList: any;
  budgetReviewList: any;
  status: string;
  createbudget1:FormGroup;
  BudgetItemTypeId: any;
  BudgetItemTypeIdArray: any[]=[];
  budgetItemSeqId: any;
  createbudget2:FormGroup;
  allrole: any;
  allroleArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  createbudget3:FormGroup;
  ReviewResultTypes: any;
  ReviewResultTypesArray: any[]=[];
  reviewDate: string;



  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,

    readonly toastr: ToastrService,
  
    readonly router: Router,
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly _ToastrService: ToastrService,
    readonly addSkillService: AddSkillService,
    readonly datePipe: DatePipe,
    ) {
      this.createbudget3 = this._FormBuilder.group({
        partyId: [''],
        reviewReview:[''],
        reviewDate:['']
       
        
      });
      this.createbudget2 = this._FormBuilder.group({
        partyId: [''],
        roleId:[''],
       
        
      });

      this.createbudget1 = this._FormBuilder.group({
        budgetId: [''],
        amount:[''],
        Purpose:[''],
        Justification:[''],
        
      });
      this.createbudget = this._FormBuilder.group({
        budgetId: [''],
        customTime:[''],
        comment:['']
       
        
      });
     }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getPartyId1();
    this.budgetReviewResultTypes();

    this.getRoleAllType();
    this. getPartyId();

    this.getBudgetItemTypeId();
    if(this.budgetItemSeqId){
      this.show=true;
      this.getbudgetsItemEdit();
     
          }else{
            this.show=false;
          }


    this._ActivatedRoute.queryParams.subscribe(params => {
      this.budgetId = params["budgetId"];
    });
    this.getBudgetOverview();
    this.getBudgetItem();
    this.getbudgetRole();
    this.getbudgetReview();
    this.getBudgetType();
    this.getCustomTimePeriodListDetail();
 
      this.show=true;
     
  }
  budgetPdf(): void {
    this.spinner.show();
    this.accountsService.generateBudgetPdf(this.budgetId)
      .subscribe(value => {
        const blob = new Blob([value], { type: 'application/application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        this.spinner.hide();
        link.download = `${this.budgetId}.pdf`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });

  }
  getPartyId1() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  budgetReviewResultTypes() {
    this.spinner.show();
    this.accountsService.budgetReviewResultTypes().subscribe(res => {
      this.ReviewResultTypes = res.data;
      this.spinner.hide();
      for (const value of this.ReviewResultTypes) {
        this.ReviewResultTypesArray.push({
          label: value.description,
          value: value.budgetReviewResultTypeId
        })
      }
    })
    
  }
  onSubmitNew3() {
    this.spinner.show();
    const reviewDate=this.createbudget3.get('reviewDate').value;
    this.reviewDate=this.datePipe.transform(reviewDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "budgetId": this.budgetId,
      "budgetReviewResultTypeId": this.createbudget3.value.reviewReview,
      "partyId":this.createbudget3.value.partyId,
      "reviewDate":this.reviewDate
    }
    this.accountsService.postbudgetReview(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.getbudgetReview();
        this.closebutton3.nativeElement.click();
        // this._Router.navigate(['/financial/detail-budget-summary'],{ queryParams: { budgetId:this.budgetId}});
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
     
     onSubmitNew2(): void {

      const requestData = {
        "budgetId": this.budgetId,
        "partyId": this.createbudget2.value.partyId,
        "roleTypeId": this.createbudget2.value.roleId,
       
      
      }
  
      this.accountsService.postbudgetRole(requestData).subscribe((res: any) => {
      
        if (res.success) {
          this._ToastrService.success("Created Successfully");
          this.spinner.hide();
          this.getbudgetRole();
        this.closebutton2.nativeElement.click();
          // this._Router.navigate(['/financial/detail-budget-summary'],{ queryParams: { budgetId:this.budgetId}});
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    
    }

  getbudgetsItemEdit() {
    this.spinner.show();
   
    this.accountsService.getbudgetsItemEdit(this.budgetId,this.budgetItemSeqId).subscribe((res: any) => {
     
      this.spinner.hide();
      setTimeout(() => {
     
        const formValue = this.createbudget2;

        formValue.patchValue({
          budgetId: res.data.budgetItemTypeId,
          amount:res.data.amount,
          Purpose:res.data.purpose,
          Justification:res.data.justification,
        })
      
      }, 3000);
    })
  
  }
  onSubmit(): void {

    const requestData = {
      "amount": this.createbudget1.value.amount,
      "budgetId":this.budgetId,
      "budgetItemTypeId":this.createbudget1.value.budgetId,
      "justification": this.createbudget1.value.Justification,
      "purpose":this.createbudget1.value.Purpose,
    
    }

    this.accountsService.postBudgetItem(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.getBudgetItem();
        this.closebutton1.nativeElement.click();
        // this._Router.navigate(['/financial/detail-budget-summary'],{ queryParams: { budgetId:this.budgetId}});
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  getBudgetItemTypeId() {
    this.spinner.show();
    this.accountsService.getBudgetItemTypeId().subscribe((res: any) => {
      this.BudgetItemTypeId = res.data;
      this.spinner.hide();
      for (const value of this.BudgetItemTypeId) {
        this.BudgetItemTypeIdArray.push({
          label: value.description,
          value: value.budgetItemTypeId
        })
      }
    })
  
  }

  getBudgetList() {
    this.spinner.show();
    const req={
      "budgetId":this.budgetId,
      "budgetIdSearchType": "Contains",
      "budgetTypeId": "",
      "comments": "",
      "commentsSearchType": "Contains",
      "customTimePeriodId": "",
      "customTimePeriodIdSearchType": "Contains"
    
    }
    this.accountsService.getBudgetList(this.finSize,req).subscribe((res: any) => {
     
      this.spinner.hide();
      setTimeout(() => {
       
      
        const formValue = this.createbudget;

        formValue.patchValue({
          budgetId: res.data[0].budgetTypeId,
          customTime:res.data[0].customTimePeriodId,
          comment:res.data[0].comments,
        })
      
      }, 3000);
    })
  
  }
  getBudgetType() {
    this.spinner.show();
    this.accountsService.getBudgetType().subscribe((res: any) => {
      this.BudgetType = res.data;
      this.spinner.hide();
      for (const value of this.BudgetType) {
        this.BudgetTypeArray.push({
          label: value.description,
          value: value.budgetTypeId
        })
      }
    })
  
  }
  getCustomTimePeriodListDetail() {
    this.spinner.show();
   this.id=""
    this.accountsService.getCustomTimePeriodListDetail(this.id).subscribe((res: any) => {

      this.timePerod = res.data;
      this.spinner.hide();
      for (const value of this.timePerod) {
        this.timePerodArray.push({
          label:  value.customTimePeriodId,
          value: value.customTimePeriodId
        })
      }


    })
   

  }
  onUpdate(): void {

    const requestData = {
      "budgetId":this.budgetId,
      "budgetTypeId": this.createbudget.value.budgetId,
      "comments": this.createbudget.value.comment,
      "customTimePeriodId": this.createbudget.value.customTime,
  
           
    }

    this.accountsService.updatetBudget(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getBudgetItem();
        this.getBudgetOverview();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
 
  
  cancelSubmit(){
    this.router.navigate(["/financial/budget-list"])
  }  
  removebudgetReview(bid, reveiwResult, party) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.removebudgetReview(this.budgetId, bid, reveiwResult, party).subscribe((res: any) => {
          this.getbudgetReview();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}
    })
  }
  removebudgetRole(pid, rid) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.removebudgetRole(this.budgetId, pid, rid).subscribe((res: any) => {
          this.getbudgetRole();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  deleteBudgetItem(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteBudgetItem(this.budgetId, id).subscribe((res: any) => {
          this.getBudgetItem();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  getbudgetReview() {
    this.spinner.show();

    this.accountsService.getbudgetReview(this.budgetId, this.finSize).subscribe((res: any) => {
      this.budgetReviewList = res.data;

      this.spinner.hide();
    })

  }

  updateBudgetApproved() {
    this.spinner.show();
    this.status = 'BG_APPROVED'
    this.accountsService.updateBudgetStatus(this.budgetId, this.status).subscribe((res: any) => {

      if (res.success) {
        this.getBudgetOverview();
        this.toastr.success("Status Updated Successfully");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });


  }
  updateBudgetReview() {
    this.spinner.show();
    this.status = 'BG_REVIEWED'
    this.accountsService.updateBudgetStatus(this.budgetId, this.status).subscribe((res: any) => {

      if (res.success) {
        this.getBudgetOverview();
        this.toastr.success("Status Updated Successfully");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });


  }
  updateBudgetRejected() {
    this.spinner.show();
    this.status = 'BG_REJECTED'
    this.accountsService.updateBudgetStatus(this.budgetId, this.status).subscribe((res: any) => {

      if (res.success) {
        this.getBudgetOverview();
        this.toastr.success("Status Updated Successfully");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });


  }
  getbudgetRole() {
    this.spinner.show();

    this.accountsService.getbudgetRole(this.budgetId, this.finSize).subscribe((res: any) => {
      this.budgetRoleList = res.data;

      this.spinner.hide();
    })

  }
  getBudgetItem() {
    this.spinner.show();

    this.accountsService.getBudgetItem(this.budgetId, this.finSize).subscribe((res: any) => {
      this.budgetItemList = res.data;

      this.spinner.hide();
    })

  }
  getBudgetOverview() {
    this.spinner.show();

    this.accountsService.getBudgetOverview(this.budgetId).subscribe((res: any) => {
      this.budgetHeader = res.data;
      this.budgetStatus = res.data.budgetStatus;
      this.budgetRole = res.data.budgetRole;
      this.budgetItem = res.data.budgetItem;
      this.budgetReview = res.data.budgetReview;
      this.spinner.hide();
    })

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  createItem() {
    this.router.navigate(["financial/create-acc-item"], { queryParams: { budgetId: this.budgetId } });

  }
  updateBudgetItem(ebudgetItemSeqId) {
    this.router.navigate(["financial/create-acc-item"], { queryParams: { budgetId: this.budgetId, budgetItemSeqId: ebudgetItemSeqId } });

  }
  createRole() {
    this.router.navigate(["financial/create-acc-role"], { queryParams: { budgetId: this.budgetId } });

  }
  createReview() {
    this.router.navigate(["financial/create-acc-review"], { queryParams: { budgetId: this.budgetId } });

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
