import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder ,FormGroup,Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';  import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-internal-requirements-main',
  templateUrl: './internal-requirements-main.component.html',
  styleUrls: ['./internal-requirements-main.component.css']
})
export class InternalRequirementsMainComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 5;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  internalRequirementsForm: FormGroup;
  approvedInternalRequirements: any;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,    private _location: Location,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly mergeContactsService: MergeContactsService,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router
  ) 
  {
    this.internalRequirementsForm=this._FormBuilder.group({
      product:"",
      productionFacility:"",
    })
   }

  ngOnInit(): void {
    this.getApprovedInternalRequirements();
  }
  deleteInternalRequirementPlanning(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "requirementId":product.requirementId 

        }
        this.myContactsService.deleteInternalRequirementPlanning(req).subscribe((res: any) => {
          this.getApprovedInternalRequirements();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getApprovedInternalRequirements() {
    this.spinner.show();
    this.mergeContactsService.getApprovedInternalRequirements().subscribe(res => {
      this.approvedInternalRequirements = res.data[0].getApprovedProductRequirementsByVendor;
      this.spinner.hide();
      
    })
   
  }
  openInventory()
  {
    this._Router.navigate(['/facilities/Planning']);

  }
  openFindRequirements()
  {
    this._Router.navigate(['/facilities/Planning/find-requirements']);

  }
  openRequirements()
  {
    this._Router.navigate(['/facilities/Planning/open-requirements']);

  }
  openProductRequirements()
  {
    this._Router.navigate(['/facilities/Planning/product-requirements']);
    
  }
  openInternalRequirements()
  {
    this._Router.navigate(['/facilities/Planning/internal-requirements']);

  }
  openTransferRequirements()
  {
    this._Router.navigate(['/facilities/Planning/transfer-requirements']);

  }
  openSalesOrderHistory()
  {
    this._Router.navigate(['/facilities/Planning/upload-sales-order-history']);

  }
  openRunMRP()
  {
    this._Router.navigate(['/facilities/Planning/run-mrp']);

  }
  openViewMRP()
  {
    this._Router.navigate(['/facilities/Planning/view-mrp']);

  }
  openConfigureWarehouses()
  {
    this._Router.navigate(['/facilities/Planning/configure-warehouse']);

  }  
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  

}
