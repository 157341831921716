<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Add New Person</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <!-- <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;">Parties
                </span>
                <span class="color-black pl-1"> >Add New Person
                </span>
                <button type="submit" class="btn btn-danger buttonclass" (click)="GoToQuickLink()">Cancel</button>&nbsp;
            </div> -->
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" style="margin-top:1%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                    Add New Person
                                </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                    Update New Person
                                </a></li>
                        </ul>
                    </div>
                    <div class="">
                        <div class="container-fluid">
                            <div class="row" style="margin-bottom: 30%;">
                                <form class="w-100" [formGroup]="createNewPerson">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Party ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Party ID"
                                                    formControlName="partyId">
                                                    <br>
                                                    <label>ID sequence will be generated if empty</label>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Salutation</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Salutation"
                                                    formControlName="saluatation">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">First name<span
                                                        style="color: red !important;">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter First name"
                                                    formControlName="firstName">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Middle Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Middle Initial"
                                                    formControlName="middleName">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Last Name<span
                                                        style="color: red !important;">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Last Name"
                                                    formControlName="lastName">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Personal Title</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Personal Title"
                                                    formControlName="personalTitle">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Suffix</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Suffix"
                                                    formControlName="suffix">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Nickname</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Nickname"
                                                    formControlName="nickname">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">First Name Local</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter First Name Local"
                                                    formControlName="firstNameLocal">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Middle Name Local</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Middle Name Local"
                                                    formControlName="middleNameLocal">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Last Name Local</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Last Name Local"
                                                    formControlName="lastNameLocal">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Other Local</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Other Local"
                                                    formControlName="otherLocal">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Member ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Member ID"
                                                    formControlName="memberId">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Gender</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Gender" [options]="gender"
                                                    formControlName="gender" optionlabel="label">
                                                </p-dropdown>
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Birth Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Birth Date"
                                                formControlName="birthDate" >
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Deceased Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Deceased Date"
                                                formControlName="deceasedDate">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Country Code</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Country Code"
                                                    formControlName="countryCode">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Height</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Height"
                                                    formControlName="height">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Weight</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Weight"
                                                    formControlName="weight">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Mothers Maiden Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Mothers Maiden Name"
                                                    formControlName="mothersMaidenName">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Old Marital Status</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="maritalArray" filter="true" placeholder="Old Marital Status"
                                                formControl="oldMaritalStatus">
                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Marital Status Enum ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Select Maritial Status"
                                                            [options]="maritalStatus" formControlName="maritalStatusEnumId"
                                                            optionlabel="label">
                                                        </p-dropdown>
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Social Security Number</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Social Security Number"
                                                    formControlName="socialSecurity">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Passport Number</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Passport Number"
                                                    formControlName="passportNumber">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Passport Expire Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Passport Expire Date"
                                                    formControlName="passportExpire">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Total Years Work Experience</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Total Years Work Experience"
                                                    formControlName="totalYears">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Comments</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Comments"
                                                    formControlName="comments">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Employment Status Enum ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="AllEmploymentEnumIdArray" filter="true"  optionlabel="label"
                                                 placeholder="Enter Employment Status Enum ID">
                                                </p-dropdown>
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Residence Status Enum ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="AllResidentStatusEnumIdArray"   optionlabel="label"
                                                filter="true" placeholder="Enter Residence Status Enum ID">
                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Occupation</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Occupation"
                                                    formControlName="occupation">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Years With Employer</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Years With Employer"
                                                    formControlName="yearsWith">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Months With Employer</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Months With Employer"
                                                    formControlName="monthsWith">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Existing Customer</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="customerArray" filter="true" placeholder="Enter Existing Customer"
                                                    formControl="customerArray">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Card ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Card ID"
                                                    formControlName="cardId">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Us Citizen</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Us Citizen"
                                                    formControlName="usCitizen">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Ethnicity</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Ethnicity"
                                                    formControlName="ethnicity">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Account No</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Account No"
                                                    formControlName="accountNo">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Billing Rate Level</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Billing Rate Level"
                                                    formControlName="billingRate">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Preferred Currency Uom ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="currencyArray" filter="true" placeholder="Enter Preferred Currency Uom ID"
                                                formControlName="preferredCurrency">
                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Description</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Description"
                                                    formControlName="description">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">External ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter User Name"
                                                    formControlName="externalId">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Status ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="statusIdArray" filter="true" placeholder="Enabled"
                                                    formControlName="statusId">
                                                    </p-dropdown>
                                                </div>
                                               
                                            </div>
                                          </div>
                                       
                                      
                                    </div>
                                </form>
                                <div class="main-submit-button" style="margin-left: -22%;">
                                    <button type="submit"
                                        class="btn btn-secondary submit-btn" (click)="onSubmit()">Save</button>&nbsp;&nbsp;
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>