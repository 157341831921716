import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';   import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-tasks-sprint-backlog',
  templateUrl: './tasks-sprint-backlog.component.html',
  styleUrls: ['./tasks-sprint-backlog.component.css']
})
export class TasksSprintBacklogComponent implements OnInit {
  activeCategory = 1;
  ProductBacklogItemStatusList: any;
  productId: any;
  custRequestId: any;
  total = 0;
  rowNumber = 0;
  pageSize = 10;
  rows = 50;
  EmailsProductBacklogs: any;
  ProductContentList: any;
  constructor(
    readonly spinner: NgxSpinnerService,    
    private _location: Location,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly _Router: Router,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.spinner.hide();
    this.activatedRoute.queryParams.subscribe(params => {

      this.productId = params.productId;
      this.productId = params.productId;
      this.custRequestId = params.custRequestId;

    })
    this.getProductBacklogItemStatusList();
    this.getEmailsProductBacklogs();
    this.getProductOverviewProductContent();
  }
  getProductOverviewProductContent() {

    this.spinner.show();
    this.accountsService.getProductOverviewProductContent(this.productId).subscribe(res => {
      this.ProductContentList = res.data;
      this.spinner.hide();
    })
  }
  getEmailsProductBacklogs() {

    this.spinner.show();
    this.accountsService.getEmailsProductBacklogs().subscribe(res => {
      this.EmailsProductBacklogs = res.data.data;
      this.spinner.hide();
    })
  }
  getProductBacklogItemStatusList() {

    this.spinner.show();
    this.accountsService.getProductBacklogItemStatusList(this.productId, this.custRequestId).subscribe(res => {
      this.ProductBacklogItemStatusList = res.data;
      this.spinner.hide();
    })
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }


  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
