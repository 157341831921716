import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-create-address-match-map',
  templateUrl: './create-address-match-map.component.html',
  styleUrls: ['./create-address-match-map.component.css']
})
export class CreateAddressMatchMapComponent implements OnInit {
  createClassification:FormGroup;
  fileUpload:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[]=[];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  efromDate: any;
  ethruDate: any;
  getthruDate: string;
  fileData: File;
  userPermissions: any;
Hidebtn: boolean=false;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,) { 
      this.contentIds = [];
      this.createClassification = this._FormBuilder.group({
        matchKey: [''],
        matchValue: [''],
        sequenceNum:['']
       
        
      });
    
  }
  ngOnInit(): void {
    
 this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
 if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
    this.fileUpload = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
    });
   
  }

  onSubmit() {
    this.spinner.show();
    let req={
      "mapKey":this.createClassification.value.matchKey,
      "mapValue":this.createClassification.value.matchValue,
      "sequenceNum": this.createClassification.value.sequenceNum
    }
    this.accountsService.createAddressMatchMap(req).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  GoToQuickLink(){
    this._location.back();
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  onUpload(){
    const formData = new FormData();
    formData.append("file",this.fileData);
    this.accountsService.uploadCsvMatch(formData).subscribe((res:any)=>{
      if(res.success) {
        this._ToastrService.success(res.data.responseMessage);
        this._location.back();

      }     },(err)=>{
        this._ToastrService.error(err.message);
     })
  }
}
