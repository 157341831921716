<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
          <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
            <span class="color-black pl-1 titlePanels">Order Overview</span>
            <span>
                 <!-- <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button> -->
             </span> 
         </div>
         
            <div class=" bg-white color-grey">
                <div class="container-fluid">

                </div>

                <div>
                    <div class="container-fluid">
                        <div class="row" >
                          <form class="w-100">
                            
                              <div class="w-100">
                                <div class="panel-group">
                                  <div>
                                    <div style="width: 49.7%; float:left">
                                    <div class="w3-card-4 cardEffect" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Stats
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/main-order">Orders</p>
                                                  </div>
                                                  
                                               
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                   
                                   
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Sales Order
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/create-sales-order"> Create New Sales-Order</p>
                                                  </div>
                                                
                                               
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Return Order

                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/return-order">Find Return</p>
                                                    <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/return-order">Create New Return </span>
                                                  </div>
                                                
                                               
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                  

                                 
                                    
                                      <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Requests
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/requests-order">Find Requests</p>
                                                    <span  routerLink="/order/requests-order" class="color-grey pl-20 col-lg-10 col-12" style="cursor: pointer;    color: #0000FF;" >Create New Requests</span>
                                                  </div>

                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs" >
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Requirements
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/requirements">Requirements                                                    </p>
                                                    <span  routerLink="/order/requirements" class="color-grey pl-20 col-lg-10 col-12" style="cursor: pointer;    color: #0000FF;" >Approve Requirements</span>
                                                  </div>
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/requirements">Find Approved Requirements By Supplier</p>
                                                    <span  routerLink="/order/requirements" class="color-grey pl-20 col-lg-10 col-12" style="cursor: pointer;    color: #0000FF;" >Approved Product Requirements</span>
                                                  </div>

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                 
                                    <div class="w3-card-4 cardEffect mb-5 mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Allocation
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/allocation-plan">Find Allocation Plan</p>
                                                    <span  routerLink="/order/allocation-plan" class="color-grey pl-20 col-lg-10 col-12" style="cursor: pointer;    color: #0000FF;" >Create New Allocation Plan</span>
                                                  </div>
                                                

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                    <br>
                                  
                                   
                                   </div>
                                   
                                   <div style="width: 49.5%; float:right">
                                    <div class="w3-card-4 cardEffect" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Find Order
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/order-management">Find Order Form</p>
                                                  </div>

                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Purchase Order
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/create-purchase-order">Purchase Order Form</p>
                                                  </div>
                                            
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                 
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Quotes

                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/quotations">Find Quotes</p>
                                                    <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/quotations">Create New Quotes </span>
                                                  </div>
                                               
                                               
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                 
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Order List

                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/order-list">Find Order List</p>
                                                  </div>
                                                
                                               
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Reports

                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/reports-order">View Reports</p>
                                                  </div>
                                                 
                                               
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>

                                   
                                  
                                  
                                  </div>
                                   
                                   
                                  
                                
    
                              </div>
                              </div>
                            </div>
    
                          </form>
                        </div>
                      </div>
                  

                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>