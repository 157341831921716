<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Fixed Assest

 
                </span>
                <span class="color-black pl-1" > >Add Fixed Asset Type Gl Mappings

 
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Add Fixed Asset Type Gl Mappings

 
                    </a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createFixedAssest">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                          
                                                    Asset GL account<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Asset GL account" filter="true"
                                            formControlName="assestGlAccount" 
                                            [options]="assetGlAccountIdArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                           
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                               
                                                    Accumulated depreciation GL account<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Accumulated depreciation GL account" filter="true"
                                            formControlName="accumulated"  
                                            [options]="accDepGlAccountIdArray" optionlabel="label">
                                                            
                                            </p-dropdown>
                                           
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                

                                                    Depreciation GL account<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Depreciation GL account" filter="true"
                                            formControlName="depreciation" 
                                            [options]="depGlAccountIdArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                           
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                

                                                    Profit GL account<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Profit GL account" filter="true"
                                            formControlName="profit" 
                                            [options]="profitGlAccountIdArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                           
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                

                                                    Loss GL account<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Loss GL account" filter="true"
                                                formControlName="loss" 
                                                [options]="lossGlAccountIdArray" optionlabel="label" >
                                                                
                                                </p-dropdown>
                                           
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                

                                                    Fixed Asset Type ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Fixed Asset Type ID" filter="true"
                                            formControlName="fixedAssestTypeId"  
                                            [options]="fixedAssetTypeIdArray" optionlabel="label">
                                                            
                                            </p-dropdown>
                                           
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                

                                                    Fixed Asset ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Fixed Asset ID" filter="true"
                                            formControlName="asssestId" 
                                            [options]="fixedAssetIdArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                           
                                            </div>
                                          
                                        </div>
                                    </div>

                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left:68%;">
                               
                                <button type="submit" (click)="onSubmit();"   class="btn btn-secondary submit-btn">Save</button>
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
