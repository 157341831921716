import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
    import { Location } from '@angular/common';
    import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { ProductionService } from '../production.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
@Component({
  selector: 'app-cost-cacls-list',
  templateUrl: './cost-cacls-list.component.html',
  styleUrls: ['./cost-cacls-list.component.css']
})
export class CostCaclsListComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 500;
  pageNo = 1;
  rows = 50;
  search = ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  financialList = [{
    "product": "1"
  }];
  FinAccountTypeList: any;
  FinAccountTypeListArray: any[] = [];
  smallerArray: { label: string; value: string; }[];
  greaterArray: { label: string; value: string; }[];
  ProdCost: any;
  createCost: FormGroup;
  activeCategory = 2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[] = [];
  varianceReasonId: any;
  varianceReasonIdArray: any[] = [];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  budgetId: any;
  personId: any;
  partyIdArray: any[] = [];
  ReviewResultTypes: any;
  ReviewResultTypesArray: any[] = [];
  reviewDate: string;
  currencyArray: any[]=[];
  costCustomMethodID: any;
  costCustomMethodIDArray: any[]=[];
  CalcId: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly toastr: ToastrService,  private _location: Location,
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly productionService:ProductionService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
   
    readonly router: Router) {
      this.createCost = this._FormBuilder.group({
        Description: [''],
        FixedCost: [''],
        VariableCost: [''],
        PerMilliSecond: [''],
        Currency: [''],
        CostCustomMethodID: [''],
  
  
      });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show=false;
     this.getProdCost();
     this.getCurrency();
     this.getInitialDataCost();
  }
  getInitialDataCost() {
    this.spinner.show();
    this.accountsService.getInitialDataCost().subscribe(res => {
      
      this.costCustomMethodID = res.data.costCustomMethodID;
    
      this.spinner.hide();
     
      for (const value of this.costCustomMethodID) {
        this.costCustomMethodIDArray.push({
          label: value.description,
          value: value.customMethodId
        })
      }
     
    })
   
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "costComponentCalcId":"",
      "costCustomMethodId": this.createCost.value.CostCustomMethodID,
      "costGlAccountTypeId":"",
      "currencyUomId": this.createCost.value.Currency,
      "description":this.createCost.value.Description,
      "fixedCost":this.createCost.value.FixedCost,
      "offsettingGlAccountTypeId": "",
      "perMilliSecond": this.createCost.value.PerMilliSecond,
      "variableCost": this.createCost.value.VariableCost,
    }

    this.productionService.postProdCost(requestData).subscribe((res: any) => {
     
      if (res.success) {
      
        this.toastr.success("Created Successfully");
        this.closebutton.nativeElement.click();
        this.getProdCost();
       
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  getProdCost() {
    this.spinner.show();
  
    this.productionService.getProdCost(this.finSize).subscribe((res: any) => {
      this.ProdCost = res.data;
     
      this.spinner.hide();
    })
  
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  deleteProdCostById(costComponentCalcId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {  
        this.productionService.deleteProdCostById(costComponentCalcId).subscribe((res: any) => {
          this.getProdCost();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
 /*  createCost() {
    this.router.navigate(["production/create-cost-cacl"])

  } */
  updateCost(id) {
    this.router.navigate(["production/create-cost-cacl"],{ queryParams: { CalcId : id}})

  }
  detailCost(id) {
    this.router.navigate(["production/detail-calc-cost"],{ queryParams: { CalcId : id}})

  }
  

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  ngOnDestroy(): void {
    this.spinner.hide();
      }

}
