
 <div class="container-fluid main-container-wrapper">
   <br>
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Edit Personal Information</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="w3-card-4 classCard">
                <header class="w3-container w3-blue">
                    <div class="header-tabs">
                        <h4 class=" common-styling" style="margin-left: 7px;">
                            Edit Personal Information
                        </h4>

                    </div>
                </header>
                <div class=" bg-white color-grey">
                    <div class="port-header-contact create-lead font-medium mb-0">

                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form *ngIf="empPersonalFormService.emp_personal_form"
                                    [formGroup]="empPersonalFormService.emp_personal_form" class="w-100">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Party Id </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="partyId"
                                                        [ngModelOptions]="{standalone: true}" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                         disabled>
                                                </div>
                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">External Id </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="externalId"
                                                        class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter External Id">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">First Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="firstName" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Enter First name">
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Last Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="lastName" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Enter Last name">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Comment</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="comments" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Enter Comment">
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Status</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" formControlName="statusId"
                                                        [options]="crmArray" optionlabel="label"
                                                        placeholder="Enter Status"></p-dropdown>


                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="description">Description</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text" placeholder="Description"
                                                        formControlName="description" class="form-control">
                                                </div>
                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <div class="form-group" style="color: black!important">Preferred
                                                        Currency</div>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="currencyList"
                                                        placeholder="Preferred Currency" filter="true"
                                                        formControlName="preferredCurrencyUomId"></p-dropdown>
                                                </div>

                                            </div>
                                        </div>


                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>


                    <div class="main-submit-button" style="    margin-right: 32%;">
                        <button type="submit" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                        <button type="button" (click)="onCancel()" routerLink="/hr/employees" routerLinkActive="active"
                            class="btn btn-degault cncl-btn">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- 
<div class="modal-header">
    <span class="color-black pl-1">User Login</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">
    <div class=" bg-white color-grey" style="width: 100%;">
        <div class="w3-card-4 classCardPopUp" style="width: 100%;">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">

                    <li class="active"><a>
                        Edit Personal Information</a></li>
                </ul>
            </div>
            
            <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form *ngIf="empPersonalFormService.emp_personal_form"
                            [formGroup]="empPersonalFormService.emp_personal_form" class="w-100 ">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">Party Id </label>
                                        </div>
                                        <div class="col-lg-3" >
                                            <input type="email" formControlName="partyId"
                                             class="form-control"
                                            id="exampleInputEmail1" aria-describedby="emailHelp" 
                                            disabled>
                                        </div>
                                        <div class="col-lg-2 form-group classInput__new_changes">
                                            <label for="exampleInputEmail1">External Id </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" formControlName="externalId" class="form-control"
                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                            placeholder="Enter External Id">
                                        </div>
                                    
                                    </div>
                                </div>
                              
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">First Name</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" formControlName="firstName" class="form-control"
                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                            placeholder="Enter First name">
                                        </div>

                                        <div class="col-lg-2 form-group classInput__new_changes">
                                            <label for="exampleInputEmail1">Last Name</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" formControlName="lastName" class="form-control"
                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                            placeholder="Enter Last name">
                                        </div>
                                    
                                    </div>
                                </div>
                               
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">Comment</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" formControlName="comments" class="form-control"
                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                            placeholder="Enter Comment">
                                        </div>

                                        <div class="col-lg-2 form-group classInput__new_changes">
                                            <label for="exampleInputEmail1">Status</label>
                                        </div>
                                        <div class="col-lg-3 ">
                                            <p-dropdown filter="true" formControlName="statusId" [options]="crmArray"
                                            optionlabel="label" placeholder="Enter Status"></p-dropdown>

                                        </div>
                                    
                                    </div>
                                </div>
                               
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="description">Description</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" placeholder="Description" formControlName="description"
                                            class="form-control">
                                        </div>
                                        <div class="col-lg-2 form-group classInput__new_changes">
                                            <div class="form-group" style="color: black!important">Preferred Currency</div>
                                        </div>
                                        <div class="col-lg-3 ">
                                            <p-dropdown [options]="currencyList" placeholder="Preferred Currency"
                                        filter="true" formControlName="preferredCurrencyUomId"></p-dropdown>
                                        </div>
                                    
                                    </div>
                                </div>
                                
                             
                            </div>

                        </form>
                    </div>
                </div>

            </div>

      
        <div class="main-submit-button" style="    margin-right: 17%;">
            <button type="submit" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
            <button type="button" (click)="onCancel()" routerLink="/hr/employees" routerLinkActive="active"
                class="btn btn-degault cncl-btn">Cancel</button> 
        </div>
            
            </div>
    </div>
</div> -->
