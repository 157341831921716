<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;">Address Match Map
                </span>
                <span class="color-black pl-1"> >Add New Address Match Map
                </span>
                <button type="submit" class="btn btn-danger buttonclass" (click)="GoToQuickLink()">Cancel</button>&nbsp;
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                    Add New Address Match Map
                                </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                    Update New Address Match Map
                                </a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100"  [formGroup]="createClassification">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Match Key</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Match Key"
                                                    formControlName="matchKey">
                                                </div>
    
    
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Match Value</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control"  id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Match Value"
                                                    formControlName="matchValue">
                                                </div>
    
                                              
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Sequence</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Sequence"
                                                    formControlName="sequenceNum">
                                                </div>
    
    
    
                                              
                                            </div>
                                        </div>
                                       
                                     
                                       <!--  <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Choose File</label>
                                                <input type="file" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Choose File"
                                                    formControlName="Justification">
                                            </div>
                                        </div> -->
                                    </div>
                                </form>
                                <div class="main-submit-button" style="margin-left: 59%;">
                                    <button type="submit" 
                                        class="btn btn-secondary submit-btn" (click)="onSubmit()">Save</button>&nbsp;
                                    <button type="submit" *ngIf="this.show"
                                        class="btn btn-secondary submit-btn">Update</button>&nbsp;
                                </div>
                                <br>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<ngx-spinner></ngx-spinner>