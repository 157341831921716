import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  faInfoCircle = faInfoCircle;
  isShown = false; 
  products3: any;
  total=0;
  rowNumber=0;
  pageSize = 10;
  activeCategory=1;
  activeCategoryMain=2;
  rows = 50;
  statusIds: any[];
  invoiceTypes: any[];
  invoiceStatusArray = [
    {
      label: 'Unpaid Invoice',
      value: 'INVOICE_IN_PROCESS'
    },
    {
      label: 'Paid Invoice',
      value: 'INVOICE_PAID'
    },

  ];
  invoiceDateArray = [
    {
      label: 'Past due date',
      value: 'past due date'
    },
    {
      label: 'Within Due date',
      value: 'within due date'
    },
  ];
  opportunityIdArray = [
    {
      label: 'Contains',
      value: 'Contains'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    },
    {
      label: 'Begins With',
      value: 'Begins With'
    },
  ];
  greaterArray = [
    {
      label: 'Equals',
      value: 'equal'
    },
    {
      label: 'Same Day',
      value: 'Same Day'
    },
    {
      label: 'Greater Than From Day Start',
      value: 'Greater Than From Day Start'
    },
    {
      label: 'Greater Than',
      value: 'Greater Than'
    }
  ];
  smallerArray = [
    {
      label: 'Less Than',
      value: 'Less Than'
    },
    {
      label: 'Up To Day',
      value: 'Up To Day'
    },
    {
      label: 'Up Through Day',
      value: 'Up Through Day'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    }
  ];
  searchInvoiceForm: FormGroup;
  invoices: any[];
  partyIds: any[];
  invoiceType: string;
  agreementTypeValue: string;
  advanceSearch: { description: string; fromPartyId: string; invoiceDateFrom: string; invoiceDateTo: string; invoiceId: string; invoiceType: string; statusId: string; toPartyId: string; };
  getPurchaseInvoicesData: any;
  filterInvoiceDateForm: FormGroup;
  filterInvoiceStatusForm: FormGroup;
  constructor(
    readonly _FormBuilder: FormBuilder,
    readonly _AccountingApService: AccountingApService,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly accountsService: AccountsService,
    private _location: Location,


  ) {
    this.filterInvoiceDateForm = this._FormBuilder.group({
      invoiceDate: [''],
    })
    this.filterInvoiceStatusForm = this._FormBuilder.group({
      invoiceStatus: [''],
    })
    this.filterInvoiceStatusForm = this._FormBuilder.group({
      description: [''],
      descriptionSearchType: ['Contains'],
      fromPartyId: [''],
      invoiceDateFrom: [''],
      invoiceDateFromSearchType: ['equal'],
      invoiceDateTo: [''],
      invoiceDateToSearchType: ['oplessthen'],
      invoiceId: [''],
      invoiceIdSearchType: ['Contains'],
      invoiceType: ['PURCHASE_INVOICE', [Validators.required]],
      statusId: [''],
      toPartyId: ['']
    });
    this.searchInvoiceForm = this._FormBuilder.group({
      description: [''],
      descriptionSearchType: ['Contains'],
      fromPartyId: [''],
      invoiceDateFrom: [''],
      invoiceDateFromSearchType: ['equal'],
      invoiceDateTo: [''],
      invoiceDateToSearchType: ['oplessthen'],
      invoiceId: [''],
      invoiceIdSearchType: ['Contains'],
      invoiceType: ['PURCHASE_INVOICE', [Validators.required]],
      statusId: [''],
      toPartyId: ['']
    });
    this.advanceSearch = {

      description: "",
      fromPartyId: "",
      invoiceDateFrom: "",
      invoiceDateTo: "",
      invoiceId: "",
      invoiceType:"",
      statusId: "",
      toPartyId:""

    };
    this.invoiceTypes = [];
    this.statusIds = [];
    this.invoices = [];
    this.partyIds = [];
    this.invoiceType = 'payable';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.agreementTypeValue = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this.invoiceType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this.getInvoiceTypeAndStatus();
    this.getPartyIds();
    if (window.location.pathname.includes('receiveable')) {
      this.searchInvoiceForm.patchValue({
        invoiceType: 'SALES_INVOICE'
      });
    }
    if(this.invoiceType=='payable'){
     this.searchInvoicePay();
    }
    else{
    this.searchInvoice();
    }
    this.findPurchaseInvoices();
  }
  invoiceFilter(event){
    this.searchInvoiceForm.value.statusId=event.value;
    this.spinner.show();
    if (this.searchInvoiceForm.valid) {
      this._AccountingApService.searchInvoice({ pageNo: 1, pageSize: 100 }, {
        ... this.searchInvoiceForm.value, ...{
          invoiceDateFrom: this.searchInvoiceForm.value.invoiceDateFrom ? moment(this.searchInvoiceForm.value.invoiceDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
          invoiceDateTo: this.searchInvoiceForm.value.invoiceDateTo ? moment(this.searchInvoiceForm.value.invoiceDateTo).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          this.spinner.hide();
          this.invoices = data.data;
        })
        .catch(err => {
          this.spinner.hide();
          if (err.errors) {
            err.errors.forEach(value => {
              this._ToastrService.success(value.fieldName);
            });
          }
        });

    }
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this.router.navigate(['/financial/main']);
}

  
  mainReceive(){
    this.router.navigate(["/financial/main-receivable"])
  }
  main(){
    this.router.navigate(["/financial/main-payable"])
  }
  enterBills(){
    this.router.navigate(["/financial/payable/invoices"])
  }
  payableMakePayment(){
    this.router.navigate(["/financial/payable/payment-payables"])
  }
  payableMakeGroupPayment(){
    this.router.navigate(["/financial/payable/payment-group"])
  }
  salesInvoice(){
    this.router.navigate(["/financial/receiveable/invoices"])
  }
  receivePayment(){
    this.router.navigate(["/financial/receiveable/payments"])
  }
  receiveGroupPayments(){
    this.router.navigate(["/financial/receiveable/payment-group"])
  }
  invoiceTypeFunction(){
    this.isShown = !this.isShown;
  }
  changeactiveCategoryFunctionPurchae(){
    this.router.navigate([`/financial/payable/invoices`]);
  }
  changeactiveCategoryFunctionCredit(){
    this.router.navigate([`/financial/payable/credit-memo`]);
  }
  changeactiveCommission(){
    this.router.navigate([`/financial/payable/commission-invoice`]);
  }
  getInvoiceTypeAndStatus(): void {
    this.spinner.show();
    this._AccountingApService.getInvoiceTypeAndStatus()
      .then(data => {
        this.spinner.hide();
        this.statusIds = data.data.invoiceStatus.map(value => {
          return {
            label: value.description,
            value: value.statusId
          };
        });
        this.invoiceTypes = data.data.invoiceType.map(value => {
          return {
            label: value.description,
            value: value.invoiceTypeId
          };
        });
      });
     
  }



  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
     
  }
  findPurchaseInvoices(): void {
    this.spinner.show();
    const req = {
      "billingAccountId": "",
      "description": this.searchInvoiceForm.value.description?this.searchInvoiceForm.value.description:'',
      "invoiceDate": this.searchInvoiceForm.value.invoiceDateFrom?this.searchInvoiceForm.value.invoiceDateFrom:'',
      "invoiceId": this.searchInvoiceForm.value.invoiceId?this.searchInvoiceForm.value.invoiceId:'',
      "invoiceTypeId": this.searchInvoiceForm.value.invoiceType?this.searchInvoiceForm.value.invoiceType:'',
      "partyId": "",
      "partyIdFrom": this.searchInvoiceForm.value.fromPartyId?this.searchInvoiceForm.value.fromPartyId:'',
      "referenceNumber": "",
      "statusId": this.searchInvoiceForm.value.statusId?this.searchInvoiceForm.value.statusId:'',
    }
    this.accountsService.findPurchaseInvoices(req).subscribe(res => {
      if (res.success) {
        this.spinner.hide();
        this.getPurchaseInvoicesData = res.data;
      }
    });

  }

  searchInvoicePay(): void {
    this.spinner.show();
    if (this.searchInvoiceForm.valid) {
      this._AccountingApService.searchInvoice({ pageNo: 1, pageSize: 100 }, {
        ... this.searchInvoiceForm.value, ...{
          invoiceDateFrom: this.searchInvoiceForm.value.invoiceDateFrom ? moment(this.searchInvoiceForm.value.invoiceDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
          invoiceDateTo: this.searchInvoiceForm.value.invoiceDateTo ? moment(this.searchInvoiceForm.value.invoiceDateTo).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          this.spinner.hide();
          this.invoices = data.data;
        })
        .catch(err => {
          this.spinner.hide();
          if (err.errors) {
            err.errors.forEach(value => {
              this._ToastrService.success(value.fieldName);
            });
          }
        });

    }
   
  }
  resetPerInvoice(){
    this.searchInvoiceForm.reset();
  }

  reset() {

    this.searchInvoiceForm.controls['description'].reset();
    this.searchInvoiceForm.controls['fromPartyId'].reset();
    this.searchInvoiceForm.controls['invoiceDateFrom'].reset();
    this.searchInvoiceForm.controls['invoiceDateTo'].reset();
    this.searchInvoiceForm.controls['invoiceId'].reset();
    this.searchInvoiceForm.controls['invoiceType'].reset();
    this.searchInvoiceForm.controls['statusId'].reset();
    this.searchInvoiceForm.controls['toPartyId'].reset();

    this.searchInvoice();

  }
 
  searchInvoice(): void {
    this.spinner.show();
    
 if (this.searchInvoiceForm.valid) {
      this._AccountingApService.searchInvoice({ pageNo: 1, pageSize: 100 }, {
        ... this.searchInvoiceForm.value, ...{
          invoiceDateFrom: this.searchInvoiceForm.value.invoiceDateFrom ? moment(this.searchInvoiceForm.value.invoiceDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
          invoiceDateTo: this.searchInvoiceForm.value.invoiceDateTo ? moment(this.searchInvoiceForm.value.invoiceDateTo).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          this.spinner.hide();
          this.invoices = data.data;
        })
        .catch(err => {
          this.spinner.hide();
          if (err.errors) {
            err.errors.forEach(value => {
              this._ToastrService.success(value.fieldName);
            });
          }
        });

    }
   
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
