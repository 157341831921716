import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from '../../app.constant';

@Injectable({
  providedIn: 'root'
})
export class MainCatalogService {

  constructor(readonly http: HttpClient) { }
  getCategoryList(): Observable<any> {
    const catalogList = URLS.catalogList;
    return this.http.get(catalogList);
  }
  getStoreList(id): Observable<any> {
    const storeList = URLS.getProductCatalogStores.replace(":catalogId", id);
    return this.http.get(storeList);
  }
  createStories(formData, id): Observable<any> {
    const createStore = URLS.createCatalogStore.replace(":catalogId", id);
    return this.http.post(createStore, formData);
  }
  updateCatalogStore(formData, id): Observable<any> {
   
    const updateStore = URLS.updateCatalogStore.replace(":catalogId", id);
    return this.http.put(updateStore, formData)
  }
  storeDropdown(): Observable<any> {
    const storeDropdown = URLS.storeDropdown;
    return this.http.get(storeDropdown);
  }
  getCatalogLists(data): Observable<any> {
    const catalogList = URLS.catalogList + "?pageSize=" + data.pageSize + "&pageNo=" + data.pageNo + "&search=" + data.search;
    return this.http.get(catalogList);
  }
  getCategories(pagination: { pageNo: number, pageSize: number }, searchFilter: { categoryId: string, categoryIdSearchType: string, categoryName: string, categoryNameSearchType: string }): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/categories/search?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter).toPromise();
  }
  getCatalogById(id): Observable<any> {
    const catalogId = URLS.getProductCatalogsById.replace(":catalogId", id);
    return this.http.get(catalogId);
  }
  getCatalogList(): Observable<any> {
    const productCatalog = URLS.getProductCatalogs;
    return this.http.get(productCatalog);
  }
  getProducts(pagination: { pageNo: number, pageSize: number }, searchFilter: { internalName: string, internalNameSearchType: string, productId: string, productIdSearchType: string }): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/product/products?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter).toPromise();
  }
  getCatalogs(pagination: { pageNo: number, pageSize: number }, searchFilter: { catalogName: string, catalogNameSearchType: string, prodCatalogId: string, prodCatalogIdSearchType: string }): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/productCatalog/catalogs?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter).toPromise();
  }
  createCatalog(formData): Observable<any> {
    const createCatalog = URLS.createProductCatalog;
    return this.http.post(createCatalog, formData);
  }
  updateCatalog(formData): Observable<any> {
    const updateCatalog = URLS.updateProductCatalog;
    return this.http.put(updateCatalog, formData);
  }
  deleteCatalogStore(catalogId, productStoreId, fromDate) {
    const deleteStore = URLS.deleteCatalogStore.replace(":catalogId", catalogId).replace(":productStoreId", productStoreId).replace(":fromDate", fromDate);
    return this.http.delete(deleteStore);
  }
 
  categoryAdvanceSearch(viewIndex: any, viewSize: any, searchFilter: any): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/catalog/keywordSearch?viewIndex=${viewIndex}&viewSize=${viewSize}`, searchFilter).toPromise();
  }
}
