<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Survey</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100" [formGroup]="findSurveyForm">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Find Survey
                                    <!-- <button type="submit" class="btn btn-secondary submit-btn ml-2" style="width: 113px;" data-toggle="modal" data-target="#exampleCreateNewContactList">Create New Contact List
                                    </button> -->
                                </div>
                                <div class="create-new-leade">
                                    <div class="container-fluid" style="padding: 0;">
                                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w-100">
                                                <div class="w3-card-4  w-100">
                                                    <div class="accordion classCard" id="myAccordion">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="headingOne">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                    <!-- <span class="headingAccords"> Path Alias</span> -->
                                                                    <button type="button" class="btn btn-outline-secondary active marginHeader"   routerLinkActive="active"
                                                                    data-target="#createSurveyPopup" data-toggle="modal">Create New Survey</button>
                                                        
                                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 80%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;">
                                                                    </button>									
                        
                                                                </div>
                            
                                                            </h2>
                                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                <div class="card-body">
                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Survey ID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"  formControlName="surveyID" class="form-control" id="exampleInputEmail1" 
                                                                                            aria-describedby="emailHelp" placeholder="Enter Survey ID">
                                                                            
                                                                                    </div>
                                            
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Survey Name</label>
                                                                                    </div>
                                            
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"  formControlName="surveyName" class="form-control" id="exampleInputEmail1" 
                                                                                            aria-describedby="emailHelp" placeholder="Enter Survey Name">
                                                                            
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                            
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Description</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                                                            aria-describedby="emailHelp" placeholder="Enter Description">
                                                                            
                                                                                    </div>
                                            
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Comments</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"  formControlName="comments" class="form-control" id="exampleInputEmail1" 
                                                                                            aria-describedby="emailHelp" placeholder="Enter Comments">
                                                                            
                                                                                    </div>
                                            
                                                                                
                                                                                </div>
                                                                            </div>
                                            
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Submit Caption</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"  formControlName="submitCaption" class="form-control" id="exampleInputEmail1" 
                                                                                            aria-describedby="emailHelp" placeholder="Enter Submit Caption">
                                                                            
                                                                                    </div>
                                            
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Response Service</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email"  formControlName="responseService" class="form-control" id="exampleInputEmail1" 
                                                                                            aria-describedby="emailHelp" placeholder="Enter Response Service">
                                                                            
                                                                                    </div>
                                            
                                                                                
                                                                                </div>
                                                                            </div>
                                            
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Is Anonymous</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" optionlabel="label" 
                                                                                        formControlName="yesNo" 
                                                                                        placeholder="Choose Option" [options]="yesNoArray">
                                            
                                                                                        </p-dropdown>
                                                                                    </div>
                                            
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Allow Multiple</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" optionlabel="label" 
                                                                                        formControlName="allowMultiple" 
                                                                                        placeholder="Choose Option" [options]="yesNoArray">
                                            
                                                                                        </p-dropdown>
                                                                                    </div>
                                            
                                                                                
                                                                                </div>
                                                                            </div>
                                            
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Allow Update</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" optionlabel="label" 
                                                                                        formControlName="allowUpdate" 
                                                                                        placeholder="Choose Option" [options]="yesNoArray">
                                            
                                                                                        </p-dropdown>
                                                                                    </div>
                                            
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">AcroForm (PDF) Content ID	
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" optionlabel="label" 
                                                                                        formControlName="secureContentPrefix" 
                                                                                        placeholder="Enter Secure Content Prefix" [options]="contentIdsArray">
                                            
                                                                                        </p-dropdown>
                                                                                        
                                                                            
                                                                                    </div>
                                            
                                                                                
                                                                                </div>
                                                                            </div>
                                            
                                            
                                            
                                                                        </div>
                
                                                                    <div class="col-lg-10 main-submit-button" style="margin-left: -9% !important;">
                                                                        <button type="submit"  class="btn btn-secondary submit-btn" (click)="findSurvey()">Find</button>&nbsp;
                                                                        <button type="button" class="btn btn-danger ml-2" (click)="resetSurvey()">Reset</button>&nbsp;
                                                                    </div>
                
                
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <!-- <div class="w3-card-4 classCardView">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                    
                                                        <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                            data-target="#createSurveyPopup" data-toggle="modal">Create Survey</button>
                                                
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Survey ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"  formControlName="surveyID" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Survey ID">
                                                        
                                                                </div>
                        
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Survey Name</label>
                                                                </div>
                        
                                                                <div class="col-lg-2">
                                                                    <input type="email"  formControlName="surveyName" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Survey Name">
                                                        
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                        
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Description</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Description">
                                                        
                                                                </div>
                        
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Comments</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"  formControlName="comments" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Comments">
                                                        
                                                                </div>
                        
                                                            
                                                            </div>
                                                        </div>
                        
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Submit Caption</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"  formControlName="submitCaption" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Submit Caption">
                                                        
                                                                </div>
                        
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Response Service</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"  formControlName="responseService" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Response Service">
                                                        
                                                                </div>
                        
                                                            
                                                            </div>
                                                        </div>
                        
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Is Anonymous</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" optionlabel="label" 
                                                                    formControlName="yesNo" 
                                                                    placeholder="Choose Option" [options]="yesNoArray">
                        
                                                                    </p-dropdown>
                                                                </div>
                        
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Allow Multiple</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" optionlabel="label" 
                                                                    formControlName="allowMultiple" 
                                                                    placeholder="Choose Option" [options]="yesNoArray">
                        
                                                                    </p-dropdown>
                                                                </div>
                        
                                                            
                                                            </div>
                                                        </div>
                        
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Allow Update</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" optionlabel="label" 
                                                                    formControlName="allowUpdate" 
                                                                    placeholder="Choose Option" [options]="yesNoArray">
                        
                                                                    </p-dropdown>
                                                                </div>
                        
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">AcroForm (PDF) Content ID	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" optionlabel="label" 
                                                                    formControlName="secureContentPrefix" 
                                                                    placeholder="Enter Secure Content Prefix" [options]="contentIdsArray">
                        
                                                                    </p-dropdown>
                                                                    
                                                        
                                                                </div>
                        
                                                            
                                                            </div>
                                                        </div>
                        
                        
                        
                                                    </div>
                                                    <div class="col-lg-10 main-submit-button" style="margin-left: -9% !important;">
                                                        <button type="submit"  class="btn btn-secondary submit-btn" (click)="findSurvey()">Find</button>&nbsp;
                                                        <button type="button" class="btn btn-danger ml-2" (click)="resetSurvey()">Reset</button>&nbsp;
                                                    </div>
                                                </div> -->
                                               
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Search Results
                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                        
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="surveyDataAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                        
                                                                                        Survery ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                       Survery Name
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Description
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Comments
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Submit Caption
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Response Service
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Is Anonymous
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Allow Multiple
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Allow Update
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        AcroForm (PDF) Content ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                
                        
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span class="account-button"    (click)="surveyDetailpage(product.surveyId)">
                                                                                      {{product.surveyId}}
                                                                                        </span>
                        
                                                                                    </div>
                                                                                </td>
                        
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.surveyName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.description}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.comments}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.submitCaption}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.responseService}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.isAnonymous}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.allowMultiple}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.allowUpdate}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.acroFormContentId}}
                                                                                </td>
                                                                              
                                                                                
                        
                                                                            </tr>
                                                                        </ng-template>
                        
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                        
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal fade" id="createSurveyPopup" tabindex="-1" role="dialog" aria-labelledby="createSurveyPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Survey</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Survey</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="creatSurveyForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Survey ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="surveyID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Survey ID"  [readonly]="surveyIdValue">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Survey Name</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="surveyName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Survey Name">
                                               
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Description">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="comments" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Comments">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Submit Caption</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="submitCaption" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Submit Caption">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Response Service</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="responseService" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Response Service">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Is Anonymous</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="yesNo" 
                                                        placeholder="Choose Option" [options]="yesNoArray">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Allow Multiple</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="allowMultiple" 
                                                        placeholder="Choose Option" [options]="yesNoArray">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Allow Update</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="allowUpdate" 
                                                        placeholder="Choose Option" [options]="yesNoArray">

                                                        </p-dropdown>
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>

                                            
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="createSurvey()">Create</button>
                                               
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>
