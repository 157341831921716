import { FormControl,FormGroup,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmployeeApplicationModel {

  
  
  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        applicationDate:(data && data.applicationDate) ? data.applicationDate : null,
        applicationId:(data && data.applicationId) ? data.applicationId : null,
        applyingPartyId:(data && data.applyingPartyId) ? data.applyingPartyId : null,
        emplPositionId:(data && data.emplPositionId) ? data.emplPositionId : null,
        employmentAppSourceTypeId:(data && data.employmentAppSourceTypeId) ? data.employmentAppSourceTypeId : null,
        referredByPartyId:(data && data.referredByPartyId) ? data.referredByPartyId : null,
        statusId:(data && data.statusId) ? data.statusId : null,

        Signature:(data && data.Signature) ? data.Signature : null,
        signDate:(data && data.signDate) ? data.signDate : null,

        name:(data && data.name) ? data.name : null,
        socailSecurityNumber:(data && data.socailSecurityNumber) ? data.socailSecurityNumber : null,
        address:(data && data.address) ? data.address : null,
        telephoneNo:(data && data.telephoneNo) ? data.telephoneNo : null,
        OtherPhonenumber:(data && data.OtherPhonenumber) ? data.OtherPhonenumber : null,
        crime:(data && data.crime) ? data.crime : null,
        reason:(data && data.reason) ? data.reason : null,
        empDate:(data && data.empDate) ? data.empDate : null,

        PositionAppliedFor:(data && data.PositionAppliedFor) ? data.PositionAppliedFor : null,
        DateAvailable:(data && data.DateAvailable) ? data.DateAvailable : null,
        referred:(data && data.referred) ? data.referred : null,
        payRate:(data && data.payRate) ? data.payRate : null,
        
        SchoolAddress:(data && data.SchoolAddress) ? data.SchoolAddress : null,
        degree:(data && data.degree) ? data.degree : null,
        SchoolAddressTwo:(data && data.SchoolAddressTwo) ? data.SchoolAddressTwo : null,
        degreeTwo:(data && data.degreeTwo) ? data.degreeTwo : null,
        SchoolAddressThree:(data && data.SchoolAddressThree) ? data.SchoolAddressThree : null,
        degreeThree:(data && data.degreeThree) ? data.degreeThree : null,
        SchoolAddressFour:(data && data.SchoolAddressFour) ? data.SchoolAddressFour : null,
        degreeFour:(data && data.degreeFour) ? data.degreeFour : null,

        ProfessionalLicense:(data && data.ProfessionalLicense) ? data.ProfessionalLicense : null,
        Expiration:(data && data.Expiration) ? data.Expiration : null,
        applicationFilled:(data && data.applicationFilled) ? data.applicationFilled : null,
        explain:(data && data.explain) ? data.explain : null,
        transportation:(data && data.transportation) ? data.transportation : null,
        license:(data && data.license) ? data.license : null,
        LicenseNumber:(data && data.LicenseNumber) ? data.LicenseNumber : null,
        Issue:(data && data.Issue) ? data.Issue : null,
        ExpirationDate:(data && data.ExpirationDate) ? data.ExpirationDate : null,

        nameOne:(data && data.nameOne) ? data.nameOne : null,
        nameTwo:(data && data.nameTwo) ? data.nameTwo : null,
        addressOne:(data && data.addressOne) ? data.addressOne : null,
        secondAddress:(data && data.ExpirationDate) ? data.secondAddress : null,
        TelephoneNumber:(data && data.TelephoneNumber) ? data.TelephoneNumber : null,
        TelephoneNumberTwo:(data && data.TelephoneNumberTwo) ? data.TelephoneNumberTwo : null,

        Employer:(data && data.Employer) ? data.Employer : null,
        TypeofBusiness:(data && data.TypeofBusiness) ? data.TypeofBusiness : null,
        EmpAddress:(data && data.EmpAddress) ? data.EmpAddress : null,
        Phone:(data && data.Phone) ? data.Phone : null,
        TitlesDuties:(data && data.TitlesDuties) ? data.TitlesDuties : null,
        RatePay:(data && data.RatePay) ? data.RatePay : null,
        PeriodEmployed:(data && data.PeriodEmployed) ? data.PeriodEmployed : null,
        ReasonforLeaving:(data && data.ReasonforLeaving) ? data.ReasonforLeaving : null,
        SupervisorName:(data && data.SupervisorName) ? data.SupervisorName : null,

        position:(data && data.position) ? data.position : null,
        Department:(data && data.Department) ? data.Department : null,
        dateHire:(data && data.dateHire) ? data.dateHire : null,
        Salary:(data && data.Salary) ? data.Salary : null,
        Comments:(data && data.Comments) ? data.Comments : null,
   


    });
   
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    applicationDate: new FormControl(data.applicationDate),
    applicationId: new FormControl(data.applicationId),
    applyingPartyId: new FormControl(data.applyingPartyId,[Validators.required]),
    emplPositionId: new FormControl(data.emplPositionId),
    employmentAppSourceTypeId: new FormControl(data.employmentAppSourceTypeId,[Validators.required]),
    referredByPartyId: new FormControl(data.referredByPartyId),
    statusId: new FormControl(data.statusId),

    Signature: new FormControl(data.Signature),
    signDate: new FormControl(data.signDate),

    name: new FormControl(data.name),
    socailSecurityNumber: new FormControl(data.socailSecurityNumber),
    address: new FormControl(data.address),
    telephoneNo: new FormControl(data.telephoneNo),
    OtherPhonenumber: new FormControl(data.OtherPhonenumber),
    crime: new FormControl(data.crime),
    reason: new FormControl(data.reason),
    empDate: new FormControl(data.empDate),

    PositionAppliedFor: new FormControl(data.PositionAppliedFor),
    DateAvailable: new FormControl(data.DateAvailable),
    referred: new FormControl(data.referred),
    payRate: new FormControl(data.payRate),

    SchoolAddress: new FormControl(data.SchoolAddress),
    degree: new FormControl(data.degree),
    SchoolAddressTwo: new FormControl(data.SchoolAddressTwo),
    degreeTwo: new FormControl(data.degreeTwo),
    SchoolAddressThree: new FormControl(data.SchoolAddressThree),
    degreeThree: new FormControl(data.degreeThree),
    SchoolAddressFour: new FormControl(data.SchoolAddressFour),
    degreeFour: new FormControl(data.degreeFour),

    ProfessionalLicense: new FormControl(data.ProfessionalLicense),
    Expiration: new FormControl(data.Expiration),
    applicationFilled: new FormControl(data.applicationFilled),
    explain: new FormControl(data.explain),
    transportation: new FormControl(data.transportation),
    license: new FormControl(data.license),
    LicenseNumber: new FormControl(data.LicenseNumber),
    Issue: new FormControl(data.Issue),
    ExpirationDate: new FormControl(data.ExpirationDate),

    nameOne: new FormControl(data.nameOne),
    nameTwo: new FormControl(data.nameTwo),
    addressOne: new FormControl(data.addressOne),
    secondAddress: new FormControl(data.secondAddress),
    TelephoneNumber: new FormControl(data.TelephoneNumber),
    TelephoneNumberTwo: new FormControl(data.TelephoneNumberTwo),

    Employer: new FormControl(data.Employer),
    TypeofBusiness: new FormControl(data.TypeofBusiness),
    EmpAddress: new FormControl(data.EmpAddress),
    Phone: new FormControl(data.Phone),
    TitlesDuties: new FormControl(data.TitlesDuties),
    RatePay: new FormControl(data.RatePay),
    PeriodEmployed: new FormControl(data.PeriodEmployed),
    ReasonforLeaving: new FormControl(data.ReasonforLeaving),
    SupervisorName: new FormControl(data.SupervisorName),

    position: new FormControl(data.position),
    Department: new FormControl(data.Department),
    dateHire: new FormControl(data.dateHire),
    Salary: new FormControl(data.Salary),
    Comments: new FormControl(data.Comments),

   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
