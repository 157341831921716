import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css']
})
export class InvitationComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  advanceSearch: {
    searchPartyInvitation: string,
    partyInvitationId: string,
    partyIdFrom: string,
    partyIdTo: string,
    searchToName: string,
    toName: string,
    searchEmail: string,
    email: string,
    fromDate: string,
    statusId: string,
    throughDate: string


  };
  activeCategory = 1;
  searchArray: any[] = [];
  opportunityIdArray: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[] = [];
  status: string;
  PartyInvitationStatusList: any;
  PartyInvitationStatusListArray: any[] = [];
  invitationParty: any;
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  invitataionForm: FormGroup;
  rows = 50;
  pageNo = 1;


  show = false;
  userLoginIdArray: any[] = [];
  fromDate: string;
  inviteId: any;
  getEstimateFrom: string;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly toastr: ToastrService,
    readonly datePipe: DatePipe,
    readonly addSkillService: AddSkillService,
    private _location: Location,
    readonly router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly _ToastrService: ToastrService,
    readonly activate: ActivatedRoute
  ) {
    this.advanceSearch = {
      searchPartyInvitation: 'contains',
      partyInvitationId: '',
      partyIdFrom: '',
      partyIdTo: '',
      searchToName: 'contains',
      toName: '',
      searchEmail: 'contains',
      email: '',
      fromDate: '',
      statusId: '',
      throughDate: ''



    };
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];

    this.invitataionForm = this._FormBuilder.group({
      partyFrom: [''],
      partyTo: [''],
      toName: [''],
      emailAddress: [''],
      statusId: [''],
      inviteDate: ['']



    });
  }

  ngOnInit(): void {
  
 this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
 if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
    this.getPartyId();
    this.getPartyInvitationStatusList();
    this.partyInvitations();

    this.getPartyId();
    this.getPartyInvitationStatusList();
    this.activate.queryParams.subscribe(params => {
      this.inviteId = params["inviteId"];


    });
    if (this.inviteId) {
      this.show = true;
      this.editPartyInvitations();
    }
    else {
      this.show = false;
    }
    this.advanceSearchContacts(false);

  }
  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this.router.navigate(['/security/security-dashboard']);
}
  summary(id) {
    this.router.navigate(["security/detail-invitation-summary"], { queryParams: { inviteId: id } });
  }
  update(id) {
    this.router.navigate(["security/create-party-invitation"], { queryParams: { inviteId: id } });
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.invitationParty = [];
    const req = {
      "dateThru": this.datePipe.transform(this.advanceSearch.throughDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.throughDate, "yyyy-MM-dd hh:mm:ss") : '',
      "dateThru_i18n": "",
      "datefrom": this.datePipe.transform(this.advanceSearch.fromDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.fromDate, "yyyy-MM-dd hh:mm:ss") : '',
      "datefrom_i18n": "",
      "emailAddress": this.advanceSearch.email ? this.advanceSearch.email : '',
      "emailAddress_ic": "Y",
      "emailAddress_op": this.advanceSearch.searchEmail ? this.advanceSearch.searchEmail : '',
      "noConditionFind": "Y",
      "partyId": this.advanceSearch.partyIdTo ? this.advanceSearch.partyIdTo : '',
      "partyIdFrom": this.advanceSearch.partyIdFrom ? this.advanceSearch.partyIdFrom : '',
      "partyInvitationId": this.advanceSearch.partyInvitationId ? this.advanceSearch.partyInvitationId : '',
      "partyInvitationId_ic": "Y",
      "partyInvitationId_op": this.advanceSearch.searchPartyInvitation ? this.advanceSearch.searchPartyInvitation : '',
      "performSearch": "Y",
      "statusId": this.advanceSearch.statusId ? this.advanceSearch.statusId : '',
      "toName": this.advanceSearch.toName ? this.advanceSearch.toName : '',
      "toName_ic": "Y",
      "toName_op": this.advanceSearch.searchToName ? this.advanceSearch.searchToName : '',

    }
    this.accountsService.partyInvitations(req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          // this.invitationParty = resp.data.listIt.completeList;
          this.invitationParty = resp.data[0].findPartyInvitation;

        }
      });

  }
  reset() {
    this.advanceSearch = {
      searchPartyInvitation: 'Contains',
      partyInvitationId: '',
      partyIdFrom: '',
      partyIdTo: '',
      searchToName: 'Contains',
      toName: '',
      searchEmail: 'Contains',
      email: '',
      fromDate: '',
      statusId: '',
      throughDate: ''
    };
    this.spinner.show();
    const req = {
      "dateThru": "",
      "dateThru_i18n": "",
      "datefrom": "",
      "datefrom_i18n": "",
      "emailAddress": "",
      "emailAddress_ic": "",
      "emailAddress_op": "",
      "noConditionFind": "Y",
      "partyId": "",
      "partyIdFrom": "",
      "partyInvitationId": "",
      "partyInvitationId_ic": "",
      "partyInvitationId_op": "",
      "performSearch": "",
      "statusId": "",
      "toName": "",
      "toName_ic": "",
      "toName_op": ""
    }
    this.accountsService.partyInvitations(req).subscribe((res: any) => {
      // this.invitationParty = res.data.listIt.completeList;
      this.invitationParty = res.data[0].findPartyInvitation;
      this.spinner.hide();

    })

  }
  partyInvitations() {
    this.spinner.show();
    const req = {
      "dateThru": "",
      "dateThru_i18n": "",
      "datefrom": "",
      "datefrom_i18n": "",
      "emailAddress": "",
      "emailAddress_ic": "",
      "emailAddress_op": "",
      "noConditionFind": "Y",
      "partyId": "",
      "partyIdFrom": "",
      "partyInvitationId": "",
      "partyInvitationId_ic": "",
      "partyInvitationId_op": "",
      "performSearch": "",
      "statusId": "",
      "toName": "",
      "toName_ic": "",
      "toName_op": ""
    }
    this.accountsService.partyInvitations(req).subscribe((res: any) => {
      // this.invitationParty = res.data.listIt.completeList;
      this.invitationParty = res.data[0].findPartyInvitation;

      this.spinner.hide();

    })

  }
  getPartyInvitationStatusList() {
    this.spinner.show();
    this.status = 'PARTY_INV_STATUS'
    this.accountsService.getPartyInvitationStatusList(this.status).subscribe((res: any) => {
      this.PartyInvitationStatusList = res.data;
      this.spinner.hide();
      for (const value of this.PartyInvitationStatusList) {
        this.PartyInvitationStatusListArray.push({
          label: value.data.description,
          value: value.data.statusId
        })
      }

    })

  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  deleteStoreRole(id) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.accountsService.deletePartyInvitations(id).subscribe(res => {
          this.partyInvitations();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }

  editPartyInvitations() {
    this.spinner.show();

    this.accountsService.editPartyInvitations(this.inviteId).subscribe((res: any) => {
      setTimeout(() => {
        const fromdate = res.data.PartyInvitation.lastInviteDate
        this.getEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");

        const formValue = this.invitataionForm;
        formValue.patchValue({
          partyFrom: res.data.PartyInvitation.partyIdFrom,
          partyTo: res.data.PartyInvitation.partyId,
          toName: res.data.PartyInvitation.toName,
          emailAddress: res.data.PartyInvitation.emailAddress,
          statusId: res.data.PartyInvitation.statusId,
          inviteDate: this.getEstimateFrom
        })

      }, 3000);
      this.spinner.hide();
    })
  }




  onUpdate() {
    this.spinner.show();
    const fromDate = this.invitataionForm.get('inviteDate').value;
    this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {
      "emailAddress": this.invitataionForm.value.emailAddress,
      "lastInviteDate": this.fromDate,
      "lastInviteDate_i18n": "",
      "partyId": this.invitataionForm.value.partyTo,
      "partyIdFrom": this.invitataionForm.value.partyFrom,
      "partyInvitationId": this.inviteId,
      "statusId": this.invitataionForm.value.statusId,
      "toName": this.invitataionForm.value.toName,
    }
    this.accountsService.updatePartyInvitations(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.partyInvitations();
        // this.router.navigate(['/partyQuickLink/invitation']);
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  onSubmit() {
    this.spinner.show();
    const fromDate = this.invitataionForm.get('inviteDate').value;
    this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {
      "emailAddress": this.invitataionForm.value.emailAddress,
      "lastInviteDate": this.fromDate,
      "lastInviteDate_i18n": "",
      "partyId": this.invitataionForm.value.partyTo,
      "partyIdFrom": this.invitataionForm.value.partyFrom,
      "partyInvitationId": '',
      "statusId": this.invitataionForm.value.statusId,
      "toName": this.invitataionForm.value.toName,
    }
    this.accountsService.createPartyInvitations(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.partyInvitations();
        this.closebutton.nativeElement.click();
        // this.router.navigate(['/partyQuickLink/invitation']);
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }

  GoToQuickLink() {
    this.router.navigate(['/partyQuickLink/invitation']);
  }
}
