<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                    <span class="d-flex">

                        <button type="submit" (click)="homeButton();"
                            class=" btn btn-outline-secondary mr-2">Home</button>
                
                        <button type="submit" (click)="cancelSubmit();"
                            class="btn btn-danger buttonclass ">Back</button>
                    </span>
                
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                                    Visit List</a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                   <!--  <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Search Results
                                                        </h4>
                                                    </div> -->
                                                    <div class="panel-body">
                                                    <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12" >
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Active
                                                                            only</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-checkbox
                                                                            _ngcontent-egj-c329=""
                                                                            (click)="selectAllDepositWith($event)"
                                                                            styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Visit
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="opportunityIdArray" style="display: none;"
                                                                        filter="true"
                                                                        [(ngModel)]="advanceSearch.visitId_op"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        name="visitId_op">
                                                                    </p-dropdown>
                                                                    <input type="text"   [(ngModel)]="advanceSearch.visitId"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    name="visitId"
                                                                        class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Visit id">
                                                                    </div>
                                                                   
                                                                </div>
                                                              </div>
                                                              <div class="col-lg-12" >
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Visitor
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                       
                                                                        <p-dropdown [options]="opportunityIdArray" style="display: none;"
                                                                            filter="true" 
                                                                            [(ngModel)]="advanceSearch.visitorId_op"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="visitorId_op">
                                                                        </p-dropdown>
                                                                        <input type="text"   [(ngModel)]="advanceSearch.visitorId"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        name="visitorId"
                                                                            class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Visitor id">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Party
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                       
                                                                        <p-dropdown [options]="partyIdArray"
                                                                            filter="true"
                                                                            placeholder="Enter party id"
                                                                            [(ngModel)]="advanceSearch.partyId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="partyId">
                                                                        </p-dropdown>
                                                                    </div>
                                                                   
                                                                </div>
                                                              </div>
                                                              <div class="col-lg-12" >
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">User Login
                                                                            Id</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                       
                                                                       
                                                                        <p-dropdown [options]="opportunityIdArray"
                                                                            filter="true"  style="display: none;"
                                                                            [(ngModel)]="advanceSearch.userLoginId_op"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="userLoginId_op">
                                                                        </p-dropdown>
                                                                        <input type="text"   [(ngModel)]="advanceSearch.userLoginId"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        name="userLoginId"
                                                                            class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter user login Id">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Client
                                                                            Ip Address</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                       
                                                                      
                                                                        <p-dropdown [options]="opportunityIdArray"
                                                                            filter="true"  style="display: none;"
                                                                            [(ngModel)]="advanceSearch.clientIpAddress_op"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="clientIpAddress_op">
                                                                        </p-dropdown>
                                                                        <input type="text"   [(ngModel)]="advanceSearch.clientIpAddress"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        name="clientIpAddress"
                                                                            class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Client Ip">
                                                                    </div>
                                                                   
                                                                </div>
                                                              </div>
                                                              <div class="col-lg-12" >
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">User
                                                                            Created</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="greaterArray" style="display: none;"
                                                                                filter="true" placeholder="Equals"
                                                                                [(ngModel)]="advanceSearch.userCreated_fld0_op"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="userCreated_fld0_op">
                                                                            </p-dropdown>
                                                                       
                                                                            <input type="date"  class="form-control"
                                                                            [(ngModel)]="advanceSearch.userCreated_fld0_value"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="userCreated_fld0_value">
                                                                    </div>
                                                                   
                                                                    <div class="col-lg-2">
                                                                       
                                                                        <input type="date"  class="form-control"
                                                                        [(ngModel)]="advanceSearch.userCreated_fld1_value"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        name="userCreated_fld1_value">
                                                              
                                                                        <p-dropdown [options]="smallerArray" style="display: none;"
                                                                        filter="true" placeholder="Less Than"
                                                                        [(ngModel)]="advanceSearch.userCreated_fld1_op"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        name="userCreated_fld1_op">
                                                                    </p-dropdown>
                                                                    </div>
                                                                   
                                                                </div>
                                                              </div>
                                                          
                                                          
                                                        </div>
                                                        <div class="col-lg-12 col-12 main-submit-button "  style="margin-left: -31%;">
                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                class="btn btn-secondary submit-btn"
                                                                (click)="advanceSearchDeposit(false)">Find</button>&nbsp;&nbsp;
                                                            <button type="button"
                                                                class="btn btn-danger ml-2" (click)="reset()">Reset</button>&nbsp;
                                                        </div> 
                                                        <div>
                                                            <header class="w3-card-4 classCardView">
                                                                <div class="header-tabs" style="margin-left: 7px;">
                                                                    <h4 class=" common-styling">
                                                                        Search Results
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div
                                                                class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="Visitors" [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                              <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                  <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                    Visit ID <p-sortIcon field="code"></p-sortIcon>
                                                                                  </div>
                                  
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                  <div style="color: white;"> User Login
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                  
                                                                                  </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                  <div style="color: white;"> user Impersonated<p-sortIcon
                                                                                      field="quantity"></p-sortIcon>
                                                                                  </div>
                                  
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                  <div style="color: white;"> New User <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                  </div>
                                  
                                                                                </th>
                                  
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                  <div style="color: white;"> Web App
                                  
                                  
                                                                                  </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                  <div style="color: white;"> Client IP
                                  
                                  
                                                                                  </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                  <div style="color: white;"> From Date
                                  
                                  
                                                                                  </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                  <div style="color: white;"> Through Date
                                  
                                  
                                                                                  </div>
                                                                                </th>
                                                                              </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                              <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                  <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span style="cursor: pointer;color: #0d3769 !important;" (click)="detailSecurity(product.visitorData.visitId)">
                                                                                    {{product.visitorData.visitId}}</span>
                                                                                   
                                                                                  </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}" (click)="myProfilePage(product.visitorData.userLoginId)"> {{product.visitorData.userLoginId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.userImpersonated}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.visitorData.userCreated}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.visitorData.webappName}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.clientIpAddress}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.visitorData.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.visitorData.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                  
                                  
                                  
                                                                              </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                  <td [ngStyle]="{'width':'170px'}">
                                                                                    <span class="text-center">
                                                                                      <h4 class="ml-2">No Record Found
                                                                                      </h4>
                                                                                    </span>
                                                                                  </td>
                                                                                </tr>
                                                                              </ng-template>
                                                                          </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>