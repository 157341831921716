import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class Settings{

  constructor(private http:HttpClient) { }
  getSkills() {
    const getAllSkillType = URLS.getSkillTypeList;
    return this.http.get(getAllSkillType);
  }
  deleteSkillTypePost(skillTypeId) {
    const deleteSkillType = URLS.deleteSkillType.replace(":skillTypeId",skillTypeId);
    return this.http.post(deleteSkillType,"")
  }
  deleteSkillType(skillTypeId) {
    const deleteSkillType = URLS.deleteSkillType.replace(":skillTypeId",skillTypeId);
    return this.http.delete(deleteSkillType)
  }
  createSkillType(formData) {
    const createSkillType = URLS.creaSkillType;
    return this.http.post(createSkillType,formData)
  }
  getJobInterviewList(data){
    const getJobInterviewType=URLS.getJobInterviewTypes + "?pageSize=" + data.pageSize + "&pageNo=" + data.pageNo;
    return this.http.get(getJobInterviewType);
  }
  createInterviewType(formData):Observable<any>{
    const createJobInterview = URLS.createJobInterviewType;
    return this.http.post(createJobInterview,formData);
  }
  updateSkillType(skillData,skillTypeId):Observable<any> {
    const updateSkillType = URLS.updateSkillType.replace(":skillTypeId",skillTypeId);
    return this.http.put(updateSkillType,skillData);
  }
  updateInterviewType(formData,jobInterviewTypeId):Observable<any> {
    const updateInterviewType = URLS.updateJobInterviewType.replace(":jobInterviewTypeId",jobInterviewTypeId);
   return this.http.put(updateInterviewType,formData);
  }
  deleteInterviewId(id):Observable<any> {
    const deleteInterviewId = URLS.deleteJobInterviewType.replace(":jobInterviewTypeId",id);
    return this.http.delete(deleteInterviewId);
  }
  getLeaveType():Observable<any>{
    const getLeaveType = URLS.getLeaveTypes;
    return this.http.get(getLeaveType);
  }
  leaveTypeSubmit(form):Observable<any>{
    const submitLeaveType = URLS.createLeaveType;
    return this.http.post(submitLeaveType,form);
  }
  createResponsibility(formData):Observable<any>{
    const createResponsibilitytype = URLS.addResponsibilityType;
    return this.http.post(createResponsibilitytype,formData);
  }
  leaveTypeUpdate(formData,id):Observable<any>{
    const updateLeaveType = URLS.updateLeaveType.replace(":leaveTypeId",id);
    return this.http.put(updateLeaveType,formData);
  }
  deleteResponsibilitytype(id):Observable<any>{
    const deleteResponsility = URLS.deleteResponsType.replace(":responsibilityTypeId",id);
    return this.http.delete(deleteResponsility);
  }
  updateResponsibility(formData,id):Observable<any>{
    const updateResponsibility = URLS.updateResponType.replace(":responsibilityTypeId",id);
    return this.http.put(updateResponsibility,formData);
  }
  deleteLeaveType(id):Observable<any>{
    const deleteData = URLS.deleteLeaveType.replace(":leaveTypeId",id);
    return this.http.delete(deleteData);
  }
  getLeaveReasonType():Observable<any>{
    const deleteReasonType = URLS.getLeaveReasonTypes;
    return this.http.get(deleteReasonType);
  }
  createLeaveReason(form):Observable<any>{
    const saveLeaveReason = URLS.createLeaveReasonType;
    return this.http.post(saveLeaveReason,form)
  }
  updateLeaveReason(formData,id):Observable<any>{
    const updateLeaveReason = URLS.updateLeaveReasonType.replace(":leaveReasonTypeId",id);
    return this.http.put(updateLeaveReason,formData)
  }
  deleteReasonType(id):Observable<any>{
    const deleteReason = URLS.deleteLeaveReasonType.replace(":leaveReasonTypeId",id);
    return this.http.delete(deleteReason);
  }
getTrainingType(data):Observable<any>{
  const getTrainingList = URLS.getTrainingTypeList + "?pageSize=" + data.pageSize + "&pageNo=" + data.pageNo;
  return this.http.get(getTrainingList);
}
createTrainingType(formData):Observable<any>{
  const createTrainingType = URLS.createTrainingType;
return this.http.post(createTrainingType,formData)
}
updateTrainingType(formData,id){
  const updateTrainingData = URLS.updateTrainingType.replace(":trainingClassTypeId",id);
  return this.http.put(updateTrainingData,formData);
}
createPositionType(formData):Observable<any>{
  const positionData = URLS.createPositionType;
  return this.http.post(positionData,formData);
}
deleteTrainingType(id):Observable<any>{
  const deleteTrainingType = URLS.deleteTrainingType.replace(":trainingClassTypeId",id);
  return this.http.delete(deleteTrainingType);
}
getPositionType(){
  const getPositionType = URLS.getPositionTypes;
  return this.http.get(getPositionType);
}
deletePosition(id){
  const deletePosition = URLS.deletePositionType.replace(":positionTypeId",id);
  return this.http.delete(deletePosition);
}
updatePositionType(formData,id):Observable<any>{
  const updatePositionType = URLS.updatePositionType.replace(":positionTypeId",id)
  return this.http.put(updatePositionType,formData);
}
getPublicHolidays():Observable<any>{
  const getPublicHolidays = URLS.publicHolidays;
  return this.http.get(getPublicHolidays);
}
deleteHoliday(id):Observable<any>{
  const deleteHoliday = URLS.deletePublicHoliday.replace(":holidayId",id);
  return this.http.delete(deleteHoliday);
}
updatePublicHoliday(formData,id):Observable<any>{
  const updateData = URLS.updatePublicHoliday.replace(":holidayId",id);
  return this.http.put(updateData,formData);
}
createPublicHolidays(formData):Observable<any>{
  const createHoliday = URLS.createPublicHolidays;
  return this.http.post(createHoliday,formData);
}
getResponsibility():Observable<any> {
  const getResponsibility = URLS.getResponsibilityTypes;
  return this.http.get(getResponsibility);
}
createTerminationType(formData):Observable<any>{
  const terminationData = URLS.createTerminationTypes;
  return this.http.post(terminationData,formData);
}
getTerminationTypeList():Observable<any>{
  const getTerminationList = URLS.getTerminationTypeList;
  return this.http.get(getTerminationList);
}

deleteTerminationType(formdata){
  const deleteTermination= URLS.deleteTerminationType;
  return this.http.post(deleteTermination,formdata);
}
createTerminationReason(formData):Observable<any>{
  const TerminationReasonData = URLS.createTerminationReason;
  return this.http.post(TerminationReasonData,formData);
}
getTerminationReasonList():Observable<any>{
  const getTerminationReasonList = URLS.getTerminationReasonList;
  return this.http.get(getTerminationReasonList);
}
deleteTerminationReason(formdata){
  const deleteTerminationReason= URLS.deleteTerminationReason;
  return this.http.post(deleteTerminationReason,formdata);
}

updateTerminationType(data): Observable<any> {
  const updateTerminationType = URLS.updateTerminationType;
  return this.http.post(updateTerminationType,data);
}
getPayGrade():Observable<any>{
  const getFindPayGrade = URLS.getFindPayGrade;
  return this.http.post(getFindPayGrade,"");
}
getFindPayGrade(payGradeId,payGradeName,comment){
  const getFindPayGrade = URLS.getFindPayGrade +"?comments="+comment+"&payGradeId="+payGradeId+"&payGradeName="+payGradeName;
  return this.http.post(getFindPayGrade,"");
}
deletePayGrade(formdata){
  const deletePayGrade= URLS.deletePayGrade;
  return this.http.post(deletePayGrade,formdata);
}
createPayGrade(formData):Observable<any>{
  const createPayGrade = URLS.createPayGrade;
  return this.http.post(createPayGrade,formData);
}
updatePayGrade(data): Observable<any> {
  const updatePayGrade = URLS.updatePayGrade;
  return this.http.post(updatePayGrade,data);
}
getSalaryStepList():Observable<any>{
  const getSalaryStepList = URLS.getSalaryStepList;
  return this.http.get(getSalaryStepList);
}
createSalaryStep(formData):Observable<any>{
  const createSalaryStep = URLS.createSalaryStep;
  return this.http.post(createSalaryStep,formData);
}
deleteSalaryStep(formdata){
  const deleteSalaryStep= URLS.deleteSalaryStep;
  return this.http.post(deleteSalaryStep,formdata);
}
updateSalaryStep(data): Observable<any> {
  const updateSalaryStep = URLS.updateSalaryStep;
  return this.http.post(updateSalaryStep,data);
}
updateTerminationReason(data): Observable<any> {
  const updateTerminationReason = URLS.updateTerminationReason;
  return this.http.post(updateTerminationReason,data);
}
// /api/v1/hr/globalSettings/responsibilityType
// getResponsibilityTypes

    

//   getAllDepartment():Observable<any> {
//     let getAllDepartment = URLS.getDepartments;
//     return this.http.get(getAllDepartment)
//   }
//  assignDepartment(partyId,departmentId):Observable<any>{
//    let assignDepartment = URLS.assignDepartment.replace(":partyId",partyId).replace(":departmentId",departmentId);
//    return this.http.post(assignDepartment,"")
//  }
  
}
