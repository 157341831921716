import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerAgreementsService } from '../customer-agreements/customer-agreements.service';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountingApService } from '../../accounting-ap/accounting-ap.service';
import { faEdit, faTrash, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-summary-customer-agreements',
  templateUrl: './summary-customer-agreements.component.html',
  styleUrls: ['./summary-customer-agreements.component.css']
})
export class SummaryCustomerAgreementsComponent implements OnInit {
  faEdit = faEdit;
  faTrash = faTrash;
  activeCategory=1;
  activeCategoryMain=2;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  @ViewChild('closebuttonB') closebuttonB;
  @ViewChild('closebuttonC') closebuttonC;
  @ViewChild('closebuttonD') closebuttonD;
  faFileDownload = faFileDownload;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  agreementId: any = [];
  AgreementTermList: any = [];
  agreementItemList: any[];
  agreementRoles: any[];
  agreementContents: any[];
  agreementType: string;
  agreementList: any;

  updateCustomerAgreements: FormGroup;
  PersonsAndPartyGroupsList: any = [];
  productList: any = [];
  AgreementTypeList: any = [];
  RoleTypesList: any = [];
  editMode: boolean;
  createAgreementItem: FormGroup;

  agreementItemSeqId: string;
 
  agreementItemTypes: any[];
  currencyList: any[];
 
  agreementItem: any;

  agreementValue: any;
  partyIds: any[];
  TermTypeIdList: any = [];
 
  InvoiceItemTypeList: any = [];

  AgreementsTerms: FormGroup;

  agreementTermId: string;
  agreementTerm: any;
  roleTypeIds: any[];
  roleFrom: FormGroup;
  editItem: boolean;
  editAssign: boolean;
  statusIds: any[];
  file: FormData;
 
  createAgreementContentForm: FormGroup;

  contentTypeIds: any[];
  contentIds: any[];
  itemSeqIds: any[];
  editTerm: boolean;
  constructor(
    readonly _ActivatedRoute: ActivatedRoute,
    readonly  _Router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly _AccountingApService: AccountingApService,
    readonly CustomerAgreementsService: CustomerAgreementsService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly toastr: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService
  ) {
    this.agreementId = '';
    this.partyIds = [];
    this.roleTypeIds = [];
    this.agreementType = '';
    this.roleFrom = this._FormBuilder.group({
        agreementId: [''],
        partyId: ['', [Validators.required]],
        roleTypeId: ['', [Validators.required]]
    });
    
    this.agreementItemList = [];
    this.agreementRoles = [];
    this.agreementType = '';
    this.agreementContents = [];
    this.updateCustomerAgreements = this._FormBuilder.group({
      agreementTypeId: [''],
      agreementDate: [''],
      description: [''],
      fromDate: [''],
      partyIdFrom: [''],
      partyIdTo: [''],
      productId: [''],
      roleTypeIdFrom: [''],
      roleTypeIdTo: [''],
      textData: [''],
      thruDate: [''],
      agreementId: ['']
    });
    this.editMode = false;
    this.agreementType = '';
    this.agreementItemTypes = [];
    this.currencyList = [];
    this.createAgreementItem = this._FormBuilder.group({
        agreementId: [''],
        agreementImage: [''],
        agreementItemTypeId: [''],
        agreementText: [''],
        currencyUomId: ['']
    });
    this.agreementId = '';
    this.editMode = false;
    this.agreementType = '';
    this.createAgreementContentForm = this._FormBuilder.group({
      agreementContentTypeId: [''],
      agreementItemSeqId: [''],
      contentTypeId: [''],
      fromDate: [new Date()],
      thruDate: [new Date()],
      statusId: [''],
      file: ['']
  });
  this.file = new FormData();
  this.statusIds = [];
  this.agreementId = '';
  this.agreementType = '';
  this.contentTypeIds = [];
  this.contentIds = [];
  this.itemSeqIds = [];
  this.AgreementsTerms = this._FormBuilder.group({
    agreementDate: [''],
    agreementId: [''],
    agreementItemSeqId: [''],
    description: [''],
    fromDate: [''],
    invoiceItemTypeId: [''],
    maxQuantity: [''],
    minQuantity: [''],
    termDays: [''],
    termTypeId: ['', [Validators.required]],
    termValue: [''],
    textValue: [''],
    thruDate: ['']
  });
  this.editMode = false;
  this.agreementType = '';
  this.agreementTermId = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this.agreementId = this._ActivatedRoute.snapshot.queryParams.agreementID;
    this.getAgreementTermList();
    this.editItem=false;
    this.editTerm=false;
    this.getAgreementItemList();
    this.getAgreementContent();
    this.getAgreementRole();
    this.submit();
    this.getPersonsAndPartyGroups();
    this.getProducts();
    this.getRoleTypes();
    this.getAgreementTypeList();
    this.getAgreementSeqIds();
    this.getStatusIds();
    this.getAgreementContentTypeIds();
    this.getContentIds();
    this.getAgreementById();
    this.getPartyIds();
    this.getRoleTypeIds();
    this.getCurrencyList();
    this.getAgreementItemTypes();
    this.getTermTypeIdList();
    this.getInvoiceItemTypeList();
  }
  getTermTypeIdList() {
    this.spinner.show();
    this.CustomerAgreementsService.getTermTypeIdList().subscribe((responce) => {
      this.spinner.hide();
      this.TermTypeIdList = responce.data.map(value => {
        return {
          label: value.description,
          value: value.termTypeId
        };
      });
    });
   
  }

  getInvoiceItemTypeList() {
    this.spinner.show();
    this.CustomerAgreementsService.getInvoiceItemTypeList().subscribe((responce) => {
      this.spinner.hide();
      this.InvoiceItemTypeList = responce.data.map(value => {
        return {
          label: value.description,
          value: value.invoiceItemTypeId
        };
      });
    });
   
  }
 
  updateTermData(): void {
    this.spinner.show();
    this.CustomerAgreementsService.updateAgreementTerm(this.agreementTermId, {
      ...this.agreementTerm, ...this.AgreementsTerms.value, ...{

        fromDate: this.AgreementsTerms.value.formDate ? moment(this.AgreementsTerms.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDate: this.AgreementsTerms.value.thruDate ? moment(this.AgreementsTerms.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Updated');
          this.spinner.hide();
          this.AgreementsTerms.reset();
          this.getAgreementTermList();
          this.closebuttonD.nativeElement.click();

        }
      });
     
  }
  submitTerm(): void {
    this.spinner.show();
    if (this.editMode) {
      this.updateTermData();
    } else {
      this.CustomerAgreementsService.createAgreementTerm({
        ...this.AgreementsTerms.value, ...{
          fromDate: this.AgreementsTerms.value.formDate ? moment(this.AgreementsTerms.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
          thruDate: this.AgreementsTerms.value.thruDate ? moment(this.AgreementsTerms.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : '',
          agreementId: this.agreementId
        }
      }).subscribe((res: any) => {
     
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.AgreementsTerms.reset();
          this.closebuttonD.nativeElement.click();
          this.getAgreementTermList();

        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
  
    }
  }
  getAgreementSeqIds(): void {
    this.spinner.show();
    this._AccountingApService.getAgreementItemList(this.agreementId, { pageNo: '', pageSize: '' })
        .then(data => {
            this.spinner.hide();
            this.itemSeqIds = data.data.map(value => {
                return {
                    label: value.agreementItemSeqId,
                    value: value.agreementItemSeqId
                };
            });
        });
      
}
getStatusIds(): void {
    this.spinner.show();
    this._AccountingApService.getContentStatusId()
        .then(data => {
            this.spinner.hide();
            this.statusIds = data.data.map(value => {
                return {
                    label: value.description,
                    value: value.statusId
                };
            });
        });
       
}
getAgreementContentTypeIds(): void {
    this.spinner.show();
    this._AccountingApService.getAgreementContentTypeIds()
        .then(data => {
            this.spinner.hide();
            this.contentTypeIds = data.data.map(value => {
                return {
                    label: value.description,
                    value: value.agreementContentTypeId
                };
            });
        });
       
}
getContentIds(): void {
    this.spinner.show();
    this._AccountingApService.getContentIds()
        .then(data => {
            this.spinner.hide();
            this.contentIds = data.data.map(value => {
                return {
                    label: value.description,
                    value: value.contentTypeId
                };
            });
        });
       
}
setFile(event: any): void {
    this.file.append('file', event.target.files[0] as File);
}
reset(): void {
    this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID: this.agreementId } });
}
submitContent(): void {
    this.spinner.show();
    if (this.file) {
        const { agreementId, agreementContentTypeId, agreementItemSeqId, contentTypeId, fromDate, statusId, thruDate } = this.createAgreementContentForm.value;
        this._AccountingApService.createAgreementContent(this.file, this.agreementId, agreementContentTypeId, agreementItemSeqId, contentTypeId, moment(fromDate).format('YYYY-MM-DD HH:mm:ss'), statusId, moment(thruDate).format('YYYY-MM-DD HH:mm:ss'))
            .then(data => {
                if (data.success) {
                    this.toastr.success('Created');
                    this.spinner.hide();
                    this.closebuttonC.nativeElement.click();
                    this.getAgreementContent();
                }
            });
    } else {
        this.spinner.hide();
        this.toastr.error('Please select file');
    }
  
}
  getCurrencyList(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
        .then(data => {
            this.spinner.hide();
            this.currencyList = data.data.currencyList.map(value => {
                return {
                    label: value.description,
                    value: value.uomId
                };
            });
        });
       
}
getAgreementItemTypes(): void {
    this.spinner.show();
    this._AccountingApService.getAgreementItemTypeList()
        .then(data => {
            this.spinner.hide();
            this.agreementItemTypes = data.data.map(value => {
                return {
                    label: value.description,
                    value: value.agreementItemTypeId
                };
            });
        });
      
}

updateItem(): void {
    this.spinner.show();
    this._AccountingApService.updateAgreementItem(this.agreementItemSeqId, {
        ...this.agreementItem, ...this.createAgreementItem.value

    })
        .then(data => {
            if (data.success) {
                this.toastr.success('Updated');
                this.spinner.hide();
                this.createAgreementItem.reset();
                this.closebuttonB.nativeElement.click();
                this.getAgreementItemList();
            }
        });
      
}
submitItem(): void {
    this.spinner.show();
    if (this.editMode) {
        this.updateItem();
    } else {
        this._AccountingApService.createAgreementItem({
            ...this.createAgreementItem.value, ...{
                agreementId: this.agreementId
            }
        })
            .then(data => {
                if (data.success) {
                    this.toastr.success('Created');
                    this.spinner.hide();
                    this.createAgreementItem.reset();
                    this.closebuttonB.nativeElement.click();
                    this.getAgreementItemList();
                }
            });
    }
  
}
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
        .then(data => {
            this.spinner.hide();
            this.partyIds = data.data.PersonsGroups.map(value => {
                return {
                    label: value.partyId,
                    value: value.partyId
                };
            });
        });
      
}
getRoleTypeIds(): void {
    this.spinner.show();
    this._AccountingApService.getRoleType()
        .then(data => {
            this.spinner.hide();
            this.roleTypeIds = data.data.map(value => {
                return {
                    label: value.description,
                    value: value.roleTypeId
                };
            });
        });
       
}
submitRole(): void {
    this.spinner.show();
    if (this.roleFrom.valid) {
        this._AccountingApService.createAgreementRole({
            ...this.roleFrom.value, ...{
                agreementId: this.agreementId
            }
        })
            .then(data => {
                if (data.success) {
                    this.toastr.success('Created');
                    this.roleFrom.reset();
                    this.spinner.hide();
                    this.closebuttonA.nativeElement.click();
                    this.getAgreementRole();
                }
            });
    }
   
}

  submitData(): void {
    this.spinner.show();
    this.CustomerAgreementsService.updateAgreement(this.agreementId, {
      ...this.agreementValue, ...this.updateCustomerAgreements.value, ...{
        fromDate: this.updateCustomerAgreements.value.fromDate ? moment(this.updateCustomerAgreements.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDate: this.updateCustomerAgreements.value.thruDate ? moment(this.updateCustomerAgreements.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : '',
        agreementDate: this.updateCustomerAgreements.value.agreementDate ? moment(this.updateCustomerAgreements.value.agreementDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    }).then((res: any) => {
      
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.submit();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
    });
   
  }

  getPersonsAndPartyGroups() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {

      this.PersonsAndPartyGroupsList = responce.data.PersonsGroups.map(value => {
        return {
          label: value.partyId,
          value: value.partyId
        };
      });
    });
    this.spinner.hide();
  }

  getProducts() {
    this.spinner.show();
    const requestData = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains"
    };
    this.CustomerAgreementsService.getProducts(requestData).subscribe((responce) => {

      this.productList = responce.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
    });
    this.spinner.hide();
  }

  getRoleTypes() { this.spinner.show();
    this.CustomerAgreementsService.getRoleTypes().subscribe((responce) => {

      this.RoleTypesList = responce.data.map(value => {
        return {
          label: value.roleTypeId,
          value: value.roleTypeId
        };
      });
    });
    this.spinner.hide();
  }

  getAgreementTypeList() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {

      this.AgreementTypeList = responce.data.map(value => {
        return {
          label: value.agreementTypeId,
          value: value.agreementTypeId
        };
      });
    });
    this.spinner.hide();
  }

  getAgreementById(): void {
    this.spinner.show();
    this._AccountingApService.getAgreementById(this.agreementId).then(data => {
      this.agreementValue = data.data;
     
      this.updateCustomerAgreements.patchValue({
        ...data.data, ...{
          fromDate: data.data.fromDate ? new Date(data.data.fromDate) : '',
          thruDate: data.data.thruDate ? new Date(data.data.thruDate) : '',
          agreementDate: data.data.agreementDate ? new Date(data.data.agreementDate) : ''
        }
      });
      this.spinner.hide();
    });
  
  }

  getdata(data) {
    this.updateCustomerAgreements.patchValue({
      facilityGroupTypeId: data.facilityGroupTypeId,
      facilityGroupName: data.facilityGroupName,
      description: data.description,
      facilityGroupId: data.facilityGroupId,
    });
  }
  submit(): void {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementList({
      agreementDate:'',
      agreementId:  this.agreementId,
      agreementIdSearchType: 'Contains',
      agreementTypeId: '',
      fromDateFrom: '',
      fromDateFromSearchType: 'Greater Than',
      fromDateTo: '',
      fromDateToSearchType: 'Less Than',
      partyIdFrom: '',
      partyIdTo: '',
      productId: ''
    }).subscribe((res: any) => {
     
      if (res.success) {
       
        this.agreementList = res.data[0];
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
    });
   
  }
  cancelSubmit(){
    this._Router.navigate(["/financial/payable/customer-agreeement"])
  }  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  getAgreementTermList() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTermList(this.agreementId).subscribe((responce: any) => {
     
      this.AgreementTermList = responce.data;
      this.spinner.hide();
    });
    
  }

  createAgreementTerms(): void {
    this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/create-agreement-terms`], { queryParams: { agreementID: this.agreementId } });
  }
  getAgreementItemList(): void {
    this.spinner.show();
    this._AccountingApService.getAgreementItemList(this.agreementId, { pageNo: 1, pageSize: 200 })
      .then(data => {
        this.agreementItemList = data.data;
        this.spinner.hide();
      });
     
  }
  deleteAgreementItem(agreementItemSeqId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._AccountingApService.deleteAgreementItem(this.agreementId, agreementItemSeqId)
        .then(data => {
          if (data.success) {
            this.getAgreementItemList();
          }
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}
    }) 
  }

  getAgreementRole(): void {
    this.spinner.show();
    this._AccountingApService.getAgreementRoleList(this.agreementId, { pageNo: 1, pageSize: 200 })
      .then(data => {
        this.agreementRoles = data.data;
        this.spinner.hide();
      });
     
  }
  removeAgreementContent(agreementContentTypeId: string, agreementItemSeqId: string, contentId: string, contentName: string, contentTypeId: string, fromDate: string, statusId: string, thruDate: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._AccountingApService.removeAgreementContent({ agreementContentTypeId, agreementId: this.agreementId, agreementItemSeqId, contentId, contentName, contentTypeId, fromDate: moment(fromDate).format('YYYY-MM-DD HH:mm:ss'), statusId, thruDate: moment(thruDate).format('YYYY-MM-DD HH:mm:ss') })
        .then(data => {
          if (data.success) {
            this.getAgreementContent();
          }
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        ) }
    })
  }
  getAgreementContent(): void {
    this.spinner.show();
    this._AccountingApService.getAgreementContentList(this.agreementId, { pageNo: 1, pageSize: 200 })
      .then(data => {
        this.agreementContents = data.data;
      });
      this.spinner.hide();
  }
  setNavigation(agreementItemSeqId: string, currency: string): void {
    window.localStorage.setItem('currency', currency);
    this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreementId, agreementItemSeqId } });
  }
  downloadAgreementContent(contentId: string, contentName: string): void {
    this.spinner.show();
    this._AccountingApService.downloadAgreementContent(contentId)
      .subscribe(value => {
        const blob = new Blob([value], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        this.spinner.hide();
        link.download = contentName;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
      });
     
  }
  deleteAgreementTerm(agreementTermId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.CustomerAgreementsService.deleteAgreementTerm(this.agreementId, agreementTermId)
        .then(data => {
          if (data.success) {
            this.getAgreementTermList();
          }
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })     }
  removeAgreementRole(partyId: string, roleTypeId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._AccountingApService.removeAgreementRole({
          agreementId: this.agreementId,
          partyId, roleTypeId
        }).then(data => {
            if (data.success) {
              this.getAgreementRole();
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        ) } })  }
  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}