import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ChartType } from 'chart.js';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AddEmployeePositionService } from 'src/app/human-resource/add-employee-position/add-employee-position.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

import { Location } from '@angular/common';


@Component({
  selector: 'app-main-reports',
  templateUrl: './main-reports.component.html',
  styleUrls: ['./main-reports.component.css']
})
export class MainReportsComponent implements OnInit {
  name = 'Angular 6';
  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart;
  lineChartData: ChartDataSets[] = [
    { data: [], label: '#Payslip' }
  ];

  //Labels shown on the x-axis
  lineChartLabels: Label[] = [];

  // Define chart options
  lineChartOptions: ChartOptions = {
    responsive: true
  };

  // Define colors of chart segments
  lineChartColors: Color[] = [

    { // dark grey
      backgroundColor: '#7FBDFF',
      borderColor: '#007bff',
    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
    }
  ];

  // Set true to show legends
  lineChartLegend = true;

  // Define type of chart
  lineChartType = 'line';

  lineChartPlugins = [];

  DropDownsArray: any[] = [];
  DropDowns: any;
  LineChartNumberOfDays: any;
  PieChart: any;
  PieChartData: any;
  activeCategory = 6;

  // events
  chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems, data) {
          return data.datasets[0].data[tooltipItems.index] + ' %';
        }
      }
    },
  };


  pieChartLabels: Label[] = [''];

  pieChartData: number[] = [];

  pieChartType: ChartType = 'pie';

  pieChartLegend = true;

  pieChartPlugins = [];

  pieChartColors = [
    {
      backgroundColor: ['#007bff', '#007bff', '#007bff'],
    },
  ];

  constructor(
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly toastr: ToastrService,
    private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router
  ) { }

  ngOnInit(): void {
    this.getDropDown();
    this.getLineChartNumberOfDays();

    this.getPieChart();
    this.getPieChartData();


  }
  showNumberOfDays() {
    this.getLineChartNumberOfDays();
  }
  showNumberOfHours() {
    this.getLineChartNumberOfHours();
  }
  backWindow() {
    this._location.back();
  }
  toDashboard() {
    this.router.navigate(['/financial/main']);
  }

  getPieChartData() {
    this.accountsService.getPieChartData().subscribe((res: any) => {
      this.spinner.show();
      this.pieChartLabels = res.data[0];
      this.pieChartData = res.data[1];

      this.spinner.hide();
    })
  }

  getPieChart() {
    this.accountsService.getPieChart().subscribe((res: any) => {
      this.spinner.show();
      this.PieChart = res.data[0];
      this.spinner.hide();
    })
  }

  getLineChartNumberOfDays() {
    this.accountsService.getLineChartNumberOfDays().subscribe((res: any) => {
      this.spinner.show();
      this.lineChartLabels = res.data[0];
      this.lineChartData = [
        { data: res.data[1], label: '#Payslip' }
      ];
      this.spinner.hide();
    })
  }

  getLineChartNumberOfHours() {
    this.accountsService.getLineChartNumberOfHours().subscribe((res: any) => {
      this.spinner.show();
      this.lineChartLabels = res.data[0];
      this.lineChartData = [
        { data: res.data[1], label: '#Payslip' }
      ];
      this.spinner.hide();
    })
  }

  getDropDown() {

    this.spinner.show();
    this.accountsService.getDropDowns().subscribe(res => {
      this.DropDowns = res.data;
      this.spinner.hide();
      for (const value of this.DropDowns) {
        this.DropDownsArray.push({
          label: value,
          value: value
        })
      }
    })
  }

  onSubmitPdf() {

    this.spinner.show();
    this.accountsService.ReportDaysHoursToXLSX().subscribe(res => {
      const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "downloadPDF";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }

  findPaycheck() {
    this.router.navigate(['financial/main-find-paycheck']);
  }
  createPaychecks() {
    this.router.navigate(['/financial/main-create-paycheck']);

  }
  paycheckConfiguration() {
    this.router.navigate(['/financial/main-configuration']);

  }
  employeess() {
    this.router.navigate(['/financial/employees-profile']);

  }
  reports() {
    this.router.navigate(['/financial/main-reports']);

  }

  contractReferencees() {
    this.router.navigate(['/financial/contractor']);

  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }


}

