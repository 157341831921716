import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { SortEvent } from 'primeng/api';
import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-party-communication-list',
  templateUrl: './party-communication-list.component.html',
  styleUrls: ['./party-communication-list.component.css']
})
export class PartyCommunicationListComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  search= '';
  accountsList:any[]=[]
  relatedContact:any[]=[]
  rows = 50;
  activeCategory=2;
  activeCategoryValue=2;
  createCommEvent:FormGroup;
  opportunityIdArray: { label: string; value: string; }[];
  getInitailData: any;
  stausIdArray: any[]=[];
  comtypeIdArray: any[]=[];
  contentMMArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  contactListId: any;
  contactListIdArray: any[]=[];
  datetimeEnded: string;
  datetimeStarted: string;
  parentCommEventArray: any[]=[];
  reasonenumArray: any[]=[];
  contactMech: any;
  contactMechArray: any[]=[];
  contactMechTypeArray: any[]=[];
  contactMechTo: any;
  contactMechToArray: any[]=[];
  readonly req = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  getOverviewList: any;
  commEventIdPartyId: string;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,  private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService
  ) { 
    this.createCommEvent = this._FormBuilder.group({
      commEventId: [''],
      parCommEventID: [''],
      commTypeId: [''],
      status:[''],
      partyFrom:[''],
      partyTo: [''],
      conMechId: [''],
      fromContactMechId: [''],
      toContactMechId:[''],
      roleTypeFrom:[''],
      roleTypeTo: [''],
      contactListId: [''],
      startdate: [''],
      finishDate:[''],
      subject:[''],
      reasonEnumId: [''],
      contentMimeTypeId: [''],
      content: [''],
      note:[''],
 
    });
    this.accountsList=[{
      'product':'admin'
    }]
  }
 ngAfterViewInit(): void { this.spinner.show(); } 
  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
    const partyId: string = localStorage.getItem('partyId');
    this.commEventIdPartyId=partyId
    this.getCrmListDetail();
    this.getDropdownList();
    this.getPartyId();
    this.getRoleId();
    this.getContactList();
  }
  homeButton(){
    this.router.navigate(['/communication-overview/Dashboard'])

  }
  cancelSubmit(){
    this._location.back();
  }
  communicationEvents(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
  
          this.accountsService.communicationEvents(product.communicationEventId).subscribe(res => {
            this.getCrmListDetail(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
 
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getDropdownList() {
    this.spinner.show();
   
    this.accountsService.getCrmInitialCommData().subscribe((res: any) => {
    const stausId = res.data.commEventstatus;
    const comtypeId=res.data.commEventTypes;
    const contentMM=res.data.mimeType;
    const parentCommEvent=res.data.parentCommEvent;
    const reasonenum=res.data.reasonEnum;
    const contactMechType=res.data.contactMechType;
    this.spinner.hide();
    for (const value of contactMechType) {
      this.contactMechTypeArray.push({
        label: value.description,
        value: value.contactMechTypeId
      })
    } 
    for (const value of reasonenum) {
      this.reasonenumArray.push({
        label: value.description,
        value: value.enumId
      })
    } 
    for (const value of parentCommEvent) {
      this.parentCommEventArray.push({
        label: value.communicationEventId,
        value: value.communicationEventId
      })
    } 
      for (const value of stausId) {
        this.stausIdArray.push({
          label: value.description,
          value: value.statusId
        })
      } 
      for (const value of comtypeId) {
        this.comtypeIdArray.push({
          label: value.description,
          value: value.communicationEventTypeId
        })
      } 
      for (const value of contentMM) {
        this.contentMMArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      } 
    })
     

    
   
  }
  
  getRoleId() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.roleTypeId = res.data.roleType;
      this.spinner.hide();
      for (const value of this.roleTypeId) {
        this.roleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  
  }
  contactEventMech(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMech = res.data;
      this.spinner.hide();
      for (const value of this.contactMech) {
        this.contactMechArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
   
  }
  contactEventMechTo(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMechTo = res.data;
      this.spinner.hide();
      for (const value of this.contactMechTo) {
        this.contactMechToArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
  
  }
  
  getContactList() {
    this.spinner.show();
    this.accountsService.getContactList().subscribe(res => {
      this.contactListId = res.data;
      this.spinner.hide();
      for (const value of this.contactListId) {
        this.contactListIdArray.push({
          label: value.description,
          value: value.contactListId
        })
      }
    })
  
  }
  
  onSubmit() {
    this.spinner.show();
    const fromActualdate=this.createCommEvent.get('startdate').value;
    this.datetimeStarted=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createCommEvent.get('finishDate').value;
    this.datetimeEnded=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "communicationEventId": '',
      "communicationEventTypeId": this.createCommEvent.value.commTypeId,
      "contactListId": this.createCommEvent.value.contactListId,
      "contactMechIdFrom": this.createCommEvent.value.fromContactMechId,
      "contactMechIdTo": this.createCommEvent.value.toContactMechId,
      "contactMechTypeId": this.createCommEvent.value.conMechId,
      "content": this.createCommEvent.value.content,
      "contentMimeTypeId": this.createCommEvent.value.contentMimeTypeId,
      "datetimeEnded": this.datetimeEnded,
      "datetimeStarted": this.datetimeStarted,
      "messageId": '',
      "note": this.createCommEvent.value.note,
      "parentCommEventId": this.createCommEvent.value.parCommEventID,
      "partyIdFrom": this.createCommEvent.value.partyFrom,
      "partyIdTo": this.createCommEvent.value.partyTo,
      "reasonEnumId": this.createCommEvent.value.reasonEnumId,
      "roleTypeIdFrom": this.createCommEvent.value.roleTypeFrom,
      "roleTypeIdTo":this.createCommEvent.value.roleTypeTo,
      "statusId":this.createCommEvent.value.status,
      "subject": this.createCommEvent.value.subject,
    }

    this.accountsService.postCommunicationNew(requestData).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getCrmListDetail();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  
  getCrmListDetail() {
    this.spinner.show();
    const data={
      "communicationEventId": "",
      "communicationEventIdSearchType": "Contains",
      "communicationEventTypeId": "",
      "parentCommEventId": "",
      "parentCommEventIdSearchType": "Contains",
      "partyId": "",
      "partyIdFrom": "",
      "partyIdTo":  this.commEventIdPartyId,
      "roleTypeId": "",
      "statusId": "",
      "subject": "",
      "subjectSearchType": ""
    }
   
    this.accountsService.crmMainPageData(data, this.req).subscribe((res: any) => {
      this.getOverviewList = res.data;
      this.spinner.hide();

    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
 
      
      accountDetails(id){
        this.router.navigate(['/crm/main-detail-summary'], { queryParams: { commEventId: id} });

      }
      ngOnDestroy(): void {
        this.spinner.hide();
          } 
          replyMail(){
            this.router.navigate(['/crm/new-communication-crm']);
          }
}
