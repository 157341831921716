<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
             
                   <span class="color-black pl-1 titlePanels">Security Group List</span>
                   <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
               

            </div>

            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords">Search</span>
                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCenter">Create New Security Group</button>

                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 70%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>	
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select formWidth">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Group Id</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="groupIdIdArray" [(ngModel)]="advanceSearch.groupId" [ngModelOptions]="{standlone:true}" name="groupId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter User Id" filter="true" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Description</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="descriptionIdArray" [(ngModel)]="advanceSearch.description" [ngModelOptions]="{standlone:true}" name="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" filter="true" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                
                
                                                    </div>

                                                    <div class="col-lg-4 col-12 main-submit-button findButton"><button _ngcontent-cta-c128="" (click)="advanceSearchContacts(false)" type="submit" class="btn btn-secondary submit-btn">Find</button> &nbsp;
                                                        <button _ngcontent-cta-c128="" (click)="reset()" type="submit" class="btn btn-danger ml-2">Reset</button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <!-- <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New Security Group</button>
                                        <button type="button" (click)="createUser()" class="btn btn-outline-secondary active" >Create New
                                                Security Group</button>


                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select formWidth">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Group Id</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="groupIdIdArray" [(ngModel)]="advanceSearch.groupId" [ngModelOptions]="{standlone:true}" name="groupId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter User Id" filter="true" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Description</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="descriptionIdArray" [(ngModel)]="advanceSearch.description" [ngModelOptions]="{standlone:true}" name="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" filter="true" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-lg-4 col-12 main-submit-button findButton"><button _ngcontent-cta-c128="" (click)="advanceSearchContacts(false)" type="submit" class="btn btn-secondary submit-btn">Find</button> &nbsp;
                                        <button _ngcontent-cta-c128="" (click)="reset()" type="submit" class="btn btn-danger ml-2">Reset</button>
                                    </div>
                                </div> -->
                                <br>
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Security Group List
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="securtiyList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Security Group ID
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="name">
                                                                    <div style="color: white;">Description
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>


                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td style="cursor: pointer;">
                                                                    <div class="checkbox-align" (click)="permisiionPage(product.groupId)">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <a class="account-button">
                                                                        {{product.groupId}}</a>

                                                                    </div>
                                                                </td>
                                                                <td>{{product.description}}</td>



                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="!show" class="color-black pl-1">  Create Security Group</span>
                <span *ngIf="show" class="color-black pl-1">  Update Security Group</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey create-new-table">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                          Create Security Group</a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                            Update Security Group</a></li>

                            </ul>

                        </div>
                        <div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" role="tablist" aria-multiselectable="true">
                                                <div class="panel panel-default">

                                                    <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                                                        <div class="panel-body">
                                                            <div class="mt-2">
                                                                <div class="">
                                                                    <div class="row">
                                                                        <form class="w-100" [formGroup]="createGroup">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select formWidth">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightFormD">
                                                                                            <label class="common-labels">
                                                            Security Group ID	<span
                                                            style="color:red">*</span></label>
                                                                                        </div>
                                                                                        <div class="col-lg-3">
                                                                                            <input type="text" class="form-control" formControlName="groupId" placeholder="Enter Security Group ID" id="exampleInputEmail1">
                                                                                            <small class="text-danger" *ngIf="!!createGroup.controls.groupId.invalid && !!createGroup.controls.groupId.touched"> Group Id is required!</small>


                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                            <label class="common-labels">Name</label>
                                                                                        </div>
                                                                                        <div class="col-lg-3">
                                                                                            <input type="text" class="form-control" formControlName="groupName" placeholder="Enter Name" id="exampleInputEmail1">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightFormD">
                                                                                            <label class="common-labels">Description</label>
                                                                                        </div>
                                                                                        <div class="col-lg-3">
                                                                                            <input type="text" class="form-control" formControlName="description" placeholder="Enter Description" id="exampleInputEmail1">
                                                                                        </div>
                                                                                        <div class="col-lg-2"></div>

                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                            <div class="submit-btn-sales main-submit-button" style="padding-right:8%;margin-top:-8px">
                                                                                <button *ngIf="!show" (click)="submit()" type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                                                                <button *ngIf="show" (click)="update()" type="submit" class="btn btn-secondary submit-btn">Update</button>

                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>