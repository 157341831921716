import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-create-financial-transaction',
  templateUrl: './create-financial-transaction.component.html',
  styleUrls: ['./create-financial-transaction.component.css']
})
export class CreateFinancialTransactionComponent implements OnInit {
  show: boolean;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  activeCategory = 2;
  search=""
  readonly finSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,

  }
  createTransaction: FormGroup;
  finTypeArray: any[] = [];
  stausIdArray: any[] = [];
  personId: any;
  partyIdArray: any[] = [];
  giAccountArray: any[] = [];
  finAccountId: any;
  actualfromdate: string;
  actualthroughdate: string;
  orderIdArray: any[]=[];
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,
  ) {
    this.createTransaction = this._FormBuilder.group({
      amount: [''],
      comments: [''],
      entryDate: [''],

      finAccountTransTypeId: ['',[Validators.required]],
      glAccountId: ['',[Validators.required]],
      glReconciliationId: [''],
      orderId: [''],
      orderItemSeqId: [''],
      partyId: [''],
      paymentId: [''],
      reasonEnumId: [''],
      statusId: ['',[Validators.required]],
      transactionDate: [''],


    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.finAccountId = params["finAccountId"];

    });
    this.getfinAccountTransTypeId();
    this.getfinancialTransactionStatus();
    this.getPartyId();
    this.getGiAccountId();
    this.financialOrderId();
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getfinancialTransactionStatus() {
    this.spinner.show();
    this.accountsService.financialTransactionStatus().subscribe(res => {
      const stausId = res.data;
      this.spinner.hide();
      for (const value of stausId) {
        this.stausIdArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
   
  }
  financialOrderId(){
    this.spinner.show();
    const req={
        "billingAccountId": "", "correspondingPoId": "","countryGeoId": "","createdBy": "","externalId": "",
        "filterInventoryProblems": "","filterPOsOpenPastTheirETA": "","filterPOsWithRejectedItems": "","filterPartiallyReceivedPOs": "",
        "gatewayAvsResult": "","gatewayScoreResult": "","goodIdentificationIdValue": "","goodIdentificationTypeId": "",
        "hasBackOrders": "","hideFields": "Y","includeCountry": "Y","internalCode": "","inventoryItemId": "","isViewed": "",
        "lookupFlag": "Y","maxDate": "","minDate": "","orderId": "","orderName": "",
  "orderStatusId": [
        ""
        ], "orderTypeId": "", "orderWebSiteId": "", "partyId": "","paymentStatusId": "","productId": "","productStoreId": "","roleTypeId": "",
        "salesChannelEnumId": "","serialNumber": "","shipmentMethod": "","showAll": "Y","softIdentifier": "",
        "userLoginId": ""
        }
    this.accountsService.financialOrderId(this.finSize,req).subscribe((res:any)=> {
      const orderId= res.data.listOrders;
      this.spinner.hide();
      for (const value of orderId) {
        this.orderIdArray.push({
          label: value.orderId,
          value: value.orderId
        })
      }
    })
   
   } 
  getGiAccountId() {
    this.spinner.show();
    this.accountsService.getGioAccountId().subscribe(res => {
      const giAccount = res.data.glAccounts;
      this.spinner.hide();
      for (const value of giAccount) {
        this.giAccountArray.push({
          label: value.glAccountId,
          value: value.glAccountId
        })
      }
    })
   
  }
  getfinAccountTransTypeId() {
    this.spinner.show();
    this.accountsService.finAccountTransTypeId().subscribe(res => {
      const finType = res.data;
      this.spinner.hide();
      for (const value of finType) {
        this.finTypeArray.push({
          label: value.description,
          value: value.finAccountTransTypeId
        })
      }
    })
   
  }
  onUpdate() {

  }
  
  onSubmit() {
    this.spinner.show();
    const fromActualdate = this.createTransaction.get('entryDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createTransaction.get('transactionDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "amount": this.createTransaction.value.amount,
      "comments": this.createTransaction.value.comments,
      "entryDate":  this.actualfromdate,"finAccountId": this.finAccountId,
      "finAccountTransTypeId": this.createTransaction.value.finAccountTransTypeId,
      "glAccountId": this.createTransaction.value.glAccountId,
      "glReconciliationId": this.createTransaction.value.glReconciliationId,
      "orderId": this.createTransaction.value.orderId,
      "orderItemSeqId": this.createTransaction.value.orderItemSeqId,
      "partyId": this.createTransaction.value.partyId,
      "paymentId": this.createTransaction.value.paymentId,
      "reasonEnumId":this.createTransaction.value.reasonEnumId,
      "statusId":this.createTransaction.value.statusId,
      "transactionDate":  this.actualthroughdate
    }
    this.accountsService.createFinancialAccountTransaction(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/detail-financial-summary"], { queryParams: { finAccountId: this.finAccountId } })
    }}, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }});}
  cancelSubmit() {
    this.router.navigate(["financial/detail-financial-summary"], { queryParams: { finAccountId: this.finAccountId } })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
