
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LeadSourceModel } from './leadSource-model';

@Injectable({
  providedIn: 'root'
})


export class LeadSourceService {
  lead_source_form: FormGroup;


  constructor(public _formBuilder: FormBuilder,
    public leadSourceModel: LeadSourceModel
  ) { }

  leadSourceForm(data: any): void {
    if (!this.lead_source_form) {
      this.lead_source_form = this._formBuilder.group(this.leadSourceModel.mapDataToModel(data));

    }

  }

}




