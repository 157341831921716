<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="margin-bottom: 0 !important;">
                <span class="color-black pl-1 titlePanels">Current Project: </span>

                <span class="">

                    <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard border_wdth_1">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Promotion</a>
                            </li>
                            <li hidden [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a>Promotion</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                <a>Rules</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                <a>Stores</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)">
                                <a>Promotion Code</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)">
                                <a>Content</a>
                            </li>
                            <li hidden [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)">
                                <a>Promo Code</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategory==1" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Summary</p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit"
                                                            class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                                                            data-toggle="modal"
                                                            data-target="#updatepopup">Update</button>
                                                        <!--                                                     <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#newpopup">New Product Promo</button> -->
                                                    </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select"
                                                    *ngIf="this.ProductByProductPromoId">
                                                    <div class="color-black container">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Promotion Id</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.productPromoId}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Promo Name</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.promoName}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Promo Text</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.promoText}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Fixed</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">

                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">User Entered</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.userEntered}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Promo Show to Customer</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.showToCustomer}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Req. Code</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.requireCode}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Use Limit Per Order</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.useLimitPerOrder}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Use Limit Per Customer</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.useLimitPerCustomer}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Use Limit Per Promotion</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.useLimitPerPromotion}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Billback Factor</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.billbackFactor}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Override Org Party ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.overrideOrgPartyId}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Created Date</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.createdDate
                                                                            |date:'yyyy-MM-dd hh:mm:ss'}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Created By User</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.createdByUserLogin}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Last Modified Date</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.lastModifiedDate
                                                                            |date:'yyyy-MM-dd hh:mm:ss'}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Last Modified By User</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.ProductByProductPromoId.lastModifiedByUserLogin}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==2" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Promotion For: {{productPromoId}}</p>
                                                    <span>
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#newpopup">New Product Promo</button>
                                                    </span>
                                                </div>
                                                <form class="w-100" [formGroup]="editProductPromo">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Promotion
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Promo Name
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="promoName"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Promo Name">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Promo Text
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="promoText"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Promo Text">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">User Entered
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true"
                                                                        formControlName="userEntered"
                                                                        optionlabel="label"
                                                                        placeholder="Enter User Entered">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Promo Show to
                                                                        Customer
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true"
                                                                        formControlName="promoShow" optionlabel="label"
                                                                        placeholder="Enter Promo Show to Customer">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Req. Code
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="reqCode"
                                                                        optionlabel="label"
                                                                        placeholder="Enter Req. Code">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Use Limit Per Order
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="limitOrder"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Use Limit Per Order">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Use Limit Per
                                                                        Customer
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="limitCust"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Use Limit Per Customer">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Use Limit Per
                                                                        Promotion
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="limitPromo"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Use Limit Per Promotion">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Billback Factor
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="billbackFactor"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Billback Factor">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Override Org Party
                                                                        ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true"
                                                                        formControlName="orgPartyID" optionlabel="label"
                                                                        placeholder="Enter Override Org Party ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Created Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="createdDate"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Created Date">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Created By User
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="createdByUser"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Created By User">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Last Modified
                                                                        Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="modifiedDate"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Last Modified Date">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Last Modified By
                                                                        User
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="modifiedByUser"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Last Modified By User">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-10 col-10 main-submit-button">
                                                        <button type="submit"
                                                            class="btn btn-secondary submit-btn">Update</button>
                                                    </div>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Last Modified By:
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2 form-group">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Last Created By:
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2 form-group">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==3" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Rules For: {{productPromoId}}</p>
                                                </div>
                                                <div
                                                    class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                    <div class="w3-card-4 classCardView w-100 mt-2">
                                                        <div class="header-tabs" >
                                                            <h4 class="common-styling h4Margin">
                                                                Edit Promo Rules
                                                            </h4>
                                                            <span *ngIf="!this.Hidebtn">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                    style="margin: 0;" data-toggle="modal"
                                                                    data-target="#newPromopopup">Add Promo Rule</button>


                                                            </span>
                                                        </div>
                                                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                                                            *ngFor="let main of promoRulesList">
                                                            <table class="table table-striped">
                                                                <thead>
                                                                    <tr style="background: #0d3769;">
                                                                        <th style="color: white; width: 150px;">Rule ID
                                                                        </th>
                                                                        <th style="color: white;">Rule Name</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style="width:20% !important;">
                                                                            <label>Rule</label>
                                                                        </td>
                                                                        <td>
                                                                            <form class="w-100">
                                                                                <div class="row">
                                                                                    <div class="col-lg-6">
                                                                                        <div
                                                                                            class="form-group width_150">
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                [(ngModel)]="main.pRules.ruleName"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Rule Name">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-6">
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="updateProductPromoRule(main)">Update</button>
                                                                                        <button type="submit"
                                                                                            *ngIf="!this.promoRulesList.pRules"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="deleteProductPromoRule(main)">Delete</button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>

                                                                            <div
                                                                                *ngFor="let q of main.ConditionManagerData">
                                                                                <div>
                                                                    <tr>
                                                                        <td style="width:20% !important;">
                                                                            <label>Conditions for Rule</label> 
                                                                        </td>


                                                                        <td>
                                                                            <div class="w-100">
                                                                                <div class="row">
                                                                                    <div class="floatingDiv">
                                                                                        <label>New</label>
                                                                                    </div>
                                                                                    <div class="floatingDiv">
                                                                                        <div class="width_150">
                                                                                            <p-dropdown filter="true"
                                                                                                [options]="enumCodeIdArray"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                [(ngModel)]="q.conditionManager.customMethodId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="floatingDiv">
                                                                                        <label></label>
                                                                                    </div>
                                                                                    <div class="floatingDiv">
                                                                                        <div class="width_150">
                                                                                            <p-dropdown filter="true"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                [(ngModel)]="q.conditionManager.operatorEnumId"
                                                                                                [options]="conditionValuesArray"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="floatingDiv">
                                                                                        <label>Condition
                                                                                            Value</label>
                                                                                    </div>
                                                                                    <div class="floatingDiv">
                                                                                        <div
                                                                                            class="form-group width_150">
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                [(ngModel)]="q.conditionManager.condValue"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="floatingDiv">
                                                                                        <label>Other</label>
                                                                                    </div>
                                                                                    <div class="floatingDiv">
                                                                                        <div
                                                                                            class="form-group width_150">
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="floatingDiv">
                                                                                        <label>Select Shipping
                                                                                            Method</label>
                                                                                    </div>
                                                                                    <div class="floatingDiv">
                                                                                        <div class="width_150">
                                                                                            <p-dropdown filter="true"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                [(ngModel)]="q.conditionManager.otherValue"
                                                                                                [options]="ShipmentMethodListArray"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="floatingDiv">
                                                                                        <label>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="floatingDiv">
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="updateProductPromoCond(q)">Update
                                                                                        </button>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="deleteProductPromoCond(q)">Delete
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>


                                                                    </tr>

                                                                    <tr>
                                                                        <div>
                                                                            <div> 
                                                                                <label>Conditions Categories For
                                                                                    Condition :
                                                                                </label>
                                                                            </div>

                                                                    <tr *ngFor="let product of q.categoryList">
                                                                        <td> {{product.productCategoryId}} </td>
                                                                        <td>{{product.productPromoApplEnumId}}
                                                                        </td>
                                                                        <td>{{product.includeSubCategories}}
                                                                        </td>
                                                                        <td>{{product.productPromoCondSeqId}}
                                                                        </td>
                                                                        <td>{{product.andGroupId}}
                                                                        </td>
                                                                        <td> <button
                                                                                class="btn btn-secondary submit-btn"
                                                                                (click)="deleteProductActCategory(product)">Delete</button>
                                                                        </td>
                                                                    </tr>
                                                        </div>
                                                        </tr>

                                                        <tr>
                                                            <td><label>Conditions for Rule </label> </td>
                                                            <td>

                                                                <form [formGroup]="AddConditionCategoryForm"
                                                                    class="w-100">
                                                                    <div class="row">
                                                                        <div class="floatingDiv">
                                                                            <div class="width_150">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="productCategoryListArray"
                                                                                    formControlName="category"
                                                                                    optionlabel="label">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="floatingDiv">
                                                                            <div class="width_150">
                                                                                <p-dropdown filter="true"
                                                                                    formControlName="categoryType"
                                                                                    [options]="productPromoEnumTypeIdListArray"
                                                                                    optionlabel="label">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="floatingDiv">
                                                                            <div class="width_150">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="yesNoArray"
                                                                                    formControlName="categoryConsent"
                                                                                    optionlabel="label">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="floatingDiv">
                                                                            <label>And Group<span
                                                                                    style="color: red;">*</span></label>
                                                                        </div>
                                                                        <div class="floatingDiv">
                                                                            <div class="form-group width_150">
                                                                                <input type="text" class="form-control"
                                                                                    formControlName="group"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp">
                                                                            </div>
                                                                        </div>
                                                                        <div class="floatingDiv">
                                                                            <button type="submit"
                                                                                class="btn btn-secondary submit-btn"
                                                                                (click)="createProductActionCategory(q)">Add
                                                                                Condition Category</button>
                                                                        </div>
                                                                    </div>
                                                                </form>

                                                            </td>
                                                        </tr>
                                                        <tr>

                                                            <div>
                                                                <div> <label>Conditions Products For Condition :
                                                                    </label></div>

                                                        <tr *ngFor="let product of q.promoProductList">
                                                            <td> {{product.productId}} </td>
                                                            <td>{{product.productPromoApplEnumId}}
                                                            </td>
                                                            <td>{{product.productPromoCondSeqId}}
                                                            </td>
                                                            <td> <button class="btn btn-secondary submit-btn"
                                                                    (click)="deleteProductConditionProduct(product)">Delete</button>
                                                            </td>
                                                        </tr>
                                                    </div>
                                                    </tr>
                                                    <tr>
                                                        <td>Conditions for Rule </td>
                                                        <td>
                                                            <form [formGroup]="createProducts" class="w-100">
                                                                <div class="row">
                                                                    <div class="floatingDiv">
                                                                        <div class="width_150">
                                                                            <p-dropdown filter="true"
                                                                                formControlName="product"
                                                                                [options]="CatalogProductIdArray"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="floatingDiv">
                                                                        <div class="width_150">
                                                                            <p-dropdown filter="true"
                                                                                formControlName="productType"
                                                                                [options]="productPromoEnumTypeIdListArray"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="floatingDiv">
                                                                        <button type="submit"
                                                                            class="btn btn-secondary submit-btn"
                                                                            (click)="createProductActionProduct(q)">Add
                                                                            Condition Products</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </td>
                                                    </tr>

                                                </div>
                                            </div>
                                            </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <form [formGroup]="createConditionForm" class="w-100">
                                                        <div class="row">
                                                            <div class="floatingDiv">
                                                                <label>New</label>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="width_150">
                                                                    <p-dropdown filter="true"
                                                                        [options]="enumCodeIdArray"
                                                                        formControlName="enumCode" optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="width_150">
                                                                    <p-dropdown filter="true"
                                                                        formControlName="condition"
                                                                        [options]="conditionValuesArray"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <label>Condition
                                                                    Value</label>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="form-group width_150">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="ConditionVal"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp">
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <label>Other</label>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="form-group width_150">
                                                                    <input type="text" formControlName="other"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp">
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <label>Select Shipping
                                                                    Method</label>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="width_150">
                                                                    <p-dropdown filter="true"
                                                                        formControlName="ShippingMethod"
                                                                        [options]="ShipmentMethodListArray"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="createProductPromoCond(main)">Create
                                                                    Condition
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </form>
                                                </td>
                                            </tr>



                                            <tr>
                                                <div *ngFor="let action of main.actionManagerData">
                                            <tr>
                                                <td>Actions for Rule </td>
                                                <td>
                                                    <div>
                                                        <label>Action </label>
                                                    </div>
                                                    <div class="w-100">
                                                        <div class="row">
                                                            <div class="floatingDiv">
                                                                <div class="width_150">
                                                                    <p-dropdown filter="true"
                                                                        [options]="enumCodeIdArray"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        [(ngModel)]="action.pActionDataIterate.customMethodId"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <label>Quantity</label>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="form-group width_150">
                                                                    <input type="text" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        [(ngModel)]="action.pActionDataIterate.quantity"
                                                                        aria-describedby="emailHelp">
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <label>Amount</label>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="form-group width_150">
                                                                    <input type="text" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        [(ngModel)]="action.pActionDataIterate.amount"
                                                                        aria-describedby="emailHelp">
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <label>Item ID</label>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="width_150">
                                                                    <p-dropdown filter="true"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        [(ngModel)]="action.pActionDataIterate.productId"
                                                                        [options]="CatalogProductIdArray"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <label>Party</label>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="width_150">
                                                                    <p-dropdown filter="true" [options]="partyIdArray"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        [(ngModel)]="action.pActionDataIterate.partyId"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <label>Service Name</label>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="form-group width_150">
                                                                    <input type="text" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        [(ngModel)]="action.pActionDataIterate.serviceName"
                                                                        aria-describedby="emailHelp">
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <label>Use Cart Quantity</label>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="width_150">
                                                                    <p-dropdown filter="true"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        [(ngModel)]="action.pActionDataIterate.useCartQuantity"
                                                                        [options]="yesNoArray" optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)=" updateProductPromoAction(action)">Update</button>
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)=" deleteProductPromoAction(action)">Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>

                                            </tr>

                                            <tr>
                                                <div>
                                                    <label>Actions Categories For Action: </label>

                                            <tr *ngFor="let product of action.actionCategoryList">
                                                <td> {{product.productCategoryId}} </td>
                                                <td> {{product.productPromoApplEnumId}} </td>
                                                <td> {{product.includeSubCategories}} </td>
                                                <td>{{product.andGroupId}}
                                                </td>

                                                <td> <button *ngIf="!this.Hidebtn" class="btn btn-secondary submit-btn"
                                                        (click)="deleteProductActCategory(product)">
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <form [formGroup]="actionCategory" class="w-100">
                                                        <div class="row">
                                                            <div class="floatingDiv">
                                                                <div class="width_150">
                                                                    <p-dropdown filter="true"
                                                                        [options]="productCategoryListArray"
                                                                        formControlName="category" optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="width_150">
                                                                    <p-dropdown filter="true"
                                                                        formControlName="categoryType"
                                                                        [options]="productPromoEnumTypeIdListArray"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="width_150">
                                                                    <p-dropdown filter="true" [options]="yesNoArray"
                                                                        formControlName="categoryConsent"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <label>And Group<span
                                                                        style="color: red;">*</span></label>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <div class="form-group width_150">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="group" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp">
                                                                </div>
                                                            </div>
                                                            <div class="floatingDiv">
                                                                <button type="submit"
                                                                    (click)="addActionCategory(action)"
                                                                    class="btn btn-secondary submit-btn">Add
                                                                    Action Category</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </td>
                                            </tr>

                                            <tr>

                                                <div>
                                                    <div>
                                                        <label>Action Products for Action: </label>
                                                    </div>

                                                    <!-- Action-----Product -->

                                            <tr *ngFor="let product of action.actionProductList">
                                                <td> {{product.productId}} </td>
                                                <td> {{product.productPromoApplEnumId}} </td>
                                                <td> <button *ngIf="!this.Hidebtn" class="btn btn-secondary submit-btn"
                                                        (click)="deleteProductConditionProduct(product)">
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        </div>
                                        </tr>
                                        <tr>
                                            <td>
                                                <form [formGroup]="createProductsForm" class="w-100">
                                                    <div class="row">
                                                        <div class="floatingDiv">
                                                            <div class="width_150">
                                                                <p-dropdown filter="true" formControlName="product"
                                                                    [options]="CatalogProductIdArray"
                                                                    optionlabel="label">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                        <div class="floatingDiv">
                                                            <div class="width_150">
                                                                <p-dropdown filter="true" formControlName="productType"
                                                                    [options]="productPromoEnumTypeIdListArray"
                                                                    optionlabel="label">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                        <div class="floatingDiv">
                                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                                (click)="createCategoryAction(action)">Add
                                                                Action Products</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </td>
                                        </tr>
                                    </div>
                                    </tr>
                            </div>
                            </tr>

                            <tr>
                                <td> <label></label> </td>
                                <td>
                                    <div>
                                        <label>Action </label>
                                    </div>
                                    <form [formGroup]="createAction" class="w-100">
                                        <div class="row">
                                            <div class="col-lg-3">
                                                <label>New</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="width_150">
                                                    <p-dropdown filter="true" formControlName="actionFor"
                                                        [options]="CustomMethodIdArray" optionlabel="label">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <label>Quantity</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="form-group width_150">
                                                    <input type="text" class="form-control" formControlName="quantity"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <label>Amount</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="form-group width_150">
                                                    <input type="text" class="form-control" formControlName="amount"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <label>Item ID</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="width_150">
                                                    <p-dropdown filter="true" [options]="CatalogProductIdArray"
                                                        formControlName="itemID" optionlabel="label">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <label>Party</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="width_150">
                                                    <p-dropdown filter="true" [options]="partyIdArray"
                                                        formControlName="party" optionlabel="label">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <label>Service Name</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="form-group width_150">
                                                    <input type="text" class="form-control"
                                                        formControlName="serviceName" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <label>Use Cart Quantity</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="width_150">
                                                    <p-dropdown filter="true" formControlName="cartQty"
                                                        [options]="yesNoArray" optionlabel="label">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createProductPromoAction(main)">Create Action</button>
                                            </div>
                                        </div>
                                    </form>
                                </td>
                            </tr>


                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="w3-card-4 classCard w-100 mt-2 ml-3" style="width: 97% !important;">
                    <div
                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Promotion Categories
                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                style="margin: 0; " data-toggle="modal" data-target="#newPromoCategorypopup">Add
                                Promotion
                                Categories</button>
                        </p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <p-table [value]="promotionCategoryList" [paginator]="true" [rows]="rows"
                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                        [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                        [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                                <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: white;">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        Promotion Category
                                                        <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Includes
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Consent
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Group
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Action
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{ 'width': '180px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        <span>
                                                            {{product.pPCategoryList.productCategoryId}}</span>
                                                    </div>
                                                </td>
                                                <td [ngStyle]="{ 'width': '180px'}">
                                                    <span>{{product.pPCategoryList.productPromoApplEnumId}}</span>
                                                </td>
                                                <td [ngStyle]="{ 'width': '180px'}">
                                                    <span>{{product.pPCategoryList.includeSubCategories}}</span>
                                                </td>
                                                <td [ngStyle]="{ 'width': '180px'}">
                                                    <span>{{product.pPCategoryList.andGroupId}}</span>
                                                </td>
                                                <td [ngStyle]="{ 'width': '180px'}">
                                                    <span *ngIf="!this.Hidebtn">

                                                        <a class="account-button"
                                                            (click)="deleteProductPromoCategory(product)">Remove</a>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No Record Found
                                                        </h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>

                                    <p class="paginate_data">
                                        View per page
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w3-card-4 classCard w-100 mt-2 ml-3" style="width: 97% !important;">
                    <div
                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Promotion Products
                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                style="margin: 0;" data-toggle="modal" data-target="#newPromoProductspopup">Add
                                Promotion
                                Products</button>
                        </p>

                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <p-table [value]="promotionProductList" [paginator]="true" [rows]="rows"
                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                        [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                        [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                                <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: white;">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        Product ID
                                                        <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Includes
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Action
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{ 'width': '180px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        <span>{{product.genericValue.productId}}</span>
                                                    </div>
                                                </td>

                                                <td [ngStyle]="{ 'width': '180px'}">
                                                    <span>{{product.pPEnumData[0].description}}</span>
                                                </td>
                                                <td [ngStyle]="{ 'width': '180px'}">
                                                    <span *ngIf="!this.Hidebtn">
                                                        <a class="account-button"
                                                            (click)="deleteProductPromoProduct(product)">Remove</a>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No Record Found
                                                        </h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>

                                    <p class="paginate_data">
                                        View per page
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="activeCategory==4" class="w-100">
            <div class="w3-card-4 classCard w-100">
                <div
                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                    <p class="h-text">Stores For: {{productPromoId}}
                        <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2"
                            style="margin: 0;" data-toggle="modal" data-target="#newStorepopup">Add
                            Store Promo</button>
                        <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2"
                            style="margin: 0;" data-toggle="modal" data-target="#newpopup">New
                            Product
                            Promo</button>
                    </p>

                </div>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="col-lg-12 col-12">
                        <div class="form-group">
                            <div class="card own-account-table">
                                <p-table [value]="ProductStoreByProductList" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                    [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                    [customSort]="true">
                                    <ng-template pTemplate="header">
                                        <tr style="background: #0d3769;">
                                            <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                    <p-checkbox styleclass="custom-checkbox" binary="true"
                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                            <div class="ui-helper-hidden-accessible">
                                                                <input type="checkbox" name="undefined"
                                                                    value="undefined">
                                                            </div>
                                                            <div role="checkbox"
                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                            </div>
                                                        </div>
                                                        <!---->
                                                    </p-checkbox>
                                                    Store Name
                                                    <p-sortIcon field="code">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>

                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    From Date Time
                                                    <p-sortIcon field="name">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>
                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    Through Date Time
                                                    <p-sortIcon field="name">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>

                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    Sequence
                                                    <p-sortIcon field="name">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>

                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    Action
                                                    <p-sortIcon field="name">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                        <tr>
                                            <td [ngStyle]="{ 'width': '180px'}">
                                                <div class="checkbox-align">
                                                    <p-checkbox styleclass="custom-checkbox" binary="true"
                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                            <div class="ui-helper-hidden-accessible">
                                                                <input type="checkbox" name="undefined"
                                                                    value="undefined">
                                                            </div>
                                                            <div role="checkbox"
                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                            </div>
                                                        </div>
                                                    </p-checkbox>
                                                    <span style="color: #0d3769;"
                                                        (click)="detailStore(product.productStoreId)">{{product.productStoreId}}</span>
                                                </div>
                                            </td>
                                            <td [ngStyle]="{ 'width': '180px'}">
                                                <span>{{product.fromDate |
                                                    date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                            </td>
                                            <td [ngStyle]="{ 'width': '180px'}">
                                                <span>{{product.thruDate |
                                                    date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                            </td>
                                            <td [ngStyle]="{ 'width': '180px'}">
                                                <span>{{product.sequenceNum}}</span>
                                            </td>
                                            <td [ngStyle]="{ 'width': '180px'}">

                                                <button *ngIf="!this.Hidebtn" type="button"
                                                    class=" btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                                    (click)="updateStore(product)" data-target="#editStorepopup">Update
                                                </button>

                                                <button *ngIf="!this.Hidebtn" type="button"
                                                    class="btn btn-secondary submit-btn ml-2"
                                                    class="btn btn-secondary submit-btn ml-2"
                                                    (click)="promo_deleteProductStorePromoAppl(product)">Remove
                                                </button>

                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                        <tr>
                                            <td>
                                                <span class="text-center">
                                                    <h4 class="ml-2">No Record Found
                                                    </h4>
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>

                                <p class="paginate_data">
                                    View per page
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="activeCategory==5" class="w-100">
            <div class="w3-card-4 classCard w-100">
                <div
                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                    <p class="h-text">Promotion Code For:{{this.productPromoId}} </p>
                    <span>
                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                            style="margin: 0;" (click)="resetPromo()" data-toggle="modal"
                            data-target="#newpromoCodepopup">New
                            Promotion Code</button>
                        <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                            (click)="PromotionManualImported()" *ngIf="this.showImport || !this.Hidebtn">Promotion
                            Manual
                            Imported</button>
                        <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                            (click)="PromotionManualOnly()" *ngIf="!this.showImport || !this.Hidebtn">Manual
                            Only</button>
                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                            style="margin: 0;" data-toggle="modal" data-target="#newpopup">New
                            Product
                            Promo</button>
                    </span>
                </div>
                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                    <div class="w3-card-4 classCardView w-100 mt-2">
                        <div class="header-tabs" >
                            <h4 class="common-styling h4Margin">
                                Find Product Promotion Code
                            </h4>
                            <span>
                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                    style="margin: 0;" data-toggle="modal" data-target="#uploadCodepopup">Upload
                                    Promotion
                                    Codes</button>
                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                    style="margin: 0;" data-toggle="modal" data-target="#newCodepopup">Add
                                    Set of Promotion
                                    Codes</button></span>
                        </div>
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <div class="card own-account-table">
                                        <p-table [value]="PromotionCodeListList" [paginator]="true" [rows]="rows"
                                            scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                            [totalRecords]="total" [scrollable]="true"
                                            (sortFunction)="customSort($event)" [customSort]="true">
                                            <ng-template pTemplate="header">
                                                <tr style="background: #0d3769;">
                                                    <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                        <div class="checkbox-align" style="color: white;">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            Product Promo Code ID
                                                            <p-sortIcon field="code">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>

                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                        <div style="color: white;">
                                                            User Entered
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>
                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                        <div style="color: white;">
                                                            Require Email or Party
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>

                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                        <div style="color: white;">
                                                            Use Limit per Code
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>

                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                        <div style="color: white;">
                                                            Use Limit per Customer
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>

                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                        <div style="color: white;">
                                                            From Date
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>

                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                        <div style="color: white;">
                                                            Through Date
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>

                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                        <div style="color: white;">
                                                            Created Date
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>

                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                        <div style="color: white;">
                                                            Created By User
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>

                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                        <div style="color: white;">
                                                            Last Modified Date
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>

                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                        <div style="color: white;">
                                                            Last Modified by User
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>

                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                        <div style="color: white;">
                                                            Action
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-product>
                                                <tr>
                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <div class="checkbox-align">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                            </p-checkbox>
                                                            <span style="color:#0d3769;cursor:pointer;"
                                                                (click)="promoPage(product.promotionCodeList.productPromoCodeId,product.promotionCodeList.productPromoId)">
                                                                {{product.promotionCodeList.productPromoCodeId}}</span>
                                                        </div>
                                                    </td>
                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <span>{{product.promotionCodeList.userEntered}}</span>
                                                    </td>
                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <span>{{product.promotionCodeList.requireEmailOrParty}}</span>
                                                    </td>
                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <span>{{product.promotionCodeList.useLimitPerCode}}</span>
                                                    </td>
                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <span>{{product.promotionCodeList.useLimitPerCustomer}}</span>
                                                    </td>
                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <span>{{product.promotionCodeList.fromDate
                                                            |
                                                            date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                    </td>
                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <span>{{product.promotionCodeList.thruDate
                                                            | date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                    </td>
                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <span>{{product.promotionCodeList.createdDate
                                                            | date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                    </td>
                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <span>{{product.promotionCodeList.createdByUserLogin}}</span>
                                                    </td>
                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <span>{{product.promotionCodeList.lastModifiedDate
                                                            | date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                    </td>

                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <span>{{product.promotionCodeList.lastModifiedByUserLogin}}</span>
                                                    </td>
                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <span *ngIf="!this.Hidebtn">
                                                            <a class="account-button" style="color:#0d3769"
                                                                data-toggle="modal"
                                                                data-target="#updatePromotionCodepopup"
                                                                (click)="updatePromoCode(product)">Update</a>&nbsp;
                                                            <a class="account-button" style="color:#0d3769"
                                                                (click)="deleteProductPromoCode(product)">Remove</a>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage" let-columns>
                                                <tr>
                                                    <td>
                                                        <span class="text-center">
                                                            <h4 class="ml-2">No Record
                                                                Found
                                                            </h4>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>

                                        <p class="paginate_data">
                                            View per page
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="activeCategory==6" class="w-100">
            <div class="w3-card-4 classCard w-100">
                <div
                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                    <p class="h-text">Product Promo Contents For: {{productPromoId}}</p>
                    <span>
                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                            style="margin: 0;" data-toggle="modal" data-target="#newpopup">New
                            Product
                            Promo</button>
                    </span>
                </div>

                <form class="w-100" [formGroup]="editContents">
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-3 form-group classInput">
                                    <label for="exampleInputEmail1">From Date
                                    </label>
                                </div>
                                <div class="col-lg-2 form-group">
                                    <input type="date" formControlName="fromDate" class="form-control"
                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                        placeholder="Enter From Date">
                                </div>
                                <div class="col-lg-3 form-group classInput">
                                    <label for="exampleInputEmail1">Through Date
                                    </label>
                                </div>
                                <div class="col-lg-2 form-group">
                                    <input type="date" formControlName="thruDate" class="form-control"
                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                        placeholder="Enter Through Date">
                                </div>
                                <div class="col-lg-3 form-group classInput">
                                    <label for="exampleInputEmail1">Upload File
                                    </label>
                                </div>
                                <div class="col-lg-2">
                                    <input type="file" formControlName="file" class="classFile" id="exampleInputEmail1"
                                        aria-describedby="emailHelp">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-10 col-10 main-submit-button">
                        <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                    </div>
                </form>
            </div>
            <div class="w3-card-4 classCardView w-100 mt-2">
                <div class="header-tabs" >
                    <h4 class="common-styling h4Margin">
                        Product Promo Content List
                    </h4>
                </div>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="col-lg-12 col-12">
                        <div class="form-group">
                            <div class="card own-account-table">
                                <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                        <tr style="background: #0d3769;">
                                            <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                    <p-checkbox styleclass="custom-checkbox" binary="true"
                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                            <div class="ui-helper-hidden-accessible">
                                                                <input type="checkbox" name="undefined"
                                                                    value="undefined">
                                                            </div>
                                                            <div role="checkbox"
                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                            </div>
                                                        </div>
                                                        <!---->
                                                    </p-checkbox>
                                                    From Date
                                                    <p-sortIcon field="code">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                        ui-corner-all" value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>

                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    Through Date
                                                    <p-sortIcon field="name">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                        ui-corner-all" value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>

                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    Action
                                                    <p-sortIcon field="name">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                        ui-corner-all" value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                        <tr>
                                            <td [ngStyle]="{ 'width': '180px'}">
                                                <div class="checkbox-align">
                                                    <p-checkbox styleclass="custom-checkbox" binary="true"
                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                            <div class="ui-helper-hidden-accessible">
                                                                <input type="checkbox" name="undefined"
                                                                    value="undefined">
                                                            </div>
                                                            <div role="checkbox"
                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                            </div>
                                                        </div>
                                                    </p-checkbox>
                                                    <span></span>
                                                </div>
                                            </td>
                                            <td [ngStyle]="{ 'width': '180px'}">
                                                <span></span>
                                            </td>
                                            <td [ngStyle]="{ 'width': '180px'}">
                                                <span>
                                                    <a class="account-button">Update</a>&nbsp;<a
                                                        class="account-button">Remove</a>
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                        <tr>
                                            <td>
                                                <span class="text-center">
                                                    <h4 class="ml-2">No Record Found
                                                    </h4>
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>

                                <p class="paginate_data">
                                    View per page
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="activeCategory==7" class="w-100">
            <div class="w3-card-4 classCard w-100">
                <div
                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                    <p class="h-text">Promotion Code For:{{this.productPromoId}} </p>
                    <span>
                        <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                            (click)="resetPromo()" data-toggle="modal" data-target="#newpromoCodepopup">New
                            Promotion Code</button>
                        <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                            data-toggle="modal" data-target="#newpopup">New
                            Product
                            Promo</button>
                        <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                            data-toggle="modal" data-target="#updatePromotionCodepopup">Update Promotion
                            Code</button>
                        <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                            data-toggle="modal" data-target="#promoCodeEmailspopup">New Promo Code
                            Emails</button>
                    </span>
                </div>
                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                    <div class="w3-card-4 classCardView w-100 mt-2">
                        <div class="header-tabs" >
                            <h4 class="common-styling h4Margin">
                                Promo Code Parties
                            </h4>

                            <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                                data-toggle="modal" data-target="#addPartypopup">Add
                                Party ID</button>
                        </div>
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <div class="card own-account-table">
                                        <p-table [value]="promoCodePartiesList" [paginator]="true" [rows]="rows"
                                            scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                            [totalRecords]="total" [scrollable]="true"
                                            (sortFunction)="customSort($event)" [customSort]="true">
                                            <ng-template pTemplate="header">
                                                <tr style="background: #0d3769;">
                                                    <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                        <div class="checkbox-align" style="color: white;">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            Party ID
                                                            <p-sortIcon field="code">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>


                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                        <div style="color: white;">
                                                            Action
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-product>
                                                <tr>
                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <div class="checkbox-align">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                            </p-checkbox>
                                                            <span>{{product.partyId}}</span>
                                                        </div>
                                                    </td>

                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <span>

                                                            <a class="account-button"
                                                                (click)="deleteProductPromoCodeParty(product)">Remove</a>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage" let-columns>
                                                <tr>
                                                    <td>
                                                        <span class="text-center">
                                                            <h4 class="ml-2">No Record
                                                                Found
                                                            </h4>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>

                                        <p class="paginate_data">
                                            View per page
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    </form>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="modal fade" id="newpopup" tabindex="-1" role="dialog" aria-labelledby="newpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Product Promo</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Edit Product Promo</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newProductPromo">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="promoName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Promo Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Text
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="promoText" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Promo Text">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">User Entered
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="arrayValue"
                                                            optionlabel="label" formControlName="userEntered"
                                                            optionlabel="label" placeholder="Enter User Entered">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Show to Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="arrayValue"
                                                            optionlabel="label" formControlName="promoShow"
                                                            optionlabel="label"
                                                            placeholder="Enter Promo Show to Customer">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Req. Code
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="arrayValue"
                                                            optionlabel="label" formControlName="reqCode"
                                                            optionlabel="label" placeholder="Enter Req. Code">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limit Per Order
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitOrder" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Use Limit Per Order">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limit Per Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitCust" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Use Limit Per Customer">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limit Per Promotion
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitPromo" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Use Limit Per Promotion">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Billback Factor
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="billbackFactor" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Billback Factor">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Override Org Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray"
                                                            optionlabel="label" formControlName="orgPartyID"
                                                            optionlabel="label"
                                                            placeholder="Enter Override Org Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="createdDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Created Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created By User
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="createdByUser" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Created By User">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Modified Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="modifiedDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Last Modified Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Modified By User
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="modifiedByUser" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Last Modified By User">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button (click)="createNewProductPromo()" type="submit"
                                                class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="updatepopup" tabindex="-1" role="dialog" aria-labelledby="updatepopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Product Promo</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonA>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Edit Product Promo</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editProductPromo">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Promotion
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{ this.productPromoId}}
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="promoName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Promo Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Text
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="promoText" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Promo Text">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">User Entered
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="arrayValue"
                                                            optionlabel="label" formControlName="userEntered"
                                                            optionlabel="label" placeholder="Enter User Entered">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Show to Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="arrayValue"
                                                            optionlabel="label" formControlName="promoShow"
                                                            optionlabel="label"
                                                            placeholder="Enter Promo Show to Customer">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Req. Code
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="arrayValue"
                                                            optionlabel="label" formControlName="reqCode"
                                                            optionlabel="label" placeholder="Enter Req. Code">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limit Per Order
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitOrder" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Use Limit Per Order">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limit Per Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitCust" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Use Limit Per Customer">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limit Per Promotion
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitPromo" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Use Limit Per Promotion">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Billback Factor
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="billbackFactor" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Billback Factor">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Override Org Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray"
                                                            optionlabel="label" formControlName="orgPartyID"
                                                            optionlabel="label"
                                                            placeholder="Enter Override Org Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="createdDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Created Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created By User
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="createdByUser" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Created By User">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Modified Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="modifiedDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Last Modified Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Modified By User
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="modifiedByUser" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Last Modified By User">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button (click)="updateProductPromo()" type="submit"
                                                class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="editStorepopup" tabindex="-1" role="dialog" aria-labelledby="editStorepopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Product Promo Stores</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonB>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Edit Product Promo Stores</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editProductStore">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Store Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">

                                                        <input type="email" disabled formControlName="storeName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Store Name [ID]">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" disabled formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="thruDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seq"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="promo_updateProductStorePromoAppl()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="newStorepopup" tabindex="-1" role="dialog" aria-labelledby="newStorepopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Promo Stores</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonD>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Promo Stores</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addProductStore">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Store Name [ID]
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="storeName"
                                                            [options]="ProductStoreIdArray" optionlabel="label"
                                                            placeholder="Enter Store Name [ID]">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="promo_createProductStorePromoAppl()">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="uploadCodepopup" tabindex="-1" role="dialog" aria-labelledby="uploadCodepopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Upload Set of Promotion Codes</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton10>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Upload Set of Promotion Codes</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="uploadPromotionCodes">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">User Entered
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="userEntered"
                                                            [options]="yesNoArray" optionlabel="label"
                                                            placeholder="Enter User Entered">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Req. Email or Party
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="regEmailParty"
                                                            [options]="yesNoArray" optionlabel="label"
                                                            placeholder="Enter Req. Email or Party">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limits:Per Code
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitCode" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Per Code">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Per Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitCust" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Per Customer">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Upload File
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="file" formControlName="file" class="classFile"
                                                            (change)="fileProgressCom($event)" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" accept="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="createBulkProductPromoCode()">Upload</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="newCodepopup" tabindex="-1" role="dialog" aria-labelledby="newCodepopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Set of Promotion Codes</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Set of Promotion Codes</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPromotionCodes">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="quantity" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Promo Code Length
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="codeLength" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Product Promo Code Length">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Code Layout
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="codeLayout"
                                                            [options]="promoCodeLayoutArray" optionlabel="label"
                                                            placeholder="Enter Promo Code Layout">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-12 form-group">
                                                        <p>"smart": Avoids equal Chars (0 and O, ...) | "normal":
                                                            alphanumeric | "sequence": Sequence Number</p>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">User Entered
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="userEntered"
                                                            [options]="yesNoArray" optionlabel="label"
                                                            placeholder="Enter User Entered">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Req. Email or Party
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="regEmailParty"
                                                            [options]="yesNoArray" optionlabel="label"
                                                            placeholder="Enter Req. Email or Party">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limits:Per Code
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitCode" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Per Code">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Per Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitCust" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Per Customer">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="createProductPromoCodeSet()">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="newpromoCodepopup" tabindex="-1" role="dialog" aria-labelledby="newpromoCodepopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> New Product Promotion code</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>New Product Promotion code</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newPromotionCode">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product promo Code ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="promoCodeID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Product promo Code ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Promo ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="promoID"
                                                            [options]="PromotionCodeListArray" optionlabel="label"
                                                            placeholder="Enter Product Promo ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">User Entered
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="userEntered"
                                                            [options]="yesNoArray" optionlabel="label"
                                                            placeholder="Enter User Entered">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Require Email or Party
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="reqEmailParty" optionlabel="label"
                                                            placeholder="Enter Require Email or Party">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limit per Code
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitCode" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Use Limit per Code">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limit per Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitCust" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Use Limit per Customer">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="thruDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="createdDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Created Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created by User
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="createdUser" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Created by User">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Modified Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="modifiyDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Last Modified Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Modified by User
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="modifyUser" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Last Modified by User">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="updateProductPromoCode()">Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="newPromopopup" tabindex="-1" role="dialog" aria-labelledby="newPromopopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Promo Rule</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton11>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add New Promo Rule</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newPromoRule">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Rule Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="ruleName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Rule Name">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-5 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="createProductPromoRule()">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="newPromoCategorypopup" tabindex="-1" role="dialog"
    aria-labelledby="newPromoCategorypopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Promotion Categories</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton13>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add New Promotion Categories</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPromoCategory">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Promotion Category
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="category"
                                                            [options]="productCategoryListArray" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Includes
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="categoryType"
                                                            [options]="productPromoEnumTypeIdListArray"
                                                            optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Consent
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="categoryConsent"
                                                            [options]="yesNoArray" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Group
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="group"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp">
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="createProductPromoCategory()">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="newPromoProductspopup" tabindex="-1" role="dialog"
    aria-labelledby="newPromoProductspopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Promotion Products</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton14>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add New Promotion Products</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPromoProduct">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product ID<span
                                                                style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="product"
                                                            [options]="CatalogProductIdArray" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Includes
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="productType"
                                                            [options]="productPromoEnumTypeIdListArray"
                                                            optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="createProductPromoProduct()">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updatePromotionCodepopup" tabindex="-1" role="dialog"
    aria-labelledby="updatePromotionCodepopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> New Product Promotion code</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton9>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>New Product Promotion code</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updatePromotionCode">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product promo Code ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="promoCodeID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Product promo Code ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Promo ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="promoID"
                                                            [options]="PromotionCodeListArray" optionlabel="label"
                                                            placeholder="Enter Product Promo ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">User Entered
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="userEntered"
                                                            [options]="yesNoArray" optionlabel="label"
                                                            placeholder="Enter User Entered">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Require Email or Party
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="reqEmailParty" optionlabel="label"
                                                            placeholder="Enter Require Email or Party">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limit per Code
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitCode" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Use Limit per Code">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limit per Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="limitCust" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Use Limit per Customer">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="thruDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="createdDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Created Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created by User
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="createdUser" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Created by User">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Modified Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="modifiyDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Last Modified Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Modified by User
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="modifyUser" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Last Modified by User">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Modified By:
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="LastModifiedBy" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Last Modified by ">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created By:
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="CreatedBy" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Created by ">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="updateProductCode()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addPartypopup" tabindex="-1" role="dialog" aria-labelledby="addPartypopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Promo Code Parties</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Promo Code Parties</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPartyForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Add Party ID:
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="PartyID"
                                                            [options]="partyIdArray" optionlabel="label"
                                                            placeholder="Choose Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="createProductPromoCodeParty()">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="promoCodeEmailspopup" tabindex="-1" role="dialog"
    aria-labelledby="promoCodeEmailspopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Promo Code Emails</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Promo Code Emails</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="promoCodeEmailsForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Add Email:
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="AddEmail" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Email">
                                                    </div>


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Attach File
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="file" class="form-control" formControlName="File"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter File">
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="updateProductPromoCode()">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>