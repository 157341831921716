import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-bank-reconciliation',
  templateUrl: './bank-reconciliation.component.html',
  styleUrls: ['./bank-reconciliation.component.css']
})
export class BankReconciliationComponent implements OnInit {
  products3: any;
  advanceSearchA: {

    finAccountId: string,
    finAccountTransTypeId: string,
    fromEntryDate: string,
    fromTransactionDate: string,
    glReconciliationId: string,
    statusId: string,
    thruEntryDate: string,
    thruTransactionDate: string

  };
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  finAccountId: any;
  FinancialTransaction: any[];
  finTypeArray: any[] = [];
  giTransArray: any[] = [];
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    readonly toastr: ToastrService,
    readonly datePipe: DatePipe,
    readonly router: Router) {
    this.advanceSearchA = {
      finAccountId: "",
      finAccountTransTypeId: "",
      fromEntryDate: "",
      fromTransactionDate: "",
      glReconciliationId: "",
      statusId: "",
      thruEntryDate: "",
      thruTransactionDate: ""

    };
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
  }
  financialList = [{
    'value': 1
  }]
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.finAccountId = params["finAccountId"];
    });
    this.searchFinancialTransaction();
    this.getfinAccountTransTypeId();
    this.getTransglReconciliationID();
  }
  getTransglReconciliationID() {
    this.spinner.show();
    this.accountsService.getTransglReconciliationID(this.finAccountId).subscribe(res => {
      const giTrans = res.data;
      for (const value of giTrans) {
        this.giTransArray.push({
          label: value.glReconciliationId,
          value: value.glReconciliationId
        })
      }
    })
    this.spinner.hide();
  }
  getfinAccountTransTypeId() {
    this.spinner.show();
    this.accountsService.finAccountTransTypeId().subscribe(res => {
      const finType = res.data;
      for (const value of finType) {
        this.finTypeArray.push({
          label: value.description,
          value: value.finAccountTransTypeId
        })
      }
    })
    this.spinner.hide();
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  advanceSearchTransition(isPopUp: boolean): void {
    this.spinner.show();
    this.FinancialTransaction = [];
    const req = {
      "finAccountId": this.finAccountId,
      "finAccountTransTypeId": this.advanceSearchA.finAccountTransTypeId ? this.advanceSearchA.finAccountTransTypeId : '',
      "fromEntryDate": this.datePipe.transform(this.advanceSearchA.fromEntryDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchA.fromEntryDate, "yyyy-MM-dd hh:mm:ss") : '',
      "fromTransactionDate": this.datePipe.transform(this.advanceSearchA.fromTransactionDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchA.fromTransactionDate, "yyyy-MM-dd hh:mm:ss") : '',
      "glReconciliationId": this.advanceSearchA.glReconciliationId ? this.advanceSearchA.glReconciliationId : '',
      "statusId": "FINACT_TRNS_CREATED",
      "thruEntryDate": this.datePipe.transform(this.advanceSearchA.thruEntryDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchA.thruEntryDate, "yyyy-MM-dd hh:mm:ss") : '',
      "thruTransactionDate": this.datePipe.transform(this.advanceSearchA.thruTransactionDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchA.thruTransactionDate, "yyyy-MM-dd hh:mm:ss") : '',

    }
    this.accountsService.searchFinancialTransaction(req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.FinancialTransaction = resp.data.finAccountTransList;
        } 
      });
    
  }
  resetTransaction() {
    this.spinner.show();
    this.advanceSearchA.finAccountTransTypeId = "";
    this.advanceSearchA.fromEntryDate = "";
    this.advanceSearchA.fromTransactionDate = "";
    this.advanceSearchA.glReconciliationId = "";
    this.advanceSearchA.statusId = "";
    this.advanceSearchA.thruEntryDate = "";
    this.advanceSearchA.thruTransactionDate = "";
    const req = {
      "finAccountId": this.finAccountId,
      "finAccountTransTypeId": "",
      "fromEntryDate": "",
      "fromTransactionDate": "",
      "glReconciliationId": "",
      "statusId": "FINACT_TRNS_CREATED",
      "thruEntryDate": "",
      "thruTransactionDate": ""
    }
    this.accountsService.searchFinancialTransaction(req).subscribe((res: any) => {
      this.FinancialTransaction = res.data.finAccountTransList;
     
      this.spinner.hide();
    })
   
  }
  searchFinancialTransaction() {
    this.spinner.show();
    const req = {
      "finAccountId": this.finAccountId,
      "finAccountTransTypeId": "",
      "fromEntryDate": "",
      "fromTransactionDate": "",
      "glReconciliationId": "",
      "statusId": "FINACT_TRNS_CREATED",
      "thruEntryDate": "",
      "thruTransactionDate": ""
    }
    this.accountsService.searchFinancialTransaction(req).subscribe((res: any) => {
      this.FinancialTransaction = res.data.finAccountTransList;
      this.spinner.hide();

    })
   

  }

  cancelFinancialAccountTransaction(id, status) {

    this.spinner.show();
    this.accountsService.cancelFinancialAccountTransaction(id, this.finAccountId, status).subscribe((res: any) => {
      this.toastr.success("Cancelled Successfully");
      this.spinner.hide();
      this.searchFinancialTransaction();
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.message);
    })
    
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
