<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12" style="padding: 0;">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Information System</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard"  style="border-width: 0px; box-shadow: none;">
                    <!-- <div class="container-fluid" style="border: 1px solid #0d3769; border-left-width: 0px;">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="toHardware()"><a>
                                Hardware</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="toSoftware()" ><a>
                                Software</a></li>
                            
                        </ul>
                    </div> -->
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategory==1">
                                            <div class="create-new-leade">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="bg-white color-grey w-100 ">
                                                                <div class="">
                                                                    <div class="row">
                                                                        <form class="w-100">

                                                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                    Hardware
                                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" 
                                                                                    style="width: 113px;" (click)="toSoftware()" >New Software
                                                                                    </button>
                                                                                </div>
                                                                                <div class="create-new-leade">
                                                                                    <div class="container-fluid" style="padding: 0;">
                                                                                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                                                            <div class="w-100">

                                                                                                <div class="w3-card-4  w-100">
                                                                                                    <div class="accordion classCard" id="myAccordion">
                                                                                                        <div class="accordion-item">
                                                                                                            <h2 class="accordion-header" id="headingOne">
                                                                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                                                                    <span class="headingAccords"> Search</span>
                                                                                                                    <button type="button" class="btn btn-outline-secondary active ml-2"   routerLinkActive="active"
                                                                                                                    data-target="#hardwarePopup" data-toggle="modal">Create New Hardware</button>
                                                                                                
                                                
                                                                                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 70%!important; padding-top: 11px !important;
                                                                                                                        padding-bottom: 11px !important;">
                                                                                                                    </button>									
                                                                        
                                                                                                                </div>
                                                                            
                                                                                                            </h2>
                                                                                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                                                                <div class="card-body">
                                                                                                                    <form [formGroup]="findHardwareForm">
                                                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                                            <div class="col-lg-12">
                                                                                                                                <div class="row">
                                                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                                                        <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                                                                                                    </div>
                                                                                                                                    <div class="col-lg-2">
                                                                                                                                        <input type="email"  formControlName="fixedAssetID" class="form-control" id="exampleInputEmail1" 
                                                                                                                                            aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                                                                                            
                                                                                                                                    </div>
                                                                                            
                                                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                                                        <label for="exampleInputEmail1">Class Enum ID</label>
                                                                                                                                    </div>
                                                                                            
                                                                                                                                    <div class="col-lg-2">
                                                                                                                                        <input type="email"  formControlName="classEnumID" class="form-control" id="exampleInputEmail1" 
                                                                                                                                            aria-describedby="emailHelp" placeholder="Enter Class Enum ID">
                                                                                                                            
                                                                                                                                        
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                            
                                                                                                                            <div class="col-lg-12">
                                                                                                                                <div class="row">
                                                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                                                        <label for="exampleInputEmail1">Fixed Asset Name</label>
                                                                                                                                    </div>
                                                                                                                                    <div class="col-lg-2">
                                                                                                                                        <input type="email"  formControlName="fixedAssetName" class="form-control" id="exampleInputEmail1" 
                                                                                                                                            aria-describedby="emailHelp" placeholder="Enter Fixed Asset Name">
                                                                                                                            
                                                                                                                                    </div>
                                                                                            
                                                                                            
                                                                                                                                
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                            
                                                                                            
                                                                                            
                                                                                                                        </div>
                                                                                                                    </form>
                                                                
                                                                                                                    <div class="col-lg-10 main-submit-button" style="margin-left: -9% !important;">
                                                                                                                        <button type="submit"  class="btn btn-secondary submit-btn" (click)="ListComputerHardware()">Find</button>&nbsp;
                                                                                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()" >Reset</button>&nbsp;
                                                                                                                    </div>
                                                                
                                                                
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    
                                                                                                </div>

                                                                                                <!-- <div class="w3-card-4 classCardView">
                                                                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                                                    
                                                                                                        <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                                                                            data-target="#hardwarePopup" data-toggle="modal">Create New Hardware</button>
                                                                                                
                                                                                                    </div>
                                                                                                    <form [formGroup]="findHardwareForm">
                                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                            <div class="col-lg-12">
                                                                                                                <div class="row">
                                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                                        <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                                                                                    </div>
                                                                                                                    <div class="col-lg-2">
                                                                                                                        <input type="email"  formControlName="fixedAssetID" class="form-control" id="exampleInputEmail1" 
                                                                                                                            aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                                                                            
                                                                                                                    </div>
                                                                            
                                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                                        <label for="exampleInputEmail1">Class Enum ID</label>
                                                                                                                    </div>
                                                                            
                                                                                                                    <div class="col-lg-2">
                                                                                                                        <input type="email"  formControlName="classEnumID" class="form-control" id="exampleInputEmail1" 
                                                                                                                            aria-describedby="emailHelp" placeholder="Enter Class Enum ID">
                                                                                                            
                                                                                                                        
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                            
                                                                                                            <div class="col-lg-12">
                                                                                                                <div class="row">
                                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                                        <label for="exampleInputEmail1">Fixed Asset Name</label>
                                                                                                                    </div>
                                                                                                                    <div class="col-lg-2">
                                                                                                                        <input type="email"  formControlName="fixedAssetName" class="form-control" id="exampleInputEmail1" 
                                                                                                                            aria-describedby="emailHelp" placeholder="Enter Fixed Asset Name">
                                                                                                            
                                                                                                                    </div>
                                                                            
                                                                            
                                                                                                                
                                                                                                                </div>
                                                                                                            </div>
                                                                            
                                                                            
                                                                            
                                                                                                        </div>
                                                                                                    </form>
                                                                                                    <div class="col-lg-10 main-submit-button" style="margin-left: -9% !important;">
                                                                                                        <button type="submit"  class="btn btn-secondary submit-btn" (click)="ListComputerHardware()">Find</button>&nbsp;
                                                                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()" >Reset</button>&nbsp;
                                                                                                    </div>
                                                                                                </div> -->
                                                                                                <div class="w3-card-4 classCardView mt-2">
                                                                                                    <div class="header-tabs" >
                                                                                                        <h4 class="common-styling h4Margin">
                                                                                                            Search Results
                                                                                                        </h4>
                                                                                                    </div>
                                                                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                                        <div class="col-lg-12 col-12">
                                                                                                            <div class="form-group">
                                                                        
                                                                                                                <div class="card own-account-table">
                                                                                                                    <p-table [value]="computerHardwareFindData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                        <ng-template pTemplate="header">
                                                                                                                            <tr style="background: #0d3769;">
                                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                </div>
                                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </p-checkbox>
                                                                        
                                                                                                                                        Fixed Asset ID	
                                                                                                                                    </div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </th>
                                                                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                    <div style="color: white;">
                                                                                                                                        Class Enum ID	
                                                                                                                                    </div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                    <div style="color: white;">
                                                                                                                                        Fixed Asset Parent ID
                                                
                                                                                                                                    </div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </th>
                                                                        
                                                                                                                                
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                                            <tr>
                                                                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                                                                    <div class="checkbox-align">
                                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                </div>
                                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </p-checkbox>
                                                                                                                                       
                                                                                                                                        <span class="account-button"
                                                                                                                                        (click)="detail_page(product.dataNode.fixedAssetId)" >
                                                                                                                                        {{product.dataNode.fixedAssetId}}
                                                                                                                                            </span>
                                                                        
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                        
                                                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                                                    {{product.dataNode.classEnumId}}
                                                                                                                                </td>
                                                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                                                    {{product.dataNode.parentFixedAssetId}}
                                                                                                                                </td>
                                                                                                                             
                                                                        
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                        
                                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                            <tr>
                                                                                                                                <td>
                                                                                                                                    <span class="text-center">
                                                                                                                                <h4
                                                                                                                                    class="ml-2">
                                                                                                                                    No Record
                                                                                                                                    Found</h4>
                                                                                                                            </span>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                    </p-table>
                                                                        
                                                                                                                    <p class="paginate_data">
                                                                                                                        View per page </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>  
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    
                                            </div>
                                            
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Hardware
                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" 
                                    style="width: 113px;" (click)="toSoftware()" >New Software
                                    </button>
                                </div>
                                <div class="create-new-leade">
                                    <div class="container-fluid" style="padding: 0;">
                                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w-100">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                    
                                                        <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                            data-target="#hardwarePopup" data-toggle="modal">Create New Hardware</button>
                                                
                                                    </div>
                                                    <form [formGroup]="findHardwareForm">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="fixedAssetID" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                            
                                                                    </div>
                            
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Class Enum ID</label>
                                                                    </div>
                            
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="classEnumID" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Class Enum ID">
                                                            
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                            
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Fixed Asset Name</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="fixedAssetName" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Fixed Asset Name">
                                                            
                                                                    </div>
                            
                            
                                                                
                                                                </div>
                                                            </div>
                            
                            
                            
                                                        </div>
                                                    </form>
                                                    <div class="col-lg-10 main-submit-button" style="margin-left: -9% !important;">
                                                        <button type="submit"  class="btn btn-secondary submit-btn" (click)="ListComputerHardware()">Find</button>&nbsp;
                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()" >Reset</button>&nbsp;
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Search Results
                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                        
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="computerHardwareFindData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                        
                                                                                        Fixed Asset ID	
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Class Enum ID	
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Fixed Asset Parent ID

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                       
                                                                                        <span  class="account-button"  
                                                                                        (click)="detail_page(product.dataNode.fixedAssetId)" >
                                                                                        {{product.dataNode.fixedAssetId}}
                                                                                            </span>
                        
                                                                                    </div>
                                                                                </td>
                        
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.classEnumId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.parentFixedAssetId}}
                                                                                </td>
                                                                             
                        
                                                                            </tr>
                                                                        </ng-template>
                        
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                        
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->



<div class="modal fade" id="hardwarePopup" tabindex="-1" role="dialog" aria-labelledby="hardwarePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create New Hardware</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create New Hardware</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class=" mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createNewHardwareForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Fixed Asset ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="fixedAssetID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Fixed Asset Parent ID	
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="fixedAssetParentID" [options]="parentFixedAssetIdArray"
                                                        placeholder="Select Fixed Asset Parent ID">

                                                        </p-dropdown>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Instance Of Product ID	
		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="instanceOfProductID"  [options]="ProductIdArray"
                                                        placeholder="Select Instance Of Product ID">

                                                        </p-dropdown>
                                                        
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Class Enum ID			
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="classEnumID"  [options]="classEnumIdArray"
                                                        placeholder="Select Class Enum ID">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Fixed Asset Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="fixedAssetName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Fixed Asset Name">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Acquire Order ID			
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="acquireOrderID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Acquire Order ID">
                                               
                                                       
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Acquire Order Item Seq ID		
	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="acquireOrderItemSeqID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Acquire Order Item Seq ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Date Acquired</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="dateAcquired" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Date Last Serviced	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="dateLastServiced" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Date Next Service</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="dateNextService" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Expected End Of Life</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="expectedEndOfLife" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Actual End Of Life	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="actualEndOfLife" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Production Capacity	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="productionCapacity" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Production Capacity">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">UOM		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="UOMId"  [options]="umoIdArray"
                                                        placeholder="Select UOM">
                                                        </p-dropdown>
                                                        
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Calendar</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="calendar"  [options]="calendarArray"
                                                        placeholder="Select Calendar">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Serial Number</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="serialNumber" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Serial Number">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Located At Facility ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="locatedAtFacilityID"   [options]="FacilityIDArray"
                                                        placeholder="Select Located At Facility ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Located At Location Seq ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="locatedAtLocationSeqID"  
                                                        [options]="LocationSeqIdArray"
                                                        placeholder="Select Located At Location Seq ID">
                                                        </p-dropdown>
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Salvage Value</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="salvageValue" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Salvage Value">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Depreciation				
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="depreciation" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Depreciation">
                                               
                                                       
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Purchase Cost	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="purchaseCost" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Purchase Cost">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Purchase Cost Uom ID					
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="purchaseCostUomID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Purchase Cost Uom ID">
                                               
                                                       
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                          
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createComputerHardware()">Create</button>
                                              
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>
