import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderManagementService } from '../order-management.service';

@Component({
    selector: 'app-receive-payment',
    templateUrl: './order-receive-payment.component.html'
})
export class OrderReceivePaymentComponent implements OnInit {

    receivePaymentMethods: any;
    orderId: string;
    partyId: string;
    paymentMethods: any[];
    paymentTypes: any[];
    isSales: boolean;


    constructor(
        readonly _OrderManagementService: OrderManagementService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ToastrService: ToastrService,
        readonly spinner: NgxSpinnerService,
        readonly  _Router: Router
    ) {
        this.isSales = false;
        this.orderId = '';
        this.partyId = '';
        this.paymentMethods = [];
        this.paymentTypes = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.orderId = this._ActivatedRoute.snapshot.queryParams.orderId;
        this.isSales = this._ActivatedRoute.snapshot.queryParams.isSales === 'true' ? true : false;
        this.getReceivePaymentMethod();
    }
    getReceivePaymentMethod(): void {
        this.spinner.show();
        this._OrderManagementService.getReceivePayment(this.orderId)
            .then(data => {
                this.spinner.hide();
                this.partyId = data.data.partyId;
                data.data?.paymentMethods.filter(value => {
                    this.paymentMethods = {
                        ...this.paymentMethods, ...{
                            [`${value.paymentMethodId}_amount`]: '',
                            [`${value.paymentMethodId}_reference`]: ''
                        }
                    }
                });
                data.data?.paymentMethodTypes.filter(value => {
                    this.paymentTypes = {
                        ...this.paymentTypes, ...{
                            [`${value.paymentMethodTypeId}_amount`]: '',
                            [`${value.paymentMethodTypeId}_reference`]: ''
                        }
                    }
                });
                this.receivePaymentMethods = data.data;
            });
          
    }

    createReceivePayment(): void {
        this.spinner.show();
        this._OrderManagementService.createReceivePayment(this.orderId, this.partyId, {
            ...this.paymentMethods, ...this.paymentTypes
        })
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this._ToastrService.success('Payment Received');
                    this.navigation();
                }
            });
            this.spinner.hide();
    }
    navigation(): void {
        this.isSales ?
            this._Router.navigate(['/order/sales-order-view-page'], { queryParams: { orderId: this.orderId } }) :
            this._Router.navigate(['/order/purchase-order-view-page'], { queryParams: { orderId: this.orderId } });
    }
}