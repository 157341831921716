import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-party-content',
  templateUrl: './add-party-content.component.html',
  styleUrls: ['./add-party-content.component.css']
})
export class AddPartyContentComponent implements OnInit {

  createJournal:FormGroup;
  activeCategory=2;
  partyId: any;
  show=false;
  selectedFileName: any;
  partyContentData: any=[];
  partyContentArray: any[]=[];
  fileData: File;
  partyContentTypeId: any;
  Editform: FormGroup;
  DataCategoryIdList: any;
  DataCategoryIdListArray: any[]=[];
  isPublic=[{
    "label":"Yes",
    "value":"Y"
  },
  {
    "label":"No",
    "value":"N"
  }]
  PartyContentTypeIdList: any;
  PartyContentTypeIdListArray: any[]=[];
  PartyStatusIdList: any;
  PartyStatusIdListArray: any[]=[];
  actualfromdate: any;
  actualthroughdate: string;
  contentId: any;
  contentTypeId: any;
  dataResourceId: any;
  fromDate: any;
  statusId:any
  thruDate: any;
  editgetEstimateFrom: string;
  egetEstimateTo: string;
  @ViewChild('closebutton') closebutton;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,private _location: Location,
    readonly spinner: NgxSpinnerService,readonly datePipe: DatePipe,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    public bsModalRef: BsModalRef) {
      this.Editform = this._FormBuilder.group({
        file: [''],
        partyContentTypeId: [''],
        fromDate:[''],
        throughDate:[''],
        ContentTypeID:[''],
        StatusID:[''],
        DataCategoryID:[''],
        IsPublic:['']
       
        
      });
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params['party'];
      this.contentId = params['contentId'];
      this.partyContentTypeId = params['partyContentTypeId'];
      this.contentTypeId = params['contentTypeId'];
      this.fromDate = params['fromDate'];
      this.thruDate = params['thruDate'];
      this.statusId = params['statusId'];
      this.dataResourceId = params['dataResourceId']});
    this.getPartyContent();
    this.getDataCategoryIdList();
    this.getPartyContentTypeIdList();
    this.getPartyStatusIdList();
    if(this.contentId)
    {
      this.show=true
      setTimeout(() => {
        const fromdate= this.fromDate
            this.editgetEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
            const toEstdate=this.thruDate
            this.egetEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const formValue = this.Editform;
      formValue.patchValue({
        partyContentTypeId:this.partyContentTypeId,
        fromDate:this.editgetEstimateFrom,
        throughDate: this.egetEstimateTo,
        ContentTypeID:this.contentTypeId,
        StatusID:this.statusId
    
      })
      }, 3000); 
    }   
    else{
      this.show=false
    }
  
  }

  getPartyContent() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        });
      }
    });

  }
  
  getPartyStatusIdList() {
    this.spinner.show();
    this.accountsService.getPartyStatusIdList().subscribe(res => {
      this.PartyStatusIdList = res.data[0].statusIdList;
      this.spinner.hide();
      for (const value of this.PartyStatusIdList) {
        this.PartyStatusIdListArray.push({
          label: value.description,
          value: value.statusId
        });
      }
    });

  }
  getPartyContentTypeIdList() {
    this.spinner.show();
    this.accountsService.getPartyContentTypeIdList().subscribe(res => {
      this.PartyContentTypeIdList = res.data[0].contentTypeIdList;
      this.spinner.hide();
      for (const value of this.PartyContentTypeIdList) {
        this.PartyContentTypeIdListArray.push({
          label: value.description,
          value: value.contentTypeId
        });
      }
    });

  }
  getDataCategoryIdList() {
    this.spinner.show();
    this.accountsService.getDataCategoryIdList().subscribe(res => {
      this.DataCategoryIdList = res.data[0].dataCategoryIdList;
      this.spinner.hide();
      for (const value of this.DataCategoryIdList) {
        this.DataCategoryIdListArray.push({
          label: value.categoryName,
          value: value.dataCategoryId
        });
      }
    });

  }
  
  onpartyContent(event) {
    this.partyContentTypeId = event;
  }

  fileProgress(fileInput: any){
    this.fileData = <File>fileInput.target.files[0];
  }
 
  onSubmit() {
    this.spinner.show();
    let formData = new FormData();
    formData.append('file', this.fileData);
    const fromActualdate=this.Editform.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.Editform.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "contentTypeId": this.Editform.value.ContentTypeID,
      "dataCategoryId": this.Editform.value.DataCategoryID,
      "fromDate": this.actualfromdate,
      "isPublic": this.Editform.value.IsPublic,
      "partyContentTypeId":this.Editform.value.partyContentTypeId,
      "partyId": this.partyId,
      "statusId": this.Editform.value.StatusID,
      "thruDate":  this.actualthroughdate
    }
   
    
    this.accountsService.createPartyContent(formData,encodeURIComponent(JSON.stringify(requestData))).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success(res.data.successMessage);
        this.closebutton.nativeElement.click();
		    this.bsModalRef.hide();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      this._ToastrService.error(err.message);

    });
    }
    onUpdate() {
      this.spinner.show();
      let formData = new FormData();
      formData.append('file', this.fileData);
      const fromActualdate=this.Editform.get('fromDate').value;
      this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const throughActualdate=this.Editform.get('throughDate').value;
      this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={
        "contentTypeId": this.Editform.value.ContentTypeID,
        "dataCategoryId": this.Editform.value.DataCategoryID,
        "fromDate":this.fromDate,
        "isPublic": this.Editform.value.IsPublic,
        "partyContentTypeId":this.Editform.value.partyContentTypeId,
        "partyId": this.partyId,
        "statusId": this.Editform.value.StatusID,
        "thruDate":  this.actualthroughdate
      }
     
      
      this.accountsService.updatePartyContent(formData,encodeURIComponent(JSON.stringify(requestData)),this.contentId,this.dataResourceId).subscribe((res: any) => {
        if (res.success) {
          this._ToastrService.success(res.data.successMessage);
          this.closebutton.nativeElement.click();
		      this.bsModalRef.hide();
          this.spinner.hide();
          this._location.back();
        }
      }, (err) => {
        this.spinner.hide();
        this._ToastrService.error(err.message);
  
      });
      }

 
  
  cancelSubmit(){
    this.closebutton.nativeElement.click();
		this.bsModalRef.hide();
    this._Router.navigate(['/financial/view-party-profile']);
  }

}
