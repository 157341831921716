<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white 0 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Administration </span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        
            </div>
            <div class="w3-card-4 classCardA ">

                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                        <li [ngClass]="activeCategoryA==1?'active':''" (click)="changeactiveCategoryA(1)"><a>
                                        All Backlog

                                    </a></li>
                        <li [ngClass]="activeCategoryA==2?'active':''" (click)="changeactiveCategoryA(2)">
                            <a>
                                        Timesheet
                                    </a>
                        </li>
                        <li [ngClass]="activeCategoryA==3?'active':''" (click)="changeactiveCategoryA(3)">
                            <a>
                                        Billing</a>
                        </li>
                        <li [ngClass]="activeCategoryA==4?'active':''" (click)="changeactiveCategoryA(4)">
                            <a>
                                        Default Tasks
                                    </a>
                        </li>
                        <li [ngClass]="activeCategoryA==5?'active':''" (click)="changeactiveCategoryA(5)">
                            <a>
                                        Scrum Members
                                    </a>
                        </li>
                        <li [ngClass]="activeCategoryA==6?'active':''" (click)="changeactiveCategoryA(6)">
                            <a>
                                        Quick Add New Tasks
                                    </a>
                        </li>
                        <li [ngClass]="activeCategoryA==7?'active':''" (click)="changeactiveCategoryA(7)">
                            <a>
                                        Quick Add Backlog
                                    </a>
                        </li>
                        <li [ngClass]="activeCategoryA==8?'active':''" (click)="changeactiveCategoryA(8)">
                            <a>
                                        Product Statistics
                                    </a>
                        </li>



                    </ul>
                </div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                    <div class="create-new-leade ">
                        <div class="container-fluid">


                            <div class="panel-group">
                                <div *ngIf="activeCategoryA==1">

                                    <div class="w3-card-4  w-100">
                                        <div class="accordion classCard" id="myAccordion">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                        <span class="headingAccords"> Find Product Backlog Item</span>
                                                       

                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 75%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;">
                                                        </button>									
            
                                                    </div>
                
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                    <div class="card-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Product
                                                                                    Backlog ID
                                                                                </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ProductBacklogID" class="form-control" id="exampleInputEmail1" name="ProductBacklogID" aria-describedby="emailHelp" placeholder="Enter Product Backlog ID">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Status
                                                                                </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="backlogStatus" [ngModelOptions]="{standalone:true}"
                                                                         [(ngModel)]="advanceSearch.Status" filter="true" name="Status" optionlabel="label" placeholder="Choose Status">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <!-- <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Product
                                                                                    ID
                                                                                </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                
                                                                        <input type="email" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ProductID" class="form-control" id="exampleInputEmail1" name="ProductID" aria-describedby="emailHelp" placeholder="Enter Product  ID">
                                                                    </div> -->
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Product
                                                                                    Backlogs
                                                                                    Name
                                                                                </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ProductBacklogsName" name="ProductBacklogsName" aria-describedby="emailHelp" placeholder="Enter Product Backlogs Name ">
                
                                                                    </div>
                
                
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">
                                                                                    Requester
                                                                                    Name
                                                                                </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="RequesterNameArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.RequesterName" name="RequesterName" optionlabel="label" placeholder="Choose Requester Name">
                                                                        </p-dropdown>
                                                                    </div>
                
                
                
                
                
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Backlog
                                                                                    Item
                                                                                    Category
                                                                                </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.BacklogItemCategory" name="BacklogItemCategory" aria-describedby="emailHelp" placeholder="Enter Backlog Item Category ">
                
                                                                    </div>
                
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Request
                                                                                    Date
                                                                                </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                
                                                                        <input type="date" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.RequestDate" name="RequestDate" aria-describedby="emailHelp" placeholder="Enter Request Date">
                                                                    </div>
                
                
                
                
                
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Planned
                
                                                                                </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                
                
                                                                        <p-dropdown [options]="yesNOArray" filter="true" name="Planned" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.Planned" placeholder="Choose Planned Hours">
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Billed
                                                                                </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="yesNOArray" filter="true" name="Billed" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.Billed" placeholder="Choose Billed">
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12  main-submit-button" style="
                                                            margin-left: -24%;">
                             <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getFindTotalBacklog()">Find</button>&nbsp;

                             <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>

                         </div>
                
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>


                                    <!-- <div class="w3-card-4 classCardView">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                flex-wrap: wrap !important;
                                display: flex !important;">
                                                <li><a>
                                                                Find Product Backlog Item
                                                            </a>
                                                </li>

                                            </ul>
                                        </div>


                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product
                                                                    Backlog ID
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ProductBacklogID" class="form-control" id="exampleInputEmail1" name="ProductBacklogID" aria-describedby="emailHelp" placeholder="Enter Product Backlog ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Product
                                                                    ID
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ProductID" class="form-control" id="exampleInputEmail1" name="ProductID" aria-describedby="emailHelp" placeholder="Enter Product  ID">
                                                    </div>





                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product
                                                                    Backlogs
                                                                    Name
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ProductBacklogsName" name="ProductBacklogsName" aria-describedby="emailHelp" placeholder="Enter Product Backlogs Name ">

                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">
                                                                    Requester
                                                                    Name
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="RequesterNameArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.RequesterName" name="RequesterName" optionlabel="label" placeholder="Choose Requester Name">
                                                        </p-dropdown>
                                                    </div>





                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Backlog
                                                                    Item
                                                                    Category
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.BacklogItemCategory" name="BacklogItemCategory" aria-describedby="emailHelp" placeholder="Enter Backlog Item Category ">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Request
                                                                    Date
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="date" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.RequestDate" name="RequestDate" aria-describedby="emailHelp" placeholder="Enter Request Date">
                                                    </div>





                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Planned

                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">


                                                        <p-dropdown [options]="yesNOArray" filter="true" name="Planned" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.Planned" placeholder="Choose Planned Hours">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Billed
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="yesNOArray" filter="true" name="Billed" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.Billed" placeholder="Choose Billed">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="backlogStatus" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.Status" filter="true" name="Status" optionlabel="label" placeholder="Choose Status">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12  main-submit-button" style="
                                                                           margin-left: -24%;">
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getFindTotalBacklog()">Find</button>&nbsp;

                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>

                                        </div>

                                    </div> -->


                                    <div class="w3-card-4 classCardView ">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Find Product Backlog Item


                                                </h4>

                                            </div>
                                        </header>
                                        <div *ngIf="!advanceSearch.ProductID" class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="TotalBacklogList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">


                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;padding-left: 25px !important;">
                                                                            <!-- <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                  
                                                                                </p-checkbox>  -->
                                                                            Product Backlog Id

                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Product

                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'500px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Product Backlog Name
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Category
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Planned Hours
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Billed
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Request Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>




                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                            </p-checkbox>
                                                                            <span (click)="detailNewSprint(product.totalBacklogSt.productId,
                                                                            product.totalBacklogs.custRequestId,product.totalBacklogs.description)" 
                                                                            *ngIf="product.totalBacklogs"  class="account-button">
                                                                                        {{product.totalBacklogs.custRequestId}}</span>

                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'100px'}" >
                                                                      <div *ngIf="product.productNames&&product.totalBacklogSt"
                                                                      (click)="TimesheetPage(product.totalBacklogSt.productId)"  class="account-second-button"> 
                                                                      {{product.productNames.internalName + product.totalBacklogSt.productId}}</div>  
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'500px'}">
                                                                        <div *ngIf="product.totalBacklogs"> {{product.totalBacklogs.description}} </div>  
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div *ngIf="product.totalBacklogs">  {{product.totalBacklogs.statusId}} </div>     
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div *ngIf="product.totalBacklogs"> {{product.totalBacklogs.parentCustRequestId}} </div>   
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div *ngIf="product.totalBacklogs">   {{product.totalBacklogs.custEstimatedMilliSeconds}}</div>  
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div *ngIf="product.totalBacklogs">  {{product.totalBacklogs.billed}} </div> 
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <div *ngIf="product.totalBacklogs">  {{product.totalBacklogs.custRequestDate | date:'yyyy-MM-d hh:mm:ss'}}</div>  
                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                                    <h4
                                                                                        class="ml-2">
                                                                                        No
                                                                                        Record
                                                                                        Found
                                                                                    </h4>
                                                                                </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="advanceSearch.ProductID" class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="TotalBacklogList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">


                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;padding-left: 25px !important;">
                                                                            <!-- <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox" binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                              
                                                                            </p-checkbox>  -->
                                                                            Seq

                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>


                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Product

                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'500px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Product Backlog Item
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Category
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Planned Hours
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Billed
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Request Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>




                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                            </p-checkbox>
                                                                            <span (click)="detailOverviewPage(product.totalBacklogSt.productId)"  class="account-button">
                                                                                        {{product.totalBacklogSt.custRequestId}}</span>

                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                        {{product.totalBacklogSt.productId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'500px'}">
                                                                        {{product.totalBacklogSt.description}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.totalBacklogs.statusId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.totalBacklogSt.parentCustRequestId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.totalBacklogSt.custEstimatedMilliSeconds}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.totalBacklogSt.billed}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.totalBacklogSt.custRequestDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                                    <h4
                                                                                        class="ml-2">
                                                                                        No
                                                                                        Record
                                                                                        Found
                                                                                    </h4>
                                                                                </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="activeCategoryA==2">

                                    <div class=" bg-white color-grey">
                                        <div class="create-new-leade ">
                                            <div class="">
                                                <div class="row">
                                                    <form class="w-100">

                                                        <div class="w3-card-4  w-100">
                                                            <div class="accordion classCard" id="myAccordion">
                                                                <div class="accordion-item">
                                                                    <h2 class="accordion-header" id="headingOne">
                                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                            <span class="headingAccords"> Find
                                                                                TimeSheet</span>
                                                                           
                    
                                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 82%!important; padding-top: 11px !important;
                                                                                padding-bottom: 11px !important;">
                                                                            </button>									
                                
                                                                        </div>
                                    
                                                                    </h2>
                                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                        <div class="card-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group classInputAB">
                                                                                            <label for="exampleInputEmail1">Timesheet
                                                                                                        ID
            
            
                                                                                                    </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
            
                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" name="TimesheetID" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchTimeSheet.TimesheetID" aria-describedby="emailHelp" placeholder="Enter Timesheet ID">
                                                                                        </div>
            
                                                                                        <div class="col-lg-3 form-group classInputAB">
                                                                                            <label for="exampleInputEmail1">
                                                                                                        Comments
                                                                                                    </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" name="Comments" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchTimeSheet.Comments" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group classInputAB">
                                                                                            <label for="exampleInputEmail1">Party
            
                                                                                                    </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true" name="Party" [options]="PartyTimesheetIdArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchTimeSheet.Party" optionlabel="label" placeholder="Choose Party">
                                                                                            </p-dropdown>
                                                                                        </div>
            
                                                                                        <div class="col-lg-3 form-group classInputAB">
                                                                                            <label for="exampleInputEmail1">Status
            
                                                                                                    </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="TimesheetStatusArray" filter="true" name="Status" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchTimeSheet.Status" optionlabel="label" placeholder="Choose Status">
                                                                                            </p-dropdown>
                                                                                        </div>
            
            
            
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
            
            
                                                                                        <div class="col-lg-3 form-group classInputAB">
                                                                                            <label for="exampleInputEmail1">
                                                                                                        From
                                                                                                        Date
                                                                                                    </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="date" class="form-control" id="exampleInputEmail1" name="FromDate" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchTimeSheet.FromDate" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                                        </div>
            
                                                                                        <div class="col-lg-2">
                                                                                            <input type="date" class="form-control" id="exampleInputEmail1" name="throughDate" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchTimeSheet.throughDate" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                                        </div>
            
            
            
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12  main-submit-button" style="
                                                                                                           margin-left: -23%;">
                                                                                <button _ngcontent-cta-c128="" type="submit" (click)="getFindTimeSheet()" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                                <button _ngcontent-cta-c128="" type="submit" (click)="resetTimeSheet()" class="btn btn-danger ">Reset</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>


                                                        <!-- <div class="w3-card-4 classCard">
                                                            <div class="container-fluid">
                                                                <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;">
                                                                    <li><a>
                                                                                    Find
                                                                                    TimeSheet

                                                                                </a>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                            <form>

                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInputAB">
                                                                                <label for="exampleInputEmail1">Timesheet
                                                                                            ID


                                                                                        </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <input type="email" class="form-control" id="exampleInputEmail1" name="TimesheetID" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchTimeSheet.TimesheetID" aria-describedby="emailHelp" placeholder="Enter Timesheet ID">
                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInputAB">
                                                                                <label for="exampleInputEmail1">
                                                                                            Comments
                                                                                        </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control" id="exampleInputEmail1" name="Comments" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchTimeSheet.Comments" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    



                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInputAB">
                                                                                <label for="exampleInputEmail1">Party

                                                                                        </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" name="Party" [options]="PartyTimesheetIdArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchTimeSheet.Party" optionlabel="label" placeholder="Choose Party">
                                                                                </p-dropdown>
                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInputAB">
                                                                                <label for="exampleInputEmail1">Status

                                                                                        </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown [options]="TimesheetStatusArray" filter="true" name="Status" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchTimeSheet.Status" optionlabel="label" placeholder="Choose Status">
                                                                                </p-dropdown>
                                                                            </div>



                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">


                                                                            <div class="col-lg-3 form-group classInputAB">
                                                                                <label for="exampleInputEmail1">
                                                                                            From
                                                                                            Date
                                                                                        </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control" id="exampleInputEmail1" name="FromDate" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchTimeSheet.FromDate" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                            </div>

                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control" id="exampleInputEmail1" name="throughDate" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchTimeSheet.throughDate" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                            </div>



                                                                        </div>
                                                                    </div>



                                                                </div>
                                                                <div class="col-lg-12  main-submit-button" style="
                                                                                               margin-left: -23%;">
                                                                    <button _ngcontent-cta-c128="" type="submit" (click)="getFindTimeSheet()" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                    <button _ngcontent-cta-c128="" type="submit" (click)="resetTimeSheet()" class="btn btn-danger ">Reset</button>
                                                                </div>
                                                            </form>

                                                        </div> -->

                                                        <div class="w3-card-4 classCard mt-3">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling h4Margin">
                                                                        Timesheet list
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="TimeSheetList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">


                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'250px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </p-checkbox>

                                                                                                Timesheet ID

                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Party
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Actual Hours
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Status
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                From Date
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Through Date
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Status to 'complete'
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Status to In Process
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>


                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'250px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                   
                                                                                                </p-checkbox>
                                                                                                <div>
                                                                                                    <span 
                                                                                                    *ngIf="product.TimeSheethours.timesheetId">
                                                                                                                {{product.TimeSheethours.timesheetId}}</span>

                                                                                                </div>


                                                                                            </div>
                                                                                        </td>

                                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                                            <div>
                                                                                                <span  *ngIf="product.TimeSheethours.partyId">
                                                                                                            {{product.TimeSheethours.partyId}}
                                                                                                        </span>
                                                                                            </div>

                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                                            <div >
                                                                                                <span  *ngIf="product.ActualHours[0]">
                                                                                                            {{product.ActualHours[0].planHours ? product.ActualHours[0].planHours: ''}}
                                                                                                        </span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                                            <div>
                                                                                                <span  *ngIf="product.TimeSheethours.statusId">
                                                                                                            {{product.TimeSheethours.statusId}}
                                                                                                        </span>
                                                                                            </div>

                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                                            <div>
                                                                                                <span  *ngIf="product.TimeSheethours.fromDate">
                                                                                                            {{product.TimeSheethours.fromDate
                                                                                                            |
                                                                                                            date:'yyyy-MM-d  hh:mm:ss'}}
                                                                                                        </span>
                                                                                            </div>

                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                                            <div>
                                                                                                <span  *ngIf="product.TimeSheethours.thruDate">
                                                                                                            {{product.TimeSheethours.thruDate
                                                                                                            |
                                                                                                            date:'yyyy-MM-d  hh:mm:ss'}}
                                                                                                        </span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No
                                                                                                            Record
                                                                                                            Found
                                                                                                        </h4>
                                                                                                    </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="activeCategoryA==3">
                                    <div class=" bg-white color-grey">
                                        <div class="create-new-leade ">
                                            <div class="">
                                                <div class="row">
                                                    <form class="w-100">


                                                        <br>
                                                        <div class="create-new-leade ">
                                                            <div class="container-fluid">
                                                                <div class="row">

                                                                    <form class="w-100">

                                                                        <div>

                                                                            <div class="w3-card-4  w-100">
                                                                                <div class="accordion classCard" id="myAccordion">
                                                                                    <div class="accordion-item">
                                                                                        <h2 class="accordion-header" id="headingOne">
                                                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                                                <span class="headingAccords"> Find Time Entry Not yet Billed</span>
                                                                                               
                                        
                                                                                                <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 72%!important; padding-top: 11px !important;
                                                                                                    padding-bottom: 11px !important;">
                                                                                                </button>									
                                                    
                                                                                            </div>
                                                        
                                                                                        </h2>
                                                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                                            <div class="card-body">
                                                                                                <form [formGroup]="billingForm">
                                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                                                                                                                <div class="col-lg-3 form-group classInputAB">
                                                                                                                    <label for="exampleInputEmail1">
                                                                                                                                Product
                                                                                                                                Name
                
                
                                                                                                                            </label>
                                                                                                                </div>
                                                                                                                <div class="col-lg-2">
                                                                                                                    <p-dropdown filter="true" [options]="ProjectSprintProductNameArray"
                                                                                                                     name="ProductName" optionlabel="label" [ngModelOptions]="{standalone:true}"
                                                                                                                      [(ngModel)]="advanceSearchA.ProductName" placeholder="Enter 
                                                                                                                                Product Name ">
                                                                                                                    </p-dropdown>
                
                
                
                                                                                                                </div>
                
                                                                                                                <div class="col-lg-3 form-group classInputAB">
                                                                                                                    <label for="exampleInputEmail1">From
                                                                                                                                Date
                                                                                                                            </label>
                                                                                                                </div>
                                                                                                                <div class="col-lg-2">
                                                                                                                    <input type="date" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchA.FromDate" name="FromDate" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                                                                </div>
                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        
                
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                                                                                                                <div class="col-lg-3 form-group classInputAB">
                                                                                                                    <label for="exampleInputEmail1">Through
                                                                                                                                Date
                                                                                                                            </label>
                                                                                                                </div>
                                                                                                                <div class="col-lg-2">
                                                                                                                    <input type="date" class="form-control" id="exampleInputEmail1" name="ThroughDate" 
                                                                                                                    [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchA.ThroughDate" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                                                                </div>
                
                                                                                                                <div class="col-lg-3 form-group classInputAB">
                                                                                                                    <label for="exampleInputEmail1">Include
                                                                                                                                Meeting
                                                                                                                            </label>
                                                                                                                </div>
                
                                                                                                                <div class="col-lg-2">
                
                                                                                                                    <p-dropdown filter="true" [options]="yesNOArray"  [ngModelOptions]="{standalone:true}" 
                                                                                                                    [(ngModel)]="advanceSearchA.IncludeMeeting" name="IncludeMeeting" optionlabel="label" placeholder="Choose Include Meeting">
                                                                                                                    </p-dropdown>
                                                                                                                </div>
                
                                                                                                            </div>
                                                                                                        </div>
                
                                                                                                        
                                                                                                    </div>
                                                                                                    <div class="col-lg-12  main-submit-button" style="margin-left: -22%;">
                                                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getFindBilling()">Find</button>&nbsp;
                
                                                                                                        <button type="button" class="btn btn-danger ml-2" (click)="resetBilling()">Reset</button>
                
                                                                                                    </div>
                                                                                                </form>
                    
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </div>


                                                                            <!-- <div class="w3-card-4 classCard">
                                                                                <div class="container-fluid">
                                                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;">
                                                                                        <li><a>
                                                                                                        Find
                                                                                                        Time
                                                                                                        Entry
                                                                                                        Not
                                                                                                        yet
                                                                                                        Billed



                                                                                                    </a>
                                                                                        </li>

                                                                                    </ul>
                                                                                </div>
                                                                                <form [formGroup]="billingForm">
                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputAB">
                                                                                                    <label for="exampleInputEmail1">
                                                                                                                Product
                                                                                                                Name


                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown filter="true" [options]="ProjectSprintProductNameArray" name="ProductName" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchA.ProductName" placeholder="Enter 
                                                                                                                Product Name ">
                                                                                                    </p-dropdown>



                                                                                                </div>

                                                                                                <div class="col-lg-3 form-group classInputAB">
                                                                                                    <label for="exampleInputEmail1">From
                                                                                                                Date
                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="date" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchA.FromDate" name="FromDate" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        

                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputAB">
                                                                                                    <label for="exampleInputEmail1">Through
                                                                                                                Date
                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="date" class="form-control" id="exampleInputEmail1" name="ThroughDate" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchA.ThroughDate" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                                                </div>

                                                                                                <div class="col-lg-3 form-group classInputAB">
                                                                                                    <label for="exampleInputEmail1">Include
                                                                                                                Meeting
                                                                                                            </label>
                                                                                                </div>

                                                                                                <div class="col-lg-2">

                                                                                                    <p-dropdown filter="true" [options]="yesNOArray" name="IncludeMeeting" optionlabel="label" placeholder="Choose Include Meeting">
                                                                                                    </p-dropdown>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>

                                                                                        
                                                                                    </div>
                                                                                    <div class="col-lg-12  main-submit-button" style="margin-left: -22%;">
                                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getFindBilling()">Find</button>&nbsp;

                                                                                        <button type="button" class="btn btn-danger ml-2" (click)="resetBilling()">Reset</button>

                                                                                    </div>
                                                                                </form>

                                                                            </div> -->

                                                                            <div class="w3-card-4 classCard ">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs">
                                                                                        <h4 class="common-styling h4Margin" *ngIf="this.billingInvoiceList">

                                                                                            Hours not yet billed Information : Bill From Party 
                                                                                            {{this.billingInvoiceList.billFromParty}} 
                                                                                             Bill To Party  {{this.billingInvoiceList.billToParty}} : 
                                                                                             From Date {{this.billingInvoiceList.productRoles.fromDate|date:'yyyy-MM-dd HH:mm:ss'}}
                                                                                             Through Date {{this.billingInvoiceList.productRoles.thruDate|date:'yyyy-MM-dd HH:mm:ss'}}

                                                                                        </h4>
                                                                                    </div>
                                                                                </header>
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">

                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="billingList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                       
                                                                                                                    </p-checkbox>
                                                                                                                    Project

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Sprint
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'250px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Backlog Item
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Billed

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                        </tr>

                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                       
                                                                                                                    </p-checkbox>
                                                                                                                    Task Name

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Hours
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'250px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Timesheet Party ID
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Timesheet ID
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Rate Type ID
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Date
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                       
                                                                                                                    </p-checkbox>
                                                                                                                    <span (click)="viewcrumProject(product.timeEntryBilled.workEffortId)" class="account-button">
                                                                                                                                {{product.timeEntryBilled.workEffortId}}</span>

                                                                                                                </div>
                                                                                                            </td>

                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.timeEntryBilled.hours}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.timeEntryBilled.partyId}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.timeEntryBilled.timesheetId}}
                                                                                                            </td>

                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.timeEntryBilled.rateTypeId}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.fromDate}}
                                                                                                            </td>
                                                                                                            <!-- <td [ngStyle]="{'width':'180px'}">
                                                
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                        class="ml-20" width="16" height="16"
                                                                                                                        viewBox="0 0 26 26">
                                                                                                                        <defs>
                                                                                                                            <style>
                                                                                                                                .a {
                                                                                                                                    fill: #f44336;
                                                                                                                                }
                                                
                                                                                                                                .b {
                                                                                                                                    fill: #fafafa;
                                                                                                                                    margin-left: 20px;
                                                                                                                                }
                                                                                                                            </style>
                                                                                                                        </defs>
                                                                                                                        <path class="a fill-color"
                                                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                        <path class="b"
                                                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                                                    </svg>
                                                                                                                </td> -->
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                                            <h4
                                                                                                                                class="ml-2">
                                                                                                                                No
                                                                                                                                Record
                                                                                                                                Found
                                                                                                                            </h4>
                                                                                                                        </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                                <p class="paginate_data">
                                                                                                    View per page
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="w3-card-4 classCard " *ngIf="showInvoice">
                                                                                <div class="container-fluid">
                                                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;">
                                                                                        <li><a>
                                                                                                        Add
                                                                                                        all
                                                                                                        reported/approved
                                                                                                        task
                                                                                                        time
                                                                                                        to
                                                                                                        a
                                                                                                        new
                                                                                                        invoice
                                                                                                    </a>
                                                                                        </li>

                                                                                    </ul>
                                                                                </div>
                                                                                <form [formGroup]="billingForm">


                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Bill
                                                                                                                From
                                                                                                                Party
                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <span>{{this.billingInvoiceList.billFromParty}}</span>

                                                                                                    <!-- <input
                                                                                                            type="email"
                                                                                                            [(ngModel)]="this.billingInvoiceList.billFromParty"
                                                                                                            [ngModelOptions]="{standalone:true}"
                                                                                                            class="form-control"
                                                                                                            id="exampleInputEmail1"
                                                                                                            formControlName="BillFromParty"
                                                                                                            aria-describedby="emailHelp"
                                                                                                            > -->
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Bill
                                                                                                                To
                                                                                                                Party
                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <span>{{this.billingInvoiceList.billToParty}}</span>

                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">From
                                                                                                                Date
                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <span>{{this.billingInvoiceList.productRoles.fromDate|date:'yyyy-MM-dd   hh:mm:ss'}}</span>



                                                                                                </div>

                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Through
                                                                                                                Date
                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <span>{{this.billingInvoiceList.productRoles.thruDate|date:'yyyy-MM-dd                                                             hh:mm:ss'}}</span>

                                                                                                    <!-- <input
                                                                                                            type="date"
                                                                                                            class="form-control"[(ngModel)]="{this.billingInvoiceList.productRoles.thruDate|date:'yyyy-MM-d hh:mm:ss'}"
                                                                                                            [ngModelOptions]="{standalone:true}"
                                                                                                            id="exampleInputEmail1"
                                                                                                            formControlName="Throughdate"
                                                                                                            aria-describedby="emailHelp"
                                                                                                            > -->
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">re-create Invoice: CI2	
                                                                                                                
                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown [options]="yesNOArray" 
                                                                                                 
                                                                                                     filter="true" formControlName="invoice" 
                                                                                                     optionlabel="label" >
                                                                                                    </p-dropdown>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Include
                                                                                                                Meeting
                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown [options]="yesNOArray" 
                                                                                                    [(ngModel)]="this.billingInvoiceList.IncludeMeeting" 
                                                                                                     filter="true" formControlName="IncludeMeeting" 
                                                                                                     optionlabel="label" >
                                                                                                    </p-dropdown>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>




                                                                                    </div>
                                                                                    <div class="col-lg-12  main-submit-button" style="
                                                                                                                   margin-left: -65%;">
                                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="addProductTimeToNewInvoice()">Add
                                                                                                    all
                                                                                                    reported/approved
                                                                                                    task
                                                                                                    time
                                                                                                    to
                                                                                                    a
                                                                                                    new
                                                                                                    invoice</button>&nbsp;


                                                                                    </div>
                                                                                </form>

                                                                            </div>

                                                                        </div>
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="activeCategoryA==4">
                                    <div class=" bg-white color-grey">
                                        <div class="create-new-leade ">
                                            <div class="">
                                                <div class="row">
                                                    <form class="w-100">



                                                        <div class="w3-card-4 classCard">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling h4Margin">
                                                                        Default Tasks List



                                                                    </h4>

                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2 mt-2" data-toggle="modal" data-target="#exampleModalCreateDefaultTask" style="margin-right: 7px;">
                                                                                Create New Default
                                                                                Tasks
                                                                            </button>

                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="TaskList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">

                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                   
                                                                                                </p-checkbox>

                                                                                                Task Name
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Type

                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Created Date

                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Assigned To
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Remove
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>






                                                                                    </tr>

                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-pro>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                   
                                                                                                </p-checkbox>
                                                                                                <span data-toggle="modal" data-target="#ModalUpdateDefaultTask"
                                                                                                 (click)="edit(pro)"  class="account-button">
                                                                                                 {{pro.defaultTaskParty.workEffortName}}</span>

                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{pro.defaultTaskParty.workEffortTypeId}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{pro.defaultTaskParty.createdDate|date:'yyyy-MM-d hh:mm:ss'}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                                                            (click)="onDelete(pro.defaultTaskParty.workEffortId)" 
                                                                                            class="ml-20" width="16" 
                                                                                            height="16" viewBox="0 0 26 26">
                                                                                                        <defs>
                                                                                                            <style>
                                                                                                                .a {
                                                                                                                    fill: #f44336;
                                                                                                                }

                                                                                                                .b {
                                                                                                                    fill: #fafafa;
                                                                                                                    margin-left: 20px;
                                                                                                                }
                                                                                                            </style>
                                                                                                        </defs>
                                                                                                        <path
                                                                                                            class="a fill-color"
                                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                        <path
                                                                                                            class="b"
                                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                                    </svg>
                                                                                        </td>

                                                                                        <!-- <td [ngStyle]="{'width':'170px'}">
                                                                                                <svg class="hover"
                                                                                                    (click)="updateEvent(product.workEffortId)"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width="16" height="16"
                                                                                                    viewBox="0 0 21.223 21.222">
                                                                                                    <g
                                                                                                        transform="translate(0 -0.004)">
                                                                                                        <g
                                                                                                            transform="translate(0 1.52)">
                                                                                                            <path
                                                                                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                transform="translate(0 -34.137)" />
                                                                                                        </g>
                                                                                                        <g
                                                                                                            transform="translate(4.548 0.004)">
                                                                                                            <g
                                                                                                                transform="translate(0 0)">
                                                                                                                <path
                                                                                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                    transform="translate(-102.409 -0.004)" />
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </td> -->
                                                                                        <!-- <svg (click)="onDelete(product.workEffortId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26"><defs><style>.a{fill:#f44336;}.b{fill:#fafafa; margin-left: 20px;}</style></defs><path class="a fill-color" d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0"/><path class="b" d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0" transform="translate(-147.576 -147.576)"/></svg> -->


                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No
                                                                                                            Record
                                                                                                            Found
                                                                                                        </h4>
                                                                                                    </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div *ngIf="activeCategoryA==5">
                                    <div class=" bg-white color-grey">
                                        <div class="create-new-leade ">
                                            <div class="">
                                                <div class="row">
                                                    <form class="w-100">

                                                        <div class="create-new-leade ">
                                                            <div class="container-fluid">
                                                                <div class="row">

                                                                    <form class="w-100">

                                                                        <div>

                                                                            <div class="w3-card-4  w-100">
                                                                                <div class="accordion classCard" id="myAccordion">
                                                                                    <div class="accordion-item">
                                                                                        <h2 class="accordion-header" id="headingOne">
                                                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                                                <span class="headingAccords"> Find Scrum Member 
                                                                                                </span>
                                                                                               
                                                                                                <button
                                                                                                            type="submit"
                                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#createNewParty">
                                                                                                            Create
                                                                                                            New
                                                                                                            Party
                                                                                                        </button>

                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#createNewPartyRelationship">
                                                                                                            Create New 
                                                                                                            Party
                                                                                                            Relationship
                                                                                                        </button>

                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#AddScrumMember">
                                                                                                            Create New 
                                                                                                            Scrum Member
                                                                                                        </button>
                                        
                                                                                                <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 29%!important; padding-top: 11px !important;
                                                                                                    padding-bottom: 11px !important;">
                                                                                                </button>									
                                                    
                                                                                            </div>
                                                        
                                                                                        </h2>
                                                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                                            <div class="card-body">
                                                                                                <form [formGroup]="billingForm">
                                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                    
                                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">First Name
                                                                                                                            </label>
                                                                                                                </div>
                    
                                                                                                                <div class="col-lg-2">
                    
                                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearchD.FirstName" name="FirstName" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" placeholder=" Enter First Name">
                                                                                                                </div>
                    
                                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">Last Name
                                                                                                                            </label>
                                                                                                                </div>
                                                                                                                <div class="col-lg-2">
                    
                                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearchD.LastName" name="LastName" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" placeholder=" Enter Last Name">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                    
                                                                                                        
                    
                    
                    
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">Middle Name
                                                                                                                            </label>
                                                                                                                </div>
                                                                                                                <div class="col-lg-2">
                    
                                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchD.MiddleName" name="MiddleName" aria-describedby="emailHelp" placeholder="Enter Middle Name">
                                                                                                                </div>
                    
                                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">Group ID
                                                                                                                            </label>
                                                                                                                </div>
                    
                                                                                                                <div class="col-lg-2">
                                                                                                                    <p-dropdown filter="true" [options]="GroupIdArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchD.GroupId" name="GroupId" optionlabel="label" placeholder="Choose Group ID">
                                                                                                                    </p-dropdown>
                    
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                    
                                                                                                        
                    
                                                                                                    </div>
                    
                                                                                                    <div class="col-lg-12  main-submit-button" style="
                                                                                                                           margin-left: -26%;">
                                                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" 
                                                                                                        (click)="getFindScrumMembers()">Find</button>&nbsp;
                    
                                                                                                        <button type="button" class="btn btn-danger ml-2"
                                                                                                         (click)="resetScrum()">Reset</button>
                    
                                                                                                    </div>
                                                                                                </form>
                                                                                                
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </div>

                                                                            <!-- <div class="w3-card-4 classCard">
                                                                                <div class="container-fluid">
                                                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                flex-wrap: wrap !important;
                                                                display: flex !important;">
                                                                                        <li>
                                                                                            <a>
                                                                                                        Find
                                                                                                        Scrum
                                                                                                        Member

                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#createNewParty">
                                                                                                            Create
                                                                                                            New
                                                                                                            Party
                                                                                                        </button>

                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#createNewPartyRelationship">
                                                                                                            Create
                                                                                                            Party
                                                                                                            Relationship
                                                                                                        </button>

                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#AddScrumMember">
                                                                                                            Create
                                                                                                            Scrum Member
                                                                                                        </button>

                                                                                                    </a>
                                                                                        </li>

                                                                                    </ul>
                                                                                </div>

                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">First Name
                                                                                                        </label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">

                                                                                                <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearchD.FirstName" name="FirstName" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" placeholder=" Enter First Name">
                                                                                            </div>

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">Last Name
                                                                                                        </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">

                                                                                                <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearchD.LastName" name="LastName" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" placeholder=" Enter Last Name">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    



                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">Middle Name
                                                                                                        </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">

                                                                                                <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchD.MiddleName" name="MiddleName" aria-describedby="emailHelp" placeholder="Enter Middle Name">
                                                                                            </div>

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">Group ID
                                                                                                        </label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown filter="true" [options]="GroupIdArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchD.GroupId" name="GroupId" optionlabel="label" placeholder="Choose Group ID">
                                                                                                </p-dropdown>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    

                                                                                </div>

                                                                                <div class="col-lg-12  main-submit-button" style="
                                                                                                       margin-left: -26%;">
                                                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getFindScrumMembers()">Find</button>&nbsp;

                                                                                    <button type="button" class="btn btn-danger ml-2" (click)="resetScrum()">Reset</button>

                                                                                </div>
                                                                            </div> -->

                                                                            <div class="w3-card-4 classCard ">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs">
                                                                                        <h4 class=" common-styling h4Margin">
                                                                                            Scrum Members List


                                                                                        </h4>

                                                                                    </div>
                                                                                </header>
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">

                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="ScrumMembersList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'260px'}" pSortableColumn="code">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                       
                                                                                                                    </p-checkbox>



                                                                                                                    Member
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">

                                                                                                                    User Login ID

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Group ID

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Rate Type ID
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Rate Amount
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Percentage Used
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Update
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Remove
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>






                                                                                                        </tr>

                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'260px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </p-checkbox>
                                                                                                                    <span style="color:#0d3769;cursor:pointer;" (click)="myProfilePage(product.Persons.partyId)" *ngFor="let r of product.partyIdTo"  class="account-button">{{r.firstName
                                                                                                                                +
                                                                                                                                "
                                                                                                                                "
                                                                                                                                +
                                                                                                                                r.lastName
                                                                                                                                +
                                                                                                                                "
                                                                                                                                "
                                                                                                                                +
                                                                                                                                r.middleName
                                                                                                                                +
                                                                                                                                "["
                                                                                                                                +
                                                                                                                                r.partyId
                                                                                                                                +
                                                                                                                                "]"}}</span>

                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div *ngIf="product.GroupId[0]||product.groupIds">
                                                                                                                    <span>{{product.GroupId[0].userLoginId}}</span>
                                                                                                                </div>

                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div *ngIf="product.GroupId[0]||product.groupIds">
                                                                                                                    <span>{{product.GroupId[0].groupId}}</span>
                                                                                                                </div>

                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div *ngIf="product.RateTypeID[0]">
                                                                                                                    <span>{{product.RateTypeID[0].rateTypeId}}</span>
                                                                                                                </div>

                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div *ngIf="product.rateAmount[0]">
                                                                                                                    <span>{{product.rateAmount[0].rateAmount}}</span>
                                                                                                                </div>
                                                                                                            </td>

                  

                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div *ngIf="product.RateTypeID[0]">
                                                                                                                    <span>{{product.RateTypeID[0].percentageUsed}}</span>
                                                                                                                </div>

                                                                                                            </td>

                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                <button type="submit" class="btn btn-secondary submit-btn" 
                                                                                                                data-toggle="modal" data-target="#UpdateScrumBacklog"
                                                                                                                 (click)="editScrum(product)">Update</button>

                                                                                                            </td>

                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                <button type="submit" class="btn btn-secondary submit-btn" 
                                                                                                                (click)="removeScrumMember(product)">Remove</button>


                                                                                                            </td>

                                                                                                            <!-- <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <svg class="hover"
                                                                                                                        (click)="updateEvent(product.workEffortId)"
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                        width="16" height="16"
                                                                                                                        viewBox="0 0 21.223 21.222">
                                                                                                                        <g
                                                                                                                            transform="translate(0 -0.004)">
                                                                                                                            <g
                                                                                                                                transform="translate(0 1.52)">
                                                                                                                                <path
                                                                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                                    transform="translate(0 -34.137)" />
                                                                                                                            </g>
                                                                                                                            <g
                                                                                                                                transform="translate(4.548 0.004)">
                                                                                                                                <g
                                                                                                                                    transform="translate(0 0)">
                                                                                                                                    <path
                                                                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                                                                </g>
                                                                                                                            </g>
                                                                                                                        </g>
                                                                                                                    </svg>
                                                                                                                </td> -->
                                                                                                            <!-- <svg (click)="onDelete(product.workEffortId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26"><defs><style>.a{fill:#f44336;}.b{fill:#fafafa; margin-left: 20px;}</style></defs><path class="a fill-color" d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0"/><path class="b" d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0" transform="translate(-147.576 -147.576)"/></svg> -->


                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                                <p class="paginate_data">
                                                                                                    View per page
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="activeCategoryA==6">
                                    <div class=" bg-white color-grey">
                                        <div class="create-new-leade ">
                                            <div class="">
                                                <div class="row">
                                                    <form class="w-100">
                                                        <div class="create-new-leade ">
                                                            <div class="">
                                                                <div class="row">

                                                                    <form class="w-100">

                                                                        <div>


                                                                            <div class="w3-card-4 classCard ">


                                                                                <div class="container-fluid">
                                                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                    flex-wrap: wrap !important;
                                                                    display: flex !important;">
                                                                                        <li><a>
                                                                                                        Create
                                                                                                        Task
                                                                                                        for
                                                                                                        Product
                                                                                                        Backlog

                                                                                                      <button
                                                                                                            type="submit"
                                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#exampleModalCenter">Create New Task
                                                                                                        </button> 

                                                                                                    </a>
                                                                                        </li>

                                                                                    </ul>
                                                                                </div>


                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">

                                                                                            <!-- <div class="card own-account-table">
                                                                                                <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                       
                                                                                                                    </p-checkbox>


                                                                                                                    Task Name
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">

                                                                                                                    Task Type

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Sprint

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Product Backlog
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Task Current Status ID
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Actual Start Date
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Actual Hours
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Plan Hours
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>





                                                                                                        </tr>

                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                       
                                                                                                                    </p-checkbox>
                                                                                                                    {{product.workEffortId}}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.workEffortName}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.toPartyId}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.toPartyId}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.toPartyId}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.toPartyId}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.toPartyId}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
mm                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                                <p class="paginate_data">
                                                                                                    View per page
                                                                                                </p>
                                                                                            </div> -->
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div *ngIf="activeCategoryA==7">
                                    <div class=" bg-white color-grey">
                                        <div class="create-new-leade ">
                                            <div class="">
                                                <div class="row">
                                                    <form class="w-100">
                                                        <br>
                                                        <div class="create-new-leade ">
                                                            <div class="">
                                                                <div class="row">
                                                                    <form class="w-100">

                                                                        <div>
                                                                            <div class="w3-card-4 classCard">
                                                                                <div class="container-fluid">
                                                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;">
                                                                                        <li><a>
                                                                                                        Add
                                                                                                        New
                                                                                                        Product
                                                                                                        Backlog
                                                                                                        Item
                                                                                                    </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <form [formGroup]="quickAddBacklogForm">
                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                                    <label for="productId">Product
                                                                                                                Backlog
                                                                                                                Item


                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" 
                                                                                                    aria-describedby="emailHelp" formControlName="ProductBacklogItem" 
                                                                                                    placeholder="Enter Product Backlog Item">

                                                                                                </div>

                                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                                    <label for="productId">Billed


                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown filter="true" [options]="yesNOArray" formControlName="Billed" 
                                                                                                    optionlabel="label" placeholder="Enter Billed">
                                                                                                    </p-dropdown>


                                                                                                </div>

                                                                                            </div>
                                                                                        </div>

                                                                                        

                                                                                        

                                                                                        <!-- <div class="col-lg-12" *ngIf="this.showUnplanned"> -->
                                                                                            <div class="col-lg-12" >
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                                    <label for="productId">Backlog
                                                                                                                Item
                                                                                                                Category


                                                                                                            </label>
                                                                                                </div>

                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                                     aria-describedby="emailHelp"
                                                                                                     formControlName="BacklogItemCategory" 
                                                                                                     placeholder="Enter Backlog Item Category" >

                                                                                                </div> 
                                                                                               

                                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                                    <label for="productId">Unplanned


                                                                                                            </label>
                                                                                                </div>

                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown filter="true" [options]="unplannedArray" 
                                                                                                    (onChange)="onChange($event)" formControlName="Unplanned"
                                                                                                     optionlabel="label" placeholder="Enter Unplanned">
                                                                                                    </p-dropdown>


                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                                    <label for="productId">Plan
                                                                                                                Time
                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp" formControlName="PlanTime" 
                                                                                                    placeholder="Enter Plan Time">
                                                                                                </div>

                                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                                    <label for="productId">Story
                                                                                                            </label>
                                                                                                </div>

                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                                     aria-describedby="emailHelp" formControlName="Story" 
                                                                                                     placeholder="Enter Story">

                                                                                                </div>

                                                                                            </div>
                                                                                        </div>


                                                                                        


                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                                    <label for="productId">How
                                                                                                                to
                                                                                                                test


                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" 
                                                                                                    aria-describedby="emailHelp" formControlName="Howtotest" 
                                                                                                    placeholder="Enter How To Test">

                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12" >
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputC" *ngIf="this.showProductSprint">
                                                                                                    <label for="exampleInputEmail1">
                                                                                                                Product
                                                                                                                Project
                                                                                                                Sprint
                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2" *ngIf="this.showProductSprint">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                                     formControlName="ProductProjectSprint" aria-describedby="emailHelp"
                                                                                                      placeholder="Enter Product Project Sprint">
                                                                                                </div>

                                                                                                <div class="col-lg-3 form-group classInputC" *ngIf="this.showUnplanned"> 
                                                                                                    <label for="exampleInputEmail1">Product
                                                                                                                Name


                                                                                                            </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2"  *ngIf="this.showUnplanned">
                                                                                                 <p-dropdown filter="true" [options]="ProjectSprintProductNameArray"
                                                                                                  formControlName="ProductName" optionlabel="label"
                                                                                                   placeholder="Enter Product Name">
                                                                                                </p-dropdown>

                                                                                                </div>
                                                                                            </div>

                                                                                        </div>



                                                                                    </div>
                                                                                    <div class="col-lg-12  main-submit-button" style="
                                                                                                                   margin-left: -29%;">
                                                                                        <button _ngcontent-cta-c128="" type="submit"
                                                                                         class="btn btn-secondary submit-btn" 
                                                                                        (click)="QuickAddBacklog()">Submit</button>&nbsp;


                                                                                    </div>
                                                                                </form>

                                                                            </div>




                                                                        </div>
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div *ngIf="activeCategoryA==8">

                                    <div class=" bg-white color-grey">
                                        <div class="create-new-leade ">
                                            <div class="">
                                                <div class="row">
                                                    <form class="w-100">
                                                        <div class="w3-card-4 classCard">
                                                            <div class="container-fluid">
                                                                <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;">
                                                                    <li><a>
                                                                        Find Product Statistics       </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <form [formGroup]="productStatForm">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInputC">
                                                                                <label for="productId">
                                                                                    Product Name
                                                                                        </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" [options]="proNameArray" 
                                                                                formControlName="ProductName" optionlabel="label" 
                                                                                placeholder="Enter Product Name">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12  main-submit-button" style="
                                                                                               margin-left: -64%;">
                                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" 
                                                                    (click)="proStats()">Submit</button>&nbsp;


                                                                </div>
                                                            </form>

                                                        </div>

                                                        <div class="divA" *ngIf="showGraphs">
                                                            <div class="w3-card-4 classCardView">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        Project And Sprint Information Chart


                                                                    </h4>
                                                                </div>

                                                              
                                                                <div class="panel-body" >
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table borderTable">
                                                                                <div class="col-lg-12">
                                                                                    <div class="chartjs-container">
                                                                                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                                                                            [chartType]="pieChartType" [options]="pieChartOptions"
                                                                                            [plugins]="pieChartPlugins" [colors]="pieChartColors"
                                                                                            [legend]="pieChartLegend">
                                                                                        </canvas>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="w3-card-4 classCardView">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        Backlogs Information Chart

                                                                    </h4>
                                                                </div>

                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table borderTable">
                                                                                <div class="col-lg-12">
                                                                                    <div class="chartjs-container">
                                                                                        <canvas baseChart [data]="pieChartDataBacklogsType" [labels]="pieChartLabelBacklogsType"
                                                                                            [chartType]="pieChartType" [options]="pieChartOptions"
                                                                                            [plugins]="pieChartPlugins" [colors]="pieChartColors"
                                                                                            [legend]="pieChartLegend">
                                                                                        </canvas>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="w3-card-4 classCardView">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        Task Information Chart

                                                                    </h4>
                                                                </div>

                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table borderTable">
                                                                                <div class="col-lg-12">
                                                                                    <div class="chartjs-container">
                                                                                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                                                                            [chartType]="pieChartType" [options]="pieChartOptions"
                                                                                            [plugins]="pieChartPlugins" [colors]="pieChartColors"
                                                                                            [legend]="pieChartLegend">
                                                                                        </canvas>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="divB" *ngIf="showGraphs">
                                                            <div class="w3-card-4 classCardView">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        Project And Sprint Information Chart

                                                                    </h4>
                                                                </div>

                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table borderTable">
                                                                                <div class="col-lg-12">
                                                                                    <div class="chartjs-container">
                                                                                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                                                                            [chartType]="pieChartType" [options]="pieChartOptions"
                                                                                            [plugins]="pieChartPlugins" [colors]="pieChartColors"
                                                                                            [legend]="pieChartLegend">
                                                                                        </canvas>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="w3-card-4 classCardView">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        Backlogs Information Chart

                                                                    </h4>
                                                                </div>

                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table borderTable">
                                                                                <div class="col-lg-12">
                                                                                    <div class="chartjs-container">
                                                                                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                                                                            [chartType]="pieChartType" [options]="pieChartOptions"
                                                                                            [plugins]="pieChartPlugins" [colors]="pieChartColors"
                                                                                            [legend]="pieChartLegend">
                                                                                        </canvas>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="w3-card-4 classCardView">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        Task Information Chart

                                                                    </h4>
                                                                </div>

                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table borderTable">
                                                                                <div class="col-lg-12">
                                                                                    <div class="chartjs-container">
                                                                                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                                                                            [chartType]="pieChartType" [options]="pieChartOptions"
                                                                                            [plugins]="pieChartPlugins" [colors]="pieChartColors"
                                                                                            [legend]="pieChartLegend">
                                                                                        </canvas>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCreateDefaultTask" tabindex="-1" role="dialog" aria-labelledby="exampleModalCreateDefaultTaskTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Default Task
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp create-new-leade">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Default Task
                                    </a></li>
                            </ul>
                        </div>


                        <form [formGroup]="defaultTaskCreateForm">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Task
                                                Name
                                            </label>
                                        </div>
                                        <div class="col-lg-3">

                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="TaskName" aria-describedby="emailHelp" placeholder="Enter Task Name">
                                        </div>

                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Product


                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Product" aria-describedby="emailHelp" placeholder="Enter Product ">
                                        </div>

                                    </div>
                                </div>


                                


                                <div class="col-lg-12">
                                    <div class="row">


                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Type


                                            </label>

                                        </div>

                                        <div class="col-lg-3">

                                            <p-dropdown filter="true" [options]="DefaultTaskArray" formControlName="Type" optionlabel="label" placeholder="Enter Type">
                                            </p-dropdown>
                                        </div>

                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Initial
                                                Planned
                                                Hours


                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="InitialPlannedHours" aria-describedby="emailHelp" placeholder="Enter Initial Planned Hours">
                                        </div>

                                    </div>
                                </div>
                                
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Description
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                        </div>

                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Assigned
                                                To
                                            </label>
                                        </div>

                                        <div class="col-lg-3">

                                            <p-dropdown filter="true" [options]="AssignToArray" formControlName="AssignedTo" optionlabel="label" placeholder="Enter Assigned To">
                                            </p-dropdown>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">


                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Product Id


                                            </label>

                                        </div>

                                        <div class="col-lg-3">

                                            <p-dropdown filter="true" [options]="ProductIdArray" 
                                            formControlName="productId" optionlabel="label" placeholder="Enter product Id">
                                            </p-dropdown>
                                        </div>

                              

                                    </div>
                                </div>

                                



                            </div>
                        </form>

                        <div class="col-lg-12  main-submit-button" style="
                                       margin-left: -10%;">
                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="createDefaultTasks()">Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="ModalUpdateDefaultTask" tabindex="-1" role="dialog" aria-labelledby="ModalUpdateDefaultTaskTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Default Task
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Default Task
                                    </a></li>
                            </ul>
                        </div>


                        <form [formGroup]="defaultTaskUpdateForm">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormG">
                                            <label for="exampleInputEmail1">Task
                                                Name
                                            </label>
                                        </div>
                                        <div class="col-lg-3">

                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="TaskName" aria-describedby="emailHelp" placeholder="Enter Task Name">
                                        </div>

                                        <div class="col-lg-3 form-group rightFormH">
                                            <label for="exampleInputEmail1">Product


                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Product" aria-describedby="emailHelp" placeholder="Enter Product ">
                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormG">
                                            <label for="exampleInputEmail1">Type
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown filter="true" [options]="DefaultTaskArray" formControlName="Type" optionlabel="label" placeholder="Enter Type">
                                            </p-dropdown>
                                        </div>

                                        <div class="col-lg-3 form-group rightFormH">
                                            <label for="exampleInputEmail1">Initial
                                                Planned
                                                Hours
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="InitialPlannedHours" aria-describedby="emailHelp" placeholder="Enter Initial Planned Hours">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormG">
                                            <label for="exampleInputEmail1">Description
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                        </div>

                                        <div class="col-lg-3 form-group rightFormH">
                                            <label for="exampleInputEmail1">Assigned
                                                To
                                            </label>
                                        </div>

                                        <div class="col-lg-3">

                                            <p-dropdown filter="true" [options]="AssignToArray" formControlName="AssignedTo" optionlabel="label" placeholder="Enter Assigned To">
                                            </p-dropdown>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="col-lg-12  main-submit-button" style="
                                       margin-left: -4%;">
                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" 
                            (click)="updateDefaultTask()">Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createNewParty" tabindex="-1" role="dialog" aria-labelledby="createNewPartyTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Scrum Members</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Edit Personal Information</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade ">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createNewParty">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">First Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="firstName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First name">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Middle Name

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="middleName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Middle name">

                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="lastName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last name">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">E-mail Address
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="email" class="form-control" formControlName="emailAddress" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter E-mail Address">

                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">User Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="userName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter User name">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Password
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="password" class="form-control" formControlName="password" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter password">

                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Password
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="password" class="form-control" formControlName="verifyPassword" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Re-Enter Password">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="roleTypeID" [options]="roleTypeIdArray" optionlabel="label" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Company Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="companyName" [options]="partyIdArray" optionlabel="label" placeholder="Enter Company name">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 40%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="createParty()">Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="UpdateScrumBacklog" tabindex="-1" role="dialog" aria-labelledby="UpdateScrumBacklogTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Party Rates
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Party Rates
                                    </a></li>
                            </ul>
                        </div>


                        <form [formGroup]="scrumMemberUpdateForm">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Party ID

                                            </label>
                                        </div>
                                        <div class="col-lg-3">

                                            <input type="email" disabled class="form-control" 
                                            id="exampleInputEmail1" formControlName="PartyID" 
                                            aria-describedby="emailHelp" placeholder="Enter Party ID">
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Rate Type


                                            </label>
                                        </div>
                                        <div class="col-lg-3">

                                            <p-dropdown filter="true" [options]="RateTypeListArray" formControlName="RateType" optionlabel="label" placeholder="Enter Rate Type">
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>


                     


                                <div class="col-lg-12">
                                    <div class="row">


                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Default Rate


                                            </label>

                                        </div>

                                        <div class="col-lg-3">
                                            <p-dropdown filter="true" [options]="yesNOArray" formControlName="DefaultRate" optionlabel="label" placeholder="Enter Default Rate">
                                            </p-dropdown>
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Rate Amount


                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="RateAmount" aria-describedby="emailHelp" placeholder="Enter Rate Amount">
                                        </div>

                                    </div>
                                </div>
                              
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Percentage Used
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="PercentageUsed" aria-describedby="emailHelp" placeholder="Enter Percentage Used">
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </form>

                        <div class="col-lg-12  main-submit-button" style="
                                       margin-left: -67%;">
                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="updateQuickAddPartyRate()">Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="QuickAddNewTask" tabindex="-1" role="dialog" aria-labelledby="QuickAddNewTaskTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Task
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Create Task for Product Backlog
                                    </a></li>
                            </ul>
                        </div>


                        <form [formGroup]="QuickAddTaskForm">


                            <div class="all-users-infomation font-13 font-medium own-user-select">

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputC">
                                            <label for="exampleInputEmail1">
                                                Product
                                                Backlog


                                            </label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown filter="true" formControlName="ProductBacklog" optionlabel="label" placeholder="Enter 
                                            Product Backlog">
                                            </p-dropdown>



                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputC">
                                            <label for="productId">Task
                                                Name

                                            </label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="TaskName" placeholder="Enter Task Name">

                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputC">
                                            <label for="exampleInputEmail1">
                                                Task
                                                Type


                                            </label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown filter="true" formControlName="TaskType" optionlabel="label" placeholder="Choose 
                                        Task Type">
                                            </p-dropdown>



                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputC">
                                            <label for="productId">Planned
                                                Hours

                                            </label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="PlannedHours" placeholder="Enter Planned Hours ">

                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputC">
                                            <label for="productId">Description

                                            </label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="Description" placeholder="Enter Description  ">

                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputC">
                                            <label for="productId">Uploaded
                                                File

                                            </label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="file" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="UploadedFile" placeholder="Enter Uploaded File">

                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputC">
                                            <label for="exampleInputEmail1">
                                                Assigned
                                                To


                                            </label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown filter="true" formControlName="AssignedTo" optionlabel="label" placeholder="Choose 
                                        Assigned To">
                                            </p-dropdown>



                                        </div>

                                    </div>
                                </div>



                            </div>
                            <div class="col-lg-12  main-submit-button" style="
                                               margin-left: -66%;">
                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Create</button>&nbsp;


                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="AddScrumMember" tabindex="-1" role="dialog" aria-labelledby="AddScrumMemberTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Create Scrum Member
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton10>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp create-new-leade">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                    Add Scrum Member
                                    </a></li>
                            </ul>
                        </div>

                        <form [formGroup]="scrumForm">
                            <div class="all-users-infomation font-13 font-medium own-user-select">

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormK">
                                            <label for="exampleInputEmail1">
                                            Person
                                            <span style="color:red;"> *</span>
                                        </label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown [options]="PersonArray" filter="true" formControlName="Person" optionlabel="label" placeholder="Enter 
                                            Person">
                                            </p-dropdown>
                                        </div>
                                        <div class="col-lg-3 form-group rightFormL">
                                            <label for="productId">Group
                                            ID
                                        </label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="GroupIdArray" formControlName="GroupID" optionlabel="label" placeholder="Enter 
                                            Group ID">
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormK">
                                            <label for="exampleInputEmail1">
                                            Role Type Id


                                        </label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown [options]="roleTypeIdArray" filter="true" 
                                            formControlName="roleTypeId" optionlabel="label" placeholder="Enter 
                                            role Type Id">
                                            </p-dropdown>



                                        </div>

                                      

                                    </div>
                                </div>

                                




                            </div>
                            <div class="col-lg-12  main-submit-button" style="
                                               margin-left: -19%;">
                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="addScrumMember()">Add</button>&nbsp;


                            </div>
                        </form>



                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createNewPartyRelationship" tabindex="-1" role="dialog" aria-labelledby="createNewPartyRelationshipTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Scrum Members
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp create-new-leade">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                    Create Party Relationship
                                    </a></li>
                            </ul>
                        </div>

                        <form class="w-100" [formGroup]="createPartyRelationship">
                            <div class="all-users-infomation font-13 font-medium own-user-select">

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormG">
                                            <label for="exampleInputEmail1">Party ID
                                                <span style="color: red;">*</span>
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <p-dropdown filter="true" formControlName="partyID" [options]="PartyTimesheetIdArray" optionlabel="label" placeholder="Enter Party ID">
                                            </p-dropdown>
                                        </div>

                                        <div class="col-lg-3 form-group rightFormH">
                                            <label for="exampleInputEmail1">Company Party ID
                                                <span style="color: red;">*</span>
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <p-dropdown filter="true" formControlName="companyPartyID" [options]="partyIdArray" optionlabel="label" placeholder="Enter Company Party ID">
                                            </p-dropdown>
                                        </div>

                                    </div>
                                </div>

                                
                            </div>
                            <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 40%;">
                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createPartyRelation()">Create</button>
                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Task # Information</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Edit Task # Information</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100 " [formGroup]="addNewTaskForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Project ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="ProjectIDArray" formControlName="ProjectID" placeholdder="Enter Project ID">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="productId">Task Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="TaskName" placeholder="Enter Task Name">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Task Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" formControlName="TaskType"
                                                         [options]="RoleTypeListArray" placeholder="Enter Task Type">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Sprint ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="SprintID" aria-describedby="emailHelp" placeholder="Enter Sprint ID">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Sprint Backlog
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="SprintBacklogListArray" placeholder="Enter Sprint Backlog" formControlName="SprintBacklog">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Plan Hours</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="PlanHours" aria-describedby="emailHelp" placeholder="Enter Plan Hours">
                                                    </div>


                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Uploaded
                                                            File</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="file" class="form-control" id="exampleInputEmail1" 
                                                        (change)="fileProgress($event)" formControlName="Uploaded"
                                                         aria-describedby="emailHelp" placeholder="Choose File">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Assign To
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="AssignToArray" placeholder="Enter Assign To" formControlName="AssignTo">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -62%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="submit()">Create</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>