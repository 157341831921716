import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';

@Component({
  selector: 'app-create-acc-item',
  templateUrl: './create-acc-item.component.html',
  styleUrls: ['./create-acc-item.component.css']
})
export class CreateAccItemComponent implements OnInit {
  createbudget:FormGroup;
  activeCategory=2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  varianceReasonId: any;
  varianceReasonIdArray: any[]=[];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  budgetId: any;
  BudgetItemTypeId: any;
  BudgetItemTypeIdArray: any[]=[];
  budgetItemSeqId: any;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createbudget = this._FormBuilder.group({
        budgetId: [''],
        amount:[''],
        Purpose:[''],
        Justification:[''],
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.budgetId = params["budgetId"];
      this.budgetItemSeqId=params["budgetItemSeqId"];
    });
    this.getBudgetItemTypeId();
    if(this.budgetItemSeqId){
      this.show=true;
      this.getbudgetsItemEdit();
     
          }else{
            this.show=false;
          }
  }
  
  getbudgetsItemEdit() {
    this.spinner.show();
   
    this.accountsService.getbudgetsItemEdit(this.budgetId,this.budgetItemSeqId).subscribe((res: any) => {
     
      this.spinner.hide();
      setTimeout(() => {
     
        const formValue = this.createbudget;

        formValue.patchValue({
          budgetId: res.data.budgetItemTypeId,
          amount:res.data.amount,
          Purpose:res.data.purpose,
          Justification:res.data.justification,
        })
      
      }, 3000);
    })
  
  }
  onUpdate(): void {

    const requestData = {
      "amount": this.createbudget.value.amount,
      "budgetId":this.budgetId,
      "budgetItemSeqId": this.budgetItemSeqId,
      "budgetItemTypeId":this.createbudget.value.budgetId,
      "justification": this.createbudget.value.Justification,
      "purpose":this.createbudget.value.Purpose,
    
    }

    this.accountsService.updateBudgetItem(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-budget-summary'],{ queryParams: { budgetId:this.budgetId}});
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  onSubmit(): void {

    const requestData = {
      "amount": this.createbudget.value.amount,
      "budgetId":this.budgetId,
      "budgetItemTypeId":this.createbudget.value.budgetId,
      "justification": this.createbudget.value.Justification,
      "purpose":this.createbudget.value.Purpose,
    
    }

    this.accountsService.postBudgetItem(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-budget-summary'],{ queryParams: { budgetId:this.budgetId}});
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  getBudgetItemTypeId() {
    this.spinner.show();
    this.accountsService.getBudgetItemTypeId().subscribe((res: any) => {
      this.BudgetItemTypeId = res.data;
      this.spinner.hide();
      for (const value of this.BudgetItemTypeId) {
        this.BudgetItemTypeIdArray.push({
          label: value.description,
          value: value.budgetItemTypeId
        })
      }
    })
  
  }
  
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-budget-summary'],{ queryParams: { budgetId:this.budgetId}});
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
