<div class="container-fluid main-container-wrapper">
    <div class="row">
         <div class="col-12 pad_0">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">
                </span>

                <span class="">

                    <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
                </span>

            </div>
            <div class=" bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard mt-20 border_wdth_1">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="summary()"><a> Summary </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="editWorkEffort1()"><a> Work Effort </a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="childWorkEfforts()"><a> Children </a></li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="partyAssigns()"><a> Parties </a></li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="rates()"><a> Rates </a></li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="commEvents()"><a> Communications </a></li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="shopLists()"><a> Shopping Lists </a></li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="requests()"><a> Requests </a></li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="requirements()"><a> Requirements </a></li>
                            <li [ngClass]="activeCategory==10?'active':''" (click)="quotes()"><a> Quotes </a></li>
                            <li [ngClass]="activeCategory==11?'active':''" (click)="orderHeaders()"><a> Orders </a></li>
                            <li [ngClass]="activeCategory==12?'active':''" (click)="timeEntries()"><a> Time Entries </a></li>
                            <li [ngClass]="activeCategory==13?'active':''" (click)="notes()"><a> Notes </a></li>
                            <li [ngClass]="activeCategory==14?'active':''" (click)="contents()"><a> Content </a></li>
                            <li [ngClass]="activeCategory==15?'active':''" (click)="products()"><a> Product </a></li>
                            <li [ngClass]="activeCategory==16?'active':''" (click)="reviews()"><a> Reviews </a></li>
                            <li [ngClass]="activeCategory==17?'active':''" (click)="keywords()"><a> Keywords </a></li>
                            <li [ngClass]="activeCategory==18?'active':''" (click)="contactMechs()"><a> Contact Mechs </a></li>
                            <li [ngClass]="activeCategory==19?'active':''" (click)="agreementApplics()"><a> Agreement Applics </a></li>
                            <li [ngClass]="activeCategory==20?'active':''" (click)="fixedAssets()"><a> Fixed Assets </a></li>
                            <li [ngClass]="activeCategory==21?'active':''" (click)="attributes()"><a> Attributes </a></li>
                            <li [ngClass]="activeCategory==22?'active':''" (click)="eventReminders()"><a> Event Reminders </a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" (click)="addWorkEffort()"> Create Work Effort</button>
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCardView w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">Child Work Efforts
                                                <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addNChild"> Add New Work Effort Child</button>
                                                <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addEChild"> Add Existing Work Effort Child</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="panel-body">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="color-black container">
                                                    <p-tree [value]="ChildrenWorkeffort" selectionMode="single" (onNodeSelect)="nodeSelect($event)"></p-tree>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addEChild" tabindex="-1" role="dialog" aria-labelledby="addEChild" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Child Work Efforts </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Edit Work Effort Assoc </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addExistingChild">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Work Effort ID From</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="WorkEffortIDFrom" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Work Effort ID From">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Work Effort ID To</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="WorkEffortIDTo"
                                                         [options]="partyIdArray" optionlabel="label" placeholder="Enter Work Effort ID To">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Work Effort Assoc Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="WorkEffortAssocTypeID" [options]="WorkEffortAssocTypeArray" optionlabel="label" placeholder="Enter Work Effort Assoc Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence Num</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="sequenceNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="fromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="throughDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createWorkEffortAssoc()" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addNChild" tabindex="-1" role="dialog" aria-labelledby="addNChild" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Child Work Efforts </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Work Effort (associated from parent to child) </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addNewChild">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Work Effort ID From</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="WorkEffortIDFrom" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Work Effort ID From">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Work Effort Assoc Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="WorkEffortAssocTypeID" [options]="WorkEffortAssocTypeArray" optionlabel="label" placeholder="Enter Work Effort Assoc Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence Num</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="sequenceNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="fromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="throughDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Work Effort ID To</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="WorkEffortIDTo" [options]="partyIdArray" optionlabel="label" placeholder="Enter Work Effort ID To">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Name<span style="color: red;"></span></label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="name" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quick Assign Party ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="QuickAssignPartyID" [options]="partyIdArray" optionlabel="label" placeholder="Enter Quick Assign Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Type<span style="color: red;"></span></label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="type" [options]="TypeArray" optionlabel="label" placeholder="Enter Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Purpose</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="purpose" [options]="PurposeArray" optionlabel="label" placeholder="Enter Purpose">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="status" [options]="StatusNewWorkEffortArray" optionlabel="label" placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Percent complete</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="percentComplete" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Percent Complete">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="priority" [options]="PriorityArray" optionlabel="label" placeholder="Enter Priority">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Scope</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="scope" [options]="scopeArray" optionlabel="label" placeholder="Enter Scope">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Start Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="estimatedStartDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Start Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Completion Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="estimatedCompletionDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Completion Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Start Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="actualStartDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual Start Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Completion Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="actualCompletionDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual Completion Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Communication Event ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="CommunicationEventID" [options]="CommunicationEventIdArray" optionlabel="label" placeholder="Enter Communication Event ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reason</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="reason" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reason">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Website ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="websiteID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Website ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Work Effort Parent ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="parentID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Work Effort Parent ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Show As Enum ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="enumID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Enum ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Send Notification Mail</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="SendNotificationMail" [options]="yesNOArray" optionlabel="label" placeholder="Enter Send Notification Mail">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Loaction Desc</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="loactionDesc" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Loaction Desc">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Milli Seconds</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="EstimatedMilliSeconds" optionlabel="label" placeholder="Enter Estimated Milli Seconds">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Setup Millis</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="EstimatedSetupMillis" optionlabel="label" placeholder="Enter Estimated Setup Milli Seconds">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimate calc Method</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="estimateCalc" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimate calc Method">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Milli Seconds</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ActualMilliSeconds" optionlabel="label" placeholder="Enter Actual Milli Seconds">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Setup Millis</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ActualSetupMillis" optionlabel="label" placeholder="Enter Actual Setup Millis">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Total Milliseconds Allowed</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="TotalMillisecondsAllowed" optionlabel="label" placeholder="Enter Total Milliseconds Allowed">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Total Money Allowed</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="totalMoneyAllowed" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Total Money Allowed">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Currency</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="Currency" [options]="CurrencyListArray" optionlabel="label" placeholder="Enter Currency ">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Special Terms </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="specialTerms" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Special Terms">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Time Transparency</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="timeTransparency" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Time Transparency">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Universal ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="universalID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Universal ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Source Reference ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="sourceReferenceID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Source Reference ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="fixedAssetID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Facility ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="FacilityID" [options]="FacilityIdListArray" optionlabel="label" placeholder="Enter Facility ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Info URL</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="infoURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Info URL">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Temporal Expression</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="TemporalExpression" [options]="TemporalExpressionArray" optionlabel="label" placeholder="Enter Temporal Expression ">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Service Loader Name</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="serviceLoaderName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Service Loader Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quality to Produce</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="qualityToProduce" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quality to Produce">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quantity Produced</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="quantityProduced" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity Produced">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quantity Rejected</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="quantityRejected" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity Rejected">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reserv Persons</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="reservPersons" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reserv Persons">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reserv 2nd PP Perc</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="reserv2nd" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reserv 2nd PP Perc">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reserv Nth PP Perc</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="reservnth" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reserv Nth PP Perc">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Accomodation Map ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="accMapID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Accomodation Map ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Accomodation Spot ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="accSpotID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Accomodation Spot ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Requirement ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="RequirementID" [options]="RequirementListArray" optionlabel="label" placeholder="Enter Requirement ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createWorkEffortAndAssoc()" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>