
import { ConfirmationEmailComponent } from './order-management/confirmation-email/confirmation-email.component';
import { AppInterceptor } from './app.interceptor';
import { AccountsComponent } from './crm/accounts/accounts.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { AdminGuard } from './admin.guard';

import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DatePipe, CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MainContainerComponent } from './main-container/main-container.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { CreateNewSuppliersComponent } from './purchasing/create-new-suppliers/create-new-suppliers.component';
import { MergeSuppliersComponent } from './purchasing/merge-suppliers/merge-suppliers.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PurchasingReportsComponent } from './purchasing/purchasing-reports/purchasing-reports.component';
import { DropdownModule } from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainCatalogMgmtComponent } from './catalog-mgmt/main-catalog-mgmt/main-catalog-mgmt.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProductsComponent } from './catalog-mgmt/products/products.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { LeadsComponent } from './crm/leads/leads.component';
import { TableModule } from 'primeng/table';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CreateAccountComponent } from './crm/create-account/create-account.component';
import { FindAccountsComponent } from './crm/find-accounts/find-accounts.component';
import { MergeAccountsComponent } from './crm/merge-accounts/merge-accounts.component';
import { MyContactsComponent } from './crm/contacts/my-contacts/my-contacts.component';
import { CreateContactsComponent } from './crm/contacts/create-contacts/create-contacts.component';
import { FindContactsComponent } from './crm/contacts/find-contacts/find-contacts.component';
import { MergeContactsComponent } from './crm/contacts/merge-contacts/merge-contacts.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MyLeadsComponent } from './crm/my-leads/my-leads.component';
import { FindLeadsComponent } from './crm/find-leads/find-leads.component';
import { MergeLeadsComponent } from './crm/merge-leads/merge-leads.component';
import { TeamsOpportunitiesComponent } from './crm/opportunities/teams-opportunities/teams-opportunities.component';
import { CreateOpportunityComponent } from './crm/opportunities/create-opportunity/create-opportunity.component';
import { FindOpportunitiesComponent } from './crm/opportunities/find-opportunities/find-opportunities.component';
import { MyQuotesComponent } from './crm/quotations/my-quotes/my-quotes.component';
import { CreateQuotesComponent } from './crm/quotations/create-quotes/create-quotes.component';
import { FindQuotesComponent } from './crm/quotations/find-quotes/find-quotes.component';
import { CreateEventsComponent } from './crm/events/create-events/create-events.component';
import { EmployeesComponent } from './human-resource/employees/employees.component';
import { AddNewEmployeeComponent } from './human-resource/add-new-employee/add-new-employee.component';
import { AppContentComponent } from './app-content/app-content.component';
import { EmployeePositionComponent } from './human-resource/employee-position/employee-position.component';
import { AddEmployeePositionComponent } from './human-resource/add-employee-position/add-employee-position.component';
import { SkillsComponent } from './human-resource/skills/skills.component';
import { AddSkillsComponent } from './human-resource/add-skills/add-skills.component';
import { EmployeeLeaveComponent } from './human-resource/leave/employee-leave/employee-leave.component';
import { LeaveApprovalComponent } from './human-resource/leave/leave-approval/leave-approval.component';
import { AddNewLeavesComponent } from './human-resource/leave/add-new-leaves/add-new-leaves.component';
import { TrainingApprovalsComponent } from './human-resource/training/training-approvals/training-approvals.component';
import { TimesheetComponent } from './human-resource/timesheet/timesheet/timesheet.component';
import { CreateTimesheetComponent } from './human-resource/timesheet/create-timesheet/create-timesheet.component';
import { JobRequisitionComponent } from './human-resource/recuitment/job-requisition/job-requisition.component';
import { InternalJobPostingComponent } from './human-resource/recuitment/internal-job-posting/internal-job-posting.component';
import { CreateInternalJobPostingComponent } from './human-resource/recuitment/create-internal-job-posting/create-internal-job-posting.component';
import { NewJobRequisitionComponent } from './human-resource/recuitment/new-job-requisition/new-job-requisition.component';
import { JobInterviewComponent } from './human-resource/job-interview/job-interview.component';
import { NewJobInterviewComponent } from './human-resource/new-job-interview/new-job-interview.component';
import { ApprovalComponent } from './human-resource/approval/approval.component';
import { ReallocationComponent } from './human-resource/reallocation/reallocation.component';
import { CatalogsComponent } from './catalog-mgmt/catalogs/catalogs.component';
import { NewProductCatalogComponent } from './catalog-mgmt/new-product-catalog/new-product-catalog.component';
import { MainHumanResourceComponent } from './human-resource/main-human-resource/main-human-resource.component';
import { CatagoriesComponent } from './catalog-mgmt/catagories/catagories.component';
import { NewCatagoryComponent } from './catalog-mgmt/new-catagory/new-catagory.component';
import { NewProductsComponent } from './catalog-mgmt/new-products/new-products.component';
import { FeatureTypeComponent } from './catalog-mgmt/features/feature-type/feature-type.component';
import { FeatureCategoryComponent } from './catalog-mgmt/features/feature-category/feature-category.component';
import { FeatureGroupComponent } from './catalog-mgmt/features/feature-group/feature-group.component';
import { FeatureInteractionComponent } from './catalog-mgmt/features/feature-interaction/feature-interaction.component';
import { NewFeatureTypeComponent } from './catalog-mgmt/features/new-feature-type/new-feature-type.component';
import { NewFeatureCategoryComponent } from './catalog-mgmt/features/new-feature-category/new-feature-category.component';
import { NewFeatureInteractionComponent } from './catalog-mgmt/features/new-feature-interaction/new-feature-interaction.component';
import { ConfigurationsComponent } from './catalog-mgmt/configurations/configurations/configurations.component';
import { CreateConfigurationsComponent } from './catalog-mgmt/configurations/create-configurations/create-configurations.component';
import { ThesaurusComponent } from './catalog-mgmt/thesaurus/thesaurus.component';
import { ShipmentComponent } from './catalog-mgmt/shipping/shipment/shipment.component';
import { CarrierShipmentComponent } from './catalog-mgmt/shipping/carrier-shipment/carrier-shipment.component';
import { ShipmentEstimateComponent } from './catalog-mgmt/shipping/shipment-estimate/shipment-estimate.component';
import { WarehouseComponent } from './warehouse/warehouse/warehouse.component';
import { QuantityBreaksComponent } from './catalog-mgmt/shipping/quantity-breaks/quantity-breaks.component';
import { InvoicesComponent } from './accounting/invoices/invoices.component';
import { CreateInvoicesComponent } from './accounting/create-invoices/create-invoices.component';
import { CommissionComponent } from './accounting/commission/commission.component';

import { CreateAgreementItemComponent } from './accounting/create-agreement-item/create-agreement-item.component';
import { AdditionalItemsComponent } from './accounting/additional-items/additional-items.component';
import { AddNewPromotionsComponent } from './accounting/add-new-promotions/add-new-promotions.component';
import { PaymentsComponent } from './accounting/payments/payments/payments.component';
import { CreatePaymentsComponent } from './accounting/payments/create-payments/create-payments.component';
import { SaleInvoicesComponent } from './accounting/payments/sale-invoices/sale-invoices.component';
import { PurchaseInvoicesComponent } from './accounting/payments/purchase-invoices/purchase-invoices.component';
import { PaymentGroupComponent } from './accounting/payments/payment-group/payment-group.component';
import { CreatePaymentGroupComponent } from './accounting/accounting-ap/payable-payment-group/create-payment-group/create-payment-group.component';
import { ReportsComponent } from 'src/app/reports/reports.component';
import { CommissionReportComponent } from './accounting/reports/commission-report/commission-report.component';
import { VendorsComponent } from './accounting/accounting-ap/vendors/vendors.component';
import { CreateVendorsComponent } from './accounting/accounting-ap/create-vendors/create-vendors.component';
import { BilingAccountsComponent } from './accounting/biling-accounts/biling-accounts.component';
import { AccountingDocumentComponent } from './accounting/accounting-document/accounting-document.component';
import { CreateNewFacilitiesComponent } from './warehouse/create-new-facilities/create-new-facilities.component';
import { DetailedContactsSummaryComponent } from './crm/contacts/detailed-contacts-summary/detailed-contacts-summary.component';
import { DetailedAccountsSummaryComponent } from './crm/accounts/detailed-accounts-summary/detailed-accounts-summary.component';
import { DetailedOpportunitySummaryComponent } from './crm/opportunities/detailed-opportunity-summary/detailed-opportunity-summary.component';
import { DetailedQuoteSummaryComponent } from './crm/quotations/detailed-quote-summary/detailed-quote-summary.component';
import { DocumentsComponent } from './crm/documents/documents.component';
import { EmploymentsComponent } from './human-resource/employments/employments.component';
import { AddNewEmploymentComponent } from './human-resource/add-new-employment/add-new-employment.component';
import { MainWarehouseComponent } from './warehouse/main-warehouse/main-warehouse.component';
import { FacilityGroupComponent } from './warehouse/facility-group/facility-group.component';
import { InventoryLabelsComponent } from './warehouse/inventory-labels/inventory-labels.component';
import { LabelTypesComponent } from './warehouse/label-types/label-types.component';
import { ShipmentGatewayComponent } from './warehouse/shipment-gateway/shipment-gateway.component';
import { WarehouseShipmentComponent } from './warehouse/warehouse-shipment/warehouse-shipment.component';
import { NewWarehouseShipmentComponent } from './warehouse/new-warehouse-shipment/new-warehouse-shipment.component';
import { MainAccountingComponent } from './accounting/main-accounting/main-accounting.component';
import { DetailedEventSummaryComponent } from './crm/events/create-events/detailed-event-summary/detailed-event-summary.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { CreaditMemosComponent } from './accounting/accounting-ap/creadit-memos/creadit-memos.component';
import { CreateCreditMemosComponent } from './accounting/accounting-ap/create-credit-memos/create-credit-memos.component';
import { PayablesPaymentComponent } from './accounting/accounting-ap/payables-payment/payables-payment.component';
import { EditSaleInvoiceComponent } from './accounting/edit-sale-invoice/edit-sale-invoice.component';
import { InvoicePdfComponent } from './accounting/invoice-pdf/invoice-pdf.component';
import { EditPurchaseInvoiceComponent } from './accounting/edit-purchase-invoice/edit-purchase-invoice.component';
import { DetailedInvoiceSummaryComponent } from './accounting/detailed-invoice-summary/detailed-invoice-summary.component';
import { MakePaymentComponent } from './accounting/accounting-ap/make-payment/make-payment.component';
import { MultipleVendorsComponent } from './accounting/accounting-ap/multiple-vendors/multiple-vendors.component';
import { ConfirmPaymentsComponent } from './accounting/accounting-ap/confirm-payments/confirm-payments.component';
import { CommissionAgreementComponent } from './accounting/accounting-ap/commission-agreement/commission-agreement.component';
import { CreaateCommissionComponent } from './accounting/accounting-ap/creaate-commission/creaate-commission.component';
import { EventsComponent } from './crm/events/events/events.component';
import { CommissionInvoicesComponent } from './accounting/accounting-ap/commission-invoices/commission-invoices.component';
import { CreateCommissionInvoicesComponent } from './accounting/accounting-ap/create-commission-invoices/create-commission-invoices.component';
import { CommissionBalancesComponent } from './accounting/accounting-ap/commission-balances/commission-balances.component';
import { CommissionStatementComponent } from './accounting/accounting-ap/commission-statement/commission-statement.component';
import { PayablesReportsComponent } from './accounting/accounting-ap/payables-reports/payables-reports.component';
import { ReceivableSaleInvoiceComponent } from './accounting/accounting-ar/receivable-sale-invoice/receivable-sale-invoice.component';
import { CreateSaleInvoicesComponent } from './accounting/accounting-ar/create-sale-invoices/create-sale-invoices.component';
import { InvoicesServiceItemsComponent } from './accounting/accounting-ar/invoices-service-items/invoices-service-items.component';
import { ReceivalesPaymentComponent } from './accounting/accounting-ar/receivales-payment/receivales-payment.component';
import { ReceivePaymentComponent } from './accounting/accounting-ar/receive-payment/receive-payment.component';
import { CashDrawersComponent } from './accounting/accounting-ar/cash-drawers/cash-drawers.component';
import { CustomerAgreementsComponent } from './accounting/accounting-ar/customer-agreements/customer-agreements.component';
import { CreateCustomerAgreementsComponent } from './accounting/accounting-ar/create-customer-agreements/create-customer-agreements.component';
import { CustomerCreditComponent } from './accounting/accounting-ar/customer-credit/customer-credit.component';
import { ReceivableReportsComponent } from './accounting/accounting-ar/receivable-reports/receivable-reports.component';
import { DetaledLeadComponent } from './crm/detaled-lead/detaled-lead.component';
import { SuppliersComponent } from './purchasing/suppliers/suppliers.component';
import { OutsourceTaskComponent } from './purchasing/outsource-task/outsource-task.component';
import { NewFacilityGroupComponent } from './warehouse/new-facility-group/new-facility-group.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DepartmentsComponent } from './human-resource/departments/departments.component';
import { CreateDepartmentsComponent } from './human-resource/create-departments/create-departments.component';
import { CreateBillingComponent } from './accounting/accounting-ap/billing-account/create-billing/create-billing.component';
import { DetailedEmployeeSummaryComponent } from './human-resource/detailed-employee-summary/detailed-employee-summary.component';
import { DetailedEmployeePositionComponent } from './human-resource/detailed-employee-position/detailed-employee-position.component';
import { DetailedPaymentGroupsComponent } from './accounting/detailed-payment-groups/detailed-payment-groups.component';
import { AddJobInterviewComponent } from './human-resource/add-job-interview/add-job-interview.component';
import { InternalJobPostingApplicationComponent } from './human-resource/internal-job-posting-application/internal-job-posting-application.component';
import { NewInternalJobPostingComponent } from './human-resource/new-internal-job-posting/new-internal-job-posting.component';
import { VoidedComponent } from './accounting/payments/payments/voided/voided.component';
import { ReceivedComponent } from './accounting/payments/payments/received/received.component';
import { SentComponent } from './accounting/payments/payments/sent/sent.component';
import { NotPaidComponent } from './accounting/payments/payments/not-paid/not-paid.component';
import { PaymentApplicationComponent } from './accounting/payments/payments/payment-application/payment-application.component';
import { SettingComponent } from './setting/setting.component';
import { NewPositionTypeComponent } from './setting/new-position-type/new-position-type.component';
import { ReasonTypeComponent } from './setting/reason-type/reason-type.component';
import { NewPayGradeComponent } from './setting/new-pay-grade/new-pay-grade.component';
import { NotPaidHeaderComponent } from './accounting/payments/payments/not-paid-header/not-paid-header.component';
import { NotPaidApplicationsComponent } from './accounting/payments/payments/not-paid-applications/not-paid-applications.component';
import { EmployeeListComponent } from './employeeList/employee-list/employee-list.component';
import { EmployeeApplicationComponent } from './employeeApplication/employee-application/employee-application.component';
import { ResponsibilitiesComponent } from './responsibilities/responsibilities/responsibilities.component';
import { CreateEmployeeApplicationComponent } from './employeeApplication/create-employee-application/create-employee-application.component';
import { StoriesComponent } from '../app/catalog-mgmt/new-product-catalog/stories/stories/stories.component';
import { ImportComponent } from './contactImport/import/import.component';
import { ContactsComponent } from './newContacts/contacts/contacts.component';
import { ImportAccountComponent } from './accountImport/import/import.component';
import { NotesComponent } from './contactNotes/notes/notes.component';
import { AccountNotesComponent } from './accountNotes/account-notes/account-notes.component';
import { QuotesNotesComponent } from './quotesNotes/quotes-notes/quotes-notes.component';
import { ListRelatedAccountComponent } from './listRelated/list-related-account/list-related-account.component';
import { ListRelatedContactComponent } from './listRelated/list-related-contact/list-related-contact.component';
import { MyLeadComponent } from './myLead/my-lead/my-lead.component';
import { LeadUpdateComponent } from './updateLead/lead-update/lead-update.component';
import { PartyGroupComponent } from './updateLead/party-group/party-group.component';
import { TelecomComponent } from './updateLead/telecom/telecom.component';
import { EmailAddressComponent } from './updateLead/email-address/email-address.component';
import { PostalAddressComponent } from './updateLead/postal-address/postal-address.component';
import { LeadNotesComponent } from './leadNotes/lead-notes/lead-notes.component';
import { AssignByMeComponent } from './eventsAssignByme/assign-by-me/assign-by-me.component';
import { ConvertLeadComponent } from './convertLead/convert-lead/convert-lead.component';
import { EditContactGroupComponent } from './editContactGroup/edit-contact-group/edit-contact-group.component';
import { LeadPersonalGroupComponent } from './leadPersonalGroup/lead-personal-group/lead-personal-group.component';
import { MergeLeadComponent } from './mergeLead/merge-lead/merge-lead.component';
import { InterbalJobPostingAppComponent } from './internalJobPosting/interbal-job-posting-app/interbal-job-posting-app.component';
import { UpdateLeadComponent } from './updateLead/update-lead/update-lead.component';
import { LeadPersonalComponent } from './leadPersonal/lead-personal/lead-personal.component';
import { ContactInformationComponent } from './crm/contacts/contact-information/contact-information.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MyQuotesResolver } from './crm/quotations/my-quotes/my-quotes.resolver';
import { NewProductsResolver } from './catalog-mgmt/new-products/new-products.resolver';
import { DetailProductSummaryComponent } from './catalog-mgmt/detail-product-summary/detail-product-summary.component';
import { DetailProductSummaryResolver } from './catalog-mgmt/detail-product-summary/detail-product-summary.resolver';
import { DetailCategorySummaryComponent } from './catalog-mgmt/detail-category-summary/detail-category-summary.component';
import { DetailCategorySummaryResolver } from './catalog-mgmt/detail-category-summary/detail-category-summary.resolver';
import { ThesaurusResolver } from './catalog-mgmt/thesaurus/thesaurus.resolver';
import { DetailedSummaryFeatureCategoryComponent } from './catalog-mgmt/features/detailed-summary-feature-category/detailed-summary-feature-category.component';
import { CreateEditFeatureMaintenanceComponent } from './catalog-mgmt/features/detailed-summary-feature-category/create-edit-feature-maintenance/create-edit-feature-maintenance.component';
import { DetailedSummaryFeatureGroupComponent } from './catalog-mgmt/features/detailed-summary-feature-group/detailed-summary-feature-group.component';
import { SummaryPageComponent } from './catalog-mgmt/features/detailed-summary-feature-category/summary-page/summary-page.component';
import { DetailedSummaryFacilitiesComponent } from './warehouse/detailed-summary-facilities/detailed-summary-facilities.component';
import { CreateNewFacilitiesResolver } from './warehouse/create-new-facilities/create-new-facilities.resolver';
import { CreateNewFacilityLocationComponent } from './warehouse/detailed-summary-facilities/create-new-facility-location/create-new-facility-location.component';
import { CreateNewInventoryItemComponent } from './warehouse/detailed-summary-facilities/create-new-inventory-item/create-new-inventory-item.component';
import { UpdatePhysicalInventoryComponent } from './warehouse/detailed-summary-facilities/update-physical-inventory/update-physical-inventory.component';
import { CreateInventoryTransferComponent } from './warehouse/detailed-summary-facilities/create-inventory-transfer/create-inventory-transfer.component';
import { UpdateWarehouseShipmentComponent } from './warehouse/update-warehouse-shipment/update-warehouse-shipment.component';
import { CreateNewContactInformationComponent } from './warehouse/detailed-summary-facilities/create-new-contact-information/create-new-contact-information.component';
import { UpdateCustomerAgreementsComponent } from './accounting/accounting-ar/update-customer-agreements/update-customer-agreements.component';
import { WarehouseShipmentSummaryComponent } from './warehouse/warehouse-shipment-summary/warehouse-shipment-summary.component';
import { SummaryCustomerAgreementsComponent } from './accounting/accounting-ar/summary-customer-agreements/summary-customer-agreements.component';
import { CreateAgrrementTermsComponent } from './accounting/accounting-ar/create-agrrement-terms/create-agrrement-terms.component';
import { DetailedPayableInvoiceSummaryComponent } from './accounting/detailed-payable-invoice-summary/detailed-payable-invoice-summary.component';
import { CreateNewInvoiceRoleComponent } from './accounting/detailed-payable-invoice-summary/create-new-invoice-role/create-new-invoice-role.component';
import { CreateNewInvoiceTermComponent } from './accounting/detailed-payable-invoice-summary/create-new-invoice-term/create-new-invoice-term.component';
import { CreateNewInvoiceItemComponent } from './accounting/detailed-payable-invoice-summary/create-new-invoice-item/create-new-invoice-item.component';
import { SendEmailComponent } from './accounting/detailed-payable-invoice-summary/send-email/send-email.component';
import { CreditMemoComponent } from './accounting/accounting-ap/credit-memo/credit-memo.component';
import { CreateCreditMemoComponent } from './accounting/accounting-ap/create-credit-memo/create-credit-memo.component';
import { UpdateInvoiceComponent } from './accounting/update-invoice/update-invoice.component';
import { CreateNewAgreementItemComponent } from './accounting/accounting-ar/summary-customer-agreements/create-new-agreement-item/create-new-agreement-item.component';
import { CreateNewAgreementContentComponent } from './accounting/accounting-ar/summary-customer-agreements/create-new-agreement-content/create-new-agreement-content.component';
import { CreateNewAgreementRoleComponent } from './accounting/accounting-ar/summary-customer-agreements/create-new-agreement-role/create-new-agreement-role.component';
import { AgreementSummaryItemComponent } from './accounting/accounting-ar/summary-customer-agreements/agreement-summary-item/agreement-summary-item.component';
import { CreateNewItemPromotionComponent } from './accounting/accounting-ar/summary-customer-agreements/agreement-summary-item/create-new-item-promotion/create-new-item-promotion.component';
import { CreateNewItemTermComponent } from './accounting/accounting-ar/summary-customer-agreements/agreement-summary-item/create-new-item-term/create-new-item-term.component';
import { CreateNewItemProductComponent } from './accounting/accounting-ar/summary-customer-agreements/agreement-summary-item/create-new-item-product/create-new-item-product.component';
import { AgreementSummaryItemResolver } from './accounting/accounting-ar/summary-customer-agreements/agreement-summary-item/agreement-summary-item.resolver';
import { FindOrderComponent } from './order-management/find-order/find-order.component';
import { CreateNewItemGeoComponent } from './accounting/accounting-ar/summary-customer-agreements/agreement-summary-item/create-new-item-geo/create-new-item-geo.component';
import { CreateNewItemFacilityComponent } from './accounting/accounting-ar/summary-customer-agreements/agreement-summary-item/create-new-item-facility/create-new-item-facility.component';
import { CommissionInvoiceComponent } from './accounting/commission-invoice/commission-invoice.component';
import { CreateNewCommissionComponent } from './accounting/commission-invoice/create-new-commission-invoice/create-new-commission-invoice.component';
import { SummaryCommissionInvoiceComponent } from './accounting/commission-invoice/summary-commission-invoice/summary-commission-invoice.component';
import { EmpImportComponent } from './human-resource/employee-import/emp-import/emp-import.component';
import { CreateNewCommissionInvoiceItemComponent } from './accounting/commission-invoice/create-new-commission-invoice-item/create-new-commission-invoice-item.component';
import { ImportContactsComponent } from './crm/contacts/import-contacts/import-contacts.component';
import { PersonalUpdateComponent } from './human-resource/update-employe-personalInfo/personal-update/personal-update.component';
import { EmpContactInformationComponent } from './human-resource/emp-contact-information/emp-contact-information.component';
import { UpdatePostalAddressComponent } from './human-resource/update-postal-address/update-postal-address.component';
import { EmpRelatedAccountComponent } from './human-resource/emp-related-account/emp-related-account.component';
import { UpdatePhoneNumberComponent } from './human-resource/update-phone-number/update-phone-number.component';
import { CreateNewInvoiceCommissionTermComponent } from './accounting/commission-invoice/create-new-invoice-commission-term/create-new-invoice-commission-term.component';
import { CreatePayablePaymentComponent } from './accounting/accounting-ap/payables-payment/create-payable-payment/create-payable-payment.component';
import { SummaryPayablePaymentComponent } from './accounting/accounting-ap/payables-payment/summary-payable-payment/summary-payable-payment.component';
import { EmpUpdateEmailComponent } from './human-resource/emp-update-email/emp-update-email.component';
import { CreateOrderComponent } from './order-management/create-order/create-order.component';
import { SalesContinueComponent } from './order-management/sales-continue/sales-continue.component';
import { PurchaseContinueComponent } from './order-management/purchase-continue/purchase-continue.component';
import { CreateSalesContinueComponent } from './order-management/create-sales-continue/create-sales-continue.component';
import { EmpLeadNotesComponent } from './human-resource/emp-lead-notes/emp-lead-notes.component';
import { UpdatePayablePaymentComponent } from './accounting/accounting-ap/payables-payment/update-payable-payment/update-payable-payment.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { SalesPromoCodesComponent } from './order-management/sales-promo-codes/sales-promo-codes.component';
import { SalesProductComponent } from './order-management/sales-product/sales-product.component';
import { CreatePurchaseContinueComponent } from './order-management/create-purchase-continue/create-purchase-continue.component';
import { SalesFinalaizeOrderComponent } from './order-management/sales-finalaize-order/sales-finalaize-order.component';
import { PayablePaymentGroupComponent } from './accounting/accounting-ap/payable-payment-group/payable-payment-group.component';
import { SummaryPayablePaymentGroupComponent } from './accounting/accounting-ap/payable-payment-group/summary-payable-payment-group/summary-payable-payment-group.component';
import { IncomingPaymentComponent } from './accounting/accounting-ar/incoming-payment/incoming-payment.component';
import { CreateIncomingPaymentComponent } from './accounting/accounting-ar/incoming-payment/create-incoming-payment/create-incoming-payment.component';
import { SummaryIncomingPaymentComponent } from './accounting/accounting-ar/incoming-payment/summary-incoming-payment/summary-incoming-payment.component';
import { ArPaymentGroupComponent } from './accounting/accounting-ar/ar-payment-group/ar-payment-group.component';
import { SummaryArPaymentGroupComponent } from './accounting/accounting-ar/ar-payment-group/summary-ar-payment-group/summary-ar-payment-group.component';
import { BillingAccountComponent } from './accounting/accounting-ap/billing-account/billing-account.component';
import { EmpFulfillmentPositionComponent } from './human-resource/emp-fulfillment-position/emp-fulfillment-position.component';
import { EmpResponsibilityComponent } from './human-resource/emp-responsibility/emp-responsibility.component';
import { SummaryBillingAccountComponent } from './accounting/accounting-ap/billing-account/summary-billing-account/summary-billing-account.component';
import { EmpPositionReportedToComponent } from './human-resource/emp-position-reported-to/emp-position-reported-to.component';
import { EmpPositionReportByComponent } from './human-resource/emp-position-report-by/emp-position-report-by.component';


import { CreateSalesNewAddressComponent } from './order-management/create-sales-new-address/create-sales-new-address.component';
import { SalesOrderOptionsSettingsComponent } from './order-management/sales-order-options-settings/sales-order-options-settings.component';
import { PurchaseOrderOptionsSettingsComponent } from './order-management/purchase-order-options-settings/purchase-order-options-settings.component';

import { SalesOrderEntryTermsComponent } from './order-management/sales-order-entry-terms/sales-order-entry-terms.component';
import { SalesPaymentOptionsComponent } from './order-management/sales-payment-options/sales-payment-options.component';
import { SalesOrderAdditionalPartyComponent } from './order-management/sales-order-additional-party/sales-order-additional-party.component';
import { SalesOrderConfirmationComponent } from './order-management/sales-order-confirmation/sales-order-confirmation.component';
import { PurchaseOrderConfirmationComponent } from './order-management/purchase-order-confirmation/purchase-order-confirmation.component';
import { SalesOrderViewPageComponent } from './order-management/sales-order-view-page/sales-order-view-page.component';
import { ReturnMainComponent } from './order-management/return-page/return-main/return-main.component';
import { PurchaseOrderViewPageComponent } from './order-management/purchase-order-view-page/purchase-order-view-page.component';
import { CreateNewReturnComponent } from './order-management/return-page/create-new-return/create-new-return.component';
import { ReturnHistoryComponent } from './order-management/return-page/return-history/return-history.component';
import { ReturnItemsComponent } from './order-management/return-page/return-items/return-items.component';
import { CreateNotesComponent } from './order-management/create-notes/create-notes.component';
import { ViewOrderHistoryComponent } from './order-management/view-order-history/view-order-history.component';
import { SummaryCreditMemoComponent } from './accounting/accounting-ap/credit-memo/summary-credit-memo/summary-credit-memo.component';
import { SendCreditMemoMailComponent } from './accounting/accounting-ap/credit-memo/summary-credit-memo/send-credit-memo-mail/send-credit-memo-mail.component';
import { CreateCreditItemComponent } from './accounting/accounting-ap/credit-memo/summary-credit-memo/create-credit-item/create-credit-item.component';
import { CreateCreditRoleComponent } from './accounting/accounting-ap/credit-memo/summary-credit-memo/create-credit-role/create-credit-role.component';
import { CreateCreditTermComponent } from './accounting/accounting-ap/credit-memo/summary-credit-memo/create-credit-term/create-credit-term.component';
import { AllEventsComponent } from './crm/events/all-events/all-events.component';
import { CrmDashboardComponent } from './crm/crm-dashboard/crm-dashboard.component';
import { WarehouseDashboardComponent } from './warehouse/warehouse-dashboard/warehouse-dashboard.component';
import { UserDashboardComponent } from './users/user-dashboard/user-dashboard.component';
import { CatalogDashboardComponent } from './catalog-mgmt/catalog-dashboard/catalog-dashboard.component';
import { HrmDashboardComponent } from './human-resource/hrm-dashboard/hrm-dashboard.component';
import { OrderDashboardComponent } from './order-management/order-dashboard/order-dashboard.component';
import { ArDashboardComponent } from './accounting/accounting-ar/ar-dashboard/ar-dashboard.component';
import { PurchaseFinalaizeOrderComponent } from './order-management/purchase-finalaize-order/purchase-finalaize-order.component';
import { CreatePurchaseNewAddressComponent } from './order-management/create-purchase-new-address/create-purchase-new-address.component';
import { PurchaseOrderEntryTermsComponent } from './order-management/purchase-order-entry-terms/purchase-order-entry-terms.component';
import { PurchasePaymentOptionsComponent } from './order-management/purchase-payment-options/purchase-payment-options.component';

import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule as CalendarPrime } from 'primeng/calendar';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseOrderAdditionalPartyComponent } from './order-management/purchase-order-additional-party/purchase-order-additional-party.component';
import { DetailedTimesheetSummaryComponent } from './human-resource/detailed-timesheet-summary/detailed-timesheet-summary.component';
import { CreateRoleComponent } from './human-resource/create-role/create-role.component';
import { UpdateApplicationComponent } from './catalog-mgmt/features/update-application/update-application.component';
import { ReviewOrderNotPickedComponent } from './warehouse/detailed-summary-facilities/review-order-not-picked/review-order-not-picked.component';
import { PhysicalUpdateInventoryComponent } from './warehouse/detailed-summary-facilities/physical-update-inventory/physical-update-inventory.component';
import { ShowReturnComponent } from './warehouse/detailed-summary-facilities/show-return/show-return.component';
import { ReturnViewOrderComponent } from './order-management/return-page/return-view-order/return-view-order.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { ImportUserComponent } from './users/import-user/import-user.component';
import { UserDetailSummaryComponent } from './users/user-detail-summary/user-detail-summary.component';
import { AddToRoleComponent } from './users/add-to-role/add-to-role.component';
import { NewRoleTypeComponent } from './users/new-role-type/new-role-type.component';
import { OrderReceivePaymentComponent } from './order-management/order-receive-payment/order-receive-payment.component';
import { SecurityGroupComponent } from './users/security-group/security-group.component';
import { CreateSecurityGroupComponent } from './users/create-security-group/create-security-group.component';
import { PermisionDetailPageComponent } from './users/permision-detail-page/permision-detail-page.component';
import { AddPermisionManuallyComponent } from './users/add-permision-manually/add-permision-manually.component';
import { AddPermissionListComponent } from './users/add-permission-list/add-permission-list.component';
import { AddFinancailAccountComponent } from './accounting/add-financail-account/add-financail-account.component';
import { CreateFinancialAccountComponent } from './accounting/create-financial-account/create-financial-account.component';
import { DetailFinancialSummaryComponent } from './accounting/detail-financial-summary/detail-financial-summary.component';
import { CreateFinancialRoleComponent } from './accounting/create-financial-role/create-financial-role.component';
import { CreateFinancialTransactionComponent } from './accounting/create-financial-transaction/create-financial-transaction.component';
import { BankReconciliationComponent } from './accounting/bank-reconciliation/bank-reconciliation.component';
import { FinancialNewDepositPaymentComponent } from './accounting/financial-new-deposit-payment/financial-new-deposit-payment.component';
import { FinancialNewWithdrawPaymentComponent } from './accounting/financial-new-withdraw-payment/financial-new-withdraw-payment.component';
import { FinancialNewReconciliationComponent } from './accounting/financial-new-reconciliation/financial-new-reconciliation.component';
import { DetailSummaryReconciliationComponent } from './accounting/detail-summary-reconciliation/detail-summary-reconciliation.component';
import { FixAssestListComponent } from './accounting/fix-assest-list/fix-assest-list.component';
import { CreateFixAssestComponent } from './accounting/create-fix-assest/create-fix-assest.component';
import { FixAssestSummaryPageComponent } from './accounting/fix-assest-summary-page/fix-assest-summary-page.component';
import { CreateProductComponent } from './accounting/create-product/create-product.component';
import { CreateFixAssignmentComponent } from './accounting/create-fix-assignment/create-fix-assignment.component';
import { GlobalGlSettingListComponent } from './accounting/global-gl-setting-list/global-gl-setting-list.component';
import { CreateGlobalSettingComponent } from './accounting/create-global-setting/create-global-setting.component';
import { AssignGlAccountComponent } from './accounting/assign-gl-account/assign-gl-account.component';
import { GlAccountSummaryComponent } from './accounting/gl-account-summary/gl-account-summary.component';
import { GlobalGlCostComponent } from './accounting/global-gl-cost/global-gl-cost.component';
import { CreateGlobalCostComponent } from './accounting/create-global-cost/create-global-cost.component';
import { GlCustomTimePeriodListComponent } from './accounting/gl-custom-time-period-list/gl-custom-time-period-list.component';
import { CreateTimePeriodComponent } from './accounting/create-time-period/create-time-period.component';
import { GlobalGlRateListComponent } from './accounting/global-gl-rate-list/global-gl-rate-list.component';
import { CreateGlRatesComponent } from './accounting/create-gl-rates/create-gl-rates.component';
import { GlPaymentMethodTypeComponent } from './accounting/gl-payment-method-type/gl-payment-method-type.component';
import { UpdatePaymentMethodTypeComponent } from './accounting/update-payment-method-type/update-payment-method-type.component';
import { GlInvoiceTypeListComponent } from './accounting/gl-invoice-type-list/gl-invoice-type-list.component';
import { UpdateInvoiceMethodTypeComponent } from './accounting/update-invoice-method-type/update-invoice-method-type.component';
import { GlForeignExchangeRateComponent } from './accounting/gl-foreign-exchange-rate/gl-foreign-exchange-rate.component';
import { CreateForeignExchangeRateComponent } from './accounting/create-foreign-exchange-rate/create-foreign-exchange-rate.component';
import { GlAccountCategoryListComponent } from './accounting/gl-account-category-list/gl-account-category-list.component';
import { CreateGlAccountCategoryComponent } from './accounting/create-gl-account-category/create-gl-account-category.component';
import { DetailAccountCategorySummaryComponent } from './accounting/detail-account-category-summary/detail-account-category-summary.component';
import { CreateAccountCategoryMemberComponent } from './accounting/create-account-category-member/create-account-category-member.component';
import { GlCostCenterComponent } from './accounting/gl-cost-center/gl-cost-center.component';
import { LoginGuard } from './guards/login.guard';
import { CreateCreditCardComponent } from './crm/accounts/detailed-accounts-summary/create-credit-card/create-credit-card.component';
import { CreateGiftCardComponent } from './crm/accounts/detailed-accounts-summary/create-gift-card/create-gift-card.component';
import { CreateEFTAccountComponent } from './crm/accounts/detailed-accounts-summary/create-eft-account/create-eft-account.component';
import { CreateBillingAccountComponent } from './crm/accounts/detailed-accounts-summary/create-billing-account/create-billing-account.component';
import { AssignListGlComponent } from './accounting/assign-list-gl/assign-list-gl.component';
import { QuoteEmailComponent } from './crm/quotations/detailed-quote-summary/quote-email/quote-email.component';
import { CreateCheckAccountComponent } from './crm/accounts/detailed-accounts-summary/create-check-account/create-check-account.component';
import { CreateAccountAdditionalDetailsComponent } from './crm/accounts/detailed-accounts-summary/create-account-additional-details/create-account-additional-details.component';
import { CreateAccountJobInfoComponent } from './crm/accounts/detailed-accounts-summary/create-account-job-info/create-account-job-info.component';
import { ListVendorsComponent } from './accounting/accounting-ap/list-vendors/list-vendors.component';
import { NewVendorCreateComponent } from './accounting/accounting-ap/new-vendor-create/new-vendor-create.component';
import { CreateSupplierProductComponent } from './catalog-mgmt/create-supplier-product/create-supplier-product.component';
import { AccountPostalAddressComponent } from './crm/accounts/detailed-accounts-summary/account-postal-address/account-postal-address.component';
import { EmployeeEmergencyContactComponent } from './human-resource/detailed-employee-summary/employee-emergency-contact/employee-emergency-contact.component';
import { EmployeeFaxInformationComponent } from './human-resource/detailed-employee-summary/employee-fax-information/employee-fax-information.component';
import { NumberOnlyDirective } from './directives/numberOnly.directive';
import { TreeModule } from 'primeng/tree';
import { UpdateApprovalComponent } from './human-resource/approval/update-approval/update-approval.component';
import { AccountFaxNumberComponent } from './crm/accounts/detailed-accounts-summary/account-fax-number/account-fax-number.component';
import { DeleteConfirmationComponent } from './shared/delete-confirmation/delete-confirmation.component';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { ProductPriceComponent } from './catalog-mgmt/detail-product-summary/product-price/product-price.component';
import { CreateProductContentComponent } from './catalog-mgmt/detail-product-summary/create-product-content/create-product-content.component';
import { UpdateContactInfoComponent } from './update-contact-info/update-contact-info.component';
import { DetailDepartmentSummaryComponent } from './human-resource/detail-department-summary/detail-department-summary.component';
import { DetailSkillSummaryComponent } from './human-resource/detail-skill-summary/detail-skill-summary.component';
import { DetailRequistionSummaryComponent } from './human-resource/detail-requistion-summary/detail-requistion-summary.component';
import { DetailInternalJobSummaryComponent } from './human-resource/detail-internal-job-summary/detail-internal-job-summary.component';
import { JobInterviewSummaryComponent } from './human-resource/job-interview-summary/job-interview-summary.component';
import { ApprovalSummaryComponent } from './human-resource/approval-summary/approval-summary.component';
import { ReallocationSummaryComponent } from './human-resource/reallocation-summary/reallocation-summary.component';
import { LeaveSummaryComponent } from './human-resource/leave/leave-summary/leave-summary.component';
import { EmployeeApplicationSummaryComponent } from './employeeApplication/employee-application-summary/employee-application-summary.component';
import { TrainingSummaryComponent } from './human-resource/training-summary/training-summary.component';
import { DetailSkillTypeComponent } from './detail-skill-type/detail-skill-type.component';
import { DetailResTypeComponent } from './detail-res-type/detail-res-type.component';
import { DetailHolidaySummaryComponent } from './detail-holiday-summary/detail-holiday-summary.component';
import { DetailTrainingClassTypeComponent } from './detail-training-class-type/detail-training-class-type.component';
import { DetailPositionTypeComponent } from './detail-position-type/detail-position-type.component';
import { RespDetailSummaryComponent } from './resp-detail-summary/resp-detail-summary.component';
import { DetailJobSummaryComponent } from './detail-job-summary/detail-job-summary.component';
import { DetailLeaveSummaryComponent } from './detail-leave-summary/detail-leave-summary.component';
import { DetailReasonTypeComponent } from './detail-reason-type/detail-reason-type.component';
import { DetailVendorSummaryComponent } from './accounting/accounting-ap/detail-vendor-summary/detail-vendor-summary.component';
import { DetailFacilityGroupSummaryComponent } from './warehouse/detail-facility-group-summary/detail-facility-group-summary.component';
import { DetailLabelTypeSummaryComponent } from './warehouse/detail-label-type-summary/detail-label-type-summary.component';
import { DetailInventoryLabelSummaryComponent } from './warehouse/detail-inventory-label-summary/detail-inventory-label-summary.component';
import { DetailShipmentSummaryComponent } from './catalog-mgmt/detail-shipment-summary/detail-shipment-summary.component';
import { DetailQuantityBreakSummaryComponent } from './catalog-mgmt/detail-quantity-break-summary/detail-quantity-break-summary.component';
import { DetailCarrierSummaryComponent } from './catalog-mgmt/detail-carrier-summary/detail-carrier-summary.component';
import { DetailFeatureTypeComponent } from './catalog-mgmt/detail-feature-type/detail-feature-type.component';
import { ArSubmenuComponent } from './accounting/ar-submenu/ar-submenu.component';
import { AccountMainPageComponent } from './accounting/account-main-page/account-main-page.component';
import { MainComponent } from './human-resource/main/main.component';
import { NewIdentificationComponent } from './human-resource/new-identification/new-identification.component';
import { NewUsernameComponent } from './human-resource/new-username/new-username.component';
import { NewPartyAttributeComponent } from './human-resource/new-party-attribute/new-party-attribute.component';
import { MainCompanyComponent } from './human-resource/main-company/main-company.component';
import { OverviewSkillComponent } from './human-resource/overview-skill/overview-skill.component';
import { OverviewLeaveComponent } from './human-resource/overview-leave/overview-leave.component';
import { CompanyProfileComponent } from './users/company-profile/company-profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ConfirmPasswordComponent } from './confirm-password/confirm-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { MainCrmComponent } from './crm/main-crm/main-crm.component';
import { MainDetailSummaryComponent } from './crm/main-detail-summary/main-detail-summary.component';
import { NewInternalNoteComponent } from './crm/new-internal-note/new-internal-note.component';
import { NewMailComponent } from './crm/new-mail/new-mail.component';
import { MailReplyComponent } from './crm/mail-reply/mail-reply.component';
import { CrmOverviewComponent } from './crm/crm-overview/crm-overview.component';
import { CreateEventPurposeComponent } from './crm/create-event-purpose/create-event-purpose.component';
import { CreateEventRoleComponent } from './crm/create-event-role/create-event-role.component';
import { CreateWorkEffortComponent } from './crm/create-work-effort/create-work-effort.component';
import { CreateContentComponent } from './crm/create-content/create-content.component';
import { CreateCommunicationOrderComponent } from './crm/create-communication-order/create-communication-order.component';
import { CreateCrmProductComponent } from './crm/create-crm-product/create-crm-product.component';
import { CreateCrmReturnComponent } from './crm/create-crm-return/create-crm-return.component';
import { NewCommunicationCrmComponent } from './crm/new-communication-crm/new-communication-crm.component';
import { OrganizationGlSettingComponent } from './accounting/organization-gl-setting/organization-gl-setting.component';
import { CreateOrganizationGlSettingComponent } from './accounting/create-organization-gl-setting/create-organization-gl-setting.component';
import { DetailOrganizationGlSummaryComponent } from './accounting/detail-organization-gl-summary/detail-organization-gl-summary.component';
import { CreateOrganizationChartAccountComponent } from './accounting/create-organization-chart-account/create-organization-chart-account.component';
import { CreateDocTypeComponent } from './accounting/create-doc-type/create-doc-type.component';
import { CreateJournalComponent } from './accounting/create-journal/create-journal.component';
import { CreateGlAccountTypeDefaultComponent } from './accounting/create-gl-account-type-default/create-gl-account-type-default.component';
import { CreateGlProductAccountComponent } from './accounting/create-gl-product-account/create-gl-product-account.component';
import { CreateGlProductCategoryAccountComponent } from './accounting/create-gl-product-category-account/create-gl-product-category-account.component';
import { CreateFinGlAccountTypeComponent } from './accounting/create-fin-gl-account-type/create-fin-gl-account-type.component';
import { CreateSalesInvoiceGlAccountComponent } from './accounting/create-sales-invoice-gl-account/create-sales-invoice-gl-account.component';
import { CreatePurchaseInvoiceGlAccountComponent } from './accounting/create-purchase-invoice-gl-account/create-purchase-invoice-gl-account.component';
import { CreatePurchaseOrderComponent } from './order-management/create-purchase-order/create-purchase-order.component';
import { GlAccountTypePaymentTypeComponent } from './accounting/gl-account-type-payment-type/gl-account-type-payment-type.component';
import { CreatePaymentMethodTypeAccountComponent } from './accounting/create-payment-method-type-account/create-payment-method-type-account.component';
import { CreateVarianceReasonTypeComponent } from './accounting/create-variance-reason-type/create-variance-reason-type.component';
import { CreateCreditCardTypeComponent } from './accounting/create-credit-card-type/create-credit-card-type.component';
import { CreatePartyGlAccountComponent } from './accounting/create-party-gl-account/create-party-gl-account.component';
import { CreateGlAccountFixedAssestComponent } from './accounting/create-gl-account-fixed-assest/create-gl-account-fixed-assest.component';
import { CreateTaxAuthorityComponent } from './accounting/create-tax-authority/create-tax-authority.component';
import { DetailAccountingSummaryComponent } from './accounting/detail-accounting-summary/detail-accounting-summary.component';
import { EditAccountReconcilationComponent } from './accounting/edit-account-reconcilation/edit-account-reconcilation.component';
import { CreateAccountingTransactionComponent } from './accounting/create-accounting-transaction/create-accounting-transaction.component';
import { CreateAccountingQuickTransactionComponent } from './accounting/create-accounting-quick-transaction/create-accounting-quick-transaction.component';
import { UnpostedAccountTransactionComponent } from './accounting/unposted-account-transaction/unposted-account-transaction.component';
import { CreateGlTimePeriodComponent } from './accounting/create-gl-time-period/create-gl-time-period.component';
import { DetailAccountingTransactionComponent } from './accounting/detail-accounting-transaction/detail-accounting-transaction.component'
import { BudgetListComponent } from './accounting/budget-list/budget-list.component';
import { CreateBudgetComponent } from './accounting/create-budget/create-budget.component';
import { DetailBudgetSummaryComponent } from './accounting/detail-budget-summary/detail-budget-summary.component';
import { CreateAccItemComponent } from './accounting/create-acc-item/create-acc-item.component';
import { CreateAccRoleComponent } from './accounting/create-acc-role/create-acc-role.component';
import { CreateAccReviewComponent } from './accounting/create-acc-review/create-acc-review.component';
import { CreateTransEntriesComponent } from './accounting/create-trans-entries/create-trans-entries.component';
import { AuthorizeTransactionComponent } from './accounting/authorize-transaction/authorize-transaction.component';
import { CaptureComponent } from './accounting/capture/capture.component';
import { PaymentGatewayResponseComponent } from './accounting/payment-gateway-response/payment-gateway-response.component';
import { ManualElectronicTransactionComponent } from './accounting/manual-electronic-transaction/manual-electronic-transaction.component';
import { ViewGatewayResponseComponent } from './accounting/view-gateway-response/view-gateway-response.component';
import { TaxAuthorityComponent } from './accounting/tax-authority/tax-authority.component';
import { AddTaxAuthDetailComponent } from './accounting/add-tax-auth-detail/add-tax-auth-detail.component';
import { DetailTaxAuthorityComponent } from './accounting/detail-tax-authority/detail-tax-authority.component';
import { CreateTaxCategoryComponent } from './accounting/create-tax-category/create-tax-category.component';
import { CreateTaxAssociationComponent } from './accounting/create-tax-association/create-tax-association.component';
import { CreateTaxGlAccountComponent } from './accounting/create-tax-gl-account/create-tax-gl-account.component';
import { CreateTaxProductRatesComponent } from './accounting/create-tax-product-rates/create-tax-product-rates.component';
import { CreateTaxPartyComponent } from './accounting/create-tax-party/create-tax-party.component';
import { JobshopListComponent } from './production/jobshop-list/jobshop-list.component';
import { RoutingComponent } from './production/routing/routing.component';
import { CreateJobshopComponent } from './production/create-jobshop/create-jobshop.component';
import { DetailJobshopSummaryComponent } from './production/detail-jobshop-summary/detail-jobshop-summary.component';
import { CreateJobshopTaskComponent } from './production/create-jobshop-task/create-jobshop-task.component';
import { CreateJobshopMaterialComponent } from './production/create-jobshop-material/create-jobshop-material.component';
import { CreateJobshopFixedassetComponent } from './production/create-jobshop-fixedasset/create-jobshop-fixedasset.component';
import { CreateJobshopPartiesComponent } from './production/create-jobshop-parties/create-jobshop-parties.component';
import { CreateJobshopAssocsComponent } from './production/create-jobshop-assocs/create-jobshop-assocs.component';
import { CreateRoutingComponent } from './production/create-routing/create-routing.component';
import { DetailRoutingSummaryComponent } from './production/detail-routing-summary/detail-routing-summary.component';
import { CreateRoutingProductLinkComponent } from './production/create-routing-product-link/create-routing-product-link.component';
import { CreateRoutingTaskAssocComponent } from './production/create-routing-task-assoc/create-routing-task-assoc.component';
import { RoutingTaskComponent } from './production/routing-task/routing-task.component';
import { AddRouteTaskComponent } from './production/add-route-task/add-route-task.component';
import { DetailRoutingTaskSummaryComponent } from './production/detail-routing-task-summary/detail-routing-task-summary.component';
import { CreateRouteTaskCostComponent } from './production/create-route-task-cost/create-route-task-cost.component';
import { CreateTaskDeliverableProductComponent } from './production/create-task-deliverable-product/create-task-deliverable-product.component';
import { CreateRoutingTaskFixedAssetComponent } from './production/create-routing-task-fixed-asset/create-routing-task-fixed-asset.component';
import { CreateRoutingTaskPartiesComponent } from './production/create-routing-task-parties/create-routing-task-parties.component';
import { CreateRoutingTaskSkillsComponent } from './production/create-routing-task-skills/create-routing-task-skills.component';
import { CalenderListComponent } from './production/calender-list/calender-list.component';
import { WeekListComponent } from './production/week-list/week-list.component';
import { CreateNewCalenderComponent } from './production/create-new-calender/create-new-calender.component';
import { CreateNewWeekComponent } from './production/create-new-week/create-new-week.component';
import { CostCaclsListComponent } from './production/cost-cacls-list/cost-cacls-list.component';
import { CreateCostCaclComponent } from './production/create-cost-cacl/create-cost-cacl.component';
import { BillOfMaterialListComponent } from './production/bill-of-material-list/bill-of-material-list.component';
import { CreateProductBomComponent } from './production/create-product-bom/create-product-bom.component';
import { CreateBomSimulationComponent } from './production/create-bom-simulation/create-bom-simulation.component';
import { ManufacturingRulesComponent } from './production/manufacturing-rules/manufacturing-rules.component';
import { CreateManufacturingRulesComponent } from './production/create-manufacturing-rules/create-manufacturing-rules.component';
import { ViewPartyProfileComponent } from './accounting/view-party-profile/view-party-profile.component';
import { PartyRoleTypeComponent } from './accounting/party-role-type/party-role-type.component';
import { PartyAddToRoleComponent } from './accounting/party-add-to-role/party-add-to-role.component';
import { PartyCreateIdentificationNumberComponent } from './accounting/party-create-identification-number/party-create-identification-number.component';
import { MrpLogListComponent } from './production/mrp-log-list/mrp-log-list.component';
import { CreateRunMrpComponent } from './production/create-run-mrp/create-run-mrp.component';
import { ShipmentPlanListComponent } from './production/shipment-plan-list/shipment-plan-list.component';
import { CreateActualMaterialComponent } from './production/create-actual-material/create-actual-material.component';
import { ProductionRunDeclarationComponent } from './production/production-run-declaration/production-run-declaration.component';
import { ReportComponent } from './production/report/report.component';
import { AddQuantityProducedComponent } from './production/add-quantity-produced/add-quantity-produced.component';
import { NewRelationshipTypeComponent } from './accounting/new-relationship-type/new-relationship-type.component';
import { AddOtherPartyRelationshipComponent } from './accounting/add-other-party-relationship/add-other-party-relationship.component';
import { EditPartyRatesComponent } from './accounting/edit-party-rates/edit-party-rates.component';
import { AddPartySkillComponent } from './accounting/add-party-skill/add-party-skill.component';
import { AddPartyContentComponent } from './accounting/add-party-content/add-party-content.component';
import { AddPartySegmentRoleComponent } from './accounting/add-party-segment-role/add-party-segment-role.component';
import { AddPartyResumesComponent } from './accounting/add-party-resumes/add-party-resumes.component';
import { EditPersonalInformationComponent } from './accounting/edit-personal-information/edit-personal-information.component';
import { AddRelatedAccountComponent } from './accounting/add-related-account/add-related-account.component';
import { AddNewContactInformationComponent } from './accounting/add-new-contact-information/add-new-contact-information.component';
import { CreateUserLoginComponent } from './accounting/create-user-login/create-user-login.component';
import { CreatePartyAttributeComponent } from './accounting/create-party-attribute/create-party-attribute.component';
import { NewPartyNoteComponent } from './accounting/new-party-note/new-party-note.component';

import { AttachContentComponent } from './accounting/attach-content/attach-content.component';
import { DetailCalcCostComponent } from './production/detail-calc-cost/detail-calc-cost.component';
import { DetailCalenderComponent } from './production/detail-calender/detail-calender.component';
import { CreateExceptionDayComponent } from './production/create-exception-day/create-exception-day.component';
import { CreateExceptionWeekComponent } from './production/create-exception-week/create-exception-week.component';
import { BomSimulationListComponent } from './production/bom-simulation-list/bom-simulation-list.component';
import { UpdateMaterialUsedComponent } from './production/update-material-used/update-material-used.component';
import { UpdateProductBomComponent } from './production/update-product-bom/update-product-bom.component';
import { FindPartyComponent } from './partyQuickLink/find-party/find-party.component';
import { MyCommunicationsComponent } from './partyQuickLink/my-communications/my-communications.component';
import { CommunicationsComponent } from './partyQuickLink/communications/communications.component';
import { VisitsComponent } from './partyQuickLink/visits/visits.component';
import { LoggedInUsersComponent } from './partyQuickLink/logged-in-users/logged-in-users.component';
import { ClassificationsComponent } from './partyQuickLink/classifications/classifications.component';
import { SecurityComponent } from './partyQuickLink/security/security.component';
import { AddressMatchMapComponent } from './partyQuickLink/address-match-map/address-match-map.component';
import { InvitationComponent } from './partyQuickLink/invitation/invitation.component';
import { ImportExportComponent } from './partyQuickLink/import-export/import-export.component';
import { MainPartyQuickLinksComponent } from './partyQuickLink/main-party-quick-links/main-party-quick-links.component';
import { CreateEmployeeComponent } from './partyQuickLink/create-employee/create-employee.component';
import { CreateProspectComponent } from './partyQuickLink/create-prospect/create-prospect.component';
import { CreateCustomerComponent } from './partyQuickLink/create-customer/create-customer.component';
import { CreateNewPersonComponent } from './partyQuickLink/create-new-person/create-new-person.component';
import { CreateNewPartyGroupComponent } from './partyQuickLink/create-new-party-group/create-new-party-group.component';
import { CreateNewCommunicationComponent } from './partyQuickLink/create-new-communication/create-new-communication.component';
import { CreateNewPartyClassificationGroupComponent } from './partyQuickLink/create-new-party-classification-group/create-new-party-classification-group.component';
import { CreateNewSecurityGroupComponent } from './partyQuickLink/create-new-security-group/create-new-security-group.component';
import { CreateNewUserLoginComponent } from './partyQuickLink/create-new-user-login/create-new-user-login.component';
import { CreateAddressMatchMapComponent } from './partyQuickLink/create-address-match-map/create-address-match-map.component';
import { CreatePartyInvitationComponent } from './partyQuickLink/create-party-invitation/create-party-invitation.component';
import { MainMyPortalComponent } from './my-portal/main-my-portal/main-my-portal.component';
import { CreateProductVariantComponent } from './catalog-mgmt/create-product-variant/create-product-variant.component';
import { MyPortalMyCommsComponent } from './my-portal/my-portal-my-comms/my-portal-my-comms.component';
import { MyPortalMyProfileComponent } from './my-portal/my-portal-my-profile/my-portal-my-profile.component';
import { MyPortalOtherPartyCommsComponent } from './my-portal/my-portal-other-party-comms/my-portal-other-party-comms.component';
import { MyPortalMyTimeSheetComponent } from './my-portal/my-portal-my-time-sheet/my-portal-my-time-sheet.component';
import { MyPortalMyTasksComponent } from './my-portal/my-portal-my-tasks/my-portal-my-tasks.component';
import { MyPortalMyLeaveComponent } from './my-portal/my-portal-my-leave/my-portal-my-leave.component';
import { MyPortalMyTrainingComponent } from './my-portal/my-portal-my-training/my-portal-my-training.component';
import { MyPortalMyPaymentsComponent } from './my-portal/my-portal-my-payments/my-portal-my-payments.component';
import { MyPortalPreferencesComponent } from './my-portal/my-portal-preferences/my-portal-preferences.component';
import { MyPortalPageAdminComponent } from './my-portal/my-portal-page-admin/my-portal-page-admin.component';
import { CreatePublicMessageComponent } from './my-portal/create-public-message/create-public-message.component';
import { CreateNewPortalPageComponent } from './my-portal/create-new-portal-page/create-new-portal-page.component';
import { ProfilePartyQuickLinkComponent } from './partyQuickLink/profile-party-quick-link/profile-party-quick-link.component';
import { CreateClassificationPartyComponent } from './partyQuickLink/create-classification-party/create-classification-party.component';
import { UpdateProfilePasswordComponent } from './partyQuickLink/update-profile-password/update-profile-password.component';
import { PartySecurityGroupListComponent } from './partyQuickLink/party-security-group-list/party-security-group-list.component';
import { CreatePartySecutiyGroupComponent } from './partyQuickLink/create-party-secutiy-group/create-party-secutiy-group.component';
import { CreatePartyContactComponent } from './partyQuickLink/create-party-contact/create-party-contact.component';
import { CreatePartyEmployeApplicationComponent } from './partyQuickLink/create-party-employe-application/create-party-employe-application.component';
import { CreateProductStoreComponent } from './partyQuickLink/create-product-store/create-product-store.component';
import { DetailInvitationSummaryComponent } from './partyQuickLink/detail-invitation-summary/detail-invitation-summary.component';
import { CreatePartyGroupAssocComponent } from './partyQuickLink/create-party-group-assoc/create-party-group-assoc.component';
import { CreatePartyRoleTypeComponent } from './partyQuickLink/create-party-role-type/create-party-role-type.component';
import { CreateGroupClassificationComponent } from './partyQuickLink/create-group-classification/create-group-classification.component';
import { DetailSecurityComponent } from './partyQuickLink/detail-security/detail-security.component';
import { CreateProtectedViewComponent } from './partyQuickLink/create-protected-view/create-protected-view.component';
import { CreatePartyLoginComponent } from './partyQuickLink/create-party-login/create-party-login.component';
import { VisitorDetailSummaryComponent } from './partyQuickLink/visitor-detail-summary/visitor-detail-summary.component';
import { PartyClassificationComponent } from './partyQuickLink/party-classification/party-classification.component';
import { ImportAddressComponent } from './partyQuickLink/import-address/import-address.component';
import { PayrollComponent } from './accounting/payroll/payroll.component';
import { MainVendorComponent } from './accounting/main-vendor/main-vendor.component';
import { QuickAddShoppingComponent } from './partyQuickLink/quick-add-shopping/quick-add-shopping.component';
import { NewImportComponent } from './new-import/new-import.component';
import { CompanyInformationImportComponent } from './new-import/company-information-import/company-information-import.component';
import { FiscalYearImportComponent } from './new-import/fiscal-year-import/fiscal-year-import.component';
import { EmailConfigurationImportComponent } from './new-import/email-configuration-import/email-configuration-import.component';
import { IssueCreatedImportComponent } from './new-import/issue-created-import/issue-created-import.component';
import { EditShoppingListComponent } from './partyQuickLink/edit-shopping-list/edit-shopping-list.component';
import { CreateTaxInfoComponent } from './partyQuickLink/create-tax-info/create-tax-info.component';
import { CreateAvsStringComponent } from './partyQuickLink/create-avs-string/create-avs-string.component';
import { BalanceSheetImportComponent } from './new-import/balance-sheet-import/balance-sheet-import.component';
import { TrialBalanceImportComponent } from './new-import/trial-balance-import/trial-balance-import.component';
import { TrialBalanceOnlyImportComponent } from './new-import/trial-balance-only-import/trial-balance-only-import.component';
import { CashFlowImportComponent } from './new-import/cash-flow-import/cash-flow-import.component';
import { AccountsGroupImportComponent } from './new-import/accounts-group-import/accounts-group-import.component';
import { DefaultsAccountsImportComponent } from './new-import/defaults-accounts-import/defaults-accounts-import.component';
import { CreateTaskComponent } from './my-portal/create-task/create-task.component';
import { SecurityGroupImportComponent } from './new-import/security-group-import/security-group-import.component';
import { TaxComponentImportComponent } from './new-import/tax-component-import/tax-component-import.component';
import { TaxesOnlyImportComponent } from './new-import/taxes-only-import/taxes-only-import.component';
import { PaymentsTermsImportComponent } from './new-import/payments-terms-import/payments-terms-import.component';
import { BanksOnlyImportComponent } from './new-import/banks-only-import/banks-only-import.component';
import { CashiersOnlyImportComponent } from './new-import/cashiers-only-import/cashiers-only-import.component';
import { BranchesOnlyImportComponent } from './new-import/branches-only-import/branches-only-import.component';
import { OpenCustomersInvoicesImportComponent } from './new-import/open-customers-invoices-import/open-customers-invoices-import.component';
import { OpenVendorsInvoicesImportComponent } from './new-import/open-vendors-invoices-import/open-vendors-invoices-import.component';
import { GlAccountBalancesImportComponent } from './new-import/gl-account-balances-import/gl-account-balances-import.component';
import { SalesRepresentativesImportComponent } from './new-import/sales-representatives-import/sales-representatives-import.component';
import { AccountsByPartFamilyImportComponent } from './new-import/accounts-by-part-family-import/accounts-by-part-family-import.component';
import { UnitOfMeasureImportComponent } from './new-import/unit-of-measure-import/unit-of-measure-import.component';
import { CostingMethodImportComponent } from './new-import/costing-method-import/costing-method-import.component';
import { CreditCardProcessingImportComponent } from './new-import/credit-card-processing-import/credit-card-processing-import.component';
import { CreateLeaveComponent } from './my-portal/create-leave/create-leave.component';
import { PortalLeaveSummaryComponent } from './my-portal/portal-leave-summary/portal-leave-summary.component';
import { CustomersOnlyImportComponent } from './new-import/customers-only-import/customers-only-import.component';
import { CustomersContactsImportComponent } from './new-import/customers-contacts-import/customers-contacts-import.component';
import { CustomersSiteImportComponent } from './new-import/customers-site-import/customers-site-import.component';
import { VendorsOnlyImportComponent } from './new-import/vendors-only-import/vendors-only-import.component';
import { VendorsContactsImportComponent } from './new-import/vendors-contacts-import/vendors-contacts-import.component';
import { InventoryCountImportComponent } from './new-import/inventory-count-import/inventory-count-import.component';
import { WarehousesOnlyImportComponent } from './new-import/warehouses-only-import/warehouses-only-import.component';
import { WarehousesLocationImportComponent } from './new-import/warehouses-location-import/warehouses-location-import.component';
import { ShipmentsOnlyImportComponent } from './new-import/shipments-only-import/shipments-only-import.component';
import { PartsBrandsImportComponent } from './new-import/parts-brands-import/parts-brands-import.component';
import { PartsClassImportComponent } from './new-import/parts-class-import/parts-class-import.component';
import { PartsFamiliesImportComponent } from './new-import/parts-families-import/parts-families-import.component';
import { PartsGroupsImportComponent } from './new-import/parts-groups-import/parts-groups-import.component';
import { PartsCatalogueImportComponent } from './new-import/parts-catalogue-import/parts-catalogue-import.component';
import { CustomerPartsAliasImportComponent } from './new-import/customer-parts-alias-import/customer-parts-alias-import.component';
import { VendorsPartsAliasImportComponent } from './new-import/vendors-parts-alias-import/vendors-parts-alias-import.component';
import { BalanceSheetHeadingsImportComponent } from './new-import/balance-sheet-headings-import/balance-sheet-headings-import.component';
import { GlAccountsImportComponent } from './new-import/gl-accounts-import/gl-accounts-import.component';
import { PortalCreateTaskComponent } from './my-portal/portal-create-task/portal-create-task.component';
import { FinalizeConfigurationImportComponent } from './new-import/finalize-configuration-import/finalize-configuration-import.component';
import { MainOverviewComponent } from './crm/main-overview/main-overview.component';
import { OverviewHrmMainComponent } from './human-resource/overview-hrm-main/overview-hrm-main.component';
import { MainContractComponent } from './accounting/main-contract/main-contract.component';
import { MainProductionComponent } from './production/main-production/main-production.component';
import { MainOrderComponent } from './order-management/main-order/main-order.component';
import { PortalCommunicationComponent } from './my-portal/portal-communication/portal-communication.component';
import { PartyCommunicationListComponent } from './partyQuickLink/party-communication-list/party-communication-list.component';
import { UploadCustomerImageComponent } from './crm/upload-customer-image/upload-customer-image.component';
import { UploadCrmCustomerImageComponent } from './human-resource/upload-crm-customer-image/upload-crm-customer-image.component';
import { DetailAddressMapComponent } from './partyQuickLink/detail-address-map/detail-address-map.component';
import { DetailLoggedUserComponent } from './partyQuickLink/detail-logged-user/detail-logged-user.component';
import { DetailMainPortalComponent } from './my-portal/detail-main-portal/detail-main-portal.component';
import { DetailPortalPageAdminComponent } from './my-portal/detail-portal-page-admin/detail-portal-page-admin.component';
import { DetailPaymentPortalSummaryComponent } from './my-portal/detail-payment-portal-summary/detail-payment-portal-summary.component';
import { DetailPortalTrainingSummaryComponent } from './my-portal/detail-portal-training-summary/detail-portal-training-summary.component';
import { PortalTaskSummaryComponent } from './my-portal/portal-task-summary/portal-task-summary.component';
import { DetailPortalCommunicationComponent } from './my-portal/detail-portal-communication/detail-portal-communication.component';
import { MainPayableComponent } from './accounting/main-payable/main-payable.component';
import { MainReceivableComponent } from './accounting/main-receivable/main-receivable.component';
import { DetailBillSummaryComponent } from './production/detail-bill-summary/detail-bill-summary.component';
import { DetailPrefernceSummaryComponent } from './my-portal/detail-prefernce-summary/detail-prefernce-summary.component';
import { OgclReportListComponent } from './accounting/ogcl-report-list/ogcl-report-list.component';
import { OgclDetailReportSummaryComponent } from './accounting/ogcl-detail-report-summary/ogcl-detail-report-summary.component';
import { FinancialCheckComponent } from './accounting/financial-check/financial-check.component';
import { FinancialCheckDetailSummaryComponent } from './accounting/financial-check-detail-summary/financial-check-detail-summary.component';
import { DetailWeekListComponent } from './production/detail-week-list/detail-week-list.component';
import { DetailManufacturingRuleComponent } from './production/detail-manufacturing-rule/detail-manufacturing-rule.component';
import { GlCostSummaryComponent } from './accounting/gl-cost-summary/gl-cost-summary.component';
import { GlUpdateCustomTimePeriodComponent } from './accounting/gl-update-custom-time-period/gl-update-custom-time-period.component';
import { GlPaymentMethodSummaryComponent } from './accounting/gl-payment-method-summary/gl-payment-method-summary.component';
import { GlInvoiceListSummaryComponent } from './accounting/gl-invoice-list-summary/gl-invoice-list-summary.component';
import { MyPortalNewMailComponent } from './my-portal/my-portal-new-mail/my-portal-new-mail.component';
import { MyPortalNewInternalNoteComponent } from './my-portal/my-portal-new-internal-note/my-portal-new-internal-note.component';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { HrmEmployeementComponent } from './human-resource/hrm-employeement/hrm-employeement.component';
import { DetailEmployementSummaryComponent } from './human-resource/detail-employement-summary/detail-employement-summary.component';
import { HrmPerformanceReviewComponent } from './human-resource/hrm-performance-review/hrm-performance-review.component';
import { DetailPerformceReviewComponent } from './human-resource/detail-performce-review/detail-performce-review.component';
import { HrmQualificationComponent } from './human-resource/hrm-qualification/hrm-qualification.component';
import { DetailQualificationComponent } from './human-resource/detail-qualification/detail-qualification.component';
import { HrmResumeComponent } from './human-resource/hrm-resume/hrm-resume.component';
import { DetailResumeComponent } from './human-resource/detail-resume/detail-resume.component';
import { ShipmentConfigComponent } from './warehouse/shipment-config/shipment-config.component';
import { DetailShipmentConfigComponent } from './warehouse/detail-shipment-config/detail-shipment-config.component';
import { DetailShipmentConfigTypesComponent } from './warehouse/detail-shipment-config-types/detail-shipment-config-types.component';
import { MainCreatePaycheckComponent } from './accounting/main-create-paycheck/main-create-paycheck.component';
import { MainFindPaycheckComponent } from './accounting/main-find-paycheck/main-find-paycheck.component';
import { MainConfigurationComponent } from './accounting/main-configuration/main-configuration.component';
import { DetailedPaycheckComponent } from './accounting/detailed-paycheck/detailed-paycheck.component';
import { EditPayCheckFormComponent } from './accounting/edit-pay-check-form/edit-pay-check-form.component';
import { ContractorComponent } from './accounting/contractor/contractor.component';
import { EmployeesProfileComponent } from './accounting/employees-profile/employees-profile.component';
import { ProductBacklogComponent } from './scrum/product-backlog/product-backlog.component';
import { AdministrationComponent } from './scrum/administration/administration.component';
import { RevisionComponent } from './scrum/revision/revision.component';
import { SprintsComponent } from './scrum/sprints/sprints.component';
import { TasksComponent } from './scrum/tasks/tasks.component';
import { OpenTestComponent } from './scrum/open-test/open-test.component';
import { MyProfileComponent } from './scrum/my-profile/my-profile.component';
import { MyWorkComponent } from './scrum/my-work/my-work.component';
import { MainReportsComponent } from './accounting/main-reports/main-reports.component';
import { ChartsModule } from 'ng2-charts';
import { SystemInfoNoteComponent } from './my-portal/system-info-note/system-info-note.component';
import { ProductOverviewComponent } from './scrum/product-overview/product-overview.component';
import { DetailSprintProjectComponent } from './scrum/detail-sprint-project/detail-sprint-project.component';
import { ProjectComponent } from './project/project.component';
import { DetailNewSprintComponent } from './scrum/detail-new-sprint/detail-new-sprint.component';
import { MyTasksComponent } from './project/my-tasks/my-tasks.component';
import { MyTimeComponent } from './project/my-time/my-time.component';
import { ProjectsComponent } from './project/projects/projects.component';
import { RequestListComponent } from './project/request-list/request-list.component';
import { ResourcesComponent } from './project/resources/resources.component';
import { SkillTypeComponent } from './project/skill-type/skill-type.component';
import { TimesheetProjectComponent } from './project/timesheet-project/timesheet-project.component';
import { TasksProjectComponent } from './project/tasks-project/tasks-project.component';
import { DetailProjectSummaryComponent } from './project/detail-project-summary/detail-project-summary.component';
import { DetailTasksComponent } from './scrum/tasks/detail-tasks/detail-tasks.component';
import { TasksSprintBacklogComponent } from './scrum/tasks-sprint-backlog/tasks-sprint-backlog.component';
import { DetailMyTaskComponent } from './project/detail-my-task/detail-my-task.component';

import { RequestListEffortComponent } from './work-effort/request-list-effort/request-list-effort.component';
import { WorkEffortComponent } from './work-effort/work-effort.component';
import { TaskListComponent } from './work-effort/task-list/task-list.component';
import { CalendarComponent } from './work-effort/calendar/calendar.component';
import { SubmittedJobsComponent } from './work-effort/submitted-jobs/submitted-jobs.component';

import { MyTimeEffortComponent } from './work-effort/my-time-effort/my-time-effort.component';
import { IcalendarComponent } from './work-effort/icalendar/icalendar.component';
import { TimesheetEffortComponent } from './work-effort/timesheet-effort/timesheet-effort.component';
import { WorkEffortEffortComponent } from './work-effort/work-effort-effort/work-effort-effort.component';
import { DetailTaskProjectComponent } from './project/detail-task-project/detail-task-project.component';
import { AddWorkEffortComponent } from './work-effort/add-work-effort/add-work-effort.component';
import { TaskListViewComponent } from './work-effort/task-list-view/task-list-view.component';
import { WorkEffortSummaryComponent } from './work-effort/task-list-view/work-effort-summary/work-effort-summary.component';
import { EditWorkEffortComponent } from './work-effort/task-list-view/edit-work-effort/edit-work-effort.component';
import { ChildWorkEffortComponent } from './work-effort/task-list-view/child-work-effort/child-work-effort.component';
import { WorkEffortPartyAssignsComponent } from './work-effort/task-list-view/work-effort-party-assigns/work-effort-party-assigns.component';
import { WorkEffortRatesComponent } from './work-effort/task-list-view/work-effort-rates/work-effort-rates.component';
import { WorkEffortCommEventsComponent } from './work-effort/task-list-view/work-effort-comm-events/work-effort-comm-events.component';
import { WorkEffortShopListsComponent } from './work-effort/task-list-view/work-effort-shop-lists/work-effort-shop-lists.component';
import { WorkEffortRequestsComponent } from './work-effort/task-list-view/work-effort-requests/work-effort-requests.component';
import { WorkEffortRequirementsComponent } from './work-effort/task-list-view/work-effort-requirements/work-effort-requirements.component';
import { WorkEffortQuotesComponent } from './work-effort/task-list-view/work-effort-quotes/work-effort-quotes.component';
import { WorkEffortOrderHeadersComponent } from './work-effort/task-list-view/work-effort-order-headers/work-effort-order-headers.component';
import { WorkEffortTimeEntriesComponent } from './work-effort/task-list-view/work-effort-time-entries/work-effort-time-entries.component';
import { WorkEffortNotesComponent } from './work-effort/task-list-view/work-effort-notes/work-effort-notes.component';
import { WorkEffortContentsComponent } from './work-effort/task-list-view/work-effort-contents/work-effort-contents.component';
import { WorkEffortProductsComponent } from './work-effort/task-list-view/work-effort-products/work-effort-products.component';
import { WorkEffortReviewsComponent } from './work-effort/task-list-view/work-effort-reviews/work-effort-reviews.component';
import { WorkEffortKeywordsComponent } from './work-effort/task-list-view/work-effort-keywords/work-effort-keywords.component';
import { WorkEffortContactMechsComponent } from './work-effort/task-list-view/work-effort-contact-mechs/work-effort-contact-mechs.component';
import { WorkEffortAgreementApplicsComponent } from './work-effort/task-list-view/work-effort-agreement-applics/work-effort-agreement-applics.component';
import { WorkEffortFixedAssetsComponent } from './work-effort/task-list-view/work-effort-fixed-assets/work-effort-fixed-assets.component';
import { WorkEffortAttributesComponent } from './work-effort/task-list-view/work-effort-attributes/work-effort-attributes.component';
import { WorkEffortEventRemindersComponent } from './work-effort/task-list-view/work-effort-event-reminders/work-effort-event-reminders.component';
import { DetailNewTaskComponent } from './project/detail-new-task/detail-new-task.component';
import { DetailNewRequestComponent } from './project/detail-new-request/detail-new-request.component';
import { UnplannedSprintComponent } from './scrum/unplanned-sprint/unplanned-sprint.component';
import { TimesheetViewComponent } from './work-effort/timesheet-view/timesheet-view.component';
import { TimesheetTimesheetComponent } from './work-effort/timesheet-view/timesheet-timesheet/timesheet-timesheet.component';
import { EntriesTimesheetComponent } from './work-effort/timesheet-view/entries-timesheet/entries-timesheet.component';
import { PartiesTimesheetComponent } from './work-effort/timesheet-view/parties-timesheet/parties-timesheet.component';
import { IcalendarViewComponent } from './work-effort/icalendar-view/icalendar-view.component';
import { EditIcalendarComponent } from './work-effort/icalendar-view/edit-icalendar/edit-icalendar.component';
import { ChildIcalendarComponent } from './work-effort/icalendar-view/child-icalendar/child-icalendar.component';
import { AddIcalendarPartyComponent } from './work-effort/icalendar-view/add-icalendar-party/add-icalendar-party.component';
import { AddIcalendarFixedAssetComponent } from './work-effort/icalendar-view/add-icalendar-fixed-asset/add-icalendar-fixed-asset.component';
import { AddIcalendarDataComponent } from './work-effort/icalendar-view/add-icalendar-data/add-icalendar-data.component';
import { HelpIcalendarComponent } from './work-effort/icalendar-view/help-icalendar/help-icalendar.component';
import { ListAllProfileComponent } from './scrum/list-all-profile/list-all-profile.component';
import { EditProfileComponent } from './scrum/edit-profile/edit-profile.component';
import { UpdateContactComponent } from './scrum/update-contact/update-contact.component';
import { EditPartyComponent } from './scrum/edit-party/edit-party.component';
import { OverrideEditComponent } from './scrum/override-edit/override-edit.component';
import { VisitsMyProfileComponent } from './scrum/visits-my-profile/visits-my-profile.component';
import { PartyContentMyProfileComponent } from './scrum/party-content-my-profile/party-content-my-profile.component';
import { SecurityMyProfileComponent } from './scrum/security-my-profile/security-my-profile.component';
import { UpdateIdentificationNumberComponent } from './scrum/update-identification-number/update-identification-number.component';
import { NewIdentificationNumberComponent } from './scrum/new-identification-number/new-identification-number.component';
import { PreferencesMyProfileComponent } from './scrum/preferences-my-profile/preferences-my-profile.component';
import { RolesComponent } from './scrum/roles/roles.component';
import { ProjectMyProfileComponent } from './scrum/project-my-profile/project-my-profile.component';
import { TasksMyProfileComponent } from './scrum/tasks-my-profile/tasks-my-profile.component';
import { RatesMyProfileComponent } from './scrum/rates-my-profile/rates-my-profile.component';
import { RevisionsMyProfileComponent } from './scrum/revisions-my-profile/revisions-my-profile.component';
import { MarketingComponent } from './marketing/marketing.component';
import { MainMarketingComponent } from './marketing/main-marketing/main-marketing.component';
import { DataSourceMarketingComponent } from './marketing/data-source-marketing/data-source-marketing.component';
import { MarketingCampaignComponent } from './marketing/marketing-campaign/marketing-campaign.component';
import { TrackingMarketingComponent } from './marketing/tracking-marketing/tracking-marketing.component';
import { SegmentMarketingComponent } from './marketing/segment-marketing/segment-marketing.component';
import { ContactListMarketingComponent } from './marketing/contact-list-marketing/contact-list-marketing.component';
import { ReportsMarketingComponent } from './marketing/reports-marketing/reports-marketing.component';
import { EcommerceSiteComponent } from './marketing/ecommerce-site/ecommerce-site.component';
import { EditDataSourceTypeComponent } from './marketing/edit-data-source-type/edit-data-source-type.component';
import { MarketingCampaignIdComponent } from './marketing/marketing-campaign-id/marketing-campaign-id.component';
import { TrackingIdComponent } from './marketing/tracking-id/tracking-id.component';
import { TrackingIdAccessComponent } from './marketing/tracking-id-access/tracking-id-access.component';
import { SegmentIdComponent } from './marketing/segment-id/segment-id.component';
import { DetailOverviewPageAdminComponent } from './scrum/detail-overview-page-admin/detail-overview-page-admin.component';
import { UpdatePostalAddressProfileComponent } from './scrum/update-postal-address-profile/update-postal-address-profile.component';
import { UpdateContactProfileComponent } from './scrum/update-contact-profile/update-contact-profile.component';
import { PartnersComponent } from './crm/partners/partners.component';
import { CasesComponent } from './crm/cases/cases.component';
import { TeamComponent } from './crm/team/team.component';
import { TeamDetailComponent } from './crm/team/team-detail/team-detail.component';
import { PartnersDetailComponent } from './crm/partners/partners-detail/partners-detail.component';
import { CasesDetailComponent } from './crm/cases/cases-detail/cases-detail.component';
import { ForecastComponent } from './crm/forecast/forecast.component';
import { ForecastDetailComponent } from './crm/forecast/forecast-detail/forecast-detail.component';
import { PreferencesComponent } from './crm/preferences/preferences.component';
import { PreferencesDetailComponent } from './crm/preferences/preferences-detail/preferences-detail.component';
import { TasksCrmComponent } from './crm/tasks-crm/tasks-crm.component';
import { PlanningComponent } from './warehouse/planning/planning.component';
import { DistributionComponent } from './warehouse/distribution/distribution.component';
import { SuppliersVendorsComponent } from './accounting/suppliers-vendors/suppliers-vendors.component';
import { DetailSupplierVendorsComponent } from './accounting/suppliers-vendors/detail-supplier-vendors/detail-supplier-vendors.component';
import { DetailPlanningComponent } from './warehouse/planning/detail-planning/detail-planning.component';
import { DetailDistributionComponent } from './warehouse/distribution/detail-distribution/detail-distribution.component';
import { DetailTimesheetComponent } from './project/detail-timesheet/detail-timesheet.component';
import { CreateTaskFromRequestComponent } from './project/create-task-from-request/create-task-from-request.component';
import { ViewContactComponent } from './marketing/contact-list-marketing/view-contact/view-contact.component';
import { MainAssetComponent } from './assetMaint/main-asset/main-asset.component';
import { AssestTaskListComponent } from './assetMaint/assest-task-list/assest-task-list.component';
import { AssetFixedAssetComponent } from './assetMaint/asset-fixed-asset/asset-fixed-asset.component';
import { AssetMaintenanceComponent } from './assetMaint/asset-maintenance/asset-maintenance.component';
import { AssetFacilityComponent } from './assetMaint/asset-facility/asset-facility.component';
import { CreateQuoteFromRequestListComponent } from './project/create-quote-from-request-list/create-quote-from-request-list.component';
import { EditFixedAssetComponent } from './assetMaint/edit-fixed-asset/edit-fixed-asset.component';
import { EditFixedAssetMaintComponent } from './assetMaint/edit-fixed-asset-maint/edit-fixed-asset-maint.component';
import { EditFacilityComponent } from './assetMaint/edit-facility/edit-facility.component';
import { EditTrackingCodeComponent } from './marketing/tracking-marketing/edit-tracking-code/edit-tracking-code.component';
import { ViewDataSourceComponent } from './marketing/view-data-source/view-data-source.component';
import { ViewDataSourceTypeComponent } from './marketing/view-data-source-type/view-data-source-type.component';
import { EditTrackingCodeTypeComponent } from './marketing/tracking-marketing/edit-tracking-code-type/edit-tracking-code-type.component';
import { PromosCatalogComponent } from './catalog-mgmt/promos-catalog/promos-catalog.component';
import { PriceRulesCatalogComponent } from './catalog-mgmt/price-rules-catalog/price-rules-catalog.component';
import { StoresCatalogComponent } from './catalog-mgmt/stores-catalog/stores-catalog.component';
import { ProductStoreGroupsCatalogComponent } from './catalog-mgmt/product-store-groups-catalog/product-store-groups-catalog.component';
import { ReviewsCatalogComponent } from './catalog-mgmt/reviews-catalog/reviews-catalog.component';
import { SubscriptionsCatalogComponent } from './catalog-mgmt/subscriptions-catalog/subscriptions-catalog.component';
import { ImageManagementCatalogComponent } from './catalog-mgmt/image-management-catalog/image-management-catalog.component';
import { NewProductStoreComponent } from './catalog-mgmt/stores-catalog/new-product-store/new-product-store.component';
import { NgxDailyGanttChartModule } from 'ngx-daily-gantt-chart';
import { SubscriptionResourceCatalogComponent } from './catalog-mgmt/subscriptions-catalog/subscription-resource-catalog/subscription-resource-catalog.component';
import { TaskListSummaryComponent } from './assetMaint/task-list-summary/task-list-summary.component';
import { EditProductPromoComponent } from './catalog-mgmt/promos-catalog/edit-product-promo/edit-product-promo.component';
import { EditProductPriceRulesComponent } from './catalog-mgmt/price-rules-catalog/edit-product-price-rules/edit-product-price-rules.component';
import { EditProductStoreComponent } from './catalog-mgmt/stores-catalog/edit-product-store/edit-product-store.component';
import { EditProductStoreGroupComponent } from './catalog-mgmt/product-store-groups-catalog/edit-product-store-group/edit-product-store-group.component';
import { EditProductSubscriptionsComponent } from './catalog-mgmt/subscriptions-catalog/edit-product-subscriptions/edit-product-subscriptions.component';
import { EditProductProductsComponent } from './catalog-mgmt/products/edit-product-products/edit-product-products.component';
import { EditReviewsCatalogComponent } from './catalog-mgmt/reviews-catalog/edit-reviews-catalog/edit-reviews-catalog.component';
import { RequestsOrderComponent } from './order-management/requests-order/requests-order.component';
import { QuotesOrderComponent } from './order-management/quotes-order/quotes-order.component';
import { OrderListComponent } from './order-management/order-list/order-list.component';
import { DetailQuotesOrderComponent } from './order-management/detail-quotes-order/detail-quotes-order.component';
import { DetailRequestsOrderComponent } from './order-management/detail-requests-order/detail-requests-order.component';
import { StatsComponent } from './order-management/stats/stats.component';
import { ReportsOrderComponent } from './order-management/reports-order/reports-order.component';
import { RequirementsComponent } from './order-management/requirements/requirements.component';
import { MainDetailComponent } from './webpos/main-detail/main-detail.component';
import { DetailRequirementsComponent } from './order-management/detail-requirements/detail-requirements.component';
import { PreferencesAllComponent } from './crm/preferences/preferences-all/preferences-all.component';
import { PreferencesCommComponent } from './crm/preferences/preferences-comm/preferences-comm.component';
import { PreferencesInternalNoteComponent } from './crm/preferences/preferences-internal-note/preferences-internal-note.component';
import { PreferencesEmailComponent } from './crm/preferences/preferences-email/preferences-email.component';
import { ShipmentTimeEstimateComponent } from './catalog-mgmt/shipping/shipment-time-estimate/shipment-time-estimate.component';
import { DetailShipmentTimeEstimateComponent } from './catalog-mgmt/shipping/detail-shipment-time-estimate/detail-shipment-time-estimate.component';
import { ManagerDetailComponent } from './webpos/manager-detail/manager-detail.component';
import { DetailSubscriptionResourceComponent } from './catalog-mgmt/subscriptions-catalog/detail-subscription-resource/detail-subscription-resource.component';
import { FacilityMainComponent } from './facility-main/facility-main.component';
import { CreateProductPromotionCodeComponent } from './catalog-mgmt/promos-catalog/create-product-promotion-code/create-product-promotion-code.component';
import { FiledserviceComponent } from './fsm/filedservice/filedservice.component';
import { NewworkorderComponent } from './fsm/newworkorder/newworkorder.component';
import { CustomerComponent } from './customer/customer.component';
import { FsmreportsComponent } from './fsm/fsmreports/fsmreports.component';
import { FsminvoicesComponent } from './fsm/fsminvoices/fsminvoices.component';
import { FsmpeopleComponent } from './fsm/fsmpeople/fsmpeople.component';
import { FsmTimeSheetComponent } from './fsm-time-sheet/fsm-time-sheet.component';
import { DetailOpenOrderItemReportComponent } from './order-management/detail-open-order-item-report/detail-open-order-item-report.component';
import { AllocationPlanComponent } from './order-management/allocation-plan/allocation-plan.component';
import { DetailAllocationPlanComponent } from './order-management/detail-allocation-plan/detail-allocation-plan.component';
import { FsmSettingComponent } from './fsm/fsm-setting/fsm-setting.component';
import { WorkOrdersComponent } from './work-orders/work-orders.component';
import { WorkOrdersPrioritiesComponent } from './fsm/work-orders/work-orders-priorities/work-orders-priorities.component';
import { WorkOrdersSkillsComponent } from './work-orders-skills/work-orders-skills.component';
import { CancelReasonComponent } from './fsm/cancel-reason/cancel-reason.component';
import { OnholdReasonsComponent } from './fsm/onhold-reasons/onhold-reasons.component';
import { FlagReasonsComponent } from './fsm/flag-reasons/flag-reasons.component';
import { RepairCodesComponent } from './fsm/repair-codes/repair-codes.component';
import { WorkOrderTypesComponent } from './fsm/work-order-types/work-order-types.component';
import { AlertSettingsComponent } from './fsm/alert-settings/alert-settings.component';
import { PrintTemplatesComponent } from './fsm/print-templates/print-templates.component';
import { DispatchBoardSettingsComponent } from './fsm/dispatch-board-settings/dispatch-board-settings.component';
import { WorkOrderWorkFlowComponent } from './fsm/work-order-work-flow/work-order-work-flow.component';
import { WorkOrderBackDateComponent } from './fsm/work-order-back-date/work-order-back-date.component';
import { WorkZoneComponent } from './fsm/work-zone/work-zone.component';
import { ModelYourBusinessComponent } from './fsm/model-your-business/model-your-business.component';
import { FsmCompanyComponent } from './fsm/fsm-company/fsm-company.component';
import { MobileSettingsComponent } from './fsm/mobile-settings/mobile-settings.component';
import { FsmFinancialComponent } from './fsm/fsm-financial/fsm-financial.component';
import { FsmCustomersComponent } from './fsm/fsm-customers/fsm-customers.component';
import { TimeSheetComponent } from './fsm/time-sheet/time-sheet.component';
import { ServiceAgreementsComponent } from './fsm/service-agreements/service-agreements.component';
import { WorkOrderNetworkComponent } from './work-order-network/work-order-network.component';
import { WorkZonesComponent } from './fsm/work-zones/work-zones.component';
import { ServiceTeamsComponent } from './fsm/service-teams/service-teams.component';
import { DispatchCentersComponent } from './fsm/dispatch-centers/dispatch-centers.component';
import { ServicePackagesComponent } from './service-packages/service-packages.component';
import { EquipmentTypesComponent } from './fsm/equipment-types/equipment-types.component';
import { AttributesComponent } from './fsm/attributes/attributes.component';
import { CustomFieldsComponent } from './fsm/custom-fields/custom-fields.component';
import { TerminologyComponent } from './fsm/terminology/terminology.component';
import { SubscriptionDetailsComponent } from './subscription-details/subscription-details.component';
import { UploadLogoComponent } from './fsm/upload-logo/upload-logo.component';
import { DocumentTypesComponent } from './fsm/document-types/document-types.component';
import { FsmAppearanceComponent } from './fsm/fsm-appearance/fsm-appearance.component';
import { FsmLandmarksComponent } from './fsm/fsm-landmarks/fsm-landmarks.component';
import { FsmIntegrationComponent } from './fsm/fsm-integration/fsm-integration.component';
import { ImageManagementMainDetailComponent } from './catalog-mgmt/image-management-catalog/image-management-main-detail/image-management-main-detail.component';
import { ImageManagementManageDetailComponent } from './catalog-mgmt/image-management-catalog/image-management-manage-detail/image-management-manage-detail.component';
import { ClassSettingsComponent } from './fsm/class-settings/class-settings.component';
import { MyWorkSettingComponent } from './fsm/mobile-setting/my-work-setting/my-work-setting.component';
import { PrimaryWorkOrderComponent } from './fsm/mobile-setting/primary-work-order/primary-work-order.component';
import { NonPrimaryWorkOrderComponent } from './fsm/mobile-setting/non-primary-work-order/non-primary-work-order.component';
import { DeviceSettingComponent } from './fsm/mobile-setting/device-setting/device-setting.component';
import { ImageManagementRecentlyApprovedDetailComponent } from './catalog-mgmt/image-management-catalog/image-management-recently-approved-detail/image-management-recently-approved-detail.component';
import { ImageManagementReplaceDetailComponent } from './catalog-mgmt/image-management-catalog/image-management-replace-detail/image-management-replace-detail.component';
import { BasicInvoiceSettingComponent } from './fsm/Financial/basic-invoice-setting/basic-invoice-setting.component';
import { PostingInvoicesComponent } from './fsm/Financial/posting-invoices/posting-invoices.component';
import { SalesTaxItemsComponent } from './fsm/Financial/sales-tax-items/sales-tax-items.component';
import { SalesTaxGroupsComponent } from './fsm/Financial/sales-tax-groups/sales-tax-groups.component';
import { SalesTaxCodesComponent } from './fsm/Financial/sales-tax-codes/sales-tax-codes.component';
import { InvoiceStatusComponent } from './fsm/Financial/invoice-status/invoice-status.component';
import { PriceListComponent } from './fsm/Financial/price-list/price-list.component';
import { PriceLitGroupsComponent } from './fsm/Financial/price-lit-groups/price-lit-groups.component';
import { PaymentProcessingComponent } from './fsm/Financial/payment-processing/payment-processing.component';
import { QbmsIntegrationComponent } from './fsm/Financial/qbms-integration/qbms-integration.component';
import { PrintsTemplatesComponent } from './fsm/Financial/prints-templates/prints-templates.component';
import { FsmNotificationsComponent } from './fsm/Customers/fsm-notifications/fsm-notifications.component';
import { DetailConfigurationsComponent } from './catalog-mgmt/configurations/detail-configurations/detail-configurations.component';
import { TimeSheetPeriodComponent } from './fsm/Time-Sheet/time-sheet-period/time-sheet-period.component';
import { WorkFlowSettingsComponent } from './fsm/Time-Sheet/work-flow-settings/work-flow-settings.component';
import { OverTimeRulesComponent } from './fsm/Time-Sheet/over-time-rules/over-time-rules.component';
import { RoundingRulesComponent } from './fsm/Time-Sheet/rounding-rules/rounding-rules.component';
import { ListViewConfigurationComponent } from './fsm/Time-Sheet/list-view-configuration/list-view-configuration.component';
import { ReconcileFlagRulesComponent } from './fsm/Time-Sheet/reconcile-flag-rules/reconcile-flag-rules.component';
import { PmSettingsComponent } from './fsm/Service Aggrements/pm-settings/pm-settings.component';
import { SeasonSettingsComponent } from './fsm/Service Aggrements/season-settings/season-settings.component';
import { SettingWorkOrderNetworkComponent } from './fsm/Work Order Network/setting-work-order-network/setting-work-order-network.component';
import { WonBusinessListingComponent } from './fsm/Work Order Network/won-business-listing/won-business-listing.component';
import { CreateSimpleTextComponent } from './catalog-mgmt/configurations/create-simple-text/create-simple-text.component';
import { DetailCatalogComponent } from './catalog-mgmt/catalogs/detail-catalog/detail-catalog.component';
import { RepairCategoryComponent } from './fsm/work-orders/repair-category/repair-category.component';
import { EditWorkZoneComponent } from './fsm/edit-work-zone/edit-work-zone.component';
import { DefaultServiceTeamComponent } from './fsm/default-service-team/default-service-team.component';
import { MyServicePackageComponent } from './fsm/my-service-package/my-service-package.component';
import { EquipmentServiceComponent } from './fsm/equipment-service/equipment-service.component';
import { NewCustomerComponent } from './fsm/new-customer/new-customer.component';
import { WebsiteListComponent } from './Content/WebSite/website-list/website-list.component';
import { WebsiteMainComponent } from './Content/Main/website-main/website-main.component';
import { SurveyComponent } from './Content/Survey/survey/survey.component';
import { PartsComponent } from './fsm/parts/parts.component';
import { PriceListItemComponent } from './fsm/price-list-item/price-list-item.component';
import { ForumComponent } from './Content/Forum/forum-groups/forum.component';
import { BlogComponent } from './Content/Blog/blog/blog.component';
import { ContentComponent } from './Content/Content/content/content.component';
import { DataResourceComponent } from './Content/DataResource/data-resource/data-resource.component';
import { ContentSetupComponent } from './Content/Content Setup/content-setup/content-setup.component';
import { DataresourceSetupComponent } from './Content/DataResource Setup/dataresource-setup/dataresource-setup.component';
import { TemplateComponent } from './Content/Template/template/template.component';
import { ContentCmsComponent } from './Content/CMS/content-cms/content-cms.component';
import { CompDocComponent } from './Content/CompDoc/comp-doc/comp-doc.component';
import { FsmTechnicianComponent } from './fsm/fsm-technician/fsm-technician.component';
import { FsmCustomerComponent } from './fsm/fsm-customer/fsm-customer.component'
import { ContentDetailPageComponent } from './Content/Content/content-detail-page/content-detail-page.component';
import { FsmTechInvoiveComponent } from './fsm/fsm-tech-invoive/fsm-tech-invoive.component'
import { MainDetailPageComponent } from './Content/Main/main-detail-page/main-detail-page.component';
import { SurveyDetailPageComponent } from './Content/Survey/survey-detail-page/survey-detail-page.component';
import { ForumDetailPageComponent } from './Content/Forum/forum-detail-page/forum-detail-page.component';
import { DataresourceDetailPageComponent } from './Content/DataResource/dataresource-detail-page/dataresource-detail-page.component';
import { DataresourceFindComponent } from './Content/DataResource/dataresource-find/dataresource-find.component';
import { TechnicianResetComponent } from './fsm/technician-reset/technician-reset.component';
import { CustomerResetComponent } from './fsm/customer-reset/customer-reset.component'
import { ProblemDetailComponent } from './fsm/problem-detail/problem-detail.component';
import { CustomerDetailComponent } from './fsm/customer-detail/customer-detail.component';
import { EditContentPurposeOperationComponent } from './Content/Content Setup/edit-content-purpose-operation/edit-content-purpose-operation.component';
import { EditContentOperationComponent } from './Content/Content Setup/edit-content-operation/edit-content-operation.component';
import { EditContentAssociationPredicateComponent } from './Content/Content Setup/edit-content-association-predicate/edit-content-association-predicate.component';
import { EditContentTypeAttributeComponent } from './Content/Content Setup/edit-content-type-attribute/edit-content-type-attribute.component';
import { EditContentPurposeTypeComponent } from './Content/Content Setup/edit-content-purpose-type/edit-content-purpose-type.component';
import { EditContentAssociationTypeComponent } from './Content/Content Setup/edit-content-association-type/edit-content-association-type.component';
import { ContentPermissionComponent } from './Content/Content Setup/content-permission/content-permission.component';
import { CustomerSummaryPageComponent } from './fsm/customer-summary-page/customer-summary-page.component';
import { CharacterSetComponent } from './Content/DataResource Setup/character-set/character-set.component';
import { CategoryComponent } from './Content/DataResource Setup/category/category.component';
import { TypeAttributeComponent } from './Content/DataResource Setup/type-attribute/type-attribute.component';
import { FileExtComponent } from './Content/DataResource Setup/file-ext/file-ext.component';
import { MetaDataPredicateComponent } from './Content/DataResource Setup/meta-data-predicate/meta-data-predicate.component';
import { MimeTypeComponent } from './Content/DataResource Setup/mime-type/mime-type.component';
import { MimeTypeTemplateComponent } from './Content/DataResource Setup/mime-type-template/mime-type-template.component';
import { FindResponseComponent } from './Content/Survey/find-response/find-response.component';
import { ResposeSummaryComponent } from './Content/Survey/respose-summary/respose-summary.component';
import { ListCreatedTemplateComponent } from './Content/Template/list-created-template/list-created-template.component';
import { BlogDetailPageComponent } from './Content/Blog/blog-detail-page/blog-detail-page.component';
import { RolesPageComponent } from './Content/Content/roles-page/roles-page.component';
import { BlogsArticlesDetailPageComponent } from './Content/Blog/blogs-articles-detail-page/blogs-articles-detail-page.component';
import { SubSitesComponent } from './Content/CMS/sub-sites/sub-sites.component';
import { IndexComponent } from './Content/CMS/index/index.component';
import { FindComponent } from './Content/CMS/find/find.component';
import { SearchProductsComponent } from './Content/CMS/search-products/search-products.component';
import { CompdocDetailPageComponent } from './Content/CompDoc/compdoc-detail-page/compdoc-detail-page.component';
import { EditCompdocPageComponent } from './Content/CompDoc/edit-compdoc-page/edit-compdoc-page.component';
import { ApprovalsPageComponent } from './Content/CompDoc/approvals-page/approvals-page.component';
import { RevisionPageComponent } from './Content/CompDoc/revision-page/revision-page.component';
import { NewRootPageComponent } from './Content/CompDoc/new-root-page/new-root-page.component';
import { ViewWaitingApprovalsComponent } from './Content/CompDoc/view-waiting-approvals/view-waiting-approvals.component';
import { WebposLoginPageComponent } from './webpos/webpos-login-page/webpos-login-page.component';
import { MessageDetailPageComponent } from './Content/Forum/message-detail-page/message-detail-page.component';
import { HardwareMainComponent } from './IS Mgr/Hardware/hardware-main/hardware-main.component';
import { HardwareDetailPageComponent } from './IS Mgr/Hardware/hardware-detail-page/hardware-detail-page.component';
import { SoftwareMainComponent } from './IS Mgr/Software/software-main/software-main.component';
import { SoftwareDetailPageComponent } from './IS Mgr/Software/software-detail-page/software-detail-page.component';
import { BirtMainComponent } from './BIRT/Main/birt-main/birt-main.component';
import { SendFormatThroughMailComponent } from './BIRT/Main/send-format-through-mail/send-format-through-mail.component';
import { FlexibleMainComponent } from './BIRT/Flexible_Reports/flexible-main/flexible-main.component';
import { ForumsPageComponent } from './Content/Forum/forums/forums-page/forums-page.component'
import { ForumPurposesComponent } from './Content/Forum/forum-purposes/forum-purposes.component';
import { ForumRolesComponent } from './Content/Forum/forum-roles/forum-roles.component';
import { EditComponent } from './Content/Blog/edit/edit.component';
import { ListOfBlogsComponent } from './Content/Blog/list-of-blogs/list-of-blogs.component';
import { FindDetailComponent } from './Content/Content/find-detail/find-detail.component';

//import { CKEditorModule } from 'ng2-ckeditor';
import { CKEditorModule } from 'ckeditor4-angular';
import { CmsSummaryComponent } from './Content/CMS/cms-summary/cms-summary.component';
import { StaffingComponent } from './fsm/staffing/staffing.component';
import { MainBiComponent } from './Business-Intelligence/main-bi/main-bi.component';
import { ReportBuilderComponent } from './Business-Intelligence/report-builder/report-builder.component';
import { UpdateReportComponent } from './BIRT/Flexible_Reports/update-report/update-report.component';
import { DetailReportBuilderComponent } from './Business-Intelligence/detail-report-builder/detail-report-builder.component';
import { ConfigureWarehouseMainComponent } from './warehouse/planning/Configure_Warehouse/configure-warehouse-main/configure-warehouse-main.component';
import { FindRequirementsMainComponent } from './warehouse/planning/Find_Requirements/find-requirements-main/find-requirements-main.component';
import { InternalRequirementsMainComponent } from './warehouse/planning/Internal_Requirements/internal-requirements-main/internal-requirements-main.component';
import { ProductRequirementsMainComponent } from './warehouse/planning/Product_Requirements/product-requirements-main/product-requirements-main.component';
import { RunMrpMainComponent } from './warehouse/planning/Run_MRP/run-mrp-main/run-mrp-main.component';
import { SalesOrderHistoryMainComponent } from './warehouse/planning/Sales_Order_History/sales-order-history-main/sales-order-history-main.component';
import { TransferRequirementsMainComponent } from './warehouse/planning/Transfer_Requirements/transfer-requirements-main/transfer-requirements-main.component';
import { ViewMrpMainComponent } from './warehouse/planning/View_MRP/view-mrp-main/view-mrp-main.component';
import { RequirementsMainComponent } from './warehouse/planning/Open_Requirements/requirements-main/requirements-main.component';
import { FindRequirementsDetailComponent } from './warehouse/planning/Find_Requirements/find-requirements-detail/find-requirements-detail.component';
import { OpenRequirementsDetailComponent } from './warehouse/planning/Open_Requirements/open-requirements-detail/open-requirements-detail.component';
import { ReviewOrderComponent } from './warehouse/planning/Product_Requirements/review-order/review-order.component';
import { PurchaseOrderQuickEntryComponent } from './warehouse/planning/Product_Requirements/purchase-order-quick-entry/purchase-order-quick-entry.component';
import { PurchaseOrderComponent } from './warehouse/planning/Product_Requirements/purchase-order/purchase-order.component';
import { ConfigureWarehouseDetailComponent } from './warehouse/planning/Configure_Warehouse/configure-warehouse-detail/configure-warehouse-detail.component';
import { ReportMainComponent } from './Reports/report-main/report-main.component';
import { ConfigureWarehouseExistingDetailComponent } from './warehouse/planning/Configure_Warehouse/configure-warehouse-existing-detail/configure-warehouse-existing-detail.component';
import { NewEmployeeContractsComponent } from './accounting/New_Employee_Contract/new-employee-contracts/new-employee-contracts.component';
import { TrackDistributorComponent } from './warehouse/distribution/track-distributor/track-distributor.component';
import { SalesOrderDistributorComponent } from './warehouse/distribution/sales-order-distributor/sales-order-distributor.component';
import { EmployeeContractsComComponent } from './accounting/accounting-ar/Employees_Contracts/employee-contracts-com/employee-contracts-com.component';
import { SearchByLabelsComponent } from './warehouse/search-by-labels/search-by-labels.component';
import { DetailInventoryItemsComponent } from './warehouse/detail-inventory-items/detail-inventory-items.component';
import { MyPortalCreatePaymentsComponent } from './my-portal/my-portal-create-payments/my-portal-create-payments.component';
import { MyPortalCreateInvoiceComponent } from './my-portal/my-portal-create-invoice/my-portal-create-invoice.component';
import { MyPortalNewInvoiceComponent } from './my-portal/my-portal-new-invoice/my-portal-new-invoice.component';
import { OrganizationGlSettingNewComponent } from './accounting/organization-gl-setting-new/organization-gl-setting-new.component';
import { FindUserLoginComponent } from './partyQuickLink/find-user-login/find-user-login/find-user-login.component';
import { FindSecurityGroupComponent } from './partyQuickLink/find-security-group/find-security-group/find-security-group.component';
import { CertIssuersComponent } from './partyQuickLink/cert-issuers/cert-issuers/cert-issuers.component';
import { CommunicationEmailOverviewComponent } from './Communication/communication-email-overview/communication-email-overview.component';
import { FindCommunicationComponent } from './Communication/find-communication/find-communication.component';
import { MyCasesComponent } from './my-portal/my-cases/my-cases.component';
import { HubDashboardComponent } from './hub-dashboard/hub-dashboard.component';
import { FinancialsDashboardComponent } from './financials-dashboard/financials-dashboard.component';
import { VendorDashboardComponent } from './vendor-dashboard/vendor-dashboard.component';
import { OrdersDashboardComponent } from './orders-dashboard/orders-dashboard.component';
import { SupplyDashboardComponent } from './DashboardComp/supply-dashboard/supply-dashboard.component';
import { PSADashboardComponent } from './DashboardComp/psa-dashboard/psa-dashboard.component';
import { CommunicationDashboardComponent } from './DashboardComp/communication-dashboard/communication-dashboard.component';
import { HRMSDashboardComponent } from './DashboardComp/hrms-dashboard/hrms-dashboard.component';
import { POSDashboardComponent } from './DashboardComp/pos-dashboard/pos-dashboard.component';
import { ECommerceDashboardComponent } from './DashboardComp/e-commerce-dashboard/e-commerce-dashboard.component';
import { ReportsDashboardComponent } from './DashboardComp/reports-dashboard/reports-dashboard.component';
import { SettingDashboardComponent } from './DashboardComp/setting-dashboard/setting-dashboard.component';
import { MyProtalDashboardComponent } from './DashboardComp/my-protal-dashboard/my-protal-dashboard.component';
import { CrmNewDocumentComponent } from './crm/crm-new-document/crm-new-document.component';
import { CustomerServiceComponent } from './crm/customer-service/customer-service.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FSMDashboardComponent } from './fsm-dashboard/fsm-dashboard.component';
import { TimesheetSummaryComponent } from './timesheet-summary/timesheet-summary.component';
import { WarehouseDashboardOverviewComponent } from './warehouse-dashboard-overview/warehouse-dashboard-overview.component';
import { InactiveCustomerComponent } from './crm/inactive-customer/inactive-customer.component';
import { PartyDashboardComponent } from './DashboardComp/party-dashboard/party-dashboard.component';
import { WarehouseShipmentConfigComponent } from './warehouse/warehouse-shipment-config/warehouse-shipment-config.component';
import { InactiveCustomerSummaryComponent } from './inactive-customer-summary/inactive-customer-summary.component';
import { DashboardSecurityComponent } from './DashboardComp/dashboard-security/dashboard-security.component';
import { DashboardIsmgrComponent } from './DashboardComp/dashboard-ismgr/dashboard-ismgr.component';
import { CrmDashboardPageComponent } from './DashboardComp/crm-dashboard-page/crm-dashboard-page.component';
// import { IsMgrDashboardComponent } from './DashboardComp/is-mgr-dashboard/is-mgr-dashboard.component';
import { PIMOverviewComponent } from './pim-overview/pim-overview.component';
import { EdiOverviewComponent } from './edi/edi-overview/edi-overview.component';
import { CustomerSummaryComponent } from './customer-summary/customer-summary.component';
import { SalesDocumentsComponent } from './crm/sales-documents/sales-documents.component';
import { NewForecastComponent } from './crm/new-forecast/new-forecast.component';
import { SalesNotesComponent } from './crm/sales-notes/sales-notes.component';
import { SalesCalendarComponent } from './crm/sales-calendar/sales-calendar.component';
import { NewAccountsComponent } from './crm/new-accounts/new-accounts.component';
import { CustomerActivitiesComponent } from './crm/customer-activities/customer-activities.component';
import { CustomerCasesComponent } from './crm/customer-cases/customer-cases.component';
import { MarketingCreateContentComponent } from './crm/marketing-create-content/marketing-create-content.component';
import { DetailNoteComponent } from './project/detail-note/detail-note.component';
import { CreateNewSubsidiaryComponent } from './new-import/create-new-subsidiary/create-new-subsidiary.component';
import { FsmContractComponent } from './fsm-contract/fsm-contract.component';
import { WorkOrderSummaryComponent } from './fsm/work-order-summary/work-order-summary.component';
import { CommerceDashboardComponent } from './DashboardComp/commerce/commerce-dashboard/commerce-dashboard.component';




const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};
const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'forget-email-password', component: NewPasswordComponent },
  { path: 'documents', component: DocumentsComponent },
  { path: 'main-accounting', component: MainAccountingComponent },
  { path: 'edit-sale-invoice', component: EditSaleInvoiceComponent },
  { path: 'ar-submenu', component: ArSubmenuComponent },
  { path: 'edit-purchase', component: EditPurchaseInvoiceComponent },
  { path: 'create-quotes', component: CreateQuotesComponent },
  { path: 'onMain', component: AppContentComponent },

  {
    path: 'crm',
    children: [
      {
        path: '',
        redirectTo: 'main-overview',
        pathMatch: 'full',
      },


      {
        path: 'main-overview', children: [
          { path: '', component: CrmDashboardPageComponent, canActivate: [AdminGuard] },
        ]
      },
      { path: 'crm-overview', component: MainOverviewComponent, canActivate: [AdminGuard] },
      { path: 'Case', component: MyCasesComponent, canActivate: [AdminGuard] },
      { path: 'main-crm', component: MainCrmComponent, canActivate: [AdminGuard] },
      { path: 'documents', component: CrmNewDocumentComponent, canActivate: [AdminGuard] },
      { path: 'sales-documents', component: SalesDocumentsComponent, canActivate: [AdminGuard] },

      { path: 'upload-customer-image', component: UploadCustomerImageComponent, canActivate: [AdminGuard] },
      { path: 'create-accounts', component: CreateAccountComponent, canActivate: [AdminGuard] },
      { path: 'crm-dashboard', component: CrmDashboardComponent, canActivate: [AdminGuard] },
      { path: 'new-internal-note', component: NewInternalNoteComponent, canActivate: [AdminGuard] },
      { path: 'mail-reply', component: MailReplyComponent, canActivate: [AdminGuard] },
      { path: 'create-content', component: CreateContentComponent, canActivate: [AdminGuard] },
      { path: 'create-communication-order', component: CreateCommunicationOrderComponent, canActivate: [AdminGuard] },
      { path: 'crm-overview', component: CrmOverviewComponent, canActivate: [AdminGuard] },
      { path: 'create-event-role', component: CreateEventRoleComponent, canActivate: [AdminGuard] },
      { path: 'create-crm-product', component: CreateCrmProductComponent, canActivate: [AdminGuard] },
      { path: 'create-crm-return', component: CreateCrmReturnComponent, canActivate: [AdminGuard] },
      { path: 'create-work-effort', component: CreateWorkEffortComponent, canActivate: [AdminGuard] },
      { path: 'create-event-purpose', component: CreateEventPurposeComponent, canActivate: [AdminGuard] },
      { path: 'new-communication-crm', component: NewCommunicationCrmComponent, canActivate: [AdminGuard] },
      { path: 'new-mail', component: NewMailComponent, canActivate: [AdminGuard] },
      { path: 'main-detail-summary', component: MainDetailSummaryComponent, canActivate: [AdminGuard] },
      { path: 'Tasks', component: TasksCrmComponent, canActivate: [AdminGuard] },
      {
        path: 'Partners-Competitors', children: [
          { path: '', component: PartnersComponent, canActivate: [AdminGuard] },
          { path: 'viewPartner-Competitor', component: PartnersDetailComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'sales-notes', children: [
          { path: '', component: SalesNotesComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'Cases', children: [
          { path: '', component: CasesComponent, canActivate: [AdminGuard] },
          { path: 'viewCase', component: CasesDetailComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'Teams', children: [
          { path: '', component: TeamComponent, canActivate: [AdminGuard] },
          { path: 'viewTeam', component: TeamDetailComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'sales-calendar', children: [
          { path: '', component: SalesCalendarComponent, canActivate: [AdminGuard] },
          // { path: 'viewTeam', component: TeamDetailComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'Forecast', children: [
          { path: '', component: ForecastComponent, canActivate: [AdminGuard] },
          { path: 'viewForecast', component: ForecastDetailComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'sales-forecast', children: [
          { path: '', component: NewForecastComponent, canActivate: [AdminGuard] },
          { path: 'viewForecast', component: ForecastDetailComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'customer-activities', children: [
          { path: '', component: CustomerActivitiesComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'customer-cases', children: [
          { path: '', component: CasesComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'new-accounts', children: [
          { path: '', component: NewAccountsComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'Preferences', children: [
          { path: '', component: PreferencesComponent, canActivate: [AdminGuard] },
          { path: 'viewPreferences', component: PreferencesDetailComponent, canActivate: [AdminGuard] },
          { path: 'preferencesall', component: PreferencesAllComponent, canActivate: [AdminGuard] },
          { path: 'mailPreferences', component: PreferencesCommComponent, canActivate: [AdminGuard] },
          { path: 'notePreferences', component: PreferencesInternalNoteComponent, canActivate: [AdminGuard] },
          { path: 'emailPreferences', component: PreferencesEmailComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'my-contacts', children: [
          { path: '', component: MyContactsComponent, canActivate: [AdminGuard] },
          { path: 'contact', component: ContactsComponent, canActivate: [AdminGuard] },
          { path: 'update-contact-info', component: UpdateContactInfoComponent, canActivate: [AdminGuard] },

          { path: 'create-contacts', component: CreateContactsComponent, canActivate: [AdminGuard] },
          { path: 'Find-contacts', component: FindContactsComponent, canActivate: [AdminGuard] },
          { path: 'merge-contacts', component: MergeContactsComponent, canActivate: [AdminGuard] },
          { path: 'details-contact', component: DetailedContactsSummaryComponent, canActivate: [AdminGuard] },
          { path: 'import', component: ImportComponent, canActivate: [AdminGuard] },
          // MergeContactsComponent
          { path: 'listRelatedAccount', component: ListRelatedAccountComponent, canActivate: [AdminGuard] },
          { path: 'mergeContacts', component: MergeContactsComponent, canActivate: [AdminGuard] },
          { path: 'createNote', component: NotesComponent, canActivate: [AdminGuard] },
          { path: 'create-contact-information', component: ContactInformationComponent, canActivate: [AdminGuard] },
          // ImportComponent
          { path: 'import-contact', component: ImportContactsComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'customer-service', children: [
          { path: '', component: CustomerServiceComponent, canActivate: [AdminGuard] },
          { path: 'Inactive-Customer', component: InactiveCustomerComponent, canActivate: [AdminGuard] },
          { path: 'inactive-customer-summary-detail', component: InactiveCustomerSummaryComponent, canActivate: [AdminGuard] },

          { path: 'contact', component: ContactsComponent, canActivate: [AdminGuard] },
          { path: 'update-contact-info', component: UpdateContactInfoComponent, canActivate: [AdminGuard] },

          { path: 'create-contacts', component: CreateContactsComponent, canActivate: [AdminGuard] },
          { path: 'Find-contacts', component: FindContactsComponent, canActivate: [AdminGuard] },
          { path: 'merge-contacts', component: MergeContactsComponent, canActivate: [AdminGuard] },
          { path: 'details-contact', component: DetailedContactsSummaryComponent, canActivate: [AdminGuard] },
          { path: 'import', component: ImportComponent, canActivate: [AdminGuard] },
          // MergeContactsComponent
          { path: 'listRelatedAccount', component: ListRelatedAccountComponent, canActivate: [AdminGuard] },
          { path: 'mergeContacts', component: MergeContactsComponent, canActivate: [AdminGuard] },
          { path: 'createNote', component: NotesComponent, canActivate: [AdminGuard] },
          { path: 'create-contact-information', component: ContactInformationComponent, canActivate: [AdminGuard] },
          // ImportComponent
          { path: 'import-contact', component: ImportContactsComponent, canActivate: [AdminGuard] },
        ]
      },

      {
        path: 'leads', children: [
          { path: '', component: MyLeadsComponent },
          { path: 'create-leads', component: LeadsComponent, canActivate: [AdminGuard] },
          { path: 'Find-leads', component: FindLeadsComponent, canActivate: [AdminGuard] },
          { path: 'merge-leads', component: MyLeadsComponent, canActivate: [AdminGuard] },
          // LeadPersonalComponent
          { path: 'merges-leads', component: MergeLeadComponent, canActivate: [AdminGuard] },
          { path: 'personal-leads', component: LeadPersonalComponent, canActivate: [AdminGuard] },
          { path: 'my-lead', component: MyLeadComponent, canActivate: [AdminGuard] },

          { path: 'leadPersonal', component: LeadPersonalGroupComponent, canActivate: [AdminGuard] },

          { path: 'convertLead', component: ConvertLeadComponent, canActivate: [AdminGuard] },

          { path: 'leadNotes', component: LeadNotesComponent, canActivate: [AdminGuard] },

          { path: 'details-lead', component: DetaledLeadComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'accounts', children: [
          { path: '', component: AccountsComponent, canActivate: [AdminGuard] },
          { path: 'create-accounts', component: CreateAccountComponent, canActivate: [AdminGuard] },
          // ListRelatedContactComponent
          { path: 'editAccountPartyId', component: EditContactGroupComponent, canActivate: [AdminGuard] },
          { path: 'importAccount', component: ImportAccountComponent, canActivate: [AdminGuard] },
          { path: 'listRelatedAccount', component: ListRelatedContactComponent, canActivate: [AdminGuard] },
          { path: 'accountNotes', component: AccountNotesComponent, canActivate: [AdminGuard] },
          { path: 'Find-accounts', component: FindAccountsComponent, canActivate: [AdminGuard] },
          { path: 'merge-accounts', component: MergeAccountsComponent, canActivate: [AdminGuard] },
          { path: 'account-details', component: DetailedAccountsSummaryComponent, canActivate: [AdminGuard] },
          { path: 'create-credit-card', component: CreateCreditCardComponent, canActivate: [AdminGuard] },
          { path: 'create-gift-card', component: CreateGiftCardComponent, canActivate: [AdminGuard] },
          { path: 'create-eft-account', component: CreateEFTAccountComponent, canActivate: [AdminGuard] },
          { path: 'create-billing-account', component: CreateBillingAccountComponent, canActivate: [AdminGuard] },
          { path: 'create-check-account', component: CreateCheckAccountComponent, canActivate: [AdminGuard] },
          { path: 'create-additional-details', component: CreateAccountAdditionalDetailsComponent, canActivate: [AdminGuard] },
          { path: 'create-job-info', component: CreateAccountJobInfoComponent, canActivate: [AdminGuard] },
          { path: 'create-postal-address', component: AccountPostalAddressComponent, canActivate: [AdminGuard] },
          { path: 'create-contact', component: AccountFaxNumberComponent, canActivate: [AdminGuard] }
        ]
      },

      {
        path: 'opportunities', children: [
          { path: '', component: TeamsOpportunitiesComponent, canActivate: [AdminGuard] },
          { path: 'create-opportunity', component: CreateOpportunityComponent, canActivate: [AdminGuard] },
          { path: 'Find-opportunity', component: FindOpportunitiesComponent, canActivate: [AdminGuard] },
          { path: 'opportunity-details', component: DetailedOpportunitySummaryComponent, canActivate: [AdminGuard] },

        ]
      },
      {
        path: 'quotations', children: [
          { path: '', component: MyQuotesComponent, canActivate: [AdminGuard], resolve: { quote: MyQuotesResolver } },
          { path: 'create-quotes', component: CreateQuotesComponent, canActivate: [AdminGuard] },
          { path: 'quote-details', component: DetailedQuoteSummaryComponent, canActivate: [AdminGuard] },
          { path: 'quoteNotes', component: QuotesNotesComponent, canActivate: [AdminGuard] },
          { path: 'quote-email', component: QuoteEmailComponent, canActivate: [AdminGuard] }


        ]
      },
      { path: 'documents', component: DocumentsComponent },
      {
        path: 'events', children: [
          { path: '', component: EventsComponent, canActivate: [AdminGuard] },
          { path: 'create-events', component: CreateEventsComponent, canActivate: [AdminGuard] },

          { path: 'details-event', component: DetailedEventSummaryComponent, canActivate: [AdminGuard] },
          { path: 'all-events', component: AllEventsComponent, canActivate: [AdminGuard] },

        ]
      },
    ]
  },
  {
    path: 'hr',
    children: [
      {
        path: '',
        redirectTo: 'overview-hrm-main',
        pathMatch: 'full',
      },
      {
        path: 'overview-hrm-main', children: [
          {
            path: '', component: HRMSDashboardComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'detail-resume', children: [
          {
            path: '', component: DetailResumeComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'hrm-resume', children: [
          {
            path: '', component: HrmResumeComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'detail-qualification', children: [
          {
            path: '', component: DetailQualificationComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'hrm-qualification', children: [
          {
            path: '', component: HrmQualificationComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'detail-performce-review', children: [
          {
            path: '', component: DetailPerformceReviewComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'hrm-performance-review', children: [
          {
            path: '', component: HrmPerformanceReviewComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'detail-employement-summary', children: [
          {
            path: '', component: DetailEmployementSummaryComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'find-employement', children: [
          {
            path: '', component: HrmEmployeementComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'hrms-overview', children: [
          {
            path: '', component: OverviewHrmMainComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'hrm-dashboard', children: [
          {
            path: '', component: HrmDashboardComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'hrm-company', component: MainCompanyComponent, canActivate: [AdminGuard]
      },
      { path: 'upload-hr-image', component: UploadCrmCustomerImageComponent, canActivate: [AdminGuard] }
      ,
      {
        path: 'hrm-main', component: MainComponent, canActivate: [AdminGuard]
      },
      {
        path: 'responsibilities', component: ResponsibilitiesComponent, canActivate: [AdminGuard]
      },
      {
        path: 'global-settings', component: SettingComponent, canActivate: [AdminGuard]
      },
      {
        path: 'overview-skill', component: OverviewSkillComponent, canActivate: [AdminGuard]
      },
      {
        path: 'overview-leave', component: OverviewLeaveComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-skill-type', component: DetailSkillTypeComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-position-type', component: DetailPositionTypeComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-reason-type', component: DetailReasonTypeComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-res-summary', component: RespDetailSummaryComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-job-summary', component: DetailJobSummaryComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-leave-summary', component: DetailLeaveSummaryComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-responsibility-type', component: DetailResTypeComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-holiday-summary', component: DetailHolidaySummaryComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-training-class-type', component: DetailTrainingClassTypeComponent, canActivate: [AdminGuard]
      },
      {
        path: 'reason-type', component: ReasonTypeComponent, canActivate: [AdminGuard]
      },
      {
        path: 'employee-application', component: EmployeeApplicationComponent, canActivate: [AdminGuard]
      },
      {
        path: 'employee-application-summary', component: EmployeeApplicationSummaryComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-employee-application', component: CreateEmployeeApplicationComponent, canActivate: [AdminGuard]
      },

      {
        path: 'main-hrm', children: [
          { path: '', component: MainHumanResourceComponent },

        ]
      },
      {
        path: 'employees', children: [
          {
            path: '', component: EmployeesComponent, canActivate: [AdminGuard]
          },
          {
            path: 'emergency-contact', component: EmployeeEmergencyContactComponent, canActivate: [AdminGuard]
          },
          {
            path: 'contact', component: EmployeeFaxInformationComponent, canActivate: [AdminGuard]
          },
          {
            path: 'add-employee', component: AddNewEmployeeComponent, canActivate: [AdminGuard]
          },
          {
            path: 'new-identification', component: NewIdentificationComponent, canActivate: [AdminGuard]
          },
          {
            path: 'new-username', component: NewUsernameComponent, canActivate: [AdminGuard]
          },
          {
            path: 'new-party-attribute', component: NewPartyAttributeComponent, canActivate: [AdminGuard]
          },
          {
            path: 'employee-details', component: DetailedEmployeeSummaryComponent, canActivate: [AdminGuard]
          },
          {
            path: 'employee-import', component: EmpImportComponent, canActivate: [AdminGuard]
          },
          {
            path: 'update-personal-info', component: PersonalUpdateComponent, canActivate: [AdminGuard]
          },
          {
            path: 'contact-personal-info', component: EmpContactInformationComponent, canActivate: [AdminGuard]
          },
          {
            path: 'update-address', component: UpdatePostalAddressComponent, canActivate: [AdminGuard]
          },
          {
            path: 'add-emp-account', component: EmpRelatedAccountComponent, canActivate: [AdminGuard]
          },
          {
            path: 'update-phone-number', component: UpdatePhoneNumberComponent, canActivate: [AdminGuard]
          },
          {
            path: 'update-email', component: EmpUpdateEmailComponent, canActivate: [AdminGuard]
          },
          {
            path: 'emp-notes', component: EmpLeadNotesComponent, canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'employee-position', children: [
          { path: '', component: EmployeePositionComponent },
          { path: 'add-employee-position', component: AddEmployeePositionComponent },
          { path: 'employee-position-details', component: DetailedEmployeePositionComponent },
          { path: 'employee-fullfilment', component: EmpFulfillmentPositionComponent },
          { path: 'employee-responsibility', component: EmpResponsibilityComponent },
          { path: 'employee-reported-to', component: EmpPositionReportedToComponent },
          { path: 'employee-reported-by', component: EmpPositionReportByComponent },


        ]
      },
      {
        path: 'skills', children: [
          { path: '', component: SkillsComponent },
          { path: 'add-skills', component: AddSkillsComponent },
          { path: 'skill-summary', component: DetailSkillSummaryComponent },
        ]
      },
      {
        path: 'job-requisition', children: [
          { path: '', component: JobRequisitionComponent },
          { path: 'new-job-requisition', component: NewJobRequisitionComponent, canActivate: [AdminGuard] },
          { path: 'detail-summary-requisition', component: DetailRequistionSummaryComponent },
          { path: 'internal-job-posting', component: InternalJobPostingComponent },
          { path: 'detail-internal-job-summary', component: DetailInternalJobSummaryComponent },
          { path: 'approval', component: ApprovalComponent, canActivate: [AdminGuard] },
          { path: 'approval-summary', component: ApprovalSummaryComponent, canActivate: [AdminGuard] },
          { path: 'update-approval', component: UpdateApprovalComponent, canActivate: [AdminGuard] },
          { path: 'reAllocations', component: ReallocationComponent, canActivate: [AdminGuard] },
          { path: 'reAllocations-summary', component: ReallocationSummaryComponent, canActivate: [AdminGuard] },
          { path: 'create-internal-posting', component: CreateInternalJobPostingComponent, canActivate: [AdminGuard] },
          { path: 'job-interview', component: JobInterviewComponent, canActivate: [AdminGuard] },
          { path: 'detail-job-interview-summary', component: JobInterviewSummaryComponent, canActivate: [AdminGuard] },
          { path: 'add-job-interview', component: AddJobInterviewComponent, canActivate: [AdminGuard] },
          { path: 'internal-job-posting-app', component: InternalJobPostingApplicationComponent, canActivate: [AdminGuard] },
          { path: 'new-internal-job-posting', component: NewInternalJobPostingComponent, canActivate: [AdminGuard] },
        ]
      },

      {
        path: 'training-approvals', children: [
          { path: '', component: TrainingApprovalsComponent },
          { path: 'training-summary', component: TrainingSummaryComponent },
        ]
      },
      {
        path: 'employee-leave', children: [
          { path: '', component: EmployeeLeaveComponent },
          { path: 'leave-approval', component: LeaveApprovalComponent },
          { path: 'add-new-leaves', component: AddNewLeavesComponent },
          { path: 'leave-summary', component: LeaveSummaryComponent },
        ]
      },
      {
        path: 'employments', children: [
          { path: '', component: EmploymentsComponent },
          { path: 'add-new-employment', component: AddNewEmploymentComponent },
        ]
      },
      {
        path: 'departments', children: [
          { path: '', component: DepartmentsComponent },
          { path: 'create-departments', component: CreateDepartmentsComponent },
          { path: 'employee-list', component: EmployeeListComponent },
          { path: 'detail-summary', component: DetailDepartmentSummaryComponent }
        ]
      },
      {
        path: 'timesheets', children: [
          { path: '', component: TimesheetComponent },
          { path: 'create-timesheet', component: CreateTimesheetComponent },
          { path: 'detail-timesheet-summary', component: DetailedTimesheetSummaryComponent },
          { path: 'create-role', component: CreateRoleComponent },
        ]
      }
    ]
  },

  // for order  management
  {
    path: 'order',
    children: [
      {
        path: '',
        redirectTo: 'Dashboard',
        pathMatch: 'full',
      },
      { path: 'Dashboard', component: OrdersDashboardComponent, canActivate: [AdminGuard] },
      { path: 'quotations', component: MyQuotesComponent, canActivate: [AdminGuard], resolve: { quote: MyQuotesResolver } },
      { path: 'create-quotes', component: CreateQuotesComponent, canActivate: [AdminGuard] },
      { path: 'quote-details', component: DetailedQuoteSummaryComponent, canActivate: [AdminGuard] },
      { path: 'quoteNotes', component: QuotesNotesComponent, canActivate: [AdminGuard] },
      { path: 'quote-email', component: QuoteEmailComponent, canActivate: [AdminGuard] },
      {
        path: 'order-dashboard', component: OrderDashboardComponent, canActivate: [AdminGuard]
      },
      {
        path: 'main-order', component: MainOrderComponent, canActivate: [AdminGuard]
      },

      {
        path: 'order-management', component: FindOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-order', component: CreateOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-sales-order', component: CreateOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'receive-payment', component: OrderReceivePaymentComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-purchase-order', component: CreatePurchaseOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-sales-continue', component: CreateSalesContinueComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-continue', component: SalesContinueComponent, canActivate: [AdminGuard]
      },
      {
        path: 'purchase-continue', component: PurchaseContinueComponent, canActivate: [AdminGuard]
      },
      {
        path: 'promo-code', component: SalesPromoCodesComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-product', component: SalesProductComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-purchase-continue', component: CreatePurchaseContinueComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-finalize-order', component: SalesFinalaizeOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'purchase-finalize-order', component: PurchaseFinalaizeOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'new-shipping-address', component: CreateSalesNewAddressComponent, canActivate: [AdminGuard]
      },
      {
        path: 'purchase-shipping-address', component: CreatePurchaseNewAddressComponent, canActivate: [AdminGuard]
      },

      {
        path: 'sales-order-options', component: SalesOrderOptionsSettingsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'purchase-order-options', component: PurchaseOrderOptionsSettingsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-order-entry-terms', component: SalesOrderEntryTermsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'purchase-order-entry-terms', component: PurchaseOrderEntryTermsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-payment-options', component: SalesPaymentOptionsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'purchase-payment-options', component: PurchasePaymentOptionsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-order-additional-party-entry', component: SalesOrderAdditionalPartyComponent, canActivate: [AdminGuard]
      },
      {
        path: 'purchase-order-additional-party-entry', component: PurchaseOrderAdditionalPartyComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-order-confirmation', component: SalesOrderConfirmationComponent, canActivate: [AdminGuard]
      },
      {
        path: 'purchase-order-confirmation', component: PurchaseOrderConfirmationComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-order-view-page', component: SalesOrderViewPageComponent, canActivate: [AdminGuard]
      },
      {
        path: 'purchase-order-view-page', component: PurchaseOrderViewPageComponent, canActivate: [AdminGuard]
      },
      {
        path: 'return-order', component: ReturnMainComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-new-return-order', component: CreateNewReturnComponent, canActivate: [AdminGuard]
      },
      {
        path: 'return-item', component: ReturnItemsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'return-history', component: ReturnHistoryComponent, canActivate: [AdminGuard]
      },
      {
        path: 'confirmation-email', component: ConfirmationEmailComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-note', component: CreateNotesComponent, canActivate: [AdminGuard]
      },
      {
        path: 'view-order-history', component: ViewOrderHistoryComponent, canActivate: [AdminGuard]
      },
      {
        path: 'requests-order', component: RequestsOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'quotes-order', component: QuotesOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'order-list', component: OrderListComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-quotes-order', component: DetailQuotesOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-requests-order', component: DetailRequestsOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'stats', component: StatsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'reports-order', component: ReportsOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'requirements', component: RequirementsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-requirements', component: DetailRequirementsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-open-order-item-report', component: DetailOpenOrderItemReportComponent, canActivate: [AdminGuard]
      },
      {
        path: 'allocation-plan', component: AllocationPlanComponent, canActivate: [AdminGuard]
      },
      {
        path: 'detail-allocation-plan', component: DetailAllocationPlanComponent, canActivate: [AdminGuard]
      },

    ]
  },
  // order management ends
  // for order  management
  {
    path: 'order',
    children: [
      {
        path: '',
        redirectTo: 'order-management',
        pathMatch: 'full',
      },
      {
        path: 'order-management', component: FindOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-order', component: CreateOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'return-view-order-accept', component: ReturnViewOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-sales-continue', component: CreateSalesContinueComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-continue', component: SalesContinueComponent, canActivate: [AdminGuard]
      },
      {
        path: 'purchase-continue', component: PurchaseContinueComponent, canActivate: [AdminGuard]
      },
      {
        path: 'promo-code', component: SalesPromoCodesComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-product', component: SalesProductComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-purchase-continue', component: CreatePurchaseContinueComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-finalize-order', component: SalesFinalaizeOrderComponent, canActivate: [AdminGuard]
      },
      {
        path: 'new-shipping-address', component: CreateSalesNewAddressComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-order-options', component: SalesOrderOptionsSettingsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-order-entry-terms', component: SalesOrderEntryTermsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-payment-options', component: SalesPaymentOptionsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-order-additional-party-entry', component: SalesOrderAdditionalPartyComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-order-confirmation', component: SalesOrderConfirmationComponent, canActivate: [AdminGuard]
      },
      {
        path: 'sales-order-view-page', component: SalesOrderViewPageComponent, canActivate: [AdminGuard]
      },
      {
        path: 'return-order', component: ReturnMainComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-new-return-order', component: CreateNewReturnComponent, canActivate: [AdminGuard]
      },
      {
        path: 'return-item', component: ReturnItemsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'return-history', component: ReturnHistoryComponent, canActivate: [AdminGuard]
      },
      {
        path: 'confirmation-email', component: ConfirmationEmailComponent, canActivate: [AdminGuard]
      },
      {
        path: 'create-note', component: CreateNotesComponent, canActivate: [AdminGuard]
      },
      {
        path: 'view-order-history', component: ViewOrderHistoryComponent, canActivate: [AdminGuard]
      },
    ]
  },



  // order management ends
  {
    path: 'accounts', component: AccountsComponent, children: [
      { path: 'my-accounts', component: AccountsComponent },
      { path: 'Find-accounts', component: FindAccountsComponent },
      { path: 'merge-accounts', component: MergeAccountsComponent },
    ]
  },


  {
    path: 'facilities',
    children: [
      {
        path: '',
        redirectTo: 'Dashboard',
        pathMatch: 'full',
      },
      { path: 'Dashboard', component: SupplyDashboardComponent },
      { path: 'track-distribution', component: FacilityMainComponent },
      {
        path: 'warehouse-dashborad-overview', children: [
          { path: '', component: WarehouseDashboardOverviewComponent },
        ]
      },
      {
        path: 'Planning', children: [
          { path: '', component: PlanningComponent },
          { path: 'viewPlanning', component: DetailPlanningComponent },

          { path: 'find-requirements', component: FindRequirementsMainComponent },
          { path: 'find-requirements-detail', component: FindRequirementsDetailComponent },

          { path: 'open-requirements', component: RequirementsMainComponent },
          { path: 'open-requirements-detail', component: OpenRequirementsDetailComponent },

          { path: 'product-requirements', component: ProductRequirementsMainComponent },
          { path: 'review-order', component: ReviewOrderComponent },
          { path: 'purchase-order-quick-entry', component: PurchaseOrderQuickEntryComponent },
          { path: 'purchase-orders', component: PurchaseOrderComponent },

          { path: 'internal-requirements', component: InternalRequirementsMainComponent },
          { path: 'transfer-requirements', component: TransferRequirementsMainComponent },
          { path: 'upload-sales-order-history', component: SalesOrderHistoryMainComponent },
          { path: 'run-mrp', component: RunMrpMainComponent },
          { path: 'view-mrp', component: ViewMrpMainComponent },
          { path: 'configure-warehouse', component: ConfigureWarehouseMainComponent },
          { path: 'configure-warehouse-detail', component: ConfigureWarehouseDetailComponent },
          { path: 'configure-warehouse-existing-detail', component: ConfigureWarehouseExistingDetailComponent },

        ]
      },



      // {
      //   path: 'webpos',
      //   children: [
      //     {
      //       path: '',
      //       redirectTo: 'login',
      //       pathMatch: 'full',
      //     },

      //     {
      //       path: 'login', children: [
      //         { path: '', component: WebposLoginPageComponent },
      //       ]
      //     },

      //     {
      //       path: 'control/main', children: [
      //         { path: '', component: MainDetailComponent },
      //       ]
      //     },
      //     {
      //       path: 'control/manager', children: [
      //         { path: '', component: ManagerDetailComponent },
      //       ]
      //     },
      //   ]
      // },
      {
        path: 'Distribution', children: [
          { path: '', component: DistributionComponent },
          { path: 'viewDistribution', component: DetailDistributionComponent },
          { path: 'track-distribution', component: TrackDistributorComponent },
          { path: 'sales-order-distribution', component: SalesOrderDistributorComponent },
        ]
      },
      {
        path: 'warehouse-dashboard', children: [
          { path: '', component: WarehouseDashboardComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'sales-order-distribution', children: [
          { path: '', component: MainWarehouseComponent },
        ]
      },
      {
        path: 'shipment-config', children: [
          { path: '', component: ShipmentConfigComponent },
          { path: 'detail-shipment-config', component: DetailShipmentConfigComponent },

        ]
      },
      {
        path: 'warehouse-shipment-config', children: [
          { path: '', component: WarehouseShipmentConfigComponent },


        ]
      },
      {
        path: 'detail-shipment-config-types', children: [
          { path: '', component: DetailShipmentConfigTypesComponent },

        ]
      },
      {
        path: 'detail-shipment-config', children: [
          { path: '', component: DetailShipmentConfigComponent },

        ]
      },

      {
        path: 'warehouse', children: [
          { path: '', component: WarehouseComponent },
          { path: 'create-new-facilities', component: CreateNewFacilitiesComponent, resolve: { facility: CreateNewFacilitiesResolver } },
          { path: 'detailed-summary-facilities', component: DetailedSummaryFacilitiesComponent },
          { path: 'create-new-facility-location', component: CreateNewFacilityLocationComponent },
          { path: 'create-new-inventory-item', component: CreateNewInventoryItemComponent },
          { path: 'update-physical-inventory', component: UpdatePhysicalInventoryComponent },
          { path: 'create-new-inventory-transfer', component: CreateInventoryTransferComponent },
          { path: 'create-new-contact-information', component: CreateNewContactInformationComponent },
          { path: 'update-inventory', component: PhysicalUpdateInventoryComponent },
          { path: 'review-order', component: ReviewOrderNotPickedComponent },
          { path: 'view-retuen', component: ShowReturnComponent },
          { path: 'detail-inventory-page', component: DetailInventoryItemsComponent },
          { path: 'search-by-label', component: SearchByLabelsComponent },




        ]
      },
      {
        path: 'facility-group', children: [
          { path: '', component: FacilityGroupComponent },
          { path: 'new-facility-group', component: NewFacilityGroupComponent },
          { path: 'detail-facility-group-summary', component: DetailFacilityGroupSummaryComponent },
        ]
      },
      {
        path: 'inventory-labels', children: [
          { path: '', component: InventoryLabelsComponent },
          { path: 'label-types', component: LabelTypesComponent },
          { path: 'detail-label-summary', component: DetailLabelTypeSummaryComponent },
          { path: 'detail-inventory-label-summary', component: DetailInventoryLabelSummaryComponent },
        ]
      },
      {
        path: 'shipment-gateway', children: [

          { path: '', component: ShipmentGatewayComponent },
        ]
      },
      {
        path: 'warehouse-shipment', children: [
          { path: '', component: WarehouseShipmentComponent },
          { path: 'new-warehouse-shipment', component: NewWarehouseShipmentComponent },
          { path: 'update-warehouse-shipment', component: UpdateWarehouseShipmentComponent },
          { path: 'warehouse-shipment-summary', component: WarehouseShipmentSummaryComponent }
        ]
      },
      { path: 'thesaurus', component: ThesaurusComponent },
      {
        path: 'shipment', children: [
          { path: '', component: ShipmentComponent },
          { path: 'quantity-breaks', component: QuantityBreaksComponent },
          { path: 'carrier-shipment', component: CarrierShipmentComponent },
          { path: 'shipment-estimate', component: ShipmentEstimateComponent },

        ]
      },
      {
        path: 'catalog',
        children: [
          {
            path: '',
            redirectTo: 'catalog-dashboard',
            pathMatch: 'full',
          },
          {
            path: 'catalog-dashboard', children: [
              {
                path: '', component: CatalogDashboardComponent,
                canActivate: [AdminGuard]
              },
            ]
          },
          {
            path: 'main-catalog', children: [
              {
                path: '', component: MainCatalogMgmtComponent,
                canActivate: [AdminGuard]
              },
            ]
          },
          {
            path: 'catalogs', children: [
              {
                path: '', component: CatalogsComponent,
                canActivate: [AdminGuard]
              },
              { path: 'detail-catalog-page', component: DetailCatalogComponent },
              { path: 'detail-catalog', component: StoriesComponent },
              { path: 'new-product-catalog', component: NewProductCatalogComponent },
            ]
          },
          {
            path: 'catagories', children: [
              { path: '', component: CatagoriesComponent },
              { path: 'new-product-catagory', component: NewCatagoryComponent },
              { path: 'detail-category-summary/:categoryId', component: DetailCategorySummaryComponent, resolve: { category: DetailCategorySummaryResolver } }
            ]
          },
          {
            path: 'products', children: [
              { path: '', component: ProductsComponent, resolve: { product: NewProductsResolver } },
              { path: 'new-products', component: NewProductsComponent, resolve: { product: NewProductsResolver } },
              { path: 'detail-product-summary/:productId', component: DetailProductSummaryComponent, resolve: { product: DetailProductSummaryResolver } },
              { path: 'create-supplier', component: CreateSupplierProductComponent },
              { path: 'product-price', component: ProductPriceComponent },
              { path: 'additional-add-content', component: CreateProductContentComponent },
              { path: 'create-product-variant', component: CreateProductVariantComponent },
            ]
          },
          {
            path: 'feature-type', children: [
              { path: '', component: FeatureTypeComponent },
              { path: 'new-feature-type', component: NewFeatureTypeComponent },
              { path: 'detail-feature-type', component: DetailFeatureTypeComponent },

            ]
          },
          {
            path: 'feature-category', children: [

              { path: '', component: FeatureCategoryComponent },
              { path: 'detailed-summary-feature-category', component: DetailedSummaryFeatureCategoryComponent },
              { path: 'new-feature-maintenance', component: CreateEditFeatureMaintenanceComponent },
              { path: 'new-feature-category', component: NewFeatureCategoryComponent },

              { path: 'summary-page', component: SummaryPageComponent }
            ]
          },
          {
            path: 'feature-group', children: [


              { path: '', component: FeatureGroupComponent },
              { path: 'update-application', component: UpdateApplicationComponent },
              { path: 'detailed-summary-feature-group', component: DetailedSummaryFeatureGroupComponent },

            ]
          },

          {
            path: 'feature-interaction', children: [


              { path: '', component: FeatureInteractionComponent },
              {
                path: 'new-feature-interaction', component: NewFeatureInteractionComponent,
              },


            ]
          },

          {
            path: 'promos', children: [
              {
                path: '', component: PromosCatalogComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'editPromo', component: EditProductPromoComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'create-product-promotion-code', component: CreateProductPromotionCodeComponent,
                canActivate: [AdminGuard]
              },
            ]
          },
          {
            path: 'price-rules', children: [
              {
                path: '', component: PriceRulesCatalogComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'editPriceRules', component: EditProductPriceRulesComponent,
                canActivate: [AdminGuard]
              },
            ]
          },
          {
            path: 'stores', children: [
              {
                path: '', component: StoresCatalogComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'new-product-store', component: NewProductStoreComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'editStore', component: EditProductStoreComponent,
                canActivate: [AdminGuard]
              },
            ]
          },
          {
            path: 'product-store-groups', children: [
              {
                path: '', component: ProductStoreGroupsCatalogComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'editStoreGroups', component: EditProductStoreGroupComponent,
                canActivate: [AdminGuard]
              },
            ]
          },
          {
            path: 'reviews', children: [
              {
                path: '', component: ReviewsCatalogComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'editReviews', component: EditReviewsCatalogComponent,
                canActivate: [AdminGuard]
              },
            ]
          },
          {
            path: 'subscriptions', children: [
              {
                path: '', component: SubscriptionsCatalogComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'editSubscriptions', component: EditProductSubscriptionsComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'suscription-resource', component: SubscriptionResourceCatalogComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'detail-subscription-resource', component: DetailSubscriptionResourceComponent,
                canActivate: [AdminGuard]
              },
            ]
          },
          {
            path: 'image-management', children: [
              {
                path: '', component: ImageManagementCatalogComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'image-management-main-detail', component: ImageManagementMainDetailComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'image-management-manage-detail', component: ImageManagementManageDetailComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'image-management-recently-approved-detail', component: ImageManagementRecentlyApprovedDetailComponent,
                canActivate: [AdminGuard]
              },
              {
                path: 'image-management-replace-detail', component: ImageManagementReplaceDetailComponent,
                canActivate: [AdminGuard]
              },
            ]
          },
          {
            path: 'configurations', children: [
              { path: '', component: ConfigurationsComponent },
              { path: 'create-config', component: CreateConfigurationsComponent },
              { path: 'detail-configurations', component: DetailConfigurationsComponent },
              { path: 'create-simple-text', component: CreateSimpleTextComponent },
            ]
          },
          { path: 'thesaurus', component: ThesaurusComponent, resolve: { thesaurus: ThesaurusResolver } },
          {
            path: 'shipment', children: [
              { path: '', component: ShipmentComponent },
              { path: 'quantity-breaks', component: QuantityBreaksComponent },
              { path: 'carrier-shipment', component: CarrierShipmentComponent },
              { path: 'carrier-shipment', component: CarrierShipmentComponent },
              { path: 'shipment-estimate', component: ShipmentEstimateComponent },
              { path: 'detail-shipment-summary', component: DetailShipmentSummaryComponent },
              { path: 'detail-quantity-summary', component: DetailQuantityBreakSummaryComponent },
              { path: 'detail-carrier-summary', component: DetailCarrierSummaryComponent },
              { path: 'shipment-time-estimate', component: ShipmentTimeEstimateComponent },
              { path: 'detail-shipment-time-estimate', component: DetailShipmentTimeEstimateComponent },
            ]
          },
        ]
      },
    ]
  },
  {
    path: 'product-management',
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
      {
        path: 'overview', children: [
          {
            path: '', component: PIMOverviewComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'catalog-dashboard', children: [
          {
            path: '', component: CatalogDashboardComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'main-catalog', children: [
          {
            path: '', component: MainCatalogMgmtComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'catalogs', children: [
          {
            path: '', component: CatalogsComponent,
            canActivate: [AdminGuard]
          },
          { path: 'detail-catalog-page', component: DetailCatalogComponent },
          { path: 'detail-catalog', component: StoriesComponent },
          { path: 'new-product-catalog', component: NewProductCatalogComponent },
        ]
      },
      {
        path: 'catagories', children: [
          { path: '', component: CatagoriesComponent },
          { path: 'new-product-catagory', component: NewCatagoryComponent },
          { path: 'detail-category-summary/:categoryId', component: DetailCategorySummaryComponent, resolve: { category: DetailCategorySummaryResolver } }
        ]
      },
      {
        path: 'products', children: [
          { path: '', component: ProductsComponent, resolve: { product: NewProductsResolver } },
          { path: 'new-products', component: NewProductsComponent, resolve: { product: NewProductsResolver } },
          { path: 'detail-product-summary/:productId', component: DetailProductSummaryComponent, resolve: { product: DetailProductSummaryResolver } },
          { path: 'create-supplier', component: CreateSupplierProductComponent },
          { path: 'product-price', component: ProductPriceComponent },
          { path: 'additional-add-content', component: CreateProductContentComponent },
          { path: 'create-product-variant', component: CreateProductVariantComponent },
        ]
      },
      {
        path: 'feature-type', children: [
          { path: '', component: FeatureTypeComponent },
          { path: 'new-feature-type', component: NewFeatureTypeComponent },
          { path: 'detail-feature-type', component: DetailFeatureTypeComponent },

        ]
      },
      {
        path: 'feature-category', children: [

          { path: '', component: FeatureCategoryComponent },
          { path: 'detailed-summary-feature-category', component: DetailedSummaryFeatureCategoryComponent },
          { path: 'new-feature-maintenance', component: CreateEditFeatureMaintenanceComponent },
          { path: 'new-feature-category', component: NewFeatureCategoryComponent },

          { path: 'summary-page', component: SummaryPageComponent }
        ]
      },
      {
        path: 'feature-group', children: [


          { path: '', component: FeatureGroupComponent },
          { path: 'update-application', component: UpdateApplicationComponent },
          { path: 'detailed-summary-feature-group', component: DetailedSummaryFeatureGroupComponent },

        ]
      },

      {
        path: 'feature-interaction', children: [


          { path: '', component: FeatureInteractionComponent },
          {
            path: 'new-feature-interaction', component: NewFeatureInteractionComponent,
          },


        ]
      },

      {
        path: 'promos', children: [
          {
            path: '', component: PromosCatalogComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'editPromo', component: EditProductPromoComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'create-product-promotion-code', component: CreateProductPromotionCodeComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'price-rules', children: [
          {
            path: '', component: PriceRulesCatalogComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'editPriceRules', component: EditProductPriceRulesComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'stores', children: [
          {
            path: '', component: StoresCatalogComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'new-product-store', component: NewProductStoreComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'editStore', component: EditProductStoreComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'product-store-groups', children: [
          {
            path: '', component: ProductStoreGroupsCatalogComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'editStoreGroups', component: EditProductStoreGroupComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'reviews', children: [
          {
            path: '', component: ReviewsCatalogComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'editReviews', component: EditReviewsCatalogComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'subscriptions', children: [
          {
            path: '', component: SubscriptionsCatalogComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'editSubscriptions', component: EditProductSubscriptionsComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'suscription-resource', component: SubscriptionResourceCatalogComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'detail-subscription-resource', component: DetailSubscriptionResourceComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'image-management', children: [
          {
            path: '', component: ImageManagementCatalogComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'image-management-main-detail', component: ImageManagementMainDetailComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'image-management-manage-detail', component: ImageManagementManageDetailComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'image-management-recently-approved-detail', component: ImageManagementRecentlyApprovedDetailComponent,
            canActivate: [AdminGuard]
          },
          {
            path: 'image-management-replace-detail', component: ImageManagementReplaceDetailComponent,
            canActivate: [AdminGuard]
          },
        ]
      },
      {
        path: 'configurations', children: [
          { path: '', component: ConfigurationsComponent },
          { path: 'create-config', component: CreateConfigurationsComponent },
          { path: 'detail-configurations', component: DetailConfigurationsComponent },
          { path: 'create-simple-text', component: CreateSimpleTextComponent },
        ]
      },
      { path: 'thesaurus', component: ThesaurusComponent, resolve: { thesaurus: ThesaurusResolver } },
      {
        path: 'shipment', children: [
          { path: '', component: ShipmentComponent },
          { path: 'quantity-breaks', component: QuantityBreaksComponent },
          { path: 'carrier-shipment', component: CarrierShipmentComponent },
          { path: 'carrier-shipment', component: CarrierShipmentComponent },
          { path: 'shipment-estimate', component: ShipmentEstimateComponent },
          { path: 'detail-shipment-summary', component: DetailShipmentSummaryComponent },
          { path: 'detail-quantity-summary', component: DetailQuantityBreakSummaryComponent },
          { path: 'detail-carrier-summary', component: DetailCarrierSummaryComponent },
          { path: 'shipment-time-estimate', component: ShipmentTimeEstimateComponent },
          { path: 'detail-shipment-time-estimate', component: DetailShipmentTimeEstimateComponent },
        ]
      },
    ]
  },
  {
    path: 'webpos',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },

      {
        path: 'login', children: [
          { path: '', component: WebposLoginPageComponent },
        ]
      },

      {
        path: 'control/main', children: [
          { path: '', component: MainDetailComponent },
        ]
      },
      {
        path: 'control/manager', children: [
          { path: '', component: ManagerDetailComponent },
        ]
      },
    ]
  },
  {
    path: 'dashboard',
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard', children: [
          { path: '', component: DashboardComponent },
        ]
      },
    ]
  },
  {
    path: 'implementation',
    children: [
      {
        path: '',
        redirectTo: 'issue-created-import',
        pathMatch: 'full',
      },
      {
        path: 'Dashboard', children: [
          { path: '', component: SettingDashboardComponent },
        ]
      },
     
      {
        path: 'create-new-subsidiary', children: [
          { path: '', component: CreateNewSubsidiaryComponent },
        ]
      },
      {
        path: 'documents', children: [
          { path: '', component: CrmNewDocumentComponent },
        ]
      },
      {
        path: 'user-dashboard', children: [
          { path: '', component: UserDashboardComponent },
        ]
      },
      {
        path: 'user-list', children: [
          { path: '', component: UserListComponent },
        ]
      },
      {
        path: 'create-user', children: [
          { path: '', component: CreateUserComponent },
        ]
      },
      {
        path: 'security-group', children: [
          { path: '', component: SecurityGroupComponent },
        ]
      },
      {
        path: 'create-security-group', children: [
          { path: '', component: CreateSecurityGroupComponent },
        ]
      },
      {
        path: 'company-profile', children: [
          { path: '', component: CompanyProfileComponent },
        ]
      },
      {
        path: 'detail-page', children: [
          { path: '', component: PermisionDetailPageComponent },
        ]
      },
      {
        path: 'add-role', children: [
          { path: '', component: AddToRoleComponent },
        ]
      },
      {
        path: 'add-permision-manaully', children: [
          { path: '', component: AddPermisionManuallyComponent },
        ]
      },
      {
        path: 'add-permision-list', children: [
          { path: '', component: AddPermissionListComponent },
        ]
      },
      {
        path: 'new-role', children: [
          { path: '', component: NewRoleTypeComponent },
        ]
      },
      {
        path: 'user-detail-summary', children: [
          { path: '', component: UserDetailSummaryComponent },
        ]
      }
      ,
      {
        path: 'import-user', children: [
          { path: '', component: ImportUserComponent },
        ]
      },
      {
        path: 'company-information-import', component: CompanyInformationImportComponent
      },
      {
        path: 'fiscal-year-import', component: FiscalYearImportComponent
      },
      {
        path: 'email-configuration-import', component: EmailConfigurationImportComponent
      },
      {
        path: 'issue-created-import', component: IssueCreatedImportComponent
      },
      {
        path: 'balance-sheet-import', component: BalanceSheetImportComponent
      },
      {
        path: 'trial-balance-import', component: TrialBalanceImportComponent
      },
      {
        path: 'trial-balance-only-import', component: TrialBalanceOnlyImportComponent
      },
      {
        path: 'cash-flow-import', component: CashFlowImportComponent
      },
      {
        path: 'accounts-group-import', component: AccountsGroupImportComponent
      },
      {
        path: 'defaults-accounts-import', component: DefaultsAccountsImportComponent
      },
      {
        path: 'security-group-import', component: SecurityGroupImportComponent
      },
      {
        path: 'accounts-by-part-family-import', component: AccountsByPartFamilyImportComponent
      },
      {
        path: 'unit-of-measure-import', component: UnitOfMeasureImportComponent
      },
      {
        path: 'costing-method-import', component: CostingMethodImportComponent
      },
      {
        path: 'credit-card-processing-import', component: CreditCardProcessingImportComponent
      },
      {
        path: 'tax-component-import', component: TaxComponentImportComponent
      },
      {
        path: 'taxes-only-import', component: TaxesOnlyImportComponent
      },
      {
        path: 'payments-terms-import', component: PaymentsTermsImportComponent
      },
      {
        path: 'banks-only-import', component: BanksOnlyImportComponent
      },
      {
        path: 'cashiers-only-import', component: CashiersOnlyImportComponent
      },
      {
        path: 'branches-only-import', component: BranchesOnlyImportComponent
      },
      {
        path: 'open-customers-invoices-import', component: OpenCustomersInvoicesImportComponent
      },
      {
        path: 'open-vendors-invoices-import', component: OpenVendorsInvoicesImportComponent
      },
      {
        path: 'gl-account-balances-import', component: GlAccountBalancesImportComponent
      },
      {
        path: 'sales-representatives-import', component: SalesRepresentativesImportComponent
      },
      {
        path: 'customers-only-import', component: CustomersOnlyImportComponent
      },
      {
        path: 'customers-contacts-import', component: CustomersContactsImportComponent
      },
      {
        path: 'customers-sites-import', component: CustomersSiteImportComponent
      },
      {
        path: 'vendors-only-import', component: VendorsOnlyImportComponent
      },
      {
        path: 'vendors-contacts-import', component: VendorsContactsImportComponent
      },
      {
        path: 'inventory-count-import', component: InventoryCountImportComponent
      },
      {
        path: 'warehouses-only-import', component: WarehousesOnlyImportComponent
      },
      {
        path: 'warehouses-location-import', component: WarehousesLocationImportComponent
      },
      {
        path: 'shipments-only-import', component: ShipmentsOnlyImportComponent
      },
      {
        path: 'parts-brands-import', component: PartsBrandsImportComponent
      },
      {
        path: 'parts-class-import', component: PartsClassImportComponent
      },
      {
        path: 'parts-families-import', component: PartsFamiliesImportComponent
      },
      {
        path: 'parts-catalogue-import', component: PartsCatalogueImportComponent
      },
      {
        path: 'parts-groups-import', component: PartsGroupsImportComponent
      },
      {
        path: 'customer-parts-alias-import', component: CustomerPartsAliasImportComponent
      },
      {
        path: 'vendors-parts-alias-import', component: VendorsPartsAliasImportComponent
      },
      {
        path: 'balance-sheet-headings-import', component: BalanceSheetHeadingsImportComponent
      },
      {
        path: 'gl-accounts-import', component: GlAccountsImportComponent
      },
      {
        path: 'finalize-configuration-import', component: FinalizeConfigurationImportComponent
      },
      {
        path: 'find-party', component: FindPartyComponent
      },
    ]
  },
  {
    path: 'users',
    children: [
      {
        path: '',
        redirectTo: 'company-information-import',
        pathMatch: 'full',
      },
      {
        path: 'Dashboard', children: [
          { path: '', component: SettingDashboardComponent },
        ]
      },
     
      {
        path: 'create-new-subsidiary', children: [
          { path: '', component: CreateNewSubsidiaryComponent },
        ]
      },
      {
        path: 'documents', children: [
          { path: '', component: CrmNewDocumentComponent },
        ]
      },
      {
        path: 'user-dashboard', children: [
          { path: '', component: UserDashboardComponent },
        ]
      },
      {
        path: 'user-list', children: [
          { path: '', component: UserListComponent },
        ]
      },
      {
        path: 'create-user', children: [
          { path: '', component: CreateUserComponent },
        ]
      },
      {
        path: 'security-group', children: [
          { path: '', component: SecurityGroupComponent },
        ]
      },
      {
        path: 'create-security-group', children: [
          { path: '', component: CreateSecurityGroupComponent },
        ]
      },
      {
        path: 'company-profile', children: [
          { path: '', component: CompanyProfileComponent },
        ]
      },
      {
        path: 'detail-page', children: [
          { path: '', component: PermisionDetailPageComponent },
        ]
      },
      {
        path: 'add-role', children: [
          { path: '', component: AddToRoleComponent },
        ]
      },
      {
        path: 'add-permision-manaully', children: [
          { path: '', component: AddPermisionManuallyComponent },
        ]
      },
      {
        path: 'add-permision-list', children: [
          { path: '', component: AddPermissionListComponent },
        ]
      },
      {
        path: 'new-role', children: [
          { path: '', component: NewRoleTypeComponent },
        ]
      },
      {
        path: 'user-detail-summary', children: [
          { path: '', component: UserDetailSummaryComponent },
        ]
      }
      ,
      {
        path: 'import-user', children: [
          { path: '', component: ImportUserComponent },
        ]
      },
      {
        path: 'company-information-import', component: CompanyInformationImportComponent
      },
      {
        path: 'fiscal-year-import', component: FiscalYearImportComponent
      },
      {
        path: 'email-configuration-import', component: EmailConfigurationImportComponent
      },
      {
        path: 'issue-created-import', component: IssueCreatedImportComponent
      },
      {
        path: 'balance-sheet-import', component: BalanceSheetImportComponent
      },
      {
        path: 'trial-balance-import', component: TrialBalanceImportComponent
      },
      {
        path: 'trial-balance-only-import', component: TrialBalanceOnlyImportComponent
      },
      {
        path: 'cash-flow-import', component: CashFlowImportComponent
      },
      {
        path: 'accounts-group-import', component: AccountsGroupImportComponent
      },
      {
        path: 'defaults-accounts-import', component: DefaultsAccountsImportComponent
      },
      {
        path: 'security-group-import', component: SecurityGroupImportComponent
      },
      {
        path: 'accounts-by-part-family-import', component: AccountsByPartFamilyImportComponent
      },
      {
        path: 'unit-of-measure-import', component: UnitOfMeasureImportComponent
      },
      {
        path: 'costing-method-import', component: CostingMethodImportComponent
      },
      {
        path: 'credit-card-processing-import', component: CreditCardProcessingImportComponent
      },
      {
        path: 'tax-component-import', component: TaxComponentImportComponent
      },
      {
        path: 'taxes-only-import', component: TaxesOnlyImportComponent
      },
      {
        path: 'payments-terms-import', component: PaymentsTermsImportComponent
      },
      {
        path: 'banks-only-import', component: BanksOnlyImportComponent
      },
      {
        path: 'cashiers-only-import', component: CashiersOnlyImportComponent
      },
      {
        path: 'branches-only-import', component: BranchesOnlyImportComponent
      },
      {
        path: 'open-customers-invoices-import', component: OpenCustomersInvoicesImportComponent
      },
      {
        path: 'open-vendors-invoices-import', component: OpenVendorsInvoicesImportComponent
      },
      {
        path: 'gl-account-balances-import', component: GlAccountBalancesImportComponent
      },
      {
        path: 'sales-representatives-import', component: SalesRepresentativesImportComponent
      },
      {
        path: 'customers-only-import', component: CustomersOnlyImportComponent
      },
      {
        path: 'customers-contacts-import', component: CustomersContactsImportComponent
      },
      {
        path: 'customers-sites-import', component: CustomersSiteImportComponent
      },
      {
        path: 'vendors-only-import', component: VendorsOnlyImportComponent
      },
      {
        path: 'vendors-contacts-import', component: VendorsContactsImportComponent
      },
      {
        path: 'inventory-count-import', component: InventoryCountImportComponent
      },
      {
        path: 'warehouses-only-import', component: WarehousesOnlyImportComponent
      },
      {
        path: 'warehouses-location-import', component: WarehousesLocationImportComponent
      },
      {
        path: 'shipments-only-import', component: ShipmentsOnlyImportComponent
      },
      {
        path: 'parts-brands-import', component: PartsBrandsImportComponent
      },
      {
        path: 'parts-class-import', component: PartsClassImportComponent
      },
      {
        path: 'parts-families-import', component: PartsFamiliesImportComponent
      },
      {
        path: 'parts-catalogue-import', component: PartsCatalogueImportComponent
      },
      {
        path: 'parts-groups-import', component: PartsGroupsImportComponent
      },
      {
        path: 'customer-parts-alias-import', component: CustomerPartsAliasImportComponent
      },
      {
        path: 'vendors-parts-alias-import', component: VendorsPartsAliasImportComponent
      },
      {
        path: 'balance-sheet-headings-import', component: BalanceSheetHeadingsImportComponent
      },
      {
        path: 'gl-accounts-import', component: GlAccountsImportComponent
      },
      {
        path: 'finalize-configuration-import', component: FinalizeConfigurationImportComponent
      },
      {
        path: 'find-party', component: FindPartyComponent
      },
    ]
  },
  {
    path: 'accounting',
    children: [
      {
        path: '',
        redirectTo: 'main-accounting',
        pathMatch: 'full',
      },
      {
        path: 'accounting-organization', children: [
          { path: '', component: OrganizationGlSettingNewComponent },
        ]
      },
      {
        path: 'main-accounting', children: [
          { path: '', component: MainAccountingComponent },
        ]
      },
    

      {
        path: 'invoices', children: [
          { path: '', component: InvoicesComponent },
          { path: 'create-invoice', component: CreateInvoicesComponent },
          { path: 'commission', component: CommissionComponent },
          { path: 'invoice-pdf', component: InvoicePdfComponent },
          { path: 'details-invoice', component: DetailedInvoiceSummaryComponent },
        ]
      },
      {
        path: 'payments', children: [
          { path: '', component: PaymentsComponent },
          { path: 'create-payments', component: CreatePaymentsComponent },
          // { path: 'detailed-payment', component: DetailedPaymentComponent },
          { path: 'sale-invoices', component: SaleInvoicesComponent },
          { path: 'purchase-invoices', component: PurchaseInvoicesComponent },
          { path: 'voided', component: VoidedComponent },
          { path: 'received', component: ReceivedComponent },
          { path: 'sent', component: SentComponent },
          { path: 'not-paid', component: NotPaidComponent },
          { path: 'payment-application', component: PaymentApplicationComponent },
          { path: 'not-paid-header', component: NotPaidHeaderComponent },
          { path: 'not-paid-application', component: NotPaidApplicationsComponent },
        ]
      },
      {
        path: 'payment-group', children: [
          { path: '', component: PaymentGroupComponent },
          { path: 'payment-group-details', component: DetailedPaymentGroupsComponent },
        ]
      },
      {
        path: 'biling-accounts', children: [
          { path: '', component: BilingAccountsComponent },
          { path: 'create-billing', component: CreateBillingComponent },
        ]
      },
      {
        path: 'agreements', children: [
          { path: 'create-agreement-item', component: CreateAgreementItemComponent },
          { path: 'additional-items', component: AdditionalItemsComponent },
        ]
      },
      {
        path: 'reports', children: [
          { path: '', component: ReportsComponent },
          { path: 'commission-report', component: CommissionReportComponent },
        ]
      },
    ]
  },


  {
    path: 'settings',
    children: [
      {
        path: '',
        redirectTo: 'main-setting',
        pathMatch: 'full',
      },
      {
        path: 'main-setting', children: [
          { path: '', component: NewPositionTypeComponent },
          { path: 'new-pay-grade', component: NewPayGradeComponent },
        ]
      }
    ]
  },
  {
    path: 'financial',
    children: [
      {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full',
      },
      {
        path: 'Dashboard', children: [

          { path: '', component: FinancialsDashboardComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'main', children: [

          { path: '', component: AccountMainPageComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'main-accounting', children: [
          { path: '', component: MainAccountingComponent },
        ]
      },
      {
        path: 'Accounting', children: [
          { path: '', component: TimesheetViewComponent },
        ]
      },
      {
        path: 'Invoices', children: [
          { path: '', component: AccountingDocumentComponent },
        ]
      },
      {
        path: 'my-portal-create-new-invoice', component: MyPortalNewInvoiceComponent
      },
      {
        path: 'Payments', children: [
          { path: '', component: AddNewContactInformationComponent },
        ]
      },
      {
        path: 'my-portal-create-payments', component: MyPortalCreatePaymentsComponent
      },
      {
        path: 'my-portal-create-invoice', component: MyPortalCreateInvoiceComponent
      },
      {
        path: 'detail-payment-portal-summary', component: DetailPaymentPortalSummaryComponent
      },
      {
        path: 'Payment-group', children: [
          { path: '', component: MainVendorComponent },
        ]
      },
      {
        path: 'Billing-Account', children: [
          { path: '', component: ReportsComponent },
        ]
      },
      {
        path: 'Agreements', children: [
         { path: '', component: TaskListViewComponent },
        ]
      },
      {
        path: 'Commission-Report', children: [
          { path: '', component: CommissionReportComponent },
        ]
      },
      {
        path: 'main-agreements', children: [
         // { path: '', component: FindResponseComponent },
        ]
      },
      {
        path: 'main-Billing-Accounts', children: [
          { path: '', component: IcalendarViewComponent },
        ]
      },
      {
        path: 'main-Fixed-Assets', children: [
          { path: '', component: WonBusinessListingComponent },
        ]
      },
      {
        path: 'main-Invoices', children: [
          { path: '', component: DetailQuotesOrderComponent },
        ]
      },
      {
        path: 'main-Payment', children: [
          { path: '', component: DetailPlanningComponent },
        ]
      },
      {
        path: 'dashboard', children: [

          { path: '', component: ArDashboardComponent, canActivate: [AdminGuard] },
        ]
      },
      {
        path: 'main-payable', children: [
          { path: '', component: MainPayableComponent },
        ]
      },
      {
        path: 'ogcl-report-list', children: [
          { path: '', component: OgclReportListComponent },
        ]
      },
      {
        path: 'gl-payment-method-summary', children: [
          { path: '', component: GlPaymentMethodSummaryComponent },
        ]
      },
      {
        path: 'gl-invoice-list-summary', children: [
          { path: '', component: GlInvoiceListSummaryComponent },
        ]
      },
      {
        path: 'financial-check', children: [
          { path: '', component: FinancialCheckComponent },
        ]
      },
      {
        path: 'financial-check-detail-summary', children: [
          { path: '', component: FinancialCheckDetailSummaryComponent },
        ]
      },
      {
        path: 'ogcl-detail-report-summary', children: [
          { path: '', component: OgclDetailReportSummaryComponent },
        ]
      },
      {
        path: 'main-receivable', children: [
          { path: '', component: MainReceivableComponent },
        ]
      },
      {
        path: 'financial-account', children: [
          { path: '', component: AddFinancailAccountComponent },
        ]
      },
      {
        path: 'main-contract', children: [
          { path: '', component: MainContractComponent },
        ]
      },

      {
        path: 'view-party-profile', children: [
          { path: '', component: ViewPartyProfileComponent },
        ]
      },
      {
        path: 'party-role-type', children: [
          { path: '', component: PartyRoleTypeComponent },
        ]
      },
      {
        path: 'party-add-to-role', children: [
          { path: '', component: PartyAddToRoleComponent },
        ]
      },
      {
        path: 'new-relationship-type', children: [
          { path: '', component: NewRelationshipTypeComponent },
        ]
      },
      {
        path: 'add-other-party-relationship', children: [
          { path: '', component: AddOtherPartyRelationshipComponent },
        ]
      },
      {
        path: 'party-create-identification-number', children: [
          { path: '', component: PartyCreateIdentificationNumberComponent },
        ]
      },
      {
        path: 'edit-party-rates', children: [
          { path: '', component: EditPartyRatesComponent },
        ]
      },
      {
        path: 'add-party-skill', children: [
          { path: '', component: AddPartySkillComponent },
        ]
      },
      {
        path: 'add-party-content', children: [
          { path: '', component: AddPartyContentComponent },
        ]
      },
      {
        path: 'add-party-segment-role', children: [
          { path: '', component: AddPartySegmentRoleComponent },
        ]
      },
      {
        path: 'add-party-resumes', children: [
          { path: '', component: AddPartyResumesComponent },
        ]
      },
      {
        path: 'edit-personal-information', children: [
          { path: '', component: EditPersonalInformationComponent },
        ]
      },
      {
        path: 'add-related-account', children: [
          { path: '', component: AddRelatedAccountComponent },
        ]
      },
      {
        path: 'add-new-contact-information', children: [
          { path: '', component: AddNewContactInformationComponent },
        ]
      },
      {
        path: 'create-user-login', children: [
          { path: '', component: CreateUserLoginComponent },
        ]
      },
      {
        path: 'create-party-attribute', children: [
          { path: '', component: CreatePartyAttributeComponent },
        ]
      },
      {
        path: 'new-party-note', children: [
          { path: '', component: NewPartyNoteComponent },
        ]
      },
      {
        path: 'attach-content', children: [
          { path: '', component: AttachContentComponent },
        ]
      },
      {
        path: 'create-financial-account', children: [
          { path: '', component: CreateFinancialAccountComponent },
        ]
      },
      {
        path: 'detail-financial-summary', children: [
          { path: '', component: DetailFinancialSummaryComponent },
        ]
      },
      {
        path: 'create-financial-role', children: [
          { path: '', component: CreateFinancialRoleComponent },
        ]
      },
      {
        path: 'create-financial-transaction', children: [
          { path: '', component: CreateFinancialTransactionComponent },
        ]
      },
      {
        path: 'bank-reconcl', children: [
          { path: '', component: BankReconciliationComponent },
        ]
      },
      {
        path: 'add-financial-new-deposit-payment', children: [
          { path: '', component: FinancialNewDepositPaymentComponent },
        ]
      },
      {
        path: 'add-financial-new-withdraw-payment', children: [
          { path: '', component: FinancialNewWithdrawPaymentComponent },
        ]
      },
      {
        path: 'new-financial-reconciliation', children: [
          { path: '', component: FinancialNewReconciliationComponent },
        ]
      },
      {
        path: 'reconciliation-detail-summary', children: [
          { path: '', component: DetailSummaryReconciliationComponent },
        ]
      }
      ,
      {
        path: 'fix-assest-list', children: [
          { path: '', component: FixAssestListComponent },
        ]
      }
      ,
      {
        path: 'create-fix-assest', children: [
          { path: '', component: CreateFixAssestComponent },
        ]
      }
      ,
      {
        path: 'fix-assest-summary-page', children: [
          { path: '', component: FixAssestSummaryPageComponent },
        ]
      }
      ,
      {
        path: 'create-product', children: [
          { path: '', component: CreateProductComponent },
        ]
      }
      ,
      {
        path: 'create-fix-assignment', children: [
          { path: '', component: CreateFixAssignmentComponent },
        ]
      }
      ,
      {
        path: 'gl-account-type-payment-type', children: [
          { path: '', component: GlAccountTypePaymentTypeComponent },
        ]
      },
      {
        path: 'create-variance-reason-type', children: [
          { path: '', component: CreateVarianceReasonTypeComponent },
        ]
      },
      {
        path: 'create-credit-card-type', children: [
          { path: '', component: CreateCreditCardTypeComponent },
        ]
      },
      {
        path: 'create-party-gl-account', children: [
          { path: '', component: CreatePartyGlAccountComponent },
        ]
      },
      {
        path: 'create-gl-account-fixed-assest', children: [
          { path: '', component: CreateGlAccountFixedAssestComponent },
        ]
      },
      {
        path: 'create-tax-authority', children: [
          { path: '', component: CreateTaxAuthorityComponent },
        ]
      },
      {
        path: 'create-payment-method-type-account', children: [
          { path: '', component: CreatePaymentMethodTypeAccountComponent },
        ]
      },
      {
        path: 'detail-accounting-summary', children: [
          { path: '', component: DetailAccountingSummaryComponent },
        ]
      },
      {
        path: 'detail-accounting-transaction', children: [
          { path: '', component: DetailAccountingTransactionComponent },
        ]
      },
      {
        path: 'create-accounting-transaction', children: [
          { path: '', component: CreateAccountingTransactionComponent },
        ]
      },
      {
        path: 'unposted-account-transaction', children: [
          { path: '', component: UnpostedAccountTransactionComponent },
        ]
      },
      {
        path: 'create-accounting-quick-transaction', children: [
          { path: '', component: CreateAccountingQuickTransactionComponent },
        ]
      },
      {
        path: 'edit-account-reconcilation', children: [
          { path: '', component: EditAccountReconcilationComponent },
        ]
      },
      {
        path: 'detail-organization-gl-summary', children: [
          { path: '', component: DetailOrganizationGlSummaryComponent },
        ]
      },
      {
        path: 'create-journal', children: [
          { path: '', component: CreateJournalComponent },
        ]
      },
      {
        path: 'create-purchase-invoice-gl-account', children: [
          { path: '', component: CreatePurchaseInvoiceGlAccountComponent },
        ]
      },
      {
        path: 'create-sales-invoice-gl-account', children: [
          { path: '', component: CreateSalesInvoiceGlAccountComponent },
        ]
      },
      {
        path: 'create-fin-gl-account-type', children: [
          { path: '', component: CreateFinGlAccountTypeComponent },
        ]
      },
      {
        path: 'create-gl-product-category-account', children: [
          { path: '', component: CreateGlProductCategoryAccountComponent },
        ]
      },
      {
        path: 'create-gl-time-period', children: [
          { path: '', component: CreateGlTimePeriodComponent },
        ]
      },
      {
        path: 'create-gl-product-account', children: [
          { path: '', component: CreateGlProductAccountComponent },
        ]
      },
      {
        path: 'create-gl-account-type-default', children: [
          { path: '', component: CreateGlAccountTypeDefaultComponent },
        ]
      },
      {
        path: 'create-doc-type', children: [
          { path: '', component: CreateDocTypeComponent },
        ]
      },
      {
        path: 'create-organization-chart-account', children: [
          { path: '', component: CreateOrganizationChartAccountComponent },
        ]
      },
      {
        path: 'create-organization-gl-setting', children: [
          { path: '', component: CreateOrganizationGlSettingComponent },
        ]
      },
      {
        path: 'organization-gl-setting', children: [
          { path: '', component: OrganizationGlSettingComponent },
        ]
      },
      // {
      //   path: 'accounting-organization', children: [
      //     { path: '', component: OrganizationGlSettingNewComponent },
      //   ]
      // },
      {
        path: 'budget-list', children: [
          { path: '', component: BudgetListComponent },
        ]
      },
      {
        path: 'payroll', children: [
          { path: '', component: PayrollComponent },
        ]
      },
      {
        path: 'detail-budget-summary', children: [
          { path: '', component: DetailBudgetSummaryComponent },
        ]
      },
      {
        path: 'create-acc-item', children: [
          { path: '', component: CreateAccItemComponent },
        ]
      },
      {
        path: 'create-acc-role', children: [
          { path: '', component: CreateAccRoleComponent },
        ]
      },
      {
        path: 'create-acc-review', children: [
          { path: '', component: CreateAccReviewComponent },
        ]
      },
      {
        path: 'create-trans-entries', children: [
          { path: '', component: CreateTransEntriesComponent },
        ]
      },
      {
        path: 'create-budget', children: [
          { path: '', component: CreateBudgetComponent },
        ]
      },
      {
        path: 'authorize-transaction', children: [
          { path: '', component: AuthorizeTransactionComponent },
        ]
      },
      {
        path: 'capture', children: [
          { path: '', component: CaptureComponent },
        ]
      },
      {
        path: 'payment-gateway-response', children: [
          { path: '', component: PaymentGatewayResponseComponent },
        ]
      },
      {
        path: 'manual-electronic-transaction', children: [
          { path: '', component: ManualElectronicTransactionComponent },
        ]
      },
      {
        path: 'view-gateway-response', children: [
          { path: '', component: ViewGatewayResponseComponent },
        ]
      },
      {
        path: 'global-gl-setting-list', children: [
          { path: '', component: GlobalGlSettingListComponent },
        ]
      },
      {
        path: 'tax-authority', children: [
          { path: '', component: TaxAuthorityComponent },
        ]
      },
      {
        path: 'detail-tax-authority', children: [
          { path: '', component: DetailTaxAuthorityComponent },
        ]
      },
      {
        path: 'create-tax-category', children: [
          { path: '', component: CreateTaxCategoryComponent },
        ]
      },
      {
        path: 'create-tax-association', children: [
          { path: '', component: CreateTaxAssociationComponent },
        ]
      },
      {
        path: 'create-tax-gl-account', children: [
          { path: '', component: CreateTaxGlAccountComponent },
        ]
      },
      {
        path: 'create-tax-product-rates', children: [
          { path: '', component: CreateTaxProductRatesComponent },
        ]
      },
      {
        path: 'create-tax-party', children: [
          { path: '', component: CreateTaxPartyComponent },
        ]
      },
      {
        path: 'add-tax-auth-detail', children: [
          { path: '', component: AddTaxAuthDetailComponent },
        ]
      },
      {
        path: 'create-global-gl-setting', children: [
          { path: '', component: CreateGlobalSettingComponent },
        ]
      },
      {
        path: 'assign-gl-account', children: [
          { path: '', component: AssignGlAccountComponent },
        ]
      }
      ,
      {
        path: 'gl-account-summary', children: [
          { path: '', component: GlAccountSummaryComponent },
        ]
      }
      ,
      {
        path: 'global-gl-cost', children: [
          { path: '', component: GlobalGlCostComponent },
        ]
      }
      ,
      {
        path: 'create-global-cost', children: [
          { path: '', component: CreateGlobalCostComponent },
        ]
      }
      ,
      {
        path: 'gl-custom-time-period-list', children: [
          { path: '', component: GlCustomTimePeriodListComponent },
        ]
      }
      ,
      {
        path: 'gl-create-custom-time-period', children: [
          { path: '', component: CreateTimePeriodComponent },
        ]
      }
      ,
      {
        path: 'gl-update-custom-time-period', children: [
          { path: '', component: GlUpdateCustomTimePeriodComponent },
        ]
      }
      ,
      {
        path: 'global-gl-rate-list', children: [
          { path: '', component: GlobalGlRateListComponent },
        ]
      }
      ,
      {
        path: 'create-gl-rates', children: [
          { path: '', component: CreateGlRatesComponent },
        ]
      }
      ,
      {
        path: 'gl-payment-method-type', children: [
          { path: '', component: GlPaymentMethodTypeComponent },
        ]
      }
      ,
      {
        path: 'update-payment-method-type', children: [
          { path: '', component: UpdatePaymentMethodTypeComponent },
        ]
      }
      ,
      {
        path: 'gl-invoice-type-list', children: [
          { path: '', component: GlInvoiceTypeListComponent },
        ]
      }
      ,
      {
        path: 'update-invoice-method-type', children: [
          { path: '', component: UpdateInvoiceMethodTypeComponent },
        ]
      }
      ,
      {
        path: 'gl-foreign-exchange-rate', children: [
          { path: '', component: GlForeignExchangeRateComponent },
        ]
      }
      ,
      {
        path: 'create-foreign-exchange-rate', children: [
          { path: '', component: CreateForeignExchangeRateComponent },
        ]
      }
      ,
      {
        path: 'gl-account-category-list', children: [
          { path: '', component: GlAccountCategoryListComponent },
        ]
      }
      ,
      {
        path: 'create-gl-account-category', children: [
          { path: '', component: CreateGlAccountCategoryComponent },
        ]
      }
      ,
      {
        path: 'detail-account-category-summary', children: [
          { path: '', component: DetailAccountCategorySummaryComponent },
        ]
      }
      ,
      {
        path: 'create-account-category-member', children: [
          { path: '', component: CreateAccountCategoryMemberComponent },
        ]
      }
      ,
      {
        path: 'gl-cost-center', children: [
          { path: '', component: GlCostCenterComponent },
        ]
      },
      {
        path: 'gl-assign-list', children: [
          { path: '', component: AssignListGlComponent },
        ]
      },
      {
        path: 'gl-cost-summary', children: [
          { path: '', component: GlCostSummaryComponent },
        ]
      },
      {
        path: 'payable',
        children: [
          {
            path: '',
            redirectTo: 'ar-dashboard',
            pathMatch: 'full',
          },
          {
            path: 'ar-dashboard', children: [
              { path: '', component: ArDashboardComponent, canActivate: [AdminGuard] },

            ]
          },

          {
            path: 'vendors', children: [
              { path: '', component: VendorsComponent },
              { path: 'create-vendors', component: CreateVendorsComponent },
              { path: 'credit-memos', component: CreaditMemosComponent },
              { path: 'create-credit-memos', component: CreateCreditMemosComponent },
            ]
          },
          {
            path: 'list-vendor', children: [
              { path: '', component: ListVendorsComponent },
              { path: 'new-vendor-create', component: NewVendorCreateComponent },
              { path: 'detail-vendor-summary', component: DetailVendorSummaryComponent },
            ]
          },
          {
            path: 'commission-invoice', children: [
              { path: '', component: CommissionInvoiceComponent },
              { path: 'create-commission-invoice', component: CreateNewCommissionComponent },
              { path: 'summary-commission-invoice', component: SummaryCommissionInvoiceComponent },
              { path: 'create-invoice-term', component: CreateNewInvoiceCommissionTermComponent },
              { path: 'create-invoice-item', component: CreateNewCommissionInvoiceItemComponent }
            ]
          },
          {
            path: 'invoices', children: [
              { path: '', component: InvoicesComponent },
              { path: 'create-invoice', component: CreateInvoicesComponent },
              { path: 'commission', component: CommissionComponent },
              { path: 'invoice-pdf', component: InvoicePdfComponent },
              { path: 'detailed-summary', component: DetailedPayableInvoiceSummaryComponent },
              { path: 'create-new-invoice-role', component: CreateNewInvoiceRoleComponent },
              { path: 'create-new-invoice-term', component: CreateNewInvoiceTermComponent },
              { path: 'create-new-invoice-item', component: CreateNewInvoiceItemComponent },
              { path: 'send-email', component: SendEmailComponent },
              { path: 'credit-memo', component: CreditMemoComponent },
              { path: 'create-credit-memo', component: CreateCreditMemoComponent },
              { path: 'update-invoice', component: UpdateInvoiceComponent }
            ]
          },
          {
            path: 'credit-memo', children: [
              { path: '', component: CreditMemoComponent },
              { path: 'create-credit-memo', component: CreateCreditMemoComponent },
              { path: 'summary-credit-memo', component: SummaryCreditMemoComponent },
              { path: 'send-email', component: SendCreditMemoMailComponent },
              { path: 'create-credit-item', component: CreateCreditItemComponent },
              { path: 'create-credit-role', component: CreateCreditRoleComponent },
              { path: 'create-credit-term', component: CreateCreditTermComponent }
            ]
          },
          {
            path: 'payment-group', children: [
              { path: '', component: PayablePaymentGroupComponent },
              { path: 'create-payment-group', component: CreatePaymentGroupComponent },
              { path: 'summary-payment-group', component: SummaryPayablePaymentGroupComponent }
            ]
          },
          {
            path: 'payment-payables', children: [
              { path: '', component: PayablesPaymentComponent },
              { path: 'create-payable-payment', component: CreatePayablePaymentComponent },
              { path: 'update-payable-payment', component: UpdatePayablePaymentComponent },
              { path: 'make-payment', component: MakePaymentComponent },
              { path: 'summary-payable-payment', component: SummaryPayablePaymentComponent },
              { path: 'multiple-vendors', component: MultipleVendorsComponent },
              { path: 'confirm-payments', component: ConfirmPaymentsComponent },
            ]
          },

          {
            path: 'employee-contracts', children: [
              { path: '', component: NewEmployeeContractsComponent },
            ]
          },

          {
            path: 'employees-contracts-com', children: [
              { path: '', component: EmployeeContractsComComponent },
            ]
          },





          {
            path: 'customer-agreeement', children: [
              { path: '', component: CustomerAgreementsComponent },
              { path: 'viewPartner-Competitor', component: PayrollComponent, canActivate: [AdminGuard] },
              { path: 'create-customer-agreeement', component: CreateCustomerAgreementsComponent },
              { path: 'update-customer-agreeement', component: UpdateCustomerAgreementsComponent },
              { path: 'summary-customer-agreeement', component: SummaryCustomerAgreementsComponent },
              { path: 'create-agreement-terms', component: CreateAgrrementTermsComponent },
              { path: 'update-customer-agreeement', component: UpdateCustomerAgreementsComponent },
              { path: 'create-agreement-item', component: CreateNewAgreementItemComponent },
              { path: 'create-agreement-content', component: CreateNewAgreementContentComponent },
              { path: 'create-agreement-role', component: CreateNewAgreementRoleComponent },
              { path: 'agreement-summary-item', component: AgreementSummaryItemComponent, resolve: { agreement: AgreementSummaryItemResolver } },
              { path: 'create-item-promotion', component: CreateNewItemPromotionComponent },
              { path: 'create-item-term', component: CreateNewItemTermComponent },
              { path: 'create-item-product', component: CreateNewItemProductComponent, resolve: { agreement: AgreementSummaryItemResolver } },
              { path: 'create-item-geo', component: CreateNewItemGeoComponent },
              { path: 'create-item-facility', component: CreateNewItemFacilityComponent }
            ]
          },
          {
            path: 'commission-agreeement', children: [
              { path: '', component: CommissionAgreementComponent },
              { path: 'create-commission', component: CreaateCommissionComponent },
              { path: 'commission-invoice', component: CommissionInvoicesComponent },
              { path: 'create-commision-invoice', component: CreateCommissionInvoicesComponent },
              { path: 'commission-balances', component: CommissionBalancesComponent },
              { path: 'commission-statement', component: CommissionStatementComponent },
            ]
          },
          { path: 'payables-report', component: PayablesReportsComponent },
          {
            path: 'billing-account', children: [
              { path: '', component: BillingAccountComponent },
              { path: 'create-billing-account', component: CreateBillingComponent },
              { path: 'summary-billing-account', component: SummaryBillingAccountComponent }
            ]
          }
        ]
      },
      {
        path: 'receiveable',
        children: [
          {
            path: '',
            redirectTo: 'customer-agreeement',
            pathMatch: 'full',
          },
          {
            path: 'invoices', children: [
              { path: '', component: InvoicesComponent },
              { path: 'update-invoice', component: UpdateInvoiceComponent },
              { path: 'create-invoice', component: CreateInvoicesComponent },
              { path: 'send-email', component: SendEmailComponent },
              { path: 'detailed-summary', component: DetailedPayableInvoiceSummaryComponent },
              { path: 'create-new-invoice-role', component: CreateNewInvoiceRoleComponent },
              { path: 'create-new-invoice-term', component: CreateNewInvoiceTermComponent },
              { path: 'create-new-invoice-item', component: CreateNewInvoiceItemComponent },
            ]
          },
          {
            path: 'receive-sale-invoice', children: [
              { path: '', component: ReceivableSaleInvoiceComponent },
              { path: 'create-sale-invoices', component: CreateSaleInvoicesComponent },
              { path: 'service-items', component: InvoicesServiceItemsComponent },
            ]
          },
          {
            path: 'receivales-payment', children: [
              { path: '', component: ReceivalesPaymentComponent },
              { path: 'receive-payment', component: ReceivePaymentComponent },
              { path: 'cash-drawers', component: CashDrawersComponent },
              { path: 'confirm-payments', component: ConfirmPaymentsComponent },
            ]
          },
          {
            path: 'customer-agreeement', children: [
              { path: '', component: CustomerAgreementsComponent },
              { path: 'create-customer-agreeement', component: CreateCustomerAgreementsComponent },
              { path: 'update-customer-agreeement', component: UpdateCustomerAgreementsComponent },
              { path: 'summary-customer-agreeement', component: SummaryCustomerAgreementsComponent },
              { path: 'create-agreement-terms', component: CreateAgrrementTermsComponent },
              { path: 'update-customer-agreeement', component: UpdateCustomerAgreementsComponent },
              { path: 'create-agreement-item', component: CreateNewAgreementItemComponent },
              { path: 'create-agreement-content', component: CreateNewAgreementContentComponent },
              { path: 'create-agreement-role', component: CreateNewAgreementRoleComponent },
              { path: 'agreement-summary-item', component: AgreementSummaryItemComponent, resolve: { agreement: AgreementSummaryItemResolver } },
              { path: 'create-item-promotion', component: CreateNewItemPromotionComponent },
              { path: 'create-item-term', component: CreateNewItemTermComponent },
              { path: 'create-item-product', component: CreateNewItemProductComponent, resolve: { agreement: AgreementSummaryItemResolver } },
              { path: 'create-item-geo', component: CreateNewItemGeoComponent },
              { path: 'create-item-facility', component: CreateNewItemFacilityComponent }
            ]
          },
          {
            path: 'customer-credit', children: [
              { path: '', component: CustomerCreditComponent },
            ]
          },
          {
            path: 'payments', children: [
              { path: '', component: IncomingPaymentComponent },
              { path: 'create-incoming-payment', component: CreateIncomingPaymentComponent },
              { path: 'summary-incoming-payment', component: SummaryIncomingPaymentComponent }
            ]
          },
          {
            path: 'payment-group', children: [
              { path: '', component: ArPaymentGroupComponent },
              { path: 'create-payment-group', component: CreatePaymentGroupComponent },
              { path: 'summary-payment-group', component: SummaryArPaymentGroupComponent }

            ]
          },
          {
            path: 'receive-reports', children: [
              { path: '', component: ReceivableReportsComponent },
            ]
          },


          {
            path: 'payment-payables', children: [
              { path: '', component: PayablesPaymentComponent },
              { path: 'make-payment', component: MakePaymentComponent },
              { path: 'multiple-vendors', component: MultipleVendorsComponent },
              { path: 'confirm-payments', component: ConfirmPaymentsComponent },
            ]
          },
          {
            path: 'commission-agreeement', children: [
              { path: '', component: CommissionAgreementComponent },
              { path: 'create-commission', component: CreaateCommissionComponent },
              { path: 'commission-invoice', component: CommissionInvoicesComponent },
              { path: 'create-commision-invoice', component: CreateCommissionInvoicesComponent },
              { path: 'commission-balances', component: CommissionBalancesComponent },
              { path: 'commission-statement', component: CommissionStatementComponent },
            ]
          },
          { path: 'payables-report', component: PayablesReportsComponent },
        ]
      },
      {
        path: 'main-create-paycheck', children: [
          { path: '', component: MainCreatePaycheckComponent },
        ]
      },
      {
        path: 'main-find-paycheck', children: [
          { path: '', component: MainFindPaycheckComponent },
        ]
      },
      {
        path: 'main-configuration', children: [
          { path: '', component: MainConfigurationComponent },
        ]
      },
      {
        path: 'detailed-paycheck', children: [
          { path: '', component: DetailedPaycheckComponent },
        ]
      },
      {
        path: 'edit-pay-check-form', children: [
          { path: '', component: EditPayCheckFormComponent },
        ]
      },
      {
        path: 'contractor', children: [
          { path: '', component: ContractorComponent },
        ]
      },
      {
        path: 'employees-profile', children: [
          { path: '', component: EmployeesProfileComponent },
        ]
      },
      {
        path: 'main-reports', children: [
          { path: '', component: MainReportsComponent },
        ]
      },
    ]
  },
  {
    path: 'production',
    children: [
      {
        path: '',
        redirectTo: 'main-production',
        pathMatch: 'full',
      },

      {
        path: 'main-production', children: [
          { path: '', component: MainProductionComponent },
        ]
      },
      {
        path: 'detail-manufacturing-rule', children: [
          { path: '', component: DetailManufacturingRuleComponent },
        ]
      },
      {
        path: 'detail-week-list', children: [
          { path: '', component: DetailWeekListComponent },
        ]
      },
      {
        path: 'jobshop-list', children: [
          { path: '', component: JobshopListComponent },
        ]
      },
      {
        path: 'detail-bill-summary', children: [
          { path: '', component: DetailBillSummaryComponent },
        ]
      },
      {
        path: 'create-jobshop', children: [
          { path: '', component: CreateJobshopComponent },
        ]
      },
      {
        path: 'detail-jobshop-summary', children: [
          { path: '', component: DetailJobshopSummaryComponent },
        ]
      },
      {
        path: 'create-jobshop-task', children: [
          { path: '', component: CreateJobshopTaskComponent },
        ]
      },
      {
        path: 'create-jobshop-material', children: [
          { path: '', component: CreateJobshopMaterialComponent },
        ]
      },
      {
        path: 'create-jobshop-fixedasset', children: [
          { path: '', component: CreateJobshopFixedassetComponent },
        ]
      },
      {
        path: 'create-jobshop-parties', children: [
          { path: '', component: CreateJobshopPartiesComponent },
        ]
      },
      {
        path: 'create-jobshop-assocs', children: [
          { path: '', component: CreateJobshopAssocsComponent },
        ]
      },
      {
        path: 'routing', children: [
          { path: '', component: RoutingComponent },
        ]
      },
      {
        path: 'create-routing', children: [
          { path: '', component: CreateRoutingComponent },
        ]
      },
      {
        path: 'detail-routing-summary', children: [
          { path: '', component: DetailRoutingSummaryComponent },
        ]
      },
      {
        path: 'create-routing-product-link', children: [
          { path: '', component: CreateRoutingProductLinkComponent },
        ]
      },
      {
        path: 'create-routing-task-assoc', children: [
          { path: '', component: CreateRoutingTaskAssocComponent },
        ]
      },
      {
        path: 'routing-task', children: [
          { path: '', component: RoutingTaskComponent },
        ]
      },
      {
        path: 'add-route-task', children: [
          { path: '', component: AddRouteTaskComponent },
        ]
      },
      {
        path: 'detail-routing-task-summary', children: [
          { path: '', component: DetailRoutingTaskSummaryComponent },
        ]
      },
      {
        path: 'create-route-task-cost', children: [
          { path: '', component: CreateRouteTaskCostComponent },
        ]
      },
      {
        path: 'create-task-deliverable-product', children: [
          { path: '', component: CreateTaskDeliverableProductComponent },
        ]
      },
      {
        path: 'create-routing-task-fixed-asset', children: [
          { path: '', component: CreateRoutingTaskFixedAssetComponent },
        ]
      },
      {
        path: 'create-routing-task-parties', children: [
          { path: '', component: CreateRoutingTaskPartiesComponent },
        ]
      },
      {
        path: 'create-routing-task-skills', children: [
          { path: '', component: CreateRoutingTaskSkillsComponent },
        ]
      },
      {
        path: 'calender-list', children: [
          { path: '', component: CalenderListComponent },
        ]
      },
      {
        path: 'week-list', children: [
          { path: '', component: WeekListComponent },
        ]
      },
      {
        path: 'create-new-calender', children: [
          { path: '', component: CreateNewCalenderComponent },
        ]
      },
      {
        path: 'create-new-week', children: [
          { path: '', component: CreateNewWeekComponent },
        ]
      },
      {
        path: 'cost-cacls-list', children: [
          { path: '', component: CostCaclsListComponent },
        ]
      },
      {
        path: 'create-cost-cacl', children: [
          { path: '', component: CreateCostCaclComponent },
        ]
      },
      {
        path: 'bill-of-material-list', children: [
          { path: '', component: BillOfMaterialListComponent },
        ]
      },
      {
        path: 'create-product-bom', children: [
          { path: '', component: CreateProductBomComponent },
        ]
      },
      {
        path: 'create-bom-simulation', children: [
          { path: '', component: CreateBomSimulationComponent },
        ]
      },
      {
        path: 'manufacturing-rules', children: [
          { path: '', component: ManufacturingRulesComponent },
        ]
      },
      {
        path: 'create-manufacturing-rules', children: [
          { path: '', component: CreateManufacturingRulesComponent },
        ]
      },
      {
        path: 'mrp-log-list', children: [
          { path: '', component: MrpLogListComponent },
        ]
      },
      {
        path: 'create-run-mrp', children: [
          { path: '', component: CreateRunMrpComponent },
        ]
      },
      {
        path: 'shipment-plan-list', children: [
          { path: '', component: ShipmentPlanListComponent },
        ]
      },
      {
        path: 'create-actual-material', children: [
          { path: '', component: CreateActualMaterialComponent },
        ]
      },
      {
        path: 'production-run-declaration', children: [
          { path: '', component: ProductionRunDeclarationComponent },
        ]
      },
      {
        path: 'detail-calc-cost', children: [
          { path: '', component: DetailCalcCostComponent },
        ]
      },
      {
        path: 'detail-calender', children: [
          { path: '', component: DetailCalenderComponent },
        ]
      },
      {
        path: 'create-exception-day', children: [
          { path: '', component: CreateExceptionDayComponent },
        ]
      },
      {
        path: 'create-exception-week', children: [
          { path: '', component: CreateExceptionWeekComponent },
        ]
      },
      {
        path: 'bom-simulation-list', children: [
          { path: '', component: BomSimulationListComponent },
        ]
      },
      {
        path: 'update-bom-simulation', children: [
          { path: '', component: UpdateProductBomComponent },
        ]
      },
      {
        path: 'update-material', children: [
          { path: '', component: UpdateMaterialUsedComponent },
        ]
      },
      {
        path: 'report', children: [
          { path: '', component: ReportComponent },
        ]
      },
      {
        path: 'add-quantity-produced', children: [
          { path: '', component: AddQuantityProducedComponent },
        ]
      },
    ]
  },

  {
    path: 'ismgr',
    children: [
      {
        path: '',
        redirectTo: 'ismgr-Dashboard',
        pathMatch: 'full',
      },
      {
        path: '', children: [
          { path: 'ismgr-Dashboard', component: DashboardIsmgrComponent },


        ]
      },
      {
        path: 'hardware', children: [
          { path: '', component: HardwareMainComponent },
          { path: 'hardware-detail-page', component: HardwareDetailPageComponent },

        ]
      },

      {
        path: 'software', children: [
          { path: '', component: SoftwareMainComponent },
          { path: 'software-detail-page', component: SoftwareDetailPageComponent },

        ]
      },

    ]
  },

  {
    path: 'commerce',
    children: [
      {
        path: '',
        redirectTo: 'commerce-Dashboard',
        pathMatch: 'full',
      },
      {
        path: '', children: [
          { path: 'commerce-Dashboard', component: CommerceDashboardComponent },


        ]
      },
    ]
  },

  {
    path: 'hub',
    children: [
      {
        path: '',
        redirectTo: 'Dashboard',
        pathMatch: 'full',
      },
      {
        path: 'Dashboard', component: HubDashboardComponent
      },
      {
        path: 'main-party-quick-links', children: [
          { path: '', component: MainPartyQuickLinksComponent },

        ]
      },

      // {
      //   path: 'hardware', children: [
      //     { path: '', component: HardwareMainComponent },
      //     { path: 'hardware-detail-page', component: HardwareDetailPageComponent },

      //   ]
      // },

      // {
      //   path: 'software', children: [
      //     { path: '', component: SoftwareMainComponent },
      //     { path: 'software-detail-page', component: SoftwareDetailPageComponent },

      //   ]
      // },

      {
        path: 'issue-created-import', component: IssueCreatedImportComponent
      },

      {
        path: 'security-group-import', component: SecurityGroupImportComponent
      },
      {
        path: 'email-configuration-import', component: EmailConfigurationImportComponent
      },

      {
        path: 'balance-sheet-headings-import', component: BalanceSheetHeadingsImportComponent
      },

      {
        path: 'balance-sheet-import', component: BalanceSheetImportComponent
      },
      {
        path: 'trial-balance-import', component: TrialBalanceImportComponent
      },
      {
        path: 'trial-balance-only-import', component: TrialBalanceOnlyImportComponent
      },
      {
        path: 'cash-flow-import', component: CashFlowImportComponent
      },
      {
        path: 'gl-accounts-import', component: GlAccountsImportComponent
      },

      {
        path: 'open-customers-invoices-import', component: OpenCustomersInvoicesImportComponent
      },
      {
        path: 'open-vendors-invoices-import', component: OpenVendorsInvoicesImportComponent
      },
      {
        path: 'customers-only-import', component: CustomersOnlyImportComponent
      },
      {
        path: 'customers-sites-import', component: CustomersSiteImportComponent
      },

      {
        path: 'vendors-only-import', component: VendorsOnlyImportComponent
      },
      {
        path: 'inventory-count-import', component: InventoryCountImportComponent
      },

      {
        path: 'warehouses-only-import', component: WarehousesOnlyImportComponent
      },

      {
        path: 'warehouses-location-import', component: WarehousesLocationImportComponent
      },
      {
        path: 'shipments-only-import', component: ShipmentsOnlyImportComponent
      },

      {
        path: 'visits', component: VisitsComponent
      },


      {
        path: 'visitor-summary', component: VisitorDetailSummaryComponent
      },

      {
        path: 'logged-in-users', component: LoggedInUsersComponent
      },
      {
        path: 'classifications', component: ClassificationsComponent
      },

      {
        path: 'address-match-map', component: AddressMatchMapComponent
      },
      {
        path: 'invitation', component: InvitationComponent
      },
      {
        path: 'detail-logged-user', component: DetailLoggedUserComponent
      },
      {
        path: 'import-address', component: ImportAddressComponent
      },
      {
        path: 'detail-address-map', component: DetailAddressMapComponent
      },
      {
        path: 'create-new-party-classification-group', component: CreateNewPartyClassificationGroupComponent
      },

      {
        path: 'detail-invitation-summary', component: DetailInvitationSummaryComponent
      },

      // NEW ROUTING FOR PARTY

      {
        path: 'party-communication-list', component: PartyCommunicationListComponent
      },

      {
        path: 'detail-address-map', component: DetailAddressMapComponent
      },

      {
        path: 'main-party-quick-links', component: MainPartyQuickLinksComponent
      },

      {
        path: 'detail-logged-user', component: DetailLoggedUserComponent
      },
      {
        path: 'find-party', component: FindPartyComponent
      },
      {
        path: 'create-employee', component: CreateEmployeeComponent
      },
      {
        path: 'create-party-classification', component: CreateClassificationPartyComponent
      },
      {
        path: 'create-new-communication', component: CreateNewCommunicationComponent
      },
      {
        path: 'update-password', component: UpdateProfilePasswordComponent
      },
      {
        path: 'party-security-group-list', component: PartySecurityGroupListComponent
      },
      {
        path: 'create-party-security-group', component: CreatePartySecutiyGroupComponent
      },
      {
        path: 'create-party-contact', component: CreatePartyContactComponent
      },
      {
        path: 'create-party-employee-application', component: CreatePartyEmployeApplicationComponent
      },
      {
        path: 'create-product-store', component: CreateProductStoreComponent
      },
      {
        path: 'detail-invitation-summary', component: DetailInvitationSummaryComponent
      },
      {
        path: 'create-party-group-assoc', component: CreatePartyGroupAssocComponent
      },
      {
        path: 'create-party-role-type', component: CreatePartyRoleTypeComponent
      },
      {
        path: 'create-group-classification', component: CreateGroupClassificationComponent
      },
      {
        path: 'detail-party-security', component: DetailSecurityComponent
      },
      {
        path: 'create-protected-view', component: CreateProtectedViewComponent
      },
      {
        path: 'create-party-login', component: CreatePartyLoginComponent
      },
      {
        path: 'party-classification', component: PartyClassificationComponent
      },
      {
        path: 'visitor-summary', component: VisitorDetailSummaryComponent
      },
      {
        path: 'import-address', component: ImportAddressComponent
      },
      {
        path: 'create-address-match-map', component: CreateAddressMatchMapComponent
      },
      {
        path: 'create-customer', component: CreateCustomerComponent
      },
      {
        path: 'create-new-party-classification-group', component: CreateNewPartyClassificationGroupComponent
      },
      {
        path: 'create-new-party-group', component: CreateNewPartyGroupComponent
      },
      {
        path: 'create-new-person', component: CreateNewPersonComponent
      },
      {
        path: 'create-new-security-group', component: CreateNewSecurityGroupComponent
      },
      {
        path: 'create-new-user-login', component: CreateNewUserLoginComponent
      },
      {
        path: 'create-party-invitation', component: CreatePartyInvitationComponent
      },
      {
        path: 'create-prospect', component: CreateProspectComponent
      },
      {
        path: 'my-communications', component: MyCommunicationsComponent
      },
      {
        path: 'communications', component: CommunicationsComponent
      },
      {
        path: 'visits', component: VisitsComponent
      },
      {
        path: 'logged-in-users', component: LoggedInUsersComponent
      },
      {
        path: 'classifications', component: ClassificationsComponent
      },

      {
        path: 'address-match-map', component: AddressMatchMapComponent
      },
      {
        path: 'invitation', component: InvitationComponent
      },

      {
        path: 'profile-party-quick-link', component: ProfilePartyQuickLinkComponent
      },
      {
        path: 'quick-add-shopping', component: QuickAddShoppingComponent
      },
      {
        path: 'edit-shopping-list', component: EditShoppingListComponent
      },
      {
        path: 'create-tax-info', component: CreateTaxInfoComponent
      },
      {
        path: 'create-avs-string', component: CreateAvsStringComponent
      },

      // {
      //   path: 'import-export', component: ImportExportComponent
      // },

      { path: 'find-user-login', component: FindUserLoginComponent },
      { path: 'find-security-group', component: FindSecurityGroupComponent },
      { path: 'cert-issuers', component: CertIssuersComponent },

      // New code for user(settings)Routing
      {
        path: 'user-dashboard', children: [
          { path: '', component: UserDashboardComponent },
        ]
      },
      {
        path: 'user-list', children: [
          { path: '', component: UserListComponent },
        ]
      },
      {
        path: 'create-user', children: [
          { path: '', component: CreateUserComponent },
        ]
      },
      // {
      //   path: 'security-group', children: [
      //     { path: '', component: SecurityGroupComponent },
      //   ]
      // },
      {
        path: 'create-security-group', children: [
          { path: '', component: CreateSecurityGroupComponent },
        ]
      },
      {
        path: 'company-profile', children: [
          { path: '', component: CompanyProfileComponent },
        ]
      },
      {
        path: 'detail-page', children: [
          { path: '', component: PermisionDetailPageComponent },
        ]
      },
      {
        path: 'add-role', children: [
          { path: '', component: AddToRoleComponent },
        ]
      },
      {
        path: 'add-permision-manaully', children: [
          { path: '', component: AddPermisionManuallyComponent },
        ]
      },
      {
        path: 'add-permision-list', children: [
          { path: '', component: AddPermissionListComponent },
        ]
      },
      {
        path: 'new-role', children: [
          { path: '', component: NewRoleTypeComponent },
        ]
      },
      {
        path: 'user-detail-summary', children: [
          { path: '', component: UserDetailSummaryComponent },
        ]
      }
      ,
      {
        path: 'import-user', children: [
          { path: '', component: ImportUserComponent },
        ]
      },
      {
        path: 'company-information-import', component: CompanyInformationImportComponent
      },
      {
        path: 'fiscal-year-import', component: FiscalYearImportComponent
      },
      {
        path: 'email-configuration-import', component: EmailConfigurationImportComponent
      },
      {
        path: 'issue-created-import', component: IssueCreatedImportComponent
      },
      {
        path: 'balance-sheet-import', component: BalanceSheetImportComponent
      },
      {
        path: 'trial-balance-import', component: TrialBalanceImportComponent
      },
      {
        path: 'trial-balance-only-import', component: TrialBalanceOnlyImportComponent
      },
      {
        path: 'cash-flow-import', component: CashFlowImportComponent
      },
      {
        path: 'accounts-group-import', component: AccountsGroupImportComponent
      },
      {
        path: 'defaults-accounts-import', component: DefaultsAccountsImportComponent
      },
      {
        path: 'security-group-import', component: SecurityGroupImportComponent
      },
      {
        path: 'accounts-by-part-family-import', component: AccountsByPartFamilyImportComponent
      },
      {
        path: 'unit-of-measure-import', component: UnitOfMeasureImportComponent
      },
      {
        path: 'costing-method-import', component: CostingMethodImportComponent
      },
      {
        path: 'credit-card-processing-import', component: CreditCardProcessingImportComponent
      },
      {
        path: 'tax-component-import', component: TaxComponentImportComponent
      },
      {
        path: 'taxes-only-import', component: TaxesOnlyImportComponent
      },
      {
        path: 'payments-terms-import', component: PaymentsTermsImportComponent
      },
      {
        path: 'banks-only-import', component: BanksOnlyImportComponent
      },
      {
        path: 'cashiers-only-import', component: CashiersOnlyImportComponent
      },
      {
        path: 'branches-only-import', component: BranchesOnlyImportComponent
      },
      {
        path: 'open-customers-invoices-import', component: OpenCustomersInvoicesImportComponent
      },
      {
        path: 'open-vendors-invoices-import', component: OpenVendorsInvoicesImportComponent
      },
      {
        path: 'gl-account-balances-import', component: GlAccountBalancesImportComponent
      },
      {
        path: 'sales-representatives-import', component: SalesRepresentativesImportComponent
      },
      {
        path: 'customers-only-import', component: CustomersOnlyImportComponent
      },
      {
        path: 'customers-contacts-import', component: CustomersContactsImportComponent
      },
      {
        path: 'customers-sites-import', component: CustomersSiteImportComponent
      },
      {
        path: 'vendors-only-import', component: VendorsOnlyImportComponent
      },
      {
        path: 'vendors-contacts-import', component: VendorsContactsImportComponent
      },
      {
        path: 'inventory-count-import', component: InventoryCountImportComponent
      },
      {
        path: 'warehouses-only-import', component: WarehousesOnlyImportComponent
      },
      {
        path: 'warehouses-location-import', component: WarehousesLocationImportComponent
      },
      {
        path: 'shipments-only-import', component: ShipmentsOnlyImportComponent
      },
      {
        path: 'parts-brands-import', component: PartsBrandsImportComponent
      },
      {
        path: 'parts-class-import', component: PartsClassImportComponent
      },
      {
        path: 'parts-families-import', component: PartsFamiliesImportComponent
      },
      {
        path: 'parts-catalogue-import', component: PartsCatalogueImportComponent
      },
      {
        path: 'parts-groups-import', component: PartsGroupsImportComponent
      },
      {
        path: 'customer-parts-alias-import', component: CustomerPartsAliasImportComponent
      },
      {
        path: 'vendors-parts-alias-import', component: VendorsPartsAliasImportComponent
      },
      {
        path: 'balance-sheet-headings-import', component: BalanceSheetHeadingsImportComponent
      },
      {
        path: 'gl-accounts-import', component: GlAccountsImportComponent
      },
      {
        path: 'finalize-configuration-import', component: FinalizeConfigurationImportComponent
      },

    ]
  },

  {
    path: 'edi',
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
      {
        path: 'overview', children: [
          {
            path: '', component: EdiOverviewComponent
          },
        ]
      },
      {
        path: 'import-export', children: [
          {
            path: '', component: ImportExportComponent
          },
        ]
      },

      {
        path: 'user-dashboard', children: [
          { path: '', component: UserDashboardComponent },
        ]
      },
      {
        path: 'user-list', children: [
          { path: '', component: UserListComponent },
        ]
      },
      {
        path: 'create-user', children: [
          { path: '', component: CreateUserComponent },
        ]
      },
      {
        path: 'security-group', children: [
          { path: '', component: SecurityGroupComponent },
        ]
      },
      {
        path: 'create-security-group', children: [
          { path: '', component: CreateSecurityGroupComponent },
        ]
      },
      {
        path: 'company-profile', children: [
          { path: '', component: CompanyProfileComponent },
        ]
      },
      {
        path: 'detail-page', children: [
          { path: '', component: PermisionDetailPageComponent },
        ]
      },
      {
        path: 'add-role', children: [
          { path: '', component: AddToRoleComponent },
        ]
      },
      {
        path: 'add-permision-manaully', children: [
          { path: '', component: AddPermisionManuallyComponent },
        ]
      },
      {
        path: 'add-permision-list', children: [
          { path: '', component: AddPermissionListComponent },
        ]
      },
      {
        path: 'new-role', children: [
          { path: '', component: NewRoleTypeComponent },
        ]
      },
      {
        path: 'user-detail-summary', children: [
          { path: '', component: UserDetailSummaryComponent },
        ]
      }
      ,
      {
        path: 'import-user', children: [
          { path: '', component: ImportUserComponent },
        ]
      },
      {
        path: 'company-information-import', component: CompanyInformationImportComponent
      },
      {
        path: 'fiscal-year-import', component: FiscalYearImportComponent
      },
      {
        path: 'email-configuration-import', component: EmailConfigurationImportComponent
      },
      {
        path: 'issue-created-import', component: IssueCreatedImportComponent
      },
      {
        path: 'balance-sheet-import', component: BalanceSheetImportComponent
      },
      {
        path: 'trial-balance-import', component: TrialBalanceImportComponent
      },
      {
        path: 'trial-balance-only-import', component: TrialBalanceOnlyImportComponent
      },
      {
        path: 'cash-flow-import', component: CashFlowImportComponent
      },
      {
        path: 'accounts-group-import', component: AccountsGroupImportComponent
      },
      {
        path: 'defaults-accounts-import', component: DefaultsAccountsImportComponent
      },
      {
        path: 'security-group-import', component: SecurityGroupImportComponent
      },
      {
        path: 'accounts-by-part-family-import', component: AccountsByPartFamilyImportComponent
      },
      {
        path: 'unit-of-measure-import', component: UnitOfMeasureImportComponent
      },
      {
        path: 'costing-method-import', component: CostingMethodImportComponent
      },
      {
        path: 'credit-card-processing-import', component: CreditCardProcessingImportComponent
      },
      {
        path: 'tax-component-import', component: TaxComponentImportComponent
      },
      {
        path: 'taxes-only-import', component: TaxesOnlyImportComponent
      },
      {
        path: 'payments-terms-import', component: PaymentsTermsImportComponent
      },
      {
        path: 'banks-only-import', component: BanksOnlyImportComponent
      },
      {
        path: 'cashiers-only-import', component: CashiersOnlyImportComponent
      },
      {
        path: 'branches-only-import', component: BranchesOnlyImportComponent
      },
      {
        path: 'open-customers-invoices-import', component: OpenCustomersInvoicesImportComponent
      },
      {
        path: 'open-vendors-invoices-import', component: OpenVendorsInvoicesImportComponent
      },
      {
        path: 'gl-account-balances-import', component: GlAccountBalancesImportComponent
      },
      {
        path: 'sales-representatives-import', component: SalesRepresentativesImportComponent
      },
      {
        path: 'customers-only-import', component: CustomersOnlyImportComponent
      },
      {
        path: 'customers-contacts-import', component: CustomersContactsImportComponent
      },
      {
        path: 'customers-sites-import', component: CustomersSiteImportComponent
      },
      {
        path: 'vendors-only-import', component: VendorsOnlyImportComponent
      },
      {
        path: 'vendors-contacts-import', component: VendorsContactsImportComponent
      },
      {
        path: 'inventory-count-import', component: InventoryCountImportComponent
      },
      {
        path: 'warehouses-only-import', component: WarehousesOnlyImportComponent
      },
      {
        path: 'warehouses-location-import', component: WarehousesLocationImportComponent
      },
      {
        path: 'shipments-only-import', component: ShipmentsOnlyImportComponent
      },
      {
        path: 'parts-brands-import', component: PartsBrandsImportComponent
      },
      {
        path: 'parts-class-import', component: PartsClassImportComponent
      },
      {
        path: 'parts-families-import', component: PartsFamiliesImportComponent
      },
      {
        path: 'parts-catalogue-import', component: PartsCatalogueImportComponent
      },
      {
        path: 'parts-groups-import', component: PartsGroupsImportComponent
      },
      {
        path: 'customer-parts-alias-import', component: CustomerPartsAliasImportComponent
      },
      {
        path: 'vendors-parts-alias-import', component: VendorsPartsAliasImportComponent
      },
      {
        path: 'balance-sheet-headings-import', component: BalanceSheetHeadingsImportComponent
      },
      {
        path: 'gl-accounts-import', component: GlAccountsImportComponent
      },
      {
        path: 'finalize-configuration-import', component: FinalizeConfigurationImportComponent
      },


    ]
  },

  {
    path: 'security',
    children: [
      {
        path: '',
        redirectTo: 'security-dashboard',
        pathMatch: 'full',
      },
      { path: 'security-dashboard', component: DashboardSecurityComponent },
      { path: 'find-user-login', component: FindUserLoginComponent },
      { path: 'find-security-group', component: FindSecurityGroupComponent },
      { path: 'cert-issuers', component: CertIssuersComponent },
      {
        path: 'issue-created-import', component: IssueCreatedImportComponent
      },

      {
        path: 'security-group-import', component: SecurityGroupImportComponent
      },
      {
        path: 'email-configuration-import', component: EmailConfigurationImportComponent
      },

      {
        path: 'balance-sheet-headings-import', component: BalanceSheetHeadingsImportComponent
      },

      {
        path: 'balance-sheet-import', component: BalanceSheetImportComponent
      },
      {
        path: 'trial-balance-import', component: TrialBalanceImportComponent
      },
      {
        path: 'trial-balance-only-import', component: TrialBalanceOnlyImportComponent
      },
      {
        path: 'cash-flow-import', component: CashFlowImportComponent
      },
      {
        path: 'gl-accounts-import', component: GlAccountsImportComponent
      },

      {
        path: 'open-customers-invoices-import', component: OpenCustomersInvoicesImportComponent
      },
      {
        path: 'open-vendors-invoices-import', component: OpenVendorsInvoicesImportComponent
      },
      {
        path: 'customers-only-import', component: CustomersOnlyImportComponent
      },
      {
        path: 'customers-sites-import', component: CustomersSiteImportComponent
      },

      {
        path: 'vendors-only-import', component: VendorsOnlyImportComponent
      },
      {
        path: 'inventory-count-import', component: InventoryCountImportComponent
      },

      {
        path: 'warehouses-only-import', component: WarehousesOnlyImportComponent
      },

      {
        path: 'warehouses-location-import', component: WarehousesLocationImportComponent
      },
      {
        path: 'shipments-only-import', component: ShipmentsOnlyImportComponent
      },

      {
        path: 'visits', component: VisitsComponent
      },


      {
        path: 'visitor-summary', component: VisitorDetailSummaryComponent
      },

      {
        path: 'logged-in-users', component: LoggedInUsersComponent
      },
      {
        path: 'classifications', component: ClassificationsComponent
      },

      {
        path: 'address-match-map', component: AddressMatchMapComponent
      },
      {
        path: 'invitation', component: InvitationComponent
      },
      {
        path: 'detail-logged-user', component: DetailLoggedUserComponent
      },
      {
        path: 'import-address', component: ImportAddressComponent
      },
      {
        path: 'detail-address-map', component: DetailAddressMapComponent
      },
      {
        path: 'create-new-party-classification-group', component: CreateNewPartyClassificationGroupComponent
      },

      {
        path: 'detail-invitation-summary', component: DetailInvitationSummaryComponent
      },

      // NEW ROUTING FOR PARTY

      {
        path: 'party-communication-list', component: PartyCommunicationListComponent
      },

      {
        path: 'detail-address-map', component: DetailAddressMapComponent
      },

      {
        path: 'main-party-quick-links', component: MainPartyQuickLinksComponent
      },

      {
        path: 'detail-logged-user', component: DetailLoggedUserComponent
      },
      {
        path: 'find-party', component: FindPartyComponent
      },
      {
        path: 'create-employee', component: CreateEmployeeComponent
      },
      {
        path: 'create-party-classification', component: CreateClassificationPartyComponent
      },
      {
        path: 'create-new-communication', component: CreateNewCommunicationComponent
      },
      {
        path: 'update-password', component: UpdateProfilePasswordComponent
      },
      {
        path: 'party-security-group-list', component: PartySecurityGroupListComponent
      },
      {
        path: 'create-party-security-group', component: CreatePartySecutiyGroupComponent
      },
      {
        path: 'create-party-contact', component: CreatePartyContactComponent
      },
      {
        path: 'create-party-employee-application', component: CreatePartyEmployeApplicationComponent
      },
      {
        path: 'create-product-store', component: CreateProductStoreComponent
      },
      {
        path: 'detail-invitation-summary', component: DetailInvitationSummaryComponent
      },
      {
        path: 'create-party-group-assoc', component: CreatePartyGroupAssocComponent
      },
      {
        path: 'create-party-role-type', component: CreatePartyRoleTypeComponent
      },
      {
        path: 'create-group-classification', component: CreateGroupClassificationComponent
      },
      {
        path: 'detail-party-security', component: DetailSecurityComponent
      },
      {
        path: 'create-protected-view', component: CreateProtectedViewComponent
      },
      {
        path: 'create-party-login', component: CreatePartyLoginComponent
      },
      {
        path: 'party-classification', component: PartyClassificationComponent
      },
      {
        path: 'visitor-summary', component: VisitorDetailSummaryComponent
      },
      {
        path: 'import-address', component: ImportAddressComponent
      },
      {
        path: 'create-address-match-map', component: CreateAddressMatchMapComponent
      },
      {
        path: 'create-customer', component: CreateCustomerComponent
      },
      {
        path: 'create-new-party-classification-group', component: CreateNewPartyClassificationGroupComponent
      },
      {
        path: 'create-new-party-group', component: CreateNewPartyGroupComponent
      },
      {
        path: 'create-new-person', component: CreateNewPersonComponent
      },
      {
        path: 'create-new-security-group', component: CreateNewSecurityGroupComponent
      },
      {
        path: 'create-new-user-login', component: CreateNewUserLoginComponent
      },
      {
        path: 'create-party-invitation', component: CreatePartyInvitationComponent
      },
      {
        path: 'create-prospect', component: CreateProspectComponent
      },
      {
        path: 'my-communications', component: MyCommunicationsComponent
      },
      {
        path: 'communications', component: CommunicationsComponent
      },
      {
        path: 'visits', component: VisitsComponent
      },
      {
        path: 'logged-in-users', component: LoggedInUsersComponent
      },
      {
        path: 'classifications', component: ClassificationsComponent
      },

      {
        path: 'address-match-map', component: AddressMatchMapComponent
      },
      {
        path: 'invitation', component: InvitationComponent
      },

      {
        path: 'profile-party-quick-link', component: ProfilePartyQuickLinkComponent
      },
      {
        path: 'quick-add-shopping', component: QuickAddShoppingComponent
      },
      {
        path: 'edit-shopping-list', component: EditShoppingListComponent
      },
      {
        path: 'create-tax-info', component: CreateTaxInfoComponent
      },
      {
        path: 'create-avs-string', component: CreateAvsStringComponent
      },


    ]
  },


  {
    path: 'partyQuickLink',
    children: [
      {
        path: '',
        redirectTo: 'party-dashboard',
        pathMatch: 'full',
      },
      {
        path: 'party-dashboard', component: PartyDashboardComponent
      },
      {
        path: 'party-communication-list', component: PartyCommunicationListComponent
      },

      {
        path: 'detail-address-map', component: DetailAddressMapComponent
      },

      {
        path: 'main-party-quick-links', component: MainPartyQuickLinksComponent
      },

      {
        path: 'detail-logged-user', component: DetailLoggedUserComponent
      },
      {
        path: 'find-party', component: FindPartyComponent
      },
      {
        path: 'create-employee', component: CreateEmployeeComponent
      },
      {
        path: 'create-party-classification', component: CreateClassificationPartyComponent
      },
      {
        path: 'create-new-communication', component: CreateNewCommunicationComponent
      },
      {
        path: 'update-password', component: UpdateProfilePasswordComponent
      },
      {
        path: 'party-security-group-list', component: PartySecurityGroupListComponent
      },
      {
        path: 'create-party-security-group', component: CreatePartySecutiyGroupComponent
      },
      {
        path: 'create-party-contact', component: CreatePartyContactComponent
      },
      {
        path: 'create-party-employee-application', component: CreatePartyEmployeApplicationComponent
      },
      {
        path: 'create-product-store', component: CreateProductStoreComponent
      },
      {
        path: 'detail-invitation-summary', component: DetailInvitationSummaryComponent
      },
      {
        path: 'create-party-group-assoc', component: CreatePartyGroupAssocComponent
      },
      {
        path: 'create-party-role-type', component: CreatePartyRoleTypeComponent
      },
      {
        path: 'create-group-classification', component: CreateGroupClassificationComponent
      },
      {
        path: 'detail-party-security', component: DetailSecurityComponent
      },
      {
        path: 'create-protected-view', component: CreateProtectedViewComponent
      },
      {
        path: 'create-party-login', component: CreatePartyLoginComponent
      },
      {
        path: 'party-classification', component: PartyClassificationComponent
      },
      {
        path: 'visitor-summary', component: VisitorDetailSummaryComponent
      },
      {
        path: 'import-address', component: ImportAddressComponent
      },
      {
        path: 'create-address-match-map', component: CreateAddressMatchMapComponent
      },
      {
        path: 'create-customer', component: CreateCustomerComponent
      },
      {
        path: 'create-new-party-classification-group', component: CreateNewPartyClassificationGroupComponent
      },
      {
        path: 'create-new-party-group', component: CreateNewPartyGroupComponent
      },
      {
        path: 'create-new-person', component: CreateNewPersonComponent
      },
      {
        path: 'create-new-security-group', component: CreateNewSecurityGroupComponent
      },
      {
        path: 'create-new-user-login', component: CreateNewUserLoginComponent
      },
      {
        path: 'create-party-invitation', component: CreatePartyInvitationComponent
      },
      {
        path: 'create-prospect', component: CreateProspectComponent
      },
      {
        path: 'my-communications', component: MyCommunicationsComponent
      },
      {
        path: 'communications', component: CommunicationsComponent
      },
      {
        path: 'visits', component: VisitsComponent
      },
      {
        path: 'logged-in-users', component: LoggedInUsersComponent
      },
      {
        path: 'classifications', component: ClassificationsComponent
      },

      {
        path: 'address-match-map', component: AddressMatchMapComponent
      },
      {
        path: 'invitation', component: InvitationComponent
      },
      // {
      //   path: 'import-export', component: ImportExportComponent
      // },
      {
        path: 'profile-party-quick-link', component: ProfilePartyQuickLinkComponent
      },
      {
        path: 'quick-add-shopping', component: QuickAddShoppingComponent
      },
      {
        path: 'edit-shopping-list', component: EditShoppingListComponent
      },
      {
        path: 'create-tax-info', component: CreateTaxInfoComponent
      },
      {
        path: 'create-avs-string', component: CreateAvsStringComponent
      },



    ]
  },


  {
    path: 'vendor',
    children: [
      {
        path: '',
        redirectTo: 'Dashboard',
        pathMatch: 'full',
      },
      { path: 'Dashboard', component: VendorDashboardComponent },
      { path: 'main-vendor', component: MainVendorComponent },
      { path: 'list-vendor', component: ListVendorsComponent },
      { path: 'new-vendor-create', component: NewVendorCreateComponent },
      { path: 'detail-vendor-summary', component: DetailVendorSummaryComponent },
      {
        path: 'Suppliers', children: [
          { path: '', component: SuppliersVendorsComponent },
          { path: 'viewSupplier', component: DetailSupplierVendorsComponent },
        ]
      },

    ]
  },





  // {
  //   path: 'ismgr',
  //   children: [
  //     {
  //       path: '',
  //       redirectTo: 'hardware',
  //       pathMatch: 'full',
  //     },
  //     {
  //       path: 'hardware', children: [
  //         { path: '', component: HardwareMainComponent },
  //         { path: 'hardware-detail-page', component: HardwareDetailPageComponent },

  //       ]
  //     },

  //     {
  //       path: 'software', children: [
  //         { path: '', component: SoftwareMainComponent },
  //         { path: 'software-detail-page', component: SoftwareDetailPageComponent },

  //       ]
  //     },

  //   ]
  // },



  {
    path: 'Reports',
    children: [
      {
        path: '',
        redirectTo: 'Dashboard',
        pathMatch: 'full',
      },
      {
        path: 'Dashboard', children: [
          { path: '', component: ReportsDashboardComponent },
        ]
      },

      {
        path: 'report-summary', children: [
          { path: '', component: ReportMainComponent },
        ]
      },
    ]
  },

  {
    path: 'birt',
    children: [
      {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full',
      },
      {
        path: 'main', children: [
          { path: '', component: BirtMainComponent },
          { path: 'send-format-through-mail', component: SendFormatThroughMailComponent },

        ]
      },
      {
        path: 'flexible-reports', children: [
          { path: '', component: FlexibleMainComponent },
          { path: 'update-report', component: UpdateReportComponent },

        ]
      },

    ]
  },

  {
    path: 'business-intelligence',
    children: [
      {
        path: '',
        redirectTo: 'reports-dashboard',
        pathMatch: 'full',
      },
      {
        path: 'reports-dashboard', children: [
          { path: '', component: ReportsDashboardComponent },
        ]
      },
      {
        path: 'main', children: [
          { path: '', component: MainBiComponent },
        ]
      },
      {
        path: 'report-builder', children: [
          { path: '', component: ReportBuilderComponent },

        ]
      },
      {
        path: 'detail-report-builder', children: [
          { path: '', component: DetailReportBuilderComponent },

        ]
      },
      {
        path: 'report-summary', children: [
          { path: '', component: ReportMainComponent },
        ]
      },

    ]
  },

  {
    path: 'content',
    children: [
      {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full',
      },
      {
        path: 'main', children: [
          { path: '', component: WebsiteMainComponent },
          { path: 'website-detail-page', component: MainDetailPageComponent },

        ]
      },

      {
        path: 'webSite', children: [
          { path: '', component: WebsiteListComponent },
          { path: 'website-detail-page', component: MainDetailPageComponent },

        ]
      },

      {
        path: 'survey', children: [
          { path: '', component: SurveyComponent },
          { path: 'survey-detail-page', component: SurveyDetailPageComponent },

        ]
      },

      {
        path: 'forum', children: [
          { path: '', component: ForumComponent },
          { path: 'forum-detail-page', component: ForumDetailPageComponent },
          { path: 'msg-detail-page', component: MessageDetailPageComponent },
          { path: 'forums', component: ForumsPageComponent },
          { path: 'forum-purposes', component: ForumPurposesComponent },
          { path: 'forum-roles', component: ForumRolesComponent },

        ]
      },

      {
        path: 'blog', children: [
          { path: '', component: BlogComponent },
          { path: 'blog-detail-page', component: BlogDetailPageComponent },
          { path: 'blog-articles-detail-page', component: BlogsArticlesDetailPageComponent },

        ]
      },

      {
        path: 'content-page', children: [
          { path: '', component: ContentComponent },
          { path: 'find-detail', component: FindDetailComponent },
          { path: 'content-detail-page', component: ContentDetailPageComponent },

        ]
      },

      {
        path: 'data-resource', children: [
          { path: '', component: DataResourceComponent },
          { path: 'dataresource-detail-page', component: DataresourceDetailPageComponent },
          { path: 'dataresource-navigate-page', component: DataresourceFindComponent },
        ]
      },

      {
        path: 'content-setup', children: [
          { path: '', component: ContentSetupComponent },
          { path: 'edit-content-purpose-operation', component: EditContentPurposeOperationComponent },
          { path: 'edit-content-operation', component: EditContentOperationComponent },
          { path: 'edit-content-assoication-predicate', component: EditContentAssociationPredicateComponent },
          { path: 'edit-content-type-attribute', component: EditContentTypeAttributeComponent },
          { path: 'edit-content-purpose-type', component: EditContentPurposeTypeComponent },
          { path: 'edit-content-association-type', component: EditContentAssociationTypeComponent },
          { path: 'permission', component: ContentPermissionComponent },
        ]
      },

      {
        path: 'data-resource-setup', children: [
          { path: '', component: DataresourceSetupComponent },
          { path: 'character-set', component: CharacterSetComponent },
          { path: 'category', component: CategoryComponent },
          { path: 'type-attribute', component: TypeAttributeComponent },
          { path: 'file-ext', component: FileExtComponent },
          { path: 'metadata-predicate', component: MetaDataPredicateComponent },
          { path: 'mimetype', component: MimeTypeComponent },
          { path: 'mimetype-template', component: MimeTypeTemplateComponent },

        ]
      },

      {
        path: 'template', children: [
          { path: '', component: TemplateComponent },
        ]
      },
      {
        path: 'import-export', component: ImportExportComponent
      },
      {
        path: 'cms', children: [
          { path: '', component: ContentCmsComponent },
          { path: 'cms-summary', component: CmsSummaryComponent },
          { path: 'subsites', component: SubSitesComponent },
          { path: 'index', component: IndexComponent },
          { path: 'find', component: FindComponent },
          { path: 'search-products', component: SearchProductsComponent },

        ]
      },

      {
        path: 'compDoc', children: [
          { path: '', component: CompDocComponent },
          { path: 'view-tree', component: CompdocDetailPageComponent },
          { path: 'edit', component: EditCompdocPageComponent },
          { path: 'approvals', component: ApprovalsPageComponent },
          { path: 'revisions', component: RevisionPageComponent },
          { path: 'new-root-compdoc-template', component: NewRootPageComponent },
          { path: 'view-waiting-approvals', component: ViewWaitingApprovalsComponent },

        ]
      },

      {
        path: 'import-export', children: [
          {
            path: '', component: ImportExportComponent
          },
        ]
      },

      {
        path: 'user-dashboard', children: [
          { path: '', component: UserDashboardComponent },
        ]
      },
      {
        path: 'user-list', children: [
          { path: '', component: UserListComponent },
        ]
      },
      {
        path: 'create-user', children: [
          { path: '', component: CreateUserComponent },
        ]
      },
      {
        path: 'security-group', children: [
          { path: '', component: SecurityGroupComponent },
        ]
      },
      {
        path: 'create-security-group', children: [
          { path: '', component: CreateSecurityGroupComponent },
        ]
      },
      {
        path: 'company-profile', children: [
          { path: '', component: CompanyProfileComponent },
        ]
      },
      {
        path: 'detail-page', children: [
          { path: '', component: PermisionDetailPageComponent },
        ]
      },
      {
        path: 'add-role', children: [
          { path: '', component: AddToRoleComponent },
        ]
      },
      {
        path: 'add-permision-manaully', children: [
          { path: '', component: AddPermisionManuallyComponent },
        ]
      },
      {
        path: 'add-permision-list', children: [
          { path: '', component: AddPermissionListComponent },
        ]
      },
      {
        path: 'new-role', children: [
          { path: '', component: NewRoleTypeComponent },
        ]
      },
      {
        path: 'user-detail-summary', children: [
          { path: '', component: UserDetailSummaryComponent },
        ]
      }
      ,
      {
        path: 'import-user', children: [
          { path: '', component: ImportUserComponent },
        ]
      },
      {
        path: 'company-information-import', component: CompanyInformationImportComponent
      },
      {
        path: 'fiscal-year-import', component: FiscalYearImportComponent
      },
      {
        path: 'email-configuration-import', component: EmailConfigurationImportComponent
      },
      {
        path: 'issue-created-import', component: IssueCreatedImportComponent
      },
      {
        path: 'balance-sheet-import', component: BalanceSheetImportComponent
      },
      {
        path: 'trial-balance-import', component: TrialBalanceImportComponent
      },
      {
        path: 'trial-balance-only-import', component: TrialBalanceOnlyImportComponent
      },
      {
        path: 'cash-flow-import', component: CashFlowImportComponent
      },
      {
        path: 'accounts-group-import', component: AccountsGroupImportComponent
      },
      {
        path: 'defaults-accounts-import', component: DefaultsAccountsImportComponent
      },
      {
        path: 'security-group-import', component: SecurityGroupImportComponent
      },
      {
        path: 'accounts-by-part-family-import', component: AccountsByPartFamilyImportComponent
      },
      {
        path: 'unit-of-measure-import', component: UnitOfMeasureImportComponent
      },
      {
        path: 'costing-method-import', component: CostingMethodImportComponent
      },
      {
        path: 'credit-card-processing-import', component: CreditCardProcessingImportComponent
      },
      {
        path: 'tax-component-import', component: TaxComponentImportComponent
      },
      {
        path: 'taxes-only-import', component: TaxesOnlyImportComponent
      },
      {
        path: 'payments-terms-import', component: PaymentsTermsImportComponent
      },
      {
        path: 'banks-only-import', component: BanksOnlyImportComponent
      },
      {
        path: 'cashiers-only-import', component: CashiersOnlyImportComponent
      },
      {
        path: 'branches-only-import', component: BranchesOnlyImportComponent
      },
      {
        path: 'open-customers-invoices-import', component: OpenCustomersInvoicesImportComponent
      },
      {
        path: 'open-vendors-invoices-import', component: OpenVendorsInvoicesImportComponent
      },
      {
        path: 'gl-account-balances-import', component: GlAccountBalancesImportComponent
      },
      {
        path: 'sales-representatives-import', component: SalesRepresentativesImportComponent
      },
      {
        path: 'customers-only-import', component: CustomersOnlyImportComponent
      },
      {
        path: 'customers-contacts-import', component: CustomersContactsImportComponent
      },
      {
        path: 'customers-sites-import', component: CustomersSiteImportComponent
      },
      {
        path: 'vendors-only-import', component: VendorsOnlyImportComponent
      },
      {
        path: 'vendors-contacts-import', component: VendorsContactsImportComponent
      },
      {
        path: 'inventory-count-import', component: InventoryCountImportComponent
      },
      {
        path: 'warehouses-only-import', component: WarehousesOnlyImportComponent
      },
      {
        path: 'warehouses-location-import', component: WarehousesLocationImportComponent
      },
      {
        path: 'shipments-only-import', component: ShipmentsOnlyImportComponent
      },
      {
        path: 'parts-brands-import', component: PartsBrandsImportComponent
      },
      {
        path: 'parts-class-import', component: PartsClassImportComponent
      },
      {
        path: 'parts-families-import', component: PartsFamiliesImportComponent
      },
      {
        path: 'parts-catalogue-import', component: PartsCatalogueImportComponent
      },
      {
        path: 'parts-groups-import', component: PartsGroupsImportComponent
      },
      {
        path: 'customer-parts-alias-import', component: CustomerPartsAliasImportComponent
      },
      {
        path: 'vendors-parts-alias-import', component: VendorsPartsAliasImportComponent
      },
      {
        path: 'balance-sheet-headings-import', component: BalanceSheetHeadingsImportComponent
      },
      {
        path: 'gl-accounts-import', component: GlAccountsImportComponent
      },
      {
        path: 'finalize-configuration-import', component: FinalizeConfigurationImportComponent
      },

    ]
  },


  {
    path: 'fsm',
    children: [
      {
        path: '',
        redirectTo: 'Overview',
        pathMatch: 'full',
      },
      {
        path: 'Overview', children: [
          { path: '', component: FSMDashboardComponent },
        ]
      },
      {
        path: 'main', children: [
          { path: '', component: FiledserviceComponent },
        ]
      },
      {
        path: 'workorder-summary', children: [
          { path: '', component: WorkOrderSummaryComponent },
        ]
      },
      {
        path: 'fsm-setting', children: [
          {
            path: '',
            redirectTo: 'work-order-priorites',
            pathMatch: 'full',
          },
          { path: 'work-order-priorites', component: WorkOrdersPrioritiesComponent },
          { path: 'payment-processing', component: PaymentProcessingComponent },
          { path: 'work-order-skill', component: WorkOrdersSkillsComponent },
        ]
      },


      {
        path: 'newWorkOrder', children: [
          { path: '', component: NewworkorderComponent },


        ]
      },

      {
        path: 'problem-detail', children: [
          { path: '', component: ProblemDetailComponent },


        ]
      },

      {
        path: 'customer', children: [
          { path: '', component: CustomerComponent },
          { path: 'summary', component: CustomerSummaryComponent },


        ]
      },

      {
        path: 'reports', children: [
          { path: '', component: FsmreportsComponent },
        ]
      },

      {
        path: 'invoices', children: [
          { path: '', component: FsminvoicesComponent },
        ]
      },

      {
        path: 'people', children: [
          { path: '', component: FsmpeopleComponent },
        ]
      },

      {
        path: 'timesheet', children: [
          {
            path: '',
            redirectTo: 'timesheet',
            pathMatch: 'full',
          },
          { path: '', component: FsmTimeSheetComponent },
          { path: 'summary', component: TimesheetSummaryComponent },

        ]
      },
      {
        path: 'fsm-technician', children: [
          { path: '', component: FsmTechnicianComponent },
        ]
      },
      {
        path: 'fsm-technicin-reset', children: [
          { path: '', component: TechnicianResetComponent },
        ]
      },
      {
        path: 'fsm-tech-invoice', children: [
          { path: '', component: FsmTechInvoiveComponent },
        ]
      },
      {
        path: 'fsm-customer', children: [
          { path: '', component: CustomerDetailComponent },
          { path: 'customer-summary-page', component: CustomerSummaryPageComponent },
        ]
      },
      {
        path: 'fsm-password-reset', children: [
          { path: '', component: CustomerResetComponent },
        ]
      },
      {
        path: 'technician', children: [
          { path: '', component: StaffingComponent },
        ]
      },

      {
        path: 'fsm-contract', children: [
          { path: '', component: FsmContractComponent },
        ]
      },
      { 
        path: 'summary-customer-agreeement', component: SummaryCustomerAgreementsComponent 
      },
      
      { 
        path: 'asset-maintenance', component: TechnicianResetComponent 
      },
      {
        path: 'fixed-asset',
        children: [
          {
            path: '',
            redirectTo: 'fixed-asset',
            pathMatch: 'full',
          },
          { path: 'edit-fixed-asset-maint', component: FsmCustomerComponent  },
        ]
      },



      // {
      //   path: 'staffing', children: [
      //     { path: '', component: StaffingComponent },
      //   ]
      // },
      // {
      //   path: 'fsmSetting',
      //   children: [
      //     {
      //       path: '',
      //       redirectTo: 'work-order',
      //       pathMatch: 'full',
      //     },


      //     { path: 'work-order', children:[
      //       { path:'',component: FsmSettingComponent },
      //     ]
      //     },

      //     {
      //       path: 'work-order-priorites', children: [
      //         { path: '', component: WorkOrdersPrioritiesComponent },
      //       ]
      //     },

      //     {
      //       path: 'work-order-skill', children: [
      //         { path: '', component: WorkOrdersSkillsComponent },
      //       ]
      //     },

      //     {
      //       path: 'cancel-reason', children: [
      //         { path: '', component: CancelReasonComponent },
      //       ]
      //     },

      //     {
      //       path: 'on-hold-reason', children: [
      //         { path: '', component: OnholdReasonsComponent },
      //       ]
      //     },

      //     {
      //       path: 'flag-reason', children: [
      //         { path: '', component: FlagReasonsComponent },
      //       ]
      //     },

      //     {
      //       path: 'reapir-codes', children: [
      //         { path: '', component: RepairCodesComponent },
      //         { path: 'detail-repair-category', component: RepairCategoryComponent },

      //       ]
      //     },

      //     {
      //       path: 'work-orders-types', children: [
      //         { path: '', component: WorkOrderTypesComponent },
      //       ]
      //     },

      //     {
      //       path: 'alert-setting', children: [
      //         { path: '', component: AlertSettingsComponent },
      //       ]
      //     },

      //     {
      //       path: 'print-templates', children: [
      //         { path: '', component: PrintTemplatesComponent },
      //       ]
      //     },

      //     {
      //       path: 'dispatch-board-setting', children: [
      //         { path: '', component: DispatchBoardSettingsComponent },
      //       ]
      //     },

      //     {
      //       path: 'work-orders-work-flow', children: [
      //         { path: '', component: WorkOrderWorkFlowComponent },
      //       ]
      //     },

      //     {
      //       path: 'work-orders-back-date', children: [
      //         { path: '', component: WorkOrderBackDateComponent },
      //       ]
      //     },




      //     {
      //       path: 'work-zones', children: [
      //         { path: '', component: WorkZonesComponent },
      //         { path: 'edit-work-zone', component: EditWorkZoneComponent },


      //       ]
      //     },

      //     {
      //       path: 'service-teams', children: [
      //         { path: '', component: ServiceTeamsComponent },
      //         { path: 'default-service-team', component: DefaultServiceTeamComponent },

      //       ]
      //     },

      //     {
      //       path: 'dispatch-centers', children: [
      //         { path: '', component: DispatchCentersComponent },


      //       ]
      //     },

      //     {
      //       path: 'service-package', children: [
      //         { path: '', component: ServicePackagesComponent },
      //         { path: 'my-service-package', component: MyServicePackageComponent },


      //       ]
      //     },

      //     {
      //       path: 'equipment-types', children: [
      //         { path: '', component: EquipmentTypesComponent },
      //         { path: 'equipment-service', component: EquipmentServiceComponent },


      //       ]
      //     },

      //     {
      //       path: 'attributes', children: [
      //         { path: '', component: AttributesComponent },
      //       ]
      //     },
      //     {
      //       path: 'parts', children: [
      //         { path: '', component: PartsComponent },
      //       ]
      //     },
      //     {
      //       path: 'custom-fields', children: [
      //         { path: '', component: CustomFieldsComponent },
      //       ]
      //     },

      //     {
      //       path: 'terminology', children: [
      //         { path: '', component: TerminologyComponent },
      //       ]
      //     },

      //     {
      //       path: 'subscription-details', children: [
      //         { path: '', component: SubscriptionDetailsComponent },
      //       ]
      //     },

      //     {
      //       path: 'upload-logo', children: [
      //         { path: '', component: UploadLogoComponent },
      //       ]
      //     },

      //     {
      //       path: 'document-types', children: [
      //         { path: '', component: DocumentTypesComponent },
      //       ]
      //     },


      //     {
      //       path: 'appearance', children: [
      //         { path: '', component: FsmAppearanceComponent },
      //       ]
      //     },


      //     {
      //       path: 'landmarks', children: [
      //         { path: '', component: FsmLandmarksComponent },
      //       ]
      //     },


      //     {
      //       path: 'integration', children: [
      //         { path: '', component: FsmIntegrationComponent },
      //       ]
      //     },


      //     {
      //       path: 'class-settings', children: [
      //         { path: '', component: ClassSettingsComponent },
      //       ]
      //     },

      //     {
      //       path: 'my-work-settings', children: [
      //         { path: '', component: MyWorkSettingComponent },
      //       ]
      //     },


      //     {
      //       path: 'primary-work-order', children: [
      //         { path: '', component: PrimaryWorkOrderComponent },
      //       ]
      //     },


      //     {
      //       path: 'non-primary-work-order', children: [
      //         { path: '', component: NonPrimaryWorkOrderComponent },
      //       ]
      //     },


      //     {
      //       path: 'device-settings', children: [
      //         { path: '', component: DeviceSettingComponent },
      //       ]
      //     },

      //     {
      //       path: 'basic-invoice-settings', children: [
      //         { path: '', component: BasicInvoiceSettingComponent },
      //       ]
      //     },

      //     {
      //       path: 'posting-invoices', children: [
      //         { path: '', component: PostingInvoicesComponent },
      //       ]
      //     },


      //     {
      //       path: 'sales-tax-items', children: [
      //         { path: '', component: SalesTaxItemsComponent },
      //       ]
      //     },

      //     {
      //       path: 'sales-tax-groups', children: [
      //         { path: '', component: SalesTaxGroupsComponent },
      //       ]
      //     },


      //     {
      //       path: 'sales-tax-codes', children: [
      //         { path: '', component: SalesTaxCodesComponent },
      //       ]
      //     },

      //     {
      //       path: 'invoice-status', children: [
      //         { path: '', component: InvoiceStatusComponent },
      //       ]
      //     },

      //     {
      //       path: 'price-list', children: [
      //         { path: '', component: PriceListComponent },
      //         { path: 'price-list-item', component: PriceListItemComponent },


      //       ]
      //     },


      //     {
      //       path: 'price-list-groups', children: [
      //         { path: '', component: PriceLitGroupsComponent },
      //       ]
      //     },


      //     {
      //       path: 'prints-templates', children: [
      //         { path: '', component: PrintsTemplatesComponent },
      //       ]
      //     },


      //     {
      //       path: 'payment-processing', children: [
      //         { path: '', component: PaymentProcessingComponent },
      //       ]
      //     },

      //     {
      //       path: 'qbms-integration', children: [
      //         { path: '', component: QbmsIntegrationComponent },
      //       ]
      //     },

      //     {
      //       path: 'customer-notifications', children: [
      //         { path: '', component: FsmNotificationsComponent },
      //       ]
      //     },

      //     {
      //       path: 'work-flow-settings', children: [
      //         { path: '', component:  WorkFlowSettingsComponent},
      //       ]
      //     },

      //     {
      //       path: 'time-sheet-period', children: [
      //         { path: '', component: TimeSheetPeriodComponent },
      //       ]
      //     },

      //     {
      //       path: 'over-time-rules', children: [
      //         { path: '', component: OverTimeRulesComponent },
      //       ]
      //     },

      //     {
      //       path: 'rounding-rules', children: [
      //         { path: '', component: RoundingRulesComponent },
      //       ]
      //     },

      //     {
      //       path: 'list-view-configuration', children: [
      //         { path: '', component: ListViewConfigurationComponent },
      //       ]
      //     },

      //     {
      //       path: 'reconcile-flag-rules', children: [
      //         { path: '', component: ReconcileFlagRulesComponent },
      //       ]
      //     },

      //     {
      //       path: 'pm-setting', children: [
      //         { path: '', component: PmSettingsComponent },
      //       ]
      //     },

      //     {
      //       path: 'seasons-setting', children: [
      //         { path: '', component: SeasonSettingsComponent },
      //       ]
      //     },

      //     {
      //       path: 'work-order-network-settings', children: [
      //         { path: '', component: SettingWorkOrderNetworkComponent },
      //       ]
      //     },


      //     {
      //       path: 'won-business-listing', children: [
      //         { path: '', component: WonBusinessListingComponent },
      //       ]
      //     },

      //     { path: 'work-zone', component: WorkZoneComponent },

      //     { path: 'model-your-business', component: ModelYourBusinessComponent },
      //     { path: 'company', component: FsmCompanyComponent },
      //     { path: 'mobile-setting', component: MobileSettingsComponent },
      //     { path: 'fsmfinancials', component: FsmFinancialComponent },
      //     { path: 'customers', component: FsmCustomersComponent },
      //     { path: 'times-sheets', component: TimeSheetComponent },
      //     { path: 'work-order-networks', component: WorkOrderNetworkComponent },
      //     { path: 'service-agreements', component: ServiceAgreementsComponent },

      //  ]

      // },
    ]

  },
  {
    path: 'staffing',
    children: [
      {
        path: '',
        redirectTo: 'technician',
        pathMatch: 'full',
      },
      {
        path: 'technician', children: [
          { path: '', component: StaffingComponent },
        ]
      },
    ]

  },

  {
    path: 'Setting',
    children: [
      {
        path: '',
        redirectTo: 'work-order',
        pathMatch: 'full',
      },


      {
        path: 'work-order', children: [
          { path: '', component: FsmSettingComponent },
        ]
      },

      // {
      //   path: 'work-order-priorites', children: [
      //     { path: '', component: WorkOrdersPrioritiesComponent },
      //   ]
      // },

      // {
      //   path: 'work-order-skill', children: [
      //     { path: '', component: WorkOrdersSkillsComponent },
      //   ]
      // },

      {
        path: 'cancel-reason', children: [
          { path: '', component: CancelReasonComponent },
        ]
      },

      {
        path: 'on-hold-reason', children: [
          { path: '', component: OnholdReasonsComponent },
        ]
      },

      {
        path: 'flag-reason', children: [
          { path: '', component: FlagReasonsComponent },
        ]
      },

      {
        path: 'reapir-codes', children: [
          { path: '', component: RepairCodesComponent },
          { path: 'detail-repair-category', component: RepairCategoryComponent },

        ]
      },

      {
        path: 'work-orders-types', children: [
          { path: '', component: WorkOrderTypesComponent },
        ]
      },

      {
        path: 'alert-setting', children: [
          { path: '', component: AlertSettingsComponent },
        ]
      },

      {
        path: 'print-templates', children: [
          { path: '', component: PrintTemplatesComponent },
        ]
      },

      {
        path: 'dispatch-board-setting', children: [
          { path: '', component: DispatchBoardSettingsComponent },
        ]
      },

      {
        path: 'work-orders-work-flow', children: [
          { path: '', component: WorkOrderWorkFlowComponent },
        ]
      },

      {
        path: 'work-orders-back-date', children: [
          { path: '', component: WorkOrderBackDateComponent },
        ]
      },




      {
        path: 'work-zones', children: [
          { path: '', component: WorkZonesComponent },
          { path: 'edit-work-zone', component: EditWorkZoneComponent },


        ]
      },

      {
        path: 'service-teams', children: [
          { path: '', component: ServiceTeamsComponent },
          { path: 'default-service-team', component: DefaultServiceTeamComponent },

        ]
      },

      {
        path: 'dispatch-centers', children: [
          { path: '', component: DispatchCentersComponent },


        ]
      },

      {
        path: 'service-package', children: [
          { path: '', component: ServicePackagesComponent },
          { path: 'my-service-package', component: MyServicePackageComponent },


        ]
      },

      {
        path: 'equipment-types', children: [
          { path: '', component: EquipmentTypesComponent },
          { path: 'equipment-service', component: EquipmentServiceComponent },


        ]
      },

      {
        path: 'attributes', children: [
          { path: '', component: AttributesComponent },
        ]
      },
      {
        path: 'parts', children: [
          { path: '', component: PartsComponent },
        ]
      },
      {
        path: 'custom-fields', children: [
          { path: '', component: CustomFieldsComponent },
        ]
      },

      {
        path: 'terminology', children: [
          { path: '', component: TerminologyComponent },
        ]
      },

      {
        path: 'subscription-details', children: [
          { path: '', component: SubscriptionDetailsComponent },
        ]
      },

      {
        path: 'upload-logo', children: [
          { path: '', component: UploadLogoComponent },
        ]
      },

      {
        path: 'document-types', children: [
          { path: '', component: DocumentTypesComponent },
        ]
      },


      {
        path: 'appearance', children: [
          { path: '', component: FsmAppearanceComponent },
        ]
      },


      {
        path: 'landmarks', children: [
          { path: '', component: FsmLandmarksComponent },
        ]
      },


      {
        path: 'integration', children: [
          { path: '', component: FsmIntegrationComponent },
        ]
      },


      {
        path: 'class-settings', children: [
          { path: '', component: ClassSettingsComponent },
        ]
      },

      {
        path: 'my-work-settings', children: [
          { path: '', component: MyWorkSettingComponent },
        ]
      },


      {
        path: 'primary-work-order', children: [
          { path: '', component: PrimaryWorkOrderComponent },
        ]
      },


      {
        path: 'non-primary-work-order', children: [
          { path: '', component: NonPrimaryWorkOrderComponent },
        ]
      },


      {
        path: 'device-settings', children: [
          { path: '', component: DeviceSettingComponent },
        ]
      },

      {
        path: 'basic-invoice-settings', children: [
          { path: '', component: BasicInvoiceSettingComponent },
        ]
      },

      {
        path: 'posting-invoices', children: [
          { path: '', component: PostingInvoicesComponent },
        ]
      },


      {
        path: 'sales-tax-items', children: [
          { path: '', component: SalesTaxItemsComponent },
        ]
      },

      {
        path: 'sales-tax-groups', children: [
          { path: '', component: SalesTaxGroupsComponent },
        ]
      },


      {
        path: 'sales-tax-codes', children: [
          { path: '', component: SalesTaxCodesComponent },
        ]
      },

      {
        path: 'invoice-status', children: [
          { path: '', component: InvoiceStatusComponent },
        ]
      },

      {
        path: 'price-list', children: [
          { path: '', component: PriceListComponent },
          { path: 'price-list-item', component: PriceListItemComponent },


        ]
      },


      {
        path: 'price-list-groups', children: [
          { path: '', component: PriceLitGroupsComponent },
        ]
      },


      {
        path: 'prints-templates', children: [
          { path: '', component: PrintsTemplatesComponent },
        ]
      },


      {
        path: 'payment-processing', children: [
          { path: '', component: PaymentProcessingComponent },
        ]
      },

      {
        path: 'qbms-integration', children: [
          { path: '', component: QbmsIntegrationComponent },
        ]
      },

      {
        path: 'customer-notifications', children: [
          { path: '', component: FsmNotificationsComponent },
        ]
      },

      {
        path: 'work-flow-settings', children: [
          { path: '', component: WorkFlowSettingsComponent },
        ]
      },

      {
        path: 'time-sheet-period', children: [
          { path: '', component: TimeSheetPeriodComponent },
        ]
      },

      {
        path: 'over-time-rules', children: [
          { path: '', component: OverTimeRulesComponent },
        ]
      },

      {
        path: 'rounding-rules', children: [
          { path: '', component: RoundingRulesComponent },
        ]
      },

      {
        path: 'list-view-configuration', children: [
          { path: '', component: ListViewConfigurationComponent },
        ]
      },

      {
        path: 'reconcile-flag-rules', children: [
          { path: '', component: ReconcileFlagRulesComponent },
        ]
      },

      {
        path: 'pm-setting', children: [
          { path: '', component: PmSettingsComponent },
        ]
      },

      {
        path: 'seasons-setting', children: [
          { path: '', component: SeasonSettingsComponent },
        ]
      },

      {
        path: 'work-order-network-settings', children: [
          { path: '', component: SettingWorkOrderNetworkComponent },
        ]
      },


      {
        path: 'won-business-listing', children: [
          { path: '', component: WonBusinessListingComponent },
        ]
      },

      { path: 'work-zone', component: WorkZoneComponent },

      { path: 'model-your-business', component: ModelYourBusinessComponent },
      { path: 'company', component: FsmCompanyComponent },
      { path: 'mobile-setting', component: MobileSettingsComponent },
      { path: 'fsmfinancials', component: FsmFinancialComponent },
      { path: 'customers', component: FsmCustomersComponent },
      { path: 'times-sheets', component: TimeSheetComponent },
      { path: 'work-order-networks', component: WorkOrderNetworkComponent },
      { path: 'service-agreements', component: ServiceAgreementsComponent },

    ]

  },

  {
    path: 'psa',
    children: [
      {
        path: '',
        redirectTo: 'project',
        pathMatch: 'full',
      },
      { path: 'Dashboard', component: PSADashboardComponent },
      { path: 'product-backlog', component: ProductBacklogComponent },
      { path: 'admin-scrum', component: AdministrationComponent },
      { path: 'find-task-revision', component: RevisionComponent },
      { path: 'sprints', component: SprintsComponent },
      { path: 'tasks', component: TasksComponent },
      { path: 'open-test', component: OpenTestComponent },
      { path: 'my-profile', component: MyProfileComponent },
      { path: 'my-work', component: MyWorkComponent },
      { path: 'my-timesheet', component: MyWorkComponent },
      { path: 'product-overview', component: ProductOverviewComponent },
      { path: 'detail-sprint-project', component: DetailSprintProjectComponent },
      { path: 'detail-tasks', component: DetailTasksComponent },
      { path: 'detail-tasks', component: DetailTasksComponent },
      { path: 'detail-overview-page-admin', component: DetailOverviewPageAdminComponent },
      { path: 'update-postal-address-profile', component: UpdatePostalAddressProfileComponent },
      { path: 'update-contact-profile', component: UpdateContactProfileComponent },
      { path: 'project-my-profile', component: ProjectMyProfileComponent },
      { path: 'tasks-my-profile', component: TasksMyProfileComponent },
      { path: 'unplanned-sprint', component: UnplannedSprintComponent }, { path: 'visits-my-profile', component: VisitsMyProfileComponent },
      { path: 'party-content-my-profile', component: PartyContentMyProfileComponent },
      { path: 'security-my-profile', component: SecurityMyProfileComponent },
      { path: 'update-identification-number', component: UpdateIdentificationNumberComponent },
      { path: 'new-identification-number', component: NewIdentificationNumberComponent },
      { path: 'preferences-my-profile', component: PreferencesMyProfileComponent },
      { path: 'detail-new-sprint', component: DetailNewSprintComponent },
      { path: 'roles', component: RolesComponent },
      { path: 'edit-profile', component: EditProfileComponent },
      { path: 'list-all-profile', component: ListAllProfileComponent },
      { path: 'update-contact', component: UpdateContactComponent },
      { path: 'edit-party', component: EditPartyComponent },
      { path: 'override-edit', component: OverrideEditComponent },
      { path: 'rates-my-profile', component: RatesMyProfileComponent },
      { path: 'revisions-my-profile', component: RevisionsMyProfileComponent },
      {
        path: 'project',
        children: [
          {
            path: '',
            redirectTo: 'project',
            pathMatch: 'full',
          },
          { path: 'project', component: ProjectComponent },
          { path: 'my-tasks', component: MyTasksComponent },
          { path: 'my-time', component: MyTimeComponent },
          { path: 'projects', component: ProjectsComponent },
          { path: 'request-list', component: RequestListComponent },
          { path: 'resources', component: ResourcesComponent },
          { path: 'skill-type', component: SkillTypeComponent },
          { path: 'timesheet-project', component: TimesheetProjectComponent },
          { path: 'tasks-project', component: TasksProjectComponent },
          { path: 'detail-project-summary', component: DetailProjectSummaryComponent },
          { path: 'detail-my-task', component: DetailMyTaskComponent },
          { path: 'detail-task-project', component: DetailTaskProjectComponent },
          { path: 'detail-note', component: DetailNoteComponent },
          { path: 'detail-new-task', component: DetailNewTaskComponent },
          { path: 'detail-new-request', component: DetailNewRequestComponent },
          { path: 'detail-timesheet', component: DetailTimesheetComponent },
          { path: 'create-task-from-request', component: CreateTaskFromRequestComponent },
          { path: 'create-quote-from-request-list', component: CreateQuoteFromRequestListComponent },
        ]
      },



      {
        path: 'work-effort',
        children: [
          {
            path: '',
            redirectTo: 'task-list',
            pathMatch: 'full',
          },
          { path: 'task-list', component: TaskListComponent },
          { path: 'calendar', component: CalendarComponent },
          { path: 'request-list-effort', component: RequestListEffortComponent },
          { path: 'my-time-effort', component: MyTimeEffortComponent },
          { path: 'submitted-jobs', component: SubmittedJobsComponent },
          { path: 'icalendar', component: IcalendarComponent },
          { path: 'timesheet-effort', component: TimesheetEffortComponent },
          { path: 'work-effort-effort', component: WorkEffortEffortComponent },
          { path: 'add-work-effort', component: AddWorkEffortComponent },
          {
            path: 'task-list-view',
            children: [
              {
                path: '',
                redirectTo: 'summary',
                pathMatch: 'full',
              },
              { path: 'summary', component: WorkEffortSummaryComponent },
              { path: 'editWorkEffort', component: EditWorkEffortComponent },
              { path: 'childWorkEfforts', component: ChildWorkEffortComponent },
              { path: 'PartyAssigns', component: WorkEffortPartyAssignsComponent },
              { path: 'Rates', component: WorkEffortRatesComponent },
              { path: 'CommEvents', component: WorkEffortCommEventsComponent },
              { path: 'ShopLists', component: WorkEffortShopListsComponent },
              { path: 'Requests', component: WorkEffortRequestsComponent },
              { path: 'Requirements', component: WorkEffortRequirementsComponent },
              { path: 'Quotes', component: WorkEffortQuotesComponent },
              { path: 'OrderHeaders', component: WorkEffortOrderHeadersComponent },
              { path: 'TimeEntries', component: WorkEffortTimeEntriesComponent },
              { path: 'Notes', component: WorkEffortNotesComponent },
              { path: 'Contents', component: WorkEffortContentsComponent },
              { path: 'Products', component: WorkEffortProductsComponent },
              { path: 'Reviews', component: WorkEffortReviewsComponent },
              { path: 'Keywords', component: WorkEffortKeywordsComponent },
              { path: 'ContactMechs', component: WorkEffortContactMechsComponent },
              { path: 'AgreementApplics', component: WorkEffortAgreementApplicsComponent },
              { path: 'FixedAssets', component: WorkEffortFixedAssetsComponent },
              { path: 'Attributes', component: WorkEffortAttributesComponent },
              { path: 'EventReminders', component: WorkEffortEventRemindersComponent },
            ]
          },
          { path: 'work-effort-effort', component: WorkEffortEffortComponent },
          {
            path: 'timesheet-view',
            children: [
              {
                path: '',
                redirectTo: 'EditTimesheet',
                pathMatch: 'full',
              },
              { path: 'EditTimesheet', component: TimesheetTimesheetComponent },
              { path: 'EditTimesheetRoles', component: PartiesTimesheetComponent },
              { path: 'EditTimesheetEntries', component: EntriesTimesheetComponent }
            ]
          },
          {
            path: 'iCalendar-view',
            children: [
              {
                path: '',
                redirectTo: 'EditiCalendar',
                pathMatch: 'full',
              },
              { path: 'EditiCalendar', component: EditIcalendarComponent },
              { path: 'iCalendarChildren', component: ChildIcalendarComponent },
              { path: 'iCalendarParties', component: AddIcalendarPartyComponent },
              { path: 'iCalendarFixedAssets', component: AddIcalendarFixedAssetComponent },
              { path: 'editiCalendarData', component: AddIcalendarDataComponent },
              { path: 'iCalendarHelp', component: HelpIcalendarComponent },
            ]
          },

        ]
      },
    ]
  },
  {
    path: 'communication-overview',
    children: [
      {
        path: '',
        redirectTo: 'Dashboard',
        pathMatch: 'full',
      },
      {
        path: 'Dashboard', component: CommunicationDashboardComponent
      },
      {
        path: 'mycommunications', component: PartyCommunicationListComponent
      },
      {
        path: 'allcommunications', component: CommunicationsComponent
      },
      {
        path: 'communications-email-form', component: CommunicationEmailOverviewComponent
      },
      {
        path: 'find-communications', component: FindCommunicationComponent
      },
    ]
  },
  {
    path: 'marketing',
    children: [
      {
        path: '',
        redirectTo: 'main-marketing',
        pathMatch: 'full',
      },
      { path: 'main-marketing', component: MainMarketingComponent },
      { path: 'data-source-marketing', component: DataSourceMarketingComponent },
      { path: 'view-data-source', component: ViewDataSourceComponent },
      { path: 'view-data-source-type', component: ViewDataSourceTypeComponent },
      { path: 'marketing-campaign', component: MarketingCampaignComponent },
      { path: 'segment-marketing', component: SegmentMarketingComponent },
      { path: 'create-content', component: WorkEffortComponent, canActivate: [AdminGuard] },
      { path: 'contact-list-marketing', component: ContactListMarketingComponent },
      { path: 'cases-marketing', component: CasesComponent },
      {
        path: 'tracking-marketing',
        children: [
          {
            path: '',
            redirectTo: 'tracking-marketing',
            pathMatch: 'full',
          },
          { path: 'tracking-marketing', component: TrackingMarketingComponent },
          { path: 'view-tracking-code', component: EditTrackingCodeComponent },
          { path: 'view-tracking-code-type', component: EditTrackingCodeTypeComponent },
        ]
      },
      {
        path: 'contact-list-marketing',
        children: [
          {
            path: '',
            redirectTo: 'contact-list-marketing',
            pathMatch: 'full',
          },
          { path: 'contact-list-marketing', component: ContactListMarketingComponent },
          { path: 'view-contact', component: ViewContactComponent },
        ]
      },
      { path: 'reports-marketing', component: ReportsMarketingComponent },
      { path: 'ecommerce-site', component: EcommerceSiteComponent },
      { path: 'edit-data-source-type', component: EditDataSourceTypeComponent },
      { path: 'EditMarketingCampaign', component: MarketingCampaignIdComponent },
      { path: 'tracking-id', component: TrackingIdComponent },
      { path: 'tracking-id-access', component: TrackingIdAccessComponent },
      { path: 'segment-id', component: SegmentIdComponent },

    ]
  },


  {
    path: 'myPortal',
    children: [
      {
        path: '',
        redirectTo: 'my-portal-my-profile',
        pathMatch: 'full',
      },
      {
        path: 'Dashboard', component: MyProtalDashboardComponent
      },
      {
        path: 'system-info-note', component: SystemInfoNoteComponent
      },
      {
        path: 'my-portal-new-internal-note', component: MyPortalNewInternalNoteComponent
      },
      {
        path: 'portal-new-mail', component: MyPortalNewMailComponent
      },
      {
        path: 'portal-communication', component: PortalCommunicationComponent
      },
      {
        path: 'detail-new-request', component: DetailNewRequestComponent
      },
      {
        path: 'detail-preference-summary', component: DetailPrefernceSummaryComponent
      },
      {
        path: 'detail-portal-page-admin', component: DetailPortalPageAdminComponent
      },
      {
        path: 'detail-main-portal', component: DetailMainPortalComponent
      },
      {
        path: 'my-portal-create-invoice', component: MyPortalCreateInvoiceComponent
      },
      {
        path: 'detail-payment-portal-summary', component: DetailPaymentPortalSummaryComponent
      },
      {
        path: 'detail-training-portal-summary', component: DetailPortalTrainingSummaryComponent
      },
      {
        path: 'detail-portal-communication', component: DetailPortalCommunicationComponent
      },
      {
        path: 'detail-portal-task-summary', component: PortalTaskSummaryComponent
      },
      {
        path: 'main-my-portal', component: MainMyPortalComponent
      },
      {
        path: 'create-task', component: CreateTaskComponent
      },
      {
        path: 'my-portal-my-comms', component: MyPortalMyCommsComponent
      },
      {
        path: 'my-portal-my-leave', component: MyPortalMyLeaveComponent
      },
      {
        path: 'my-portal-my-payments', component: MyPortalMyPaymentsComponent
      },
      {
        path: 'my-cases', component: MyCasesComponent
      },
      {
        path: 'my-portal-my-profile', component: MyPortalMyProfileComponent
      },
      {
        path: 'update-personal-info', component: PersonalUpdateComponent, canActivate: [AdminGuard]
      },
      {
        path: 'my-portal-my-tasks', component: MyPortalMyTasksComponent
      },
      {
        path: 'my-portal-my-time-sheet', component: MyPortalMyTimeSheetComponent
      },
      {
        path: 'my-portal-my-training', component: MyPortalMyTrainingComponent
      },
      {
        path: 'my-portal-other-party-comms', component: MyPortalOtherPartyCommsComponent
      },
      {
        path: 'new-internal-note', component: NewInternalNoteComponent,
      },
      {
        path: 'new-mail', component: NewMailComponent
      },
      {
        path: 'my-portal-page-admin', component: MyPortalPageAdminComponent
      },
      {
        path: 'my-portal-preferences', component: MyPortalPreferencesComponent
      },
      {
        path: 'create-public-message', component: CreatePublicMessageComponent
      },
      {
        path: 'create-new-portal-page', component: CreateNewPortalPageComponent
      },
      {
        path: 'create-leave', component: CreateLeaveComponent
      },
      {
        path: 'portal-leave-summary', component: PortalLeaveSummaryComponent
      },
      {
        path: 'portal-create-task', component: PortalCreateTaskComponent
      },
      {
        path: 'my-portal-create-payments', component: MyPortalCreatePaymentsComponent
      },
      {
        path: 'my-portal-create-new-invoice', component: MyPortalNewInvoiceComponent
      },
      {
        path: 'detail-my-task', component: DetailMyTaskComponent
      },
      {
        path: 'detail-my-task', component: DetailMyTaskComponent
      },
    ]
  },
  {
    path: 'newImport',
    children: [
      {
        path: '',
        redirectTo: 'company-information-import',
        pathMatch: 'full',
      },
      {
        path: 'company-information-import', component: CompanyInformationImportComponent
      },
      {
        path: 'fiscal-year-import', component: FiscalYearImportComponent
      },
      {
        path: 'email-configuration-import', component: EmailConfigurationImportComponent
      },
      {
        path: 'issue-created-import', component: IssueCreatedImportComponent
      },
      {
        path: 'balance-sheet-import', component: BalanceSheetImportComponent
      },
      {
        path: 'trial-balance-import', component: TrialBalanceImportComponent
      },
      {
        path: 'trial-balance-only-import', component: TrialBalanceOnlyImportComponent
      },
      {
        path: 'cash-flow-import', component: CashFlowImportComponent
      },
      {
        path: 'accounts-group-import', component: AccountsGroupImportComponent
      },
      {
        path: 'defaults-accounts-import', component: DefaultsAccountsImportComponent
      },
      {
        path: 'security-group-import', component: SecurityGroupImportComponent
      },
      {
        path: 'accounts-by-part-family-import', component: AccountsByPartFamilyImportComponent
      },
      {
        path: 'unit-of-measure-import', component: UnitOfMeasureImportComponent
      },
      {
        path: 'costing-method-import', component: CostingMethodImportComponent
      },
      {
        path: 'credit-card-processing-import', component: CreditCardProcessingImportComponent
      },
      {
        path: 'tax-component-import', component: TaxComponentImportComponent
      },
      {
        path: 'taxes-only-import', component: TaxesOnlyImportComponent
      },
      {
        path: 'payments-terms-import', component: PaymentsTermsImportComponent
      },
      {
        path: 'banks-only-import', component: BanksOnlyImportComponent
      },
      {
        path: 'cashiers-only-import', component: CashiersOnlyImportComponent
      },
      {
        path: 'branches-only-import', component: BranchesOnlyImportComponent
      },
      {
        path: 'open-customers-invoices-import', component: OpenCustomersInvoicesImportComponent
      },
      {
        path: 'open-vendors-invoices-import', component: OpenVendorsInvoicesImportComponent
      },
      {
        path: 'gl-account-balances-import', component: GlAccountBalancesImportComponent
      },
      {
        path: 'sales-representatives-import', component: SalesRepresentativesImportComponent
      },
      {
        path: 'customers-only-import', component: CustomersOnlyImportComponent
      },
      {
        path: 'customers-contacts-import', component: CustomersContactsImportComponent
      },
      {
        path: 'customers-sites-import', component: CustomersSiteImportComponent
      },
      {
        path: 'vendors-only-import', component: VendorsOnlyImportComponent
      },
      {
        path: 'vendors-contacts-import', component: VendorsContactsImportComponent
      },
      {
        path: 'inventory-count-import', component: InventoryCountImportComponent
      },
      {
        path: 'warehouses-only-import', component: WarehousesOnlyImportComponent
      },
      {
        path: 'warehouses-location-import', component: WarehousesLocationImportComponent
      },
      {
        path: 'shipments-only-import', component: ShipmentsOnlyImportComponent
      },
      {
        path: 'parts-brands-import', component: PartsBrandsImportComponent
      },
      {
        path: 'parts-class-import', component: PartsClassImportComponent
      },
      {
        path: 'parts-families-import', component: PartsFamiliesImportComponent
      },
      {
        path: 'parts-catalogue-import', component: PartsCatalogueImportComponent
      },
      {
        path: 'parts-groups-import', component: PartsGroupsImportComponent
      },
      {
        path: 'customer-parts-alias-import', component: CustomerPartsAliasImportComponent
      },
      {
        path: 'vendors-parts-alias-import', component: VendorsPartsAliasImportComponent
      },
      {
        path: 'balance-sheet-headings-import', component: BalanceSheetHeadingsImportComponent
      },
      {
        path: 'gl-accounts-import', component: GlAccountsImportComponent
      },
      {
        path: 'finalize-configuration-import', component: FinalizeConfigurationImportComponent
      },
    ]
  },
  {
    path: 'assetMaint',
    children: [
      {
        path: '',
        redirectTo: 'main-assest',
        pathMatch: 'full',
      },
      { path: 'main-assest', component: MainAssetComponent },
      { path: 'task-list', component: AssestTaskListComponent },
      {
        path: 'fixed-asset',
        children: [
          {
            path: '',
            redirectTo: 'fixed-asset',
            pathMatch: 'full',
          },
          { path: 'fixed-asset', component: AssetFixedAssetComponent },
          { path: 'edit-fixed-asset', component: EditFixedAssetComponent },
          { path: 'edit-fixed-asset-maint', component: EditFixedAssetMaintComponent },
          { path: 'edit-facility', component: EditFacilityComponent },
          { path: 'workeffort-summary', component: TaskListSummaryComponent },
        ]
      },
      { path: 'asset-maintenance', component: AssetMaintenanceComponent },
      { path: 'asset-facility', component: AssetFacilityComponent },
    ]
  }
];


FullCalendarModule.registerPlugins([
  interactionPlugin,
  timeGridPlugin,
  dayGridPlugin,

]);
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    MainContainerComponent,
    FooterComponent,
    LoginComponent,
    CreateNewSuppliersComponent,
    MergeSuppliersComponent,
    PurchasingReportsComponent,
    MainCatalogMgmtComponent,
    ProductsComponent,
    LeadsComponent,
    AccountsComponent,
    CreateAccountComponent,
    FindAccountsComponent,
    MergeAccountsComponent,
    MyContactsComponent,
    CreateContactsComponent,
    FindContactsComponent,
    MergeContactsComponent,
    MyLeadsComponent,
    FindLeadsComponent,
    MergeLeadsComponent,
    TeamsOpportunitiesComponent,
    CreateOpportunityComponent,
    FindOpportunitiesComponent,
    MyQuotesComponent,
    CreateQuotesComponent,
    FindQuotesComponent,
    CreateEventsComponent,
    EmployeesComponent,
    EmployeeEmergencyContactComponent,
    EmployeeFaxInformationComponent,
    AddNewEmployeeComponent,
    AppContentComponent,
    EmployeePositionComponent,
    SalesPromoCodesComponent,
    SalesProductComponent,
    AddEmployeePositionComponent,
    SkillsComponent,
    AddSkillsComponent,
    EmployeeLeaveComponent,
    LeaveApprovalComponent,
    AddNewLeavesComponent,
    TrainingApprovalsComponent,
    TimesheetComponent,
    CreateTimesheetComponent,
    JobRequisitionComponent,
    InternalJobPostingComponent,
    CreateInternalJobPostingComponent,
    NewJobRequisitionComponent,
    JobInterviewComponent,
    NewJobInterviewComponent,
    UpdateApprovalComponent,
    ApprovalComponent,
    ReallocationComponent,
    CatalogsComponent,
    ImportAccountComponent,
    NewProductCatalogComponent,
    MainHumanResourceComponent,
    CatagoriesComponent,
    SalesContinueComponent,
    CreateSalesContinueComponent,
    PurchaseContinueComponent,
    NewCatagoryComponent,
    NewProductsComponent,
    FeatureTypeComponent,
    FeatureCategoryComponent,
    FeatureGroupComponent,
    FeatureInteractionComponent,
    NewFeatureTypeComponent,
    NewFeatureCategoryComponent,
    NewFeatureInteractionComponent,
    ConfigurationsComponent,
    CreateConfigurationsComponent,
    ThesaurusComponent,
    ShipmentComponent,
    CarrierShipmentComponent,
    ShipmentEstimateComponent,
    WarehouseComponent,
    QuantityBreaksComponent,
    InvoicesComponent,
    CreateInvoicesComponent,
    CommissionComponent,
    CreateAgreementItemComponent,
    AdditionalItemsComponent,
    AddNewPromotionsComponent,
    PaymentsComponent,
    CreatePaymentsComponent,
    SaleInvoicesComponent,
    PurchaseInvoicesComponent,
    PaymentGroupComponent,
    CreatePaymentGroupComponent,
    ReportsComponent,
    CommissionReportComponent,
    VendorsComponent,
    CreateVendorsComponent,
    BilingAccountsComponent,
    AccountingDocumentComponent,
    CreateNewFacilitiesComponent,
    DetailedContactsSummaryComponent,
    DetailedAccountsSummaryComponent,
    DetailedOpportunitySummaryComponent,
    DetailedQuoteSummaryComponent,
    DocumentsComponent,
    EmploymentsComponent,
    AddNewEmploymentComponent,
    PurchaseFinalaizeOrderComponent,
    MainWarehouseComponent,
    FacilityGroupComponent,
    InventoryLabelsComponent,
    LabelTypesComponent,
    ShipmentGatewayComponent,
    WarehouseShipmentComponent,
    NewWarehouseShipmentComponent,
    UpdateWarehouseShipmentComponent,
    MainAccountingComponent,
    DetailedEventSummaryComponent,
    UserListComponent,
    CreaditMemosComponent,
    CreateCreditMemosComponent,
    PayablesPaymentComponent,
    EditSaleInvoiceComponent,
    InvoicePdfComponent,
    EditPurchaseInvoiceComponent,
    DetailedInvoiceSummaryComponent,
    MakePaymentComponent,
    MultipleVendorsComponent,
    ConfirmPaymentsComponent,
    CommissionAgreementComponent,
    CreaateCommissionComponent,
    EventsComponent,
    CommissionInvoicesComponent,
    CreateCommissionInvoicesComponent,
    CommissionBalancesComponent,
    CommissionStatementComponent,
    PayablesReportsComponent,
    ReceivableSaleInvoiceComponent,
    CreateSaleInvoicesComponent,
    CreatePurchaseNewAddressComponent,
    InvoicesServiceItemsComponent,
    ReceivalesPaymentComponent,
    ReceivePaymentComponent,
    CashDrawersComponent,
    CustomerAgreementsComponent,
    CreateCustomerAgreementsComponent,
    CustomerCreditComponent,
    ReceivableReportsComponent,
    DetaledLeadComponent,
    SuppliersComponent,
    OutsourceTaskComponent,
    NewFacilityGroupComponent,
    DashboardComponent,
    CrmDashboardComponent,
    WarehouseDashboardComponent,
    UserDashboardComponent,
    CatalogDashboardComponent,
    ArDashboardComponent,
    HrmDashboardComponent,
    DepartmentsComponent,
    CreateDepartmentsComponent,
    CreateBillingComponent,
    DetailedEmployeeSummaryComponent,
    DetailedEmployeePositionComponent,
    DetailedPaymentGroupsComponent,
    AddJobInterviewComponent,
    InternalJobPostingApplicationComponent,
    NewInternalJobPostingComponent,
    VoidedComponent,
    ReceivedComponent,
    SentComponent,
    NotPaidComponent,
    PaymentApplicationComponent,
    SettingComponent,
    NewPositionTypeComponent,
    ReasonTypeComponent,
    NewPayGradeComponent,
    NotPaidHeaderComponent,
    NotPaidApplicationsComponent,
    EmployeeListComponent,
    EmployeeApplicationComponent,
    ResponsibilitiesComponent,
    CreateEmployeeApplicationComponent,
    StoriesComponent,
    ImportComponent,
    ContactsComponent,
    NotesComponent,
    AccountNotesComponent,
    QuotesNotesComponent,
    ListRelatedAccountComponent,
    ListRelatedContactComponent,
    MyLeadComponent,
    LeadUpdateComponent,
    PartyGroupComponent,
    TelecomComponent,
    EmailAddressComponent,
    PostalAddressComponent,
    LeadNotesComponent,
    AssignByMeComponent,
    ConvertLeadComponent,
    EditContactGroupComponent,
    LeadPersonalGroupComponent,
    MergeLeadComponent,
    InterbalJobPostingAppComponent,
    UpdateLeadComponent,
    LeadPersonalComponent,
    ContactInformationComponent,
    DetailProductSummaryComponent,
    DetailCategorySummaryComponent,
    DetailedSummaryFeatureCategoryComponent,
    CreateEditFeatureMaintenanceComponent,
    DetailedSummaryFeatureGroupComponent,
    SummaryPageComponent,
    DetailedSummaryFacilitiesComponent,
    CreateNewFacilityLocationComponent,
    CreateNewInventoryItemComponent,
    UpdatePhysicalInventoryComponent,
    CreateInventoryTransferComponent,
    UpdateWarehouseShipmentComponent,
    CreateNewContactInformationComponent,
    UpdateCustomerAgreementsComponent,
    WarehouseShipmentSummaryComponent,
    SummaryCustomerAgreementsComponent,
    CreateAgrrementTermsComponent,
    DetailedPayableInvoiceSummaryComponent,
    CreateNewInvoiceRoleComponent,
    CreateNewInvoiceTermComponent,
    CreateNewInvoiceItemComponent,
    SendEmailComponent,
    CreditMemoComponent,
    CreateCreditMemoComponent,
    UpdateInvoiceComponent,
    CreateNewAgreementItemComponent,
    CreateNewAgreementContentComponent,
    CreateNewAgreementRoleComponent,
    AgreementSummaryItemComponent,
    CreateNewItemPromotionComponent,
    CreateNewItemTermComponent,
    CreateNewItemProductComponent,
    FindOrderComponent,
    CreateOrderComponent,
    CreatePurchaseContinueComponent,
    EmpImportComponent,
    CreateNewItemProductComponent,
    CreateNewItemGeoComponent,
    CreateNewItemFacilityComponent,
    CommissionInvoiceComponent,
    CreateNewCommissionComponent,
    SummaryCommissionInvoiceComponent,
    CreateNewCommissionInvoiceItemComponent,
    ImportContactsComponent,
    PersonalUpdateComponent,
    EmpContactInformationComponent,
    UpdatePostalAddressComponent,
    EmpRelatedAccountComponent,
    UpdatePhoneNumberComponent,
    CreateNewInvoiceCommissionTermComponent,
    EmpUpdateEmailComponent,
    EmpLeadNotesComponent,
    CreatePayablePaymentComponent,
    SummaryPayablePaymentComponent,
    EmpUpdateEmailComponent,
    UpdatePayablePaymentComponent,
    SalesFinalaizeOrderComponent,
    PayablePaymentGroupComponent,
    SummaryPayablePaymentGroupComponent,
    IncomingPaymentComponent,
    CreateIncomingPaymentComponent,
    SummaryIncomingPaymentComponent,
    ArPaymentGroupComponent,
    SummaryArPaymentGroupComponent,
    BillingAccountComponent,
    EmpFulfillmentPositionComponent,
    EmpResponsibilityComponent,
    SummaryBillingAccountComponent,
    EmpPositionReportedToComponent,
    EmpPositionReportByComponent,
    CreateSalesNewAddressComponent,
    SalesOrderOptionsSettingsComponent,
    SalesOrderEntryTermsComponent,
    PurchaseOrderOptionsSettingsComponent,
    PurchaseOrderEntryTermsComponent,
    SalesPaymentOptionsComponent,
    PurchasePaymentOptionsComponent,
    SalesOrderAdditionalPartyComponent,
    PurchaseOrderAdditionalPartyComponent,
    SalesOrderConfirmationComponent,
    PurchaseOrderConfirmationComponent,
    SalesOrderViewPageComponent,
    PurchaseOrderViewPageComponent,
    ReturnMainComponent,
    CreateNewReturnComponent,
    ReturnHistoryComponent,
    ReturnItemsComponent,
    ConfirmationEmailComponent,
    CreateNotesComponent,
    ViewOrderHistoryComponent,
    SummaryCreditMemoComponent,
    SendCreditMemoMailComponent,
    CreateCreditItemComponent,
    CreateCreditRoleComponent,
    CreateCreditTermComponent,
    AllEventsComponent,
    OrderDashboardComponent,
    DetailedTimesheetSummaryComponent,
    CreateRoleComponent,
    UpdateApplicationComponent,
    ReviewOrderNotPickedComponent,
    PhysicalUpdateInventoryComponent,
    ShowReturnComponent,
    ReturnViewOrderComponent,
    CreateUserComponent,
    ImportUserComponent,
    UserDetailSummaryComponent,
    AddToRoleComponent,
    NewRoleTypeComponent,
    OrderReceivePaymentComponent,
    SecurityGroupComponent,
    CreateSecurityGroupComponent,
    PermisionDetailPageComponent,
    AddPermisionManuallyComponent,
    AddPermissionListComponent,
    AddFinancailAccountComponent,
    CreateFinancialAccountComponent,
    DetailFinancialSummaryComponent,
    CreateFinancialRoleComponent,
    CreateFinancialTransactionComponent,
    BankReconciliationComponent,
    FinancialNewDepositPaymentComponent,
    FinancialNewWithdrawPaymentComponent,
    FinancialNewReconciliationComponent,
    DetailSummaryReconciliationComponent,
    FixAssestListComponent,
    CreateFixAssestComponent,
    FixAssestSummaryPageComponent,
    CreateProductComponent,
    CreateFixAssignmentComponent,
    GlobalGlSettingListComponent,
    CreateGlobalSettingComponent,
    AssignGlAccountComponent,
    GlAccountSummaryComponent,
    GlobalGlCostComponent,
    CreateGlobalCostComponent,
    GlCustomTimePeriodListComponent,
    CreateTimePeriodComponent,
    GlobalGlRateListComponent,
    CreateGlRatesComponent,
    GlPaymentMethodTypeComponent,
    UpdatePaymentMethodTypeComponent,
    GlInvoiceTypeListComponent,
    UpdateInvoiceMethodTypeComponent,
    GlForeignExchangeRateComponent,
    CreateForeignExchangeRateComponent,
    GlAccountCategoryListComponent,
    CreateGlAccountCategoryComponent,
    DetailAccountCategorySummaryComponent,
    CreateAccountCategoryMemberComponent,
    DeleteConfirmationComponent,
    GlCostCenterComponent,
    CreateCreditCardComponent,
    CreateGiftCardComponent,
    CreateEFTAccountComponent,
    CreateBillingAccountComponent,
    CreateCheckAccountComponent,
    AssignListGlComponent,
    QuoteEmailComponent,
    CreateAccountAdditionalDetailsComponent,
    CreateAccountJobInfoComponent,
    AccountPostalAddressComponent,
    AccountFaxNumberComponent,
    AccountPostalAddressComponent,
    ListVendorsComponent,
    NewVendorCreateComponent,
    CreateSupplierProductComponent,
    NumberOnlyDirective,
    ProductPriceComponent,
    CreateProductContentComponent,
    UpdateContactInfoComponent,
    DetailDepartmentSummaryComponent,
    DetailSkillSummaryComponent,
    DetailRequistionSummaryComponent,
    DetailInternalJobSummaryComponent,
    JobInterviewSummaryComponent,
    ApprovalSummaryComponent,
    ReallocationSummaryComponent,
    LeaveSummaryComponent,
    EmployeeApplicationSummaryComponent,
    TrainingSummaryComponent,
    DetailSkillTypeComponent,
    DetailResTypeComponent,
    DetailHolidaySummaryComponent,
    DetailTrainingClassTypeComponent,
    DetailPositionTypeComponent,
    RespDetailSummaryComponent,
    DetailJobSummaryComponent,
    DetailLeaveSummaryComponent,
    DetailReasonTypeComponent,
    DetailVendorSummaryComponent,
    DetailFacilityGroupSummaryComponent,
    DetailLabelTypeSummaryComponent,
    DetailInventoryLabelSummaryComponent,
    DetailShipmentSummaryComponent,
    DetailQuantityBreakSummaryComponent,
    DetailCarrierSummaryComponent,
    DetailFeatureTypeComponent,
    ArSubmenuComponent,
    AccountMainPageComponent,
    MainComponent,
    NewIdentificationComponent,
    NewUsernameComponent,
    NewPartyAttributeComponent,
    MainCompanyComponent,
    OverviewSkillComponent,
    OverviewLeaveComponent,
    CompanyProfileComponent,
    ResetPasswordComponent,
    ConfirmPasswordComponent,
    NewPasswordComponent,
    MainCrmComponent,
    MainDetailSummaryComponent,
    NewInternalNoteComponent,
    NewMailComponent,
    MailReplyComponent,
    CrmOverviewComponent,
    CreateEventPurposeComponent,
    CreateEventRoleComponent,
    CreateWorkEffortComponent,
    CreateContentComponent,
    CreateCommunicationOrderComponent,
    CreateCrmProductComponent,
    CreateCrmReturnComponent,
    NewCommunicationCrmComponent,
    OrganizationGlSettingComponent,
    CreateOrganizationGlSettingComponent,
    DetailOrganizationGlSummaryComponent,
    CreateOrganizationChartAccountComponent,
    CreateDocTypeComponent,
    CreateJournalComponent,
    CreateGlAccountTypeDefaultComponent,
    CreateGlProductAccountComponent,
    CreateGlProductCategoryAccountComponent,
    CreateFinGlAccountTypeComponent,
    CreateSalesInvoiceGlAccountComponent,
    CreatePurchaseInvoiceGlAccountComponent,
    CreatePurchaseOrderComponent,
    GlAccountTypePaymentTypeComponent,
    CreatePaymentMethodTypeAccountComponent,
    CreateVarianceReasonTypeComponent,
    CreateCreditCardTypeComponent,
    CreatePartyGlAccountComponent,
    CreateGlAccountFixedAssestComponent,
    CreateTaxAuthorityComponent,
    DetailAccountingSummaryComponent,
    EditAccountReconcilationComponent,
    CreateAccountingTransactionComponent,
    CreateAccountingQuickTransactionComponent,
    UnpostedAccountTransactionComponent,
    CreateGlTimePeriodComponent,
    DetailAccountingTransactionComponent,
    BudgetListComponent,
    CreateBudgetComponent,
    DetailBudgetSummaryComponent,
    CreateAccItemComponent,
    CreateAccRoleComponent,
    CreateAccReviewComponent,
    CreateTransEntriesComponent,
    AuthorizeTransactionComponent,
    CaptureComponent,
    PaymentGatewayResponseComponent,
    ManualElectronicTransactionComponent,
    ViewGatewayResponseComponent,
    TaxAuthorityComponent,
    AddTaxAuthDetailComponent,
    DetailTaxAuthorityComponent,
    CreateTaxCategoryComponent,
    CreateTaxAssociationComponent,
    CreateTaxGlAccountComponent,
    CreateTaxProductRatesComponent,
    CreateTaxPartyComponent,
    JobshopListComponent,
    RoutingComponent,
    CreateJobshopComponent,
    DetailJobshopSummaryComponent,
    CreateJobshopTaskComponent,
    CreateJobshopMaterialComponent,
    CreateJobshopFixedassetComponent,
    CreateJobshopPartiesComponent,
    CreateJobshopAssocsComponent,
    CreateRoutingComponent,
    DetailRoutingSummaryComponent,
    CreateRoutingProductLinkComponent,
    CreateRoutingTaskAssocComponent,
    RoutingTaskComponent,
    AddRouteTaskComponent,
    DetailRoutingTaskSummaryComponent,
    CreateRouteTaskCostComponent,
    CreateTaskDeliverableProductComponent,
    CreateRoutingTaskFixedAssetComponent,
    CreateRoutingTaskPartiesComponent,
    CreateRoutingTaskSkillsComponent,
    CalenderListComponent,
    WeekListComponent,
    CreateNewCalenderComponent,
    CreateNewWeekComponent,
    CostCaclsListComponent,
    CreateCostCaclComponent,
    BillOfMaterialListComponent,
    CreateProductBomComponent,
    CreateBomSimulationComponent,
    ManufacturingRulesComponent,
    CreateManufacturingRulesComponent,
    ViewPartyProfileComponent,
    PartyRoleTypeComponent,
    PartyAddToRoleComponent,
    PartyCreateIdentificationNumberComponent,
    MrpLogListComponent,
    CreateRunMrpComponent,
    ShipmentPlanListComponent,
    CreateActualMaterialComponent,
    ProductionRunDeclarationComponent,
    ReportComponent,
    AddQuantityProducedComponent,
    NewRelationshipTypeComponent,
    AddOtherPartyRelationshipComponent,
    EditPartyRatesComponent,
    AddPartySkillComponent,
    AddPartyContentComponent,
    AddPartySegmentRoleComponent,
    AddPartyResumesComponent,
    EditPersonalInformationComponent,
    AddRelatedAccountComponent,
    AddNewContactInformationComponent,
    CreateUserLoginComponent,
    CreatePartyAttributeComponent,
    NewPartyNoteComponent,
    AttachContentComponent,
    DetailCalcCostComponent,
    DetailCalenderComponent,
    CreateExceptionDayComponent,
    CreateExceptionWeekComponent,
    BomSimulationListComponent,
    UpdateMaterialUsedComponent,
    UpdateProductBomComponent,
    FindPartyComponent,
    MyCommunicationsComponent,
    CommunicationsComponent,
    VisitsComponent,
    LoggedInUsersComponent,
    ClassificationsComponent,
    SecurityComponent,
    AddressMatchMapComponent,
    InvitationComponent,
    ImportExportComponent,
    MainPartyQuickLinksComponent,
    CreateEmployeeComponent,
    CreateProspectComponent,
    CreateCustomerComponent,
    CreateNewPersonComponent,
    CreateNewPartyGroupComponent,
    CreateNewCommunicationComponent,
    CreateNewPartyClassificationGroupComponent,
    CreateNewSecurityGroupComponent,
    CreateNewUserLoginComponent,
    CreateAddressMatchMapComponent,
    CreatePartyInvitationComponent,
    MainMyPortalComponent,
    CreateProductVariantComponent,
    MyPortalMyCommsComponent,
    MyPortalMyProfileComponent,
    MyPortalOtherPartyCommsComponent,
    MyPortalMyTimeSheetComponent,
    MyPortalMyTasksComponent,
    MyPortalMyLeaveComponent,
    MyPortalMyTrainingComponent,
    MyPortalMyPaymentsComponent,
    MyPortalPreferencesComponent,
    MyPortalPageAdminComponent,
    CreatePublicMessageComponent,
    CreateNewPortalPageComponent,
    ProfilePartyQuickLinkComponent,
    CreateClassificationPartyComponent,
    UpdateProfilePasswordComponent,
    PartySecurityGroupListComponent,
    CreatePartySecutiyGroupComponent,
    CreatePartyContactComponent,
    CreatePartyEmployeApplicationComponent,
    CreateProductStoreComponent,
    DetailInvitationSummaryComponent,
    CreatePartyGroupAssocComponent,
    CreatePartyRoleTypeComponent,
    CreateGroupClassificationComponent,
    DetailSecurityComponent,
    CreateProtectedViewComponent,
    CreatePartyLoginComponent,
    VisitorDetailSummaryComponent,
    PartyClassificationComponent,
    ImportAddressComponent,
    PayrollComponent,
    MainVendorComponent,
    QuickAddShoppingComponent,
    NewImportComponent,
    CompanyInformationImportComponent,
    FiscalYearImportComponent,
    EmailConfigurationImportComponent,
    IssueCreatedImportComponent,
    EditShoppingListComponent,
    CreateTaxInfoComponent,
    CreateAvsStringComponent,
    BalanceSheetImportComponent,
    TrialBalanceImportComponent,
    TrialBalanceOnlyImportComponent,
    CashFlowImportComponent,
    AccountsGroupImportComponent,
    DefaultsAccountsImportComponent,
    CreateTaskComponent,
    SecurityGroupImportComponent,
    TaxComponentImportComponent,
    TaxesOnlyImportComponent,
    PaymentsTermsImportComponent,
    BanksOnlyImportComponent,
    CashiersOnlyImportComponent,
    BranchesOnlyImportComponent,
    OpenCustomersInvoicesImportComponent,
    OpenVendorsInvoicesImportComponent,
    GlAccountBalancesImportComponent,
    SalesRepresentativesImportComponent,
    AccountsByPartFamilyImportComponent,
    UnitOfMeasureImportComponent,
    CostingMethodImportComponent,
    CreditCardProcessingImportComponent,
    CreateLeaveComponent,
    PortalLeaveSummaryComponent,
    CustomersOnlyImportComponent,
    CustomersContactsImportComponent,
    CustomersSiteImportComponent,
    VendorsOnlyImportComponent,
    VendorsContactsImportComponent,
    InventoryCountImportComponent,
    WarehousesOnlyImportComponent,
    WarehousesLocationImportComponent,
    ShipmentsOnlyImportComponent,
    PartsBrandsImportComponent,
    PartsClassImportComponent,
    PartsFamiliesImportComponent,
    PartsGroupsImportComponent,
    PartsCatalogueImportComponent,
    CustomerPartsAliasImportComponent,
    VendorsPartsAliasImportComponent,
    BalanceSheetHeadingsImportComponent,
    GlAccountsImportComponent,
    PortalCreateTaskComponent,
    FinalizeConfigurationImportComponent,
    MainOverviewComponent,
    OverviewHrmMainComponent,
    MainContractComponent,
    MainProductionComponent,
    MainOrderComponent,
    PortalCommunicationComponent,
    PartyCommunicationListComponent,
    UploadCustomerImageComponent,
    UploadCrmCustomerImageComponent,
    DetailAddressMapComponent,
    DetailLoggedUserComponent,
    DetailMainPortalComponent,
    DetailPortalPageAdminComponent,
    DetailPaymentPortalSummaryComponent,
    DetailPortalTrainingSummaryComponent,
    PortalTaskSummaryComponent,
    DetailPortalCommunicationComponent,
    MainPayableComponent,
    MainReceivableComponent,
    DetailBillSummaryComponent,
    DetailPrefernceSummaryComponent,
    OgclReportListComponent,
    OgclDetailReportSummaryComponent,
    FinancialCheckComponent,
    FinancialCheckDetailSummaryComponent,
    DetailWeekListComponent,
    DetailManufacturingRuleComponent,
    GlCostSummaryComponent,
    GlUpdateCustomTimePeriodComponent,
    GlPaymentMethodSummaryComponent,
    GlInvoiceListSummaryComponent,
    MyPortalNewMailComponent,
    MyPortalNewInternalNoteComponent,
    HrmEmployeementComponent,
    DetailEmployementSummaryComponent,
    HrmPerformanceReviewComponent,
    DetailPerformceReviewComponent,
    HrmQualificationComponent,
    DetailQualificationComponent,
    HrmResumeComponent,
    DetailResumeComponent,
    ShipmentConfigComponent,
    DetailShipmentConfigComponent,
    DetailShipmentConfigTypesComponent,
    MainCreatePaycheckComponent,
    MainFindPaycheckComponent,
    MainConfigurationComponent,
    DetailedPaycheckComponent,
    EditPayCheckFormComponent,
    ContractorComponent,
    EmployeesProfileComponent,
    AdministrationComponent,
    RevisionComponent,
    SprintsComponent,
    TasksComponent,
    OpenTestComponent,
    MyProfileComponent,
    MyWorkComponent,
    MainReportsComponent,
    SystemInfoNoteComponent,
    ProductOverviewComponent,
    ProductBacklogComponent,
    ProjectComponent,
    DetailSprintProjectComponent,
    DetailNewSprintComponent,
    MyTasksComponent,
    MyTimeComponent,
    ProjectsComponent,
    RequestListComponent,
    ResourcesComponent,
    SkillTypeComponent,
    TimesheetProjectComponent,
    TasksProjectComponent,
    DetailProjectSummaryComponent,
    DetailTasksComponent,
    TasksSprintBacklogComponent,
    DetailMyTaskComponent,
    WorkEffortComponent,
    TaskListComponent,
    CalendarComponent,
    RequestListEffortComponent,
    MyTimeEffortComponent,
    IcalendarComponent,
    TimesheetEffortComponent,
    SubmittedJobsComponent,
    WorkEffortEffortComponent,
    DetailTaskProjectComponent,
    AddWorkEffortComponent,
    TaskListViewComponent,
    WorkEffortSummaryComponent,
    EditWorkEffortComponent,
    ChildWorkEffortComponent,
    WorkEffortPartyAssignsComponent,
    WorkEffortRatesComponent,
    WorkEffortCommEventsComponent,
    WorkEffortShopListsComponent,
    WorkEffortRequestsComponent,
    WorkEffortRequirementsComponent,
    WorkEffortQuotesComponent,
    WorkEffortOrderHeadersComponent,
    WorkEffortTimeEntriesComponent,
    WorkEffortNotesComponent,
    WorkEffortContentsComponent,
    WorkEffortProductsComponent,
    WorkEffortReviewsComponent,
    WorkEffortKeywordsComponent,
    WorkEffortContactMechsComponent,
    WorkEffortAgreementApplicsComponent,
    WorkEffortFixedAssetsComponent,
    WorkEffortAttributesComponent,
    WorkEffortEventRemindersComponent,
    DetailNewTaskComponent,
    DetailNewRequestComponent,
    UnplannedSprintComponent,
    TimesheetViewComponent,
    TimesheetTimesheetComponent,
    EntriesTimesheetComponent,
    PartiesTimesheetComponent,
    IcalendarViewComponent,
    EditIcalendarComponent,
    ChildIcalendarComponent,
    AddIcalendarPartyComponent,
    AddIcalendarFixedAssetComponent,
    AddIcalendarDataComponent,
    HelpIcalendarComponent,
    EditProfileComponent,
    ListAllProfileComponent,
    UpdateContactComponent,
    EditPartyComponent,
    OverrideEditComponent,
    VisitsMyProfileComponent,
    PartyContentMyProfileComponent,
    SecurityMyProfileComponent,
    UpdateIdentificationNumberComponent,
    NewIdentificationNumberComponent,
    PreferencesMyProfileComponent,
    RolesComponent,
    ProjectMyProfileComponent,
    TasksMyProfileComponent,
    RatesMyProfileComponent,
    RevisionsMyProfileComponent,
    MarketingComponent,
    MainMarketingComponent,
    DataSourceMarketingComponent,
    MarketingCampaignComponent,
    TrackingMarketingComponent,
    SegmentMarketingComponent,
    ContactListMarketingComponent,
    ReportsMarketingComponent,
    EcommerceSiteComponent,
    EditDataSourceTypeComponent,
    MarketingCampaignIdComponent,
    TrackingIdComponent,
    TrackingIdAccessComponent,
    SegmentIdComponent,
    DetailOverviewPageAdminComponent,
    UpdatePostalAddressProfileComponent,
    UpdateContactProfileComponent,
    PartnersComponent,
    CasesComponent,
    TeamComponent,
    TeamDetailComponent,
    PartnersDetailComponent,
    CasesDetailComponent,
    ForecastComponent,
    ForecastDetailComponent,
    PreferencesComponent,
    PreferencesDetailComponent,
    TasksCrmComponent,
    PlanningComponent,
    DistributionComponent,
    SuppliersVendorsComponent,
    DetailSupplierVendorsComponent,
    DetailPlanningComponent,
    DetailDistributionComponent,
    DetailTimesheetComponent,
    CreateTaskFromRequestComponent,
    ViewContactComponent,
    MainAssetComponent,
    AssestTaskListComponent,
    AssetFixedAssetComponent,
    AssetMaintenanceComponent,
    AssetFacilityComponent,
    CreateQuoteFromRequestListComponent,
    EditFixedAssetComponent,
    EditFixedAssetMaintComponent,
    EditFacilityComponent,
    EditTrackingCodeComponent,
    ViewDataSourceComponent,
    ViewDataSourceTypeComponent,
    EditTrackingCodeTypeComponent,
    PromosCatalogComponent,
    PriceRulesCatalogComponent,
    StoresCatalogComponent,
    ProductStoreGroupsCatalogComponent,
    ReviewsCatalogComponent,
    SubscriptionsCatalogComponent,
    ImageManagementCatalogComponent,
    NewProductStoreComponent,
    SubscriptionResourceCatalogComponent,
    TaskListSummaryComponent,
    EditProductPromoComponent,
    EditProductPriceRulesComponent,
    EditProductStoreComponent,
    EditProductStoreGroupComponent,
    EditProductSubscriptionsComponent,
    EditProductProductsComponent,
    EditReviewsCatalogComponent,
    RequestsOrderComponent,
    QuotesOrderComponent,
    OrderListComponent,
    DetailQuotesOrderComponent,
    DetailRequestsOrderComponent,
    StatsComponent,
    ReportsOrderComponent,
    RequirementsComponent,
    MainDetailComponent,
    DetailRequirementsComponent,
    PreferencesAllComponent,
    PreferencesCommComponent,
    PreferencesInternalNoteComponent,
    PreferencesEmailComponent,
    ShipmentTimeEstimateComponent,
    DetailShipmentTimeEstimateComponent,
    ManagerDetailComponent,
    DetailSubscriptionResourceComponent,
    FacilityMainComponent,
    CreateProductPromotionCodeComponent,
    FiledserviceComponent,
    NewworkorderComponent,
    CustomerComponent,
    FsmreportsComponent,
    FsminvoicesComponent,
    FsmpeopleComponent,
    FsmTimeSheetComponent,
    DetailOpenOrderItemReportComponent,
    AllocationPlanComponent,
    DetailAllocationPlanComponent,
    FsmSettingComponent,
    WorkOrdersComponent,
    WorkOrdersPrioritiesComponent,
    WorkOrdersSkillsComponent,
    CancelReasonComponent,
    OnholdReasonsComponent,
    FlagReasonsComponent,
    RepairCodesComponent,
    WorkOrderTypesComponent,
    AlertSettingsComponent,
    PrintTemplatesComponent,
    DispatchBoardSettingsComponent,
    WorkOrderWorkFlowComponent,
    WorkOrderBackDateComponent,
    WorkZoneComponent,
    ModelYourBusinessComponent,
    FsmCompanyComponent,
    MobileSettingsComponent,
    FsmFinancialComponent,
    FsmCustomersComponent,
    TimeSheetComponent,
    ServiceAgreementsComponent,
    WorkOrderNetworkComponent,
    WorkZonesComponent,
    ServiceTeamsComponent,
    DispatchCentersComponent,
    ServicePackagesComponent,
    EquipmentTypesComponent,
    AttributesComponent,
    CustomFieldsComponent,
    TerminologyComponent,
    SubscriptionDetailsComponent,
    UploadLogoComponent,
    DocumentTypesComponent,
    FsmAppearanceComponent,
    FsmLandmarksComponent,
    FsmIntegrationComponent,
    ClassSettingsComponent,
    ImageManagementMainDetailComponent,
    ImageManagementManageDetailComponent,
    MyWorkSettingComponent,
    PrimaryWorkOrderComponent,
    NonPrimaryWorkOrderComponent,
    DeviceSettingComponent,
    ImageManagementRecentlyApprovedDetailComponent,
    ImageManagementReplaceDetailComponent,
    BasicInvoiceSettingComponent,
    PostingInvoicesComponent,
    SalesTaxItemsComponent,
    SalesTaxGroupsComponent,
    SalesTaxCodesComponent,
    InvoiceStatusComponent,
    PriceListComponent,
    PriceLitGroupsComponent,
    PaymentProcessingComponent,
    QbmsIntegrationComponent,
    PrintsTemplatesComponent,
    FsmNotificationsComponent,
    DetailConfigurationsComponent,
    TimeSheetPeriodComponent,
    WorkFlowSettingsComponent,
    OverTimeRulesComponent,
    RoundingRulesComponent,
    ListViewConfigurationComponent,
    ReconcileFlagRulesComponent,
    PmSettingsComponent,
    SeasonSettingsComponent,
    SettingWorkOrderNetworkComponent,
    WonBusinessListingComponent,
    CreateSimpleTextComponent,
    DetailCatalogComponent,
    RepairCategoryComponent,
    EditWorkZoneComponent,
    DefaultServiceTeamComponent,
    MyServicePackageComponent,
    EquipmentServiceComponent,
    NewCustomerComponent,
    WebsiteListComponent,
    WebsiteMainComponent,
    SurveyComponent,
    PartsComponent,
    PriceListItemComponent,
    ForumComponent,
    BlogComponent,
    ContentComponent,
    DataResourceComponent,
    ContentSetupComponent,
    DataresourceSetupComponent,
    TemplateComponent,
    ContentCmsComponent,
    CompDocComponent,
    ContentDetailPageComponent,
    FsmTechnicianComponent,
    FsmCustomerComponent,
    FsmTechInvoiveComponent,
    MainDetailPageComponent,
    SurveyDetailPageComponent,
    ForumDetailPageComponent,
    DataresourceDetailPageComponent,
    DataresourceFindComponent,
    TechnicianResetComponent,
    CustomerResetComponent,
    ProblemDetailComponent,
    CustomerDetailComponent,
    EditContentPurposeOperationComponent,
    EditContentOperationComponent,
    EditContentAssociationPredicateComponent,
    EditContentTypeAttributeComponent,
    EditContentPurposeTypeComponent,
    EditContentAssociationTypeComponent,
    ContentPermissionComponent,
    CustomerSummaryPageComponent,
    CharacterSetComponent,
    CategoryComponent,
    TypeAttributeComponent,
    FileExtComponent,
    MetaDataPredicateComponent,
    MimeTypeComponent,
    MimeTypeTemplateComponent,
    FindResponseComponent,
    ResposeSummaryComponent,
    ListCreatedTemplateComponent,
    BlogDetailPageComponent,
    RolesPageComponent,
    BlogsArticlesDetailPageComponent,
    SubSitesComponent,
    IndexComponent,
    FindComponent,
    SearchProductsComponent,
    CompdocDetailPageComponent,
    EditCompdocPageComponent,
    ApprovalsPageComponent,
    RevisionPageComponent,
    NewRootPageComponent,
    ViewWaitingApprovalsComponent,
    WebposLoginPageComponent,
    MessageDetailPageComponent,
    HardwareMainComponent,
    HardwareDetailPageComponent,
    SoftwareMainComponent,
    SoftwareDetailPageComponent,
    BirtMainComponent,
    SendFormatThroughMailComponent,
    FlexibleMainComponent,
    ForumsPageComponent,
    ForumPurposesComponent,
    ForumRolesComponent,
    EditComponent,
    ListOfBlogsComponent,
    FindDetailComponent,
    CmsSummaryComponent,
    StaffingComponent,
    MainBiComponent,
    ReportBuilderComponent,
    UpdateReportComponent,
    DetailReportBuilderComponent,
    ConfigureWarehouseMainComponent,
    FindRequirementsMainComponent,
    InternalRequirementsMainComponent,
    ProductRequirementsMainComponent,
    RunMrpMainComponent,
    SalesOrderHistoryMainComponent,
    TransferRequirementsMainComponent,
    ViewMrpMainComponent,
    RequirementsMainComponent,
    FindRequirementsDetailComponent,
    OpenRequirementsDetailComponent,
    ReviewOrderComponent,
    PurchaseOrderQuickEntryComponent,
    PurchaseOrderComponent,
    ConfigureWarehouseDetailComponent,
    ReportMainComponent,
    ConfigureWarehouseExistingDetailComponent,
    NewEmployeeContractsComponent,
    TrackDistributorComponent,
    SalesOrderDistributorComponent,
    EmployeeContractsComComponent,
    SearchByLabelsComponent,
    DetailInventoryItemsComponent,
    MyPortalCreatePaymentsComponent,
    MyPortalCreateInvoiceComponent,
    MyPortalNewInvoiceComponent,
    OrganizationGlSettingNewComponent,
    FindUserLoginComponent,
    FindSecurityGroupComponent,
    CertIssuersComponent,
    CommunicationEmailOverviewComponent,
    FindCommunicationComponent,
    MyCasesComponent,
    HubDashboardComponent,
    FinancialsDashboardComponent,
    VendorDashboardComponent,
    OrdersDashboardComponent,
    SupplyDashboardComponent,
    PSADashboardComponent,
    CommunicationDashboardComponent,
    HRMSDashboardComponent,
    POSDashboardComponent,
    ECommerceDashboardComponent,
    ReportsDashboardComponent,
    SettingDashboardComponent,
    MyProtalDashboardComponent,
    CustomerServiceComponent,
    FSMDashboardComponent,
    TimesheetSummaryComponent,
    WarehouseDashboardOverviewComponent,
    CrmNewDocumentComponent,
    InactiveCustomerComponent,
    PartyDashboardComponent,
    // IsMgrDashboardComponent,
    WarehouseShipmentConfigComponent,
    InactiveCustomerSummaryComponent,
    DashboardSecurityComponent,
    DashboardIsmgrComponent,
    CrmDashboardPageComponent,
    PIMOverviewComponent,
    EdiOverviewComponent,
    CustomerSummaryComponent,
    SalesDocumentsComponent,
    NewForecastComponent,
    SalesNotesComponent,
    SalesCalendarComponent,
    NewAccountsComponent,
    CustomerActivitiesComponent,
    CustomerCasesComponent,
    MarketingCreateContentComponent,
    DetailNoteComponent,
    CreateNewSubsidiaryComponent,
    FsmContractComponent,
    WorkOrderSummaryComponent,
    CommerceDashboardComponent,

  ],
  imports: [
    FullCalendarModule,
    NgWizardModule.forRoot(ngWizardConfig),
    BrowserModule,
    ChartsModule,
    TreeModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    AppRoutingModule,
    NgxSpinnerModule,
    MultiSelectModule,
    // ScheduleModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    TableModule,
    CalendarModule,
    CheckboxModule,
    TooltipModule,
    CalendarPrime,
    HttpClientModule,
    FontAwesomeModule,
    RouterModule.forRoot(appRoutes),
    FlatpickrModule.forRoot(),
    ModalModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    NgbModule,
    NgxDailyGanttChartModule,
    CKEditorModule,
    NgApexchartsModule,



  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true
      // DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService
    },
    LoginGuard,
    MyQuotesResolver,
    NewProductsResolver,
    DetailProductSummaryResolver,
    DetailCategorySummaryResolver,
    ThesaurusResolver,
    CreateNewFacilitiesResolver,
    AgreementSummaryItemResolver,
    DatePipe,
    BsModalRef
  ],
  entryComponents: [DeleteConfirmationComponent],
  exports: [NumberOnlyDirective],
  bootstrap: [AppComponent]
})
export class AppModule { }