<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Payment Group Overview For {{paymentGroupId}} </span>
                <button style="margin-left: 56%;" type="button" class="btn btn-outline-secondary" (click)="downloadPDF()" [disabled]="downloading">
                    <ng-container *ngIf="downloading;else noDownload">
                        PDF <fa-icon [icon]="faSpinner" [pulse]="true"></fa-icon>
                    </ng-container>
                    <ng-template #noDownload>
                        PDF
                    </ng-template>
                </button>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                    <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span> 
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">
                    </div>
                </div>

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            <div class="divA">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class="common-styling hCard">
                                                            Payment Group Information
                                                        </h4>
                                                        <span class="px-2 edit-buttons " style="float: left !important;cursor: pointer;" data-toggle="modal" data-target="#exampleModalCenter" (click)="edit()">Update</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <ng-container *ngIf="paymentGroup">
                                                                    <div class="row">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Payment Group ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{paymentGroup.paymentGroupId}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Type</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{paymentGroup.paymentGroupType}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Payment Group Name
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{paymentGroup.paymentGroupName}}</span>

                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Fin Account Name</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{paymentGroup.finAccountName}}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Owner Party ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{paymentGroup.ownerPartyId}}</span>
                                                                            </div>




                                                                        </div>


                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class="common-styling hCard">
                                                            Payment Group Members
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select allUserCardDetail">

                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="paymentGroupMember"   scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                    Payment ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" price class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                <div style="color: white;"> From Party
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                                <div style="color: white;"> To Party
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                                <div style="color: white;"> Payment Type
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                                <div style="color: white;"> Payment Status
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Amount
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="fromDate">
                                                                                <div style="color: white;"> From Date
                                                                                </div> <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="thruDate">
                                                                                <div style="color: white;"> Through Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" price value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                    <span class="account-button"> {{product.paymentId}}</span>
                                                                                   
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                {{product.partyIdFrom}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                {{product.partyIdTo}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                {{product.paymentType}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                {{product.status}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                {{product.amount}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                {{product.fromDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                {{product.thruDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <span class="text-center">
                                                                                           <h4 class="ml-2">No Record Found
                                                                                           </h4>
                                                                                       </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <!-- <p class="paginate_data">
                                                                    View per page </p> -->
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <br>

                                            </div>

                                            <div class="divB">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class="common-styling hCard">
                                                            Add & List of Payment Group Members
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="borderTable">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                                <form class="w-100" [formGroup]="paymentGroupMemberForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Payment
                                                                                        ID</label>
                                                                                </div>

                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown filter="true" [options]="paymentIds" optionlabel="label" formControlName="paymentId" placeholder="Select Payment">
                                                                                    </p-dropdown>
                                                                                </div>

                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">From
                                                                                        Date</label>
                                                                                </div>

                                                                                <div class="col-lg-3">
                                                                                    <p-calendar placeholder="Select Date" formControlName="fromDate">
                                                                                    </p-calendar>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Through
                                                                                        Date</label>
                                                                                </div>

                                                                                <div class="col-lg-3">
                                                                                    <p-calendar formControlName="thruDate" placeholder="Select Date">
                                                                                    </p-calendar>
                                                                                </div>

                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Sequence
                                                                                        Num</label>
                                                                                </div>

                                                                                <div class="col-lg-3">
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" formControlName="sequenceNum" aria-describedby="emailHelp" placeholder="Sequence Num">
                                                                                </div>


                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div class="col-lg-12 col-12 main-submit-button ">
                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="submit()">{{editMode ? 'Update':'Add'}}</button>
                                                                <button class="btn btn-danger cursor-pointer ml-2" (click)="reset()">Reset</button>
                                                            </div>
                                                        </div>
                                                        <div class="all-users-infomation border-top-grey font-13 font-medium own-user-select allUserCardDetail">

                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="groupMembers"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Payment ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                                <div style="color: white;"> From Party
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                                <div style="color: white;"> To Party
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                                <div style="color: white;"> Payment Type
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Amount
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Created Date
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Action</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                    <span class="account-button">{{product.paymentId}}</span>
                                                                                    
                                                                                </div>
                                                                            </td>



                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                {{product.fromParty}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                {{product.partyIdTo}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                {{product.paymentType}} </td>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                {{product.amount |currency :'USD'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                <fa-icon  class="account-button"  [icon]="faEdit" (click)="makeGroupMemberEditable(product.paymentId, product.fromDate, product.thruDate, product.paymentRefNum)">
                                                                                </fa-icon>
                                                                                <fa-icon (click)="cancelGroupMember(product.paymentId, product.fromDate)" class="text-danger cursor-pointer ml-2" [icon]="faTrash"></fa-icon>
                                                                            </td>
                                                                        </tr>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'190px'}" [ngStyle]="{'width':'190px'}">
                                                                                    <span class="text-center">
                                                                                               <h4 class="ml-2">No Record
                                                                                                   Found</h4>
                                                                                           </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </ng-template>
                                                                </p-table>

                                                                <!-- <p class="paginate_data">
                                                                    View per page </p> -->
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                                <br>








                                            </div>









                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="main-submit-button">
                <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
            </div> -->
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Payment Group</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                            {{editMode ?'Update':'Create'}} Payment Group</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="paymentGroupForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="paymentGroupTypes" placeholder="Select Type" optionlabel="label" formControlName="paymentGroupTypeId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA" *ngIf="editMode">
                                                        <label for="payment">Payment Group ID </label>
                                                    </div>

                                                    <div class="col-lg-3" *ngIf="editMode">
                                                        <input type="text" class="form-control" [value]="paymentGroupId" readOnly>
                                                    </div>




                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Payment Group Name <span
                                                        class="text-danger">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" formControlName="paymentGroupName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Payment Group Name">

                                                        <small class="text-danger" *ngIf="!!paymentGroupForm.controls.paymentGroupName.invalid && !!paymentGroupForm.controls.paymentGroupName.touched">Payment
                                                Group Name is required</small>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -8%;">
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="submitData()">{{editMode ?'Update':'Create'}}</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>