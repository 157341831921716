import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { Location } from '@angular/common';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-edit-tracking-code-type',
  templateUrl: './edit-tracking-code-type.component.html',
  styleUrls: ['./edit-tracking-code-type.component.css']
})
export class EditTrackingCodeTypeComponent implements OnInit {
  @ViewChild('closebuttonA') closebuttonA;
  CreateTrackingCodeTypeForm: any;
  TrackCodeTypeList: any;
  trackCodeTypeId: any;
  dataSourceTypeById: any;
  trackCodeTypeById: any;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
        private _location: Location,
        readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) { 
    this.CreateTrackingCodeTypeForm = this._FormBuilder.group({
      TrackingCodeTypeID: [''],
      Description: [''],
      
    });
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.trackCodeTypeId = params.trackingCodeTypeID;
    })
    this.getTrackCodeTypeList();
    this.getTrackCodeTypeById();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  cancelSubmit(){
    this._location.back();
  }
  getTrackCodeTypeById() {
    this.spinner.show();
    this.myContactsService.getTrackCodeTypeById(this.trackCodeTypeId).subscribe(res => {
      this.trackCodeTypeById = res.data[0].data[0];
      setTimeout(() => {
      
        const formValue = this.CreateTrackingCodeTypeForm;
        formValue.patchValue({
          TrackingCodeTypeID: this.trackCodeTypeById.trackingCodeTypeId ,
          Description:this.trackCodeTypeById.description,
        })
      
      }, 2000);
      this.spinner.hide();
    })
  }
  updateTrackingCodeType(): void {

    const requestData ={
      "description": this.CreateTrackingCodeTypeForm.value.Description,
      "trackingCodeTypeId": this.CreateTrackingCodeTypeForm.value.TrackingCodeTypeID,
      
      }
     
    this.accountsService.updateTrackingCodeType(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonA.nativeElement.click();
        this.getTrackCodeTypeList(); 
        this.getTrackCodeTypeById();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    getTrackCodeTypeList() {
      this.spinner.show();
      this.accountsService.getTrackCodeTypeList().subscribe(res => {
        this.TrackCodeTypeList = res.data[0].data;
        this.spinner.hide();
     
      })
    }
  ngOnDestroy(): void {
    this.spinner.hide();
   
  }
}
