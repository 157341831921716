import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';

@Component({
  selector: 'app-upload-crm-customer-image',
  templateUrl: './upload-crm-customer-image.component.html',
  styleUrls: ['./upload-crm-customer-image.component.css']
})
export class UploadCrmCustomerImageComponent implements OnInit {

  imageFile:FormGroup;
  partyId: any;
  fileData: File;
  activeCategory=2;
  employeeUser: string;
  constructor(readonly router: Router,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    private _location: Location,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,) { 
    this.imageFile = this._FormBuilder.group({
      image: [''],
     
 
    });}

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["demoId"];
    })

  }
  
  onSubmit(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file",this.fileData);
    this.employeeUser="employee";
    this.accountsService.uploadEmployeePersonalImage(formData, this.partyId,this.employeeUser).subscribe((res:any)=>{
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.router.navigate(['/hr/employees/employee-details'],{ queryParams: { employeeDetailId: this.partyId} });

      }     },(err)=>{
        this.spinner.hide();
        this.toastr.error(err.message);
     })
    
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  back(){
    this.router.navigate(['/hr/employees/employee-details'],{ queryParams: { employeeDetailId: this.partyId} });
  }

}
