<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Party Attributes
                </span>
                <span class="color-black pl-1" > >Create Party Attributes
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                        Create Party Attributes
                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                        Update Party Attributes
                    </a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createAttribue">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Attribute name<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="text"  *ngIf="!show" formControlName="attrName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Attr name"> 
                                                <input type="text" *ngIf="show"  formControlName="attrName"  class="form-control" id="exampleInputEmail1"
                                                readonly aria-describedby="emailHelp" placeholder="Enter Attr name"> 
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Attribute Value<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text"  formControlName="attrValue"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Attr Value">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Attribute Description<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="text"  formControlName="attrDescription"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Attr Description">
                                            </div>
                                         
                                        </div>
                                    </div>
                                  
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 67%;">
                               
                                <button type="submit" *ngIf="!show" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                <button type="submit" *ngIf="show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div> -->

<div class="modal fade" id="attrModalCenter" tabindex="-1" role="dialog" aria-labelledby="attrModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">Party Attributes</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li class="active"><a>
                                    Create Party Attributes
                                </a></li>
                                <!-- <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                    Update Party Attributes
                                </a></li> -->
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form  class="w-100" [formGroup]="createAttribue">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Attribute name<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="attrName"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Attr name"> 
                                                        <input type="text" *ngIf="show"  formControlName="attrName"  class="form-control" id="exampleInputEmail1"
                                                        readonly aria-describedby="emailHelp" placeholder="Enter Attr name"> 
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Attribute Value<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="attrValue"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Attr Value">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Attribute Description<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="attrDescription"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Attr Description">
                                                    </div>
                                                 
                                                </div>
                                            </div>
                                          
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: -10%;">
                                       
                                        <button type="submit"  *ngIf="!show"  (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>
                                        
                                        
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div>

