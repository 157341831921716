 <div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;"> 

    <div class="">
        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
            <span *ngIf="this.productFeatureId" class="color-grey">Feature ID : {{productFeatureId}}</span>
            <span class="d-flex">
     
                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
    
        </div>
        <div class="container-fluid" style="padding: 0px 0px;">
            <div class="col-md-12 p-0" >
                    <div class="row">
                        <form class="w-100">
                            <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                <div class="w3-card-4 classCard w-100">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                        style="justify-content: space-between;">
                                        <p class="h-text">Summary</p>
                                        <span>
                                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                style="margin: 0;" data-toggle="modal"
                                                data-target="#updateFeaturePopup" (click)="updateProductFeaturePatch()">Update</button>
                                        </span>
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        
                                        <div *ngIf="this.getProductFeatureByIdAll" class="color-black container">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Feature ID :</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                            {{getProductFeatureByIdAll.productFeatureId}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Feature Type :</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{getProductFeatureByIdAll.productFeatureType}}
                                                           
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Feature Category :</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{getProductFeatureByIdAll.productFeatureCategory}}
                                                           
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Description :</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{getProductFeatureByIdAll.description}}

                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Unit of Measure ID :</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{getProductFeatureByIdAll.uomId}}
                                                          
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Number / Quantity :</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{getProductFeatureByIdAll.quantity}}
                                                           
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Default Amount :</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{getProductFeatureByIdAll.amount}}
                                                           
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Default Sequence Number :</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{getProductFeatureByIdAll.IDSeqNum}}
                                                           
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Abbreviation :</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{getProductFeatureByIdAll.abbrev}}
                                                           
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">ID Code :</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{getProductFeatureByIdAll.idCode}}
                                                           
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                              
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

               
                <div class="w3-card-4 classCard w-100 mt-2 " style="width: 100% !important;">
                    <div
                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Edit Feature Price
                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0; "
                                data-toggle="modal" data-target="#addFeaturePricePopup" (click)="resetForm()">Add Feature Price
                                </button>
                        </p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">

                        <div class="col-lg-12 col-12">
                            <div class="form-group">

                                <div class="card own-account-table">
                                    <p-table [value]="getFeaturePriceData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                                <th width="50%" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: white;">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        Price Type
                                                        <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th width="50%" pSortableColumn="name">
                                                    <div style="color: white;"> Currency
                                                        <p-sortIcon field="name"></p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th width="50%" pSortableColumn="name">
                                                    <div style="color: white;"> From Date
                                                        <p-sortIcon field="name"></p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th width="50%" pSortableColumn="name">
                                                    <div style="color: white;"> Through Date
                                                        <p-sortIcon field="name"></p-sortIcon>
                                                    </div>
                                                
                                                </th>

                                                <th width="50%" pSortableColumn="name">
                                                    <div style="color: white;"> Price
                                                        <p-sortIcon field="name"></p-sortIcon>
                                                    </div>
                                                
                                                </th>

                                                <th width="50%" pSortableColumn="name">
                                                    <div style="color: white;"> Last Modified By
                                                        <p-sortIcon field="name"></p-sortIcon>
                                                    </div>
                                                
                                                </th>
                                                <th width="50%" pSortableColumn="name">
                                                    <div style="color: white;">Action
                                                      
                                                    </div>
                                                
                                                </th>

                                        
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td width="50%">
                                                    <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            
                                                        </p-checkbox>
                                                        <span  class="account-button" >
                                                            {{product.productPriceTypeId}}

                                                        </span>
                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    {{product.currency}}[{{product.currencyUomId}}]
                                                </td>
                                                <td width="50%">
                                                    {{product.fromDate|date}}
                                                </td>
                                                <td width="50%">
                                                    {{product.thruDate|date}}
                                                </td>
                                                <td width="50%">
                                                    {{product.price}}
                                                </td>
                                                <td width="50%">
                                                    {{product.lastModifiedByUserLogin}}
                                                </td>

                                                <td [ngStyle]="{ 'width': '180px'}">
                                                    <small *ngIf="!this.Hidebtn" style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                    pointer; " data-toggle="modal" data-target="#addFeaturePricePopup" (click)="updateFeaturePricePatch(product)">Update</small>
                                                    <small *ngIf="!this.Hidebtn" style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                    pointer; " (click)="deleteFeaturePrice(product)">Delete</small>

                                               

                                                </td>
                                        

                                            </tr>
                                        </ng-template>
                                    </p-table>

                                    <p class="paginate_data">
                                        View per page </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w3-card-4 classCard w-100 mt-2 " style="width: 100% !important;">
                    <div
                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Supplier Specific Feature Information
                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0; "
                                data-toggle="modal" data-target="#createInformationSupplierPopup" (click)="resetForm()">Add Supplier Specific Feature Information
                                </button>
                        </p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">

                                    <div class="card own-account-table">
                                        <p-table [value]="getSupplierProductFeatureById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                            <ng-template pTemplate="header">
                                                <tr style="background: #0d3769;">
                                                    <th width="50%" pSortableColumn="code">
                                                        <div class="checkbox-align" style="color: white;">
                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                            </p-checkbox>
                                                            Suppliers
                                                            <p-sortIcon field="code">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>
                                                    <th width="50%" pSortableColumn="name">
                                                        <div style="color: white;"> Product Description
                                                            <p-sortIcon field="name"></p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>

                                                    


                                                    <th width="50%" pSortableColumn="name">
                                                        <div style="color: white;"> Currency
                                                            <p-sortIcon field="name"></p-sortIcon>
                                                        </div>
                                                    
                                                    </th>

                                                    <th width="50%" pSortableColumn="name">
                                                        <div style="color: white;"> ID Code
                                                            <p-sortIcon field="name"></p-sortIcon>
                                                        </div>
                                                    
                                                    </th>
                                                    <th width="50%" pSortableColumn="name">
                                                        <div style="color: white;"> Action
                                                            <p-sortIcon field="name"></p-sortIcon>
                                                        </div>
                                                    
                                                    </th>

                                            
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-product>
                                                <tr>
                                                    <td width="50%">
                                                        <div class="checkbox-align">
                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                
                                                            </p-checkbox>
                                                            <span  class="account-button" >
                                                                {{product.partyId}}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td width="50%">
                                                        {{product.description}}
                                                    </td>
                                                    <td width="50%">
                                                        {{product.uomId}}
                                                    </td>
                                                    <td width="50%">
                                                        {{product.idCode}}
                                                    </td>

                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                        <small *ngIf="!this.Hidebtn" style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                        pointer; " data-toggle="modal" data-target="#createInformationSupplierPopup" (click)="updateSupplierProductFeaturePatch(product)">Update</small>
                                                        <small *ngIf="!this.Hidebtn" style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                        pointer; " (click)="deleteSupplierProductFeature(product)">Delete</small>
    
                                                   
    
                                                   
    
                                                    </td>
                                            

                                                </tr>
                                            </ng-template>
                                        </p-table>

                                        <p class="paginate_data">
                                            View per page </p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    
                        
                           
                       

                    
                </div>
                </div>
            </div>
        </div>
 </div> 

 <div class="modal fade" id="addFeaturePricePopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="this.show" class="color-black pl-1"> Add Feature Price</span>
                <span *ngIf="!this.show" class="color-black pl-1">Update Feature Price</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton2>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                             <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="this.show"><a>
                                    Add Feature Price</a></li>
                                <li *ngIf="!this.show"><a>
                                    Update Feature Price</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="addFeaturePriceForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12 mb-20"></div>

                                            


                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Price Type</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                        placeholder="Select Price Type"
                                                        formControlName="priceType" [options]="productPriceTypeByIdArray" [readonly]="readOnlyCode">
                                                    </p-dropdown>

                                                    </div>

                                                    
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">From Date
                                                       </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" [readonly]="readOnlyCode">

                                                    </div>

                                                    

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Through Date 
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thorughDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" >
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Price
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="datetime" formControlName="price" class="form-control" id="exampleInputEmail1"
                                                         aria-describedby="emailHelp" placeholder="Enter Price">

                                                    </div>


                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Currency</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                        placeholder="Choose Currency"
                                                        formControlName="currency" [options]="currencyArray" [readonly]="readOnlyCode">
                                                    </p-dropdown>

                                                    </div>

                                                    

                                                    
                                                </div>
                                            </div>

                                           


                                        </div>


                                        <div class="main-submit-button" style="margin-right: 16%;">
                                            <button *ngIf="this.show" type="submit"  class="btn btn-secondary submit-btn" (click)="createFeaturePrice()">Add</button>
                                            <button *ngIf="!this.show" type="submit"  class="btn btn-secondary submit-btn" (click)="updateFeaturePrice()">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="createInformationSupplierPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="this.show" class="color-black pl-1"> Create information for new Supplier</span>
                <span *ngIf="!this.show" class="color-black pl-1"> Update information for new Supplier</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton3>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                             <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="this.show"><a>
                                    Create information for new Supplier
                                </a></li>
                                <li *ngIf="!this.show"><a>
                                    Update information for new Supplier
                                </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createInformationForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12 mb-20"></div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Supplier</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                        placeholder="Select Supplier"
                                                        formControlName="supplier" [readonly]="readOnlyCode" [options]="supplierListArray">
                                                    </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Description
                                                       </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <textarea formControlName="description" class="form-control" 
                                                        id="exampleInputEmail1" placeholder="Enter Description">

                                                        </textarea>
                                                    </div>

                                                    
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Currency</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                        placeholder="Select Currency"
                                                        formControlName="currency" [options]="currencyArray">
                                                    </p-dropdown>

                                                    </div>
                                                   

                                                    

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">ID Code 
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="idCode" class="form-control" 
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter ID Code">
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            

                                           


                                        </div>


                                        <div class="main-submit-button" style="margin-right: 16%;">
                                            <button *ngIf="this.show" type="submit"  class="btn btn-secondary submit-btn" (click)="addSupplierProductFeature()">Create</button>
                                            <button *ngIf="!this.show" type="submit"  class="btn btn-secondary submit-btn" (click)="updateSupplierProductFeature()">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="updateFeaturePopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Feature 
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton4>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                             <ul class="tabbing-section tabbing-accordians tabClass">

                                <li ><a>
                                    Update Feature 
                                </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="updateFeatureForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12 mb-20"></div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Feature ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="featureID" class="form-control" 
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Feature ID" [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Feature Type
                                                       </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                        placeholder="Select Feature Type "
                                                        formControlName="featureTypes" [options]="getFeatureTypeIdListArray">
                                                    </p-dropdown>
                                                    </div>

                                                    
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Feature Category</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                        placeholder="Select Feature Category "
                                                        formControlName="featureCategorys" [options]="getFeatureCategoryIdListArray">
                                                    </p-dropdown>

                                                    </div>
                                                   

                                                    

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Description
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <textarea formControlName="description" class="form-control" placeholder="Enter Description ">

                                                        </textarea>

                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Unit of Measure ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                        placeholder="Enter Unit of Measure ID"
                                                        formControlName="measuereID" [options]="currencyArray">
                                                        </p-dropdown>
                                                       


                                                    </div>
                                                   

                                                    

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Number / Quantity</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="numberQuantity" class="form-control" placeholder="Enter Number / Quantity"
                                                         id="exampleInputEmail1" aria-describedby="emailHelp" >


                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Default Amount</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="DefaultAmount" class="form-control" 
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Amount" >


                                                    </div>
                                                   

                                                    

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Default Sequence Number</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="defaultSequenceNumber" class="form-control" 
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Sequence Number">


                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Abbreviation</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="abbreviation" class="form-control" 
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Abbreviation" >


                                                    </div>
                                                   

                                                    

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">ID Code</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="idCode" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter ID Code">


                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            

                                           


                                        </div>


                                        <div class="main-submit-button" style="margin-right: 17%;">
                                            <button type="submit"  class="btn btn-secondary submit-btn" (click)="updateProductFeature()">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>



																	 