import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-work-effort-event-reminders',
  templateUrl: './work-effort-event-reminders.component.html',
  styleUrls: ['./work-effort-event-reminders.component.css']
})
export class WorkEffortEventRemindersComponent implements OnInit {
  activeCategory = 22;
  editEventReminders: any;
  updateEventReminder: any;
  total = 0;
  @ViewChild('closebutton') closebutton;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  workEffortId: any;
  WorkEffortEventReminderById: any;
  personId: any;
  partyIdArray: any[]=[];
  ContactMechId: any;
  ContactMechIdArray: any[]=[];
  actualfromdate: string;
  show: boolean;
  getEstimateFrom: string;
  sequenceId: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor( public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly addSkillService: AddSkillService,
    readonly accountsService: AccountsService,
    private _location: Location,
    
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router){
    this.editEventReminders = this._FormBuilder.group({
      contactMechID: [''],
      partyID: [''],
      estimatedStartDate: [''],
      repeatCount: [''],
      repeatInterval: [''],
      repeatOffset: [''],
    })

  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params['workEffortId'];

    });
    this.getWorkEffortEventReminderById();
    this.getPartyId();
    this.getContactMechId();
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getContactMechId() {
    this.spinner.show();
    this.accountsService.getContactMechId().subscribe(res => {
      this.ContactMechId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContactMechId) {
        this.ContactMechIdArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
   
  }
  
  deleteWorkEffortEventReminder(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "contactMechId":product.contactMechId,
          "sequenceId": product.sequenceId,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "workEffortId":this.workEffortId
        }
        this.accountsService.deleteWorkEffortEventReminder(req).subscribe(res => {
          this.getWorkEffortEventReminderById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  
  updateWorkEffortEventReminder(): void {
    this.spinner.show();
    const fromActualdate=this.editEventReminders.get('estimatedStartDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
  "contactMechId":  this.editEventReminders.value.contactMechID,
  "partyId":  this.editEventReminders.value.partyID,
  "reminderDateTime": this.actualfromdate,
  "reminderOffset":  this.editEventReminders.value.repeatOffset,
  "repeatCount":  this.editEventReminders.value.repeatCount,
  "repeatInterval":  this.editEventReminders.value.repeatInterval,
  "workEffortId":this.workEffortId,
  "sequenceId":this.sequenceId
  
      }
     
    this.accountsService.updateWorkEffortEventReminder(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.show=false;
        this.closebutton.nativeElement.click();
        this.getWorkEffortEventReminderById(); 
        
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  createWorkEffortEventReminder(): void {
    this.spinner.show();
    const fromActualdate=this.editEventReminders.get('estimatedStartDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
  "contactMechId":  this.editEventReminders.value.contactMechID,
  "localeId": "en_US",
  "partyId":  this.editEventReminders.value.partyID,
  "reminderDateTime": this.actualfromdate,
  "reminderOffset":  this.editEventReminders.value.repeatOffset,
  "repeatCount":  this.editEventReminders.value.repeatCount,
  "repeatInterval":  this.editEventReminders.value.repeatInterval,
  "timeZoneId": "Asia/Calcutta",
  "workEffortId":this.workEffortId     
      }
     
    this.accountsService.createWorkEffortEventReminder(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getWorkEffortEventReminderById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
updateEvent(product){
  this.show=true;
  this.spinner.show();
 
    this.sequenceId=product.sequenceId
    setTimeout(() => {
      const fromdate=product.reminderDateTime;
      this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
     
      const formValue = this.editEventReminders;
      formValue.patchValue({
        contactMechID: product.contactMechId,
    partyID:product.partyId,
    estimatedStartDate:this.getEstimateFrom,
    repeatCount:product.repeatCount,
    repeatInterval:product.repeatInterval,
    repeatOffset: product.reminderOffset,
      })
    
    }, 3000);

}
  getWorkEffortEventReminderById(){
    this.spinner.show();
   
    this.accountsService.getWorkEffortEventReminderById(this.workEffortId).subscribe((res:any)=> {
      this.WorkEffortEventReminderById = res.data[0].workEffortAttributeById;
      this.spinner.hide();
    
    })   
   }
  
  addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  editWorkEffort1(){
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  summary(){
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"],{ queryParams: { workEffortId: this.workEffortId} })
  }

  childWorkEfforts(){
    this._Router.navigate(["/psa/work-effort/task-list-view/childWorkEfforts"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  partyAssigns(){
    this._Router.navigate(["/psa/work-effort/task-list-view/PartyAssigns"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  rates(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Rates"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  commEvents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/CommEvents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  shopLists(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ShopLists"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requests(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requests"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requirements(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requirements"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  quotes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Quotes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  orderHeaders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/OrderHeaders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  timeEntries(){
    this._Router.navigate(["/psa/work-effort/task-list-view/TimeEntries"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  notes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Notes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Contents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  products(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Products"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  reviews(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Reviews"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  keywords(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Keywords"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contactMechs(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ContactMechs"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  agreementApplics(){
    this._Router.navigate(["/psa/work-effort/task-list-view/AgreementApplics"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  fixedAssets(){
    this._Router.navigate(["/psa/work-effort/task-list-view/FixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  attributes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Attributes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  eventReminders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/EventReminders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  addEffortEventReminder(){
    this.show=false;
    this.editEventReminders.reset();
  }
}
