import { Component, OnInit } from '@angular/core';
import { LeadService } from './lead-service';
import { LeadServices } from './leads.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.css']
})
export class LeadsComponent implements OnInit {
  array: any = [];
  countryGeoId: any;
  stateData: any;
  stateArray: any = [];
  leadSource: any;
  leadArray: any = [];
  contactList: any;
  contactListArray: any = [];
  leadId: any;
  show = false;
  gender: any = [];
  crmStatus: any;
  crmArray: any = [];
  datailedData: any;
  activeCategory = 2;
  // countryCode;

  constructor(
    public leadService: LeadService,
    readonly activatedRoute: ActivatedRoute,
    readonly spinner:NgxSpinnerService,
    readonly leadServices: LeadServices,
    readonly toastr: ToastrService, 
    readonly router: Router) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.leadId = params["leadId"];
    });
    if (this.leadId) {
      this.show = true;
    }
    else {
      this.show = false;
    }
   
    this.getLeadSource()
    this.getCountryList();
    this.getContactList();
    this.getStatusCrm();
    this.getLeadById();
    this.leadService.lead_form = null;
    this.leadService.leadInfoForm(this.leadService.lead_form);
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    this.gender.push({
      "label": "Male",
      "value": "M"
    },
      {
        "label": "Female",
        "value": "F"
      })
  }

  getCountryList() {
    this.spinner.show();
    this.leadServices.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })

      })

    })
  
  }
  getLeadById() {
    this.spinner.show();
    this.leadServices.getLeadDetail(this.leadId).subscribe(res => {
      this.datailedData = res.data;
      this.spinner.hide();
      const formLead = this.leadService.lead_form;
      formLead.patchValue({
        address1: this.datailedData.contactInformation.address !== null ? this.datailedData.contactInformation.address.address1 : '',
        address2: this.datailedData.contactInformation.address !== null ? res.data.contactInformation.address.address2 : '',
        emailId: res.data.contactInformation.email.infoString,
        firstName: res.data.personalInformation.firstName,
        lastName: res.data.personalInformation.lastName,
        suffix: res.data.personalInformation.suffix,
        comments: res.data.personalInformation.comments,
       
        areaCode: res.data.personalInformation.areaCode,
        
        phoneNumber: res.data.personalInformation.contactNumber

      })
     
    })
   
  }
  onCountryChange(event) {
    this.stateArray = [];
    this.countryGeoId = event;
    if (event) {
      this.getStateList();
    }
  }
  getStatusCrm() {
    this.spinner.show();
    this.leadServices.getStatusCrm().subscribe(res => {
      this.crmStatus = res.data;
      this.spinner.hide();
      for (const value of this.crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  
  }
  getLeadSource() {
    this.spinner.show();
    this.leadServices.getLeadSource().subscribe(res => {
      this.leadSource = res.data.leadSourceList;
      this.spinner.hide();
      for (const value of this.leadSource) {
        this.leadArray.push({
          label: value.description,
          value: value.dataSourceId
        })
      }
    })
   
  }
  getContactList() {
    this.spinner.show();
    this.leadServices.getContactList().subscribe(res => {
      this.contactList = res.data;
      this.spinner.hide();
      for (const value of this.contactList) {
        this.contactListArray.push({
          label: value.description,
          value: value.descriptioncontactListId
        })
      }
    })
    
  }
  navigateBack() {
    this.router.navigate(["/crm/leads"])
  }
  updateLead() {
    this.spinner.show();
    this.leadService.lead_form.markAllAsTouched();
    const formValues = { ...this.leadService.lead_form.value };
    this.leadServices.updateLead(formValues).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data);
        this.spinner.hide();
      
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.debugMessage);
    })
    
  }
  getStateList() {
    this.spinner.show();
    this.leadServices.getState(this.countryGeoId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
  
  }

  onSubmit() {
    this.spinner.show();
    this.leadService.lead_form.markAllAsTouched();
    const formValues = { ...this.leadService.lead_form.value };
    this.leadServices.createLead(formValues).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data);
        this.spinner.hide();
        this.router.navigate(['/crm/leads'])
      }

    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.debugMessage);
    })
  
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  generalInformation(first,last,customer,noOfEmp){
    if(first && last&&customer && noOfEmp){
      this.activeCategory=3
    }
    else{
      this.toastr.error("First Name,Last Name,No of Employee and Customer Name is required");
    }
  }
  addressInformation(address,city,country,state,postal){
    if(address && country  && city  && state && postal){
      this.activeCategory=4
    }
    else{
      this.toastr.error("Address1,Country,City,State and Postal Code is required");
    }
  }
  phoneInformation(phone){
    if(phone){
      this.activeCategory=5
    }
    else{
      this.toastr.error("Phone Number is required");
    }
  
  }
  emailInformation(email){
    if(email){
      this.activeCategory=6
    }
    else{
      this.toastr.error("Email is required");
    }
  }
  leadInformation(lead){
    if(lead.constructor === Array){
      this.toastr.error("Lead Source is required");
     
    }
    else{
      this.activeCategory=8
    }
  
  }
  addressInfo(first,last,customer,noOfEmp){
    if(first && last&&customer && noOfEmp){
      this.activeCategory=3
    }
    else{
      this.toastr.error("Fill General Information.");
    }
  }
  phoneInfo(address,city,country,state,postal){
    if(address && country  && city  && state && postal){
      this.activeCategory=4
    }
    else{
      this.toastr.error("Fill Address Information.");
    }
  }
  emailInfo(phone){
    if(phone){
      this.activeCategory=5
    }
    else{
      this.toastr.error("Fill Phone Information.");
    }
  
  }
  leadInfo(email){
    if(email){
      this.activeCategory=6
    }
    else{
      this.toastr.error("Fill Email Information.");
    }
  }
  contactInfo(lead){
    if(lead.constructor === Array){
      this.toastr.error("Fill Lead Information.");
     
    }
    else{
      this.activeCategory=8
    }
  
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
