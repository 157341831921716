<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/facilities/warehouse">Facilities </span>
                <span class="color-black pl-1"> > Facility Summary</span>
                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Back</button>
            </div>
            <div class="w3-card-4 classCard">
            <div class="container-fluid scrolling-container">
                <ul class="tabbing-section scolling-tabss tabbing-accordians tabClass" style="padding-left: 9px;">
                    <li class="litab" [ngClass]="activeCategory==1?'active':''"
                        (click)="toBackInventory()"><a>
                            Create New Inventory Item for this Facility
                        </a></li>
                    <li class="litab" [ngClass]="activeCategory==2?'active':''"
                        (click)="changeactiveCategoryFunction(2)"><a>
                            Search by Labels</a></li>
                    
                    
                </ul>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            
                                            <div *ngIf="activeCategory==2">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Search Results
                                                    </h4>

                                                    <!-- <span class="edit-buttons"
                                                    data-toggle="modal"
                                                    data-target="#createSearchLabelPopup">Create</span>
                                                 -->
                                                    
                                                 
                                                </div>
                                                <div class="panel-body">

                                                    <ng-container >

                                                        <ng-container >
                                                            <form class="w-100" [formGroup]="createSearchLablesForm">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">
                                                                                    ded</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                [options]="SearchInventoryItemsByLabelArray"
                                                                                formControlName="ded" id="exampleInputEmail1" 
                                                                                aria-describedby="emailHelp" placeholder="Select ">
                                                                                </p-dropdown>
                                                                            </div>
                        
                                                                        
                                                                        </div>
                                                                    </div>
                        
                                                                    <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="SearchInventoryItemsByLabels();">Submit</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </ng-container>

                                                        <ng-container >
                                                            <div class="card own-account-table mt-2">
                                                                <p-table [value]="inventoryItemsByLabels" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Inventory Item ID	 <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> InventoryItem Type ID	 <p-sortIcon
                                                                                        field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price"><div style="color: white;">  Status ID	</div>

                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price"><div style="color: white;">Date Time Received	</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">Expire Date	</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">Product ID	</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;"> Lot ID	</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">Serial Number	</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">Soft Identifier	</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">Quantity On Hand Total
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                    <span
                                                                                         class="account-button" >{{product.dataNodeDes[0].inventoryItemId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.dataNodeDes[0].inventoryItemTypeId }}
                                                                            </td>
                                                                            

                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.dataNodeDes[0].statusId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.dataNodeDes[0].datetimeReceived |date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.expireDate |date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.lotId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.serialNumber}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.softIdentifier}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.quantityOnHandTotal}}
                                                                            </td>

                                                                            

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <!-- <p class="paginate_data">
                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                <p class="paginate_data">
                                                                    View per page </p>

                                                            </div>
                                                        </ng-container>
                                                    </ng-container>

                                                    
                                                </div>
                                                
                                            </div>
                                            
                                         
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <!-- <div class="main-submit-button">
                <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
            </div> -->
        </div>
    </div>
</div>

<!-- <div class="modal fade" id="createSearchLabelPopup" tabindex="-1" role="dialog" aria-labelledby="createSearchLabelPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Search By Label


                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Search By Label
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createSearchLablesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            {{eventPurpose.inventoryItemLabelTypeId}}</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        [options]="SearchInventoryItemsByLabelArray"
                                                        formControlName="ded" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Select ">
                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="modal fade" id="createSearchLabelPopup" tabindex="-1" role="dialog" aria-labelledby="createSearchLabelPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Search By Label


                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Search By Label
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createSearchLablesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            {{eventPurpose.inventoryItemLabelTypeId}}</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        [options]="SearchInventoryItemsByLabelArray"
                                                        formControlName="ded" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Select ">
                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->