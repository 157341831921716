import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { SortEvent } from 'primeng/api';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-detail-distribution',
  templateUrl: './detail-distribution.component.html',
  styleUrls: ['./detail-distribution.component.css']
})
export class DetailDistributionComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  personId: any;
  partyIdArray: any[] = []
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  partyId: any;
  createVendor: FormGroup;

  public applicationSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  listVendors: any;
  listDistributors: any;
  constructor(
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly addSkillService: AddSkillService,
    readonly headerService: HeaderService,
    readonly _FormBuilder: FormBuilder,
    readonly accountsService: AccountsService,
    readonly toastr: ToastrService,
    readonly _Router: Router) {

    this.createVendor = this._FormBuilder.group({
      partyId: [''],
      Name: [''],
      type: [''],
      DistributorName: [''],
      CompanyWebsite: [''],
      DistributorisActive: [''],
      individual: [''],
      DistributorForms: [''],
      Map: [''],
      manifestCompanyTitle: [''],
      manifestPolicies: [''],
      manifestCompanyName: [''],
      manifestLogoUrl: [''],
      Phone: [''],
      DistributorPhone: [''],
      DistributorFax: [''],
      DistributorEmail: [''],
      CreditLimit: [''],
      billingRateLevel: [''],
      company: [''],
      CompanyTaxID: [''],
      EligibleFor: [''],
      agreement: [''],
      PrintCheckAs: [''],
      accountNo: ['']




    });
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params.partyId;
    })
    this.editVendorsData();
    this.getPartyId();
    this.getVendors();
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  cancelSubmit() {
    this._Router.navigate(["/facilities/Distribution"])

  }
  getVendors() {
    this.spinner.show();
    const req = {
      "manifestCompanyName": "",
      "manifestCompanyNameSearchType": "Contains",
      "manifestCompanyTitle": "",
      "manifestCompanyTitleSearchType": "Contains",
      "manifestLogoUrl": "",
      "manifestLogoUrlSearchType": "Contains",
      "manifestPolicies": "",
      "manifestPoliciesSearchType": "Contains",
      "partyId": this.partyId,
      "partyIdSearchType": "Contains"
    }
    this.accountsService.getVendors(this.applicationSize, req).subscribe((res: any) => {
      this.listDistributors = res.data[0];

      this.spinner.hide();

    })

  }

  editVendorsData() {
    this.spinner.show();
    this.accountsService.getVendorListById(this.partyId).subscribe((res: any) => {

      setTimeout(() => {
        const formValue = this.createVendor;
        formValue.patchValue({
          manifestCompanyName: res.data.manifestCompanyName,
          manifestCompanyTitle: res.data.manifestCompanyTitle,
          manifestLogoUrl: res.data.manifestLogoUrl,
          manifestPolicies: res.data.manifestPolicies,
          partyId: this.partyId,
          accountNo: res.data.additionInformation == null ? '' : res.data.additionInformation.accountNo,
          billingRateLevel: res.data.additionInformation == null ? '' : res.data.additionInformation.billingRateLevel,
          creditLimit: res.data.additionInformation == null ? '' : res.data.additionInformation.creditLimit,
          eligibleFor: res.data.additionInformation == null ? '' : res.data.additionInformation.eligibleFor,
          printCheckAs: res.data.additionInformation == null ? '' : res.data.additionInformation.printCheckAs,
          terms: res.data.additionInformation == null ? '' : res.data.additionInformation.terms,
          type: res.data.additionInformation == null ? '' : res.data.additionInformation.type,
          Name: res.data.name,
          DistributorName: res.data.vendorName,
          CompanyWebsite: res.data.CompanyWebsite,
          DistributorisActive: res.data.isActive,
          individual: res.data.individual,
          DistributorForms: res.data.VendorForms,
          Map: res.data.Map,
          Phone: "",
          DistributorPhone: res.data.VendorPhone,
          DistributorFax: res.data.VendorFax,
          DistributorEmail: res.data.VendorEmail,
          CreditLimit: "",
          company: res.data.company,
          CompanyTaxID: "",
          EligibleFor: res.data.additionInformation == null ? '' : res.data.additionInformation.eligibleFor,
          agreement: res.data.additionInformation == null ? '' : res.data.additionInformation.terms,
          PrintCheckAs: res.data.additionInformation == null ? '' : res.data.additionInformation.printCheckAs,
        })

      }, 3000);
      this.spinner.hide();
    })
  }

  onSubmit() {
    this.spinner.show();
    const requestData = {
      "manifestCompanyName": this.createVendor.value.manifestCompanyName,
      "manifestCompanyTitle": this.createVendor.value.manifestCompanyTitle,
      "manifestLogoUrl": this.createVendor.value.manifestLogoUrl,
      "manifestPolicies": this.createVendor.value.manifestPolicies,
      "partyId": this.partyId,
      "accountNo": this.createVendor.value.accountNo,
      "billingRateLevel": this.createVendor.value.billingRateLevel,
      "creditLimit": this.createVendor.value.CreditLimit,
      "eligibleFor": this.createVendor.value.EligibleFor,
      "printCheckAs": this.createVendor.value.PrintCheckAs,
      "taxId": this.createVendor.value.CompanyTaxID,
      "terms": this.createVendor.value.agreement,
      "type": this.createVendor.value.type,
      "company": this.createVendor.value.company,
      "companyWebsite": this.createVendor.value.CompanyWebsite,
      "individual": this.createVendor.value.individual,
      "isActive": this.createVendor.value.DistributorisActive,
      "map": this.createVendor.value.Map,
      "name": this.createVendor.value.Name,
      "phone": this.createVendor.value.Phone,
      "typeVendor": "distributor",
      "vendorEmail": this.createVendor.value.DistributorEmail,
      "vendorFax": this.createVendor.value.DistributorFax,
      "vendorForms": this.createVendor.value.DistributorForms,
      "vendorName": this.createVendor.value.DistributorName,
      "vendorPhone": this.createVendor.value.DistributorPhone,


    }
    this.accountsService.updateVendors(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Updated Successfully");
        this.closebutton1.nativeElement.click();
        this.getVendors();
        this.editVendorsData();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
}
