<div class="container-fluid main-container-wrapper">
    <div class="panel-group" >
      <br>
      <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
    
        <button type="submit"  style="margin-left: auto!important;;" class="btn btn-danger"  (click)="onCancel()">Cancel</button>
    </div>
      <div class="w3-card-4 classCard" >
        <div class="container-fluid">
          
          <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
            <li ><a>
              Import User Data</a></li>
          </ul>
        </div>
        <div class="panel panel-default">
           
            <div >
              <div class="panel-body">
                  <form>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                  <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-2 form-group classInputText">
                          <label for="exampleInputEmail1">Attach Content</label>
                        </div>
                        <div class="col-lg-2">
                          <input type="file"  (change)="fileProgress($event)" class="classFile" id="exampleInputEmail1" aria-describedby="emailHelp"  accept="">
                        </div>
                        <div class="col-lg-2"></div>
                      
                      
                    </div>
                </div>

                    <div class="col-lg-4 col-12">
                        <div class="form-group">
                          
                            <button style="    margin-left:100%;" type="button" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                           
                        </div>
                    </div>
                </div>
          </form>
            </div>
            </div>
          </div>
          </div>
          </div>
        </div>
        <ngx-spinner></ngx-spinner> 