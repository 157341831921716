
<div class="modal-header">
    <span class="color-black pl-1">{{editMode? 'Update':'Create'}} {{isEftAccount?'EFT':'Check'}} Account</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
</div>
<div class="row p-15">
    <div class="col-12">
        <div class=" bg-white color-grey">
            <div class="w3-card-4 classCard border-card">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">
        
                    <li class="active"><a>
                        {{editMode? 'Update':'Create'}} {{isEftAccount?'EFT':'Check'}} Account</a></li>
                    
                
                </ul>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100 ml-54" [formGroup]="eftAccountForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -11%;">
                                                <label for="exampleInputEmail1">Name Account <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Name Account"
                                                formControlName="nameOnAccount">
                                            <small class="text-danger"
                                                *ngIf="!!eftAccountForm.controls.nameOnAccount.invalid && !!eftAccountForm.controls.nameOnAccount.touched">Name
                                                Account is Required</small>
                                            </div>
                                            
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            ">
                                                <label for="exampleInputEmail1">Company Name On Account</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control"
                                                placeholder="Company Name On Account"
                                                formControlName="companyNameOnAccount">
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -11%;">
                                                <label for="exampleInputEmail1">Bank Name <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Bank Name"
                                                formControlName="bankName">
                                            <small class="text-danger"
                                                *ngIf="!!eftAccountForm.controls.bankName.invalid && !!eftAccountForm.controls.bankName.touched">Bank
                                                Name is Required</small>
                                            </div>
                                            
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            ">
                                                <label for="exampleInputEmail1">Routing Number <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Routing Number"
                                                formControlName="routingNumber">
                                            <small class="text-danger"
                                                *ngIf="!!eftAccountForm.controls.routingNumber.invalid && !!eftAccountForm.controls.routingNumber.touched">Routing
                                                Number is Required</small>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -11%;">
                                                <label for="exampleInputEmail1">Account Type <span
                                                    class="text-danger">*</span>
                                            </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown placeholder="Account Type" filter="true"
                                                [options]="accountTypes" formControlName="accountType">
                                            </p-dropdown>
                                            <small class="text-danger"
                                                *ngIf="!!eftAccountForm.controls.accountType.invalid && !!eftAccountForm.controls.accountType.touched">Account
                                                Type is Required</small>
                                            </div>
                                            
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            ">
                                                <label for="exampleInputEmail1">Account Number <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Account Number"
                                                formControlName="accountNumber">
                                            <small class="text-danger"
                                                *ngIf="!!eftAccountForm.controls.accountNumber.invalid && !!eftAccountForm.controls.accountNumber.touched">Account
                                                Number is Required</small>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -11%;">
                                                <label for="exampleInputEmail1">Description <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="description" aria-describedby="emailHelp"
                                                placeholder="Description">
                                            <small class="text-danger"
                                                *ngIf="!!eftAccountForm.controls.description.invalid && !!eftAccountForm.controls.description.touched">Description
                                                is Required</small>
                                            </div>
                                            
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            ">
                                                <label for="">Billing Address</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <ng-container *ngIf="accountData?.address;else noData">
                                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                                        (change)="getAddress($event,accountData?.address.contactMechId)">
                                                    <label class="form-check-label" for="exampleCheck1">
                                                        To:<span>{{accountData?.address.address1}}</span>,
                                                        <span>{{accountData?.address.address2}}</span>,
                                                        <span>{{accountData?.address.city}}</span>,
                                                        <span>{{accountData?.address.stateProvinceGeoId}}</span>,
                                                        <span>{{accountData?.address.countryId}}</span>,
                                                        <span>{{accountData?.address.postalCode}}</span>
                                                    </label>
                                                </ng-container>
                                                <ng-template #noData>
                                                    No contact information on file.
                                                </ng-template>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: -8%;">
                                <button type="submit" *ngIf="editMode" (click)="update()"
                                    class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" *ngIf="!editMode" (click)="submit()"
                                    class="btn btn-secondary submit-btn">Submit</button>
                                
                            </div>
                        </div>
                    </div>
                </div>
            
            
            </div>

        </div>
        
    </div>
</div>
