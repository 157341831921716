import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-survey-detail-page',
  templateUrl: './survey-detail-page.component.html',
  styleUrls: ['./survey-detail-page.component.css']
})
export class SurveyDetailPageComponent implements OnInit {
  activeCategory=1;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;




  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  createSurveyForm: FormGroup;
  updateSurveyForm: FormGroup;
  surveyResponseForm: FormGroup;
  addSurveyMultiResponseColumnForm:FormGroup;
  createSurveyMultiResponseGroupForm: FormGroup;
  applyQuestionForm: FormGroup;
  createSurveyPageForm: FormGroup;
  createNewQuestionForm: FormGroup;
  surveyFindForm: FormGroup;
  buidSurveyResponseForm: FormGroup;
  createQuestionCategoryForm: FormGroup;
  createSurveyResponseForm: FormGroup;
  responseSummaryForm: FormGroup;
  SurveyByIdAll: any;
  surveyId: any;
  yesNoArray: { label: string; value: string; }[];
  surveyIdValue=true;
  contentIdsArray: any[];
  SurveyByIdAllData: any;
  fromDateCate: string;
  throughDateCate: string;
  SurveyResponseAll: any;
  SurveyMultiResps: any;
  updateSurveyMultiRespPat: any;
  show: boolean;
  SurveyMultiResponseColumn: any;
  SurveyMultiRespId: any;
  updateSurveyMultiRespColumnPat: any;
  SurveyPageById: any;
  updateSurveyPagePat: any;
  ApplyQuestionList: any;
  ApplyQuestionListArray: any[]=[];
  SurveyQuestionTypeIdList: any;
  SurveyQuestionTypeIdListArray: any[]=[];
  GeoId: any;
  GeoIdArray: any[]=[];
  ProductGeoEnumId: any;
  ProductGeoEnumIdArray: any[]=[];
  updateSurveyQuestionPat: any;
  SurveyListBySurveyQuestionCategoryId: any;
  ApplyQuestionListAddArray: any[]=[];
  addQuestionCategory: FormGroup;
  CreateSurveyQuestionById: any;
  firstDate: Date;
  StartDate: string;
  privilegeEnumID: any;
  privilegeEnumIDArray: any[]=[];
  applySurveyQuestion: FormGroup;
  createSurveyQuestionApplPat: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _CategoryService: CategoryService,
    private _location: Location,
  )
  {
    this.yesNoArray=[
      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.createSurveyForm=this._FormBuilder.group({
      surveyName:"",
      description:"",
      comments:"",
      submitCaption:"",
      responseService:"",
      isAnonymous:"",
      allowMultiple:"",
      allowUpdate:"",
    })

    this.surveyResponseForm=this._FormBuilder.group({
      surveyID:"",
      surveyName:"",
      description:"",
      comments:"",
      submitCaption:"",
      responseService:"",
      isAnonymous:"",
      allowMultiple:"",
      allowUpdate:"",
      AcroFormPDFContentID:"",
      pdfContentID:"",
    })

    this.updateSurveyForm=this._FormBuilder.group({
      surveyID:"",
      surveyName:"",
      description:"",
      comments:"",
      submitCaption:"",
      responseService:"",
      isAnonymous:"",
      allowMultiple:"",
      allowUpdate:"",
      acroFormPDFContentID:"",
      PDFContentID:"",
    })

    this.addSurveyMultiResponseColumnForm=this._FormBuilder.group({
      columnTitle:"",
      sequenceNum:"",
    })

    this.createSurveyMultiResponseGroupForm=this._FormBuilder.group({
      multiResponseTitle:"",
    })

    this.applyQuestionForm=this._FormBuilder.group({
      applyQuestion:"",
    })

    this.createSurveyPageForm=this._FormBuilder.group({
      pageName:"",
      sequenceNum:"",
    })

    this.buidSurveyResponseForm=this._FormBuilder.group({
      pdfFileNameIn:"",
      pdfContentID:"",
    })

    this.createQuestionCategoryForm=this._FormBuilder.group({
      parentCategoryID:"",
      description:"",
    })

    this.createSurveyResponseForm=this._FormBuilder.group({
      partyID:"",
      senderName:"",
      recipientsName:"",
      recipientsEmail:"",
      giftMessage:"",
      whatisyourname:"",
      pdfFileNameIn:"",
      PDFContentID:"",
    })
    
    this.surveyFindForm=this._FormBuilder.group({
      surveyResposeID:"",
      surveyID:"",
      partyID:"",
      responseDate:"",
      lastModifiedDate:"",
      referenceID:"",
      generalFeedback:"",
      orderID:"",
      orderItemSeqID:"",
      statusID:"",
    })

    this.createNewQuestionForm=this._FormBuilder.group({
      surveyQuestionCategoryID:"",
      surveyQuestionTypeID:"",
      description:"",
      question:"",
      hint:"",
      enumTypeID:"",
      geoID:"",
      formatString:"",
    })
    this.responseSummaryForm=this._FormBuilder.group({
      senderName:"",
      recipientsName:"",
      recipientsEmail:"",
      giftMessage:"",
    })
    this.addQuestionCategory=this._FormBuilder.group({
      parentCategoryID:[''],
      description:['']
    })
    this.applySurveyQuestion=this._FormBuilder.group({
      surveyQuestionId:[''],
      description:[''],
      surveyQuestionType:[''],
      Question:[''],
      Page:[''],
      multiResp:[''],
      multiRespColumn:[''],
      required:[''],
      sequenceNum:[''],
      wQuestion:[''],
      wOption:[''],
    })




    }

  ngOnInit(): void {
   
 this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
 if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN" || this.userPermissions.CONTENTMGR_ADMIN=="CONTENTMGR_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CONTENTMGR_ROLE_CREATE=="CONTENTMGR_ROLE_CREATE"){
      this.Hidebtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.surveyId = params["surveyId"];
    });
    this.show=true;

    this.getSurveyById();
    this.getContentId();
    this.EditSurveyMultiResps();
    this.getSurveyMultiResponseColumn();
    this.getSurveyPageById();
    this.getApplyQuestionList();
    this.getSurveyQuestionTypeIdList();
    this.getGeoId();
    this.getProductGeoEnumId();
    this.getApplyQuestionListAdd();
    this.getCreateSurveyQuestionById();
    this.getPrivilegeEnumID();
  }
  generatePDFSurvey() {
    this.spinner.show();
  
    this.myContactsService.generatePDFSurvey(this.surveyId).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "SURVEY.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  buildSurveyFromPdf(): void {
    this.spinner.show();
    const requestData ={
      "contentId": this.updateSurveyForm.value.PDFContentID,
      "surveyId": this.surveyId
    }  
    this.accountsService.buildSurveyFromPdf(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Generate Successfully");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  MultiRespResetForm(){
    this.show=true;
    this.createSurveyMultiResponseGroupForm.reset();
    this.addSurveyMultiResponseColumnForm.reset();
    this.createSurveyPageForm.reset();
    this.createNewQuestionForm.reset();
    this.surveyFindForm.reset();
  }
  movetab(){
    this.activeCategory=5;
  }
  createSurveyQuestionApplPatch(product) {
    this.show=false;
    this.createSurveyQuestionApplPat  = product;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.applySurveyQuestion;
        formValue.patchValue({
            surveyQuestionId: this.createSurveyQuestionApplPat.node.surveyQuestionId,
            description: this.createSurveyQuestionApplPat.node.description,
            surveyQuestionType: this.createSurveyQuestionApplPat.surveyQuestionType[0].description,
            Question: this.createSurveyQuestionApplPat.node.question,
        })
      }, 2000);
      this.spinner.hide();
  }
  
  createSurveyQuestionAppl(): void {
    this.spinner.show();
    const requestData ={
      "requiredField": this.applySurveyQuestion.value.required,
      "sequenceNum": this.applySurveyQuestion.value.sequenceNum,
      "surveyId": this.surveyId,
      "surveyMultiRespColId": this.applySurveyQuestion.value.multiRespColumn,
      "surveyMultiRespId": this.applySurveyQuestion.value.multiResp,
      "surveyPageSeqId": this.applySurveyQuestion.value.Page,
      "surveyQuestionCategoryId": this.createSurveyQuestionApplPat.node.surveyQuestionCategoryId,
      "surveyQuestionId": this.applySurveyQuestion.value.surveyQuestionId,
      "withSurveyOptionSeqId": this.applySurveyQuestion.value.wQuestion,
      "withSurveyQuestionId": this.applySurveyQuestion.value.wQuestion,
      }
     
    this.accountsService.createSurveyQuestionAppl(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Applied Successfully");
        this.spinner.hide();
        this.getCreateSurveyQuestionById();
        this.closebutton9.nativeElement.click();
        this.applySurveyQuestion.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getPrivilegeEnumID() {
    this.spinner.show();
    this.myContactsService.getPrivilegeEnumID().subscribe(res => {
      this.privilegeEnumID = res.data.getPrivilegeEnumID;
      this.spinner.hide();
      for (const value of this.privilegeEnumID) {
        this.privilegeEnumIDArray.push({
          label: value.enumId,
          value: value.enumId
        })
      }
    })
  }
  buildSurveyResponseFromPdf(): void {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.CONTENTMGR_ADMIN){
      this.spinner.show();
      const requestData ={
        "contentId": this.buidSurveyResponseForm.value.pdfContentID,
        "pdfFileNameIn": this.buidSurveyResponseForm.value.pdfFileNameIn,
        "surveyId": this.surveyId,
        "surveyResponseId": ''
        }
       
      this.accountsService.buildSurveyResponseFromPdf(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Create Successfully");
          this.spinner.hide();
          this.buidSurveyResponseForm.reset();
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); 
    }
    else if(this.userPermissions.CONTENTMGR_ROLE_CREATE){
      this.toastr.error("Permission Denied"); 
    }
   
  }
  createSurveyQuestionCategory(): void {
    this.spinner.show();
    const requestData ={
      "description": this.addQuestionCategory.value.description,
      "parentCategoryId": this.addQuestionCategory.value.parentCategoryID,
      "surveyId": this.surveyId
      }
     
    this.accountsService.createSurveyQuestionCategory(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Added Successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.getApplyQuestionListAdd();
        this.getApplyQuestionList();
        this.addQuestionCategory.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  getSurveyListBySurveyQuestionCategoryId() {
    this.spinner.show();
    this.accountsService.getSurveyListBySurveyQuestionCategoryId(this.applyQuestionForm.value.applyQuestion).subscribe(res => {
      this.SurveyListBySurveyQuestionCategoryId = res.data;
      this.spinner.hide();
      if (res.success) {
        this.closebutton7.nativeElement.click();
        this.applyQuestionForm.reset();
      }
    })
  }
  removeSurveyQuestionAppl(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const StartDate=product.dataNode.fromDate
        this.StartDate=this.datePipe.transform(StartDate,"yyyy-MM-dd hh:mm:ss");
        const req={
          "fromDate":  product.fromDate,
          "surveyId": product.dataNode.surveyId,
          "surveyQuestionId": product.dataNode.surveyQuestionId
        }
        this.accountsService.removeSurveyQuestionAppl(req).subscribe(res => {
          this.getCreateSurveyQuestionById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  updateSurveyQuestion(): void {
    this.spinner.show();
    const requestData ={
      "description": this.createNewQuestionForm.value.description,
      "enumTypeId": this.createNewQuestionForm.value.enumTypeID,
      "formatString": this.createNewQuestionForm.value.formatString,
      "geoId": this.createNewQuestionForm.value.geoID,
      "hint": this.createNewQuestionForm.value.hint,
      "question": this.createNewQuestionForm.value.question,
      "surveyId": this.updateSurveyQuestionPat.dataNode.surveyId,
      "surveyQuestionCategoryId": this.createNewQuestionForm.value.surveyQuestionCategoryID,
      "surveyQuestionId": this.updateSurveyQuestionPat.dataNode.surveyQuestionId,
      "surveyQuestionTypeId": this.createNewQuestionForm.value.surveyQuestionTypeID,
      }
     
    this.accountsService.updateSurveyQuestion(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getCreateSurveyQuestionById();
        this.closebutton6.nativeElement.click();
        this.show=true;
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateSurveyQuestionPatch(product) {
    this.show=false;
    this.updateSurveyQuestionPat  = product;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.createNewQuestionForm;
        formValue.patchValue({
            surveyQuestionCategoryID: this.updateSurveyQuestionPat.dataNode.surveyQuestionCategoryId,
            surveyQuestionTypeID: this.updateSurveyQuestionPat.dataCategoryType[0].description,
            description: this.updateSurveyQuestionPat.dataNode.description,
            question: this.updateSurveyQuestionPat.dataNode.question,
            hint: this.updateSurveyQuestionPat.dataNode.hint,
            enumTypeID: this.updateSurveyQuestionPat.dataNode.enumTypeId,
            geoID: this.updateSurveyQuestionPat.dataNode.geoId,
            formatString: this.updateSurveyQuestionPat.dataNode.formatString,
            
    
        })
      }, 2000);
      this.spinner.hide();
  }
  createSurveyQuestion(): void {
    this.spinner.show();
    const requestData ={
      "description": this.createNewQuestionForm.value.description,
      "enumTypeId": this.createNewQuestionForm.value.enumTypeID,
      "formatString": this.createNewQuestionForm.value.formatString,
      "geoId": this.createNewQuestionForm.value.geoID,
      "hint": this.createNewQuestionForm.value.hint,
      "question": this.createNewQuestionForm.value.question,
      "surveyId": this.surveyId,
      "surveyQuestionCategoryId": this.createNewQuestionForm.value.surveyQuestionCategoryID,
      "surveyQuestionTypeId": this.createNewQuestionForm.value.surveyQuestionTypeID,
      }
     
    this.accountsService.createSurveyQuestion(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCreateSurveyQuestionById();
        this.closebutton6.nativeElement.click();
        this.createNewQuestionForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getCreateSurveyQuestionById() {
    this.spinner.show();
    this.accountsService.getCreateSurveyQuestionById(this.surveyId).subscribe(res => {
      this.CreateSurveyQuestionById = res.data;
      this.spinner.hide();
    })
  }
  getProductGeoEnumId() {
    this.spinner.show();
    this.accountsService.getProductGeoEnumId().subscribe(res => {
      this.ProductGeoEnumId = res.data.getProductGeoEnumId;
      this.spinner.hide();
      for (const value of this.ProductGeoEnumId) {
        this.ProductGeoEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  getGeoId() {
    this.spinner.show();
    this.myContactsService.getGeoIds().subscribe(res => {
      this.GeoId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.GeoId) {
        this.GeoIdArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
  }
  getSurveyQuestionTypeIdList() {
    this.spinner.show();
    this.accountsService.getSurveyQuestionTypeIdList().subscribe(res => {
      this.SurveyQuestionTypeIdList = res.data[0].sqType;
      this.spinner.hide();
      for (const value of this.SurveyQuestionTypeIdList) {
        this.SurveyQuestionTypeIdListArray.push({
          label: value.description,
          value: value.surveyQuestionTypeId
        })
      }
    })
  
  }
  getApplyQuestionList() {
    this.spinner.show();
    this.accountsService.getApplyQuestionList().subscribe(res => {
      this.ApplyQuestionList = res.data[0].surveyQuestionCategory;
      this.spinner.hide();
      for (const value of this.ApplyQuestionList) {
        this.ApplyQuestionListArray.push({
          label: value.description,
          value: value.surveyQuestionCategoryId
        })
      }
    })
  
  }
  getApplyQuestionListAdd() {
    this.spinner.show();
    this.accountsService.getApplyQuestionList().subscribe(res => {
      this.ApplyQuestionList = res.data[0].surveyQuestionCategory;
      this.spinner.hide();
      for (const value of this.ApplyQuestionList) {
        this.ApplyQuestionListAddArray.push({
          label: value.description+"["+value.surveyQuestionCategoryId+"]",
          value: value.surveyQuestionCategoryId
        })
      }
    })
  
  }
  updateSurveyPage(): void {
    this.spinner.show();
    const requestData ={
      "pageName": this.createSurveyPageForm.value.pageName,
      "sequenceNum": this.createSurveyPageForm.value.sequenceNum,
      "surveyId": this.updateSurveyPagePat.surveyId,
      "surveyPageSeqId": this.updateSurveyPagePat.surveyPageSeqId
      }
     
    this.accountsService.updateSurveyPage(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getSurveyPageById();
        this.closebutton5.nativeElement.click();
        this.show=true;
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateSurveyPagePatch(product) {
    this.show=false;
    this.updateSurveyPagePat  = product;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.createSurveyPageForm;
        formValue.patchValue({
            pageName: this.updateSurveyPagePat.pageName,
            sequenceNum: this.updateSurveyPagePat.sequenceNum,
        })
      }, 2000);
      this.spinner.hide();
  }
  createSurveyPage(): void {
    this.spinner.show();
    const requestData ={
      "pageName": this.createSurveyPageForm.value.pageName,
      "sequenceNum": this.createSurveyPageForm.value.sequenceNum,
      "surveyId": this.surveyId
      }
     
    this.accountsService.createSurveyPage(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getSurveyPageById();
        this.closebutton5.nativeElement.click();
        this.createSurveyPageForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getSurveyPageById() {
    this.spinner.show();
    this.accountsService.getSurveyPageById(this.surveyId).subscribe(res => {
      this.SurveyPageById = res.data[0].surveyPage;
      this.spinner.hide();
    })
  }
  updateSurveyMultiRespColumn(): void {
    this.spinner.show();
    const requestData ={
      "columnTitle": this.addSurveyMultiResponseColumnForm.value.columnTitle,
      "sequenceNum": this.addSurveyMultiResponseColumnForm.value.sequenceNum,
      "surveyId": this.surveyId,
      "surveyMultiRespColId": this.updateSurveyMultiRespColumnPat.surveyMultiRespColId,
      "surveyMultiRespId": this.updateSurveyMultiRespColumnPat.surveyMultiRespId
      }
     
    this.accountsService.updateSurveyMultiRespColumn(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getSurveyMultiResponseColumn();
        this.closebutton4.nativeElement.click();
        this.show=true;
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateSurveyMultiRespColumnPatch(product) {
    this.show=false;
    this.updateSurveyMultiRespColumnPat  = product;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.addSurveyMultiResponseColumnForm;
        formValue.patchValue({
            columnTitle: this.updateSurveyMultiRespColumnPat.columnTitle,
            sequenceNum: this.updateSurveyMultiRespColumnPat.sequenceNum,
        })
      }, 2000);
      this.spinner.hide();
  }
  createSurveyMultiRespId(product){
    this.MultiRespResetForm();
    this.SurveyMultiRespId=product;
  }
  createSurveyMultiRespColumn(): void {
    this.spinner.show();
    const requestData ={
      "columnTitle": this.addSurveyMultiResponseColumnForm.value.columnTitle,
      "sequenceNum": this.addSurveyMultiResponseColumnForm.value.sequenceNum,
      "surveyId": this.surveyId,
      "surveyMultiRespId": this.SurveyMultiRespId.surveyMultiRespId
      }
     
    this.accountsService.createSurveyMultiRespColumn(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getSurveyMultiResponseColumn();
        this.closebutton4.nativeElement.click();
        this.addSurveyMultiResponseColumnForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getSurveyMultiResponseColumn() {
    this.spinner.show();
    this.accountsService.getSurveyMultiResponseColumn(this.surveyId).subscribe(res => {
      this.SurveyMultiResponseColumn = res.data[0].data;
      this.spinner.hide();
    })
  }
 
  updateSurveyMultiResp(): void {
    this.spinner.show();
    const requestData ={
      "multiRespTitle": this.createSurveyMultiResponseGroupForm.value.multiResponseTitle,
      "surveyId": this.updateSurveyMultiRespPat.surveyId,
      "surveyMultiRespId": this.updateSurveyMultiRespPat.surveyMultiRespId,
      }
     
    this.accountsService.updateSurveyMultiResp(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.EditSurveyMultiResps();
        this.closebutton3.nativeElement.click();
        this.show=true;
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateSurveyMultiRespPatch(product) {
    this.show=false;
    this.updateSurveyMultiRespPat  = product;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.createSurveyMultiResponseGroupForm;
        formValue.patchValue({
            multiResponseTitle: this.updateSurveyMultiRespPat.multiRespTitle
        })
      }, 2000);
      this.spinner.hide();
  }

  createSurveyMultiResp(): void {
    this.spinner.show();
    const requestData ={
      "multiRespTitle": this.createSurveyMultiResponseGroupForm.value.multiResponseTitle,
      "surveyId": this.surveyId
      }
     
    this.accountsService.createSurveyMultiResp(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.EditSurveyMultiResps();
        this.closebutton3.nativeElement.click();
        this.createSurveyMultiResponseGroupForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  EditSurveyMultiResps() {
    this.spinner.show();
    this.accountsService.EditSurveyMultiResps(this.surveyId).subscribe(res => {
      this.SurveyMultiResps = res.data.EditSurveyMultiResps;
      this.spinner.hide();
    })
  }
  FindSurveyResponse(): void {
    this.spinner.show();
    const fromDateCate=this.surveyFindForm.get('lastModifiedDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.surveyFindForm.get('responseDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");

    const requestData ={
      "generalFeedback": this.surveyFindForm.value.generalFeedback?this.surveyFindForm.value.generalFeedback:'',
      "lastModifiedDate": this.fromDateCate?this.fromDateCate:'',
      "orderId": this.surveyFindForm.value.orderID?this.surveyFindForm.value.orderID:'',
      "orderItemSeqId": this.surveyFindForm.value.orderItemSeqID?this.surveyFindForm.value.orderItemSeqID:'',
      "partyId": this.surveyFindForm.value.partyID?this.surveyFindForm.value.partyID:'',
      "referenceId": this.surveyFindForm.value.referenceID?this.surveyFindForm.value.referenceID:'',
      "responseDate": this.throughDateCate?this.throughDateCate:'',
      "statusId": this.surveyFindForm.value.statusID?this.surveyFindForm.value.statusID:'',
      "surveyId": this.surveyFindForm.value.surveyID?this.surveyFindForm.value.surveyID:'',
      "surveyResponseId": this.surveyFindForm.value.surveyResposeID?this.surveyFindForm.value.surveyResposeID:'',
      }
    this.accountsService.FindSurveyResponse(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.SurveyResponseAll = res.data;
        this.surveyFindForm.reset();
        
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getContentId(): void {
    this.spinner.show();
    this._CategoryService.getCatalogIdList()
        .then(data => {
            this.spinner.hide();
            if (data.success) {
                this.contentIdsArray = data.data.map(content => {
                    return {
                        label: content.contentId,
                        value: content.contentId
                    };
                });
            }
        })
       
  }
  updateSurvey(): void {
    this.spinner.show();
    const requestData ={
      "allowMultiple": this.updateSurveyForm.value.allowMultiple,
      "allowUpdate": this.updateSurveyForm.value.allowUpdate,
      "comments": this.updateSurveyForm.value.comments,
      "description": this.updateSurveyForm.value.description,
      "isAnonymous": this.updateSurveyForm.value.yesNo,
      "responseService": this.updateSurveyForm.value.responseService,
      "submitCaption": this.updateSurveyForm.value.submitCaption,
      "surveyId": this.updateSurveyForm.value.surveyID,
      "surveyName": this.updateSurveyForm.value.surveyName,
      }
     
    this.accountsService.updateSurvey(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getSurveyById();
        this.closebutton10.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateProductAssocPatch() {
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.updateSurveyForm;
        formValue.patchValue({
          surveyID: this.SurveyByIdAllData.surveyId,
          surveyName: this.SurveyByIdAllData.surveyName,
          description: this.SurveyByIdAllData.description,
          comments: this.SurveyByIdAllData.comments,
          submitCaption: this.SurveyByIdAllData.submitCaption,
          responseService: this.SurveyByIdAllData.responseService,
          isAnonymous: this.SurveyByIdAllData.isAnonymous,
          allowMultiple: this.SurveyByIdAllData.allowMultiple,
          allowUpdate: this.SurveyByIdAllData.allowUpdate,
        })
      }, 2000);
      this.spinner.hide();
  }
  getSurveyById() {
    this.spinner.show();
    this.accountsService.getSurveyById(this.surveyId).subscribe(res => {
      this.SurveyByIdAll = res.data.getSurveyById;
      this.SurveyByIdAllData = res.data.getSurveyById[0];

      this.spinner.hide();
    })
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

changeactiveCategory(tab: number)
{
  this.activeCategory = tab;

}

}
