import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-assign-gl-account',
  templateUrl: './assign-gl-account.component.html',
  styleUrls: ['./assign-gl-account.component.css']
})
export class AssignGlAccountComponent implements OnInit {
  show: boolean;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[] = [];
  personId: any;
  partyIdArray: any[] = [];
  assignGlAccount: FormGroup;
  actualfromdate: string;
  activeCategory = 2;
  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
   
    readonly _FormBuilder: FormBuilder,
   ) {
    this.assignGlAccount = this._FormBuilder.group({
      fromDate: [''],
      glAccountId: [''],
      organizationPartyId: ['']

    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show = false;
    this.getGlAccountInitialData();
    this.getPartyId();
  }
  cancelSubmit() {
    this.router.navigate(["financial/gl-assign-list"])
  }
  getPartyId() {
    this.spinner.show();
    this.accountsService.internalOrganization().subscribe(res => {
      this.personId = res.data;
       for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.groupName,
          value: value.partyId
        })
      } 
    })
    this.spinner.hide();
  }

  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }





    })
   
  }
  onSubmit() {
    this.spinner.show();
    const fromActualdate = this.assignGlAccount.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.actualfromdate,
      "glAccountId": this.assignGlAccount.value.glAccountId,
      "organizationPartyId": this.assignGlAccount.value.organizationPartyId,
    }

    this.accountsService.assignGLAccount(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Assign Successfully");
        this.router.navigate(["financial/gl-assign-list"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}