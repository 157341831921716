<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Cost Component Calc Entries </span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>


            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter">Create New Cost Component Calc Entries</button>

                                    </div>




                                    <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="ProdCost" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Cost Component Calc ID
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;"> Description
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>


                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Fixed Cost
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;"> Variable Cost
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>


                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Per Milli Second
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;"> Currency
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Cost Custom Method ID
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Action
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>



                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span  class="account-button" (click)="detailCost(product.costComponentCalcId);">{{product.costComponentCalcId}}</span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.description}}
                                                                </td>

                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.fixedCost}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.variableCost}}
                                                                </td>

                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.perMilliSecond}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.currencyUomId}}
                                                                </td>

                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.costCustomMethodId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">

                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteProdCostById(product.costComponentCalcId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                       <defs>
                                                                           <style>
                                                                               .a {
                                                                                   fill: #f44336;
                                                                               }
                             
                                                                               .b {
                                                                                   fill: #fafafa;
                                                                                   margin-left: 20px;
                                                                               }
                                                                           </style>
                                                                       </defs>
                                                                       <path class="a fill-color"
                                                                           d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                       <path class="b"
                                                                           d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                           transform="translate(-147.576 -147.576)" />
                                                                   </svg> </td>





                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create Cost Component Calc Entries</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">

                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                        Add Cost Component Calc Entries
    
                                    </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                        Update Cost Component Calc Entries
    
                                    </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCost">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="Description">
                                                    </div>

                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Fixed Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fixed Cost" formControlName="FixedCost">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Variable Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Variable Cost" formControlName="VariableCost">
                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Per Milli Second</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Per Milli Second" formControlName="PerMilliSecond">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Currency</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Currency" filter="true" formControlName="Currency" [options]="currencyArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Cost Custom Method ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Cost Custom Method ID" filter="true" formControlName="CostCustomMethodID" [options]="costCustomMethodIDArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -11%!important;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                                        <!-- <button type="submit" *ngIf="this.show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button> -->

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>