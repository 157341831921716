import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  public token: string;

invalid = false;
newPassword:string;
confirmPasswords :string;
user_id : number;
hash: string;
tenantID:string;
password: string;
  constructor(public router: Router, readonly _ActivatedRoute: ActivatedRoute,
    readonly loginService: LoginService, readonly toastr:ToastrService) { }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=> {
      
      this.token = params["token"];
     
     
    });
  }
  submit(){
  
    let data = {
     
      newPassword: this.newPassword,
      confirmPassword:this.confirmPasswords

    }
    this.loginService.changePassword(this.token,this.tenantID,data).subscribe((res) =>{
     
     if(res.success ){
      this.toastr.success(res.data);
      window.location.href = '#';
  
     }
   else if(res.success == false) {
       this.toastr.error("Change Password failed");
    }
   },(err) => {
     this.toastr.error(err.error.message);
  }
  );
   }

}
