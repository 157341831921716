import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-new-promotions',
  templateUrl: './add-new-promotions.component.html',
  styleUrls: ['./add-new-promotions.component.css']
})
export class AddNewPromotionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
