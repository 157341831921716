<div class="container-fluid main-container-wrapper">
    <div class="row pt-2">

        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
                <span class="color-black pl-1 titlePanels">Payroll</span>
 
                <span>
                 <button type="submit" class="btn btn-secondary submit-btn mr-2"
                     (click)="toDashboard()">Home</button>
                 <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
             </span>
 
 
             </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="findPaycheck()"><a>
                                Find Paycheck</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="createPaychecks()" ><a>
                                Create Paycheck</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="paycheckConfiguration()" ><a>
                                Paycheck Configuration</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="employeess()"><a>
                                Employees</a></li>

                            <li [ngClass]="activeCategory==5?'active':''"  (click)="contractReferencees()"><a>
                                Contract Reference</a></li>
            
                            <li [ngClass]="activeCategory==6?'active':''" (click)="reports()"><a>
                                Reports</a></li>

                        </ul>
                    </div>
            <button type="submit" class="btn btn-secondary submit-btn" (click)="showNumberOfDays()">Number of Days</button>

            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="showNumberOfHours()">Number of Hours
            </button>

            <div class="chartjs-container">
                <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
                    [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
                    [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)">
                </canvas>
            </div>

            <div class="container-fluid">
                <div class="row">
                    <form class="w-100">
                        <div class="col-lg-12 mt-5">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-lg-12 ">
                                        <div class="row">
                                            <div class="col-lg-4 borders">
                                                Total Net Wage <br>
                                                <span style="color: #666666;font-size: 20px;">{{this.PieChart.Net_Wage}}</span>
                                            </div>
                                            <div class="col-lg-4 borders">
                                                Total Gross Wage<br>
                                                <span style="color: #666666;font-size: 20px;">{{this.PieChart.Gross_Wage}}</span>
                                                
                                            </div>
                                            <div class="col-lg-4 borders">
                                                #Payslips<br>
                                                <span style="color: #666666;font-size: 20px;">{{this.PieChart.Payslip}}</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-lg-12 mt-3">
                                        <div class="row">
                                            <div class="col-lg-4 borders">
                                                Number of Days<br>
                                                <span style="color: #666666;font-size: 20px;">{{this.PieChart.Number_Of_Days}}</span>
                                            </div>
                                            <div class="col-lg-4 borders">
                                                Number of Hours<br>
                                                <span style="color: #666666;font-size: 20px;">{{this.PieChart.Number_Of_Hours}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 ">
                                    <div class="chartjs-container">
                                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                            [chartType]="pieChartType" [options]="pieChartOptions"
                                            [plugins]="pieChartPlugins" [colors]="pieChartColors"
                                            [legend]="pieChartLegend">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <button type="submit" class="btn btn-secondary submit-btn ml-2  mb-4" (click)="onSubmitPdf()">Download
            </button>

            <div class="w3-card-4 classCard mb-4">
                <div class="panel-body panel">
                    <form class="w-100">
                        <div class=" font-13 font-medium border-top-grey own-user-select">
                            <div class="col-lg-12 col-12">
                                <div class="form-group table-responsive">
                                    <table class="table align-middle">
                                        <thead>

                                            <!-- <tr class="table-rows" style="background: #0d3769;">
                                                <th
                                                    style="color: white;font-size: 12px;padding: 14px 20px !important;min-width: 250px;">


                                                </th>
                                                <th
                                                    style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                    + Total

                                                </th>
                                                <th
                                                    style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">


                                                </th>
                                                <th
                                                    style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">


                                                </th>

                                                <th
                                                    style="color: white;font-size: 12px;min-width:180px;padding: 14px 20px !important;">


                                                </th>
                                                <th
                                                    style="color: white;font-size: 12px;min-width: 180px;padding: 14px 20px !important;">


                                                </th>



                                                <th
                                                    style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">



                                                </th>



                                            </tr> -->
                                            <tr class="table-rows" style="background: #0d3769;">
                              
                                                <th
                                                    style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                    #Payslip

                                                </th>
                                                <th
                                                    style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                    Net Wage

                                                </th>
                                                <th
                                                    style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                    Gross Wage

                                                </th>

                                                <th
                                                    style="color: white;font-size: 12px;min-width: 180px;padding: 14px 20px !important;">
                                                    Number of Hours

                                                </th>
                                                <th
                                                    style="color: white;font-size: 12px;min-width: 180px;padding: 14px 20px !important;">
                                                    Number of Days

                                                </th>

                                            </tr>

                                        </thead>
                                        <tbody>

                                            <tr class="table-rows"
                                                style="min-width: 155px;height: 11px !important;min-width: 250px;"
                                                class="align-bottom">
                                                <!-- <td style="    color: black;
                                
                              
                              ">

                                                    <p-dropdown [options]="DropDownsArray" filter="true"
                                                        
                                                        appendTo="body" 
                                                        class="dropdowns" optionlabel="label">
                                                    </p-dropdown>

                                                </td> -->
                                                <td style="min-width: 155px;">
                                                   {{this.PieChart.Payslip}}

                                                </td>
                                                <td style="min-width: 155px;">
                                                    {{this.PieChart.Net_Wage}}


                                                </td>
                                                <td style="min-width: 155px;">
                                                    {{this.PieChart.Gross_Wage}}

                                                </td>

                                                <td style="min-width: 180px;">
                                                    {{this.PieChart.Number_Of_Hours}}

                                                </td>

                                                <td style="min-width: 180px;">
                                                    {{this.PieChart.Number_Of_Days}}

                                                </td>


                                                <!-- <td
                                                    style="color: white;font-size: 12px;min-width: 76px;padding: 0px 20px !important;">
                                                    <button type="submit"
                                                        class="btn btn-secondary submit-btn mt-2">Create
                                                    </button>
                                                </td> -->

                                            </tr>


                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>



            </div>
        </div>




        </div>
    </div>
</div>
<!-- 
<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Content</span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
  
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="findPaycheck()"><a>
                                Find Paycheck</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="createPaychecks()" ><a>
                                Create Paycheck</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="paycheckConfiguration()" ><a>
                                Paycheck Configuration</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="employeess()"><a>
                                Employees</a></li>
            
                            <li [ngClass]="activeCategory==5?'active':''" (click)="reports()"><a>
                                Reports</a></li>
  
                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategory==5">
                                                
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div> -->

