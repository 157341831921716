import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApprovalService } from '../approval.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-update-approval',
    templateUrl: './update-approval.component.html'
})
export class UpdateApprovalComponent implements OnInit {
    updateApprovalForm: FormGroup;
    activeCategory = 2;
    statusIds: any[];
    updateValue: {
        applicationDate: string,
        approverPartyId: string,
        jobRequisitionId: string,
        applicationId: string,
        statusId: string
    };
    constructor(
        readonly _ToastrService: ToastrService,
        readonly _FormBuilder: FormBuilder,
        readonly  _Router: Router,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ApprovalService: ApprovalService,
        readonly spinner:NgxSpinnerService,
    ) {

        this.updateApprovalForm = this._FormBuilder.group({
            applicationDate: [''],
            approverPartyId: [''],
            jobRequisitionId: [''],
            statusId: ['', [Validators.required]]
        });
        this.statusIds = [];
        this.updateValue = {
            applicationDate: '',
            approverPartyId: '',
            jobRequisitionId: '',
            applicationId: '',
            statusId: ''
        };
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this._ActivatedRoute.queryParams.subscribe(params => {
            if (params) {
                this.spinner.show();
                this.updateValue = {
                    applicationDate: params.applicationDate ? moment(Number(params.applicationDate)).format('YYYY-MM-DD HH:mm:ss') : '',
                    applicationId: params.applicationId,
                    approverPartyId: params.approverPartyId ? params.approverPartyId : '',
                    jobRequisitionId: params.jobRequisitionId ? params.jobRequisitionId : '',
                    statusId: params.statusId ? params.statusId : ''
                };
                this.updateApprovalForm.patchValue(this.updateValue);
                this.spinner.hide();
            }
        });
        this.getStatusIds();
    }
    getStatusIds(): void {
        this.spinner.show();
        this._ApprovalService.getApprovalStatus()
            .then(data => {
                this.spinner.hide();
                if (data.success) {
                    this.statusIds = data.data.map(value => {
                        return {
                            label: value.description,
                            value: value.statusId
                        };
                    });
                }
            });
           
    }

    update(): void {
        this.spinner.show();
        if (this.updateApprovalForm.valid) {
            this._ApprovalService.updateApproval(this.updateValue.applicationId, this.updateApprovalForm.value)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.cancelSubmit();
                        this.spinner.hide();
                    }
                });
        }
        this.spinner.hide();
    }
    cancelSubmit(): void {
        this.updateApprovalForm.reset();
        this._Router.navigate(['/hr/job-requisition/approval']);
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}