import { Component, OnInit } from '@angular/core';
import { ReallocationsService } from "./reallocations.service";
import { SortEvent } from 'primeng/api';
import { AddSkillService } from '../add-skills/add-skill.service';
import { AddEmployeePositionService } from '../add-employee-position/add-employee-position.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

import { Location } from '@angular/common';

@Component({
  selector: 'app-reallocation',
  templateUrl: './reallocation.component.html',
  styleUrls: ['./reallocation.component.css']
})
export class ReallocationComponent implements OnInit {
  pageNo = 1;
  total=0;
  rows = 50;
  activeCategoryMain=2;
  pageSize= 100;
  activeCateg=3;
  personId: any;
  partyIdArray: any = [];
  internalOrganization: any;
  organizationArray: any = [];
  internalArray: any = [];
  public applicationSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  readonly employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  advanceSearch: {

    emplPositionId: string,
    emplPositionIdReportingTo: string,
    internalOrganisation: string,
    partyId: string,
    reportingDateFrom: string,
    reportingDateFromSearchType: string,
    reportingDateTo: string,
    reportingDateToSearchType: string,
    locationSearch: string,
    location: string
  };
  reLocationsList: any;
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  dateIdArray: { label: string; value: string; }[];
  positionType: any;
  positionArray: any = [];
  constructor(readonly reallocationsService: ReallocationsService,
    public addSkillService: AddSkillService, readonly datePipe: DatePipe,
    readonly addEmployeePositionService: AddEmployeePositionService,
      private _location: Location,
      readonly spinner:NgxSpinnerService,
    readonly router: Router,) {
    this.advanceSearch = {

      emplPositionId: "",
      emplPositionIdReportingTo: "",
      internalOrganisation: "",
      partyId: "",
      reportingDateFrom: "",
      reportingDateFromSearchType: "Equals",
      reportingDateTo: "",
      reportingDateToSearchType: "Equals",
      locationSearch: "Contains",
      location: ""
    };
    this.dateIdArray = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },

      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equal To',
        value: 'GreaterThanEqualTo'
      },
      {
        label: 'Less Than Equal To',
        value: 'LessThanEqualTo'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      }

    ];
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.relocationsDetails();
    this.getInternalOrganization();
    this.getPartyId();
    this.getPositionType();
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  detailPage(id) {
    this.router.navigate(["/hr/job-requisition/reAllocations-summary"], { queryParams: { interviewId: id } })
  }
  getInternalOrganization() {
    this.spinner.show();
    this.addEmployeePositionService.getInternalOrganization().subscribe(res => {
      this.internalOrganization = res.data;
      this.spinner.hide();
      for (const value of this.internalOrganization) {
        this.internalArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }
    
    })
   
  }
  JobRequisition() {
    this.router.navigate(["/hr/job-requisition"])
  }
  InternalJobPostingApplication() {
    this.router.navigate(["/hr/job-requisition/internal-job-posting"])
  }
  jobInterview() {
    this.router.navigate(["/hr/job-requisition/job-interview"])
  }
  approval() {
    this.router.navigate(["/hr/job-requisition/approval"])
  }
  Relocation() {
    this.router.navigate(["/hr/job-requisition/reAllocations"])
  }
  getPositionType() {
    this.spinner.show();
    let emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId: "",
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.reallocationsService.getEmployeePosition(this.employessSize, emplPosition).subscribe(res => {

      this.positionType = res.data;
      this.spinner.hide();
      for (const value of this.positionType) {
        this.positionArray.push({
          label: value.emplPositionId,
          value: value.emplPositionId
        })
      }
    })
   
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  relocationsDetails() {
    this.spinner.show();
    let relocation = {
      "emplPositionId": "",
      "emplPositionIdReportingTo": "",
      "internalOrganisation": "",
      "partyId": "",
      "reportingDateFrom": "",
      "reportingDateFromSearchType": "Contains",
      "reportingDateTo": "",
      "reportingDateToSearchType": "Contains"
    }
    this.reallocationsService.getRelocationList(this.applicationSize, relocation).subscribe((res: any) => {
      this.reLocationsList = res.data;
      this.spinner.hide();
    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    let relocation = {
      "emplPositionId": this.advanceSearch.emplPositionId ? this.advanceSearch.emplPositionId : '',
      "emplPositionIdReportingTo": this.advanceSearch.emplPositionIdReportingTo ? this.advanceSearch.emplPositionIdReportingTo : '',
      "internalOrganisation": this.advanceSearch.internalOrganisation ? this.advanceSearch.internalOrganisation : '',
      "partyId": this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
      "reportingDateFrom": this.datePipe.transform(this.advanceSearch.reportingDateFrom, "yyyy-MM-dd HH:mm:ss") ? this.datePipe.transform(this.advanceSearch.reportingDateFrom, "YYYY-MM-DD HH:mm:ss") : '',
      "reportingDateFromSearchType": this.advanceSearch.reportingDateFromSearchType ? this.advanceSearch.reportingDateFromSearchType : '',
      "reportingDateTo": this.datePipe.transform(this.advanceSearch.reportingDateTo, "yyyy-MM-dd HH:mm:ss") ? this.datePipe.transform(this.advanceSearch.reportingDateTo, "YYYY-MM-DD HH:mm:ss") : '',
      "reportingDateToSearchType": this.advanceSearch.reportingDateToSearchType ? this.advanceSearch.reportingDateToSearchType : '',
    }
    this.reallocationsService.getRelocationList(this.applicationSize, relocation).subscribe((res: any) => {
      this.reLocationsList = res.data;
      this.spinner.hide();
    })
   
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.emplPositionId = "";
    this.advanceSearch.emplPositionIdReportingTo = "";
    this.advanceSearch.internalOrganisation = "";
    this.advanceSearch.location = "";
    this.advanceSearch.partyId = "";
    this.advanceSearch.reportingDateFrom = "";
    this.advanceSearch.reportingDateTo = ""

    let relocation = {
      "emplPositionId": "",
      "emplPositionIdReportingTo": "",
      "internalOrganisation": "",
      "partyId": "",
      "reportingDateFrom": "",
      "reportingDateFromSearchType": "Contains",
      "reportingDateTo": "",
      "reportingDateToSearchType": "Contains"
    }
    this.reallocationsService.getRelocationList(this.applicationSize, relocation).subscribe((res: any) => {
      this.reLocationsList = res.data;
      this.spinner.hide();
    })
  
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
