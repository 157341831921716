<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/financial/payable/commission-invoice">Invoices </span>
                <span class="color-gry cursor-pointer"
                    routerLink="/financial/payable/commission-invoice/summary-commission-invoice"
                    [queryParams]="{invoiceId:invoiceId}">>Commission Invoice Summary </span>
                <span class="color-black pl-1"> > {{editMode ? 'Update':'Create'}} Invoice Item </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <div class="panel panel-default">
                                                <div class="w3-card-4" style="border:1px solid;
                                                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                                  background: #F5F7FA!important;">
                                          
                                                      <li [ngClass]="activeCategory==2?'active':''"><a>
                                                        {{editMode ? 'Update':'Create'}} Invoice Item</a></li>
                                                   
                                                   
                                          
                                                    </ul>
                                                  </div>
                                             <br>
                                                <div >
                                                    <div class="panel-body">
                                                        <form [formGroup]="invoiceItemForm">
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="invoiceItem">Invoice Item
                                                                                Type</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="invoiceItemTypes"
                                                                            optionlabel="label"
                                                                            formControlName="invoiceItemTypeId"
                                                                            placeholder="Select Invoice Type">
                                                                        </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="description">Description</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            placeholder="Description"
                                                                            formControlName="description">
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="accountId">Override Gl Account
                                                                                ID</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                [options]="GIAccounts"
                                                                                optionlabel="label"
                                                                                formControlName="overrideGlAccountId"
                                                                                placeholder="Select Account Id">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="itemId">Inventory Item
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="inventoryItems"
                                                                            optionlabel="label"
                                                                            formControlName="inventoryItemId"
                                                                            placeholder="Select Inventory Item">
                                                                        </p-dropdown>
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="productId">Product ID</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                [options]="productIds"
                                                                                optionlabel="label"
                                                                                formControlName="productId"
                                                                                placeholder="Select Product Id">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="featureId">Product Feature
                                                                                Id</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                [options]="featureIds"
                                                                                optionlabel="label"
                                                                                formControlName="productFeatureId"
                                                                                placeholder="Select Product Id">
                                                                            </p-dropdown>
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="quantity">Quantity</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            placeholder="Quantity"
                                                                            formControlName="quantity">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="uom">Uom</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="uomIds"
                                                                                optionlabel="label"
                                                                                formControlName="uomId"
                                                                                placeholder="Select Uom">
                                                                            </p-dropdown>
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="unitPrice">Unit Price</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            placeholder="Unit Price"
                                                                            formControlName="amount">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="flag">Taxable Flag</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                formControlName="taxableFlag"
                                                                                [options]="decisionArray"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>

                                                           
                                                            </div>
                                                            <div class="main-submit-button">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="submit()">{{editMode ?'Update':'Submit'}}</button>
                                                                <button class="btn btn-danger cursor-pointer ml-2"
                                                                    (click)="reset()">Cancel</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 