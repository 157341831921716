<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
              <span class="color-black pl-1 titlePanels">Content Overview</span>
              <span>
                  <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                  <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
              </span> 
            </div>
        </div>
    </div>

    
    <div class=" bg-white color-grey">
                <div>
                    <div class="container-fluid">
                        <div class="row" >
                          <form class="w-100">
                            
                              <div class="w-100">
                                <div class="panel-group">
                                  <div>
                                    <div class="card_div_first">
                                      <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            WebSite
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/webSite">Create New Website</p>
                                                  </div>
                                               
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs" >
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Forum
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div  class="row">
                                                  <p routerLink="/content/forum" style="cursor: pointer;    color: #0000FF;"
                                                   class="color-grey pl-20 col-lg-10 col-12">Create New Forum Group</p>
                                                      
                                                
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Content
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div  class="row">
                                                  <div class="article-container">
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                                      routerLink="/content/content-page">Find Content</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/content-page">Create Content</span>
                                                    </div>
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                                      routerLink="/content/content-page">Advance Search</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/content-page">Navigate Content</span>

                                                    </div>
     
                                                  </div>
                                                
                                                  
                                                </div>

        
                                              
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Content Setup
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div  class="row">
                                                  <div class="article-container">
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                                      routerLink="/content/content-setup">Create New Content Type</p>
                                                    </div>
                     
     
                                                  </div>
                                                
                                                  
                                                </div>

        
                                              
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Template
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div  class="row">
                                                  <div class="article-container">
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                                      routerLink="/content/template">Find Template</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/template">Create New Template</span>
                                                    </div>
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                                      routerLink="/content/template">List own created Templates</p>

                                                    </div>
     
                                                  </div>
                                                
                                                  
                                                </div>

        
                                              
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2 mb-5" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            CompDoc
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div  class="row">
                                                  <div class="article-container">
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                                      routerLink="/content/compDoc">Find CompDoc</p>
                                                      <span style="color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/compDoc">Create New Root CompDoc Template</span>
                                                    </div>
         
     
                                                  </div>
                                                
                                                  
                                                </div>

        
                                              
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                   
                                    

                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Import/Export
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/import-export">Party Import/Export</p>
                                                  </div>
                                               
                  
                
        
        
        
                                                  
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div> 
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs" >
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Accounting
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div  class="row">
                                                    <div class="article-container">
                                                      <div class="article">
                                                        <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/balance-sheet-headings-import">Balance Sheet Heading</p>
                                                        <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/balance-sheet-import">Balance Sheet</span>
                                                      </div>
                                                      <div class="article">
                                                        <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/trial-balance-import">Trial Balance Sheet Heading</p>
                                                        <span style="    color: #0000FF;" routerLink="/content/trial-balance-only-import" class="color-grey pl-20 col-lg-10 col-12">Trial Balance</span>
                                                      </div>
                                                      <div class="article">
                                                        <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/cash-flow-import">Cash Flows</p>
                                                        <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/gl-accounts-import">Gl Accounts</span>
                                                      </div>
                                                      <div class="article">
                                                        <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/open-customers-invoices-import">Open Customers Invoices</p>
                                                        <span style="    color: #0000FF;" routerLink="/content/open-vendors-invoices-import" class="color-grey pl-20 col-lg-10 col-12">Open Vendors Invoices</span>
                                                      </div>
    
                                                    </div>
                                                  
                                                    
                                                </div>
                                              
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Vendors
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                                 routerLink="/content/vendors-only-import">Vendors </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Inventory
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                                 routerLink="/content/inventory-count-import">Inventory Count </p>
                                                      
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                   
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Shipments
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                                 routerLink="/content/vendors-only-import">Shipments
                                                </p>
                                                      
                                              
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                   
                                    
                                    
                                    
                                   </div>
                                   
                                   <div class="card_div_second">
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                           Survey
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/survey">Find Survey</p>
                                                    <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/survey">Create New Survey</span>
                                                  </div>
   
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                          Blog
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/blog">Create New Blog</p>
                                                  </div> 
                                                </div>
                                              
                                          
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Data Resource
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/data-resource">Find Data Resource</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/data-resource">Create New Data Resource</span>

                                                    </div> 
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/data-resource/dataresource-navigate-page">Navigate</p>

                                                    </div> 
                                                  </div>
                                             
                                               
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Data Resource Setup
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/data-resource-setup">Create Data Resource Type</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/data-resource-setup/character-set">Create Character Set</span>

                                                    </div> 
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/data-resource-setup/category">Create Category</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/data-resource-setup/type-attribute">Create Data Resource Type Attribute</span>

                                                    </div> 
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/data-resource-setup/file-ext">Add File Extension</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/data-resource-setup/metadata-predicate">Add Meta Data Predicate</span>

                                                    </div> 
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/data-resource-setup/mimetype">Add Mime Type</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/data-resource-setup/mimetype-template">Add Mime Type Template</span>

                                                    </div> 
                                                  </div>
                                             
                                               
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    

                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            CMS
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/cms">Find Content</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/cms">Create CMS</span>

                                                    </div> 
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/cms/subsites">Sub Sites</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/cms/index">Index</span>

                                                    </div> 
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/cms/find">Find</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/cms/search-products">Search Products</span>


                                                    </div> 
                                                  </div>
                                             
                                               
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    
                                   
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Users
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/issue-created-import">Setup users</p>
                                                    <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/security-group-import">Security Group</span>
                                                  </div>
                                                

                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Email Configuration Setup
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/email-configuration-import">Email Configuration Setup</p>
                                                  </div>

                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    
                                    <div class="w3-card-4 cardEffect mt-2" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                         CRM
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/customers-only-import">Customers</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/customers-sites-import">Customer Sites</span>

                                                    </div>
  
                                                  </div>
                                             
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    
                                 
                                    <div class="w3-card-4 cardEffect mt-2" >
                                        <header class="w3-container w3-blue">
                                          <div class="header-tabs">
                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                              Warehouses
                                            </h4>
                                         
                                          </div>
                                        </header>
                                    
                                        <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div  class="row">
                                                  <div class="article-container">
                                                      <div class="article">
                                                        <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/warehouses-only-import">Warehouses</p>
                                                        <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/content/warehouses-location-import">Warehouses Locations</span>
  
                                                      </div>
    
                                                    </div>
                                               
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                       
                                    </div>
                                   
                                  
                                  
                                  </div>
                                   
                                   
                                  
                                
    
                              </div>
                              </div>
                            </div>
    
                          </form>
                        </div>
                      </div>
                  

                </div>

    </div>

        
    
</div>
<ngx-spinner></ngx-spinner>