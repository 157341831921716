import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-to-role',
  templateUrl: './add-to-role.component.html',
  styleUrls: ['./add-to-role.component.css']
})
export class AddToRoleComponent implements OnInit {
  partyId: any;
  activeCategory = 2;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  rows = 50;
  pageNo=1;
  search="";
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  roleList: any;
  id: string;
  mainrole: any;
  MainArrayValue: any[]=[];
  allroleArray: any[]=[];
  allrole: any;
  allRoleRole:string;
  secondRoleRole:string;
  mainRoleDrop:string;
  parent: any;
  parentArray: any[]=[];
  loginId: any;
  url: string;
  @Output() addModalClosed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;

  constructor(readonly accountsService: AccountsService,
    readonly headerService : HeaderService, readonly spinner: NgxSpinnerService,
    readonly router: Router,  readonly toastr:ToastrService,
    readonly activatedRoute: ActivatedRoute) {
     this.url='/users/user-detail-summary'
     }
     ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params['partyId'];
      this.loginId = params['login'];
    });
    this.getRoleMainType();
    this.getRoleAllType();
  }
  getRoleMainType(){
    this.spinner.show();
   this.id="MAIN"
     this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
       this.mainrole = res.data;
       this.spinner.hide();
        for(const value of this.mainrole){
         this.MainArrayValue.push({
           label:value.description,
           value:value.roleTypeId
         })
       } 

     })
    
    }
    getRoleAllType(){
      this.spinner.show();
      this.id="ALL"
        this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
          this.allrole = res.data;
          this.spinner.hide();
          for(const value of this.allrole){
            this.allroleArray.push({
              label:value.description,
              value:value.roleTypeId
            })
          }
        
        })
       
       }
 /*  getRoleMainType */
  onChange(event){
    this.spinner.show();
    this.parentArray=[]
    this.accountsService.getRoleParentTypeID(event.value).subscribe((res:any)=> {
      this.parent = res.data;
      this.spinner.hide();
      for(const value of this.parent){
        this.parentArray.push({
          label:value.description,
          value:value.roleTypeId
        })
      }
    })

  }
 
 addMainRole(): void {
  this.spinner.show();
      this.accountsService.postRoleTypeSave(this.partyId,this.mainRoleDrop).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.modalClose();
          this.spinner.hide();
          this.router.navigate([this.url], { queryParams: { partyId: this.partyId,login:this.loginId } });
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
     
    }
    addPraentRole(): void {
      this.spinner.show();
      this.accountsService.postRoleTypeSave(this.partyId,this.secondRoleRole).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.modalClose();
          this.spinner.hide();
          this.router.navigate([this.url], { queryParams: { partyId: this.partyId ,login:this.loginId} });
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    
    }
    addAllRole(): void {
      this.spinner.show();
         this.accountsService.postRoleTypeSave(this.partyId,this.allRoleRole).subscribe((res: any) => {         
           if (res.success) {
             this.toastr.success("Created Successfully");
             this.modalClose();
             this.spinner.hide();

             this.router.navigate([this.url], { queryParams: { partyId: this.partyId,login:this.loginId } });
           }
         }, (err) => {
          this.spinner.hide();
           for (const value of err.error.errors) {
             this.toastr.error(value.fieldName + ' ' + value.fieldError);
           }
         });
        
       }
       cancel(){
        this.router.navigate([this.url], { queryParams: { partyId: this.partyId,login:this.loginId } });
       }
       modalClose(): void {
        this.closebutton.nativeElement.click();
        this.addModalClosed.emit();
      }
       ngOnDestroy(): void {
        this.spinner.hide();
          }
}

