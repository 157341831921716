<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Resume</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords">Find Party Resume</span>
                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2" style="width: 113px;" data-toggle="modal" data-target="#exampleModalCenter">Create Party Resume</button>

                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 65%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>	
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">
                                                                    Resume ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" placeholder="Enter Resume ID" [(ngModel)]="advanceSearch.ResumeID" [options]="resumeIDArray" optionlabel="label" [ngModelOptions]="{standalone:true}" name="ResumeID">
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Party ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.PartyID" [ngModelOptions]="{standlone:true}" name="PartyID" [options]="partyIdArray" optionlabel="label" placeholder="Enter Party ID">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Content ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.ContentID" [ngModelOptions]="{standlone:true}" name="ContentID" [options]="ContentIDArray" optionlabel="label" placeholder="Enter Content ID">
                
                                                                    </p-dropdown>
                                                                </div>
                
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Resume Text</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="email" class="form-control" [(ngModel)]="advanceSearch.ResumeText" name="ResumeText" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Resume Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromdateFrom" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromDateTo" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                                </div>
                
                
                
                                                            </div>
                                                        </div>
                
                
                
                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -24%;">
                                                        <button type="submit" (click)="getFindResume()" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <!-- <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <p class="h-text">Find Party Resume</p>
                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" style="width: 113px;" data-toggle="modal" data-target="#exampleModalCenter">Create Party Resume</button>

                                    </div>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">
                                                    Resume ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" placeholder="Enter Resume ID" [(ngModel)]="advanceSearch.ResumeID" [options]="resumeIDArray" optionlabel="label" [ngModelOptions]="{standalone:true}" name="ResumeID">
                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Party ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.PartyID" [ngModelOptions]="{standlone:true}" name="PartyID" [options]="partyIdArray" optionlabel="label" placeholder="Enter Party ID">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Content ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.ContentID" [ngModelOptions]="{standlone:true}" name="ContentID" [options]="ContentIDArray" optionlabel="label" placeholder="Enter Content ID">

                                                    </p-dropdown>
                                                </div>


                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Resume Text</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="email" class="form-control" [(ngModel)]="advanceSearch.ResumeText" name="ResumeText" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                                </div>

                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Resume Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromdateFrom" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromDateTo" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                </div>



                                            </div>
                                        </div>



                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -24%;">
                                        <button type="submit" (click)="getFindResume()" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                    </div>
                                </div> -->
                                <br>
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Find Party Resume
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">


                                                <div class="card own-account-table">
                                                    <p-table [value]="employeeList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Resume ID
                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="name" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;"> Party ID
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;">Content ID
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;"> Resume Date
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;"> Resume Text
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>


                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;">Action
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td routerLinkActive="active" [ngStyle]="{'width':'190px','text-decoration':'none'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span class="account-button" (click)="employementDetail(product.findPerfReview.resumeId,product.findPerfReview.partyId,product.findPerfReview.contentId,
                                                                       product.resumeDate,product.findPerfReview.resumeText)">
                                                                       {{product.findPerfReview.resumeId}}
                                                                    </span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}" (click)="employeeProfile(product.findPerfReview.partyId)"> {{product.findPerfReview.partyId}}</td>
                                                                <td [ngStyle]="{'width':'190px'}"> {{product.findPerfReview.contentId}}</td>
                                                                <td [ngStyle]="{'width':'190px'}"> {{product.resumeDate}}</td>
                                                                <td [ngStyle]="{'width':'190px'}"> {{product.findPerfReview.resumeText}}</td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <button type="submit"  class="btn btn-secondary submit-btn ml-2" 
                                                                    (click)="deletePartyResume(product)" 
                                                                    >Delete</button>
                                                                </td>



                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">{{show?'Update':'Create'}} New Party Resume</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                Create New Party Resume </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                    Update New Party Resume </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createResume" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">
                                                            Resume ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ResumeID" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Content ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Content ID" formControlName="ContentID" [options]="ContentIDArray" optionlabel="label" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Party ID
                                                           </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Party ID" formControlName="PartyID" [options]="partyIdArray" optionlabel="label" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Resume Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="ResumeDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Date">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Resume Text</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ResumeText" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                                    </div>



                                                </div>
                                            </div>



                                        </div>

                                        <div class="main-submit-button" style="margin-right: 10%;">
                                            <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!show" class="btn btn-secondary submit-btn" (click)="submitResume()">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>