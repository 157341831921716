<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Communications of party:{{this.commEventIdPartyId}}                </span>
            </div>
            <div class=" bg-white color-grey">
              
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                      <div class="header-tabs">
                                        <h4 class=" common-styling h4Margin">
                                            Communications List
                                        </h4>
                                        <span style="margin-right: 1%;" class="edit-buttons">
                                            <a (click)="replyMail()">New Communication</a></span>
                                      </div>
                                    </header>
                                <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                           
                                            <div class="card own-account-table">
                                                <p-table [value]="getOverviewList" [paginator]="true" [rows]="rows"
                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined"></div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    Subject <p-sortIcon field="code"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                               <div style="color: white;"> Comm.Type ID <p-sortIcon field="name"></p-sortIcon>
                                                               </div>
                                                            </th>
                                                         
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="quantity"> <div style="color: white;"> Party ID From <p-sortIcon
                                                                    field="quantity"></p-sortIcon></div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">  Party ID To<p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                                </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">  Status ID<p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                         </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">  Entry Date<p-sortIcon field="price"></p-sortIcon>
                                                              
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">  Role Type Id<p-sortIcon field="price"></p-sortIcon>
                                                             
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;"> Remove<p-sortIcon field="price"></p-sortIcon>
                                                              
                                                                </div>
                                                            </th>
                                                          
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'170px'}"
                                                                (click)="accountDetails(product.communicationEventId)">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined"></div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                  {{product.subject}}
                                                                </div>
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.communicationEventTypeId}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">	{{product.partyIdFrom}}</td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.partyIdToDesc}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.statusId}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.entryDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.roleTypeIdTo}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                
                                                            </td>
                                                         
                                                    </ng-template>
                                                </p-table>
                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
         

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 