<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"> 
                </span>
                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            
            </div>
            <div class="w3-card-4 classCard">
                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12" style="color:#0d3769 ;font-size: 14px;font-weight: 600;">Add Work Effort</div>
                                <div class="w3-card-4 classCardView mt-2">
                                    <form class="w-100" [formGroup]="addWorkEffort">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important; flex-wrap: wrap !important; display: flex !important;">
                                                <li><a>Add Work Effort</a></li>
                                            </ul>
                                        </div>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" *ngIf="this.duplicate">
                                                        <label for="exampleInputEmail1"> Work Effort Id<span style="color: red;">*</span> </label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.duplicate">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="quickAssignPartyID" aria-describedby="emailHelp" placeholder="Enter WorkEffort Id">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA" *ngIf="!this.duplicate" style="margin-left: 50% !important;">
                                                        <label for="exampleInputEmail1"> Quick Assign Party ID </label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.duplicate">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="partyIdArray" formControlName="quickAssignPartyID" optionlabel="label" placeholder="Enter Quick Assign Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Name<span style="color: red;">*</span> </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="name" aria-describedby="emailHelp" placeholder="Enter Name">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Description </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Type<span style="color: red;">*</span> </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="type" [options]="TypeArray" optionlabel="label" placeholder="Enter Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Purpose </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="purpose" [options]="PurposeArray" optionlabel="label" placeholder="Enter Purpose">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Status<span style="color: red;">*</span> </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="status" [options]="StatusNewWorkEffortArray" optionlabel="label" placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Percent Complete </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="percentComplete" aria-describedby="emailHelp" placeholder="Enter Percent Complete">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Priority </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="priority" [options]="PriorityArray" optionlabel="label" placeholder="Enter Priority">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Scope </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="scope" [options]="scopeArray" optionlabel="label" placeholder="Enter Scope">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Estimated Start Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" formControlName="estimatedStartDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Start Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Estimated Completion Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" formControlName="estimatedCompletionDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Completion Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Actual Start Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" formControlName="actualStartDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual Start Date">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Actual Completion Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" formControlName="actualCompletionDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual Completion Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Communication Event ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="communicationEventID" [options]="CommunicationEventIdArray" optionlabel="label" placeholder="Enter Communication Event ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Reason </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="reason" aria-describedby="emailHelp" placeholder="Enter Reason">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Web Site ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="webSiteID" aria-describedby="emailHelp" placeholder="Enter Web Site ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Work Effort Parent Id </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="workEffortParentID" aria-describedby="emailHelp" placeholder="Enter Work Effort Parent ID">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Show As Enum ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="showAsEnumID" aria-describedby="emailHelp" placeholder="Enter Show As Enum ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Send Notification Email </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="sendNotificationEmail" [options]="yesNOArray" optionlabel="label" placeholder="Enter Send Notification Email">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Location Desc </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="locationDesc" aria-describedby="emailHelp" placeholder="Enter Location Desc">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Estimated Milli Seconds </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        formControlName="estimatedMilliSeconds" aria-describedby="emailHelp"     
                                                         placeholder="Enter Estimated Milli Seconds">

                                                        <!-- <p-dropdown filter="true" formControlName="estimatedMilliSeconds" optionlabel="label"
                                                         placeholder="Enter Estimated Milli Seconds">
                                                        </p-dropdown> -->
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Estimated Setup Milli Seconds </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        formControlName="estimatedSetupMilliSeconds" aria-describedby="emailHelp"
                                                        placeholder="Enter Estimated Setup Milli Seconds">
                                                        <!-- <p-dropdown filter="true" formControlName="estimatedSetupMilliSeconds" optionlabel="label"
                                                         placeholder="Enter Estimated Setup Milli Seconds">
                                                        </p-dropdown> -->
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Estimated Calc Method </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="estimatedCalcMethod" aria-describedby="emailHelp" placeholder="Enter Estimated Calc Method">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Actual Milli Seconds </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        formControlName="actualMilliSeconds" aria-describedby="emailHelp"  
                                                          placeholder="Enter Actual Milli Seconds">
                                                        <!-- <p-dropdown filter="true" formControlName="actualMilliSeconds" optionlabel="label" 
                                                        placeholder="Enter Actual Milli Seconds">
                                                        </p-dropdown> -->
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Actual Setup Milli Seconds </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        formControlName="actualSetupMilliSeconds" aria-describedby="emailHelp" 
                                                        placeholder="Enter Actual Setup Milli Seconds">
                                                        <!-- <p-dropdown filter="true" formControlName="actualSetupMilliSeconds" optionlabel="label"
                                                         placeholder="Enter Actual Setup Milli Seconds">
                                                        </p-dropdown> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Total Milli Seconds Allowed </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        formControlName="totalMilliSecondsAllowed" aria-describedby="emailHelp"
                                                        placeholder="Enter Total Milli Seconds Allowed">

                                                        <!-- <p-dropdown filter="true" formControlName="totalMilliSecondsAllowed" optionlabel="label" 
                                                        placeholder="Enter Total Milli Seconds Allowed">
                                                        </p-dropdown> -->
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Total Money Allowed </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="totalMoneyAllowed" aria-describedby="emailHelp" placeholder="Enter Total Money Allowed">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Currency </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="currency" [options]="CurrencyListArray" optionlabel="label" placeholder="Enter Currency">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Special Terms </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="specialTerms" aria-describedby="emailHelp" placeholder="Enter Special Terms">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Time Transparency </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="timeTransparency" aria-describedby="emailHelp" placeholder="Enter Time Transparency">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Universal ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="universalID" aria-describedby="emailHelp" placeholder="Enter Universal ID">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Source Reference ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="sourceReferenceID" aria-describedby="emailHelp" placeholder="Enter Source Reference ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Fixed Asset ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="fixedAssetID" aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Facility Id </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="facilityID" [options]="FacilityIdListArray" optionlabel="label" placeholder="Enter Facility ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Info URL </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="infoURL" aria-describedby="emailHelp" placeholder="Enter Info URL">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Temporal Expression </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="temporalExpression" [options]="TemporalExpressionArray" optionlabel="label" placeholder="Enter Temporal Expression">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Service Loader Name </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="serviceLoaderName" aria-describedby="emailHelp" placeholder="Enter Service Loader Name">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Quantity To Produce </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="quantityToProduce" aria-describedby="emailHelp" placeholder="Enter Quantity To Produce">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Quantity Produced </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="quantityProduced" aria-describedby="emailHelp" placeholder="Enter Quantity Produced">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Quantity Rejected </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="quantityRejected" aria-describedby="emailHelp" placeholder="Enter Quantity Rejected">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Reserv Persons </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="reservPersons" aria-describedby="emailHelp" placeholder="Enter Reserv Persons">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Reserv 2nd P P Perc </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="reserv2ndPerc" aria-describedby="emailHelp" placeholder="Enter Reserv 2nd P P Perc">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Reserv Nth P P Perc </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="reservNthPerc" aria-describedby="emailHelp" placeholder="Enter Reserv Nth P P Perc">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Accommodation Map ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="accommodationMapID" aria-describedby="emailHelp" placeholder="Enter Accommodation Map ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Accommodation Spot ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="accommodationSpotID" aria-describedby="emailHelp" placeholder="Enter Accommodation Spot ID">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Sequence Num </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="sequenceNum" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1"> Requirement ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="requirementID" [options]="RequirementListArray" optionlabel="label" placeholder="Enter Requirement ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-lg-12  main-submit-button" style="margin-left: -9%;">
                                            <button type="submit" (click)="createWorkEffort()" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>

                                <div class="w3-card-4 classCardView mt-2">
                                    <form class="w-100" [formGroup]="duplicateWorkEffort">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important; flex-wrap: wrap !important; display: flex !important;">
                                                <li><a>Duplicate Work Effort</a></li>
                                            </ul>
                                        </div>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> New Work Effort ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="newWorkEffortID" class="form-control" id="exampleInputEmail1" name="newWorkEffortID" aria-describedby="emailHelp" placeholder="Enter New Work Effort ID">
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12"> 
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Status ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="StatusID" [options]="statusArray" optionlabel="label" placeholder="Enter Status ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Duplicate</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <div class="checkbox-align">
                                                            <p-checkbox formControlName="duplicateWorkEffortAssignmentRates" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined" disabled></div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            <span class="text-black">&nbsp;Rate</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <div class="checkbox-align">
                                                            <p-checkbox formControlName="duplicateWorkEffortAssocs" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            <span class="text-black">&nbsp;Work Effort Association</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <div class="checkbox-align">
                                                            <p-checkbox formControlName="duplicateWorkEffortContents" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            <span class="text-black">&nbsp;Content</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <div class="checkbox-align">
                                                            <p-checkbox formControlName="duplicateWorkEffortNotes" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            <span class="text-black">&nbsp;Notes</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Remove</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <div class="checkbox-align">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            <span class="text-black">&nbsp;Rate</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <div class="checkbox-align">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            <span class="text-black">&nbsp;Work Effort Association</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <div class="checkbox-align">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            <span class="text-black">&nbsp;Content</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <div class="checkbox-align">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            <span class="text-black">&nbsp;Notes</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12  main-submit-button1">
                                            <button type="submit" (click)="duplicateWorkEffortFunction()" class="btn btn-secondary submit-btn">Duplicate</button>&nbsp;
                                            <button type="button" (click)="Reset()" class="btn btn-danger ml-2">Reset</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>