<div class="modal-header">
    <span class="color-black pl-1">Gift Card</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>

<div class="row p-15">
    <div class="col-12">
        <div class=" bg-white color-grey">
            <div class="w3-card-4 classCard border-card">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">
        
                    <li class="active" ><a>
                        {{editMode? 'Update':'Create'}} Gift Card
                </a></li>
                
                    
                
                </ul>
                </div>
            
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="giftForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -7%;">
                                                <label for="exampleInputEmail1">Card Number <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="cardNumber" aria-describedby="emailHelp"
                                                placeholder="Card Number">
                                            <small class="text-danger"
                                                *ngIf="!!giftForm.controls.cardNumber.invalid && !!giftForm.controls.cardNumber.touched">Card
                                                Number is Required</small>
                                            </div>
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            ">
                                                <label for="exampleInputEmail1">PIN number <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Pin Number"
                                                formControlName="pinNumber">
                                            <small class="text-danger"
                                                *ngIf="!!giftForm.controls.pinNumber.invalid && !!giftForm.controls.pinNumber.touched">PIN
                                                Number is Required</small>
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -7%;">
                                                <label for="exampleInputEmail1">Expiration Date <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown placeholder="Month" filter="true"
                                                [options]="expirationMonth" formControlName="expMonth">
                                            </p-dropdown>
                                            <small class="text-danger"
                                            *ngIf="!!giftForm.controls.expMonth.invalid && !!giftForm.controls.expMonth.touched">Expiration
                                            Month is Required</small>
                                            </div>
                                            <div class="col-lg-3">
                                            <p-dropdown class="ml-2" placeholder="Year" filter="true"
                                                [options]="expirationYear" formControlName="expYear">
                                            </p-dropdown>
                                        
                                        
                                        <small class="text-danger"
                                            *ngIf="!!giftForm.controls.expYear.invalid && !!giftForm.controls.expYear.touched">Expiration
                                            Year is Required</small>
                                        
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 14px!important;
                                            margin-left: -7%;">
                                                <label for="exampleInputEmail1">Description</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="description" aria-describedby="emailHelp"
                                                placeholder="Description">
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </div>
    
    
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: -9%;">
                                <button type="submit" *ngIf="editMode" (click)="update()"
                                    class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" *ngIf="!editMode" (click)="submit()"
                                    class="btn btn-secondary submit-btn">Submit</button>
                                
                            </div>
                        </div>
                    </div>
                </div>
            
               

            </div>
            </div>

        </div>
        
</div>



