import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountsService } from '../../accounts/accounts.service';
import { MyContactsService } from '../../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../../events/create-events/create-event-service';
import Swal from 'sweetalert2';
import { CreateContactsService } from '../../contacts/create-contacts/create-contacts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-partners-detail',
  templateUrl: './partners-detail.component.html',
  styleUrls: ['./partners-detail.component.css']
})
export class PartnersDetailComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  @ViewChild('closebutton12') closebutton12;
  @ViewChild('closebutton13') closebutton13;
  @ViewChild('closebutton14') closebutton14;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  editPartner: any;
  addAddress: any;
  addPhone: any;
  addEmail: any;
  addUrl: any;
  addSkype: any;
  addBookmark: any;
  fileUpload: any;
  addNote: any;
  addAgreement: any;
  hours: { label: string; value: string; }[];
  amPM: { label: string; value: string; }[];
  minute: { label: string; value: string; }[];
  NotePartnerList: any;
  partnerId: any;
  showNote: boolean;
  noteProduct: any;
  partnerById: any;
  aggreementsSupplierPartner: any;
  ownerShip: any;
  ownerShipArray: any[]=[];
  currencyList: any[];
  industryListArray: any[]=[];
  industryList: any;
  AgreementPro: any;
  personId: any;
  showAgree : boolean;
  partyIdArray :any[]=[]
  BookUpload: any;
  showUpload: boolean;
  bookMarksUrlPartner: any;
  showBookmark: boolean;
  productBook: any;
  fileData: File;
  fileURL: any;
  bookmarksUploadPartner: any;
  ClassificationBookmark: any;
  ClassificationBookmarkArray: any[]=[];
  logTaskCallPartnerId: any;
  logTaskForm: any;
  logEmailForm: any;
  Cases: any;
  CasesArray: any[]=[];
  SalesOpportunity: any;
  SalesOpportunityArray: any[]=[];
  OutboundArray: { label: string; value: string; }[];
  LogTaskEmail: any;
  showLogEmail: boolean;
  productLogEmail: any;
  showLogCall: boolean;
  productLogCall: any;
  PendingActivityTask: any;
  PendingActivityEvent: any;
  eventForm: any;
  taskForm: any;
  pendingActivityEvent: any;
  showActivityTask: boolean;
  productActivityTask: any;
  yesNoArray: { label: string; value: string; }[];
  AvailabilityArray: { label: string; value: string; }[];
  securityArray: { label: string; value: string; }[];
  PurposeArray: { label: string; value: string; }[];
  productActivityEvent: any;
  showActivityEvent: boolean;
  PurposeWebUrlArray: { label: string; value: string; }[];
  showWebUrl: boolean;
  productWebUrl: any;
  showSkype: boolean;
  productSkype: any;
  allDataList: any;
  phoneNumberPartnerList: any;
  postalAddressPartnerList: any;
  skypeContactPartnerList: any;
  webUrlPartnerList: any;
  emailAddressPartnerList: any;
  productTelecomNumber: any;
  showTelecomNumber: boolean;
  WebUrlPurpose: any;
  WebUrlPurposeArray: any[]=[];
  postalAddressPurpose: any;
  postalAddressPurposeArray: any[]=[];
  phoneNumberPurpose: any;
  phoneNumberPurposeArray: any[]=[];
  emailAddressPurpose: any;
  emailAddressPurposeArray: any[]=[];
  showEmail: boolean;
  productEmail: any;
  countryArray: any =[];
  productPostalAddress: any;
  statesArray: any=[];
  countryID: any;
  stateData: any;
  showPostalAddress: boolean;
  stateProvinceGeoId: any;
  array: any=[];
  stateArray: any=[];
  countryGeoId: any;
  countryId: any;
  AgreementsSupplierPartnerAll: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    public createContact: CreateContactsService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly spinner: NgxSpinnerService,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.editPartner = this._FormBuilder.group({
      partnerName: [''],
      localName: [''],
      siteName: [''],
      annualRevenue: [''],
      preferredCurrency: [''],
      industry: [''],
      numberOfEmployees: [''],
      ownership: [''],
      sitCode: [''],
      tickerSymbol: [''],
      description: [''],
      note: [''],
    });
    this.addAddress = this._FormBuilder.group({
      purpose: [''],
      toName: [''],
      attentionName: [''],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      state: [''],
      country: [''],
      directions: [''],
      solicitation: [''],
      postalCode: [''],
      postalCodeExt: [''],
    });
    this.addPhone = this._FormBuilder.group({
      purpose: [''],
      phone1: [''],
      phone2: [''],
      phone3: [''],
      ext: [''],
      personToAsk: [''],
      solicitation: [''],
    });
    this.addEmail = this._FormBuilder.group({
      purpose: [''],
      email: [''],
      solicitation: [''],
    });
    this.addUrl = this._FormBuilder.group({
      purpose: [''],
      url: [''],
      solicitation: [''],
    });
    this.addSkype = this._FormBuilder.group({
      skype: [''],
      solicitation: [''],
    });
    this.addBookmark = this._FormBuilder.group({
      classification: [''],
      url: [''],
      description: [''],
    });
    this.fileUpload = this._FormBuilder.group({
      classification: [''],
      file: [''],
      description: [''],
    });
    this.addNote = this._FormBuilder.group({
      note: ['']
    });
    this.addAgreement = this._FormBuilder.group({
      agreementDate: [''],
      hour: [''],
      minute: [''],
      amPm: [''],
      partyTo: [''],
      fromDate: [''],
      hourFrom: [''],
      minuteFrom: [''],
      amPmFrom: [''],
      thruDate: [''],
      hourThru: [''],
      minuteThru: [''],
      amPmThru: [''],
      description: [''],
      fullText: [''],
      agreement:['']
    });
    this.logTaskForm = this._FormBuilder.group({
      Type: [''] ,
      Outbound: [''] ,
      InternalParty: [''] ,
      ExternalParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Subject: [''] ,
      Message: [''] ,
      StartDateandTime: [''] ,
      hourFrom: [''] ,
      Duration: [''] ,
      minuteFrom: [''] ,
      amPmFrom: [''] ,
    });
    this.logEmailForm = this._FormBuilder.group({
      Type: [''] ,
      Outbound: [''] ,
      InternalParty: [''] ,
      ExternalParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Subject: [''] ,
      Message: [''] ,
      StartDateandTime: [''] ,
      Duration: [''] ,
      hourFrom: [''] ,
      minuteFrom: [''] ,
      amPmFrom: [''] 
    });
    this.taskForm = this._FormBuilder.group({
      Subject: [''] ,
      Security: [''] ,
      RelatedParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Purpose: [''] ,
      EndDateandTime: [''] ,
      Duration: [''] ,
      StartDateandTime: [''] ,
      Description: [''] ,
      Availability: [''] ,
      IgnoreSchedulingConflicts: [''] ,
      CompletionDateandTime: [''] ,
      CompletionDateHour: [''] ,
      CompletionAmPm: [''] ,
      CompletionDateMinutes: [''] ,
      StartDateAmPm: [''] ,
      StartDateandHour: [''] ,
      StartDateMinutes: [''] ,
    });
    this.eventForm = this._FormBuilder.group({
      Name: [''] ,
      Security: [''] ,
      RelatedParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      ScheduledStart: [''] ,
      EndDateandTime: [''] ,
      Duration: [''] ,
      Location: [''] ,
      Description: [''] ,
      Availability: [''] ,
      IgnoreSchedulingConflicts: [''] ,
      CompletionDateandTime: [''] ,
      CompletionDateHour: [''] ,
      CompletionAmPm: [''] ,
      CompletionDateMinutes: [''] ,
      StartDateAmPm: [''] ,
      StartDateandHour: [''] ,
      StartDateandTime: [''] ,
      StartDateMinutes: [''] ,
    });
    this.PurposeWebUrlArray = [
      {
        label: 'Primary Website URL',
        value: 'PRIMARY_WEB_URL'
      },
      {
        label: 'iCalendar URL',
        value: 'ICAL_URL'
      },

    ];
    this.PurposeArray = [
      {
        label: 'Email',
        value: 'WEPT_TASK_EMAIL'
      },
      {
        label: 'Phone Call',
        value: 'WEPT_TASK_PHONE_CALL'
      },

    ];
    this.AvailabilityArray =[
    {
      label: 'Available',
      value: 'WEPA_AV_AVAILABLE'
    },
    {
      label: 'Busy',
      value: 'WEPA_AV_BUSY'
    },
    {
      label: 'Away',
      value: 'WEPA_AV_AWAY'
    },
  ];
  this.securityArray =[
    {
      label: 'General, public access',
      value: 'WES_PUBLIC'
    },
    {
      label: 'Very restricted, confidential access',
      value: 'WES_CONFIDENTIAL'
    },
  
  ];
    this.hours = [
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },

    ];
    this.minute = [
      {
        label: '00',
        value: '00'
      },
      {
        label: '01',
        value: '01'
      },
      {
        label: '02',
        value: '02'
      },
      {
        label: '03',
        value: '03'
      },
      {
        label: '04',
        value: '04'
      },
      {
        label: '05',
        value: '05'
      },
      {
        label: '06',
        value: '06'
      },
      {
        label: '07',
        value: '07'
      },
      {
        label: '08',
        value: '08'
      },
      {
        label: '09',
        value: '09'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      },
      {
        label: '21',
        value: '21'
      },
      {
        label: '22',
        value: '22'
      },
      {
        label: '23',
        value: '23'
      },
      {
        label: '24',
        value: '24'
      },
      {
        label: '25',
        value: '25'
      },
      {
        label: '26',
        value: '26'
      },
      {
        label: '27',
        value: '27'
      },
      {
        label: '28',
        value: '28'
      },
      {
        label: '29',
        value: '29'
      },
      {
        label: '30',
        value: '30'
      },
      {
        label: '31',
        value: '31'
      },
      {
        label: '32',
        value: '32'
      },
      {
        label: '33',
        value: '33'
      }, {
        label: '34',
        value: '34'
      },
      {
        label: '35',
        value: '35'
      }, {
        label: '36',
        value: '36'
      },
      {
        label: '37',
        value: '37'
      }, {
        label: '38',
        value: '38'
      },
      {
        label: '39',
        value: '39'
      },
      {
        label: '40',
        value: '40'
      }, {
        label: '41',
        value: '41'
      },
      {
        label: '42',
        value: '42'
      },
      {
        label: '43',
        value: '43'
      }, {
        label: '44',
        value: '44'
      },
      {
        label: '45',
        value: '45'
      },
      {
        label: '46',
        value: '46'
      }, {
        label: '47',
        value: '47'
      },
      {
        label: '48',
        value: '48'
      },
      {
        label: '49',
        value: '49'
      }, {
        label: '50',
        value: '50'
      },
      {
        label: '51',
        value: '51'
      },
      {
        label: '52',
        value: '52'
      },
      {
        label: '53',
        value: '53'
      }, {
        label: '54',
        value: '54'
      }, {
        label: '55',
        value: '55'
      }, {
        label: '56',
        value: '56'
      }, {
        label: '57',
        value: '57'
      },
      {
        label: '58',
        value: '58'
      },
      {
        label: '59',
        value: '59'
      }


    ];
    this.amPM = [
      {
        label: 'AM',
        value: 'AM'
      },
      {
        label: 'PM',
        value: 'PM'
      },
    ];
    this.OutboundArray = [
      {
        label: 'Outbound',
        value: 'Y'
      },
      {
        label: 'Inbound',
        value: 'N'
      },

    ];
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partnerId = params.partyId;
    })

    const patchData = this.logTaskForm;
    setTimeout(() => {
      patchData.patchValue({
        InternalParty:"admin",
        ExternalParty:"admin",
        })
    
    }, 1000);

    const patchDatas = this.logEmailForm;
    setTimeout(() => {
      patchDatas.patchValue({
        InternalParty:"admin",
        ExternalParty:"admin",
        })
    
    }, 1000);

    this.getNotePartnerBId();
    this.getPartnerById();
    this.getAgreementsSupplierPartnerById();
    this.getOwnerShip();
    this.getCurrency();
    this.getIndustry();
    this.getPartyId();
    this.getBookMarksUrlIdPartner();
    this.getBookmarksUploadPartner();
    this.getPendingActivityEventPartner();
    this.getPendingActivityTaskPartner();
    this.getClassificationBookmark();
    this.getLogTaskCallPartnerId();
    this.getLogTaskEmailPartnerId();
    this.getCases();
    this.getSalesOpportunity();
    this.getEmailAddressPartner();
    this.getWebUrlPartner();
    this.getSkypeContactPartner();
    this.getPhoneNumberPartner();
    this.getPostalAddressPartner();
    this.getAllData();
    this.getEmailAddressPurpose();
    this.getPhoneNumberPurpose();
    this.getPostalAddressPurpose();
    this.getWebUrlPurpose();
    this.getCountryList();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  
  cancelSubmit(){
    this._Router.navigate(["/crm/Partners-Competitors"])
  }
  onStateChange(event) {
    this.stateProvinceGeoId = event
  }
  getCountryList() {
    this.spinner.show();
    this.createContact.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })
      })
    })
   
  }

  
  // getAgreementsSupplierPartnerAll() {
  //   this.spinner.show();
  //   this.accountsService.getAgreementsSupplierPartnerAll().subscribe(res => {
  //     this.AgreementsSupplierPartnerAll = res.data.getAgreementsSupplierAll;
  //     this.spinner.hide();
      
  //   })
  // }

  getStateList() {
    this.spinner.show();
    this.createContact.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
   
  }
  
  onCountryChange(event) {
    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }
  resetAddress(){
    this.showPostalAddress = false;
    this.addAddress.reset();
  }
  updatePostalAddress(product){
    this.showPostalAddress = true;
    this.productPostalAddress = product;

    setTimeout(()=>{
      const formValue = this.addAddress;
      formValue.patchValue({
        purpose: product.contactMechPurposeTypeId,
        toName: product.toName,
        attentionName: product.attnName,
        addressLine1: product.address1,
        addressLine2: product.address2,
        city: product.city,
        state: product.stateProvinceGeoId,
        country: product.countryGeoId,
        directions: product.directions,
        solicitation: product.allowSolicitation,
        postalCode: product.postalCode,
        postalCodeExt: product.postalCodeExt,
      }
      )
    },2000
    );
  }
  UpdatePostalAddressPartner(){
    this.spinner.show();
    const requestData = {
    
      "address1": this.addAddress.value.addressLine1 ,
  "address2":  this.addAddress.value.addressLine2 ,
  "allowSolicitation":  this.addAddress.value.solicitation ,
  "attnName":  this.addAddress.value.attentionName ,
  "city":  this.addAddress.value.city ,
  "contactMechPurposeTypeId":  this.addAddress.value.purpose ,
  "contactMechTypeId":  "POSTAL_ADDRESS",
  "countryGeoId":  this.addAddress.value.country ,
  "directions":  this.addAddress.value.directions ,
  "donePage": "" ,
  "errorPage":  "",
  "partnerId":  this.partnerId,
  "postalAddressId":   this.productPostalAddress.postalAddressId,
  "postalCode":  this.addAddress.value.postalCode ,
  "postalCodeExt":  this.addAddress.value.postalCodeExt ,
  "preContactMechTypeId":"POSTAL_ADDRESS" ,
  "stateProvinceGeoId":  this.addAddress.value.state ,
  "toName":  this.addAddress.value.toName ,

    }
    this.myContactsService.UpdatePostalAddressPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addAddress.reset();
        this.getPostalAddressPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  
createPostalAddressPartner(){
    this.spinner.show();
    const requestData = {
     
  "address1": this.addAddress.value.addressLine1 ,
  "address2":  this.addAddress.value.addressLine2 ,
  "allowSolicitation":  this.addAddress.value.solicitation ,
  "attnName":  this.addAddress.value.attentionName ,
  "city":  this.addAddress.value.city ,
  "contactMechPurposeTypeId":  this.addAddress.value.purpose ,
  "contactMechTypeId":  "POSTAL_ADDRESS",
  "countryGeoId":  this.addAddress.value.country ,
  "directions":  this.addAddress.value.directions ,
  "donePage": "" ,
  "errorPage":  "",
  "partnerId":  this.partnerId,
  "postalAddressId":  "",
  "postalCode":  this.addAddress.value.postalCode ,
  "postalCodeExt":  this.addAddress.value.postalCodeExt ,
  "preContactMechTypeId":"POSTAL_ADDRESS" ,
  "stateProvinceGeoId":  this.addAddress.value.state ,
  "toName":  this.addAddress.value.toName ,
}
    this.myContactsService.createPostalAddressPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addAddress.reset();
        this.getPostalAddressPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deletePostalAddressPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partnerId,
          "postalAddressId":product.postalAddressId
        }
        this.myContactsService.deletePostalAddressPartner(formData).subscribe(res => {
        this.getPostalAddressPartner(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  resetEmail(){
    this.showEmail= false;
    this.addEmail.reset();
  }

  updateEmail(product){
    this.showEmail = true;
    this.productEmail = product;

    setTimeout(()=>{
      const formValue = this.addEmail;
      formValue.patchValue({
        purpose: product.contactMechPurposeTypeId,
        email: product.emailAddress,
        solicitation: product.allowSolicitation, 
      }
      )
    },2000
    );
  }
  deleteEmailAddressPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partnerId,
          "partnerEmailAddressId": product.partnerEmailAddressId
        }
        this.myContactsService.deleteEmailAddressPartner(formData).subscribe(res => {
        this.getEmailAddressPartner (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  UpdateEmailAddressPartner(){
    this.spinner.show();
    const requestData = {
    
      "allowSolicitation":  this.addEmail.value.solicitation ,
      "contactMechPurposeTypeId":  this.addEmail.value.purpose ,
      "contactMechTypeId":  "EMAIL_ADDRESS" ,
      "emailAddress":  this.addEmail.value.email ,
      "partnerEmailAddressId":  this.productEmail.partnerEmailAddressId ,
      "partnerId": this.partnerId,

    }
    this.myContactsService.UpdateEmailAddressPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.addEmail.reset();
        this.getEmailAddressPurpose();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createEmailAddressPartner(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation":  this.addEmail.value.solicitation ,
      "contactMechPurposeTypeId":  this.addEmail.value.purpose ,
      "contactMechTypeId":  "EMAIL_ADDRESS" ,
      "emailAddress":  this.addEmail.value.email ,
      "partnerEmailAddressId":  "",
      "partnerId": this.partnerId
    }
    this.myContactsService.createEmailAddressPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.getEmailAddressPartner();
        this.addEmail.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getWebUrlPurpose() {
    this.spinner.show();
    this.myContactsService.getWebUrlPurpose().subscribe(res => {
      this.WebUrlPurpose = res.data[0].data;
      this.spinner.hide();
      for (const value of this.WebUrlPurpose) {
        this.WebUrlPurposeArray.push({
          label: value.description,
          value: value.contactMechPurposeTypeId
        })
      }
    })

  }
  getPostalAddressPurpose() {
    this.spinner.show();
    this.myContactsService.getPostalAddressPurpose().subscribe(res => {
      this.postalAddressPurpose = res.data[0].data;
      this.spinner.hide();
      for (const value of this.postalAddressPurpose) {
        this.postalAddressPurposeArray.push({
          label: value.description,
          value: value.contactMechPurposeTypeId
        })
      }
    })

  }
  getPhoneNumberPurpose() {
    this.spinner.show();
    this.myContactsService.getPhoneNumberPurpose().subscribe(res => {
      this.phoneNumberPurpose = res.data[0].data;
      this.spinner.hide();
      for (const value of this.phoneNumberPurpose) {
        this.phoneNumberPurposeArray.push({
          label: value.description,
          value: value.contactMechPurposeTypeId
        })
      }
    })

  }
  getEmailAddressPurpose() {
    this.spinner.show();
    this.myContactsService.getEmailAddressPurpose().subscribe(res => {
      this.emailAddressPurpose = res.data[0].data;
      this.spinner.hide();
      for (const value of this.emailAddressPurpose) {
        this.emailAddressPurposeArray.push({
          label: value.description,
          value: value.contactMechPurposeTypeId
        })
      }
    })

  }

  UpdatePhoneNumberPartner(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation": this.addPhone.value.solicitation ,
      "areaCode":this.addPhone.value.phone2 ,
      "askForName": this.addPhone.value.personToAsk ,
      "contactMechPurposeTypeId": this.addPhone.value.purpose ,
      "contactMechTypeId": "TELECOM_NUMBER" ,
      "contactNumber": this.addPhone.value.phone3 ,
      "countryCode": this.addPhone.value.phone1 ,
      "donePage": "" ,
      "errorPage": "",
      "extension": this.addPhone.value.ext ,
      "partnerId": this.partnerId ,
      "preContactMechTypeId": "TELECOM_NUMBER",
      "telecomNumberId": this.productTelecomNumber.telecomNumberId,


    }
    this.myContactsService.UpdatePhoneNumberPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.addPhone.reset();
       this.getPhoneNumberPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
   
  deletePartnerTelecomNumber(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partnerId,
          "telecomNumberId": product.telecomNumberId
        }
        this.myContactsService.deletePartnerTelecomNumber(formData).subscribe(res => {
        this.getPhoneNumberPartner(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createPhoneNumberPartner(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation": this.addPhone.value.solicitation ,
      "areaCode":this.addPhone.value.phone2 ,
      "askForName": this.addPhone.value.personToAsk ,
      "contactMechPurposeTypeId": this.addPhone.value.purpose ,
      "contactMechTypeId": "TELECOM_NUMBER" ,
      "contactNumber": this.addPhone.value.phone3 ,
      "countryCode": this.addPhone.value.phone1 ,
      "donePage": "" ,
      "errorPage": "",
      "extension": this.addPhone.value.ext ,
      "partnerId": this.partnerId ,
      "preContactMechTypeId": "TELECOM_NUMBER",
      "telecomNumberId": ""

    }
    this.myContactsService.createPhoneNumberPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.addPhone.reset();
        this.getPhoneNumberPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  resetTelecomNumber(){
    this.showTelecomNumber = false;
    this.addPhone.reset();
  }

  updateTelecomNumber(product){
    this.showTelecomNumber = true;
    this.productTelecomNumber = product;

    setTimeout(()=>{
      const formValue = this.addPhone;
      formValue.patchValue({
        purpose: product.contactMechPurposeTypeId,
        phone1:  product.countryCode,
        phone2:  product.areaCode,
        phone3:  product.contactNumber,
        ext:  product.extension,
        personToAsk: product.askForName,
        solicitation:  product.allowSolicitation,
      }
      )

    },2000
    );

  }
  getEmailAddressPartner() {
    this.spinner.show();
    this.myContactsService.getEmailAddressPartner(this.partnerId).subscribe(res => {
      this.emailAddressPartnerList = res.data[0].getPhoneNumberPartner;
      this.spinner.hide();

    })
  }
  getWebUrlPartner() {
    this.spinner.show();
    this.myContactsService.getWebUrlPartner(this.partnerId).subscribe(res => {
      this.webUrlPartnerList = res.data[0].getWebUrlPartner;
      this.spinner.hide();

    })
  }
  getSkypeContactPartner() {
    this.spinner.show();
    this.myContactsService.getSkypeContactPartner(this.partnerId).subscribe(res => {
      this.skypeContactPartnerList = res.data[0].getSkypeContactPartner;
      this.spinner.hide();

    })
  }
  getPostalAddressPartner() {
    this.spinner.show();
    this.myContactsService.getPostalAddressPartner(this.partnerId).subscribe(res => {
      this.postalAddressPartnerList = res.data[0].getPostalAddressPartner;
      this.spinner.hide();

    })
  }
  getPhoneNumberPartner() {
    this.spinner.show();
    this.myContactsService.getPhoneNumberPartner(this.partnerId).subscribe(res => {
      this.phoneNumberPartnerList = res.data[0].getPhoneNumberPartner;
      this.spinner.hide();

    })
  }
  getAllData() {
    this.spinner.show();
    this.myContactsService.getAllData(this.partnerId).subscribe(res => {
      this.allDataList = res.data[0].getData;
      this.spinner.hide();

    })
  }
  resetSkype(){
    this.showSkype = false;
    this.addSkype.reset();
  }
  UpdateSkype(product){
    this.showSkype = true;
    this.productSkype = product;

    setTimeout(()=>{
      const formValue = this.addSkype;
      formValue.patchValue({
        skype: product.infoString,
        solicitation: product.allowSolicitation,
      }
      )

    },2000
    );

  }
  UpdateSkypeContactPartner(){
    this.spinner.show();

 
    const requestData = {
      "allowSolicitation":this.addSkype.value.solicitation ,
  "contactMechTypeId": "SKYPE",
  "infoString": this.addSkype.value.skype ,
  "partnerId": this.partnerId,
  "preContactMechTypeId": "SKYPE",
  "skypeContactId":  this.productSkype.skypeContactId
    }
    this.myContactsService.UpdateSkypeContactPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated successfully");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.addSkype.reset();
       this.getSkypeContactPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createSkypeContactPartner(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation":this.addSkype.value.solicitation ,
  "contactMechTypeId": "SKYPE",
  "infoString": this.addSkype.value.skype ,
  "partnerId": this.partnerId,
  "preContactMechTypeId": "SKYPE",
  "skypeContactId": ""
    }
    this.myContactsService.createSkypeContactPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.addSkype.reset();
        this.getSkypeContactPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteSkypeContactPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partnerId,
          "skypeContactId": product.skypeContactId
        }
        this.myContactsService.deleteSkypeContactPartner(formData).subscribe(res => {
        this.getSkypeContactPartner (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
 
  deleteWebUrlPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partnerId,
          "partnerWebUrlId": product.partnerWebUrlId
        }
        this.myContactsService.deleteWebUrlPartner(formData).subscribe(res => {
       this.getWebUrlPartner (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  UpdateWebPartner(product){
    this.showWebUrl = true;
    this.productWebUrl = product;

    setTimeout(()=>{
      const formValue = this.addUrl;
      formValue.patchValue({
        purpose: product.contactMechPurposeTypeId ,
        url: product.infoString,
        solicitation: product.allowSolicitation,
      }
      )

    },2000
    );

  }
  resetWebUrl(){
    this.showWebUrl = false;
    this.addUrl.reset();
  }
  UpdateWebUrlPartner(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation":  this.addUrl.value.solicitation ,
      "contactMechPurposeTypeId": this.addUrl.value.purpose ,
      "contactMechTypeId": "WEB_ADDRESS",
      "infoString": this.addUrl.value.url ,
      "partnerId": this.partnerId,
      "partnerWebUrlId":  "" ,

    }
    this.myContactsService.UpdateWebUrlPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.addUrl.reset();
     this.getWebUrlPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createWebUrlPartner(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation":  this.addUrl.value.solicitation ,
      "contactMechPurposeTypeId": this.addUrl.value.purpose ,
      "contactMechTypeId": "WEB_ADDRESS",
      "infoString": this.addUrl.value.url ,
      "partnerId": this.partnerId,
      "partnerWebUrlId":  "" ,
    }
    this.myContactsService.createWebUrlPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.addUrl.reset();
      this.getWebUrlPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deletePendingActivityTaskPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partnerId,
          "pendingActivityTaskId": product.pendingActivityTaskId
         
        }
        this.myContactsService.deletePendingActivityTaskPartner(formData).subscribe(res => {
          this.getLogTaskEmailPartnerId (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deletePendingActivityEventPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partnerId,
    "pendingActivitiesId": product.pendingActivitiesId
        }
        this.myContactsService.deletePendingActivityEventPartner(formData).subscribe(res => {
          this.getLogTaskEmailPartnerId (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updatePendingActivityEvent(product) {
    this.spinner.show();
    this.showActivityEvent = true ;
    this.productActivityEvent = product ;
   
      setTimeout(() => {
        const formValue = this.eventForm;
        formValue.patchValue({
          Security:  product.scopeEnumId ,
          RelatedParty:  product.relatedPartyId ,
          Opportunity:  product.lookupOpportunity ,
          Case: "" ,
          Purpose:  product.workEffortPurposeTypeId ,
          Duration:  product.duration ,
          StartDateandTime: this.datePipe.transform(product.estimatedStartDateDate ,"yyyy-MM-dd hh:mm:ss") , 
          Description:  product.description ,
          Availability:  product.availability ,
          IgnoreSchedulingConflicts:  "",
          CompletionDateandTime: this.datePipe.transform(product.estimatedCompletionDate ,"yyyy-MM-dd hh:mm:ss") , 
          CompletionDateHour:  product.estimatedCompletionDateHour ,
          CompletionAmPm:  product.estimatedCompletionDateAmPm ,
          CompletionDateMinutes:  product.estimatedCompletionDateMinutes ,
          StartDateAmPm:  product.estimatedStartDateAmPm ,
          StartDateandHour:  product.estimatedStartDateHour ,
          StartDateMinutes:  product.estimatedStartDateMinutes ,
          Name:   product.workEffortName ,
          Location:  product.location ,
        })
      }, 2000);
      this.spinner.hide();
  }
  UpdatePendingActivityEventPartner(){
    this.spinner.show();
    const requestData = {
      "availability": this.eventForm.value.Availability,
      "currentStatusId": "EVENT_SCHEDULED",
      "description": this.eventForm.value.Description,
      "duration": this.eventForm.value.Duration,
      "estimatedCompletionCompositeType": "Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.eventForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedCompletionDateAmPm": this.eventForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.eventForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.eventForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.eventForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate":this.datePipe.transform(this.eventForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedStartDateHour": this.eventForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.eventForm.value.StartDateMinutes,
      "location": this.eventForm.value.Location,
      "lookupCase": "",
      "lookupOpportunity": this.eventForm.value.Opportunity,
      "pendingActivitiesId":this.productActivityEvent.pendingActivitiesId,
      "relatedPartyId": this.eventForm.value.RelatedParty,
      "scopeEnumId": this.eventForm.value.Security,
      "type": "",
      "workEffortName": this.eventForm.value.Name,
      "workEffortPurposeTypeId": "",
      "workEffortTypeId": "EVENT",
      "partnerId": this.partnerId,
    }
    this.myContactsService.UpdatePendingActivityEventPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityEvent Updated Successfully");
        this.spinner.hide();
        this.closebutton13.nativeElement.click();
        this.eventForm.reset();
        this.getPendingActivityEventPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  CreatePendingActivityEventPartner(){
    this.spinner.show();
    const requestData = {
      "availability": this.eventForm.value.Availability,
      "currentStatusId": "EVENT_SCHEDULED",
      "description": this.eventForm.value.Description,
      "duration": this.eventForm.value.Duration, 
      "estimatedCompletionCompositeType": "Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.eventForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedCompletionDateAmPm": this.eventForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.eventForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.eventForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.eventForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate":this.datePipe.transform(this.eventForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedStartDateHour": this.eventForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.eventForm.value.StartDateMinutes,
      "location": this.eventForm.value.Location,
      "lookupCase": "",
      "lookupOpportunity": this.eventForm.value.Opportunity,
      "pendingActivitiesId":"",
      "relatedPartyId": this.eventForm.value.RelatedParty,
      "scopeEnumId": this.eventForm.value.Security,
      "type": "",
      "workEffortName": this.eventForm.value.Name,
      "workEffortPurposeTypeId": "",
      "workEffortTypeId": "EVENT",
      "partnerId": this.partnerId,
 
    }
    this.myContactsService.CreatePendingActivityEventPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityEvent Created Successfully");
        this.spinner.hide();
        this.closebutton13.nativeElement.click();
        this.eventForm.reset();
        this.getPendingActivityEventPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updatePendingActivityTask(product) {
    this.spinner.show();
    this.showActivityTask = true ;
    this.productActivityTask = product ;
   
      setTimeout(() => {
        const formValue = this.taskForm;
        formValue.patchValue({
          Subject: product.workEffortName ,
          Security:  product.scopeEnumId ,
          RelatedParty:  product.relatedPartyId ,
          Opportunity:  product.lookupOpportunity ,
          Case: "" ,
          Purpose:  product.workEffortPurposeTypeId ,

          Duration:  product.duration ,
          StartDateandTime: this.datePipe.transform(product.estimatedStartDateDate ,"yyyy-MM-dd hh:mm:ss") , 
          Description:  product.description ,
          Availability:  product.availability ,
          IgnoreSchedulingConflicts:  "",
          CompletionDateandTime: this.datePipe.transform(product.estimatedCompletionDate ,"yyyy-MM-dd hh:mm:ss") , 
          CompletionDateHour:  product.estimatedCompletionDateHour ,
          CompletionAmPm:  product.estimatedCompletionDateAmPm ,
          CompletionDateMinutes:  product.estimatedCompletionDateMinutes ,
          StartDateAmPm:  product.estimatedStartDateAmPm ,
          StartDateandHour:  product.estimatedStartDateHour ,
          StartDateMinutes:  product.estimatedStartDateMinutes ,
        })
      }, 2000);
      this.spinner.hide();
  }
  UpdatePendingActivityTaskPartner(){
    this.spinner.show();
    const requestData = {
      "availability":this.taskForm.value.Availability,
      "currentStatusId": "TASK_SCHEDULED"  ,
      "description": this.taskForm.value.Description,
      "duration": this.taskForm.value.Duration,
      "estimatedCompletionCompositeType":"Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.taskForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedCompletionDateAmPm": this.taskForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.taskForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.taskForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.taskForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate":this.datePipe.transform(this.taskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedStartDateHour": this.taskForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.taskForm.value.StartDateMinutes,
      "ignoreSchedulingConflicts": this.taskForm.value.IgnoreSchedulingConflicts,
      "lookupCase": "",
      "lookupOpportunity": this.taskForm.value.Opportunity,
      "pendingActivityTaskId":  this.productActivityTask.pendingActivityTaskId,
      "relatedPartyId": this.taskForm.value.RelatedParty,
      "scopeEnumId": this.taskForm.value.Security,
      "type": "",
      "workEffortName": this.taskForm.value.Subject,
      "workEffortPurposeTypeId": this.taskForm.value.Purpose,
      "workEffortTypeId": "TASK",
      "partnerId": this.partnerId,
    }

    this.myContactsService.UpdatePendingActivityTaskPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityTask Update successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.taskForm.reset();
        this.getPendingActivityTaskPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createPendingActivityTaskPartner(){
    this.spinner.show();
    const requestData = {
      "availability":this.taskForm.value.Availability,
      "currentStatusId": "TASK_SCHEDULED"  ,
      "description": this.taskForm.value.Description,
      "duration": this.taskForm.value.Duration,
      "estimatedCompletionCompositeType":"Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.taskForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedCompletionDateAmPm": this.taskForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.taskForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.taskForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.taskForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate":this.datePipe.transform(this.taskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedStartDateHour": this.taskForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.taskForm.value.StartDateMinutes,
      "ignoreSchedulingConflicts": this.taskForm.value.IgnoreSchedulingConflicts,
      "lookupCase": "",
      "lookupOpportunity": this.taskForm.value.Opportunity,
      "pendingActivityTaskId": "",
      "relatedPartyId": this.taskForm.value.RelatedParty,
      "scopeEnumId": this.taskForm.value.Security,
      "type": "",
      "workEffortName": this.taskForm.value.Subject,
      "workEffortPurposeTypeId": this.taskForm.value.Purpose,
      "workEffortTypeId": "TASK",
      "partnerId": this.partnerId,
  
    }

    this.myContactsService.createPendingActivityTaskPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityTask Created successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.taskForm.reset();
        this.getPendingActivityTaskPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getPendingActivityEventPartner() {
    this.spinner.show();
    this.myContactsService.getPendingActivityEventPartner(this.partnerId).subscribe(res => {
      this.pendingActivityEvent = res.data[0].getPendingActivityEventPartner;
      setTimeout(() => {
        const formValue = this.eventForm;
        formValue.patchValue({
          RelatedParty: this.partnerId,
          ScheduledStart : this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
          Duration : "1:00"
        })
  
      }, 2000);
      this.spinner.hide();
    })
  }
  getPendingActivityTaskPartner() {
    this.spinner.show();
    this.myContactsService.getPendingActivityTaskPartner(this.partnerId).subscribe(res => {
      this.PendingActivityTask = res.data[0].getPendingActivityTaskPartner;
      this.spinner.hide();
    })
  }
  createLogTaskCallPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logTaskForm.value.amPmFrom,
  "actualStartDateCompositeType":"Timestamp",
  "actualStartDateDate": this.datePipe.transform(this.logTaskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
  "actualStartDateHour": this.logTaskForm.value.hourFrom,
  "actualStartDateMinutes":  this.logTaskForm.value.minuteFrom,
  "caseId":"",
  "completionStartDateAmPm":"",
  "completionStartDateCompositeType":"",
  "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
  "completionStartDateHour":  "23",
  "completionStartDateMinutes":  "59",
  "content":this.logTaskForm.value.Message,
  "currentStatusId":"EXST_DEFINED",
  "duration":this.logTaskForm.value.Duration,
  "externalPartyId":this.logTaskForm.value.ExternalParty,
  "inboundAndOutBound":this.logTaskForm.value.Outbound,
  "internalPartyId":this.logTaskForm.value.InternalParty,
  "logTaskCasesCallId":"",
  "salesOpportunityId":this.logTaskForm.value.Opportunity,
  "workEffortName":this.logTaskForm.value.Subject,
  "workEffortPurposeTypeId": "WEPT_TASK_PHONE_CALL",
  "partnerId": this.partnerId,
 
    }

    this.myContactsService.createLogTaskCallPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.logTaskForm.reset();
        this.getLogTaskCallPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  UpdateLogTaskCallPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logTaskForm.value.amPmFrom,
  "actualStartDateCompositeType":"Timestamp",
  "actualStartDateDate": this.datePipe.transform(this.logTaskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
  "actualStartDateHour": this.logTaskForm.value.hourFrom,
  "actualStartDateMinutes":  this.logTaskForm.value.minuteFrom,
  "caseId":"",
  "completionStartDateAmPm":"",
  "completionStartDateCompositeType":"",
  "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
  "completionStartDateHour":  "23",
  "completionStartDateMinutes":  "59",
  "content":this.logTaskForm.value.Message,
  "currentStatusId":"EXST_DEFINED",
  "duration":this.logTaskForm.value.Duration,
  "externalPartyId":this.logTaskForm.value.ExternalParty,
  "inboundAndOutBound":this.logTaskForm.value.Outbound,
  "internalPartyId":this.logTaskForm.value.InternalParty,
  "logTaskCasesCallId":this.productLogCall.logTaskCasesCallId ,
  "salesOpportunityId":this.logTaskForm.value.Opportunity,
  "workEffortName":this.logTaskForm.value.Subject,
  "workEffortPurposeTypeId": "WEPT_TASK_PHONE_CALL",
  "partnerId": this.partnerId,
 
    }

    this.myContactsService.UpdateLogTaskCallPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.logTaskForm.reset();
        this.getLogTaskCallPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getSalesOpportunity(){
    this.spinner.show();
    this.myContactsService.getSalesOpportunity().subscribe(res=>{
      this.SalesOpportunity = res.data[0].getSalesOpportunity;
      this.spinner.hide();
      for (const value of this.SalesOpportunity) {
        this.SalesOpportunityArray.push({
          label: value.opportunityName,
          value: value.salesOpportunityId
        })
      }
    })
  }
  getCases(){
    this.spinner.show();
    this.myContactsService.getCases().subscribe(res=>{
      this.Cases = res.data[0].getCasesById;
      this.spinner.hide();
      for (const value of this.Cases) {
        this.CasesArray.push({
          label: value.custRequestName,
          value: value.custRequestId
        })
      }
    })
  }
  updateLogCall(product) {
    this.spinner.show();
    this.showLogCall = true ;
    this.productLogCall = product ;
   
      setTimeout(() => {
        const formValue = this.logTaskForm;
        formValue.patchValue({
          Type: product.workEffortPurposeTypeId ,
          Outbound:  product.inboundAndOutBound ,
          InternalParty:  product.internalPartyId ,
          ExternalParty:  product.externalPartyId ,
          Opportunity:  product.salesOpportunityId ,
          Case:  product.partnerId ,
          Subject:  "",
          Message:  "",
          StartDateandTime:  product.actualStartDateDate ,
          Duration:  product.duration ,
          hourFrom:  product.actualStartDateHour ,
          minuteFrom:  product.actualStartDateMinutes ,
          amPmFrom:  product.actualStartDateAmPm ,

         
        })
      }, 2000);
      this.spinner.hide();
  }
  resetLogCall(){
    this.showLogCall = false ;
    this.logTaskForm.reset();
  }
  resetLogEmail(){
    this.showLogEmail = false ;
    this.logEmailForm.reset();
  }
  updateLogEmail(product) {
    this.spinner.show();
    this.showLogEmail = true ;
    this.productLogEmail = product ;
   
      setTimeout(() => {
        const formValue = this.logEmailForm;
        formValue.patchValue({
          Type: product.workEffortPurposeTypeId ,
          Outbound:  product.inboundAndOutBound ,
          InternalParty:  product.internalPartyId ,
          ExternalParty:  product.externalPartyId ,
          Opportunity:  product.salesOpportunityId ,
          Case:  product.partnerId ,
          Subject:  "",
          Message:  "",
          StartDateandTime:  product.actualStartDateDate ,
          Duration:  product.duration ,
          hourFrom:  product.actualStartDateHour ,
          minuteFrom:  product.actualStartDateMinutes ,
          amPmFrom:  product.actualStartDateAmPm ,
        })
      }, 2000);
      this.spinner.hide();
  }
  createLogTaskEmailPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logEmailForm.value.amPmFrom,
      "actualStartDateCompositeType":"Timestamp",
      "actualStartDateDate":this.datePipe.transform(this.logEmailForm.value.StartDateandTime, "yyyy-MM-dd"),
      "actualStartDateHour": this.logEmailForm.value.hourFrom,
      "actualStartDateMinutes": this.logEmailForm.value.minuteFrom,
      "caseId": "",
      "completionStartDateAmPm": "",
      "completionStartDateCompositeType": "",
      "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
      "completionStartDateHour": "24",
      "completionStartDateMinutes": "60",
      "content": this.logEmailForm.value.Message ,
      "currentStatusId": "EXST_DEFINED",
      "duration":this.logEmailForm.value.Duration,
      "externalPartyId": this.logEmailForm.value.ExternalParty,
      "inboundAndOutBound": this.logEmailForm.value.Outbound,
      "internalPartyId": this.logEmailForm.value.InternalParty,
      "logTaskEmailId": "",
      "salesOpportunityId":this.logEmailForm.value.Opportunity,
      "workEffortName":this.logEmailForm.value.Subject,
      "workEffortPurposeTypeId":"WEPT_TASK_EMAIL",
      "partnerId":  this.partnerId
    }

    this.myContactsService.createLogTaskEmailPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton12.nativeElement.click();
        this.logEmailForm.reset();
        this.getLogTaskEmailPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  UpdateLogTaskEmailPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logEmailForm.value.amPmFrom,
      "actualStartDateCompositeType":"Timestamp",
      "actualStartDateDate":this.datePipe.transform(this.logEmailForm.value.StartDateandTime, "yyyy-MM-dd"),
      "actualStartDateHour": this.logEmailForm.value.hourFrom,
      "actualStartDateMinutes": this.logEmailForm.value.minuteFrom,
      "caseId": "",
      "completionStartDateAmPm": "",
      "completionStartDateCompositeType": "",
      "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
      "completionStartDateHour": "24",
      "completionStartDateMinutes": "60",
      "content": this.logEmailForm.value.Message ,
      "currentStatusId": "EXST_DEFINED",
      "duration":this.logEmailForm.value.Duration,
      "externalPartyId": this.logEmailForm.value.ExternalParty,
      "inboundAndOutBound": this.logEmailForm.value.Outbound,
      "internalPartyId": this.logEmailForm.value.InternalParty,
      "logTaskEmailId": this.productLogEmail.logTaskEmailId ,
      "salesOpportunityId":this.logEmailForm.value.Opportunity,
      "workEffortName":this.logEmailForm.value.Subject,
      "workEffortPurposeTypeId":"WEPT_TASK_EMAIL",
      "partnerId":  this.partnerId,

    }

    this.myContactsService.UpdateLogTaskEmailPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton12.nativeElement.click();
        this.logEmailForm.reset();
        this.getLogTaskEmailPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteLogTaskEmailPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partnerId,
          "logTaskEmailId": product.logTaskEmailId,
         
        }
        this.myContactsService.deleteLogTaskEmailPartner(formData).subscribe(res => {
          this.getLogTaskEmailPartnerId (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  deleteLogTaskCallPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "logTaskCasesCallId": product.logTaskCasesCallId,
          "partnerId": this.partnerId
         
        }
        this.myContactsService.deleteLogTaskCallPartner(formData).subscribe(res => {
          this.getLogTaskCallPartnerId(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getLogTaskCallPartnerId() {
    this.spinner.show();
    this.myContactsService.getLogTaskCallPartnerId(this.partnerId).subscribe(res => {
      this.logTaskCallPartnerId = res.data[0].getLogTaskCallPartnerId;
      this.spinner.hide();
    })
  }
  getLogTaskEmailPartnerId() {
    this.spinner.show();
    this.myContactsService.getLogTaskEmailPartnerId(this.partnerId).subscribe(res => {
      this.LogTaskEmail = res.data[0].getLogTaskEmailPartnerId;
      this.spinner.hide();
    })
  }
  getClassificationBookmark(){
    this.spinner.show();
    this.myContactsService.getClassificationBookmark().subscribe(res=>{
      this.ClassificationBookmark = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ClassificationBookmark) {
        this.ClassificationBookmarkArray.push({
          label: value.description,
          value: value.classificationEnumId
        })
      }
    })
  }
  deleteBookmarksUploadPartner(bookmarksUploadId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.myContactsService.deleteBookmarksUploadPartner(bookmarksUploadId).subscribe(res => {
          this.getBookmarksUploadPartner(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateBookmarksUploadPartner(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);
    this.myContactsService.updateBookmarksUploadPartner(formData,this.BookUpload.bookmarksUploadId,this.fileUpload.value.classification,
    this.fileUpload.value.description,this.partnerId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.fileUpload.reset();
        this.getBookmarksUploadPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }  
  updateBookmarkUpload(){
    this.onSubmitFile();

    setTimeout(() => {
      this.updateBookmarksUploadPartner();
    }, 2000);
   
  }
  getBookmarksUploadPartner() {
    this.spinner.show();
    this.myContactsService.getBookmarksUploadPartner(this.partnerId).subscribe(res => {
      this.bookmarksUploadPartner = res.data[0].getBookmarksUploadPartner;
      this.spinner.hide();
    })
  }
  createBookmarksUploadPartner(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.myContactsService.createBookmarksUploadPartner(formData,this.fileUpload.value.classification,
      this.fileUpload.value.description,this.partnerId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.fileUpload.reset();
        this.getBookmarksUploadPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }   
  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  onSubmitFile() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.attachFile(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.fileURL = res.data.URL;
      }
    }, (err) => {
      this.toastr.error(err.message);
      this.spinner.hide();
    })

  }

  createBookmarkUpload(){
    this.onSubmitFile();

    setTimeout(() => {
      this.createBookmarksUploadPartner();
    }, 2000);
   
  }
  getBookMarksUrlIdPartner() {
    this.spinner.show();
    this.myContactsService.getBookMarksUrlIdPartner(this.partnerId).subscribe(res => {
      this.bookMarksUrlPartner = res.data[0].getBookMarksUrlIdPartner;
      this.spinner.hide();
    })
  }
  deleteBookMarksUrlPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "bookmarksUrlId": product.bookmarksUrlId,
          "partnerId": this.partnerId
         
        }
        this.myContactsService.deleteBookMarksUrlPartner(formData).subscribe(res => {
          this.getBookMarksUrlIdPartner(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  resetUpload(){
    this.showUpload = false ;
    this.fileUpload.reset();
  }
  resetBookUrl(){
    this.showBookmark = false ;
    this.addBookmark.reset();
  }
  updateBook(product) {
    this.spinner.show();
    this.showBookmark = true ;
    this.productBook = product ;
   
      setTimeout(() => {
        const formValue = this.addBookmark;
        formValue.patchValue({
          classification: product.classification,
          url: product.urlAddress,
          description: product.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  UpdateBookMarksUrlPartner(){
    this.spinner.show();
    const requestData = {
      "partnerId": this.partnerId,
      "bookmarksUrlId":this.productBook.bookmarksUrlId,
      "classification": this.addBookmark.value.classification,
     
      "description":this.addBookmark.value.description,
      "urlAddress": this.addBookmark.value.url,

    }
    this.myContactsService.UpdateBookMarksUrlPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.addBookmark.reset();
        this.getBookMarksUrlIdPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createBookMarksUrlPartner(){
    this.spinner.show();
    const requestData = {
    
      "bookmarksUrlId":this.addBookmark.value.description,
      "classification": this.addBookmark.value.classification,
      "partnerId": this.partnerId,
      "description":this.addBookmark.value.description,
      "urlAddress": this.addBookmark.value.url,
    }
    this.myContactsService.createBookMarksUrlPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("BookmarksUrl created successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.addBookmark.reset();
        this.getBookMarksUrlIdPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  updateBookUpload(product) {
    this.spinner.show();
    this.showUpload = true ;
    this.BookUpload = product ;
   
      setTimeout(() => {
        const formValue = this.fileUpload;
        formValue.patchValue({
          classification: product.classification,
          file: product.url,
          description: product.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  deleteAgreementsSupplierPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "agreementId":product.agreementId ,
          "partnerId": this.partnerId
         
        }
        this.myContactsService.deleteAgreementsSupplierPartner(formData).subscribe(res => {
          this.getAgreementsSupplierPartnerById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  resetAgree(){
     this.showAgree = false ;
        this.addAgreement.reset();

  }
  updateAgreement(product){
    this.showAgree = true ;
    this.AgreementPro = product ;
setTimeout(() => {
        const formValue = this.addAgreement;
        formValue.patchValue({
           agreementDate: this.datePipe.transform(product.agreementDate , "yyyy-MM-dd"),
      hour: product.agreementDateHour,
      minute: product.agreementDateMin,
      amPm: product.agreementDateAmPm,
      partyTo: "",
      fromDate:this.datePipe.transform(product.fromDate, "yyyy-MM-dd") ,
      hourFrom: product.fromDateHour,
      minuteFrom: product.fromDateMin,
      amPmFrom: product.fromDateAmPm,
      thruDate: this.datePipe.transform(product.thruDate , "yyyy-MM-dd"),
      hourThru: product.thruDateHour,
      minuteThru: product.thruDateMin,
      amPmThru: product.thruDateAmPm,
      description: "",
      fullText: "",
        })
      }, 2000);
  }
    updateAgreementsSupplierPartner() {
    this.spinner.show();
    const requestData = {
     
      "agreementCompositeType": "Timestamp",
      "agreementDate": this.datePipe.transform(this.addAgreement.value.agreementDate , "yyyy-MM-dd"),
      "agreementDateAmPm": this.addAgreement.value.amPm ,
      "agreementDateHour": this.addAgreement.value.hour ,
      "agreementDateMin": this.addAgreement.value.minute ,
      "agreementId": this.AgreementPro.agreementId,
      "agreementName":"" ,
      "fromDate": this.datePipe.transform(this.addAgreement.value.fromDate, "yyyy-MM-dd")  ,
      "fromDateAmPm":this.addAgreement.value.amPmFrom ,
      "fromDateCompositeType":"Timestamp" ,
      "fromDateHour":this.addAgreement.value.hourFrom ,
      "fromDateMin":this.addAgreement.value.minuteFrom ,
      "partnerId":this.partnerId ,
      "statusId":"",
      "thruDate":this.datePipe.transform(this.addAgreement.value.thruDate , "yyyy-MM-dd") ,
      "thruDateAmPm":this.addAgreement.value.amPmThru ,
      "thruDateCompositeType":"Timestamp" ,
      "thruDateHour":this.addAgreement.value.hourThru ,
      "thruDateMin": this.addAgreement.value.minuteThru ,

 
    }
    this.myContactsService.updateAgreementsSupplierPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.addAgreement.reset();
        this.getAgreementsSupplierPartnerById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createAgreementsSupplierPartner() {
    this.spinner.show();
    const requestData = {
     
      "agreementCompositeType": "Timestamp",
      "agreementDate": this.datePipe.transform(this.addAgreement.value.agreementDate , "yyyy-MM-dd"),
      "agreementDateAmPm": this.addAgreement.value.amPm ,
      "agreementDateHour": this.addAgreement.value.hour ,
      "agreementDateMin": this.addAgreement.value.minute ,
      "agreementId": "",
      "agreementName":"" ,
      "fromDate": this.datePipe.transform(this.addAgreement.value.fromDate, "yyyy-MM-dd")  ,
      "fromDateAmPm":this.addAgreement.value.amPmFrom ,
      "fromDateCompositeType":"Timestamp" ,
      "fromDateHour":this.addAgreement.value.hourFrom ,
      "fromDateMin":this.addAgreement.value.minuteFrom ,
      "partnerId":this.partnerId ,
      "statusId":"",
      "thruDate":this.datePipe.transform(this.addAgreement.value.thruDate , "yyyy-MM-dd") ,
      "thruDateAmPm":this.addAgreement.value.amPmThru ,
      "thruDateCompositeType":"Timestamp" ,
      "thruDateHour":this.addAgreement.value.hourThru ,
      "thruDateMin": this.addAgreement.value.minuteThru ,
    }
    this.myContactsService.createAgreementsSupplierPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.addAgreement.reset();
        this.getAgreementsSupplierPartnerById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.currencyList = data.data.currencyList.map(value => {
            return {
              label: value.description,
              value: value.uomId
            };
          });
        }
      });

  }
  getIndustry() {
    this.spinner.show();
    this.myContactsService.getIndustry().subscribe(res => {
      this.industryList = res.data[0].node;
      this.spinner.hide();
      for (const value of this.industryList) {
        this.industryListArray.push({
          label: value.industryDescription,
          value: value.industryValue
        })
      }
    })
  }
  getOwnerShip() {
    this.spinner.show();
    this.myContactsService.getOwnerShip().subscribe(res => {
      this.ownerShip = res.data[0].node;
      this.spinner.hide();
      for (const value of this.ownerShip) {
        this.ownerShipArray.push({
          label: value.ownerShipDescription,
          value: value.ownerShipValue
        })
      }
    })
  }
  getAgreementsSupplierPartnerById() {
    this.spinner.show();
    this.myContactsService.getAgreementsSupplierPartnerById(this.partnerId).subscribe(res => {
      this.aggreementsSupplierPartner = res.data[0].getAgreementsSupplierById;
      this.spinner.hide();
    })
  }
  updatePartner() {
    this.spinner.show();
    const requestData = {
      "address1": "",
      "address2": "",
      "annualRevenue": this.editPartner.value.annualRevenue,
      "areaCode": "",
      "attnName": "",
      "city": "",
      "country": "",
      "countryCode": "",
      "description": this.editPartner.value.description,
      "emailAddress": "",
      "extension": "",
      "importantNote":this.editPartner.value.note,
      "industry": this.editPartner.value.industry,
      "localName": this.editPartner.value.localName,
      "numberOfEmployees":this.editPartner.value.numberOfEmployees,
      "ownership": this.editPartner.value.ownership,
      "partnerId": this.partnerId,
      "partnerName":this.editPartner.value.partnerName ,
      "personToAskFor": "",
      "phoneNumber": "",
      "postalCode": "",
      "postalCodeExt": "",
      "preferredCurrency": this.editPartner.value.preferredCurrency ,
      "sicCode": this.editPartner.value.sitCode ,
      "siteName": this.editPartner.value.siteName,
      "stateProvince": "",
      "tickerSymbol":this.editPartner.value.tickerSymbol,
      "toName": "",
      "webUrl": ""
    }
    this.myContactsService.updatePartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.editPartner.reset();
        this.getPartnerById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getPartnerById() {
    this.spinner.show();
    this.myContactsService.getPartnerById(this.partnerId).subscribe(res => {
      this.partnerById = res.data[0].node[0];
    
      setTimeout(() => {
        const formValue = this.editPartner;
        formValue.patchValue({
          partnerName: this.partnerById.partnerName ,
          localName:this.partnerById.localName ,
          siteName: this.partnerById.siteName ,
          annualRevenue:this.partnerById.annualRevenue ,
          preferredCurrency: this.partnerById.preferredCurrency ,
          industry: this.partnerById.industry ,
          numberOfEmployees: this.partnerById.numberOfEmployees ,
          ownership: this.partnerById.ownership ,
          sitCode: this.partnerById.sicCode ,
          tickerSymbol: this.partnerById.tickerSymbol ,
          description:this.partnerById.description ,
          note: this.partnerById.importantNote ,
        })
      }, 2000);
      this.spinner.hide();
    })
  }
  updateNote(product){
    this.showNote = true ;
    this.noteProduct = product ;
  }
  UpdateNotePartner() {
    this.spinner.show();
    const requestData = {
      "noteId": this.noteProduct.noteId  ,
      "noteInformation": this.addNote.value.note,
      "partnerId": this.partnerId
    }
    this.myContactsService.UpdateNotePartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton10.nativeElement.click();
        this.addNote.reset();
        this.getNotePartnerBId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createNotePartner() {
    this.spinner.show();
    const requestData = {
      "noteId": "",
      "noteInformation": this.addNote.value.note,
      "partnerId": this.partnerId
    }
    this.myContactsService.createNotePartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton10.nativeElement.click();
        this.addNote.reset();
        this.getNotePartnerBId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getNotePartnerBId() {
    this.spinner.show();
    this.myContactsService.getNotePartnerBId(this.partnerId).subscribe(res => {
      this.NotePartnerList = res.data[0].getNotePartnerAll;
      this.spinner.hide();

    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
