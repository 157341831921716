import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { DetaledLeadService } from 'src/app/crm/detaled-lead/detaled-lead.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LeadNotesService } from "../../leadNotes/lead-notes/lead-notes.service";
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { detailedContactsService } from '../contacts/detailed-contacts-summary/detailed-contacts-summary.service';
import { DOCUMENT } from '@angular/common';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { LeadPersonalLeadServices } from 'src/app/leadPersonal/lead-personal/lead-personal.service';
import { LeadNotesFormService } from 'src/app/leadNotes/lead-notes-service';
import { LeadPersonalServices } from 'src/app/leadPersonalGroup/lead-personal-group/lead-personal-group.service';
import { LeadPersonalFormService } from 'src/app/leadPersonal/lead-personal-service';
import { LeadNewService } from 'src/app/leadPersonalGroup/lead-new.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  Editform: FormGroup;
  fileData: File;
  partyContentArray: any[]=[];
  partyContentData: any;
  partyContentTypeId: any;
  partyId: any;

  constructor( readonly  HeaderService: HeaderService,
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly detaledLeadService: DetaledLeadService,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _detailedContactsService: detailedContactsService,
    readonly leadNotesServices: LeadNotesService,
    public leadPersonalFormService: LeadPersonalFormService,
    readonly leadPersonalLeadServices: LeadPersonalLeadServices,
    @Inject(DOCUMENT) readonly document: Document,
    public leadNotesFormService:LeadNotesFormService,
    readonly leadPersonalServices:LeadPersonalServices,
    public leadNewService: LeadNewService,
    readonly _FormBuilder: FormBuilder,) {
      this.Editform = new FormGroup({
        file: new FormControl('', [
          Validators.required
        ]),
        partyContentTypeId: new FormControl('')
      });
     }

  ngOnInit(): void {
    
  }

  onpartyContent(event) {
    this.partyContentTypeId = event;
  }

  getPartyContent() {
    this.spinner.show();
    this.detaledLeadService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        })
      }
    })
    
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  onSubmit() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.fileData);
    this.partyContentTypeId = this.Editform.get('partyContentTypeId').value;
    this.detaledLeadService.attachPartyContent(formData, this.partyContentTypeId, this.partyId)
      .subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          window.location.reload();

        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
      });
     
  }


}
