import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

import {DropdownModule} from 'primeng/dropdown';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-add-permission-list',
  templateUrl: './add-permission-list.component.html',
  styleUrls: ['./add-permission-list.component.css']
})
export class AddPermissionListComponent implements OnInit {
  total=0;
  rowNumber=0;
  activeCategory = 2;
  pageSize= 100;
  rows = 50;
  pageNo=1;
  search="";
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  createGroup: FormGroup;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  show: boolean;
  groupId: any;
  DateFrom: string;
  throughDate: string;
  getAllPermisionData: any;
  getAllPermisionDataArray: any[]=[];
  @Output() listModalClosed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;
  
  constructor(  readonly accountsService: AccountsService,
   
    public addSkillService:AddSkillService, private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly toastr: ToastrService, readonly spinner: NgxSpinnerService,
    readonly activatedRoute:ActivatedRoute,
    readonly router: Router  ) {
      this.createGroup = this._FormBuilder.group({
        fromDate: [''],
        permissionId: ['',[Validators.required]],
        thruDate: [''],
      });}
      ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> [
      this.groupId = params["groupId"],
      
    ])
    this.getAllPermision()
  }
  getAllPermision(){
    this.spinner.show();
    this.accountsService.getAllPermision().subscribe((res:any)=> {
      this.getAllPermisionData = res.data;
      this.spinner.hide();
      for(const value of this.getAllPermisionData){
        this.getAllPermisionDataArray.push({
          label:value.description,
          value:value.permissionId
        })
      }

    })
   
   }
  
  submit(): void {  
    this.spinner.show();
    const fromDate=this.createGroup.get('fromDate').value;
    this.DateFrom=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const thruDate=this.createGroup.get('thruDate').value;
    this.throughDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={ 
          "fromDate":  this.DateFrom,
          "permissionId": this.createGroup.value.permissionId,
          "thruDate": this.throughDate   
          
      }
      this.accountsService.postPermisionDate(requestData,this.groupId).subscribe((res: any) => {
     
        if (res.success) {
          this.modalClose();
          this.toastr.success("Successfully Created");
          this.spinner.hide();
          this._location.back();
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
     
    }
  cancel(){
    this._location.back();
  }
  modalClose(): void {
    this.closebutton.nativeElement.click();
    this.listModalClosed.emit();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

