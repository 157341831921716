
<div class="container" style="margin-top: 7%;">
  <div class="row">
    <div class="col-12">
     
      <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Lead > Merge Leads</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>
      <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
          <div class="suppliers-wrapper">
          </div>
        </div>
        <div class="create-new-leade mt-2">
          <div class="container-fluid">
            <div class="row">
              <form class="w-100">
                <div class="w3-card-4 classCard" >
                  <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                       Merge Lead</a></li>
                      
                   
          
                    </ul>
                  </div>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                  <div class="col-lg-12" >
                    <div class="row">
                        <div class="col-lg-2 form-group rightForm">
                          <label for="exampleInputEmail1">To Party</label>
                        </div>
                        <div class="col-lg-2 dropdowns">
                          <p-dropdown filter="true" placeholder="To Party" [ngModelOptions]="{standalone: true}" [(ngModel)]="partyIdTo"
                          (ngModelChange)="partyIdFromData(partyIdTo)" [options]="leadListarray" optionlabel="label">

                        </p-dropdown>
                        </div>
                        <div class="col-lg-2 form-group rightFormA">
                          <label for="exampleInputEmail1">From Party</label>
                        </div>
                        <div class="col-lg-2 dropdowns">
                          <p-dropdown [(ngModel)]="partyIdFrom" placeholder="From Party" (ngModelChange)="partyIdToData(partyIdFrom)"
                            [options]="leadListarray" [ngModelOptions]="{standalone: true}" filter="true"
                            optionlabel="label">
  
                          </p-dropdown>
                        </div>
                       
                    </div>
                </div>
               
                  
                
                  <div class="col-lg-4 col-12" style="    margin-top: -3%;margin-left: 17%;">
                    <div class="form-group col-lg-4 small-area-pl">
                      
                      <label for="exampleInputEmail1"></label>
                      <div style="margin-top: 18%;
                      margin-left: 438%;
                  ">
                     
                          <button type="button" (click)="mergeContact()" class="btn btn-outline-secondary active">Merge
                          </button>
                       
                      </div>

                    </div>
                  </div>

                </div>
              </div>

              </form>
            </div>
          </div>
          <br> &nbsp;
          <div class="w3-card-4 classCard">
            <header class="w3-container w3-blue">
              <div class="header-tabs">
                <h4 class=" common-styling h4Margin">
                  Merge Lead List
                </h4>
             
              </div>
            </header>
          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            <div class="panel panel-default">
              <div class="container-fluid">
               
              </div>
              <div >
                <div class="panel-body" *ngIf="mergeLead && mergeLead.length > 0">
                  <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="color-black container">
                      <div class="row color" >
                        <table  class="table">
                          <thead>
                            <tr>
                              <th></th>
                              <th>First Contact
                              </th>
                              <th>Second Contact
                              </th>
                              <th>Select
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>First name</td>
                              <td>{{mergeLead[0].firstName}}</td>
                              <td>{{mergeLead[1].firstName}}</td>
                              <td rowspan="2"><label class="address">
                                  <input type="checkbox" [(ngModel)]="useContactNum2" [ngModelOptions]="{standalone: true}"
                                    class="form-check-input" (change)="checkContact($event)" value="">
                                </label></td>
                            </tr>
                            <tr>
                              <td>Last Name</td>
                              <td>{{mergeLead[0].lastName}}</td>
                              <td>{{mergeLead[1].lastName}}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>General Correspondence Address </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Address Line 1 </td>
                              <td>{{mergeLead[0].address1}}</td>
                              <td>{{mergeLead[1].address1}}</td>
                              <td rowspan="5"> <label class="form-check-label">
                                  <input type="checkbox" [(ngModel)]="useAddress2" (change)="checkAddress($event)"
                                    [ngModelOptions]="{standalone: true}" class="form-check-input" value="">
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>Address Line 2 </td>
                              <td>{{mergeLead[0].address2}}</td>
                              <td>{{mergeLead[1].address2}}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>City </td>
                              <td>{{mergeLead[0].city}}</td>
                              <td>{{mergeLead[1].city}}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>State/Province </td>
                              <td>{{mergeLead[0].state}}</td>
                              <td>{{mergeLead[1].state}}</td>
                              <td></td>
        
                            </tr>
                            <tr>
                              <td>Country </td>
                              <td>{{mergeLead[0].country}}</td>
                              <td>{{mergeLead[1].country}}</td>
                              <td></td>
                            </tr>
                         
                            <tr>
                              <td>E-Mail Address </td>
                              <td>{{mergeLead[0].primaryEmail}}</td>
                              <td>{{mergeLead[1].primaryEmail}}</td>
                              <td rowspan="5"> <label class="forms">
                                  <input type="checkbox" id="email" [(ngModel)]="useEmail2"
                                    [ngModelOptions]="{standalone: true}" class="form-check-input" (change)="checkEmail($event)"
                                    value="">
                                </label></td>
                            </tr>
                            <tr>
                              <td> </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                         
                          <tr>
                            <td> </td>
                            <td></td>
                            <td></td>
                          </tr>
                          
                        </table>
                        <button type="button" (click)="onSubmit()"   style="margin-left: 85%;height: 12%!important"
                          class="btn btn-outline-secondary">Submit</button>
                          <br><br>  <br><br>
                      </div>
                     
                    </div>
                  </div>
                </div>
                <br><br><br><br>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>

 
 
</div>
<ngx-spinner></ngx-spinner> 