import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EmployeeApplicationService {

  constructor(readonly http:HttpClient) { }
  
  getStatusList():Observable<any>{
    const getStatusList = URLS.getStatusIdsList;
    return this.http.get(getStatusList);
  }
 
  getAppsourceList():Observable<any>{
    const appSourceList = URLS.AppSourceTypeList;
    return this.http.get(appSourceList);
  }
  createEmploymentApplication(formData):Observable<any>{
    const createEmpApplication = URLS.addEmploymentApp;
    return this.http.post(createEmpApplication,formData);
  }
  updateEmployee(formData,id):Observable<any>{
    const updateEmpApplication = URLS.updateEmpApplication.replace(":applicationId",id);
    return this.http.put(updateEmpApplication,formData);
  }
  deleteEmployeeApp(id):Observable<any>{
    const deleteEmpApplication = URLS.delEmploymentApp.replace(":applicationId",id);
    return this.http.delete(deleteEmpApplication);
  }

  
}
