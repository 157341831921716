<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of testing by [{{this.party}}]</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">
                        <button type="button" (click)="createUser()" style="cursor: pointer;" class="btn btn-outline-secondary" >Create</button>&nbsp;
                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''" ><a>
                                User Login to Security Group
                            </a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                  
                                                    <div class="panel-body">
                                                      
                                                        <div>
                                                            <header class="w3-container">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Search Results
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div
                                                                class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="AddUserLoginToSecurityGroup" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                          [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="code">
                                                                                            <div class="checkbox-align"
                                                                                                style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox" binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined"
                                                                                                            value="undefined"></div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                              
                                                                                                
Security Group ID<p-sortIcon
                                                                                                    field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Description
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                From Date<p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="category">
                                                                                            <div style="color: white;">
                                                                                                Through Date
                                                                                                <p-sortIcon
                                                                                                    field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'200px'}"
                                                                                            width="11%"
                                                                                            pSortableColumn="quantity">
                                                                                            <div style="color: white;">
                                                                                            Action
                                                                                                <p-sortIcon
                                                                                                    field="quantity">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                      
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body"
                                                                                    let-product>
                                                                                    <tr>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox" binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined"
                                                                                                            value="undefined"></div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                                <span
                                                                                                    class="account-button">{{product.securityGroupId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.description}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                                            <svg class="hover"  (click)="updateUserLoginToSecurityGroup(product)"
                                                                                              xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                                              viewBox="0 0 21.223 21.222">
                                                                                              <g transform="translate(0 -0.004)">
                                                                                                <g transform="translate(0 1.52)">
                                                                                                  <path
                                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                    transform="translate(0 -34.137)" />
                                                                                                </g>
                                                                                                <g transform="translate(4.548 0.004)">
                                                                                                  <g transform="translate(0 0)">
                                                                                                    <path
                                                                                                      d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                      transform="translate(-102.409 -0.004)" />
                                                                                                  </g>
                                                                                                </g>
                                                                                              </g>
                                                                                            </svg>
                                                                                         <span style="cursor: pointer;"  (click)="expireAddUserLoginToSecurityGroup(product)">Expire</span>
                                                  
                                                                                         
                                                                                          </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage"
                                                                                    let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">No
                                                                                                    Record Found
                                                                                                </h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>