<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
       <span class="color-black pl-1 titlePanels">The Profile of Your Company Name Here [Company] </span>
        <button type="button" class="btn btn-outline-secondary">Show Old</button>
      </div>
      <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
          <div class="suppliers-wrapper">
            <button type="button" class="btn btn-outline-secondary">Create</button>
            <button type="button" class="btn btn-outline-secondary">Communication</button>
          </div>
        </div>
        <div class="create-new-leade mt-2">
          <div class="container-fluid">
            <div class="row">
              <form class="w-100">
                <div class="port-header-contact create-lead font-medium mb-0 text-white" data-toggle="collapse"
                  href="#collapsetwo">
                  <div class="w-100">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                          <h4 class="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                              aria-expanded="true" aria-controls="collapseOne">
                              Party Group Information
                              <span class="detail-created">Update</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel"
                          aria-labelledby="headingOne">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="color-black container">
                                <div class="row">
                                  <p class="col-lg-2 col-12">Organization Logo</p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">DemoCustomer1</span>
                                  <p class="col-lg-2 col-12">Party ID</p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">ManagerP1 Customer 1</span>
                                  <p class="col-lg-2 col-12">Group Name</p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">DemoCustomer1</span>
                                  <p class="col-lg-2 col-12">Group Name Local</p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">DemoCustomer1</span>
                                  <p class="col-lg-2 col-12">Office Site Name</p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">Enabled</span>
                                  <p class="col-lg-2 col-12">OAnnual revenue</p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">DemoCustomer1</span>
                                  <p class="col-lg-2 col-12">Number of employees </p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">ManagerP1 Customer 1</span>
                                  <p class="col-lg-2 col-12">Ticker symbol</p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">DemoCustomer1</span>
                                  <p class="col-lg-2 col-12">Description</p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">DemoCustomer1</span>
                                  <p class="col-lg-2 col-12">Currency</p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">Enabled</span>
                                  <p class="col-lg-2 col-12">External ID</p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">DemoCustomer1</span>
                                  <p class="col-lg-2 col-12">Status ID</p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">Enabled</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                          <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              List Related Contacts
                              <span class="detail-created">Create</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingTwo">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <div class="card own-account-table">
                                  <p-table [value]="products3" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                    (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr>
                                        <th pSortableColumn="code">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                              binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                    name="undefined" value="undefined"></div>
                                                <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-clickable"></span></div>
                                              </div>
                                              <!---->
                                            </p-checkbox>
                                            Contact Id <p-sortIcon field="code"></p-sortIcon>
                                          </div>
                                        </th>
                                        <th pSortableColumn="name">First Name <p-sortIcon field="name"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="category">Last Name <p-sortIcon field="category">
                                          </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="quantity">State/Province <p-sortIcon field="quantity">
                                          </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="quantity">City <p-sortIcon field="quantity"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="price">Phone Number <p-sortIcon field="price"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="price">Email Address <p-sortIcon field="price">
                                          </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="price">Action
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td (click)="contactsDetail()">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                              binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                    name="undefined" value="undefined"></div>
                                                <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-clickable"></span></div>
                                              </div>
                                              <!---->
                                            </p-checkbox>
                                            {{product.code}}
                                          </div>
                                        </td>
                                        <td>{{product.name}}</td>
                                        <td>{{product.category}}</td>
                                        <td>{{product.quantity}}</td>
                                        <td>{{product.price | currency: 'USD'}}</td>
                                        <td>{{product.category}}</td>
                                        <td>
                                          <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                              viewBox="0 0 21.223 21.222">
                                              <g transform="translate(0 -0.004)">
                                                <g transform="translate(0 1.52)">
                                                  <path
                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                    transform="translate(0 -34.137)" />
                                                </g>
                                                <g transform="translate(4.548 0.004)">
                                                  <g transform="translate(0 0)">
                                                    <path
                                                      d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                      transform="translate(-102.409 -0.004)" />
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16"
                                              viewBox="0 0 26 26">
                                              <defs>
                                                <style>
                                                  .a {
                                                    fill: #f44336;
                                                  }

                                                  .b {
                                                    fill: #fafafa;
                                                    margin-left: 20px;
                                                  }
                                                </style>
                                              </defs>
                                              <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                              <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                            </svg>
                                          </div>
                                        </td>

                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <!-- <p class="paginate_data">
                                                                    View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                  <p class="paginate_data">
                                    View per page </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                          <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              Contact Information
                              <span class="detail-created">Create</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingThree">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <div class="card own-account-table">
                                  <p-table [value]="products3" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                    (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr>
                                        <th pSortableColumn="code">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                              binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                    name="undefined" value="undefined"></div>
                                                <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-clickable"></span></div>
                                              </div>
                                              <!---->
                                            </p-checkbox>
                                            Contact Id <p-sortIcon field="code"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th pSortableColumn="name">First Name <p-sortIcon field="name"></p-sortIcon>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th pSortableColumn="category">Last Name <p-sortIcon field="category">
                                          </p-sortIcon>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th pSortableColumn="quantity">State/Province <p-sortIcon field="quantity">
                                          </p-sortIcon>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th pSortableColumn="quantity">City <p-sortIcon field="quantity"></p-sortIcon>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th pSortableColumn="price">Phone Number <p-sortIcon field="price"></p-sortIcon>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th pSortableColumn="price">Email Address <p-sortIcon field="price">
                                          </p-sortIcon>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th pSortableColumn="price">Action
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td (click)="contactsDetail()">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                              binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                    name="undefined" value="undefined"></div>
                                                <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-clickable"></span></div>
                                              </div>
                                              <!---->
                                            </p-checkbox>
                                            {{product.code}}
                                          </div>
                                        </td>
                                        <td>{{product.name}}</td>
                                        <td>{{product.category}}</td>
                                        <td>{{product.quantity}}</td>
                                        <td>{{product.price | currency: 'USD'}}</td>
                                        <td>{{product.category}}</td>
                                        <td>
                                          <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                              viewBox="0 0 21.223 21.222">
                                              <g transform="translate(0 -0.004)">
                                                <g transform="translate(0 1.52)">
                                                  <path
                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                    transform="translate(0 -34.137)" />
                                                </g>
                                                <g transform="translate(4.548 0.004)">
                                                  <g transform="translate(0 0)">
                                                    <path
                                                      d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                      transform="translate(-102.409 -0.004)" />
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16"
                                              viewBox="0 0 26 26">
                                              <defs>
                                                <style>
                                                  .a {
                                                    fill: #f44336;
                                                  }

                                                  .b {
                                                    fill: #fafafa;
                                                    margin-left: 20px;
                                                  }
                                                </style>
                                              </defs>
                                              <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                              <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                            </svg>
                                          </div>
                                        </td>

                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <!-- <p class="paginate_data">
                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                  <p class="paginate_data">
                                    View per page </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFour1">
                          <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseFour1" aria-expanded="false" aria-controls="collapseFour1">
                              Loyalty Points
                              <span class="detail-created">Create</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFour1" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingThree">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="color-black container">
                                You have 0 points from 0 order(s) in the last 12 Months.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFour">
                          <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                              Identification Numbers
                              <span class="detail-created">New</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFour" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingThree">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="color-black container">
                                No Records Found
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFive">
                          <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                              Party Attribute(s)
                              <span class="detail-created">Create</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingFive">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="color-black container">
                                No party attributes found.
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFiveone">
                          <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseFiveone" aria-expanded="false" aria-controls="collapseFiveone">
                              User Name(s)
                              <span class="detail-created">Create</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFiveone" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingFiveone">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="color-black container">
                                No UserLogin(s) found for this party.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFivetwo">
                          <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseFivetwo" aria-expanded="false" aria-controls="collapseFivetwo">
                              AVS Override (CC Fraud Screening)
                              <span class="detail-created">Create</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFivetwo" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingFivetwo">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="color-black container">
                                <div class="row">
                                  <p class="col-lg-2 col-12">AVS String</p>
                                  <span class="color-grey pl-20 col-lg-10 col-12">Global </span>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFivethree">
                          <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseFivethree" aria-expanded="false" aria-controls="collapseFivethree">
                              Visits
                              <span class="detail-created">Create</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFivethree" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingFivethree">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="color-black container">
                                No Visit(s) found for this party.
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingSix">
                          <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                              Payment Method
                              <span class="detail-created">List All</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSix" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingSix">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="color-black container">
                                <div class="row">
                                  <div class="suppliers-wrapper">
                                    <button type="button" class="btn btn-outline-secondary">Create credit card</button>
                                    <button type="button" class="btn btn-outline-secondary">Create Gift Card</button>
                                    <button type="button" class="btn btn-outline-secondary">Create EFT Account</button>
                                    <button type="button" class="btn btn-outline-secondary">Add Check Account</button>
                                    <button type="button" class="btn btn-outline-secondary">Create Billing
                                      Account</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headinSeven">
                          <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                              Party Content
                              <span class="detail-created">Create</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingSeven">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="col-lg-4 col-12">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">Attach Content</label>
                                  <div class="suppliers-wrapper">
                                    <button type="button" class="btn btn-outline-secondary">Choose File</button>
                                    <input type="email" class="form-control mt-2" id="exampleInputEmail1"
                                      aria-describedby="emailHelp" >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headinEight">
                          <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                              Notes
                              <span class="detail-created">Create</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseEight" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingEight">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="color-black container">
                                No notes for this party.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headinEightone">
                          <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseEightone" aria-expanded="false" aria-controls="collapseEightone">
                              Stores
                              <span class="detail-created">Create</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseEightone" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingEightone">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="color-black container">
                                No product store(s) found for this party.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>