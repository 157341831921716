import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

import { Location } from '@angular/common';

import Swal from 'sweetalert2'
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';
@Component({
  selector: 'app-detail-resume',
  templateUrl: './detail-resume.component.html',
  styleUrls: ['./detail-resume.component.css']
})
export class DetailResumeComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory=0;
  products3: any;
  paymentPartyGroup: any;
  createReview:FormGroup;
  createPrefItem:FormGroup;
  downloading: boolean;
  total = 0;
  activeCategoryMain=2;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  show: boolean;
  createResume:FormGroup;
  resumeId: any;
  contentId: any;
  partyId: any;
  resumeDate: any;
  resumeText: any;
  PartyID: any;
  ContentID: any;
  ContentIDArray: any[]=[];
  partyIdArray: any[]=[];
  actualThroughDate: string;
  constructor(readonly spinner: NgxSpinnerService,
      private _location: Location,
      readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
   
    readonly addSkillService: AddSkillService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
   
    readonly router: Router){ 
      this.createResume = this._FormBuilder.group({
        ResumeID: [''],
        ContentID: [''],
        PartyID: [''],
        ResumeDate: [''],
        ResumeText: [''],
       
      
      });
    }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.contentId = params.contentId;
      this.resumeId = params["resumeId"];
      this.resumeDate = params.resumeDate;
      this.resumeText = params.resumeText;
     
      
    })
  
  this.show=true;

  this.getContentIdList();
  this.getPartyId();
   
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  edit(){
    this.actualThroughDate = this.datePipe.transform(this.resumeDate, "yyyy-MM-dd");
  

    setTimeout(() => {
      const formValue = this.createResume;
    formValue.patchValue({
      ResumeID: this.resumeId  ,
      ContentID:this.contentId,
      PartyID: this.partyId,
      ResumeDate: this.actualThroughDate,
      ResumeText: this.resumeText,
      

    })
    }, 2000);

  }

  updateResume(){
    this.spinner.show();
    let fromActualdate = this.createResume.get('ResumeDate').value;
    this.resumeDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
  
  const formdata={
    "contentId": this.createResume.value.ContentID,
    "partyId": this.createResume.value.PartyID ,
    "resumeDate": this.resumeDate,
    "resumeId":this.createResume.value.ResumeID ,
    "resumeText":this.createResume.value.ResumeText

   
  }
  
    this.accountsService.updatePartyResume(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();


      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }

  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.PartyID = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.PartyID) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }


  getResumeID() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.PartyID = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.PartyID) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  getContentIdList() {
    this.spinner.show();
    this.accountsService.getContentIdList().subscribe(res => {
      this.ContentID = res.data;
      this.spinner.hide();
      for (const value of this.ContentID) {
        this.ContentIDArray.push({
          label: value.data.contentId,
          value: value.data.contentId
        })
      }
    })
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }

}

