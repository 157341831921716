<div class="container-fluid main-container-wrapper">
    <br>
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Payment Groups</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>


    <div class=" bg-white color-grey">
        
        <div class="w3-card-4 classCardA classCardType">
            <div class="container-fluid" *ngIf="this.agreementType=='payable'">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                    <li [ngClass]="activeCategory==4?'active':''" (click)="main()"><a>
                        Payables Summary </a></li>
                    <li [ngClass]="activeCategory==1?'active':''" (click)="enterBills()"><a>
                    Enter Bills </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" (click)="payableMakePayment()"><a>
                    Make Payments</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="payableMakeGroupPayment()"><a>
                        Make Group Payments</a></li>
                </ul>
            </div>
            <div class="container-fluid" *ngIf="this.agreementType=='receiveable'">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                    <li [ngClass]="activeCategory==1?'active':''" (click)="salesInvoice()"><a>
                    Sales Invoice </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" (click)="receivePayment()"><a>
                    Receive Payments</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="receiveGroupPayments()"><a>
                        Receive Group Payments</a></li>
                </ul>
            </div>

            <div class="create-new-leade mt-2">
                <div class="container-fluid">


                    <div class="panel-group">
                        <div *ngIf="activeCategory==3">


                            <div class="panel-collapse">

                                <div class="create-new-leade mt-2">
                                    <div class="">
                                        <form class="w-100" [formGroup]="searchPaymentGroupForm">
                                            <div class="container-fluid">
                                                <div class="w3-card-4  w-100">
                                                    <div class="accordion classCard" id="myAccordion">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="headingOne">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                    <button data-toggle="modal" data-target="#exampleModalCenter" type="button" class="btn btn-outline-secondary marginHeader">Create New Payment Group
                                                                    </button>
                                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 73%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;">
                                                                    </button>									
                    
                                                                </div>
                            
                                                            </h2>
                                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                <div class="card-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Payment Group ID</label>
                                                                                </div>
                
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="paymentGroupIdSearchType">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Payment Group Id" formControlName="paymentGroupId">
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                    <label for="exampleInputEmail1">Payment Group Name</label>
                                                                                </div>
                
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="paymentGroupNameSearchType">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="paymentGroupName" placeholder="Payment Group Name">
                                                                                </div>
                
                
                                                                            </div>
                                                                        </div>
                
                
                                                                    </div>

                                                                    <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -26%;">
                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getPaymentGroups()">Find</button>&nbsp;
                                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                    </div>
                                                                    
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <!-- <div class="w3-card-4 classCardA">

                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <button data-toggle="modal" data-target="#exampleModalCenter" type="button" class="btn btn-outline-secondary">Create New Payment Group
                                                    </button>
                                                </div><br>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Payment Group ID</label>
                                                                </div>

                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="paymentGroupIdSearchType">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Payment Group Id" formControlName="paymentGroupId">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Payment Group Name</label>
                                                                </div>

                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="paymentGroupNameSearchType">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="paymentGroupName" placeholder="Payment Group Name">
                                                                </div>


                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -26%;">
                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getPaymentGroups()">Find</button>&nbsp;
                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                    </div>
                                                </div> -->
                                                <br>
                                                
                                                <div class="w3-card-4 classCard">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Payment Group List
                                                            </h4>

                                                        </div>
                                                    </header>
                                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">

                                                                <div class="card own-account-table">
                                                                    <p-table [value]="paymentGroups" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Payment Group Id
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Payment Group Type
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Payment Group Name
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;"> Created Date</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;"> Action</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span class="account-button" routerLink="/financial/payable/payment-group/summary-payment-group" [queryParams]="{paymentGroupId:product.paymentGroupId}">{{product.paymentGroupId}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{{product.paymentGroupType}}</td>
                                                                                <td>{{product.paymentGroupName}}</td>
                                                                                <td>{{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                <td>

                                                                                    <fa-icon [icon]="faTrash" (click)="deleteProdCatalogToPartyNew(product.paymentGroupId)" class="text-danger ml-2 cursor-pointer">
                                                                                    </fa-icon>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                                <h4 class="ml-2">No Record Found</h4>
                                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Payment Group</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==3?'active':''"><a>
                            {{editMode ?'Update':'Create'}} Payment Group</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="paymentGroupForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="paymentGroupTypes" placeholder="Select Type" optionlabel="label" formControlName="paymentGroupTypeId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Payment Group Name <span
                                                        class="text-danger">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" formControlName="paymentGroupName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Payment Group Name">

                                                        <small class="text-danger" *ngIf="!!paymentGroupForm.controls.paymentGroupName.invalid && !!paymentGroupForm.controls.paymentGroupName.touched">Payment
                                                Group Name is required</small>
                                                    </div>

                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG" *ngIf="editMode">
                                                        <label for="payment">Payment Group ID </label>
                                                    </div>

                                                    <div class="col-lg-3" *ngIf="editMode">
                                                        <input type="text" class="form-control" [value]="paymentGroupId" readOnly>
                                                    </div>
                                                

                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -7%;">
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="submit()">{{editMode ?'Update':'Create'}}</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>