<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">
                </span>
            </div>
            <div class="w3-card-4 classCard">



                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="w3-card-4 classCard">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 102.5% !important;
                        flex-wrap: wrap !important;
                        display: flex !important;">
                                                <li><a>
                                                    Edit Data Source
 

                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                        <div class="all-users-infomation font-13 font-medium  own-user-select" style="margin-left: -9%;">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Data Source ID</label>
                                                    </div>
                                                    <div class="col-lg-2" style="display: none;">

                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" name="DataSourceID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Data Source ID">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Data Source Type ID </label>
                                                    </div>
                                                    <div class="col-lg-2" style="display: none;">

                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" name="DataSourceTypeID" optionlabel="label" [options]="DataSourceTypeIdsArray" placeholder="Enter Data Source Type ID">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2" style="display: none;">

                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" name="Description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>






                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -67%;">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>&nbsp;

                                        </div>
                                    </div>


                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>