<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Orders</span>
                <span>
                    <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass"
                        (click)="backWindow()">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div style="width: 49%; float: left;">
                                            <div class="w3-card-4 classCard classCardView mt-2 w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import  create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="color: #242222b5;
                                                    font-size: 13px;
                                                    font-weight: 600;">
                                                    <h4 class="common-styling"> Order Received On {{this.myDate | date:"yyyy-MM-dd hh:mm:ss"}}</h4>
                                                   

                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium  own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="" [paginator]="true" [rows]="rows"
                                                                    scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>

                                                                                    Price Rule Name [ID]
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Sale Rule?
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.ruleName}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.isSale}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.fromDate|date:'yyyy-MM- dd    hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.thruDate|date:'yyyy-MM-dd    hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">

                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="w3-card-4 classCard w-100" style="height: auto;margin-top:1.3%;">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import  create-lead font-medium mb-0 suppliers-wrapper">
                                                  
                                                     <h4 class="common-styling"> Orders Totals </h4>
                                                   
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select pb-0">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group mb-0">
                                                            <div class="card own-account-table">
                                                                <div class="table-responsive ">
                                                                    <table class="table table-striped mb-0"
                                                                        style="overflow: hidden;white-space: nowrap;">

                                                                        <tbody>
                                                                            <tr
                                                                                style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Orders Totals</td>
                                                                                <td>Today</td>
                                                                                <td>WTD</td>
                                                                                <td>MTD</td>
                                                                                <td>YTD</td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">


                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Gross Dollar Amounts (includes
                                                                                    adjustments and pending orders)
                                                                                </td>

                                                                                <td>
                                                                                    {{OrderStatsList.dayItemTotal}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekItemTotal}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthItemTotal}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearItemTotal}}
                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">

                                                                                <td colspan="2" style="width: 61%;">Paid
                                                                                    Dollar Amounts (includes
                                                                                    adjustments)</td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayItemTotalPaid}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekItemTotalPaid}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthItemTotalPaid}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearItemTotalPaid}}

                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">

                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Pending Payment Dollar Amounts
                                                                                    (includes adjustments)</td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayItemTotalPending}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekItemTotalPending}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthItemTotalPending}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearItemTotalPending}}

                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="w3-card-4 classCard  w-100"
                                                style="height: auto;margin-top:1.3%;">
                                                <div style="color: #242222b5;
                                                font-size: 13px;
                                                font-weight: 600;"
                                                    class="d-flex bd-highlight port-header-contact-import create-lead font-medium mb-0 suppliers-wrapper">
                                                    <h4 class="common-styling"> Orders Item Counts</h4> 
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select  pb-0">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group mb-0">
                                                            <div class="card own-account-table">
                                                                <div class="table-responsive mb-0">
                                                                    <table class="table table-striped"
                                                                        style="overflow: hidden;white-space: nowrap;">

                                                                        <tbody>
                                                                            <tr
                                                                                style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Orders Item Counts</td>
                                                                                <td>Today</td>
                                                                                <td>WTD</td>
                                                                                <td>MTD</td>
                                                                                <td>YTD</td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">


                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Gross Items Sold (includes
                                                                                    promotions and pending orders)
                                                                                </td>

                                                                                <td>
                                                                                    {{OrderStatsList.dayItemCount}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekItemCount}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthItemCount}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearItemCount}}
                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">

                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Paid Items Sold (includes
                                                                                    promotions)
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayItemCountPaid}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekItemCountPaid}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthItemCountPaid}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearItemCountPaid}}

                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">

                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Pending Payment Items Sold (includes
                                                                                    promotions)
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayItemCountPending}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekItemCountPending}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthItemCountPending}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearItemCountPending}}

                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width: 49.8%; float: right;">
                                            <div class="w3-card-4 classCard classCardView mt-2 w-100">
                                                <div style="color: #242222b5;
                                                font-size: 13px;
                                                font-weight: 600;"
                                                    class="d-flex bd-highlight port-header-contact-import  create-lead font-medium mb-0 suppliers-wrapper">
                                                    <h4 class="common-styling">  Best Selling Products of  {{this.myDate | date:"yyyy-MM-dd hh:mm:ss"}}</h4>   

                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium  own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="getOrderPageDetailsData"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>

                                                                                    Name
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Amount
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Quantity Ordered
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.productName}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                ${{product.price}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.quantity}}
                                                                            </td>


                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w3-card-4 classCard w-100"
                                                style="height: auto;margin-top:1.3%;">
                                                <div style="    color: rgba(36,34,34,.7098039215686275);
                                                font-size: 13px;
                                                font-weight: 600;"
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <h4 class="common-styling">  Orders Pending</h4>   

                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select pb-0 pt-0" >
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group  mb-0">
                                                            <div class="card own-account-table">
                                                                <div class="table-responsive mb-0">
                                                                    <table class="table table-striped mb-1"
                                                                        style="overflow: hidden;white-space: nowrap;">

                                                                        <tbody>
                                                                            <tr
                                                                                style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Orders Pending</td>
                                                                                <td>Today</td>
                                                                                <td>WTD</td>
                                                                                <td>MTD</td>
                                                                                <td>YTD</td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">


                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Waiting Payment
                                                                                </td>

                                                                                <td>
                                                                                    {{OrderStatsList.waitingPayment}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingWeekPayment}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingMonthPayment}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingYearPayment}}
                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">

                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Waiting Approval
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingApproval}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingWeekApproval}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingMonthApproval}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingYearApproval}}

                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">

                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Waiting Completion
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingComplete}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingWeekComplete}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingMonthComplete}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingYearComplete}}

                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="w3-card-4 classCard  w-100"
                                                style="height: 260px;margin-top:1.3%;overflow:auto">
                                                <div style="    color: rgba(36,34,34,.7098039215686275);
                                                font-size: 13px;
                                                font-weight: 600;"
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <h4 class="common-styling">Status Changes</h4>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select pb-0 pt-0">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group  mb-0">
                                                            <div class="card own-account-table">
                                                                <div class="table-responsive mb-0">
                                                                    <table class="table table-striped"
                                                                        style="overflow: hidden;white-space: nowrap;">

                                                                        <tbody>
                                                                            <tr
                                                                                style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Status Changes</td>
                                                                                <td>Today</td>
                                                                                <td>WTD</td>
                                                                                <td>MTD</td>
                                                                                <td>YTD</td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">


                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Created
                                                                                </td>

                                                                                <td>
                                                                                    {{OrderStatsList.dayOrder}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekOrder}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthOrder}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearOrder}}
                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">

                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Approved
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayApprove}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekApprove}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthApprove}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearApprove}}

                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">

                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Completed
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayComplete}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekComplete}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthCancelled}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearCancelled}}

                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">

                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Cancelled
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayCancelled}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekCancelled}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthCancelled}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearCancelled}}

                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="this.OrderStatsList">

                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Rejected
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayRejected}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekRejected}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthRejected}}

                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearRejected}}

                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Order Received On {{this.myDate}}
                                   
                                </div>
                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">

                                            <div class="card own-account-table">
                                                <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                            </div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                       
                                                                    </p-checkbox>

                                                                    Price Rule Name [ID]
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>

                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    Sale Rule?
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    From Date
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    Through Date
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    Action
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>

                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'150px'}">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                            </div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                     
                                                                    </p-checkbox>
                                                                    <span  class="account-button"  
                                                                 >
                                                                  {{product.ruleName}}
                                                                    </span>

                                                                </div>
                                                            </td>

                                                            <td [ngStyle]="{'width':'180px'}">
                                                                {{product.isSale}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'180px'}">
                                                                {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'180px'}">
                                                                {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'180px'}">

                                                            </td>

                                                        </tr>
                                                    </ng-template>

                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                        <tr>
                                                            <td>
                                                                <span class="text-center">
                                                            <h4
                                                                class="ml-2">
                                                                No Record
                                                                Found</h4>
                                                        </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>

                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Best Selling Products of {{this.myDate}}
                                   
                                </div>
                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">

                                            <div class="card own-account-table">
                                                <p-table [value]="getOrderPageDetailsData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                            </div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                  
                                                                    </p-checkbox>

                                                                    Name
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>

                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    Amount
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    Quantity Ordered
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>
                                                           

                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'150px'}">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                            </div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                  
                                                                    </p-checkbox>
                                                                    <span  class="account-button"  
                                                                 >
                                                                  {{product.productName}}
                                                                    </span>

                                                                </div>
                                                            </td>

                                                            <td [ngStyle]="{'width':'180px'}">
                                                                ${{product.price}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'180px'}">
                                                                {{product.quantity}}
                                                            </td>
                                 

                                                        </tr>
                                                    </ng-template>

                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                        <tr>
                                                            <td>
                                                                <span class="text-center">
                                                            <h4
                                                                class="ml-2">
                                                                No Record
                                                                Found</h4>
                                                        </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>

                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>   -->




<ngx-spinner></ngx-spinner>