import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import Swal from 'sweetalert2';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';
import { Location } from '@angular/common';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CustomerAgreementsService } from 'src/app/accounting/accounting-ar/customer-agreements/customer-agreements.service';

@Component({
  selector: 'app-detail-supplier-vendors',
  templateUrl: './detail-supplier-vendors.component.html',
  styleUrls: ['./detail-supplier-vendors.component.css']
})
export class DetailSupplierVendorsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  editSupplier: any;
  addAddress: any;
  addPhone: any;
  addEmail: any;
  addUrl: any;
  addNote: any;  agreementDate: any;
  fromDate: any;
  addAgreement: any;
  hours: { label: string; value: string; }[];
  minute: { label: string; value: string; }[];
  amPM: { label: string; value: string; }[];
  thruDate: any;
  partyId: any;
  noteSupplierBId: any;
  yesNoArray: { label: string; value: string; }[];
  editSupplierList: any;
  webUlSupplier: any;
  openOrdersSupplierById: any;
  agreementsSupplier: any;
  showWebUrl: boolean;
  productWebUrl: any;
  PurposeWebUrlArray: { label: string; value: string; }[];
  supplierArray: { label: string; value: string; }[];
  orderForm: any;
  supplierAll: any;
  supplierAllArray: any[]=[];
  currencyList: any[];
  ProductId: any;
  uploadDocForm: FormGroup;
  ProductIdArray: any;
  contactInformation: any;
  AgreementTypeListArray: any[]=[];
  adressSupplier: any;
  fileData: File;
  showPostalAddress: boolean;
  productPostalAddress: any;
  postalAddressPurpose: any;
  postalAddressPurposeArray: any[]=[];
  stateArray: any=[];
  productList: any = [];
  countryGeoId: any;
  countryId: any;
  stateProvinceGeoId: any;
  stateData: any;
  array: any=[];
  phoneNumberPurpose: any;
  phoneNumberPurposeArray: any[]=[];
  showTelecomNumber: boolean;
  productTelecomNumber: any;
  emailSupplier: any;
  showEmail: boolean;
  activeCategoryMainTs=1;
  productEmail: any;
  emailAddressPurpose: any;
  emailAddressPurposeArray: any[]=[];
  @ViewChild('closebutton1') closebutton1;
  agreementsList: any;
 
  activeCategory=1;
  activeCategoryA=2;
  personId: any;
  partyIdArray: any[] = []
  public applicationSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  trainingList: any;
  listVendors: any;
  listDistributors:any;
  createVendor: FormGroup;  
  PersonsAndPartyGroupsList: any = [];
  RoleTypesList: any = [];
  attachDocumentId: any;
  vendor: any;
  supplier: any;
  addressSupplier: any;
  createCustomerAgreements: FormGroup;
  InformationSupplier: any;  
  AgreementTypeList: any = [];
  product: any;
  productAgree: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly CustomerAgreementsService: CustomerAgreementsService,
    readonly datePipe: DatePipe,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    public createContact: CreateContactsService,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
    readonly addSkillService: AddSkillService,

  ) { 
    this.createVendor = this._FormBuilder.group({
      accountNo:  [''],
      billingRateLevel:  [''],
      company:  [''],
      companyWebsite:  [''],
      contactMechPurposeTypeId:  [''],
      creditLimit:  [''],
      eligibleFor:  [''],
      federalTaxId:  [''],
      generalAddress1:  [''],
      generalAddress2:  [''],
      generalAttnName:  [''],
      generalCity:  [''],
      generalCountryGeoId:  [''],
      generalPostalCode:  [''],
      generalStateProvinceGeoId:  [''],
      generalToName:  [''],
      groupName:  [''],
      individual:  [''],
      info :  [''],
      isActive:  [''],
      isIncorporated:  [''],
      manifestCompanyName:  [''],
      manifestCompanyTitle:  [''],
      manifestLogoUrl:  [''],
      manifestPolicies:  [''],
      map:  [''],
      name:  [''],
      partyId:  [''],
      phone:  [''],
      primaryEmail:  [''],
      primaryFaxAreaCode:  [''],
      primaryFaxCountryCode:  [''],
      primaryFaxExtension:  [''],
      primaryFaxNumber:  [''],
      primaryPhoneAreaCode:  [''],
      primaryPhoneCountryCode:  [''],
      primaryPhoneExtension:  [''],
      primaryPhoneNumber:  [''],
      primaryWebUrl:  [''],
      printCheckAs:  [''],
      requires1099:  [''],
      taxId:  [''],
      terms:  [''],
      type:  [''],
      typeVendor:  [''],
      vendorEmail:  [''],
      vendorFax:  [''],
      vendorForms:  [''],
      vendorName:  [''],
      vendorPhone:  [''],
      webUrlId:  [''],
      PrimaryWebUrl:['']
    });
    this.PurposeWebUrlArray = [
      {
        label: 'Primary Website URL',
        value: 'PRIMARY_WEB_URL'
      },
      {
        label: 'iCalendar URL',
        value: 'ICAL_URL'
      },

    ];
    this.supplierArray = [
      {
        label: 'There are no agreements for this supplier',
        value: 'There are no agreements for this supplier'
      },
     

    ];
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
    this.editSupplier = this._FormBuilder.group({
      name: [''],
      taxID: [''],
      requires1099: [''],
      incorporated: [''],
    });
    this.addAddress = this._FormBuilder.group({
      purpose: [''],
      toName: [''],
      attentionName: [''],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      state: [''],
      country: [''],
      directions: [''],
      solicitation: [''],
      PostalCode : [''],
      PostalCode2 : ['']
    });
    this.addPhone = this._FormBuilder.group({
      purpose: [''],
      phone1: [''],
      phone2: [''],
      phone3: [''],
      ext: [''],
      personToAsk: [''],
      solicitation: [''],
    });
    this.addEmail = this._FormBuilder.group({
      purpose: [''],
      email: [''],
      solicitation: [''],
    });
    this.addUrl = this._FormBuilder.group({
      purpose: [''],
      url: [''],
      solicitation: [''],
    });
    this.addNote = this._FormBuilder.group({
      note: ['']
    });
    this.orderForm = this._FormBuilder.group({
      Supplier: [''] ,
      OrderName: [''] ,
      SelectAgreement: [''] ,
      ShipAfterDate: [''] ,
      currency: [''] ,
      ShipBeforeDate: [''] ,
      amount: [''] ,
    });
    this.addAgreement = this._FormBuilder.group({
      agreementDate: [''],
      hour: [''],
      minute: [''],
      amPm: [''],
      fromDate: [''],
      hourFrom: [''],
      minuteFrom: [''],
      amPmFrom: [''],
      thruDate: [''],
      hourThru: [''],
      minuteThru: [''],
      amPmThru: [''],
      description: [''],
      fullText: ['']
    });
    this.createCustomerAgreements = this._FormBuilder.group({
      statusId: [''],
      agreementTypeId: ['', [Validators.required]],
      agreementDate: [''],
      description: [''],
      fromDate: [''],
      partyIdFrom: [''],
      partyIdTo: [''],
      productId: [''],
      roleTypeIdFrom: [''],
      roleTypeIdTo: [''],
      textData: [''],
      thruDate: ['']
    });
    this.hours = [
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },

    ];
    this.minute = [
      {
        label: '00',
        value: '00'
      },
      {
        label: '01',
        value: '01'
      },
      {
        label: '02',
        value: '02'
      },
      {
        label: '03',
        value: '03'
      },
      {
        label: '04',
        value: '04'
      },
      {
        label: '05',
        value: '05'
      },
      {
        label: '06',
        value: '06'
      },
      {
        label: '07',
        value: '07'
      },
      {
        label: '08',
        value: '08'
      },
      {
        label: '09',
        value: '09'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      },
      {
        label: '21',
        value: '21'
      },
      {
        label: '22',
        value: '22'
      },
      {
        label: '23',
        value: '23'
      },
      {
        label: '24',
        value: '24'
      },
      {
        label: '25',
        value: '25'
      },
      {
        label: '26',
        value: '26'
      },
      {
        label: '27',
        value: '27'
      },
      {
        label: '28',
        value: '28'
      },
      {
        label: '29',
        value: '29'
      },
      {
        label: '30',
        value: '30'
      },
      {
        label: '31',
        value: '31'
      },
      {
        label: '32',
        value: '32'
      },
      {
        label: '33',
        value: '33'
      }, {
        label: '34',
        value: '34'
      },
      {
        label: '35',
        value: '35'
      }, {
        label: '36',
        value: '36'
      },
      {
        label: '37',
        value: '37'
      }, {
        label: '38',
        value: '38'
      },
      {
        label: '39',
        value: '39'
      },
      {
        label: '40',
        value: '40'
      }, {
        label: '41',
        value: '41'
      },
      {
        label: '42',
        value: '42'
      },
      {
        label: '43',
        value: '43'
      }, {
        label: '44',
        value: '44'
      },
      {
        label: '45',
        value: '45'
      },
      {
        label: '46',
        value: '46'
      }, {
        label: '47',
        value: '47'
      },
      {
        label: '48',
        value: '48'
      },
      {
        label: '49',
        value: '49'
      }, {
        label: '50',
        value: '50'
      },
      {
        label: '51',
        value: '51'
      },
      {
        label: '52',
        value: '52'
      },
      {
        label: '53',
        value: '53'
      },{
        label: '54',
        value: '54'
      },{
        label: '55',
        value: '55'
      },{
        label: '56',
        value: '56'
      },{
        label: '57',
        value: '57'
      },
      {
        label: '58',
        value: '58'
      },
      {
        label: '59',
        value: '59'
      }


    ];
    this.amPM = [
      {
        label: 'AM',
        value: 'AM'
      },
      {
        label: 'PM',
        value: 'PM'
      },
    ];
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params =>{
      this.partyId = params.partyId;
    })
    this.getEditSupplierById();
    this.getNoteSupplierBId();
    this.getWebUlSupplier();
    this.getOpenOrdersSupplierById();
    this.getAgreementsSupplierById();
    this.getSupplierAll();
    this.getCurrency();
    this.getProductId();
    this.getPostalAddressPurpose();
    this.getCurrency();
    this.getContactInformationSupplier();
    this.getAddressSupplier();
    this.getCountryList();
    this.getPhonenumberPurpose();
    this.getCountryList();
    this.getEmailAddressPurpose();
    this.getEmailSupplier();
    this.getVendors();
    this.getPartyId();
    this.getAgreementByPartyId();
    this.getRoleTypes();
    this.getRoleTypes();
    this.getRoleTypes();
    this.getVendors();
    this.getPartyId();
    this.getAttachDocumentByPartyId();
    this.getAgreementTypeList();
    this.getProductsData();
    this.getPersonsAndPartyGroupsData();
    this.getRoleTypes();
    this.getAgreementTypeListData();
    this.getAgreementByPartyId();
  }

  downloadDocuments(product) {
    this.spinner.show();
    this.product = product.url;

  } 
  getAgreementByPartyId() {
    this.spinner.show();
    this.addSkillService.getAgreementByPartyId(this.partyId).subscribe((responce) => {
      this.spinner.hide();
      this.agreementsList = responce.data[0].getAgreementByPartyId;
    });

  }
  cancelAgreement(agreementId: string): void {
    this.spinner.show();
    this.addSkillService.cancelAgreements(agreementId).subscribe((responce) => {
      this.spinner.hide();
      this.toastr.success('Cancelled');

    });
   
  }
  getRoleTypes() {
    this.spinner.show();
    this.CustomerAgreementsService.getRoleTypes().subscribe((responce) => {
      this.spinner.hide();
      this.RoleTypesList = responce.data;
    });
   
  }
  getAgreementTypeListData() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {
      this.spinner.hide();
      this.AgreementTypeList = responce.data;
    });

  }
  getPersonsAndPartyGroupsData() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {
      this.spinner.hide();
      this.PersonsAndPartyGroupsList = responce.data.PersonsGroups;
    });
  
  }
  getProductsData() {
    this.spinner.show();
    const requestData = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains"
    };
    this.CustomerAgreementsService.getProducts(requestData).subscribe((responce) => {
      this.spinner.hide();
      this.productList = responce.data;
    });
   
  }

  getAgreementTypeList() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {

      this.AgreementTypeListArray = responce.data.map(value => {
        return {
          label: value.description,
          value: value.agreementTypeId
        };
      });
    });
    this.spinner.hide();
  }
  submitData(): void {
    this.spinner.show();
    if (this.createCustomerAgreements.valid) {
      const agreementDate = this.createCustomerAgreements.get('agreementDate').value;
      this.agreementDate = this.datePipe.transform(agreementDate, "yyyy-MM-dd hh:mm:ss");
      const thruDate = this.createCustomerAgreements.get('thruDate').value;
      this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
      const fromDate = this.createCustomerAgreements.get('fromDate').value;
      this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
      const requestData = {
        agreementDate: this.agreementDate,
        agreementTypeId: this.createCustomerAgreements.value.agreementTypeId.agreementTypeId,
        description: this.createCustomerAgreements.value.description,
        fromDate: this.fromDate,
        partyIdFrom: this.createCustomerAgreements.value.partyIdFrom.partyId,
        partyIdTo: this.createCustomerAgreements.value.partyIdTo.partyId,
        productId: this.createCustomerAgreements.value.productId.productId,
        roleTypeIdFrom: this.createCustomerAgreements.value.roleTypeIdFrom.roleTypeId,
        roleTypeIdTo: this.createCustomerAgreements.value.roleTypeIdTo.roleTypeId,
        textData: this.createCustomerAgreements.value.textData,
        thruDate: this.thruDate,
        agreementId: "" ,
        partyId: this.partyId,

          
      };
      this.addSkillService.createAgreements(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.closebutton3.nativeElement.click();
          this.getAgreementTypeList();
         // this.router.navigate([`/financial/${this.agreementType}/customer-agreeement`]);
        } }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          } } });
    } else {
      this.spinner.hide();
      this.toastr.error('Please fill required field');
    }}
    updateAgree(product){
this.productAgree = product;
setTimeout(() => {
  const formValue = this.createCustomerAgreements;
  formValue.patchValue({
    manifestCompanyName: product.data.manifestCompanyName,

    statusId: product.data.manifestCompanyName,
    agreementTypeId: product.data.manifestCompanyName,
    agreementDate: product.data.manifestCompanyName,
    description: product.data.manifestCompanyName,
    fromDate: product.data.manifestCompanyName,
    partyIdFrom: product.data.manifestCompanyName,
    partyIdTo: product.data.manifestCompanyName,
    productId: product.data.manifestCompanyName,
    roleTypeIdFrom: product.data.manifestCompanyName,
    roleTypeIdTo: product.data.manifestCompanyName,
    textData: product.data.manifestCompanyName,
    thruDate: product.data.manifestCompanyName,
  })

}, 3000);

    }
    updateAgreements(): void {
      this.spinner.show();
      if (this.createCustomerAgreements.valid) {
        const agreementDate = this.createCustomerAgreements.get('agreementDate').value;
        this.agreementDate = this.datePipe.transform(agreementDate, "yyyy-MM-dd hh:mm:ss");
        const thruDate = this.createCustomerAgreements.get('thruDate').value;
        this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
        const fromDate = this.createCustomerAgreements.get('fromDate').value;
        this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
        const requestData = {
          agreementDate: this.agreementDate,
          agreementTypeId: this.createCustomerAgreements.value.agreementTypeId.agreementTypeId,
          description: this.createCustomerAgreements.value.description,
          fromDate: this.fromDate,
          partyIdFrom: this.createCustomerAgreements.value.partyIdFrom.partyId,
          partyIdTo: this.createCustomerAgreements.value.partyIdTo.partyId,
          productId: this.createCustomerAgreements.value.productId.productId,
          roleTypeIdFrom: this.createCustomerAgreements.value.roleTypeIdFrom.roleTypeId,
          roleTypeIdTo: this.createCustomerAgreements.value.roleTypeIdTo.roleTypeId,
          textData: this.createCustomerAgreements.value.textData,
          thruDate: this.thruDate,
          agreementId: this.productAgree.agreementId ,
          partyId: this.partyId,
  
            
        };
        this.addSkillService.updateAgreements(requestData).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success("Updated Sucessfully");
            this.spinner.hide();
            this.closebutton3.nativeElement.click();
            this.getAgreementTypeList();
           // this.router.navigate([`/financial/${this.agreementType}/customer-agreeement`]);
          } }, (err) => {
          this.spinner.hide();
          if (err.error.errors) {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            } } });
      } else {
        this.spinner.hide();
        this.toastr.error('Please fill required field');
      }}
  downloadDocument(attachDocumentId) {
    this.spinner.show();
    this.addSkillService.downloadDocument(attachDocumentId).subscribe(res => {
      const blob = new Blob([res.data[0].url], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      console.log(blob,"blobb")
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "CONTENT.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  resetDoc(){
    this.uploadDocForm.reset();
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  attachDocument() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.addSkillService.attachDocument(formData, this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Attach Document Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.getAttachDocumentByPartyId();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }
  getAttachDocumentByPartyId() {
    this.spinner.show();
    this.addSkillService.getAttachDocumentByPartyId(this.partyId).subscribe(res => {
      this.attachDocumentId = res.data[0].getAttachDocumentByPartyId;
      this.spinner.hide();
     
    })

  }
  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/vendor/Dashboard']);
}



getEditSupplierById() {
  this.spinner.show();
  this.myContactsService.getEditSupplierById(this.partyId).subscribe(res => {
    this.vendor=res.data[0][0].Supplier[0];
    this.supplier=res.data[1][0].getWebUlSupplier[0];
    this.emailSupplier=res.data[2][0].getEmailSupplier[0];
    this.addressSupplier=res.data[3][0].getAddressSupplier[0];
    this.InformationSupplier=res.data[4][0].getContactInformationSupplier[0]
    setTimeout(()=>{
      const formValue = this.createVendor;
      formValue.patchValue({
        accountNo: this.vendor.accountNo,
        billingRateLevel: this.vendor.billingRateLevel,
        company: this.vendor.company,
        companyWebsite: this.vendor.companyWebsite,
        contactMechPurposeTypeId: this.supplier.contactMechPurposeTypeId,
        creditLimit: this.vendor.creditLimit,
        eligibleFor: this.vendor.eligibleFor,
        federalTaxId: this.vendor.federalTaxId,
        generalAddress1: this.addressSupplier.generalAddress1,
        generalAddress2: this.addressSupplier.generalAddress2,
        generalAttnName: this.addressSupplier.generalAttnName,
        generalCity: this.addressSupplier.generalCity,
        generalCountryGeoId: this.addressSupplier.generalCountryGeoId,
        generalPostalCode: this.addressSupplier.generalPostalCode,
        generalStateProvinceGeoId: this.addressSupplier.generalStateProvinceGeoId,
        generalToName: this.addressSupplier.generalToName,
        groupName: this.vendor.groupName,
        individual: this.vendor.individual,
        isActive: this.vendor.isActive,
        isIncorporated: this.vendor.isIncorporated,
        manifestCompanyName:  this.vendor.manifestCompanyName,
        manifestCompanyTitle: this.vendor.manifestCompanyTitle,
        manifestLogoUrl: this.vendor.manifestLogoUrl,
        manifestPolicies: this.vendor.manifestPolicies,
        map: this.vendor.map,
        name: this.vendor.name,
        partyId: this.vendor.partyId,
        phone: this.vendor.phone,
        primaryEmail: this.emailSupplier.primaryEmail,
        primaryFaxAreaCode: this.InformationSupplier.primaryFaxAreaCode,
        primaryFaxCountryCode: this.InformationSupplier.primaryFaxCountryCode,
        primaryFaxExtension: this.InformationSupplier.primaryFaxExtension,
        primaryFaxNumber: this.InformationSupplier.primaryFaxNumber,
        primaryPhoneAreaCode: this.InformationSupplier.primaryPhoneAreaCode,
        primaryPhoneCountryCode: this.InformationSupplier.primaryPhoneCountryCode,
        primaryPhoneExtension: this.InformationSupplier.primaryPhoneExtension,
        primaryPhoneNumber: this.InformationSupplier.primaryPhoneNumber,
        primaryWebUrl: this.supplier.primaryWebUrl,
        printCheckAs: this.vendor.printCheckAs,
        requires1099: this.vendor.requires1099,
        taxId: this.vendor.taxId,
        terms: this.vendor.terms,
        type: this.vendor.type,
        typeVendor: this.vendor.typeVendor,
        vendorEmail: this.vendor.vendorEmail,
        vendorFax: this.vendor.vendorFax,
        vendorForms: this.vendor.vendorForms,
        vendorName: this.vendor.vendorName,
        vendorPhone: this.vendor.vendorPhone,
        webUrlId: this.supplier.webUrlId,
        PrimaryWebUrl: this.supplier.PrimaryWebUrl,
      })
    }, 2000);
    this.spinner.hide();
  })
}

getPartyId() {
  this.spinner.show();
  this.addSkillService.getPartyId().subscribe(res => {
    this.personId = res.data.PersonsGroups;
    this.spinner.hide();
    for (const value of this.personId) {
      this.partyIdArray.push({
        label: value.partyId,
        value: value.partyId
      })
    }
  })

}
getVendors() {
  this.spinner.show();
  const req = {
    "manifestCompanyName": "",
    "manifestCompanyNameSearchType": "Contains",
    "manifestCompanyTitle": "",
    "manifestCompanyTitleSearchType": "Contains",
    "manifestLogoUrl": "",
    "manifestLogoUrlSearchType": "Contains",
    "manifestPolicies": "",
    "manifestPoliciesSearchType": "Contains",
    "partyId": this.partyId,
    "partyIdSearchType": "Contains"
  }
  this.accountsService.getVendors(this.applicationSize, req).subscribe((res: any) => {
    this.listDistributors = res.data[0];

    this.spinner.hide();

  })

}
onUpdate() {
  this.spinner.show();
  const requestData = {
    "manifestCompanyName": this.createVendor.value.manifestCompanyName,
    "manifestCompanyTitle": this.createVendor.value.manifestCompanyTitle,
    "manifestLogoUrl": this.createVendor.value.manifestLogoUrl,
    "manifestPolicies": this.createVendor.value.manifestPolicies,
    "partyId": this.createVendor.value.partyId,
    "accountNo": this.createVendor.value.accountNo,
    "billingRateLevel": this.createVendor.value.billingRateLevel,
    "creditLimit": this.createVendor.value.creditLimit,
    "eligibleFor": this.createVendor.value.eligibleFor,
    "printCheckAs": this.createVendor.value.printCheckAs,
    "taxId": this.createVendor.value.taxId,
    "terms": this.createVendor.value.terms,
    "type": this.createVendor.value.type,
    "company": this.createVendor.value.company,
    "companyWebsite": this.createVendor.value.companyWebsite,
    "individual": this.createVendor.value.individual,
    "isActive": this.createVendor.value.isActive,
    "map": this.createVendor.value.map,
    "name": this.createVendor.value.name,
    "phone": this.createVendor.value.phone,
    "typeVendor": "distributor",
    "vendorEmail": this.createVendor.value.vendorEmail,
    "vendorFax": this.createVendor.value.vendorFax,
    "vendorForms": this.createVendor.value.vendorForms,
    "vendorName": this.createVendor.value.vendorName,
    "vendorPhone": this.createVendor.value.vendorPhone,
    "contactMechPurposeTypeId": this.createVendor.value.contactMechPurposeTypeId,
    "federalTaxId": this.createVendor.value.federalTaxId,
    "generalAddress1": this.createVendor.value.generalAddress1,
    "generalAddress2": this.createVendor.value.generalAddress2,
    "generalAttnName": this.createVendor.value.generalAttnName,
    "generalCity": this.createVendor.value.generalCity,
    "generalCountryGeoId": this.createVendor.value.generalCountryGeoId,
    "generalPostalCode": this.createVendor.value.generalPostalCode,
    "generalStateProvinceGeoId": this.createVendor.value.generalStateProvinceGeoId,
    "generalToName": this.createVendor.value.generalToName,
    "groupName": this.createVendor.value.groupName,
    "infoString": this.createVendor.value.infoString,
    "isIncorporated": this.createVendor.value.isIncorporated,
    "primaryEmail": this.createVendor.value.primaryEmail,
    "primaryFaxAreaCode": this.createVendor.value.primaryFaxAreaCode,
    "primaryFaxCountryCode": this.createVendor.value.primaryFaxCountryCode,
    "primaryFaxExtension": this.createVendor.value.primaryFaxExtension,
    "primaryFaxNumber": this.createVendor.value.primaryFaxNumber,
    "primaryPhoneAreaCode": this.createVendor.value.primaryPhoneAreaCode,
    "primaryPhoneCountryCode": this.createVendor.value.primaryPhoneCountryCode,
    "primaryPhoneExtension": this.createVendor.value.primaryPhoneExtension,
    "primaryPhoneNumber": this.createVendor.value.primaryPhoneNumber,
    "primaryWebUrl": this.createVendor.value.primaryWebUrl,
    "requires1099": this.createVendor.value.requires1099,
    "webUrlId":this.createVendor.value.webUrlId,
    
  }
  this.accountsService.updateVendors(requestData).subscribe((res: any) => {
    if (res.success) {
      this.spinner.hide();
      this.toastr.success("Updated Successfully");
      this.closebutton1.nativeElement.click();
      this.getVendors();

    }
  }, (err) => {
    this.spinner.hide();
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
  });
}

updateSupplierForm() {
  this.spinner.show();
  const requestData = {
    "manifestCompanyName": this.createVendor.value.manifestCompanyName,
    "manifestCompanyTitle": this.createVendor.value.manifestCompanyTitle,
    "manifestLogoUrl": this.createVendor.value.manifestLogoUrl,
    "manifestPolicies": this.createVendor.value.manifestPolicies,
    "partyId": this.createVendor.value.partyId,
    "accountNo": this.createVendor.value.accountNo,
    "billingRateLevel": this.createVendor.value.billingRateLevel,
    "creditLimit": this.createVendor.value.creditLimit,
    "eligibleFor": this.createVendor.value.eligibleFor,
    "printCheckAs": this.createVendor.value.printCheckAs,
    "taxId": this.createVendor.value.taxId,
    "terms": this.createVendor.value.terms,
    "type": this.createVendor.value.type,
    "company": this.createVendor.value.company,
    "companyWebsite": this.createVendor.value.companyWebsite,
    "individual": this.createVendor.value.individual,
    "isActive": this.createVendor.value.isActive,
    "map": this.createVendor.value.map,
    "name": this.createVendor.value.name,
    "phone": this.createVendor.value.phone,
    "typeVendor": "distributor",
    "vendorEmail": this.createVendor.value.vendorEmail,
    "vendorFax": this.createVendor.value.vendorFax,
    "vendorForms": this.createVendor.value.vendorForms,
    "vendorName": this.createVendor.value.vendorName,
    "vendorPhone": this.createVendor.value.vendorPhone,
    "contactMechPurposeTypeId": this.createVendor.value.contactMechPurposeTypeId,
    "federalTaxId": this.createVendor.value.federalTaxId,
    "generalAddress1": this.createVendor.value.generalAddress1,
    "generalAddress2": this.createVendor.value.generalAddress2,
    "generalAttnName": this.createVendor.value.generalAttnName,
    "generalCity": this.createVendor.value.generalCity,
    "generalCountryGeoId": this.createVendor.value.generalCountryGeoId,
    "generalPostalCode": this.createVendor.value.generalPostalCode,
    "generalStateProvinceGeoId": this.createVendor.value.generalStateProvinceGeoId,
    "generalToName": this.createVendor.value.generalToName,
    "groupName": this.createVendor.value.groupName,
    "infoString": this.createVendor.value.infoString,
    "isIncorporated": this.createVendor.value.isIncorporated,
    "primaryEmail": this.createVendor.value.primaryEmail,
    "primaryFaxAreaCode": this.createVendor.value.primaryFaxAreaCode,
    "primaryFaxCountryCode": this.createVendor.value.primaryFaxCountryCode,
    "primaryFaxExtension": this.createVendor.value.primaryFaxExtension,
    "primaryFaxNumber": this.createVendor.value.primaryFaxNumber,
    "primaryPhoneAreaCode": this.createVendor.value.primaryPhoneAreaCode,
    "primaryPhoneCountryCode": this.createVendor.value.primaryPhoneCountryCode,
    "primaryPhoneExtension": this.createVendor.value.primaryPhoneExtension,
    "primaryPhoneNumber": this.createVendor.value.primaryPhoneNumber,
    "primaryWebUrl": this.createVendor.value.primaryWebUrl,
    "requires1099": this.createVendor.value.requires1099,
    "webUrlId":this.createVendor.value.webUrlId,
    
  }
  this.myContactsService.updateSupplierForm(requestData).subscribe((res: any) => {
    if (res.success) {
      this.toastr.success("Updated Successfully");
      this.spinner.hide();
      this.closebutton1.nativeElement.click();
      this.createVendor.reset();
      this.getEditSupplierById();
    }
  }, (err) => {
    this.spinner.hide();
    if (err.error.errors) {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    }
    else {
      this.toastr.error(err.error.message);
    }
  });
}
changeactiveCategoryFunction(tab: number) {
  this.activeCategory = tab;
}
changeactiveCategoryFunctionA(tab: number) {
  this.activeCategoryA = tab;
}
  getEmailAddressPurpose() {
    this.spinner.show();
    this.myContactsService.getEmailAddressPurpose().subscribe(res => {
      this.emailAddressPurpose = res.data[0].data;
      this.spinner.hide();
      for (const value of this.emailAddressPurpose) {
        this.emailAddressPurposeArray.push({
          label: value.description,
          value: value.contactMechPurposeTypeId
        })
      }
    })

  }
  getEmailSupplier() {
    this.spinner.show();
    this.myContactsService.getEmailSupplier(this.partyId).subscribe(res => {
      this.emailSupplier = res.data[0].getEmailSupplier ;
      this.spinner.hide();

    })

  }
  resetEmail(){
    this.showEmail= false;
    this.addEmail.reset();
  }

  updateEmail(product){
    this.showEmail = true;
    this.productEmail = product;

    setTimeout(()=>{
      const formValue = this.addEmail;
      formValue.patchValue({
        purpose: product.contactMechPurposeTypeId,
        email:product.primaryEmail,
        solicitation:product.allowSolicitation
      }
      )
    },2000
    );
  }
  deleteEmailSupplier(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "emailId": product.emailId,
          "partyId":this.partyId
        }
        this.myContactsService.deleteEmailSupplier(formData).subscribe(res => {
        this.getEmailSupplier (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateEmailSupplier(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation": this.addEmail.value.solicitation ,
      "contactMechPurposeTypeId": this.addEmail.value.purpose ,
      "emailId": this.productEmail.emailId ,
      "partyId": this.partyId,
      "primaryEmail": this.addEmail.value.email ,
    }
    this.myContactsService.updateEmailSupplier(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.addEmail.reset();
        this.getEmailSupplier();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createEmailSupplier(){
    this.spinner.show();
  
    const requestData = {
      "allowSolicitation": this.addEmail.value.solicitation ,
  "contactMechPurposeTypeId": this.addEmail.value.purpose ,
  "emailId": "",
  "partyId": this.partyId,
  "primaryEmail": this.addEmail.value.email ,
    }
    this.myContactsService.createEmailSupplier(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.addEmail.reset();
        this.getEmailSupplier();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  cancelSubmit(){
    this._Router.navigate(["/vendor/Suppliers"])
  }
  getPhonenumberPurpose() {
    this.spinner.show();
    this.myContactsService.getPhonenumberPurpose().subscribe(res => {
      this.phoneNumberPurpose = res.data[0].data;
      this.spinner.hide();
      for (const value of this.phoneNumberPurpose) {
        this.phoneNumberPurposeArray.push({
          label: value.description,
          value: value.contactMechPurposeTypeId
        })
      }
    })

  }
  updateContactInformationSupplier(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation":this.addPhone.value.solicitation ,
      "areaCode": this.addPhone.value.phone2 ,
      "askForName": this.addPhone.value.personToAsk ,
      "contactInformationId":     this.productTelecomNumber.contactInformationId ,
      "contactMechPurposeTypeId":  this.addPhone.value.purpose ,
      "contactNumber": this.addPhone.value.phone3 ,
      "countryCode":this.addPhone.value.phone1 ,
      "extension": this.addPhone.value.ext ,
      "partyId": this.partyId,
      "primaryFaxAreaCode": "",
      "primaryFaxCountryCode": "",
      "primaryFaxExtension": "",
      "primaryFaxNumber": "",
      "primaryPhoneAreaCode": "",
      "primaryPhoneCountryCode": "",
      "primaryPhoneExtension": "",
      "primaryPhoneNumber": "",    

    }
    this.myContactsService.updateContactInformationSupplier(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.addPhone.reset();
       this.getContactInformationSupplier();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
   
  deleteContactInformationSupplier(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "contactInformationId": product.contactInformationId,
          "partyId":this.partyId
        }
        this.myContactsService.deleteContactInformationSupplier(formData).subscribe(res => {
        this.getContactInformationSupplier(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createContactInformationSupplier(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation":this.addPhone.value.solicitation ,
      "areaCode": this.addPhone.value.phone2 ,
      "askForName": this.addPhone.value.personToAsk ,
      "contactInformationId": "",
      "contactMechPurposeTypeId":  this.addPhone.value.purpose ,
      "contactNumber": this.addPhone.value.phone3 ,
      "countryCode":this.addPhone.value.phone1 ,
      "extension": this.addPhone.value.ext ,
      "partyId": this.partyId,
      "primaryFaxAreaCode": "",
      "primaryFaxCountryCode": "",
      "primaryFaxExtension": "",
      "primaryFaxNumber": "",
      "primaryPhoneAreaCode": "",
      "primaryPhoneCountryCode": "",
      "primaryPhoneExtension": "",
      "primaryPhoneNumber": ""

    }
    this.myContactsService.createContactInformationSupplier(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.addPhone.reset();
        this.getContactInformationSupplier();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  resetTelecomNumber(){
    this.showTelecomNumber = false;
    this.addPhone.reset();
  }

  updateTelecomNumber(product){
    this.showTelecomNumber = true;
    this.productTelecomNumber = product;

    setTimeout(()=>{
      const formValue = this.addPhone;
      formValue.patchValue({
        purpose: product.contactMechPurposeTypeId,
        phone1:  product.countryCode,
        phone2:  product.areaCode,
        phone3:  product.contactNumber,
        ext:  product.extension,
        personToAsk: product.askForName,
        solicitation:  product.allowSolicitation,
      }
      )

    },2000
    );

  }
  getCountryList() {
    this.spinner.show();
    this.createContact.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })
      })
    })
   
  }

  onCountryChange(event) {
    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }
  onStateChange(event) {
    this.stateProvinceGeoId = event
  }
  getStateList() {
    this.spinner.show();
    this.createContact.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
   
  }

  getPostalAddressPurpose() {
    this.spinner.show();
    this.myContactsService.getPostalAddressPurpose().subscribe(res => {
      this.postalAddressPurpose = res.data[0].data;
      this.spinner.hide();
      for (const value of this.postalAddressPurpose) {
        this.postalAddressPurposeArray.push({
          label: value.description,
          value: value.contactMechPurposeTypeId
        })
      }
    })

  }
  resetAddress(){
    this.showPostalAddress = false;
    this.addAddress.reset();
  }
  updateAddress(product){
    this.showPostalAddress = true;
    this.productPostalAddress = product;

    setTimeout(()=>{
      const formValue = this.addAddress;
      formValue.patchValue({
        purpose: product.contactMechPurposeTypeId ,
        toName:  product.generalToName ,
        attentionName:  product.generalAttnName ,
        addressLine1:  product.generalAddress1 ,
        addressLine2:  product.generalAddress2 ,
        city:  product.generalCity ,
        state:  product.generalStateProvinceGeoId ,
        country:  product.generalCountryGeoId ,
        directions:  product.direction ,
        solicitation:  product.allowSolicitation ,
        PostalCode:  product.generalPostalCode ,
      }
      )
    },2000
    );
  }
  updateAddressSupplier(){
    this.spinner.show();
    const requestData = {
      "addressId":  this.productPostalAddress.addressId,
      "allowSolicitation": this.addAddress.value.solicitation ,
      "contactMechPurposeTypeId": this.addAddress.value.purpose ,
      "direction": this.addAddress.value.directions ,
      "generalAddress1": this.addAddress.value.addressLine1 ,
      "generalAddress2": this.addAddress.value.addressLine2 ,
      "generalAttnName": this.addAddress.value.attentionName ,
      "generalCity": this.addAddress.value.city ,
      "generalCountryGeoId": this.addAddress.value.country ,
      "generalPostalCode": this.addAddress.value.PostalCode ,
      "generalStateProvinceGeoId": this.addAddress.value.state ,
      "generalToName": this.addAddress.value.toName ,
      "partyId": this.partyId,


    }
    this.myContactsService.updateAddressSupplier(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addAddress.reset();
        this.getAddressSupplier();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  
  createAddressSupplier(){
    this.spinner.show();
    const requestData = {
      "addressId": "",
      "allowSolicitation": this.addAddress.value.solicitation ,
      "contactMechPurposeTypeId": this.addAddress.value.purpose ,
      "direction": this.addAddress.value.directions ,
      "generalAddress1": this.addAddress.value.addressLine1 ,
      "generalAddress2": this.addAddress.value.addressLine2 ,
      "generalAttnName": this.addAddress.value.attentionName ,
      "generalCity": this.addAddress.value.city ,
      "generalCountryGeoId": this.addAddress.value.country ,
      "generalPostalCode": this.addAddress.value.PostalCode ,
      "generalStateProvinceGeoId": this.addAddress.value.state ,
      "generalToName": this.addAddress.value.toName ,
      "partyId": this.partyId
}
    this.myContactsService.createAddressSupplier(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addAddress.reset();
        this.getAddressSupplier();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteAddressSupplier(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "addressId":product.addressId ,
          "partyId":  this.partyId
        }
        this.myContactsService.deleteAddressSupplier(formData).subscribe(res => {
        this.getAddressSupplier(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getContactInformationSupplier() {
    this.spinner.show();
    this.myContactsService.getContactInformationSupplier(this.partyId).subscribe(res => {
      this.contactInformation = res.data[0].getContactInformationSupplier ;
      this.spinner.hide();

    })

  }
  getAddressSupplier() {
    this.spinner.show();
    this.myContactsService.getAddressSupplier(this.partyId).subscribe(res => {
      this.adressSupplier = res.data[0].getAddressSupplier ;
      this.spinner.hide();

    })

  }
  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
   
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.currencyList = data.data.currencyList.map(value => {
            return {
              label: value.description,
              value: value.uomId
            };
          });
        }
      });
  }
  getSupplierAll() {
    this.spinner.show();
    this.myContactsService.getSupplierAll().subscribe(res => {
      this.supplierAll = res.data[0].getEditSupplier;
      this.spinner.hide();
      for (const value of this.supplierAll) {
        this.supplierAllArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }
    })
  }
  createOpenOrdersSupplier(){
    this.spinner.show();
    const requestData = {
      "amount": this.orderForm.value.amount ,
      "openOrderId": "",
      "openOrdersDate":this.datePipe.transform(this.orderForm.value.ShipAfterDate , "yyyy-MM-dd hh:mm:ss") ,
      "openOrdersThruDate":this.datePipe.transform(this.orderForm.value.ShipAfterDate , "yyyy-MM-dd hh:mm:ss") ,
      "orderName":  this.orderForm.value.OrderName,
      "partyId": this.partyId,
      "statusId": "ORDER_CREATED",
      "supplierName":  this.orderForm.value.Supplier,

    } 
    this.myContactsService.createOpenOrdersSupplier(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.orderForm.reset();
        this.getOpenOrdersSupplierById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteWebUrlSupplier(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partyId":this.partyId,
          "webUrlId": product.webUrlId
        }
        this.myContactsService.deleteWebUrlSupplier(formData).subscribe(res => {
       this.getWebUlSupplier (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  UpdateWeb(product){
    this.showWebUrl = true;
    this.productWebUrl = product;

    setTimeout(()=>{
      const formValue = this.addUrl;
      formValue.patchValue({
        purpose: product.contactMechPurposeTypeId,
        url: product.infoString,
        solicitation: product.allowSolicitation,
      }
      )

    },2000
    );

  }
  resetWebUrl(){
    this.showWebUrl = false;
    this.addUrl.reset();
  }
  updateWebUrlSupplier(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation": this.addUrl.value.solicitation ,
  "contactMechPurposeTypeId":this.addUrl.value.purpose ,
  "infoString": this.addUrl.value.url ,
  "partyId": this.partyId,
  "primaryWebUrl": this.productWebUrl.primaryWebUrl ,
  "webUrlId":  this.productWebUrl.webUrlId ,

    }
    this.myContactsService.updateWebUrlSupplier(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.addUrl.reset();
     this.getWebUlSupplier();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createWebUrlSupplier(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation": this.addUrl.value.solicitation ,
  "contactMechPurposeTypeId":this.addUrl.value.purpose ,
  "infoString": this.addUrl.value.url ,
  "partyId": this.partyId,
  "primaryWebUrl": "" ,
  "webUrlId": ""
    } 
    this.myContactsService.createWebUrlSupplier(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.addUrl.reset();
        this.getWebUlSupplier();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createAgreementsSupplier(){
    this.spinner.show();
    const requestData = {
      "agreementCompositeType":"Timestamp" ,
      "agreementDate":this.datePipe.transform(this.addAgreement.value.agreementDate ,'yyyy-MM-dd hh:mm:ss'),
      "agreementDateAmPm": this.addAgreement.value.amPm ,
      "agreementDateHour": this.addAgreement.value.hour ,
      "agreementDateMin": this.addAgreement.value.minute ,
      "agreementId": "" ,
      "agreementName": this.addAgreement.value.description ,
      "fromDate": this.datePipe.transform(this.addAgreement.value.fromDate ,'yyyy-MM-dd hh:mm:ss'),
      "fromDateAmPm": this.addAgreement.value.amPmFrom ,
      "fromDateCompositeType": "Timestamp" ,
      "fromDateHour": this.addAgreement.value.hourFrom ,
      "fromDateMin": this.addAgreement.value.minuteFrom ,
      "partyId": this.partyId ,
      "statusId": "EXST_APPROVED",
      "thruDate":this.datePipe.transform(this.addAgreement.value.thruDate ,'yyyy-MM-dd hh:mm:ss'),
      "thruDateAmPm": this.addAgreement.value.amPmThru ,
      "thruDateCompositeType": "Timestamp" ,
      "thruDateHour": this.addAgreement.value.hourThru ,
      "thruDateMin": this.addAgreement.value.minuteThru ,
    } 
    this.myContactsService.createAgreementsSupplier(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.addAgreement.reset();
        this.getAgreementsSupplierById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getAgreementsSupplierById() {
    this.spinner.show();
    this.myContactsService.getAgreementsSupplierById(this.partyId).subscribe(res => {
      this.agreementsSupplier = res.data[0].getAgreementsSupplierAll ;
      this.spinner.hide();

    })

  }
  getWebUlSupplier() {
    this.spinner.show();
    this.myContactsService.getWebUlSupplier(this.partyId).subscribe(res => {
      this.webUlSupplier = res.data[0].getWebUlSupplier;
      this.spinner.hide();

    })
    
  }
  getOpenOrdersSupplierById() {
    this.spinner.show();
    this.myContactsService.getOpenOrdersSupplierById(this.partyId).subscribe(res => {
      this.openOrdersSupplierById = res.data[0].getOpenOrdersSupplierAll;
      this.spinner.hide();

    })

  }
  createNoteSupplier(){
    this.spinner.show();
    const requestData = {
      "noteId": "",
      "noteInformation": this.addNote.value.note ,
      "partyId": this.partyId
    }
    this.myContactsService.createNoteSupplier(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.addNote.reset();
        this.getNoteSupplierBId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getNoteSupplierBId() {
    this.spinner.show();
    this.myContactsService.getNoteSupplierBId(this.partyId).subscribe(res => {
      this.noteSupplierBId = res.data[0].getNoteSupplierAll;
      this.spinner.hide();

    })

  }
  // getEditSupplierById() {
  //   this.spinner.show();
  //   this.myContactsService.getEditSupplierById(this.partyId).subscribe(res => {
  //     this.editSupplierList = res.data[0].getEditSupplier[0];
  //     setTimeout(()=>{
  //       const formValue = this.editSupplier;
  //       formValue.patchValue({
  //         name: this.editSupplierList.groupName ,
  //         taxID:this.editSupplierList.federalTaxId,
  //         requires1099:this.editSupplierList.requires1099,
  //         incorporated: this.editSupplierList.isIncorporated,
  //       }
  //       )
  //     }, 2000);
  //     this.spinner.hide();
  //   })
  // }
 
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
