import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-res-type',
  templateUrl: './detail-res-type.component.html',
  styleUrls: ['./detail-res-type.component.css']
})
export class DetailResTypeComponent implements OnInit {

  activeCategory=1;
  skillType: any;
  Des: any;

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
      private _location: Location,
      readonly router: Router,
  ) { }
 
  ngOnInit(): void {
   
    this.activatedRoute.queryParams.subscribe(params=> [
      this.skillType = params["ResType"],
      this.Des=params["des"],
    
    ])
   
  }

  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
}

