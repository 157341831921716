import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from '../crm/contacts/my-contacts/my-contacts.service';


@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  projectForm: FormGroup;
  timesheetForm: FormGroup;
  newrequestForm: FormGroup;
  projectSummaryData: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    private _location: Location,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
        readonly _FormBuilder: FormBuilder,
        readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.timesheetForm = this._FormBuilder.group({
      Party: [''],
      SelectDate: [''],
      

    });
    
    this.newrequestForm = this._FormBuilder.group({
      RequestingParty: [''],
      CustRequestName: [''],
      CustRequestTypeID: [''],
      ShortDescription: [''],
      Description: [''],
      Priority: [''],
      ProjectandPhaseName: [''],
      Party: [''],
      RequestDate: [''],
      ResponseRequiredDate: [''],
      CustEstimatedMilliSeconds: [''],
      CustSequenceNum: [''],
      ParentCustRequestID: [''],
      Billed: [''],
      Story: [''],
      WebSiteID: [''],
      

    });
   }

  ngOnInit(): void {
    this.getProjectSummary();
  }
  projectMain() {
    this._Router.navigate(["/psa/project/project"])
  }
  myTasks() {
    this._Router.navigate(["/psa/project/my-tasks"])
  }
  myTime() {
    this._Router.navigate(["/psa/project/my-time"])
  }
  projects() {
    this._Router.navigate(["/psa/project/projects"])
  }
  tasks() {
    this._Router.navigate(["/psa/project/tasks-project"])
  }
  resources() {
    this._Router.navigate(["/psa/project/resources"])
  }
  timesheet() {
    this._Router.navigate(["/psa/project/timesheet-project"])
  }
  skillType() {
    this._Router.navigate(["/psa/project/skill-type"])
  }
  requestList() {
    this._Router.navigate(["/psa/project/request-list"])
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  detailedPage(prjectID) {
    this._Router.navigate(["/psa/project/detail-project-summary"], { queryParams:{ projectID: prjectID} })
  }
  getProjectSummary(){
    this.spinner.show();
    this.myContactsService.getProjectSummary().subscribe((res:any)=> {
      this.projectSummaryData = res.data;
      this.spinner.hide();
    
    })   
   }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
