import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from '../../app.constant';

@Injectable({
  providedIn: 'root'
})
export class InventoryLabelsService {

  constructor(private http: HttpClient) { }

  getinventoryItemLabelList(): Observable<any> {
    const inventoryItemLabelList = URLS.inventoryItemLabelList;
    return this.http.get(inventoryItemLabelList);
  }

  getInventoryItemLabelTypeId(): Observable<any> {
    const getInventoryItemLabelTypeId = URLS.getInventoryItemLabelTypeId;
    return this.http.get(getInventoryItemLabelTypeId);
  }

  createInventoryItemLabel(formData): Observable<any> {
    const createInventoryItemLabel = URLS.createInventoryItemLabel;
    return this.http.post(createInventoryItemLabel, formData);
  }

  updateInventoryItemLabel(formData): Observable<any> {
    const updateInventoryItemLabel = URLS.createInventoryItemLabel;
    return this.http.put(updateInventoryItemLabel, formData);
  }

  deleteCatalogStore(id) {
    const deleteInventoryItemLabel = URLS.createInventoryItemLabel + `/${id}`;
    return this.http.delete(deleteInventoryItemLabel);
  }

}
