<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Manufacturing Rule : [{{ this.ruleId}}]</span> <span></span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>


                <div>
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">



                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select " style="display: block!important;">

                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="w3-card-4 classCard">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling Hleft">
                                                                        Manufacturing Rule Information
                                                                    </h4>
                                                                    <span class="edit-buttons hCreate">
                               
                                          <a  data-toggle="modal" (click)="getManufacturingIdByDataEdit()"
                                          data-target="#exampleModalCenter" >Update</a>
                                        </span>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select" *ngIf="manufactureRule">
                                                                        <div class="color-black container">
                                                                            <div class="row">
                                                                                <div class="article-container">
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Rule ID</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.ruleId}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Rule Seq Id</p>
                                                                                        <span style="display: contents;" class="color-grey pl-20 col-lg-10 col-12">{{this.manufactureRule.ruleSeqId}}</span>

                                                                                    </div>



                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Description</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.manufactureRule.description}}</span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Product Id</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.manufactureRule.productId}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Product Id For</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.manufactureRule.productIdFor}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Product Id In</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.manufactureRule.productIdIn}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Product Id In Subset</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.manufactureRule.productNameInSubst}}</span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Product Feature</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.manufactureRule.productFeatureDesc}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Quantity</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.manufactureRule.quantity}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Rule Operator</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.manufactureRule.ruleOperator}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">From Date</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.manufactureRule.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Through Date</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.manufactureRule.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                                    </div>
                                                                                </div>




                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>



                                                </div>
                                            </div>



                                        </div>

                                    </div>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <!-- <div class="main-submit-button">
                    <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
                </div> -->
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Add Product Manufacturing Rules</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">

                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                            Add Product Manufacturing Rules
    
    
                        </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                            Update Product Manufacturing Rules
    
    
                        </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createManRule">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Product ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product ID" filter="true" formControlName="ProductID" [options]="productListArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Product ID For</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product ID For" filter="true" formControlName="ProductIDFor" [options]="productListArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Product ID In<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product ID In" filter="true" formControlName="ProductIDIn" [options]="productInIdsArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Calendar ID" formControlName="FromDate">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Product ID In Subst</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product ID In Subst" filter="true" formControlName="ProductIDInSubst" [options]="productListArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Product Feature</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product Feature" filter="true" formControlName="ProductFeature" [options]="ProductFeatureIdsArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Rule Operator</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Rule Operator" filter="true" formControlName="RuleOperator" [options]="Rule" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Quantity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity" formControlName="Quantity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Calendar ID" formControlName="ThroughDate">

                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>




                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 79%;">
                                        <button type="submit" (click)="onSubmit()" *ngIf="!this.show" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" (click)="onUpdate()" *ngIf="this.show" class="btn btn-secondary submit-btn">Update</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>