import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ThesaurusService } from './thesaurus.service';

@Injectable()
export class ThesaurusResolver implements Resolve<any> {

    constructor(
        readonly _ThesaurusService: ThesaurusService
    ) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return this._ThesaurusService.getRelationShipList().then(data => data.data)
    }
}