<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Content Detail</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                Content</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)" ><a>
                                Association</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)" ><a>
                                Roles</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="changeactiveCategory(4)"><a>
                                Purposes</a></li>
            
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)"><a>
                                Attributes</a></li>
            
                            <li [ngClass]="activeCategory==6?'active':''"  (click)="changeactiveCategory(6)"><a>
                                WebSites</a></li>
                                        
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)"><a>
                                Metadata</a></li>
                                        
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)" ><a>
                                Work Effort</a></li>
            
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategory(9)"><a>
                                Keywords</a></li>

                            
                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                        <div *ngIf="activeCategory==1">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Summary</p>
                                                    <span>
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" 
                                                        (click)="generatePDFContent()">Download</button> 
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#creatContentPopup" (click)="updateContentPatch()">Update</button>
                                                    
                                                    </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select"> 
                                                    <div *ngIf="EditContentByIdAll" class="color-black container">
                                                        <div class="row">
                                                            <div class="col-lg-6"> 
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Content ID	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditContentByIdAll.contentId ? this.EditContentByIdAll.contentId:"Null"}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Content Type ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditContentByIdAll.contentTypeId ? this.EditContentByIdAll.contentTypeId:"Null"}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Owner Content ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{ this.EditContentByIdAll.ownerContentId ? this.EditContentByIdAll.ownerContentId:"Null"}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Decorator Content ID	
                                                                        </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditContentByIdAll.decoratorContentId ? this.EditContentByIdAll.decoratorContentId:"Null"}}

                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Instance Of Content ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditContentByIdAll.instanceOfContentId ? this.EditContentByIdAll.instanceOfContentId:"Null"}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Data Resource ID	
                                                                        </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditContentByIdAll.dataResourceId ? this.EditContentByIdAll.dataResourceId:"Null"}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Template Data Resource ID	
                                                                        </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditContentByIdAll.contentTypeId ? this.EditContentByIdAll.contentTypeId:"Null"}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Data Source ID		</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditContentByIdAll.templateDataResourceId ? this.EditContentByIdAll.templateDataResourceId:"Null"}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Status ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{ this.EditContentByIdAll.statusId ? this.EditContentByIdAll.statusId:"Null"}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Privilege Enum ID	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{ this.EditContentByIdAll.privilegeEnumId ? this.EditContentByIdAll.privilegeEnumId:"Null"}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Service Name</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{ this.EditContentByIdAll.serviceName ? this.EditContentByIdAll.serviceName:"Null"}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Custom Method ID	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{ this.EditContentByIdAll.customMethodId ? this.EditContentByIdAll.customMethodId:"Null"}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Content Name</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{ this.EditContentByIdAll.contentName ? this.EditContentByIdAll.contentName:"Null"}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Description	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{ this.EditContentByIdAll.description ? this.EditContentByIdAll.description:"Null"}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Locale String	
                                                                        </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{ this.EditContentByIdAll.localeString ? this.EditContentByIdAll.localeString:"Null"}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Mime Type ID		</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{EditContentByIdAll.mimeTypeId?EditContentByIdAll.mimeTypeId:"Null"}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Character Set ID		</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{EditContentByIdAll.characterSetId?EditContentByIdAll.characterSetId:"Null"}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Child Leaf Count		</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{EditContentByIdAll.childLeafCount?EditContentByIdAll.childLeafCount:"Null"}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Child Branch Count		</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{EditContentByIdAll.childBranchCount?EditContentByIdAll.childBranchCount:"Null"}}
                                                                      
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                    
                                        <div *ngIf="activeCategory==2">

                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Content Association</p>
                                                    <span>
                                                        <button type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#associationFormPopup" (click)="clickContentAssPatch()">Create New Association</button>
                                                </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                 <p-table [value]="ContentAssocById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                       
                                                                                            Content ID To
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Content Assoc Type ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Map Key
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date 
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                   Action 
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span class="account-button" (click)="referToNewTable(product.getContentAssocById.contentIdTo)">
                                                                                        {{product.getContentAssocById.contentIdTo}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.getContentAssocById.contentAssocTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.getContentAssocById.mapKey}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.getContentAssocById.fromDate | date}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.getContentAssocById.thruDate | date}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <span> 
                                                                                    <button type="submit" class="btn btn-secondary submit-btn mr-2"  data-toggle="modal" data-target="#associationFormPopup" (click)="updateContentAssocPatch(product)">
                                                                                        Update</button>
                                                                                    <button  type="submit" class="btn btn-danger buttonclass" (click)="removeContentAssoc(product)">Delete</button>
                                                                                </span>
                                                                            </td>
    

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                            <h4
                                                                                class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page 
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div>
                                            <div class="w3-card-4 classCard w-100 mt-4">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">List associations 'To'</p>
                                                   
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                 <p-table [value]="contentAssocSecondById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                       
                                                                                            Content ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Content Assoc Type ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Map Key
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date 
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                   Action 
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span class="account-button"  data-toggle="modal" data-target="#associationFormPopup" (click)="updateContentAssocBottomPatch(product)">
                                                                                        {{product.getContentAssocById.contentId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.getContentAssocById.contentAssocTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.getContentAssocById.mapKey}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.getContentAssocById.fromDate | date}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.getContentAssocById.thruDate | date}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <span>
                                                                                    <button type="submit" class="btn btn-secondary submit-btn mr-2"  data-toggle="modal" data-target="#associationFormPopup" (click)="updateContentAssocBottomPatch(product)">
                                                                                        Update</button>
                                                                                    <button  type="submit" class="btn btn-danger buttonclass" (click)="deleteContentAssocNew(product)">Delete</button>
                                                                         
                                                                                    
                                                                                </span>
                                                                            </td>
    

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                            <h4
                                                                                class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page 
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div>
                                            
                                        </div>
                    
                                        <div *ngIf="activeCategory==3">
                                            
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit For Roles </p>
                                                    <span>
                                                        <button type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#rolesPopup" (click)="getAddBtn()">Create New Roles</button>
                                                    </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                 <p-table [value]="ContentRoleById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    
                                                                                    Content ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Party ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Role Type ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th> 
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span class="account-button"   data-toggle="modal" data-target="#rolesPopup" (click)="updateContentRolePatch(product)">
                                                                                   {{product.contentId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.partyId}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.roleTypeId}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.fromDate | date}}

                                                                            </td>
                     
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.thruDate | date}}

                                                                            </td>
              
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <span>
                                                                                    <button type="submit" class="btn btn-secondary submit-btn mr-2" 
                                                                                     data-toggle="modal" data-target="#rolesPopup" (click)="updateContentRolePatch(product)">
                                                                                        Update</button>
                                                                                    <button  type="submit" class="btn btn-danger buttonclass" 
                                                                                    (click)="removeContentRole(product)">Delete</button>
                                                                         
                                                                                  
                                                                                </span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                            <h4
                                                                                class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                    
                                        <div *ngIf="activeCategory==4">
                                            
                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        Purposes
                
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"  style="margin-left:80%!important;"
                                                    data-toggle="modal" data-target="#purposesPopup" (click)="getAddBtn()">Add Purposes
                                                    </button>
                                                    
                                                </div>
                                                
                                                
                
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                
                                                            <div class="card own-account-table">
                                                                <p-table [value]="ContentPurposeById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                
                                                                                    Content ID	
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Content Purpose Type ID		
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Sequence Num		
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            
                
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button"
                                                                                    data-toggle="modal" data-target="#purposesPopup" (click)="updateContentPurposepatch(product)" >
                                                                                    {{product.contentId}}
                                                                                    </span>
                
                                                                                </div>
                                                                            </td>
                
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.contentPurposeTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.sequenceNum}}
                                                                            </td>
                                                                            
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <span>
                                                                                    <button type="submit" class="btn btn-secondary submit-btn mr-2"  data-toggle="modal" data-target="#purposesPopup" (click)="updateContentPurposepatch(product)">
                                                                                        Update</button>
                                                                                    <button  type="submit" class="btn btn-danger buttonclass" (click)="removeContentPurpose(product)">Delete</button>
        
                                                                                    
                                                                                </span>

                
                                                                            </td>
                
                                                                        </tr>
                                                                    </ng-template>
                
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                            <h4
                                                                                class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                
                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div>
                                                                            
                                                                                
                                            
                                        </div>

                                        <div *ngIf="activeCategory==5">
                                            
                                            <div class="create-new-leade">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="bg-white color-grey w-100 ">
                                                                <div class="">
                                                                    <div class="row">
                                                                        <form class="w-100">
                                                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                        Attributes
                                                
                                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"  style="margin-left:80%!important;"
                                                                                    data-toggle="modal" data-target="#addAnAttributePopup" (click)="getAddBtn()">Add an attribute

                                                                                    </button>
                                                                                   
                                                                                </div>
                                                                               
                                                                                
                                                
                                                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">
                                                
                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="ContentAttributeById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                
                                                                                                                    Content ID	
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Attr Name		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Attr Value		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Attr Description		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Action		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            
                                                
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                                                                                    <span class="account-button"
                                                                                                                    data-toggle="modal" data-target="#addAnAttributePopup" (click)="updateContentAttributePatch(product)"   >
                                                                                                                  {{product.contentId}}
                                                                                                                    </span>
                                                
                                                                                                                </div>
                                                                                                            </td>
                                                
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.attrName}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.attrValue}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.attrDescription}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                <span>
                                                                                                                    <button type="submit" class="btn btn-secondary submit-btn mr-2"  data-toggle="modal" data-target="#addAnAttributePopup" (click)="updateContentAttributePatch(product)">
                                                                                                                        Update</button>
                                                                                                                    <button  type="submit" class="btn btn-danger buttonclass" (click)="removeContentAttribute(product)">Delete</button>
                                        
                                                                                                                    <!-- <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" data-toggle="modal" data-target="#addAnAttributePopup" (click)="updateContentAttributePatch(product)">Update</small>
                                                                                                                    <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="removeContentAttribute(product)">Delete</small> -->
                                                                                                                    
                                                                                                                </span>
                                                                                                               
                
                                                
                                                                                                            </td>
                                                
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No Record
                                                                                                                Found</h4>
                                                                                                        </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
                                                
                                                                                                <p class="paginate_data">
                                                                                                    View per page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>  
                                                                            </div>
                                                                            
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    
                                            </div> 
                                            
                                                
                                                
                                            
                                        </div>

                                        <div *ngIf="activeCategory==6">
                                            
                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        WebSites
                
                                                    <!-- <button type="submit" class="btn btn-secondary submit-btn ml-2"  style="margin-left:80%!important;"
                                                    data-toggle="modal" data-target="#">Add an WebSites

                                                    </button> -->
                                                   
                                                </div>
                                               
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                
                                                            <div class="card own-account-table">
                                                                <p-table [value]="ListWebSiteById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                
                                                                                    ID	
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Name		
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Http Host	
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Web Site Content Type ID	
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date	
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date	
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            
                                                                            
                
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button"
                                                                                 (click)="referToWebsite(product.dataWeb[0].webSiteId)">
                                                                                  {{product.dataWeb[0].webSiteId}}
                                                                                    </span>
                
                                                                                </div>
                                                                            </td>
                
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.dataWeb[0].siteName}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.dataWeb[0].httpsHost}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.data.webSiteContentTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.data.fromDate|date:'yyyy-MM-dd'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.data.thruDate|date:'yyyy-MM-dd'}}
                                                                            </td>
                                                                            
                
                                                                        </tr>
                                                                    </ng-template>
                
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                            <h4
                                                                                class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                
                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div> 
                                                                                
                                            
                                        </div>

                                        <div *ngIf="activeCategory==7">
                                            
                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    MetaData

                
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"  style="margin-left:80%!important;"
                                                    data-toggle="modal" data-target="#addMetaDataPopup" (click)="getAddBtn()">Add a MetaData


                                                    </button>
                                                   
                                                </div>
                                               
                                                
                
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                
                                                            <div class="card own-account-table">
                                                                <p-table [value]="ContentMetaDataById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                
                                                                                    Content ID	
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Meta Data Predicate ID			
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Meta Data Value				
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Data Source ID			
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Update		
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            
                
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button"
                                                                                    data-toggle="modal" data-target="#addMetaDataPopup" (click)="updateContentMetaDatapatch(product)"          >
                                                                                  {{product.contentId}}
                                                                                    </span>
                
                                                                                </div>
                                                                            </td>
                
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.metaDataPredicateId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.metaDataValue}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.dataSourceId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <span>
                                                                                    
                                                                                    <button type="submit" class="btn btn-secondary submit-btn mr-2"  data-toggle="modal" data-target="#addMetaDataPopup" (click)="updateContentMetaDatapatch(product)">
                                                                                        Update</button>
                                                                                    <button  type="submit" class="btn btn-danger buttonclass" (click)="removeContentMetaData(product)">Delete</button>
                                 
                                                                                </span>
                                                                            </td>
                
                                                                        </tr>
                                                                    </ng-template>
                
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                            <h4
                                                                                class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                
                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div>
                                                                                
                                                                                
                                            
                                        </div>

                                        <div *ngIf="activeCategory==8">
                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    Work Effort


                
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"  style="margin-left:79%!important;"
                                                    data-toggle="modal" data-target="#addWorkEffortPopup" (click)="getAddBtn()">Add Work Effort



                                                    </button>
                                                   
                                                </div>
                                               
                                                
                
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                
                                                            <div class="card own-account-table">
                                                                <p-table [value]="WorkEffortContentById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                
                                                                                    Work Effort ID	

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Work Effort Content Type ID				
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date				
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date				
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Update		
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            
                
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button"
                                                                                 (click)="referToworkEffort(product.workEffortId)">
                                                                                  {{product.workEffortId}}
                                                                                    </span>
                
                                                                                </div>
                                                                            </td>
                
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.workEffortContentTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.fromDate | date}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.thruDate | date}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <span>
                                                                                    <button type="submit" class="btn btn-secondary submit-btn mr-2"  data-toggle="modal" data-target="#addWorkEffortPopup" (click)="updateWorkEffortContentNewPatch(product)">
                                                                                        Update</button>
                                                                                    <button  type="submit" class="btn btn-danger buttonclass" (click)="deleteWorkEffortContentNew(product)">Delete</button>
                                 
                                                                                    <!-- <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" data-toggle="modal" data-target="#addWorkEffortPopup" (click)="updateWorkEffortContentNewPatch(product)">Update</small>
                                                                                    <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="deleteWorkEffortContentNew(product)">Delete</small>  -->
                                                                                </span>

                
                                                                            </td>
                
                                                                        </tr>
                                                                    </ng-template>
                
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                            <h4
                                                                                class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                
                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div>
                                            
                                                                                
                                            
                                        </div>

                                        <div *ngIf="activeCategory==9">
                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    Keyword



                
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"  style="margin-left:77%!important;"
                                                    data-toggle="modal" data-target="#addContentKeywordPopup">Add Content Keyword




                                                    </button>
                                                   
                                                </div>
                                               
                                                
                
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                
                                                            <div class="card own-account-table">
                                                                <p-table [value]="ContentKeywordById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                
                                                                                    Keyword		

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Relevancy Weight					
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                
                                                                            
                                                                            
                
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    
                                                                                    <span class=""
                                                                                 >
                                                                                  {{product.keyword}}
                                                                                    </span>
                
                                                                                </div>
                                                                            </td>
                
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.relevancyWeight}}
                                                                            </td>
                                                                            
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <span>
                                                                                    <button  type="submit" class="btn btn-danger buttonclass" (click)="deleteContentKeyword(product)">Delete</button>
                                                                                    <!-- <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" data-toggle="modal"
                                                                                     data-target="#addContentKeywordPopup" >Update</small> -->
                                                                                  
                                                                                </span>

                
                                                                            </td>
                
                                                                        </tr>
                                                                    </ng-template>
                
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                            <h4
                                                                                class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                
                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div>      
                                            
                                                                               
                                            
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 
 <div class="modal fade" id="creatContentPopup" tabindex="-1" role="dialog" aria-labelledby="creatContentPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Content</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Content</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createContentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Content ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Type ID	</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="contentTypeId" 
                                                        placeholder="Choose Content Type ID" [options]="CalatalogContentTypeIdArray">

                                                        </p-dropdown>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Owner Content ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="ownerContentID" 
                                                        placeholder="Choose Owner Content ID" [options]="CatalogContentIdArray">

                                                        </p-dropdown>
                                                        
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Decorator Content ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="decoratorContentID" 
                                                        placeholder="Choose Decorator Content ID" [options]="CatalogContentIdArray">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Instance Of Content ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="instanceContentID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Instance Of Content ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Resource ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="dataResourceId" 
                                                        placeholder="Choose Data Resource ID" [options]="DataResourceIdArray">
                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Template Data Resource ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="templateDataResourceID" 
                                                        placeholder="Choose Template Data Resource ID	
                                                        " [options]="DataResourceIdArray">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Source ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="dataSourceID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Data Source ID">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Privilege Enum ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="privilegeEnumID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Privilege Enum ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Service Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="serviceName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Service Name">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Custom Method ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="customMethodID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Custom Method ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content Name">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Description	</label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Description">
                                           
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Locale String	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="localeString" [options]="localStringArray"
                                                        placeholder="Select Locale String	
                                                        ">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Mime Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="mimeTypeID" 
                                                        placeholder="Select Mime Type ID" [options]="CalatalogMimeTypeIdArray">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Character Set ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="characterSetID" 
                                                        placeholder="Select Character Set ID" [options]="CharacterSetIdArray">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Child Leaf Count</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="childLeafCount" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Child Leaf Count">
                                           
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Child Branch Count</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="childBranchCount" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Child Branch Count">
                                           
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="createdDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" >
                                           
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Created By User	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="createdByUser" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Created By User">
                                           
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Last Modified Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="lastModifiedDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" >
                                           
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Last Modified By User</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="lastModifiedByUser" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Last Modified By User">
                                                        
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="updateContent()">Update</button>
                                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="purposesPopup" tabindex="-1" role="dialog" aria-labelledby="purposesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="this.show" class="color-black pl-1">Add a Purpose

                </span>
                <span *ngIf="!this.show" class="color-black pl-1">Update a Purpose

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="this.show">
                                    <a>Add a Purpose

                                    </a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>Update a Purpose

                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPurposeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Content ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="contentID" 
                                                        placeholder="Select Content ID" [options]="CatalogContentIdArray" [readonly]="readOnlyCode">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Purpose Type ID	</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="contentPurposeTypeID" 
                                                        placeholder="Select Content Purpose Type ID" [options]="ContentPurposeTypeArray" [readonly]="readOnlyCode">

                                                        </p-dropdown>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Sequence Num</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="sequenceNum" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                               
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            

                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn" (click)="addContentPurpose()">Create</button>
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="updateContentPurpose()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="rolesPopup" tabindex="-1" role="dialog" aria-labelledby="rolesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="this.show" class="color-black pl-1">Add a Role

                </span>
                <span *ngIf="!this.show" class="color-black pl-1">Update a Role

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="this.show">
                                    <a>Add a Role

                                    </a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>Update a Role

                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="rolesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">
                                                            Content ID<span style="color: red;">*</span></label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <p-dropdown filter="true"  optionlabel="label"
                                                        formControlName="contentID" placeholder="Select Content ID" 
                                                        [options]="CatalogContentIdArray" [readonly]="readOnlyCode">
                                                    </p-dropdown>
                                                    </div>
                                                    
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Party ID<span style="color: red;">*</span>	
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <p-dropdown filter="true"  optionlabel="label"
                                                        formControlName="partyID" placeholder="Select Party ID" 
                                                        [options]="partyIdArray" [readonly]="readOnlyCode">
                                                    </p-dropdown>
                                                    </div>
                            
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Role Type ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Select Role Type ID"
                                                            optionlabel="label"  formControlName="roleTypeID" 
                                                            [options]="ContentRoleTypeIdArray"
                                                            [readonly]="readOnlyCode">
                                                    </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" 
                                                        formControlName="fromDate" [readonly]="readOnlyCode">
                                                    </div>
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" 
                                                        formControlName="throughDate">
                                                    </div>
                                                    
                            
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn" (click)="addContentRole()">Create</button>
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="updateContentRole()">Update</button>
                                            </div>
                                            
                                            
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addAnAttributePopup" tabindex="-1" role="dialog" aria-labelledby="addAnAttributePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="this.show" class="color-black pl-1">Add an attribute
                </span>
                <span *ngIf="!this.show" class="color-black pl-1">Update an attribute
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="this.show">
                                    <a>Add an attribute


                                    </a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>Update an attribute


                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAttributeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Content ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="contentID" 
                                                        placeholder="Select Content ID" [options]="CatalogContentIdArray" [readonly]="readOnlyCode">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Attr Name<span style="color:red ;">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="attrName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Attr Name" [readonly]="readOnlyCode">
                                               
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Attr Value	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="attrValue" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Attr Value" [readonly]="readOnlyCode">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Attr Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="attrDescription" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Attr Description">
                                               
                                                    </div>


                                                    
                                                </div>
                                            </div>

                                            

                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn" (click)="addContentAttribute()">Create</button>
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="updateContentAttribute()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addMetaDataPopup" tabindex="-1" role="dialog" aria-labelledby="addMetaDataPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="this.show" class="color-black pl-1">Add a MetaData
                </span>
                <span *ngIf="!this.show" class="color-black pl-1">Update a MetaData
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="this.show">
                                    <a>Add a MetaData



                                    </a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>Update a MetaData



                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addMetaDataForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Content ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="contentID" 
                                                        placeholder="Select Content ID" [options]="CatalogContentIdArray" [readonly]="readOnlyCode">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Meta Data Predicate ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="metaDataPredicateID" 
                                                        placeholder="Select Meta Data Predicate ID" [options]="MetaDataPredicateIDNewArray" [readonly]="readOnlyCode">
                                                    </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Meta Data Value</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="metaDataValue" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Meta Data Value">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Data Source ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="dataSourceID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Data Source ID">
                                               
                                                    </div>


                                                    
                                                </div>
                                            </div>

                                            

                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn" (click)="addContentMetaData()">Create</button>
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="updateContentMetaData()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addWorkEffortPopup" tabindex="-1" role="dialog" aria-labelledby="addMetaDataPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="this.show" class="color-black pl-1">Add Work Effort
                </span>
                <span *ngIf="!this.show" class="color-black pl-1">Update Work Effort
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="this.show">
                                    <a>Add Work Effort
                                    </a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>Update Work Effort
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addWorkEffortForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Work Effort ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        
                                                            <p-dropdown filter="true" optionlabel="label" 
                                                            formControlName="workEffortID" 
                                                            placeholder="Select Work Effort ID" [options]="WorkEffortIdArray" [readonly]="readOnlyCode">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Work Effort Content Type ID	</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="workEffortContentTypeID" 
                                                        placeholder="Select Work Effort Content Type ID" [options]="contentTypeIdArray" [readonly]="readOnlyCode">
                                                    </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="fromDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp"  [readonly]="readOnlyCode">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="throughDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                               
                                                    </div>


                                                    
                                                </div>
                                            </div>

                                            

                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createWorkEffortContentNew()">Create</button>
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="updateWorkEffortContentNew()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addContentKeywordPopup" tabindex="-1" role="dialog" aria-labelledby="addContentKeywordPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Content Keyword
	




                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton9>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Content Keyword
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addContentKeywordForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Keyword<span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="keyword" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Keyword">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Weight</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="weight" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Weight">
                                               
                                                    
                                                    </div>
                                                </div>
                                            </div>

                                            

                                            

                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createContentKeyword()">Create</button>
           
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="associationFormPopup" tabindex="-1" role="dialog" aria-labelledby="addContentKeywordPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="this.show" class="color-black pl-1">Add Association</span>
                <span *ngIf="!this.show" class="color-black pl-1">Update Association</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="this.show">
                                    <a>Add Association
                                    </a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>Update Association
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="associationForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Content ID	</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Content ID"
                                                        formControlName="contentID" [readonly]="readOnlyCodeNew">
                                                    </div>
                                                
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Content ID To<span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <p-dropdown filter="true" 
                                                        formControlName="contentIDTo"  
                                                        optionlabel="label" 
                                                        placeholder="Select Content ID To" 
                                                        [options]="CatalogContentIdArray" [readonly]="readOnlyCode">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput">

                                                        <label for="accountLimit">Content Assoc Type ID	</label>
                                                        <span style="color:red">*</span>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Select Content Assoc Type ID"
                                                            optionlabel="label"  formControlName="contentAssocTypeID" 
                                                            [options]="ContentAssocTypeIdArray" [readonly]="readOnlyCode">
                                                    </p-dropdown>
                                                    <small class="text-danger" *ngIf="!!associationForm.controls.contentAssocTypeID.invalid && !!associationForm.controls.contentAssocTypeID.touched">Required</small>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" 
                                                        formControlName="fromDate" [readonly]="readOnlyCode">
                                                    </div>

                                                </div>
                                            </div> 
                            
                                               
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">Content Assoc Predicate ID	</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Content Assoc Predicate ID"
                                                        formControlName="contentAssocPredicateID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">ThruDate</label>
                                                    </div>
                                                    
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" 
                                                        formControlName="thruDate">
                                                    </div>
                                                   
                            
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">Sequence Num</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Sequence Num"
                                                        formControlName="sequenceNum">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Map Key</label>
                                                    </div>
                                                    
                                                    
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Map Key"
                                                        formControlName="mapKey">
                                                    </div>
                            
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">Upper Coordinate</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Upper Coordinate"
                                                        formControlName="upperCoordinate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Left Coordinate	</label>
                                                    </div>
                                                    
                                                    
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Left Coordinate	"
                                                        formControlName="leftCoordinate">
                                                    </div>
                            
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">Meta Data Predicate ID	</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder=" Select Meta Data Predicate ID"
                                                        optionlabel="label"  formControlName="metaDataPredicateID" [options]="MetaDataPredicateIDArray">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Data Source ID	</label>
                                                    </div>
                                                    
                                                    
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Enter Data Source ID"
                                                        optionlabel="label"  formControlName="dataSourceID" [options]="dataResourceIdAllArray">
                                                        </p-dropdown>
                                                        
                                                    </div>
                                                
                            
                                                </div>
                                            </div>
                                            <div *ngIf="!this.addOnFeild" class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Date"
                                                        formControlName="createdDate">
                                                      
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Created By User</label>
                                                    </div>
                                                    
                                                    
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Created By User"
                                                        formControlName="createdByUser">
                                                    </div>
                                                
                            
                                                </div>
                                            </div>
                                            <div *ngIf="!this.addOnFeild" class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">lastModifiedDate</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Last Modified Date"
                                                        formControlName="lastModifiedDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Last Modified By User</label>
                                                    </div>
                                                    
                                                    
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Last Modified By User"
                                                        formControlName="lastModifiedByUser">
                                                    </div>
                                                
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left:-25%;">
                                                <button  type="submit" class="btn btn-secondary submit-btn" *ngIf="this.show" (click)="createContentAssoc()">Add</button>
                                                <button  type="submit" class="btn btn-secondary submit-btn" *ngIf="!this.show && this.addOnFeild" (click)="updateContentAssoc()">Update</button>
                                                <button  type="submit" class="btn btn-secondary submit-btn" *ngIf="!this.show && !this.addOnFeild" (click)="updateContentAssocBottom()">Update</button>


                                   
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </form>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 <ngx-spinner></ngx-spinner> 

