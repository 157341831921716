import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { SalesTaxGroupsComponent } from '../Financial/sales-tax-groups/sales-tax-groups.component';

@Component({
  selector: 'app-terminology',
  templateUrl: './terminology.component.html',
  styleUrls: ['./terminology.component.css']
})
export class TerminologyComponent implements OnInit {
  
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;

  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;

  terminologyworkZonesForm:FormGroup;
  communities:string;
  property:string;
  serviceAreas:string;
  workZone:string;

  terminologyforSiteForm:FormGroup;
  jobs:string;
  sales:string;
  stores:string;
  section:string;

  terminologyforCustomersForm:FormGroup;
  clients:string;
  guests:string;
  customers:string;
  tenants:string;
  residents:string;

  terminologyWorkOrdersForm:FormGroup;
  serviceOrders:string;
  serviceTickets:string;
  workOrders:string;

  terminologyRepairCategoriesForm:FormGroup;
  operationalCategories:string;
  repairCategories:string;
  rootCauseCategories:string;

  terminologyRepairCodesForm:FormGroup;
  operationCodes:string;
  repairCodes:string;
  rootCauseCodes:string;

  terminologyServicesForm:FormGroup;
  complaint:string;
  customerInstructions:string;
  problem:string;
  service:string;
  symptom:string;
  task:string;

  terminologySkillsForm:FormGroup;
  exprertise:string;
  skills:string;
  specialities:string;
  trades:string;

  terminologyDispatchersForm:FormGroup
  csr:string;
  customerService:string;
  serviceRepresentatives:string;
  dispatchers:string;

  terminologyServiceAddressForm:FormGroup;
  address:string;
  driveToAddress:string;
  jobLocation:string;
  serviceAddress:string;
  siteAddress:string;

  terminologyCategoryForm:FormGroup;
  assetEquipment:string;
  category:string;
  categoryEquipment:string;

  terminologyForTechsForm:FormGroup;
  engineers:string;
  serviceTechs:string;
  workMen:string;

  terminologyServiceTeamsForm:FormGroup;
  maintenanceTeams:string;
  workTeams:string;


  terminologyDispatchCentersForm:FormGroup;
  branches:string;
  dispatchCenters:string;
  managementOffices:string;
  serviceCenters:string;

  terminologyServiceAgreementsForm:FormGroup;
  energyServiceAgrrements:string;
  maintenanceAgreements:string;
  plannedMaintenanceAgreements:string;
  preventiveMaintenanceAgreements:string;
  serviceAgreements:string;
constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 
  {
    this.terminologyforCustomersForm= this._FormBuilder.group({
      guests:"",
      customers:"",
      tenants:"",
      residents:"",
    });

    this.terminologyforSiteForm= this._FormBuilder.group({
      jobs:"",
      sales:"",
      stores:"",
      selection:"",
    });

    this.terminologyworkZonesForm=this._FormBuilder.group({
      communities:"",
      property:"",
      serviceAreas:"",
      workZone:"",
    });

    this.terminologyWorkOrdersForm=this._FormBuilder.group({
      serviceOrders:"",
      serviceTickets:"",
      workOrders:"",
    });

    this.terminologyRepairCategoriesForm=this._FormBuilder.group({
      serviceOrders:"",
      serviceTickets:"",
      workOrders:"",
    });

    this.terminologyRepairCategoriesForm=this._FormBuilder.group({
      operationalCategories:"",
      repairCategories:"",
      rootCauseCategories:"",
     
    });

    this.terminologyRepairCodesForm=this._FormBuilder.group({
      
     
    });

    this.terminologySkillsForm=this._FormBuilder.group({
      exprertise:"",
      skills:"",
      specialities:"",
      trades:""
     
    });

    this.terminologyDispatchersForm=this._FormBuilder.group({
      csr:"",
      customerService:"",
      serviceRepresentatives:"",
      dispatchers:"",
     
    });

    this.terminologyServiceAddressForm=this._FormBuilder.group({
      address:"",
      driveToAddress:"",
      jobLocation:"",
      serviceAddress:"",
      siteAddress:"",
     
    });

    this.terminologyCategoryForm=this._FormBuilder.group({
      assetEquipment:"",
      category:"",
      categoryEquipment:"",
     
    });

    this.terminologyForTechsForm=this._FormBuilder.group({
      engineers:"",
      serviceTechs:"",
      workMen:"",
     
    });

    this.terminologyServiceTeamsForm=this._FormBuilder.group({
      maintenanceTeams:"",
      serviceTechs:"",
      workTeams:"",
     
    });

    this.terminologyServiceAgreementsForm=this._FormBuilder.group({
      energyServiceAgrrements:"",
      maintenanceAgreements:"",
      plannedMaintenanceAgreements:"",
      preventiveMaintenanceAgreements:"",
      serviceAgreements:"",
     
    });

    


  }

  ngOnInit(): void {
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  

  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
