<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"></span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        
            </div>

            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                        <li [ngClass]="activeCategory==1?'active':''" (click)="findPage()"><a>
                                Find </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)"><a>
                                Overview
                            </a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'"><a>
                                Update</a></li>
                        <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'"><a>
                                Notes</a></li>
                        <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'||this.StatusDescription=='STS_ON_HOLD'||this.StatusDescription=='STS_CANCELLED'"><a>
                                Member</a></li>


                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 pt-0 mt-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div *ngIf="activeCategory==2">
                                                    <div class="container-fluid" style="width: 103% !important;
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;
                                                    padding: 10px !important;">


                                                        <div *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'">
                                                            <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'||this.StatusDescription=='STS_CANCELLED'" class="btn btn-secondary submit-btn ">
                                                                New
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusHold()" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'" class="btn btn-secondary submit-btn ml-2">
                                                                On-hold

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusComplete()" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'" class="btn btn-secondary submit-btn ml-2">
                                                                Complete

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusCancel()" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'" class="btn btn-secondary submit-btn ml-2">
                                                                Cancel
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_ON_HOLD'" class="btn btn-secondary submit-btn ml-2">
                                                                Release
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_COMPLETED'" class="btn btn-secondary submit-btn ml-2">
                                                                re-open
                                                            </button>
                                                        </div>

                                                        <div *ngIf="this.StatusDescription=='STS_ON_HOLD'">
                                                            <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" *ngIf="showStatus" class="btn btn-secondary submit-btn ">
                                                                New
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusHold()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                On-hold

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusComplete()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                Complete

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusCancel()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                Cancel
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_ON_HOLD'" class="btn btn-secondary submit-btn ml-2">
                                                                Release
                                                            </button>
                                                        </div>

                                                        <div *ngIf="this.StatusDescription=='STS_COMPLETED'">
                                                            <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ">
                                                                New
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusHold()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                On-hold

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusComplete()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                Complete

                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusCancel()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                Cancel
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_COMPLETED'" class="btn btn-secondary submit-btn ml-2">
                                                                re-open
                                                            </button>
                                                        </div>

                                                        <div *ngIf="this.StatusDescription=='STS_CANCELLED'">
                                                            <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" class="btn btn-secondary submit-btn ">
                                                                New
                                                            </button>



                                                        </div>

                                                    </div>

                                                    <div style="color: #0d3769;font-weight: bold;font-size: 15px;">
                                                        Current Task : <span>{{this.workEffortName + "["
                                                            +this.workEffortId + "]"}}</span>


                                                    </div>

                                                    <div class="divA">

                                                        <br>
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Task information
                                                                </h4>
                                                            </div>

                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Task Name

                                                                                    </div>
                                                                                    <div class="color-grey col-lg-6  font-weight-bold">
                                                                                        {{this.TaskOverview.tasks.workEffortName}}

                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Task Type

                                                                                    </div>
                                                                                    <div class="color-grey  col-lg-6 ">
                                                                                        {{this.TaskOverview.tasks.workEffortTypeId}}
                                                                                    </div>


                                                                                </div>

                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Product Backlog

                                                                                    </div>
                                                                                    <div class="color-grey    col-lg-6">
                                                                                        {{this.TaskOverview.tasks.workEffortName + "[" + this.TaskOverview.tasks.workEffortParentId + "]"}}
                                                                                    </div>
                                                                                </div>

                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Sprint

                                                                                    </div>
                                                                                    <div class="color-grey  col-lg-6 ">
                                                                                        {{this.TaskOverview.tasks.workEffortName + "[" +this.TaskOverview.tasks.workEffortId + "]"}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Project

                                                                                    </div>
                                                                                    <div class="color-grey  col-lg-6 ">
                                                                                        {{this.TaskOverview.custid[0].description + "[" + this.TaskOverview.tasks.custRequestId + "]"}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Product

                                                                                    </div>
                                                                                    <div class="color-grey  col-lg-6 ">
                                                                                        {{this.TaskOverview.productNames.productId}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Company
                                                                                    </div>

                                                                                    <div class="color-grey col-lg-6 ">


                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Description

                                                                                    </div>

                                                                                    <div class="color-grey  col-lg-6">
                                                                                        {{this.TaskOverview.custid[0].description}}

                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Current Status ID

                                                                                    </div>
                                                                                    <div class="color-grey   col-lg-6">
                                                                                        {{this.TaskOverview.tasks.currentStatusId}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Plan Hours

                                                                                    </div>
                                                                                    <div class="color-grey   col-lg-6">

                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Actual Hours

                                                                                    </div>
                                                                                    <div class="color-grey    col-lg-6">
                                                                                        {{this.TaskOverview.ActualHours}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Assigned To

                                                                                    </div>
                                                                                    <div class="color-grey    col-lg-6">
                                                                                        {{this.TaskOverview.partidscrum.partyId}}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="w3-card-4 classCard mt-2">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling h4Margin">
                                                                        Revisions
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="" scrollHeight="150px"  [paginator]="false" [rows]="rows" 
                                                                            scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </p-checkbox>

                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Current Status
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">

                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">


                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">


                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>




                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </p-checkbox>
                                                                                                <span  class="account-button"  >
                                                                                                    {{product.SprintID}}
                                                                                                </span>

                                                                                            </div>
                                                                                        </td>

                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.Status}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.StartDate | date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                        </td>

                                                                                        <td [ngStyle]="{'width':'150px'}">

                                                                                            {{product.Enddate | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                        </td>

                                                                                        <td [ngStyle]="{'width':'150px'}">

                                                                                            {{product.SprintLengthWeeks}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">
                                                                                                    No
                                                                                                    Record
                                                                                                    Found
                                                                                                </h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <!-- <p class="paginate_data">
                                                                                View per page </p> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="divB mt-1">
                                                        <div class=" bg-white color-grey">
                                                            <div class=" mt-2">
                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        <form class="w-100">
                                                                            <div class="w3-card-4 classCard mt-2">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs">
                                                                                        <h4 class=" common-styling h4Margin">
                                                                                            Task Attachements
                                                                                        </h4>

                                                                                    </div>
                                                                                </header>
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">

                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="TaskAttachments" [paginator]="false" [rows]="3" 
                                                                                                scrollHeight="150px"  scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </p-checkbox>
                                                                                                                    Content ID


                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Mime Type ID

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    From Date

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </p-checkbox>
                                                                                                                    <span  class="account-button"  >
                                                                                                                        {{product.taskAttachment.contentId}}</span>

                                                                                                                </div>
                                                                                                            </td>

                                                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                                                <span>{{product.mime[0].mimeTypeId}}</span>

                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                                                <span> {{product.taskAttachment.fromDate
                                                                                                                    |date:'yyyy-MM-dd hh:mm:ss'}}</span>


                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
<!-- 
                                                                                                <p class="paginate_data">
                                                                                                    View per page </p> -->
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="w3-card-4 classCard mt-2">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs">
                                                                                        <h4 class=" common-styling h4Margin">
                                                                                            Task Note List


                                                                                        </h4>

                                                                                    </div>
                                                                                </header>
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">

                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="NoteOverviewList" [paginator]="false" [rows]="3" 
                                                                                                scrollHeight="150px"  scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    Note Name

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'120px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Note Info
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Note Date Time
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Note Party
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>


                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    <span  class="account-button"  >
                                                                                                                        {{product.note[0].noteName}}</span>

                                                                                                                </div>
                                                                                                            </td>

                                                                                                            <td [ngStyle]="{'width':'120px'}">
                                                                                                                {{product.note[0].noteInfo}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                                {{product.note[0].noteDateTime |date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                                {{product.note[0].noteParty}}
                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                                <!-- <p class="paginate_data">
                                                                                                    View per page </p> -->
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="w3-card-4 classCard mt-2">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs">
                                                                                        <h4 class=" common-styling h4Margin">
                                                                                            Task Member
                                                                                        </h4>

                                                                                    </div>
                                                                                </header>
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">

                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="ProjectMemberList" [paginator]="false" [rows]="3" 
                                                                                                scrollHeight="150px"  scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </p-checkbox>

                                                                                                                    Member Name

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'250px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Role Type ID
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    From Date
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Through Date

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>


                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </p-checkbox>
                                                                                                                    <span  class="account-button"  >
                                                                                                                        {{product.partyids.partyId}}</span>

                                                                                                                </div>
                                                                                                            </td>

                                                                                                            <td [ngStyle]="{'width':'250px'}">
                                                                                                                {{product.partyids.roleTypeId}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.partyids.fromDate |date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.partyids.thruDate |date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                                            </td>


                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
<!-- 
                                                                                                <p class="paginate_data">
                                                                                                    View per page </p> -->
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <br>
                                                                        </form>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <br>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==3">

                                                    <div class=" bg-white color-grey">
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div class="container-fluid" style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;
                                                                        padding: 10px !important;">


                                                                            <div *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'">
                                                                                <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'||this.StatusDescription=='STS_CANCELLED'" class="btn btn-secondary submit-btn ">
                                                                                    New
                                                                                </button>

                                                                                <button type="submit" (click)="setTaskStatusHold()" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'" class="btn btn-secondary submit-btn ml-2">
                                                                                    On-hold
                    
                                                                                </button>
                                                                                <button type="submit" (click)="setTaskStatusComplete()" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'" class="btn btn-secondary submit-btn ml-2">
                                                                                    Complete
                    
                                                                                </button>
                                                                                <button type="submit" (click)="setTaskStatusCancel()" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'" class="btn btn-secondary submit-btn ml-2">
                                                                                    Cancel
                                                                                </button>

                                                                                <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_ON_HOLD'" class="btn btn-secondary submit-btn ml-2">
                                                                                    Release
                                                                                </button>

                                                                                <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_COMPLETED'" class="btn btn-secondary submit-btn ml-2">
                                                                                    re-open
                                                                                </button>
                                                                            </div>

                                                                            <div *ngIf="this.StatusDescription=='STS_ON_HOLD'">
                                                                                <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" *ngIf="showStatus" class="btn btn-secondary submit-btn ">
                                                                                    New
                                                                                </button>

                                                                                <button type="submit" (click)="setTaskStatusHold()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                                    On-hold
                    
                                                                                </button>
                                                                                <button type="submit" (click)="setTaskStatusComplete()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                                    Complete
                    
                                                                                </button>
                                                                                <button type="submit" (click)="setTaskStatusCancel()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                                    Cancel
                                                                                </button>

                                                                                <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_ON_HOLD'" class="btn btn-secondary submit-btn ml-2">
                                                                                    Release
                                                                                </button>
                                                                            </div>

                                                                            <div *ngIf="this.StatusDescription=='STS_COMPLETED'">
                                                                                <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ">
                                                                                    New
                                                                                </button>

                                                                                <button type="submit" (click)="setTaskStatusHold()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                                    On-hold
                    
                                                                                </button>
                                                                                <button type="submit" (click)="setTaskStatusComplete()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                                    Complete
                    
                                                                                </button>

                                                                                <button type="submit" (click)="setTaskStatusCancel()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                                    Cancel
                                                                                </button>

                                                                                <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_COMPLETED'" class="btn btn-secondary submit-btn ml-2">
                                                                                    re-open
                                                                                </button>
                                                                            </div>

                                                                            <div *ngIf="this.StatusDescription=='STS_CANCELLED'">
                                                                                <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" class="btn btn-secondary submit-btn ">
                                                                                    New
                                                                                </button>



                                                                            </div>

                                                                        </div>

                                                                        <div>
                                                                            <p style="color: #0d3769;font-weight: bold;font-size: 15px;">
                                                                                Current Task :
                                                                                <span>{{this.workEffortName + "["
                                                                                    +this.workEffortId + "]"}}</span>

                                                                            </p>
                                                                        </div>
                                                                        <br>

                                                                        <div class="w3-card-4 classCard">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass">

                                                                                    <li [ngClass]="activeCategory==2?'active':''">
                                                                                        <a>
                                                                                            Edit Task #12223
                                                                                            Information</a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>

                                                                            <div class="create-new-leade mt-2">
                                                                                <div class="container-fluid">
                                                                                    <div class="row">
                                                                                        <form class="w-100 " [formGroup]="editTaskForm">
                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">


                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Project
                                                                                                                ID
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-3">
                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="this.UpdatePageList.ProjectMembersRoles.description" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" formControlName="ProjectID" placeholder="Enter Project ID">


                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">


                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Task
                                                                                                                Id
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-3">
                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="this.workEffortId" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" formControlName="TaskId" placeholder="Enter Task Id">


                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">


                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="productId">Task
                                                                                                                Name
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-3">
                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="TaskName" placeholder="Enter Task Name">

                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>

                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">


                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Task
                                                                                                                Type
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-3">

                                                                                                            <p-dropdown filter="true" optionlabel="label" formControlName="TaskType" [options]="RoleTypeArray" placeholder="Enter Task Type">

                                                                                                            </p-dropdown>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>



                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Sprint
                                                                                                                Backlog
                                                                                                            </label>
                                                                                                        </div>

                                                                                                        <div class="col-lg-3">
                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="this.UpdatePageList.ProjectMembersRoles.workEffortName" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" formControlName="SprintBacklog" placeholder="Enter Sprint Backlog">

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">


                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Plan
                                                                                                                Hours</label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-3">
                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="this.UpdatePageList.Hours" [ngModelOptions]="{standalone:true}" formControlName="PlanHours" aria-describedby="emailHelp" placeholder="Enter Plan Hours">
                                                                                                        </div>


                                                                                                    </div>
                                                                                                </div>


                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">


                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Actual
                                                                                                                Hours</label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-3">
                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="ActualHours" aria-describedby="emailHelp" placeholder="Enter Actual Hours">
                                                                                                        </div>


                                                                                                    </div>
                                                                                                </div>


                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">


                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Description</label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-3">
                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                                                                        </div>
                                                                                                        <div class="col-lg-3">
                                                                                                            250 Chars Maximum
                                                                                                        </div>


                                                                                                    </div>
                                                                                                </div>

                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">


                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Assign
                                                                                                                To
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-3">

                                                                                                            <p-dropdown filter="true" optionlabel="label" [options]="AssignToArray" placeholder="Enter Assign To" formControlName="AssignTo">

                                                                                                            </p-dropdown>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>



                                                                                            </div>
                                                                                            <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -62%;">
                                                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="updateTask()">Update</button>

                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div *ngIf="activeCategory==4">
                                                    <div class="container-fluid" style="width: 103% !important;
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;
                                                    padding: 10px !important;">


                                                        <div *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'">
                                                            <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'||this.StatusDescription=='STS_CANCELLED'" class="btn btn-secondary submit-btn ">
                                                                New
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusHold()" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'" class="btn btn-secondary submit-btn ml-2">
                                                                On-hold

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusComplete()" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'" class="btn btn-secondary submit-btn ml-2">
                                                                Complete

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusCancel()" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'" class="btn btn-secondary submit-btn ml-2">
                                                                Cancel
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_ON_HOLD'" class="btn btn-secondary submit-btn ml-2">
                                                                Release
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_COMPLETED'" class="btn btn-secondary submit-btn ml-2">
                                                                re-open
                                                            </button>
                                                        </div>

                                                        <div *ngIf="this.StatusDescription=='STS_ON_HOLD'">
                                                            <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" *ngIf="showStatus" class="btn btn-secondary submit-btn ">
                                                                New
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusHold()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                On-hold

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusComplete()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                Complete

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusCancel()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                Cancel
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_ON_HOLD'" class="btn btn-secondary submit-btn ml-2">
                                                                Release
                                                            </button>
                                                        </div>

                                                        <div *ngIf="this.StatusDescription=='STS_COMPLETED'">
                                                            <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ">
                                                                New
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusHold()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                On-hold

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusComplete()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                Complete

                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusCancel()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                Cancel
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_COMPLETED'" class="btn btn-secondary submit-btn ml-2">
                                                                re-open
                                                            </button>
                                                        </div>

                                                        <div *ngIf="this.StatusDescription=='STS_CANCELLED'">
                                                            <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" class="btn btn-secondary submit-btn ">
                                                                New
                                                            </button>



                                                        </div>

                                                    </div>
                                                    <div>
                                                        <p style="color: #0d3769;font-weight: bold;font-size: 15px;">
                                                            Current Task : <span> {{this.workEffortName + "["
                                                                +this.workEffortId + "]"}}</span></p>
                                                    </div>
                                                    <br>
                                                    <div class=" bg-white color-grey">
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">

                                                                        <div class="w3-card-4 classCard mt-2">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                flex-wrap: wrap !important;
                                                                display: flex !important;">
                                                                                    <li><a>
                                                                                            New Task Note

                                                                                        </a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>

                                                                            <form [formGroup]="TaskNoteForm">


                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-3 form-group classInputE">
                                                                                                <label for="exampleInputEmail1">
                                                                                                    Note Name
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="email" class="form-control" id="exampleInputEmail1" formControlName="NoteName" aria-describedby="emailHelp" placeholder="Enter Note Name">
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-3 form-group classInputE">
                                                                                                <label for="exampleInputEmail1">
                                                                                                    Note Info
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="email" class="form-control" id="exampleInputEmail1" formControlName="NoteInfo" aria-describedby="emailHelp" placeholder="Enter Note Info">
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>




                                                                                </div>
                                                                                <div class="col-lg-12  main-submit-button" style="margin-left: -67%;">
                                                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="createProdBacklogNote()">Create</button>&nbsp;


                                                                                </div>
                                                                            </form>

                                                                        </div>
                                                                        <div class="w3-card-4 classCard mt-2">
                                                                            <header class="w3-container w3-blue">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling h4Margin">
                                                                                        Task Note List



                                                                                    </h4>

                                                                                </div>
                                                                            </header>
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">

                                                                                        <div class="card own-account-table">
                                                                                            <p-table [value]="NoteOverviewList"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                <ng-template pTemplate="header">
                                                                                                    <tr style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'300px'}" pSortableColumn="code">
                                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                Note Name

                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Note Info
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Note Date Time
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Note Party
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">

                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>




                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                    <tr>
                                                                                                        <td [ngStyle]="{'width':'300px'}">
                                                                                                            <div class="checkbox-align">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                <span *ngFor="let r of product.note"  class="account-button"  >
                                                                                                                    {{r.noteName}}</span>

                                                                                                            </div>
                                                                                                        </td>

                                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                                            {{product.note[0].noteInfo}}
                                                                                                        </td>
                                                                                                        <td *ngFor="let r of product.note" [ngStyle]="{'width':'180px'}">
                                                                                                            {{r.noteDateTime |date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                                        </td>
                                                                                                        <td *ngFor="let r of product.note" [ngStyle]="{'width':'180px'}">
                                                                                                            {{r.noteParty}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                                            <button (click)="deleteTaskNote(product)" type="submit" class="btn btn-secondary submit-btn ml-2">
                                                                                                                Delete
                                                                                                            </button>


                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>

                                                                                            <p class="paginate_data">
                                                                                                View per page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==5">
                                                    <div class="container-fluid" style="width: 103% !important;
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;
                                                    padding: 10px !important;">


                                                        <div *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'">
                                                            <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'||this.StatusDescription=='STS_CANCELLED'" class="btn btn-secondary submit-btn ">
                                                                New
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusHold()" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'" class="btn btn-secondary submit-btn ml-2">
                                                                On-hold

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusComplete()" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'" class="btn btn-secondary submit-btn ml-2">
                                                                Complete

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusCancel()" *ngIf="this.StatusDescription=='STS_CREATED'||this.StatusDescription=='SPJ_ACTIVE'" class="btn btn-secondary submit-btn ml-2">
                                                                Cancel
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_ON_HOLD'" class="btn btn-secondary submit-btn ml-2">
                                                                Release
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_COMPLETED'" class="btn btn-secondary submit-btn ml-2">
                                                                re-open
                                                            </button>
                                                        </div>

                                                        <div *ngIf="this.StatusDescription=='STS_ON_HOLD'">
                                                            <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" *ngIf="showStatus" class="btn btn-secondary submit-btn ">
                                                                New
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusHold()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                On-hold

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusComplete()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                Complete

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusCancel()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                Cancel
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_ON_HOLD'" class="btn btn-secondary submit-btn ml-2">
                                                                Release
                                                            </button>
                                                        </div>

                                                        <div *ngIf="this.StatusDescription=='STS_COMPLETED'">
                                                            <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ">
                                                                New
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusHold()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                On-hold

                                                            </button>
                                                            <button type="submit" (click)="setTaskStatusComplete()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                Complete

                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusCancel()" *ngIf="this.StatusDescription=='STS_CREATED'" class="btn btn-secondary submit-btn ml-2">
                                                                Cancel
                                                            </button>

                                                            <button type="submit" (click)="setTaskStatusRelease()" *ngIf="this.StatusDescription=='STS_COMPLETED'" class="btn btn-secondary submit-btn ml-2">
                                                                re-open
                                                            </button>
                                                        </div>

                                                        <div *ngIf="this.StatusDescription=='STS_CANCELLED'">
                                                            <button type="submit" data-target="#exampleModalCenter" data-toggle="modal" class="btn btn-secondary submit-btn ">
                                                                New
                                                            </button>



                                                        </div>

                                                    </div>
                                                    <div>
                                                        <p style="color: #0d3769;font-weight: bold;font-size: 15px;">
                                                            Current Task :<span>{{this.workEffortName + "["
                                                                +this.workEffortId + "]"}}</span></p>
                                                    </div>
                                                    <br>
                                                    <div class=" bg-white color-grey">
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div class="w3-card-4 classCard mt-2">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                flex-wrap: wrap !important;
                                                                display: flex !important;">
                                                                                    <li><a>Task Member
                                                                                        </a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>

                                                                            <form [formGroup]="TaskMemberForm">


                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12" *ngIf="!showMember">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-3 form-group classInputE">
                                                                                                <label for="exampleInputEmail1"> 
                                                                                                    Assign To
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">

                                                                                                <p-dropdown filter="true" [options]="AssignToArray" formControlName="AssignTo" optionlabel="label" placeholder="Enter Assign To">
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-lg-12" *ngIf="showMember">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-3 form-group classInputE">
                                                                                                <label for="exampleInputEmail1"> 
                                                                                                    Assign To
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2 ">

                                                                                                <input type="email" disabled class="form-control" id="exampleInputEmail1" formControlName="AssignToo" aria-describedby="emailHelp" placeholder="Enter Assign To">


                                                                                            </div>
                                                                                            <div class="col-lg-2"><button type="submit" *ngIf="showMember" class="btn btn-secondary submit-btn" (click)="changeAssignTo()">Change</button></div>

                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-lg-12" *ngIf="showMember">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-3 form-group classInputE">
                                                                                                <label for="exampleInputEmail1">
                                                                                                    Role Type ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">

                                                                                                <p-dropdown filter="true" [options]="GroupIdArray" formControlName="RoleTypeID" optionlabel="label" placeholder="Enter Role Type ID">
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>


                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-3 form-group classInputE">
                                                                                                <label for="exampleInputEmail1">From
                                                                                                    Date
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date" class="form-control" id="exampleInputEmail1" formControlName="FromDate" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-3 form-group classInputE">
                                                                                                <label for="exampleInputEmail1">Through
                                                                                                    Date
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date" class="form-control" id="exampleInputEmail1" formControlName="ThroughDate" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>




                                                                                </div>
                                                                                <div class="col-lg-12  main-submit-button" style="
                                                                                                           margin-left: -67%;">
                                                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="addMemberTask()">Create</button>&nbsp;


                                                                                </div>
                                                                            </form>

                                                                        </div>
                                                                        <div class="w3-card-4 classCard mt-2">
                                                                            <header class="w3-container w3-blue">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling h4Margin">
                                                                                        Task Members List
                                                                                    </h4>

                                                                                </div>
                                                                            </header>
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">

                                                                                        <div class="card own-account-table">
                                                                                            <p-table [value]="ProjectMembersList"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                <ng-template pTemplate="header">
                                                                                                    <tr style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </p-checkbox>

                                                                                                                Member Name

                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'250px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Role Type ID
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                From Date
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Through Date

                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Remove

                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>




                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                    <tr>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </p-checkbox>
                                                                                                                <span  class="account-button"  >
                                                                                                                    {{product.partyids.partyId}}</span>

                                                                                                            </div>
                                                                                                        </td>

                                                                                                        <td [ngStyle]="{'width':'250px'}">
                                                                                                            {{product.partyids.roleTypeId}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.partyids.fromDate |date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.partyids.thruDate |date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            <button type="submit" (click)="removeMemberTask()" class="btn btn-secondary submit-btn ml-2">
                                                                                                                Remove
                                                                                                            </button>


                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>

                                                                                            <p class="paginate_data">
                                                                                                View per page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                            </div>

                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Task # Information</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Edit Task # Information</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100 " [formGroup]="addNewTaskForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Project ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="ProjectIDArray" formControlName="ProjectID" placeholdder="Enter Project ID">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="productId">Task Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="TaskName" placeholder="Enter Task Name">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Task Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" formControlName="TaskType" [options]="RoleTypeArray" placeholder="Enter Task Type">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Sprint ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="SprintID" aria-describedby="emailHelp" placeholder="Enter Sprint ID">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Sprint Backlog
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="SprintBacklogListArray" placeholder="Enter Sprint Backlog" formControlName="SprintBacklog">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Plan Hours</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="PlanHours" aria-describedby="emailHelp" placeholder="Enter Plan Hours">
                                                    </div>


                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Uploaded
                                                            File</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="file" class="form-control" id="exampleInputEmail1" formControlName="Uploaded" aria-describedby="emailHelp" placeholder="Choose File">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Assign To
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="AssignToArray" placeholder="Enter Assign To" formControlName="AssignTo">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -62%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="addTask()">Create</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>