import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-gl-foreign-exchange-rate',
  templateUrl: './gl-foreign-exchange-rate.component.html',
  styleUrls: ['./gl-foreign-exchange-rate.component.css']
})
export class GlForeignExchangeRateComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  createRate: FormGroup
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  foreignExchangeRates: any;
  Company: string;
  activeCategory=7;
  show: boolean;
  exchangePurpose: any;
  exchangePurposeArray: any[]=[];
  uom: any;
  uomArray: any[]=[];
  actualfromdate: string;
  actualTodate: string;
  constructor(  
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly accountsService: AccountsService,
    readonly _FormBuilder: FormBuilder,
   ) {
    this.createRate = this._FormBuilder.group({
      conversionFactor: ['',[Validators.required]],
      fromDate:[''],
      organizationPartyId: [''],
      purposeEnumId:[''],
      thruDate: [''],
      uomId: ['',[Validators.required]],
      uomIdTo: ['',[Validators.required]],

    });
    }
    getCost=[{
      value:'GEN_COST_CALC'
    }]
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activeCategory=7;
    this.getForeignExchangeRates();

    this.show = false;
    this.getInitialDataCost();
    this.getRatesInitialData();
  }

  getRatesInitialData() {
    this.spinner.show();
    this.accountsService.getRatesInitialData().subscribe(res => {
       this.exchangePurpose = res.data.exchangePurpose;
       this.spinner.hide();
        for (const value of this.exchangePurpose) {
          this.exchangePurposeArray.push({
            label: value.description,
            value: value.enumId
          })
        } 



    })
   
  }
  getInitialDataCost() {
    this.spinner.show();
    this.accountsService.getInitialDataCost().subscribe(res => {

      this.uom = res.data.uom;
      this.spinner.hide();
      for (const value of this.uom) {
        this.uomArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
    
  }
  onSubmit() {
    this.spinner.show();
    const fromActualdate = this.createRate.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const toActualdate = this.createRate.get('thruDate').value;
    this.actualTodate = this.datePipe.transform(toActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "conversionFactor": this.createRate.value.conversionFactor,
      "fromDate": this.actualfromdate,
      "organizationPartyId": "Company",
      "purposeEnumId": this.createRate.value.purposeEnumId,
      "thruDate": this.actualTodate ,
      "uomId": this.createRate.value.uomId,
      "uomIdTo":this.createRate.value.uomIdTo,
    }
    this.accountsService.updateForeignExchangeRates(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.createRate.reset();
        this.getForeignExchangeRates();
        this.closebutton.nativeElement.click();
        // this.router.navigate(["financial/gl-foreign-exchange-rate"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  chartAccount(){
    this.router.navigate(["/financial/global-gl-setting-list"])
  }
  timePeriod(){
    this.router.navigate(["/financial/gl-custom-time-period-list"])
  }
  cost(){
    this.router.navigate(["/financial/global-gl-cost"])
  }
  paymentMethod(){
    this.router.navigate(["/financial/gl-payment-method-type"])
  }
  invoiceMethod(){
    this.router.navigate(["/financial/gl-invoice-type-list"])
  }
  rate(){
    this.router.navigate(["/financial/global-gl-rate-list"])
  }
  foreignExc(){
    this.router.navigate(["/financial/gl-foreign-exchange-rate"])
  }
  accountCat(){
    this.router.navigate(["/financial/gl-account-category-list"])
  }
  costCenter(){
    this.router.navigate(["/financial/gl-cost-center"])
  } 
  getForeignExchangeRates() {
    this.spinner.show();
    this.accountsService.getForeignExchangeRates(this.finSize).subscribe((res: any) => {
      this.foreignExchangeRates = res.data;
      this.spinner.hide();
    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  createForeignExchange(){
    this.Company="Company"
    this.router.navigate(["financial/create-foreign-exchange-rate"],{ queryParams: { organizationPartyId :  this.Company}})
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}