
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LeadModel } from './lead-model';

@Injectable({
  providedIn: 'root'
})


export class LeadService {
  lead_form: FormGroup;


  constructor(public _formBuilder: FormBuilder,
    public leadModel: LeadModel
  ) { }

  leadInfoForm(data: any): void {
    if (!this.lead_form) {
      this.lead_form = this._formBuilder.group(this.leadModel.mapDataToModel(data));

    }

  }

}

