<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;">Invitation
                </span>
                <span class="color-black pl-1"> >Add New Invitation
                </span>
                <button type="submit" class="btn btn-danger buttonclass" (click)="GoToQuickLink()">Cancel</button>&nbsp;
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                 Add New Invitation
                                </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                    Update New Invitation
                                </a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100"  [formGroup]="invitataionForm">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Party From</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Party From" filter="true"
                                                [options]="partyIdArray"  optionlabel="label"
                                                formControlName="partyFrom">
                                                </p-dropdown>
                                              
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA" *ngIf="this.show">
                                                    <label for="exampleInputEmail1">
                                                        Invitation ID</label>
                                                </div>
                                                <div class="col-lg-2" *ngIf="this.show">
                                                    <label>{{this.inviteId}}</label>
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Party ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Party ID"
                                                [options]="partyIdArray" filter="true"  optionlabel="label"
                                                formControlName="partyTo">
                                                </p-dropdown>
                                              
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">To Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter To Name"
                                                   
                                                    formControlName="toName">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Email Address</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Email Address"
                                                    
                                                    formControlName="emailAddress">
                                              
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Status ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Status ID"
                                                    [options]="PartyInvitationStatusListArray" filter="true" 
                                                     optionlabel="label"  formControlName="statusId">
                                                    </p-dropdown>
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Last Invite Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Last Invite Date"
                                                    formControlName="inviteDate">
                                              
                                                </div>
                                                
                                               
                                            </div>
                                          </div>
                                        
                                       
                                    </div>
                                </form>
                                <div class="main-submit-button" style="margin-left: 61%;">
                                    <button type="submit" *ngIf="!this.show" (click)="onSubmit();"
                                        class="btn btn-secondary submit-btn">Create</button>&nbsp;
                                        <button type="submit" *ngIf="this.show" (click)="onUpdate();"
                                        class="btn btn-secondary submit-btn">Update</button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>