import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddEmployeeModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        address1:(data && data.address1) ? data.address1 : null,
        address2:(data && data.address2) ? data.address2 : null,
        areaCode:(data && data.areaCode) ? data.areaCode : null,
        contactNumber:(data && data.contactNumber) ? data.contactNumber : null,
        countryCode:(data && data.countryCode) ? data.countryCode : null,
        countryGeoId:(data && data.countryGeoId) ? data.countryGeoId : null,
        emailAddress:(data && data.emailAddress) ? data.emailAddress : null,
        extension:(data && data.extension) ? data.extension : null,
        firstName:(data && data.firstName) ? data.firstName : null,  
        fromDate:(data && data.fromDate) ? data.fromDate : null,
        internalOrganization:(data && data.internalOrganization) ? data.internalOrganization : null,
        lastName:(data && data.lastName) ? data.lastName : null,
        middleInitial:(data && data.middleInitial) ? data.middleInitial : null,  
        postalCode:(data && data.postalCode) ? data.postalCode : null,
        stateProvinceGeoId:(data && data.stateProvinceGeoId) ? data.stateProvinceGeoId : null,
        title:(data && data.title) ? data.title : null,
       
     
    });
   
   
    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    address1: new FormControl(data.address1),
    address2: new FormControl(data.address2),
    areaCode: new FormControl(data.areaCode),
    city: new FormControl(data.city),
    contactNumber: new FormControl(data.contactNumber),
    countryCode: new FormControl(data.countryCode),
    countryGeoId: new FormControl(data.countryGeoId),
    emailAddress: new FormControl(data.emailAddress),
    extension: new FormControl(data.extension),
    firstName: new FormControl(data.firstName),
    fromDate: new FormControl(data.fromDate),
    internalOrganization: new FormControl(data.internalOrganization),
    lastName: new FormControl(data.lastName),
    middleInitial: new FormControl(data.middleInitial),
    postalCode: new FormControl(data.postalCode),
    stateProvinceGeoId: new FormControl(data.stateProvinceGeoId),
    title: new FormControl(data.title),

    
    
   
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
