import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { DropdownModule } from 'primeng/dropdown';
import { OrderManagementService } from '../order-management.service';
import * as moment from 'moment';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-purchase-order-options-settings',
  templateUrl: './purchase-order-options-settings.component.html',
  styleUrls: ['./purchase-order-options-settings.component.css']
})
export class PurchaseOrderOptionsSettingsComponent implements OnInit {
  activeCategory = 1;
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 4,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],
    }
  };
  carrierShipments: any[];
  shipmentOption: string;
  notes: {
    internalNote: string,
    shippingNote: string
  };
  shipmentCustom: {
    shipDateBefore: string,
    shipDateAfter: string,
    specialInstruction: string,
    isGift: boolean,
    giftMessage: string,
    shipAtOnce: boolean
  };
  shippingEstimate: number
  constructor(
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _OrderManagementService: OrderManagementService
  ) {
    this.notes = {
      internalNote: '',
      shippingNote: ''
    };
    this.carrierShipments = [];
    this.shipmentOption = '';
    this.shipmentCustom = {
      shipDateBefore: '',
      shipDateAfter: '',
      specialInstruction: '',
      isGift: false,
      giftMessage: '',
      shipAtOnce: false
    };
    this.shippingEstimate = 0;
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getShippingMethod();
  }
  getShippingMethod(): void {
    this.spinner.show();
    this._OrderManagementService.getOrderShippingMethod()
      .then(data => {
        this.spinner.hide();
         this.carrierShipments = data.data.carrierShipmentMethodList });
     
  }




  finalizeOrder(): void {
    this.spinner.show();
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      'internal_order_notes': this.notes.internalNote,
      'shippingNotes': this.notes.shippingNote,
      'finalizeMode': 'options',
      '0_shipping_method': 'STANDARD@_NA_',
      '0_may_split': this.shipmentCustom.shipAtOnce,
      'sgi0_shipBeforeDate_i18n': this.shipmentCustom.shipDateBefore ? moment(this.shipmentCustom.shipDateBefore).format('YYYY-MM-DD HH:mm:ss') : '',
      'sgi0_shipBeforeDate': this.shipmentCustom.shipDateBefore ? moment(this.shipmentCustom.shipDateBefore).format('YYYY-MM-DD HH:mm:ss') : '',
      'sgi0_shipAfterDate_i18n': this.shipmentCustom.shipDateAfter ? moment(this.shipmentCustom.shipDateAfter).format('YYYY-MM-DD HH:mm:ss') : '',
      'sgi0_shipAfterDate': this.shipmentCustom.shipDateAfter ? moment(this.shipmentCustom.shipDateAfter).format('YYYY-MM-DD HH:mm:ss') : '',
      '0_shipping_instructions': this.shipmentCustom.specialInstruction,
      '0_is_gift': this.shipmentCustom.isGift,
      '0_gift_message': this.shipmentCustom.giftMessage,
      '0_ship_estimate': this.shippingEstimate
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          switch (data.data.responseMessage) {
            case 'shipping': this.router.navigate(['/order/purchase-finalize-order']);
              break;
            case 'options': this.router.navigate(['/order/purchase-order-options']);
              break;
            case 'term': this.router.navigate(['/order/purchase-order-entry-terms']);
              break;
            case 'addparty': this.router.navigate(['/order/purchase-order-additional-party-entry']);
              break;
            case 'purchase': this.router.navigate(['/order/purchase-order-confirmation']);
              break;
          }
       
        }
      });
      this.spinner.hide();
  }
  setOption(event: any, partyId: string, shippingEstimate: number) {
    this.shipmentOption = `${event}@${partyId}`;
    this.shippingEstimate = shippingEstimate;
  }

}
