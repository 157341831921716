<div class="container-fluid main-container-wrapper" style="margin-top: 53px !important;">
    <!-- <div class="row " style="background-color: #0492C2; padding-top: 6px !important;">
        

        <div class="col-lg-1">
           <span class="color-black pl-1 titlePanels" style="cursor: pointer; color: white; font-weight: bold;">Find </span>
        </div>
            <div class="col-lg-2" style="margin-left: -4% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Work Order Number"
                >
                </p-dropdown>

            </div>

            <div class="col-lg-2" style="margin-left: -2% !important;">
                <input type="text" class="form-control" id=""  >
            </div>

            <div class="col-lg-1" style="margin-left: -2% !important;">
                <button type="submit" class="btn btn-secondary submit-btn">Search</button>

            </div>
        

        <div class="col-lg-4">
        </div>
            <div class="col-lg-2" style="margin-left: 7% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Recent Items"
                >
                </p-dropdown>

            </div>

        

    

</div> -->

<div class="row" style="background-color:  #0492C2; padding-bottom: 6px !important; padding-top: 4px !important;">

<!-- <div class="col-lg-3   ">
    <button type="submit" class="btn btn-secondary submit-btn" style="margin-left: -2%;">
        Dispatch Board
     </button>
</div> -->

<!-- <div class="col-lg-2 " style="margin-left: 48% !important;">
    <button (click)="toWorkOrder()" style="background-color: #1338BE; 
    color: white !important; font-weight: bold;"> Work Orders</button>
</div>

<div class="col-lg-2 " style="margin-left: -8% !important;">
    <button (click)="toCustomer()" style="background-color: #48AAAD; 
    color: white !important; font-weight: bold;">Customer</button>

</div>               
   
<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toInvoices()" style="background-color:#016064;
     color: white; font-weight: bold;">Invoices</button>

</div>                 -->
    
<!-- <div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toReports()" style="background-color: #1520A6; 
    color: white; font-weight: bold;">Reports</button>

</div>               

<div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toPeople()" style="background-color:#59788E;
     color: white !important; font-weight: bold; padding-left: 12px; padding-right: 12px;">Staff</button>

</div> -->
<!-- <div class="col-lg-2 " style="margin-left: -2% !important;">
    <button (click)="toTimeSheet()" style="background-color: #1F456E;
     color: white !important; font-weight: bold;">Time Sheet</button>

</div>                 

<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toSetting()" style="background-color:#757c88 ; 
    color: white; font-weight: bold; ">Settings</button>

</div>
<div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toStaffing()" style="background-color:#757c88 ; 
    color: white; font-weight: bold; ">Staffing</button>

</div>

<div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toHub()" class="btn btn-secondary submit-btn"  
     style="font-weight:bold;">Home</button>

</div> -->



</div>

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;" >
            
            <div  class="w3-card-4 classCssShadow" style="border:1px solid; border-color: #0d3769 !important; padding-bottom: 2%;">

                <!-- <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                ">Work Orders Settings</a>
                <p>You can customize the following and make the Work Order section behave in way 
                    that most suited for your business <a >Note:</a> You may choose to continue
                    using work order as they are not change any of the suggested settings.
                    Click  <a href="" style="font-weight: bold;">here </a>to learn  about how can you use best practices
                    by customzing your settings.
                </p> -->

                <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                "(click)="toPriorties()" >Priorites</a>
                <p>You can customize have you would like to call your priorites and set overdue 
                    hours for each of the predefined priorites here.


                </p>

                <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                " (click)="toSkill()">Skills</a>
                <p>You can add all Skill that are in your company portfolio and assign those skills
                    to your Tech. This will quicken your decission on assigning to Tech to a 
                Work Order because you will only see Tech with the appropriate Skills while assigning
                a Work Order.</p>

                <!-- <a class="btn btn-link" style="font-size:16px; font-weight: bold;
                " (click)="toCancelReason()" >Cancel Reasons</a>
                <p>You can different reason for cancelling Work Order. When you are or your tech cancel
                    Work Order they can pick appropriate reason from the list you define 
                    here.
                </p>
                
                <a class="btn btn-link" style="font-size:16px; font-weight: bold;
                "(click)="toOnHoldReason()">On-Hold Reasons</a>
                <p>
                    You can add different reason for putting work order on hold. When you or your Tech
                    put Work Order on hold you can pick appropriate reason from the list you define 
                    here.
                </p>

                <a class="btn btn-link" style="font-size:16px; font-weight: bold;
                " (click)="toFlagReason()">Flag Reasons</a>
                <p>
                    You can add different reason for flagging a Work Order. When you or your Tech
                    flag a Work Order you can pick appropriate reason from the list you define 
                    here.
                </p>

                <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                " (click)="toRepairCodes()">Repair Codes</a>
                <p>You can enter different types of categories and codes you use when your Tech complete
                    Work Order they can pick appropriate Repair Category and code for Work Order.

                </p>

                <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                " (click)="toWorkOrderTypes()">Work Order Types</a>
                <p>
                    You can enter different types of Work Order  types here. This will help you to categorize
                    to Work Order in Reporting.

                </p>

                <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                " (click)="toAlertSettings()">Alert Settings</a>
                <p>
                    You can configure how alert work in this section.
                </p>

                <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                " (click)="toPrintTemplates()">Print Templates</a>
                <p>
                    You can pick and choose the templates for all of your Work Order points here.
                </p>

                <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                " (click)="toDispatchBoardSetting()">Dispatch Board Setting</a>
                
                <br>

                <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                " (click)="toWorkOrderWorkFlow()">Work Order Work Flow</a>

                <br>
                


                <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                " (click)="toWorkOrderBackDate()">Work Order Back Date</a>
                
               
 -->


        
        </div>
    </div>

    </div>
</div>