import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {Router} from "@angular/router";
import {ActivatedRoute} from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {MyContactsService} from "../../crm/contacts/my-contacts/my-contacts.service";



@Component({
  selector: 'app-list-related-contact',
  templateUrl: './list-related-contact.component.html',
  styleUrls: ['./list-related-contact.component.css']
})
export class ListRelatedContactComponent implements OnInit {
  fileUploadForm: FormGroup;
  partyId: any;
  activeCategory=1;
  contactId: any;
  contactArray: any=[];
  params: any;

  constructor(readonly formBuilder:FormBuilder,
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly myContactsService:MyContactsService,
    readonly toastr:ToastrService,
    readonly router:Router) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.partyId = params["partyId"]
    });
  

    this.getContactList();
    this.fileUploadForm = this.formBuilder.group({
      accountPartyId: [''],
      comments:[''],
      contactPartyId:[''],
      partyId:['']
    });
  }
  navigate(){
    this.router.navigate(["/crm/accounts/account-details"],{queryParams:{partyId:this.partyId}})
  }
  getContactList(){
    this.spinner.show();
    this.myContactsService.getContactsLists().subscribe(res=> {
      this.contactId=res.data;
      this.spinner.hide();
      for(const value of this.contactId){
        this.contactArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }
  onSubmit(){
    this.spinner.show();
    this.fileUploadForm.get('accountPartyId').patchValue(this.partyId);
    this.fileUploadForm.get('partyId').patchValue(this.partyId);
    const formData = new FormData();
    const formdata = {...this.fileUploadForm.value};
    this.myContactsService.RelatedAccount(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.router.navigate(["/crm/accounts/account-details"],{queryParams:{partyId:this.partyId}})
      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }

 changeactiveCategoryFunction(tab: number){
  this.activeCategory =tab;
}


}
