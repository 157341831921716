<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">My Work
                </span>
                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            
            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                        <li [ngClass]="activeCategory==1?'active':''" (click)="Backlog()"><a>
                                Product Backlog </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="Sprint()"><a>
                                Project/Sprint
                            </a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="MyWork()"><a>
                                My Work</a></li>
                        <li [ngClass]="activeCategory==4?'active':''" (click)="Tasks()"><a>
                                Tasks
                            </a></li>
                        <li [ngClass]="activeCategory==5?'active':''" (click)="OpenTest()"><a>
                                Open Test
                            </a></li>
                        <li [ngClass]="activeCategory==6?'active':''" (click)="Revision()"><a>
                                Revision
                            </a></li>


                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="w3-card-4 classCardView " *ngIf="!showTable">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    {{CurrentTimesheetHead ? CurrentTimesheetHead:"Current Timesheet"}}
                                                    <button type="submit" class="btn btn-secondary submit-btn" 
                                                    (click)="updateTimesheetStatus()" 
                                                    *ngIf="this.CurrentTimesheetStatus=='TIMESHEET_IN_PROCESS'">Status to Complete</button>

                                                </h4>

                                            </div>
                                        </header>
                                        <div
                                        class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group table-responsive">
                                                <table class="table align-middle">
                                                    <thead>
                                                        <tr class="table-rows" style="background: #0d3769;">
                                                            <th
                                                                style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                                                Task Name

                                                            </th>
                                                            <th
                                                                style="color: white;font-size: 12px;min-width: 50px;padding: 14px 20px !important;">
                                                                Check Complete

                                                            </th>
                                                            <th
                                                                style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                Rate Type ID

                                                            </th>
                                                            <th
                                                                style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                Mon
                                                            </th>
                                                            <th
                                                                style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                Tue
                                                            </th>
                                                            <th
                                                                style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                Wen
                                                            </th>
                                                            <th
                                                                style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                Thu
                                                            </th>
                                                            <th
                                                                style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                Fri
                                                            </th>
                                                            <th
                                                                style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                Sat
                                                            </th>
                                                            <th
                                                                style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                Sun
                                                            </th>
              
                                                            <th
                                                                style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                Actual Hours
                                                            </th>
                                                            <th
                                                                style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                Act Total 
                                                            </th>
                                                            <th
                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                            Plan Hours

                                                        </th>

                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        <tr class="table-rows" class="align-bottom"
                                                        *ngFor="let product of CurrentTimesheetTable;let i = index;"
                                                        style="height: 11px !important;">
                                                     
                                                        <td style="    color: black;
                                                                              font-weight: 100;
                                                                              font-size: 12px;
                                                                              min-width: 155px;">

                                                          
                                                            <p-dropdown filter="true" appendTo="body"
                                                            [(ngModel)]="product.taskName"
                                                            class="dropdowns"
                                                            [options]="workEffortNameArray"
                                                            [ngModelOptions]="{standalone: true}"
                                                            optionlabel="label">
                                                        </p-dropdown>
                                                            


                                                        </td>
                                                      
                                                        <td style="min-width: 50px;">
                                                            <p-checkbox formControlName="duplicateWorkEffortAssocs" 
                                                             [(ngModel)]="product.checkComplete"
                                                             styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            <!-- <input type="checkbox"
                                                                [(ngModel)]="product.checkComplete"
                                                                [ngModelOptions]="{standalone: true}"
                                                                class="form-control"> -->

                                                        </td>
                                                        <td style="min-width: 155px;">

                                                            <p-dropdown filter="true" appendTo="body"
                                                                [(ngModel)]="product.rateTypeID"
                                                                class="dropdowns"
                                                                [options]="rateTypeIDArray"
                                                                [ngModelOptions]="{standalone: true}"
                                                                optionlabel="label">
                                                            </p-dropdown>

                                                        </td>
                                                        <td style="min-width: 155px;">
                                                            <input type="text"
                                                                [(ngModel)]="product.mon"
                                                                [ngModelOptions]="{standalone: true}"
                                                                class="form-control">

                                                        </td>
                                                        <td style="min-width: 155px;">
                                                            <input type="text"
                                                                [(ngModel)]="product.tue"
                                                                [ngModelOptions]="{standalone: true}"
                                                                class="form-control">

                                                        </td>
                                                        <td style="min-width: 155px;">
                                                            <input type="text"
                                                                [(ngModel)]="product.wed"
                                                                [ngModelOptions]="{standalone: true}"
                                                                class="form-control">

                                                        </td>
                                                        <td style="min-width: 155px;">
                                                            <input type="text"
                                                                [(ngModel)]="product.thur"
                                                                [ngModelOptions]="{standalone: true}"
                                                                class="form-control">

                                                        </td>
                                                        <td style="min-width: 155px;">
                                                            <input type="text"
                                                                [(ngModel)]="product.fri"
                                                                [ngModelOptions]="{standalone: true}"
                                                                class="form-control">

                                                        </td>
                                                        <td style="min-width: 155px;">
                                                            <input type="text"
                                                                [(ngModel)]="product.sat"
                                                                [ngModelOptions]="{standalone: true}"
                                                                class="form-control">

                                                        </td>
                                                        <td style="min-width: 155px;">
                                                            <input type="text"
                                                                [(ngModel)]="product.sun"
                                                                [ngModelOptions]="{standalone: true}"
                                                                class="form-control">

                                                        </td>
                                                        <td style="min-width: 155px;">
                                                          {{product.hours}}

                                                        </td>
                                                        <td style="min-width: 155px;">
                                                  

                                                          </td>
                                                          <td style="min-width: 155px;">
                                                            

                                                          </td>
                                                    </tr>
                                                       
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-lg-1 main-submit-button" *ngIf="this.CurrentTimesheetStatus=='TIMESHEET_IN_PROCESS'">
                                                <button type="submit"
                                                style="padding: 0px 12px !important;"
                                                (click)="createAddNewWeeklyTimesheetCurrent()"
                                                class="btn btn-secondary submit-btn">Update
                                            </button>
                                            </div>

                                        </div>
                                    </div>
                                       
                                    </div>
                                    <div class="w3-card-4 classCardView mt-2" *ngIf="showTable">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling ml-2">
                                                {{CurrentTimesheetNbrHeader}}
                                            </h4>

                                            <button type="submit"
                                            style="padding: 0px 12px !important;"
                                            (click)="updateTimesheets()" *ngIf="showStatusBtn"
                                            class="btn btn-secondary submit-btn">Status to 'Complete'
                                        </button>

                                        </div>

                                        <div class="panel-body panel">
                                            <form class="w-100" [formGroup]="updateTimesheet">
                                                <div
                                                    class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group table-responsive">
                                                            <table class="table align-middle">
                                                                <thead>
                                                                    <tr class="table-rows" style="background: #0d3769;">
                                                                        <th
                                                                            style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                                                            Task Name

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 50px;padding: 14px 20px !important;">
                                                                            Check Complete

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Rate Type ID

                                                                        </th>
                                     

                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Mon
                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Tue
                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Wen
                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Thu
                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Fri
                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Sat
                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Sun
                                                                        </th>
                          
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Actual Hours
                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Act Total 
                                                                        </th>
                                                                        <th
                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                        Plan Hours

                                                                    </th>

                                                                    </tr>

                                                                </thead>
                                                                <tbody>
                                                                    <tr class="table-rows" class="align-bottom"
                                                                    *ngFor="let product of CurrentTimesheetNbr;let i = index;"
                                                                    style="height: 11px !important;">
                                                                 
                                                                    <td style="    color: black;
                                                                                          font-weight: 100;
                                                                                          font-size: 12px;
                                                                                          min-width: 155px;">

                                                                      
                                                                        <p-dropdown filter="true" appendTo="body"
                                                                        [(ngModel)]="product.taskName"
                                                                        class="dropdowns"
                                                                        [options]="workEffortNameArray"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                        


                                                                    </td>
                                                                  
                                                                    <td style="min-width: 50px;">
                                                                        <p-checkbox formControlName="duplicateWorkEffortAssocs" 
                                                                         [(ngModel)]="product.checkComplete"
                                                                        styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <!-- <input type="checkbox"
                                                                            [(ngModel)]="product.checkComplete"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            class="form-control"> -->

                                                                    </td>
                                                                    <td style="min-width: 155px;">

                                                                        <p-dropdown filter="true" appendTo="body"
                                                                            [(ngModel)]="product.rateTypeID"
                                                                            class="dropdowns"
                                                                            [options]="rateTypeIDArray"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            optionlabel="label">
                                                                        </p-dropdown>

                                                                    </td>
                                                                    <td style="min-width: 155px;">
                                                                        <input type="text"
                                                                            [(ngModel)]="product.mon"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            class="form-control">

                                                                    </td>
                                                                    <td style="min-width: 155px;">
                                                                        <input type="text"
                                                                            [(ngModel)]="product.tue"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            class="form-control">

                                                                    </td>
                                                                    <td style="min-width: 155px;">
                                                                        <input type="text"
                                                                            [(ngModel)]="product.wed"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            class="form-control">

                                                                    </td>
                                                                    <td style="min-width: 155px;">
                                                                        <input type="text"
                                                                            [(ngModel)]="product.thur"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            class="form-control">

                                                                    </td>
                                                                    <td style="min-width: 155px;">
                                                                        <input type="text"
                                                                            [(ngModel)]="product.fri"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            class="form-control">

                                                                    </td>
                                                                    <td style="min-width: 155px;">
                                                                        <input type="text"
                                                                            [(ngModel)]="product.sat"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            class="form-control">

                                                                    </td>
                                                                    <td style="min-width: 155px;">
                                                                        <input type="text"
                                                                            [(ngModel)]="product.sun"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            class="form-control">

                                                                    </td>
                                                                    <td style="min-width: 155px;">
                                                                      {{product.hours}}

                                                                    </td>
                                                                    <td style="min-width: 155px;">
                                                              
  
                                                                      </td>
                                                                      <td style="min-width: 155px;">
                                                                        
  
                                                                      </td>

                                                                     

                                                                </tr>

                                                                 

                                                                   
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-lg-1 main-submit-button" *ngIf="this.showStatusBtn">
                                                            <button type="submit"
                                                            style="padding: 0px 12px !important;"
                                                            (click)="createAddNewWeeklyTimesheet()"
                                                            class="btn btn-secondary submit-btn">Update
                                                        </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <!-- <p class="paginate_data">
                                            View per page
                                        </p> -->
                                    </div>
                                </form>
                                <form class="w-100">
                                    <div class="w3-card-4 classCardView">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                        flex-wrap: wrap !important;
                        display: flex !important;">
                                                <li><a>
                                                        Add a new weekly Timesheet
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <form class="w-100" [formGroup]="addnewtimsheet">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">
                                                                Select Date
                                                            </label>
                                                        </div>

                                                        <div class="col-lg-2">
                                                            <input type="date" class="form-control"
                                                                formControlName="selectDate" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Enter Date">
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-lg-12  main-submit-button" style="margin-left: -67%;">
                                                    <button type="submit" (click)="createWeeklyTimesheet()"
                                                        class="btn btn-secondary submit-btn">Add</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="w3-card-4 classCardView ">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Timesheet list of {{partyId}}
                                                </h4>
                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="allTimesheet" [paginator]="true" [rows]="rows"
                                                            scrollWidth="100%"
                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                            [totalRecords]="total" [scrollable]="true"
                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                        pSortableColumn="code">
                                                                        <div class="checkbox-align"
                                                                            style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Timesheet ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            From Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Through Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Hours
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Comments
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status to 'complete'
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>



                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span
                                                                                 class="account-button"  
                                                                                (click)="getCurrentTimesheetNbr(product)">
                                                                                {{product.timesheetId}}</span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.statusId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.fromDate}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.thruDate}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.hours}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.comments}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                      
                                                                    </td>
                                                                    <!-- <td [ngStyle]="{'width':'170px'}">

                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        class="ml-20" width="16" height="16"
                                                                        viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }

                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                </td> -->
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>