
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AddSkillModel } from './add-skill-model'

@Injectable({
    providedIn: 'root'
  })


export class AddSkillsService {
  add_skill_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    private addSkillModel: AddSkillModel
    ) { } 

  addSkillForm(data:any): void {
    if (!this.add_skill_form) {
        this.add_skill_form = this._formBuilder.group(this.addSkillModel.mapDataToModel(data));
    }
  }
}

 