<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Quantity-Break  of {{ this.id}} </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <header class="w3-container w3-blue">
                            <div class="header-tabs" style="margin-left: -15px;
                        margin-right: -14px;">
                                <h4 class=" common-styling h4Margin">
                                    Quantity-Break Information
                                </h4>
                                <span *ngIf="!this.Hidebtn" class="edit-buttons hCreate">

                              <a   data-toggle="modal" (click)="edit();"
                              data-target="#exampleModalCenter">Update</a>
                            </span>
                            </div>
                        </header>

                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group">
                                                <div *ngIf="activeCategory==1">

                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Quantity-Breaks ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.id}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Type </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.type}}</span>

                                                                        </div>



                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">From Quantity</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.fromquan}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Through Quantity</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.thruquan}}</span>
                                                                        </div>





                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Quantity Breaks</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategorytab==2?'active':''"><a>
                            Update Quantity Breaks</a></li>


                        </ul>
                    </div>

                    <div>

                        <div class="row">
                            <form class="w-100" [formGroup]="quantityBreakForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Type <span
                                                        class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown [options]="quantityType" filter="true" placeholder="Type" formControlName="quantityBreakTypeId"></p-dropdown>

                                                <small class="text-danger" *ngIf="!!quantityBreakForm.controls.quantityBreakTypeId.invalid && !!quantityBreakForm.controls.quantityBreakTypeId.touched">Required</small>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">From Quantity</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1" formControlName="fromQuantity" aria-describedby="emailHelp" placeholder="Enter From Quantity">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Through Quantity</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1" formControlName="thruQuantity" aria-describedby="emailHelp" placeholder="Enter Through Quantity">
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -11%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="submit()">Update</button>

                                </div>
                            </form>
                        </div>


                    </div>
                </div><br><br>
            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>