import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forum-purposes',
  templateUrl: './forum-purposes.component.html',
  styleUrls: ['./forum-purposes.component.css']
})
export class ForumPurposesComponent implements OnInit {
  @ViewChild('closebutton')closebutton;
  @ViewChild('closebutton2')closebutton2;
  activeCategory = 3;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  purposeForm: FormGroup;
  contentPurposeTypeId: any;
  contentPurposeTypeIdArray: any[]=[];
  forumGroupPurposes: any;
  forumGroupPurposess: any;
  contentId: any;
  forumGroupId: any;
  fromDateCatePatch: string;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) 
  {
    this.purposeForm=this._FormBuilder.group({
      contentPurposeTypeId:"",
      sequenceNum:"",
    })

  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.forumGroupId = params["forumGroupId"];
    });

    this.getcontentPurposeTypeId();
    this.getforumGroupPurposes();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  getforumGroupPurposes() {
    this.spinner.show();
    this.accountsService.getforumGroupPurposes(this.forumGroupId).subscribe(res => {
      this.forumGroupPurposess = res.data.forumGroupPurposes;
      this.spinner.hide();
    })
  }

  createForumGroupPurpose(): void {
    this.spinner.show();
    const requestData ={
    "contentId": this.forumGroupId,
    "contentPurposeTypeId": this.purposeForm.value.contentPurposeTypeId,
    "forumGroupId": this.forumGroupId,
    "sequenceNum": this.purposeForm.value.sequenceNum,
  }
     this.accountsService.createForumGroupPurpose(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getforumGroupPurposes();
        this.closebutton.nativeElement.click();
        this.purposeForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  deleteForumGroupPurpose(product) {
      Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "contentId": product.contentId,
          "contentPurposeTypeId": product.contentPurposeTypeId,
          "sequenceNum": product.sequenceNum,
        }
        this.accountsService.deleteForumGroupPurpose(req).subscribe((res: any) => {
          if (res.success) {
            this.getforumGroupPurposes();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  

  getcontentPurposeTypeId() {
    this.spinner.show();
    this.accountsService.getcontentPurposeTypeId().subscribe(res => {
      this.contentPurposeTypeId= res.data.contentPurposeTypeId
      this.spinner.hide();
      for (const value of this.contentPurposeTypeId) {
        this.contentPurposeTypeIdArray.push({
          label: value.description,
          value: value.contentPurposeTypeId
        })
      }
    })
  }

  openForumGroup()
  {
    this._Router.navigate(['/content/forum']);

  }
  openForums()
  {
    this._Router.navigate(['/content/forum/forums'], { queryParams: { forumGroupId: this.forumGroupId } });

  }
  openPurposes()
  {
    this._Router.navigate(['/content/forum/forum-purposes'], { queryParams: { forumGroupId: this.forumGroupId } });

  }
  openRoles()
  {
    this._Router.navigate(['/content/forum/forum-roles'], { queryParams: { forumGroupId: this.forumGroupId } });

  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
