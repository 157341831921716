import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.css']
})
export class CommunicationsComponent implements OnInit {
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  @ViewChild('closebutton') closebutton;
  search= '';
  accountsList:any[]=[]
  relatedContact:any[]=[]
  rows = 50;

  readonly req = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  advanceSearchOrderByReturn :{
    communicationEventId: string,
    communicationEventIdSearchType: string,
    communicationEventTypeId:string,
    partyId:string,
    partyIdFrom: string,
    partyIdTo: string,
    returnId: string,
    returnIdSearchType: string,
    statusId: string
  }
  advanceSearchOrderBy: {
    communicationEventId:string,
    communicationEventIdSearchType:string,
    communicationEventTypeId: string,
    partyId: string,
    partyIdFrom: string,
    partyIdTo: string,
    orderId: string,
    orderIdSearchType:string,
    statusId: string
   


  };
  advanceSearch: {
   communicationEventId: string,
  communicationEventIdSearchType: string,
  communicationEventTypeId:  string,
  parentCommEventId:  string,
  parentCommEventIdSearchType: string,
  partyId:  string,
  partyIdFrom:  string,
  partyIdTo: string,
  roleTypeId:  string,
  statusId:  string,
  subject: string,
  subjectSearchType:  string,
  }
  activeCategory=1;
  searchArray:any[]=[];
  opportunityIdArray: { label: string; value: string; }[];
  evenTByOrder: any;
  commByOrder: any;
  personId: any;
  partyIdArray: any[]=[];
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  stausIdArray: any[]=[];
  parentCommEventArray: any[]=[];
  comtypeIdArray: any[]=[];
  getOverviewList: any;
  UnknownPartyComms: any;
  products3: any;
  
  activeCategoryValue=2;
  createCommEvent:FormGroup;

  getInitailData: any;
 
  contentMMArray: any[]=[];

  contactListId: any;
  contactListIdArray: any[]=[];
  datetimeEnded: string;
  datetimeStarted: string;

  reasonenumArray: any[]=[];
  contactMech: any;
  contactMechArray: any[]=[];
  contactMechTypeArray: any[]=[];
  contactMechTo: any;
  contactMechToArray: any[]=[];
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(readonly router: Router,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
  
    readonly _ActivatedRoute: ActivatedRoute,
    readonly addSkillService: AddSkillService,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly myContactsService: MyContactsService,
    ) { 
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.advanceSearch= {
      communicationEventId: "",
     communicationEventIdSearchType: "Contains",
     communicationEventTypeId: "",
     parentCommEventId: "",
     parentCommEventIdSearchType: "Contains",
     partyId: "",
     partyIdFrom: "",
     partyIdTo: "",
     roleTypeId: "",
     statusId: "",
     subject: "",
     subjectSearchType: "Contains"
     }
    this.advanceSearchOrderByReturn = {
      communicationEventId: "",
      communicationEventIdSearchType: "Contains",
      communicationEventTypeId: "",
      partyId: "",
      partyIdFrom: "",
      partyIdTo: "",
      returnId: "",
      returnIdSearchType: "Contains",
      statusId: ""
     


    };
    this.advanceSearchOrderBy = {
      communicationEventId: "",
      communicationEventIdSearchType: "Contains",
      communicationEventTypeId: "",
      partyId: "",
      partyIdFrom: "",
      partyIdTo: "",
      orderId: "",
      orderIdSearchType: "Contains",
      statusId: ""
     


    };
    this.createCommEvent = this._FormBuilder.group({
      commEventId: [''],
      parCommEventID: [''],
      commTypeId: [''],
      status:[''],
      partyFrom:[''],
      partyTo: [''],
      conMechId: [''],
      fromContactMechId: [''],
      toContactMechId:[''],
      roleTypeFrom:[''],
      roleTypeTo: [''],
      contactListId: [''],
      startdate: [''],
      finishDate:[''],
      subject:[''],
      reasonEnumId: [''],
      contentMimeTypeId: [''],
      content: [''],
      note:[''],
 
    });
  }

  ngOnInit(): void {
    
 this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
    this.getCommunicationEventByOrder();
    this.getCommunicationByOrder() ;
    this.getPartyId();
    this.getDropdownList();
    this.getRoleId();
    this.getCrmListDetail();
    this.getUnknownPartyComms();
    this.getDropdownListData();
    this.getContactList();

  }
  homeButton(){
    this.router.navigate(['/communication-overview/Dashboard'])

  }
  cancelSubmit(){
    this._location.back();
  }
  
  



  communicationEvents(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
  
          this.accountsService.communicationEvents(product.communicationEventId).subscribe(res => {
            this.getCrmListDetail(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
  
  }
  deleteCommunicationEvents(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const formData = {
            "_rowSubmit": "",
            "_useRowSubmit": "",
            "communicationEventId": "",
            "delContentDataResource": "" ,
            "entryDate": this.datePipe.transform(product.entryDate,'yyyy-MM-dd hh:mm:ss'),
            "note": "",
            "subject": product.subject,
            "viewIndex_2": "",
            "viewSize_2": ""   
          }
          this.myContactsService.deleteCommunicationEventsBy(formData).subscribe(res => {
            this.getUnknownPartyComms(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
   
  }

  getDropdownListData() {
    this.spinner.show();
   
    this.accountsService.getCrmInitialCommData().subscribe((res: any) => {
    const stausId = res.data.commEventstatus;
    const comtypeId=res.data.commEventTypes;
    const contentMM=res.data.mimeType;
    const parentCommEvent=res.data.parentCommEvent;
    const reasonenum=res.data.reasonEnum;
    const contactMechType=res.data.contactMechType;
    this.spinner.hide();
    for (const value of contactMechType) {
      this.contactMechTypeArray.push({
        label: value.description,
        value: value.contactMechTypeId
      })
    } 
    for (const value of reasonenum) {
      this.reasonenumArray.push({
        label: value.description,
        value: value.enumId
      })
    } 
    for (const value of parentCommEvent) {
      this.parentCommEventArray.push({
        label: value.communicationEventId,
        value: value.communicationEventId
      })
    } 
      for (const value of stausId) {
        this.stausIdArray.push({
          label: value.description,
          value: value.statusId
        })
      } 
      for (const value of comtypeId) {
        this.comtypeIdArray.push({
          label: value.description,
          value: value.communicationEventTypeId
        })
      } 
      for (const value of contentMM) {
        this.contentMMArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      } 
    })
     

    
   
  }
  

  contactEventMech(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMech = res.data;
      this.spinner.hide();
      for (const value of this.contactMech) {
        this.contactMechArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
   
  }
  contactEventMechTo(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMechTo = res.data;
      this.spinner.hide();
      for (const value of this.contactMechTo) {
        this.contactMechToArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
  
  }
  
  getContactList() {
    this.spinner.show();
    this.accountsService.getContactList().subscribe(res => {
      this.contactListId = res.data;
      this.spinner.hide();
      for (const value of this.contactListId) {
        this.contactListIdArray.push({
          label: value.description,
          value: value.contactListId
        })
      }
    })
  
  }
  
  onSubmit() {
    this.spinner.show();
    const fromActualdate=this.createCommEvent.get('startdate').value;
    this.datetimeStarted=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createCommEvent.get('finishDate').value;
    this.datetimeEnded=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "communicationEventId": '',
      "communicationEventTypeId": this.createCommEvent.value.commTypeId,
      "contactListId": this.createCommEvent.value.contactListId,
      "contactMechIdFrom": this.createCommEvent.value.fromContactMechId,
      "contactMechIdTo": this.createCommEvent.value.toContactMechId,
      "contactMechTypeId": this.createCommEvent.value.conMechId,
      "content": this.createCommEvent.value.content,
      "contentMimeTypeId": this.createCommEvent.value.contentMimeTypeId,
      "datetimeEnded": this.datetimeEnded,
      "datetimeStarted": this.datetimeStarted,
      "messageId": '',
      "note": this.createCommEvent.value.note,
      "parentCommEventId": this.createCommEvent.value.parCommEventID,
      "partyIdFrom": this.createCommEvent.value.partyFrom,
      "partyIdTo": this.createCommEvent.value.partyTo,
      "reasonEnumId": this.createCommEvent.value.reasonEnumId,
      "roleTypeIdFrom": this.createCommEvent.value.roleTypeFrom,
      "roleTypeIdTo":this.createCommEvent.value.roleTypeTo,
      "statusId":this.createCommEvent.value.status,
      "subject": this.createCommEvent.value.subject,
    }

    this.accountsService.postCommunicationNew(requestData).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getCommunicationEventByOrder();
        this.getCommunicationByOrder() ;
      
        this.getCrmListDetail();
   
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  getUnknownPartyComms() {
    this.spinner.show();
  
   
    this.accountsService.getUnknownPartyComms().subscribe((res: any) => {
      this.UnknownPartyComms = res.data;
      this.spinner.hide();

    })
   
  }
  getCrmListDetail() {
    this.spinner.show();
    const data={
      "communicationEventId": "",
      "communicationEventIdSearchType": "Contains",
      "communicationEventTypeId": "",
      "parentCommEventId": "",
      "parentCommEventIdSearchType": "Contains",
      "partyId": "",
      "partyIdFrom": "",
      "partyIdTo": "",
      "roleTypeId": "",
      "statusId": "",
      "subject": "",
      "subjectSearchType": ""
    }
   
    this.accountsService.crmMainPageData(data, this.req).subscribe((res: any) => {
      this.getOverviewList = res.data;
      this.spinner.hide();

    })
   
  }
  resetOverview() {
    this.advanceSearch= {
      communicationEventId: "",
     communicationEventIdSearchType: "Contains",
     communicationEventTypeId: "",
     parentCommEventId: "",
     parentCommEventIdSearchType: "Contains",
     partyId: "",
     partyIdFrom: "",
     partyIdTo: "",
     roleTypeId: "",
     statusId: "",
     subject: "",
     subjectSearchType: "Contains"
     }
    this.getOverviewList=[]
    this.spinner.show();
    const data={
      "communicationEventId": "",
      "communicationEventIdSearchType": "Contains",
      "communicationEventTypeId": "",
      "parentCommEventId": "",
      "parentCommEventIdSearchType": "Contains",
      "partyId": "",
      "partyIdFrom": "",
      "partyIdTo": "",
      "roleTypeId": "",
      "statusId": "",
      "subject": "",
      "subjectSearchType": ""
    }
   
    this.accountsService.crmMainPageData(data, this.req).subscribe((res: any) => {
      this.getOverviewList = res.data;
      this.spinner.hide();

    })
   
  }
  advanceSearchOverview(isPopUp: boolean) {
    this.getOverviewList=[]
    this.spinner.show();
    const data={
      "communicationEventId":this.advanceSearch.communicationEventId ? this.advanceSearch.communicationEventId : '',
      "communicationEventIdSearchType":this.advanceSearch.communicationEventIdSearchType ? this.advanceSearch.communicationEventIdSearchType : '',
      "communicationEventTypeId":this.advanceSearch.communicationEventTypeId ? this.advanceSearch.communicationEventTypeId : '',
      "parentCommEventId":this.advanceSearch.parentCommEventId ? this.advanceSearch.parentCommEventId : '',
      "parentCommEventIdSearchType": this.advanceSearch.parentCommEventIdSearchType ? this.advanceSearch.parentCommEventIdSearchType : '',
      "partyId":this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
      "partyIdFrom": this.advanceSearch.partyIdFrom ? this.advanceSearch.partyIdFrom : '',
      "partyIdTo":this.advanceSearch.partyIdTo ? this.advanceSearch.partyIdTo : '',
      "roleTypeId": this.advanceSearch.roleTypeId ? this.advanceSearch.roleTypeId : '',
      "statusId":this.advanceSearch.statusId ? this.advanceSearch.statusId : '',
      "subject":this.advanceSearch.subject ? this.advanceSearch.subject : '',
      "subjectSearchType": this.advanceSearch.subjectSearchType ? this.advanceSearch.subjectSearchType : '',
    }
   
    this.accountsService.crmMainPageData(data, this.req).subscribe((res: any) => {
      this.getOverviewList = res.data;
      this.spinner.hide();

    })
   
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    this.spinner.hide();
  }
  getDropdownList() {
    this.spinner.show();
   
    this.accountsService.getCrmInitialCommData().subscribe((res: any) => {
    const stausId = res.data.commEventstatus;
    const comtypeId=res.data.commEventTypes;
    const contentMM=res.data.mimeType;
    const parentCommEvent=res.data.parentCommEvent;
    const reasonenum=res.data.reasonEnum;
    const contactMechType=res.data.contactMechType;
    this.spinner.hide();
  
    for (const value of parentCommEvent) {
      this.parentCommEventArray.push({
        label: value.communicationEventId,
        value: value.communicationEventId
      })
    } 
      for (const value of stausId) {
        this.stausIdArray.push({
          label: value.description,
          value: value.statusId
        })
      } 
      for (const value of comtypeId) {
        this.comtypeIdArray.push({
          label: value.description,
          value: value.communicationEventTypeId
        })
      } 
     
    })
     

    
   
  }
  
  getRoleId() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.roleTypeId = res.data.roleType;
      for (const value of this.roleTypeId) {
        this.roleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
    this.spinner.hide();
  }
  getCommunicationByOrder() {
    this.spinner.show();
    const result = {
      communicationEventId: "",
      communicationEventIdSearchType: "Contains",
      communicationEventTypeId: "",
      partyId: "",
      partyIdFrom: "",
      partyIdTo: "",
      orderId: "",
      orderIdSearchType: "Contains",
      statusId: ""

    }
    this.accountsService.getCommunicationByOrder(this.req,result).subscribe((res: any) => {
      this.commByOrder = res.data;
      this.spinner.hide();

    })
   
  }
  getCommunicationEventByOrder() {
    this.spinner.show();
    const result = {
      communicationEventId: "",
      communicationEventIdSearchType: "Contains",
      communicationEventTypeId: "",
      partyId: "",
      partyIdFrom: "",
      partyIdTo: "",
      returnId: "",
      returnIdSearchType: "Contains",
      statusId: ""

    }
    this.accountsService.getCommunicationEventByOrder(this.req,result).subscribe((res: any) => {
      this.evenTByOrder = res.data;
      this.spinner.hide();

    })
   
  }
  advanceSearchReturnOrder(isPopUp: boolean): void {
    this.spinner.show();
    this.evenTByOrder = [];
    const result = {
      communicationEventId: this.advanceSearchOrderByReturn.communicationEventId ? this.advanceSearchOrderByReturn.communicationEventId : '',
        communicationEventIdSearchType: this.advanceSearchOrderByReturn.communicationEventIdSearchType ? this.advanceSearchOrderByReturn.communicationEventIdSearchType : '',
        communicationEventTypeId:this.advanceSearchOrderByReturn.communicationEventTypeId ? this.advanceSearchOrderByReturn.communicationEventTypeId : '',
        partyId: this.advanceSearchOrderByReturn.partyId ? this.advanceSearchOrderByReturn.partyId : '',
        partyIdFrom: this.advanceSearchOrderByReturn.partyIdFrom ? this.advanceSearchOrderByReturn.partyIdFrom : '',
        partyIdTo: this.advanceSearchOrderByReturn.partyIdTo ? this.advanceSearchOrderByReturn.partyIdTo : '',
        returnId: this.advanceSearchOrderByReturn.returnId ? this.advanceSearchOrderByReturn.returnId : '',
        returnIdSearchType: this.advanceSearchOrderByReturn.returnIdSearchType ? this.advanceSearchOrderByReturn.returnIdSearchType : '',
        statusId: this.advanceSearchOrderByReturn.statusId ? this.advanceSearchOrderByReturn.statusId : '',
      

    }
    this.accountsService.getCommunicationEventByOrder(this.req,result).subscribe((resp: any) => {
        if (resp.success) {
          this.spinner.hide();
          this.evenTByOrder = resp.data;
        } 
      });
     
  }
  reset(){
    this.spinner.show();
    this.advanceSearchOrderByReturn.communicationEventId="";
    this.advanceSearchOrderByReturn.communicationEventTypeId="";
    this.advanceSearchOrderByReturn.partyId="";
    this.advanceSearchOrderByReturn.partyIdFrom="";
    this.advanceSearchOrderByReturn.partyIdTo="";
    this.advanceSearchOrderByReturn.returnId="";
    this.advanceSearchOrderByReturn.statusId="";
    const result = {
      communicationEventId: "",
        communicationEventIdSearchType: "Contains",
        communicationEventTypeId: "",
        partyId: "",
        partyIdFrom: "",
        partyIdTo: "",
        returnId: "",
        returnIdSearchType: "Contains",
        statusId: ""

    }
    this.accountsService.getCommunicationEventByOrder(this.req,result).subscribe((res: any) => {
      this.evenTByOrder = res.data;
      this.spinner.hide();

    })

  }
  advanceSearchOrder(isPopUp: boolean): void {
    this.spinner.show();
    this.commByOrder = [];
    const result = {
      communicationEventId: this.advanceSearchOrderBy.communicationEventId ? this.advanceSearchOrderBy.communicationEventId : '',
        communicationEventIdSearchType: this.advanceSearchOrderBy.communicationEventIdSearchType ? this.advanceSearchOrderBy.communicationEventIdSearchType : '',
        communicationEventTypeId:this.advanceSearchOrderBy.communicationEventTypeId ? this.advanceSearchOrderBy.communicationEventTypeId : '',
        partyId: this.advanceSearchOrderBy.partyId ? this.advanceSearchOrderBy.partyId : '',
        partyIdFrom: this.advanceSearchOrderBy.partyIdFrom ? this.advanceSearchOrderBy.partyIdFrom : '',
        partyIdTo: this.advanceSearchOrderBy.partyIdTo ? this.advanceSearchOrderBy.partyIdTo : '',
        orderId: this.advanceSearchOrderBy.orderId ? this.advanceSearchOrderBy.orderId : '',
        orderIdSearchType: this.advanceSearchOrderBy.orderIdSearchType ? this.advanceSearchOrderBy.orderIdSearchType : '',
        statusId: this.advanceSearchOrderBy.statusId ? this.advanceSearchOrderBy.statusId : '',
      

    }
    this.accountsService.getCommunicationByOrder(this.req,result).subscribe((resp: any) => {
        if (resp.success) {
          this.spinner.hide();
          this.commByOrder = resp.data;
        } 
      });
     
  }
  resetAdvanceReset(){
    this.spinner.show();
    this.advanceSearchOrderBy.communicationEventId="";
    this.advanceSearchOrderBy.communicationEventTypeId="";
    this.advanceSearchOrderBy.partyId="";
    this.advanceSearchOrderBy.partyIdFrom="";
    this.advanceSearchOrderBy.partyIdTo="";
    this.advanceSearchOrderBy.orderId="";
    this.advanceSearchOrderBy.statusId="";
    this.spinner.show();
    const result = {
      communicationEventId: "",
      communicationEventIdSearchType: "Contains",
      communicationEventTypeId: "",
      partyId: "",
      partyIdFrom: "",
      partyIdTo: "",
      orderId: "",
      orderIdSearchType: "Contains",
      statusId: ""

    }
    this.accountsService.getCommunicationByOrder(this.req,result).subscribe((res: any) => {
      this.commByOrder = res.data;
      this.spinner.hide();

    })

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  accountDetails(id){
    this.router.navigate(['/crm/main-detail-summary'], { queryParams: { commEventId: id} });

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
