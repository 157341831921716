<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Billing Account {{billingAccountId}}</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="generateBillingPdf()">Create PDF</button>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>

            <div class=" bg-white color-grey">


                <div class="">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">

                                            <div class="w3-card-4 classCardView">
                                                <div class="header-tabs">


                                                    <h4 class=" common-styling hCard">
                                                        Billing Account Information
                                                    </h4>
                                                    <span class="edit-buttons  " style="margin-right: 7px;">
                                                        <a data-toggle="modal" (click)="edit()"
                                                            data-target="#exampleModalCenter">Update</a>
                                                    </span>

                                                </div>
                                                <div class="panel-body">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select py-0 mt-1">
                                                        <div class="color-black container" *ngIf="billingAccount">
                                                            <div class="article-container">
                                                                <div class="row">
                                                                    <div class="col-lg-6">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Name</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{billingAccount?.billingAccountId
                                                                                ?
                                                                                billingAccount?.billingAccountId:'N/A'}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Billing Account
                                                                                Id
                                                                            </p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{billingAccount?.billingAccountId
                                                                                ?
                                                                                billingAccount?.billingAccountId:'N/A'}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Account Limit</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{billingAccount?.accountLimit
                                                                                ? billingAccount?.accountLimit
                                                                                :'N/A'}}</span>

                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Account Currency
                                                                                Uom
                                                                                ID
                                                                            </p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{billingAccount?.accountCurrencyUomId
                                                                                ?
                                                                                billingAccount?.accountCurrencyUomId:'N/A'}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Contact Mech ID
                                                                            </p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{billingAccount?.contactMechId
                                                                                ?
                                                                                billingAccount?.contactMechId:'N/A'}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">From Date</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{billingAccount.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Through Date</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{billingAccount.thruDate|date:'yyyy-MM-dd  hh:mm:ss'}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Description</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{billingAccount?.description
                                                                                ? billingAccount?.description :
                                                                                'N/A'}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Party Billed To
                                                                            </p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{billingAccount?.partyId
                                                                                ? billingAccount?.partyId:'N/A'}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6">

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Available Balance
                                                                            </p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{billingAccount?.availableBalance
                                                                                ? billingAccount?.availableBalance :
                                                                                'N/A'}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="w3-card-4 classCardView">
                                                <div class="header-tabs">

                                                    <h4 class=" common-styling hCard">
                                                        Invoices
                                                    </h4>
                                                </div>
                                                <div class="panel-body">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">

                                                        <div class="borderTable" style="width: 100%;">
                                                            <br>
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="row">

                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="status">Select
                                                                                Status</label>
                                                                        </div>


                                                                        <div class="col-lg-3">
                                                                            <p-dropdown filter="true"
                                                                                [options]="statusIds"
                                                                                optionlabel="label"
                                                                                [(ngModel)]="statusId"
                                                                                placeholder="Select status"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                (ngModelChange)="searchInvoices()">
                                                                            </p-dropdown>
                                                                        </div>



                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">

                                                        <div class="card own-account-table borderTable">
                                                            <ng-container *ngIf="invoices">
                                                                <p-table [value]="invoices" scrollHeight="150px"
                                                                    [paginator]="false" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Invoice Id
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    price
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Invoice Type
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Invoice Date
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="quantity">
                                                                                <div style="color: white;"> Status
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="quantity">
                                                                                <div style="color: white;"> Description
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;"> From Party
                                                                                    ID
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="fromDate">
                                                                                <div style="color: white;"> To Party ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="thruDate">
                                                                                <div style="color: white;"> Total</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="thruDate">
                                                                                <div style="color: white;"> Outstanding
                                                                                    amount</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="thruDate">
                                                                                <div style="color: white;"> Created Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    price
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                    <span class="account-button"
                                                                                        (click)="referToInvoice(product.invoiceId)">
                                                                                        {{product.invoiceId}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.invoiceTypeId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.invoiceDate |date:'yyyy-MM-d
                                                                                hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.statusId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.description}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.partyIdFrom}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <!-- {{product.partyTo }} -->
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <!-- {{product.total |currency :'USD'}} -->
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <!-- {{product.outstanding |currency :'USD'}} -->
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.createdStamp |date:'yyyy-MM-d
                                                                                hh:mm:ss'}}
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <!-- <p class="paginate_data">
                                                                        View per page </p> -->
                                                            </ng-container>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                            <br>

                                            <div class="w3-card-4 classCardView">
                                                <div class="header-tabs">


                                                    <h4 class=" common-styling hCard">
                                                        Payments
                                                    </h4>
                                                </div>
                                                <div class="panel-body">
                                                    <div class="borderTable">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                            <form class="w-100" [formGroup]="createPaymentForm">
                                                                <div class="row">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">From
                                                                                    Party Id</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="partyIds"
                                                                                    optionlabel="label"
                                                                                    formControlName="partyIdFrom"
                                                                                    placeholder="Select Party ">
                                                                                </p-dropdown>
                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label
                                                                                    for="exampleInputEmail1">Organization
                                                                                    Party Id</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="array"
                                                                                    optionlabel="label"
                                                                                    formControlName="partyIdTo"
                                                                                    placeholder="Select Party ">
                                                                                </p-dropdown>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Payment
                                                                                    Type</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="paymentTypes"
                                                                                    optionlabel="label"
                                                                                    formControlName="paymentTypeId"
                                                                                    placeholder="Select Payment Type ">
                                                                                </p-dropdown>
                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Payment
                                                                                    Method Type</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="paymentMethodTypes"
                                                                                    optionlabel="label"
                                                                                    formControlName="paymentMethodTypeId"
                                                                                    placeholder="Select Payment Method Type ">
                                                                                </p-dropdown>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label
                                                                                    for="exampleInputEmail1">Amount<span
                                                                                        class="text-danger">*</span></label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="email" class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    formControlName="amount"
                                                                                    aria-describedby="emailHelp"
                                                                                    >
                                                                                <small class="text-danger"
                                                                                    *ngIf="!!createPaymentForm.controls.amount.invalid && !!createPaymentForm.controls.amount.touched">Amount
                                                                                    is required</small>
                                                                            </div>


                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div class="col-lg-12 col-12 main-submit-button ">
                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                class="btn btn-secondary submit-btn"
                                                                (click)="submit()">Add</button>
                                                            <button class="btn btn-danger cursor-pointer ml-2"
                                                                (click)="reset()">Reset</button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="all-users-infomation border-top-grey font-13 font-medium own-user-select allUserCardDetail">

                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="payments" scrollHeight="150px"
                                                                [paginator]="false" [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Payment ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;"> Type
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;"> Invoice ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="quantity">
                                                                            <div style="color: white;"> Item No
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>

                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="quantity">
                                                                            <div style="color: white;"> Effective Date
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Amount Applied
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>

                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Amount</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span  (click)="detailMainPage(product.paymentId,product.statusId,product.comments,product.partyIdFrom,product.partyIdTo,product.effectiveDate,product.amount)"
                                                                                    class="account-button">{{product.paymentId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.paymentTypeId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.invoiceId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.invoiceItemSeqId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.effectiveDate |date:'yyyy-MM-d
                                                                            hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.amountApplied}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.amount |currency :'USD'}}</td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>


                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                            <br>
                                            <div class="w3-card-4 classCardView">
                                                <div class="header-tabs">


                                                    <h4 class=" common-styling hCard">
                                                        Roles
                                                    </h4>
                                                    <span class="edit-buttons "
                                                        style="margin-right: 7px;">
                                                        <a data-toggle="modal" (click)="editrole()"
                                                            data-target="#exampleModalCenterRole">Create</a>
                                                    </span>
                                                </div>
                                                <div class="panel-body">

                                                    <div
                                                        class="all-users-infomation border-top-grey font-13 font-medium own-user-select allUserCardDetail">
                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="BillingAccountRole"
                                                                scrollHeight="150px" [paginator]="false"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)"
                                                                [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>

                                                                                Party ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;"> RoleType ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">From Date
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="quantity">
                                                                            <div style="color: white;">Is Deleted
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>

                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="quantity">
                                                                            <div style="color: white;"> Through Date
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Action

                                                                            </div>

                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span class="account-button"
                                                                                    (click)="referToparty(product.partyId)">{{product.partyId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.roleTypeId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.fromDate|date:'yyyy-MM-d
                                                                            hh:mm:ss'}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.isDeleted}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.thruDate |date:'yyyy-MM-d
                                                                            hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <button type="submit"
                                                                                (click)="updateRole(product)"
                                                                                data-toggle="modal"
                                                                                data-target="#exampleModalCenterRole"
                                                                                class="btn btn-secondary submit-btn">Update</button>
                                                                            <button type="submit"
                                                                                (click)="deleteBillingAccountRole(product)"
                                                                                class="btn btn-danger ">Delete</button>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <!-- -->
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                            <br>

                                            <div class="divA mb-5">
                                              

                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling hCard">
                                                            Orders
                                                        </h4>
                                                    </div>
                                                    <div id="collapseThree" class="panel-collapse show collapse"
                                                        role="tabpanel" aria-labelledby="headingThree">
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium border-top-grey own-user-select allUserCardDetail">

                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="orders" scrollHeight="150px"
                                                                        [paginator]="false" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Order Id
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        price
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;"> Order
                                                                                        Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Payment
                                                                                        Method Type
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Status
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Max
                                                                                        Amount
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        price
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span
                                                                                            class="account-button">{{product.orderId}}</span>

                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.orderDate |
                                                                                    date:'yyyy-MM-d hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.paymentMethodType}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.paymentStatus}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.maxAmount |currency
                                                                                    :'USD'}}
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <!-- <p class="paginate_data">
                                                                        View per page </p> -->
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                            </div>

                                            <div class="divB">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            Terms
                                                        </h4>
                                                        <span class="edit-buttons "
                                                            style="margin-right: 7px;">
                                                            <a data-toggle="modal" (click)="editrole()"
                                                                data-target="#exampleModalCenterTerm">Create</a>
                                                        </span>
                                                    </div>
                                                    <div class="panel-body">

                                                        <div
                                                            class="all-users-infomation border-top-grey font-13 font-medium own-user-select allUserCardDetail">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="billingAccountTerms"
                                                                    [paginator]="false" [rows]="rows" scrollWidth="100%"
                                                                    scrollHeight="150px"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Term Type <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Value
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">UOM
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;"> Action

                                                                                </div>

                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span class="account-button1">
                                                                                        {{product.termTypeId}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.termValue}}</td>

                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.uomId}}</td>

                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <button type="submit"
                                                                                    (click)="updateTerm(product)"
                                                                                    data-toggle="modal"
                                                                                    data-target="#exampleModalCenterTerm"
                                                                                    class="btn btn-secondary submit-btn">Update</button>
                                                                                <button type="submit"
                                                                                    (click)="deleteBillingAccountTerms(product)"
                                                                                    class="btn btn-danger ">Delete</button>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <!-- -->
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                            </div>
                                            <br>
                                            <br>
                                            <br>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="main-submit-button">
                <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
            </div> -->
        </div>
    </div>
</div>
<div class="modal fade ht40" id="exampleModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Billing Account</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        {{editMode ?'Update':'Create'}} Billing Account </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="billingAccountForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="name"
                                                            placeholder="Enter Name">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Account Type :</label>
                                                    </div>
                                                    <div class="col-lg-3" style="display: flex;">

                                                        <input type="radio"
                                                            style="width: 14px !important;margin-left: 5px;cursor: pointer;"
                                                            name="accountType" value="Commercial"
                                                            id="exampleInputEmail1" formControlName="accountType"
                                                            aria-describedby="emailHelp">
                                                        <span style="margin-left: 5px;">Commercial</span>
                                                        <input type="radio"
                                                            style="width: 14px !important;margin-left: 5px;cursor: pointer;"
                                                            name="accountType" value="Individual"
                                                            id="exampleInputEmail1" formControlName="accountType"
                                                            aria-describedby="emailHelp">
                                                        <span style="margin-left: 5px;">Individual</span>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Account Limit</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="accountLimit"
                                                            placeholder="Account Limit">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA" *ngIf="editMode">
                                                        <label for="billingAccountId">Billing Account Id :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="editMode">
                                                        <span>{{billingAccountId}}</span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Account Currency Uom ID <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="currencyUomIds"
                                                            formControlName="accountCurrencyUomId" optionlabel="label"
                                                            placeholder="Select Currency">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Contact Mech ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="contactMechIds"
                                                            formControlName="contactMechId" optionlabel="label"
                                                            placeholder="Contact Mech Id"></p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">From Date <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-calendar id="exampleInputEmail1" formControlName="fromDate"
                                                            placeholder="Select Date">
                                                        </p-calendar>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-calendar id="exampleInputEmail1" formControlName="thruDate"
                                                            placeholder="Select Date">
                                                        </p-calendar>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Description
                                                            <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Description"
                                                            formControlName="description">
                                                        <small class="text-danger"
                                                            *ngIf="!!billingAccountForm.controls.description.invalid && !!billingAccountForm.controls.description.touched">Description
                                                            is required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">External Account ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="externalAccountId"
                                                            aria-describedby="emailHelp"
                                                            placeholder="External Account Id">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Party Billed To <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="partyIds"
                                                            optionlabel="label" formControlName="partyId"
                                                            placeholder="Select Party">
                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!billingAccountForm.controls.partyId.invalid && !!billingAccountForm.controls.partyId.touched">Party
                                                            is required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Available Balance</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="availableBalance"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Available Balance">
                                                    </div>


                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -4%;">
                                            <button _ngcontent-cta-c128="" type="submit"
                                                class="btn btn-secondary submit-btn"
                                                (click)="submitBilling()">{{editMode ?'Update':'Create'}}</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade ht40" id="exampleModalCenterRole" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterRole" aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Billing Account Roles
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        {{editModeRole ?'Update':'Create'}} Billing Account Roles</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="rolesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Party ID </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" [options]="partyIdArray"
                                                            formControlName="PartyID"
                                                            (onChange)="onChange($event.value)"
                                                            placeholder="Choose Party ID" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="billingAccountId">RoleType ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="PartyRolesArray"
                                                            formControlName="RoleTypeID"
                                                            placeholder="Choose RoleType ID" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">From Date <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <!-- <p-calendar id="exampleInputEmail1">
                                                        </p-calendar> -->

                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="fromDate"
                                                            placeholder="Select Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="thruDate"
                                                            placeholder="Select Date">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Is Deleted
                                                            <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Is Deleted"
                                                            formControlName="IsDeleted">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -4%;">
                                            <button _ngcontent-cta-c128="" *ngIf="!editModeRole" type="submit"
                                                class="btn btn-secondary submit-btn"
                                                (click)="createBillingAccountRole()">Create</button>

                                            <button _ngcontent-cta-c128="" type="submit"
                                                class="btn btn-secondary submit-btn" *ngIf="editModeRole"
                                                (click)="updateBillingAccountRole()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade ht40" id="exampleModalCenterTerm" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTerm" aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Billing Account Terms

                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        {{editTerm ?'Update':'Create'}} Billing Account Terms
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="termForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Term Type </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" [options]="TermTypeIdList"
                                                            formControlName="termTypeId" placeholder="Choose Term Type"
                                                            optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="billingAccountId">UOM
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="currencyUomIds"
                                                            formControlName="uomId" placeholder="Choose UOM ID"
                                                            optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Term Value</label>

                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Term Value"
                                                            formControlName="termValue">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -4%;">
                                            <button _ngcontent-cta-c128="" *ngIf="!editTerm" type="submit"
                                                class="btn btn-secondary submit-btn"
                                                (click)="createBillingAccountTerm()">Create</button>

                                            <button _ngcontent-cta-c128="" type="submit"
                                                class="btn btn-secondary submit-btn" *ngIf="editTerm"
                                                (click)="updateBillingAccountTerm()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>