import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
import { MyContactsService } from "../../crm/contacts/my-contacts/my-contacts.service";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { quoteNotesService } from "../quote-notes.service";
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-quotes-notes',
  templateUrl: './quotes-notes.component.html',
  styleUrls: ['./quotes-notes.component.css']
})
export class QuotesNotesComponent implements OnInit {
  fileUploadForm: FormGroup;
  partyId: any;
  editMode: boolean;
  noteId: string;
  constructor(readonly formBuilder: FormBuilder,
    readonly quoteNotesService: quoteNotesService, 
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly myContactsService: MyContactsService,
    private activatedRoute: ActivatedRoute) {
    this.editMode = false;
    this.noteId = '';
    this.fileUploadForm = this.formBuilder.group({
      noteName: ['', [Validators.required]],
      noteInfo: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params["quoteId"];
      this.noteId = params['noteId'];
    });
    if (this.noteId) {
      this.quoteNotesService.getQuoteNoteById(this.noteId)
        .then(data => {
          if (data.success) {
            this.fileUploadForm.patchValue(data.data);
            this.editMode = true;
          }
        });
    }

  }
  onSubmit(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      if (this.fileUploadForm.valid) {
        const form = { ...this.fileUploadForm.value }
        this.quoteNotesService.createQuoteNote(this.partyId, form).then(res => {
          if (res.success) {
            this.toastr.success(res.data.successMessage);
            this.spinner.hide();

            this.router.navigate(['/crm/quotations/quote-details'], { queryParams: { quotoId: this.partyId } });
          }
        });
      }
    }
    this.spinner.hide();
  }
  update(): void {
    this.spinner.show();
    if (this.fileUploadForm.valid) {
      this.quoteNotesService.updateQuoteNote(this.noteId, this.partyId, this.fileUploadForm.value)
        .then(data => {
          if (data.success) {
            this.toastr.success('Updated');
            this.spinner.hide();
            this.fileUploadForm.reset();
            this.router.navigate(['/crm/quotations/quote-details'], { queryParams: { quotoId: this.partyId } });
          }
        });
    }
    this.spinner.hide();
  }
  navigate(): void {
    this.fileUploadForm.reset();
    this.router.navigate(['/crm/quotations/quote-details'], { queryParams: { quotoId: this.partyId } })

  }

}
