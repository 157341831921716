<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-grey " (click)="cancelSubmit();" style="cursor: pointer;">Week


 
                </span>
                <span class="color-black pl-1" > >Add New Week



 
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show" ><a>
                        Add Week

                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''"  *ngIf="this.show"><a>
                        Update Week

                    </a></li>
                 
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createWeek">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRight"  >
                                                <label for="exampleInputEmail1">
                                                    Calendar Week ID<span style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2"  *ngIf="!this.show">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Calendar Week ID"
                                            formControlName="CalendarWeekID" >

                                            </div>
                                            <div class="col-lg-2"  *ngIf="this.show">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Calendar Week ID"
                                            formControlName="CalendarWeekID" readonly>

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Description</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Description"
                                            formControlName="Description" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRight"  >
                                                <label for="exampleInputEmail1">
                                                    Monday Start Time</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Monday Start Time"
                                            formControlName="MondayStartTime" >

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Monday Capacity</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Monday Capacity"
                                                formControlName="MondayCapacity" >
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRight"  >
                                                <label for="exampleInputEmail1">
                                                    Tuesday Start Time</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Tuesday Start Time"
                                            formControlName="TuesdayStartTime" >

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Tuesday Capacity</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Tuesday Capacity"
                                            formControlName="TuesdayCapacity" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRight"  >
                                                <label for="exampleInputEmail1">
                                                    Wednesday Start Time</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Wednesday Start Time"
                                            formControlName="WednesdayStartTime" >

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Wednesday Capacity</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Wednesday Capacity"
                                            formControlName="WednesdayCapacity" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRight"  >
                                                <label for="exampleInputEmail1">
                                                    Thursday Start Time</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Thursday Start Time"
                                                formControlName="ThursdayStartTime" >

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Thursday Capacity</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Thursday Capacity"
                                            formControlName="ThursdayCapacity" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRight"  >
                                                <label for="exampleInputEmail1">
                                                    Friday Start Time</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Friday Start Time"
                                            formControlName="FridayStartTime" >

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Friday Capacity</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Friday Capacity"
                                                formControlName="FridayCapacity" >
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRight"  >
                                                <label for="exampleInputEmail1">
                                                    Saturday Start Time</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Saturday Start Time"
                                                formControlName="SaturdayStartTime" >

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Saturday Capacity</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Saturday Capacity"
                                            formControlName="SaturdayCapacity" >
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRight"  >
                                                <label for="exampleInputEmail1">
                                                    Sunday Start Time</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Sunday Start Time"
                                            formControlName="SundayStartTime" >

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Sunday Capacity</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Sunday Capacity"
                                                formControlName="SundayCapacity" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 67%;">
                                <button type="submit" *ngIf="!this.show" (click)="onSubmit()"    class="btn btn-secondary submit-btn">Save</button>
                             
                                <button type="submit"  *ngIf="this.show" (click)="onUpdate()"    class="btn btn-secondary submit-btn">Update</button>
                             
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
