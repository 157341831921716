import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { DeparmentsService } from 'src/app/human-resource/departments/departments.service';
import { ReallocationsService } from 'src/app/human-resource/reallocation/reallocations.service';
import { InternalJobService } from 'src/app/human-resource/recuitment/internal-job-posting/internal-job-posting.service';
import { CreateEventFormService } from '../create-employee-application/create-employee-application-service';
import { EmployementApplicationService } from '../employee-application.service';
import { EmployeeApplicationService } from '../employee-application/employee-application.service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-employee-application-summary',
  templateUrl: './employee-application-summary.component.html',
  styleUrls: ['./employee-application-summary.component.css']
})
export class EmployeeApplicationSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton3') closebutton3;
  appId: any;
  pageNo = 1;
  total=0;
  rows = 50;
  activeCategory = 3;
  activeCategoryA = 1;
  pageSize= 100;
  employmentApplication: any;
  public applicationSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  readonly employessSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,

  }
  search="";
  public jobPost = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search

  }
  updateId: any;
  show: boolean;
  internalJobPostList: any;
  appIdArray: any[]=[];
  positionType: any;
  positionArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  statusList: any;
  statusArray: any[]=[];
  updateethrdateStatus: string;
  appSource: any;
  appSourceArray: any[]=[];
  empTypeId: any;
  name: any;
  socialSecurityNumber: any;
  positionAppliedFor: any;
  availability: any;
  driverLicenseNumber: any;
  public departments = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  DepartmentArray: any[]=[];
  departmentList: any;
  EmploymentFormSignature: FormGroup;
  available: any;
  AvailabilityArray: any[]=[];
  transportationArray: { label: string; value: string; }[];
  yesNoArray: { label: string; value: string; }[];
  createEmploymentForm: any;
  fileData: File;
  signatureURL: any;
  employeDate: string;
  DateAvailable: string;
  Expiration: string;
  ExpirationDate: string;
  HireDate: string;
  constructor(
    readonly employeeApplicationService:EmployeeApplicationService,
    readonly toastr:ToastrService,
    readonly reallocationsService:ReallocationsService,
    public addSkillService:AddSkillService,
    readonly internalJobService:InternalJobService,
      private _location: Location,
      readonly deparmentsService: DeparmentsService,
    readonly spinner:NgxSpinnerService,
    readonly router: Router,
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly employementApplicationService: EmployementApplicationService,
    readonly accountsService: AccountsService,
    public datePipe: DatePipe,
    readonly _FormBuilder: FormBuilder,
    
  ) {
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Yes'
      },
      {
        label: 'No',
        value: 'No'
      },

    ];
    this.transportationArray = [
      {
        label: 'Own Vehicle',
        value: 'Own Vehicle'
      },
      {
        label: 'Public Transport',
        value: 'Public Transport'
      },


    ];

    this.createEmploymentForm = this._FormBuilder.group({
      crime: [''],
      name: [''],
      socailSecurityNumber: [''],
      address: [''],
      telephoneNo: [''],
      OtherPhonenumber: [''],
      reason: [''],
      empDate: [''],


      PositionAppliedFor: [''],
      DateAvailable: [''],
      referred: [''],
      payRate: [''],
      available: [''],


      SchoolAddress: [''],
      degree: [''],
      SchoolAddressTwo: [''],
      degreeTwo: [''],
      SchoolAddressThree: [''],
      degreeThree: [''],
      SchoolAddressFour: [''],
      degreeFour: [''],


      ProfessionalLicense: [''],
      Expiration: [''],
      applicationFilled: [''],
      explain: [''],
      transportation: [''],
      license: [''],
      LicenseNumber: [''],
      StateofIssue: [''],
      ExpirationDate: [''],


      nameOne: [''],
      nameTwo: [''],
      addressOne: [''],
      secondAddress: [''],
      TelephoneNumber: [''],
      TelephoneNumberTwo: [''],


      Employer: [''],
      TypeofBusiness: [''],
      EmpAddress: [''],
      Phone: [''],
      TitlesDuties: [''],
      RatePay: [''],
      PeriodEmployed: [''],
      ReasonforLeaving: [''],
      SupervisorName: [''],


      position: [''],
      Department: [''],
      dateHire: [''],
      Salary: [''],
      Comments: [''],

      Signature: [''],
      signDate: [''],

    });

    this.EmploymentFormSignature = this._FormBuilder.group({
      Signature: [''],
      signDate: [''],

    });
   }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    // this.activatedRoute.queryParams.subscribe(params=> {
    //   this.appId = params["appId"];
    // });

    this.activatedRoute.queryParams.subscribe(params=> {
      this.empTypeId = params["empTypeId"];
     
    });
    this.getEmployeeApplication();

    this.createEventFormService.employee_application_form=null;
    this.createEventFormService.employeeApplicationForm(this.createEventFormService.employee_application_form);
    this.statusId();
    this.appSourceTypeList();
    this.getPositionType();
    this.getPartyId();
    this.getInternalJobDropdwon();
    this.activatedRoute.queryParams.subscribe(params=> {
      this.updateId = params["appId"];
      if(this.updateId){
        this.show=true;
        this.getEmployeeApplicationNew();
      }
      else {
        this.show=false;
      }
    })
    this.findApplicationEmploymentId();
    this.getAvailabilty() ;
    this.getdepartmentsList() ;
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  

  updateEmploymentApp(){
    this.spinner.show();
    //this.resumeDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
  
  const formdata={
    "name": this.createEmploymentForm.value.name,
    "empTypeId": this.empTypeId,
    "address": this.createEmploymentForm.value.address,
    "socialSecurityNumber": this.createEmploymentForm.value.socailSecurityNumber,
    "telephoneNumber": this.createEmploymentForm.value.telephoneNo,
    "phoneNumber": this.createEmploymentForm.value.OtherPhonenumber,
    "date": this.createEmploymentForm.value.empDate,
    "felonyCrime": this.createEmploymentForm.value.crime,
    "description": this.createEmploymentForm.value.reason,


    "positionAppliedFor": this.createEmploymentForm.value.PositionAppliedFor,
    "availableDate": this.createEmploymentForm.value.DateAvailable,
    "desirePayRate": this.createEmploymentForm.value.payRate,
    "availability": this.createEmploymentForm.value.available,
    "referredToUs": this.createEmploymentForm.value.referred,

    "schoolAddress1": this.createEmploymentForm.value.SchoolAddress,
    "schoolAddress2": this.createEmploymentForm.value.SchoolAddressTwo,
    "schoolAddress3": this.createEmploymentForm.value.SchoolAddressThree,
    "schoolAddress4": this.createEmploymentForm.value.SchoolAddressFour,
    "degree1": this.createEmploymentForm.value.degree,
    "degree2": this.createEmploymentForm.value.degreeTwo,
    "degree3": this.createEmploymentForm.value.degreeThree,
    "degree4": this.createEmploymentForm.value.degreeFour,

    "professionalLicense": this.createEmploymentForm.value.ProfessionalLicense,
    "expirationDate": this.createEmploymentForm.value.Expiration,
    "applicationHereBefore": this.createEmploymentForm.value.applicationFilled,
    "explain": this.createEmploymentForm.value.explain,
    "meansOfTransportation": this.createEmploymentForm.value.transportation,
    "driverLicense": this.createEmploymentForm.value.license,
    "driverLicenseNumber": this.createEmploymentForm.value.LicenseNumber,
    "stateOfIssue": this.createEmploymentForm.value.StateofIssue,
    "expirationDate1": this.createEmploymentForm.value.ExpirationDate,

    "referencesAddress1": this.createEmploymentForm.value.addressOne,
    "referencesAddress2": this.createEmploymentForm.value.secondAddress,
    "referencesName1": this.createEmploymentForm.value.nameOne,
    "referencesName2": this.createEmploymentForm.value.nameTwo,

    "referencesTelephoneNumber2": this.createEmploymentForm.value.TelephoneNumberTwo,
    "referencestTelephoneNumber1": this.createEmploymentForm.value.TelephoneNumber,

    "employer": this.createEmploymentForm.value.Employer,
    "typeOfBusiness": this.createEmploymentForm.value.TypeofBusiness,
    "employmentAddress": this.createEmploymentForm.value.EmpAddress,
    "employmentPhone": this.createEmploymentForm.value.Phone,
    "titlesAndDuties": this.createEmploymentForm.value.TitlesDuties,
    "ratePay": this.createEmploymentForm.value.RatePay,
    "periodEmployed": this.createEmploymentForm.value.PeriodEmployed,
    "reasonForLeaving": this.createEmploymentForm.value.ReasonforLeaving,
    "supervisorName": this.createEmploymentForm.value.SupervisorName,


    "comments": this.createEmploymentForm.value.Comments,
    "departments": this.createEmploymentForm.value.Department,
    "position": this.createEmploymentForm.value.position,
    "hireDate": this.createEmploymentForm.value.dateHire,
    "salary": this.createEmploymentForm.value.Salary,
    "signatureUrl": this.signatureURL,


   
  }
  
    this.accountsService.updateApplicationEmployment(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.findApplicationEmploymentId();

      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }


  edit(){
    this.employeDate = this.datePipe.transform( this.employmentApplication.data.date, "yyyy-MM-dd");
    this.DateAvailable = this.datePipe.transform( this.employmentApplication.data.availableDate, "yyyy-MM-dd");
    this.Expiration = this.datePipe.transform( this.employmentApplication.data.expirationDate, "yyyy-MM-dd");
    this.ExpirationDate = this.datePipe.transform( this.employmentApplication.data.expirationDate1, "yyyy-MM-dd");
    this.HireDate = this.datePipe.transform( this.employmentApplication.data.hireDate, "yyyy-MM-dd");
  

    setTimeout(() => {
      const formValue = this.createEmploymentForm;
    formValue.patchValue({
      crime:this.employmentApplication.data.felonyCrime,
      name: this.employmentApplication.data.name,
      socailSecurityNumber:this.employmentApplication.data.socialSecurityNumber,
      address: this.employmentApplication.data.address,
      telephoneNo:this.employmentApplication.data.telephoneNumber,
      OtherPhonenumber: this.employmentApplication.data.phoneNumber,
      reason: this.employmentApplication.data.description,
      empDate:this.employeDate,

      PositionAppliedFor:this.employmentApplication.data.positionAppliedFor,
      DateAvailable: this.DateAvailable ,
      referred: this.employmentApplication.data.referredToUs,
      payRate: this.employmentApplication.data.desirePayRate,
      available: this.employmentApplication.data.availability,
     
      SchoolAddress: this.employmentApplication.data.schoolAddress1,
      degree: this.employmentApplication.data.degree1,
      SchoolAddressTwo: this.employmentApplication.data.schoolAddress2,
      degreeTwo: this.employmentApplication.data.degree2,
      SchoolAddressThree: this.employmentApplication.data.schoolAddress3,
      degreeThree: this.employmentApplication.data.degree3,
      SchoolAddressFour: this.employmentApplication.data.schoolAddress4,
      degreeFour: this.employmentApplication.data.degree4,

      ProfessionalLicense: this.employmentApplication.data.professionalLicense,
      Expiration: this.Expiration,
      applicationFilled: this.employmentApplication.data.applicationHereBefore,
      explain: this.employmentApplication.data.explain,
      transportation: this.employmentApplication.data.meansOfTransportation,
      license: this.employmentApplication.data.driverLicense,
      LicenseNumber:this.employmentApplication.data.driverLicenseNumber,
      StateofIssue: this.employmentApplication.data.stateOfIssue,
      ExpirationDate:   this.ExpirationDate,

      nameOne: this.employmentApplication.data.referencesName1,
      nameTwo: this.employmentApplication.data.referencesName2,
      addressOne: this.employmentApplication.data.referencesAddress1,
      secondAddress: this.employmentApplication.data.referencesAddress2,
      TelephoneNumber: this.employmentApplication.data.referencestTelephoneNumber1,
      TelephoneNumberTwo: this.employmentApplication.data.referencesTelephoneNumber2,

      Employer: this.employmentApplication.data.employer,
      TypeofBusiness: this.employmentApplication.data.typeOfBusiness,
      EmpAddress: this.employmentApplication.data.employmentAddress,
      Phone: this.employmentApplication.data.employmentPhone,
      TitlesDuties: this.employmentApplication.data.titlesAndDuties,
      RatePay: this.employmentApplication.data.ratePay,
      PeriodEmployed: this.employmentApplication.data.periodEmployed,
      ReasonforLeaving: this.employmentApplication.data.reasonForLeaving,
      SupervisorName: this.employmentApplication.data.supervisorName,
     
      position:  this.employmentApplication.data.position,
      Department:this.employmentApplication.data.departments,
      dateHire: this.HireDate,
      Salary: this.employmentApplication.data.salary,
      Comments: this.employmentApplication.data.comments,

      Signature: this.employmentApplication.data.signatureUrl,

    });
    }, 2000);
  }

  onSubmitSign() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.attachFile(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.signatureURL = res.data.URL;
        this.closebutton3.nativeElement.click();


      }
    }, (err) => {
      this.toastr.error(err.message);
      this.spinner.hide();
    })

  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];

  }

  getAvailabilty() {
    this.spinner.show();
    this.accountsService.getAvailabilty().subscribe(res => {
      this.available = res.data[0].data;
      this.spinner.hide();
      for (const value of this.available) {
        this.AvailabilityArray.push({
          label: value.availability,
          value: value.availability
        })
      }
    })
  }

  getdepartmentsList() {
    
    this.spinner.show();
    const dept = {
      companyId: "",
      companyIdSearchType: "Contains",
      departmentId: "",
      departmentIdSearchType: "Contains",
      departmentName: "",
      departmentNameSearchType: "Contains"
    }
    this.deparmentsService.getDepartment(this.departments, dept).subscribe((res: any) => {
      this.departmentList = res.data;
      this.spinner.hide();
      for (const value of this.departmentList) {
        this.DepartmentArray.push({
          label: value.departmentName,
          value: value.departmentName
        })
      }
    })

  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  findApplicationEmploymentId() {
    this.accountsService.findApplicationEmploymentId(this.empTypeId).subscribe((res: any) => {
      this.spinner.show();
      this.employmentApplication = res.data[0];
      this.EmploymentFormSignature = res.data[0];
      this.spinner.hide();
    })
  }


  getInternalJobDropdwon() {
    this.spinner.show();
    const jobList ={
      applicationDateFrom: "",
      applicationDateTo: "",
      applicationId: "",
      applyingPartyId: "",
      approverPartyId: "",
      jobRequisitionId: "",
      statusId: "",
      applicationDateFromSearchType: "Equals",
      applicationDateToSearchType: "Equals"
    }
   this.internalJobService.getInternalJobList(this.jobPost,jobList).subscribe((res:any)=> {
     this.internalJobPostList = res.data;
     this.spinner.hide();
     for(const value of  this.internalJobPostList){
      this.appIdArray.push({
        label:value.applicationId,
        value:value.applicationId
      })
  }
   })
  
  }
  getPositionType(){
    this.spinner.show();
    const emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId:"",
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.reallocationsService.getEmployeePosition(this.employessSize,emplPosition).subscribe(res=> {
      
      this.positionType = res.data;
      this.spinner.hide();
      for(const value of this.positionType){
        this.positionArray.push({
          label:value.emplPositionId,
          value:value.emplPositionId
        })
      }
    })
   
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res=> {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.personId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }
   statusId(){
    this.spinner.show();
     this.employeeApplicationService.getStatusList().subscribe(res=> {
       this.statusList = res.data;
       this.spinner.hide();
       for(const value of this.statusList){
         this.statusArray.push({
           label:value.description,
           value:value.statusId
         })
       }
     })
   
   }
   getEmployeeApplicationNew(){
  
    this.spinner.show();
    const data= {
       "applicationDateFrom": "",
       "applicationDateFromSearchType": "Contains",
       "applicationDateTo": "",
       "applicationDateToSearchType": "Contains",
       "applicationId":this.updateId,
       "applyingPartyId": "",
       "emplPositionId": "",
       "employmentAppSourceTypeId": "",
       "referredByPartyId": "",
       "statusID": [
         ""
       ]
     }
     this.employementApplicationService.getEmpJobList(this.applicationSize,data).subscribe(res=> {
      // this.employmentApplication=res.data;
      this.spinner.hide();
      setTimeout(() => {
        const formValue =  this.createEventFormService.employee_application_form;
        const date=this.datePipe.transform(res.data[0].applicationDate,"yyyy-MM-dd");
      formValue.patchValue({
        applicationId:res.data[0].applicationId,
        applicationDate:date,
        applyingPartyId:res.data[0].applyingPartyId,
        emplPositionId: res.data[0].emplPositionId,
        employmentAppSourceTypeId: res.data[0].employmentAppSourceTypeId,
        statusId:res.data[0].statusId,
        referredByPartyId:res.data[0].referredByPartyId
       
      })
      }, 3000);
     
     })
     
   }
   onUpdate(){
    this.spinner.show();
    const thrudate=this.createEventFormService.employee_application_form.get('applicationDate').value;
    this.updateethrdateStatus=this.datePipe.transform(thrudate,"yyyy-MM-dd hh:mm:ss");
     const formDate={
      applicationDate: this.updateethrdateStatus,
      applicationId: this.createEventFormService.employee_application_form.value.applicationId,
      applyingPartyId:this.createEventFormService.employee_application_form.value.applyingPartyId,
      emplPositionId: this.createEventFormService.employee_application_form.value.emplPositionId,
      employmentAppSourceTypeId: this.createEventFormService.employee_application_form.value.employmentAppSourceTypeId,
      referredByPartyId: this.createEventFormService.employee_application_form.value.referredByPartyId,
      statusId:this.createEventFormService.employee_application_form.value.statusId
     }
  
this.employeeApplicationService.updateEmployee(formDate,this.updateId).subscribe((res:any)=> {
  if(res.success) {
    this.toastr.success(res.data.successMessage);
    this.spinner.hide();
    this.closebutton.nativeElement.click();
    this.router.navigate(['/hr/employee-application'])
  }
  else if(res.success == false) {
    this.toastr.error("Error");
    this.spinner.hide();
  }
},(err) => {
  this.spinner.hide();
  for(const data of err.error.errors){
    this.toastr.error(data.fieldName + ' ' + data.fieldError)
  }
})

   }

   appSourceTypeList(){
    this.spinner.show();
  this.employeeApplicationService.getAppsourceList().subscribe(res=> {
    this.appSource = res.data;
    this.spinner.hide();
    for(const data of this.appSource){
      this.appSourceArray.push({
        label:data.description,
        value:data.employmentAppSourceTypeId
      })
    }
  })
 
   }
 
  getEmployeeApplication() {
    this.spinner.show();
    const data = {
      "applicationDateFrom": "",
      "applicationDateFromSearchType": "Contains",
      "applicationDateTo": "",
      "applicationDateToSearchType": "Contains",
      "applicationId":this.appId,
      "applyingPartyId": "",
      "emplPositionId": "",
      "employmentAppSourceTypeId": "",
      "referredByPartyId": "",
      "statusID": [
        ""
      ]
    }
    this.employementApplicationService.getEmpJobList(this.applicationSize, data).subscribe(res => {
      this.employmentApplication = res.data[0];
      this.spinner.hide();
    })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
