<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Facility </span>
                <span class="color-black pl-1"> > Create New</span>
            </div>
            <div class=" bg-white color-grey"> 
                <div class="port-header-contact create-lead font-medium mb-0">
                    Facility Group
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <div class="panel panel-default">
                                                <div class="panel-heading" role="tab" id="headingOne">
                                                    <h4 class="panel-title">
                                                        <a role="button" data-toggle="collapse" data-parent="#accordion"
                                                            href="#collapseOne" aria-expanded="true"
                                                            aria-controls="collapseOne">
                                                            New facility Group
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseOne" class="panel-collapse show collapse in"
                                                    role="tabpanel" aria-labelledby="headingOne">
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Facility Group Type
                                                                        ID</label>
                                                                    <div class="">
                                                                        <p-dropdown filter="true" optionLabel="label">
                                                                            <ng-template let-item
                                                                                pTemplate="selectedItem">
                                                                                <span class="label-info"
                                                                                    style="margin-left: .5em">{{item.label}}</span>
                                                                            </ng-template>
                                                                            <ng-template let-car pTemplate="item">
                                                                                <div class="ui-helper-clearfix"
                                                                                    style="position: relative;height: 25px;">
                                                                                    <div
                                                                                        style="font-size:14px;margin-top:4px">
                                                                                        {{car.label}}
                                                                                    </div>
                                                                                </div>
                                                                            </ng-template>
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Primary Parent Group
                                                                        ID</label>
                                                                    <div class="">
                                                                        <p-dropdown filter="true" optionLabel="label">
                                                                            <ng-template let-item
                                                                                pTemplate="selectedItem">
                                                                                <span class="label-info"
                                                                                    style="margin-left: .5em">{{item.label}}</span>
                                                                            </ng-template>
                                                                            <ng-template let-car pTemplate="item">
                                                                                <div class="ui-helper-clearfix"
                                                                                    style="position: relative;height: 25px;">
                                                                                    <div
                                                                                        style="font-size:14px;margin-top:4px">
                                                                                        {{car.label}}
                                                                                    </div>
                                                                                </div>
                                                                            </ng-template>
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Facility Group
                                                                        Name</label>
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Description </label>
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-12 main-submit-button "><button
                                                                _ngcontent-cta-c128="" type="submit"
                                                                class="btn btn-secondary submit-btn">Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="panel panel-default">
                                                <div class="panel-heading" role="tab" id="headingTwo">
                                                    <h4 class="panel-title">
                                                        <a class="collapsed" role="button" data-toggle="collapse"
                                                            data-parent="#accordion" href="#collapseTwo"
                                                            aria-expanded="false" aria-controls="collapseTwo">
                                                            Rollups
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseTwo" class="panel-collapse show collapse"
                                                    role="tabpanel" aria-labelledby="headingTwo">
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Parent Facility
                                                                        Group ID
                                                                        ID</label>
                                                                    <div class="d-flex">
                                                                        <p-dropdown filter="true" optionLabel="label">
                                                                            <ng-template let-item
                                                                                pTemplate="selectedItem">
                                                                                <span class="label-info"
                                                                                    style="margin-left: .5em">{{item.label}}</span>
                                                                            </ng-template>
                                                                            <ng-template let-car pTemplate="item">
                                                                                <div class="ui-helper-clearfix"
                                                                                    style="position: relative;height: 25px;">
                                                                                    <div
                                                                                        style="font-size:14px;margin-top:4px">
                                                                                        {{car.label}}
                                                                                    </div>
                                                                                </div>
                                                                            </ng-template>
                                                                        </p-dropdown>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">From Date</label>
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Through Date</label>
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Sequence Num
                                                                    </label>
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-12 main-submit-button "><button
                                                                _ngcontent-cta-c128="" type="submit"
                                                                class="btn btn-secondary submit-btn">Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="panel panel-default">
                                                <div class="panel-heading" role="tab" id="headingThree">
                                                    <h4 class="panel-title">
                                                        <a class="collapsed" role="button" data-toggle="collapse"
                                                            data-parent="#accordion" href="#collapseThree"
                                                            aria-expanded="false" aria-controls="collapseThree">
                                                            Group Rollup
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseThree" class="panel-collapse show collapse"
                                                    role="tabpanel" aria-labelledby="headingThree">
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">
                                                                        Facility Group ID</label>
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">From Date</label>
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Through Date
                                                                    </label>
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Sequence Num</label>
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-12 main-submit-button "><button
                                                                _ngcontent-cta-c128="" type="submit"
                                                                class="btn btn-secondary submit-btn">Add</button></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 