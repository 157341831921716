import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr'; import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { FeaturesService } from '../features.service';

@Component({
  selector: 'app-feature-group',
  templateUrl: './feature-group.component.html',
  styleUrls: ['./feature-group.component.css']
})
export class FeatureGroupComponent implements OnInit {
  total=0;
  activeCategory = 2;
  activeCat=5;
  rowNumber=0;
  activeCategoryMain=3;
  pageSize = 10;
  @ViewChild('closebutton') closebutton;
  rows = 50;
  featureGroup: any;
  editMode: boolean;
  description: string;
  productFeatureGroupId: string;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    readonly router: Router,
    readonly _FeaturesService: FeaturesService,
    readonly spinner: NgxSpinnerService,  
      private _location: Location,
  
    readonly _ToastrService: ToastrService
  ) {
    this.editMode = false;
    this.description = '';
    this.productFeatureGroupId = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this.getFeatureGroup();
  }
  getFeatureGroup(): void {
    this.spinner.show();
    this._FeaturesService.getFeatureGroup({ pageNo: 1, pageSize: 200 }, '')
      .then(data => {
        this.featureGroup = data.data;
        this.spinner.hide();
      });
     
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  edit(productFeatureGroupId: string, description: string): void {
    this.editMode = true;
    this.description = description;
    this.productFeatureGroupId = productFeatureGroupId;
  }
  
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      if (this.description) {
        this._FeaturesService.updateFeatureGroup({ description: this.description }, this.productFeatureGroupId)
          .then(data => {
            if (data.success) {
              this.description = '';
              this._ToastrService.success('Updated');
              this.closebutton.nativeElement.click();
              this.spinner.hide();
              this.getFeatureGroup();
            }
          });
      }
    } else {
      if (this.description) {
        this._FeaturesService.createFeatureGroup({ description: this.description })
          .then(data => {
            if (data.success) {
              this.description = '';
              this._ToastrService.success('Created');
              this.closebutton.nativeElement.click();
              this.spinner.hide();
              this.getFeatureGroup();
            }
          });
      }
    }
  
  }
  featureType(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  featureCategory(){
    this.router.navigate(['/facilities/catalog/feature-category']);
  }
  featureGroupUrl(){
    this.router.navigate(['/facilities/catalog/feature-group']);
  }

  featureInteraction(){
    this.router.navigate(['/facilities/catalog/feature-interaction']);
  }

  reset(): void {
    this.editMode = false;
    this.description = '';
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
