import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe,Location } from '@angular/common';
@Component({
    selector: 'app-create-account-job-info',
    templateUrl: './create-account-job-info.component.html'
})
export class CreateAccountJobInfoComponent implements OnInit {
    editMode: boolean;
    jobInfoForm: FormGroup;
    partyId: string;
    jobInfo: any;
    constructor(
        readonly _CRMAccountService: CRMAccountService,
        readonly  _Router: Router,private _location: Location,
        readonly _ToastrService: ToastrService,
        readonly spinner:NgxSpinnerService,
        readonly _FormBuilder: FormBuilder,
        readonly _ActivatedRoute: ActivatedRoute
    ) {
        this.editMode = false;
        this.partyId = '';
        this.jobInfoForm = this._FormBuilder.group({
            description: [''],
            endDate: [''],
            jobStatus: [''],
            jobType: [''],
            partyId: [''],
            productType: [''],
            projectedEndDate: [''],
            serviceType: [''],
            startDate: ['']
        });
    }
    ngOnInit(): void {
        this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
        this.editMode = this._ActivatedRoute.snapshot.queryParams.isEditable === 'true' ? true : false;
        if (this.editMode) {
            this.spinner.show();
            this._CRMAccountService.getAccountById(this.partyId)
                .then(data => {
                    if (data.success) {
                        this.spinner.hide();
                        this.jobInfo = data.data.jobInformation;
                        this.jobInfoForm.patchValue({
                            ...data.data.jobInformation,
                            startDate: data.data.jobInformation.startDate ? moment(data.data.jobInformation.startDate).format('YYYY-MM-DD') : '',
                            endDate: data.data.jobInformation.endDate ? moment(data.data.jobInformation.endDate).format('YYYY-MM-DD') : '',
                            projectedEndDate: data.data.jobInformation.projectedEndDate ? moment(data.data.jobInformation.projectedEndDate).format('YYYY-MM-DD') : ''
                        });
                       
                    }

                });
               
        }
    }
    update(): void {
        this.spinner.show();
        this._CRMAccountService.updateJobInformation({
            ...this.jobInfo, ...this.jobInfoForm.value, ...{
                partyId: this.partyId,
                startDate: this.jobInfoForm.value.startDate ? moment(this.jobInfoForm.value.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
                endDate: this.jobInfoForm.value.endDate ? moment(this.jobInfoForm.value.endDate).format('YYYY-MM-DD HH:mm:ss') : '',
                projectedEndDate: this.jobInfoForm.value.projectedEndDate ? moment(this.jobInfoForm.value.projectedEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.cancelSubmit();
                }
            });
        
    }
    submit(): void {
        this.spinner.show();
        this._CRMAccountService.createJobInformation({
            ...this.jobInfoForm.value, ...{
                partyId: this.partyId,
                startDate: this.jobInfoForm.value.startDate ? moment(this.jobInfoForm.value.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
                endDate: this.jobInfoForm.value.endDate ? moment(this.jobInfoForm.value.endDate).format('YYYY-MM-DD HH:mm:ss') : '',
                projectedEndDate: this.jobInfoForm.value.projectedEndDate ? moment(this.jobInfoForm.value.projectedEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Created');
                    this.spinner.hide();
                    this.cancelSubmit();
                }
            });
           
    }

    cancelSubmit(): void {
        this.jobInfoForm.reset();
        this._location.back();
    }
}