import { DatePipe } from '@angular/common';
import { Component, OnInit ,ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import Swal from 'sweetalert2'
import { detailedContactsService } from 'src/app/crm/contacts/detailed-contacts-summary/detailed-contacts-summary.service';
import * as moment from 'moment';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SkillService } from 'src/app/human-resource/skills/skill.service';
import { EmployeeService } from 'src/app/human-resource/employees/employess.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { EmployementApplicationService } from 'src/app/employeeApplication/employee-application.service';
import { EmployeeApplicationService } from 'src/app/employeeApplication/employee-application/employee-application.service';
import { CreateUserLoginComponent } from 'src/app/accounting/create-user-login/create-user-login.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PersonalUpdateComponent } from 'src/app/human-resource/update-employe-personalInfo/personal-update/personal-update.component';
import { CreateCreditCardComponent } from 'src/app/crm/accounts/detailed-accounts-summary/create-credit-card/create-credit-card.component';
import { PartyCreateIdentificationNumberComponent } from 'src/app/accounting/party-create-identification-number/party-create-identification-number.component';
import { PartyRoleTypeComponent } from 'src/app/accounting/party-role-type/party-role-type.component';
import { PartyAddToRoleComponent } from 'src/app/accounting/party-add-to-role/party-add-to-role.component';
import { NewRelationshipTypeComponent } from 'src/app/accounting/new-relationship-type/new-relationship-type.component';
import { AddOtherPartyRelationshipComponent } from 'src/app/accounting/add-other-party-relationship/add-other-party-relationship.component';
import { EditPartyRatesComponent } from 'src/app/accounting/edit-party-rates/edit-party-rates.component';
import { AddPartySkillComponent } from 'src/app/accounting/add-party-skill/add-party-skill.component';
import { AddPartyContentComponent } from 'src/app/accounting/add-party-content/add-party-content.component';
import { AddPartySegmentRoleComponent } from 'src/app/accounting/add-party-segment-role/add-party-segment-role.component';
import { CreateClassificationPartyComponent } from '../create-classification-party/create-classification-party.component';
import { AddPartyResumesComponent } from 'src/app/accounting/add-party-resumes/add-party-resumes.component';
import { AddRelatedAccountComponent } from 'src/app/accounting/add-related-account/add-related-account.component';
import { AccountFaxNumberComponent } from 'src/app/crm/accounts/detailed-accounts-summary/account-fax-number/account-fax-number.component';
import { AccountPostalAddressComponent } from 'src/app/crm/accounts/detailed-accounts-summary/account-postal-address/account-postal-address.component';
import { CreatePartyContactComponent } from '../create-party-contact/create-party-contact.component';
import { CreatePartyEmployeApplicationComponent } from '../create-party-employe-application/create-party-employe-application.component';
import { CreateProductStoreComponent } from '../create-product-store/create-product-store.component';
import { CreateTaxInfoComponent } from '../create-tax-info/create-tax-info.component';
import { CreateGiftCardComponent } from 'src/app/crm/accounts/detailed-accounts-summary/create-gift-card/create-gift-card.component';
import { CreateEFTAccountComponent } from 'src/app/crm/accounts/detailed-accounts-summary/create-eft-account/create-eft-account.component';
import { CreateBillingAccountComponent } from 'src/app/crm/accounts/detailed-accounts-summary/create-billing-account/create-billing-account.component';
import { CreateCheckAccountComponent } from 'src/app/crm/accounts/detailed-accounts-summary/create-check-account/create-check-account.component';
import { AttachContentComponent } from 'src/app/accounting/attach-content/attach-content.component';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
// import { BsModalRef } from 'ngx-bootstrap/modal/modal-options.class';
//import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-profile-party-quick-link',
  templateUrl: './profile-party-quick-link.component.html',
  styleUrls: ['./profile-party-quick-link.component.css']
})
export class ProfilePartyQuickLinkComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton7') closebutton7;

  faEdit = faEdit;
  faTrash = faTrash;
  shoppingList:FormGroup;
  relatedContact:any[]=[];
  createJournal:FormGroup;
  createVendor:FormGroup;
  findPartyForm:FormGroup;
  activeCategory=3;
  products3: any;
  paymentPartyGroup: any;
  downloading: boolean;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search = ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  FinancialTransaction:any[]=[];
  accountData:any;
  budgetId: any;
  budgetHeader: any;
  budgetStatus: any;
  budgetRole: any;
  budgetItem: any;
  budgetReview: any;
  budgetItemList: any;
  budgetRoleList: any;
  budgetReviewList: any;
  status: string;
  taxAuthPartyId: any;
  roleList: any;
  accountDetails: any;
  isAdditionalDetail: boolean;
  isJobDetail: boolean;
  contactInformation: any[]=[];
  accountName: any;
  addr: string;
  activeCategoryB = 1;
  emailId: any;
  phoneNumber: string;
  contentList: any;
  billingAccounts: any[];
  paymentMethods: any[];
  skillList: any;
  public employeeDetails: any;
  public employeeDetail: any;
  employeeDetailArray: any = [];
  departmentList: any;
  departmantArray: any = [];
  departmentId: any;
  department: any;
  demoName: any;
  demoId: any;
  employeeDetailArrayEmail: any = [];
  employeeDetailArrayPhone: any = [];
  employeeDetailRelatedAccount: any;
  emergencyContact: any;
  personId: any;
  createLinkParty:FormGroup;
  partyIdArray: any[]=[];
  IdentificationList: any;
  PartyResume: any;
  SegmentRole: any;
  PartyClassification: any;
  PartyRate: any;
  UserLoginData: any;
  PartyAttribute: any;
  PartyContactList: any;
  employmentApplication: any;
  productStoresListArray: any[]=[];
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  AllProductStoreRole: any;
  Visitors: any;
  PartyLoyaltyPoints: any;
  Person: any;
  ProductStore: any;
  Preferences: any;
  RelationshipPartyIdTo: any;
  findParty: string;
  partyGroup: boolean;
  PartyGroup: any;
  AllFinanceHistory: any;
  ShoppingListArray: any[]=[];
  TaxInfo: any;
  PartyIcsAvsOverRide: any[]=[];
  statusIdPerson: any;
  externalId: any;
  relatedContactInfo: any;
  PartyContentData: any;
  showUpdateModal: boolean = false;
  showUserModal: boolean = false;
  showAttrModal: boolean = false;
  showAccModal: boolean = false;
  showaddrModal: boolean = false;
  showtelecomModal: boolean = false;
  showNoteModal: boolean = false;
  showContentModal: boolean = false;
  showIdentificationModal: boolean = false;
  bsModalRef: any;
  showCreditModal: boolean = false;
  showGiftModal: boolean = false;
  showEftModal: boolean = false;
  showBillModal: boolean = false;
  userPermissions: any;
  Hidebtn: boolean=false;
  isPublic=[{
    "label":"Yes",
    "value":"Y"
  },
  {
    "label":"No",
    "value":"N"
  }]
  PartyContentTypeIdList: any;
  PartyContentTypeIdListArray: any[]=[];
  PartyStatusIdList: any;
  PartyStatusIdListArray: any[]=[];
  actualfromdate: any;
  actualthroughdate: string;
  contentId: any;
  contentTypeId: any;
  dataResourceId: any;
  fromDate: any;
  statusId:any
  thruDate: any;
  editgetEstimateFrom: string;
  egetEstimateTo: string;
   selectedFileName: any;
  partyContentData: any=[];
  partyContentArray: any[]=[];
  fileData: File;
  partyContentTypeId: any;
  Editform: FormGroup;
  DataCategoryIdList: any;
  DataCategoryIdListArray: any[]=[];
  show: boolean;
  fileUploadFormNote: FormGroup;
  partyAttributes: FormGroup;
  showAttribute: boolean;
  showUpdateNoteBtn: boolean;
  notePro: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly _detailedContactsService: detailedContactsService,
    readonly toastr: ToastrService,
    readonly skillService: SkillService,
    readonly employeeService: EmployeeService,
    readonly myContactsService: MyContactsService,
    readonly router: Router,
    readonly employementApplicationService: EmployementApplicationService,
    readonly addSkillService: AddSkillService,
    readonly _CRMAccountService: CRMAccountService,
    readonly employeeApplicationService: EmployeeApplicationService,
    readonly _FormBuilder: FormBuilder,
    public modalService: BsModalService) {
      this.createVendor=this._FormBuilder.group({
        policies: [''],
        logo: [''],
        CompanyTitle: [''],
        ManifestCompany: [''],
       
        
      });
      this.Editform = this._FormBuilder.group({
        file: [''],
        partyContentTypeId: [''],
        fromDate:[''],
        throughDate:[''],
        ContentTypeID:[''],
        StatusID:[''],
        DataCategoryID:[''],
        IsPublic:['']
       
        
      });
      this.createJournal = this._FormBuilder.group({
        journalName: [''],
       
        
      });
      this.createLinkParty=this._FormBuilder.group({
        partyIdTo: [''],
        partyIdFrom:['']
       
        
      });
      this.findPartyForm=this._FormBuilder.group({
        StoreID: [''],
        RoleTypeID:[''],
        FromDate: [''],
        ThroughDate:[''],
        SequenceNum: ['']
       
        
      });
      this.partyAttributes = this._FormBuilder.group({
        attrName: [''],
        attrValue: [''],
        attrDescription: [''],
      });
      this.shoppingList=this._FormBuilder.group({
        shoppingList: [''],
      
      });
      this.fileUploadFormNote = this._FormBuilder.group({
        noteName: [''],
        note: ['']
      });
      // this.Editform = new FormGroup({
      //   file: new FormControl('', [
      //     Validators.required
      //   ]),
      //   partyContentTypeId: new FormControl('')
      // });
      this.findParty=localStorage.getItem("findParty");
     }
     ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.taxAuthPartyId = params["party"];
    });
    if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
        this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
    this.getPartyRole();
    this.getAccountNote();
   
    this.getPartyContentsList();
    this.getPaymentMethods();
    this.editVendorsData();
    this.getSkillList();
   // this.getEmployeeDetail();
    this.getPartyId();
    this.getIdentificationList();
    this.getPartyResume();
    this.getSegmentRole();
    this.getPartyClassification();
    this.getPartyRate();
    this.getUserLoginData();
    this.getPartyAttribute();
    this.getPartyContactList();
    this.getEmployeeApplication();
    this.getOrderListItems();
    this.getRoleAllType();
    this.getAllProductStoreRole();
    this.getVisitors();
    this.getPartyLoyaltyPoints();
    
    this.getProductStore();
    this.getPreferences();
    this.getRelationshipPartyIdTo();
    this.getAllFinanceHistory();
    if(this.findParty=="PARTY_GROUP"){
      this.partyGroup=true;
      this.getPartyGroup();
      this.accountDetail();
    }else{

      this.partyGroup=false;
    this.getPerson();
  
   // this.accountRelatedDetail();
    this.accountDetail();
  }
  this.getShoppingList();
  this.getTaxInfo();
  this.getPartyIcsAvsOverRide();
  this.getPartyContent();
  this.getPartyContents();
  this.getPartyStatusIdList();
  this.getPartyContentTypeIdList();
  this.getDataCategoryIdList();
  this.getAccountNotes();
  this.getAccountNotes();
  }
  getAccountNotes() {
    this.spinner.show();
    this.accountsService.getContactNotes(this.taxAuthPartyId).subscribe(res => {
      this.accountData = res.data;
      this.spinner.hide();
    });
  }

  updateAttributes(product) {
    if (product.attrName) {
      this.showAttribute = true;
      setTimeout(() => {
        const formValue = this.partyAttributes;
        formValue.patchValue({
          attrName: product.attrName,
          attrDescription: product.attrDescription,
          attrValue: product.attrValue,
        })
      }, 2000);
    }
    else {
      this.showAttribute = false;
    }
  }

  onSubmitPartyAttribute() {
    
    this.spinner.show();

    const requestData = {
      "attrDescription": this.partyAttributes.value.attrDescription,
      "attrName": this.partyAttributes.value.attrName,
      "attrValue": this.partyAttributes.value.attrValue,
      "partyId": this.taxAuthPartyId


    }
    this.accountsService.createPartyAttribute(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        //this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  resetnote(){
    this.showUpdateNoteBtn = false;
    this.fileUploadFormNote.reset();

  }
  updateNote(product){
    this.showUpdateNoteBtn = true;
    this.notePro =product;
    setTimeout(() => {
    const form =this.fileUploadFormNote;
    form.patchValue({
      "note": product.noteInfo  ,
      "noteName": product.noteName  ,
    })
    }, 2000);
  }
  updateContactNote() {
    this.spinner.show();
    const formData={
      "note": this.fileUploadFormNote.value.note  ,
      "noteName": this.fileUploadFormNote.value.noteName  ,
}
    this.accountsService.updateContactNoteHrms(formData, this.notePro.noteId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Succesfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getAccountNote();

      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message);

    });

  }
  onSubmitNote() {
    this.spinner.show();
    const form = { ...this.fileUploadFormNote.value }
    this.myContactsService.createContactNote(form, this.taxAuthPartyId).subscribe(res => {
      if (res.success) {
        this.toastr.success("Created Succesfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getAccountNote();
        this.fileUploadFormNote.reset();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )

  }
  onUpdatePartyAttribute() {
    this.spinner.show();

    const requestData = {
      "attrDescription": this.partyAttributes.value.attrDescription,
      "attrName": this.partyAttributes.value.attrName,
      "attrValue": this.partyAttributes.value.attrValue,
      "partyId": this.taxAuthPartyId
    }
    this.accountsService.updatePartyProfileAttribute(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getPartyContents() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        });
      }
    });

  }
  
  getPartyStatusIdList() {
    this.spinner.show();
    this.accountsService.getPartyStatusIdList().subscribe(res => {
      this.PartyStatusIdList = res.data[0].statusIdList;
      this.spinner.hide();
      for (const value of this.PartyStatusIdList) {
        this.PartyStatusIdListArray.push({
          label: value.description,
          value: value.statusId
        });
      }
    });

  }
  getPartyContentTypeIdList() {
    this.spinner.show();
    this.accountsService.getPartyContentTypeIdList().subscribe(res => {
      this.PartyContentTypeIdList = res.data[0].contentTypeIdList;
      this.spinner.hide();
      for (const value of this.PartyContentTypeIdList) {
        this.PartyContentTypeIdListArray.push({
          label: value.description,
          value: value.contentTypeId
        });
      }
    });

  }
  getDataCategoryIdList() {
    this.spinner.show();
    this.accountsService.getDataCategoryIdList().subscribe(res => {
      this.DataCategoryIdList = res.data[0].dataCategoryIdList;
      this.spinner.hide();
      for (const value of this.DataCategoryIdList) {
        this.DataCategoryIdListArray.push({
          label: value.categoryName,
          value: value.dataCategoryId
        });
      }
    });

  }
  
  onpartyContent(event) {
    this.partyContentTypeId = event;
  }

  fileProgress(fileInput: any){
    this.fileData = <File>fileInput.target.files[0];
  }
  reset(){
  this.show=false;

  }
 updateContent(product){
  this.show=true;

    setTimeout(() => {
      const fromdate= product.fromDate
          this.editgetEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
          const toEstdate=product.thruDate
          this.egetEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
      const formValue = this.Editform;
    formValue.patchValue({
      partyContentTypeId:product.partyContentTypeId,
      fromDate:this.editgetEstimateFrom,
      throughDate: this.egetEstimateTo,
      ContentTypeID:product.contentTypeId,
      StatusID:product.statusId
  
    })
    }, 3000); 
 }
  onSubmit() {
    this.spinner.show();
    let formData = new FormData();
    formData.append('file', this.fileData);
    const fromActualdate=this.Editform.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.Editform.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "contentTypeId": this.Editform.value.ContentTypeID,
      "dataCategoryId": this.Editform.value.DataCategoryID,
      "fromDate": this.actualfromdate,
      "isPublic": this.Editform.value.IsPublic,
      "partyContentTypeId":this.Editform.value.partyContentTypeId,
      "partyId": this.taxAuthPartyId,
      "statusId": this.Editform.value.StatusID,
      "thruDate":  this.actualthroughdate
    }
   
    
    this.accountsService.createPartyContent(formData,encodeURIComponent(JSON.stringify(requestData))).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton1.nativeElement.click();
		    this.bsModalRef.hide();
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message);

    });
    }
    onUpdates() {
      this.spinner.show();
      let formData = new FormData();
      formData.append('file', this.fileData);
      const fromActualdate=this.Editform.get('fromDate').value;
      this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const throughActualdate=this.Editform.get('throughDate').value;
      this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={
        "contentTypeId": this.Editform.value.ContentTypeID,
        "dataCategoryId": this.Editform.value.DataCategoryID,
        "fromDate":this.fromDate,
        "isPublic": this.Editform.value.IsPublic,
        "partyContentTypeId":this.Editform.value.partyContentTypeId,
        "partyId": this.taxAuthPartyId,
        "statusId": this.Editform.value.StatusID,
        "thruDate":  this.actualthroughdate
      }
     
      
      this.accountsService.updatePartyContent(formData,encodeURIComponent(JSON.stringify(requestData)),this.contentId,this.dataResourceId).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.closebutton1.nativeElement.click();
		      this.bsModalRef.hide();
          this.spinner.hide();
         
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
  
      });
      }

 
 
  getPartyContent() {
    this.spinner.show();
    this.accountsService.getPartyContent(this.taxAuthPartyId).subscribe(res => {
      this.PartyContentData = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyIcsAvsOverRide() {
    this.spinner.show();
    this.accountsService.getPartyIcsAvsOverRide(this.taxAuthPartyId).subscribe(res => {
      this.PartyIcsAvsOverRide = res.data;
      this.spinner.hide();
    })
  
  }
  GoToQuickLink(){
    this.router.navigate(['/partyQuickLink/find-party']);
  } 
  
  resetAvsOverride() {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
         
          this.accountsService.resetAvsOverride(this.taxAuthPartyId).subscribe(res => {
            this.getPartyIcsAvsOverRide(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([], { queryParams: { personId: this.taxAuthPartyId } })
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
         
          this.accountsService.resetAvsOverride(this.taxAuthPartyId).subscribe(res => {
            this.getPartyIcsAvsOverRide(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
  

  }
  getTaxInfo() {
    this.spinner.show();
    this.accountsService.getTaxInfo(this.finSize,this.taxAuthPartyId).subscribe(res => {
      this.TaxInfo = res.data;
      this.spinner.hide();
    })
  
  }
  deleteTaxInfo(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req={ 
            "fromDate": product.fromDate,
            
            "partyId": this.taxAuthPartyId,
           
            "taxAuthGeoId": product.getTaxInfo.taxAuthGeoId,
            "taxAuthPartyId": product.getTaxInfo.taxAuthPartyId,
       
  
          }
          this.accountsService.deleteTaxInfo(req).subscribe(res => {
            this.getTaxInfo(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req={ 
            "fromDate": product.fromDate,
            
            "partyId": this.taxAuthPartyId,
           
            "taxAuthGeoId": product.getTaxInfo.taxAuthGeoId,
            "taxAuthPartyId": product.getTaxInfo.taxAuthPartyId,
       
  
          }
          this.accountsService.deleteTaxInfo(req).subscribe(res => {
            this.getTaxInfo(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    
 
  

  }
  getShoppingList(): void {
    this.spinner.show();
    this.ShoppingListArray=[]
    this.accountsService.getShoppingList(this.finSize,this.taxAuthPartyId).subscribe((res) => {
      let ShoppingList = res.data;
      this.spinner.hide();
      for (const value of ShoppingList) {
        this.ShoppingListArray.push({
          label: value.shoppingListWithListName.listName,
          value: value.shoppingListWithListName.shoppingListId,
        });
      }
    });
   
  }
  createPartyEmptyShopping() {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.spinner.show();
  
      this.accountsService.createPartyEmptyShopping(this.taxAuthPartyId).subscribe((res: any) => {
      
        if (res.success) {
          this.getShoppingList();
          this.toastr.success("Created Successfully");
          this.spinner.hide();
         
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.spinner.show();
  
      this.accountsService.createPartyEmptyShopping(this.taxAuthPartyId).subscribe((res: any) => {
      
        if (res.success) {
          this.getShoppingList();
          this.toastr.success("Created Successfully");
          this.spinner.hide();
         
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }


  }
  getAllFinanceHistory() {
    this.spinner.show();
    this.accountsService.getAllFinanceHistory(this.finSize,this.taxAuthPartyId).subscribe(res => {
      this.AllFinanceHistory = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyGroup() {
    this.spinner.show();
    this.accountsService.getPartyGroup(this.taxAuthPartyId).subscribe(res => {
      this.PartyGroup = res.data;
      this.spinner.hide();
    })
  
  }
  
  getRelationshipPartyIdTo() {
    this.spinner.show();
    if(this.taxAuthPartyId=='admin'){
      
      this.accountsService.getRelationshipPartyIdFrom(this.taxAuthPartyId).subscribe(res => {
        this.RelationshipPartyIdTo = res.data;
        this.spinner.hide();
      })
    }else{
    this.accountsService.getRelationshipPartyIdTo(this.taxAuthPartyId).subscribe(res => {
      this.RelationshipPartyIdTo = res.data;
      this.spinner.hide();
    })
    }
  }
  

  deleteOtherRelationship(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req={ 
            "comments":product.data.comments,
          "fromDate": product.fromDate,
          "partyId": this.taxAuthPartyId,
          "partyIdFrom": product.data.partyIdFrom,
          "partyIdTo": product.data.partyIdTo,
          "partyRelationshipTypeId": product.data.partyRelationshipTypeId,
          "roleTypeIdFrom": product.data.roleTypeIdFrom,
          "roleTypeIdTo": product.data.roleTypeIdTo,
          "securityGroupId": product.data.securityGroupId==null?'':product.data.securityGroupId,
          "statusId":product.data.statusId==null?'':product.data.statusId,
          "thruDate":product.thruDate==null?'':product.thruDate,
          }
          this.accountsService.deleteOtherRelationship(req).subscribe(res => {
            this.getRelationshipPartyIdTo(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req={ 
            "comments":product.data.comments,
          "fromDate": product.fromDate,
          "partyId": this.taxAuthPartyId,
          "partyIdFrom": product.data.partyIdFrom,
          "partyIdTo": product.data.partyIdTo,
          "partyRelationshipTypeId": product.data.partyRelationshipTypeId,
          "roleTypeIdFrom": product.data.roleTypeIdFrom,
          "roleTypeIdTo": product.data.roleTypeIdTo,
          "securityGroupId": product.data.securityGroupId==null?'':product.data.securityGroupId,
          "statusId":product.data.statusId==null?'':product.data.statusId,
          "thruDate":product.thruDate==null?'':product.thruDate,
          }
          this.accountsService.deleteOtherRelationship(req).subscribe(res => {
            this.getRelationshipPartyIdTo(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }

  

  }
  getPreferences() {
    this.spinner.show();
    this.accountsService.getPreferences(this.taxAuthPartyId).subscribe(res => {
      this.Preferences = res.data;
      this.spinner.hide();
    })
  
  }
  
 
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  getOrderListItems(): void {
    this.spinner.show();
    this.accountsService.getOrderList().subscribe((res) => {
      let productStores = res.data.productStores;
      this.spinner.hide();
      for (const value of productStores) {
        this.productStoresListArray.push({
          label: value.storeName,
          value: value.productStoreId,
        });
      }
    });
   
  }
  getEmployeeApplication() {
    this.spinner.show();
    const data = {
      "applicationDateFrom": "",
      "applicationDateFromSearchType": "Contains",
      "applicationDateTo": "",
      "applicationDateToSearchType": "Contains",
      "applicationId": "",
      "applyingPartyId":this.taxAuthPartyId,
      "emplPositionId": "",
      "employmentAppSourceTypeId": "",
      "referredByPartyId": "",
      "statusID": [
        ""
      ]
    }
    this.employementApplicationService.getEmpJobList(this.finSize, data).subscribe(res => {
      this.employmentApplication = res.data;
      this.spinner.hide();
    })
  }
  getVisitors() {
    this.spinner.show();
   
    this.accountsService.getVisitors(this.finSize).subscribe(res => {
      this.Visitors = res.data;
      this.spinner.hide();
    })
  }
  
  onDeleteEmp(id) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.employeeApplicationService.deleteEmployeeApp(id).subscribe((res: any) => {
            if (res.success) {  
              this.getEmployeeApplication();
            }
            else if (res.success == false) {
              this.toastr.error("Error");
            }
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }}) 
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.employeeApplicationService.deleteEmployeeApp(id).subscribe((res: any) => {
            if (res.success) {  
              this.getEmployeeApplication();
            }
            else if (res.success == false) {
              this.toastr.error("Error");
            }
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }}) 
    }

    }
      
      getAllProductStoreRole() {
        this.spinner.show();
        this.accountsService.getAllProductStoreRole(this.taxAuthPartyId).subscribe(res => {
          this.AllProductStoreRole = res.data;
          this.spinner.hide();
        })
      
      }
      getProductStore() {
        this.spinner.show();
        this.accountsService.getProductStore(this.taxAuthPartyId).subscribe(res => {
          this.ProductStore = res.data;
          this.spinner.hide();
        })
      
      }
      
      getPartyLoyaltyPoints() {
        this.spinner.show();
        this.accountsService.getPartyLoyaltyPoints(this.taxAuthPartyId).subscribe(res => {
          this.PartyLoyaltyPoints = res.data;
          this.spinner.hide();
        })
      
      }
      getPerson() {
        this.spinner.show();
        this.accountsService.getPerson(this.taxAuthPartyId).subscribe(res => {
          this.Person = res.data;
         /*  this.statusIdPerson = res.data.statusId;
          this.externalId = res.data; */
          this.spinner.hide();
        })
      
      }
      
      
  getPartyContactList() {
    this.spinner.show();
    this.accountsService.getPartyContactList(this.taxAuthPartyId).subscribe(res => {
      this.PartyContactList = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyAttribute() {
    this.spinner.show();
    this.accountsService.getPartyAttribute(this.taxAuthPartyId).subscribe(res => {
      this.PartyAttribute = res.data;
      this.spinner.hide();
    })
  
  }
  getUserLoginData() {
    this.spinner.show();
    this.accountsService.getUserLoginData(this.taxAuthPartyId).subscribe(res => {
      this.UserLoginData = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyRate() {
    this.spinner.show();
    this.accountsService.getPartyRate(this.taxAuthPartyId).subscribe(res => {
      this.PartyRate = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyClassification() {
    this.spinner.show();
    this.accountsService.getPartyClassificationByPartyId(this.taxAuthPartyId).subscribe(res => {
      this.PartyClassification = res.data;
      this.spinner.hide();
    })
  
  }
  
 
  getIdentificationList() {
    this.spinner.show();
    this.accountsService.getIdentificationList(this.taxAuthPartyId).subscribe(res => {
      this.IdentificationList = res.data;
      this.spinner.hide();
    })
  
  }
  
  getSegmentRole() {
    this.spinner.show();
    this.accountsService.getSegmentRole(this.taxAuthPartyId).subscribe(res => {
      this.SegmentRole = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyResume() {
    this.spinner.show();
    this.accountsService.getPartyResume(this.taxAuthPartyId).subscribe(res => {
      this.PartyResume = res.data;
      this.spinner.hide();
    })
  
  }
  
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  updatePerson() {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(['/hr/employees/update-personal-info'], { queryParams: { personId: this.taxAuthPartyId } }) 

    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(['/hr/employees/update-personal-info'], { queryParams: { personId: this.taxAuthPartyId } }) 

    }
  } 
  updatePartyInfo(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([], { queryParams: { personId: this.taxAuthPartyId } })
      this.bsModalRef = this.modalService.show(PersonalUpdateComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([], { queryParams: { personId: this.taxAuthPartyId } })
      this.bsModalRef = this.modalService.show(PersonalUpdateComponent,{class: 'modal-lg'});
    }
    // this.showUpdateModal = true;
  }
 /*  getEmployeeDetail() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.taxAuthPartyId).subscribe(res => {
      this.employeeDetail = res.data;
     res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;
     
      this.emergencyContact = res.data.emergencyContacts;
      this.demoName = res.data.firstName + " " + res.data.lastName;
      this.demoId = res.data.partyId;
      this.employeeDetailArray.push({
        data: this.employeeDetail
      })
      this.employeeDetailArrayEmail.push({
        data: this.employeeDetail
      })
      this.employeeDetailArrayPhone.push({
        data: this.employeeDetail
      })
      
    })
    this.spinner.hide();
  } */
  
  deletePartyIdentification(partyTypeId) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.accountsService.deletePartyIdentification(this.taxAuthPartyId, partyTypeId).subscribe(res => {
            this.getIdentificationList(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.accountsService.deletePartyIdentification(this.taxAuthPartyId, partyTypeId).subscribe(res => {
            this.getIdentificationList(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }

  

  }
  
  deleteStoreRole(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "fromDate": product.fromDate,
            "partyId":product.data.partyId,
            "productStoreId": product.data.productStoreId,
            "roleTypeId": product.data.roleTypeId,
            "sequenceNum": product.data.sequenceNum,
            "thruDate": product.thruDate,
  
            
          }
          this.accountsService.deleteStoreRole(req).subscribe(res => {
            this.getAllProductStoreRole(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "fromDate": product.fromDate,
            "partyId":product.data.partyId,
            "productStoreId": product.data.productStoreId,
            "roleTypeId": product.data.roleTypeId,
            "sequenceNum": product.data.sequenceNum,
            "thruDate": product.thruDate,
  
            
          }
          this.accountsService.deleteStoreRole(req).subscribe(res => {
            this.getAllProductStoreRole(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }

  

  }
  deleteContactList(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "contactListId":product.data.contactListId,
            "fromDate":product.fromDate,
            "partyId": product.data.partyId,
            "preferredContactMechId": product.data.preferredContactMechId,
            "statusId":product.data.statusId,
            "thruDate": product.thruDate,
  
            
          }
          this.accountsService.deleteContactList(req).subscribe(res => {
            this.getPartyContactList(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "contactListId":product.data.contactListId,
            "fromDate":product.fromDate,
            "partyId": product.data.partyId,
            "preferredContactMechId": product.data.preferredContactMechId,
            "statusId":product.data.statusId,
            "thruDate": product.thruDate,
  
            
          }
          this.accountsService.deleteContactList(req).subscribe(res => {
            this.getPartyContactList(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }

  

  }
  deletePartyAttribute(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "attrDescription":product.attrDescription,
            "attrName":product.attrName,
            "attrValue":product.attrValue,
            "partyId":product.partyId,
            
          }
          this.accountsService.deletePartyAttribute(req).subscribe(res => {
            this.getPartyAttribute(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "attrDescription":product.attrDescription,
            "attrName":product.attrName,
            "attrValue":product.attrValue,
            "partyId":product.partyId,
            
          }
          this.accountsService.deletePartyAttribute(req).subscribe(res => {
            this.getPartyAttribute(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }

  

  }
  deletePartyClassification(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "fromDate": product.fromDate,
            "partyClassificationGroupId":product.partyRateData.partyClassificationGroupId,
            "partyId": product.partyRateData.partyId,
            "thruDate": product.thruDate,
          }
          this.accountsService.deletePartyClassification(req).subscribe(res => {
            this.getPartyClassification(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "fromDate": product.fromDate,
            "partyClassificationGroupId":product.partyRateData.partyClassificationGroupId,
            "partyId": product.partyRateData.partyId,
            "thruDate": product.thruDate,
          }
          this.accountsService.deletePartyClassification(req).subscribe(res => {
            this.getPartyClassification(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
  
  

  }
  deletePartySegment(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "partyId":product.segmentGroupId.partyId,
             "roleTypeId": product.segmentGroupId.roleTypeId,
             "segmentGroupId": product.segmentGroupId.segmentGroupId,
          }
          this.accountsService.deletePartySegment(req).subscribe(res => {
            this.getSegmentRole(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "partyId":product.segmentGroupId.partyId,
             "roleTypeId": product.segmentGroupId.roleTypeId,
             "segmentGroupId": product.segmentGroupId.segmentGroupId,
          }
          this.accountsService.deletePartySegment(req).subscribe(res => {
            this.getSegmentRole(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
 
  

  }
  
  deletePartRate(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "defaultRate":product.partyRateData.defaultRate,
            "fromDate": product.fromDate,
            "partyId": product.partyRateData.partyId,
            "percentageUsed": product.partyRateData.percentageUsed,
            "periodTypeId":"RATE_HOUR",
            "rateAmountFromDate":product.fromDate,
            "rateCurrencyUomId":"USD",
            "rateTypeId": product.partyRateData.rateTypeId,
          }
          this.accountsService.deletePartRate(req).subscribe(res => {
            this.getPartyRate(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "defaultRate":product.partyRateData.defaultRate,
            "fromDate": product.fromDate,
            "partyId": product.partyRateData.partyId,
            "percentageUsed": product.partyRateData.percentageUsed,
            "periodTypeId":"RATE_HOUR",
            "rateAmountFromDate":product.fromDate,
            "rateCurrencyUomId":"USD",
            "rateTypeId": product.partyRateData.rateTypeId,
          }
          this.accountsService.deletePartRate(req).subscribe(res => {
            this.getPartyRate(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }

  

  }
  deletePartyResume(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "contentId":product.data.contentId,
            "partyId": product.data.partyId,
            "resumeDate": product.resumeDate,
            "resumeId": product.data.resumeId,
            "resumeText": product.data.resumeText,
          }
          this.accountsService.deletePartyResume(req).subscribe(res => {
            this.getPartyResume(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req={
            "contentId":product.data.contentId,
            "partyId": product.data.partyId,
            "resumeDate": product.resumeDate,
            "resumeId": product.data.resumeId,
            "resumeText": product.data.resumeText,
          }
          this.accountsService.deletePartyResume(req).subscribe(res => {
            this.getPartyResume(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }

  

  }
  onDeleteSkill(partyId, skillTypeId) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.skillService.deleteSkills(partyId, skillTypeId).subscribe(res => {
            this.getSkillList(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.skillService.deleteSkills(partyId, skillTypeId).subscribe(res => {
            this.getSkillList(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }

  

  }
  getSkillList() {
    this.spinner.show();
    const req = {
      "pageNo": this.pageNo,
      "pageSize": this.pageSize
    }
    const skillList = {
      partyId: this.taxAuthPartyId,
      rating: "",
      skillLevel: "",
      skillTypeId: "",
      yearsExperience: ""
    }


    this.skillService.getSkillList(req, skillList).subscribe(res => {
      this.skillList = res.data;
      this.spinner.hide();
    })
   
  }
  

  setPartyLink() {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.spinner.show();
  
      this.accountsService.setPartyLink(this.createLinkParty.value.partyIdTo,this.createLinkParty.value.partyIdFrom).subscribe((res: any) => {
      
        if (res.success) {
         
          this.toastr.success("Created Successfully");
          this.spinner.hide();
         
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
          }
          else{
            this.toastr.error(err.error.message);
          }
      });
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.spinner.show();
  
      this.accountsService.setPartyLink(this.createLinkParty.value.partyIdTo,this.createLinkParty.value.partyIdFrom).subscribe((res: any) => {
      
        if (res.success) {
         
          this.toastr.success("Created Successfully");
          this.spinner.hide();
         
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
          }
          else{
            this.toastr.error(err.error.message);
          }
      });
    }
  

  }
  onUpdateVendor() {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.spinner.show();
      const requestData = {
        "manifestCompanyName":  this.createVendor.value.ManifestCompany,
        "manifestCompanyTitle": this.createVendor.value.CompanyTitle,
        "manifestLogoUrl":  this.createVendor.value.logo,
        "manifestPolicies": this.createVendor.value.policies,
        "partyId": this.taxAuthPartyId,
       
      }
  
      this.accountsService.updateVendors(requestData).subscribe((res: any) => {
      
        if (res.success) {
          this.editVendorsData();
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
         
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.spinner.show();
      const requestData = {
        "manifestCompanyName":  this.createVendor.value.ManifestCompany,
        "manifestCompanyTitle": this.createVendor.value.CompanyTitle,
        "manifestLogoUrl":  this.createVendor.value.logo,
        "manifestPolicies": this.createVendor.value.policies,
        "partyId": this.taxAuthPartyId,
       
      }
  
      this.accountsService.updateVendors(requestData).subscribe((res: any) => {
      
        if (res.success) {
          this.editVendorsData();
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
         
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
   

  }
 
  editVendorsData() {
    this.spinner.show();
    this.accountsService.getVendorListById(this.taxAuthPartyId).subscribe((res: any) => {
     
      setTimeout(() => {
       
      
        const formValue = this.createVendor;
        formValue.patchValue({
          ManifestCompany: res.data.manifestCompanyName,
          CompanyTitle: res.data.manifestCompanyTitle,
          logo: res.data.manifestLogoUrl,
          policies: res.data.manifestPolicies,
       
        
    
   
        })
      
      }, 3000);
   
      this.spinner.hide();
    })
  }
  accountDetail() {
    this.spinner.show();
    this.accountsService.getAccountById(this.taxAuthPartyId).subscribe(res => {
      this.accountDetails = res.data;
     // this.isAdditionalDetail = res.data.additionalInformation.infoType ? true : false;
     // this.isJobDetail = res.data.jobInformation.jobStatus ? true : false;
    //  res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;
      this.relatedContact = res.data.relatedContacts;
      this.accountName = res.data.groupName;
      this.addr = res.data.address.address1 + ' ' + res.data.address.city + ' ' + res.data.address.postalCode + ' ' + res.data.address.countryGeoId;
      this.emailId = res.data.email.infoString;
      this.phoneNumber = res.data.telenumber.countryCode + ' ' + res.data.telenumber.areaCode + ' ' + res.data.telenumber.contactNumber
    })
    this.spinner.hide();
  }
  accountRelatedDetail() {
    this.spinner.show();
    this.accountsService.getAccountRelatedById(this.taxAuthPartyId).subscribe(res => {
      this.accountDetails = res.data;
     // this.isAdditionalDetail = res.data.additionalInformation.infoType ? true : false;
     // this.isJobDetail = res.data.jobInformation.jobStatus ? true : false;
    //  res.data.contactInformation.pop();
    //  this.contactInformation = res.data.contactInformation;
      this.relatedContactInfo = res.data.relatedCompanies;
     
    })
    this.spinner.hide();
  }

  getPartyRole(){
    this.accountsService.getPartyRole(this.finSize,this.taxAuthPartyId).subscribe((res:any)=> {
       this.roleList = res.data;
    })
   }

   getAccountNote() {
    this.spinner.show();
    this.accountsService.getContactNotes(this.taxAuthPartyId).subscribe(res => {
      this.accountData = res.data;
      this.spinner.hide();
    });
  }

   deleteRoleTypeId(id){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
        this.accountsService.deleteRoleTypeId(this.taxAuthPartyId,id).subscribe((res:any)=> {
          this.toastr.success("Delete Successfully");
          this.getPartyRole();
      },(err) => {
        this.toastr.error(err.error.message);
     })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
        this.accountsService.deleteRoleTypeId(this.taxAuthPartyId,id).subscribe((res:any)=> {
          this.toastr.success("Delete Successfully");
          this.getPartyRole();
      },(err) => {
        this.toastr.error(err.error.message);
     })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
  
}

getPartyContentsList() {
  this.spinner.show();
  this.accountsService.getPartyContentList(this.taxAuthPartyId).subscribe(res => {
    this.contentList = res.data;
    this.spinner.hide();
  })

}



getBillingAccounts(): void {
  this.spinner.show();
  this._CRMAccountService.getBillingAccounts(this.taxAuthPartyId)
    .then(data => {
      if (data.success) {
        this.billingAccounts = data.data.map(value => {
          return {
            ...value, ...{ type: 'BILLING', paymentMethodTypeId: 'BILLING' }
          };
        });
        this.paymentMethods = [...this.paymentMethods, ...this.billingAccounts];
      }
      this.spinner.hide();
    });

}

getPaymentMethods(): void {
  this.spinner.show();
  this._CRMAccountService.getAllPayment(this.taxAuthPartyId, false)
    .then(data => {
      if (data.success) {
        this.paymentMethods = data.data.map(value => {
          switch (value.paymentMethod.paymentMethodTypeId) {
            case 'GIFT_CARD':
              return {
                ...value.giftCard, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Gift Card'
                }
              };
              break;
            case 'EFT_ACCOUNT':
              return {
                ...value.eftAccount, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'EFT Account'
                }
              };
              break;
            case 'CERTIFIED_CHECK':
              return {
                ...value.certifiedCheckAccount, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Certified Check'
                }
              };
              break;
            case 'CREDIT_CARD':
              return {
                ...value.creditCard, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Credit Card'
                }
              };
              break;
            case 'COMPANY_CHECK':
              return {
                ...value.companyCheckAccount, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Company Check'
                }
              }
              break;
            case 'PERSONAL_CHECK':
              return {
                ...value.personalCheckAccount, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Personal Check'
                }
              };
          }
        });
        this.getBillingAccounts();
        this.spinner.hide();
      }
    });

}

removePaymentMethod(type: string, paymentMethodId: string): void {
  if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
    if (type === 'BILLING') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this._CRMAccountService.removeBillingAccount(this.taxAuthPartyId, paymentMethodId)
            .then(data => {
              if (data.success) {
                this.getPaymentMethods();
              }
            });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
  
  
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this._CRMAccountService.removeGiftCard(paymentMethodId)
            .then(data => {
              if (data.success) {
                this.getPaymentMethods();
              }
            });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
  
  
    }
  }
  else if(this.userPermissions.PARTYMGR_VIEW){
    this.toastr.error("Permission Denied"); 
  }
  else{
    if (type === 'BILLING') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this._CRMAccountService.removeBillingAccount(this.taxAuthPartyId, paymentMethodId)
            .then(data => {
              if (data.success) {
                this.getPaymentMethods();
              }
            });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
  
  
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this._CRMAccountService.removeGiftCard(paymentMethodId)
            .then(data => {
              if (data.success) {
                this.getPaymentMethods();
              }
            });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
  
  
    }
  }
 
}

paymentMethodNavigation(type: string, paymentMethodId: string): void {
  switch (type) {
    case 'GIFT_CARD':
      this.router.navigate(['/crm/accounts/create-gift-card'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId } });
      break;
    case 'EFT_ACCOUNT':
      this.router.navigate(['/crm/accounts/create-eft-account'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, isEftAccount: true } });
      break;
    case 'CERTIFIED_CHECK':
      this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, paymentMethodTypeId: 'CERTIFIED_CHECK' } });
      break;
    case 'CREDIT_CARD':
      this.router.navigate(['/crm/accounts/create-credit-card'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId } });
      break;
    case 'COMPANY_CHECK':
      this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, paymentMethodTypeId: 'COMPANY_CHECK' } });
      break;
    case 'BILLING':
      this.router.navigate([`/crm/accounts/create-billing-account`], { queryParams: { partyId: this.taxAuthPartyId, billingAccountId: paymentMethodId } });
      break;
    case 'PERSONAL_CHECK':
      this.router.navigate([`/crm/accounts/create-check-account`], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, paymentMethodTypeId: 'PERSONAL_CHECK' } });
      break;
  }
}

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
editContent(contentId,partyContentTypeId,contentTypeId,fromDate,thruDate,statusId,dataResourceId){
  if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
    this.router.navigate(["financial/add-party-content"], { queryParams: { party:this.taxAuthPartyId,contentId:contentId,partyContentTypeId:partyContentTypeId,contentTypeId:contentTypeId,fromDate:fromDate,thruDate:thruDate,statusId:statusId,dataResourceId:dataResourceId} });

  }
  else if(this.userPermissions.PARTYMGR_VIEW){
    this.toastr.error("Permission Denied"); 
  }
  else{
    this.router.navigate(["financial/add-party-content"], { queryParams: { party:this.taxAuthPartyId,contentId:contentId,partyContentTypeId:partyContentTypeId,contentTypeId:contentTypeId,fromDate:fromDate,thruDate:thruDate,statusId:statusId,dataResourceId:dataResourceId} });
  }
}

  export(id: any, contentName: any) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.spinner.show();
      this.accountsService.downloadView(id).subscribe(res => {
        const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        this.spinner.hide();
        link.download = contentName;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.spinner.show();
      this.accountsService.downloadView(id).subscribe(res => {
        const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        this.spinner.hide();
        link.download = contentName;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
    }


  }
changeactiveCategoryFunctionValue(tab: number) {
    this.activeCategoryB = tab;
  }
  onDelete(contentId, fromDate, partyContent, partyId): void {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.accountsService.deletePartyContentData(contentId,fromDate, partyContent, partyId).subscribe(res => {
            if (res['data'].responseMessage === 'success') {
            }
            this.getPartyContent();
          });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.accountsService.deletePartyContentData(contentId,fromDate, partyContent, partyId).subscribe(res => {
            if (res['data'].responseMessage === 'success') {
            }
            this.getPartyContent();
          });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }

  }
  
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  createRoleType(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { party:  this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(PartyRoleTypeComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { party:  this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(PartyRoleTypeComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["financial/party-role-type"],{ queryParams: { party:  this.taxAuthPartyId} });
 

  }

  createAddRole(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { party:  this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(PartyAddToRoleComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { party:  this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(PartyAddToRoleComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["financial/party-add-to-role"],{ queryParams: { party:  this.taxAuthPartyId} });

  }
  createIdentificationNumber(){
    //this.router.navigate(["financial/party-create-identification-number"],{ queryParams: { party:  this.taxAuthPartyId}});
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.showIdentificationModal = true;
      this.bsModalRef = this.modalService.show(PartyCreateIdentificationNumberComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.showIdentificationModal = true;
      this.bsModalRef = this.modalService.show(PartyCreateIdentificationNumberComponent,{class: 'modal-lg'});
    }

  }
  updatePartyIdentification(epartyTypeId,epartyValue){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["financial/party-create-identification-number"],{ queryParams: { party:  this.taxAuthPartyId,partyTypeId:epartyTypeId,partyValue:epartyValue}});

    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["financial/party-create-identification-number"],{ queryParams: { party:  this.taxAuthPartyId,partyTypeId:epartyTypeId,partyValue:epartyValue}});

    }
  }
  createNewRelationshipType(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { party:  this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(NewRelationshipTypeComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { party:  this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(NewRelationshipTypeComponent,{class: 'modal-lg'});
    }
    //this.router.navigate(["financial/new-relationship-type"],{ queryParams: { party:  this.taxAuthPartyId}});

  }
  createAddOtherpartyRelationship(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { party:  this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(AddOtherPartyRelationshipComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { party:  this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(AddOtherPartyRelationshipComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["financial/add-other-party-relationship"],{ queryParams: { party:  this.taxAuthPartyId}});
 
  }
  updateAddOtherpartyRelationship(activity){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["financial/add-other-party-relationship"],{ queryParams: { party:  this.taxAuthPartyId,fromDate:activity.fromDate,thruDate:activity.thruDate,comment:activity.data.comments,partyIdFrom:activity.data.partyIdFrom,isA:activity.isA,partyRelationshipTypeId:activity.data.partyRelationshipTypeId,roleTypeIdFrom:activity.data.roleTypeIdFrom,roleTypeIdTo:activity.data.roleTypeIdTo,securityGroupId:activity.data.securityGroupId,statusId:activity.data.statusId}});

    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["financial/add-other-party-relationship"],{ queryParams: { party:  this.taxAuthPartyId,fromDate:activity.fromDate,thruDate:activity.thruDate,comment:activity.data.comments,partyIdFrom:activity.data.partyIdFrom,isA:activity.isA,partyRelationshipTypeId:activity.data.partyRelationshipTypeId,roleTypeIdFrom:activity.data.roleTypeIdFrom,roleTypeIdTo:activity.data.roleTypeIdTo,securityGroupId:activity.data.securityGroupId,statusId:activity.data.statusId}});

    }
  }
  createEditPartyRates(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { party:  this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(EditPartyRatesComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { party:  this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(EditPartyRatesComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["financial/edit-party-rates"],{ queryParams: { party:  this.taxAuthPartyId}});

  }
  createAddPartySkill(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { party:  this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(AddPartySkillComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { party:  this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(AddPartySkillComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["financial/add-party-skill"],{ queryParams: { party:  this.taxAuthPartyId} });

  }
  onUpdateSkill(eskid, erating, eskill, eyear) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["financial/add-party-skill"], { queryParams: { party:this.taxAuthPartyId, skid: eskid, rate: erating, skill: eskill, year: eyear } })

    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["financial/add-party-skill"], { queryParams: { party:this.taxAuthPartyId, skid: eskid, rate: erating, skill: eskill, year: eyear } })

    }
  }
  createAddPartyContent(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([], { queryParams: { party:this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(AddPartyContentComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([], { queryParams: { party:this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(AddPartyContentComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["financial/add-party-content"], { queryParams: { party:this.taxAuthPartyId} });

  }
  createAddPartySegmentRole(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([], { queryParams: { party:this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(AddPartySegmentRoleComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([], { queryParams: { party:this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(AddPartySegmentRoleComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["financial/add-party-segment-role"], { queryParams: { party:this.taxAuthPartyId} });

  }
  createAddPartyResumes(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([], { queryParams: { party:this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(AddPartyResumesComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([], { queryParams: { party:this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(AddPartyResumesComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["financial/add-party-resumes"], { queryParams: { party:this.taxAuthPartyId} });

  }
  createEmployeeAppilcation(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([], { queryParams: { party:this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(CreatePartyEmployeApplicationComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([], { queryParams: { party:this.taxAuthPartyId} });
      this.bsModalRef = this.modalService.show(CreatePartyEmployeApplicationComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["partyQuickLink/create-party-employee-application"], { queryParams: { party:this.taxAuthPartyId} });

  }
  onUpdate(id){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["partyQuickLink/create-party-employee-application"], { queryParams: { party:this.taxAuthPartyId,updateId:id} });

    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["partyQuickLink/create-party-employee-application"], { queryParams: { party:this.taxAuthPartyId,updateId:id} });

    }
  }
  
  updateResume(eresumeId,econtentId,eresumeDate,eresumeText){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["financial/add-party-resumes"], { queryParams: { party:this.taxAuthPartyId,resumeId:eresumeId,contentId:econtentId,resumeDate:eresumeDate,resumeText:eresumeText} });

    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["financial/add-party-resumes"], { queryParams: { party:this.taxAuthPartyId,resumeId:eresumeId,contentId:econtentId,resumeDate:eresumeDate,resumeText:eresumeText} });

    }
  }
  updatePassword(loginId){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["partyQuickLink/update-password"], { queryParams: { party:this.taxAuthPartyId,loginid:loginId} });

    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["partyQuickLink/update-password"], { queryParams: { party:this.taxAuthPartyId,loginid:loginId} });
 
    }
  }
  securityList(loginId){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["partyQuickLink/party-security-group-list"], { queryParams: { party:this.taxAuthPartyId,loginid:loginId} });

    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["partyQuickLink/party-security-group-list"], { queryParams: { party:this.taxAuthPartyId,loginid:loginId} });

    }
  }
  
  createEditPersonalInformation(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["financial/edit-personal-information"]);

    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["financial/edit-personal-information"]);

    }
  }
  createAddRelatedAccount(){
    //this.router.navigate(["financial/add-related-account"],{queryParams:{party:this.taxAuthPartyId}});
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{queryParams:{party:this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(AddRelatedAccountComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{queryParams:{party:this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(AddRelatedAccountComponent,{class: 'modal-lg'});
    }

    // this.showAccModal = true;
  }

  createAddPartyClassification(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{queryParams:{party:this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(CreateClassificationPartyComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{queryParams:{party:this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(CreateClassificationPartyComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["partyQuickLink/create-party-classification"],{queryParams:{party:this.taxAuthPartyId}});

  }
  
  createProductStore(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{queryParams:{party:this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(CreateProductStoreComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{queryParams:{party:this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(CreateProductStoreComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["partyQuickLink/create-product-store"],{queryParams:{party:this.taxAuthPartyId}});

  }
  onUpdateProductStore(product){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["partyQuickLink/create-product-store"],{queryParams:{party:this.taxAuthPartyId,fromDate:product.fromDate,thruDate:product.thruDate,roleTypeId:product.data.roleTypeId,sequenceNumber:product.data.sequenceNum,productStoreId:product.data.productStoreId}});

    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["partyQuickLink/create-product-store"],{queryParams:{party:this.taxAuthPartyId,fromDate:product.fromDate,thruDate:product.thruDate,roleTypeId:product.data.roleTypeId,sequenceNumber:product.data.sequenceNum,productStoreId:product.data.productStoreId}});
    }
  }
  
  createPartyContact(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{queryParams:{party:this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(CreatePartyContactComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{queryParams:{party:this.taxAuthPartyId}});
      this.bsModalRef = this.modalService.show(CreatePartyContactComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["partyQuickLink/create-party-contact"],{queryParams:{party:this.taxAuthPartyId}});

  }
  updateContactList(activity){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["partyQuickLink/create-party-contact"],{queryParams:{party:this.taxAuthPartyId,contactId:activity.data.contactListId,preferredContactMechId:activity.data.preferredContactMechId,statusId:activity.data.statusId,fromDate:activity.fromDate,thruDate:activity.thruDate}});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["partyQuickLink/create-party-contact"],{queryParams:{party:this.taxAuthPartyId,contactId:activity.data.contactListId,preferredContactMechId:activity.data.preferredContactMechId,statusId:activity.data.statusId,fromDate:activity.fromDate,thruDate:activity.thruDate}});
    }
  }
  updatePartyClassification(epartyClassId,fromdate,thrudate){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["partyQuickLink/create-party-classification"],{queryParams:{party:this.taxAuthPartyId,partyClassId:epartyClassId,fromDate:fromdate,thruDate:thrudate}});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["partyQuickLink/create-party-classification"],{queryParams:{party:this.taxAuthPartyId,partyClassId:epartyClassId,fromDate:fromdate,thruDate:thrudate}});
    }
  }
  
  createAddNewContactInformation(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["financial/add-new-contact-information"],{queryParams:{party:this.taxAuthPartyId}});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["financial/add-new-contact-information"],{queryParams:{party:this.taxAuthPartyId}});
    }
  }
  updateData(partyId: string, type: string) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      switch (type) {
        case 'POSTAL_ADDRESS':
          this.router.navigate(['/crm/accounts/create-postal-address'], { queryParams: { partyId: this.taxAuthPartyId, contactMechId: partyId } })
          break;
  
        case 'TELECOM_NUMBER':
          this.router.navigate(['/crm/accounts/create-contact'], { queryParams: { partyId: this.taxAuthPartyId, contactMechId: partyId } })
      }
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      switch (type) {
        case 'POSTAL_ADDRESS':
          this.router.navigate(['/crm/accounts/create-postal-address'], { queryParams: { partyId: this.taxAuthPartyId, contactMechId: partyId } })
          break;
  
        case 'TELECOM_NUMBER':
          this.router.navigate(['/crm/accounts/create-contact'], { queryParams: { partyId: this.taxAuthPartyId, contactMechId: partyId } })
      }
    }


  }
  createUserLogin(){
    //this.router.navigate(["financial/create-user-login"],{ queryParams: { party: this.taxAuthPartyId } });
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { party: this.taxAuthPartyId } });
      this.bsModalRef = this.modalService.show(CreateUserLoginComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { party: this.taxAuthPartyId } });
      this.bsModalRef = this.modalService.show(CreateUserLoginComponent,{class: 'modal-lg'});
    }
  }
  createPartyAttribute(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([], { queryParams: { party: this.taxAuthPartyId } });
      this.showAttrModal = true;
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([], { queryParams: { party: this.taxAuthPartyId } });
      this.showAttrModal = true;
    }
    //this.router.navigate(["financial/create-party-attribute"], { queryParams: { party: this.taxAuthPartyId } });

  }
  
  updateAttribute(product){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(["financial/create-party-attribute"], { queryParams: { party: this.taxAuthPartyId,attName:product.attrName,attValue:product.attrValue,attDes:product.attrDescription } });
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(["financial/create-party-attribute"], { queryParams: { party: this.taxAuthPartyId,attName:product.attrName,attValue:product.attrValue,attDes:product.attrDescription } });
    }
  }
  createNewPartyNote(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([], { queryParams: { party: this.taxAuthPartyId } });
      this.showNoteModal = true;
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([], { queryParams: { party: this.taxAuthPartyId } });
      this.showNoteModal = true;
    }
    //this.router.navigate(["financial/new-party-note"], { queryParams: { party: this.taxAuthPartyId } });

  }
  onUpdateNotes(enoteId, enoteName, enote) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(['financial/new-party-note'], { queryParams: { noteId: enoteId, party: this.taxAuthPartyId, noteName: enoteName, note: enote } })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(['financial/new-party-note'], { queryParams: { noteId: enoteId, party: this.taxAuthPartyId, noteName: enoteName, note: enote } })
    }
  }
  
  editAddShoping() {
    this.router.navigate(['partyQuickLink/edit-shopping-list'],{ queryParams: {party: this.taxAuthPartyId, shoppingListId:this.shoppingList.value.shoppingList } })
  }
 
  createTaxInfo() {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { party: this.taxAuthPartyId } });
      this.bsModalRef = this.modalService.show(CreateTaxInfoComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { party: this.taxAuthPartyId } });
      this.bsModalRef = this.modalService.show(CreateTaxInfoComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(['partyQuickLink/create-tax-info'], { queryParams: { party: this.taxAuthPartyId } });

  }
  updateTaxInfo(product) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(['partyQuickLink/create-tax-info'], { queryParams: { party: this.taxAuthPartyId,taxAuthGeoId:product.getTaxInfo.taxAuthGeoId,taxAuthPartyId:product.getTaxInfo.taxAuthPartyId,fromDate:product.fromDate,thruDate:product.thruDate,partyTaxId:product.getTaxInfo.partyTaxId,isExempt:product.getTaxInfo.isExempt,isNexus:product.getTaxInfo.isNexus } });
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(['partyQuickLink/create-tax-info'], { queryParams: { party: this.taxAuthPartyId,taxAuthGeoId:product.getTaxInfo.taxAuthGeoId,taxAuthPartyId:product.getTaxInfo.taxAuthPartyId,fromDate:product.fromDate,thruDate:product.thruDate,partyTaxId:product.getTaxInfo.partyTaxId,isExempt:product.getTaxInfo.isExempt,isNexus:product.getTaxInfo.isNexus } });
    }
  }
  
  updateAVS() {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate(['partyQuickLink/create-avs-string'], { queryParams: { party: this.taxAuthPartyId } });

    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate(['partyQuickLink/create-avs-string'], { queryParams: { party: this.taxAuthPartyId } });

    }
  }
  
  listAll(){
    this.router.navigate(['partyQuickLink/visits']); 
  }
  onDeleteNotes(noteId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteNote(noteId).subscribe(res => {
          this.toastr.success("Delete Successfully");
          this.getAccountNote();
        }, (err) => {
          this.toastr.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  createAttachContent(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { party: this.taxAuthPartyId } });
    this.router.navigate(["financial/attach-content"],{ queryParams: { party: this.taxAuthPartyId } });

  //    this.bsModalRef = this.modalService.show(AttachContentComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { party: this.taxAuthPartyId } });
    this.router.navigate(["financial/attach-content"],{ queryParams: { party: this.taxAuthPartyId } });
      
      
      //this.bsModalRef = this.modalService.show(AttachContentComponent,{class: 'modal-lg'});
    }
    // this.router.navigate(["financial/attach-content"],{ queryParams: { party: this.taxAuthPartyId } });

  }
  closeAttrModal(): void{
    this.showAttrModal = false;
  }
  openTelecomModal(partyId): void{
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { partyId:partyId } });
      this.bsModalRef = this.modalService.show(AccountFaxNumberComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { partyId:partyId } });
      this.bsModalRef = this.modalService.show(AccountFaxNumberComponent,{class: 'modal-lg'});
    }

  }

  openAddrModal(partyId): void{
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { partyId:partyId } });
      this.bsModalRef = this.modalService.show(AccountPostalAddressComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { partyId:partyId } });
      this.bsModalRef = this.modalService.show(AccountPostalAddressComponent,{class: 'modal-lg'});
    }

  }

  closeNoteModal(){
    this.showNoteModal = false;
  }

  closeContentModal(){
    this.showContentModal = false;
  }

  closeIdentificationModal(){
    this.showIdentificationModal = false;
  }
  openCreditModal(partyId){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { partyId:partyId } });
      this.bsModalRef = this.modalService.show(CreateCreditCardComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { partyId:partyId } });
      this.bsModalRef = this.modalService.show(CreateCreditCardComponent,{class: 'modal-lg'});
    }

  }
  openGiftModal(partyId){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { partyId:partyId } });
      this.bsModalRef = this.modalService.show(CreateGiftCardComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { partyId:partyId } });
      this.bsModalRef = this.modalService.show(CreateGiftCardComponent,{class: 'modal-lg'});
    }

  }
  openEftModal(partyId){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { partyId:partyId,isEftAccount:true } });
      this.bsModalRef = this.modalService.show(CreateEFTAccountComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { partyId:partyId,isEftAccount:true } });
      this.bsModalRef = this.modalService.show(CreateEFTAccountComponent,{class: 'modal-lg'});
    }
 
  }
  openCheckModal(partyId){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { partyId:partyId } });
      this.bsModalRef = this.modalService.show(CreateCheckAccountComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { partyId:partyId } });
      this.bsModalRef = this.modalService.show(CreateCheckAccountComponent,{class: 'modal-lg'});
    }

  }

  openBillModal(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.router.navigate([],{ queryParams: { partyId:this.taxAuthPartyId } });
      this.bsModalRef = this.modalService.show(CreateBillingAccountComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
    else{
      this.router.navigate([],{ queryParams: { partyId:this.taxAuthPartyId } });
      this.bsModalRef = this.modalService.show(CreateBillingAccountComponent,{class: 'modal-lg'});
    }

    //this.showBillModal = true;
  }

  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
