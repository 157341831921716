import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { NgxDailyGanttChartModule } from 'ngx-daily-gantt-chart';

@Component({
  selector: 'app-detail-project-summary',
  templateUrl: './detail-project-summary.component.html',
  styleUrls: ['./detail-project-summary.component.css']
})
export class DetailProjectSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  @ViewChild('closebuttonB') closebuttonB;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton11') closebutton11;
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  newprojectForm: FormGroup;
  PriorityList: any;
  PriorityListArray: any[] = [];
  ScopeList: any;
  ScopeListArray: any[] = [];
  ParentProjectName: any;
  ParentProjectNameArray: any[] = [];
  PartyId: any;
  partyIdArray: any[] = [];
  OrganizationFindEmployment: any;
  OrganizationFindEmploymentArray: any[] = [];
  TemplateId: any;
  TemplateIdArray: any[] = [];
  orderIdListData: any;
  editProject: FormGroup;
  addPhase: FormGroup;
  addOrderID: FormGroup;
  addResource: FormGroup;
  ProjectName: any;
  ProjectNameArray: any[] = [];
  addSubProject: FormGroup;
  projectName: any;
  fileDatas:File;
  projectID: any;
  newBilling: FormGroup;
  existBilling: FormGroup;
  thrudate: string;
  invoiceID: any;
  invoiceIDArray: any[] = [];
  orderID: any;
  orderIDArray: any[] = [];
  RoleTypeIdTo: any;
  RoleTypeIdToArray: any[] = [];
  PartyIDTo: any;
  PartyIDToArray: any[] = [];
  startdate: string;
  completiondate: string;
  fromdate: string;
  thruudate: string;
  searchOptions: { CommunicationEventID: string; PartyIDFrom: string; Subject: string; EntryDate: string; RoleTypeID: string; };
  entryDate: string;
  mailingListData: any;
  phaseData: any;
  newTaskProject: FormGroup;
  ParentPhase: any;
  ParentPhaseArray: any[] = [];
  RoleResourceList: any;
  RoleResourceListArray: any[] = [];
  SkillTypeId: any;
  SkillTypeIdArray: any[] = [];
  TypeList: any;
  TypeListArray: any[] = [];
  endate: string;
  TaskNameList: any;
  partyId: any;
  roles: any;
  rolesArray: any[] = [];
  taskData: any;
  partyIds: any;
  resourceList: any;
  subprojectList: any;
  ordersList: any;
  projectInfo: any = [];
  timestamp: any;
  clientData: any;
  companiesData: any;
  projectInfoflag: boolean = false;
  updatePhaseForm: FormGroup;
  showPhase: boolean;
  productValue: any;
  productTask: any;
  FindTaskContentById: any;
  fileData: File;
  AddTaskContentForm: FormGroup;
  fileURL: any;
  uploadId: any;
  workEffortContentType: any;
  workEffortContentTypeArray: any[]=[];
  existingContentId: any;
  existingContentIdListArray: any[]=[];
  parentContentIdArray: any[]=[];
  parentContentId: any;
  billingInvoiceList: any;
  mimeTypeIdArray: any[]=[];
  MimeTypeId: any;
  NotesByWorkEffortdata: any;
  filesForm:FormGroup;
  AddTaskNotesForm: FormGroup;
  yesNOArray: { label: string; value: string; }[];
  public: boolean;
  projectBilling: any;
  statusProject: any;
  gantChartList: any;
  projectSummaryById: any;
  getTaskByIdList:any;
  projectSummaryflag: boolean;
  tasks: ({
    id: number; label: string; description: string; start: string; end: string; statusList: { start: string; color: string; }[]; isParent?: undefined; // Unique ID
    parentID?: undefined; // states this is a subtask
    isHidden?: undefined; tooltip?: undefined;
  } | {
    id: number; label: string; description: string; start: string; end: string; isParent: boolean; // makes this row clickable & expandable
    statusList: { start: string; color: string; }[]; // Unique ID
    parentID?: undefined; // states this is a subtask
    isHidden?: undefined; tooltip?: undefined;
  } | {
    id: number; // Unique ID
    parentID: number; // states this is a subtask
    isHidden: boolean; // hidden by default
    label: string; // is shown inside the bars on timeline
    description: string; tooltip: string; // is shown when task is hovered
    start: string; // start time of the task
    end: string; // end time of the task
    statusList: ({
      start: string; // start time of first status
      color: string; // background color of the status
      tooltip?: undefined;
    } | {
      start: string; // start time of second status = end time of first status
      color: string; tooltip: string;
    })[]; isParent?: undefined;
  })[];
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  HidebtnRoleView: boolean=false;
  HidebtnTaskCrud: boolean=false;
  HidebtnTimeCurd: boolean=false;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
        private _location: Location,
        readonly myContactsService: MyContactsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly addSkillService: AddSkillService,
    readonly _ToastrService: ToastrService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly router: Router,
    readonly _Router: Router
  ) {
    this.searchOptions = {
      CommunicationEventID: "",
      PartyIDFrom: "",
      Subject: "",
      EntryDate: "",
      RoleTypeID: ""
    };
    this.newprojectForm = this._FormBuilder.group({
      projTempid: [''],
      projectName: [''],
      description: [''],
      clientBillingID: [''],
      organizationID: [''],
      priority: [''],
      scope: [''],
      parentProjectName: [''],
      estimatedStartDate: [''],
      estimatedCompletionDate: [''],
      mailingEmail: [''],
    });
    this.editProject = this._FormBuilder.group({
      projectName: [''],
      description: [''],
      status: [''],
      clientBillingPartyID: [''],
      organizationPartyID: [''],
      priority: [''],
      scope: [''],
      parentProjectName: [''],
      estimatedStartDate: [''],
      estimatedCompletionDate: [''],
      actualStartDate: [''],
      actualCompletionDate: [''],
      mailingemail: [''],
    });

    this.addPhase = this._FormBuilder.group({
      SequenceNum: [''],
      PhaseName: [''],
      Description: [''],
    });
    this.updatePhaseForm = this._FormBuilder.group({
      SequenceNum: [''],
      PhaseName: [''],
      Description: [''],
    });
    this.addOrderID = this._FormBuilder.group({
      OrderID: [''],
    });
    this.addSubProject = this._FormBuilder.group({
      projectName: [''],
    });
    this.newBilling = this._FormBuilder.group({
      fromParty: [''],
      toParty: [''],
      throughDate: [''],
    });
    this.existBilling = this._FormBuilder.group({
      billtoParty: [''],
      invoiceID: [''],
      throughDate: [''],
    });
    this.addResource = this._FormBuilder.group({
      partyID: [''],
      FromDate: [''],
      ThroughDate: [''],
      roleTypeId: [''],
      
    });
    this.newTaskProject = this._FormBuilder.group({
      taskName: "",
      parentPhase: "",
      sequenceNum: "",
      roleWeek: "",
      estimatedHours: "",
      skillTypeID: "",
      priority: "",
      type: "",
      description: "",
      scope: "",
      startDate: "",
      endDate: "",
    });
    this.AddTaskContentForm = this._FormBuilder.group({
      ExistingContent: "",
      WorkEffortContentTypeID: "",
      Upload: "",
      MimeTypeID: "",
      ParentContent: "",

    });
    this.filesForm = this._FormBuilder.group({
      Upload: "",

    });
    this.AddTaskNotesForm = this._FormBuilder.group({
      Note: "",
      InternalNote: "",

    });
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
   
   
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.Hidebtn=true;
    }
      else if(this.userPermissions.PROJECTMGR_ROLE_VIEW=="PROJECTMGR_ROLE_VIEW"){
      this.HidebtnRoleView=true;
    }
     else if(this.userPermissions.PROJECTMGR_ROLE_TASK_CREATE=="PROJECTMGR_ROLE_TASK_CREATE"){
      this.HidebtnTaskCrud=true;
    }
    else if(this.userPermissions.PROJECTMGR_ROLE_TIMESHEET_CREATE=="PROJECTMGR_ROLE_TIMESHEET_CREATE"){
      this.HidebtnTimeCurd=true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.projectID = params.projectID;
    })
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyIds = params["partyId"];
    });
   

    
    this.getPriorityList();
    this.getScopeList();
    this.getParentProjectNameList();
    this.getProductMemberParty();
    this.getInternalOrganizationFindEmployment();
    this.getTemplateId();
    this.orderIdList();
    this.getProjectName();
    this.getInvoiceId();
    this.getorderId();
    this.getRoleTypeIdTo();
    this.getPartyId();

    this.getProjectInformationById();
    this.getFindMailingList();
    this.getParentPhaseList();
    this.getRoleResourceList();
    this.getSkillTypeId();
    this.getTypeList();
    this.getPhaseById();
    this.taskById();
    this.getResourceListByProjectId();
    this.getSubProjectsById();
    this.getParticularOrderById();
    this.getClientContacts();
    this.getCompanies();
    this.getFindTaskContentById();
    this.workEffortContentTypeList();
    this.existingContentIdList();
    this.parentContentIdList();
    this.getBillingPage();
    this.getMimeTypeId();
    this.getNotesByWorkEffort();
    this.getProjectBilling();
    this.getGantChart();
    this.getProjectSummaryById();
    this.gant();
    this.getRoles();
    this.getTaskById();
  
  }
  fileProgress(fileInput: any) {
    this.fileDatas = <File>fileInput.target.files[0];
  }
  onCreateFile() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileDatas);

    this.accountsService.newTaskById(formData,this.projectID).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.fileURL = res.data.URL;
      }
    }, (err) => {
      this.toastr.error(err.message);
      this.spinner.hide();
    })

  }
  getTaskById(){
    this.spinner.show();
    this.accountsService.getTaskById(this.projectID).subscribe((res:any)=> {
      this.getTaskByIdList = res.data.data;
      this.spinner.hide();
    
    })   
   }
  getdownloadTaskById(imageUrl) {
    this.spinner.show();
    this.accountsService.downloadTaskById(imageUrl).subscribe(res => {
      const blob = new Blob([res], { type: 'document/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'productContent';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

  }

  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  


  gant(){
    this.spinner.show();
    this.myContactsService.getGantChart(this.projectID).subscribe((res:any)=> {
      this.tasks = res.data;
      // this.tasks = [
      //   {
      //     id: 1,
      //     label: this.gantChartList.data.workEffortName,
      //     description: this.gantChartList.des.workEffortName,
      //     start: this.gantChartList.startDate,
      //     end: this.gantChartList.endDate,
      //     statusList: [
      //       {
      //         start: '09:30',
      //         color: '#18BFED'
      //       },
      //       {
      //         start: '10:30',
      //         color: '#b3c71e'
      //       }
      //     ]
      //   },
      //   // {
      //   //   id: 2,
      //   //   label: 'task 2',
      //   //   description: 'description for task 2',
      //   //   start: '10:00',
      //   //   end: '11:00',
      //   //   isParent: true, // makes this row clickable & expandable
      //   //   statusList: [
      //   //     {
      //   //       start: '09:30',
      //   //       color: '#18BFED'
      //   //     },
      //   //     {
      //   //       start: '09:45',
      //   //       color: '#ff7300'
      //   //     },
      //   //     {
      //   //       start: '10:30',
      //   //       color: '#b3c71e'
      //   //     }
      //   //   ]
      //   // },
      //   // {
      //   //   id: 3, // Unique ID
      //   //   parentID: 2, // states this is a subtask
      //   //   isHidden: true, // hidden by default
      //   //   label: 'task 2a', // is shown inside the bars on timeline
      //   //   description: 'description for task 2a',
      //   //   tooltip: 'tooltip for task', // is shown when task is hovered
      //   //   start: '10:00', // start time of the task
      //   //   end: '14:25', // end time of the task
      //   //   statusList: [
      //   //     {
      //   //       start: '11:30', // start time of first status
      //   //       color: '#18BFED' // background color of the status
      //   //     },
      //   //     {
      //   //       start: '12:30', // start time of second status = end time of first status
      //   //       color: '#b3c71e',
      //   //       tooltip: 'tooltip for status', // is shown when status is hovered
      //   //     }
      //   //   ]
      //   // }
      // ];
      this.spinner.hide();
    })  
  
  }
  getGantChart(){
    this.spinner.show();
    this.myContactsService.getGantChart(this.projectID).subscribe((res:any)=> {
      this.gantChartList = res.data;
      this.spinner.hide();
    
    })   
   }
  getProjectSummaryById() {
    this.spinner.show();
    this.myContactsService.getProjectSummaryById(this.projectID).subscribe(res => {
      this.projectSummaryById = res.data[0];
      this.projectSummaryflag = true;
      this.statusProject = res.data[0].data1.currentStatusId;

      setTimeout(() => {
        const formValue = this.editProject;
        formValue.patchValue({
          projectName: this.projectSummaryById.data1.taskName,
          description:  this.projectSummaryById.data1.description,
          status:  this.projectSummaryById.data1.currentStatusId,
          clientBillingPartyID:  "",
          organizationPartyID: "",
          priority:  this.projectSummaryById.data1.priority,
          scope:  "",
          parentProjectName: this.projectSummaryById.data1.phaseName,
          estimatedStartDate:  this.datePipe.transform(this.projectSummaryById.data1.estimatedStartDate, "yyyy-MM-dd"), 
          estimatedCompletionDate:   this.datePipe.transform(this.projectSummaryById.data1.estimatedCompletionDate, "yyyy-MM-dd"),
          actualStartDate: this.datePipe.transform(this.projectSummaryById.data1.actualStartDate, "yyyy-MM-dd"),
          actualCompletionDate:  this.datePipe.transform(this.projectSummaryById.data1.actualCompletionDate, "yyyy-MM-dd"),
          mailingemail: ""
        })
  
      }, 2000);

      this.spinner.hide();
    })
  }


  getProjectBilling(){
    this.spinner.show();
    this.myContactsService.getProjectBilling(this.projectID).subscribe((res:any)=> {
      this.projectBilling = res.data;
      this.spinner.hide();
    
    })   
   }
  makeNotePublic(product) {
    this.spinner.show();
    const requestData = {
      "internalNote": "Y",
      "noteId": product.noteData.noteId,
      "view_INDEX_1": "20",
      "view_SIZE_1": "0",
      "workEffortId": this.projectID
    }
    this.myContactsService.makeNotePrivate(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        // if(){
        //   this.public = true;
        // }
        
        this.getNotesByWorkEffort();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  makeNotePrivate(product) {
    this.spinner.show();
    const requestData = {
      "internalNote": "Y",
      "noteId": product.noteData.noteId,
      "view_INDEX_1": "20",
      "view_SIZE_1": "0",
      "workEffortId": this.projectID
    }
    this.myContactsService.makeNotePrivate(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.public = false;

        this.getNotesByWorkEffort();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  addTaskNote() {
    this.spinner.show();

    const formData = {
      "internalNote": this.AddTaskNotesForm.value.InternalNote ,
      "noteInfo": this.AddTaskNotesForm.value.Note ,
      "noteName": "",
      "noteParty": "",
      "workEffortId": this.projectID

    }
    this.myContactsService.addProjectNote(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.AddTaskNotesForm.reset();
        this.getNotesByWorkEffort();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getNotesByWorkEffort(){
    this.spinner.show();
    this.myContactsService.getNotesByWorkEffort(this.projectID).subscribe((res:any)=> {
      this.NotesByWorkEffortdata = res.data;
      this.spinner.hide();
    
    })   
   }
  getMimeTypeId() {
    this.spinner.show();
    this.accountsService.getMimeTypeId().subscribe(res => {
      this.MimeTypeId = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.MimeTypeId) {
        this.mimeTypeIdArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }
  getBillingPage() {
    this.spinner.show();
    this.myContactsService.getBillingPage(this.projectID).subscribe(res => {
      this.billingInvoiceList = res.data[0];
      setTimeout(() => {
        const formValue = this.existBilling;
        formValue.patchValue({
          billtoParty:  this.billingInvoiceList.productRoles.partyId,
          throughDate:   this.billingInvoiceList.productRoles.thruDate,
        })
  
      }, 2000);
      setTimeout(() => {
        const formValue = this.newBilling;
        formValue.patchValue({
          fromParty:  this.billingInvoiceList.billToParty,
          toParty:   this.billingInvoiceList.productRoles.partyId,
          throughDate:   this.billingInvoiceList.productRoles.thruDate,
        })
  
      }, 2000);
      this.spinner.hide();
    })
  }
  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  parentContentIdList() {
    this.spinner.show();
    this.myContactsService.parentContentIdList().subscribe(res => {
      this.parentContentId = res.data;
      this.spinner.hide();
      for (const value of this.parentContentId) {
        this.parentContentIdArray.push({
          label: value.contentIdTo,
          value: value.contentId
        })
      }
    })
  }
  existingContentIdList() {
    this.spinner.show();
    this.myContactsService.existingContentIdList().subscribe(res => {
      this.existingContentId = res.data;
      this.spinner.hide();
      for (const value of this.existingContentId) {
        this.existingContentIdListArray.push({
          label: value.description,
          value: value.contentId
        })
      }
    })
  }
  workEffortContentTypeList() {
    this.spinner.show();
    this.myContactsService.workEffortContentTypeList().subscribe(res => {
      this.workEffortContentType = res.data.node;
      this.spinner.hide();
      for (const value of this.workEffortContentType) {
        this.workEffortContentTypeArray.push({
          label: value.description,
          value: value.workEffortContentTypeId
        })
      }
    })
  }
 
  downloadFileTaskContent(fileUrl) {
    this.spinner.show();
    this.myContactsService.downloadFileTaskContent(fileUrl).subscribe(res => {
      const blob = new Blob([res], { type: 'document/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'ViewSimpleContent';
      this.spinner.hide();
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

  }

  deletedTaskContentById(contentId) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
      
        this.myContactsService.deletedTaskContentById(contentId).subscribe(res => {
          this.getFindTaskContentById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  getFindTaskContentById() {
    this.spinner.show();
    this.myContactsService.getFindTaskContentById(this.projectID).subscribe(res => {
      this.FindTaskContentById = res.data.node;
      this.spinner.hide();
    })
  }
  uploadFormContent(){
    this.onSubmitFile();

    setTimeout(() => {
      this.addTaskContent();
    }, 4000);
   
  }
  addTaskContent() {
    this.spinner.show();
    const formData = {
      
      "existingContentId":this.AddTaskContentForm.value.ExistingContent,
      "fileUrl":this.fileURL,
      "id": "",
      "mimeTypeId": this.AddTaskContentForm.value.MimeTypeID,
      "parentContentId":this.AddTaskContentForm.value.ParentContent,
      "workEffortContentTypeId":this.AddTaskContentForm.value.WorkEffortContentTypeID,
      "workEffortId":this.projectID
    }
    this.myContactsService.addTaskContent(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.AddTaskContentForm.reset();
        this.getFindTaskContentById();
        this.uploadId = res.data.id;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  onSubmitFile() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.attachFile(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.fileURL = res.data.URL;
      }
    }, (err) => {
      this.toastr.error(err.message);
      this.spinner.hide();
    })

  }
  uploadFileTaskContent() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.myContactsService.uploadFileTaskContent(formData,this.uploadId, this.AddTaskContentForm.value.Upload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getFindTaskContentById();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  myProfile(partyId) {
    this.router.navigate(["/psa/my-profile"], { queryParams: { partyId: partyId } })
  }
  
  deleteWorkEffortPartyAssign(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
         

          "fromDate":   this.datePipe.transform(product.fromDate, "yyyy-MM-dd hh:mm:ss") ,
          "partyId":product.partyId,
          "projectId": this.projectID,
          "roleTypeId": product.roleTypeId,
          "thruDate":   this.datePipe.transform(product.thruDate, "yyyy-MM-dd hh:mm:ss") ,
          "workEffortId": this.projectID
      
        }
        this.myContactsService.deleteWorkEffortPartyAssign(formData).subscribe(res => {
          this.getResourceListByProjectId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  createWorkEffortPartyAssign() {
    this.spinner.show();
    const formData = {
      "fromDate": this.datePipe.transform(this.addResource.value.FromDate, "yyyy-MM-dd hh:mm:ss") ,
      "partyId": this.addResource.value.partyID  ,
      "projectId": this.projectID,
      "roleTypeId": this.addResource.value.roleTypeId ,
      "statusId": "PAS_ASSIGNED",
      "thruDate":      this.datePipe.transform(this.addResource.value.ThroughDate, "yyyy-MM-dd hh:mm:ss") ,
      "workEffortId": this.projectID,
  
    }
    this.myContactsService.createWorkEffortPartyAssign(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.addResource.reset();
        this.getResourceListByProjectId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  taskPage(workEffortId) {
    this._Router.navigate(["/psa/project/detail-task-project"], 
    { queryParams: { workEffortId: workEffortId } })
  }
  closeStatus() {
    this.spinner.show();
    const formData = {
      "currentStatusId": "PRJ_CLOSED",
      "workEffortId": this.projectID
    }
    this.myContactsService.closeStatus(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getTaskNameList();
        this.activeCategory =2;
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  copyProject() {
    this.spinner.show();
    const formData = {
      "projectId": this.projectID
    }
    this.myContactsService.copyProject(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getTaskNameList();
        this.activeCategory =2;
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  schedule() {
    this.spinner.show();
    const formData = {
      "projectId": this.projectID
    }
    this.myContactsService.schedule(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getTaskNameList();
        this.activeCategory = 1;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  copyProjectToTemplate() {
    this.spinner.show();
    const formData = {
      "projectId": this.projectID
    }
    this.myContactsService.copyProjectToTemplate(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getTaskNameList();
        this.activeCategory =2;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
 

  getPriorityList() {
    this.spinner.show();
    this.myContactsService.getPriorityList().subscribe(res => {
      this.PriorityList = res.data;
      this.spinner.hide();
      for (const value of this.PriorityList) {
        this.PriorityListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }
  getScopeList() {
    this.spinner.show();
    this.myContactsService.getScopeList().subscribe(res => {
      this.ScopeList = res.data;
      this.spinner.hide();
      for (const value of this.ScopeList) {
        this.ScopeListArray.push({
          label: value.scopeData.description,
          value: value.scopeData.enumId
        })
      }
    })
  }
  getParentProjectNameList() {
    this.spinner.show();
    this.myContactsService.getParentProjectNameList().subscribe(res => {
      this.ParentProjectName = res.data;
      this.spinner.hide();
      for (const value of this.ParentProjectName) {
        this.ParentProjectNameArray.push({
          label: value.ParentProjectNameList.workEffortName,
          value: value.ParentProjectNameList.workEffortId
        })
      }
    })
  }
  getProductMemberParty() {
    this.spinner.show();
    this.accountsService.getProductMemberParty().subscribe(res => {
      this.PartyId = res.data[0].partyId;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getInternalOrganizationFindEmployment() {
    this.spinner.show();
    this.myContactsService.getInternalOrganizationFindEmployment().subscribe(res => {
      this.OrganizationFindEmployment = res.data;
      this.spinner.hide();
      for (const value of this.OrganizationFindEmployment) {
        this.OrganizationFindEmploymentArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }
    })
  }
  getTemplateId() {
    this.spinner.show();
    this.myContactsService.getTemplateId().subscribe(res => {
      this.TemplateId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.TemplateId) {
        this.TemplateIdArray.push({
          label: value.workEffortName,
          value: value.workEffortId
        })
      }
    })
  }
  getProjectName() {
    this.spinner.show();
    this.myContactsService.getProjectName().subscribe(res => {
      this.ProjectName = res.data[0].projectName;
      this.spinner.hide();
      for (const value of this.ProjectName) {
        this.ProjectNameArray.push({
          label: value.workEffortName,
          value: value.workEffortId
        })
      }
    })
  }
  getInvoiceId() {
    this.spinner.show();
    this.myContactsService.getInvoiceId().subscribe(res => {
      this.invoiceID = res.data[0].Invoice;
      this.spinner.hide();
      for (const value of this.invoiceID) {
        this.invoiceIDArray.push({
          label: value.invoiceId,
          value: value.invoiceId
        })
      }
    })
  }

  getorderId() {
    this.spinner.show();
    this.myContactsService.getorderId().subscribe(res => {
      this.orderID = res.data.node;
      this.spinner.hide();
      for (const value of this.orderID) {
        this.orderIDArray.push({
          label: value.orderId,
          value: value.orderId
        })
      }
    })
  }

  orderIdList() {
    this.spinner.show();
    this.myContactsService.orderIdList().subscribe((res: any) => {
      this.orderIdListData = res.data.node;
      this.spinner.hide();
    })
  }
  getRoleTypeIdTo() {
    this.spinner.show();
    this.accountsService.getRoleTypeIdTo().subscribe(res => {
      this.RoleTypeIdTo = res.data;
      this.spinner.hide();
      for (const value of this.RoleTypeIdTo) {
        this.RoleTypeIdToArray.push({
          label: value.roleTypeId,
          value: value.roleTypeId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.PartyIDTo = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.PartyIDTo) {
        this.PartyIDToArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  createProject() {
    this.spinner.show();
    const sdate = this.newprojectForm.get('estimatedStartDate').value;
    this.fromdate = this.datePipe.transform(sdate, "yyyy-MM-dd hh:mm:ss");
    const edate = this.newprojectForm.get('estimatedCompletionDate').value;
    this.thruudate = this.datePipe.transform(edate, "yyyy-MM-dd hh:mm:ss");
    const formData = {
      "clientBillingPartyId": this.newprojectForm.value.clientBillingID,
      "currentStatusId": "",
      "description": this.newprojectForm.value.description,
      "emailAddress": this.newprojectForm.value.mailingEmail,
      "estimatedCompletionDate": this.thruudate,
      "estimatedStartDate": this.fromdate,
      "organizationPartyId": this.newprojectForm.value.organizationID,
      "priority": this.newprojectForm.value.priority,
      "projectId": this.projectID,
      "scopeEnumId": this.newprojectForm.value.scope,
      "templateId": this.newprojectForm.value.projTempid,
      "workEffortId": "",
      "workEffortName":  this.newprojectForm.value.projectName,
      "workEffortParentId": this.newprojectForm.value.parentProjectName,
      "workEffortTypeId": "PROJECT",
      "actualCompletionDate": "",
      "actualStartDate": "",
      "dummy": "",
    }
    this.myContactsService.createProject(formData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
          this._Router.navigate(["/psa/project/detail-my-task"],
            { queryParams: { workEffortId: this.projectID } })
      
         this.closebutton7.nativeElement.click();
        // this.getPaymentMethods();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  updateProject() {
    this.spinner.show();
    const sdate = this.editProject.get('estimatedStartDate').value;
    this.startdate = this.datePipe.transform(sdate, "yyyy-MM-dd hh:mm:ss");
    const edate = this.editProject.get('estimatedCompletionDate').value;
    this.completiondate = this.datePipe.transform(edate, "yyyy-MM-dd hh:mm:ss");
    const formData = {
      "estimatedCompletionDate": this.completiondate,
      "estimatedStartDate": this.startdate,
      "priority": this.editProject.value.priority,
      "projectId": this.projectID,
      "workEffortId": this.projectID,
      "workEffortName": this.editProject.value.projectName,
      "workEffortParentId": this.editProject.value.parentProjectName,
      "clientBillingPartyId":  this.editProject.value.clientBillingPartyID,
      "currentStatusId":  this.editProject.value.status,
      "description":  this.editProject.value.description,
      "emailAddress":  this.editProject.value.mailingemail,
      "organizationPartyId":  this.editProject.value.organizationPartyID,
      "scopeEnumId": this.editProject.value.scope,
      "templateId": "",
      "workEffortTypeId": "PROJECT",
      "actualCompletionDate": this.datePipe.transform(this.editProject.value.actualCompletionDate, "yyyy-MM-dd hh:mm:ss"),
      "actualStartDate": this.datePipe.transform(this.editProject.value.actualStartDate, "yyyy-MM-dd hh:mm:ss"),
      "dummy": "PTS_CREATED_IP",

    }
    this.myContactsService.updateProject(formData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Update Successfully");
        this.activeCategory = 2;
        this.getProjectInformationById();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  createOrderId() {
    this.spinner.show();
    const formData = {
      "orderId": this.addOrderID.value.OrderID,
      "workEffortId": this.projectID
    }
    this.myContactsService.createOrderId(formData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
         this.closebutton8.nativeElement.click();
        this.orderIdList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  AddSubProject() {
    this.spinner.show();
    const formData = {
      "projectId": this.projectID,
      "workEffortId": this.addSubProject.value.projectName,
      "workEffortParentId": this.projectID
    }
    this.myContactsService.AddSubProject(formData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.addSubProject.reset();
        //this.closebutton7.nativeElement.click();
        this.getSubProjectsById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  addProjectTimeToNewInvoice() {
    this.spinner.show();
    const throughDatebilling = this.newBilling.get('throughDate').value;
    this.thrudate = this.datePipe.transform(throughDatebilling, "yyyy-MM-dd hh:mm:ss");
    const formData = { 
      "invoiceId": "",
      "partyId": "",
      "partyIdFrom": this.newBilling.value.fromParty ,
      "projectId": this.projectID,
      "thruDate": this.thrudate
    }
    this.myContactsService.addProjectTimeToNewInvoice(formData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        // this.closebutton7.nativeElement.click();
        // this.getPaymentMethods();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) { 
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  addProjectTimeToInvoice() {
    this.spinner.show();
    const throughDatebilling = this.existBilling.get('throughDate').value;
    this.thrudate = this.datePipe.transform(throughDatebilling, "yyyy-MM-dd hh:mm:ss");
    const formData = {
      "billedPartyId": this.existBilling.value.billtoParty,
      "invoiceId": this.existBilling.value.invoiceID,
      "projectId": this.projectID,
      "thruDate": this.thrudate
    }
    this.myContactsService.addProjectTimeToInvoice(formData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        // this.closebutton7.nativeElement.click();
        // this.getPaymentMethods();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }

  addPhases() {
    this.spinner.show();
    const formData = {
      "currentStatusId": "_NA_",
      "description": this.addPhase.value.Description,
      "projectId": this.projectID,
      "sequenceNum": this.addPhase.value.SequenceNum,
      "workEffortName": this.addPhase.value.PhaseName,
      "workEffortParentId": this.projectID,
      "workEffortTypeId": "PHASE",

      
    }
    this.myContactsService.addPhase(formData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonA.nativeElement.click();
        this.addPhase.reset();
        this.getPhaseById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  updatePHASE(product) {
   
    this.productValue = product;
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.updatePhaseForm;
      formValue.patchValue({
        PhaseName: product.data.workEffortName,
        SequenceNum: "",
      })

    }, 2000);
    this.spinner.hide();
  }

  updatePhase() {
    this.spinner.show();
    const formData = {
      "actualCompletionDate":this.datePipe.transform(this.productValue.data.actualCompletionDate, "yyyy-MM-dd"),
      "actualHours": "",
      "actualStartDate":this.datePipe.transform(this.productValue.data.actualStartDate, "yyyy-MM-dd"), 
      "dummy": "PTS_CREATED_IP",
      "estimatedCompletionDate":this.datePipe.transform(this.productValue.data.estimatedCompletionDate, "yyyy-MM-dd"),
      "estimatedStartDate":this.datePipe.transform(this.productValue.data.estimatedStartDate, "yyyy-MM-dd"), 
      "plannedHours": "",
      "priority": this.productValue.data.priority,
      "projectId": this.projectID,
      "scopeEnumId": this.productValue.data.scopeEnumId,
      "sequenceNum": this.updatePhaseForm.value.SequenceNum,
      "workEffortId": this.productValue.data.workEffortId,
      "workEffortName": this.updatePhaseForm.value.PhaseName,
    }
    this.myContactsService.updatePhase(formData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Update Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
       this.getPhaseById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  updateTask(product) {
   
    this.productTask = product;
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.newTaskProject;
      formValue.patchValue({
        taskName: product.description.workEffortName,
        parentPhase: product.description.workEffortParentId,
        sequenceNum:  product.description.sequenceNum,
        roleWeek: "",
        estimatedHours: "",
        skillTypeID: "",
        priority: product.description.priority,
        type : product.description.workEffortTypeId,
        description: "",
        scope: "",
        startDate: "",
        endDate: "",
      })

    }, 2000);
    this.spinner.hide();
  }
  createNewTask() {
    this.spinner.show();

    const startDate = this.newTaskProject.get('startDate').value;
    this.startdate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const endDate = this.newTaskProject.get('endDate').value;
    this.endate = this.datePipe.transform(endDate, "yyyy-MM-dd hh:mm:ss");

    const formData = {
      "currentStatusId": "PTS_CREATED",
      "description": this.newTaskProject.value.description,
      "estimatedCompletionDate": this.endate,
      "estimatedHours": this.newTaskProject.value.estimatedHours,
      "estimatedStartDate": this.startdate,
      "partyId": "admin",
      "priority": this.newTaskProject.value.priority,
      "projectId": "",
      "roleTypeId": this.newTaskProject.value.roleWeek,
      "scopeEnumId": this.newTaskProject.value.scope,
      "sequenceNum": this.newTaskProject.value.sequenceNum,
      "skillTypeId": this.newTaskProject.value.skillTypeID,
      "statusId": "PAS_ASSIGNED",
      "workEffortName": this.newTaskProject.value.taskName,
      "workEffortParentId": this.newTaskProject.value.parentPhase,
      "workEffortTypeId": this.newTaskProject.value.type,
    }
    this.myContactsService.createNewTask(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonB.nativeElement.click();
        this.getTaskNameList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  updateProjectTask() {
    this.spinner.show();

    const startDate = this.newTaskProject.get('startDate').value;
    this.startdate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const endDate = this.newTaskProject.get('endDate').value;
    this.endate = this.datePipe.transform(endDate, "yyyy-MM-dd hh:mm:ss");

    const formData = {
      "estimatedCompletionDate": this.endate,
      "estimatedStartDate": this.startdate,
      "priority": this.newTaskProject.value.priority,
      "projectId": this.projectID,
      "sequenceNum": this.newTaskProject.value.sequenceNum,
      "workEffortName": this.newTaskProject.value.taskName,
      "workEffortParentId": this.newTaskProject.value.parentPhase,
      "actualHours":  "" ,
      "edit": "",
      "estimatedDuration": "" ,
      "view_INDEX": "0",
      "workEffortId": this.productTask.description.workEffortId ,
    }
    this.myContactsService.updateProjectTask(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.getTaskNameList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getTaskNameList() {
    this.spinner.show();
    this.myContactsService.getTaskNameList().subscribe(res => {
      this.TaskNameList = res.data;
      this.spinner.hide();

    })
  }
  getPhaseById() {
    this.spinner.show();
    this.myContactsService.getPhaseById(this.projectID).subscribe(res => {
      this.phaseData = res.data;
      this.spinner.hide();
    })
  }
  taskById() {
    this.spinner.show();
    this.myContactsService.taskById(this.projectID).subscribe(res => {
      this.taskData = res.data;
      this.spinner.hide();
    })
  }
  getClientContacts() {
    this.spinner.show();
    this.myContactsService.getClientContacts(this.projectID).subscribe(res => {
      this.clientData = res.data[0].node;
      this.spinner.hide();
    })
  }
  getCompanies() {
    this.spinner.show();
    this.myContactsService.getCompanies(this.projectID).subscribe(res => {
      this.companiesData = res.data;
      this.spinner.hide();
    })
  }
  getResourceListByProjectId() {
    this.spinner.show();
    this.myContactsService.getResourceListByProjectId(this.projectID).subscribe(res => {
      this.resourceList = res.data[0]?.ResourceListByProjectId;
      this.spinner.hide();
    })
  }
  getSubProjectsById() {
    this.spinner.show();
    this.myContactsService.getSubProjectsById(this.projectID).subscribe(res => {
      this.subprojectList = res.data;
      this.timestamp = res.timestamp;
      this.spinner.hide();
    })
  }

  getParticularOrderById() {
    this.spinner.show();
    this.myContactsService.getParticularOrderById(this.projectID).subscribe(res => {
      this.ordersList = res.data;
      this.spinner.hide();
    })
  }
  getProjectInformationById() {
    this.spinner.show();
    this.myContactsService.getProjectInformationById(this.projectID).subscribe(res => {
      if (res.data.length > 0) {
        this.projectInfo = res.data[0];
        this.projectInfoflag = true;

      }

   
      this.spinner.hide();
    })
  }

  resetFindMailing() {
    this.spinner.show();
    // this.searchOptions = null;
    this.searchOptions.CommunicationEventID = null;
    this.searchOptions.EntryDate = null;
    this.searchOptions.PartyIDFrom = null;
    this.searchOptions.RoleTypeID = null;
    this.searchOptions.Subject = null;
    // this.myContactsService.getFindProjectList().subscribe((res: any) => {
    //   this.mailingListData = res.data;
    this.getFindMailingList();
    this.spinner.hide();
    // })
  }

  getFindMailing() {
    this.spinner.show();
    if (this.searchOptions.CommunicationEventID !== "" || this.searchOptions.EntryDate !== "" ||
      this.searchOptions.PartyIDFrom !== "" || this.searchOptions.RoleTypeID !== "" || this.searchOptions.Subject !== "") {
      const req = {
        "communicationEventId": this.searchOptions.CommunicationEventID ? this.searchOptions.CommunicationEventID : '',
        "entryDate": this.searchOptions.EntryDate ? this.searchOptions.EntryDate : '',
        "partyIdTo": this.searchOptions.PartyIDFrom ? this.searchOptions.PartyIDFrom : '',
        "roleTypeIdTo": this.searchOptions.RoleTypeID ? this.searchOptions.RoleTypeID : '',
        "workEffortId": "" ? "" : '',
      }
      this.myContactsService.findMailing(req.communicationEventId, req.entryDate,
        req.partyIdTo, req.roleTypeIdTo, req.workEffortId).subscribe((res: any) => {
          this.mailingListData = res.data;
          this.spinner.hide();
        })
    }
    else {
      this.getFindMailingList();
    }
    this.spinner.hide();
  }

  getFindMailingList() {
    this.spinner.show();
    this.myContactsService.findMailingList().subscribe(res => {
      this.mailingListData = res.data;
      // this.getFindMailingList = res.data;
      this.spinner.hide();
    })
  }
  getParentPhaseList() {
    this.spinner.show();
    this.myContactsService.getParentPhaseList().subscribe(res => {
      this.ParentPhase = res.data;
      this.spinner.hide();
      for (const value of this.ParentPhase) {
        this.ParentPhaseArray.push({
          label: value.getParentPhaseList.projectName + "--" + value.getParentPhaseList.phaseName,
          value: value.getParentPhaseList.phaseId
        })
      }
    })
  }
 
  getSkillTypeId() {
    this.spinner.show();
    this.myContactsService.getSkillTypeId().subscribe(res => {
      this.SkillTypeId = res.data;
      this.spinner.hide();
      for (const value of this.SkillTypeId) {
        this.SkillTypeIdArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }
    })
  }
  getTypeList() {
    this.spinner.show();
    this.myContactsService.getTypeList().subscribe(res => {
      this.TypeList = res.data;
      this.spinner.hide();
      for (const value of this.TypeList) {
        this.TypeListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }
  getRoleResourceList() {
    this.spinner.show();
    this.myContactsService.getRoleTaskList().subscribe(res => {
      this.RoleResourceList = res.data;
      this.spinner.hide();
      for (const value of this.RoleResourceList) {
        this.RoleResourceListArray.push({
          label: value.roleTypeData.description,
          value: value.roleTypeData.roleTypeId
        })
      }
    })
  }
  getRoles() {
    this.spinner.show();
    this.myContactsService.getRoles(this.partyIds).subscribe(res => {
      this.roles = res.data.role;
      this.spinner.hide();
      for (const value of this.roles) {
        this.rolesArray.push({
          label: value.roleTypeId,
          value: value.roleTypeId
        })
      }
    })
  }
  deleteOrderById(order) {
    console.log(order);

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.myContactsService.deleteOrderByIdAPI(order.orderId, order.workEffortId, order.workEffortId).subscribe(res => {
          this.getParticularOrderById();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }

  detailedPage(prjectID) {
    this._Router.navigate(["/psa/project/detail-project-summary"], { queryParams: { projectID: prjectID } })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
