import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CreateInternalService {

  constructor(readonly http:HttpClient) { }

 getApproverParty():Observable<any> {
   const approverParty = URLS.getPersonsGroups;
   return this.http.get(approverParty);
 }
 getDataById(id):Observable<any>{
   const getDataById = URLS.getInternPostingById.replace(":applicationId",id);;
   return this.http.get(getDataById);
 }
 getJobRequistionList(data):Observable<any> {
  const jobRequistionList = URLS.getJobRequisitions;
  return this.http.post(jobRequistionList,data);
}
createForm(formData):Observable<any>{
  const createForm = URLS.createInternalJobPost;
  return this.http.post(createForm,formData)
}
updateForm(formData,id):Observable<any>{
  const updateForm = URLS.updateInternalJobPost.replace(":applicationId",id);
  return this.http.put(updateForm,formData);
}






  
}
