import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ShippingService } from '../shipping/shipping.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-carrier-summary',
  templateUrl: './detail-carrier-summary.component.html',
  styleUrls: ['./detail-carrier-summary.component.css']
})
export class DetailCarrierSummaryComponent implements OnInit {
  activeCategory=1;
  id: any;
  pid: any;
  rid: any;
  snum: any;
  code: any;
 
  @ViewChild('closebutton') closebutton;
 
  activeCategorytab = 2;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  roleIds: any[];
  shipmentTypeIds: any[];
  carrierShipments: any[];
  carrierShipmentForm: FormGroup;
  editMode: boolean;
  editShipment: { partyId: string, roleTypeId: string, shipmentMethodTypeId: string };
  partyIds: any[];
  isShown=false;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    readonly activatedRoute:ActivatedRoute,
private _location: Location,
readonly spinner: NgxSpinnerService,
    readonly _ShippingService: ShippingService,
    readonly _FormBuilder: FormBuilder,
    readonly  router: Router,
   
    readonly _ToastrService: ToastrService
  ) {
    this.shipmentTypeIds = [];
    this.carrierShipments = [];
    this.carrierShipmentForm = this._FormBuilder.group({
      carrierServiceCode: [''],
      partyId: [''],
      roleTypeId: [''],
      sequenceNumber: [''],
      shipmentMethodTypeId: ['']
    });
    this.carrierShipments = [];
    this.shipmentTypeIds = [];
    this.roleIds = [];
    this.editShipment = { partyId: '', roleTypeId: '', shipmentMethodTypeId: '' };
    this.partyIds = [];
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    
    this.getRoleId();
    this.getShipmentTypeId();
    this.getPartyId();
    this.getCarrierShipment();
   
    this.activatedRoute.queryParams.subscribe(params=> [
      this.id = params["id"],
      this.pid=params["pid"],
      this.rid=params["rid"],
      this.snum=params["snum"],
      this.code=params["code"]
    ])
   
  }
  getRoleId(): void {
    this.spinner.show();
    this._ShippingService.getRoleType()
      .then(data => {
        this.roleIds = data.data;
      });
      this.spinner.hide();
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  getPartyId(): void {
    this.spinner.show();
    this._ShippingService.getPartyId()
      .then(data => {
        if (data.success) {
          this.partyIds = data.data.PersonsGroups;
          this.spinner.hide();
        }
      });
     
  }
  getShipmentTypeId(): void {
    this.spinner.show();
    this._ShippingService.shipmentMethodType()
      .then(data => {
        this.shipmentTypeIds = data.data;
        this.spinner.hide();
      });
    
  }
  getCarrierShipment(): void {
    this.spinner.show();
    this._ShippingService.getCarrierShipment({ pageNo: 1, pageSize: 200 })
      .then(data => {
        this.carrierShipments = data.data;
        this.spinner.hide();
      });
    
  }
  edit(): void {
    this.editMode = true;
    this.carrierShipmentForm.patchValue({
      carrierServiceCode: this.code,
      partyId:  this.pid,
      roleTypeId: this.rid,
      sequenceNumber: this.snum,
      shipmentMethodTypeId: this.id
     
    });
  
     this.editShipment = {partyId:  this.pid, roleTypeId: this.rid, shipmentMethodTypeId: this.id };
  /*   this.carrierShipmentForm.patchValue({
      partyId, roleTypeId, shipmentMethodTypeId, sequenceNumber, carrierServiceCode
    });  */
    this.carrierShipmentForm.controls.partyId.disable();
    this.carrierShipmentForm.controls.partyId.updateValueAndValidity();
    this.carrierShipmentForm.controls.roleTypeId.disable();
    this.carrierShipmentForm.controls.roleTypeId.updateValueAndValidity();
    this.carrierShipmentForm.controls.shipmentMethodTypeId.disable();
    this.carrierShipmentForm.controls.shipmentMethodTypeId.updateValueAndValidity();
  }
 
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      this._ShippingService.updateCarrierShipment({
        ...this.editShipment, ...this.carrierShipmentForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.closebutton.nativeElement.click();
            this.router.navigate(["facilities/catalog/shipment/carrier-shipment"])
            this.getCarrierShipment();
            this.carrierShipmentForm.controls.partyId.enable();
            this.carrierShipmentForm.controls.partyId.updateValueAndValidity();
            this.carrierShipmentForm.controls.roleTypeId.enable();
            this.carrierShipmentForm.controls.roleTypeId.updateValueAndValidity();
            this.carrierShipmentForm.controls.shipmentMethodTypeId.enable();
            this.carrierShipmentForm.controls.shipmentMethodTypeId.updateValueAndValidity();
           
          }
        });
    } else {
      this._ShippingService.createCarrierShipment(this.carrierShipmentForm.value)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton.nativeElement.click();
            this.router.navigate(["facilities/catalog/shipment/carrier-shipment"])
            this.getCarrierShipment();
            
          }
        });
    }
   
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  

}
