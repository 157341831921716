<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"> 
                </span>

 <span class="">

    <button type="submit" (click)="homeButton();"
        class=" btn btn-outline-secondary mr-2">Home</button>

    <button type="submit" (click)="cancelSubmit();"
        class="btn btn-danger buttonclass ">Back</button>
</span>

            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                        <li [ngClass]="activeCategory==1?'active':''" (click)="TaskList()"><a>
                            Main </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="TaskList()"><a>
                            Task List
                            </a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="Calendar()"><a>
                            Calendar</a></li>
                        <li [ngClass]="activeCategory==4?'active':''" (click)="MyTime()"><a>
                            My Time
                            </a></li>
                        <li [ngClass]="activeCategory==5?'active':''" (click)="RequestList()"><a>
                            Request List
                            </a></li>
                        <li [ngClass]="activeCategory==6?'active':''" (click)="WorkEffort()"><a>
                            Work Effort
                            </a></li>
                        <li [ngClass]="activeCategory==7?'active':''" (click)="Timesheet()"><a>
                            Timesheet
                            </a></li>
                        <li [ngClass]="activeCategory==8?'active':''" (click)="SubmittedJobs()"><a>
                            Submitted Jobs
                            </a></li>
                        <li [ngClass]="activeCategory==9?'active':''" (click)="iCalendar()"><a>
                            iCalendar
                        </a></li>


                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="w3-card-4  w-100">
                                        <div class="accordion classCard" id="myAccordion">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                        <span class="headingAccords"> Search Options

                                                        </span>
                                                        <button _ngcontent-cta-c128="" *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="width: 113px;" (click)="addWorkEffort()">Create New Work Effort 
                                                        </button>
        
        
                                                        <button _ngcontent-cta-c128="" *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="width: 113px;" data-toggle="modal" data-target="#advanceSearch">Advanced Search
                                                        </button>
                                                       
                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 74%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;">
                                                        </button>									
            
                                                    </div>
                
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                    <div class="card-body">
                                                        <div class="all-users-infomation font-13 font-medium  own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">
                                                                            Workeffort ID</label>
                                                                    </div>
                
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.workEffortID" name="workEffortID" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Workeffort ID">
                                                                    </div>
                
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">
                                                                            Workeffort Parent ID</label>
                                                                    </div>
                
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.workEffortParentID" name="workEffortParentID" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Workeffort Parent ID">
                                                                    </div>
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Type </label>
                                                                    </div>
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearch.type" [options]="TypeArray" [ngModelOptions]="{standlone:true}" name="type" optionlabel="label" placeholder="Enter Type">
                
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Purpose </label>
                                                                    </div>
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearch.purpose" [options]="PurposeArray" [ngModelOptions]="{standlone:true}" name="purpose" optionlabel="label" placeholder="Enter Purpose">
                
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">
                                                                            Status</label>
                                                                    </div>
                
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.status" name="status" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Status">
                                                                    </div>
                
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">
                                                                            Priority</label>
                                                                    </div>
                
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" placeholder="Enter Priority" [options]="PriorityArray" [(ngModel)]="advanceSearch.priority" optionlabel="label" [ngModelOptions]="{standalone:true}" name="priority">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Workeffort Name</label>
                                                                    </div>
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.workEffortName" name="workEffortName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Workeffort Name">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Description</label>
                                                                    </div>
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.description" name="description" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Facility ID</label>
                                                                    </div>
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.facilityID" name="facilityID" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Facility ID">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                                    </div>
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.fixedAssetID" name="fixedAssetID" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                                    </div>
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">
                                                                            Scope</label>
                                                                    </div>
                
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" placeholder="Enter Scope" [options]="scopeArray" [(ngModel)]="advanceSearch.scope" optionlabel="label" [ngModelOptions]="{standalone:true}" name="scope">
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">
                                                                            Currency</label>
                                                                    </div>
                
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" placeholder="Enter Currency" [options]="CurrencyListArray" [(ngModel)]="advanceSearch.currency" name="currency" optionlabel="label" [ngModelOptions]="{standalone:true}">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Party ID</label>
                                                                    </div>
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.PartyID" name="PartyID" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party ID">
                                                                    </div>
                                                                </div>
                                                            </div>
                
                                                        </div>

                                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -13%;">
                                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="getfindWorkEffort()">Find</button>&nbsp;
                                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <!-- <div class="w3-card-4 classCard">
                                        <div class="d-flex bd-highlight port-header-contact-import create-lead font-medium mb-0 suppliers-wrapper">
                                            Search Options
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2" style="width: 113px;" (click)="addWorkEffort()">Create Work Effort 
                                                </button>


                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2" style="width: 113px;" data-toggle="modal" data-target="#advanceSearch">Advanced Search
                                                </button>
                                        </div>
                                        <br>
                                        <div class="all-users-infomation font-13 font-medium  own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Workeffort ID</label>
                                                    </div>


                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.workEffortID" name="workEffortID" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Workeffort ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Workeffort Parent ID</label>
                                                    </div>


                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.workEffortParentID" name="workEffortParentID" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Workeffort Parent ID">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Type </label>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearch.type" [options]="TypeArray" [ngModelOptions]="{standlone:true}" name="type" optionlabel="label" placeholder="Enter Type">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Purpose </label>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearch.purpose" [options]="PurposeArray" [ngModelOptions]="{standlone:true}" name="purpose" optionlabel="label" placeholder="Enter Purpose">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Status</label>
                                                    </div>


                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.status" name="status" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Status">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Priority</label>
                                                    </div>


                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" placeholder="Enter Priority" [options]="PriorityArray" [(ngModel)]="advanceSearch.priority" optionlabel="label" [ngModelOptions]="{standalone:true}" name="priority">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Workeffort Name</label>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.workEffortName" name="workEffortName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Workeffort Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.description" name="description" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Facility ID</label>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.facilityID" name="facilityID" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Facility ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.fixedAssetID" name="fixedAssetID" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Scope</label>
                                                    </div>


                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" placeholder="Enter Scope" [options]="scopeArray" [(ngModel)]="advanceSearch.scope" optionlabel="label" [ngModelOptions]="{standalone:true}" name="scope">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Currency</label>
                                                    </div>


                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" placeholder="Enter Currency" [options]="CurrencyListArray" [(ngModel)]="advanceSearch.currency" name="currency" optionlabel="label" [ngModelOptions]="{standalone:true}">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" [(ngModel)]="advanceSearch.PartyID" name="PartyID" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party ID">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -13%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="getfindWorkEffort()">Find</button>&nbsp;
                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                        </div>
                                    </div> -->


                                    <div class="w3-card-4 classCardView mt-2">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Search Results
                                                </h4>

                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="WorkEffortdata" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>

                                                                            WorkEffort ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Type
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Purpose
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>


                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Description
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>


                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Priority
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>


                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Estimated Start Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>


                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Estimated Completion Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Actual Start Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Actual Completion Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">

                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>


                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span (click)="detailedPage(product.workEfforts.workEffortId)"  class="account-button">
                                                                                {{product.workEfforts.workEffortId}}</span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span>{{product.workEfforts.workEffortTypeId}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span>{{product.workEfforts.currentStatusId}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span>{{product.workEfforts.workEffortPurposeTypeId}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span>{{product.workEfforts.description}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span>{{product.workEfforts.priority}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">

                                                                        <svg *ngIf="!this.Hidebtn" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }

                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                        <h4
                                                                            class="ml-2">
                                                                            No Record
                                                                            Found</h4>
                                                                    </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="advanceSearch" tabindex="-1" role="dialog" aria-labelledby="createAdvancedSearch" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Work Effort </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Advanced Search</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="advancedSearchpopup">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Keywords</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="keywords" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Keywords">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Reviews</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="reviews" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reviews">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Work Effort ID</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="workEffortID" [options]="WorkEffortIdArray" optionlabel="label" placeholder="Enter Work Effort ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Work Effort Assoc Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="workEffortAssocTypeID" [options]="WorkEffortAssocTypeArray" optionlabel="label" placeholder="Enter Work Effort Assoc Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="partyID" [options]="partyIdArray" optionlabel="label" placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Role Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="roleTypeID" optionlabel="label" [options]="RoleTypeIdArray" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Product ID 1</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="productID1" [options]="ProductIdsListArray" optionlabel="label" placeholder="Enter Product ID 1">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Product ID 2</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="productID2" [options]="ProductIdsListArray" optionlabel="label" placeholder="Enter Product ID 2">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Last Updated Date Filter From</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="updatedFrom" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Updated Date Filter From">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Last Updated Date Filter Through</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="updatedThrough" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Updated Date Filter Through">
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Sorted By</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="sortedBy" [options]="sortedbyArray" optionlabel="label" placeholder="Enter Sorted By">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button1" style="margin-left: 44%;">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>