
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EmpPositionFullfillmentModel } from './fullfilment-model';


@Injectable({
    providedIn: 'root'
  })


export class FullfilmentEmployeeFormService {
    fullfilment_employee_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    public postalEmployeeModel: EmpPositionFullfillmentModel
    ) { } 

  addEmployeeForm(data:any): void {
    if (!this.fullfilment_employee_form) {
        this.fullfilment_employee_form = this._formBuilder.group(this.postalEmployeeModel.mapDataToModel(data));
       
    }

  }

}

 

 

 

 
  
//   prepareTransactionRefferalForm(data:any): void {
//     if (!this.referral_information_form) {
//       this.referral_information_form = this._formBuilder.group(data);
//     }
//   }

  

  

 

 

//   updateAllTransactionData(data) {
//     this.allTransactionData = data;
//     this.prepareTransactionDetail(this.transacDetailFormModel.mapDataToModel(this.allTransactionData["transaction_details"]));
//     this.prepareCompanyDetailsPropertyForm(this.transacCompanyDetailsFormModel.mapDataToModel(this.allTransactionData["commission_details"]));
//     this.prepareBuyerTenantForm(this.transacBuyerTenantFormModel.mapDataToModel(this.allTransactionData["buyer_tenant_information"][0]));
//     this.prepareSellerLandlordForm(this.sellerLandlordFormModel.mapDataToModel(this.allTransactionData["seller_landlord_information"][0]));
