<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Customers </span>
                <span class="color-black pl-1"> cvccvcx</span>
                <button type="submit" (click)="back();" routerLinkActive="active"
                    class="btn btn-danger text-white buttonclass">Cancel</button>
            </div>
            <div class="w3-card-4 classCard">
                <div class=" bg-white color-grey">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Address</a></li>
                                    <li [ngClass]="activeCategory==3?'active':''"><a>
                                        Additional Info</a></li>
                                        <li [ngClass]="activeCategory==4?'active':''"><a>
                                            Job Info</a></li>




                        </ul>
                    </div>

                    <div  *ngIf="activeCategory==2">
                        <div class="container-fluid">
                            <div class="row" >
                                <form [formGroup]="accountForm" class="w-100">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12" *ngIf="show">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="partyId">Party Id</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        [value]="partyId" readonly>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Company Name<span
                                                            style="color:red">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input formControlName="groupName" type="email"
                                                        class="form-control small-input" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Company Name">
                                                   
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="salutation">Salutation</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p-dropdown styleClass="small-dropdown" [options]="salutationArray"
                                                        placeholder="Salutation" filter="true" formControlName="title">
                                                    </p-dropdown>
                                                </div>


                                            </div>
                                        </div>
                                       
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="salutation">First Name</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="First Name" formControlName="firstName">
                                                </div>

                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="salutation">Last Name</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="Last Name" formControlName="lastName">
                                                </div>

                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="countryCode">Country Code</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="Country Code" formControlName="countryCode">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="salutation">Area Code</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="Area Code" formControlName="areaCode">
                                                </div>

                                            </div>
                                        </div>
                                       
                                        <div class="col-lg-12" *ngIf="!show">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="salutation">Contact</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="Contact" formControlName="contactNumber">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="salutation">Extension</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="Extension" formControlName="extension">
                                                </div>

                                            </div>
                                        </div>
                                       
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label style="font-size: 13px;" for="exampleInputEmail1">Number of
                                                        Employees</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="email" formControlName="numEmployees"
                                                        class="form-control small-input" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter No of Employess">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Office Site Name</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="email" formControlName="officeSiteName"
                                                        class="form-control small-input" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Office Site Name">
                                                </div>

                                            </div>
                                        </div>
                                       
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="emailAddress">Email Address</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="Email Address" formControlName="emailAddress">
                                                </div>

                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="address1">Address 1<span
                                                            style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="Address 1" formControlName="address1">
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div class="col-lg-12" *ngIf="!show">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="address1">Address 2<span
                                                            style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="Address 2" formControlName="address2">
                                                </div>

                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="city">City<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="city" formControlName="city">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-12" *ngIf="!show">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="city">Country<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p-dropdown styleClass="small-dropdown" [options]="array"
                                                        placeholder="Select Country" formControlName="countryGeoId"
                                                        filter="true">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="city">State<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p-dropdown styleClass="small-dropdown" [options]="stateArray"
                                                        placeholder="State" filter="true"
                                                        formControlName="stateProvinceGeoId">
                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>
                                       
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="pinCode">Pin Code<span
                                                            style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="Pin Code" formControlName="postalCode">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="city">Customer Type<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p-dropdown styleClass="small-dropdown" [options]="customerType"
                                                        placeholder="Customer Type" filter="true"
                                                        formControlName="customerType">
                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-lg-12" *ngIf="show">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="revenue">Annual Revenue</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="Annual Revenue" formControlName="annualRevenue">
                                                </div>

                                                <div class="col-lg-3 form-group rightFormA ">
                                                    <label for="desc">Description</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="Description" formControlName="description">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-12" *ngIf="show">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="externalID">External Id</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="External Id" formControlName="externalId">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="logoImage">Logo Image Url</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control small-input"
                                                        placeholder="Logo Image Url" formControlName="logoImageUrl">
                                                </div>

                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-12" *ngIf="show">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="currency">Preferred Currency Uom ID</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p-dropdown styleClass="small-dropdown" [options]="currencyList"
                                                        placeholder="Preferred Currency Uom ID"
                                                        formControlName="preferredCurrencyUomId"></p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="statusId">Status ID</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p-dropdown styleClass="small-dropdown" [options]="statusList"
                                                        placeholder="Status ID" formControlName="statusId">
                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>
                                       

                                    </div>

                                    <div class="main-submit-button" style="margin-right: 24%;">
                                        <button type="submit" (click)="createAccount()" *ngIf="!show"
                                            class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" (click)="updateAccount()" *ngIf="show"
                                            class="btn btn-secondary submit-btn">Update</button>



                                    </div>

                                </form>
                            </div>
                          
                        </div>

                    </div>
                    <div *ngIf="activeCategory==3">
                        <div class="container-fluid">
                    <div class="row">
                              
                        <form class="w-100" [formGroup]="additionalDetailForm">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormD">
                                            <label for="exampleInputEmail1">Type</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" placeholder="Type"
                                            formControlName="infoType">
                                        </div>
                                      
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Terms</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" placeholder="Terms"
                                            formControlName="terms">
                                        </div>
                                       
                                    </div>
                                </div>
                              
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormD">
                                            <label for="exampleInputEmail1">Rep. </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" placeholder="Rep"
                                            formControlName="rep">
                                        </div>
                                      
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Price Level </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" placeholder="Price Level"
                                            formControlName="priceLevel">
                                        </div>
                                       
                                    </div>
                                </div>
                              
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormD">
                                            <label for="exampleInputEmail1">Preferred Send Method
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control"
                                            placeholder="Preferred Send Method"
                                            formControlName="prefeferredSendMethod">
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Resale No. </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" placeholder="Resale No."
                                            formControlName="resaleNo">
                                        </div>
                                      
                                        
                                       
                                    </div>
                                </div>
                             
                             
                            </div>
                        </form>
                        <div class="main-submit-button" style="margin-left: 66%;">
                            <button type="submit" *ngIf="editMode" (click)="updateAdd()"
                                class="btn btn-secondary submit-btn">Update</button>
                            <button type="submit" *ngIf="!editMode" (click)="submitAdd()"
                                class="btn btn-secondary submit-btn">Submit</button>
                            
                      
                    </div>
                </div>
                </div></div>
                <div  *ngIf="activeCategory==4">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="jobInfoForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightFormC" >
                                                <label for="exampleInputEmail1">Job Status</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Job Status"
                                                formControlName="jobStatus">
                                            </div>
                                          
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Start Date</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="date" class="form-control" placeholder="Start Date"
                                                formControlName="startDate">
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightFormC" >
                                                <label for="exampleInputEmail1">Projected End </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="date" class="form-control" placeholder="Projected End"
                                                formControlName="projectedEndDate">
                                            </div>
                                          
                                            <div class="col-lg-3 form-group rightFormA" >
                                                <label for="exampleInputEmail1">End Date </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="date" class="form-control" placeholder="End Date"
                                                formControlName="endDate">
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightFormC" >
                                                <label for="exampleInputEmail1">Description
                                                </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Description"
                                                    formControlName="description">
                                            </div>
                                          
                                            <div class="col-lg-3 form-group rightFormA" >
                                                <label for="exampleInputEmail1">Job Type </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Job Type"
                                                formControlName="jobType">
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightFormC">
                                                <label for="exampleInputEmail1">Product Type </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Product Type"
                                                formControlName="productType">
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Service Type </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Service Type"
                                                formControlName="serviceType">
                                            </div>
                                            
                                           
                                        </div>
                                    </div>
                                 
                                
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: 60%;">
                                <button type="submit" *ngIf="editMode" (click)="updateJob()"
                                    class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" *ngIf="!editMode" (click)="submit()"
                                    class="btn btn-secondary submit-btn">Submit</button>
                              
                            </div>
                        </div>
                      
                    </div>

                </div>
                <br>  <br>  <br>  <br>

                </div>
            </div>





            

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>