<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Party Role
                </span>
                <span class="color-black pl-1"> >Add To Role
                </span>

                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Add To Role
                                </a></li>



                        </ul>
                    </div>


                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100 formLeft"  >
                                    <h4>Add To Main Role</h4>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-2 form-group classInput">
                                                    <label for="exampleInputEmail1">Role Type ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                   
                                                    <p-dropdown placeholder="Enter Role Type ID"  (onChange)="onChange($event)"
                                                    [(ngModel)]="mainRoleDrop" [ngModelOptions]="{standalone: true}" filter="true" [options]="MainArrayValue" optionlabel="description">
                                                         
                                                      </p-dropdown> 
                                                </div>
                                                <div class="col-lg-2"></div>
                                                <div class="col-lg-1 form-group classButtonR">
                                                    <label for="exampleInputEmail1"></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <button type="submit" (click)="addMainRole()"  class="btn btn-secondary submit-btn update-button">Add</button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                  
                                    </div>
                                </form>
                                <form class="w-100 formLeft" *ngIf="this.parent">
                                    <h4>Add To Second Role</h4>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-2 form-group classInput">
                                                    <label for="exampleInputEmail1">Role Type ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                   
                                                    <p-dropdown placeholder="Enter Role Type ID" [ngModelOptions]="{standalone: true}" [(ngModel)]="secondRoleRole"  filter="true" [options]="parentArray" optionlabel="label">
                                                       
                                                    </p-dropdown> 
                                                </div>
                                                <div class="col-lg-2"></div>
                                                <div class="col-lg-1 form-group classButtonR">
                                                    <label for="exampleInputEmail1"></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <button type="submit"  class="btn btn-secondary submit-btn update-button" (click)="addPraentRole()">Add</button>
                                                </div>
                                            </div>
                                        </div>
                                     
                                        
                                
                                    </div>
                                </form>
                                <form class="w-100 formLeft" >
                                    <h4>Add To Role : view all</h4>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-2 form-group classInput">
                                                    <label for="exampleInputEmail1">Role Type ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Role Type ID" [ngModelOptions]="{standalone: true}"
                                                    filter="true" [(ngModel)]="allRoleRole" [options]="allroleArray" optionlabel="label">
                                                        
                                                     </p-dropdown>
                                                </div>
                                                <div class="col-lg-2"></div>
                                                <div class="col-lg-1 form-group classButtonR">
                                                    <label for="exampleInputEmail1"></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <button type="submit" (click)="addAllRole()"   class="btn btn-secondary submit-btn update-button">Add</button>
                                                </div>
                                            </div>
                                        </div>
                                      
                                      
                                     
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div> -->


<div class="modal-header">
    <span class="color-black pl-1">Add To Role</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">
      <div class=" bg-white color-grey" style="width: 100%;">
          <div class="w3-card-4 classCardPopUp" style="width: 100%;">
              <div class="container-fluid">
                  <ul class="tabbing-section tabbing-accordians tabClass">

                      <li [ngClass]="activeCategory==2?'active':''"><a>
                        Add To Role</a></li>
                  </ul>
              </div>
              
              <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100 formLeft"  >
                            <h4>Add To Main Role</h4>
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Role Type ID</label>
                                        </div>
                                        <div class="col-lg-3">
                                           
                                            <p-dropdown placeholder="Enter Role Type ID"  (onChange)="onChange($event)"
                                            [(ngModel)]="mainRoleDrop" [ngModelOptions]="{standalone: true}" filter="true" [options]="MainArrayValue" optionlabel="description">
                                                 
                                              </p-dropdown> 
                                        </div>
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-1 form-group classButtonR">
                                            <label for="exampleInputEmail1"></label>
                                        </div>
                                        <div class="col-lg-3">
                                            <button type="submit" (click)="addMainRole()"  class="btn btn-secondary submit-btn update-button">Add</button>
                                        </div>
                                    </div>
                                </div>
                                
                                
                          
                            </div>
                        </form>
                        <form class="w-100 formLeft" *ngIf="this.parent">
                            <h4>Add To Second Role</h4>
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Role Type ID</label>
                                        </div>
                                        <div class="col-lg-3">
                                           
                                            <p-dropdown placeholder="Enter Role Type ID" [ngModelOptions]="{standalone: true}" [(ngModel)]="secondRoleRole"  filter="true" [options]="parentArray" optionlabel="label">
                                               
                                            </p-dropdown> 
                                        </div>
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-1 form-group classButtonR">
                                            <label for="exampleInputEmail1"></label>
                                        </div>
                                        <div class="col-lg-3">
                                            <button type="submit"  class="btn btn-secondary submit-btn update-button" (click)="addPraentRole()">Add</button>
                                        </div>
                                    </div>
                                </div>
                             
                                
                        
                            </div>
                        </form>
                        <form class="w-100 formLeft" >
                            <h4>Add To Role : view all</h4>
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Role Type ID</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown placeholder="Enter Role Type ID" [ngModelOptions]="{standalone: true}"
                                            filter="true" [(ngModel)]="allRoleRole" [options]="allroleArray" optionlabel="label">
                                                
                                             </p-dropdown>
                                        </div>
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-1 form-group classButtonR">
                                            <label for="exampleInputEmail1"></label>
                                        </div>
                                        <div class="col-lg-3">
                                            <button type="submit" (click)="addAllRole()"   class="btn btn-secondary submit-btn update-button">Add</button>
                                        </div>
                                    </div>
                                </div>
                              
                              
                             
                            </div>
                        </form>
                    </div>
                </div>
            </div>  

              
              
             </div>
      </div>
  </div>