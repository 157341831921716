import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AddSkillService } from '../add-skills/add-skill.service';
import { JobInterviewFormService } from '../job-interview/job-interview-service';
import { JobInterviewService } from '../job-interview/job-interview.service';
import { CreateInternalService } from '../recuitment/create-internal-job-posting/create-internal-job.service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-job-interview-summary',
  templateUrl: './job-interview-summary.component.html',
  styleUrls: ['./job-interview-summary.component.css']
})
export class JobInterviewSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  interviewId: any;
  interviewData: any;
  activeCategory=1;
  show: boolean;
  resultStatus: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[]=[];
  dateJob: string;
  enumId: any;
  jobInterviewId: any;
  enumArray: any[]=[];
  jobArray: any[]=[];
  jobRequestion: any;
  getJobRequistionArray: any[]=[];
  updateestifromdate: string;

  constructor(
    public jobInterviewFormService:JobInterviewFormService,
    public jobInterviewService:JobInterviewService,
    readonly toastr:ToastrService,
      private _location: Location,
      readonly activatedRoute:ActivatedRoute,
    readonly createInternalService:CreateInternalService,
    public addSkillService:AddSkillService,
    readonly datePipe: DatePipe,readonly spinner:NgxSpinnerService,
    readonly router:Router
  ) { }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.interviewId = params["interviewId"];
    });
    this.getInterviewId();


    this.activatedRoute.queryParams.subscribe(params=> {
      this.interviewId = params["interviewId"];
    });
    
    if(this.interviewId){
      this.show=true;
      this.getInterviewIdNew();
    }
    else {
      this.show=false;
    }
    this.resultStatus = [{
      "label":"Pass",
      "value":"Pass"
    },
  {
    "label":"Fail",
    "value":"Fail"
  }]
    this.jobInterviewParametre();
    this.jobInterviewFormService.job_interview_form=null;
    this.jobInterviewFormService.jobInterviewForm(this.jobInterviewFormService.job_interview_form);
    
    this.getPartyId();
    this.getJobRequistionList();
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res=> {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.personId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
  }
  getInterviewIdNew(){
   
    this.jobInterviewService.getInterviewById(this.interviewId).subscribe(res=> {
      this.spinner.show();
      setTimeout(() => {
        let fromdate=res.data.jobInterviewDate 
        this.dateJob=this.datePipe.transform(fromdate,"yyyy-MM-dd");
      
        const formPatch = this.jobInterviewFormService.job_interview_form;
        formPatch.patchValue({
          jobInterviewId:res.data.jobInterviewId,
          jobInterviewTypeId:res.data.jobInterviewTypeId,
          jobIntervieweePartyId:res.data.jobIntervieweePartyId,
          interviewerPartyId:res.data.jobInterviewerPartyId,
          jobRequisitionId:res.data.jobRequisitionId,
          interviewDate: this.dateJob,
          intervieweePartyId:res.data.jobIntervieweePartyId,
          interviewTypeId:res.data.jobInterviewTypeId,
          gradeSecuredEnum:res.data.gradeSecuredEnumId,
          interviewResult:res.data.jobInterviewResult
        })
      
      }, 3000);
     
    })
    this.spinner.hide();
  }
  jobInterviewParametre(){
    this.spinner.show();
    this.jobInterviewService.getJobInterviewParameter().subscribe(res=> {
      this.enumId = res.data.gradeSecuredEnumID;
      this.jobInterviewId = res.data.jobInterviewTypeId;
      this.spinner.hide();
      for(const value of this.enumId){
        this.enumArray.push({
          label:value.description,
          value:value.enumId
        })
      }
      for(const value of this.jobInterviewId){
        this.jobArray.push({
          label:value.jobInterviewTypeId,
          value:value.jobInterviewTypeId
        })
      }
    })
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getJobRequistionList() {
    this.spinner.show();
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Contains",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Contains",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Contains",
      experienceYears: "",
      experienceYearsSearchType: "Contains",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""
    }
    this.createInternalService.getJobRequistionList(jobRequistion).subscribe(res=> {
      this.jobRequestion=res.data;
      this.spinner.hide();
     for(const value of this.jobRequestion){
       this.getJobRequistionArray.push({
         label:value.jobRequisitionId,
         value:value.jobRequisitionId
       })
     }
    
    })
  }
  onUpdate(){
    this.spinner.show();
    let fromdate=this.jobInterviewFormService.job_interview_form.get('interviewDate').value;
    this.updateestifromdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");
    const formData={
      "gradeSecuredEnum":this.jobInterviewFormService.job_interview_form.value.gradeSecuredEnum,
      "interviewDate":  this.updateestifromdate,
      "interviewResult": this.jobInterviewFormService.job_interview_form.value.interviewResult,
      "interviewTypeId": this.jobInterviewFormService.job_interview_form.value.interviewTypeId,
      "intervieweePartyId": this.jobInterviewFormService.job_interview_form.value.intervieweePartyId,
      "interviewerPartyId":this.jobInterviewFormService.job_interview_form.value.interviewerPartyId,
      "jobRequisitionId": this.jobInterviewFormService.job_interview_form.value.jobRequisitionId
    }
 
     this.jobInterviewService.updateJobInterview(formData,this.interviewId).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this.toastr.success("Job Interview Updated Successfully.");
        this.closebutton.nativeElement.click();
        this.router.navigate(["/hr/job-requisition/job-interview"])
      }
      else if(res.success == false) {
        this.toastr.error("Error");
      }
     },(err) => {
      this.toastr.error(err.error.message);
      this.spinner.hide();
   }
   
    
     )}
 
  getInterviewId(){
    this.spinner.show();
    this.jobInterviewService.getInterviewById(this.interviewId).subscribe(res=> {
    this.interviewData=res.data;
    this.spinner.hide();
    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
