<div class="container-fluid main-container-wrapper">

    <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
       <span class="color-black pl-1 titlePanels">Tax Authority </span>
    </div>
    <div class=" bg-white color-grey">

        <div class="create-new-leade mt-2">
            <div class="container-fluid">
                <form class="w-100">
                    <div class="row">



                        <div class="w3-card-4 classCardB">
                            <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                    <h4 class=" common-styling h3Hg">
                                        Find Tax Authority
                                    </h4>
                                    <span class="edit-buttons  HRight">
                                    <a data-toggle="modal"
                                    data-target="#exampleModalCenter">Create New Tax Authority</a></span>

                                </div>
                            </header>
                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                <div class="col-lg-12 col-12">
                                    <div class="form-group">

                                        <div class="card own-account-table">
                                            <p-table [value]="TaxAuthorities" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                    <tr style="background: #0d3769;">
                                                        <th pSortableColumn="code">
                                                            <div class="checkbox-align" style="color: white;">
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                    <!---->
                                                                </p-checkbox>

                                                                Tax Auth Geo ID
                                                                <p-sortIcon field="code"></p-sortIcon>
                                                            </div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>
                                                        <th pSortableColumn="name">
                                                            <div style="color: white;"> Tax Authority Party
                                                                <p-sortIcon field="name">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>
                                                        <th pSortableColumn="category">
                                                            <div style="color: white;"> Require Tax Id For Exemption
                                                                <p-sortIcon field="category">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>
                                                        <th pSortableColumn="price">
                                                            <div style="color: white;"> Tax ID Format Pattern</div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>
                                                        <th pSortableColumn="price">
                                                            <div style="color: white;">Include Tax In Price</div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>


                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-product>
                                                    <tr>
                                                        <td>
                                                            <div class="checkbox-align">
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                    <!---->
                                                                </p-checkbox>
                                                                <span class="account-button" 
                                                                (click)="detailSumm(product.taxAuthGeoId,product.taxAuthPartyId);">
                                                                {{product.taxAuthGeoId}}</span>
                                                            </div>
                                                        </td>
                                                        <td (click)="viewParty(product.taxAuthPartyId)" 
                                                     > <span   class="account-second-button" > {{product.partyDesc}}</span></td>
                                                        <td>{{product.requireTaxIdForExemption}}</td>
                                                        <td>{{product.taxIdFormatPattern}}</td>
                                                        <td>{{product.includeTaxInPrice}}</td>


                                                    </tr>
                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                        <tr>
                                                            <td>
                                                                <span class="text-center">
                                                                 <h4 class="ml-2">No Record Found</h4>
                                                             </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </ng-template>
                                            </p-table>
                                            <p class="paginate_data">
                                                View per page </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Tax Authority</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                 <span aria-hidden="true" #closebutton>&times;</span>
             </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                        Add Tax Authority
        
        
                    </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                        Update Tax Authority
        
        
                    </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createTax">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                 
                                                    Geo<span
                                                    style="color:red">*</span> </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown *ngIf="!this.show" placeholder="Enter Geo" filter="true" formControlName="Geo" [options]="getGeoTyArray" optionlabel="label">

                                                        </p-dropdown>
                                                        <input type="email" *ngIf="this.show" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="Geo" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                          
                                                    Party<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown *ngIf="!this.show" placeholder="Enter Party" filter="true" formControlName="Party" [options]="partyIdArray " optionlabel="label">

                                                        </p-dropdown>
                                                        <input type="email" *ngIf="this.show" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="Party" readonly>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                            
                                                    Require Tax Id For Exemption</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Require Tax Id For Exemption" filter="true" formControlName="exemption" [options]="exemption" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Tax ID Format Pattern  </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="pattern">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                          
                                                    Include Tax In Price</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Include Tax In Price" filter="true" formControlName="price" [options]="exemption" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>




                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 88%;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                                        <!-- <button type="submit" *ngIf="this.show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button> -->

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>