import { Component, OnInit ,ViewChild ,Pipe,PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-work-order-summary',
  templateUrl: './work-order-summary.component.html',
  styleUrls: ['./work-order-summary.component.css']
})
export class WorkOrderSummaryComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;

  activeCategory = 4;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  assignmentByIdNew: any;
  workOrderId: any;
  promisedArrivalFrom: any;
  promisedArrivalTo: any;
  FsmCustomerFindData: any;
  FsmTechnicianFindData: any;
  estimatedDuration:any;
  editAssignmentForm: FormGroup;
  technicianAvailabilityArray: { label: string; value: string; }[];
  AppointmentTypeArray: { label: string; value: string; }[];
  technicianStatusArray: { label: string; value: string; }[];
  priorityArray: { label: string; value: string; }[];
  promisedArrivalFromPatch: any;
  promisedArrivalToPatch: any;
  estimatedDurationPatch: any;
  dueByPatch: any;
  etapatch: any;
  FsmSkillId: any;
  FsmSkillIdArray: any[]=[];
  FsmPrioritiesId: any;
  FsmPrioritiesIdArray: any[]=[];
  ExpertiseAreaByTechnicianId: any;
  ExpertiseAreaByTechnicianIdArray: any;
  constructor(

    readonly _Router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly createEventServices: CreateEventServices,
    readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    private _location: Location,
   
  ) { 

    this.editAssignmentForm = this._FormBuilder.group({
      appointmentType:[''],
      promisedArrivalFrom:[''],
      promisedArrivalTo:[''],
      priority: [''],
      skill: [''],
      technicianName:[''],
      eta:[''],
      dueBy:[''],
      estimatedDuration:[''],
      address1:[''],
      address2:[''],
      city:[''],
      countryGeoId:[''],
      customerId:[''],
      customerName:[''],
      expertiseArea:[''],
      postalCode:[''],
      problemId:[''],
      problemStatus:[''],
      stateProvinceGeoId:[''],
      technicianAvailability:[''],
      technicianId:[''],
      workOrderId:[''],
    })

    this.priorityArray = [
      {
        label: 'High',
        value: 'High'
      },
      {
        label: 'Medium',
        value: 'Medium'
      },
      {
        label: 'Low',
        value: 'Low'
      }
    ]
    this.technicianStatusArray = 
    [
      {
        label: 'Pending',
        value: 'Pending'
      },
      {
        label: 'OnHold',
        value: 'OnHold'
      },
      {
        label: 'Completed',
        value: 'Completed'
      },
    ];
    this.AppointmentTypeArray = [
      {
        label: 'Schedule',
        value: 'Schedule'
      },
      {
        label: 'Non-Schedule',
        value: 'Non-Schedule'
      }
    ];
    this.technicianAvailabilityArray = [
      {
        label: 'Assigned',
        value: 'Assigned'
      },
      {
        label: 'Unassigned',
        value: 'Unassigned'
      }
    ];
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workOrderId = params["workOrderId"];
    })
    this.getFsmAssignmentById();
    this.getFsmSkill();
    this.getFsmPriorities();
    this.getFsmPriorities();
    this.getFsmPriorities();
    this.getFsmPriorities();
  }
 
  getFsmSkill() {
    this.spinner.show();
    this.accountsService.getFsmSkill().subscribe(res => {
      this.FsmSkillId = res.data[0].getFsmSkill;
      this.spinner.hide();
      for (const value of this.FsmSkillId) {
        this.FsmSkillIdArray.push({
          label: value.skillDisplayAs,
          value: value.skillDisplayAs
        })
      }
    });

  }  
  getFsmPriorities() {
  this.spinner.show();
  this.accountsService.getFsmPriorities().subscribe(res => {
    this.FsmPrioritiesId = res.data[0].getFsmPriorities;
    this.spinner.hide();
    for (const value of this.FsmPrioritiesId) {
      this.FsmPrioritiesIdArray.push({
        label: value.prioritiesName,
        value: value.prioritiesName
      })
    }
  });

}  
 
  updateFsmAssignment(): void {
    this.spinner.show();
    const requestData = {
      "address1": this.editAssignmentForm.value.address1 ,
      "address2": this.editAssignmentForm.value.address2 ,
      "appointmentType": this.editAssignmentForm.value.appointmentType ,
      "city": this.editAssignmentForm.value.city ,
      "countryGeoId": this.editAssignmentForm.value.countryGeoId ,
      "customerId": this.editAssignmentForm.value.customerId ,
      "customerName": this.editAssignmentForm.value.customerName ,
      "dueBy": this.editAssignmentForm.value.dueBy ,
      "estimatedDuration": this.editAssignmentForm.value.estimatedDuration ,
      "eta": this.editAssignmentForm.value.eta ,
      "expertiseArea": this.editAssignmentForm.value.expertiseArea ,
      "postalCode": this.editAssignmentForm.value.postalCode ,
      "priority": this.editAssignmentForm.value.priority ,
      "problemId": this.editAssignmentForm.value.problemId ,
      "problemStatus": this.editAssignmentForm.value.problemStatus ,
      "promisedArrivalFrom": this.editAssignmentForm.value.promisedArrivalFrom ,
      "promisedArrivalTo": this.editAssignmentForm.value.promisedArrivalTo ,
      "skill": this.editAssignmentForm.value.skill ,
      "stateProvinceGeoId": this.editAssignmentForm.value.stateProvinceGeoId ,
      "technicianAvailability": this.editAssignmentForm.value.technicianAvailability ,
      "technicianId": this.editAssignmentForm.value.technicianId ,
      "technicianName": this.editAssignmentForm.value.technicianName ,
      "workOrderId": this.workOrderId
    }
    this.accountsService.updateFsmAssignment(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getFsmAssignmentById();
        this.closebutton1.nativeElement.click();
        this.editAssignmentForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/fsm/Overview']);
}

  getFsmAssignmentById() {
    this.spinner.show();
    this.accountsService.getFsmAssignmentById(this.workOrderId).subscribe(res => { 
      this.assignmentByIdNew = res.data[0].getFsmAssignment[0];
      this.promisedArrivalFrom = this.datePipe.transform(res.data[0].getFsmAssignment[0].promisedArrivalFrom,"yyyy-MM-dd hh:mm:ss"); 
      this.promisedArrivalTo = this.datePipe.transform(res.data[0].getFsmAssignment[0].promisedArrivalTo,"yyyy-MM-dd hh:mm:ss"); 
      this.estimatedDuration = this.datePipe.transform(res.data[0].getFsmAssignment[0].estimatedDuration,"hh:mm:ss"); 
   
      setTimeout(() => {
        const formValue = this.editAssignmentForm;
        formValue.patchValue({
            appointmentType: this.assignmentByIdNew.appointmentType,
            promisedArrivalFrom: this.promisedArrivalFrom,
            promisedArrivalTo: this.promisedArrivalTo,
            priority: this.assignmentByIdNew.priority,
            skill: this.assignmentByIdNew.skill,
            technicianName: this.assignmentByIdNew.technicianName,
            eta: this.etapatch,
            dueBy: this.dueByPatch,
            estimatedDuration: this.estimatedDuration,
            address1:this.assignmentByIdNew.address1,
            address2:this.assignmentByIdNew.address2,
            city:this.assignmentByIdNew.city,
            countryGeoId:this.assignmentByIdNew.countryGeoId,
            customerId:this.assignmentByIdNew.customerId,
            customerName:this.assignmentByIdNew.customerName,
            expertiseArea:this.assignmentByIdNew.expertiseArea,
            postalCode:this.assignmentByIdNew.postalCode,
            problemId:this.assignmentByIdNew.problemId,
            problemStatus:this.assignmentByIdNew.problemStatus,
            stateProvinceGeoId:this.assignmentByIdNew.stateProvinceGeoId,
            technicianAvailability:this.assignmentByIdNew.technicianAvailability,
            technicianId:this.assignmentByIdNew.technicianId,
            workOrderId:this.assignmentByIdNew.workOrderId,

        })
      }, 2000);
   
      this.spinner.hide();
    })
  }

}
