import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-create-payment-method-type-account',
  templateUrl: './create-payment-method-type-account.component.html',
  styleUrls: ['./create-payment-method-type-account.component.css']
})
export class CreatePaymentMethodTypeAccountComponent implements OnInit {
  createPayment:FormGroup;
  activeCategory=2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  getPaymentMethodType: any;
  getPaymentMethodTypeArray: any[]=[];
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  glChartOfAccount: any;
  glChartOfAccountArray: any[]=[];
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
 
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createPayment = this._FormBuilder.group({
        paymentType: [''],
        glAccountType:['']
       
        
      });
    
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
    
     
    });
    this.getGlAccountInitialData();
    this.getPaymentMethodTypeGlSetting();
    this.getChartAc();
  }
  getChartAc() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
      for (const value of this.glChartOfAccount) {
        this.glChartOfAccountArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
    })
  
  }
  getPaymentMethodTypeGlSetting() {
    this.spinner.show();
    this.accountsService.getPaymentMethodTypeGlSetting(this.finSize).subscribe((res: any) => {
      this.getPaymentMethodType = res.data;
      this.spinner.hide();
      for (const value of this.getPaymentMethodType) {
        this.getPaymentMethodTypeArray.push({
          label: value.description,
          value: value.paymentMethodTypeId
        })
      }
    })
  
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }

    })
   
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createPayment.value.glAccountType,
      "paymentMethodTypeId": this.createPayment.value.paymentType,
      "organizationPartyId":  this.partyId
    }

    this.accountsService.postGlAccountMethodTypePaymentType(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
   
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
  }

}


