<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                    Calendar</a></li>
                        </ul>
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">

                                                <div *ngIf="activeCategory==2">
                                                    <div class="calendar-full" *ngIf="activeTabbing==1"
                                                        style="padding-bottom:60px">
                                                        <div class="w3-card-4 classCard">
                                                            <div class="row text-center d-flex align-items-center"
                                                                style="padding-top:20px">
                                                                <div class="col-md-4 d-flex" style="padding-left:30px">
                                                                    <div class="btn-group">
                                                                        <div class="btn btn-primary"
                                                                            mwlCalendarPreviousView [view]="view"
                                                                            [(viewDate)]="viewDate"
                                                                            (viewDateChange)="closeOpenMonthViewDay()">
                                                                            Previous
                                                                        </div>
                                                                        <div class="btn btn-outline-secondary"
                                                                            mwlCalendarToday [(viewDate)]="viewDate">
                                                                            Today
                                                                        </div>
                                                                        <div class="btn btn-primary" mwlCalendarNextView
                                                                            [view]="view" [(viewDate)]="viewDate"
                                                                            (viewDateChange)="closeOpenMonthViewDay()">
                                                                            Next
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <h3 class="mb-0" style="color:#156085;">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                                                                </div>
                                                                <div class="col-md-4 d-flex justify-content-end"
                                                                    style=" padding-right:40px">
                                                                    <div class="btn-group">
                                                                        <div class="btn btn-primary"
                                                                            (click)="setView(CalendarView.Month)"
                                                                            [class.active]="view === CalendarView.Month">
                                                                            Month
                                                                        </div>
                                                                        <div class="btn btn-primary"
                                                                            (click)="setView(CalendarView.Week)"
                                                                            [class.active]="view === CalendarView.Week">
                                                                            Week
                                                                        </div>
                                                                        <div class="btn btn-primary"
                                                                            (click)="setView(CalendarView.Day)"
                                                                            [class.active]="view === CalendarView.Day">
                                                                            Day
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div [ngSwitch]="view">
                                                                <mwl-calendar-month-view
                                                                    *ngSwitchCase="CalendarView.Month"
                                                                    [viewDate]="viewDate" [events]="events"
                                                                    [refresh]="refresh"
                                                                    [activeDayIsOpen]="activeDayIsOpen"
                                                                    (dayClicked)="dayClicked($event.day)"
                                                                    (eventClicked)="handleEvent('Clicked', $event.event)"
                                                                    (eventTimesChanged)="eventTimesChanged($event)">
                                                                </mwl-calendar-month-view>
                                                                <mwl-calendar-week-view
                                                                    *ngSwitchCase="CalendarView.Week"
                                                                    [viewDate]="viewDate" [events]="events"
                                                                    [refresh]="refresh"
                                                                    (eventClicked)="handleEvent('Clicked', $event.event)"
                                                                    (eventTimesChanged)="eventTimesChanged($event)">
                                                                </mwl-calendar-week-view>
                                                                <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day"
                                                                    [viewDate]="viewDate" [events]="events"
                                                                    [refresh]="refresh"
                                                                    (eventClicked)="handleEvent('Clicked', $event.event)"
                                                                    (eventTimesChanged)="eventTimesChanged($event)">
                                                                </mwl-calendar-day-view>
                                                            </div>

                                                            <!-- Everything you see below is just for the demo, you don't need to include it in your app -->

                                                            <br /><br /><br />

                                                            <h3>
                                                                <!-- <span class="text-black" *ngIf="isForm">Add/modify a calendar event</span> -->
                                                                <button type="submit" data-target="#exampleModalCenter"
                                                                    data-toggle="modal"
                                                                    class="btn btn-secondary submit-btn float-right mr-3">Add
                                                                    New Customer</button>
                                                                <div class="clearfix"></div>
                                                            </h3>


                                                            <div class="port-header-contact create-lead font-medium mb-0 w-100"
                                                                *ngIf="isUpdate">
                                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                                        style="justify-content: space-between;">
                                                                        <p class="h-text">Customer Details</p>
                                                                        <span>
                                                                        </span>
                                                                    </div>
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select"
                                                                        *ngIf="this.detailsByTechnicianId">
                                                                        <div class="color-black container">
                                                                            <div class="row">
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Job Id</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataProblem.problemId
                                                                                                }}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Job Description
                                                                                            </p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataProblem.description}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                              
                                                                                 
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Technician Job Status
                                                                                            </p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataTechnician.technicianStatus}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Technician Comment
                                                                                                 </p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataTechnician.technicianStatusDescription}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Customer Id</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.customerId}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Customer Name</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.customerName}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Customer Type </p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.customerType}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Contact Number</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.primaryContactPrimaryPhone}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Alternate Contact
                                                                                                Number</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.primaryContactAlternatePrimaryPhone}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Email Address</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.primaryContactEmail}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">Fax
                                                                                            </p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.faxResidential}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Customer Address
                                                                                            </p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.address1}}
                                                                                                {{this.detailsByTechnicianId.dataCustomer.address2}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">City
                                                                                            </p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.city}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                State</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.stateProvinceGeoId}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Country</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.countryGeoId}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Postal Code</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.postalCode}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6"> Job
                                                                                                Name </p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataProblem.jobName}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6"> Job
                                                                                                Tax </p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.jobTax}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6"> Job
                                                                                                Tax Code</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataCustomer.jobTaxCode}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Service </p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataProblem.service}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6">
                                                                                                Job Category
                                                                                            </p>
                                                                                            <span
                                                                                                class="color-grey pl-20 font-weight-bold">
                                                                                                {{this.detailsByTechnicianId.dataProblem.category}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                              

                                                                                <div class="main-submit-button"
                                                                                    style="margin-left: 71%;">
                                                                                    <button type="submit"
                                                                                        class="btn btn-secondary mt-2 submit-btn"
                                                                                        (click)="techJobStatus()">Update
                                                                                        Work Status</button>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="port-header-contact create-lead font-medium mb-0 w-100"
                                                            *ngIf="isUpdateTechStatus">
                                                            <div class="w3-card-4 classCard mt-2 w-100">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                                    style="justify-content: space-between;">
                                                                    <p class="h-text">Update Technician Status Details</p>
                                                                    <span>
                                                                    </span>
                                                                </div>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select"
                                                                    *ngIf="this.detailsByTechnicianId">
                                                                    <div class="color-black container">
                                                                        <form class="w-100" [formGroup]="updateTechnicianStatus">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightForm">
                                                                                            <label for="exampleInputEmail1">Technician Work Status</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="fsmStatus" filter="true"
                                                                                                formControlName="status" placeholder="Choose Status">
                                                                                            </p-dropdown>
                                                                                            
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                                            <label for="exampleInputEmail1">Description </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                           
                                    
                                                                                            <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                                                                aria-describedby=""   placeholder="Enter Description" formControlName="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                        
                                                                             
                                                                                
                                                                                <div class="col-lg-5 main-submit-button" style="margin-left: 45%;">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                                    (click)="updateTechnicianPersonal()">Update</button>
                                                                                <br>
                                                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                                                    *ngIf="this.showInvoiceBtn" (click)="invoicePage()">Create New Invoice</button>
                                    
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>


                                                            <ng-template #modalContent let-close="close">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title">Event action occurred
                                                                    </h5>
                                                                    <button type="button" class="close"
                                                                        (click)="close()">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div>
                                                                        Action:
                                                                        <pre>{{ modalData?.action }}</pre>
                                                                    </div>
                                                                    <div>
                                                                        Event:
                                                                        <pre>{{ modalData?.event | json }}</pre>
                                                                    </div>
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button"
                                                                        class="btn btn-outline-secondary"
                                                                        (click)="close()">
                                                                        OK
                                                                    </button>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <br>


            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Customer
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Add New Customer
                                    </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addNewCustomer">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist"
                                                    aria-multiselectable="true">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">User Title
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-3">

                                                                    <p-dropdown filter="true"
                                                                        formControlName="userTitle"
                                                                        [options]="userTitleArray" optionlabel="label"
                                                                        placeholder="Choose User Title">
                                                                    </p-dropdown>
                                                                </div>

                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">User First
                                                                        Name</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter User First Name"
                                                                        formControlName="userFirstName">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">User Middle
                                                                        Name</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Middle Name"
                                                                        formControlName="userMiddleName">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">User Last
                                                                        Name</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Last Name"
                                                                        formControlName="userLastName">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Email</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter customerEmail"
                                                                        formControlName="customerEmail">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Country</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown filter="true"
                                                                        [options]="FSMCountryGeoIdArray"
                                                                        optionlabel="label"
                                                                        placeholder="Enter customerCountry"
                                                                        formControlName="customerCountry">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Address1</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter customerAddress1"
                                                                        formControlName="customerAddress1">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Address2</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter customerAddress2"
                                                                        formControlName="customerAddress2">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        City</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter customerCity"
                                                                        formControlName="customerCity">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer Postal
                                                                        Code</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter customerPostalCode"
                                                                        formControlName="customerPostalCode">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        State</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown filter="true"
                                                                        [options]="FSMStateProvIdArray"
                                                                        optionlabel="label"
                                                                        placeholder="Enter customerState"
                                                                        formControlName="customerState">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Username</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter username"
                                                                        formControlName="username">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Password</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="password" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter password"
                                                                        formControlName="password">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Confirm
                                                                        Password</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Confirm Password"
                                                                        formControlName="confirmPassword">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Password
                                                                        Hint</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="text" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Password Hint"
                                                                        formControlName="passwordHint">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer Address
                                                                        Allow Sql</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown filter="true"
                                                                        formControlName="customerAddressAllowSql"
                                                                        [options]="yesNoArray" optionlabel="label"
                                                                        placeholder="Choose Customer Address Allow Sql">
                                                                    </p-dropdown>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer Home
                                                                        Country</label>
                                                                </div>
                                                                <div class="col-lg-3">

                                                                    <p-dropdown filter="true"
                                                                        [options]="FSMCountryGeoIdArray"
                                                                        optionlabel="label"
                                                                        placeholder="Enter Customer Home Country"
                                                                        formControlName="customerHomeCountry">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer Home
                                                                        Area</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Customer Home Area"
                                                                        formControlName="customerHomeArea">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer Home
                                                                        Contact</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Customer Home Contact"
                                                                        formControlName="customerHomeContact">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer Home
                                                                        Ext</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Customer Home Ext"
                                                                        formControlName="customerHomeExt">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        HomeAllowSql</label>
                                                                </div>
                                                                <div class="col-lg-3">

                                                                    <p-dropdown filter="true" [options]="yesNoArray"
                                                                        optionlabel="label"
                                                                        placeholder="Enter Customer Home Allow Sql"
                                                                        formControlName="customerHomeAllowSql">
                                                                    </p-dropdown>

                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Work Country</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown filter="true"
                                                                        [options]="FSMCountryGeoIdArray"
                                                                        optionlabel="label"
                                                                        placeholder="Enter Customer Work Country"
                                                                        formControlName="customerWorkCountry">
                                                                    </p-dropdown>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Work Area</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Customer Work Area"
                                                                        formControlName="customerWorkArea">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Work Contact</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Customer Work Contact"
                                                                        formControlName="customerWorkContact">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Work Ext</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Customer Work Ext"
                                                                        formControlName="customerWorkExt">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Work Allow Sql</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown filter="true" [options]="yesNoArray"
                                                                        optionlabel="label"
                                                                        placeholder="Enter Customer Work Allow Sql"
                                                                        formControlName="customerWorkAllowSql">
                                                                    </p-dropdown>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Fax Country</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown filter="true"
                                                                        [options]="FSMCountryGeoIdArray"
                                                                        optionlabel="label"
                                                                        placeholder="Enter customerFaxCountry"
                                                                        formControlName="customerFaxCountry">
                                                                    </p-dropdown>

                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Fax Area</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter customerFaxArea"
                                                                        formControlName="customerFaxArea">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Fax Contact</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter customerFaxContact"
                                                                        formControlName="customerFaxContact">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Fax Allow Sql</label>
                                                                </div>
                                                                <div class="col-lg-3">


                                                                    <p-dropdown filter="true"
                                                                        formControlName="customerFaxAllowSql"
                                                                        [options]="yesNoArray" optionlabel="label"
                                                                        placeholder="Enter customerFaxAllowSql">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Mobile Country</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown filter="true"
                                                                        [options]="FSMCountryGeoIdArray"
                                                                        optionlabel="label"
                                                                        placeholder="Enter customerMobileCountry"
                                                                        formControlName="customerMobileCountry">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Mobile Area</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter customerMobileArea"
                                                                        formControlName="customerMobileArea">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        MobileContact</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter customerMobileContact"
                                                                        formControlName="customerMobileContact">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Customer
                                                                        Mobile Allow Sql</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown filter="true"
                                                                        formControlName="customerMobileAllowSql"
                                                                        [options]="yesNoArray" optionlabel="label"
                                                                        placeholder="Enter customer Mobile Allow Sql">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Customer Email
                                                                        AllowSql</label>
                                                                </div>
                                                                <div class="col-lg-3">

                                                                    <p-dropdown filter="true"
                                                                        formControlName="customerEmailAllowSql"
                                                                        [options]="yesNoArray" optionlabel="label"
                                                                        placeholder="Enter customer Email Allow Sql">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Fms Role</label>
                                                                </div>
                                                                <div class="col-lg-3">

                                                                    <p-dropdown filter="true" [options]="fsmRoleArray"
                                                                        optionlabel="label" placeholder="Enter fms Role"
                                                                        formControlName="fmsRole">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                    <div class="main-submit-button" style="margin-right: 9%;">
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                            (click)="technicianCreateCustomer()">Create</button>&nbsp;&nbsp;
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="updateTechStatus" tabindex="-1" role="dialog" aria-labelledby="updateTechStatus"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Technician Status
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Update Technician Status
                                    </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateTechnicianStatus">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Technician Work Status</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="fsmStatus" filter="true"
                                                            formControlName="status" placeholder="Choose Status">
                                                        </p-dropdown>
                                                        
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Description </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                       

                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                            aria-describedby=""   placeholder="Enter Description" formControlName="Description">
                                                    </div>
                                                </div>
                                            </div>
                                    
                                         
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 46%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="updateTechnicianPersonal()">Update</button>
                                            <br>
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                *ngIf="this.showInvoiceBtn" (click)="invoicePage()">Create New Invoice</button>

                                            </div>  
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>