<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Facilities </span>
                <span class="color-grey pl-1 cursor-pointer"
                    routerLink="/facilities/warehouse/detailed-summary-facilities"
                    [queryParams]="{facilityId:facilityId}"> > Facility Summary</span>
                <span class="color-black pl-1"> > {{editMode?'Update':'Create'}} Inventory Item</span>
                <button type="submit" routerLink="/facilities/warehouse/detailed-summary-facilities"
                    [queryParams]="{facilityId:facilityId}" routerLinkActive="active" class="btn btn-danger"
                    style="margin-left: 69%;">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4" style="border:1px solid;
                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians" style=" margin-top: 0px!important;background: #cce0e95e!important;">
                        <li [ngClass]="activeCategory==2?'active':''" (click)="activeCategoryTab(2)"><a>
                                {{editMode?'Update':'Create'}} Inventory Item</a></li>
                                <li *ngIf="editMode"  [ngClass]="activeCategory==3?'active':''" (click)="activeCategoryTab(3)">
                                    <a>
                                        Physical Inventory Variances
                                    </a></li>
                                <li *ngIf="editMode" [ngClass]="activeCategory==1?'active':''" (click)="activeCategoryTab(1)">
                            <a>
                                Inventory Item Reservations
                            </a></li>

                    </ul>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <ng-container *ngIf="activeCategory===2">

                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <form [formGroup]="inventoryItemForm">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="exampleInputEmail1">Inventory Item Type
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <ng-select class="ng-select-width"
                                                                        [items]="inventoryItemTypeIds"
                                                                        formControlName="inventoryItemTypeId"
                                                                        bindLabel="description"
                                                                        placeholder="Select Inventory Item Type"
                                                                        bindValue="inventoryItemTypeId">
                                                                    </ng-select>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                   
                                                                ">
                                                                        <label for="exampleInputEmail1">Product ID <span
                                                                            class="text-danger">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <ng-select class="ng-select-width"
                                                                        [items]="products" formControlName="productId"
                                                                        bindLabel="productId" bindValue="productId"
                                                                        placeholder="Select Product ID">
                                                                    </ng-select>
                                                                    <small class="validate-field"
                                                                        *ngIf="!!inventoryItemForm.controls.productId.invalid && !!inventoryItemForm.controls.productId.touched">
                                                                        Product ID is required!</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="exampleInputEmail1">Party ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <ng-select class="ng-select-width"
                                                                        [items]="partyIds" formControlName="partyId"
                                                                        bindLabel="partyId" bindValue="partyId"
                                                                        placeholder="Select Party ID">
                                                                    </ng-select>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                   
                                                                ">
                                                                        <label for="exampleInputEmail1">Owner Party ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <ng-select class="ng-select-width"
                                                                        [items]="partyIds"
                                                                        formControlName="ownerPartyId"
                                                                        bindLabel="partyId" bindValue="partyId"
                                                                        placeholder="Select Owner Party ID">
                                                                    </ng-select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="exampleInputEmail1">Status ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <ng-select class="ng-select-width"
                                                                        [items]="statusIds" formControlName="statusId"
                                                                        bindLabel="description" bindValue="statusId"
                                                                        placeholder="Select Status Id">
                                                                    </ng-select>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                  
                                                                ">
                                                                        <label for="exampleInputEmail1">Date Time Received
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-calendar formControlName="datetimeReceived">
                                                                        </p-calendar>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="exampleInputEmail1">Date Time
                                                                            Manufactured
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-calendar formControlName="datetimeManufactured">
                                                                        </p-calendar>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                   
                                                                "> 
                                                                        <label for="exampleInputEmail1">Expire Date
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-calendar formControlName="expireDate">
                                                                        </p-calendar>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="exampleInputEmail1">Facility ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <ng-select class="ng-select-width"
                                                                        [items]="facilityIds"
                                                                        formControlName="facilityId"
                                                                        bindLabel="facilityId" [(ngModel)]="facilityId"
                                                                        bindValue="facilityId" placeholder="Select Facility ID">
                                                                    </ng-select>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                  
                                                                ">
                                                                        <label for="exampleInputEmail1">Container ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        formControlName="containerId" placeholder="Container ID">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="exampleInputEmail1">LOT ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        formControlName="lotId" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="LOT ID">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                  
                                                                ">
                                                                        <label for="exampleInputEmail1">UOM ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        formControlName="uomId" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="UOM ID">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="exampleInputEmail1">Bin Number
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        formControlName="binNumber"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Bin Number">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                  
                                                                ">
                                                                        <label for="exampleInputEmail1">Facility Location
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <ng-select class="ng-select-width"
                                                                        [items]="facilityLocations"
                                                                        formControlName="locationSeqId"
                                                                        bindLabel="locationSeqId"
                                                                        placeholder="Facility Location"
                                                                        bindValue="locationSeqId">
                                                                    </ng-select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="exampleInputEmail1">Comments
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        formControlName="comments"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Comments">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                   
                                                                "> 
                                                                        <label for="exampleInputEmail1">Accounting Quantity
                                                                            Total
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        formControlName="accountingQuantityTotal"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Accounting Quantity
                                                                        Total">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="exampleInputEmail1">Serial Number
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        formControlName="serialNumber"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Serial Number">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                  
                                                                ">
                                                                        <label for="exampleInputEmail1">Soft Identifier
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        formControlName="softIdentifier"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Soft Identifier">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="exampleInputEmail1">Activation Number
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        formControlName="activationNumber"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Activation Number">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                 
                                                                ">
                                                                        <label for="exampleInputEmail1">Activation Valid
                                                                            Through
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-calendar formControlName="activationValidThru">
                                                                        </p-calendar>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3  form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="exampleInputEmail1">Unit Cost
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        formControlName="unitCost"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Unit Cost">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                  
                                                                ">
                                                                        <label for="exampleInputEmail1">Currency
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <ng-select class="ng-select-width"
                                                                        [items]="currencyIds"
                                                                        formControlName="currencyUomId"
                                                                        placeholder="Currency"
                                                                        bindLabel="uomId" bindValue="uomId">
                                                                    </ng-select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="exampleInputEmail1">Fixed Asset ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        formControlName="fixedAssetId"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Fixed Asset ID">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12"  *ngIf="editMode">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -7%;
                                                                ">
                                                                        <label for="availableToPromise">Available To
                                                                            Promise</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" placeholder="Available To Promise"
                                                                        [value]="inventoryItem?.availableToPromiseTotal"
                                                                        readOnly>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                 
                                                                ">
                                                                        <label for="quantityOnHand">Quantity On
                                                                            Hand</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control"
                                                                        [value]="inventoryItem?.accountingQuantityTotal"
                                                                        placeholder="Quantity On
                                                                        Hand"
                                                                        readOnly>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         

                                                        </div>
                                                    </form>
                                                    <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -22%;">
                                                        <button type="submit" (click)="submit()"
                                                            class="btn btn-secondary submit-btn update-button">{{editMode?'Update':'Create'}}</button>

                                                    </div>
                                                </div>
                                              
                                            </ng-container>
                                            <ng-container *ngIf="activeCategory===3 && editMode">
                                                <div class="panel panel-default">
                                                  
                                                    <div >
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <form [formGroup]="physicalVarianceInventoryForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                              
                                                                                <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -7%;
                                                                            ">
                                                                                    <label
                                                                                    for="exampleInputEmail1">Variance
                                                                                    Reason
                                                                                    ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <ng-select class="ng-select-width"
                                                                                    [items]="varianceReasons"
                                                                                    bindLabel="description"
                                                                                    placeholder="Select Variance"
                                                                                    formControlName="varianceReasonId"
                                                                                    bindValue="varianceReasonId">
                                                                                </ng-select>
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                               
                                                                            ">
                                                                                    <label
                                                                                    for="exampleInputEmail1">Available To Promise Var
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email"
                                                                                    class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    formControlName="availableToPromiseVar"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Available
                                                                                    To
                                                                                    Promise Var">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                              
                                                                                <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -7%;
                                                                            ">
                                                                                    <label
                                                                                    for="exampleInputEmail1">Quantity
                                                                                    On
                                                                                    Hand
                                                                                    Var</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email"
                                                                                    class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    formControlName="quantityOnHandVar"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Quantity On Hand Var
                                                                                   ">
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" 
                                                                                style="    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                  
                                                                ">
                                                                                    <label
                                                                                    for="exampleInputEmail1">Comments
                                                                                </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email"
                                                                                    class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    formControlName="comments"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Comments">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                      

                                                                    </div>
                                                                </form>
                                                                <div class="col-lg-12 col-12 main-submit-button ">
                                                                    <button _ngcontent-cta-c128="" type="submit"
                                                                        class="btn btn-secondary submit-btn"
                                                                        (click)="createPhysicalVariance()">Add</button>
                                                                </div>
                                                                <div class="card own-account-table mt-2">
                                                                    <p-table [value]="physicalVarianceInventories"
                                                                        [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr>
                                                                                <th width="25%"
                                                                                    [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Physical Inventory ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div> Physical Inventory Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    Party ID
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    General Comments
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    Variance Reason ID
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    Available To Promise Var
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    Quantity On Hand Var
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    Comments
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td width="25%"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span
                                                                                             class="account-button" >{{product.physicalInventoryId}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.physicalInventoryDate | date :'medium'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.partyId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.generalComments}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.description}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.availableToPromiseVar}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.quantityOnHandVar}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.comments}}
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record
                                                                                            Found</h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="activeCategory===1 && editMode">
                                                <div class="card own-account-table mt-2">
                                                    <p-table [value]="inventoryReservations" [paginator]="true"
                                                        [rows]="rows" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                                        [scrollable]="true" (sortFunction)="customSort($event)"
                                                        [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th width="25%" [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Order ID
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="name">
                                                                    <div> Ship Group Seq ID
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    Order Item Seq ID
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    Reserve Order Enum ID
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    Quantity
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    Quantity Not Available
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    Reserved Date Time
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    Created Date Time
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    Promised DateA Time
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    Current Promised Date
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    Priority
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    Sequence ID
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    Old Pick Start Date
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td width="25%" [ngStyle]="{'width':'190px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span
                                                                             class="account-button" >{{product.orderId}}</span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.shipGroupSeqId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.orderItemSeqId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.reserveOrderEnumId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.quantity}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.quantityNotAvailable ? product.quantityNotAvailable :'N/A'}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.reservedDatetime |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.createdDatetime |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.promisedDatetime |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.currentPromisedDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}"> {{product.priority }}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.sequenceId }}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.oldPickStartDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                    <p class="paginate_data">
                                                        View per page </p>

                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 