import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-routing-product-link',
  templateUrl: './create-routing-product-link.component.html',
  styleUrls: ['./create-routing-product-link.component.css']
})
export class CreateRoutingProductLinkComponent implements OnInit {
  createRouteProduct:FormGroup;
  activeCategory=2;
  productListArray: any[]=[];
  routingId: any;
  actualfromdate: string;
  actualthroughdate: string;
  fromdate: any;
  productid: any;
  workid: any;
  show: boolean;
  getEstimateFrom: string;
  getEstimateTo: string;

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    readonly productionService:ProductionService,
    readonly spinner: NgxSpinnerService,
  
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createRouteProduct = this._FormBuilder.group({
        ProductID: [''],
        FromDate:[''],
        ThroughDate:[''],
        Quantity: [''],
        Estimatedcost:['']
      
       
      });
    
  }
 
  ngOnInit(): void {
    this.getProducts();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.routingId = params["routingId"];
      this.fromdate = params["fromdate"];
      this.productid = params["productid"];
      this.workid = params["workid"];
    
    });
    if(this.productid){
      this.show=true;
      this.getproductLinkById();
    }
    else{
      this.show=false;
    }
  }
  
  getproductLinkById(){
    this.spinner.show();
    this.productionService.getproductLinkById(this.routingId,this.fromdate,this.productid,this.workid).subscribe((res:any)=> {  
      setTimeout(() => {
        const fromdate=res.data[0].fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=res.data[0].thruDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
      
        const formValue = this.createRouteProduct;
        formValue.patchValue({
          ProductID:res.data[0].productId,
          FromDate:this.getEstimateFrom,
          ThroughDate:this.getEstimateTo,
          Quantity: res.data[0].estimatedQuantity,
          Estimatedcost:res.data[0].estimatedCost
        })
      
      }, 3000);
      this.spinner.hide();
    })
   
   
   }
  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
   
    });
  
  }

  onSubmit() {
    this.spinner.show();
  
    const fromActualdate=this.createRouteProduct.get('FromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createRouteProduct.get('ThroughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "estimatedCost": this.createRouteProduct.value.Estimatedcost,
      "estimatedQuantity":this.createRouteProduct.value.Quantity,
      "fromDate": this.actualfromdate,
      "productId": this.createRouteProduct.value.ProductID,
      "thruDate": this.actualthroughdate
    }
    this.productionService.postproductLink(this.routingId,requestData).subscribe((res: any) => {  
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(["production/detail-routing-summary"],{ queryParams: { routingId : this.routingId}})
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }  }
        else{
          this._ToastrService.error(err.error.message);
        }
    }); }
  onUpdate() {
    this.spinner.show();
    const throughActualdate=this.createRouteProduct.get('ThroughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "estimatedCost": this.createRouteProduct.value.Estimatedcost,
      "estimatedQuantity":this.createRouteProduct.value.Quantity,
      "fromDate": this.fromdate,
      "productId": this.createRouteProduct.value.ProductID,
      "thruDate": this.actualthroughdate
    }
    this.productionService.updateproductLink(this.routingId,requestData).subscribe((res: any) => {    
    if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(["production/detail-routing-summary"],{ queryParams: { routingId : this.routingId}})
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }   }
        else{
          this._ToastrService.error(err.error.message);
        }
    }); }
  cancelSubmit(){
    this._Router.navigate(["production/detail-routing-summary"],{ queryParams: { routingId : this.routingId}})
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
