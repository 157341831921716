import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { FeaturesService } from '../features.service'; import { Location } from '@angular/common';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
@Component({
    selector: 'app-detailed-summary-feature-group',
    templateUrl: './detailed-summary-feature-group.component.html',
    styleUrls: ['./detailed-summary-feature-group.component.css']
})
export class DetailedSummaryFeatureGroupComponent implements OnInit {
    total: number;
    rowNumber: number;
    activeCategory = 4;
    pageNo: number;
    activeCategoryMain=2;
    pageSize: number;
    rows: number;
    featureGroupApplication: any[];
    featureGroupId: string;
    categories: any[];
    createWithFeatureId: string;
    featureArray: { productFeatureId: string, selected: boolean }[];
    featureFromCategory: any[];
    categoryId: string;
    featureId: any;
    featureIdArray: any[]=[];
    categoriesArray: any[]=[];
    des: any;
  
    @ViewChild('closebutton') closebutton;
  
    featureGroup: any;
    editMode: boolean;
    description: string;
    productFeatureGroupId: string;
    userPermissions: any;
    Hidebtn: boolean=false;
    HideCrudbtn: boolean=false;
    constructor(
        readonly _FeaturesService: FeaturesService,
        readonly _ActivatedRoute: ActivatedRoute,    private _location: Location,
        readonly _ToastrService: ToastrService,
        readonly spinner: NgxSpinnerService,
        readonly  _Router: Router,
    ) {
        this.total = 0;
        this.rowNumber = 0;
        this.pageNo = 1;
        this.pageSize = 100;
        this.rows = 50;
        this.featureGroupApplication = [];
        this.featureGroupId = '';
        this.categories = [];
        this.createWithFeatureId = '';
        this.featureArray = [];
        this.featureFromCategory = [];
        this.categoryId = '2100';
        this.editMode = false;
        this.description = '';
        this.productFeatureGroupId = '';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
          this.Hidebtn=false;
        }
        else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
          this.Hidebtn=true;
        }
        else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
          this.HideCrudbtn=true;
        }
        this.featureGroupId = this._ActivatedRoute.snapshot.queryParams.featureGroupId;
        this.des = this._ActivatedRoute.snapshot.queryParams.des;
        this.getFeatureGroupApplication();
        this.getCategories();
        this.getFeatureId();
        this.getFeatureGroup();
    }
    homeButton(){
      this._Router.navigate(['/facilities/Dashboard'])
    
    }
    cancelSubmit(){
      this._location.back();
    }
    getFeatureGroup(): void {
        this.spinner.show();
        this._FeaturesService.getFeatureGroup({ pageNo: 1, pageSize: 200 }, '')
          .then(data => {
            this.featureGroup = data.data;
            this.spinner.hide();
          });
         
      }
      edit(): void {
        this.editMode = true;
        this.description = this.des;
        this.productFeatureGroupId = this.featureGroupId;
      }
      
      submit(): void {
        this.spinner.show();
        if (this.editMode) {
          if (this.description) {
            this._FeaturesService.updateFeatureGroup({ description: this.description }, this.productFeatureGroupId)
              .then(data => {
                if (data.success) {
                  this.description = '';
                  this._ToastrService.success('Updated');
                  this.closebutton.nativeElement.click();
                  this.spinner.hide();
                  this.getFeatureGroup();
                  this._Router.navigate(['/facilities/catalog/feature-group']);
                 ;
                }
              });
          }
        } else {
          if (this.description) {
            this._FeaturesService.createFeatureGroup({ description: this.description })
              .then(data => {
                if (data.success) {
                  this.description = '';
                  this._ToastrService.success('Created');
                  this.closebutton.nativeElement.click();
                  this.spinner.hide();
                  this.getFeatureGroup();
                  this._Router.navigate(['/facilities/catalog/feature-group']);
                }
              });
          }
        }
      
      }
  
    getFeatureGroupApplication(): void {
        this.spinner.show();
        this._FeaturesService.productFeatureGroupApplication(this.featureGroupId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.featureGroupApplication = data.data;
                }
            });
           
    }
    getFeatureId(): void {
        this.spinner.show();
        this._FeaturesService.getFeatureId()
            .then(data => {
                if (data.success) {
                    this.featureId = data.data
                    this.spinner.hide();
                    for(const value of this.featureId){
                        this.featureIdArray.push({
                          label:value.description,
                          value:value.productFeatureId
                        })
                      }
                 
                }
            });
          
    }
    delete(fromDate: string, featureId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._FeaturesService.deleteFeatureGroupApplication(fromDate, this.featureGroupId, featureId)
                .then(data => {
                    if (data.success) {
                        
                        this.getFeatureGroupApplication();
                    }
                });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }})    }
    getCategories(): void {
        this.spinner.show();
        this._FeaturesService.getFeatureCategoriesList({ pageNo: 1, pageSize: 100 },
            { description: '', descriptionSearchType: 'Contains', productFeatureCategoryId: '', productFeatureCategoryIdSearchType: 'Contains' }
        )
            .then(data => {
                if (data.success) {
                    this.categories = data.data
                    this.spinner.hide();
                    for(const value of this.categories){
                        this.categoriesArray.push({
                          label:value.description,
                          value:value.productFeatureCategoryId
                        })
                      }
                   
                }
            });
           
    }
    submitFormWithFeatureId(): void {
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
            this.spinner.show();
            if (this.createWithFeatureId) {
                this._FeaturesService.createFeatureGroupApplication({
                    fromDate: moment().format('YYYY-MM-DD HH:mm:ss'),
                    productFeatureId: this.createWithFeatureId,
                    sequenceNum: 0
                }, this.featureGroupId)
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.spinner.hide();
                            this.createWithFeatureId = '';
                            this.activeCategory = 1;
                        }
                    });
            }
          }
          else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
            this._ToastrService.error("Permission Denied");
          }
          else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
            this._ToastrService.error("Permission Denied");
          }
  
       
    }
    resetFormWithFeatureId(): void {
        this.createWithFeatureId = '';
    }
    getFeatureFromCategory(): void {
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
            this.spinner.show();
            if (this.categoryId) {
                this._FeaturesService.getFeatureFormCategory(this.categoryId)
                    .then(data => {
                        if (data.success) {
                            this.spinner.hide();
                            this.featureFromCategory = data.data;
                            data.data.forEach(value => {
                                this.featureArray.push({
                                    productFeatureId: value.productFeatureId, selected: false
                                });
                            });
                        }
                    });
            }
          }
          else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
            this._ToastrService.error("Permission Denied");
          }
          else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
            this._ToastrService.error("Permission Denied");
          }

      
    }
    submitFeatureFromCategory(): void {
        this.spinner.show();
        if (this.featureArray.some(value => value.selected === true)) {
            this._FeaturesService.applyFeatureFromCategory(this.featureGroupId, this.featureArray.filter(value => value.selected === true))
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.featureFromCategory = [];
                        this.featureArray = [];
                        this.activeCategory = 1;
                    }
                });
        }
       
    }
    resetFeatureFormCategory(): void {
        this.featureFromCategory = [];
    }
    selectAll(event: any): void {
     
        if (event.target.checked) {
            this.featureArray.forEach(value => {
                return {
                    ...value, ...{ selected: true }
                };
            });
        } else {
            this.featureArray.forEach(value => {
                return {
                    ...value, ...{ selected: false }
                };
            });
        }
    }
    inputChecked(event: any, index: number): void {
        this.featureArray[index].selected = event.target.checked;
    }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    create(eid, edate, edescription, etype, esequenceNum) {
        this._Router.navigate(['/facilities/catalog/feature-group/update-application'], { queryParams: { featureId: this.featureGroupId, id:eid, date: edate, description: edescription, type: etype, sequenceNum: esequenceNum } });
    }
    changeactiveCategoryFunction(tab: number) {
        this.activeCategory = tab;
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}
