import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-work-effort-reviews',
  templateUrl: './work-effort-reviews.component.html',
  styleUrls: ['./work-effort-reviews.component.css']
})
export class WorkEffortReviewsComponent implements OnInit {
  activeCategory = 16;
  addReviews: any;
  updateReview: any;
  show: boolean;
  workEffortId: any;
  total = 0;
  @ViewChild('closebutton') closebutton;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  WorkEffortReviewById: any;
  posted: { label: string; value: string; }[];
  status: { label: string; value: string; }[];
  UserLoginId: any;
  UserLoginIdArray: any[]=[];
  actualfromdate: string;
  updateFromDate: any;
  getEstimateFrom: string;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
    constructor( public createEventFormService: CreateEventFormService,
      readonly _ActivatedRoute: ActivatedRoute,
      readonly datePipe: DatePipe,
      private _location: Location,
      
      readonly addSkillService: AddSkillService,
      readonly accountsService: AccountsService,
      readonly headerService: HeaderService,
      readonly spinner: NgxSpinnerService,
      readonly createEventServices: CreateEventServices,
      readonly _FormBuilder: FormBuilder,
      readonly toastr: ToastrService,
      readonly _Router: Router){
        this.posted=[{
          'label':'Y',
          'value':'Y'
        },
        {
          'label':'N',
          'value':'N'
        }]
        this.status=[{
          'label':'Approved',
          'value':'WERV_APPROVED'
        },
        {
          'label':'Deleted',
          'value':'WERV_DELETED'
        },
        {
          'label':'Pending',
          'value':'WERV_PENDING'
        }]
    this.addReviews = this._FormBuilder.group({
      userLoginID: [''],
      reviewDate: [''],
      status: [''],
      postedAnonymous: [''],
      rating: [''],
      reviewText: [''],
    })

    this.updateReview = this._FormBuilder.group({
      userLoginID: [''],
      reviewDate: [''],
      status: [''],
      postedAnonymous: [''],
      rating: [''],
      reviewText: [''],
    })
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params['workEffortId'];

    });
    this.getWorkEffortReviewById();
    this.getUserLoginId();
  }
  updateEvent(product){
    this.show=true;
    this.addReviews.reset();
    this.spinner.show();
   
      this.updateFromDate=product.reviewDate;
      setTimeout(() => {
        const fromdate=product.workEffortReviewById.reviewDate;
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        
        const formValue = this.addReviews;
        formValue.patchValue({
          userLoginID: product.workEffortReviewById.userLoginId,
          reviewDate:  this.getEstimateFrom,
          status:product.workEffortReviewById.statusId,
          postedAnonymous: product.workEffortReviewById.postedAnonymous,
          rating:product.workEffortReviewById.rating,
          reviewText: product.workEffortReviewById.reviewText,
        })
      
      }, 2000);
  
  }
  
 homeButton(){
  this._Router.navigate(['/psa/Dashboard'])

}
cancelSubmit(){
  this._location.back();
}

  updateWorkEffortReview(): void {
    this.spinner.show();
   
    const requestData ={
      
      "postedAnonymous": this.addReviews.value.postedAnonymous,
      "rating":this.addReviews.value.rating,
      "reviewDate": this.updateFromDate,
      "reviewText": this.addReviews.value.reviewText,
      "statusId":this.addReviews.value.status,
      "userLoginId":this.addReviews.value.userLoginID,
      "workEffortId": this.workEffortId  
   
      }
     
    this.accountsService.updateWorkEffortReview(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getWorkEffortReviewById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  createWorkEffortReview(): void {
    this.spinner.show();
    const fromActualdate=this.addReviews.get('reviewDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
  
    const requestData ={
      
      "postedAnonymous": this.addReviews.value.postedAnonymous,
      "rating":this.addReviews.value.rating,
      "reviewDate": this.actualfromdate,
      "reviewText": this.addReviews.value.reviewText,
      "statusId":this.addReviews.value.status,
      "userLoginId":this.addReviews.value.userLoginID,
      "workEffortId": this.workEffortId  
   
      }
     
    this.accountsService.createWorkEffortReview(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getWorkEffortReviewById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  getUserLoginId() {
    this.spinner.show();
    this.accountsService.getUserLoginId().subscribe(res => {
      this.UserLoginId = res.data[0].userLoginId;
      this.spinner.hide();
      for (const value of this.UserLoginId) {
        this.UserLoginIdArray.push({
          label: value.userLoginId,
          value: value.partyId
        })
      }
    })
   
  }
  deleteWorkEffortReview(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "reviewDate":product.reviewDate,
          "userLoginId": product.workEffortReviewById.userLoginId,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "workEffortId":this.workEffortId
        }
        this.accountsService.deleteWorkEffortReview(req).subscribe(res => {
          this.getWorkEffortReviewById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  getWorkEffortReviewById(){
    this.spinner.show();
   
    this.accountsService.getWorkEffortReviewById(this.workEffortId).subscribe((res:any)=> {
      this.WorkEffortReviewById = res.data;
      this.spinner.hide();
    
    })   
   }
  addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  editWorkEffort1(){
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  summary(){
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  detailParties(partyId){
    this._Router.navigate(["/partyQuickLink/profile-party-quick-link"],{ queryParams: { party:partyId} })
  }
  childWorkEfforts(){
    this._Router.navigate(["/psa/work-effort/task-list-view/childWorkEfforts"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  partyAssigns(){
    this._Router.navigate(["/psa/work-effort/task-list-view/PartyAssigns"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  rates(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Rates"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  commEvents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/CommEvents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  shopLists(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ShopLists"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requests(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requests"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requirements(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requirements"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  quotes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Quotes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  orderHeaders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/OrderHeaders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  timeEntries(){
    this._Router.navigate(["/psa/work-effort/task-list-view/TimeEntries"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  notes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Notes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Contents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  products(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Products"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  reviews(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Reviews"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  keywords(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Keywords"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contactMechs(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ContactMechs"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  agreementApplics(){
    this._Router.navigate(["/psa/work-effort/task-list-view/AgreementApplics"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  fixedAssets(){
    this._Router.navigate(["/psa/work-effort/task-list-view/FixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  attributes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Attributes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  eventReminders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/EventReminders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  reset(){
    this.addReviews.reset();
    this.show=false;
  }
}
