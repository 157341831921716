import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-portal-task-summary',
  templateUrl: './portal-task-summary.component.html',
  styleUrls: ['./portal-task-summary.component.css']
})
export class PortalTaskSummaryComponent implements OnInit {

  workEffortName: any;
  phaseName: any;
  projectName: any;
  priority: any;
  estimatedStartDate: any;
  estimatedCompletionDate: any;
  actualStartDate: any;
  actualCompletionDate: any;
  estimatedMilliSeconds: any;
  actualMilliSeconds: any;
  statusId: any;
  partyId: any;
  roleTypeId: any;
 
  activeCategory=1;

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router) { }
   
  ngOnInit(): void {
   
    this.activatedRoute.queryParams.subscribe(params=> [
      this.workEffortName = params["workEffortName"],
      this.phaseName=params["phaseName"],
      this.projectName=params["projectName"],
      this.priority=params["priority"],
      this.estimatedStartDate = params["estimatedStartDate"],
      this.estimatedCompletionDate=params["estimatedCompletionDate"],
      this.actualStartDate=params["actualStartDate"],
      this.actualCompletionDate=params["actualCompletionDate"],
      this.estimatedMilliSeconds=params["estimatedMilliSeconds"],
      this.actualMilliSeconds=params["actualMilliSeconds"],
      this.statusId=params["statusId"],
      this.partyId=params["partyId"],
      this.roleTypeId=params["roleTypeId"],
    ])
   
  }
  cancelSubmit(){
    this.router.navigate(["/myPortal/my-portal-my-tasks"])
  }

}