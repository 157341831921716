import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-email-configuration-import',
  templateUrl: './email-configuration-import.component.html',
  styleUrls: ['./email-configuration-import.component.css']
})
export class EmailConfigurationImportComponent implements OnInit {
  
  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  stateArray: any[]=[];
  state: any;
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  userLogin: any;
  findPartyArray: any[]=[];
  identification: any;
  identificationArray: any[]=[];
  fileData: File;
  constructor(readonly router: Router,
    private _location: Location,
    ) { }

  ngOnInit(): void {
  }

backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this.router.navigate(['/hub/Dashboard']);
}
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  goToCompanyInfo(){
    this.router.navigate(['/hub/security-group-import']);
  }
  goToSetupUsers(){
    this.router.navigate(['/hub/balance-sheet-headings-import']);
  }
}
