<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Performance Review</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>

            </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords">Find Performance Review</span>

                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2"  data-toggle="modal" data-target="#exampleModalCenter">Create Performance Review</button>


                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 57%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>	
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">
                                                                    Employee Party ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" placeholder="Enter Employee Party ID" [(ngModel)]="advanceSearch.EmployeePartyID" [ngModelOptions]="{standalone:true}" name="EmployeePartyID" [options]="partyIdArray" optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Perf Review ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.PerfReviewID" [ngModelOptions]="{standlone:true}" name="PerfReviewID" placeholder="Enter Perf Review ID" [options]="PerfReviewArray" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Manager Party ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.ManagerPartyID" [ngModelOptions]="{standlone:true}" name="ManagerPartyID" placeholder="Enter Manager Party ID" [options]="partyIdArray" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Payment ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.PaymentID" [ngModelOptions]="{standlone:true}" name="PaymentID" [options]="PaymentIDArray" optionlabel="label" filter="true" placeholder="Enter Payment ID">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">From Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromdateFrom" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromDateTo" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                                </div>
                
                
                
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Through Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.throughDateFrom" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.throughDateTo" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Empl Position ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.EmplPositionID" [ngModelOptions]="{standalone:true}" name="EmplPositionID" [options]="positionArray" optionlabel="label" placeholder="Enter Empl Position ID">
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Comments</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.Comments" [ngModelOptions]="{standlone:true}" name="Comments" placeholder="Enter Comments">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                
                
                
                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -24%;"><button type="submit" class="btn btn-secondary submit-btn" (click)="findPerfReviewData();">Find</button>&nbsp;
                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>


                                <!-- <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <p class="h-text">Find Performance Review</p>
                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" style="width: 113px;" data-toggle="modal" data-target="#exampleModalCenter">Create Performance Review</button>

                                    </div>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">
                                                    Employee Party ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" placeholder="Enter Employee Party ID" [(ngModel)]="advanceSearch.EmployeePartyID" [ngModelOptions]="{standalone:true}" name="EmployeePartyID" [options]="partyIdArray" optionlabel="label">
                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Perf Review ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.PerfReviewID" [ngModelOptions]="{standlone:true}" name="PerfReviewID" placeholder="Enter Perf Review ID" [options]="PerfReviewArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Manager Party ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.ManagerPartyID" [ngModelOptions]="{standlone:true}" name="ManagerPartyID" placeholder="Enter Manager Party ID" [options]="partyIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>


                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Payment ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.PaymentID" [ngModelOptions]="{standlone:true}" name="PaymentID" [options]="PaymentIDArray" optionlabel="label" filter="true" placeholder="Enter Payment ID">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">From Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromdateFrom" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromDateTo" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                </div>



                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Through Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.throughDateFrom" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.throughDateTo" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Empl Position ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.EmplPositionID" [ngModelOptions]="{standalone:true}" name="EmplPositionID" [options]="positionArray" optionlabel="label" placeholder="Enter Empl Position ID">
                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Comments</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.Comments" [ngModelOptions]="{standlone:true}" name="Comments" placeholder="Enter Comments">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>




                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -24%;"><button type="submit" class="btn btn-secondary submit-btn" (click)="findPerfReviewData();">Find</button>&nbsp;
                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                    </div>
                                </div> -->
                                <br>
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Find Performance Review
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">


                                                <div class="card own-account-table">
                                                    <p-table [value]="PerfReviewData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        Employee Party ID
                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="name" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;"> Perf Review ID
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;"> Manager Party ID
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;"> Payment ID
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;">Empl Position ID
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;"> From Date
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;"> Through Date
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;">Comments
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td routerLinkActive="active" [ngStyle]="{'width':'190px','text-decoration':'none'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        <span class="account-button"  (click)="employeeProfile(product.data.employeePartyId)">
                                                                       {{product.data.employeePartyId}}
                                                                    </span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}"  (click)="employementDetail(product.data.perfReviewId)">
                                                                    <span  class="account-button"> {{product.data.perfReviewId}}</span></td>
                                                                <td [ngStyle]="{'width':'190px'}"> {{product.data.managerPartyId}}</td>
                                                                <td [ngStyle]="{'width':'190px'}"> {{product.data.paymentId}}</td>
                                                                <td [ngStyle]="{'width':'190px'}"> {{product.data.emplPositionId}}</td>
                                                                <td [ngStyle]="{'width':'190px'}"> {{product.fromDate}}</td>
                                                                <td [ngStyle]="{'width':'190px'}">{{product.thruDate}}</td>
                                                                <td [ngStyle]="{'width':'190px'}">{{product.data.comments}}</td>


                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">{{show?'Update':'Create'}} New Performance Review</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                Create New Performance Review </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                    Update New Performance Review </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createReview" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Employee Party ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Employee Party ID" formControlName="EmployeePartyID" filter="true" [options]="partyIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Perf Review ID </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="PerfReviewID" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Perf Review ID">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Manager Party ID
                                                           </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Manager Party ID" formControlName="ManagerPartyID" filter="true" [options]="partyIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Manager Role Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Manager Role Type ID" formControlName="ManagerRoleTypeID" filter="true" [options]="allroleArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Payment ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="PaymentIDArray" optionlabel="label" placeholder="Enter Payment ID" formControlName="PaymentID" filter="true">


                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Empl Position ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Empl Position ID" formControlName="EmplPositionID" filter="true" [options]="positionArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="ThroughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget ID">

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>


                                                </div>
                                            </div>




                                        </div>

                                        <div class="main-submit-button" style="margin-right: 10%;">
                                            <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" (click)="onSubmit();" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>