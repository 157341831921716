import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-detail-new-task',
  templateUrl: './detail-new-task.component.html',
  styleUrls: ['./detail-new-task.component.css']
})
export class DetailNewTaskComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  sprintTable:any;
  roleForm:FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  newForm: FormGroup;
  PriorityList: any;
  PriorityListArray: any[]=[];
  ScopeList: any;
  ScopeListArray: any[]=[];
  TasksNamesList: any;
  TasksNamesListArray:any[]=[];
  PartyId: any;
  PartyIdArray:any[]=[];
  RoleResourceList: any;
  RoleResourceListArray: any[]=[];
  ParentPhase: any;
  ParentPhaseArray: any[]=[];
  startdate: string;
  endate: string;
  TaskNameList: any;
  newTaskProject: FormGroup;
  existTaskProject: FormGroup;
  TypeList: any;
  TypeListArray: any[]=[];
  SkillTypeId: any;
  SkillTypeIdArray: any[]=[];
  workEffortId: any;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
        private _location: Location,
        readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
        readonly _FormBuilder: FormBuilder,
        readonly toastr: ToastrService,
    readonly router: Router
  ) { 
    this.newForm = this._FormBuilder.group({
      TaskName: [''],
      ParentPhase: [''],
      SequenceNum: [''],
      MyRoleForThisTask: [''],
      EstimatedHours: [''],
      SkillTypeID: [''],
      Priority: [''],
      Type: [''],
      Description: [''],
      Scope: [''],
      EstimatedStartDate: [''],
      EstimatedCompletionDate: [''],
    
    });
    this.newTaskProject = this._FormBuilder.group({
      taskName: "",
      parentPhase: "",
      sequenceNum: "",
      roleWeek: "",
      estimatedHours: "",
      skillTypeID: "",
      priority: "",
      type: "",
      description: "",
      scope: "",
      startDate: "",
      endDate: "",
    })
    , this.existTaskProject = this._FormBuilder.group({
      ParentPhase: "",
    })

  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params.workEffortId;

    })

    this.getPriorityList();
    this.getScopeList();
    this.getRoleResourceList();
    this.getPartyIdList();
    this.getTasksNames();
    this.getParentPhaseList();
    this.getTaskNameList();
    this.getTypeList();
    this.getSkillTypeId();
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  homeButton(){
    this.router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getSkillTypeId() {
    this.spinner.show();
    this.myContactsService.getSkillTypeId().subscribe(res => {
      this.SkillTypeId = res.data;
      this.spinner.hide();
      for (const value of this.SkillTypeId) {
        this.SkillTypeIdArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }
    })
  }
  getTypeList() {
    this.spinner.show();
    this.myContactsService.getTypeList().subscribe(res => {
      this.TypeList = res.data;
      this.spinner.hide();
      for (const value of this.TypeList) {
        this.TypeListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }
  getParentPhaseList() {
    this.spinner.show();
    this.myContactsService.getParentPhaseList().subscribe(res => {
      this.ParentPhase = res.data;
      this.spinner.hide();
      for (const value of this.ParentPhase) {
        this.ParentPhaseArray.push({
          label: value.getParentPhaseList.projectName + "--" + value.getParentPhaseList.phaseName,
          value: value.getParentPhaseList.phaseId
        })
      }
    })
  }
  getTaskNameList() {
    this.spinner.show();
    this.myContactsService.getTaskNameList().subscribe(res => {
      this.TaskNameList = res.data;
      this.spinner.hide();

    })
  }
  createNewTask() {
    this.spinner.show();

    const startDate = this.newTaskProject.get('startDate').value;
    this.startdate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const endDate = this.newTaskProject.get('endDate').value;
    this.endate = this.datePipe.transform(endDate, "yyyy-MM-dd hh:mm:ss");

    const formData = {
      "currentStatusId": "PTS_CREATED",
      "description": this.newTaskProject.value.description,
      "estimatedCompletionDate": this.endate,
      "estimatedHours": this.newTaskProject.value.estimatedHours,
      "estimatedStartDate": this.startdate,
      "partyId": "admin",
      "priority": this.newTaskProject.value.priority,
      "projectId": "",
      "roleTypeId": this.newTaskProject.value.roleWeek,
      "scopeEnumId": this.newTaskProject.value.scope,
      "sequenceNum": this.newTaskProject.value.sequenceNum,
      "skillTypeId": this.newTaskProject.value.skillTypeID,
      "statusId": "PAS_ASSIGNED",
      "workEffortName": this.newTaskProject.value.taskName,
      "workEffortParentId": this.newTaskProject.value.parentPhase,
      "workEffortTypeId": this.newTaskProject.value.type,



    }
    this.myContactsService.createNewTask(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.newTaskProject.reset();
        this.getTaskNameList();
        this.router.navigate(["/psa/project/my-tasks"],)

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  updateTaskSave() {
    this.spinner.show();
    const formData = {
      "workEffortParentId": this.existTaskProject.value.ParentPhase,
      "workEffortId": this.workEffortId

    }
    this.myContactsService.updateTaskSave(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getTaskNameList();
        this.router.navigate(["/psa/project/my-tasks"]);

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  addAssignAndExistingTask() {
    this.spinner.show();
    const formData = {
      "partyId": "admin",
      "roleTypeId": this.existTaskProject.value.roleTypeID,
      "statusId": "PAS_ASSIGNED",
      "workEffortId": this.existTaskProject.value.taskName,

    }
    this.myContactsService.addAssignAndExistingTask(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.existTaskProject.reset();
        this.getTaskNameList();
        this.router.navigate(["/psa/project/my-tasks"],)
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getTasksNames() {
    this.spinner.show();
    this.myContactsService.getTasksNames().subscribe(res => {
      this.TasksNamesList = res.data;
      this.spinner.hide();
      for (const value of this.TasksNamesList) {
        this.TasksNamesListArray.push({
          label: value.node.workEffortName,
          value: value.node.workEffortId
        })
      }
    })
  }
  
  getPartyIdList() {
    this.spinner.show();
    this.myContactsService.getPartyIdList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  getRoleResourceList() {
    this.spinner.show();
    this.myContactsService.getRoleTaskList().subscribe(res => {
      this.RoleResourceList = res.data;
      this.spinner.hide();
      for (const value of this.RoleResourceList) {
        this.RoleResourceListArray.push({
          label: value.roleTypeData.description,
          value: value.roleTypeData.roleTypeId
        })
      }
    })
  }
  getPriorityList() {
    this.spinner.show();
    this.myContactsService.getPriorityList().subscribe(res => {
      this.PriorityList = res.data;
      this.spinner.hide();
      for (const value of this.PriorityList) {
        this.PriorityListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }
  getScopeList() {
    this.spinner.show();
    this.myContactsService.getScopeList().subscribe(res => {
      this.ScopeList = res.data;
      this.spinner.hide();
      for (const value of this.ScopeList) {
        this.ScopeListArray.push({
          label: value.scopeData.description,
          value: value.scopeData.enumId
        })
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
