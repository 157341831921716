<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Leave Id  of {{ this.leaveIdNew}} </span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling h4Margin">
                                    Leave Information
                                </h4>
                                <span class="edit-buttons hCreate">

                              <a click="getdepartmentsListById()"  data-toggle="modal" 
                              data-target="#exampleModalCenter">Update</a>
                            </span>
                            </div>
                        </header>
                        <!-- <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px;">
                        <li [ngClass]="activeCategory==1?'active':''" ><a>
                            Leave Information</a></li>
                       
                    </ul> -->
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group">
                                                <div *ngIf="activeCategory==1">

                                                    <div class="panel-body" *ngIf="employeeLeave">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Employee Party ID </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{ this.leaveIdNew}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Leave Type ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{employeeLeave.leaveType}}</span>

                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Leave Reason Type</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{employeeLeave.leaveReasonType}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">From Date</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{employeeLeave.fromDate| date :"yyyy-MM-dd HH:mm:ss"}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Through Date</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{employeeLeave.thruDate| date :"yyyy-MM-dd HH:mm:ss" }}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Approver Party </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{employeeLeave.approverPartyId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Description
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{employeeLeave.description}}</span>
                                                                        </div>



                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Leaves</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                Update Employee Leaves</a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="addLeaveService.add_leave_form" [formGroup]="addLeaveService.add_leave_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">  Employee Party ID <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="partyIdArray" formControlName="partyId" optionlabel="label" placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!addLeaveService.add_leave_form.controls.partyId.invalid && !!addLeaveService.add_leave_form.controls.partyId.touched">   Emp Party ID    is required!</small>


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Leave Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="leaveId" formControlName="leaveTypeId" optionlabel="label" placeholder="Enter Leave Type ID">

                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Leave Reason Type<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="leaveReasonArray" formControlName="emplLeaveReasonTypeId" optionlabel="label" placeholder="Enter Leave Reason Type">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">From Date <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="From Date">
                                                        <small class="text-danger" *ngIf="!!addLeaveService.add_leave_form.controls.fromDate.invalid && !!addLeaveService.add_leave_form.controls.fromDate.touched"> From Date  is required!</small>




                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Through Date">

                                                        <small class="text-danger" *ngIf="!!addLeaveService.add_leave_form.controls.thruDate.invalid && !!addLeaveService.add_leave_form.controls.thruDate.touched"> Through Date  is required!</small>


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Approver Party <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" placeholder="Enter Approver Party" formControlName="approverPartyId">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!addLeaveService.add_leave_form.controls.approverPartyId.invalid && !!addLeaveService.add_leave_form.controls.approverPartyId.touched"> Approver PartyId   is required!</small>




                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter description">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes" *ngIf="leave">
                                                        <label for="exampleInputEmail1">Leave</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="leave">
                                                        <p-dropdown filter="true" [options]="leaveStatusReoortArray" formControlName="leaveStatus" optionlabel="label" placeholder="Enter Leave">
                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="main-submit-button" style="
                                        margin-right: 11%;">
                                            
                                            <span *ngIf="show">
                                            <button type="submit" *ngIf="!leave" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="leave" (click)="onUpdateApproval()" class="btn btn-secondary submit-btn">Update</button>
                                        </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>