<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100">
                   
                    <div class="mt-3" style="margin-bottom: 10px !important; ">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 16px!important;">Custom Fields</span>

                    </div>     
                    
                    <div class="col-lg-12">
                        <form [formGroup]="customFieldForm">
                            <div class="row ">
                                <div class="col-lg-3 form-group rightForm" style="margin-left: -125px !important;">
                                    <label for="exampleInputEmail1" class="font-12" style="font-weight:bold!important;">Custom Fields for</label>
                                </div>
                                <div class="col-lg-2">
                                    <p-dropdown filter="true" optionlabel="label" 
                                    formControlName="customField" 
                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                    >
                                    </p-dropdown>
                                </div>
    
                                
                            </div>
                        </form>
                    </div>

                    
                    <div class="col-lg-12">
                        <div class="row " style="margin-top: -7px !important; margin-bottom: -1% !important;margin-left: -1% !important; ">
                            <a class="btn btn-link" data-target="#addNewCustomFieldPopup" data-toggle="modal" style="">Add a new Custom Field </a>
                        </div>

                    </div>

            
                    <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="border: 1px solid lightcyan !important;">
                                    <div class="d-flex justify-content-start" style="background-color:rgb(206, 214, 218);color: black !important;
                                        border: 1px solid lightcyan !important; margin: 0px !important ; padding: 3px !important;
                                        font-size: 15px ;  font-weight: bold;
                                        padding-left: 7px !important">
                                        <span style="padding-top: 8px;" >Custom Fields</span>
                                        <span class="ml-2" style="padding-top: 8px; font-weight: bold;">|</span>
                                        <span><a class="btn btn-link" data-toggle="modal" style="font-weight:bold; text-decoration: underline !important;"  data-target="#editCustomFieldPopup">Edit Custom Field</a></span>

                                    </div>
                                
                                   
                                    <p-table [value]="customFieldTab" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background:rgb(206, 214, 218);">
                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: black;">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        Rank
                                                        <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Label
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Pick List
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Type
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Length
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Action
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'150px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.teamMemberPartyId}}
                                                        </span>

                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.securityGroupId}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">
                                                    <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; " 
                                                      data-toggle="modal" data-target="#editCustomFieldPopup">Edit</small>
                                                    
                                                    <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                        pointer; " >Hide</small>


                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No Record Found</h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


        <div class="modal fade" id="editCustomFieldPopup" tabindex="-1" role="dialog" aria-labelledby="editCustomFieldPopup" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content cssModelLength">
                    <div class="modal-header">
                        <span class="color-black pl-1">Edit Custom Field</span>
                        <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" #closebutton2>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body d-flex">
                        <div class=" bg-black color-grey w-100">
                            <div class="w3-card-4 classCardPopUp">
                                <div class="container-fluid">
                                    <ul class="tabbing-section tabbing-accordians tabClass">
                                        <li>
                                            <a>Edit Custom Field</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="create-new-leade mt-2">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100" [formGroup]="editCustomFieldForm">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                                <label for="exampleInputEmail1">Rank</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email"  formControlName="rank" class="form-control" id="exampleInputEmail1" 
                                                                aria-describedby="emailHelp" placeholder="Enter Rank">
                                                                
                                                            </div>

                                                            <div class="col-lg-3 form-group rightFormA">
                                                                <label for="exampleInputEmail1">Label</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <input type="email"  formControlName="label" class="form-control" id="exampleInputEmail1" 
                                                                aria-describedby="emailHelp" placeholder="Enter Label">
                                                               
                                                                
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                                <label for="exampleInputEmail1">Pick List</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email"  formControlName="pickList" class="form-control" id="exampleInputEmail1" 
                                                                aria-describedby="emailHelp" placeholder="Enter Pick List">
                                                                
                                                            </div>

                                                            <div class="col-lg-3 form-group rightFormA">
                                                                <label for="exampleInputEmail1">Type</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                
                                                                
                                                                <p-dropdown filter="true" optionlabel="label"  formControlName="type" 
                                                                id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Type">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                                <label for="exampleInputEmail1">Length</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email"  formControlName="length" class="form-control" id="exampleInputEmail1" 
                                                                aria-describedby="emailHelp" placeholder="Enter Length">
                                                                
                                                            </div>

                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-5 main-submit-button" style="margin-left: 52%;">
                                                        <button type="submit" class="btn btn-secondary submit-btn">Add</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn">Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="addNewCustomFieldPopup" tabindex="-1" role="dialog" aria-labelledby="addNewCustomFieldPopup" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content cssModelLength">
                    <div class="modal-header">
                        <span class="color-black pl-1">Add a Custom Field</span>
                        <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" #closebutton2>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body d-flex">
                        <div class=" bg-black color-grey w-100">
                            <div class="w3-card-4 classCardPopUp">
                                <div class="container-fluid">
                                    <ul class="tabbing-section tabbing-accordians tabClass">
                                        <li>
                                            <a>Add a Custom Field</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="create-new-leade mt-2">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100" [formGroup]="addCustomFieldForm">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group rightForm">
                                                                <label for="exampleInputEmail1">Label</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <input type="email"  formControlName="label" class="form-control" id="exampleInputEmail1" 
                                                                aria-describedby="emailHelp" placeholder="Enter Label">
                                                               
                                                                
                                                                
                                                            </div>

                                                            <div class="col-lg-3 form-group rightFormA">
                                                                <label for="exampleInputEmail1">Type</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                
                                                                
                                                                <p-dropdown filter="true" optionlabel="label"  formControlName="type" 
                                                                id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Type">
                                                                </p-dropdown>
                                                            </div>

                                                           

                                                           
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightFormA">
                                                                <label for="exampleInputEmail1">Size</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                
                                                                
                                                                <p-dropdown filter="true" optionlabel="label"  formControlName="size" 
                                                                id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Size">
                                                                </p-dropdown>
                                                            </div>

                                                           
                                                            
                                                            
                                                        </div>
                                                    </div>


                                                   
                                                    
                                                    <div class="col-lg-5 main-submit-button" style="margin-left: 52%;">
                                                        <button type="submit" class="btn btn-secondary submit-btn">Add</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn">Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        

<!-- <ngx-spinner></ngx-spinner> -->





