import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-address-map',
  templateUrl: './detail-address-map.component.html',
  styleUrls: ['./detail-address-map.component.css']
})
export class DetailAddressMapComponent implements OnInit {
  activeCategory=1;
  mapKey: any;
  mapValue: any;
  sequenceNum: any;

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    private _location: Location,) { }

  ngOnInit(): void {
 
    this.activatedRoute.queryParams.subscribe(params=> [
      this.mapKey = params["mapKey"],
      this.mapValue=params["mapValue"],
      this.sequenceNum=params["sequenceNum"],
    
    ])
   
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/hub/Dashboard']);
  }
  
  cancelSubmit(){
    this.router.navigate(["/security/address-match-map"])
  }

}
