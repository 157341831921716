import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { FinancialService } from 'src/app/services/financial.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-create-trans-entries',
  templateUrl: './create-trans-entries.component.html',
  styleUrls: ['./create-trans-entries.component.css']
})
export class CreateTransEntriesComponent implements OnInit {
  accountTransaction:FormGroup;
  activeCategory=2;
  show: boolean;
  partyId: any;
  acctran: any;
  glAccountType: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  glAccountTypeArray: any[]=[];
  debit: { label: string; value: string; }[];
  currencyArray: any[]=[];
  purpose: { label: string; value: string; }[];
  groupStatus: any;
  groupStatusArray: any[]=[];
  accseqId: any;
  constructor( readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
    readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly _FormBuilder: FormBuilder,
    private _ActivatedRoute: ActivatedRoute,
  ) { 
    this.accountTransaction = this._FormBuilder.group({
      glAccountType: [''], glAccId: [''], DebitCreditFlag: [''], partyId: [''], OrigAmount: [''],
      OrigCurrencyUomID: [''],
      Purpose: [''],
      VoucherRef: [''],
      ProductID: [''],
      ReconcileStatus: [''],
      Settlement: [''],
      Summary: [''],
      Description: [''],  
    });
  this.debit=[{
    "label":"Credit",
    "value":"C"
  },
  {
    "label":"Debit",
    "value":"D"
  }]
  this.purpose=[{
    "label":"Internal Conversion",
    "value":"INTERNAL_CONVERSION"
  },
  {
    "label":"External Conversion",
    "value":"EXTERNAL_CONVERSION"
  }]
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.acctran=params["acctrans"];
      this.accseqId=params["accseqId"];
    });
    
    this.getInitialData();
    this.getGlAccountInitialData();
    this.getCurrency();
    this.initialTransData();
    if(this.accseqId){
      this.show=true;
      this.getTransDetailById();
    }else{
      this.show=false;
    }
  }
  getTransDetailById() {
    this.spinner.show();
    this.accountsService.getTransDetailById(this.accseqId,this.acctran).subscribe((res: any) => {
      setTimeout(() => {
        const formValue = this.accountTransaction;
        formValue.patchValue({
          glAccountType: res.data.glAccountTypeId,
          glAccId:res.data.glAccountId,
          DebitCreditFlag:res.data.debitCreditFlag,
          partyId:res.data.partyId,
          OrigAmount: res.data.origAmount,
          OrigCurrencyUomID: res.data.origCurrencyUomId,
         
          VoucherRef:res.data.voucherRef,
          ProductID:res.data.productId,
          ReconcileStatus:res.data.reconcileStatusId,
          Settlement:res.data.settlementTermId,
          Summary: res.data.isSummary,
          Description: res.data.description,
        })
      
      }, 3000);
      this.spinner.hide();
    })
  
  }
  initialTransData() {
    this.spinner.show();
    this.accountsService.initialTransData().subscribe((res: any) => {

      this.groupStatus = res.data.groupStatus;
      this.spinner.hide();
    
      for (const value of this.groupStatus) {
        this.groupStatusArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    
  

    })
   
  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }

    })
   
  }
  getInitialData() {
    this.spinner.show();
    this.accountsService.getInitialDataAccountTypeDefault().subscribe(res => {
      this.glAccountType = res.data.glAccountTypeList;
     
      this.spinner.hide();
    
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
    })
   
  }
  
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "acctgTransEntrySeqId": this.accseqId,
      "acctgTransId":this.acctran,
      "debitCreditFlag": this.accountTransaction.value.DebitCreditFlag,
      "description": this.accountTransaction.value.Description,
      "glAccountId": this.accountTransaction.value.glAccId,
      "glAccountTypeId": this.accountTransaction.value.glAccountType,
      "isSummary":this.accountTransaction.value.Summary,
      "organizationPartyId": this.partyId,
      "origAmount": this.accountTransaction.value.OrigAmount,
      "origCurrencyUomId": this.accountTransaction.value.OrigCurrencyUomID,
      "partyId": this.accountTransaction.value.partyId,
      "productId": this.accountTransaction.value.ProductID,
      "purposeEnumId": this.accountTransaction.value.Purpose,
      "reconcileStatusId": this.accountTransaction.value.ReconcileStatus,
      "settlementTermId": this.accountTransaction.value.Settlement,
      "voucherRef": this.accountTransaction.value.VoucherRef,
    }
    this.accountsService.puttransactionEntries(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-accounting-transaction'],{ queryParams: { partyId:this.partyId ,acctrans:this.acctran} });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }} else{
          this._ToastrService.error(err.error.message);
        }
    }); }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {  
      "acctgTransEntrySeqId": "", "acctgTransEntryTypeId": "_NA_", "acctgTransId":this.acctran,
      "debitCreditFlag": this.accountTransaction.value.DebitCreditFlag,
      "description": this.accountTransaction.value.Description,
      "glAccountId": this.accountTransaction.value.glAccId,
      "glAccountTypeId": this.accountTransaction.value.glAccountType,
      "isSummary":this.accountTransaction.value.Summary,
      "organizationPartyId": this.partyId,
      "origAmount": this.accountTransaction.value.OrigAmount,
      "origCurrencyUomId": this.accountTransaction.value.OrigCurrencyUomID,
      "partyId": this.accountTransaction.value.partyId,
      "productId": this.accountTransaction.value.ProductID,
      "purposeEnumId": this.accountTransaction.value.Purpose,
      "reconcileStatusId": this.accountTransaction.value.ReconcileStatus,
      "settlementTermId": this.accountTransaction.value.Settlement,
      "voucherRef": this.accountTransaction.value.VoucherRef,
    }
    this.accountsService.posttransactionEntries(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-accounting-transaction'],{ queryParams: { partyId:this.partyId ,acctrans:this.acctran} });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }}else{
          this._ToastrService.error(err.error.message);
        }});}
  
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-accounting-transaction'],{ queryParams: { partyId:this.partyId ,acctrans:this.acctran} });
  }
}
