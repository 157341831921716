<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Contact List</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard" style="margin-left: -2%!important;">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategoriess==9?'active':''" (click)="toLead()"><a>
                                Leads </a></li>
                        <!-- <li [ngClass]="activeCategoriess==10?'active':''" (click)="openMarketingCampaign()"><a>
                                Convert Lead</a></li> -->
                        <li [ngClass]="activeCategoriess==11?'active':''" (click)="toContact()"><a>
                                Contacts </a></li>
                        <li [ngClass]="activeCategoriess==12?'active':''" (click)="toAccount()"><a>
                                Accounts</a></li>
                        <li [ngClass]="activeCategoriess==1?'active':''" (click)="openDataResource()"><a>
                                Data Resource </a></li>
                        <li [ngClass]="activeCategoriess==2?'active':''" (click)="openMarketingCampaign()"><a>
                                Marketing Campaign</a></li>
                        <li [ngClass]="activeCategoriess==3?'active':''" (click)="openTracking()"><a>
                                Tracking</a></li>
                        <li [ngClass]="activeCategoriess==4?'active':''" (click)="openSegment()"><a>
                                Segment</a></li>
                        <li [ngClass]="activeCategoriess==5?'active':''" (click)="openContactList()"><a>
                                Contact List</a></li>
                                <li [ngClass]="activeCategoriess==6?'active':''" (click)="createcontent()"><a>
                                    Create Content</a></li>
                        <li [ngClass]="activeCategoriess==13?'active':''" (click)="toOpportunity()"><a>
                                Opportunities</a></li>
                        <li [ngClass]="activeCategoriess==14?'active':''" (click)="toTasks()"><a>
                                Tasks</a></li>
                        <li [ngClass]="activeCategoriess==15?'active':''" (click)="toCases()"><a>
                                Cases</a></li>
                        <li [ngClass]="activeCategoriess==16?'active':''" (click)="toForecast()"><a>
                                Forecast</a></li>
                        <li [ngClass]="activeCategoriess==17?'active':''" (click)="toDocuments()"><a>
                                Documents</a></li>
                        <li [ngClass]="activeCategoriess==18?'active':''" (click)="toNotes()"><a>
                                Notes</a></li>
                        <li [ngClass]="activeCategoriess==19?'active':''" (click)="toCalendar()"><a>
                                Calendar</a></li>
                        <li [ngClass]="activeCategoriess==20?'active':''" (click)="openReports()"><a>
                                Reports</a></li>
                        <li [ngClass]="activeCategoriess==21?'active':''" (click)="toEmail()"><a>
                                Emails marketing</a></li>
                        <li [ngClass]="activeCategoriess==22?'active':''" (click)="MobileMessaging()"><a>
                                Mobile Messaging</a></li>
                        <li [ngClass]="activeCategoriess==23?'active':''" (click)="SocialMedia()"><a>
                                Social Media Marketing </a></li>

                                
                        </ul>
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategoriess==5">
                                            <div class="create-new-leade">
                                                <div>
                                                    <div class="w3-card-4 classCardView mt-2 w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            Find Contact Lists
                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" style="width: 113px;" data-toggle="modal" data-target="#exampleCreateNewContactList">Create New Contact List
                                                            </button>
                                                        </div>
                                                        <div class="create-new-leade">
                                                            <div class="container-fluid" style="padding: 0;">
                                                                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                                    <div class="w-100">
                        
                                                                        <div class="w3-card-4 w-100">
                                                                            <div class="accordion classCard" id="myAccordion">
                                                                                <div class="accordion-item">
                                                                                    <h2 class="accordion-header" id="headingOne">
                                                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                                                                            <span style="margin-left: 12px!important;"> Search Options</span>
                                                                                           
                                                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 81%!important; padding-top: 11px !important;
                                                                                            padding-bottom: 11px !important;"></button>									
                                            
                                                                                        </div>
                                            
                                                                                    </h2>
                                                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                                        <div class="card-body">
                                                                                            
                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInput">
                                                                                                            <label for="exampleInputEmail1">Contact List ID	
                                                                                                </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <input type="email" class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactListID" name="ContactListID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact List ID">
                                                                                                        </div>
                                                                                                        <div class="col-lg-3 form-group classInput">
                                                                                                            <label for="exampleInputEmail1">Contact List Name
                                                                                                </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" name="ContactListName" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactListName" aria-describedby="emailHelp" placeholder="Enter Contact List Name">
                                                                                                        </div>
                                                                                                        <div class="col-lg-3 form-group classInput">
                                                                                                            <label for="exampleInputEmail1">Contact List Type ID
                                                                                                    </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <p-dropdown filter="true" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactListTypeID" name="ContactListTypeID" optionlabel="label" [options]="ContactListTypeIdArray" placeholder="Enter Contact List Type ID">
                                                                                                            </p-dropdown>
                                                                                                        </div>
                                                                                                        <div class="col-lg-3 form-group classInput">
                                                                                                            <label for="exampleInputEmail1">Contact Mechanism Type ID
                                                                                                    </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <p-dropdown filter="true" name="ContactMechanismTypeID" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactMechanismTypeID" [options]="ContactMechanismTypeIDArray" placeholder="Enter Contact Mechanism Type ID">
                                                                                                            </p-dropdown>
                                                                                                        </div>
                                                                                                        <div class="col-lg-3 form-group classInput">
                                                                                                            <label for="exampleInputEmail1">Marketing Campaign ID
                                                                                                    </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <p-dropdown filter="true" name="MarketingCampaignID" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.MarketingCampaignID" [options]="MarketingCampaignArray" placeholder="Enter Marketing Campaign ID">
                                                                                                            </p-dropdown>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                            
                                                                                            <div class="col-lg-10 main-submit-button">
                                                                                                <button type="submit" (click)="FindContactList()" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                                                <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>&nbsp;
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            
                                                                            </div>
                                                                        </div>
                        
                                                                        <!-- <div class="w3-card-4 classCardView">
                                                                            <div class="header-tabs" >
                                                                                <h4 class="common-styling h4Margin">
                                                                                    Search Options
                                                                                </h4>
                                                                            </div>
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Contact List ID	
                                                                                </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email" class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactListID" name="ContactListID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact List ID">
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Contact List Name
                                                                                </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" name="ContactListName" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactListName" aria-describedby="emailHelp" placeholder="Enter Contact List Name">
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Contact List Type ID
                                                                                    </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactListTypeID" name="ContactListTypeID" optionlabel="label" [options]="ContactListTypeIdArray" placeholder="Enter Contact List Type ID">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Contact Mechanism Type ID
                                                                                    </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true" name="ContactMechanismTypeID" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactMechanismTypeID" [options]="ContactMechanismTypeIDArray" placeholder="Enter Contact Mechanism Type ID">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Marketing Campaign ID
                                                                                    </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true" name="MarketingCampaignID" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.MarketingCampaignID" [options]="MarketingCampaignArray" placeholder="Enter Marketing Campaign ID">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-10 main-submit-button">
                                                                                <button type="submit" (click)="FindContactList()" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                                <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>&nbsp;
                                                                            </div>
                                                                        </div> -->
                                                                        
                                                                        <div class="w3-card-4 classCardView mt-2">
                                                                            <div class="header-tabs" >
                                                                                <h4 class="common-styling h4Margin">
                                                                                    Search Results
                                                                                </h4>
                                                                            </div>
                                                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">
                        
                                                                                        <div class="card own-account-table">
                                                                                            <p-table [value]="findContactList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                <ng-template pTemplate="header">
                                                                                                    <tr style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                        
                                                                                                                Contact List ID
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                        
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Contact List Name
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'125px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Is Public ?
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Is Single Use ?
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Contact List Type ID
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Contact Mechanism Type ID
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Marketing Campaign ID
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                        
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                    <tr>
                                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                                            <div class="checkbox-align">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                <span (click)="detailedPage(product.contactListId)"  class="account-button"  >
                                                                                                            {{product.contactListId}}</span>
                        
                                                                                                            </div>
                                                                                                        </td>
                        
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.contactListName}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'125px'}">
                                                                                                            {{product.isPublic}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.singleUse}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.contactListTypeId}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.contactMechTypeId}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.marketingCampaignId}}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                        
                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span class="text-center">
                                                                                                    <h4
                                                                                                        class="ml-2">
                                                                                                        No Record
                                                                                                        Found</h4>
                                                                                                </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                        
                                                                                            <p class="paginate_data">
                                                                                                View per page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    
                                            </div>
                                            
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Find Contact Lists
                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" style="width: 113px;" data-toggle="modal" data-target="#exampleCreateNewContactList">Create New Contact List
                                    </button>
                                </div>
                                <div class="create-new-leade">
                                    <div class="container-fluid" style="padding: 0;">
                                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w-100">

                                                <div class="w3-card-4 w-100">
                                                    <div class="accordion classCard" id="myAccordion">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="headingOne">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                                                    <span style="margin-left: 12px!important;"> Search Options</span>
                                                                   
                                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 81%!important; padding-top: 11px !important;
                                                                    padding-bottom: 11px !important;"></button>									
                    
                                                                </div>
                    
                                                            </h2>
                                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                <div class="card-body">
                                                                    
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Contact List ID	
                                                                        </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactListID" name="ContactListID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact List ID">
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Contact List Name
                                                                        </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="ContactListName" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactListName" aria-describedby="emailHelp" placeholder="Enter Contact List Name">
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Contact List Type ID
                                                                            </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactListTypeID" name="ContactListTypeID" optionlabel="label" [options]="ContactListTypeIdArray" placeholder="Enter Contact List Type ID">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Contact Mechanism Type ID
                                                                            </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" name="ContactMechanismTypeID" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactMechanismTypeID" [options]="ContactMechanismTypeIDArray" placeholder="Enter Contact Mechanism Type ID">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Marketing Campaign ID
                                                                            </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" name="MarketingCampaignID" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.MarketingCampaignID" [options]="MarketingCampaignArray" placeholder="Enter Marketing Campaign ID">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                    
                                                                    <div class="col-lg-10 main-submit-button">
                                                                        <button type="submit" (click)="FindContactList()" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>&nbsp;
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                </div>

                                                 <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Search Options
                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Contact List ID	
                                                        </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactListID" name="ContactListID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact List ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Contact List Name
                                                        </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="ContactListName" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactListName" aria-describedby="emailHelp" placeholder="Enter Contact List Name">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Contact List Type ID
                                                            </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactListTypeID" name="ContactListTypeID" optionlabel="label" [options]="ContactListTypeIdArray" placeholder="Enter Contact List Type ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Contact Mechanism Type ID
                                                            </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" name="ContactMechanismTypeID" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ContactMechanismTypeID" [options]="ContactMechanismTypeIDArray" placeholder="Enter Contact Mechanism Type ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Marketing Campaign ID
                                                            </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" name="MarketingCampaignID" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.MarketingCampaignID" [options]="MarketingCampaignArray" placeholder="Enter Marketing Campaign ID">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-10 main-submit-button">
                                                        <button type="submit" (click)="FindContactList()" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>&nbsp;
                                                    </div>
                                                </div> 
                                                
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Search Results
                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">

                                                                <div class="card own-account-table">
                                                                    <p-table [value]="findContactList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>

                                                                                        Contact List ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Contact List Name
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'125px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Is Public ?
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Is Single Use ?
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Contact List Type ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Contact Mechanism Type ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Marketing Campaign ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span (click)="detailedPage(product.contactListId)"  class="account-button"  >
                                                                                    {{product.contactListId}}</span>

                                                                                    </div>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.contactListName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'125px'}">
                                                                                    {{product.isPublic}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.singleUse}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.contactListTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.contactMechTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.marketingCampaignId}}
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>

                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                            <h4
                                                                                class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="modal fade" id="exampleCreateNewContactList" tabindex="-1" role="dialog" aria-labelledby="exampleCreateNewContactListTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">


                <span class="color-black pl-1">Add Contact List</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                    Add Contact List</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="CreateNewContactListForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact List Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="ContactListName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact List Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact List Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ContactListTypeID" optionlabel="label" [options]="ContactListTypeIdArray" placeholder="Enter Contact List Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Is Public ?
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="IsPublic" optionlabel="label" [options]="yesNOArray" placeholder="Enter Is Public ?">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Is Single Use ?
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="IsSingleUse" optionlabel="label" [options]="yesNOArray" placeholder="Enter Is Single Use ?">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact Mechanism Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ContactMechanismTypeID" optionlabel="label" [options]="ContactMechanismTypeIDArray" placeholder="Enter Contact Mechanism Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Marketing Campaign ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="MarketingCampaignID" optionlabel="label" [options]="MarketingCampaignArray" placeholder="Enter Marketing Campaign ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Owner Party ID		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="OwnerPartyID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Owner Party ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Email From	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="VerifyEmailFrom" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Verify Email From">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Email Screen	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="VerifyEmailScreen" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Verify Email Screen">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Email Subject	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="VerifyEmailSubject" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Verify Email Subject">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Email WebSite ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="VerifyEmailWebSiteID" optionlabel="label" [options]="VerifyEmailWebsiteIdArray" placeholder="Enter Verify Email WebSite ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Opt Out Screen	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="OptOutScreen" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Opt Out Screen">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button (click)="creatingContactList()" type="submit" class="btn btn-secondary submit-btn">Save</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>