<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Return Order</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class=" bg-white color-grey create-new-table">
                <br>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==1?'active':''"><a>
                  Return Order</a></li>


                        </ul>
                    </div>
                    <div>
                        <br>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                            <div class="panel panel-default">
                                                <div *ngIf="activeCategory==1">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling"> 
                                                            Find Return
                                                        </h4>
                                                        <span routerLinkActive="active" class="edit-buttons" data-toggle="modal" data-target="#exampleModalCenter">Create
                              New Return</span>
                                                    </div>
                                                    <br>
                                                    <div class="panel-body">
                                                        <form class="w-100">

                                                            <div class="w3-card-4">
                                                                <div class="accordion classCard" id="myAccordion">
                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header" id="headingOne">
                                                                            <div
                                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                            <span class="headingAccords"> Find Return

                                                                            </span>
                                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 85%!important; padding-top: 11px !important;
                                                                            padding-bottom: 11px !important;"></button>									
                                        
                                                                        </div>
                                        
                                                                        </h2>
                                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                            <div class="card-body">
                                                                                
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                                <label class="common-labels">Return Header Type ID
                                                        </label>
                                                                                            </div>
                
                
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown class="common-half-dropdown" [(ngModel)]="advanceSearch.returnHeaderTypeId" [ngModelOptions]="{standlone:true}" name="returnHeaderTypeId" placeholder="Select Return HeaderType ID	" filter="true" [options]="headerArray" optionlabel="label"></p-dropdown>
                                                                                            </div>
                
                
                                                                                            <div class=" col-lg-3 form-group rightFormA">
                                                                                                <label class="common-labels">Return ID
                                                        </label>
                                                                                            </div>
                
                                                                                            <div class="col-lg-2 d-flex">
                                                                                                <p-dropdown class="common-half-dropdown" style="display: none;" [(ngModel)]="advanceSearch.returnIdSearchType" [ngModelOptions]="{standlone:true}" name="returnIdSearchType" placeholder="Select Return ID	" filter="true" [options]="opportunityIdArray" optionlabel="label"></p-dropdown>
                                                                                                <input [(ngModel)]="advanceSearch.returnId" type="text" class="form-control" [ngModelOptions]="{standlone:true}" name="returnId" placeholder="Enter ID">
                                                                                            </div>
                
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightForm">
                                                                                            <label class="common-labels labelClass">Return From Party
                                                      </label>
                                                                                        </div>
                
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown class="common-half-dropdown" [(ngModel)]="advanceSearch.fromPartyId" [ngModelOptions]="{standlone:true}" name="fromPartyId" placeholder="Select Return From Party	" filter="true" [options]="partyIdArray" optionlabel="label"></p-dropdown>
                                                                                        </div>
                
                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                            <label class="common-labels labelClass">
                                                        Billing Account
                                                      </label>
                                                                                        </div>
                
                                                                                        <div class="col-lg-2 d-flex">
                                                                                            <p-dropdown class="common-half-dropdown" style="display: none;" [(ngModel)]="advanceSearch.billingAccountIdSearchType" [ngModelOptions]="{standlone:true}" name="billingAccountIdSearchType" placeholder="Select Billing Account" filter="true" [options]="opportunityIdArray"
                                                                                                optionlabel="label"></p-dropdown>
                                                                                            <input [(ngModel)]="advanceSearch.billingAccountId" type="text" [ngModelOptions]="{standlone:true}" name="billingAccountId" class="form-control" placeholder="Enter Account">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                
                
                
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                
                                                                                        <div class="col-lg-3 form-group rightForm">
                                                                                            <label class="common-labels labelClass">Status </label>
                                                                                        </div>
                
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown class="common-half-dropdown" [(ngModel)]="advanceSearch.statusIds" [ngModelOptions]="{standlone:true}" name="statusIds" (ngModelChange)="onChange(advanceSearch.statusIds)" placeholder="Select Status" filter="true" [options]="statusIdArray"
                                                                                                optionlabel="label"></p-dropdown>
                                                                                        </div>
                
                
                
                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                            <label class="labelClass" for="exampleInputEmail1">Entry Date From </label>
                                                                                        </div>
                
                
                                                                                        <div class="col-lg-2 d-flex">
                                                                                            <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  [(ngModel)]="advanceSearch.entryDateFrom" [ngModelOptions]="{standlone:true}" name="entryDateFrom">&nbsp
                
                                                                                            <p-dropdown class="common-half-dropdown" style="display: none;" [(ngModel)]="advanceSearch.entryDateFromSearchType" [ngModelOptions]="{standlone:true}" name="entryDateFromSearchType" placeholder="Select  Date From	" filter="true" [options]="dateIdArray"
                                                                                                optionlabel="label"></p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightForm">
                                                                                            <label class="labelClass" for="exampleInputEmail1">Entry Date To</label>
                                                                                        </div>
                
                                                                                        <div class="col-lg-2 d-flex ">
                                                                                            <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  [(ngModel)]="advanceSearch.entryDateTo" [ngModelOptions]="{standlone:true}" name="entryDateTo">&nbsp
                                                                                            <p-dropdown class="common-half-dropdown" style="display: none;" [(ngModel)]="advanceSearch.entryDateToSearchType" [ngModelOptions]="{standlone:true}" name="entryDateToSearchType" placeholder="Select Entry Date To" filter="true" [options]="dateIdArray"
                                                                                                optionlabel="label"></p-dropdown>
                                                                                        </div>
                
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                <div class="submit-btn-sales main-submit-button" style="padding-right:267px;margin-top:-8px">
                                                                                    <button type="submit" (click)="advanceSearchContacts(false)" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                                    <button type="submit" (click)="reset()" class="btn btn-danger ml-2">Reset</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <!-- <div class="w3-card-4 classCard">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label class="common-labels">Return Header Type ID
                                        </label>
                                                                            </div>


                                                                            <div class="col-lg-2">
                                                                                <p-dropdown class="common-half-dropdown" [(ngModel)]="advanceSearch.returnHeaderTypeId" [ngModelOptions]="{standlone:true}" name="returnHeaderTypeId" placeholder="Select Return HeaderType ID	" filter="true" [options]="headerArray" optionlabel="label"></p-dropdown>
                                                                            </div>


                                                                            <div class=" col-lg-3 form-group rightFormA">
                                                                                <label class="common-labels">Return ID
                                        </label>
                                                                            </div>

                                                                            <div class="col-lg-2 d-flex">
                                                                                <p-dropdown class="common-half-dropdown" style="display: none;" [(ngModel)]="advanceSearch.returnIdSearchType" [ngModelOptions]="{standlone:true}" name="returnIdSearchType" placeholder="Select Return ID	" filter="true" [options]="opportunityIdArray" optionlabel="label"></p-dropdown>
                                                                                <input [(ngModel)]="advanceSearch.returnId" type="text" class="form-control" [ngModelOptions]="{standlone:true}" name="returnId" placeholder="Enter ID">
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label class="common-labels labelClass">Return From Party
                                      </label>
                                                                        </div>

                                                                        <div class="col-lg-2">
                                                                            <p-dropdown class="common-half-dropdown" [(ngModel)]="advanceSearch.fromPartyId" [ngModelOptions]="{standlone:true}" name="fromPartyId" placeholder="Select Return From Party	" filter="true" [options]="partyIdArray" optionlabel="label"></p-dropdown>
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label class="common-labels labelClass">
                                        Billing Account
                                      </label>
                                                                        </div>

                                                                        <div class="col-lg-2 d-flex">
                                                                            <p-dropdown class="common-half-dropdown" style="display: none;" [(ngModel)]="advanceSearch.billingAccountIdSearchType" [ngModelOptions]="{standlone:true}" name="billingAccountIdSearchType" placeholder="Select Billing Account" filter="true" [options]="opportunityIdArray"
                                                                                optionlabel="label"></p-dropdown>
                                                                            <input [(ngModel)]="advanceSearch.billingAccountId" type="text" [ngModelOptions]="{standlone:true}" name="billingAccountId" class="form-control" placeholder="Enter Account">
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div class="col-lg-12">
                                                                    <div class="row">

                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label class="common-labels labelClass">Status </label>
                                                                        </div>

                                                                        <div class="col-lg-2">
                                                                            <p-dropdown class="common-half-dropdown" [(ngModel)]="advanceSearch.statusIds" [ngModelOptions]="{standlone:true}" name="statusIds" (ngModelChange)="onChange(advanceSearch.statusIds)" placeholder="Select Status" filter="true" [options]="statusIdArray"
                                                                                optionlabel="label"></p-dropdown>
                                                                        </div>



                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label class="labelClass" for="exampleInputEmail1">Entry Date From </label>
                                                                        </div>


                                                                        <div class="col-lg-2 d-flex">
                                                                            <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  [(ngModel)]="advanceSearch.entryDateFrom" [ngModelOptions]="{standlone:true}" name="entryDateFrom">&nbsp

                                                                            <p-dropdown class="common-half-dropdown" style="display: none;" [(ngModel)]="advanceSearch.entryDateFromSearchType" [ngModelOptions]="{standlone:true}" name="entryDateFromSearchType" placeholder="Select  Date From	" filter="true" [options]="dateIdArray"
                                                                                optionlabel="label"></p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label class="labelClass" for="exampleInputEmail1">Entry Date To</label>
                                                                        </div>

                                                                        <div class="col-lg-2 d-flex ">
                                                                            <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  [(ngModel)]="advanceSearch.entryDateTo" [ngModelOptions]="{standlone:true}" name="entryDateTo">&nbsp
                                                                            <p-dropdown class="common-half-dropdown" style="display: none;" [(ngModel)]="advanceSearch.entryDateToSearchType" [ngModelOptions]="{standlone:true}" name="entryDateToSearchType" placeholder="Select Entry Date To" filter="true" [options]="dateIdArray"
                                                                                optionlabel="label"></p-dropdown>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="submit-btn-sales main-submit-button" style="padding-right:267px;margin-top:-8px">
                                                                    <button type="submit" (click)="advanceSearchContacts(false)" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                    <button type="submit" (click)="reset()" class="btn btn-danger ml-2">Reset</button>
                                                                </div>
                                                            </div> -->
                                                        </form>
                                                        <br>
                                                        <div class="w3-card-4 classCard">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling h4Margin">
                                                                        Return List
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                                                <div class="w-100">
                                                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                                        <div class="panel panel-default">
                                                                            <div class="panel-heading" role="tab" id="headingOne">
                                                                            </div>
                                                                            <div class="panel-body a">
                                                                                <div class="mt-2">
                                                                                    <div class="">
                                                                                        <div class="row m-0">
                                                                                            <div class="card own-account-tables">
                                                                                                <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%" [paginator]="true" [rows]="rows" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true" [totalRecords]="total" [value]="offerShowingLineData" [scrollable]="true"
                                                                                                    styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                                                                                    <ng-template pTemplate="colgroup" let-columns>
                                                                                                        <colgroup>
                                                                                                            <col *ngFor="let col of offerShowingLineColoumns">
                                                                                                        </colgroup>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr class="activity-rows">
                                                                                                            <th id="tableHead" class="tabTh" *ngFor="let col of offerShowingLineColoumns">
                                                                                                                <ng-container *ngIf="col.field === 'status'">
                                                                                                                    <p-checkbox name="selectContacts" styleClass="custom-checkbox" binary="true"></p-checkbox>
                                                                                                                </ng-container>
                                                                                                                <ng-container style="color: white;">
                                                                                                                    {{col.header}}
                                                                                                                </ng-container>
                                                                                                                <p-sortIcon class="sort-icon" field="quantity">
                                                                                                                </p-sortIcon>

                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-activity>
                                                                                                        <tr class="activity-data">
                                                                                                            <td *ngFor="let col of offerShowingLineColoumns">
                                                                                                                <ng-container *ngIf="col.field === 'status'">
                                                                                                                    <p-checkbox styleClass="custom-checkbox" name="selectContact" binary="true"></p-checkbox>
                                                                                                                    <a  class="account-button"  routerLinkActive="active" (click)="detailPage(activity.returnId)">{{activity.returnId}}</a>
                                                                                                                </ng-container>
                                                                                                                <ng-container *ngIf="col.field === 'name'">
                                                                                                                    <span class="d-flex red-border align-items-center">
                                                            <div class="name_text">
                                                              <a>
                                                                {{activity.entryDate| date:'yyyy-MM-dd hh:mm:ss'}}
                                                              </a>
                                                            </div>
                                                          </span>
                                                                                                                </ng-container>
                                                                                                                <ng-container *ngIf="col.field === 'email'">
                                                                                                                    <span class="d-block align-items-center">
                                                            <div class="name_text">
                                                              {{activity.fromParty}}
                                                            </div>
                                                            <a  class="account-button" >{{activity.fromPartyId}}</a>
                                                          </span>
                                                                                                                </ng-container>
                                                                                                                <ng-container *ngIf="col.field === 'phone'">
                                                                                                                    <a  class="account-button" > {{activity.destinationFacilityId}}</a>
                                                                                                                </ng-container>
                                                                                                                <ng-container *ngIf="col.field === 'type'">
                                                                                                                    <span>{{activity.statusDescription}}</span>
                                                                                                                </ng-container>
                                                                                                                <ng-container *ngIf="col.field === 'action'">
                                                                                                                    <span>
                                                            <svg class="hover" (click)="onUpdate(activity.returnId)"
                                                              xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                              viewBox="0 0 21.223 21.222">
                                                              <g transform="translate(0 -0.004)">
                                                                <g transform="translate(0 1.52)">
                                                                  <path
                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                    transform="translate(0 -34.137)" />
                                                                </g>
                                                                <g transform="translate(4.548 0.004)">
                                                                  <g transform="translate(0 0)">
                                                                    <path
                                                                      d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                      transform="translate(-102.409 -0.004)" />
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </svg>
                                                          </span>
                                                                                                                </ng-container>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                                <p class="paginate_data fid-pagination">
                                                                                                    View per page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <!-- 2 -->

                            <!-- 2 -->

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Return </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">

                <div class=" bg-white color-grey create-new-table" style="    width: 100%;">

                    <div>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                            <div class="panel panel-default">
                                                <div class="w3-card-4 classCardPopUp">
                                                    <div class="container-fluid">
                                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                                            <li *ngIf="show" [ngClass]="activeCategory==0?'active':''"><a>
                                        Update return</a></li>
                                                            <li *ngIf="!show" [ngClass]="activeCategory==0?'active':''"><a>
                                        Create new return</a></li>


                                                        </ul>
                                                    </div>

                                                    <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                                                        <div class="panel-body">
                                                            <div class="mt-2">
                                                                <div class="row">
                                                                    <form class="w-100" [formGroup]="createReturn">
                                                                        <div class="all-users-infomation font-13 font-medium own-user-select allPad">

                                                                            <div class="col-lg-12">

                                                                                <div class="row">
                                                                                    <div class=" col-lg-3 form-group rightFormD">
                                                                                        <label class="common-labels labelClass">
                                                    Return HeaderType ID
                                                  </label>
                                                                                    </div>

                                                                                    <div class="col-lg-3">
                                                                                        <p-dropdown formControlName="returnHeaderTypeId" placeholder="Select Return HeaderType ID	" filter="true" [options]="headerArray" optionlabel="label"></p-dropdown>
                                                                                    </div>


                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                        <label class="common-labels labelClass">
                                                    Status ID
                                                  </label>
                                                                                    </div>

                                                                                    <div class="col-lg-3">
                                                                                        <p-dropdown formControlName="statusId" placeholder="Select Return HeaderType ID	" filter="true" [options]="statusIdArray" optionlabel="label"></p-dropdown>

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormD">
                                                                                    <label class="common-labels labelClass">
        
                                                  From Party ID
                                                </label>
                                                                                </div>

                                                                                <div class="col-lg-3">

                                                                                    <p-dropdown formControlName="fromPartyId" placeholder="Select PartyId" filter="true" [options]="partyIdArray" optionlabel="label"></p-dropdown>
                                                                                </div>



                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label class="common-labels labelClass">To Party ID
                                                </label>
                                                                                </div>

                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown formControlName="toPartyId" placeholder="Select PartyId" filter="true" [options]="partyIdArray" optionlabel="label"></p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>




                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormD">
                                                                                    <label class="common-labels labelClass">Payment Method ID </label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control" formControlName="paymentMethodId" id="exampleInputEmail1" placeholder="Enter Payment Method ID">
                                                                                </div>


                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label class="common-labels labelClass ">Find Account ID</label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control" formControlName="finAccountId" id="exampleInputEmail1" placeholder="Enter Payment Method ID">
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormD">
                                                                                    <label class="common-labels labelClass">Billing Account ID </label>

                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control" formControlName="billingAccountId" id="exampleInputEmail1" placeholder="Enter Billing Account ID">
                                                                                </div>


                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label class="labelClass" for="exampleInputEmail1">Entry Date </label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="date" class="form-control" id="exampleInputEmail1" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="entryDate">

                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormD">
                                                                                    <label class="common-labels labelClass"> Origin Contact Mech ID</label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control" formControlName="originContactMechId" id="exampleInputEmail1" placeholder="Enter  Origin Contact Mech ID">
                                                                                </div>



                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label class="common-labels labelClass">Destination Facility ID
                                                </label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown formControlName="destinationFacilityId" placeholder="Select Destination Facility ID" filter="true" [options]="facilityArray" optionlabel="label"></p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormD">
                                                                                    <label class="common-labels labelClass">Needs Inventory Receive
                                                </label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown formControlName="needsInventoryReceive" placeholder="Select Needs Inventory Receive" filter="true" 
                                                                                    [options]="dateIdArrayNew" optionlabel="label"></p-dropdown>
                                                                                </div>


                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label class="common-labels labelClass">Currency
                                                </label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown formControlName="currencyUomId" placeholder="Select Currency" filter="true" [options]="currencyListArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormD">
                                                                                    <label class="common-labels labelClass">Supplier Rma ID </label>
                                                                                </div>

                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control" formControlName="supplierRmaId" id="exampleInputEmail1" placeholder="Enter Supplier Rma ID">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="submit-btn-sales main-submit-button" style="padding-right:75px;margin-top:-8px">
                                                                            <button (click)="submit();" *ngIf="!show" type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                                                            <button *ngIf="show" type="submit" (click)="update()" class="btn btn-secondary submit-btn">Update</button>
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>