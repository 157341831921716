import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ProductionService } from '../production.service';
import { ToastrService } from 'ngx-toastr';import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-jobshop-list',
  templateUrl: './jobshop-list.component.html',
  styleUrls: ['./jobshop-list.component.css']
})
export class JobshopListComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  advanceSearch: {

    productionId: string,
    productionIdSearchType: string,
    Status: string,
    productionNameSearchType:string,
    ProductID: string,
    productionName:string,
    fromDate: string,
    fromdateSearchType: string,
    throughDate: string,
    ToDateSearchType: string,
    facilityId:string


  };
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
  private finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  financialList=[{
    "product":"1"
  }];
  FinAccountTypeList: any;
  FinAccountTypeListArray: any[] = [];
  smallerArray: { label: string; value: string; }[];
  greaterArray: { label: string; value: string; }[];
  productListArray: any[]=[];
  facilityId: any;
  routingId: any;
  productId: any;
  facilityIdArray: any[]=[];
  routingIdArray: any[]=[];
  productIdArray: any[]=[];
  JobShopProductionList: any;
  id: string;
  statusId: any;
  statusIdArray: any[]=[];
  statusSelect: any;
  createJob: FormGroup;
  activeCategory = 2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[] = [];
  varianceReasonId: any;
  varianceReasonIdArray: any[] = [];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  budgetId: any;
  personId: any;
  partyIdArray: any[] = [];
  ReviewResultTypes: any;
  ReviewResultTypesArray: any[] = [];
  reviewDate: string;
 
  
  createDependent: { label: string; value: string; }[];
  startDate: string;
  workEffortId: any;
  getEstimateFrom: string;
  estimatedCompletionDate: string;

  completeDate: any;
  dateComplete: string;
  prodId: any;
  constructor(readonly spinner: NgxSpinnerService, readonly accountsService: AccountsService,
    readonly datePipe: DatePipe,
    readonly productionService:ProductionService,
    readonly router: Router,  private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly _ToastrService: ToastrService) {
    
    this.advanceSearch = {

      productionId: '',
      productionIdSearchType: 'Contains',
      Status: '',
      productionNameSearchType: 'Contains',
      ProductID: '',
      productionName:'',
      fromDate: '',
      fromdateSearchType: 'Equals',
      throughDate: '',
      ToDateSearchType: 'Less Than',
      facilityId:''


    };
    this.greaterArray = [
      {
          label: 'Equals',
          value: 'Equals'
      },
      {
          label: 'Same Day',
          value: 'Same Day'
      },
      {
          label: 'Greater Than From Day Start',
          value: 'Greater Than From Day Start'
      },
      {
          label: 'Greater Than',
          value: 'Greater Than'
      }
  ];
  this.smallerArray = [
      {
          label: 'Less Than',
          value: 'Less Than'
      },
      {
          label: 'Up To Day',
          value: 'Up To Day'
      },
      {
          label: 'Up Through Day',
          value: 'Up Through Day'
      },
      {
          label: 'Is Empty',
          value: 'Is Empty'
      }
  ];
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.createJob = this._FormBuilder.group({
      productId: [''],
      Quantity: [''],
      startDate: [''],
      FacilityID: [''],
      RoutingID: [''],
      ProductionRunName: [''],
      Description: [''],
      dependent: [''],
      CalculatedCompletionDate:[''],
      ManufacturerID:[''],
      statusId:['']


    });
    this.createDependent=[{
      "label":"Yes",
      "value":"Y"
    },
    {
      "label":"No",
      "value":"N"
    }]
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   
    this.getProducts();
    this.getJobShopProductionList();
    this.getstatusByType();
  }

  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  onUpdate() {
    this.spinner.show();
    const startDate = this.createJob.get('startDate').value;
    this.startDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const dateComplete =this.completeDate
    this.dateComplete = this.datePipe.transform(dateComplete, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "currentStatusId": this.statusId,
      "estimatedCompletionDate":this.dateComplete,
      "estimatedStartDate": this.startDate,
      "facilityId": this.createJob.value.FacilityID,
      "manufacturerId":"",
      "productId": this.createJob.value.productId,
      "productionRunId": this.workEffortId,
      "quantity":  this.createJob.value.Quantity,
      "workEffortName": this.createJob.value.ProductionRunName,
      "description":this.createJob.value.Description,
    }
    this.productionService.updateProductionRun(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebutton.nativeElement.click();
       
      } }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  onSubmit() {
    this.spinner.show();
    const startDate = this.createJob.get('startDate').value;
    this.startDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "createDependentProductionRuns":  this.createJob.value.dependent,
      "description": this.createJob.value.Description,
      "facilityId":  this.createJob.value.FacilityID,
      "productId": this.createJob.value.productId,
      "productionRunId":"",
      "quantity":  this.createJob.value.Quantity,
      "routingId":  this.createJob.value.RoutingID,
      "startDate":this.startDate,
      "workEffortName": this.createJob.value.ProductionRunName,
    }
    this.productionService.postProductionRun(requestData).subscribe((res: any) => {
      if (res.success) {
        this.prodId=res.data.productionRunId;
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebutton.nativeElement.click();
        this.router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.prodId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });}
  onStatusChange(event) {
    /*   this.statusSelect=[]
     this.statusSelect.push(event.value)
      */
     this.statusSelect=event.value
    }
    advanceSearchContacts(isPopUp: boolean): void {
      this.spinner.show();
      this.JobShopProductionList = [];
      const req = {
        "currentStatusId": this.statusSelect==undefined?[""]:this.statusSelect,
        "estimatedStartDateFrom":this.datePipe.transform(this.advanceSearch.fromDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.fromDate, "yyyy-MM-dd hh:mm:ss") : '',
        "estimatedStartDateFromType":this.advanceSearch.fromdateSearchType ? this.advanceSearch.fromdateSearchType : '',
        "estimatedStartDateTo": this.datePipe.transform(this.advanceSearch.throughDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.throughDate, "yyyy-MM-dd hh:mm:ss") : '',
        "estimatedStartDateToType": this.advanceSearch.ToDateSearchType ? this.advanceSearch.ToDateSearchType : '',
        "facilityId": this.advanceSearch.facilityId ? this.advanceSearch.facilityId : '',
        "productId": this.advanceSearch.ProductID ? this.advanceSearch.ProductID : '',
        "workEffortId": this.advanceSearch.productionId ? this.advanceSearch.productionId : '',
        "workEffortIdSearchType": this.advanceSearch.productionIdSearchType ? this.advanceSearch.productionIdSearchType : '',
        "workEffortName": this.advanceSearch.productionName ? this.advanceSearch.productionName : '',
        "workEffortNameSearchType":this.advanceSearch.productionNameSearchType ? this.advanceSearch.productionNameSearchType : '',

      
  
      }
      this.productionService.getJobShopProductionList(this.finSize, req)
        .subscribe(resp => {
          if (resp.success) {
            this.spinner.hide();
            this.JobShopProductionList = resp.data;
          } 
        });
       
    }
    reset() {
      this.spinner.show();
      this.advanceSearch.ProductID = "";
      this.advanceSearch.Status = "";
      this.advanceSearch.facilityId="";
      this.advanceSearch.fromDate="";
      this.advanceSearch.productionId="";
      this.advanceSearch.productionName="";
      this.advanceSearch.throughDate=""
      this.statusSelect="";
      this.JobShopProductionList = []
      const req = {
        "currentStatusId": [""],
      "estimatedStartDateFrom": "",
      "estimatedStartDateFromType": "Contains",
      "estimatedStartDateTo": "",
      "estimatedStartDateToType": "Contains",
      "facilityId": "",
      "productId": "",
      "workEffortId": "",
      "workEffortIdSearchType": "Contains",
      "workEffortName": "",
      "workEffortNameSearchType": "Contains"
      }
      this.productionService.getJobShopProductionList(this.finSize,req).subscribe((res: any) => {
        this.JobShopProductionList = res.data;
        this.spinner.hide();
      }) }
  getstatusByType() {
    this.spinner.show();
    this.id="PRODUCTION_RUN"
    this.productionService.getstatusByType(this.id).subscribe(res => {
      this.statusId = res.data;
      this.spinner.hide();
      for (const value of this.statusId) {
        this.statusIdArray.push({
          label: value.statusCode,
          value: value.statusId
        })
      }
    })
    
  }
  getJobShopProductionList() {
    this.spinner.show();
    const req={
      "currentStatusId": [""],
      "estimatedStartDateFrom": "",
      "estimatedStartDateFromType": "Contains",
      "estimatedStartDateTo": "",
      "estimatedStartDateToType": "Contains",
      "facilityId": "",
      "productId": "",
      "workEffortId": "",
      "workEffortIdSearchType": "Contains",
      "workEffortName": "",
      "workEffortNameSearchType": "Contains"
    }
    this.productionService.getJobShopProductionList(this.finSize,req).subscribe((res: any) => {
      this.JobShopProductionList = res.data;
      this.spinner.hide();
    })
  
  }
  getProducts(): void {
    this.spinner.show();
  
    this.productionService.getJobShopInitialData().subscribe((res) => {
      this.facilityId = res.data.facilityId;
      this.routingId = res.data.routingId;
      this.productId = res.data.productId;
      
      this.spinner.hide();
      for (const value of this.facilityId) {
        this.facilityIdArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
      for (const value of this.routingId) {
        this.routingIdArray.push({
          label: value.description,
          value: value.workEffortId
        })
      }
      for (const value of this.productId) {
        this.productIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    
    })
  
  }

  jobDetails(id,productId) {
    this.router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: id ,productId:productId} })
  }
  updateJobShop(id) {
    this.router.navigate(["production/create-jobshop"], { queryParams: { workEffortId: id } })
  }
 /*  createJob() {
    this.router.navigate(["production/create-jobshop"])

  } */

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }

  

}

