import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
@Component({
  selector: 'app-gl-invoice-list-summary',
  templateUrl: './gl-invoice-list-summary.component.html',
  styleUrls: ['./gl-invoice-list-summary.component.css']
})
export class GlInvoiceListSummaryComponent implements OnInit {
  show: boolean;
  @ViewChild('closebutton') closebutton;
  invoiceItemTypeId: any;
  description: any;
  defaultGlAccountId: any;
  activeCategory = 2;
  updateInvoice: FormGroup;
  parentGlAccount: any;
  parentGlAccountArray: any[]=[];
  constructor(readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
   ) {
      this.updateInvoice = this._FormBuilder.group({
        defaultGlAccountId: [''],
        description: [''],
        invoiceItemTypeId: [''],
  
  
  
      });
     }
     ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getGlAccountInitialData();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.invoiceItemTypeId = params["invoiceItemTypeId"];
      this.description = params["description"];
      this.defaultGlAccountId = params["defaultGlAccountId"];

    });
 
  }
  edit(){
    const formValue = this.updateInvoice;
    formValue.patchValue({
      defaultGlAccountId: this.defaultGlAccountId,
      description: this.description,
      invoiceItemTypeId:  this.invoiceItemTypeId 



    })

  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {
 
      this.parentGlAccount = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.parentGlAccount) {
        this.parentGlAccountArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }

    })
   
  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "defaultGlAccountId":this.updateInvoice.value.defaultGlAccountId,
      "description":this.updateInvoice.value.description,
      "invoiceItemTypeId": this.updateInvoice.value.invoiceItemTypeId
     
    }

    this.accountsService.updateInvoiceItemType(requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.router.navigate(["financial/gl-invoice-type-list"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  
  cancelSubmit(){
    this.router.navigate(["financial/gl-invoice-type-list"])
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
