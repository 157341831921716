<div class="container-fluid main-container-wrapper" style="margin-top: 53px !important;">

    <!-- <div class="row " style="background-color: #0492C2; padding-top: 6px !important;">
        

        <div class="col-lg-1">
           <span class="color-black pl-1 titlePanels" style="cursor: pointer; color: white; font-weight: bold;">Find </span>
        </div>
            <div class="col-lg-2" style="margin-left: -4% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Work Order Number"
                >
                </p-dropdown>

            </div>

            <div class="col-lg-2" style="margin-left: -2% !important;">
                <input type="text" class="form-control" id=""  >
            </div>

            <div class="col-lg-1" style="margin-left: -2% !important;">
                <button type="submit" class="btn btn-secondary submit-btn">Search</button>

            </div>
        

        <div class="col-lg-4">
        </div>
            <div class="col-lg-2" style="margin-left: 7% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Recent Items"
                >
                </p-dropdown>

            </div>

        

    

</div> -->

<div class="row" style="background-color:  #0492C2; padding-bottom: 6px !important; padding-top: 4px !important;">

<!-- <div class="col-lg-3   ">
    <button type="submit" class="btn btn-secondary submit-btn" style="margin-left: -2%;">
        Dispatch Board
     </button>
</div> -->

<div class="col-lg-2 " style="margin-left: 60% !important;">
    <button (click)="toWorkOrder()" style="background-color: #1338BE; 
    color: white !important; font-weight: bold;"> Work Orders</button>
</div>

<div class="col-lg-2 " style="margin-left: -8% !important;">
    <button (click)="toCustomer()" style="background-color: #48AAAD; 
    color: white !important; font-weight: bold;">Customer</button>

</div>               
   
<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toInvoices()" style="background-color:#016064;
     color: white; font-weight: bold;">Invoices</button>

</div>                
    
<!-- <div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toReports()" style="background-color: #1520A6; 
    color: white; font-weight: bold;">Reports</button>

</div>               

<div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toPeople()" style="background-color:#59788E;
     color: white !important; font-weight: bold; padding-left: 12px; padding-right: 12px;">Staff</button>

</div> -->
<div class="col-lg-2 " style="margin-left: -3% !important;">
    <button (click)="toTimeSheet()" style="background-color: #1F456E;
     color: white !important; font-weight: bold;">Time Sheet</button>

</div>                 

<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toSetting()" style="background-color:#757c88 ; 
    color: white; font-weight: bold; ">Settings</button>

</div>



</div>
    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            <div class="col-md-12 p-0" >
                <div class="w3-card-4 classCard  w-100" style="padding-bottom: 2%;"> 
                        
                
                
                        <!-- <div>
                            <a class="btn btn-link" style="font-size:19px !important; font-weight: bold;">Financial</a>

                        </div>
                    <a class="btn btn-link" style=" 
                    " (click)="toBasicInvoiceSettings()" >Basic Invoice Settings</a>
                    <p>You can configure your Discount and Tax Settings here.</p>
            
                    
            
                    <a class="btn btn-link" style="
                    " (click)="toPostingInvoices()" >Posting Invoices </a>
                    <p>You can choose to post the invoice on Customers or Sites here.
                    </p>

                    <a class="btn btn-link" style=" 
                    " (click)="toSalesTaxItems()" >Sales Tax Items </a>
                    <p>Here you can add sales tax items for different tax rates. if normally the sales tax
                        rates differ for your Customers items created here can then be associated with Customer/Site
                        so that invoice created for those customer will have a rate automatically populated.
                    </p>

                    <a class="btn btn-link" style=" 
                    " (click)="toSalesTaxGroups()" >Sales Tax Groups </a>
                    <p>Here you can sales tax group items which are two or more sales tax items that are combined 
                        to get one sales tax rate.
                    </p>

                    <a class="btn btn-link" style=" 
                    "  (click)="toSalesTaxCodes()" >Sales Tax Codes  </a>
                    <p>Here you can add sales tax codes that are used to track the taxable or non-taxable
                        status of both the items you sell (products and services) and your Customers.
                    </p>

                    <a class="btn btn-link" style=" 
                    "  (click)="toInvoiceStatus()">Invoice Status  </a>
                    <p>You can add your own custome invoice status are here.</p>
                    
                    <a class="btn btn-link" style=" 
                    "  (click)="toPriceList()">Price List  </a>
                    <p>Here you can either add price list from an external source or add them one by
                        one using the interface provided.
                    </p>

                    <a class="btn btn-link" style=" 
                    "  (click)="toPriceListGroups()">Price List Groups  </a>
                    <p>You can create price list groups here which are grouping of your price list items.
                        If in your business you often have to enter same set of items for a service, you can 
                        create price list groups for such items instead of adding each price list itme in an 
                        invoice you can add a price list group which will add the price list items in that
                        group automatically for you.
                    </p>



                    <a class="btn btn-link" style=" 
                    " (click)="toPrintsTemplates()" >Print Templates  </a> -->

                    <a class="btn btn-link" style=" 
                    " (click)="toPaymentProcessing()" >Payment Processing </a>

                    <!-- <a class="btn btn-link" style=" 
                    " (click)="toQBMSIntegration()" >QBMS Integration  </a>
                 -->
            </div>
            </div>    
            
       
        
    </div>

</div>

