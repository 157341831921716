import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class NewWarehouseShipmentService {

  constructor(readonly http: HttpClient,) { }
  
  getOrderIds(): Observable<any> {
    const getOrderIds = URLS.getOrderIds;
    return this.http.get(getOrderIds);
  }

  getPersonsAndPartyGroups(): Observable<any> {
    const getPersonsAndPartyGroups = URLS.getPersonsAndPartyGroups;
    return this.http.get(getPersonsAndPartyGroups);
  }

  getCurrency(): Observable<any> {
    const getCurrency = URLS.getCurrency;
    return this.http.get(getCurrency);
  }

  getStatusId(): Observable<any> {
    const getStatusId = URLS.getStatusId;
    return this.http.get(getStatusId);
  }

  createFacilityShipment(formData): Observable<any> {
    const createFacilityShipment = URLS.createFacilityShipment;
    return this.http.post(createFacilityShipment, formData);
  }

  updateFacilityShipment(id,formData): Observable<any> {
    const updateFacilityShipment = URLS.updateFacilityShipment + `/${id}`;
    return this.http.put(updateFacilityShipment, formData);
  }
  updatePhysicalShipment(id,formData): Observable<any> {
    const updatePhysiaclInventory = URLS.updatePhysiaclInventory + `/${id}`;
    return this.http.post(updatePhysiaclInventory, formData);
  }
  getFacilityShipmentById(id): Observable<any> {
    const createFacilityShipment = URLS.createFacilityShipment + `/${id}`;;
    return this.http.get(createFacilityShipment);
  }
  getVaraiceResonId(): Observable<any> {
    const getReasonVarainceId = URLS.getReasonVarainceId;
    return this.http.get(getReasonVarainceId);
  }

  getFacilityShipmentByIdView(id): Observable<any> {
    const getFacilityShipmentByIdView = URLS.getFacilityShipmentByIdView + `/${id}`;;
    return this.http.get(getFacilityShipmentByIdView);
  }

  downloadView(id): Observable<any> {
    const getDownload = URLS.generateShipmentManifestPdf  + "?shipmentId=" + id
    return this.http.get(getDownload,{responseType:'blob'}) 
   }
  
   getReturnNumber(fac,id): Observable<any> {
    const getReturnViewOrder = URLS.getReturnViewOrder + `/${id}` + `/${fac}`;;
    return this.http.get(getReturnViewOrder);
  }
}
