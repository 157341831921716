import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { saveAs } from 'file-saver';
import { Location } from '@angular/common';

@Component({
  selector: 'app-open-customers-invoices-import',
  templateUrl: './open-customers-invoices-import.component.html',
  styleUrls: ['./open-customers-invoices-import.component.css']
})
export class OpenCustomersInvoicesImportComponent implements OnInit {

  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  stateArray: any[]=[];
  state: any;
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  userLogin: any;
  findPartyArray: any[]=[];
  identification: any;
  identificationArray: any[]=[];
  fileData: File;
  customerInvoiceList: any;
  
  @ViewChild('closebutton') closebutton;
  @ViewChild('fileUploader') fileUploader:ElementRef;

  constructor(readonly router: Router,
    readonly spinner:NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly toastr:ToastrService,
    private _location: Location,
    ) { }

  ngOnInit(): void {
    
    this.getCustomerInvoiceList();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/hub/Dashboard']);
  }

  getCustomerInvoiceList(){
    this.spinner.show();
    this.accountsService.getCustomerInvoiceImportList().subscribe((res:any) =>{
      this.customerInvoiceList = res.data;
      this.spinner.hide();
    })
  }

  onSubmit(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file",this.fileData);

    this.accountsService.importWareHouseOnly(formData).subscribe((res:any)=>{
      if(res.success) { 
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.closebutton.nativeElement.click();
      }     
    },(err)=>{
        this.toastr.error(err.message);
        this.spinner.hide();
     })
  }

  downloadFile() {
   
    let data = "invoiceType,invoiceDate,status,description,fromParty,toParty,total,outstanding"
    
   
   let stringResultUpload = data;
    var blob = new Blob([stringResultUpload], {type: "text/csv;charset=utf-8"});
      saveAs(blob, "CustomerInvoice.csv");
    
  }
  reset() { 
    this.fileUploader.nativeElement.value = null;
  }

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  goToPrev(){
    this.router.navigate(['/content/branches-only-import']);
  }
  goToNext(){
    this.router.navigate(['/content/open-vendors-invoices-import']);
  }

}
