import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from 'src/app/header/header.service';
import { MainCatalogService } from './catalog.service';
import { Router, RouterEvent } from '@angular/router';  import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-main-catalog-mgmt',
  templateUrl: './main-catalog-mgmt.component.html',
  styleUrls: ['./main-catalog-mgmt.component.css']
})
export class MainCatalogMgmtComponent implements OnInit {
  catalog: any;
  categories: any;
  activeCategoryValue=2;
  activeCategory = 1;
  activeCat=1;
  catalogArray: any = [];
  categoryArray: any = [];
  updateCatalog: any;
  updateCategory: any;
  products: { label: string }[];
  productId: string | null;
  productIdData: string | null;
  pagination: {
    pageNo: number,
    pageSize: number
  };
  catalogFilter: {
    catalogName: string,
    catalogNameSearchType: string,
    prodCatalogId: string,
    prodCatalogIdSearchType: string
  };
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  categoryFilter: {
    categoryId: string,
    categoryIdSearchType: string,
    categoryName: string,
    categoryNameSearchType: string
  };
  productFilter: {
    internalName: string,
    internalNameSearchType: string,
    productId: string,
    productIdSearchType: string
  };
  searchFilter: {
    searchCategoryId: string,
    searchString: string,
    noContains: string,
    operator: string
  };
  viewIndex: number;
  viewSize: number;
  advanceSearchData: any[];
  isShown=false;
  url: string;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(
    readonly headerService: HeaderService,    private _location: Location, readonly mainCatalogService: MainCatalogService,  readonly spinner: NgxSpinnerService,  readonly router: Router
  ) {
    this.url='/facilities/catalog/catalogs/new-product-catalog'
    this.pagination = {
      pageNo: 1,
      pageSize: 500
    };
    this.catalogFilter = {
      catalogName: '',
      catalogNameSearchType: 'Contains',
      prodCatalogId: '',
      prodCatalogIdSearchType: 'Contains'
    };
    this.categoryFilter = {
      categoryId: '',
      categoryIdSearchType: 'Contains',
      categoryName: '',
      categoryNameSearchType: 'Contains'
    };
    this.productFilter = {
      internalName: '',internalNameSearchType: 'Contains',productId: '',productIdSearchType: 'Contains'
    };
    this.products = [];
    this.productId = null;
    this.updateCatalog = null;
    this.updateCategory = null;
    this.productIdData = null;
    this.searchFilter = {
      searchCategoryId: '',searchString: '',noContains: '', operator: ''
    };
    this.viewIndex = 0;
    this.viewSize = 200;
    this.advanceSearchData = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.Hidebtn=true;
    }
    this.getProductCatalog();
    this.categoryList();
    this.getProductList();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getProductCatalog(): void {
    this.spinner.show();
    this.mainCatalogService.getCatalogs(this.pagination, this.catalogFilter)
      .then(data => {
        if (data.success) {
          this.catalog = data.data;
          this.spinner.hide();
          this.catalogArray = data.data.map(value => {
            return {
              label: value.prodCatalogId,
              value: value.prodCatalogId
            };
          });
        }
      });
     
  }
  categoryList(): void {
    this.spinner.show();
    this.mainCatalogService.getCategories(this.pagination, this.categoryFilter)
      .then(data => {
      
        if ( data.success) {
          this.categories = data.data;
          this.spinner.hide();
          this.categoryArray = data.data.map(value => {
            return {
              label: value.productCategoryId,
              value: value.productCategoryId
            };
          });
        }
      });
     
  }

  getProductList(): void {
    this.spinner.show();
    this.mainCatalogService.getProducts(this.pagination, this.productFilter)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.products = data.data.map(value => {
            return {
              label: value.productId
            };
          });
        }
      });
     
  }
  advanceSearch(): void {
    this.spinner.show();
    this.mainCatalogService.categoryAdvanceSearch(this.viewIndex, this.viewSize, this.searchFilter)
      .then(data => {
        this.advanceSearchData = data.data.productIds;
        this.spinner.hide();
      });
     
  }
  setContains(event: any): void {
    this.searchFilter.noContains = event.target.checked ? 'Y' : 'N';
  }
  setOperator(event: any): void {
    this.searchFilter.operator = event;
  }
  changeCatalog(updateCatalog): void {
    this.updateCatalog = updateCatalog;
  }
  changeCategory(updateCategory): void {
    this.updateCategory = updateCategory;
  }
  inputChecked(event: string, i: number): void {

  }
  editCatalog(): void {
    this.router.navigate([this.url], { queryParams: { prodCatalogId: this.updateCatalog } });
  }
  editCategory(): void {
    this.router.navigate(['/facilities/catalog/catagories/new-product-catagory'], { queryParams: { categoryId: this.updateCategory } });
  }
  createCatalog(): void {
    this.router.navigate([this.url]);
  }
  navigateToProduct(): void {
    this.router.navigate(['/facilities/catalog/products'], { queryParams: { productId: this.productIdData } });
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
}
mainOpen(){
  this.router.navigate(['/facilities/catalog/main-catalog']);
}
catalogOpen(){
  this.router.navigate(['/facilities/catalog/catalogs']);
}
categoriesOpen(){
  this.router.navigate(['/facilities/catalog/catagories']);
}
productsOpen(){
  this.router.navigate(['/facilities/catalog/products']);
}
featuresOpen(){
  this.router.navigate(['/facilities/catalog/feature-type']);
}
promosOpen(){
  this.router.navigate(['/facilities/catalog/promos']);
}
priceRulesOpen(){
  this.router.navigate(['/facilities/catalog/price-rules']);
}
storesOpen(){
  this.router.navigate(['/facilities/catalog/stores']);
}
productStoreGroupsOpen(){
  this.router.navigate(['/facilities/catalog/product-store-groups']);
}
thesaurusOpen(){
  this.router.navigate(['/facilities/catalog/thesaurus']);
}
reviewsOpen(){
  this.router.navigate(['/facilities/catalog/reviews']);
}
configurationsOpen(){
  this.router.navigate(['/facilities/catalog/configurations']);
}
subscriptionsOpen(){
  this.router.navigate(['/facilities/catalog/subscriptions']);
}
shippingOpen(){
  this.router.navigate(['/facilities/catalog/shipment']);
}
imageManagementOpen(){
  this.router.navigate(['/facilities/catalog/image-management']);
}

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  feautureValue(){
    this.isShown = !this.isShown;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
