<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Product Manufacturing Rules </span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>

            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategoryMain==1?'active':''" (click)="BomList()"><a>
                        Bom Simulation</a></li>
                        <li [ngClass]="activeCategoryMain==2?'active':''" (click)="ManufacturingRules()"><a>
                        Manufacturing Rules</a></li>


                    </ul>
                </div>
                <div class=" bg-white color-grey">

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" style="    padding: 15px 15px;">
                                    <div class="w3-card-4 classCard">
                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                            <button type="button" class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter">Create New Manufacturing Rule</button>

                                        </div>




                                        <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="ManufacturingRule" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>

                                                                            Rule ID
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;"> Rule Seq ID
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                    </th>


                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Description
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;"> Product ID
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                    </th>


                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Product ID For
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;"> Product ID In
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Product ID In Subst
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Product Feature
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Quantity
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Rule Operator
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> From Date
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Through Date
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Action
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                    </th>



                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span (click)="DetailManufacturingRules(product.ruleId)">{{product.ruleId}}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.ruleSeqId}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.description}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.productId}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.productIdFor}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.productIdIn}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.productIdInSubst}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.productFeature}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.quantity}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.ruleOperator}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteManufacturingRule(product.ruleId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                     <defs>
                                                                         <style>
                                                                             .a {
                                                                                 fill: #f44336;
                                                                             }
                           
                                                                             .b {
                                                                                 fill: #fafafa;
                                                                                 margin-left: 20px;
                                                                             }
                                                                         </style>
                                                                     </defs>
                                                                     <path class="a fill-color"
                                                                         d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                     <path class="b"
                                                                         d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                         transform="translate(-147.576 -147.576)" />
                                                                 </svg>
                                                                    </td>






                                                            </ng-template>
                                                        </p-table>
                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Add Product Manufacturing Rules</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">

                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                            Add Product Manufacturing Rules
    
    
                        </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                            Update Product Manufacturing Rules
    
    
                        </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createManRule">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Product ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product ID" filter="true" formControlName="ProductID" [options]="productListArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Product ID For</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product ID For" filter="true" formControlName="ProductIDFor" [options]="productListArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Product ID In<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product ID In" filter="true" formControlName="ProductIDIn" [options]="productInIdsArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Calendar ID" formControlName="FromDate">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Product ID In Subst</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product ID In Subst" filter="true" formControlName="ProductIDInSubst" [options]="productListArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Product Feature</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product Feature" filter="true" formControlName="ProductFeature" [options]="ProductFeatureIdsArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Rule Operator</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Rule Operator" filter="true" formControlName="RuleOperator" [options]="Rule" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Quantity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity" formControlName="Quantity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Calendar ID" formControlName="ThroughDate">

                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>




                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -11% !important;">
                                        <button type="submit" (click)="onSubmit()" *ngIf="!this.show" class="btn btn-secondary submit-btn">Save</button>
                                        <!-- <button type="submit" (click)="onUpdate()" *ngIf="this.show" class="btn btn-secondary submit-btn">Update</button> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>