<div class="container-fluid main-container-wrapper">
    <br>
    <div style="margin-top: -2%!important;"
        class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
        <div class="submenu">
            <div *ngIf="this.agreementType=='payable'">
                <button _ngcontent-cta-c128="" style="font-size:14px!important;" type="submit"
                    class="btn btn-secondary submit-btn" style="width: 154px!important" routerLinkActive="active"
                    routerLink="/financial/payable/customer-agreeement">Commission Agreement</button>&nbsp;
                <button _ngcontent-cta-c128="" style="font-size: 14px!important;" type="submit"
                    class="btn btn-secondary submit-btn" style="width: 142px!important" routerLinkActive="active"
                    routerLink="/financial/payable/commission-invoice">Commission Invoices</button>&nbsp;
                <button _ngcontent-cta-c128="" style="    font-size: 12px!important;
                    width: 13%;" routerLink="/financial/payable/invoices" type="submit"
                    class="btn btn-secondary submit-btn" (click)="invoiceType();">Invoices </button>&nbsp;
                <button _ngcontent-cta-c128="" style="font-size: 14px!important;" type="submit"
                    class="btn btn-secondary submit-btn" routerLinkActive="active"
                    routerLink="/financial/payable/payment-payables">Payments</button>&nbsp;
                <button _ngcontent-cta-c128="" style="    font-size: 12px!important;
                    width: 14%;" type="submit" class="btn btn-secondary submit-btn" routerLinkActive="active"
                    routerLink="/financial/payable/payment-group">AP Payment Groups</button>&nbsp;


                <button _ngcontent-cta-c128="" style="font-size: 12px!important;width: 15%;" type="submit"
                    class="btn btn-secondary submit-btn" style="width: 100px!important" routerLinkActive="active"
                    routerLink="/financial/payable/payables-report">Report</button>&nbsp;
                <button _ngcontent-cta-c128="" style="font-size: 12px!important;
                    width: 13%;
                    margin-top: 1%;" type="submit" class="btn btn-secondary submit-btn" routerLinkActive="active"
                    routerLink="/financial/payable/billing-account">Billing Account</button>
            </div>

            <div *ngIf="this.agreementType=='receiveable'">
                <button _ngcontent-cta-c128="" style="font-size: 12px!important;width: 15%;" type="submit"
                    class="btn btn-secondary submit-btn" routerLinkActive="active"
                    routerLink="/financial/receiveable/customer-agreeement">Agreements</button>&nbsp;
                <button _ngcontent-cta-c128="" style="font-size: 12px!important;width: 15%;" type="submit"
                    class="btn btn-secondary submit-btn" routerLinkActive="active"
                    routerLink="/financial/receiveable/invoices">Sales Invoice</button>&nbsp;

                <button _ngcontent-cta-c128="" style="font-size: 12px!important;width: 15%;" type="submit"
                    class="btn btn-secondary submit-btn" routerLinkActive="active"
                    routerLink="/financial/receiveable/payments">Payments</button>&nbsp;
                <button _ngcontent-cta-c128="" style="font-size: 12px!important;width: 15%;" type="submit"
                    class="btn btn-secondary submit-btn" routerLinkActive="active"
                    routerLink="/financial/receiveable/payment-group">AP Payment Groups</button>&nbsp;

                <button _ngcontent-cta-c128="" style="font-size: 12px!important;width: 15%;" type="submit"
                    class="btn btn-secondary submit-btn" routerLinkActive="active"
                    routerLink="/financial/receiveable/receive-reports">Report</button>&nbsp;

            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <div class="suppliers-wrapper">
                    <button type="button" class="btn btn-outline-secondary active"
                        (click)="createInvoices()">Create</button>
                </div>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    Commission Invoices
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Invoice ID</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">From Party</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Processing Status</label>
                                            <div class="">
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Status</label>
                                            <div class="">
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Invoice Date</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Due Date</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Paid Date</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Amount</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Open Amount</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Ref Num</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Message</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Item Description</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Division Tag</label>
                                            <div class="">
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Department Tag</label>
                                            <div class="">
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Activity Tag</label>
                                            <div class="">
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128=""
                                        type="submit" class="btn btn-secondary submit-btn">Find</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>