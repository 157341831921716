import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { DropdownModule } from 'primeng/dropdown';
import { OrderManagementService } from '../order-management.service';
import { ToastrService } from 'ngx-toastr';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-sales-payment-options',
  templateUrl: './sales-payment-options.component.html',
  styleUrls: ['./sales-payment-options.component.css']
})
export class SalesPaymentOptionsComponent implements OnInit {
  activeCategory = 1;
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 6,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],
    }
  };

  checkOutPaymentId: string;
  paymentMethodTypes: any[];
  billingAccount: any[];
  paymentMethod: string;
  constructor(
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _OrderManagementService: OrderManagementService,
    readonly _ToastrService: ToastrService
  ) {
    this.paymentMethod = '';
    this.checkOutPaymentId = '';
    this.paymentMethodTypes = [];
    this.billingAccount = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.setBilling();
  }
  setBilling(): void {
    this.spinner.show();
    this._OrderManagementService.setBilling('N')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this._ToastrService.success('Billing Initiated');
          this.paymentMethodTypes = data.data.paymentMethodTypeAndId;
        
        }
      });
     
  }
  setPaymentMethod(event: any): void {
    this.paymentMethod = event;
  }
  finalizeOrder(): void {
    this.spinner.show();
    if (this.paymentMethod) {
      this._OrderManagementService.finalizeOrderWithDynamicBody({
        finalizeMode: 'payment',
        billingAccountId: '',
        billingAccountAmount: '',
        checkOutPaymentId: this.paymentMethod
      })
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            switch (data.data.responseMessage) {
              case 'shipping': this.router.navigate(['/order/sales-finalize-order']);
                break;
              case 'options': this.router.navigate(['/order/sales-order-options']);
                break;
              case 'term': this.router.navigate(['/order/sales-order-entry-terms']);
                break;
              case 'payment': this.router.navigate(['/order/sales-payment-options']);
                break;
              case 'addparty': this.router.navigate(['/order/sales-order-additional-party-entry']);
                break;
              case 'sales': this.router.navigate(['/order/sales-order-confirmation']);
                break;
            }
          
          }
        });
    } else {
      this._ToastrService.error('Please select payment');
    }
    this.spinner.hide();
  }
}
