import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {FormGroup, FormBuilder} from '@angular/forms';
import { CRMAccountService } from 'src/app/services/crm-account.service'
import { GlobalResourceService } from 'src/app/services/global-resource.service'
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-create-public-message',
  templateUrl: './create-public-message.component.html',
  styleUrls: ['./create-public-message.component.css']
})
export class CreatePublicMessageComponent implements OnInit {

  show=false;
  createPage:FormGroup
  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  partyId: string;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,) {
      this.partyId=localStorage.getItem("partyId")
    this.createPage = this._FormBuilder.group({
      noteInfo: [''],
      moreInfoUrl:[''],
      noteParty:['']

      
     
      
    });
   }

  ngOnInit(): void {
  }
  onSubmit() {
    this.spinner.show();
   
    const requestData = {
      "moreInfoUrl": this.createPage.value.moreInfoUrl,
      "noteInfo":this.createPage.value.noteInfo,
      "noteParty":this.createPage.value.noteParty,
     
  
     
    }
    this.accountsService.createSystemInfoNote(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/myPortal']);
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  GoToMyPortal(){
    this._Router.navigate(['/myPortal']);
  }

}
