import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-global-gl-rate-list',
  templateUrl: './global-gl-rate-list.component.html',
  styleUrls: ['./global-gl-rate-list.component.css']
})
export class GlobalGlRateListComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  createRate: FormGroup;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  GlSetingsRateList: any;
  activeCategory=6;
  show: boolean;
  uom: any;
  uomArray: any[]=[];
  emplPositionType: any;
  periodTypes: any;
  workEffortId: any;
  rateTypes: any;
  emplPositionTypeArray: any[]=[];
  periodTypesArray: any[]=[];
  rateTypesArray: any[]=[];
  workEffortIdArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  currencyArray: any[]=[];
  constructor(
    readonly addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _FormBuilder: FormBuilder,
  ) { 
    this.createRate = this._FormBuilder.group({
      emplPositionTypeId: [''],
      partyId: [''],
      periodTypeId: ['',[Validators.required]],
      rateAmount: ['',[Validators.required]],
      rateCurrencyUomId: ['',[Validators.required]],
      rateTypeId: ['',[Validators.required]],
      workEffortId: [''],


    });
  }
  getCost = [{
    value: 'GEN_COST_CALC'
  }]
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activeCategory=6;
    this.getGlSetingsRateList();

    this.show = false;
    this.getRatesInitialData();
    this.getInitialDataCost();
    this.getCurrency();
    this.getPartyId();
  }

  getInitialDataCost() {
    this.spinner.show();
    this.accountsService.getInitialDataCost().subscribe(res => {
      
      this.uom = res.data.uom;
      this.spinner.hide();
      for (const value of this.uom) {
        this.uomArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  getRatesInitialData() {
    this.spinner.show();
    this.accountsService.getRatesInitialData().subscribe(res => {
      this.emplPositionType = res.data.emplPositionType;
      this.periodTypes = res.data.periodTypes;
      this.rateTypes = res.data.rateTypes;
      this.workEffortId = res.data.workEffortId;
      this.spinner.hide();
      for (const value of this.emplPositionType) {
        this.emplPositionTypeArray.push({
          label: value.description,
          value: value.emplPositionTypeId
        })
      }
      for (const value of this.periodTypes) {
        this.periodTypesArray.push({
          label: value.description,
          value: value.periodTypeId
        })
      }
      for (const value of this.rateTypes) {
        this.rateTypesArray.push({
          label: value.description,
          value: value.rateTypeId
        })
      }
      for (const value of this.workEffortId) {
        this.workEffortIdArray.push({
          label: value.workEffortName,
          value: value.workEffortId
        }) }}) }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "emplPositionTypeId": this.createRate.value.emplPositionTypeId,
      "partyId": this.createRate.value.partyId,
      "periodTypeId": this.createRate.value.periodTypeId,
      "rateAmount":this.createRate.value.rateAmount,
      "rateCurrencyUomId": this.createRate.value.rateCurrencyUomId,
      "rateTypeId": this.createRate.value.rateTypeId,
      "workEffortId": this.createRate.value.workEffortId



    }

    this.accountsService.postGlSetingsRateList(requestData).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.createRate.reset();
        this.getGlSetingsRateList();
        this.closebutton.nativeElement.click();
        this.router.navigate(["financial/global-gl-rate-list"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  chartAccount(){
    this.router.navigate(["/financial/global-gl-setting-list"])
  }
  timePeriod(){
    this.router.navigate(["/financial/gl-custom-time-period-list"])
  }
  cost(){
    this.router.navigate(["/financial/global-gl-cost"])
  }
  paymentMethod(){
    this.router.navigate(["/financial/gl-payment-method-type"])
  }
  invoiceMethod(){
    this.router.navigate(["/financial/gl-invoice-type-list"])
  }
  rate(){
    this.router.navigate(["/financial/global-gl-rate-list"])
  }
  foreignExc(){
    this.router.navigate(["/financial/gl-foreign-exchange-rate"])
  }
  accountCat(){
    this.router.navigate(["/financial/gl-account-category-list"])
  }
  costCenter(){
    this.router.navigate(["/financial/gl-cost-center"])
  } 
  getGlSetingsRateList() {
this.spinner.show();
    this.accountsService.getGlSetingsRateList(this.finSize).subscribe((res: any) => {
      this.GlSetingsRateList = res.data;
      this.spinner.hide();
    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  // createRate() {
  //   this.router.navigate(["financial/create-gl-rates"])

  // }
  updateRate() {
    this.router.navigate(["financial/create-gl-rates"])

  }

  deleteRate(data) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "emplPositionTypeId": data.emplPositionTypeId,
          "fromDate": data.fromDate,
          "partyId": data.partyId,
          "periodTypeId": data.periodTypeId,
          "rateAmount": data.rateAmount,
          "rateCurrencyUomId": data.rateCurrencyUomId,
          "rateDescription": data.rateDescription,
          "rateTypeId": data.rateTypeId,
          "workEffortId": data.workEffortId,
        }
        this.accountsService.deleteGlSetingsRateList(req).subscribe((res: any) => {
          this.toastr.success("Delete Successfully");
          this.getGlSetingsRateList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
