import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { CategoryService } from '../new-catagory/category.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common'
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { CategoryFormService } from '../new-catagory/category-service';
import { MainCatalogService } from '../main-catalog-mgmt/catalog.service';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service'; import { Location } from '@angular/common';

@Component({
    selector: 'app-detail-category-summary',
    templateUrl: './detail-category-summary.component.html',
    styleUrls: ['./detail-category-summary.css']
})
export class DetailCategorySummaryComponent implements OnInit {
    total: number;
    @ViewChild('closebutton') closebutton;
    @ViewChild('closebutton2') closebutton2;
    @ViewChild('closebutton3') closebutton3;
    @ViewChild('closebutton4') closebutton4;
    @ViewChild('closebutton5') closebutton5;


    data: any;
    activeCategory = 1;
    categoryArray: any = [];
    show = false;
    categoryForm: FormGroup;
    editMode: boolean;
    categories: any[];
    fileType: string;
    fileData: FormData;
    url: string;
    rowNumber: number;
    pageNo: number;

    pageSize: number;
    rows: number;
    category: any;
    categoryAttributeForm: FormGroup;
    editModeAttribute: boolean;
    categoryId: string;
    categoryAttributes: any[];
    categoryAttribute: any;
    categoryLinkForm: FormGroup;
    linkTypeEnum: any;
    editModeLink: boolean;
    categoryLinks: any[];
    categoryLink: any;
    linkSeqId: string;
    categoryContentForm: FormGroup;
    editModeContent: boolean;
    prodCatContentType: any;
    categoryContents: any[];
    categoryContent: any;
    prodCatTypeId: string;
    isProdCatTypeId: boolean;
    contentIds: any[];
    categoryProductForm: FormGroup;
    categoryProducts: any[];
    categoryProduct: any;
    categoryProductIds: any[];
    editModeProduct: boolean;
    copyProductCategoryMemberForm: FormGroup;
    categoryIds: any[];
    expireProductMember: Date;
    removeExpiredProductMember: Date;
    isActiveProductMember: boolean;
    categoryFeatureGroups: any[];
    categoryFeatureGroup: any;
    createCategoryFeatureGroupForm: FormGroup;
    editModeCategoryFeatureGroup: boolean;
    categoryFeatureGroupApply: any[];
    categoryToFeatureForm: FormGroup;
    categoryToFeatures: any[];
    categoryToFeature: any;
    editModeCategoryToFeature: boolean;
    categoryFeature: any[];
    productArray = [];

    productListformValue;
    purchaseFromdate: any;
    purchasethrougdate: any;
    catalogCategoryTypeId: any;
    catalogCategoryTypeIdArray: any[] = [];
    catalogForm: FormGroup;
    ProductCatalog: any;
    EditCategoryProdCatalogsData: any;
    showCatalog: boolean;
    ProdCatalogId: any;
    ProdCatalogIdArray: any[]=[];
    partyForm: FormGroup;
    personId: any;
    partyIdArray: any[]=[];
    showParty: boolean;
    roleTypeId: any;
    roleTypeIdArray: any[]=[];
    ProductParty: any;
    productCategoryIdData: any;
    getProductCategoryToCategoryPat: any;
    readOnlyCode=false;
    fromDateCatePatch: string;
    throughDateCatePatch: any;
    hideInout: boolean;
    hideInput: boolean;
    allCategoryId: any;
    allCategoryIdArray: any[]=[];
    productCategoryToCategorySecond: any;
    rollUpChildform: FormGroup;
    updateProductCategoryToCategorySPat: any;
    rollUpform: FormGroup;
    showNew: boolean;
    ProductCategoryToCategory: any;
    userPermissions: any;
    Hidebtn: boolean=false;
    constructor(
        readonly router: Router,
        readonly _CategoryService: CategoryService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        readonly _ToastrService: ToastrService,
        public datepipe: DatePipe,
        readonly addSkillService: AddSkillService,
        readonly activatedRoute: ActivatedRoute,private _location: Location,
        readonly categoryService: CategoryService,
        readonly toastr: ToastrService,
        public categoryFormService: CategoryFormService,
        public mergeContactsService: MergeContactsService,
        readonly _MainCatalogService: MainCatalogService,
        readonly accountsService: AccountsService,


    ) {
        this.rollUpChildform = this._FormBuilder.group({
            parentCategoryID: [''],
            fromDate: [''],
            thruDate:[''],
            sequenceNum:['']
        });
        this.rollUpform = this._FormBuilder.group({
            parentCategoryID: [''],
            fromDate: [''],
            thruDate:[''],
            sequenceNum:['']
        });
        this.productListformValue = {
            internalName: "",
            internalNameSearchType: "Contains",
            productId: "",
            productIdSearchType: "Contains"
        }
        this.categoryAttributeForm = this._FormBuilder.group({
            attrDescription: [''],
            attrName: [''],
            attrValue: [''],
            productCategoryId: ['']
        });
        this.editModeAttribute = false;
        this.categoryId = '';
        this.categoryAttributes = [];
        this.total = 0;
        this.rowNumber = 0;
        this.pageNo = 1;
        this.pageSize = 100;
        this.rows = 50;
        this.categoryLinkForm = this._FormBuilder.group({
            comments: [''],
            detailSubScreen: [''],
            detailText: [''],
            fromDate: [new Date()],
            imageTwoUrl: [''],
            imageUrl: [''],
            linkInfo: [''],
            linkTypeEnumId: [''],
            productCategoryId: [''],
            sequenceNum: [''],
            thruDate: [new Date()],
            titleText: ['']
        });
        this.editModeLink = false;
        this.categoryLinks = [];
        this.linkSeqId = '';
        this.categoryContentForm = this._FormBuilder.group({
            contentId: ['', [Validators.required]],
            fromDate: [''],
            thruDate: [''],
            prodCatContentTypeId: [''],
            productCategoryId: [''],
            purchaseFromDate: [new Date()],
            purchaseThruDate: [new Date()],
            useCountLimit: [''],
            useDaysLimit: ['']
        });
        this.editModeContent = false;
        this.prodCatContentType = [];
        this.categoryContents = [];
        this.prodCatTypeId = '';
        this.isProdCatTypeId = true;
        this.contentIds = [];
        this.categoryProductForm = this._FormBuilder.group({
            comments: [''],
            fromDate: [new Date(), [Validators.required]],
            productCategoryId: [''],
            productId: ['', [Validators.required]],
            quantity: [''],
            sequenceNum: [''],
            thruDate: [new Date()]
        });
        this.editModeProduct = false;
        this.copyProductCategoryMemberForm = this._FormBuilder.group({
            productCategoryIdTo: ['', [Validators.required]],
            recurse: [''],
            validDate: ['']
        });
        this.expireProductMember = new Date();
        this.removeExpiredProductMember = new Date();
        this.isActiveProductMember = false;
        this.categoryFeatureGroups = [];
        this.createCategoryFeatureGroupForm = this._FormBuilder.group({
            fromDate: [new Date()],
            productFeatureGroupId: ['', [Validators.required]],
            thruDate: [new Date()]
        });
        this.editModeCategoryFeatureGroup = false;
        this.categoryFeatureGroupApply = [];
        this.categoryToFeatureForm = this._FormBuilder.group({
            fromDate: [''],
            productCategoryId: [''],
            productFeatureCategoryId: ['', [Validators.required]],
            thruDate: ['']
        });
        this.categoryToFeatures = [];
        this.categoryFeature = [];
        this.categoryForm = this._FormBuilder.group({
            categoryImageUrl: [''],
            categoryName: [''],
            description: [''],
            detailScreen: [''],
            linkOneImageUrl: [''],
            linkTwoImageUrl: [''],
            primaryParentCategoryId: [''],
            productCategoryId: [''],
            productCategoryTypeId: ['', [Validators.required]]
        });
        this.editMode = false;
        this.categories = [];
        this.fileType = '';
        this.fileData = new FormData();

        this.catalogForm = this._FormBuilder.group({
            prodCatalogId: [''],
            prodCatalogCategoryTypeId: [''],
            fromDate: [''],
            thruDate: [''],
            sequenceNum: [''],
        });
        this.partyForm = this._FormBuilder.group({
            partyId: [''],
            roleTypeId: [''],
            fromDate: [''],
            thruDate: [''],
       
        });
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {

        this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
          this.Hidebtn=false;
        }
        else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
          this.Hidebtn=true;
        }
        else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
          this.Hidebtn=true;
        }
        this.categoryId = this._ActivatedRoute.snapshot.params.categoryId;
        this.showNew=true;
        this.hideInput=false;
        this.categoryAttributeForm.controls.productCategoryId.patchValue(this.categoryId);
        this.categoryAttributeForm.controls.productCategoryId.disable();
        this.categoryAttributeForm.controls.productCategoryId.updateValueAndValidity();
        this._ActivatedRoute.data.subscribe(data => {
            this.category = data.category[0]; this.categoryAttributes = data.category[1];
            this.linkTypeEnum = data.category[2];
            this.categoryLinks = data.category[3]; const prodCatContentType = data.category[4];
            prodCatContentType.forEach(element => {
                this.prodCatContentType.push({
                    label: element.prodCatContentTypeId,
                    value: element.prodCatContentTypeId
                })
            })
            this.categoryContents = data.category[5];
            this.categoryProducts = data.category[6];
            if (data.category[6].length) {
                this.categoryProductIds = data.category[6].map(product => {
                    return {
                        label: product.productId,
                        value: product.productId
                    };
                });
            }
        });
        this.getContentId(); this.getAllCategories(); this.getFeatureCategoryGrpApply(); this.getCategoryToFeatureList();
        this.getCategoryFeature();
        this.getProductList();
        this.getProductFeatureGroup();
        this.productCategoryList();

        this.categoryId = this._ActivatedRoute.snapshot.params.categoryId;
        if (this.categoryId) {
            this.show = true;
        }
        else {
            this.show = false;
        }

        if (this.categoryId) {
            this.editMode = true;


            this.categoryForm.controls.productCategoryId.disable();
            this.categoryForm.controls.productCategoryId.updateValueAndValidity();


        }
        this.getCategoryId();
        this.getCategoryList();
        this.EditCategoryProdCatalogs();
        this.prodCatalogCategoryTypeId();
        this.prodCatalogId();
        this.getCategoryIdNew();
        this.getProductCategoryToCategory();
        this.getProductCategoryToCategorySecond();
        this.getRoleTypeId();
        this.getPartyId();
        this.getProductCategoryById();

    }
    getProductCategoryById() {
        this.spinner.show();
        this.mergeContactsService.getProductCategoryId(this.categoryId).subscribe(res => {
            this.productCategoryIdData = res.data[0].data;
            this.spinner.hide();

        })
    }
    updateParty(product) {
        this.spinner.show();
        this.showParty = true;
        this.ProductParty = product;
        setTimeout(() => {
            const formValue = this.partyForm;
            formValue.patchValue({
                partyId: this.ProductParty.partyId,
                roleTypeId:this.ProductParty.roleTypeId,
                fromDate:this.datepipe.transform(this.ProductParty.fromDate,"yyyy-MM-dd") ,
                thruDate:this.datepipe.transform(this.ProductParty.thruDate, "yyyy-MM-dd") , 

            })
        }, 1000);
        this.spinner.hide();
    }
    createPartyToCategory(): void {
        this.spinner.show();
        const requestData = {
            "partyId":this.partyForm.value.partyId,
            "roleTypeId":this.partyForm.value.roleTypeId,
            "fromDate": this.datepipe.transform(this.partyForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
            "productCategoryId": this.categoryId,
        }
        this.mergeContactsService.createPartyToCategory(requestData).subscribe((res: any) => {
            if (res.success) {
                this.toastr.success("Created Successfully");
                this.spinner.hide();
                this.closebutton3.nativeElement.click();
                this.partyForm.reset();
                this.getProductCategoryById();

            }
        }, (err) => {
            this.spinner.hide();
            if (err.error.errors) {
                for (const value of err.error.errors) {
                    this.toastr.error(value.fieldName + ' ' + value.fieldError);
                }
            }
            else {
                this.toastr.error(err.error.message);
            }
        });
    }
    updateProdCatalogToParty(): void {
        this.spinner.show();
        const requestData = {
            "partyId":this.partyForm.value.partyId,
            "roleTypeId":this.partyForm.value.roleTypeId,
            "fromDate": this.datepipe.transform(this.partyForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
            "productCategoryId": this.categoryId,
            "thruDate": this.datepipe.transform(this.partyForm.value.thruDate, "yyyy-MM-dd hh:mm:ss"),
        }
        this.mergeContactsService.updateProdCatalogToParty(requestData).subscribe((res: any) => {
            if (res.success) {
                this.toastr.success("Updated Successfully");
                this.spinner.hide();
                this.closebutton3.nativeElement.click();
                this.partyForm.reset();
                this.getProductCategoryById();

            }
        }, (err) => {
            this.spinner.hide();
            if (err.error.errors) {
                for (const value of err.error.errors) {
                    this.toastr.error(value.fieldName + ' ' + value.fieldError);
                }
            }
            else {
                this.toastr.error(err.error.message);
            }
        });
    }
    getRoleTypeId() {
        this.spinner.show();
        this.accountsService.getParty().subscribe(res => {
          this.roleTypeId = res.data.roleType;
          this.spinner.hide();
          for (const value of this.roleTypeId) {
            this.roleTypeIdArray.push({
              label: value.description,
              value: value.roleTypeId
            })
          }
        })
        
      }
      homeButton(){
        this.router.navigate(['/facilities/Dashboard'])
      
      }
      cancelSubmit(){
        this._location.back();
      }
      
    deletePartyFromCategory(product) {

        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it' 
        }).then((result) => {
            if (result.value) {
                const req = {
                    "partyId":  product.partyId ,
                    "roleTypeId":  product.roleTypeId ,
                    "fromDate":this.datepipe.transform(product.fromDate ,"yyyy-MM-dd hh:mm:ss") ,
                    "productCategoryId": product.productCategoryId ,
                  }
                this.mergeContactsService.deletePartyFromCategory(req).subscribe(res => {
                    this.getProductCategoryById();
                })
                Swal.fire(
                    'Deleted!',
                    'Deleted Successfully.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your data is safe :)',
                    'error'
                )
            }
        })


    }
    resetParty() {
        this.showParty = false;
        this.partyForm.reset();

      
    }
    getPartyId() {
        this.spinner.show();
        this.addSkillService.getPartyId().subscribe(res => {
          this.personId = res.data.PersonsGroups;
          this.spinner.hide();
          for (const value of this.personId) {
            this.partyIdArray.push({
              label: value.partyId,
              value: value.partyId
            })
          }
        })
        
      }
    resetForm(){
        this.showNew=true;
        this.readOnlyCode=false;
        this.hideInput=false;
        this.rollUpform.reset();
        this.rollUpChildform.reset();
      } 
      updateProductCategoryToCategoryS(): void {
        this.spinner.show();
        const requestData = {
            "fromDate": this.datepipe.transform(this.rollUpChildform.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
            "parentProductCategoryId": this.categoryId,
            "productCategoryId": this.rollUpChildform.value.parentCategoryID,
            "sequenceNum": this.rollUpChildform.value.sequenceNum,
            "thruDate": this.datepipe.transform(this.rollUpChildform.value.thruDate, "yyyy-MM-dd HH:mm:ss"),
        }
        this.accountsService.updateProductCategoryToCategoryS(requestData).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success("Updated Successfully");
            this.spinner.hide();
            this.getProductCategoryToCategorySecond();
            this.closebutton5.nativeElement.click();
            this.showNew=true;
    
          }
        }, (err) => {
          this.spinner.hide();
          if (err.error.errors) {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
          }
          else {
            this.toastr.error(err.error.message);
          }
        });
    }  
    updateProductCategoryToCategorySPatch(product) {
        this.showNew=false;
        this.readOnlyCode=true;
        this.hideInput=true;
        this.updateProductCategoryToCategorySPat = product.dataNode;
        this.spinner.show();
        const fromDateCatePatch=product.dataNode.fromDate;
        this.fromDateCatePatch=this.datepipe.transform(fromDateCatePatch,"yyyy-MM-dd");
        const throughDateCatePatch=product.dataNode.thruDate;
        this.throughDateCatePatch=this.datepipe.transform(throughDateCatePatch,"yyyy-MM-dd");
    
        setTimeout(() => {
          const formValue = this.rollUpChildform;
          formValue.patchValue({
                parentCategoryID: this.updateProductCategoryToCategorySPat.productCategoryId,
                fromDate: this.fromDateCatePatch,
                thruDate: this.throughDateCatePatch,
                sequenceNum: this.updateProductCategoryToCategorySPat.sequenceNum,
          })
    
        }, 1000);
        this.spinner.hide();
    }
    addProductCategoryToCategoryS(): void {
        this.spinner.show();
        const requestData = {
            "fromDate": this.datepipe.transform(this.rollUpChildform.value.fromDate, "yy-MM-dd HH:mm:ss"),
            "parentProductCategoryId": this.categoryId,
            "productCategoryId": this.rollUpChildform.value.parentCategoryID,
        }
        this.accountsService.addProductCategoryToCategoryS(requestData).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success("Created Successfully");
            this.spinner.hide();
            this.getProductCategoryToCategorySecond();
            this.rollUpChildform.reset();
            this.closebutton5.nativeElement.click();
          }
        }, (err) => {
          this.spinner.hide();
          if (err.error.errors) {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
          }
          else {
            this.toastr.error(err.error.message);
          }
        });
    }  
    removeProductCategoryFromCategory(product) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this data.!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            let req = {
                "parentProductCategoryId": product.dataNode.parentProductCategoryId,
                "productCategoryId": product.dataNode.productCategoryId,    
            }
            this.accountsService.removeProductCategoryFromCategory(req).subscribe((res: any) => {
              if (res.success) {
                this.getProductCategoryToCategory();
                this.getProductCategoryToCategorySecond();
              }
              else {
                this.toastr.error("Error");
              }
            })
            Swal.fire(
              'Deleted!',
              'Deleted Successfully.',
              'success'
            )
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your data is safe :)',
              'error'
            )
          }
        })
    }
    updateProductCategoryToCategory(): void {
        this.spinner.show();
        const requestData = {
            "_rowCount": "",
            "fromDate": this.datepipe.transform(this.rollUpform.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
            "parentProductCategoryId": this.rollUpform.value.parentCategoryID,
            "productCategoryId": this.categoryId,
            "sequenceNum": this.rollUpform.value.sequenceNum,
            "showProductCategoryId": this.categoryId,
            "thruDate": this.datepipe.transform(this.rollUpform.value.thruDate, "yyyy-MM-dd HH:mm:ss"),
        }
        this.accountsService.updateProductCategoryToCategory(requestData).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success("Updated Successfully");
            this.spinner.hide();
            this.getProductCategoryToCategory();
            this.closebutton4.nativeElement.click();
            this.showNew=true;
    
          }
        }, (err) => {
          this.spinner.hide();
          if (err.error.errors) {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
          }
          else {
            this.toastr.error(err.error.message);
          }
        });
    }
    getProductCategoryToCategoryPatch(product) {
        this.showNew=false;
        this.readOnlyCode=true;
        this.hideInput=true;
        this.getProductCategoryToCategoryPat = product.dataNode;
        this.spinner.show();
        const fromDateCatePatch=product.dataNode.fromDate;
        this.fromDateCatePatch=this.datepipe.transform(fromDateCatePatch,"yyyy-MM-dd");
        const throughDateCatePatch=product.dataNode.thruDate;
        this.throughDateCatePatch=this.datepipe.transform(throughDateCatePatch,"yyyy-MM-dd");
    
        setTimeout(() => {
          const formValue = this.rollUpform;
          formValue.patchValue({
                parentCategoryID: this.getProductCategoryToCategoryPat.parentProductCategoryId,
                fromDate: this.fromDateCatePatch,
                thruDate: this.throughDateCatePatch,
                sequenceNum: this.getProductCategoryToCategoryPat.sequenceNum,
          })
    
        }, 1000);
        this.spinner.hide();
    }
    addProductCategoryToCategory(): void {
        this.spinner.show();
        const requestData = {
            "fromDate": this.datepipe.transform(this.rollUpform.value.fromDate, "yy-MM-dd HH:mm:ss"),
            "parentProductCategoryId": this.rollUpform.value.parentCategoryID,
            "productCategoryId": this.categoryId,
            "showProductCategoryId": this.categoryId
        }
        this.accountsService.addProductCategoryToCategory(requestData).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success("Created Successfully");
            this.spinner.hide();
            this.getProductCategoryToCategory();
            this.rollUpform.reset();
            this.closebutton4.nativeElement.click();
           
          }
        }, (err) => {
          this.spinner.hide();
          if (err.error.errors) {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
          }
          else {
            this.toastr.error(err.error.message);
          }
        });
    }
    getProductCategoryToCategory() {
        this.spinner.show();
        this.accountsService.getProductCategoryToCategory(this.categoryId).subscribe(res => {
          this.ProductCategoryToCategory = res.data;
          this.spinner.hide();
        })
    }
    getProductCategoryToCategorySecond() {
        this.spinner.show();
        this.accountsService.getProductCategoryToCategorySecond(this.categoryId).subscribe(res => {
          this.productCategoryToCategorySecond = res.data;
          this.spinner.hide();
        })
    }
    getCategoryIdNew() {
        this.spinner.show();
        this.accountsService.getCategoryId().subscribe(res => {
          this.allCategoryId = res.data[0].dataNode;
          this.spinner.hide();
          for (const value of this.allCategoryId) {
            this.allCategoryIdArray.push({
              label: value.productCategoryId,
              value: value.productCategoryId
            })
          }
        })
      }

    catalog_Page(id) {
        this.router.navigate(["/product-management/catalogs/detail-catalog-page"], 
        { queryParams: { prodCatalogId: id } })
      }
    category_removeProductCategoryFromProdCatalog(product) {

        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                const req = {
                    "fromDate":this.datepipe.transform(product.fromDate ,"yyyy-MM-dd hh:mm:ss") ,
                    "isDelete": "",
                    "prodCatalogCategoryTypeId": product.prodCatalogCategoryTypeId ,
                    "prodCatalogId":  product.prodCatalogId ,
                    "productCategoryId": product.productCategoryId ,
                  }
                this.mergeContactsService.category_removeProductCategoryFromProdCatalog(req).subscribe(res => {
                    this.EditCategoryProdCatalogs();
                })
                Swal.fire(
                    'Deleted!',
                    'Deleted Successfully.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your data is safe :)',
                    'error'
                )
            }
        })


    }
    EditCategoryProdCatalogs() {
        this.spinner.show();
        this.mergeContactsService.EditCategoryProdCatalogs(this.categoryId).subscribe(res => {
            this.EditCategoryProdCatalogsData = res.data[0].data;
            this.spinner.hide();

        })
    }
    prodCatalogId() {
        this.spinner.show();
        this.mergeContactsService.prodCatalogId().subscribe(res => {
            this.ProdCatalogId = res.data;
            console.log("ProdCatalogId value",this.ProdCatalogId);
            this.spinner.hide();
            for (const value of this.ProdCatalogId) {
                this.ProdCatalogIdArray.push({
                    label: value.description,
                    value: value.value
                })
            }
        })
    }
    prodCatalogCategoryTypeId() {
        this.spinner.show();
        this.mergeContactsService.prodCatalogCategoryTypeId().subscribe(res => {
            this.catalogCategoryTypeId = res.data[0].data;
            this.spinner.hide();
            for (const value of this.catalogCategoryTypeId) {
                this.catalogCategoryTypeIdArray.push({
                    label: value.description,
                    value: value.prodCatalogCategoryTypeId
                })
            }
        })
    }
    resetCatalog() {
        this.showCatalog = false;
        this.catalogForm.reset();

      
    }
    updateCatalog(product) {
        this.spinner.show();
        this.showCatalog = true;

        this.ProductCatalog = product;

        setTimeout(() => {
            const formValue = this.catalogForm;
            formValue.patchValue({
                prodCatalogId: this.ProductCatalog.prodCatalogId,
                prodCatalogCategoryTypeId: this.ProductCatalog.prodCatalogCategoryTypeId,
                fromDate:this.datepipe.transform( this.ProductCatalog.fromDate,"yyyy-MM-dd") ,
                thruDate:this.datepipe.transform(this.ProductCatalog.thruDate, "yyyy-MM-dd") , 
                sequenceNum: this.ProductCatalog.sequenceNum,

            })
        }, 1000);
        this.spinner.hide();
    }
    category_addProductCategoryToProdCatalog(): void {
        this.spinner.show();
        const requestData = {
            "fromDate": this.datepipe.transform(this.catalogForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
            "isDelete": "",
            "prodCatalogCategoryTypeId": this.catalogForm.value.prodCatalogCategoryTypeId,
            "prodCatalogId": this.catalogForm.value.prodCatalogId,
            "productCategoryId": this.categoryId,
            "sequenceNum": this.catalogForm.value.sequenceNum,
        }
        this.mergeContactsService.category_addProductCategoryToProdCatalog(requestData).subscribe((res: any) => {
            if (res.success) {
                this.toastr.success("Created Successfully");
                this.spinner.hide();
                this.closebutton2.nativeElement.click();
                this.catalogForm.reset();
                this.EditCategoryProdCatalogs();

            }
        }, (err) => {
            this.spinner.hide();
            if (err.error.errors) {
                for (const value of err.error.errors) {
                    this.toastr.error(value.fieldName + ' ' + value.fieldError);
                }
            }
            else {
                this.toastr.error(err.error.message);
            }
        });
    }
    category_updateProductCategoryToProdCatalog(): void {
        this.spinner.show();
        const requestData = {
            "fromDate": this.datepipe.transform(this.catalogForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
            "isDelete": "",
            "prodCatalogCategoryTypeId": this.catalogForm.value.prodCatalogCategoryTypeId,
            "prodCatalogId": this.catalogForm.value.prodCatalogId,
            "productCategoryId": this.categoryId,
            "sequenceNum": this.catalogForm.value.sequenceNum,
            "thruDate": this.datepipe.transform(this.catalogForm.value.thruDate, "yyyy-MM-dd hh:mm:ss"),
        }
        this.mergeContactsService.category_updateProductCategoryToProdCatalog(requestData).subscribe((res: any) => {
            if (res.success) {
                this.toastr.success("Updated Successfully");
                this.spinner.hide();
                this.closebutton2.nativeElement.click();
                this.catalogForm.reset();
                this.EditCategoryProdCatalogs();

            }
        }, (err) => {
            this.spinner.hide();
            if (err.error.errors) {
                for (const value of err.error.errors) {
                    this.toastr.error(value.fieldName + ' ' + value.fieldError);
                }
            }
            else {
                this.toastr.error(err.error.message);
            }
        });
    }
    productCategoryList(): void {
        this.spinner.show();
        this.categoryService.productCategoryTypeList().subscribe(res => {
            this.data = res.data;
            this.spinner.hide();
            this.categoryArray = res.data.map(value => {
                return {
                    label: value.description,
                    value: value.productCategoryTypeId
                };
            });

        });

    }
    onSubmit(): void {
        this.spinner.show();
        if (this.editMode) {
            if (this.categoryForm.valid) {
                this.onUpdate();
            } else {
                this.spinner.hide();
                this.toastr.error('Please select product category type');
            }
        } else {
            if (this.categoryForm.valid) {
                this.categoryService.createCategory({
                    ...this.categoryForm.value, ...{
                        productCategoryId: this.categoryForm.value.productCategoryId ? this.categoryForm.value.productCategoryId : ''
                    }
                }).subscribe((res: any) => {
                    if (res.success) {
                        this.toastr.success(res.data.successMessage);
                        this.spinner.hide();
                        this.categoryForm.reset();
                        this.closebutton.nativeElement.click();
                    }
                    else {
                        this.spinner.hide();
                        this.toastr.error(res.message);
                    }
                });
            } else {
                this.spinner.hide();
                this.toastr.error('Please select product category type');
            }
        }
    }
    onUpdate(): void {
        this.spinner.show();
        this.categoryService.updateCategory({
            ...this.categoryForm.value, ...{
                productCategoryId: this.categoryId
            }
        }, this.categoryId).subscribe((res: any) => {
            if (res.success) {
                this.toastr.success(res.data.successMessage);
                this.spinner.hide();
                this.categoryForm.reset();
                this._ActivatedRoute.data.subscribe(data => {
                    this.category = data.category[0]; this.categoryAttributes = data.category[1];
                    this.linkTypeEnum = data.category[2];
                    this.categoryLinks = data.category[3]; const prodCatContentType = data.category[4];
                    prodCatContentType.forEach(element => {
                        this.prodCatContentType.push({
                            label: element.prodCatContentTypeId,
                            value: element.prodCatContentTypeId
                        })
                    })
                    this.categoryContents = data.category[5];
                    this.categoryProducts = data.category[6];
                    if (data.category[6].length) {
                        this.categoryProductIds = data.category[6].map(product => {
                            return {
                                label: product.productId,
                                value: product.productId
                            };
                        });
                    }
                });
                this.closebutton.nativeElement.click();
            }
            else {
                this.spinner.hide();
                this.toastr.error(res.message);
            }
        });

    }

    getCategoryId() {
        this.spinner.show();
        this.categoryService.getCategoryById(this.categoryId).subscribe(res => {

            setTimeout(() => {

                this.categoryForm.patchValue({
                    ...res.data, ...{
                        categoryName: res.data.categoryName,
                        productCategoryId: res.data.productCategoryId,
                        productCategoryTypeId: res.data.productCategoryTypeId,
                        description: res.data.description,
                        detailScreen: res.data.detailScreen,
                        linkOneImageUrl: res.data.linkOneImageUrl,
                        linkTwoImageUrl: res.data.linkTwoImageUrl,
                        primaryParentCategoryId: res.data.primaryParentCategoryId
                    }
                });
            }, 3000);
            this.spinner.hide();
        });
    }
    getCategoryList(): void {
        this.spinner.show();
        this._MainCatalogService.getCategories({ pageNo: 1, pageSize: 200 }, { categoryId: '', categoryIdSearchType: 'Contains', categoryName: '', categoryNameSearchType: 'Contains' })
            .then(data => {
                this.spinner.hide();
                this.categories = data.data;
            });

    }
    setImage(event: any): void {
        this.fileData.append('file', event.target.files[0]);
    }
    setCategoryImageType(fileType: string): void {
        this.fileType = fileType;
    }
    uploadImage(): void {
        this.spinner.show();
        if (this.fileType) {
            this.categoryService.uploadCategoryImage(this.categoryId, this.fileType, this.fileData)
                .then(data => {
                    if (data.success) {
                        this.spinner.hide();
                        this.toastr.success('Uploaded');
                        this.closebutton.nativeElement.click();
                        this.fileType = '';
                        this.fileData.delete('file');

                    }
                });
        }

    }
    activeTab(tab: number): void {
        this.activeCategory = tab;
    }

    getContentId(): void {
        this.spinner.show();
        this._CategoryService.getCatalogIdList()
            .then(data => {
                this.spinner.hide();
                if (data.success) {
                    this.contentIds = data.data.map(content => {
                        return {
                            label: content.contentId,
                            value: content.contentId
                        };
                    });
                }
            })

    }
    getProductList(): void {
        this.spinner.show();
        this._CategoryService.getAllProduct({ pageNo: 1, pageSize: 200 }, this.productListformValue)
            .then(data => {
                this.spinner.hide();
                this.productArray = data.data.map(content => {
                    return {
                        label: content.productName,
                        value: content.productId
                    };
                });

            });

    }
    makeAttributeEditable(attrDescription: string, attrName: string, attrValue: string): void {
        const attributeValue = { attrDescription, attrName, attrValue };
        this.categoryAttributeForm.patchValue({ ...attributeValue, ...{ productCategoryId: this.categoryId } });
        this.editModeAttribute = true;
        this.activeCategory = 3;
        this.categoryAttribute = attributeValue;

    }
    getAttribute(): void {
        this.spinner.show();
        this._CategoryService.getCategoryAttribute(this.categoryId)
            .then(data => {
                if (data.success) {
                    this.categoryAttributes = data.data;
                    this.spinner.hide();
                }
            });

    }
    deleteAttribute(attrName: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this._CategoryService.deleteCategoryAttribute(this.categoryId, attrName)
                    .then(data => {
                        if (data.success) {
                            this.getAttribute();
                        }
                    })
                    .catch(error => {
                        if (error.errors) {
                            error.errors.forEach(value => {
                                this._ToastrService.error(value.fieldName);
                            });
                        }
                    });
                Swal.fire(
                    'Deleted!',
                    'Deleted Successfully.',
                    'success'
                )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your data is safe :)',
                    'error'
                )
            }
        })
    }
    resetCategoryLinks(): void {
        this.spinner.show();
        this.categoryAttributeForm.controls['attrName'].reset()
        this.categoryAttributeForm.controls['attrValue'].reset()
        this.categoryAttributeForm.controls['attrDescription'].reset()
        this.editModeAttribute = false;
        this.spinner.hide();
    }
    submitAttribute(): void {
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
            this.spinner.show();
            if (this.editModeAttribute) {
                this._CategoryService.updateCreateCategoryAttribute({ ...this.categoryAttribute, ...this.categoryAttributeForm.value, ...{ productCategoryId: this.categoryId } })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Updated');
                            this.spinner.hide();
                            this.editModeAttribute = false;
                            this.categoryAttributeForm.controls['attrName'].reset()
                            this.categoryAttributeForm.controls['attrValue'].reset()
                            this.categoryAttributeForm.controls['attrDescription'].reset()
    
                            this.getAttribute();
                        }
                    })
                    .catch(error => {
                        this.spinner.hide();
                        if (error.errors) {
                            error.errors.forEach(value => {
                                this._ToastrService.error(value.fieldName);
                            });
                        }
                    });
    
            } else {
                this._CategoryService.createCategoryAttribute({
                    ...this.categoryAttributeForm.value, ...{
                        productCategoryId: this.categoryId
                    }
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.spinner.hide();
                            this.categoryAttributeForm.controls['attrName'].reset()
                            this.categoryAttributeForm.controls['attrValue'].reset()
                            this.categoryAttributeForm.controls['attrDescription'].reset()
    
                            this.getAttribute();
                        }
                    })
                    .catch(error => {
                        this.spinner.hide();
                        if (error.errors) {
                            error.errors.forEach(value => {
                                this._ToastrService.error(value.fieldName);
                            });
                        }
                    });
            }
        }
        else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
          this.toastr.error("Permission Denied"); 
        }
        else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
          this.toastr.error("Permission Denied");
        }


    }
    makeLinkEditable(linkSeqId: string): void {
        this.categoryLink = this.categoryLinks.filter(link => link.linkSeqId === linkSeqId);
        this.linkSeqId = linkSeqId;
        this.categoryLinkForm.patchValue({
            ...this.categoryLink[0], ...{
                fromDate: new Date(this.categoryLink[0].fromDate),
                thruDate: this.categoryLink[0].thruDate ? new Date(this.categoryLink[0].thruDate) : new Date()
            }
        });
        this.editModeLink = true;
        this.activeCategory = 4;
        this.categoryLinkForm.controls.fromDate.disable();
        this.categoryLinkForm.controls.fromDate.updateValueAndValidity();
    }
    getLink(): void {
        this.spinner.show();
        this._CategoryService.getCategoryLinks({ pageNo: 1, pageSize: 200 }, this.categoryId)
            .then(data => {

                if (data.success) {
                    this.categoryLinks = data.data;
                    this.spinner.hide();
                }
            });

    }
    deleteLink(fromDate: number, linkSeqId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this._CategoryService.deleteCategoryLink(moment(fromDate).format('YYYY-MM-DD HH:mm:ss.SSS'), linkSeqId, this.categoryId)
                    .then(data => {
                        if (data.success) {
                            this.spinner.hide(); this.getLink();
                        }
                    })
                    .catch(error => {
                        if (error.errors) {
                            error.errors.forEach(value => {
                                this._ToastrService.error(value.fieldName);
                            });
                        }
                    });
                Swal.fire(
                    'Deleted!',
                    'Deleted Successfully.',
                    'success'
                )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your data is safe :)',
                    'error'
                )
            }
        })
    }

    resetLinks() {
        this.categoryLinkForm.reset();

    }
    resetProduct() {
        this.categoryLinkForm.controls['fromDate'].reset()
        this.categoryLinkForm.controls['Comments'].reset()
    }
    submitLink(): void {
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
            this.spinner.show();
            if (this.editModeLink) {
    
                this._CategoryService.updateCategoryLink({
                    ...{
                        comments: this.categoryLinkForm.value.comments,
                        detailSubScreen: this.categoryLinkForm.value.detailSubScreen,
                        detailText: this.categoryLinkForm.value.detailText,
                        imageTwoUrl: this.categoryLinkForm.value.imageTwoUrl,
                        imageUrl: this.categoryLinkForm.value.imageUrl,
                        linkInfo: this.categoryLinkForm.value.linkInfo,
                        linkSeqId: this.linkSeqId,
                        linkTypeEnumId: this.categoryLinkForm.value.linkTypeEnumId,
                        sequenceNum: this.categoryLinkForm.value.sequenceNum,
                        titleText: this.categoryLinkForm.value.titleText,
                        productCategoryId: this.categoryId,
                        fromDate: this.categoryLink[0].fromDate,
                        thruDate: moment(this.categoryLinkForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss')
                    }
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.spinner.hide();
                            this.categoryLinkForm.reset();
                            this.categoryLinkForm.controls.fromDate.enable();
                            this.categoryLinkForm.controls.fromDate.updateValueAndValidity();
                            this.editModeLink = false;
                            this.getLink();
                            this.activeCategory = 5;
                        }
                    })
                    .catch(error => {
                        this.spinner.hide();
                        if (error.errors) {
                            error.errors.forEach(value => {
                                this._ToastrService.error(value.fieldName);
                            });
                        }
                    });
    
            } else {
                this._CategoryService.createCategoryLink({
                    ...this.categoryLinkForm.value, ...{
    
                        productCategoryId: this.categoryId,
                        fromDate: moment(this.categoryLinkForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                        thruDate: moment(this.categoryLinkForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss')
                    }
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.spinner.hide();
                            this.categoryLinkForm.reset();
                            this.getLink();
                        }
                    })
                    .catch(error => {
                        this.spinner.hide();
                        if (error.errors) {
                            error.errors.forEach(value => {
                                this._ToastrService.error(value.fieldName);
                            });
                        }
                    });
            }
        }
        else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
          this.toastr.error("Permission Denied"); 
        }
        else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
          this.toastr.error("Permission Denied");
        }


    }

    makeContentEditable(contentId, prodCatContentTypeId, fromDate, thruDate, useCountLimit, useDaysLimit, purchasefromdate, purchasethrougdate): void {
        this.editModeContent = true;
        this.purchaseFromdate = purchasefromdate;
        this.purchasethrougdate = purchasethrougdate;
        this.categoryContent = this.categoryContents.filter(content => content.contentId === contentId);
        this.categoryContentForm.patchValue({
            contentId: contentId,
            prodCatContentTypeId: prodCatContentTypeId,
            useCountLimit: useCountLimit,
            useDaysLimit: useDaysLimit,
            fromDate: new Date(fromDate),
            thruDate: new Date(thruDate),
        });


    }
    getContents(): void {
        this.spinner.show();
        this._CategoryService.getCategoryContent(this.categoryId)
            .then(data => {
                this.categoryContents = data.data;
            });
        this.spinner.hide();
    }
    deleteContent(prodCategoryTypeId: string, contentId: string, fromDate: string,): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this._CategoryService.deleteCategoryContent(this.categoryId, prodCategoryTypeId, contentId, moment(fromDate).format('YYYY-MM-DD HH:mm:ss'))
                    .then(data => {
                        if (data.success) {
                            this.getContents();
                        }
                    });

                Swal.fire(
                    'Deleted!',
                    'Deleted Successfully.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your data is safe :)',
                    'error'
                )
            }
        })
    }
    prepareContent(): void {
        this.isProdCatTypeId = false;
        this.categoryContentForm.patchValue({
            prodCatContentTypeId: this.prodCatTypeId
        });
    }
    resetPreparedContent(): void {
        this.isProdCatTypeId = true;
        this.categoryContentForm.reset();
    }
    submitContent(): void {
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
            this.spinner.show();
            if (this.editModeContent) {
                this._CategoryService.updateCategoryContent({
                    ...this.categoryContent[0], ...this.categoryContentForm.value, ...{
                        productCategoryId: this.categoryId,
                        purchaseFromDate: moment(this.purchaseFromdate).format('YYYY-MM-DD HH:mm:ss'),
                        purchaseThruDate: moment(this.purchasethrougdate).format('YYYY-MM-DD HH:mm:ss'),
                        fromDate: moment(this.categoryContentForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                        thruDate: moment(this.categoryContentForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss')
                    }
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Updated');
                            this.spinner.hide();
                            this.categoryContentForm.reset();
                            this.editModeContent = false;
                            this.getContents();
    
                        }
                    })
                    .catch(error => {
                        this.spinner.hide();
                        if (error.errors) {
                            error.errors.forEach(value => {
                                this._ToastrService.error(value.fieldName);
                            });
                        }
                    });
    
            } else {
                this._CategoryService.createCategoryContent({
                    ...this.categoryContentForm.value, ...{
                        productCategoryId: this.categoryId,
                        purchaseFromDate: moment(this.categoryContentForm.value.purchaseFromDate).format('YYYY-MM-DD HH:mm:ss'),
                        purchaseThruDate: moment(this.categoryContentForm.value.purchaseThruDate).format('YYYY-MM-DD HH:mm:ss'),
                        fromDate: moment(this.categoryContentForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                        thruDate: moment(this.categoryContentForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss')
                    }
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.categoryContentForm.reset();
                            this.spinner.hide();
                            this.isProdCatTypeId = true;
                            this.getContents();
                        }
                    })
                    .catch(error => {
                        this.spinner.hide();
                        if (error.errors) {
                            error.errors.forEach(value => {
                                this._ToastrService.error(value.fieldName);
                            });
                        }
                    });
    
            }
        }
        else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
          this.toastr.error("Permission Denied"); 
        }
        else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
          this.toastr.error("Permission Denied");
        }
    

    }

    submitProduct(): void {
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
            this.spinner.show();
            if (this.editModeProduct) {
                if (this.categoryProductForm.valid) {
    
                    this._CategoryService.updateCategoryProductMember({
                        ...{
                            productId: this.categoryProductForm.value.productId,
                            fromDate: moment(this.categoryProductForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                            comments: this.categoryProductForm.value.comments,
                            quantity: this.categoryProductForm.value.quantity,
                            sequenceNum: this.categoryProductForm.value.sequenceNum,
                            thruDate: moment(this.categoryProductForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss'),
                            productCategoryId: this.categoryId,
                        }
                    })
                        .then(data => {
                            if (data.success) {
                                this._ToastrService.success('Updated');
                                this.spinner.hide();
                                this.categoryProductForm.reset();
                                this.isActiveProductMember = true;
                                this.editModeProduct = false;
                                this.getProductMember(false);
                            }
                        })
                        .catch(error => {
                            this.spinner.hide();
                            if (error.errors) {
                                error.errors.forEach(value => {
                                    this._ToastrService.error(value.fieldName);
                                });
                            }
                        });
                }
    
            } else {
                if (this.categoryProductForm.valid) {
                    this._CategoryService.createCategoryProductMember({
                        productCategoryId: this.categoryId,
                        comments: this.categoryProductForm.value.comments,
                        fromDate: moment(this.categoryProductForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                        productId: this.categoryProductForm.value.productId
                    })
                        .then(data => {
                            if (data.success) {
                                this._ToastrService.success('Created');
                                this.spinner.hide();
                                this.categoryProductForm.reset();
                                this.isActiveProductMember = true;
                                this.getProductMember(false);
                            }
                        })
                        .catch(error => {
                            this.spinner.hide();
                            if (error.errors) {
                                error.errors.forEach(value => {
                                    this._ToastrService.error(value.fieldName);
                                });
                            }
                        });
                }
            }
        }
        else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
          this.toastr.error("Permission Denied"); 
        }
        else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
          this.toastr.error("Permission Denied");
        }


    }

    makeProductEditable(productId: string, fromDate, comment, quantity, sequence): void {
        this.editModeProduct = true;

        this.categoryProductForm.patchValue({
            ...this.categoryProduct, ...{
                productId: productId,
                fromDate: new Date(fromDate),
                thruDate: new Date(),
                comments: comment,
                quantity: quantity,
                sequenceNum: sequence
            }
        });

    }
    deleteProduct(fromDate: string, productId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this._CategoryService.deleteCategoryProductMember(this.categoryId, moment(fromDate).format('YYYY-MM-DD HH:mm:ss.SSS'), productId)
                    .then(data => {
                        if (data.success) {


                            this.getProductMember(false);
                        }
                    })
                    .catch(error => {
                        if (error.errors) {
                            error.errors.forEach(value => {
                                this._ToastrService.error(value.fieldName);
                            });
                        }
                    });
                Swal.fire(
                    'Deleted!',
                    'Deleted Successfully.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your data is safe :)',
                    'error'
                )
            }
        })


    }
    getProductMember(isActive: boolean): void {
        this.spinner.show();
        this.isActiveProductMember = isActive;
        this._CategoryService.getProducts(this.isActiveProductMember, this.categoryId)
            .then(data => {
                if (data.success) {
                    this.categoryProducts = data.data;
                    this.spinner.hide();
                }
            });

    }
    getAllCategories(): void {
        this.spinner.show();
        this._CategoryService.getCategories(
            { pageNo: 1, pageSize: 200 },
            { categoryId: '', categoryIdSearchType: 'Contains', categoryName: '', categoryNameSearchType: 'Contains' }
        )
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.categoryIds = data.data.map(category => {
                        return {
                            label: category.productCategoryId,
                            value: category.productCategoryId
                        };
                    });
                }
            });

    }
    submitCopyCategoryProduct(): void {
        this.spinner.show();
        if (this.copyProductCategoryMemberForm.valid) {
            this._CategoryService.copyCategoryProductMember(this.categoryId, this.copyProductCategoryMemberForm.value.productCategoryIdTo, this.copyProductCategoryMemberForm.value.recurse, moment(this.copyProductCategoryMemberForm.value.validDate).format('YYYY-MM-DD HH:mm:ss'))
                .then(data => {
                    if (data.success) {
                        this.spinner.hide();
                        this._ToastrService.success('Copied');
                        this.copyProductCategoryMemberForm.reset();
                        this.getProductMember(false);
                    }
                })
                .catch(error => {
                    this.spinner.hide();
                    if (error.errors) {

                        error.errors.forEach(value => {
                            this._ToastrService.error(value.fieldName);
                        });
                    }
                });
        }
        this.spinner.hide();
    }
    resetCopyCategoryProduct(): void {
        this.copyProductCategoryMemberForm.reset();
    }
    submitToExpireProductMember(): void {
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
            this.spinner.show();
            if (this.expireProductMember) {
                this._CategoryService.removeAllProductMember(this.categoryId, moment(this.expireProductMember).format('YYYY-MM-DD HH:mm:ss'))
                    .then(data => {
                        if (data.success) {
                            this.spinner.hide();
                            this._ToastrService.success('Expired');
                            this.expireProductMember = new Date();
                        }
                    })
                    .catch(error => {
                        this.spinner.hide();
                        if (error.errors) {
                            error.errors.forEach(value => {
                                this._ToastrService.error(value.fieldName);
                            });
                        }
                    });
            }
        }
        else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
          this.toastr.error("Permission Denied"); 
        }
        else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
          this.toastr.error("Permission Denied");
        }
  
    }
    submitToRemoveExpiredProductMember(): void {
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
            this.spinner.show();
            if (this.removeExpiredProductMember) {
                this._CategoryService.removeExpiredProductMember(this.categoryId, moment(this.removeExpiredProductMember).format('YYYY-MM-DD HH:M:ss'))
                    .then(data => {
                        if (data.success) {
                            this.spinner.hide();
                            this._ToastrService.success('Removed');
                            this.removeExpiredProductMember = new Date();
                        }
                    })
                    .catch(error => {
                        this.spinner.hide();
                        if (error.errors) {
                            error.errors.forEach(value => {
                                this._ToastrService.error(value.fieldName);
                            });
                        }
                    });
            }
        }
        else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
          this.toastr.error("Permission Denied"); 
        }
        else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
          this.toastr.error("Permission Denied");
        }

    }
    getFeatureCategoryGrpApply(): void {
        this.spinner.show();
        this._CategoryService.getCategoryFeatureGrpApply({ pageNo: 1, pageSize: 100 }, this.categoryId)
            .then(data => {
                if (data.success) {
                    this.categoryFeatureGroupApply = data.data;
                    this.spinner.hide();
                }
            });
    }
    getProductFeatureGroup(): void {
        this.spinner.show();
        this._CategoryService.getProductFeatureGroup()
            .then(data => {
                this.spinner.hide();
                this.categoryFeatureGroups = data.data.map(content => {
                    return {
                        label: content.description,
                        value: content.productFeatureGroupId
                    };
                });

            });

    }
    makeFeatureCategoryGroupEditable(fromDate, productFeatureGroupId: string, thruDate): void {
        this.categoryFeatureGroup = { productCategoryId: this.categoryId, fromDate, productFeatureGroupId, thruDate: thruDate ? new Date(thruDate) : '' };
        this.editModeCategoryFeatureGroup = true;
        this.createCategoryFeatureGroupForm.patchValue({ ...this.categoryFeatureGroup, ...{ fromDate: new Date(fromDate) } });
        this.createCategoryFeatureGroupForm.controls.fromDate.disable();
        this.createCategoryFeatureGroupForm.controls.fromDate.updateValueAndValidity();
        this.createCategoryFeatureGroupForm.controls.productFeatureGroupId.disable();
        this.createCategoryFeatureGroupForm.controls.productFeatureGroupId.updateValueAndValidity();
        this.activeCategory = 10;
    }
    deleteFeatureCategoryGroup(productFeatureGroupId: string, fromDate: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this._CategoryService.deleteProductFeatureGrpApply(this.categoryId, productFeatureGroupId, fromDate)
                    .then(data => {
                        if (data.success) {
                            this.getProductFeatureGroup();
                        }
                    });
                Swal.fire(
                    'Deleted!',
                    'Deleted Successfully.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your data is safe :)',
                    'error'
                )
            }
        })
    }
    submitFeatureCategoryGroup(): void {
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
            this.spinner.show();
            if (this.editModeCategoryFeatureGroup) {
                if (this.createCategoryFeatureGroupForm.valid) {
                    this._CategoryService.updateCategoryFeatureGrpApply({
                        ...this.categoryFeatureGroup, ...this.createCategoryFeatureGroupForm.value, ...{
                            fromDate: this.categoryFeatureGroup.fromDate,
    
                            thruDate: moment(this.createCategoryFeatureGroupForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss'),
                        }
                    })
                        .then(data => {
                            if (data.success) {
                                this._ToastrService.success('Updated');
                                this.spinner.hide();
                                this.getFeatureCategoryGrpApply();
                                this.createCategoryFeatureGroupForm.reset();
                                this.createCategoryFeatureGroupForm.controls.fromDate.enable();
                                this.createCategoryFeatureGroupForm.controls.fromDate.updateValueAndValidity();
                                this.createCategoryFeatureGroupForm.controls.productFeatureGroupId.enable();
                                this.createCategoryFeatureGroupForm.controls.productFeatureGroupId.updateValueAndValidity();
                                this.editModeCategoryFeatureGroup = false;
                                this.activeCategory = 11;
                            }
                        })
                        .catch(error => {
                            this.spinner.hide();
                            if (error.errors) {
                                error.errors.forEach(value => {
                                    this._ToastrService.error(value.fieldName);
                                });
                            }
                        });
                }
    
            } else {
                if (this.createCategoryFeatureGroupForm.valid) {
                    this._CategoryService.createCategoryFeatureGrpApply({
                        fromDate: moment(this.createCategoryFeatureGroupForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                        productFeatureGroupId: this.createCategoryFeatureGroupForm.value.productFeatureGroupId,
                        productCategoryId: this.categoryId
                    })
                        .then(data => {
                            if (data.success) {
                                this.getFeatureCategoryGrpApply();
                                this._ToastrService.success('Applied');
                                this.spinner.hide();
                                this.createCategoryFeatureGroupForm.reset();
                                this.activeCategory = 11;
                            }
                        })
                        .catch(error => {
                            this.spinner.hide();
                            if (error.errors) {
                                error.errors.forEach(value => {
                                    this._ToastrService.error(value.fieldName);
                                });
                            }
                        });
                }
            }
        }
        else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
          this.toastr.error("Permission Denied"); 
        }
        else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
          this.toastr.error("Permission Denied");
        }


    }
    resetFeatureCategoryGroup(): void {
        this.createCategoryFeatureGroupForm.reset();
        this.createCategoryFeatureGroupForm.controls.fromDate.enable();
        this.createCategoryFeatureGroupForm.controls.fromDate.updateValueAndValidity();
        this.createCategoryFeatureGroupForm.controls.productFeatureGroupId.enable();
        this.createCategoryFeatureGroupForm.controls.productFeatureGroupId.updateValueAndValidity();
        this.editModeCategoryFeatureGroup = false;
        this.activeCategory = 11;
    }
    getCategoryFeature(): void {
        this.spinner.show();
        this._CategoryService.getProductFeatureCategory(
            { pageNo: 1, pageSize: 100 },
            { description: '', descriptionSearchType: 'Contains', productFeatureCategoryId: '', productFeatureCategoryIdSearchType: 'Contains' }
        )
            .then(data => {
                this.spinner.hide();
                this.categoryFeature = data.data;
            });

    }
    getCategoryToFeatureList(): void {
        this.spinner.show();
        this._CategoryService.getApplyFeaturesToCategory({ pageNo: 1, pageSize: 200 }, this.categoryId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.categoryToFeatures = data.data;
                }
            });

    }
    deleteCategoryToFeature(categoryFeatureId: string, fromDate: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this._CategoryService.deleteCategoryToFeature(this.categoryId, categoryFeatureId, moment(fromDate).format('YYYY-MM-DD HH:mm:ss'))
                    .then(data => {
                        if (data.success) {
                            this.getCategoryToFeatureList();
                        }
                    });
                Swal.fire(
                    'Deleted!',
                    'Deleted Successfully.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your data is safe :)',
                    'error'
                )
            }
        })
    }
    makeCategoryToFeatureEditable(productFeatureCategoryId: string, fromDate: string, thruDate: string): void {
        this.editModeCategoryToFeature = true;



        this.categoryToFeature = { productFeatureCategoryId, productCategoryId: this.categoryId, fromDate: fromDate, thruDate: thruDate ? new Date(thruDate) : new Date() };
        this.categoryToFeatureForm.patchValue({
            ...this.categoryToFeature, ...{ fromDate: new Date(fromDate) }
        });
        this.categoryToFeatureForm.controls.fromDate.disable();
        this.categoryToFeatureForm.controls.fromDate.updateValueAndValidity();
        this.categoryToFeatureForm.controls.productFeatureCategoryId.disable();
        this.categoryToFeatureForm.controls.productFeatureCategoryId.updateValueAndValidity();
    }

    submitCategoryToFeature() {
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
            this.spinner.show();
            if (this.editModeCategoryToFeature) {
    
                this._CategoryService.updateApplyFeatureToCategory({
                    ...this.categoryToFeature, ...this.categoryToFeatureForm.value, ...{
                        fromDate: moment(this.categoryToFeature.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                        thruDate: moment(this.categoryToFeatureForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss'),
                        productCategoryId: this.categoryId
                    }
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Updated');
                            this.spinner.hide();
                            this.categoryToFeatureForm.reset();
                            this.categoryToFeatureForm.controls.formDate.enable();
                            this.categoryToFeatureForm.controls.formDate.updateValueAndValidity();
                            this.categoryToFeatureForm.controls.productFeatureCategoryId.enable();
                            this.categoryToFeatureForm.controls.productFeatureCategoryId.updateValueAndValidity();
                            this.editModeCategoryToFeature = false;
                            this.getCategoryToFeatureList();
                        }
                    })
                    .catch(error => {
                        this.spinner.hide();
                        if (error.errors) {
                            error.errors.forEach(value => {
                                this._ToastrService.error(value.fieldName);
                            });
                        }
                    });
    
            } else {
                if (this.categoryToFeatureForm.valid) {
                    this._CategoryService.createApplyFeatureToCategory({
                        fromDate: moment(this.categoryToFeatureForm.value.fromDate).format('YYY-MM-DD HH:MM:ss'),
                        productCategoryId: this.categoryId,
                        productFeatureCategoryId: this.categoryToFeatureForm.value.productFeatureCategoryId
    
                    })
                        .then(data => {
                            if (data.success) {
                                this._ToastrService.success('Created');
                                this.categoryToFeatureForm.reset();
                                this.spinner.hide();
                                this.getCategoryToFeatureList();
                            }
                        })
                        .catch(error => {
                            this.spinner.hide();
                            if (error.errors) {
                                error.errors.forEach(value => {
                                    this._ToastrService.error(value.fieldName);
                                });
                            }
                        });
                }
            }
        }
        else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
          this.toastr.error("Permission Denied"); 
        }
        else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
          this.toastr.error("Permission Denied");
        }


    }
    resetCategoryToFeature(): void {
        this.categoryToFeatureForm.reset();
    }
    attachedProductFeatureCategory(): void {
        this.spinner.show();
        this._CategoryService.attachedCategoryFeature(this.categoryId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.getProductFeatureGroup();
                }
            })
            .catch(error => {
                this.spinner.hide();
                if (error.errors) {
                    error.errors.forEach(value => {
                        this._ToastrService.error(value.fieldName);
                    });
                }
            });
    }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    changeactiveCategoryFunction(tab: number) {
        this.activeCategory = tab;
    }
    ngOnDestroy(): void {
        this.spinner.hide();
    }
}
