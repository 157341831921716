import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NewJobRequistionsService {

  constructor(readonly http:HttpClient) { }

  createJobRequistion(formData):Observable<any>{
    const jobRequistion = URLS.createJobRequisition;
    return this.http.post(jobRequistion,formData)
  }
  updateJobRequistion(formData,id):Observable<any>{
    const updateJobRequistion = URLS.updateJobRequisition + "?jobRequisitionId=" + id;
    return this.http.put(updateJobRequistion,formData)
  }
  getQualification():Observable<any> {
    const getQualification = URLS.getQualification;
    return this.http.get(getQualification);
  }
  getSkillTypeList():Observable<any>{
    const getSkillTypeList = URLS.getSkillTypeList;
    return this.http.get(getSkillTypeList);
  }
 

  
}
