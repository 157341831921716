import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {Router,ActivatedRoute} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe,Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-create-product-promotion-code',
  templateUrl: './create-product-promotion-code.component.html',
  styleUrls: ['./create-product-promotion-code.component.css']
})
export class CreateProductPromotionCodeComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  newPromotionCode: FormGroup;
  productPromoId: any;
  @Output() closeAccModal: EventEmitter<any> = new EventEmitter<any>();
  productPromoCodeId: any;
  updatePromotionCode: FormGroup;
  PromotionCodeListArray: any[]=[];
  yesNoArray: { label: string; value: string; }[];
  PromotionCodeList:any;
  constructor(readonly router:Router,
    private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly activatedRoute:ActivatedRoute,
    readonly MyContactsService:MyContactsService,
    readonly accountsService:AccountsService,
    readonly spinner: NgxSpinnerService,
    readonly toastr:ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly addSkillService: AddSkillService,
    readonly datePipe: DatePipe,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly _Router: Router,
    public bsModalRef: BsModalRef) {
      this.updatePromotionCode = this._FormBuilder.group({
        promoCodeID: [''],
        promoID: [''],
        userEntered: [''],
        reqEmailParty: [''],
        limitCode: [''],
        limitCust: [''],
        fromDate: [''],
        thruDate: [''],
        createdDate: [''],
        createdUser: [''],
        modifiyDate: [''],
        modifyUser: [''],
      });
      this.yesNoArray = [
        {
          label: "Y",
          value: "Y"
        },
        {
          label: "N",
          value: "N"
        },
      ]
     }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.productPromoCodeId = params["productPromoCodeId"];
     
    });
    setTimeout(() => {
      const form = this.updatePromotionCode;
      form.patchValue({
        promoCodeID :   this.productPromoCodeId
      })
    }, 1000);
   
    this.getPromotionCodeListNew();
  }

  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getPromotionCodeListNew() {
    this.spinner.show();
    this.myContactsService.getPromotionCodeListNew().subscribe(res => {
      this.PromotionCodeList = res.data;
      this.spinner.hide();
      for (const value of this.PromotionCodeList) {
        this.PromotionCodeListArray.push({
          label: value.promotionCodeList.productPromoId ,
          value: value.promotionCodeList.productPromoId
        })
      }
    })
  }
  updateProductCode(): void {
    this.spinner.show();
    const requestData = {
      "createdByUserLogin":  this.updatePromotionCode.value.createdUser,
      "createdDate":  this.datePipe.transform(this.updatePromotionCode.value.createdDate,"yyyy-MM-dd hh:mm:ss"),
      "fromDate":  this.datePipe.transform(this.updatePromotionCode.value.fromDate, "yyyy-MM-dd hh:mm:ss") ,
      "lastModifiedByUserLogin":  this.updatePromotionCode.value.modifyUser,
      "lastModifiedDate":  this.datePipe.transform(this.updatePromotionCode.value.modifiyDate, "yyyy-MM-dd hh:mm:ss"),
      "productPromoCodeId":  this.updatePromotionCode.value.promoCodeID,
      "productPromoId":  this.updatePromotionCode.value.promoID,
      "requireEmailOrParty":  this.updatePromotionCode.value.reqEmailParty,
      "thruDate":  this.datePipe.transform(this.updatePromotionCode.value.thruDate, "yyyy-MM-dd hh:mm:ss"),
      "useLimitPerCode":  this.updatePromotionCode.value.limitCode,
      "useLimitPerCustomer":  this.updatePromotionCode.value.limitCust,
      "userEntered":  this.updatePromotionCode.value.userEntered,
    }
    this.myContactsService.updateProductCode(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.updatePromotionCode.reset();
        // this._Router.navigate(["facilities/catalog/promos/editPromo"],
        // { queryParams: { productPromoId: this.updatePromotionCode.value.promoID , activeCategory:'7' } })
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }


}
