import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { OrderManagementService } from '../order-management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-notes',
  templateUrl: './create-notes.component.html',
  styleUrls: ['./create-notes.component.css']
})
export class CreateNotesComponent implements OnInit {
  products3: any;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  activeCategory=1
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ''
  };
  orderId: string;
  noteForm: FormGroup;
  isSales: boolean;


  array = [
    {
      label: 'Yes',
      value: 'Y'
    },
    {
      label: 'No',
      value: 'N'
    }
  ];
  noteId: string;
  editMode: boolean;
  constructor(
    readonly _OrderManagementService: OrderManagementService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    readonly _ToastrService: ToastrService,
    readonly  _Router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
  ) {
    this.orderId = '';
    this.noteForm = this._FormBuilder.group({
      internalNote: ['Y'],
      note: ['', [Validators.required]],
      orderId: ['']
    });
    this.noteId = '';
    this.editMode = false;
  }

  ngOnInit(): void {
    this.orderId = this._ActivatedRoute.snapshot.queryParams.orderId;
    this.noteId = this._ActivatedRoute.snapshot.queryParams.noteId;
    this.isSales = this._ActivatedRoute.snapshot.queryParams.isSales === 'true' ? true : false;
    if (this.noteId) {
      this.editMode = true;
    }
   
  }
  update(): void {
    this.spinner.show();
    if (this.editMode) {
      this._OrderManagementService.updateNote(this.noteId, {
        ...this.noteForm.value, ...{
          orderId: this.orderId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Note updated successfully');
            this.spinner.hide();
            this.editMode = false;
            this.navigation();
            this.noteForm.reset();
          }
        });
    }
    this.spinner.hide();
  }
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      if (this.noteForm.valid) {
        this._OrderManagementService.createNote({
          ...this.noteForm.value, ...{
            orderId: this.orderId
          }
        })
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Note created successfully');
              this.navigation();
              this.spinner.hide();
              this.noteForm.reset();
            }
          });
      }
    }
    this.spinner.hide();
  }
  navigation(): void {
    this.isSales ?
      this._Router.navigate(['/order/sales-order-view-page'], { queryParams: { orderId: this.orderId } }) :
      this._Router.navigate(['/order/purchase-order-view-page'], { queryParams: { orderId: this.orderId } });
  }
}