import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-work-effort-fixed-assets',
  templateUrl: './work-effort-fixed-assets.component.html',
  styleUrls: ['./work-effort-fixed-assets.component.css']
})
export class WorkEffortFixedAssetsComponent implements OnInit {
  activeCategory = 20;
  fixedAsset: any;
  updateFixedAsset: any;
  workEffortId: any;
  total = 0;
  @ViewChild('closebutton') closebutton;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  WorkEffortAttributeById:any[]=[]
  WorkEffortFixedAssetAssign: any;
  FixedAssetStatusList: any;
  FixedAssetStatusListArray: any[]=[];
  available: { label: string; value: string; }[];
  FixedAssetList: any;
  FixedAssetListArray: any[]=[];
  thrufromdate: string;
  actualfromdate: string;
  show: boolean;
  getEstimateFrom: string;
  thruEstimateFrom: string;
  updateFromDate: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor( public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly addSkillService: AddSkillService,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    private _location: Location,
    
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router){
      this.available=[{
        'label':'Available',
        'value':'WEFA_AVAILABLE'
      },
      {
        'label':'In-Use',
        'value':'WEFA_IN_USE'
      }]
    this.fixedAsset = this._FormBuilder.group({
      fixedAssetID: [''],
      fromDate: [''],
      throughDate: [''],
      statusID: [''],
      availability: [''],
      allocatedCost: [''],
      comments: [''],
    })
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params['workEffortId'];

    });
    this.getWorkEffortFixedAssetAssign();
    this.getFixedAssetStatusList();
    this.getFixedAssetList();
  }
  
  createWorkEffortFixedAssetAssign(): void {
    this.spinner.show();
    const fromActualdate=this.fixedAsset.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.fixedAsset.get('throughDate').value;
    this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "allocatedCost":this.fixedAsset.value.allocatedCost,
      "availabilityStatusId":this.fixedAsset.value.availability,
      "comments":this.fixedAsset.value.comments,
      "fixedAssetId": this.fixedAsset.value.fixedAssetID,
      "fromDate": this.actualfromdate,
      "statusId":  this.fixedAsset.value.statusID,
      "thruDate":this.thrufromdate,
      "workEffortId": this.workEffortId  
   
      }
     
    this.accountsService.createWorkEffortFixedAssetAssign(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getWorkEffortFixedAssetAssign(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    homeButton(){
       this._Router.navigate(['/psa/Dashboard'])
     
     }
     cancelSubmit(){
       this._location.back();
     }
     
    updateWorkEffortFixedAssetAssign(): void {
      this.spinner.show();
      const fromActualdate=this.fixedAsset.get('fromDate').value;
      this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const thruActualdate=this.fixedAsset.get('throughDate').value;
      this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={
        "allocatedCost":this.fixedAsset.value.allocatedCost,
        "availabilityStatusId":this.fixedAsset.value.availability,
        "comments":this.fixedAsset.value.comments,
        "fixedAssetId": this.fixedAsset.value.fixedAssetID,
        "fromDate":this.updateFromDate,
        "statusId":  this.fixedAsset.value.statusID,
        "thruDate":this.thrufromdate,
        "workEffortId": this.workEffortId  
     
        }
       
      this.accountsService.updateWorkEffortFixedAssetAssign(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.getWorkEffortFixedAssetAssign(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
  getFixedAssetList() {
    this.spinner.show();
    this.accountsService.getFixedAssetList().subscribe(res => {
      this.FixedAssetList = res.data[0].node;
      this.spinner.hide();
      for (const value of this.FixedAssetList) {
        this.FixedAssetListArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
    })
   
  }
  getFixedAssetStatusList() {
    this.spinner.show();
    this.accountsService.getFixedAssetStatusList().subscribe(res => {
      this.FixedAssetStatusList = res.data;
      this.spinner.hide();
      for (const value of this.FixedAssetStatusList) {
        this.FixedAssetStatusListArray.push({
          label: value.statusDescription,
          value: value.statusValue
        })
      }
    })
   
  }
  deleteWorkEffortFixedAssetAssign(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fixedAssetId":product.data.fixedAssetId,
          "fromDate":product.fromDate,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "workEffortId":this.workEffortId
        }
        this.accountsService.deleteWorkEffortFixedAssetAssign(req).subscribe(res => {
          this.getWorkEffortFixedAssetAssign(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  getWorkEffortFixedAssetAssign(){
    this.spinner.show();
   
    this.accountsService.getWorkEffortFixedAssetAssign(this.workEffortId).subscribe((res:any)=> {
      this.WorkEffortFixedAssetAssign = res.data;
      this.spinner.hide();
    
    })   
   }
   updateEvent(product){
    this.show=true;
    this.spinner.show();
    this.updateFromDate=product.fromDate;
   
      setTimeout(() => {
        const fromdate=product.data.fromDate;
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const thrudate=product.data.thruDate;
        this.thruEstimateFrom=this.datePipe.transform(thrudate,"yyyy-MM-dd");
        const formValue = this.fixedAsset;
        formValue.patchValue({
          fixedAssetID:product.data.fixedAssetId,
          fromDate: this.getEstimateFrom,
          throughDate:this.thruEstimateFrom,
          statusID: product.data.statusId,
          availability:product.data.availabilityStatusId,
          allocatedCost:product.data.allocatedCost,
          comments: product.data.comments,
        })
      
      }, 2000);
  
  }
  reset(){
    this.fixedAsset.reset();
    this.show=false;
  }
  addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  editWorkEffort1(){
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  summary(){
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"],{ queryParams: { workEffortId: this.workEffortId} })
  }

  childWorkEfforts(){
    this._Router.navigate(["/psa/work-effort/task-list-view/childWorkEfforts"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  partyAssigns(){
    this._Router.navigate(["/psa/work-effort/task-list-view/PartyAssigns"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  rates(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Rates"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  commEvents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/CommEvents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  shopLists(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ShopLists"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requests(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requests"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requirements(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requirements"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  quotes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Quotes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  orderHeaders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/OrderHeaders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  timeEntries(){
    this._Router.navigate(["/psa/work-effort/task-list-view/TimeEntries"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  notes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Notes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Contents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  products(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Products"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  reviews(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Reviews"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  keywords(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Keywords"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contactMechs(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ContactMechs"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  agreementApplics(){
    this._Router.navigate(["/psa/work-effort/task-list-view/AgreementApplics"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  fixedAssets(){
    this._Router.navigate(["/psa/work-effort/task-list-view/FixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  attributes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Attributes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  eventReminders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/EventReminders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
