import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AddSkillsService } from '../add-skills/add-skill-service';
import { AddSkillService } from '../add-skills/add-skill.service';
import { SkillService } from '../skills/skill.service';

@Component({
  selector: 'app-overview-skill',
  templateUrl: './overview-skill.component.html',
  styleUrls: ['./overview-skill.component.css']
})
export class OverviewSkillComponent implements OnInit {
  getSkillLists: any;
  activeCategory = 2;
  skillArray: any =[];
  personId: any;
  partyIdArray: any = [];
  updateId: any;
  show: boolean = false;
  total=0;
  rowNumber=0;
  pageNo:number = 1;
  pageSize= 100;
  rows = 50;
  skillList: any;
  add_skill_form: FormGroup;
  Skillid: any;
  rate: any;
  skill: any;
  year: any;

  constructor(readonly addSkillService:AddSkillService,
    readonly spinner:NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly activatedRoute:ActivatedRoute,
    public addSkillsService:AddSkillsService,
    readonly toastr:ToastrService,
    readonly router:Router,
    readonly skillService:SkillService,
    ) { 
      this.add_skill_form = this._FormBuilder.group({
        skillTypeId: [''],
        yearsExperience: [''],
        skillLevel: [''],
  
        rating: [''],
  
  
      });
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   
    this.activatedRoute.queryParams.subscribe(params=> [
      this.updateId = params["partyId"],
      this.Skillid=params["skid"],
      this.rate=params["rate"],
      this.skill=params["skill"],
      this.year=params["year"]
    ])
    if(this.updateId && this.Skillid) {
      this.show=true;
      setTimeout(() => {
        const formValue = this.add_skill_form;
      formValue.patchValue({
        partyId:this.updateId,
        skillTypeId: this.Skillid,
        yearsExperience: this.year,
        rating: this.rate,
        skillLevel: this.skill,
       
      })
      }, 3000);
     
    }
    else {
      this.show = false;
    }
  
    this.getPartyId();
    this.getSkillList();
  
  }
  
  getSkillList() {
    this.spinner.show();
    this.addSkillService.getSkillTypeList().subscribe(res=> {
      this.getSkillLists = res.data;
      this.spinner.hide();
      for(const value of this.getSkillLists) {
        this.skillArray.push({
          label:value.description,
          value:value.skillTypeId
        })
      }
     
    })
   
  }

  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res=> {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.personId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }

  onSubmit() {
    this.spinner.show();
    const req={
      rating: this.add_skill_form.value.rating,
      skillLevel: this.add_skill_form.value.skillLevel,
      skillTypeId: this.add_skill_form.value.skillTypeId,
      yearsExperience: this.add_skill_form.value.yearsExperience,
      partyId:this.updateId
    }
   
    this.addSkillService.createSkills(req).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this.toastr.success("Skill Subbmitted Successfully");
        this.router.navigate(['/hr/overview-hrm-main'],{ queryParams: { partyId: this.updateId } })
      }

      else if(res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    },(err) => {
      for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }
        this.spinner.hide();
   })

  }
back(){
  this.router.navigate(['/hr/hrm-main'],{ queryParams: { partyId: this.updateId } })
}
  updateSkills() {
    this.spinner.show();
    const req={
      rating: this.add_skill_form.value.rating,
      skillLevel: this.add_skill_form.value.skillLevel,
      skillTypeId: this.add_skill_form.value.skillTypeId,
      yearsExperience: this.add_skill_form.value.yearsExperience,
      partyId:this.updateId
    }
   
    this.addSkillService.updateSkills(req,this.updateId).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this.toastr.success("Skill Updated Successfully");
        this.router.navigate(['/hr/hrm-main'],{ queryParams: { partyId: this.updateId } })
      }

      else if(res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    },(err) => {
      for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }
        this.spinner.hide();
   })

  
}
ngOnDestroy(): void {
  this.spinner.hide();
    }
}

