import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from './../../header/header.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import * as moment from 'moment';
import { OrderManagementService } from '../order-management.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { BillingAccountService } from 'src/app/accounting/accounting-ap/billing-account/billing-account.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';


@Component({
  selector: 'app-find-order',
  templateUrl: './find-order.component.html',
  styleUrls: ['./find-order.component.css'],
})
export class FindOrderComponent implements OnInit {
  products3: any;
  activeCategory = 2;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  productListArray: any = [];
  orderListArray: any = [];
  partyIdListArray: any = [];
  orderTypeList: any = [];
  billingAccountArray: any = [];
  salesOrderListArray: any = [];
  statusListArray: any = [];
  containsBackOrder: any = [];
  productStoresListArray: any = [];
  userLoginArray: any = [];
  webSitesListArray: any = [];
  paymentStatusArray: any = [];
  viewed: any = [];
  moreFilters: any = [];
  countryList: any = [];
  shippingMethodsList: any = [];
  moreFiltersValue: any = [];
  findOrder: any = {};
  offerShowingLineData: any = [];
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: '',
  };
  searchOrderForm: FormGroup;
  constructor(
    readonly accountsService: AccountsService,
    readonly  _Router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _OrderManagementService: OrderManagementService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _BillingAccountService: BillingAccountService,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,
  ) {
    this.searchOrderForm = this._FormBuilder.group({
      billingAccountId: [''],
      correspondingPoId: [''],
      countryGeoId: [''],
      createdBy: [''],
      externalId: [''],
      filterInventoryProblems: [''],
      filterPOsOpenPastTheirETA: [''],
      filterPOsWithRejectedItems: [''],
      filterPartiallyReceivedPOs: [''],
      gatewayAvsResult: [''],
      gatewayScoreResult: [''],
      goodIdentificationIdValue: [''],
      goodIdentificationTypeId: [''],
      hasBackOrders: [''],
      hideFields: [''],
      includeCountry: [''],
      internalCode: [''],
      inventoryItemId: [''],
      isViewed: [''],
      lookupFlag: [''],
      maxDate: [''],
      minDate: [''],
      orderId: [''],
      orderName: [''],
      orderStatusId: [],
      orderTypeId: [''],
      orderWebSiteId: [''],
      partyId: [''],
      paymentStatusId: [''],
      productId: [''],
      productStoreId: [''],
      roleTypeId: [''],
      salesChannelEnumId: [''],
      serialNumber: [''],
      shipmentMethod: [''],
      showAll: ['Y'],
      softIdentifier: [''],
      userLoginId: ['']
    });
    this.findOrder = {
      billingAccountId: '',
      correspondingPoId: '',
      countryGeoId: '',
      createdBy: '',
      externalId: '',
      filterInventoryProblems: 'N',
      filterPartiallyReceivedPOs: 'N',
      filterPOsOpenPastTheirETA: 'N',
      filterPOsWithRejectedItems: 'N',
      gatewayAvsResult: '',
      gatewayScoreResult: '',
      goodIdentificationIdValue: '',
      goodIdentificationTypeId: '',
      hasBackOrders: '',
      hideFields: 'Y',
      includeCountry: 'Y',
      internalCode: '',
      inventoryItemId: '',
      isViewed: '',
      lookupFlag: 'Y',
      maxDate: '',
      minDate: '',
      orderId: '',
      orderName: '',
      orderTypeId: '',
      orderWebSiteId: '',
      partyId: '',
      paymentStatusId: '',
      productId: '',
      productStoreId: '',
      salesChannelEnumId: '',
      serialNumber: '',
      shipmentMethod: '',
      showAll: '',
      softIdentifier: '',
      userLoginId: '',
      roleTypeId: '',
      orderStatusId: []
    };
  }

  offerShowingLineColoumns = [
    { field: 'partyName', header: 'Party Name' },
    { field: 'orderId', header: 'Order Id' },
    { field: 'orderTypeId', header: 'Order Type' },
    { field: 'orderName', header: 'Order Name' },
    { field: 'status', header: 'Status' },
    { field: 'itemsOrdered', header: 'Items Ordered' },
    { field: 'itemsBackOrdered', header: 'Items Backordered' },
    { field: 'itemsReturned', header: 'Items Returned' },
    { field: 'orderDate', header: 'Order Date' },
    { field: 'remainingSubTotal', header: 'Remaining SubTotal' },
    { field: 'grandTotal', header: 'Grand Total' },
    { field: 'currencyUom', header: 'Currency Uom' },
    { field: 'survey', header: 'Survey' },
  ];
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails();
  
    this.containsBackOrder = this.accountsService.containsBackOrder();
    this.viewed = this.accountsService.viewed();
    this.moreFilters = this.accountsService.moreFilters();
    this.getProducts();
    this.getOrderListItems();
    this.getPartyIdList();
    this.getOrderType();
    this.getBillingAccount();
    this.getSalesChannelList();
    this.getStatus();
    this.getUserLoginId();
    this.getShipToCountry();
    this.getShippingMethods();
  }


  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/order/main-order']);
  }
  resetForm(){
    this.findOrder = {
      billingAccountId: '',
      correspondingPoId: '',
      countryGeoId: '',
      createdBy: '',
      externalId: '',
      filterInventoryProblems: 'N',
      filterPartiallyReceivedPOs: 'N',
      filterPOsOpenPastTheirETA: 'N',
      filterPOsWithRejectedItems: 'N',
      gatewayAvsResult: '',
      gatewayScoreResult: '',
      goodIdentificationIdValue: '',
      goodIdentificationTypeId: '',
      hasBackOrders: '',
      hideFields: 'Y',
      includeCountry: 'Y',
      internalCode: '',
      inventoryItemId: '',
      isViewed: '',
      lookupFlag: 'Y',
      maxDate: '',
      minDate: '',
      orderId: '',
      orderName: '',
      orderTypeId: '',
      orderWebSiteId: '',
      partyId: '',
      paymentStatusId: '',
      productId: '',
      productStoreId: '',
      salesChannelEnumId: '',
      serialNumber: '',
      shipmentMethod: '',
      showAll: '',
      softIdentifier: '',
      userLoginId: '',
      roleTypeId: '',
      orderStatusId: []
    };
  }

  getProducts(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, {
      internalName: '',
      internalNameSearchType: 'Contains',
      productId: '',
      productIdSearchType: 'Contains',
    }).then((data) => {
      this.spinner.hide();
      this.productListArray = data.data;
    });
   
  }

  getOrderListItems(): void {
    this.spinner.show();
    this._OrderManagementService.getOrderItems().then(data => {
      this.spinner.hide();
      this.orderListArray = data.data.goodIdentificationTypes;
      this.productStoresListArray = data.data.productStores;
      this.webSitesListArray = data.data.websites;
      this.paymentStatusArray = data.data.paymentStatusList;

     
    });
   
  }

  getPartyIdList(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups().then(data => {
      this.partyIdListArray = data.data.PersonsGroups;
      this.spinner.hide();
    });
   
  }

  getOrderType(): void {
    this.spinner.show();
    this.orderTypeList = this.accountsService.getOrderType();
    this.spinner.hide();
  }

  getBillingAccount(): void {
    this.spinner.show();
    this._BillingAccountService.getBillingAccounts({ pageNo: 1, pageSize: 200 }, {
      accountLimit: '',
      billingAccountId: '',
      billingAccountIdSearchType: 'Contains',
      description: '',
      descriptionSearchType: 'Contains',
      fromDate: '',
      thruDate: '',
    }).then(data => {
      this.billingAccountArray = data.data;
      this.spinner.hide();
    });
    
  }

  getSalesChannelList(): void {
    this.spinner.show();
    this.accountsService.getSalesOrderList().subscribe((res) => {
      this.salesOrderListArray = res.data;
      this.spinner.hide();
    });
  
  }

  getStatus(): void {
    this.spinner.show();
    let statusTypes = 'ORDER_STATUS';
    this.accountsService.getStatus(statusTypes).subscribe((res) => {
      this.statusListArray = res.data;
      this.spinner.hide();
    });
    
  }
  getUserLoginId(): void {
    this.spinner.show();
    this.accountsService.getUserLogin().subscribe((res) => {
      this.userLoginArray = res.data;
      this.spinner.hide();
    });
   
  }

  getShipToCountry(): void {
    this.spinner.show();
    this._GlobalResourceService.getCountryList().then(data => {
      this.spinner.hide();
      this.countryList = data.data.countryList.map(value => {
        return {
          uomId: value
        };
      });
    });
   
  }

  getShippingMethods(): void {
    this.spinner.show();
    this.accountsService.getShippingMethods().subscribe((res) => {
      const shippingMethods = res.data;
      this.spinner.hide();
      for (const value of shippingMethods) {
        this.shippingMethodsList.push({
          label: value.partyId + ' ' + value.shipmentMethodType,
          value: value.partyId + '@' + value.shipmentMethodTypeId,
        });
      }
    });
   
  }

  submit(): void {
    this.spinner.show();
    for (let key in this.moreFiltersValue) {
      if (this.moreFiltersValue[key]['id'] === 'Inventory problems') {
        this.findOrder.filterInventoryProblems = 'Y'
      }

      if (this.moreFiltersValue[key]['id'] === 'Purchase orders partially received') {
        this.findOrder.filterPartiallyReceivedPOs = 'Y'
      }

      if (this.moreFiltersValue[key]['id'] === 'Purchase orders open past their ETA') {
        this.findOrder.filterPOsOpenPastTheirETA = 'Y'
      }

      if (this.moreFiltersValue[key]['id'] === 'Purchase orders with rejected items') {
        this.findOrder.filterPOsWithRejectedItems = 'Y'
      }

    }

    this.findOrder.minDate = this.findOrder.minDate ? moment(this.findOrder.minDate).format('YYYY-MM-DD HH:mm:ss') : '',
      this.findOrder.maxDate = this.findOrder.maxDate ? moment(this.findOrder.maxDate).format('YYYY-MM-DD HH:mm:ss') : '',

      this.accountsService.findOrder(this.findOrder).subscribe((res) => {
        this.spinner.hide();
        this.offerShowingLineData = res.data.listOrders;
      });
      this.spinner.hide();
  }
}
