<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"
                    routerLink="/financial/{{agreementType}}/customer-agreeement">{{agreementType==='receiveable' ? 'Customer' :'Commission'}}
                    Agreement </span>
                <span class="color-black pl-1"> > New</span>
                <button class="btn btn-danger buttonclass"  (click)="reset()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Create {{agreementType==='receiveable' ? 'Customer' :'Commission'}} Agreement</a></li>
                
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="createCustomerAgreements">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Product ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="productList" optionLabel="productId"
                                                    filter="true" placeholder="Select" formControlName="productId">
                                                </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Party Id From<span
                                                    style="color:red">*</span></label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="PersonsAndPartyGroupsList" optionLabel="partyId"
                                                    filter="true" placeholder="Select" formControlName="partyIdFrom">
                                                </p-dropdown>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Party Id To<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="PersonsAndPartyGroupsList" optionLabel="partyId"
                                                filter="true" placeholder="Select" formControlName="partyIdTo">
                                            </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Role Type Id From<span
                                                    style="color:red">*</span></label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="RoleTypesList" optionLabel="description"
                                                    filter="true" placeholder="Select" formControlName="roleTypeIdFrom">
                                                </p-dropdown>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Role Type Id To</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="RoleTypesList" optionLabel="description"
                                                    filter="true" placeholder="Select" formControlName="roleTypeIdTo">
                                                </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Agreement Type ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="AgreementTypeList" optionLabel="agreementTypeId"
                                                    filter="true" placeholder="Select"
                                                    formControlName="agreementTypeId">
                                                </p-dropdown>
                                                <small class="text-danger"
                                                    *ngIf="!!createCustomerAgreements.controls.agreementTypeId.invalid && !!createCustomerAgreements.controls.agreementTypeId.touched">Agreement
                                                    Type ID is required</small>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Agreement Date</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" formControlName="agreementDate"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">From Date<span
                                                    style="color:red">*</span></label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" formControlName="fromDate"
                                                id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Through Date<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" formControlName="thruDate"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="examCreate Commission Agreement
                                            pleInputEmail1">Description<span
                                            style="color:red">*</span></label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="description"
                                                id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Text Data</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <textarea name="" id="exampleInputEmail1" formControlName="textData"
                                                class="form-control" cols="30" rows="10" placeholder="Enter Text"></textarea>
                                            </div>

                                        </div>
                                    </div>
                                    
                               
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -28%;"><button _ngcontent-cta-c128=""
                                        type="submit" (click)="submit()"
                                        class="btn btn-secondary submit-btn">Create</button>



                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 