<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
                <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Tracking Summary</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>

            <div class=" bg-white color-grey mt-2" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 102.5%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Summary</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a>Tracking Code</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                <a>Tracking Code Orders</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                <a>Tracking Code Visits</a>
                            </li>
                           
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategory==1" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                    <p class="h-text">Summary</p>
                                                    <span>
                                                        <button type="submit" style="margin: 0 !important;" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#updateTrackingPopup">Update</button>
                                                </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Tracking Code ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.trackingCodeId}}
                                                                        
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Description</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.description}}
                                                                        
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Comments</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.comments}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Tracking Code Type ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.trackingCodeTypeId}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Marketing Campaign ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.marketingCampaignId}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Default Product Catalog ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.prodCatalogId}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">From Date</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.getEstimateFrom}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Through Date</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.getEstimateTo}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Redirect URL</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.redirectUrl}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Override Logo URL</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.overrideLogo}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Override CSS URL</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.overrideCss}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Trackable Lifetime</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.trackableLifetime}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Billable Lifetime</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.billableLifetime}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Group ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.groupId}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Sub Group ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.EditCodeTypeId.subgroupId}}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==2" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Tracking Code</p>
                                                </div>
                                                <form class="w-100" [formGroup]="CreateTrackingCodeForm">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Tracking Code ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="TrackingCodeID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tracking Code ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Description
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="Description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Comments
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="Comments" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Tracking Code Type ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="TrackingCodeTypeID" optionlabel="label" [options]="TrackingCodeTypeIdArray" placeholder="Enter Tracking Code Type ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Marketing Campaign ID
                                                                        
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="MarketingCampaignID" optionlabel="label" [options]="MarketingCampaignIdsListArray" placeholder="Enter Marketing Campaign ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Default Product Catalog ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="DefaultProductCatalogID" optionlabel="label" [options]="ProductCatalogIdArray" placeholder="Enter Default Product Catalog ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">From Date
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" class="form-control" formControlName="FromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Through Date
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" class="form-control" formControlName="ThroughDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Redirect URL		
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="RedirectURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Redirect URL">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Override Logo URL	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="OverrideLogoURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Override Logo URL">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Override CSS URL	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="OverrideCSSURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Override CSS URL">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Trackable Lifetime	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="TrackableLifetime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Trackable Lifetime">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Billable Lifetime	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="BillableLifetime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Billable Lifetime">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Group ID	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="GroupID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Group ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Sub Group ID	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="SubGroupID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sub Group ID">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-10 col-10 main-submit-button">
                                                        <button (click)="updateTrackingCode()" type="submit" class="btn btn-secondary submit-btn">Save</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==3" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">List Tracking Code Order For Tracking Code ID = {{this.trackingCodeID}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==4" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">List Tracking Code Visits For Tracking Code ID ={{this.trackingCodeID}} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="updateTrackingPopup" tabindex="-1" role="dialog" aria-labelledby="updateTrackingPopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Tracking Code</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Update Tracking Code</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="CreateTrackingCodeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Tracking Code ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="TrackingCodeID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tracking Code ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="Description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="Comments" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Tracking Code Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="TrackingCodeTypeID" optionlabel="label" [options]="TrackingCodeTypeIdArray" placeholder="Enter Tracking Code Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Marketing Campaign ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="MarketingCampaignID" optionlabel="label" [options]="MarketingCampaignIdsListArray" placeholder="Enter Marketing Campaign ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Default Product Catalog ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="DefaultProductCatalogID" optionlabel="label" [options]="ProductCatalogIdArray" placeholder="Enter Default Product Catalog ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="FromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="ThroughDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Redirect URL		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="RedirectURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Redirect URL">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Override Logo URL	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="OverrideLogoURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Override Logo URL">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Override CSS URL	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="OverrideCSSURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Override CSS URL">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Trackable Lifetime	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="TrackableLifetime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Trackable Lifetime">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Billable Lifetime	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="BillableLifetime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Billable Lifetime">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Group ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="GroupID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Group ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sub Group ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="SubGroupID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sub Group ID">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button (click)="updateTrackingCode()" type="submit" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>