import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AccountsService } from '../../accounts/accounts.service'
import { SortEvent } from 'primeng/api';
import { HeaderService } from '../../../header/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MyContactsService } from './my-contacts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportToCsv } from 'export-to-csv';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CreateContactService } from '../create-contacts/reactive-form-service/create-contact-service';
import { CreateContactsService } from '../create-contacts/create-contacts.service';
import { Location } from '@angular/common';


declare var $: any;

@Component({
  selector: 'app-my-contacts',
  templateUrl: './my-contacts.component.html',
  styleUrls: ['./my-contacts.component.css']
})
export class MyContactsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  products3: any;
  total=0;
  partyId: any = [];
  public cols: any[];
  rows = 50;
  getContacts: any = [];
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ''
  };

  teleNumber: any;
  partyIdArray: any = [];
  getId: any;
  contactMechId: any;
  firstNameSearchType: any;
  lastNameSearchType: any;
  partyIds: any;
  partyIdSearchType: any;
  opportunityIdArray: any = [];
  pageSize: any;
  rowNumber: any;
  page: number;
  filterData: any = {};
  advanceSearch: {
    contactMechId: string;
    firstName: string;
    firstNameSearchType: string;
    lastName: string;
    lastNameSearchType: string;
    partyId: string;
    partyIdSearchType: string;
  };
  pageData: { page: number; limit: number; };
  relatedCompany: any;
  public relatedCompanyInformation: any;
  // pageSize:number;

  createUpdate: any;
  address1: string;
  address2: string;
  areaCode: string;
  city: string;
  contactListId: string;
  contactNumber: string;
  countryCode: string;
  countryGeoId: string;
  emailAddress: string;
  extension: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  stateProvinceGeoId: string;
  suffix: string;
  token: any;
  countryList: any;
  country: any = [];
  array: any = [];
  stateArray: any = [];
  public stateData: any = []
  str = '';
  selectedState: any;
  countryId: any;
  show: boolean = false;
  loading = true;
  contactList: any;
  contactListArray: any = [];
  gender: any = [];
  crmStatus: any;
  crmArray: any = [];
  activeCategories = 2;
  public selectCountryGeoId = '';
  activeCategory=3;


  constructor(
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly HeaderService: HeaderService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly _ToastrService: ToastrService,
    public createContact: CreateContactsService,
    readonly activatedRoute: ActivatedRoute, readonly toastr: ToastrService,
    public createContactService: CreateContactService,
    private _location: Location,
  ) {
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.advanceSearch = {
      contactMechId: '',
      firstName: '',
      firstNameSearchType: 'Contains',
      lastName: '',
      lastNameSearchType: 'Contains',
      partyId: '',
      partyIdSearchType: 'Contains'
    };
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.gender.push({
      "label": "Male",
      "value": "M"
    },
      {
        "label": "Female",
        "value": "F"
      })


    this.getCountryList();

    this.createContactService.create_contact_form = null;
    this.createContactService.preparePresonalInfoForm(this.createContactService.create_contact_form);
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params["updateId"];
    })

    this.getContactList();

    if (this.partyId !== null && this.partyId !== undefined) {
      this.getContactDetail();
      this.show = true;
    }
    else {
      this.show = false;
    }


    this.getStatusCrm();
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });


  
  
    this.allContacts();
  
  }
 
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }
  

  toQuotes()
  {
    this.router.navigate(['/order/quotations']);

  }
  toSalesOrder()
  {
    this.router.navigate(['/order/create-sales-order']);

  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.myContactsService.getSearchedContacts(this.advanceSearch)
      .subscribe(data => {
        if (data.success) {
          if (isPopUp) {
            $('#exampleModal').modal('hide');
          }
          this.spinner.hide();
          this.getContacts = data.data;
          for (const value of this.getContacts) {
            this.partyIdArray.push({
              label: value.partyId,
              value: value.partyId
            });
          }
        } else {
          this.spinner.hide();
          this._ToastrService.error(data.errors[0].fieldError);
        }
      }, (err) => {
        for (const value of err.error.errors) {
          this.spinner.hide();
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      });
      
  }
  resetFilter(): void {
    this.spinner.show();
    this.advanceSearch = {
      contactMechId: '',
      firstName: '',
      firstNameSearchType: 'Contains',
      lastName: '',
      lastNameSearchType: 'Contains',
      partyId: '',
      partyIdSearchType: 'Contains'
    };
    this.spinner.hide();
    this.allContacts();
   
  }


  public onSearch(event) {
    this.contact.search = (event.value);
   
    this.allContacts();
  }

  loadAllEmployees(event) {
    this.rows = event.rows;
    this.pageSize = (event.first) + this.rows;
    this.rowNumber = (event.first) + 1;
    this.page = (event.first / this.rows) + 1;
  

  }


  allContacts() {
    this.spinner.show();
    this.myContactsService.getContacts(this.contact).subscribe((res) => {
      this.spinner.hide();
      this.getContacts = res.data;
      for (const value of this.getContacts) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        });
      }
    });
   
  }


  toAccount()
  {
    this.router.navigate(['/crm/accounts']);
  }
  toContact()
  {
    this.router.navigate(['/crm/my-contacts']);

  }
  toOpportunity()
  {
    this.router.navigate(['/crm/opportunities']);

  }
  toActivities()
  {
    this.router.navigate(['/crm/events']);

  }
  toCases()
  {
    this.router.navigate(['/crm/Cases']);
  }

  changeactiveCategory(tab: number) {
    this.activeCategories = tab;
  }


  public onPageSize() {
  
    this.allContacts();

  }

  customSort(event: SortEvent) {
   
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  
  }
  contactsDetail(contactId) {
    this.router.navigate(['/crm/my-contacts/details-contact'], { queryParams: { id: contactId } });
  }

  updateData(eupdateId) {
    this.router.navigate(['/crm/my-contacts/create-contacts'], { queryParams: { updateId: eupdateId } })
  }

  export() {
    this.spinner.show();
    this.myContactsService.exportContacts().subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'contacts.xlsx';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })
  
  }

  onStatusChange(event) {
    this.partyIdSearchType = event;
  }

  onNameChange(event) {
    this.firstNameSearchType = event;
  }

  onLastNameChange(event) {
    this.lastNameSearchType = event;
  }

  getSearchedContacts() {
    this.spinner.show();
    const req = {
      'contactMechId': this.contactMechId ? this.contactMechId : '',
      'firstName': this.firstName ? this.firstName : '',
      'firstNameSearchType': this.firstNameSearchType ? this.firstNameSearchType : '',
      'lastName': this.lastName ? this.lastName : '',
      'lastNameSearchType': this.lastNameSearchType ? this.lastNameSearchType : '',
      'partyId': this.partyIds ? this.partyIds : '',
      'partyIdSearchType': this.partyIdSearchType ? this.partyIdSearchType : ''
    }
  
    this.myContactsService.getSearchedContacts(req).subscribe(res => {
      //not working api
      this.getContacts = res.data;
      this.relatedCompany = res.data.relatedCompanies[0].company;
      this.spinner.hide();
    })
  
  }

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  getStatusCrm() {
    this.spinner.show();
    this.createContact.getStatusCrm().subscribe(res => {
      this.crmStatus = res.data;
      this.spinner.hide();
      for (const value of this.crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
    
  }


  getContactDetail() {
    this.spinner.show();
    this.createContact.getContacts(this.partyId).subscribe(res => {
      this.spinner.hide();
      const form = this.createContactService.create_contact_form;
      this.countryId = res.data.address.countryId;
      form.patchValue({
        address1: res.data.address.address1,
        address2: res.data.address.address2,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        suffix: res.data.suffix,
        emailAddress: res.data.email.infoString,
        contactNumber: res.data.telenumber.contactNumber,
        areaCode: res.data.telenumber.areaCode,
        countryCode: res.data.telenumber.countryCode,
        countryGeoId: res.data.address.countryId.trim(),
        comments: res.data.comments,
        gender: res.data.gender,
        statusId: res.data.statusId,
        externalId: res.data.externalId,
        city: res.data.address.city,
        postalCode: res.data.address.postalCode,
        stateProvinceGeoId: res.data.stateId.trim()
      });
     
    })
   
  }
  getCountryList() {
    this.spinner.show();
    this.createContact.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })
      })
    })
   
  }
  getContactList() {
    this.spinner.show();
    this.createContact.getContactList().subscribe(res => {
      this.contactList = res.data;
      this.spinner.hide();
      for (const value of this.contactList) {
        this.contactListArray.push({
          label: value.description,
          value: value.contactListId
        })
      }
    })
   
  }

  onCountryChange(event) {
    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }
  onStateChange(event) {
    this.stateProvinceGeoId = event
  }
  getStateList() {
    this.spinner.show();
    this.createContact.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
   
  }
  onSubmit() {
    this.spinner.show();
    this.createContactService.create_contact_form.markAllAsTouched();
    const formValues = { ...this.createContactService.create_contact_form.value };
    this.createContact.createContact(formValues).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.router.navigate(['/crm/my-contacts'])
      } else if (res.success == false) {
        this.toastr.error(res.data.message);
        this.spinner.hide();
      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {
          this.spinner.hide();
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {
          this.spinner.hide();
          this.toastr.error(err.error.message);
        }
      }
    })
   
  }
  onUpdate() {
    this.spinner.show();
    const formValues = { ...this.createContactService.create_contact_form.value };
    this.createContact.updateContacts(formValues, this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Form Updated Successfully");
        this.spinner.hide();
        this.router.navigate(['/crm/my-contacts'])
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.debugMessage);
    })
  
  }
  generalInformation(firstName,lastName){
  
    if(firstName && lastName){
      this.activeCategory=3
    }
    else{
      this.toastr.error("First Name and Last Name is required");
    }
  }
  addressInfo(address,country,city,state,postal){
    if(address && country  && city  && state && postal){
      this.activeCategory=4
    }
    else{
      this.toastr.error("Address1,Country,City,State and Postal Code is required");
    }
  }
  phoneInformation(phone){
    if(phone){
      this.activeCategory=5
    }
    else{
      this.toastr.error("Phone Number is required");
    }
  }
 
  emailInformation(email){
    if(email){
      this.activeCategory=8
    }
    else{
      this.toastr.error("Email is required");
    }
  }
  generalInfo(firstName,lastName){

    if(firstName && lastName){
      this.activeCategory=3
    }
    else{
      this.toastr.error("Fill General Information.");
    }
  }
  addressInformation(address,country,city,state,postal){
    if(address && country  && city  && state && postal){
      this.activeCategory=4
    }
    else{
      this.toastr.error("Fill Address.");
    }
  }
  phoneInfo(phone){
    if(phone){
      this.activeCategory=5
    }
    else{
      this.toastr.error("Fill Phone Information.");
    }
  }
  emailInformationAdded(email){
    if(email){
      this.activeCategory=8
    }
    else{
      this.toastr.error("Fill Email Information.");
    }
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
