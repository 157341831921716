import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeSheetService } from '../timesheet/timesheet.service';
import { SortEvent } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { CreateTimesheetFormService } from '../timesheet/create-timesheet/create-timesheet-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detailed-timesheet-summary',
  templateUrl: './detailed-timesheet-summary.component.html',
  styleUrls: ['./detailed-timesheet-summary.component.css']
})
export class DetailedTimesheetSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  employeeDetails: any;
  timeSheetList: any;
  activeCategory=1;
  activeCategoryMain=2;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  public timeSheet = {
    pageSize:this.pageSize,
    pageNo  :this.pageNo
  }
  roleData: any;
  timeSheetId: any;
  show: boolean=false;
  userLoginArray: any[]=[];
  updateefromdate: string;
  updateetodate: string;
  statusList: any;
  statusArray: any[]=[];
  partyId: any;
  partyIdArray: any[]=[];
  fileUploadForm: FormGroup;
  accountData: any;
 
  accountArray: any=[];

  groupNameSearchType='Contains'
  contactMechTypeId:any='';
  groupName='';
  partyIds='';
  partyIdSearchType='Contains';
  timesheetid: any;

  roleArray: any=[];
  constructor(
    public createTimesheetFormService:CreateTimesheetFormService,
    readonly timeSheetService:TimeSheetService,
    public datePipe: DatePipe,
    readonly formBuilder:FormBuilder,      private _location: Location,
    readonly activatedRoute:ActivatedRoute,
    readonly toastr:ToastrService,
    readonly router: Router,
    readonly spinner:NgxSpinnerService,) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    // this.activatedRoute.queryParams.subscribe(params=> {
    //   this.employeeDetails = params["timesheetid"];
    // })
   

    this.activatedRoute.queryParams.subscribe(params=> {
      this.employeeDetails=params["timesheetid"];
    })
    if(this.employeeDetails) {
      this.show=true
      this.getTimesheetList();
    }
    else {
      this.show=false
    }
    this.getPartyId();
    this.getStatusList();
    this.getUserLoginId();
    this.createTimesheetFormService.create_timesheet_form=null;
    this.createTimesheetFormService.createTimesheetForm(this.createTimesheetFormService.create_timesheet_form);
    this.getTimesheetListNew();
    this.getRoleType();
    this.fileUploadForm = this.formBuilder.group({
      roleTypeId: [''],
      partyId:[''],
      
    });
 
    this.getRoleList();
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getRoleList() {
    this.spinner.show();
    this.timeSheetService.getRoleList().subscribe(res=> {
      this.partyId=res.data;
      for(const value of this.partyId) {
        this.roleArray.push({
          label:value.description,
          value:value.roleTypeId
        })
      }
    })
    this.spinner.hide();
  }
  onSubmit(){
    this.spinner.show();
  const formdata={
    "partyId": this.fileUploadForm.value.partyId,
    "roleTypeId": this.fileUploadForm.value.roleTypeId,
    "timesheetId": this.employeeDetails
  }
  
    this.timeSheetService.postRole(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.createTimesheetFormService.create_timesheet_form.reset();
        this.getRoleType();
      
      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }
  getUserLoginId(): void {
    this.spinner.show();
    this.timeSheetService.getUserLogin().subscribe((res) => {
      let userLogin = res.data;
      this.spinner.hide();
      for (const value of userLogin) {
        if (value["groupName"] === null) {
          value["groupName"] = "";
        }
        if (value["firstName"] === null) {
          value["firstName"] = "";
        }
        if (value["middleName"] === null) {
          value["middleName"] = "";
        }
        if (value["lastName"] === null) {
          value["lastName"] = "";
        }
        let name =
          value["userLoginId"] +
          " " +
          value["groupName"] +
          " [" +
          value["firstName"] +
          " " +
          value["middleName"] +
          " " +
          value["lastName"] +
          "]";
        this.userLoginArray.push({
          label: name,
          value: value.userLoginId,
        });
      }
    }); 
  
  }
  onUpdate(){
    this.spinner.show();
    let fromdate=this.createTimesheetFormService.create_timesheet_form.get('fromDate').value;
    this.updateefromdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");

    let todate=this.createTimesheetFormService.create_timesheet_form.get('thruDate').value;
    this.updateetodate=this.datePipe.transform(todate,"yyyy-MM-dd hh:mm:ss");
    let formData={
      approvedByUserLoginId: this.createTimesheetFormService.create_timesheet_form.value.approvedByUserLoginId,
      clientPartyId:this.createTimesheetFormService.create_timesheet_form.value.clientPartyId,
      comments: this.createTimesheetFormService.create_timesheet_form.value.comments,
      fromDate:  this.updateefromdate,
      partyId: this.createTimesheetFormService.create_timesheet_form.value.partyId,
      statusId: this.createTimesheetFormService.create_timesheet_form.value.statusId,
      thruDate: this.updateetodate,
    }
   
    this.timeSheetService.updateTimesheet(formData, this.employeeDetails).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.successMessage);
        this.closebutton.nativeElement.click();
        this.createTimesheetFormService.create_timesheet_form.reset();
        this.router.navigate(['/hr/timesheets'])
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }

   })
  

  }
  getStatusList(){
    this.spinner.show();
    this.timeSheetService.getStatusList().subscribe(res=> {
      this.statusList = res.data;
      this.spinner.hide();
      for(const data of this.statusList) {
        this.statusArray.push({
          label:data.description,
          value:data.statusId
        })
      }
    })

  }
  getPartyId() {
    this.spinner.show();
    this.timeSheetService.getPartyId().subscribe(res=> {
      this.partyId=res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.partyId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }
  getTimesheetList(){
    
    this.spinner.show();
    const timesheet = {
      approvedByUserId: "",
      approvedByUserIdSearchType: "Contains",
      clientPartyId: "",
      clientPartyIdSearchType: "Contains",
      comments: "",
      commentsSearchType: "Contains",
      fromDateFrom: "",
      fromDateFromSearchType: "Contains",
      fromDateTo: "",
      fromDateToSearchType: "Contains",
      partyId: "",
      statusId: "",
      statusIdSearchType: "Contains",
      throughDateFrom: "",
      throughDateTo: "",
      thruDateFromSearchType: "Contains",
      thruDateToSearchType: "Contains",
      timesheetId: this.employeeDetails,
      timesheetIdSearchType: "Contains"
    }
  this.timeSheetService.getApplicationTimesheetList(this.timeSheet,timesheet).subscribe(res=> {
    /* this.timeSheetList=res.data; */
    // this.timeSheetList=res.data[0];
    setTimeout(() => {
      const formValue =  this.createTimesheetFormService.create_timesheet_form;
      let date=this.datePipe.transform(res.data[0].fromDate,"yyyy-MM-dd");
      let Fromdate=this.datePipe.transform(res.data[0].thruDate,"yyyy-MM-dd");
    formValue.patchValue({
      partyId:res.data[0].partyId,
      clientPartyId:res.data[0].clientPartyId,
      statusId:res.data[0].statusId,
      comments: res.data[0].comments,
      approvedByUserLoginId: res.data[0].approvedByUserLoginId,
      fromDate:date,
      thruDate:Fromdate
     
    })
    }, 3000);
    this.spinner.hide();
   })
 
  }
  getTimesheetListNew(){
    this.spinner.show();
    const timesheet = {
      approvedByUserId: "",
      approvedByUserIdSearchType: "Contains",
      clientPartyId: "",
      clientPartyIdSearchType: "Contains",
      comments: "",
      commentsSearchType: "Contains",
      fromDateFrom: "",
      fromDateFromSearchType: "Contains",
      fromDateTo: "",
      fromDateToSearchType: "Contains",
      partyId: "",
      statusId: "",
      statusIdSearchType: "Contains",
      throughDateFrom: "",
      throughDateTo: "",
      thruDateFromSearchType: "Contains",
      thruDateToSearchType: "Contains",
      timesheetId:this.employeeDetails,
      timesheetIdSearchType: "Contains"
    }
  this.timeSheetService.getApplicationTimesheetList(this.timeSheet,timesheet).subscribe(res=> {
    this.timeSheetList=res.data[0];
    this.spinner.hide();
  })
 
  }

  createRole(){
    this.router.navigate(['/hr/timesheets/create-role'],{queryParams:{timesheetid:this.employeeDetails}})
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  getRoleType(){
    this.spinner.show();
  this.timeSheetService.getRole( this.employeeDetails).subscribe(res=> {
    this.roleData=res.data;
    this.spinner.hide();
  })

  }
  onDelete(timesheet,roletype,partyid) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.timeSheetService.deleteTimeSheetNew(timesheet,roletype,partyid).subscribe(res=> {
          this.getRoleType();
      },(err) => {
        this.toastr.error(err.error.message);
      })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    }) }
   changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
