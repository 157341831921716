<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Departments</span>

               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>


            </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <div class="port-header-contact create-lead font-medium mb-0" style="    background: #cce0e95e;
                            margin-top: 0%;border-bottom:#0d3769;">
                                        <div class="suppliers-wrapper" style="    margin-top: 13px;">
                                            <!--new code-->
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New Department</button>
                                            <!--new code end-->
                                            <!-- <button type="button" class="btn btn-outline-secondary" routerLinkActive="active" routerLink="/hr/departments/create-departments">Create New Department</button> -->

                                        </div>
                                    </div>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Departments List</label>

                                                <div class="card own-account-table">
                                                    <p-table [value]="departmentList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'250px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Department Name
                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                    <div style="color: white;"> Company
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Manager
                                                                        <p-sortIcon field="category"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="category">
                                                                    <div style="color: white;">Parent Department
                                                                        <p-sortIcon field="category"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                    <div style="color: white;">Employee
                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>

                                                                <th [ngStyle]="{'width':'200px'}"  pSortableColumn="price">
                                                                    <div style="color: white;">Action </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'250px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>

                                                                        <span class="account-button"
                                                                        (click)="detailPage(product.departmentId)"> {{product.departmentName}}</span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">{{product.companyName}}</td>
                                                                <td [ngStyle]="{'width':'200px'}">{{product.active}}</td>
                                                                <td [ngStyle]="{'width':'200px'}">{{product.parentDepartment}}</td>

                                                                <td [ngStyle]="{'width':'200px'}" (click)="employeeList(product.departmentId)"
                                                                 class="account-button"> <a>Employee</a></td>

                                                                <td [ngStyle]="{'width':'200px'}" >
                                                                    
                                                                        <button type="submit" (click)="onDelete(product.departmentId)" class="btn btn-danger ">Delete</button>

                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Add New Department</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                Add New Department </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                    Update Department </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="createDepartmentFormService.job_requistion_form" [formGroup]="createDepartmentFormService.job_requistion_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Department Name <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="departmentName" placeholder="Enter Department name">
                                                        <small class="text-danger" *ngIf="!!createDepartmentFormService.job_requistion_form.controls.departmentName.invalid && !!createDepartmentFormService.job_requistion_form.controls.departmentName.touched">  Department Name is required!</small>
                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Manager</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" placeholder="Enter Manager" formControlName="active">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Company</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Company" formControlName="companyNameId" [options]="companyArray" filter="true" optionlabel="label">
                                                        </p-dropdown>


                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                        <div class="main-submit-button" style="margin-right: 9%;">
                                            <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>