import { Component, OnInit, OnDestroy } from "@angular/core";
import { HeaderService } from "../../header/header.service";
import { SortEvent } from "primeng/api";
import { Router } from "@angular/router";
import { AccountsService } from "../../crm/accounts/accounts.service";
import { DropdownModule } from "primeng/dropdown";
import * as moment from 'moment';
import { of } from 'rxjs';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: "app-create-order",
  templateUrl: "./sales-continue.component.html",
  styleUrls: ["./sales-continue.component.css"],
})
export class SalesContinueComponent implements OnInit {
  activeCategory = 2;
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 1,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],
    }
  };
  products3: any;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  soContinue: any = {};
  currencyArray: any = [];
  catalogueArray: any = [];
  workEffortArray: any = [];
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: "",
  };
  constructor(
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router
  ) {
    this.soContinue = {
      hasAgreements: "N",
      orderName: "",
      correspondingPoId: "",
      currencyUomId: "",
      CURRENT_CATALOG_ID: "",
      workEffortId: "",
      shipAfterDate: "",
      shipBeforeDate: "",
      reserveAfterDate: "",
    };
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails();
    this.getCurrency();
    this.getCatalogue();
    this.getWorkEffortList();
  }

  getCurrency(): void {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe((res) => {
      let currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId,
        });
      }
    });
   
  }

  getCatalogue(): void {
    this.spinner.show();
    let data = {
      catalogName: "",
      catalogNameSearchType: "Contains",
      prodCatalogId: "",
      prodCatalogIdSearchType: "Contains",
    };
    this.accountsService.getCatalogue(data).subscribe((res) => {
      let catalogue = res.data;
      this.spinner.hide();
      for (const value of catalogue) {
        this.catalogueArray.push({
          label: value.catalogName + " [" + value.prodCatalogId + "]",
          value: value.prodCatalogId,
        });
      }
    });
  
  }

  getWorkEffortList(): void {
    this.spinner.show();
    this.accountsService.getWorkEfforts().subscribe((res) => {
      let workEfforts = res.data;
      this.spinner.hide();
      for (const value of workEfforts) {
        this.workEffortArray.push({
          label: value.workEffortName + " [" + value.workEffortId + "]",
          value: value.workEffortId,
        });
      }
    });
   
  }



  continueSalesOrderCreation(): void {
    this.spinner.show();
    let dataPrevious = this.accountsService.createSalesOrderData;
    let formData = this.soContinue;
    let mergedData = { ...dataPrevious, ...formData };
    mergedData.reserveAfterDate = mergedData.reserveAfterDate ? moment(mergedData.reserveAfterDate).format('YYYY-MM-DD HH:mm:ss') : '',
      mergedData.shipAfterDate = mergedData.shipAfterDate ? moment(mergedData.shipAfterDate).format('YYYY-MM-DD HH:mm:ss') : '',
      mergedData.shipBeforeDate = mergedData.shipBeforeDate ? moment(mergedData.shipBeforeDate).format('YYYY-MM-DD HH:mm:ss') : '',

      this.accountsService.initorderentry(mergedData).subscribe((res) => {
        this.spinner.hide();
        this.router.navigate(["/order/create-sales-continue"]);
      });
      this.spinner.hide();
  }

}
