<div class="container-fluid main-container-wrapper">


    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
        style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Accounting Manager Main Page
        </span>
        <span>
            <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
            <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
        </span>
    </div>

    <div class=" bg-white color-grey">
        <div class="w3-card-4 classCard">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass mt-0"
                    style="padding-left:9px; width: 102%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                    <li [ngClass]="activeCategoriess==1?'active':''" (click)="mainPage()">
                        <a>
                            Main </a>
                    </li>

                    <li [ngClass]="activeCategoriess==2?'active':''" (click)="accountingPage()">
                        <a>
                            Accounting </a>
                    </li>
                    <li [ngClass]="activeCategoriess==3?'active':''" (click)="invoicePage()">
                        <a>
                            Invoices</a>
                    </li>
                    <li [ngClass]="activeCategoriess==4?'active':''" (click)="paymentPage()">
                        <a>
                            Payments </a>
                    </li>
                    <li [ngClass]="activeCategoriess==5?'active':''" (click)="paymentGrpPage()">
                        <a>
                            Payment group</a>
                    </li>
                    <li [ngClass]="activeCategoriess==6?'active':''" (click)="billingAccountPage()">
                        <a>
                            Billing Account
                        </a>
                    </li>
                    <li [ngClass]="activeCategoriess==7?'active':''" (click)="agreementPage()">
                        <a>
                            Agreements</a>
                    </li>
                    <li [ngClass]="activeCategoriess==8?'active':''" (click)="commisionReportPage()">
                        <a>
                            Commission Report</a> 
                    </li>
                </ul>
            </div>

            <div class="create-new-leade  m-2">
                <div class="">
                    <div >


                        <div class="panel-collapse">

                            <form class="w-100" [formGroup]="CustomerAgreements">
                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                    <button type="button"
                                                        class="btn btn-outline-secondary"
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter"
                                                        style=" margin-bottom: 4px!important;  margin-top: 4px;">Create
                                                        New Agreement</button>

                                                    <button type="button"
                                                        class="accordion-button  collapsed"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseOne"
                                                        style="margin-left: 76%!important; padding-top: 11px !important;padding-bottom: 11px !important;">
                                                    </button>

                                                </div>

                                            </h2>
                                            <div id="collapseOne"
                                                class="accordion-collapse collapse"
                                                data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div
                                                                    class="col-lg-3 form-group classInput">
                                                                    <label
                                                                        for="exampleInputEmail1">Agreement
                                                                        ID</label>
                                                                </div>

                                                                <div class="col-lg-2"
                                                                    style="display: none;">
                                                                    <p-dropdown
                                                                        [options]="opportunityIdArray"
                                                                        filter="true"
                                                                        formControlName="agreementIdSearchType">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text"
                                                                        name="partyId"
                                                                        formControlName="agreementId"
                                                                        class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Agreement ID">
                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInputA">
                                                                    <label
                                                                        for="exampleInputEmail1">Product
                                                                        ID</label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <p-dropdown
                                                                        [options]="productListArray"
                                                                        optionlabel="productId"
                                                                        filter="true"
                                                                        placeholder="Select"
                                                                        formControlName="productId">
                                                                    </p-dropdown>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div
                                                                    class="col-lg-3 form-group classInput">
                                                                    <label
                                                                        for="exampleInputEmail1">Party
                                                                        Id From</label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <p-dropdown
                                                                        [options]="PersonsAndPartyGroupsListArray"
                                                                        optionlabel="partyId"
                                                                        filter="true"
                                                                        placeholder="Select"
                                                                        formControlName="partyIdFrom">
                                                                    </p-dropdown>
                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInputA">
                                                                    <label
                                                                        for="exampleInputEmail1">Party
                                                                        Id To</label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <p-dropdown
                                                                        [options]="PersonsAndPartyGroupsListArray"
                                                                        optionlabel="partyId"
                                                                        filter="true"
                                                                        placeholder="Select"
                                                                        formControlName="partyIdTo">
                                                                    </p-dropdown>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div
                                                                    class="col-lg-3 form-group classInput">
                                                                    <label
                                                                        for="exampleInputEmail1">Agreement
                                                                        Type ID</label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <p-dropdown
                                                                        [options]="AgreementTypeListArray"
                                                                        optionlabel="label"
                                                                        filter="true"
                                                                        placeholder="Select"
                                                                        formControlName="agreementTypeId">
                                                                    </p-dropdown>
                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInputA">
                                                                    <label
                                                                        for="exampleInputEmail1">From
                                                                        Date</label>
                                                                </div>

                                                                <div class="col-lg-2"
                                                                    style="display: none;">
                                                                    <p-dropdown
                                                                        [options]="fromDateType"
                                                                        filter="true"
                                                                        formControlName="fromDateFromSearchType">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-calendar
                                                                        formControlName="fromDateFrom">
                                                                    </p-calendar>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div
                                                                    class="col-lg-3 form-group classInput">
                                                                    <label
                                                                        for="exampleInputEmail1">To
                                                                        Date</label>
                                                                </div>

                                                                <div class="col-lg-2"
                                                                    style="display: none;">
                                                                    <p-dropdown
                                                                        [options]="toDateType"
                                                                        filter="true"
                                                                        formControlName="fromDateToSearchType">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-calendar
                                                                        formControlName="fromDateTo"></p-calendar>
                                                                </div>



                                                            </div>
                                                        </div>


                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button "
                                                        style="    margin-left: -26%;">
                                                        <button _ngcontent-cta-c128=""
                                                            (click)="submit()" type="submit"
                                                            class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                        <button type="button"
                                                            class="btn btn-danger ml-2"
                                                            (click)="restForm()">Reset</button>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div class="w3-card-4 classCard mt-2">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Agreement List
                                            </h4>

                                        </div>
                                    </header>
                                    <div
                                        class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="agreementList"
                                                        [paginator]="true" [rows]="rows"
                                                        scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total"
                                                        [scrollable]="true"
                                                        (sortFunction)="customSort($event)"
                                                        [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr
                                                                style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align"
                                                                        style="color: white;">
                                                                        <p-checkbox
                                                                            _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>

                                                                        </p-checkbox>
                                                                        Agreement ID
                                                                        <p-sortIcon
                                                                            field="code"></p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    pSortableColumn="name">
                                                                    <div
                                                                        style="color: white;">
                                                                        Product ID
                                                                        <p-sortIcon
                                                                            field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    pSortableColumn="name">
                                                                    <div
                                                                        style="color: white;">
                                                                        From Party ID
                                                                        <p-sortIcon
                                                                            field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    pSortableColumn="category">
                                                                    <div
                                                                        style="color: white;">
                                                                        To Party ID
                                                                        <p-sortIcon
                                                                            field="category"></p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    width="11%"
                                                                    pSortableColumn="quantity">
                                                                    <div
                                                                        style="color: white;">
                                                                        Role Type Id To
                                                                        <p-sortIcon
                                                                            field="quantity"></p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    width="11%"
                                                                    pSortableColumn="price">
                                                                    <div
                                                                        style="color: white;">
                                                                        Agreement Type ID
                                                                        <p-sortIcon
                                                                            field="price"></p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    pSortableColumn="price">
                                                                    <div
                                                                        style="color: white;">
                                                                        From Date
                                                                        <p-sortIcon
                                                                            field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    pSortableColumn="price">
                                                                    <div
                                                                        style="color: white;">
                                                                        Through Date
                                                                        <p-sortIcon
                                                                            field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    pSortableColumn="price">
                                                                    <div
                                                                        style="color: white;">
                                                                        Description
                                                                        <p-sortIcon
                                                                            field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    pSortableColumn="price">
                                                                    <div
                                                                        style="color: white;">
                                                                        Created Date
                                                                        <p-sortIcon
                                                                            field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    width="10%"
                                                                    style="text-align: center;"
                                                                    pSortableColumn="price">
                                                                    <div
                                                                        style="color: white;">
                                                                        Action</div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;"
                                                                        value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body"
                                                            let-product>
                                                            <tr>
                                                                <td
                                                                    [ngStyle]="{'width':'150px'}">
                                                                    <div
                                                                        class="checkbox-align">
                                                                        <p-checkbox
                                                                            _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>

                                                                        </p-checkbox>
                                                                        <span
                                                                            (click)="summary(product.agreementId,product.partyIdTo)"
                                                                            class="account-button">{{product.agreementId}}</span>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    [ngStyle]="{'width':'150px'}">
                                                                    {{product.productId}}
                                                                </td>
                                                                <td
                                                                    [ngStyle]="{'width':'150px'}">
                                                                    {{product.partyIdFrom}}
                                                                </td>
                                                                <td
                                                                    [ngStyle]="{'width':'150px'}">
                                                                    {{product.partyIdTo}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}"
                                                                    width="11%">
                                                                    {{product.roleTypeIdTo}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}"
                                                                    width="11%">
                                                                    {{product.agreementTypeId}}
                                                                </td>
                                                                <td
                                                                    [ngStyle]="{'width':'150px'}">
                                                                    {{product.fromDate |
                                                                    date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                <td
                                                                    [ngStyle]="{'width':'150px'}">
                                                                    {{product.thruDate |
                                                                    date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                <td
                                                                    [ngStyle]="{'width':'150px'}">
                                                                    {{product.createdStamp |
                                                                    date:'yyyy-MM-d hh:mm:ss'}}
                                                                </td>
                                                                <td
                                                                    [ngStyle]="{'width':'150px'}">
                                                                    {{product.description}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'150px'}"
                                                                    width="10%"
                                                                    style="text-align: center;">
                                                                    <div>

                                                                        <svg (click)="cancelAgreement(product.agreementId)"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            pTooltip="Cancel"
                                                                            tooltipPosition="Bottom"
                                                                            class="ml-20"
                                                                            width="16"
                                                                            height="16"
                                                                            viewBox="0 0 26 26">
                                                                            <defs>
                                                                                <style>
                                                                                    .a {
                                                                                        fill: #f44336;
                                                                                    }

                                                                                    .b {
                                                                                        fill: #fafafa;
                                                                                        margin-left: 20px;
                                                                                    }
                                                                                </style>
                                                                            </defs>
                                                                            <path
                                                                                class="a fill-color"
                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                            <path class="b"
                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                transform="translate(-147.576 -147.576)" />
                                                                        </svg>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template
                                                            pTemplate="emptymessage"
                                                            let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span
                                                                        class="text-center">
                                                                        <h4 class="ml-2">No
                                                                            Record Found
                                                                        </h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>



                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Agreement</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMainTs==1?'active':''"><a>
                            Create Agreement</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCustomerAgreements">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Product ID<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="productList" optionLabel="productId" filter="true" placeholder="Select" formControlName="productId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Party Id From<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="PersonsAndPartyGroupsList" optionLabel="partyId" filter="true" placeholder="Select" formControlName="partyIdFrom">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Party Id To<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="PersonsAndPartyGroupsList" optionLabel="partyId" filter="true" placeholder="Select" formControlName="partyIdTo">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Role Type Id From<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="RoleTypesList" optionLabel="description" filter="true" placeholder="Select" formControlName="roleTypeIdFrom">
                                                        </p-dropdown>
                                                    </div> 


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Role Type Id To</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="RoleTypesList" optionLabel="description" filter="true" placeholder="Select" formControlName="roleTypeIdTo">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Agreement Type ID<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="AgreementTypeList" optionLabel="agreementTypeId" filter="true" placeholder="Select" formControlName="agreementTypeId">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createCustomerAgreements.controls.agreementTypeId.invalid && !!createCustomerAgreements.controls.agreementTypeId.touched">Agreement
                                                        Type ID is required</small>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Agreement Date</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" formControlName="agreementDate" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">From Date<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" formControlName="fromDate" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Through Date<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" formControlName="thruDate" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="examCreate Commission Agreement
                                                pleInputEmail1">Description<span
                                                style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Text Data</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <textarea name="" id="exampleInputEmail1" formControlName="textData" class="form-control" cols="30" rows="10" placeholder="Enter Text"></textarea>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -11%;"><button _ngcontent-cta-c128="" type="submit" (click)="submitData()" class="btn btn-secondary submit-btn">Create</button>



                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>


<ngx-spinner></ngx-spinner>