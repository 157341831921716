<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">

            <div class="col-md-12 p-0">
                <div class="w3-card-4 classCard  w-100" style="height: 580px;">

                    <div class="col-lg-12">
                        <div class="row d-flex mt-3 mb-2 justify-content-between px-2">
                            <div class="">
                                <span style="font-weight: bold; font-size: 15px !important;">Configure Customer Notifications</span>

                            </div>

                            <div class="" style="margin-right: 1%;">
                                <button class="btn btn-secondary submit-btn">Save</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 ">
                        <div class="row d-flex modals">
                            <p style="margin-left: 9px !important;">You can configure your Customer notifications below
                            </p>
                        </div>
                    </div>

                    <div class="row " style="width: 100%; margin-top: 2%; margin-bottom: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard"
                                style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%; height: 300px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important ;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black; font-weight: bold !important;">
                                        Send Notifications on following actions </p>

                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12 " [formGroup]="sendNotificationsForm">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class=" col-lg-1 classInput">
                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined" formControlName="unassignedWorkOrder">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>

                                                            </p-checkbox>

                                                        </div>

                                                        <div class="">
                                                            <label>New Unassigned Work Order Created</label>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class=" col-lg-1 classInput">
                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined" formControlName="assignedWorkWatingForPickup">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>

                                                            </p-checkbox>

                                                        </div>

                                                        <div class="">
                                                            <label>Assigned Work waiting for pickup</label>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class=" col-lg-1 classInput">
                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined" formControlName="pickedUp">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>

                                                            </p-checkbox>

                                                        </div>

                                                        <div class="">
                                                            <label>Picked Up</label>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class=" col-lg-1 classInput">
                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined" formControlName="enRoute">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>

                                                            </p-checkbox>

                                                        </div>

                                                        <div class="">
                                                            <label>En Route</label>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class=" col-lg-1 classInput">
                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined" formControlName="workStarted">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>

                                                            </p-checkbox>

                                                        </div>

                                                        <div class="">
                                                            <label>Work Started</label>
                                                        </div>

                                                    </div>

                                                </div>


                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class=" col-lg-1 classInput">
                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined" formControlName="paused">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>

                                                            </p-checkbox>

                                                        </div>

                                                        <div class="">
                                                            <label>paused</label>
                                                        </div>

                                                    </div>

                                                </div>


                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class=" col-lg-1 classInput">
                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined" formControlName="hold">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>

                                                            </p-checkbox>

                                                        </div>

                                                        <div class="">
                                                            <label>Hold</label>
                                                        </div>

                                                    </div>

                                                </div>


                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class=" col-lg-1 classInput">
                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined" formControlName="completed">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>

                                                            </p-checkbox>

                                                        </div>

                                                        <div class="">
                                                            <label>Completed</label>
                                                        </div>

                                                    </div>

                                                </div>


                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class=" col-lg-1 classInput">
                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined" formControlName="cancelled">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>

                                                            </p-checkbox>

                                                        </div>

                                                        <div class="">
                                                            <label>Cancelled</label>
                                                        </div>

                                                    </div>

                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>






                        </div>


                    </div>  
                    <div class="row " style="width: 100%; margin-bottom: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard"
                                style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important ;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black; font-weight: bold !important;">
                                        Configure content of notifications message </p>
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="notificationMessageForm">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-4 classInputA">
                                                            <label>Enter Email
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                           <input type="email"  formControlName="email" 
                                                           class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" 
                                                           placeholder="Enter Email">
                                                        </div>
                                                        
                                                    </div>

                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-4 classInputA">
                                                            <label>Enter Subject
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                           <input type="email"  formControlName="text" 
                                                           class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" 
                                                           placeholder="Enter Subject">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="classInputA col-lg-4">
                                                            <label>Select Action</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label"                       
                                                             [options]="dropdownArray"   placeholder="Choose Action" 
                                                                formControlName="subject">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end me-2">
                                <button class="btn btn-secondary submit-btn"
                                
                               (click)="customerNotification()" >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- <ngx-spinner></ngx-spinner> -->