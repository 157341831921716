import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { DatePipe } from '@angular/common';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-detail-invitation-summary',
  templateUrl: './detail-invitation-summary.component.html',
  styleUrls: ['./detail-invitation-summary.component.css']
})
export class DetailInvitationSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;

  activeCategory = 1;
  products3: any;

  paymentPartyGroup: any;
  downloading: boolean;
  total = 0;
  ReportTax: any[] = [];
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search = ""
  finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  FinancialTransaction: any[] = [];
  budgetId: any;
  budgetHeader: any;
  budgetStatus: any;
  budgetRole: any;
  budgetItem: any;
  budgetReview: any;
  budgetItemList: any;
  budgetRoleList: any;
  budgetReviewList: any;
  status: string;
  routingId: any;
  taskAssoc: any;
  productLink: any;
  inviteId: any;
  InvitationGroupAssocs: any;
  InvitationRoleAssociation: any;
  editData: any;

  show = false;
  userLoginIdArray: any[] = [];
  partyIdArray: any[] = [];
  PartyInvitationStatusList: any;
  PartyInvitationStatusListArray: any[] = [];
  personId: any;
  invitataionForm: FormGroup;
  fromDate: string;
  getEstimateFrom: string;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly datePipe: DatePipe,
    readonly addSkillService: AddSkillService,
    readonly _ToastrService: ToastrService,
    readonly activate: ActivatedRoute
  ) {
    this.invitataionForm = this._FormBuilder.group({
      partyFrom: [''],
      partyTo: [''],
      toName: [''],
      emailAddress: [''],
      statusId: [''],
      inviteDate: ['']



    });

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.inviteId = params["inviteId"];
    });
    this.editPartyInvitations()
    this.PartyInvitationGroupAssocs();
    this.partyInvitationRoleAssociation();
    this.getPartyId();
    this.getPartyInvitationStatusList();
    this.activate.queryParams.subscribe(params => {
      this.inviteId = params["inviteId"];


    });
    if (this.inviteId) {
      this.show = true;
      this.editPartyInvitations();
    }
    else {
      this.show = false;
    }


  }
  update() {
    this.router.navigate(["hub/create-party-invitation"], { queryParams: { inviteId: this.inviteId } });
  }
  editPartyInvitations() {
    this.spinner.show();

    this.accountsService.editPartyInvitations(this.inviteId).subscribe((res: any) => {
      this.editData = res.data.PartyInvitation
      this.spinner.hide();
    })
  }
  editPartyInvitationsdata(){
    this.spinner.show();
   
    this.accountsService.editPartyInvitations(this.inviteId).subscribe((res:any)=> {
      setTimeout(() => {
        const fromdate=res.data.PartyInvitation.lastInviteDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        
        const formValue = this.invitataionForm;
        formValue.patchValue({
          partyFrom: res.data.PartyInvitation.partyIdFrom,
        partyTo:res.data.PartyInvitation.partyId,
        toName:res.data.PartyInvitation.toName,
        emailAddress:res.data.PartyInvitation.emailAddress,
        statusId:res.data.PartyInvitation.statusId,
        inviteDate: this.getEstimateFrom
        })
      
      }, 3000);
      this.spinner.hide();
    })  
   }
  PartyInvitationGroupAssocs() {
    this.spinner.show();
    this.accountsService.PartyInvitationGroupAssocs(this.inviteId).subscribe((res: any) => {
      this.InvitationGroupAssocs = res.data;
      this.spinner.hide();
    })

  }
  partyInvitationRoleAssociation() {
    this.spinner.show();
    this.accountsService.partyInvitationRoleAssociation(this.inviteId).subscribe((res: any) => {
      this.InvitationRoleAssociation = res.data;
      this.spinner.hide();
    })

  }


  deletePartyInvitationGroupAssoci(inviteid, roleid) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.accountsService.deletePartyInvitationGroupAssoci(inviteid, roleid).subscribe((res: any) => {
            this.PartyInvitationGroupAssocs();
          }, (err) => {
            this.toastr.error(err.error.message);
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
  
  }
  deletePartyInvitationRoleAssociation(inviteid, roleid) {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.accountsService.deletePartyInvitationRoleAssociation(inviteid, roleid).subscribe((res: any) => {
            this.partyInvitationRoleAssociation();
          }, (err) => {
            this.toastr.error(err.error.message);
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
  
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  createTaskAssoc() {
    this.router.navigate(["hub/create-party-group-assoc"], { queryParams: { inviteId: this.inviteId } })

  }
  createProductLink() {
    this.router.navigate(["hub/create-party-role-type"], { queryParams: { inviteId: this.inviteId } })

  }
  updateRouting(efromdate, eproductid, eworkid) {
    this.router.navigate(["production/create-routing-product-link"], { queryParams: { routingId: this.routingId, fromdate: efromdate, productid: eproductid, workid: eworkid } })

  }

  GoToQuickLink() {
    this.router.navigate(['/hub/invitation']);
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }



  getPartyInvitationStatusList() {
    this.spinner.show();
    this.status = 'PARTY_INV_STATUS'
    this.accountsService.getPartyInvitationStatusList(this.status).subscribe((res: any) => {
      this.PartyInvitationStatusList = res.data;
      this.spinner.hide();
      for (const value of this.PartyInvitationStatusList) {
        this.PartyInvitationStatusListArray.push({
          label: value.data.description,
          value: value.data.statusId
        })
      }

    })

  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }

  onUpdate() {
    this.spinner.show();
    const fromDate = this.invitataionForm.get('inviteDate').value;
    this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {
      "emailAddress": this.invitataionForm.value.emailAddress,
      "lastInviteDate": this.fromDate,
      "lastInviteDate_i18n": "",
      "partyId": this.invitataionForm.value.partyTo,
      "partyIdFrom": this.invitataionForm.value.partyFrom,
      "partyInvitationId": this.inviteId,
      "statusId": this.invitataionForm.value.statusId,
      "toName": this.invitataionForm.value.toName,
    }
    this.accountsService.updatePartyInvitations(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.editPartyInvitations()
      //  this.router.navigate(['/partyQuickLink/invitation']);
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  onSubmit() {
    this.spinner.show();
    const fromDate = this.invitataionForm.get('inviteDate').value;
    this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {
      "emailAddress": this.invitataionForm.value.emailAddress,
      "lastInviteDate": this.fromDate,
      "lastInviteDate_i18n": "",
      "partyId": this.invitataionForm.value.partyTo,
      "partyIdFrom": this.invitataionForm.value.partyFrom,
      "partyInvitationId": '',
      "statusId": this.invitataionForm.value.statusId,
      "toName": this.invitataionForm.value.toName,
    }
    this.accountsService.createPartyInvitations(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/partyQuickLink/invitation']);
        

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }



  ngOnDestroy(): void {
    this.spinner.hide();
  }
}



