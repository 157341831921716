import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { FinancialService } from 'src/app/services/financial.service';

import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-asset-fixed-asset',
  templateUrl: './asset-fixed-asset.component.html',
  styleUrls: ['./asset-fixed-asset.component.css']
})
export class AssetFixedAssetComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= "";
  advanceSearch: {

    assetType: string,
    fixedAssetId: string,
    fixedAssetIdSearchType: string,
    fixedAssetName: string,
    fixedAssetNameSearchType: string


  };
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  fixedAssetsInitialDataArray: any[] = [];
  getFixedAssets: any;
  show: boolean;
  createFixAssetForm: FormGroup;
  fixedAssetTypes: any[];
  classEnumId: any[];
  calendar: any[];
  partyIds: any[];
  activeCategory = 2;
  productIds: any[];
  fixAssetIds: any[];
  uomIds: any[];
  roleTypeIds: any[];
  facilityId: any[];
  facilityLocation: any[];
  fixAssetId: string;
  fixAsset: any;
  editMode: boolean;
  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,

    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
   
    readonly _AccountingApService: AccountingApService,
    readonly _WarehouseService: WarehouseService,
    readonly addSkillService: AddSkillService,
    private _location: Location,
    ) {
      this.createFixAssetForm = this._FormBuilder.group({
        acquireOrderId: [''], acquireOrderItemSeqId: [''],actualEndOfLife: [''],
        calendarId: [''],classEnumId: [''], dateAcquired: [''],dateLastServiced: [''],dateNextService: [''],
        depreciation: [''],expectedEndOfLife: [''],fixedAssetId: [''],fixedAssetName: [''],
        fixedAssetTypeId: ['COMPUTER_HARDWARE'],
        instanceOfProductId: [''],locatedAtFacilityId: [''],locatedAtLocationSeqId: [''],
        parentFixedAssetId: [''],partyId: [''],productionCapacity: [''],purchaseCost: [''],
        roleTypeId: [''],
        salvageValue: [''],
        serialNumber: [''],
        uomId: ['']
      });
      this.fixedAssetTypes = [];
      this.classEnumId = [];
      this.calendar = [];
      this.partyIds = [];
      this.productIds = [];
      this.fixAssetIds = [];
      this.uomIds = [];
      this.roleTypeIds = [];
      this.editMode = false;
    this.advanceSearch = {

      assetType: "",
      fixedAssetId: "",
      fixedAssetIdSearchType: "Contains",
      fixedAssetName: "",
      fixedAssetNameSearchType: "Contains"


    };
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
  }
  financialList = [{
    'value': 1
  }]
  ngOnInit(): void {
    // this.getFixedAssetsData();
    this.fixedAssetsInitialData();

    this.getInitialData();this.getProductIds(); this.getPartyIds();this.getFixAssetIds();this.getUomIds();
    this.getRoleTypeIds();this.getFacilityIds();
    this.createFixAssetForm.controls.locatedAtFacilityId.valueChanges.subscribe(data => {
      if (data) {
        this.getFacilityLocations(data);
      }
    });
    this.findFixedAssetsNew();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
 
  onSubmit() {
    this.spinner.show();
    this._FinancialService.createFixAsset({
      ...this.createFixAssetForm.value, ...{
        dateAcquired: this.createFixAssetForm.value.dateAcquired ? moment(this.createFixAssetForm.value.dateAcquired).format('YYYY-MM-DD HH:mm:ss') : '',
        dateLastServiced: this.createFixAssetForm.value.dateLastServiced ? moment(this.createFixAssetForm.value.dateLastServiced).format('YYYY-MM-DD HH:mm:ss') : '',
        dateNextService: this.createFixAssetForm.value.dateNextService ? moment(this.createFixAssetForm.value.dateNextService).format('YYYY-MM-DD HH:mm:ss') : '',
        actualEndOfLife: this.createFixAssetForm.value.actualEndOfLife ? moment(this.createFixAssetForm.value.actualEndOfLife).format('YYYY-MM-DD HH:mm:ss') : '',
        expectedEndOfLife: this.createFixAssetForm.value.expectedEndOfLife ? moment(this.createFixAssetForm.value.expectedEndOfLife).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created');
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          // this.getFixedAssetsData();
          this.findFixedAssetsNew();
        
        }
      });
      this.spinner.hide();

     
  }
  fixedAssetsInitialData() {
    this.spinner.show();
    this.accountsService.fixedAssetsInitialData().subscribe(res => {
      this.spinner.hide();
      const fixedAssetTypes = res.data.fixedAssetTypes;
      for (const value of fixedAssetTypes) {
        this.fixedAssetsInitialDataArray.push({
          label: value.description,
          value: value.fixedAssetTypeId
        })
      }
    })
   
  }
  getFacilityLocations(facilityId: string): void {
    this.spinner.show();
    this._WarehouseService.getFacilityLocations(facilityId, { pageNo: 1, pageSize: 200 }, {
      aisle: '',
      area: '',
      level: '',
      locationSeqId: '',
      position: '',
      section: ''
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.facilityLocation = data.data.map(value => {
            return {
              label: value.locationSeqId,
              value: value.locationSeqId
            };
          });
        }
      });
     
  }
  getFacilityIds(): void {
    this.spinner.show();
    this._WarehouseService.getFacility()
      .then(data => {
        this.spinner.hide();
        this.facilityId = data.data.map(value => {
          return {
            label: value.facilityName,
            value: value.facilityId
          };
        });
      });
     
  }
  getUomIds(): void {
    this.spinner.show();
    this._AccountingApService.getTermType()
      .then(data => {
        this.spinner.hide();
        this.uomIds = data.data.uom.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });
     
  }
  getRoleTypeIds(): void {
    this.spinner.show();
    this._FinancialService.getRoleTypeIds('all')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.roleTypeIds = data.data.map(value => {
            return {
              label: value.description,
              value: value.roleTypeId
            };
          });
        }
      });
     
  }
  getFixAssetIds(): void {
    this.spinner.show();
    this._FinancialService.getAssets({ pageNo: 1, pageSize: 200 }, { assetType: '', fixedAssetId: '', fixedAssetIdSearchType: 'Contains', fixedAssetName: '', fixedAssetNameSearchType: 'Contains' })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.fixAssetIds = data.data.map(value => {
            return {
              label: value.fixedAssetName,
              value: value.fixedAssetId
            }
          });
        }
      });
     
  }
  getInitialData(): void {
    this.spinner.show();
    this._FinancialService.getInitialAssetData()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.calendar = data.data.calendar.map(value => {
            return {
              label: value.description,
              value: value.calendarId
            };
          });
          this.classEnumId = data.data.classEnumId.map(value => {
            return {
              label: value.description,
              value: value.enumId
            };
          });
          this.fixedAssetTypes = data.data.fixedAssetTypes.map(value => {
            return {
              label: value.description,
              value: value.fixedAssetTypeId
            };
          });
        }
      });
     
  }
  getProductIds(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.productIds = data.data.map(value => {
            return {
              label: value.productId,
              value: value.productId
            };
          });
        }
      });
     
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.partyIds = data.data.PersonsGroups.map(value => {
            return {
              label: value.partyId,
              value: value.partyId
            };
          });
        }
      });
     
  }
  getFixedAssetsData() {
    this.spinner.show();
    let req = {
      "assetType": "",
      "fixedAssetId": "",
      "fixedAssetIdSearchType": "Contains",
      "fixedAssetName": "",
      "fixedAssetNameSearchType": "Contains"


    }
    this.accountsService.searchFixedAssets(this.finSize, req).subscribe((res: any) => {
      this.getFixedAssets = res.data;
      this.spinner.hide();
    })
  
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.assetType = "";
    this.advanceSearch.fixedAssetId = "";
    this.advanceSearch.fixedAssetName = "";
    // const req = {
    //   "assetType": "",
    //   "fixedAssetId": "",
    //   "fixedAssetIdSearchType": "Contains",
    //   "fixedAssetName": "",
    //   "fixedAssetNameSearchType": "Contains"


    // }
    // this.accountsService.searchFixedAssets(this.finSize, req).subscribe((res: any) => {
    //   this.getFixedAssets = res.data;
    //   this.spinner.hide();
    // })
    this.findFixedAssetsNew();
   
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.getFixedAssets = [];
    const req = {
      "assetType": this.advanceSearch.assetType ? this.advanceSearch.assetType : '',
      "fixedAssetId": this.advanceSearch.fixedAssetId ? this.advanceSearch.fixedAssetId : '',
      "fixedAssetIdSearchType": this.advanceSearch.fixedAssetIdSearchType ? this.advanceSearch.fixedAssetIdSearchType : '',
      "fixedAssetName": this.advanceSearch.fixedAssetName ? this.advanceSearch.fixedAssetName : '',
      "fixedAssetNameSearchType": this.advanceSearch.fixedAssetNameSearchType ? this.advanceSearch.fixedAssetNameSearchType : '',

    }
    this.accountsService.searchFixedAssets(this.finSize, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.getFixedAssets = resp.data;
        } 
      });
     
  }
  findFixedAssetsNew() {
    this.spinner.show();
    let req = {
      "assetType": this.advanceSearch.assetType ? this.advanceSearch.assetType : '',
      "fixedAssetId": this.advanceSearch.fixedAssetId ? this.advanceSearch.fixedAssetId : '',
      "fixedAssetName": this.advanceSearch.fixedAssetName ? this.advanceSearch.fixedAssetName : '',
    }
    this.accountsService.findFixedAssetsNew(req).subscribe((res: any) => {
      this.getFixedAssets = res.data[0].dataNode;
      this.spinner.hide();
    })
  
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  accountDetails(id) {
    this._Router.navigate(["assetMaint/fixed-asset/edit-fixed-asset"], { queryParams: { fixedAssetId: id } })
  }
}
