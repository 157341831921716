import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-create-party-contact',
  templateUrl: './create-party-contact.component.html',
  styleUrls: ['./create-party-contact.component.css']
})
export class CreatePartyContactComponent implements OnInit {
  createContact:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[]=[];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  efromDate: any;
  ethruDate: any;
  getthruDate: string;
  AllPartyContactListPartyStatus: any;
  AllPartyContactListPartyStatusArray: any[]=[];
  status: string;
  PartyInvitationStatusList: any;
  PartyInvitationStatusListArray: any[]=[];
  contactId: any;
  
  preferredContactMechId: any;
  statusId: any;
  PreferredContactMechId: any;
  PreferredContactMechIdArray: any[]=[];
  contactList: any;
  contactListArray: any[]=[];
  @ViewChild('closebutton') closebutton;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    public createCont: CreateContactsService,
    readonly accountsService: AccountsService,
    public bsModalRef: BsModalRef) { 
      this.contentIds = [];
      this.createContact = this._FormBuilder.group({
        ContactListID: [''],
        StatusID: [''],
        FromDate:[''],
        ThroughDate:[''],
        PreferredContactMechID: [''],
        OptinCode:[''],
        StatusHistory:['']
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.contactId = params["contactId"];
      this.preferredContactMechId = params["preferredContactMechId"];
      this.statusId = params["statusId"];
      this.efromDate = params["fromDate"];
      this.ethruDate = params["thruDate"];
      
    });
    this.getAllPartyContactListPartyStatus();
    this.getPartyInvitationStatusList();
    this.getPreferredContactMechId();
    this.getContactList();
    if(this.contactId) {
      this.show=true;
      const fromdate=this.efromDate
      this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
      const ethruDate=this.ethruDate
      this.getthruDate=this.datePipe.transform(ethruDate,"yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.createContact;
      formValue.patchValue({
        ContactListID:this.contactId,
        StatusID: this.statusId,
        FromDate: this.getEstimateFrom,
        ThroughDate: this.getthruDate,
        PreferredContactMechID:this.preferredContactMechId,
      })
      }, 3000); 
    }
    else {
      
      this.show = false;
    }
 
  }
  
  getAllPartyContactListPartyStatus(){
    this.spinner.show();
   
      this.accountsService.getAllPartyContactListPartyStatus(this.party).subscribe((res:any)=> {
        this.AllPartyContactListPartyStatus = res.data;
        this.spinner.hide();
        for(const value of this.AllPartyContactListPartyStatus){
          this.AllPartyContactListPartyStatusArray.push({
            label:value.data.description + '' + 'By' +value.data.setByUserLoginId + '' + 'Opt-in Code' +value.data.optInVerifyCode,
            value:value.data.statusId
          })
        }
      
      })
     
     }
     getContactList() {
      this.spinner.show();
      this.createCont.getContactList().subscribe(res => {
        this.contactList = res.data;
        this.spinner.hide();
        for (const value of this.contactList) {
          this.contactListArray.push({
            label: value.description,
            value: value.contactListId
          })
        }
      })
     
    }
     getPreferredContactMechId(){
      this.spinner.show();
     
        this.accountsService.getPreferredContactMechId(this.party).subscribe((res:any)=> {
          this.PreferredContactMechId = res.data;
          this.spinner.hide();
          for(const value of this.PreferredContactMechId){
            this.PreferredContactMechIdArray.push({
              label:value.contactMechId + '' + value.paAddress1 + ',' + value.paStateProvinceGeoId + ',' + value.paPostalCode + ',' + value.paCountryGeoId,
              value:value.contactMechId
            })
          }
        
        })
       
       }
     getPartyInvitationStatusList(){
      this.spinner.show();
      this.status='CONTACTLST_PARTY'
        this.accountsService.getPartyInvitationStatusList(this.status).subscribe((res:any)=> {
          this.PartyInvitationStatusList = res.data;
          this.spinner.hide();
          for(const value of this.PartyInvitationStatusList){
            this.PartyInvitationStatusListArray.push({
              label:value.data.description,
              value:value.data.statusId
            })
          }
        
        })
       
       }
     
  onSubmit() {
    this.spinner.show();
    const fromDate=this.createContact.get('FromDate').value;
    this.fromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const thruDate=this.createContact.get('ThroughDate').value;
    this.thruDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "contactListId": this.createContact.value.ContactListID,
      "fromDate":  this.fromDate,
      "partyId":this.party,
      "preferredContactMechId":this.createContact.value.PreferredContactMechID,
      "statusId": this.createContact.value.StatusID,
      "thruDate": this.thruDate
     
  
     
    }
    this.accountsService.createContactList(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.modalClose();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
   onUpdate() {
    this.spinner.show();
   
   
    const thruDate=this.createContact.get('ThroughDate').value;
    this.thruDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "contactListId": this.createContact.value.ContactListID,
      "fromDate":   this.efromDate,
      "partyId":this.party,
      "preferredContactMechId":this.createContact.value.PreferredContactMechID,
      "statusId": this.createContact.value.StatusID,
      "thruDate": this.thruDate,
     
  "optInVerifyCode": this.createContact.value.OptinCode,
  "statusHistory": this.createContact.value.StatusHistory,
  
     
    }
    this.accountsService.updateContactList(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.modalClose();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }  
  modalClose(): void {
    this.closebutton.nativeElement.click();
    this.bsModalRef.hide();
  } 
   
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }}
