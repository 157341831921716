<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <span class="color-black pl-1 titlePanels">System info note
                    </span>
           
                    <span class="d-flex">
     
                     <button type="submit" (click)="homeButton();"
                         class=" btn btn-outline-secondary mr-2">Home</button>
             
                     <button type="submit" (click)="cancelSubmit();"
                         class="btn btn-danger buttonclass ">Back</button>
                 </span>
             
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==0?'active':''" (click)="changeactiveCategoryFunction(0)"><a>
                                Main</a></li>



                        </ul>
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategory==0">
                                                    <div class="panel-body">

                                                        <div>
                                                            <header class="w3-container">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        System info note for user: {{ this.partyId}}
                                                                    </h4> 
                                                                    <div class="float-right">

                                                                        <span  class="edit-buttons" style="cursor: pointer;" data-toggle="modal" data-target="#exampleModalCenter">Create
                                                                            Public
                                                                            Message |</span>
                                                                        <span class="edit-buttons" style="cursor: pointer;" (click)="deleteALL()">Delete
                                                                            All</span>
                                                                    </div>
                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="SystemInfoNote" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Date Info Created
                                                                                                <p-sortIcon field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                System Info Note
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Action
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span class="account-button" 
                                                                                                (click)="detailMainPage(product.noteId,product.noteDateTime,product.noteInfo)"
                                                                                                >{{product.noteDateTime|date:'yyyy-MM-dd   hh:mm:ss'}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}"
                                                                                      >
                                                                                        <span class="account-second-button" 
                                                                                        (click)="detailPage(product.moreInfoItemId,product.moreInfoItemId,product.statusId)"
                                                                                       >  {{product.noteInfo}}</span>
                                                                                          
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" 
                                                                                            (click)="deleteSystemInfoNote(product.noteId)">Delete
                                                                                        </button>
                                                                                           
                                                                                        </td>

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">No
                                                                                                    Record Found</h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-body">

                                                        <div>
                                                            <header class="w3-container">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        System info status for user: {{ this.partyId}}
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="SystemInfoStatus" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Date Last Changed
                                                                                                <p-sortIcon field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                System Info Status
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span class="account-button"
                                                                                                class="account-button"
                                                                                                 (click)="detailMainPage(product.noteId,product.noteDateTime,product.noteInfo)">
                                                                                                 {{product.noteDateTime|date:'yyyy-MM-dd  hh:mm:ss'}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.noteInfo}}
                                                                                        </td>

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">No
                                                                                                    Record Found</h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <br>


            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Public Message
              </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                    Add Public Message to PartyId: {{this.partyId}}
                                </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createPage">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div>
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">
                                                                        Note Party</label>
                                                                    </div>
                                                                    <div class="col-lg-3">

                                                                        <p-dropdown filter="true" optionlabel="label"
                                                                        formControlName="noteParty"
                                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                        [options]="partyIdArray" placeholder="Choose Note Party">
                                                                    </p-dropdown>


                                                                        
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">More Info Url</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter More Info Url" formControlName="moreInfoUrl">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Note Info</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Note Info" formControlName="noteInfo">
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 16%;">
                                                            <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>&nbsp;&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>