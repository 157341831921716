import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from "@angular/router";
import {CreateInternalService} from "./create-internal-job.service";
import {AddFormService} from "./create-internal-job-service";
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe, formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';


declare var $: any;
@Component({
  selector: 'app-create-internal-job-posting',
  templateUrl: './create-internal-job-posting.component.html',
  styleUrls: ['./create-internal-job-posting.component.css']
})
export class CreateInternalJobPostingComponent implements OnInit {
  updateId: any;
  activeCategory = 2;
  approveParty: any;
  approveData: any=[];
  jobRequestion: any;
  getJobRequistionArray: any=[];
  show: boolean = false;
  jobRequistionId: any;
  updateestifromdate: any;
  updateestifromdateUpdate: string;

  constructor(readonly activatedRoute:ActivatedRoute,
    readonly createInternalService:CreateInternalService,
    public addFormService:AddFormService,
    readonly spinner:NgxSpinnerService,
    readonly toastr:ToastrService,
    readonly router:Router,
    readonly datePipe: DatePipe,
    ) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.jobRequistionId=params["jobRequistionId"];
    })
    this.activatedRoute.queryParams.subscribe(params=> {
      this.updateId=params["updateId"];
      if(this.updateId) {
        this.show=true;
      }
      else 
      {
        this.show=false;
      }
    })
    this.addFormService.add_job_form=null;
    this.addFormService.addJobForm(this.addFormService.add_job_form);
    const form = this.addFormService.add_job_form;
    setTimeout(() => {
      const formValue = this.addFormService.add_job_form;
    formValue.patchValue({
      jobRequisitionId:this.jobRequistionId 
    })
    }, 3000);
  
    this.getApproverParty();
    this.getJobRequistionList();
    this.getJobRequistionId();
    this.activatedRoute.queryParams.subscribe(params=> {
      this.updateId = params["updateId"];
    })
    $('.right-arrow').click(function(){
      $(this).toggleClass('rotate-up');
    });
  }
  getApproverParty(){
    this.spinner.show();
    this.createInternalService.getApproverParty().subscribe(res=> {
      this.approveParty = res.data.PersonsGroups;
      this.spinner.hide();
      this.approveParty.forEach(data=> {
        this.approveData.push({
          label:data.partyId,
          value:data.partyId
        })
        
      })
    })
   
  }
  cancel(){
    this.router.navigate(['/hr/job-requisition/internal-job-posting'])
  }
  onSubmit(){
    this.spinner.show();
    let fromdate=this.addFormService.add_job_form.get('applicationDate').value;
    this.updateestifromdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");
    const formData={
  "applicationDate":this.updateestifromdate,
  "approverPartyId": this.addFormService.add_job_form.value.approverPartyId,
  "jobRequisitionId": this.addFormService.add_job_form.value.jobRequisitionId,
  "statusId": ""
    }
 
    this.createInternalService.createForm(formData).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/hr/job-requisition/internal-job-posting'])
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
  }

  onUpdate() {
    this.spinner.show();
    let fromdate=this.addFormService.add_job_form.get('applicationDate').value;
    this.updateestifromdateUpdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");
    const formData={
  "applicationDate":this.updateestifromdateUpdate,
  "approverPartyId": this.addFormService.add_job_form.value.approverPartyId,
  "jobRequisitionId": this.addFormService.add_job_form.value.jobRequisitionId,
  "statusId": ""
    }
  
    this.createInternalService.updateForm(formData, this.updateId).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/hr/job-requisition/internal-job-posting'])
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
  }
  getJobRequistionList() {
    this.spinner.show();
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Contains",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Contains",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Contains",
      experienceYears: "",
      experienceYearsSearchType: "Contains",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""
    }
    this.createInternalService.getJobRequistionList(jobRequistion).subscribe(res=> {
      this.jobRequestion=res.data;
      this.spinner.hide();
     for(const value of this.jobRequestion){
       this.getJobRequistionArray.push({
         label:value.jobRequisitionId,
         value:value.jobRequisitionId
       })
     }
    
    })
   
  }

  getJobRequistionId() {
    this.spinner.show();
    this.createInternalService.getDataById(this.updateId).subscribe(res=> {
      setTimeout(() => {
        const form = this.addFormService.add_job_form;
        form.patchValue({
          
          applicationDate  :this.datePipe.transform(res.data.applicationDate,"yyyy-MM-dd"),
          approverPartyId  :res.data.approverPartyId,
          jobRequisitionId :res.data.jobRequisitionId,
          applicationId:res.data.applicationId
  
        
  
        })
      
      }, 3000);
      this.spinner.hide();
     
    })
      
    
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
