import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sub-sites',
  templateUrl: './sub-sites.component.html',
  styleUrls: ['./sub-sites.component.css']
})
export class SubSitesComponent implements OnInit {
  activeCategory=2;

  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  cmsContentForm: FormGroup;
  customerCategoryPopup=1;
  CMSFindForm: FormGroup;
  addTopLevelForumForm: FormGroup;
  subSitesForm: FormGroup;
  subSiteForm: FormGroup;
  contentAssocTypeId: any;
  contentIdTo: any;
  contentId: any;
  MsgDefaultArray: { label: string; value: string; }[];
  userRolesForm: FormGroup;
  personId: any;
  partyIdArray: any[]=[];
  forumFilterData: any;
  forumFilterList: any;
  DataResourceId: any;
  DataResourceIdArray: any[]=[];
  uploadURL: any;
  ContentAssocTypeDataArray:  any[]=[];
  DataPredicateArray: any[]=[];
  DataPredicate: any;
  ContentAssocTypeData: any;
  resourceTypeDROP: any;
  resourceTypeDROPArray: any[]=[];
  contentTypeIdData: any;
  contentTypeIdDataArray: any[]=[];
  mimeTypeIdData: any;
  mimeTypeIdDataArray: any[]=[];
  CharacterSetIdArray: any[]=[];
  CharacterSetId: any;
  fileData: File;
  dataTemplateType: any;
  dataTemplateTypeArray: any[]=[];
  CatalogContentId: any;
  CatalogContentIdArray: any[]=[];
  show: boolean;
  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly addSkillService: AddSkillService,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  ) 
  {
  this.subSiteForm =this._FormBuilder.group({
      luceneQuery:"",
    })
    this.userRolesForm =this._FormBuilder.group({
      User:"",
    })

    this.addTopLevelForumForm=this._FormBuilder.group({
      siteName:"",
      siteDescription:"",
      postedMsgDefaultStatus:"",
    })
    this.cmsContentForm=this._FormBuilder.group({
      contentIDTo:"",
      mapKey:"",
      contentAssocTypeID:"",
      contentAssocPredicateID:"",
      fromDate:"",
      throughDate:"",
      contentID:"",
      templateDataResourceID:"",
      contentTypeID:"",
      ownerContentID:"",
      contentName:"",
      description:"",
      mimeTypeID:"",
      characterSetID:"",
      localeString:"",
      statusID:"",
      privilegeEnumID:"",
      dataResourceID:"",
      drDataResourceTypeID:"",
      filePath:"",
      drDataTemplateTypeID:"",
      drMimeTypeID:"",
      characterSetID1:"",
      localeString1:"",
      dataSource:"",
      dataCategory:"",
      textData:"",
      imageData:"",
      createdByUser:"",
      createdDate:"",
      lastModifiedByUser:"",
      lastModifiedDate:"",
    })
    this.MsgDefaultArray = 
    [
      {
        label : "Draft - not attached to any site",
        value : "CTNT_IN_PROGRESS"
    },
    {
      label : "Final Draft - but must be approve (moderated)",
      value : "CTNT_FINAL_DRAFT"
  },
  {
    label : "Publish immediately",
    value : "CTNT_PUBLISHED"
},
  ]
   

  }

  ngOnInit(): void {
    setTimeout(() => {
      const form = this.subSiteForm;
      form.patchValue({
        luceneQuery: "WebStoreFORUM"
      })
      
    }, 1000);
    this.getPartyId();
    this.forumFilter();
    this.ContentAssocType();
    this.metaDataPredicate();
    this.getCharacterSet();
    this.getMimeTypeIdData();
    this.DataResourceTypeDROP();
    this.contentTypeIds();
    this.getDataTemplateType();
    this.getCatalogContentId();
    this.getDataResourceId();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  moderate(){
    this.show = true;
  }
  getDataResourceId() {
    this.spinner.show();
    this.accountsService.getDataResourceId().subscribe(res => {
      this.DataResourceId = res.data.getDataResourceId;
      this.spinner.hide();
      for (const value of this.DataResourceId) {
        this.DataResourceIdArray.push({
          label: value.dataResourceId,
          value: value.dataResourceId
        })
      }
    })
  }
  getDataTemplateType() {
    this.spinner.show();
    this.myContactsService.getDataTemplateType().subscribe(res => {
      this.dataTemplateType = res.data.getDataTemplateType;
      this.spinner.hide();
      for (const value of this.dataTemplateType) {
        this.dataTemplateTypeArray.push({
          label: value.description,
          value: value.dataTemplateTypeId
        })
      }
    })
  }
 
  getCatalogContentId() {
    this.spinner.show();
    this.accountsService.getCatalogContentId().subscribe(res => {
      this.CatalogContentId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.CatalogContentId) {
        this.CatalogContentIdArray.push({
          label: value.contentName,
          value: value.contentId
        })
      }
    })
  }
  // onUpload() {
  //   this.spinner.show();
  //   const formData = new FormData();
  //   formData.append("file", this.fileData);

  //   this.accountsService.attachFile(formData).subscribe((res: any) => {
  //     if (res.success) {
  //       this.spinner.hide();
  //       this.uploadURL = res.data.URL;
  //       this.persistContentStuff();
  //       this.closebutton2.nativeElement.click();
  //       this.toastr.success("Success");
  //       this.cmsContentForm.reset();

  //     }
  //   }, (err) => {
  //     this.toastr.error(err.message);
  //     this.spinner.hide();
  //   })

  // }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];

  }
  onUpload(): void {
    this.spinner.show();
    const requestData = {

      "_rowCount": "1",
      "caContentAssocPredicateId": this.cmsContentForm.value.contentAssocPredicateID,
      "caContentAssocTypeId": this.cmsContentForm.value.contentAssocTypeID,
      "caContentIdTo": this.cmsContentForm.value.contentIDTo,
      "caFromDate": this.datePipe.transform(this.cmsContentForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "caMapKey": this.cmsContentForm.value.mapKey,
      "caThruDate": this.datePipe.transform(this.cmsContentForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"),

      "characterSetId": this.cmsContentForm.value.characterSetID,
      "contentAssocTitle": "",
      "contentId": this.cmsContentForm.value.contentID,
      "contentName": this.cmsContentForm.value.contentName,
      "contentPurposeString": "ARTICLE",
      "contentTitle": "",
      "contentTypeId": this.cmsContentForm.value.contentTypeID,
      "createdByUserLogin": "admin",
      "createdDate": this.datePipe.transform(new Date(), "yyyy-MM-dd hh:mm:ss"),
      "dataResourceId": this.cmsContentForm.value.dataResourceID,
      "dataResourceTitle": "",
      "deactivateExisting": true,
      "description": this.cmsContentForm.value.description,

      "drCharacterSetId": this.cmsContentForm.value.characterSetID1,
      "drDataCategoryId": this.cmsContentForm.value.dataCategory,
      "drDataResourceTypeId": "",
      "drDataSourceId": this.cmsContentForm.value.dataSource,
      "drDataTemplateTypeId": this.cmsContentForm.value.drDataTemplateTypeID,
      "drLocaleString": this.cmsContentForm.value.localeString1,
      "drMimeTypeId": this.cmsContentForm.value.drMimeTypeID,
      "drObjectInfo": this.cmsContentForm.value.filePath,
      "imageData": "",
      "imageDataTitle": "",
      "lastModifiedByUserLogin": "admin",
      "lastModifiedDate": this.datePipe.transform(this.cmsContentForm.value.throughDate,"yyyy-MM-dd hh:mm:ss"),
      "localeString": this.cmsContentForm.value.localeString,
      "masterContentId": "",
      "master_caContentAssocTypeId": this.cmsContentForm.value.contentAssocTypeID,
      "master_caContentId": "",
      "master_caContentIdTo": this.cmsContentForm.value.contentIDTo,
      "master_caFromDate": this.datePipe.transform(this.cmsContentForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "master_contentId": this.cmsContentForm.value.contentIDTo,
      "master_drDataResource": "",
      "mimeTypeId": this.cmsContentForm.value.mimeTypeID,
      "nodeTrailCsv": "",
      "ownerContentId": this.cmsContentForm.value.ownerContentID,
      "statusId": this.cmsContentForm.value.statusID,
      "targetOperationString": "CONTENT_UPDATE|CONTENT_CREATE|CONTENT_CREATE_SUB",
      "templateDataResourceId": this.cmsContentForm.value.templateDataResourceID,
      "textData": this.cmsContentForm.value.textData,
      "textDataTitle": "",
    }
    this.myContactsService.persistContentStuff(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.closebutton2.nativeElement.click();
        this.cmsContentForm.reset();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCharacterSet() {
    this.spinner.show();
    this.accountsService.getCharacterSet().subscribe(res => {
      this.CharacterSetId = res.data.getCharacterSet;
      this.spinner.hide();
      for (const value of this.CharacterSetId) {
        this.CharacterSetIdArray.push({
          label: value.description,
          value: value.characterSetId
        })
      }
    })
  }
  getMimeTypeIdData() {
    this.spinner.show();
    this.myContactsService.getMimeTypeIdData().subscribe(res => {
      this.mimeTypeIdData = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.mimeTypeIdData) {
        this.mimeTypeIdDataArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }

  ContentAssocType() {
    this.spinner.show();
    this.myContactsService.ContentAssocType().subscribe(res => {
      this.ContentAssocTypeData = res.data.ContentAssocType;
      this.spinner.hide();
      for (const value of this.ContentAssocTypeData) {
        this.ContentAssocTypeDataArray.push({
          label: value.description,
          value: value.contentAssocTypeId
        })
      }
    })
  }
  DataResourceTypeDROP() {
    this.spinner.show();
    this.myContactsService.DataResourceTypeDROP().subscribe(res => {
      this.resourceTypeDROP = res.data.DataResourceType;
      this.spinner.hide();
      for (const value of this.resourceTypeDROP) {
        this.resourceTypeDROPArray.push({
          label: value.description,
          value: value.dataResourceTypeId
        })
      }
    })
  }
  contentTypeIds() {
    this.spinner.show();
    this.myContactsService.contentTypeIds().subscribe(res => {
      this.contentTypeIdData = res.data;
      this.spinner.hide();
      for (const value of this.contentTypeIdData) {
        this.contentTypeIdDataArray.push({
          label: value.description,
          value: value.contentTypeId
        })
      }
    })
  }
  metaDataPredicate() {
    this.spinner.show();
    this.myContactsService.metaDataPredicate().subscribe(res => {
      this.DataPredicate = res.data[0].metaDataPredicate;
      this.spinner.hide();
      for (const value of this.DataPredicate) {
        this.DataPredicateArray.push({
          label: value.description,
          value: value.metaDataPredicateId
        })
      }
    })
  }

  forumFilter(){
    this.spinner.show();
    this.myContactsService.forumFilter().subscribe(res=>{
      this.forumFilterData = res.data[0].data;
      this.forumFilterList = res.data[0].dataNodeName[0];
      this.spinner.hide();
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  updateSiteRoles(): void {
    this.spinner.show();
    const requestData = {
      "_rowCount": "[]",
      "contentId_o_0":  this.forumFilterData.contentIdTo,
      "forumId": this.forumFilterData.contentId,
      "partyId_o_0": "",
      "permRoleSiteId":  this.forumFilterData.contentIdTo,  
      "rootForumId": "WebStoreFORUM",
      "submitBtn": "Update"
    }
    this.myContactsService.updateSiteRoles(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.userRolesForm.reset();
        this.closebutton3.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  postNewSubSite(): void {
    this.spinner.show();
    const requestData = {
      "contentAssocTypeId":"SUBSITE" ,
      "contentIdTo": "WebStoreFORUM",
      "contentName": this.addTopLevelForumForm.value.siteName,
      "contentTypeId": this.forumFilterList.contentTypeId,  
      "description": this.addTopLevelForumForm.value.siteDescription,
      "ownerContentId":this.forumFilterList.ownerContentId,
      "rootForumId": "WebStoreFORUM",
      "statusId": this.addTopLevelForumForm.value.postedMsgDefaultStatus,
      "submitBtn": "Create"
    }
    this.myContactsService.postNewSubSite(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.addTopLevelForumForm.reset();
        this.closebutton1.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  removeSite() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.myContactsService.removeSite(this.forumFilterData.contentAssocTypeId,this.forumFilterData.contentId,"WebStoreFORUM").subscribe(res => {
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  openContent()
  {
    this.router.navigate(['/content/cms/']);
  }
  openSubSites()
  {
    this.router.navigate(['/content/cms/subsites']);

  }
  openIndex()
  {
    this.router.navigate(['/content/cms/index']);

  }
  openFind()
  {
    this.router.navigate(['/content/cms/find']);

  }
  openSearchProducts()
  {
    this.router.navigate(['/content/cms/search-products']);

  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

}

ngOnDestroy(): void {
  this.spinner.hide();
    }

    changeactiveCategory(tab: number)
    {
      this.activeCategory = tab;
    
    }

    customerActiveCategoryFunction(tab: number)
    {
      this.customerCategoryPopup = tab;
    
    }
    
}
