<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
       <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Product Quantity


        </span>
        <span class="color-black pl-1"> >Add Product Quantity



        </span>

        <span class="">

          <button type="submit" (click)="homeButton();"
              class=" btn btn-outline-secondary mr-2">Home</button>
  
          <button type="submit" (click)="cancelSubmit();"
              class="btn btn-danger buttonclass ">Back</button>
      </span>

      </div>
      <div class=" bg-white color-grey">
        <div class="w3-card-4 classCard">
          <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass">

              <li [ngClass]="activeCategory==2?'active':''"><a>
                  Add Product Quantity



                </a></li>



            </ul>
          </div>


          <div class="create-new-leade mt-2">
            <div class="container-fluid">
              <div class="row">
                <form class="w-100" [formGroup]="createQuantity">
                  <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="col-lg-12">
                      <div class="row">
                          <div class="col-lg-3 form-group rightForm">
                            <label for="exampleInputEmail1">
                              Product ID<span
                              style="color:red">*</span></label>
                          </div>
                          <div class="col-lg-2">
                            <p-dropdown placeholder="Enter Product ID" filter="true"
                            [options]="productListArray" optionlabel="label" formControlName="ProductID">
    
                            </p-dropdown>
                          </div>
                          <div class="col-lg-3 form-group rightFormA">
                            <label for="exampleInputEmail1">
                              Add Qty Produced<span
                              style="color:red">*</span></label>
                          </div>
                          <div class="col-lg-2">
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Enter Add Qty Produced" formControlName="AddQtyProduced">
  
                          </div>
                      </div>
                  </div>
                
                  <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-3 form-group rightForm">
                          <label for="exampleInputEmail1">
                            Lot ID</label>
                        </div>
                        <div class="col-lg-2">
                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                          placeholder="Enter Lot ID" formControlName="LotID">


                        </div>
                        <div class="col-lg-2"></div>
                       
                    </div>
                </div>






                  </div>


                </form>
                <div class="main-submit-button" style="margin-left: 60%;">

                  <button type="submit"  (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>

                </div>
                <div class="all-users-infomation font-13 font-medium own-user-select">



                  <div class="w3-container">
                    <div class="panel-body">
                      <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                        <div class="card own-account-table">
                          <p-table [value]="productTask" [paginator]="true" [rows]="rows" scrollWidth="100%"
                          [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true"
                            (sortFunction)="customSort($event)" [customSort]="true">
                            <ng-template pTemplate="header">
                              <tr style="background: #0d3769;">
                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                  <div class="checkbox-align" style="color: white;">
                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>


                                    Inventory Item
                                    <p-sortIcon field="code"></p-sortIcon>
                                  </div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </th>


                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                  <div style="color: white;"> 	Product ID <p-sortIcon field="name"></p-sortIcon>
                                  </div>
                                 </th>
                                 <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                  <div style="color: white;"> Lot Id <p-sortIcon field="name"></p-sortIcon>
                                  </div>
                                 </th>
                                 <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                  <div style="color: white;"> 	Quantity <p-sortIcon field="name"></p-sortIcon>
                                  </div>
                                 </th>
                               


                              </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product>
                              <tr>
                                <td [ngStyle]="{'width':'170px'}">
                                  <div class="checkbox-align">
                                    <p-checkbox _ngcontent-egj-c329=""
                                    styleclass="custom-checkbox" binary="true"
                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                    <div
                                        class="custom-checkbox ui-chkbox ui-widget">
                                        <div class="ui-helper-hidden-accessible">
                                            <input type="checkbox" name="undefined"
                                                value="undefined"></div>
                                        <div role="checkbox"
                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                            <span
                                                class="ui-chkbox-icon ui-clickable"></span>
                                        </div>
                                    </div>
                                    <!---->
                                </p-checkbox>
                                    {{product.inventoryItemId}}
                                  </div>
                                </td>
                                <td [ngStyle]="{'width':'170px'}">
                                  {{product.productId}}                          
                                </td>
                                <td [ngStyle]="{'width':'170px'}">
                                  {{product.lotId}}                          
                                </td>
                                <td [ngStyle]="{'width':'170px'}">
                                  {{product.quantityOnHandTotal}}                          
                                </td>


                              </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                              <tr>
                                <td>
                                  <span class="text-center">
                                    <h4 class="ml-2">No Record Found
                                    </h4>
                                  </span>
                                </td>
                              </tr>
                            </ng-template>
                          </p-table>
                          <p class="paginate_data">
                            View per page

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>