import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
@Component({
  selector: 'app-security-group',
  templateUrl: './security-group.component.html',
  styleUrls: ['./security-group.component.css']
})
export class SecurityGroupComponent implements OnInit {
  total=0;
  @ViewChild('closebutton') closebutton;
  activeCategory=2;
  rowNumber=0;
  pageSize= 500;
  rows = 50;
  createGroup: FormGroup;
  pageNo=1;
  search="";
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  securtiyList: any;
  descriptionIdArray: any[]=[];
  groupIdIdArray: any[]=[];
  advanceSearch: {
       
    description:string,
    descriptionSearchType:string,
    groupId:string,
    groupIdSearchType:string,
   

  };
  Url: string;
  show: boolean;
  constructor(private accountsService: AccountsService,
    private headerService : HeaderService,
    readonly _FormBuilder: FormBuilder, 


    private _location: Location,

    readonly spinner: NgxSpinnerService,
    readonly toastr: ToastrService,
    readonly router: Router) { 
      this.Url="/users/create-security-group"
      this.advanceSearch = {
       
        description: "",
        descriptionSearchType: "Contains",
        groupId: "",
        groupIdSearchType: "Contains",
       

      };
     
      this.createGroup = this._FormBuilder.group({
        description: [''],
        groupId: ['',[Validators.required]],
        groupName: [''],
        
        
      });
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getUserDetails();
    this.show=false;
  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  getUserDetails(){
    this.spinner.show();
    const req={
  "description": "",
  "descriptionSearchType": "Contains",
  "groupId": "",
  "groupIdSearchType": "Contains"
     
    }
    this.accountsService.getSecurityGroupList(this.user,req).subscribe((res:any)=> {
      this.securtiyList = res.data;
      this.spinner.hide();
      for(const value of this.securtiyList){
      this.groupIdIdArray.push({
        label:value.groupName,
        value:value.groupId
      })
    } 
    for(const value of this.securtiyList){
      this.descriptionIdArray.push({
        label:value.description,
        value:value.description
      })
    } 
    })   
   }
   createUser(){
    this.router.navigate([this.Url])
    
   }
   homeButton(){
    this.router.navigate(['/users/company-information-import'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
   advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.securtiyList=[];
    const req = {
      "description": this.advanceSearch.description ? this.advanceSearch.description : '',
      "descriptionSearchType": "Contains",
       "groupId":this.advanceSearch.groupId ? this.advanceSearch.groupId : '',
       "groupIdSearchType": "Contains"
    }
    this.accountsService.getSecurityGroupList(this.user,req)
      .subscribe(resp => {
        if (resp.success) {
         
          this.securtiyList = resp.data;
          this.spinner.hide();
         
        } else {
     
        }
      }, (err) => {
        for (const value of err.error.errors) {
        }
      });
    
  } 
  reset(){
    this.spinner.show();
    this.advanceSearch.description="";
    this.advanceSearch.groupId=""
    const req={
      "description": "",
      "descriptionSearchType": "Contains",
      "groupId": "",
      "groupIdSearchType": "Contains"
         
        }
        this.accountsService.getSecurityGroupList(this.user,req).subscribe((res:any)=> {
          this.securtiyList = res.data;
          this.spinner.hide();
         
        })
       
  }
  editSecurity(id){
    this.router.navigate([this.Url],{queryParams:{groupId:id}})
  }
  permisiionPage(id){
    this.router.navigate(["/users/detail-page"],{queryParams:{groupId:id}})
  }
  submit(): void {
    this.spinner.show();
     const requestData ={
     
         "description": this.createGroup.value.description,
         "groupId": this.createGroup.value.groupId,
         "groupName":this.createGroup.value.groupName    
         
     }
     this.accountsService.saveSecurityGroup(requestData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.getUserDetails();
         this.createGroup.reset();
         this.closebutton.nativeElement.click();
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
