
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LeadGroupModel } from './lead-personal-group/lead-personal-group-model';


@Injectable({
  providedIn: 'root'
})
export class LeadNewService {
  lead_group_form : FormGroup;

  constructor(
    public _formBuilder: FormBuilder,
    public leadGroupModel: LeadGroupModel
  ) { }


  leadPersonaltGroup(data:any): void {
    if (!this.lead_group_form) {
        this.lead_group_form = this._formBuilder.group(this.leadGroupModel.mapDataToModel(data));
       
    }
  }


}
