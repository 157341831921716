<div class="container-fluid main-container-wrapper">
  <div class="row">
      <div class="col-12">
          <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
             <span class="color-black pl-1 titlePanels">Content</span>

             <span>
              <button type="submit" class="btn btn-secondary submit-btn mr-2"
                  (click)="toDashboard()">Home</button>
              <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
          </span>
          
          </div>
          <div class="bg-white color-grey" style="padding-bottom: 70px;">
              <div class="w3-card-4 classCard">
                  <div class="container-fluid">

                      <ul class="tabbing-section tabbing-accordians tabClass mt-0" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                          <li [ngClass]="activeCategory==1?'active':''" (click)="findPaycheck()"><a>
                              Find Paycheck</a></li>
                          <li [ngClass]="activeCategory==2?'active':''" (click)="createPaychecks()" ><a>
                              Create Paycheck</a></li>
                          <li [ngClass]="activeCategory==3?'active':''" (click)="paycheckConfiguration()" ><a>
                              Paycheck Configuration</a></li>
                          <li [ngClass]="activeCategory==4?'active':''"  (click)="employeess()"><a>
                              Employeess</a></li>

                              <li [ngClass]="activeCategory==5?'active':''"  (click)="contractReferencees()"><a>
                                Contract Reference</a></li>
          
                          <li [ngClass]="activeCategory==6?'active':''" (click)="reports()"><a>
                              Reports</a></li>

                      </ul>
                  </div>
                  <div class="create-new-leade">
                      <div class="container-fluid" style="padding: 0;">
                          <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                              <div class="w-100">
                                  <div *ngIf="activeCategory==4">
                                    <div class="container-fluid ">
                                      <div class="row">
                                        <div class="col-12">
                                          <div
                                          >
                                           <span class="color-grey"></span>
                                          </div>
                                          <div class="bg-white color-grey">
                                            <div class="create-new-leade">
                                              <div class="container-fluid">
                                                <div class="row">
                                                  <form class="w-100 col-4 mt-2" *ngFor="let employee of employees">
                                                    <div class="w3-card-4 classCard">
                                                      <div class="card" style="max-width: 540px;border:0px;">
                                                        <div class="row no-gutters">
                                                          <div class="col-md-4" style="padding:1rem 0rem 1rem 1rem;">
                                                            <img src="https://www.pinpng.com/pngs/m/341-3415688_no-avatar-png-transparent-png.png" class="card-img" alt="avatar" />
                                                          </div>
                                                          <div class="col-md-8">
                                                            <div class="card-body">
                                                              <strong class="card-title title p-3">{{ employee.partyId}}</strong>
                                                              <p class="card-text  p-2"></p>
                                                            
                                                              <p class="card-text  p-2 ">
                                                                <small class="text-muted" style="font-size: 13px;">{{ employee.firstName + employee.lastName }}</small>
                                                              </p>
                                                              <p class="card-text  p-2">
                                                                <small class="text-muted" style="font-size: 13px;">{{ employee.userLogin }}</small>
                                                              </p>
                                                              <p class="card-text  p-2">
                                                                <small class="text-muted" style="font-size: 13px;">{{ employee.description }}</small>
                                                              </p>
                                                              <!-- <p class="card-text  p-2" *ngIf="employee.email">
                                                                <small class="text-muted" style="font-size: 13px;" *ngIf="employee.email.contactMechId">{{ employee.email.contactMechId }}</small>
                                                              </p>
                                                              <p class="card-text  p-2">
                                                                <small class="text-muted" style="font-size: 13px;"*ngIf="employee.email.infoString">{{ employee.email.infoString }}</small>
                                                              </p> -->
                                                              
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
          </div>
      </div>
  </div>
</div>


