import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';  import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-edit-product-store-group',
  templateUrl: './edit-product-store-group.component.html',
  styleUrls: ['./edit-product-store-group.component.css']
})
export class EditProductStoreGroupComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;

  activeCategory = 1;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  addStoreGroup: any;
  ProductStoreGroupMemberById: any;
  ProductStoreGroupRollUpById: any;
  productStoreGroupId: any;
  ProductStoreType: any;
  ProductStoreTypeArray: any[]=[];
  PrimaryParentGroupID: any;
  PrimaryParentGroupIDArray: any[]=[];
  ProductStoreId: any;
  ProductStoreIdArray: any[]=[];
  addStoreGroup1: any;
  ProductStoreGroupById: any;
  fromDate: string;
  ProductStoreGroupAll: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,    private _location: Location,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) { 
    this.addStoreGroup = this._FormBuilder.group({
      storeType: [''],
      storeID: [''],
      name: [''],
      Description: [''],
    });
    this.addStoreGroup1 = this._FormBuilder.group({
      storeID: [''],
      fromDate: [''],
    });
  }

  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.productStoreGroupId = params["productStoreGroupId"];
    });
    this.getProductStoreGroupMemberById();
    this.getProductStoreGroupRollUpById();
    this.getProductStoreType();
    this.getPrimaryParentGroupID();
    this.getProductStoreId();
    this.getProductStoreGroupById();
    this.getProductStoreGroupAll();
  }
  getProductStoreGroupById() {
    this.spinner.show();
    this.accountsService.getProductStoreGroupById(this.productStoreGroupId).subscribe(res => {
      this.ProductStoreGroupById = res.data[0].getProductStoreGroupById[0];
      setTimeout(() => {
        const formValue = this.addStoreGroup;
        formValue.patchValue({
          storeType: this.ProductStoreGroupById.productStoreGroupTypeId,
          storeID: this.ProductStoreGroupById.productStoreGroupId,
          name: this.ProductStoreGroupById.productStoreGroupName,
          Description: this.ProductStoreGroupById.description,
        })
  
      }, 2000);
      this.spinner.hide();
    })
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  updateProductStoreGroup(): void {
    this.spinner.show();
    const requestData ={
      "description": this.addStoreGroup.value.Description,
      "primaryParentGroupId": this.addStoreGroup.value.storeID,
      "productStoreGroupId": this.productStoreGroupId,
      "productStoreGroupName": this.addStoreGroup.value.name,
      "productStoreGroupTypeId": this.addStoreGroup.value.storeType
      }
     
    this.accountsService.updateProductStoreGroup(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  createProductStoreGroupMember(): void {
    this.spinner.show();
    const fromDate=this.addStoreGroup1.get('fromDate').value;
    this.fromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "fromDate": this.fromDate,
      "productStoreGroupId": this.productStoreGroupId,
      "productStoreId": this.addStoreGroup1.value.storeID
      }
     
    this.accountsService.createProductStoreGroupMember(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getProductStoreGroupMemberById();
        this.addStoreGroup1.reset();
        this.closebutton1.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getProductStoreType() {
    this.spinner.show();
    this.accountsService.getProductStoreType().subscribe(res => {
      this.ProductStoreType = res.data[0].getType;
      this.spinner.hide();
      for (const value of this.ProductStoreType) {
        this.ProductStoreTypeArray.push({
          label: value.description,
          value: value.productStoreGroupTypeId
        })
      }
    })
  }
  getPrimaryParentGroupID() {
    this.spinner.show();
    this.accountsService.getPrimaryParentGroupID().subscribe(res => {
      this.PrimaryParentGroupID = res.data[0].getPrimaryParentGroupID;
      this.spinner.hide();
      for (const value of this.PrimaryParentGroupID) {
        this.PrimaryParentGroupIDArray.push({
          label: value.productStoreGroupName+"["+value.productStoreGroupId+"]",
          value: value.productStoreGroupId
        })
      }
    })
  }
  getProductStoreId() {
    this.spinner.show();
    this.accountsService.getProductStoreId().subscribe(res => {
      this.ProductStoreId = res.data[0].pStoreData;
      this.spinner.hide();
      for (const value of this.ProductStoreId) {
        this.ProductStoreIdArray.push({
          label: value.storeName+"["+value.productStoreId+"]",
          value: value.productStoreId
        })
      }
    })
  }
  getProductStoreGroupAll() {
    this.spinner.show();
    this.accountsService.getProductStoreGroupAll().subscribe(res => {
      this.ProductStoreGroupAll = res.data;
          this.spinner.hide();
    })

  }
  getProductStoreGroupMemberById() {
    this.spinner.show();
    this.accountsService.getProductStoreGroupMemberById(this.productStoreGroupId).subscribe(res => {
      this.ProductStoreGroupMemberById = res.data[0].getProductStoreGroupMemberById;
          this.spinner.hide();
    })

  }

  getProductStoreGroupRollUpById() {
    this.spinner.show();
    this.accountsService.getProductStoreGroupRollUpById(this.productStoreGroupId).subscribe(res => {
      this.ProductStoreGroupRollUpById = res.data[0].getProductStoreGroupMemberById;
      this.spinner.hide();
    })

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
