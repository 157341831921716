import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-segment-marketing',
  templateUrl: './segment-marketing.component.html',
  styleUrls: ['./segment-marketing.component.css']
})
export class SegmentMarketingComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  CreateNewSegmentGroupForm: FormGroup;
  SegmentGroupTypeID: any;
  SegmentGroupTypeIDArray: any[]=[];
  ProductStoreID: any;
  ProductStoreIDArray: any[]=[];
  SegmentGroupFrontPage: any;
  activeCategoriess=4;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.CreateNewSegmentGroupForm = this._FormBuilder.group({
      SegmentGroupTypeID: [''],
      ProductStoreID: [''],
      Description: [''],
      
    });
   }

  ngOnInit(): void {
    this.getSegmentGroupTypeID();
    this.getProductStoreID();
    this.getSegmentGroupFrontPage();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  accountDetails(id) {
    this._Router.navigate(["marketing/segment-id"], { queryParams: { segmentId: id } })
  }
  createSegmentGroup(): void {
    this.spinner.show();

    const requestData ={
      "description":  this.CreateNewSegmentGroupForm.value.Description,
      "productStoreId":  this.CreateNewSegmentGroupForm.value.ProductStoreID,
      "segmentGroupTypeId":  this.CreateNewSegmentGroupForm.value.SegmentGroupTypeID

      }
     
    this.accountsService.createSegmentGroup(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getSegmentGroupFrontPage(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  deleteSegmentGroup(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "segmentGroupId":product.segmentGroupId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
         
         
        }
        this.accountsService.deleteSegmentGroup(req).subscribe(res => {
          this.getSegmentGroupFrontPage();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getSegmentGroupFrontPage() {
    
    this.spinner.show();
    this.accountsService.getSegmentGroupFrontPage().subscribe(res => {
      this.SegmentGroupFrontPage = res.data[0].data;
      this.spinner.hide();
    
    })
  }
  getSegmentGroupTypeID() {
    
    this.spinner.show();
    this.myContactsService.getSegmentGroupTypeID().subscribe(res => {
      this.SegmentGroupTypeID = res.data[0].data;
      this.spinner.hide();
      for (const value of this.SegmentGroupTypeID) {
        this.SegmentGroupTypeIDArray.push({
          label: value.segmentGroupTypeId,
          value: value.segmentGroupTypeId
        })
      }
    })
  }
  getProductStoreID() {
    this.spinner.show();
    this.myContactsService.getProductStoreID().subscribe(res => {
      this.ProductStoreID = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ProductStoreID) {
        this.ProductStoreIDArray.push({
          label: value.storeName,
          value: value.productStoreId
        })
      }
    })
  }

  createcontent(){
    this._Router.navigate(['/marketing/create-content']);
    
  }
  openDataResource()
  {
    this._Router.navigate(['/marketing/data-source-marketing']);
  }
  openMarketingCampaign()
  {
    this._Router.navigate(['/marketing/marketing-campaign']);
  }
  openTracking()
  {
    this._Router.navigate(['/marketing/tracking-marketing/tracking-marketing']);
  }

  openSegment()
  {
    this._Router.navigate(['/marketing/segment-marketing']);
  }

  openContactList()
  {
    this._Router.navigate(['/marketing/contact-list-marketing']);
  
  }
  openReports()
  {
    this._Router.navigate(['/marketing/reports-marketing']);
  }

  openCustomer()
  {
    this._Router.navigate(['/crm/accounts']);

  }
  openActivities()
  {
    this._Router.navigate(['/crm/events']);

  }
  toOpportunity()
  {
    this._Router.navigate(['/crm/opportunities']);

  }
  MobileMessaging()
  {
    this._Router.navigate(['/#']);

  } 
  toCases()
  {
    this._Router.navigate(['/marketing/cases-marketing']);

  }
  SocialMedia()
  {
    this._Router.navigate(['/#']);

  }
  toAccount()
  {
    this._Router.navigate(['/crm/new-accounts']);
  }
  toContact()
  {
    this._Router.navigate(['/crm/my-contacts']);

  }
  toEmail(){
    this._Router.navigate(['/communication-overview/Dashboard']);
  }
  toForecast(){
    
    this._Router.navigate(['/crm/sales-forecast']);

  }
  toCalendar(){
    
    this._Router.navigate(['crm/sales-calendar']);

  }
  toNotes(){
    this._Router.navigate(['/crm/sales-notes']);
  }
  toEmails(){
    
    this._Router.navigate(['/communication-overview/Dashboard']);

  }  
  toTasks(){
    this._Router.navigate(['/crm/Tasks']);
  }
  toDocuments(){
    
    this._Router.navigate(['crm/sales-documents']);

  }
  toLead()
  {
    this._Router.navigate(['/crm/leads']);

  }
  changeActiveCategoryFunctions(tab)
  {
    this.activeCategoriess=tab;
  }
  
  
}
