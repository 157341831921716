<div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

<div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headinSeven">
      <h4 class="panel-title">
      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
        Import Contact Data
    
      </a>
    </h4>
    </div>
    <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
      <div class="panel-body">
          <form [formGroup]="Editform">
        <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-4 col-12">
                <div class="form-group">
                    <label for="exampleInputEmail1">Attach Content</label>
                    <div class="suppliers-wrapper">
                       
                        <input type="file" formControlName="file" (change)="fileProgress($event)" class="" id="exampleInputEmail1" aria-describedby="emailHelp"  accept="">
                      
                    </div>
                    <button type="button" (click)="onSubmit()" class="btn btn-secondary">Submit</button>
                    
                </div>
            </div>
        </div>
  </form>
    </div>
    </div>
  </div>
  </div>
  <ngx-spinner></ngx-spinner>