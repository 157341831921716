import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { InventoryLabelsService } from './inventory-labels.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-inventory-labels',
  templateUrl: './inventory-labels.component.html',
  styleUrls: ['./inventory-labels.component.css']
})
export class InventoryLabelsComponent implements OnInit {
  products3: any;
  total=0;
  @ViewChild('closebutton') closebutton;
  activeCategory = 3;
  activeCat = 3;
  activeCategoryMain=1;
  rowNumber=0;
  pageSize = 10;
  rows = 50;

  editMode: boolean;
  inventoryLabel: FormGroup;

  inventoryItemLabelList: any=[];
  inventoryItemLabelTypeId:any=[];
  inventoryItemLabelTypeIdArray: any[]=[];
  
  constructor(readonly accountsService: AccountsService,
  private _location: Location,
  readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly InventoryLabelsService: InventoryLabelsService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder) {
      this.inventoryLabel = this._FormBuilder.group({
        inventoryItemLabelId: ['', [Validators.required]],
        inventoryItemLabelTypeId: [null],
        description: [''],
      });
      this.editMode = false;
     }
     ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
      this.products3 = this.accountsService.customerDetails()
      
      this.getfacilityGroupTypeList();
      this.getInventoryItemLabelTypeId();
    }
    homeButton(){
      this.router.navigate(['/facilities/Dashboard'])
    
    }
    cancelSubmit(){
      this._location.back();
    }
    Main(){
      this.router.navigate(["/facilities/warehouse"])
    }
    Facility(){
      this.router.navigate(["/facilities/facility-group"])
    }
    Inventory(){
      this.router.navigate(["/facilities/inventory-labels"])
    }
    Shipments(){
      this.router.navigate(["/facilities/warehouse-shipment"])
    }
    Label(){
      this.router.navigate(["/facilities/inventory-labels/label-types"])
    }
    InventoryItemLabels(){
      this.router.navigate(["/facilities/inventory-labels"])
    }
    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;
  
          if (value1 == null && value2 != null){
            result = -1;
          }
          else if (value1 != null && value2 == null){
            result = 1;
          }
          else if (value1 == null && value2 == null) {
              result = 0;
           }
          else if (typeof value1 === 'string' && typeof value2 === 'string') {
              result = value1.localeCompare(value2);
          }
          else {
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
      });
    }

    getfacilityGroupTypeList() {
      this.spinner.show();
      this.inventoryItemLabelList = [];
      this.InventoryLabelsService.getinventoryItemLabelList().subscribe((responce) => {
        this.spinner.hide();
        this.inventoryItemLabelList = responce.data;
      });
     
    }

    getInventoryItemLabelTypeId() {
      this.spinner.show();
      this.InventoryLabelsService.getInventoryItemLabelTypeId().subscribe((responce) => {
        this.spinner.hide();
        this.inventoryItemLabelTypeId = responce.data;
        for(const value of this.inventoryItemLabelTypeId) {
          this.inventoryItemLabelTypeIdArray.push({
            label:value.inventoryItemLabelTypeId,
            value:value.inventoryItemLabelTypeId
          })
        }
      });

    }

    onSubmit(): void {
      this.spinner.show();
      if (this.editMode) {
      
        this.InventoryLabelsService.updateInventoryItemLabel({...this.inventoryLabel.value }).subscribe((res: any) => {
      
          if (res.success) {
            this.toastr.success(res.data.successMessage);
            this.spinner.hide();
            this.getfacilityGroupTypeList();
            this.inventoryLabel.reset();
           
            this.closebutton.nativeElement.click();
          }
  
        }, (err) => {
          this.spinner.hide();
          for (const value of err.error.error) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        });
       
      } else {
        this.InventoryLabelsService.createInventoryItemLabel(this.inventoryLabel.value).subscribe((res: any) => {
         
          if (res.success) {
            this.toastr.success(res.data.successMessage);
            this.spinner.hide();
            this.getfacilityGroupTypeList();
            this.inventoryLabel.reset();
            this.closebutton.nativeElement.click();
          }
        }, (err) => {
          this.spinner.hide();
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        });
      }
    
    }

    getData(data){
      this.editMode = true;
      this.inventoryLabel.patchValue({
        inventoryItemLabelTypeId: data.inventoryItemLabelTypeId,
        inventoryItemLabelId: data.inventoryItemLabelId,
        description: data.description,
      });
}

delete(data): void{
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      var id = data.inventoryItemLabelId;
      this.InventoryLabelsService.deleteCatalogStore(id).subscribe((responce: any) => {
        if (responce.success) { 
          this.getfacilityGroupTypeList();
        }}, (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }})}

scroll(el: HTMLElement) {
  el.scrollIntoView({behavior: 'smooth'});

}

cancel(){
  if(this.editMode){
    this.editMode = false;
    this.inventoryLabel.patchValue({
        inventoryItemLabelId: [''],
        inventoryItemLabelTypeId: [''],
        description: [''],
    });
  }
  else{
    this.inventoryLabel.patchValue({
        inventoryItemLabelId: [''],
        inventoryItemLabelTypeId: [''],
        description: [''],
    });
  }
}
mainOpen(){
  this.router.navigate(['/facilities/warehouse']);
}
ShippingOpen(){
  this.router.navigate(["/facilities/warehouse-shipment-config"])
}
dashboardOpen(){
  this.router.navigate(["/facilities/warehouse-dashborad-overview"])
}
facilityGroupOpen(){
  this.router.navigate(['/facilities/facility-group']);
}

inventoryItemOpen(){
  this.router.navigate(['/facilities/inventory-labels']);
}
detailPage(id,invenId,des,date) {
  this.router.navigate(["/facilities/inventory-labels/detail-inventory-label-summary"], { queryParams: { id: id,invenId:invenId,des:des,date:date} })
}
ngOnDestroy(): void {
  this.spinner.hide();
    }
}
