<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels"> Customer Relationship Management </span>
                <span>
                    <!-- <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass"
                        (click)="backWindow()">Back</button> -->
                </span>
            </div>

            <div class=" bg-white color-grey">
                <div class="container-fluid">

                </div>

                <div>
                    <div class="container-fluid">
                        <div class="row" >
                            <form class="w-100">

                                <div class="w-100">
                                    <div class="panel-group">
                                        <div>
                                            <div style="width: 49.4%; float:left">
                                                <div class="w3-card-4 cardEffect" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Overview


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/crm-overview">
                                                                                    Overview



                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/crm-overview">
                                                                                    Comm Event

                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/crm-overview">
                                                                                    Event Purpose(s)
                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/crm-overview">
                                                                                    Role(s)

                                                                                </span>
                                                                            </div>

                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/crm-overview">
                                                                                    Content

                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/crm-overview">
                                                                                    Email From Unknown Party


                                                                                </span>
                                                                            </div>

                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/crm-overview">
                                                                                    Return




                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/crm-overview">
                                                                                    Find Order By Return


                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/crm-overview">
                                                                                    Orders

                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/order/create-sales-order">
                                                                                    Products


                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/crm-overview  ">
                                                                                    Find Communication By Order


                                                                                </p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Marketing


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/marketing/data-source-marketing">
                                                                                    Data Resource
                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/marketing/marketing-campaign">
                                                                                    Marketing Campaign

                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/marketing/tracking-marketing/tracking-marketing">
                                                                                    Tracking
                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/marketing/segment-marketing">
                                                                                    Segment

                                                                                </span>
                                                                            </div>

                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/marketing/contact-list-marketing">
                                                                                    Contact List


                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/marketing/reports-marketing">
                                                                                    Reports

                                                                                </span>
                                                                            </div>

                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/accounts">
                                                                                    Customer
                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/events">
                                                                                    Activities
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Leads
                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/leads">
                                                                                    Find Leads


                                                                                </p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                              
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Contacts

                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <p style="cursor: pointer;    color: #0000FF;"
                                                                        class="color-grey pl-20 col-lg-10 col-12"
                                                                        routerLink="/crm/my-contacts">
                                                                        Find Contacts
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                             
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Cases


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <p style="cursor: pointer;    color: #0000FF;"
                                                                        class="color-grey pl-20 col-lg-10 col-12"
                                                                        routerLink="/crm/Case">
                                                                        Find My Cases
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Teams


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <p style="cursor: pointer;    color: #0000FF;"
                                                                        class="color-grey pl-20 col-lg-10 col-12"
                                                                        routerLink="/crm/Teams">
                                                                        Find Teams


                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                                <br>
                                                <br>
                                            </div>
                                            <div style="width: 49.8%; float:right">
                                                <div class="w3-card-4 cardEffect" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Sales


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/accounts">
                                                                                    Account
                                                                                </p>
                                                                                <span style="  cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/leads">
                                                                                    Leads
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/my-contacts">
                                                                                    Contact
                                                                                </p>
                                                                                <span style="  cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/Cases">
                                                                                    Cases
                                                                                </span>
                                                                            </div>

                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/opportunities">
                                                                                    Opportunity

                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/events">
                                                                                    Activities

                                                                                </span>
                                                                            </div>

                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/order/quotations">
                                                                                    Quotes

                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/order/create-sales-order">
                                                                                    Sales order

                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                               
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Customer Service



                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/accounts">
                                                                                    Account
                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/events">
                                                                                    Activities
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/my-contacts">
                                                                                    Contact
                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/Cases">
                                                                                    Cases
                                                                                </span>
                                                                            </div>

                                                                        </div>

                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/order/quotations">
                                                                                    Quotes

                                                                                </p>
                                                                                <span style="   cursor: pointer;   color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/order/create-sales-order">
                                                                                    Sales order

                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                               
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Preferences

                                                            </h4>

                                                        </div>
                                                    </header>
                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/Preferences">
                                                                                    Manage Portal Pages

                                                                                </p>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Forecast


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <p style="cursor: pointer;    color: #0000FF;"
                                                                        class="color-grey pl-20 col-lg-10 col-12"
                                                                        routerLink="/crm/Forecast">
                                                                        Forecast


                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Documents

                                                            </h4>
                                                        </div>
                                                    </header>
                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/crm/documents">
                                                                                    Documents

                                                                                </p>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Partners


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <p style="cursor: pointer;    color: #0000FF;"
                                                                        class="color-grey pl-20 col-lg-10 col-12"
                                                                        routerLink="/crm/Partners-Competitors">
                                                                        Find Partners


                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                                <br>
                                           
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>