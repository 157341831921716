import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-filedservice',
  templateUrl: './filedservice.component.html',
  styleUrls: ['./filedservice.component.css']
})
export class FiledserviceComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 9;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  getFsmAssignmentAll: any;
  assignmentByIdNew: any;
  workAppointmentArray: { label: string; value: string; }[];
  workOrderFilter: FormGroup;
  workVal: any;
  FsmAssignmentFilter: any;
  constructor(
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.workAppointmentArray = [
      {
        label: 'Schedule',
        value: 'Schedule'
      },
      {
        label: 'Non-Schedule',
        value: 'Non-Schedule'
      }
    ];
    this.workOrderFilter=this._FormBuilder.group({
      workAppointment:['']
    });
   }

  ngOnInit(): void {
    this.getFsmAssignment();
    this.getFsmAssignmentFilter();
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this._Router.navigate(['/hub/Dashboard']);
}
  getFsmAssignmentFilter() {
    this.spinner.show();
    const requestData = {
      "appointmentType": this.workVal?this.workVal:''
    }
    this.accountsService.getFsmAssignmentFilter(requestData).subscribe(res => { 
      this.FsmAssignmentFilter = res.data[0].getFsmAssignment;
      this.spinner.hide();
    })
  }
  workOrderVal(e){
    this.workVal=e;
    this.getFsmAssignmentFilter();
  }
  onUpdate(workOrderId){
    this._Router.navigate(["/fsm/workorder-summary"],{queryParams:{workOrderId:workOrderId}})
  }

  getFsmAssignmentById(product) {
    this.spinner.show();
    this.accountsService.getFsmAssignmentById(product.workOrderId).subscribe(res => { 
      this.assignmentByIdNew = res.data[0].getFsmAssignment[0];
      this.spinner.hide();
    })
  }
  getFsmAssignmentByIdNew(product) {
    this.spinner.show();
    this.myContactsService.getFsmAssignmentByIdNew(product.workOrderId).subscribe(res => { 
      this.assignmentByIdNew = res.data[0];
      this.spinner.hide();
    })
  }
  getFsmAssignment() {
    this.spinner.show();
    this.accountsService.getFsmAssignment().subscribe(res => {
      this.getFsmAssignmentAll = res.data[0].getFsmAssignment;
      this.spinner.hide();
    })
  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }
  toStaffing(){
    this._Router.navigate(['fsm/staffing']);
  }

  toNewWorkOrder()
  {
    this._Router.navigate(['fsm/newWorkOrder']);
  }

  toCustomer()
  {
    this._Router.navigate(['fsm/customer'])
  }

  toReports()
  {
    this._Router.navigate(['/fsm/reports'])
  }

  toInvoices()
  {
    this._Router.navigate(['/fsm/invoices'])

  }

  toWorkOrder()
  {
    this._Router.navigate(['/fsm/main'])
  }

  toPeople()
  {
    this._Router.navigate(['/fsm/people'])
  }

  toTimeSheet()
  {
    this._Router.navigate(['/fsm/timesheet'])
  }

  toSetting()
  {
    this._Router.navigate(['/fsm/fsmSetting'])
  }

  
  

}
