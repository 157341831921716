import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';  
import { Location } from '@angular/common';

import { NewJobRequistionsService } from 'src/app/human-resource/recuitment/new-job-requisition/new-job-requisition.service';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-routing-task-skills',
  templateUrl: './create-routing-task-skills.component.html',
  styleUrls: ['./create-routing-task-skills.component.css']
})
export class CreateRoutingTaskSkillsComponent implements OnInit {
  createSkill:FormGroup;
  activeCategory=2;
  getSkill: any;
  routingTaskId: any;
  skillTypeArray: any[]=[];
  skillTypeIdEdit: any;
  estimatedNumPeopleEdit: any;
  estimatedDurationEdit: any;
  estimatedCostEdit: any;
  show: boolean;

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly productionService:ProductionService,
    readonly spinner: NgxSpinnerService,private _location: Location,
    readonly newJobRequistionsService: NewJobRequistionsService,
   
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createSkill = this._FormBuilder.group({
        SkillTypeID: [''],
        EstimatedNumPeople:[''],
        EstimatedDuration: [''],
        Estimatedcost:[''],
       
       
      });
    
  }
 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.routingTaskId = params["routingTaskId"];
      this.skillTypeIdEdit = params["skillTypeId"];
      this.estimatedNumPeopleEdit = params["estimatedNumPeople"];
      this.estimatedDurationEdit = params["estimatedDuration"];
      this.estimatedCostEdit = params["estimatedCost"];
    });
    this.getSkillTypeList();
    if(this.skillTypeIdEdit) {
      this.show=true;
      setTimeout(() => {
        const formValue = this.createSkill;
      formValue.patchValue({
        SkillTypeID: this.skillTypeIdEdit,
        EstimatedNumPeople:this.estimatedNumPeopleEdit,
        EstimatedDuration:  this.estimatedDurationEdit,
        Estimatedcost:this.estimatedCostEdit, 
      })
      }, 3000);
    }
    else {
      this.show = false;
    }
  }
  getSkillTypeList() {
    this.spinner.show();
    this.newJobRequistionsService.getSkillTypeList().subscribe(res => {
      this.getSkill = res.data;
      this.spinner.hide();
      for (const value of this.getSkill) {
        this.skillTypeArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }
    })
   
  }
  homeButton(){
    this._Router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "estimatedCost":this.createSkill.value.Estimatedcost,
      "estimatedDuration":this.createSkill.value.EstimatedDuration,
      "estimatedNumPeople": this.createSkill.value.EstimatedNumPeople,
      "skillTypeId":this.createSkill.value.SkillTypeID,
    }
    this.productionService.postProdSkill(this.routingTaskId,requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-routing-task-summary"], { queryParams: {  routingTaskId: this.routingTaskId } })
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "estimatedCost":this.createSkill.value.Estimatedcost,
      "estimatedDuration":this.createSkill.value.EstimatedDuration,
      "estimatedNumPeople": this.createSkill.value.EstimatedNumPeople,
      "skillTypeId":this.createSkill.value.SkillTypeID,
    }
    this.productionService.updateProdSkill(this.routingTaskId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(["production/detail-routing-task-summary"], { queryParams: {  routingTaskId: this.routingTaskId } })
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  // cancelSubmit(){
  //   this._Router.navigate(['/production/detail-routing-task-summary'],{ queryParams: { routingTaskId :this.routingTaskId}})
  // }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}