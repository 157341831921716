import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../accounts/accounts.service';

import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { QuotesService } from '../quotes.service';
import * as moment from 'moment';
import { Location } from '@angular/common';


import { HeaderService } from '../../../header/header.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateQuetoFormService } from '../create-quotes/create-quotes-service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-my-quotes',
  templateUrl: './my-quotes.component.html',
  styleUrls: ['./my-quotes.component.css']
})
export class MyQuotesComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  currencyArray:any=[];
  setCurrency: any;
  activeCategory = 2;
  quotesId: any;
  resultData: any;
  show= false;
  productStores: any;
  productStore: any;
  productArray: any=[];
  salesChannelArray: any=[];
  quoteTypesArray: any=[];
  statusList: any;
  statusArray: any=[];
  partydata: any;
  partyData: any;
  partyarray: any=[];
  statusIds: any;
  statusArrays: any=[];
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  quetoList: any;
  currentDate: Date;
  public size = {
    pagesize: this.pageSize,
    pageNo: this.pageNo
  };
  storeData: any;
  opportunityIdArray: any[];
  filterArray: any[];
  dateFromFilter: any[];
  dateToFilter: any[];

  searchFilter: {
    currencyId: string,
    partyId: string,
    productStoreId: string,
    quoteId: string,
    quoteIdSearchType: string,
  
    quoteName: string,
    quoteNameSearchType: string,
    quoteTypeId: string,
    salesChannelId: string,
    statusId: string
  };
  currencyData: any;
  constructor(
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly quotesService: QuotesService,
    readonly HeaderService: HeaderService,
    readonly spinner:NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    public createQuetoFormService: CreateQuetoFormService,
    readonly activatedRoute:ActivatedRoute,
    readonly toastr:ToastrService,
    readonly datePipe:DatePipe,
    private _location: Location,
  
  ) {
    this.storeData = {};
    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Begins With',
        text: 'Begins With'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      }
    ];
    this.dateFromFilter = [
      {
        label: 'Greater Than',
        value: 'Greater Than'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Same Day',
        value: 'Same Day'
      },
      {
        label: 'Greater Than From day Start',
        value: 'Greater Than From day Start'
      }
    ];
    this.dateToFilter = [
      {
        label: 'Less Than',
        value: 'Less Than'
      },
      {
        label: 'Up To Day',
        value: 'Up TO Day'
      },
      {
        label: 'Up Through Day',
        value: 'Up Through Day'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      }
    ];
    this.searchFilter = {
      currencyId: null,
      partyId: '',
      productStoreId: null,
      quoteId: '',
      quoteIdSearchType: 'Contains',
     
      quoteName: '',
      quoteNameSearchType: 'Contains',
      quoteTypeId: null,
      salesChannelId: null,
      statusId: ''
    };
    this.currentDate = new Date();
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show=false;
    this.products3 = this.accountsService.customerDetails();

    this._ActivatedRoute.data.subscribe(data => {
      this.storeData = data.quote[0];
      this.currencyData = data.quote[1];
    });
    this.createQuetoFormService.create_quetos_form = null;
    this.createQuetoFormService.prepareQuetosForm(this.createQuetoFormService.create_quetos_form);
    this.getQuotoId();
    this.getStatusList();
    this.getstatusId();
  
    this.getCurrency();
    this.getPartyId();
    this.getQuetoList();
    this.getstatusIdData();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/order/main-order']);
  }

  getCurrency() {
    this.spinner.show();
    this.quotesService.getCurrency().subscribe(res=> {
      this.setCurrency = res.data.currencyList;
      this.spinner.hide();
     this.setCurrency.forEach(currencyList=> {
       this.currencyArray.push({
         label:currencyList.description + "-" +  currencyList.uomId,
         value:currencyList.uomId

       })
     })
      })
     
   }
   
   getStatusList(){
    this.spinner.show();
     this.quotesService.getstatusList().subscribe(res=> {
       this.statusList = res.data;
       this.spinner.hide();
       for(const value of this.statusList){
         this.statusArray.push({
           label:value.statusId,
           value:value.description
         })
       }

     })
    
   }
   getQuotoId(){
    this.spinner.show();
    this.quotesService.getQuotoId().subscribe(res=> {
      this.productStore = res.data;
      this.spinner.hide();
      for(const data of this.productStore.productStores){
        this.productArray.push({
          label:data.storeName,
          value:data.productStoreId,
        })
      }
      for(const data of this.productStore.salesChannels){
       this.salesChannelArray.push({
         label:data.description,
         value:data.enumId,
       })
     }
     for(const data of this.productStore.quoteTypes){
       this.quoteTypesArray.push({
         label:data.description,
         value:data.quoteTypeId,
       })
     }
    })
   
  }
 


  onSubmit() {
    this.spinner.show();
    this.createQuetoFormService.create_quetos_form.markAllAsTouched();
    const formValues = {...this.createQuetoFormService.create_quetos_form.value };
    this.quotesService.postQuotes(formValues).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success("Submitted Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getQuetoList();
        
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err)=> {
        if(err.error.success == false) {
          this.spinner.hide();
          if(err.error.errors)
          {
            for(const value of err.error.errors){
              this.toastr.error(value.fieldName + " " + value.fieldError);
              }
          }else if(err.error.success == false){
            this.toastr.error(err.error.message);
          }
        }
      })
      
  }

  getPartyId(){
    this.spinner.show();
    this.quotesService.getPartygroup().subscribe(res=> {
      this.partyData = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.partyData){
        this.partyarray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
 
  }
  getstatusIdData(){
    this.spinner.show();
    this.quotesService.status().subscribe(res=> {
       this.statusIds = (res.data.status);
       this.spinner.hide();
       for(const value of this.statusIds){
         this.statusArrays.push({
           label:value.description,
           value:value.statusId
         })
       }
    })
   
  }
  resetFilter(){
    this.spinner.show();
    this.searchFilter = {
      currencyId: null,
      partyId: '',
      productStoreId: null,
      quoteId: '',
      quoteIdSearchType: 'Contains',
     
      quoteName: '',
      quoteNameSearchType: 'Contains',
      quoteTypeId: null,
      salesChannelId: null,
      statusId: ''
    };
    this.spinner.hide();
    this.getQuetoList();
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  onStatusChange(event) {
   
    this.searchFilter.statusId = event.value.toString()
  }

  getstatusId() {
    this.spinner.show();
    this.quotesService.status().subscribe(res => {
      const statusIds = (res.data.status);
      this.spinner.hide();
      for (const value of statusIds) {
        this.statusArrays.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
   
  }

  quoteDetails(id): void {
    this.router.navigate(['/order/quote-details'], { queryParams: { quotoId: id } });
  }

  updateQuote(id): void {
    this.router.navigate(['/order/create-quotes'], { queryParams: { quotoId: id } })
  }
  createQuotation(): void {
    this.router.navigate(['/order/create-quotes'])
  }

  toCRM()
  {
    this.router.navigate(['/crm/main-overview'])

  }


  getQuetoList(): void {
    this.spinner.show();
    this.quotesService.getQueto(this.size, this.searchFilter).subscribe(res => {
      this.spinner.hide();
      this.quetoList = res.data;
    });
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
