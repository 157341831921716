import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import Swal from 'sweetalert2';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
@Component({
  selector: 'app-my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.css']
})
export class MyTasksComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  activeCategory = 2;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  projectForm: FormGroup;
  timesheetForm: FormGroup;
  newrequestForm: FormGroup;
  newTaskProject: FormGroup;
  existTaskProject: FormGroup;
  RoleResourceList: any;
  RoleResourceListArray: any[] = [];
  PartyIdListList: any;
  TasksNamesList: any;
  TasksNamesListArray: any[] = [];
  PartyIdArray: any[] = [];
  PartyId: any;
  endate: string;
  startdate: string;
  Scope: any;
  ScopeArray: any[] = [];
  PriorityList: any;
  PriorityListArray: any[] = [];
  SkillTypeId: any;
  SkillTypeIdArray: any[] = [];
  TypeList: any;
  TypeListArray: any[] = [];
  ParentPhaseArray: any[] = [];
  ParentPhase: any;
  TaskNameList: any;
  updateForm: FormGroup;
  updateProduct: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  HidebtnRoleView: boolean=false;
  HidebtnTaskCrud: boolean=false;
  HidebtnTimeCurd: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
        private _location: Location,
        readonly activatedRoute: ActivatedRoute,
    readonly myContactsService: MyContactsService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.newTaskProject = this._FormBuilder.group({
      taskName: "",
      parentPhase: "",
      sequenceNum: "",
      roleWeek: "",
      estimatedHours: "",
      skillTypeID: "",
      priority: "",
      type: "",
      description: "",
      scope: "",
      startDate: "",
      endDate: "",
    }),
      this.existTaskProject = this._FormBuilder.group({
        taskName: "",
        roleTypeID: "",
      }),
      this.updateForm = this._FormBuilder.group({
        partyId: "",
        roleTypeID: "",
      })
  }
  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.Hidebtn=true;
    }
      else if(this.userPermissions.PROJECTMGR_ROLE_VIEW=="PROJECTMGR_ROLE_VIEW"){
      this.HidebtnRoleView=true;
    }
     else if(this.userPermissions.PROJECTMGR_ROLE_TASK_CREATE=="PROJECTMGR_ROLE_TASK_CREATE"){
      this.HidebtnTaskCrud=true;
    }
    else if(this.userPermissions.PROJECTMGR_ROLE_TIMESHEET_CREATE=="PROJECTMGR_ROLE_TIMESHEET_CREATE"){
      this.HidebtnTimeCurd=true;
    }
   
    this.getRoleResourceList();
    this.getPartyIdList();
    this.getTasksNames();
    this.getScopeList();
    this.getSkillTypeId();
    this.getTypeList();
    this.getPriorityList();
    this.getTaskNameList();
    this.getParentPhaseList();
  }

  edit(product) {
    this.spinner.show();
    this.updateProduct = product;
    const form = this.updateForm;
    form.patchValue({
      roleTypeID: product.roleTypeID,
      partyId: product.partyId,
    });
    this.spinner.hide();
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  updateMyTask(updateProduct) {
    this.spinner.show();
    const formData = {
      "actualCompletionDate": this.datePipe.transform(updateProduct.actualCompletionDate, "yyyy-MM-dd hh:mm:ss"),
      "actualStartDate": this.datePipe.transform(updateProduct.actualStartDate, "yyyy-MM-dd hh:mm:ss"),
      "estimatedCompletionDate": this.datePipe.transform(updateProduct.estimatedCompletionDate, "yyyy-MM-dd hh:mm:ss"),
      "estimatedStartDate": this.datePipe.transform(updateProduct.estimatedStartDate, "yyyy-MM-dd hh:mm:ss"),
      "fromDate": this.datePipe.transform(updateProduct.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "newPartyId": this.updateForm.value.partyId,
      "newRoleTypeId": this.updateForm.value.roleTypeID,
      "partyId": this.updateProduct.partyId,
      "phaseName": this.updateProduct.phaseName,
      "plannedHours": "",
      "priority": this.updateProduct.priority,
      "projectName": this.updateProduct.projectName,
      "remove": "",
      "roleTypeId": this.updateProduct.roleTypeId,
      "statusId": this.updateProduct.currentStatusId,
      "toComplete": "",
      "workEffortId": this.updateProduct.workEffortId,

    }
    this.myContactsService.updateMyTask(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.updateProduct.reset();
        this.getTaskNameList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  getTaskNameList() {
    this.spinner.show();
    this.myContactsService.getTaskNameList().subscribe(res => {
      this.TaskNameList = res.data;
      this.spinner.hide();

    })
  }
  detailMyTasks(workEffortId) {
    this._Router.navigate(["/psa/project/detail-my-task"],
      { queryParams: { workEffortId: workEffortId } })
  }
  statusToComplete(product) {
    this.spinner.show();
    const formData = {
      "fromDate": product.fromDate,
      "partyId": product.partyId,
      "roleTypeId": product.roleTypeId,
      "statusId": "PAS_COMPLETED",
      "view_INDEX_0": "0",
      "view_SIZE_0": "20",
      "workEffortId": product.workEffortId

    }
    this.myContactsService.statusToComplete(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        //  this.closebutton2.nativeElement.click();
        this.getTaskNameList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }


  deleteTask(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "fromDate": "",
          "partyId": "admin",
          "roleTypeId": product.roleTypeId,
          "statusId": "PAS_ENDED",
          "workEffortId": product.workEffortId
        }
        this.accountsService.deleteNewTask(req).subscribe((res: any) => {
          this.toastr.success("Delete Successfully");
          this.getTaskNameList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getParentPhaseList() {
    this.spinner.show();
    this.myContactsService.getParentPhaseList().subscribe(res => {
      this.ParentPhase = res.data;
      this.spinner.hide();
      for (const value of this.ParentPhase) {
        this.ParentPhaseArray.push({
          label: value.getParentPhaseList.projectName + "--" + value.getParentPhaseList.phaseName,
          value: value.getParentPhaseList.phaseId
        })
      }
    })
  }
  getTypeList() {
    this.spinner.show();
    this.myContactsService.getTypeList().subscribe(res => {
      this.TypeList = res.data;
      this.spinner.hide();
      for (const value of this.TypeList) {
        this.TypeListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }
  getSkillTypeId() {
    this.spinner.show();
    this.myContactsService.getSkillTypeId().subscribe(res => {
      this.SkillTypeId = res.data;
      this.spinner.hide();
      for (const value of this.SkillTypeId) {
        this.SkillTypeIdArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }
    })
  }
  getPriorityList() {
    this.spinner.show();
    this.myContactsService.getPriorityList().subscribe(res => {
      this.PriorityList = res.data;
      this.spinner.hide();
      for (const value of this.PriorityList) {
        this.PriorityListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }
  createNewTask() {
    this.spinner.show();

    const startDate = this.newTaskProject.get('startDate').value;
    this.startdate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const endDate = this.newTaskProject.get('endDate').value;
    this.endate = this.datePipe.transform(endDate, "yyyy-MM-dd hh:mm:ss");

    const formData = {
      "currentStatusId": "PTS_CREATED",
      "description": this.newTaskProject.value.description,
      "estimatedCompletionDate": this.endate,
      "estimatedHours": this.newTaskProject.value.estimatedHours,
      "estimatedStartDate": this.startdate,
      "partyId": "admin",
      "priority": this.newTaskProject.value.priority,
      "projectId": "",
      "roleTypeId": this.newTaskProject.value.roleWeek,
      "scopeEnumId": this.newTaskProject.value.scope,
      "sequenceNum": this.newTaskProject.value.sequenceNum,
      "skillTypeId": this.newTaskProject.value.skillTypeID,
      "statusId": "PAS_ASSIGNED",
      "workEffortName": this.newTaskProject.value.taskName,
      "workEffortParentId": this.newTaskProject.value.parentPhase,
      "workEffortTypeId": this.newTaskProject.value.type,



    }
    this.myContactsService.createNewTask(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.newTaskProject.reset();
        this.getTaskNameList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  addAssignAndExistingTask() {
    this.spinner.show();
    const formData = {
      "partyId": "admin",
      "roleTypeId": this.existTaskProject.value.roleTypeID,
      "statusId": "PAS_ASSIGNED",
      "workEffortId": this.existTaskProject.value.taskName,

    }
    this.myContactsService.addAssignAndExistingTask(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.existTaskProject.reset();
        this.getTaskNameList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getScopeList() {
    this.spinner.show();
    this.myContactsService.getTaskScopeList().subscribe(res => {
      this.Scope = res.data;
      this.spinner.hide();
      for (const value of this.Scope) {
        this.ScopeArray.push({
          label: value.scopeData.description,
          value: value.scopeData.enumId
        })
      }
    })
  }
  getTasksNames() {
    this.spinner.show();
    this.myContactsService.getTasksNames().subscribe(res => {
      this.TasksNamesList = res.data;
      this.spinner.hide();
      for (const value of this.TasksNamesList) {
        this.TasksNamesListArray.push({
          label: value.node.workEffortName,
          value: value.node.workEffortId
        })
      }
    })
  }
  getPartyIdList() {
    this.spinner.show();
    this.myContactsService.getPartyIdList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  getRoleResourceList() {
    this.spinner.show();
    this.myContactsService.getRoleTaskList().subscribe(res => {
      this.RoleResourceList = res.data;
      this.spinner.hide();
      for (const value of this.RoleResourceList) {
        this.RoleResourceListArray.push({
          label: value.roleTypeData.description,
          value: value.roleTypeData.roleTypeId
        })
      }
    })
  }

  projectMain() {
    this._Router.navigate(["/psa/project/project"])
  }
  myTasks() {
    this._Router.navigate(["/psa/project/my-tasks"])
  }
  myTime() {
    this._Router.navigate(["/psa/project/my-time"])
  }
  projects() {
    this._Router.navigate(["/psa/project/projects"])
  }
  tasks() {
    this._Router.navigate(["/psa/project/tasks-project"])
  }
  resources() {
    this._Router.navigate(["/psa/project/resources"])
  }
  timesheet() {
    this._Router.navigate(["/psa/project/timesheet-project"])
  }

  skillType() {
    this._Router.navigate(["/psa/project/skill-type"])
  }
  requestList() {
    this._Router.navigate(["/psa/project/request-list"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();


  }
}
