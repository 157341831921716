import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from "../new-catagory/category.service";
import { MainCatalogService } from '../main-catalog-mgmt/catalog.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryFormService } from '../new-catagory/category-service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-catagories',
  templateUrl: './catagories.component.html',
  styleUrls: ['./catagories.component.css']
})
export class CatagoriesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  products3: any;
  total=0;
  rowNumber=0;
  pageNo = 1;
  pageSize= 100;
  rows = 50;
  activeCat=3;
  categorySize;

  search: {
    categoryId: string,
    categoryIdSearchType: string,
    categoryName: string,
    categoryNameSearchType: string
  };

  categoryList: any;
  categoryId: any;
  categories: any[];
  opportunityIdArray: any;
  isShown=false;

  data: any;
  activeCategory = 2;
  categoryArray: any = [];
  show = false;
  categoryForm: FormGroup;
  editMode: boolean;

  fileType: string;
  fileData: FormData;
  url: string;
  categoryFindForm: FormGroup;
  categoriesNew: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,  
    private _location: Location,
    readonly categoryService: CategoryService,
    readonly _MainCatalogService: MainCatalogService,
    readonly activatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    public categoryFormService: CategoryFormService,
    readonly _FormBuilder: FormBuilder,
    
  ) {
    this.categorySize = {
      pageNo: this.pageNo,
      pageSize: this.pageSize
    };
    this.search = {
      categoryId: '',
      categoryIdSearchType: 'Contains',
      categoryName: '',
      categoryNameSearchType: 'Contains'
    };
    this.categoryForm = this._FormBuilder.group({
      categoryImageUrl: [''],
      categoryName: [''],
      description: [''],
      detailScreen: [''],
      linkOneImageUrl: [''],
      linkTwoImageUrl: [''],
      primaryParentCategoryId: [''],
      productCategoryId: [''],
      productCategoryTypeId: ['', [Validators.required]]
    });
    this.categoryFindForm = this._FormBuilder.group({
      categoryId:[''],
      categoryName:['']
    })
    this.editMode = false;
    this.categories = [];
    this.fileType = '';
    this.fileData = new FormData();
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.Hidebtn=true;
    }
    this.getCategoryList();
   
    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Begins With',
        text: 'Begins With'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      }
    ];
    this.productCategoryList();
    this.activatedRoute.queryParams.subscribe(params => {
      this.categoryId = params["categoryId"];
      if (this.categoryId) {
        this.show = true;
      }
      else {
        this.show = false;
      }
    });
    if (this.categoryId) {
      this.editMode = true;


      this.categoryForm.controls.productCategoryId.disable();
      this.categoryForm.controls.productCategoryId.updateValueAndValidity();


    }
    this.getCategoryId();
    this.getCategoryListData();
    this.getCategorySearch();
  }

  homeButton(){
    this.router.navigate(['/myPortal/my-portal-my-profile'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  


  resetForm(){
    this.categoryFindForm.reset();
  }
  getCategoryListData(): void {
    this.spinner.show();
    this._MainCatalogService.getCategories(this.categorySize, this.search)
      .then(data => {
        this.categoryList = data.data;
        this.spinner.hide();
      });
     
  }
  productCategoryList(): void {
    this.spinner.show();
    this.categoryService.productCategoryTypeList().subscribe(res => {
      this.data = res.data;
      this.spinner.hide();
      this.categoryArray = res.data.map(value => {
        return {
          label: value.description,
          value: value.productCategoryTypeId
        };
      });
    
    });
   
  }
  onSubmit(): void {
    this.spinner.show();
    if (this.editMode) {
      if (this.categoryForm.valid) {
        this.onUpdate();
      } else {
        this.spinner.hide();
        this.toastr.error('Please select product category type');
      }  } else {
      if (this.categoryForm.valid) {
        this.categoryService.createCategory({
          ...this.categoryForm.value, ...{
            productCategoryId: this.categoryForm.value.productCategoryId ? this.categoryForm.value.productCategoryId : ''
          }
        }).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success(res.data.successMessage);
            this.spinner.hide();
            this.categoryForm.reset();
            this.closebutton.nativeElement.click();
            this.getCategoryListData();
          }
          else {
            this.spinner.hide();
            this.toastr.error(res.message);
          }
        }); } else {
        this.spinner.hide();
        this.toastr.error('Please select product category type');
      }} }
  onUpdate(): void {
    this.spinner.show();
    this.categoryService.updateCategory({
      ...this.categoryForm.value, ...{
        productCategoryId: this.categoryId
      }
    }, this.categoryId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.categoryForm.reset();
        this.closebutton.nativeElement.click();
        this.getCategoryListData();
      }
      else {
        this.spinner.hide();
        this.toastr.error(res.message);
      }
    });
   
  }

  getCategoryList(): void {
    this.spinner.show();
    this._MainCatalogService.getCategories({ pageNo: 1, pageSize: 200 }, { categoryId: this.categoryFindForm.value.categoryId?this.categoryFindForm.value.categoryId:'', categoryIdSearchType: 'Contains', categoryName: this.categoryFindForm.value.categoryName?this.categoryFindForm.value.categoryName:'', categoryNameSearchType: 'Contains' })
      .then(data => {
        this.spinner.hide();
        this.categories = data.data;
      });
     
  }
  getCategoryListNew(): void {
    this.spinner.show();
    this._MainCatalogService.getCategories({ pageNo: 1, pageSize: 200 }, { categoryId: this.categoryFindForm.value.categoryId?this.categoryFindForm.value.categoryId:'', categoryIdSearchType: 'Contains', categoryName: this.categoryFindForm.value.categoryName?this.categoryFindForm.value.categoryName:'', categoryNameSearchType: 'Contains' })
      .then(data => {
        this.spinner.hide();
        this.categories = data.data;
      });
     
  }
  getCategorySearch(): void {
    this.spinner.show();
    this.accountsService.getCategorySearch({ pageNo: 1, pageSize: 200 }, { categoryId: this.categoryFindForm.value.categoryId?this.categoryFindForm.value.categoryId:'', categoryIdSearchType: 'Contains', categoryName: this.categoryFindForm.value.categoryName?this.categoryFindForm.value.categoryName:'', categoryNameSearchType: 'Contains' }).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.categoriesNew = res.data;
        console.log("new",this.categoriesNew);

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  setImage(event: any): void {
    this.fileData.append('file', event.target.files[0]);
  }
  setCategoryImageType(fileType: string): void {
    this.fileType = fileType;
  }
  uploadImage(): void {
    this.spinner.show();
    if (this.fileType) {
      this.categoryService.uploadCategoryImage(this.categoryId, this.fileType, this.fileData)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.toastr.success('Uploaded');
            this.fileType = '';
            this.fileData.delete('file');
            this.router.navigate([this.url]);
          }
        });
    }
  
  }
  activeTab(tab: number): void {
    this.activeCategory = tab;
  }
  getCategoryId(): void {
    this.spinner.show();
    this.categoryService.getCategoryById(this.categoryId).subscribe(res => {
      this.categories = res.data;
      this.spinner.hide();
    });
 
  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  feautureValue(){
    this.isShown = !this.isShown;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
