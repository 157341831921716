import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder,FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-run-mrp-main',
  templateUrl: './run-mrp-main.component.html',
  styleUrls: ['./run-mrp-main.component.css']
})
export class RunMrpMainComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  activeCategory = 8;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  runMRPForm: FormGroup;
  ProductId: any;
  ProductIdArray: any[]=[];
  facility: any;
  facilityArray: any[]=[];
  suppliers: any[];
  yesNoArray: { label: string; value: string; }[];
  ProductProductStore: any;
  ProductProductStoreArray: any[]=[];
  MrpEventTypeId: any;
  MrpEventTypeIdArray:  any[]=[];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly mergeContactsService: MergeContactsService,
    readonly toastr: ToastrService,
    readonly _WarehouseService: WarehouseService,    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router
  ) 
  {
    this.runMRPForm=this._FormBuilder.group({
      facility:"",
      supplier:"",
      productStore:"",
      MRPStoreGroup:"",
      productId:"",
      defaultYears:"",
      inventoryReceipt:"",
      timeBuffer:"",
      percentageofSalesForecast:"",
      transferRequirements:"",
      pendingInternalRequirements:"",
    })
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
  }

  ngOnInit(): void {
    this.getProductIds();
    this.getFacilityId();
    this.getSuppliers();
    this.getProductProductStore();
    this.MrpEventType();
  }
  MrpEventType() {
    this.spinner.show();
    this.mergeContactsService.MrpEventType().subscribe(res => {
      this.MrpEventTypeId = res.data[0].MrpEventType;
      this.spinner.hide();
      for (const value of this.MrpEventTypeId) {
        this.MrpEventTypeIdArray.push({
          label: value.description,
          value: value.mrpEventTypeId
        })
      }
    })
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  createRunMrp(): void {
    this.spinner.show();
    const requestData = {
        "createPendingInternalRequirements": this.runMRPForm.value.pendingInternalRequirements ,
        "createTransferRequirements": this.runMRPForm.value.transferRequirements ,
        "defaultYearsInFutureForSalesOrders": this.runMRPForm.value.defaultYears ,
        "facilityId": this.runMRPForm.value.facility ,
        "inventoryReceiptTimeBuffer": this.runMRPForm.value.inventoryReceipt ,
        "mrpStoreGroup":this.runMRPForm.value.MRPStoreGroup ,
        "percentageOfSalesForecastToUse": this.runMRPForm.value.percentageofSalesForecast ,
        "productId": this.runMRPForm.value.productId ,
        "productStoreId":this.runMRPForm.value.productStore ,
        "runMrpId": "",

    };
    this.mergeContactsService.createRunMrp(requestData).subscribe((res: any) => {
  
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/facilities/Planning/view-mrp'])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  getProductProductStore() {
    this.spinner.show();
    this.myContactsService.getProductProductStore().subscribe(res => {
      this.ProductProductStore = res.data[0].node;
      this.spinner.hide();
      for (const value of this.ProductProductStore) {
        this.ProductProductStoreArray.push({
          label: value.storeName,
          value: value.productStoreId
        })
      }
    })
  }
  getSuppliers(): void {
    this.spinner.show();
    this._WarehouseService.getSuppliers()
        .then(data => {
            this.spinner.hide();
            this.suppliers = data.data.map(value => {
                return {
                    label: value.partyId,
                    value: value.partyId
                };
            });
        });
}
  getFacilityId() {
    this.spinner.show();
    this.myContactsService.getFacilityId().subscribe(res => {
      this.facility = res.data[0].getFacilityId;
      this.spinner.hide();
      for (const value of this.facility) {
        this.facilityArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
    })
   
  }
  getProductIds() {
    this.spinner.show();
    this.myContactsService.getProductIds().subscribe(res => {
      this.ProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName,
          value: value.productId
        })
      }
    })
  }
  // submit(): void {
  //   this.spinner.show();
  //   const requestData = {
  //     "defaultYearsOffset": this.runMRPForm.value.inventoryReceipt,
  //     "facilityGroupId":  this.runMRPForm.value.custRequestId,
  //     "facilityId":  this.runMRPForm.value.facility,
  //     "mrpName": this.runMRPForm.value.custRequestId,
  //   };
  //   this.mergeContactsService.runMrp(requestData).subscribe((res: any) => {
  
  //     if (res.success) {
  //       this.toastr.success("Created Successfully");
  //       this.spinner.hide();
  //       this.closebutton1.nativeElement.click();

  //     }
  //   }, (err) => {
  //     this.spinner.hide();
  //     for (const value of err.error.errors) {
  //       this.toastr.error(value.fieldName + ' ' + value.fieldError);
  //     }
  //   });
  
  // }
  toViewMRP()
  {
    this._Router.navigate(['/facilities/Planning/view-mrp'])
  }

  openInventory()
  {
    this._Router.navigate(['/facilities/Planning']);

  }
  openFindRequirements()
  {
    this._Router.navigate(['/facilities/Planning/find-requirements']);

  }
  openRequirements()
  {
    this._Router.navigate(['/facilities/Planning/open-requirements']);

  }
  openProductRequirements()
  {
    this._Router.navigate(['/facilities/Planning/product-requirements']);
    
  }
  openInternalRequirements()
  {
    this._Router.navigate(['/facilities/Planning/internal-requirements']);

  }
  openTransferRequirements()
  {
    this._Router.navigate(['/facilities/Planning/transfer-requirements']);

  }
  openSalesOrderHistory()
  {
    this._Router.navigate(['/facilities/Planning/upload-sales-order-history']);

  }
  openRunMRP()
  {
    this._Router.navigate(['/facilities/Planning/run-mrp']);

  }
  openViewMRP()
  {
    this._Router.navigate(['/facilities/Planning/view-mrp']);

  }
  openConfigureWarehouses()
  {
    this._Router.navigate(['/facilities/Planning/configure-warehouse']);

  }  
customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
