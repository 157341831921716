import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { DropdownModule } from 'primeng/dropdown';
import { OrderManagementService } from '../order-management.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-purchase-continue',
  templateUrl: './create-purchase-continue.component.html',
  styleUrls: ['./create-purchase-continue.component.css']
})
export class CreatePurchaseContinueComponent implements OnInit {
  activeCategory = 1;
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 2,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],
    }
  };
  products3: any;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  products: any[];
  purchaseForm: FormGroup;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  orderItems: any[];
  totalAmount: any;
  promotions: any[];
  itemTypes: any[];
  constructor(
    readonly _OrderManagementService: OrderManagementService,
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FormBuilder: FormBuilder,
    readonly _ToastrService: ToastrService
  ) {
    this.itemTypes = [];
    this.products = [];
    this.purchaseForm = this._FormBuilder.group({
      productId: [''],
      quantity: [''],
      itemDesiredDeliveryDate: [''],
      useAsDefaultDesiredDeliveryDate: [true],
      shipAfterDate: [''],
      shipBeforeDate: [''],
      reserveAfterDate: [''],
      itemComment: [''],
      useAsDefaultComment: [true]
    });
    this.orderItems = [];
    this.promotions = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
   
    this.getProducts();
    this.getPromotions();
    this.getItemType();
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  getPromotions(): void {
    this.spinner.show();
    this._OrderManagementService.getAllPromotion()
      .then(data => {
        this.spinner.hide();
        this.promotions = data.data;
      });
      
  }
  getItemType(): void {
    this.spinner.show();
    this._OrderManagementService.getItemType()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.itemTypes = data.data.purchaseOrderItemTypes.map(value => {
            return {
              label: value.parentTypeId,
              value: value.orderItemTypeId
            };
          });
        }
      });
     
  }
  getProducts(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, {
      internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains'
    })
      .then(data => {
        this.spinner.hide();
        this.products = data.data.map(value => {
          return {
            label: value.productId,
            value: value.productId
          };
        });
      });
     
  }
  placeOrder(): void {
    this.spinner.show();
    this._OrderManagementService.addItems({
      ...this.purchaseForm.value, ...{
        itemDesiredDeliveryDate: this.purchaseForm.value.itemDesiredDeliveryDate ? moment(this.purchaseForm.value.itemDesiredDeliveryDate).format('YYYY-MM-DD HH:mm:ss') : '',
        shipAfterDate: this.purchaseForm.value.shipAfterDate ? moment(this.purchaseForm.value.shipAfterDate).format('YYYY-MM-DD HH:mm:ss') : '',
        shipBeforeDate: this.purchaseForm.value.shipBeforeDate ? moment(this.purchaseForm.value.shipBeforeDate).format('YYYY-MM-DD HH:mm:ss') : '',
        reserveAfterDate: this.purchaseForm.value.reserveAfterDate ? moment(this.purchaseForm.value.reserveAfterDate).format('YYYY-MM-DD HH:mm:ss') : '',
      }
    })
      .then(data => {
        if (data.success) {
          if (data.data.errorMessage) {
            this._ToastrService.error(data.data.errorMessage);
          } else {
            this._ToastrService.success('Order added successfully');
            this.spinner.hide();
          }
          this.totalAmount = data.data.orderItems[data.data.orderItems.length - 1];
          for (var i = 0; i <= (data.data.orderItems.length - 2); i++) {
            this.orderItems.push(data.data.orderItems[i]);
          }
          this.purchaseForm.reset();
        }
      });
      this.spinner.hide();
  }


  clearOrder(): void {
    this.spinner.show();
    this._OrderManagementService.clearOrder()
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Order Cleared');
          this.spinner.hide();
          this.router.navigate(['/order/create-purchase-order']);
        }
      });
      this.spinner.hide();
  }
  finalizeOrder(): void {
    this.spinner.show();
    if (this.orderItems.length) {
      this._OrderManagementService.finalizeOrderWithDynamicBody({
        finalizeMode: 'init'
      })
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Order finalization initiated');
            this.spinner.hide();
            switch (data.data.responseMessage) {
              case 'shipping': this.router.navigate(['/order/purchase-finalize-order']);
                break;
              case 'options': this.router.navigate(['/order/purchase-order-options']);
                break;
              case 'term': this.router.navigate(['/order/purchase-order-entry-terms']);
                break;
              case 'addparty': this.router.navigate(['/order/purchase-order-additional-party-entry']);
                break;
              case 'purchase': this.router.navigate(['/order/purchase-order-confirmation']);
                break;
            }
          }
        });
    } else {
      this.spinner.hide();
      this._ToastrService.error('Please add product');
    }
   
  }
  recalculateOrder(): void {
    this.spinner.show();
    if (this.orderItems.length) {
      this._OrderManagementService.recalculateOrder(
        this.orderItems.map(value => {
          return {
            description: value.description,
            gwall: '',
            price: value.itemTotal,
            shipAfterDate: '',
            shipBeforeDate: '',
            updateQty: value.quantity
          };
        }))
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Recalculated');
          }
        });
    }
    this.spinner.hide();
  }
  
}
