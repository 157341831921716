import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { CRMAccountService } from 'src/app/services/crm-account.service'
import { GlobalResourceService } from 'src/app/services/global-resource.service'
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';

import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { SortEvent } from 'primeng/api';
import {
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';


import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid';
import { TrainingApprovalService } from 'src/app/human-resource/training/training-approvals/training-approval.service';

declare const $: any;

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-sales-calendar',
  templateUrl: './sales-calendar.component.html',
  styleUrls: ['./sales-calendar.component.css']
})
export class SalesCalendarComponent implements OnInit {


  
  show=false;
  createPage:FormGroup
  activeCategory=2;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton1') closebutton1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  partyId: string;
  SystemInfoNote: any;
  SystemInfoStatus: any;
  id: string;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
 
  activeTabbing = 1;
  
  options: any;


  trainingApprovalForm: FormGroup;

  calendarValue: any;
  trainingApproval: any;
  trainingApprovalList: any[];
  trainingClassTypes: any[];
  isForm: boolean;
  isUpdate: boolean;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  calendarEventForm: FormGroup;
  ParticipantsForm:FormGroup;
  partyIds: any[];
  workEffortId: string;

  date: {
    startDate: string,
    endDate: string
  };
  assignList: any[];
  activeCategories=13;

  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,
    readonly  router: Router,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
private _location: Location,
readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly trainingApprovalService: TrainingApprovalService,
    readonly accountsService: AccountsService){
      this.partyId=localStorage.getItem("partyId");
      this.date = {
        startDate: '',
        endDate: ''
      };
      this.calendarEventForm = this._FormBuilder.group({
        description: ['', [Validators.required]],
        estimatedCompletionDate: [''],
        estimatedStartDate: [''],
        workEffortName: ['']
      });
      this.ParticipantsForm = this._FormBuilder.group({
        partyId: ['', [Validators.required]],
        trainingClassTypeId: ['', [Validators.required]],
      });
      this.createPage = this._FormBuilder.group({
        noteInfo: [''],
        moreInfoUrl:[''],
        noteParty:['']
  
        
       
        
      });
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    // this.getSystemInfoNote();
    // this.getSystemInfoStatus();
     this.getEvents();
     this.getTrainingClass();
     this.getPartyIds();
   
    this.options = {

      defaultDate: '2017-02-01',
      plugins: [dayGridPlugin],
      initialView: 'dayGridMonth',

      header: {
        left: 'prev,next',
        center: 'title',
        right: 'timeGridMonth,timeGridWeek,timeGridDay'
      }
    }
  }
  toForecast(){
    
    this.router.navigate(['/crm/sales-forecast']);

  }
  toCalendar(){
    
    this.router.navigate(['crm/sales-calendar']);

  }
  toNotes(){
    this.router.navigate(['/crm/sales-notes']);
  }
  toEmails(){
    
    this.router.navigate(['/communication-overview/Dashboard']);

  }  
  toTasks(){
    this.router.navigate(['/crm/Tasks']);
  }
  toDocuments(){
    
    this.router.navigate(['crm/sales-documents']);

  }
  toLead()
  {
    this.router.navigate(['/crm/leads']);

  }
  toAccount()
  {
    this.router.navigate(['/crm/accounts']);
  }
  toContact()
  {
    this.router.navigate(['/crm/my-contacts']);

  }
  toOpportunity()
  {
    this.router.navigate(['/crm/opportunities']);

  }
  
  toCases(){
    this.router.navigate(['/crm/Cases']);
  }
  toActivities()
  {
    this.router.navigate(['/crm/events']);

  }
  toQuotes()
  {
    this.router.navigate(['/order/quotations']);

  }
  toSalesOrder()
  {
    this.router.navigate(['/order/create-sales-order']);

  }
  changeactiveCategoryAction(tab: number) {
    this.activeCategories = tab;
  }
  homeButton(){
    this._Router.navigate(['/crm/main-overview'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  

  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
     
  }
  getTrainingClass(): void {
    this.spinner.show();
    this.trainingApprovalService.getTrainingClassTypeList({ pageNo: 1, pageSize: 200 })
      .then(data => {
        this.spinner.hide();
        if (data.success) {
          this.trainingClassTypes = data.data.map(value => {
            return { label: value.description, value: value.trainingClassTypeId };
          });
        }
      });
     
  }
  onSubmit() {
    this.spinner.show();
   
    const requestData = {
      "moreInfoUrl": this.createPage.value.moreInfoUrl,
      "noteInfo":this.createPage.value.noteInfo,
      "noteParty":this.createPage.value.noteParty,
    }
    this.accountsService.createSystemInfoNote(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.getSystemInfoNote();
        this.getSystemInfoStatus();
        this.closebutton2.nativeElement.click();
        this.createPage.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getSystemInfoNote() {
    this.spinner.show();
    this.accountsService.getSystemInfoNote(this.partyId).subscribe(res => {
      this.SystemInfoNote = res.data.systemInfoNotes;
      this.spinner.hide();
    })
  
  }
  getSystemInfoStatus() {
    this.spinner.show();
    this.accountsService.getSystemInfoStatus(this.partyId).subscribe(res => {
      this.SystemInfoStatus = res.data.systemInfoStatus;
      this.spinner.hide();
    })
  
  }
   
  deleteSystemInfoNote(nodeId){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.id="MYPORTAL_EMPLOYEE5"
        this.accountsService.deleteSystemInfoNote(nodeId,this.id).subscribe((res:any)=> {
          this.getSystemInfoNote();
          this.getSystemInfoStatus();
      },(err) => {
        this._ToastrService.error(err.error.message);
     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )} else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  deleteALL(){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteAllSystemNotes().subscribe((res:any)=> {
          this.getSystemInfoNote();
          this.getSystemInfoStatus();
      },(err) => {
        this._ToastrService.error(err.error.message);
     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )} else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  
  detailMainPage(noteId,noteDateTime,noteInfo){
    this._Router.navigate(['/myPortal/detail-main-portal'],{ queryParams: { noteId:noteId,noteDateTime:noteDateTime,noteInfo:noteInfo}});
  }
  goToCreatePublicMessage(){
    this._Router.navigate(['/myPortal/create-public-message']);
  }
  /* Calender Code */
  getEvents(): void {
    this.spinner.show();
    
    switch (this.view) {
      case 'month': this.date = {
        startDate: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'day': this.date = {
        startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'week': this.date = {
        startDate: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
    }
    this.trainingApprovalService.getTrainingCalendar({ pageNo: 1, pageSize: 100 }, { startDate: this.date.startDate, endDate: this.date.endDate })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.events = data.data.map(value => {
            return {
              ...value, ...{
                start: new Date(value.estimatedStartDate),
                end: new Date(value.estimatedCompletionDate),
                title: value.description,
                color: colors.red,
                actions: this.actions,
                allDay: false,
                resizable: {
                  beforeStart: true,
                  afterEnd: true,
                },
                draggable: true,
              }
            }
          });
        }
      });
     
  }




 
  cancel(): void {
    this.calendarEventForm.reset();
    this.isForm = false;
    this.isUpdate = false;
  }

  assignEvent(): void {
    this.spinner.show();
    this.trainingApprovalService.assignTraining({
      fromDate: this.calendarEventForm.value.estimatedStartDate ? moment(this.calendarEventForm.value.estimatedStartDate).format('YYYY-MM-DD HH:mm:ss') : '',
      partyId: this.ParticipantsForm.value.partyId ,
      thruDate: this.calendarEventForm.value.estimatedCompletionDate ? moment(this.calendarEventForm.value.estimatedCompletionDate).format('YYYY-MM-DD HH:mm:ss') : '',
      trainingClassTypeId: this.ParticipantsForm.value.trainingClassTypeId,
      workEffortId: this.workEffortId
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Assigned');
          this.calendarEventForm.reset();
          this.isForm = false;
          this.isUpdate = false;
          this.spinner.hide();
          this.trainingApprovalForm.patchValue({ workEffortId: this.workEffortId });
         // this.getTrainingApprovalList();
          this.activeTabbing = 2;
        }
      });
     
  }
  update(): void {
    this.spinner.show();
    this.trainingApprovalService.updateTrainingCalendar(this.workEffortId, {
      ...this.calendarEventForm.value, ...{
        estimatedCompletionDate: this.calendarEventForm.value.estimatedCompletionDate ? moment(this.calendarEventForm.value.estimatedCompletionDate).format('YYYY-MM-DD HH:mm:ss') : '',
        estimatedStartDate: this.calendarEventForm.value.estimatedStartDate ? moment(this.calendarEventForm.value.estimatedStartDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Event Updated Successfully');
          this.spinner.hide();
          this.calendarEventForm.reset();
          this.isForm = false;
          this.isUpdate = false;
          this.getEvents();
        }
      });
     
  }
  submit(): void {
    this.spinner.show();
    if (this.isUpdate) {
      this.update();
    } else {

      if (this.calendarEventForm.valid) {
        this.trainingApprovalService.createTrainingCalendar({
          ...this.calendarEventForm.value, ...{
            estimatedCompletionDate: this.calendarEventForm.value.estimatedCompletionDate ? moment(this.calendarEventForm.value.estimatedCompletionDate).format('YYYY-MM-DD HH:mm:ss') : '',
            estimatedStartDate: this.calendarEventForm.value.estimatedStartDate ? moment(this.calendarEventForm.value.estimatedStartDate).format('YYYY-MM-DD HH:mm:ss') : ''
          }
        })
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Event Created Successfully');
              this.spinner.hide();
              this.calendarEventForm.reset();
              this.isForm = false;
        this.closebutton1.nativeElement.click();

              this.getEvents();
            }
          });
      }
      
    }
  
  }



  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt" (click)="update()"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    }
   
  ];

  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];
  
  

  activeDayIsOpen: boolean = true;


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {

    this.workEffortId = event.workEffortId
    this.calendarValue = {
      description: event.description,
      estimatedCompletionDate: event.estimatedCompletionDate ? moment(event.estimatedCompletionDate).format('YYYY-MM-DD HH:mm:ss') : '',
      estimatedStartDate: event.estimatedStartDate ? moment(event.estimatedStartDate).format('YYYY-MM-DD HH:mm:ss') : '',
      workEffortName: event.workEffortName
    };
    this.isForm = true;
    this.isUpdate = true;
   
    this.calendarEventForm.patchValue({
      description: event.description,
      estimatedCompletionDate: event.estimatedCompletionDate ? moment(event.estimatedCompletionDate).format('yyyy-MM-DDTHH:mm') : '',
      estimatedStartDate: event.estimatedStartDate ? moment(event.estimatedStartDate).format('yyyy-MM-DDTHH:mm') : '',
      workEffortName: event.workEffortName
    });
    this.getEventById();
   
  }

  getEventById(): void {
    this.spinner.show();
    this.trainingApprovalService.getCalendarEventById(this.workEffortId)
      .then(data => {
        if (data.success) {
          this.assignList = data.data.participantsList;
        }
      });
      this.spinner.hide();
  }
  addEvent(): void {
    this.isForm = true;
  
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }

}
