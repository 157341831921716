import { DatePipe } from '@angular/common';
import { Component,ViewChild, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';  import { Location } from '@angular/common';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-edit-product-price-rules',
  templateUrl: './edit-product-price-rules.component.html',
  styleUrls: ['./edit-product-price-rules.component.css']
})
export class EditProductPriceRulesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton5') closebutton5;
  activeCategory = 1;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  updatePrice: any;
  updateCondition: any;
  updateAction: any;
  productPriceRuleId: any;
  ProductPriceRule: any;
  yesNoArray: { label: string; value: string; }[];
  inputList: any;
  inputListArray: any[]=[];
  conditionValues: any;
  conditionValuesArray: any[]=[];
  priceRuleCond: any;
  showCond: boolean;
  showPriceRule: boolean;
  addPriceRule: any;
  productPrice: any;
  ProductId: any;
  ProductIdArray: any[]=[];
  priceRuleConds: any;
  actionList: any;
  actionTypeList: any;
  actionTypeListArray: any[]=[];
  showAction: boolean;
  productAction: any;
  actionTypePriceRules: any;
  actionTypePriceRulesArray: any[]=[];
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,    private _location: Location,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) {
    this.updatePrice = this._FormBuilder.group({
      name: [''],
      desc: [''],
      saleRule: [''],
      fromDate: [''],
      throughDate: [''],
    });
    this.addPriceRule = this._FormBuilder.group({
      name: [''],
    });
    this.updateCondition = this._FormBuilder.group({
      input: [''],
      operator: [''],
      value: [''],
    });
    this.updateAction = this._FormBuilder.group({
      actionType: [''],
      value: [''],
    });
    this.yesNoArray = 
      [{
        label: "Y",
        value: "Y",
      },
      {
        label: "N",
        value: "N",
      },
       
      ]
      
    
   }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params=>{
this.productPriceRuleId = params.productPriceRuleId;
    })
    this.getPriceRuleProductPriceRuleId();
    this.getInputList();
    this.getConditionValues();
    this.getPriceRuleCond();
    this.getProductIds();
    this.getPriceRuleCond();
    this.getActionList();
    this.getActionTypeList();
    this.getActionTypePriceRules();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getActionTypePriceRules() {
    this.spinner.show();
    this.myContactsService.getActionTypePriceRules().subscribe(res => {
      this.actionTypePriceRules = res.data[0].data;
      this.spinner.hide();
      for (const value of this.actionTypePriceRules) {
        this.actionTypePriceRulesArray.push({
          label: value.description,
          value: value.productPriceActionTypeId
        })
      }
    })
  }
  resetAct(){
    this.showAction = false;
    this.updateAction.reset();

  }
  updateAct(product){
    this.showAction = true;
    this.productAction = product;

    setTimeout(() => {
      const formValue = this.updateAction;
      formValue.patchValue({
        actionType: product.productPriceActionTypeId,
        value: product.amount
      })
    }, 2000);
    this.spinner.hide();
  }
  createProductPriceAction(): void {
    this.spinner.show();
    const requestData = {
      "amount":  this.updateAction.value.value,
      "productPriceActionTypeId": this.updateAction.value.actionType,
      "productPriceRuleId": this.productPriceRuleId
     
    }
    this.myContactsService.createProductPriceAction(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.updateAction.reset();
        this.getActionList();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  updateProductPriceAction(): void {
    this.spinner.show();
    const requestData = {
      "amount":  this.updateAction.value.value,
      "productPriceActionTypeId": this.updateAction.value.actionType,
      "productPriceRuleId": this.productPriceRuleId,
      "deleteLink": "",
      "productPriceActionSeqId": this.productAction.productPriceActionSeqId,
    }
    this.myContactsService.updateProductPriceAction(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Update Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.updateAction.reset();
        this.getActionList();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  deleteProductPriceAction(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData={
          "productPriceActionSeqId": product.productPriceActionSeqId,
          "productPriceRuleId":product.productPriceRuleId,
          "view_INDEX_4": "0",
          "view_SIZE_4": "20",

        }
        this.myContactsService.deleteProductPriceAction(formData).subscribe(res => {
          this.getPriceRuleCond();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  getActionTypeList() {
    this.spinner.show();
    this.myContactsService.getActionTypeList().subscribe(res => {
      this.actionTypeList = res.data[0].actionTypeList;
      this.spinner.hide();
      for (const value of this.actionTypeList) {
        this.actionTypeListArray.push({
          label: value.inputParamEnumId,
          value: value.inputParamEnumId
        })
      }
    })
   
  }
  getActionList() {
    this.spinner.show();
    this.myContactsService.getActionList(this.productPriceRuleId).subscribe(res => {
      this.actionList = res.data[0].productPriceActionList;
      this.spinner.hide();
    })
  }
  getProductIds() {
    this.spinner.show();
    this.myContactsService.getProductIds().subscribe(res => {
      this.ProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName,
          value: value.productId
        })
      }
    })
   
  }
  createProductPriceRule(): void {
    this.spinner.show();
    const requestData = {
      "ruleName": this.addPriceRule.value.name,
     
    }
    this.accountsService.createProductPriceRule(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.addPriceRule.reset();
        this.closebutton3.nativeElement.click();
        this._Router.navigate(["/facilities/catalog/price-rules"]);
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  getPriceRuleCond() {
    this.spinner.show();
    this.myContactsService.getPriceRuleCond(this.productPriceRuleId).subscribe(res => {
      this.priceRuleCond = res.data[0].productPriceCondList;
      this.spinner.hide();
    })
  }
  getConditionValues() {
    this.spinner.show();
    this.myContactsService.getConditionValues().subscribe(res => {
      this.conditionValues = res.data[0].pConditionValues;
      this.spinner.hide();
      for (const value of this.conditionValues) {
        this.conditionValuesArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  getInputList() {
    this.spinner.show();
    this.myContactsService.getInputList().subscribe(res => {
      this.inputList = res.data[0].inputList;
      this.spinner.hide();
      for (const value of this.inputList) {
        this.inputListArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  deleteProductPriceCond(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData={
          "productPriceCondSeqId": product.productPriceCondSeqId,
  "productPriceRuleId":  product.productPriceRuleId,
  "view_INDEX_2": "0",
  "view_SIZE_2": "20"
        }
        this.myContactsService.deleteProductPriceCond(formData).subscribe(res => {
          this.getPriceRuleCond();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  updateProductPriceCond(){
    this.spinner.show();
    const requestData = {
      "condValue":  this.updateCondition.value.value,
      "condValueInput": "",
      "deleteLink": "",
      "inputParamEnumId": this.updateCondition.value.input,
      "operatorEnumId":  this.updateCondition.value.operator,
      "productPriceCondSeqId": this.priceRuleConds.productPriceCondSeqId ,
      "productPriceRuleId":this.productPriceRuleId
    }
    this.myContactsService.updateProductPriceCond(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.updateCondition.reset();
        this.getPriceRuleCond();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createProductPriceCond(){
    this.spinner.show();
    const requestData = {
      "condValue":  this.updateCondition.value.value,
      "condValueInput": "",
      "inputParamEnumId": this.updateCondition.value.input,
      "operatorEnumId":  this.updateCondition.value.operator,
      "productPriceRuleId":this.productPriceRuleId,
    }
    this.myContactsService.createProductPriceCond(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.updateCondition.reset();
        this.getPriceRuleCond();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  resetCondition(){
    this.showCond = false;
    this.updateCondition.reset();

  }
  updateCond(product){
    this.showCond = true;
    this.priceRuleConds = product;

    setTimeout(() => {
      const formValue = this.updateCondition;
      formValue.patchValue({
        input: product.inputParamEnumId,
      operator: product.operatorEnumId,
      value: product.condValue,
      })
    }, 2000);
    this.spinner.hide();
  }
  update(product){
    this.showPriceRule = true;
    this.productPrice = product;

    setTimeout(() => {
      const formValue = this.updatePrice;
      formValue.patchValue({
        name:    product.ruleName,
        desc:  product.description,
        saleRule:product.isSale,
        fromDate:this.datePipe.transform( product.fromDate, "yyyy-MM-dd hh:mm:ss"),
        throughDate: this.datePipe.transform(product.thruDate, "yyyy-MM-dd hh:mm:ss"), 
      })
    }, 2000);
    this.spinner.hide();
  }
  getPriceRuleProductPriceRuleId() {
    this.spinner.show();
   
    this.myContactsService.getPriceRuleProductPriceRuleId(this.productPriceRuleId).subscribe(res => {
      this.ProductPriceRule = res.data[0].productPriceRule;
     
      this.spinner.hide();
    })
  }
  deleteProductPriceRule(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData={
          "productPriceRuleId": product.productPriceRuleId ,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.deleteProductPriceRule(formData).subscribe(res => {
          this.getPriceRuleProductPriceRuleId();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  updateProductPriceRule(){
    this.spinner.show();
    const requestData = {
      "deleteLink": "",
      "description": this.updatePrice.value.desc ,
      "fromDate": this.datePipe.transform( this.updatePrice.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "isSale":this.updatePrice.value.saleRule ,
      "productPriceRuleId": this.productPriceRuleId ,
      "ruleName": this.updatePrice.value.name ,
      "thruDate": this.datePipe.transform( this.updatePrice.value.throughDate, "yyyy-MM-dd hh:mm:ss"),
    }
    this.myContactsService.updateProductPriceRule(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.updatePrice.reset();
        this.getPriceRuleProductPriceRuleId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  toBack()
  {
    this._Router.navigate(['/facilities/catalog/price-rules'])
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
