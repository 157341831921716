import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-marketing-campaign',
  templateUrl: './marketing-campaign.component.html',
  styleUrls: ['./marketing-campaign.component.css']
})
export class MarketingCampaignComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  CreateMarketingCampaignForm: FormGroup;
  MarketCampaignId: any;
  MarketCampaignIdArray: any[]=[];
  yesNOArray: { label: string; value: string; }[];
  StatusList: any;
  StatusListArray: any[]=[];
  CurrencyList: any;
  CurrencyListArray: any[]=[];
  MarketingCampaignDetailData: any;
  show: boolean;
  StartDate: string;
  actualfromdate: string;
  thrufromdate: string;
  activeCategoriess=2;
  

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) { 
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.CreateMarketingCampaignForm = this._FormBuilder.group({
      ParentCampaignID: [''],
      Status: [''],
      CampaignName: [''],
      CampaignSummary: [''],
      BudgetedCost: [''],
      ActualCost: [''],
      Estimatedcost: [''],
      Currency: [''],
      FromDate: [''],
      ThroughDate: [''],
      IsActive: [''],
      ConvertedLeads: [''],
      ExpectedResponsePercent: [''],
      ExpectedRevenue: [''],
      NumSent: [''],
      StartDate: [''],
      CreatedByUser: [''],
      LastModifiedByUser: [''],
      
    });
  }

  ngOnInit(): void {
    this.getMarketCampaignId();
    this.getStatusList();
    this.getCurrencyList();
    this.getMarketingCampaignDetailData();
    
  }

backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/crm/main-overview']);
}

   
  createMarketingCampaign(): void {
    this.spinner.show();
    const fromActualdate=this.CreateMarketingCampaignForm.get('FromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.CreateMarketingCampaignForm.get('ThroughDate').value;
    this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const StartDate=this.CreateMarketingCampaignForm.get('StartDate').value;
    this.StartDate=this.datePipe.transform(StartDate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "actualCost": this.CreateMarketingCampaignForm.value.ActualCost,
      "budgetedCost": this.CreateMarketingCampaignForm.value.BudgetedCost,
      "campaignName": this.CreateMarketingCampaignForm.value.CampaignName,
      "campaignSummary": this.CreateMarketingCampaignForm.value.CampaignSummary,
      "convertedLeads": this.CreateMarketingCampaignForm.value.ConvertedLeads,
      "createdByUserLogin": this.CreateMarketingCampaignForm.value.CreatedByUser,
      "currencyUomId":this.CreateMarketingCampaignForm.value.Currency,
      "done_PAGE": this.CreateMarketingCampaignForm.value.DataSourceID,
      "estimatedCost":this.CreateMarketingCampaignForm.value.Estimatedcost,
      "expectedResponsePercent":this.CreateMarketingCampaignForm.value.ExpectedResponsePercent,
      "expectedRevenue": this.CreateMarketingCampaignForm.value.ExpectedRevenue,
      "fromDate":this.actualfromdate,
      "isActive": this.CreateMarketingCampaignForm.value.IsActive,
      "lastModifiedByUserLogin": this.CreateMarketingCampaignForm.value.LastModifiedByUser,
      "numSent": this.CreateMarketingCampaignForm.value.NumSent,
      "parentCampaignId": this.CreateMarketingCampaignForm.value.ParentCampaignID,
      "startDate": this.StartDate,
      "statusId": this.CreateMarketingCampaignForm.value.Status,
      "thruDate": this.thrufromdate

   
      }
     
    this.accountsService.createMarketingCampaign(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getMarketingCampaignDetailData(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  getMarketingCampaignDetailData() {
    this.spinner.show();
    this.accountsService.getMarketingCampaignDetailData().subscribe(res => {
      this.MarketingCampaignDetailData = res.data[0].node;
      this.spinner.hide();
     
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  getMarketCampaignId() {
    this.spinner.show();
    this.myContactsService.getMarketCampaignId().subscribe(res => {
      this.MarketCampaignId = res.data[1].data;
      this.spinner.hide();
      for (const value of this.MarketCampaignId) {
        this.MarketCampaignIdArray.push({
          label: value.campaignName,
          value: value.marketingCampaignId  
        })
      }
    })
  }
  getStatusList() {
    this.spinner.show();
    this.myContactsService.getStatusList().subscribe(res => {
      this.StatusList = res.data;
      this.spinner.hide();
      for (const value of this.StatusList) {
        this.StatusListArray.push({
          label: value.node[0].transitionName,
          value: value.node[0].statusIdTo
        })
      }
    })
  }
  getCurrencyList() {
    this.spinner.show();
    this.myContactsService.getCurrencyList().subscribe(res => {
      this.CurrencyList = res.data[0].node;
      this.spinner.hide();
      for (const value of this.CurrencyList) {
        this.CurrencyListArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  createcontent(){
    this._Router.navigate(['/marketing/create-content']);
    
  }
  addEffortEventReminder(){
    this.show=false;
    this.CreateMarketingCampaignForm.reset();
  }
  accountDetails(id) {
    this._Router.navigate(["marketing/EditMarketingCampaign"], { queryParams: { marketId: id } })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
   
  }
  toCalendar(){
    
    this._Router.navigate(['crm/sales-calendar']);

  }
  toAccount()
  {
    this._Router.navigate(['/crm/new-accounts']);
  }
  toContact()
  {
    this._Router.navigate(['/crm/my-contacts']);

  }
  MobileMessaging()
  {
    this._Router.navigate(['/#']);

  } 
  SocialMedia()
  {
    this._Router.navigate(['/#']);

  }
  toCases()
  {
    this._Router.navigate(['/marketing/cases-marketing']);

  }
  toNotes(){
    this._Router.navigate(['/crm/sales-notes']);
  }
  toEmails(){
    
    this._Router.navigate(['/communication-overview/Dashboard']);

  }   toOpportunity()
  {
    this._Router.navigate(['/crm/opportunities']);

  }
  toEmail(){
    this._Router.navigate(['/communication-overview/Dashboard']);
  }
  toForecast(){
    
    this._Router.navigate(['/crm/sales-forecast']);

  }
  toTasks(){
    this._Router.navigate(['/crm/Tasks']);
  }
  toDocuments(){
    
    this._Router.navigate(['crm/sales-documents']);

  }
  toLead()
  {
    this._Router.navigate(['/crm/leads']);

  }
  openDataResource()
  {
    this._Router.navigate(['/marketing/data-source-marketing']);
  }
  openMarketingCampaign()
  {
    this._Router.navigate(['/marketing/marketing-campaign']);
  }
  openTracking()
  {
    this._Router.navigate(['/marketing/tracking-marketing/tracking-marketing']);
  }

  openSegment()
  {
    this._Router.navigate(['/marketing/segment-marketing']);
  }

  openContactList()
  {
    this._Router.navigate(['/marketing/contact-list-marketing']);
  
  }
  openReports()
  {
    this._Router.navigate(['/marketing/reports-marketing']);
  }

  openCustomer()
  {
    this._Router.navigate(['/crm/accounts']);

  }
  openActivities()
  {
    this._Router.navigate(['/crm/events']);

  }

  changeActiveCategoryFunctions(tab)
  {
    this.activeCategoriess=tab;
  }
  
}
