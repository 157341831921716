import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { EmpPersonalFormService } from '../emp-personal-service';
import { EmployeeService } from '../../employees/employess.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-personal-update',
  templateUrl: './personal-update.component.html',
  styleUrls: ['./personal-update.component.css']
})
export class PersonalUpdateComponent implements OnInit {
  partyId: any;
  partyIds: any;
  employeeDetail: any;
  crmStatus: any;
  crmArray: any[] = [];
  currencyList: any[];
  @Output() closeUpdateModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;
  constructor(
    readonly _GlobalResourceService: GlobalResourceService,
    readonly employeeService: EmployeeService,
    public empPersonalFormService: EmpPersonalFormService,
    readonly toastr: ToastrService,
    readonly activatedRoute: ActivatedRoute,
    private _location: Location,
    readonly router: Router,
    readonly spinner:NgxSpinnerService,
    public bsModalRef: BsModalRef
  ) {
    this.currencyList = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params['personId'];

    })
    this.partyIds = this.partyId;
    this.empPersonalFormService.emp_personal_form = null;
    this.empPersonalFormService.createEventForm(this.empPersonalFormService.emp_personal_form);
    this.getCurrency();
    this.getEmpStatus();
    this.getEmployeeDetail();
  }

  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this.router.navigate(['/security/security-dashboard']);

}
  onCancel() {
    this.closeModal();
    //this.router.navigate(["/hr/employees/employee-details"], { queryParams: { employeeDetailId: this.partyId } });
  }
  onUpdate() {
    this.spinner.show();
    const formData = { ... this.empPersonalFormService.emp_personal_form.value };
    this.employeeService.updateEmpInfo(this.partyId, formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.closeModal();
        this.spinner.hide();
        this.router.navigate(["/hr/employees/employee-details"], { queryParams: { employeeDetailId: this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
  
  }


  getEmpStatus() {
    this.spinner.show();
    this.employeeService.getStatus().subscribe(res => {
      this.crmStatus = res.data;
      this.spinner.hide();
      for (const value of this.crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        this.spinner.hide();
        this.currencyList = data.data.currencyList.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });
     
  }
  getEmployeeDetail() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.partyId).subscribe(res => {
      this.spinner.hide();
      const formValue = this.empPersonalFormService.emp_personal_form;
      formValue.patchValue({
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        partyId: res.data.partyId,
        statusId: res.data.statusId,
        description: res.data.comments,
        externalId: res.data.externalId,
        comments: res.data.comments,
        preferredCurrencyUomId: res.data.preferredCurrencyUomId
      })
     
    })
  
  }

  closeModal():void{
    this.closebutton.nativeElement.click();
    this.closeUpdateModal.emit();
  }

  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
