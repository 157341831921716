<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Update User Login Security Settings</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                    
                    <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Update User Login Security Settings - {{this.party}}
                    </a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="updatePassword">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">
                                                    Enabled</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="EnabledArray" filter="true" formControlName="Enabled"
                                                placeholder="Enter Enabled"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Disabled Date Time</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"  formControlName="DisabledDateTime"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Disabled Date Time">
                                            </div>
                                           
                                        </div>
                                      </div>
                                      <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">
                                                    Successive Failed Logins</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text"  formControlName="SuccessiveFailedLogins"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Successive Failed Logins">
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    External Auth ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text"  formControlName="ExternalAuthID"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                            </div>
                                           
                                        </div>
                                      </div>
                                      <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">
                                                    Require Password Change</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="EnabledArray" filter="true" formControlName="RequirePasswordChange"
                                                placeholder="Enter Require Password Change"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Disabled By</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text"  formControlName="DisabledBy"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Disabled By">
                                            </div>
                                           
                                        </div>
                                      </div>

                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: -24% !important;">
                               
                                <button type="submit" (click)="onUpdate();"  class="btn btn-secondary submit-btn">Create</button>
                                 
                               
                                
                              
                            </div>
                        </div>
                        <div class="w3-card-4 classCard">
                            <div class="container-fluid">
                                <ul class="tabbing-section tabbing-accordians tabClass">
                      
                                
                                <li [ngClass]="activeCategory==2?'active':''" ><a>
                                    Change User Login Password
                                </a></li>
                                   
                               
                                </ul>
                              </div>
                           
                           
                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form  class="w-100" [formGroup]="changePassword">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12" >
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">
                                                                Current Password</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text"  formControlName="CurrentPassword"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                        </div>
                                                        <div class="col-lg-3 form-group rightFormA">
                                                            <label for="exampleInputEmail1">
                                                                New Password</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text"  formControlName="NewPassword"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter New Password">
                                                        </div>
                                                       
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-12" >
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">
                                                                Verify New Password</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text"  formControlName="VerifyNewPassword"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Verify New Password">
                                                        </div>
                                                        <div class="col-lg-3 form-group rightFormA">
                                                            <label for="exampleInputEmail1">
                                                                Password Hint</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text"  formControlName="PasswordHint"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                                        </div>
                                                       
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-12" >
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">
                                                                Require Password Change</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [options]="EnabledArray" filter="true" formControlName="RequirePasswordChange"
                                                            placeholder="Enter Require Password Change"
                                                            name="Parent Type ID">
                                                        </p-dropdown>
                                                        </div>
                                                       
                                                       
                                                    </div>
                                                  </div>

                                              
                                              
                                            </div>
                                            
            
                                        </form>
                                        <div class="main-submit-button" style="margin-left: -24% !important;">
                                           
                                            <button type="submit" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Create</button>
                                             
                                           
                                            
                                          
                                        </div>
                                    </div>
                                </div>
            
                            </div>
                            </div>
                    </div>

                </div>
                </div>
             

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>




