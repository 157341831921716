import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../new-products/product.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
@Component({
    selector: 'app-create-product-content',
    templateUrl: './create-product-content.component.html',
    styleUrls: ['./create-product-content.component.css']
})
export class CreateProductContentComponent implements OnInit {
    editMode: boolean;
    productId: string;
    productContentTypeId: string;
    isText: boolean;
    activeCategory: number;
    imageForm: FormGroup;
    imageValue: FormData;
    contentValue: any;
    contentId: string;
    fromDate: string;
    constructor(
        readonly _ProductService: ProductService,
private _location: Location,
readonly _ActivatedRoute: ActivatedRoute,
        readonly _FormBuilder: FormBuilder,
        readonly _ToastrService: ToastrService,
        readonly spinner: NgxSpinnerService,
        readonly  _Router: Router
    ) {
        this.imageValue = new FormData();
        this.imageForm = this._FormBuilder.group({
            fromDate: ['', [Validators.required]],
            thruDate: [''],
            sequenceNum: [''],
            contentId: [''],
            description: [''],
            localeString: [''],
            productContentTypeId: [''],
            purchaseFromDate: [''],
            purchaseThruDate: [''],
            text: ['', [Validators.required]],
            textDataResourceId: [''],
            useCountLimit: [''],
            useRoleTypeId: [''],
            useTime: [''],
            useTimeUomId: ['']
        });
        this.activeCategory = 1;
        this.editMode = false;
        this.productId = '';
        this.productContentTypeId = '';
        this.isText = false;
        this.contentId = '';
        this.fromDate = '';
    }
    ngOnInit(): void {
        this._ActivatedRoute.queryParams.subscribe(params => {
            if (params) {
                this.productContentTypeId = params.productContentTypeId;
                this.productId = params.productId;
                this.contentId = params.contentId;
                this.fromDate = params.fromDate;
                this.isText = this.productContentTypeId === 'ADDTOCART_IMAGE' ? false : true;
            }
            if (!this.isText) {
                this.imageForm.controls.text.clearValidators();
                this.imageForm.controls.text.updateValueAndValidity();
            }
            if (this.contentId) {
                this.editMode = true;
                this.getContentValue();
            }
        });
    }
    homeButton(){
        this._Router.navigate(['/facilities/Dashboard'])
      
      }
      cancelSubmit(){
        this._location.back();
      }
      
   
    
    getContentValue(): void {
        this.spinner.show();
        this._ProductService.getContentById(this.contentId, this.fromDate, this.productContentTypeId, this.productId)
            .then(data => {
                if (data.success) {
                    this.contentValue = {
                        ...data.data.productContentData, ...{
                            fromDate: this.fromDate,
                            description: this.isText ? data.data.description : '',
                            text: this.isText ? data.data.textData.text : '',
                            localeString: this.isText ? data.data.content.localeString : ''
                        }
                    };
                   
                    this.imageForm.patchValue({
                        fromDate: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DD') : '',
                        description: this.isText ? data.data.description : '',
                        text: this.isText ? data.data.textData.text : '',
                        localeString: this.isText ? data.data.content.localeString : ''
                    });
                    this.imageForm.controls.fromDate.disable();
                    this.imageForm.controls.fromDate.updateValueAndValidity();
                }
            });
            this.spinner.hide();
    }
    setImage(event: any): void {
        this.imageValue.append('file', event.target.files[0]);
    }
    submit(): void {
        if (this.editMode) {
            if (this.isText) {
                this.updateText();
            } else {
                this.submitImage();
            }
        } else {
            if (this.isText) {
                this.submitText();
            } else {
                this.submitImage();
            }
        }
    }

    submitText(): void {
        this.spinner.show();
        if (this.imageForm.valid) {
            this._ProductService.createSimpleTextContentForProduct(this.productId, {
                ...this.imageForm.value, ...{
                    fromDate: moment(this.imageForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                    thruDate: this.imageForm.value.thruDate ? moment(this.imageForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : '',
                    productContentTypeId: this.productContentTypeId
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.imageForm.reset();
                        this.cancelSubmit();
                    }
                });
        }
        this.spinner.hide();
    }
    submitImage(): void {
        this.spinner.show();
        if (this.imageForm.valid) {
            this._ProductService.addAdditionalImageContent(this.imageValue, encodeURIComponent(JSON.stringify({
                productContentTypeId: this.productContentTypeId,
                fileDataResourceId: '',
                contentId: this.editMode ? this.contentId : '',
                fromDate: this.editMode ? this.fromDate : moment(this.imageForm.value.formDate).format('YYYY-MM-DD HH:mm:ss'),
                thruDate: this.imageForm.value.thruDate ? moment(this.imageForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : '',
                purchaseFromDate: '',
                purchaseThruDate: '',
                useCountLimit: '',
                useTime: '',
                useTimeUomId: '',
                useRoleTypeId: '',
                sequenceNum: this.imageForm.value.sequenceNum,
                description: ''
            })), this.productId)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.imageForm.reset();
                        this.cancelSubmit();
                    }
                });
        } else {
            this._ToastrService.error('Please fill required fields.');
        }
        this.spinner.hide();
    }

    updateText(): void {
        this.spinner.show();
        if (this.imageForm.valid) {
            this._ProductService.updateSimpleTextContentForProduct(this.productId, {
                ...this.contentValue, ...this.imageForm.value, ...{
                    productContentTypeId: this.productContentTypeId,
                    fromDate: this.fromDate ? this.fromDate : '',
                    thruDate: this.imageForm.value.thruDate ? moment(this.imageForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this.imageForm.reset();
                        this.cancelSubmit();
                    }
                });
        } else {
            this._ToastrService.success('Please fill required fields.');
        }
        this.spinner.hide();
    }
  
}