import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {FormGroup, FormBuilder} from '@angular/forms';
import { CRMAccountService } from 'src/app/services/crm-account.service'
import { GlobalResourceService } from 'src/app/services/global-resource.service'
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import Swal from 'sweetalert2'

    import { Location } from '@angular/common';

@Component({
  selector: 'app-my-portal-other-party-comms',
  templateUrl: './my-portal-other-party-comms.component.html',
  styleUrls: ['./my-portal-other-party-comms.component.css']
})
export class MyPortalOtherPartyCommsComponent implements OnInit {

  activeCategory=1;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  userLoginIdArray:any[]=[];
  partyId: string;
  payment: any;
  communicationEvents: any;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
      private _location: Location,
      readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService){
      this.partyId=localStorage.getItem("partyId")
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.communicationEventsPortal();
  }
  
  communicationEventsPortal() {
    this.spinner.show();
    let req=
      {
        "communicationEventId": "",
        "communicationEventIdSearchType": "Contains",
        "communicationEventTypeId": "",
        "parentCommEventId": "",
        "parentCommEventIdSearchType": "Contains",
        "partyId": "Company",
        "partyIdFrom": "",
        "partyIdTo": "",
        "roleTypeId": "",
        "statusId": "",
        "subject": "",
        "subjectSearchType": "Contains"
      }
    
    this.accountsService.communicationEventsPortal(this.finSize,req).subscribe(res => {
      this.communicationEvents = res.data;
      this.spinner.hide();
    })
  
  }
  homeButton(){
    this._Router.navigate(['/myPortal/my-portal-my-profile'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
      
  deleteCommunicationEventId(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.accountsService.communicationEventsDelete(id).subscribe((res:any)=> {
          this.communicationEventsPortal();
        
      },(err) => {
        this._ToastrService.error(err.error.message);
     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )} else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  GoToQuickLink(){
    this._Router.navigate(['/crm/main-crm']);
  } 

  GoToNewNote(commEventId){
    this._Router.navigate(['/myPortal/my-portal-new-internal-note'],{ queryParams: { commEventId:commEventId}});
  }
  newMail(){
    this._Router.navigate(['/myPortal/new-mail']);
} 
 detailMainPage(subject,communicationEventTypeDesc,statusDesc,partyIdFrom,roleTypeId,roleStatusId,entryDate){
    this._Router.navigate(['/myPortal/detail-portal-communication'],{ queryParams: { subject:subject,communicationEventTypeDesc:communicationEventTypeDesc,statusDesc:statusDesc,partyIdFrom:partyIdFrom,roleTypeId:roleTypeId,roleStatusId:roleStatusId,entryDate:entryDate}});
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
