import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edi-overview',
  templateUrl: './edi-overview.component.html',
  styleUrls: ['./edi-overview.component.css']
})
export class EdiOverviewComponent implements OnInit {
  activeCategory=2;
  activeCategoryValue=2;
  constructor(
    private _location: Location,
    readonly router:Router
    
  ) { }

  ngOnInit(): void {
  }
  backWindow(){
    this._location.back();
  }
  toDashboard(){
  this.router.navigate(['/hub/Dashboard']);
  }

}
