import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-update-payable-payment',
    templateUrl: './update-payable-payment.component.html'
})
export class UpdatePayablePaymentComponent implements OnInit {
    constructor() { }
    ngOnInit(): void {

    }
}