import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 4;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  projectForm: FormGroup;
  timesheetForm: FormGroup;
  newrequestForm: FormGroup;
  editProject: FormGroup;
  PriorityList: any;
  PriorityListArray: any[]=[];
  ScopeList: any;
  ScopeListArray: any[]=[];
  ParentProjectName: any;
  ParentProjectNameArray: any[]=[];
  PartyId: any;
  partyIdArray: any[]=[];
  OrganizationFindEmployment: any;
  OrganizationFindEmploymentArray: any[]=[];
  TemplateId: any;
  TemplateIdArray: any[]=[];
  findProject: FormGroup;
  fromdate: string;
  thrudate: string;
  projectList: any;
  projectListData: any;
  advanceSearch: { ProjectID: string; ProjectName: string; fromDate: string; throughDate: string; };
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  HidebtnRoleView: boolean=false;
  HidebtnTaskCrud: boolean=false;
  HidebtnTimeCurd: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly headerService: HeaderService,
    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
        readonly _FormBuilder: FormBuilder,
        readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.advanceSearch = {
      ProjectID: "",
      ProjectName: "",
      fromDate: "",
      throughDate: "",
    };
    this.findProject = this._FormBuilder.group({
      projectID: "",
      projectName: "",
      fromDate: "",
      throughDate: "",
    }),
    this.editProject = this._FormBuilder.group({
      projTempid: "",
      projectName: "",
      description: "",
      clientBillingID: "",
      organizationID: "",
      priority: "",
      scope: "",
      parentProjectName: "",
      estimatedStartDate: "",
      estimatedCompletionDate: "",
      mailingEmail: "",
    })
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.Hidebtn=true;
    }
      else if(this.userPermissions.PROJECTMGR_ROLE_VIEW=="PROJECTMGR_ROLE_VIEW"){
      this.HidebtnRoleView=true;
    }
     else if(this.userPermissions.PROJECTMGR_ROLE_TASK_CREATE=="PROJECTMGR_ROLE_TASK_CREATE"){
      this.HidebtnTaskCrud=true;
    }
    else if(this.userPermissions.PROJECTMGR_ROLE_TIMESHEET_CREATE=="PROJECTMGR_ROLE_TIMESHEET_CREATE"){
      this.HidebtnTimeCurd=true;
    }
    this.getPriorityList();
    this.getScopeList();
    this.getParentProjectNameList();
    this.getProductMemberParty();
    this.getInternalOrganizationFindEmployment();
    this.getTemplateId();
    this.getFindProjectList()
  }
  detailPage(projectID){
    this._Router.navigate(["/psa/project/detail-project-summary"], { queryParams: { projectID: projectID } })

  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  createProject() {
    this.spinner.show();
    const sdate = this.editProject.get('estimatedStartDate').value;
        this.fromdate = this.datePipe.transform(sdate, "yyyy-MM-dd hh:mm:ss");
    const edate = this.editProject.get('estimatedCompletionDate').value;
        this.thrudate = this.datePipe.transform(edate, "yyyy-MM-dd hh:mm:ss");
    const formData = {
      "clientBillingPartyId": this.editProject.value.clientBillingID,
      "currentStatusId":"_NA_",
      "description": this.editProject.value.description,
      "emailAddress": this.editProject.value.mailingEmail,
      "estimatedCompletionDate":  this.thrudate,
      "estimatedStartDate":this.fromdate,
      "organizationPartyId": this.editProject.value.organizationID,
      "priority": this.editProject.value.priority,
      "projectId": "",
      "scopeEnumId": this.editProject.value.scope,
      "templateId": this.editProject.value.projTempid,
      "workEffortId": "",
      "workEffortName": this.editProject.value.projectName,
      "workEffortParentId": this.editProject.value.parentProjectName,
      "workEffortTypeId": "PROJECT",
      "actualCompletionDate": "",
      "actualStartDate": "",
      "dummy": "",

    }
    this.myContactsService.createProject(formData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        // this.closebutton7.nativeElement.click();
        // this.getPaymentMethods();
  
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });
  }
  resetFindProject() {
    this.spinner.show();
    this.advanceSearch.ProjectID = "";
    this.advanceSearch.ProjectName = "";
    this.advanceSearch.fromDate = "";
    this.advanceSearch.throughDate = "";
    
    this.myContactsService.getFindProjectList().subscribe((res: any) => {
      this.projectListData = res.data[0].workeffortList;
      this.spinner.hide();
    })
  }
  getFindProject() {
    this.spinner.show();
    if(this.advanceSearch.ProjectID||this.advanceSearch.ProjectName
     ){
    const req = {
      "workEffortId": this.advanceSearch.ProjectID ? this.advanceSearch.ProjectID : '',
      "workEffortName": this.advanceSearch.ProjectName ? this.advanceSearch.ProjectName : '',
    }
    this.myContactsService.getFindProject(req.workEffortId, req.workEffortName).subscribe((res: any) => {
      this.projectListData = res.data[0].workeffortList;
      this.spinner.hide();
    })}
    else{
      this.getFindProjectList();
    }
    this.spinner.hide();
  }
 
  getFindProjectList() {
    this.spinner.show();
    this.myContactsService.getFindProjectList().subscribe(res => {
      this.projectListData = res.data[0].workeffortList;
      this.spinner.hide();
    })
  }
  
  getPriorityList() {
    this.spinner.show();
    this.myContactsService.getPriorityList().subscribe(res => {
      this.PriorityList = res.data;
      this.spinner.hide();
      for (const value of this.PriorityList) {
        this.PriorityListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }
  
  getScopeList() {
    this.spinner.show();
    this.myContactsService.getScopeList().subscribe(res => {
      this.ScopeList = res.data;
      this.spinner.hide();
      for (const value of this.ScopeList) {
        this.ScopeListArray.push({
          label: value.scopeData.description,
          value: value.scopeData.enumId
        })
      }
    })
  }
  getParentProjectNameList() {
    this.spinner.show();
    this.myContactsService.getParentProjectNameList().subscribe(res => {
      this.ParentProjectName = res.data;
      this.spinner.hide();
      for (const value of this.ParentProjectName) {
        this.ParentProjectNameArray.push({
          label: value.ParentProjectNameList.workEffortName,
          value: value.ParentProjectNameList.workEffortId
        })
      }
    })
  }
  getProductMemberParty() {
    this.spinner.show();
    this.accountsService.getProductMemberParty().subscribe(res => {
      this.PartyId = res.data[0].partyId;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getInternalOrganizationFindEmployment() {
    this.spinner.show();
    this.myContactsService.getInternalOrganizationFindEmployment().subscribe(res => {
      this.OrganizationFindEmployment = res.data;
      this.spinner.hide();
      for (const value of this.OrganizationFindEmployment) {
        this.OrganizationFindEmploymentArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }
    })
  }
  getTemplateId() {
    this.spinner.show();
    this.myContactsService.getTemplateId().subscribe(res => {
      this.TemplateId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.TemplateId) {
        this.TemplateIdArray.push({
          label: value.workEffortName,
          value: value.workEffortId
        })
      }
    })
  }

   
  projectMain() {
    this._Router.navigate(["/psa/project/project"])
  }
  myTasks() {
    this._Router.navigate(["/psa/project/my-tasks"])
  }
  myTime() {
    this._Router.navigate(["/psa/project/my-time"])
  }
  projects() {
    this._Router.navigate(["/psa/project/projects"])
  }
  tasks() {
    this._Router.navigate(["/psa/project/tasks-project"])
  }
  resources() {
    this._Router.navigate(["/psa/project/resources"])
  }
  timesheet() {
    this._Router.navigate(["/psa/project/timesheet-project"])
  }
  
  skillType() {
    this._Router.navigate(["/psa/project/skill-type"])
  }
  requestList() {
    this._Router.navigate(["/psa/project/request-list"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
