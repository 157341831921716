import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-suppliers-vendors',
  templateUrl: './suppliers-vendors.component.html',
  styleUrls: ['./suppliers-vendors.component.css']
})
export class SuppliersVendorsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  activeCategory = 2;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  findByName: { supplierID: string; supplierName: string; };
  findByPhone: { phone1: string; phone2: string; phone3: string; };
  findByemail: { email: string; };
  createSupplier: any;
  outsourceTask: any;
  mergeSupplier: any;
  hours: { label: string; value: string; }[];
  minute: { label: string; value: string; }[];
  amPM: { label: string; value: string; }[];
  findSupplierList: any;
  findByAdvanced: { supplierID: string; supplierName: string; phone1: string; phone2: string; phone3: string; address: string; city: string; email: string; country: string; state: string; postalCode: string; };
  stateArray: any=[];
  countryId: any;
  array: any=[];
  stateData: any;
  statesData: any;
  countryArray: any=[];
  statesArray: any=[];
  countryID: any;
  yesNoArray: { label: string; value: string; }[];
  state: string;
  states: any;
  stateProvinceGeoId: any;
  countryGeoId: any;
  contactList: any;
  supplierAll: any;
  supplierAllArray: any[]=[];
  glAccountTypeId: any;
  glAccountTypeIdArray: any[]=[];  personId: any;
  partyIdArray: any[] = []
  currencyList: any[];
  ProductId: any;
  ProductIdArray: any[]=[];
  WorkEffortId: any;
  WorkEffortIdArray: any[]=[];
  activeCategoryA=2;
  createVendor: any;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    public createContact: CreateContactsService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly addSkillService: AddSkillService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.findByName = {
      supplierID: "",
      supplierName: ""
    };
    this.findByPhone = {
      phone1: "",
      phone2: "",
      phone3: ""
    };
    this.findByemail = {
      email: "",
    };
    this.findByAdvanced = {
      supplierID: "",
      supplierName: "",
      phone1: "",
      phone2: "",
      phone3: "",
      address: "",
      city: "", 
      email: "",
      country: "",
      state: "",
      postalCode: "",
    };
    this.createVendor = this._FormBuilder.group({
      accountNo:  [''],
      billingRateLevel:  [''],
      company:  [''],
      companyWebsite:  [''],
      contactMechPurposeTypeId:  [''],
      creditLimit:  [''],
      eligibleFor:  [''],
      federalTaxId:  [''],
      generalAddress1:  [''],
      generalAddress2:  [''],
      generalAttnName:  [''],
      generalCity:  [''],
      generalCountryGeoId:  [''],
      generalPostalCode:  [''],
      generalStateProvinceGeoId:  [''],
      generalToName:  [''],
      groupName:  [''],
      individual:  [''],
      info :  [''],
      isActive:  [''],
      isIncorporated:  [''],
      manifestCompanyName:  [''],
      manifestCompanyTitle:  [''],
      manifestLogoUrl:  [''],
      manifestPolicies:  [''],
      map:  [''],
      name:  [''],
      partyId:  [''],
      phone:  [''],
      primaryEmail:  [''],
      primaryFaxAreaCode:  [''],
      primaryFaxCountryCode:  [''],
      primaryFaxExtension:  [''],
      primaryFaxNumber:  [''],
      primaryPhoneAreaCode:  [''],
      primaryPhoneCountryCode:  [''],
      primaryPhoneExtension:  [''],
      primaryPhoneNumber:  [''],
      primaryWebUrl:  [''],
      printCheckAs:  [''],
      requires1099:  [''],
      taxId:  [''],
      terms:  [''],
      type:  [''],
      typeVendor:  [''],
      vendorEmail:  [''],
      vendorFax:  [''],
      vendorForms:  [''],
      vendorName:  [''],
      vendorPhone:  [''],
      webUrlId:  [''],
      PrimaryWebUrl:['']
    });
    this.createSupplier = this._FormBuilder.group({
      accountNo:  [''],
      billingRateLevel:  [''],
      company:  [''],
      companyWebsite:  [''],
      contactMechPurposeTypeId:  [''],
      creditLimit:  [''],
      eligibleFor:  [''],
      federalTaxId:  [''],
      generalAddress1:  [''],
      generalAddress2:  [''],
      generalAttnName:  [''],
      generalCity:  [''],
      generalCountryGeoId:  [''],
      generalPostalCode:  [''],
      generalStateProvinceGeoId:  [''],
      generalToName:  [''],
      groupName:  [''],
      individual:  [''],
      info :  [''],
      isActive:  [''],
      isIncorporated:  [''],
      manifestCompanyName:  [''],
      manifestCompanyTitle:  [''],
      manifestLogoUrl:  [''],
      manifestPolicies:  [''],
      map:  [''],
      name:  [''],
      partyId:  [''],
      phone:  [''],
      primaryEmail:  [''],
      primaryFaxAreaCode:  [''],
      primaryFaxCountryCode:  [''],
      primaryFaxExtension:  [''],
      primaryFaxNumber:  [''],
      primaryPhoneAreaCode:  [''],
      primaryPhoneCountryCode:  [''],
      primaryPhoneExtension:  [''],
      primaryPhoneNumber:  [''],
      primaryWebUrl:  [''],
      printCheckAs:  [''],
      requires1099:  [''],
      taxId:  [''],
      terms:  [''],
      type:  [''],
      typeVendor:  [''],
      vendorEmail:  [''],
      vendorFax:  [''],
      vendorForms:  [''],
      vendorName:  [''],
      vendorPhone:  [''],
      webUrlId:  [''],

    });
    this.outsourceTask = this._FormBuilder.group({
      supplier: [''],
      routingTaskID: [''],
      productID: [''],
      productName: [''],
      startDate: [''],
      hourFrom: [''],
      minuteFrom: [''],
      amPmFrom: [''],
      EndDate: [''],
      hourEnd: [''],
      minuteEnd: [''],
      amPmEnd: [''],
      minimumOrder: [''],
      supplierProductID: [''],
      supplierProductName: [''],
      price: [''],
      currency: [''],
      accTypeID: [''],
    });
    this.mergeSupplier = this._FormBuilder.group({
      fromSupplier: [''],
      toSupplier: [''],
    })
    this.hours = [
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },

    ];
    this.minute = [
      {
        label: '00',
        value: '00'
      },
      {
        label: '01',
        value: '01'
      },
      {
        label: '02',
        value: '02'
      },
      {
        label: '03',
        value: '03'
      },
      {
        label: '04',
        value: '04'
      },
      {
        label: '05',
        value: '05'
      },
      {
        label: '06',
        value: '06'
      },
      {
        label: '07',
        value: '07'
      },
      {
        label: '08',
        value: '08'
      },
      {
        label: '09',
        value: '09'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      },
      {
        label: '21',
        value: '21'
      },
      {
        label: '22',
        value: '22'
      },
      {
        label: '23',
        value: '23'
      },
      {
        label: '24',
        value: '24'
      },
      {
        label: '25',
        value: '25'
      },
      {
        label: '26',
        value: '26'
      },
      {
        label: '27',
        value: '27'
      },
      {
        label: '28',
        value: '28'
      },
      {
        label: '29',
        value: '29'
      },
      {
        label: '30',
        value: '30'
      },
      {
        label: '31',
        value: '31'
      },
      {
        label: '32',
        value: '32'
      },
      {
        label: '33',
        value: '33'
      }, {
        label: '34',
        value: '34'
      },
      {
        label: '35',
        value: '35'
      }, {
        label: '36',
        value: '36'
      },
      {
        label: '37',
        value: '37'
      }, {
        label: '38',
        value: '38'
      },
      {
        label: '39',
        value: '39'
      },
      {
        label: '40',
        value: '40'
      }, {
        label: '41',
        value: '41'
      },
      {
        label: '42',
        value: '42'
      },
      {
        label: '43',
        value: '43'
      }, {
        label: '44',
        value: '44'
      },
      {
        label: '45',
        value: '45'
      },
      {
        label: '46',
        value: '46'
      }, {
        label: '47',
        value: '47'
      },
      {
        label: '48',
        value: '48'
      },
      {
        label: '49',
        value: '49'
      }, {
        label: '50',
        value: '50'
      },
      {
        label: '51',
        value: '51'
      },
      {
        label: '52',
        value: '52'
      },
      {
        label: '53',
        value: '53'
      }, {
        label: '54',
        value: '54'
      }, {
        label: '55',
        value: '55'
      }, {
        label: '56',
        value: '56'
      }, {
        label: '57',
        value: '57'
      },
      {
        label: '58',
        value: '58'
      },
      {
        label: '59',
        value: '59'
      }


    ];
    this.amPM = [
      {
        label: 'AM',
        value: 'AM'
      },
      {
        label: 'PM',
        value: 'PM'
      },
    ];
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
  }

  ngOnInit(): void {
    this.getfindSupplier();
    this.getCountryList();
    this.getSupplierAll();
    this.getCurrency();
    this.getGlAccountTypeId();
    this.getProductId();
    this.getWorkEffortId();
    this.getPartyId();
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/vendor/Dashboard']);
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "manifestCompanyName": this.createVendor.value.manifestCompanyName,
      "manifestCompanyTitle": this.createVendor.value.manifestCompanyTitle,
      "manifestLogoUrl": this.createVendor.value.manifestLogoUrl,
      "manifestPolicies": this.createVendor.value.manifestPolicies,
      "partyId": this.createVendor.value.partyId,
      "accountNo": this.createVendor.value.accountNo,
      "billingRateLevel": this.createVendor.value.billingRateLevel,
      "creditLimit": this.createVendor.value.creditLimit,
      "eligibleFor": this.createVendor.value.eligibleFor,
      "printCheckAs": this.createVendor.value.printCheckAs,
      "taxId": this.createVendor.value.taxId,
      "terms": this.createVendor.value.terms,
      "type": this.createVendor.value.type,
      "company": this.createVendor.value.company,
      "companyWebsite": this.createVendor.value.companyWebsite,
      "individual": this.createVendor.value.individual,
      "isActive": this.createVendor.value.isActive,
      "map": this.createVendor.value.map,
      "name": this.createVendor.value.name,
      "phone": this.createVendor.value.phone,
      "typeVendor": "distributor",
      "vendorEmail": this.createVendor.value.vendorEmail,
      "vendorFax": this.createVendor.value.vendorFax,
      "vendorForms": this.createVendor.value.vendorForms,
      "vendorName": this.createVendor.value.vendorName,
      "vendorPhone": this.createVendor.value.vendorPhone,
      "contactMechPurposeTypeId": this.createVendor.value.contactMechPurposeTypeId,
      "federalTaxId": this.createVendor.value.federalTaxId,
      "generalAddress1": this.createVendor.value.generalAddress1,
      "generalAddress2": this.createVendor.value.generalAddress2,
      "generalAttnName": this.createVendor.value.generalAttnName,
      "generalCity": this.createVendor.value.generalCity,
      "generalCountryGeoId": this.createVendor.value.generalCountryGeoId,
      "generalPostalCode": this.createVendor.value.generalPostalCode,
      "generalStateProvinceGeoId": this.createVendor.value.generalStateProvinceGeoId,
      "generalToName": this.createVendor.value.generalToName,
      "groupName": this.createVendor.value.groupName,
      "infoString": this.createVendor.value.infoString,
      "isIncorporated": this.createVendor.value.isIncorporated,
      "primaryEmail": this.createVendor.value.primaryEmail,
      "primaryFaxAreaCode": this.createVendor.value.primaryFaxAreaCode,
      "primaryFaxCountryCode": this.createVendor.value.primaryFaxCountryCode,
      "primaryFaxExtension": this.createVendor.value.primaryFaxExtension,
      "primaryFaxNumber": this.createVendor.value.primaryFaxNumber,
      "primaryPhoneAreaCode": this.createVendor.value.primaryPhoneAreaCode,
      "primaryPhoneCountryCode": this.createVendor.value.primaryPhoneCountryCode,
      "primaryPhoneExtension": this.createVendor.value.primaryPhoneExtension,
      "primaryPhoneNumber": this.createVendor.value.primaryPhoneNumber,
      "primaryWebUrl": this.createVendor.value.primaryWebUrl,
      "requires1099": this.createVendor.value.requires1099,
      "webUrlId":this.createVendor.value.webUrlId,
    }
    this.accountsService.postVendors(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.closebutton1.nativeElement.click();
        this.createVendor.reset();
        this.getfindSupplier();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  getWorkEffortId() {
    this.spinner.show();
    this.myContactsService.getWorkEffortId().subscribe(res => {
      this.WorkEffortId = res.data[0].getWorkEffortId;
      this.spinner.hide();
      for (const value of this.WorkEffortId) {
        this.WorkEffortIdArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
  }
  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
   
  }
  detailPage(id) {
    this._Router.navigate(["/vendor/Suppliers/viewSupplier"],
      { queryParams: { partyId: id } })
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.currencyList = data.data.currencyList.map(value => {
            return {
              label: value.description,
              value: value.uomId
            };
          });
        }
      });
  }
  getSupplierAll() {
    this.spinner.show();
    this.myContactsService.getSupplierAll().subscribe(res => {
      this.supplierAll = res.data[0].getEditSupplier;
      this.spinner.hide();
      for (const value of this.supplierAll) {
        this.supplierAllArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }
    })
  }
  getGlAccountTypeId() {
    this.spinner.show();
    this.myContactsService.getGlAccountTypeId().subscribe(res => {
      this.glAccountTypeId = res.data[0].getGlAccountTypeId;
      this.spinner.hide();
      for (const value of this.glAccountTypeId) {
        this.glAccountTypeIdArray.push({
          label: value.glAccountTypeId,
          value: value.glAccountTypeId
        })
      }
    })
  }
  createOutsourceRoutingTask(){
    this.spinner.show();
    const requestData = {
    
      "availableFromDate":this.datePipe.transform(this.outsourceTask.value.startDate , "yyyy-MM-dd hh:mm:ss"),
      "availableFromDateAmPm":  this.outsourceTask.value.amPmFrom ,
      "availableFromDateCompositeType":  "Timestamp",
      "availableFromDateHour":  this.outsourceTask.value.hourFrom ,
      "availableFromDateMin":  this.outsourceTask.value.minuteFrom ,
      "availableThruDate": this.datePipe.transform(this.outsourceTask.value.EndDate , "yyyy-MM-dd hh:mm:ss"),
      "availableThruDateAmPm":  this.outsourceTask.value.amPmEnd ,
      "availableThruDateCompositeType":   "Timestamp",
      "availableThruDateHour":  this.outsourceTask.value.hourEnd ,
      "availableThruDateMin":  this.outsourceTask.value.minuteEnd ,
      "currencyUomId":  this.outsourceTask.value.currency ,
      "glAccountTypeId":  this.outsourceTask.value.accTypeID ,
      "minimumOrderQuantity":  this.outsourceTask.value.minimumOrder ,
      "outsourceRoutingTaskId":  "",
      "partyId":  this.outsourceTask.value.supplier ,
      "productId":  this.outsourceTask.value.productID ,
      "productName":  this.outsourceTask.value.productName ,
      "supplierProductId":  this.outsourceTask.value.supplierProductID ,
      "supplierProductName":  this.outsourceTask.value.supplierProductName ,
      "workEffortId": this.outsourceTask.value.routingTaskID ,
    }
    this.myContactsService.createOutsourceRoutingTask(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.outsourceTask.reset();
        this.getfindSupplier();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  createSuppliers(){
    this.spinner.show();
    const requestData = {
      "manifestCompanyName": this.createVendor.value.manifestCompanyName,
      "manifestCompanyTitle": this.createVendor.value.manifestCompanyTitle,
      "manifestLogoUrl": this.createVendor.value.manifestLogoUrl,
      "manifestPolicies": this.createVendor.value.manifestPolicies,
      "partyId": this.createVendor.value.partyId,
      "accountNo": this.createVendor.value.accountNo,
      "billingRateLevel": this.createVendor.value.billingRateLevel,
      "creditLimit": this.createVendor.value.creditLimit,
      "eligibleFor": this.createVendor.value.eligibleFor,
      "printCheckAs": this.createVendor.value.printCheckAs,
      "taxId": this.createVendor.value.taxId,
      "terms": this.createVendor.value.terms,
      "type": this.createVendor.value.type,
      "company": this.createVendor.value.company,
      "companyWebsite": this.createVendor.value.companyWebsite,
      "individual": this.createVendor.value.individual,
      "isActive": this.createVendor.value.isActive,
      "map": this.createVendor.value.map,
      "name": this.createVendor.value.name,
      "phone": this.createVendor.value.phone,
      "typeVendor": "distributor",
      "vendorEmail": this.createVendor.value.vendorEmail,
      "vendorFax": this.createVendor.value.vendorFax,
      "vendorForms": this.createVendor.value.vendorForms,
      "vendorName": this.createVendor.value.vendorName,
      "vendorPhone": this.createVendor.value.vendorPhone,
      "contactMechPurposeTypeId": this.createVendor.value.contactMechPurposeTypeId,
      "federalTaxId": this.createVendor.value.federalTaxId,
      "generalAddress1": this.createVendor.value.generalAddress1,
      "generalAddress2": this.createVendor.value.generalAddress2,
      "generalAttnName": this.createVendor.value.generalAttnName,
      "generalCity": this.createVendor.value.generalCity,
      "generalCountryGeoId": this.createVendor.value.generalCountryGeoId,
      "generalPostalCode": this.createVendor.value.generalPostalCode,
      "generalStateProvinceGeoId": this.createVendor.value.generalStateProvinceGeoId,
      "generalToName": this.createVendor.value.generalToName,
      "groupName": this.createVendor.value.groupName,
      "infoString": this.createVendor.value.infoString,
      "isIncorporated": this.createVendor.value.isIncorporated,
      "primaryEmail": this.createVendor.value.primaryEmail,
      "primaryFaxAreaCode": this.createVendor.value.primaryFaxAreaCode,
      "primaryFaxCountryCode": this.createVendor.value.primaryFaxCountryCode,
      "primaryFaxExtension": this.createVendor.value.primaryFaxExtension,
      "primaryFaxNumber": this.createVendor.value.primaryFaxNumber,
      "primaryPhoneAreaCode": this.createVendor.value.primaryPhoneAreaCode,
      "primaryPhoneCountryCode": this.createVendor.value.primaryPhoneCountryCode,
      "primaryPhoneExtension": this.createVendor.value.primaryPhoneExtension,
      "primaryPhoneNumber": this.createVendor.value.primaryPhoneNumber,
      "primaryWebUrl": this.createVendor.value.primaryWebUrl,
      "requires1099": this.createVendor.value.requires1099,
      "webUrlId":this.createVendor.value.webUrlId,
    }
    this.myContactsService.createSupplier(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.createVendor.reset();
       this.getfindSupplier();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCountryList() {
    this.spinner.show();
    this.createContact.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })
      })
    })
   
  }

  onCountryChange(event) {
    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }
  onStateChange(event) {
    this.stateProvinceGeoId = event
  }
  getStateList() {
    this.spinner.show();
    this.createContact.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
   
  }


 


  getfindSupplier() {
    this.spinner.show();
    const formData = {
      "addressLine1": "",
      "city": "",
      "country": "",
      "postalCode": "",
      "primaryPhoneNumber": "",
      "stateProvince": "",
    "primaryEmail": "",
    "supplierId": "",
      "supplierName": ""
    }
    this.myContactsService.findSupplier(formData).subscribe(res => {
      this.findSupplierList = res.data;
      this.spinner.hide();

    })
  }
  // getCountry() {
  //   this.spinner.show();
  //   this.accountsService.getCountry().subscribe(res => {
  //     var Country = res.data.countryList;
  //     this.spinner.hide();
  //     Country.forEach(element => {
  //       this.countryArray.push({
  //         label: element.split(":")[0],
  //         value: element.split(":").pop().trim()
  //       })
  //     })
  //   })
  // }
  // onChangeState(event) {
  //   this.states = event
  // }
  // onChange(event) {  ///// CREATE FORM DROPDOWN
  //   this.statesArray = [];
  //   this.countryID = event;
  //   if (event) {
  //     this.getStateLists();
  //   }
  
  // }
  // getStateLists() {
  //   this.statesArray = [];
  //   this.spinner.show();
  //   this.accountsService.getState(this.countryID).subscribe(res => {
  //     this.statesData = res.data.stateList;
  //     this.spinner.hide();
  //     this.statesData.forEach(stateLists => {
  //       var splitState = stateLists.split(":");
  //       var stateIndex = splitState[0];
  //       this.statesArray.push({
  //         label: stateIndex,
  //         value: stateLists.split(":").pop().trim()
  //       })
  //     })

  //   })


  // }
  
  reset() {

    this.spinner.show();
    this.findByAdvanced.address = "";
    this.findByAdvanced.city = "";
    this.findByAdvanced.country = "";
    this.findByAdvanced.postalCode = "";
    this.findByAdvanced.phone1 = "";
    this.findByAdvanced.state = "";
    this.findByAdvanced.supplierID = "";
    this.findByAdvanced.supplierName = "";
    this.findByAdvanced.email = "";
   

    const formData = {
    "addressLine1": "",
    "city": "",
    "country": "",
    "postalCode": "",
    "primaryPhoneNumber": "",
    "stateProvince": "",
    "supplierId": "",
    "primaryEmail": "",
    "supplierName": ""
    }

    this.myContactsService.findSupplier(formData).subscribe(res => {
      this.findSupplierList = res.data;
      this.spinner.hide();
    })
  }

  findSupplier() {
    this.spinner.show();
    this.findSupplierList = [];
    const formData = {
      
      "supplierId": this.findByAdvanced.supplierID ? this.findByAdvanced.supplierID : '',
      "supplierName": this.findByAdvanced.supplierName ? this.findByAdvanced.supplierName : '',
      "primaryPhoneNumber": this.findByAdvanced.phone1 ? this.findByAdvanced.phone1 : '',
      "addressLine1": this.findByAdvanced.address ? this.findByAdvanced.address : '',
      "city": this.findByAdvanced.city ? this.findByAdvanced.city : '',
      "country": this.findByAdvanced.country ? this.findByAdvanced.country : '',
      "stateProvince": this.findByAdvanced.state ? this.findByAdvanced.state : '',
      "postalCode": this.findByAdvanced.postalCode ? this.findByAdvanced.postalCode : '',
      "primaryEmail": this.findByAdvanced.email ? this.findByAdvanced.email : '',
    
    }
    this.myContactsService.findSupplier(formData).subscribe(res => {
      this.findSupplierList = res.data;
      this.spinner.hide();

    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  changeactiveCategoryFunctionA(tab: number) {
    this.activeCategoryA = tab;
  }
}
