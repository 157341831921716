import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { WarehouseService } from '../../warehouse/warehouse.service';

@Component({
    selector: 'app-create-new-contact-information',
    templateUrl: './create-new-contact-information.component.html',
    styleUrls: ['./create-new-contact-information.component.css']
})
export class CreateNewContactInformationComponent implements OnInit {
    facilityId: string;
    contactTypes: any[];
    activeCategory = 2;
    contactInformationTab: string;
    contactInformationValue: string;
    contactMechTypeId: string;
    commonElectronicAddressForm: FormGroup;
    contactPurposeIds: any[];
    emailAddressForm: FormGroup;
    postalAddressForm: FormGroup;
    countryList: any[];
    states: any[];
    telecomNumberForm: FormGroup;
    contactMechId: string;
    editMode: boolean;
    contactData: any;
    statesId: any;
    countryId: any;
    url: string;
    @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('closebutton') closebutton;
    constructor(
        readonly  _Router: Router,
        readonly _WarehouseService: WarehouseService,
        readonly _ToastrService: ToastrService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly spinner: NgxSpinnerService,
        readonly _FormBuilder: FormBuilder,
        readonly _GlobalResourceService: GlobalResourceService,
        public element: ElementRef
    ) {
        this.url='/facilities/warehouse/detailed-summary-facilities'
        this.commonElectronicAddressForm = this._FormBuilder.group({
            contactMechPurposeTypeId: [''],
            contactMechTypeId: [''],
            facilityId: [''],
            infoString: ['', [Validators.required]]
        });
        this.facilityId = '';
        this.contactTypes = [];
        this.contactInformationTab = 'initial';
        this.contactInformationValue = 'ELECTRONIC_ADDRESS';
        this.contactPurposeIds = [];
        this.emailAddressForm = this._FormBuilder.group({
            contactMechPurposeTypeId: ['', [Validators.required]],
            contactMechTypeId: [''],
            emailAddress: ['', [Validators.required]],
            facilityId: ['']
        });
        this.constructorExt();
    }
    constructorExt(): void {
        this.postalAddressForm = this._FormBuilder.group({
            address1: ['', [Validators.required]],
            address2: [''],
            attnName: [''],
            city: ['', [Validators.required]],
            contactMechPurposeTypeId: ['', [Validators.required]],
            contactMechTypeId: [''],
            countryGeoId: ['IND'],
            facilityId: [''],
            postalCode: ['', [Validators.required]],
            stateProvinceGeoId: [''],
            toName: ['']
        });
        this.telecomNumberForm = this._FormBuilder.group({
            areaCode: ['', [Validators.required]],
            contactMechPurposeTypeId: ['', [Validators.required]],
            contactMechTypeId: [''],
            contactNumber: ['', [Validators.required]],
            countryCode: ['', [Validators.required]],
            extension: ['', [Validators.required]],
            facilityId: ['']
        });
        this.contactMechId = '';
        this.editMode = false;
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {  
        this.facilityId = this._ActivatedRoute.snapshot.queryParams.facilityId;
        this.contactMechId = this._ActivatedRoute.snapshot.queryParams.contactMechId;
        if (this.contactMechId) {
            this.editMode = true;
            this._WarehouseService.getContactById(this.contactMechId, this.facilityId)
                .then(data => {
                    this.contactData = data.data;
                    if (data.data.contactMech.contactMechTypeId === 'EMAIL_ADDRESS') {
                       
                        this.emailAddressForm.patchValue(data.data.contactMech);
                        
                        this.contactInformationValue = data.data.contactMech.contactMechTypeId;
                    } else if (data.data.contactMech.contactMechTypeId === 'TELECOM_NUMBER') {
                        this.telecomNumberForm.patchValue(data.data.contactMech);
                        this.contactInformationValue = data.data.contactMech.contactMechTypeId;
                    } else if (data.data.contactMech.contactMechTypeId === 'POSTAL_ADDRESS') {
                        this.postalAddressForm.patchValue(data.data.contactMech);
                        this.contactInformationValue = data.data.contactMech.contactMechTypeId;
                    } else {
                        this.commonElectronicAddressForm.patchValue(data.data.contactMech);
                        this.contactInformationValue = data.data.contactMech.contactMechTypeId;
                    }
                    this.prepareContactForm();
                }); }
        this.getContactType(); this.getCountryList();
        this.postalAddressForm.controls.countryGeoId.valueChanges.subscribe(value => {
            if (value) {
                this.getStateList(value.trim());
            }}); }
    getCountryList(): void {
        this.spinner.show();
        this._GlobalResourceService.getCountryList()
            .then(data => {
                this.spinner.hide();
                this.countryList = data.data.countryList.map(value => {
                    return {
                        label: value,
                        value: value.split(':')[1]
                    };
                });
            });
           
    }
    getStateList(countryId: string): void {
        this.spinner.show();
        this._GlobalResourceService.getStateList(countryId)
            .then(data => {
                this.spinner.hide();
                this.states = data.data.stateList.map(value => {
                    return {
                        label: value,
                        value
                    };
                });
            });
            
    }
    getContactType(): void {
        this.spinner.show();
        this._WarehouseService.getContactType()
            .then(data => {
                this.spinner.hide();
                this.contactTypes = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.contactMechTypeId
                    };
                });
            });
           
    }
    getContactPurposeList(): void {
        this.spinner.show();
        this._WarehouseService.getContactPurposeList(this.facilityId, this.contactMechTypeId)
            .then(data => {
                this.spinner.hide();
                this.contactPurposeIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.contactMechPurposeTypeId
                    };
                });
            });
           
    }
    prepareContactForm(): void {
        
        this.contactMechTypeId = this.contactInformationValue;
        this.contactInformationTab = (this.contactInformationValue === 'EMAIL_ADDRESS' || this.contactInformationValue === 'TELECOM_NUMBER' || this.contactInformationValue === 'POSTAL_ADDRESS') ? this.contactInformationValue : 'ELECTRONIC_ADDRESS';
        this.getContactPurposeList();
    }
    submitCommonElectronicAddress(): void {
        this.spinner.show();
        if (this.editMode) {
            if (this.commonElectronicAddressForm.valid) {
                this._WarehouseService.updateCommonElectronicAddress(this.contactData.contactMech.contactMechId, this.contactData.contactMech.contactMechTypeId, this.facilityId, this.commonElectronicAddressForm.value.infoString)
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Updated');
                            this.spinner.hide();
                            this.commonElectronicAddressForm.reset();
                            this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                        }
                    });
            }
            else if (!this.commonElectronicAddressForm.valid) {
                this.spinner.hide();
                this._ToastrService.error('Please fill valid data');
            }
           
          
        } else {

            this._WarehouseService.createCommonElectronicAddress({
                ...this.commonElectronicAddressForm.value, ...{
                    contactMechTypeId: this.contactMechTypeId,
                    facilityId: this.facilityId
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Added');
                        this.spinner.hide();
                        this.commonElectronicAddressForm.reset();
                        this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                    }
                });


        }
        this.modalClose();
       
    }
    submitEmailAddress(): void {
        this.spinner.show();
        if (this.editMode) {
            if (this.emailAddressForm.valid) {
                this._WarehouseService.updateEmailAddress({
                    ...this.emailAddressForm.value,
                    ...{contactMechTypeId: this.contactData.contactMech.contactMechTypeId,
                        facilityId: this.facilityId,contactMechId:this.contactData.contactMech.contactMechId}
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Updated');
                            this.spinner.hide();
                            this.emailAddressForm.reset();
                            this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                        }
                    });
            }
            else if (!this.emailAddressForm.valid) {
                this.spinner.hide();
                this._ToastrService.error('Please fill valid data');
            }
          
        } else {
            if (this.emailAddressForm.valid) {
                this._WarehouseService.createEmailAddress({
                    ...this.emailAddressForm.value, ...{
                        contactMechTypeId: this.contactMechTypeId,
                        facilityId: this.facilityId
                    }
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Added');
                            this.spinner.hide();
                            this.emailAddressForm.reset();
                            this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                        }
                    });
            }
            else if (!this.emailAddressForm.valid) {
                this.spinner.hide();
                this._ToastrService.error('Please fill valid data');
            }
        }
       
    }
    submitPostalAddress(): void {
        this.spinner.show();
        if (this.editMode) {
            if (this.postalAddressForm.valid) {
                this._WarehouseService.updatePostalAddress({
                   ...this.postalAddressForm.value, ...{contactMechTypeId: this.contactData.contactMech.contactMechTypeId,
                    facilityId: this.facilityId,contactMechId:this.contactData.contactMech.contactMechId}
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Updated');
                            this.spinner.hide();
                            this.emailAddressForm.reset();
                            this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                        }
                    });
            }
            else if (!this.postalAddressForm.valid) {
                this.spinner.hide();
                this._ToastrService.error('Please fill valid data');
            }
           
        } else {
            if (this.postalAddressForm.valid) {
                this.statesId = this.postalAddressForm.value.stateProvinceGeoId.split(': ');
                this.countryId = this.postalAddressForm.value.countryGeoId.trim();
                console.clear();
              
                const data = {
                    address1: this.postalAddressForm.value.address1,
                    address2: this.postalAddressForm.value.address2,
                    attnName: this.postalAddressForm.value.attnName,
                    city: this.postalAddressForm.value.city,
                    contactMechPurposeTypeId: this.postalAddressForm.value.contactMechPurposeTypeId,
                    contactMechTypeId: this.postalAddressForm.value.contactMechTypeId,
                    countryGeoId: this.countryId,
                    facilityId: this.postalAddressForm.value.facilityId,
                    postalCode: this.postalAddressForm.value.postalCode,
                    stateProvinceGeoId: this.statesId[1],
                    toName: this.postalAddressForm.value.toName
                }
                this._WarehouseService.createPostalAddress({
                    ...data, ...{
                        contactMechTypeId: this.contactMechTypeId,
                        facilityId: this.facilityId
                    }
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Added');
                            this.spinner.hide();
                            this.emailAddressForm.reset();
                            this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                        }
                    });
            }
            else if (!this.postalAddressForm.valid) {
                this.spinner.hide();
                this._ToastrService.error('Please fill valid data');
            }
        }
       
    }
    submitTelecomNumber(): void {
        this.spinner.show();
        if (this.editMode) {
            this._WarehouseService.updateTelecomNumber({
                ...this.telecomNumberForm.value,
                ...{contactMechTypeId: this.contactData.contactMech.contactMechTypeId,
                    facilityId: this.facilityId,contactMechId:this.contactData.contactMech.contactMechId}
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this.emailAddressForm.reset();
                        this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                    }
                });
            
        } else {
            this._WarehouseService.createTelecomNumber({
                ...this.telecomNumberForm.value, ...{
                    contactMechTypeId: this.contactMechTypeId,
                    facilityId: this.facilityId
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Added');
                        this.spinner.hide();
                        this.emailAddressForm.reset();
                        this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                    }
                });
        }
     
    }
    modalClose(): void {
        this.closebutton.nativeElement.click();
        this.modalClosed.emit();
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }

    onBack()
    {
        this._Router.navigate(['/facilities/warehouse/detailed-summary-facilities'])
    }
}