import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-new-party-classification-group',
  templateUrl: './create-new-party-classification-group.component.html',
  styleUrls: ['./create-new-party-classification-group.component.css']
})
export class CreateNewPartyClassificationGroupComponent implements OnInit {
  createClassification:FormGroup;
  activeCategory=1;
  @ViewChild('closebutton') closebutton;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[]=[];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  efromDate: any;
  ethruDate: any;
  getthruDate: string;
  ClassificationGroup: any;
  ClassificationGroupTypeId: any;
  ClassificationGroupTypeIdArray: any[]=[];
  classId: any;
 
 
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
 
  editData: any;
  PartyClassificationById: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly router: Router,
    private _location: Location,) { 
      this.contentIds = [];
      this.createClassification = this._FormBuilder.group({
        Description: [''],
        ParentGroupID: [''],
        PartyClassificationTypeID:['']
       
        
      });
     
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   
 this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
 if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.classId = params["classId"];
    });
    this.show=true;
    this.getPartyClassificationGroupById();
    this.getPartyClassificationById();
    this.getClassificationGroup();
    this.getClassificationGroupTypeId();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/hub/Dashboard']);
  }
  getPartyClassificationGroupByIdEdit(){
    this.spinner.show();
   
    this.accountsService.getPartyClassificationGroupById(this.classId).subscribe((res:any)=> {
      setTimeout(() => {
     
        const formValue = this.createClassification;
        formValue.patchValue({
          Description: res.data[0].partyClassificationGroupData.description,
        ParentGroupID:res.data[0].partyClassificationGroupData.parentGroupId,
        PartyClassificationTypeID:res.data[0].partyClassificationGroupData.partyClassificationTypeId,
        })
      
      }, 3000);
      this.spinner.hide();
    })  
   }
  
  getClassificationGroupTypeId(){
    this.spinner.show();
   
      this.accountsService.getClassificationGroupTypeId().subscribe((res:any)=> {
        this.ClassificationGroupTypeId = res.data;
        this.spinner.hide();
        for(const value of this.ClassificationGroupTypeId){
          this.ClassificationGroupTypeIdArray.push({
            label:value.partyClassificationTypeId.description,
            value:value.partyClassificationTypeId.partyClassificationTypeId
          })
        }
      
      })
     
     }
  getClassificationGroup(){
    this.spinner.show();
   
      this.accountsService.getClassificationGroup().subscribe((res:any)=> {
        this.ClassificationGroup = res.data;
        this.spinner.hide();
        for(const value of this.ClassificationGroup){
          this.ClassificationGroupIdArray.push({
            label:value.description.description,
            value:value.partyClassificationGroupData.partyClassificationGroupId
          })
        }
      
      })
     
     }
 
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "description": this.createClassification.value.Description,
      "parentGroupId": this.createClassification.value.ParentGroupID,
      "partyClassificationTypeId": this.createClassification.value.PartyClassificationTypeID,
      "partyClassificationGroupId":this.classId,
    
     
    }
    this.accountsService.updatePartyClassificationTypeId(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getPartyClassificationGroupById();
      
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this.toastr.error(err.error.message);
        }
    });

  }
 
  getPartyClassificationGroupById(){
    this.spinner.show();
   
    this.accountsService.getPartyClassificationGroupById(this.classId).subscribe((res:any)=> {
      this.editData=res.data[0].partyClassificationGroupData
      this.spinner.hide();
    })  
   }
   
   getPartyClassificationById(){
    this.spinner.show();
   
    this.accountsService.getPartyClassificationById(this.classId).subscribe((res:any)=> {
      this.PartyClassificationById=res.data
      this.spinner.hide();
    })  
   }
   deletePartyClassification(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req={
          "fromDate": product.fromDate,
          "partyClassificationGroupId":product.partyRateData.partyClassificationGroupId,
          "partyId": product.partyRateData.partyId,
          "thruDate": product.thruDate,
        }
        this.accountsService.deletePartyClassification(req).subscribe(res => {
          this.getPartyClassificationById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  goToCreateNewClassificationGroup(){
    this.router.navigate(['/security/create-group-classification'],{ queryParams: { classId:this.classId}});
  }
   createClassificationNew(){
    this.router.navigate(['/security/party-classification'],{ queryParams: { classId:this.classId}});
  }
  updatePartyClassification(party,epartyClassId,fromdate,thrudate){
    this.router.navigate(["security/party-classification"],{queryParams:{party:party,classId:epartyClassId,fromDate:fromdate,thruDate:thrudate}});
  }
 
  GoToQuickLink(){
    this.router.navigate(['/partyQuickLink']);
  }
}
