<div class="container-fluid main-container-wrapper mt-4">
    <div class="row" style="background-color: #eff4f7;">
        <div class="col-12">
            <div style="padding-top:80px;">
                <div style="padding-right: 10px;">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-4 pl-0">
                                <div class="w3-card-4 classCardSale" style="margin:  5px 0 5px 10px;">
                                    <!-- <div class="">
                            <ul class="">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                       </a></li>
                            </ul>
                        </div> -->
                                    <div>
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="heads"> Sales Order </div>

                                                <div id="chart">
                                                    <apx-chart [series]="chartOptions.series"
                                                        [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
                                                        [stroke]="chartOptions.stroke"
                                                        [dataLabels]="chartOptions.dataLabels"
                                                        [yaxis]="chartOptions.yaxis" [labels]="chartOptions.labels"
                                                        [legend]="chartOptions.legend" [title]="chartOptions.title"
                                                        [subtitle]="chartOptions.subtitle"></apx-chart>
                                                </div>



                                                <!-- <script src="https://cdn.amcharts.com/lib/4/core.js"></script>
                                                <script src="https://cdn.amcharts.com/lib/4/charts.js"></script>
                                                <script
                                                    src="https://cdn.amcharts.com/lib/4/plugins/forceDirected.js"></script>
                                                <script
                                                    src="https://cdn.amcharts.com/lib/4/themes/animated.js"></script>
                                                <div id="chartdiv12" style="height: 180px;width: 100%;"></div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">

                                <div class="w3-card-4 classCardSale" style="margin:  5px 0 5px 10px;">
                                    <!-- <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Purchase Order Dashboard</a></li>


                            </ul>
                        </div> -->

                                    <div>
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="heads"> Purchase Order </div>

                                                <div id="chart">
                                                    <apx-chart [series]="chartOptionsPurchase.series"
                                                        [chart]="chartOptionsPurchase.chart"
                                                        [xaxis]="chartOptionsPurchase.xaxis"
                                                        [stroke]="chartOptionsPurchase.stroke"
                                                        [dataLabels]="chartOptionsPurchase.dataLabels"
                                                        [yaxis]="chartOptionsPurchase.yaxis"
                                                        [labels]="chartOptionsPurchase.labels"
                                                        [legend]="chartOptionsPurchase.legend"
                                                        [title]="chartOptionsPurchase.title"
                                                        [subtitle]="chartOptionsPurchase.subtitle"></apx-chart>
                                                </div>


                                                <!-- <script src="https://cdn.amcharts.com/lib/4/core.js"></script>
                                                <script src="https://cdn.amcharts.com/lib/4/charts.js"></script>
                                                <script
                                                    src="https://cdn.amcharts.com/lib/4/plugins/forceDirected.js"></script>
                                                <script
                                                    src="https://cdn.amcharts.com/lib/4/themes/animated.js"></script>
                                                <div id="chartdiv22" style="height: 180px;width: 100%;"></div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 pr-0">

                                <div class="w3-card-4 classCardSale" style="margin:  5px 0 5px 10px;">
                                    <!-- <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Purchase Order </a></li>


                            </ul>
                        </div> -->

                                    <div>
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="heads"> Pending Order </div>

                                                <div id="chart">
                                                    <apx-chart [series]="ChartOptionsPending.series"
                                                        [chart]="ChartOptionsPending.chart"
                                                        [xaxis]="ChartOptionsPending.xaxis"
                                                        [stroke]="ChartOptionsPending.stroke"
                                                        [dataLabels]="ChartOptionsPending.dataLabels"
                                                        [yaxis]="ChartOptionsPending.yaxis"
                                                        [labels]="ChartOptionsPending.labels"
                                                        [legend]="ChartOptionsPending.legend"
                                                        [title]="ChartOptionsPending.title"
                                                        [subtitle]="ChartOptionsPending.subtitle"></apx-chart>
                                                </div>

                                                <!-- <script src="https://cdn.amcharts.com/lib/4/core.js"></script>
                                                <script src="https://cdn.amcharts.com/lib/4/charts.js"></script>
                                                <script
                                                    src="https://cdn.amcharts.com/lib/4/plugins/forceDirected.js"></script>
                                                <script
                                                    src="https://cdn.amcharts.com/lib/4/themes/animated.js"></script>
                                                <div id="chartdiv22" style="height: 180px;width: 100%;"></div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <div style="padding-right: 10px;">
                    <div style="width: 48%;float: left;margin: 5px 0 5px 10px;margin-top: 3.5%;">
                        <app-warehouse-dashboard></app-warehouse-dashboard>
                    </div>
                    <div style="width: 48%;float:right;margin: 5px 0 5px 10px;margin-top: 3.5%;">
                        <app-user-dashboard></app-user-dashboard>
                    </div>
                </div>
                <br>
                <br>
                <div style="padding-right: 10px;">
                    <div style="width: 49.3%;float: left;margin-top: 3.6%;">
                        <div class="w3-card-4 classCard">


                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="heads"> Catalog Dashboard</div>

                                        <div id="chartA">

                                            <apx-chart [series]="ChartOptionsCatalog.series"
                                                [chart]="ChartOptionsCatalog.chart" [yaxis]="ChartOptionsCatalog.yaxis"
                                                [xaxis]="ChartOptionsCatalog.xaxis"
                                                [labels]="ChartOptionsCatalog.labels"
                                                [stroke]="ChartOptionsCatalog.stroke"
                                                [markers]="ChartOptionsCatalog.markers"
                                                [fill]="ChartOptionsCatalog.fill"
                                                [tooltip]="ChartOptionsCatalog.tooltip"></apx-chart>

                                        </div>
                                        <!-- <script src="https://cdn.amcharts.com/lib/4/core.js"></script>
                                        <script src="https://cdn.amcharts.com/lib/4/charts.js"></script>
                                        <script src="https://cdn.amcharts.com/lib/4/themes/animated.js"></script>
                                        <div id="chartdiv44" style=" width: 70%;
                        height: 400px;
                        margin-left: 8%;"></div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <!--  <app-catalog-dashboard></app-catalog-dashboard> -->
                    <div style="    width: 48%;float:right;margin-top: 3.5%;">
                        <app-ar-dashboard></app-ar-dashboard>
                    </div>
                </div>
                <br>
                <br>

                <div style="padding-right: 10px;">

                    <div style="    width: 48%;
               float: left;
                    margin-left: 10px;background-color: #fff;margin-top: 3.5%;margin-bottom: 10%;">

                        <app-hrm-dashboard></app-hrm-dashboard>

                    </div>

                    <div style="width: 48%;float:right;background-color: #fff;margin-top: -4.3%;padding-bottom:6%;">
                        <app-crm-dashboard></app-crm-dashboard>
                    </div>
                    <br><br><br>

                </div>



            </div>
        </div>
    </div>
</div>
<br><br><br>

<br><br><br>