<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <a><span class="color-grey" (click)="navigatePrev()">Leaves </span></a>
                <span class="color-black pl-1"> > Add New Leaves</span>
                <button type="button"  (click)="cancelLeave()" class="btn btn-danger buttonclass" >Cancel</button>

            </div>
           
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" >
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Add New Employee Leaves</a></li>
                       
                     
                    </ul>
                  </div>
               
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form *ngIf="add_leave_form" [formGroup]="add_leave_form" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Leave Type ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" [options]="leaveId" formControlName="leaveTypeId"
                                                optionlabel="label" placeholder="Enter Leave Type ID">
                                               
                                            </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Leave Reason Type<span style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" [options]="leaveReasonArray" formControlName="emplLeaveReasonTypeId"
                                                optionlabel="label" placeholder="Enter Leave Reason Type">
                                            </p-dropdown>   

                                            </div>
                                           
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">From Date <span style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="From Date">
                                                <small *ngIf="add_leave_form.controls['fromDate'].invalid && (add_leave_form.controls['fromDate'].dirty || add_leave_form.controls['fromDate'].touched)">
                                                    <p class="validate-field" *ngIf="add_leave_form.controls['fromDate'].errors.required">
                                                        From Date   is required!</p></small>
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Through Date <span style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"  formControlName="thruDate"  class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Through Date">


                                        <small *ngIf="add_leave_form.controls['thruDate'].invalid && (add_leave_form.controls['thruDate'].dirty || add_leave_form.controls['thruDate'].touched)">
                                            <p class="validate-field" *ngIf="add_leave_form.controls['thruDate'].errors.required">
                                                Through Date   is required!</p></small> 

                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Approver Party <span style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" [options]="partyIdArray"
                                                optionlabel="label" placeholder="Enter Approver Party" formControlName="approverPartyId">
                                               
                                            </p-dropdown>
                                        
                                    <small *ngIf="add_leave_form.controls['approverPartyId'].invalid && (add_leave_form.controls['approverPartyId'].dirty || add_leave_form.controls['approverPartyId'].touched)">
                                        <p class="validate-field" *ngIf="add_leave_form.controls['approverPartyId'].errors.required">
                                            Approver Party  is required!</p></small>
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Description</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter description"> 

                                            </div>
                                          
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput" *ngIf="leave">
                                                <label for="exampleInputEmail1">Leave</label>
                                            </div>
                                            <div class="col-lg-2" *ngIf="leave">
                                                <p-dropdown filter="true" [options]="leaveStatusReoortArray" formControlName="leaveStatus"
                                                    optionlabel="label" placeholder="Enter Leave">
                                                </p-dropdown>
                                            </div>
                                          
                                          
                                        </div>
                                    </div>

                                 
                                </div>
                                <div class="main-submit-button" style="margin-right: 29%;">
                                    <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>
                                    <span *ngIf="show">
                                    <button type="submit" *ngIf="!leave" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                    <button type="submit" *ngIf="leave" (click)="onUpdateApproval()" class="btn btn-secondary submit-btn">Update</button>
                                </span>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 