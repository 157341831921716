import { Component, OnInit, OnDestroy,ViewChild } from '@angular/core';
import{HeaderService} from '../../header/header.service';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { SortEvent } from 'primeng/api';
import { PaymentGroupService } from 'src/app/accounting/accounting-ap/payable-payment-group/payment-group.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreatePaymentServices } from 'src/app/accounting/payments/create-payments/create-payment.service';
import { Location } from '@angular/common';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { CustomerAgreementsService } from 'src/app/accounting/accounting-ar/customer-agreements/customer-agreements.service';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';

@Component({
  selector: 'app-main-vendor',
  templateUrl: './main-vendor.component.html',
  styleUrls: ['./main-vendor.component.css']
})
export class MainVendorComponent implements OnInit {

  @ViewChild('closebutton1') closebutton1;
  total=0;
  @ViewChild('closebutton') closebutton;
  rowNumber=0;
  activeCategoriess=5;
  isShown= false; 
  pageSize = 10;
  rows = 50;
  activeCategory=3;
  pageNo = 1;
  opportunityIdArray: any = [
      {
          label: 'Equals',
          value: 'Equals'
      },
      {
          label: 'Contains',
          value: 'Contains'
      },
      {
          label: 'Is Empty',
          value: 'Is Empty'
      },
      {
          label: 'Begins With',
          value: 'Begins With'
      },
  ];
  searchPaymentGroupForm: FormGroup;
  paymentGroups: any[];
  faEdit = faEdit;
  faTrash = faTrash;
  agreementType: string;
  paymentGroupTypes: any[];
  

  paymentType: string;
  paymentGroupForm: FormGroup;
  paymentGroupId: string;
  editMode: boolean;
  paymentGroup: any;
  constructor(
      readonly  _Router: Router,
      readonly _FormBuilder: FormBuilder,
      readonly spinner: NgxSpinnerService,
      readonly _PaymentGroupService: PaymentGroupService,
      readonly _ToastrService: ToastrService,
      readonly accountsService:AccountsService,
      private _location: Location,
  ) {
      this.paymentType = '';
      this.paymentGroupId = '';
      this.paymentGroupTypes = [];
      this.paymentGroupForm = this._FormBuilder.group({
        paymentGroupName: ['', [Validators.required]],
        paymentGroupTypeId: ['CHECK_RUN']
      });
      this.editMode = false;
      this.searchPaymentGroupForm = this._FormBuilder.group({
          paymentGroupId: [''],
          paymentGroupIdSearchType: ['Contains'],
          paymentGroupName: [''],
          paymentGroupNameSearchType: ['Contains']
      });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
      this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
      this.getPaymentGroups();
      this.getPaymentTypes();
  }

backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/financial/main']);
}
  getPaymentTypes(): void {
      this.spinner.show();
      this._PaymentGroupService.getPaymentGroupTypes()
        .then(data => {
          this.spinner.hide();
          this.paymentGroupTypes = data.data.map(value => {
            return {
              label: value.description,
              value: value.paymentGroupTypeId
            };
          });
        });
       
    }
 
    submit(): void {
      this.spinner.show();
      if (this.editMode) {
     
      } else {
        if (this.paymentGroupForm.valid) {
          this._PaymentGroupService.createPaymentGroup(this.paymentGroupForm.value)
            .then(data => {
              if (data.success) {
                this._ToastrService.success('Created');
                this.paymentGroupForm.reset();
                this.spinner.hide();
                this.closebutton.nativeElement.click();
                this.getPaymentGroups();
              }
            });
        }
      }
      this.spinner.hide();
    }
  main(){
      this._Router.navigate(["/financial/main-payable"])
    }
  invoiceType(){
      this.isShown = !this.isShown;
    }

    reset(){
      this.searchPaymentGroupForm.controls['paymentGroupId'].reset();
      this.searchPaymentGroupForm.controls['paymentGroupName'].reset();
     
      // this.getPaymentGroups();

    }

  getPaymentGroups(): void {
      this.spinner.show();
      this._PaymentGroupService.getPaymentGroup('CHECK_RUN', this.searchPaymentGroupForm.value)
          .then(data => {
              if (data.success) {
                  this.spinner.hide();
                  this.paymentGroups = data.data;
              }
          });
        
  }
  mainPage()
  {
    this._Router.navigate(['/financial/main-accounting']);
  }
  accountingPage()
  {
    this._Router.navigate(['/financial/Accounting']);
  }
  invoicePage()
  {
    this._Router.navigate(['/financial/Invoices']);
  }
  paymentPage()
  {
    this._Router.navigate(['/financial/Payments']);
  }
  paymentGrpPage()
  {
    this._Router.navigate(['/financial/Payment-group']);
  }
  billingAccountPage()
  {
    this._Router.navigate(['/financial/Billing-Account']);
  }
  agreementPage()
  {
    this._Router.navigate(['/financial/Agreements']);
  }
  commisionReportPage()
  {
    this._Router.navigate(['/financial/Commission-Report']);
  }
  mainAgreementPage()
  {
    this._Router.navigate(['/financial/main-agreements']);
  } 
  mainBillingAccountPage()
  {
    this._Router.navigate(['/financial/main-Billing-Accounts']);
  }
  mainFixedAssetPage()
  {
    this._Router.navigate(['/financial/main-Fixed-Assets']);
  } 
  
  mainInvoicePage()
  {
    this._Router.navigate(['/financial/main-Invoices']);
  }
   mainPaymentPage()
  {
    this._Router.navigate(['/financial/main-Payment']);
  }

  changeActiveCategoryFunctions(tab)
  {
    this.activeCategoriess=tab;
  }
  enterBills(){
      this._Router.navigate(["/financial/payable/invoices"])
    }
    payableMakePayment(){
      this._Router.navigate(["/financial/payable/payment-payables"])
    }
    payableMakeGroupPayment(){
      this._Router.navigate(["/financial/payable/payment-group"])
    }
    salesInvoice(){
      this._Router.navigate(["/financial/receiveable/invoices"])
    }
    receivePayment(){
      this._Router.navigate(["/financial/receiveable/payments"])
    }
    receiveGroupPayments(){
      this._Router.navigate(["/financial/receiveable/payment-group"])
    }
  cancelCheckRun(paymentGroupId: string): void {
      this.spinner.show();
      this._PaymentGroupService.cancelCheckRun(paymentGroupId)
          .then(data => {
              if (data.success) {
                  this._ToastrService.success('Canceled');
                  this.spinner.hide();
                  this.getPaymentGroups();
              }
          });
        
  }
  deleteProdCatalogToPartyNew(paymentGroupId: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteProdCatalogToPartyNew(paymentGroupId).subscribe((res: any) => {
          this.getPaymentGroups();
        }, (err) => {
          this._ToastrService.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
 
  customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null) {
              result = -1;
          }
          else if (value1 != null && value2 == null) {
              result = 1;
          }
          else if (value1 == null && value2 == null) {
              result = 0;
          }
          else if (typeof value1 === 'string' && typeof value2 === 'string') {
              result = value1.localeCompare(value2);
          }
          else {
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
      });
  }
  ngOnDestroy(): void {
      this.spinner.hide();
        }
}