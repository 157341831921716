import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.css']
})
export class NewCustomerComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;

  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;

  customerCommercialDetailsForm:FormGroup
  customerType:string;
  customerCommercialId:string;
  FmsCustomerCommercial:string; 
  customerName:string; 
  displayCustomerAs:string;
  tax:string;    
  taxCode:string;   
  jobName:string;   
  servicePackage:string;
  address1:string; 
  address2:string;  
  city:string;  
  stateProvinceGeoId:string; 
  postalCode:string;  
  workZone:string; 
  jobTax:string;     
  jobTaxCode:string;   
  primaryContactFirstName:string;
  primaryContactLastName:string;  
  primaryContactDisplayAs:string;  
  primaryContactPrimaryPhone:string;  
  primaryContactAlternatePrimaryPhone:string;
  primaryContactFax:string;    
  primaryContactEmail:string; 
  billingAddressInvoiceAblePO:string;  
  billingAddressSame:string;  
  additionalInformationTest:string; 
  additionalInformationNotes:string; 
  additionalInformationSource :string;   
  additionalInformationMake:string;  
  additionalInformationReference:string;  
  additionalInformationColour:string; 

customerResedentialDetailsForm:FormGroup;
fmsCustomerResidentialId:string; 
firstName:string;   
lastName:string;        
primaryPhone:string;        
alternatePrimaryPhone:string;     
fax:string;
email:string;

  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 
  
  {
    this.customerCommercialDetailsForm= this._FormBuilder.group({
      customerType:"",
      customerCommercialId:"",
      FmsCustomerCommercial:"",  
      customerName:"",
      displayCustomerAs:"", 
      tax :"",      
      taxCode:"",   
      jobName:"",   
      servicePackage:"",
      address1:"",   
      address2 :"", 
      city:"",   
      stateProvinceGeoId:"",  
      postalCode:"",  
      workZone:"", 
      jobTax:"",     
      jobTaxCode:"",    
      primaryContactFirstName:"",  
      primaryContactLastName :"",  
      primaryContactDisplayAs:"",   
      primaryContactPrimaryPhone:"",   
      primaryContactAlternatePrimaryPhone:"", 
      primaryContactFax:"",    
      primaryContactEmail:"",   
      billingAddressInvoiceAblePO:"",
      billingAddressSame :"",    
      additionalInformationTest :"", 
      additionalInformationNotes:"",  
      additionalInformationSource :"",   
      additionalInformationMake :"",   
      additionalInformationReference :"",  
      additionalInformationColour:"",
    });

    this.customerResedentialDetailsForm= this._FormBuilder.group({
      fmsCustomerResidentialId:"",    
      customerType :"",  
      firstName:"",  
      lastName:"",       
      displayCustomerAs:"",
      tax:"",     
      taxCode:"",        
      primaryPhone:"",          
      alternatePrimaryPhone:"",    
      fax:"",       
      email:"", 
      jobName:"",      
      servicePackage:"",   
      address1:"",
      address2:"",
      city:"",              
      stateProvinceGeoId:"",   
      postalCode:"",         
      workZone:"",
      jobTax:"", 
      jobTaxCode:"",
      billingAddressInvoiceAblePO:"",
      billingAddressSame :"",    
      additionalInformationTest :"", 
      additionalInformationNotes:"",  
      additionalInformationSource :"",   
      additionalInformationMake :"",   
      additionalInformationReference :"",  
      additionalInformationColour:"",
    });


   
  }
  ngOnInit(): void {
    
    
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  

  ngOnDestroy(): void {
    this.spinner.hide();
  }



}
