import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-create-new-suppliers',
  templateUrl: './create-new-suppliers.component.html',
  styleUrls: ['./create-new-suppliers.component.css']
})
export class CreateNewSuppliersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.right-arrow').click(function(){
      $(this).toggleClass('rotate-up');
    });
  }

}
