<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">The Fin Account of {{this.finAccountId}}</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
           
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">
                        <!-- <button type="button" class="btn btn-outline-secondary">Create</button> -->
                        <!-- <button type="button" class="btn btn-outline-secondary">Create from vCard</button>
                          <button type="button" class="btn btn-outline-secondary">Find Contacts</button> -->

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                Financial Account Information</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                Role(s)</a></li>

                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                Transactions</a></li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                Deposit </a></li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategoryFunction(8)"><a>
                Withdraw </a></li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                Deposit Slips </a></li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategoryFunction(7)"><a>
                Reconciliation </a></li>


                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div *ngIf="activeCategory==2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Financial Account Information
                                                        </h4>
                                                        <span class="edit-buttons ">
                            <a  data-toggle="modal" (click)="getFinancailAccountEdit()"
                            data-target="#exampleModalCenter">Update</a>
                          </span>

                                                    </div>
                                                    <div class="panel-body" *ngIf="financialList">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Financial Account ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.finAccountId}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Financial Account Type</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.finAccountTypeId ? financialList.finAccountTypeId :'N/A'}}</span>

                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Status</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.statusDescription ? financialList.statusDescription :'N/A'}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Financial Account Name</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.finAccountName ? financialList.finAccountName:'N/A'}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Financial Account Code</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.finAccountCode ? financialList.finAccountCode :'N/A'}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Financial Account Pin</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.finAccountPin ? financialList.finAccountPin:'N/A'}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Currency</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.currencyUomId}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Organization Party ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.organizationPartyId}}</span>

                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Owner Party ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.ownerPartyId ? financialList.ownerPartyId:'N/A'}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Post To Gl Account ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.postToGlAccountId ? financialList.postToGlAccountId:'N/A'}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">From Date</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.fromDate |
                                  date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Through Date</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.thruDate ? (financialList.thruDate|date:'yyyy-MM-d hh:mm:ss') :'N/A'}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Is Refundable</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.isRefundable}}</span>
                                                                    </div>

                                                                    <div class="article">

                                                                        <p class="col-lg-6 col-12">Replenish Payment ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.replenishPaymentId ? financialList.replenishPaymentId:'N/A'}}</span>

                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Replenish Level</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.replenishLevel}}</span>
                                                                    </div>

                                                                    <div class="article">

                                                                        <p class="col-lg-6 col-12">Actual Balance	 
                                                                            </p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.actualBalance ? financialList.actualBalance:'N/A'}}</span>

                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Available Balance </p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{financialList.availableBalance}}</span>
                                                                    </div>



                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Financial Account Roles
                                                        </h4>
                                                        <span class="edit-buttons ">
                            <a data-toggle="modal" data-target="#exampleModalRole">Create</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="financialRoleList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Role Type ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Customer ID
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Created At
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Through Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span class="account-button">{{activity.roleTypeId}}</span>
                                                                                        
                                                                                    </div>
                                                                                </td>
                                                                                <td> {{activity.partyId}} </td>
                                                                                <td> {{activity.createdStamp | date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td> {{activity.fromDate | date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td> {{activity.thruDate | date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td>
                                                                                    <svg class="hover" (click)="onUpdateRole(activity.roleTypeId,activity.partyId,activity.fromDate,activity.thruDate)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                          <g transform="translate(0 -0.004)">
                                            <g transform="translate(0 1.52)">
                                              <path
                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                transform="translate(0 -34.137)" />
                                            </g>
                                            <g transform="translate(4.548 0.004)">
                                              <g transform="translate(0 0)">
                                                <path
                                                  d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                  transform="translate(-102.409 -0.004)" />
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteFinancialRoleAccount(activity.fromDate,activity.partyId,activity.roleTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                          <defs>
                                            <style>
                                              .a {
                                                fill: #f44336;
                                              }

                                              .b {
                                                fill: #fafafa;
                                                margin-left: 20px;
                                              }
                                            </style>
                                          </defs>
                                          <path class="a fill-color"
                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                          <path class="b"
                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                            transform="translate(-147.576 -147.576)" />
                                        </svg>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page
                                                                        <ng-container *ngIf="openNoRecordFoundRelatedAccount">
                                                                            <span style="margin-left: 400px;">No Record Found!....</span>
                                                                        </ng-container>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==4">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Financial Account Transaction
                                                        </h4>
                                                        <span class="edit-buttons ">
                            <a data-toggle="modal" data-target="#exampleModalTransaction">Create</a>
                            <a (click)="bankCon()">| Bank Reconciliation
                            </a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="card mb-5">

                                                                <div class="card-body">
                                                                    <form>
                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="contentId">Fin Account Trans Type ID</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearchTransaction.finAccountTransTypeId" [ngModelOptions]="{standalone:true}" name="finAccountTransTypeId" [options]="finTypeArray" optionlabel="label" placeholder="Fin Account Trans Type ID">
                                                                                        </p-dropdown>


                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="prodCatContentType">Status</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearchTransaction.statusId" [ngModelOptions]="{standalone:true}" name="statusId" [options]="stausIdArray" optionlabel="label" placeholder="Status">
                                                                                        </p-dropdown>
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="fromDate">From Transaction Date</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <input type="date" [(ngModel)]="advanceSearchTransaction.fromTransactionDate" [ngModelOptions]="{standalone:true}" name="fromTransactionDate" style="width: 100%;" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Transaction Date">


                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="throughDate">From Entry Date</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="date" style="width: 100%;" [(ngModel)]="advanceSearchTransaction.fromEntryDate" [ngModelOptions]="{standalone:true}" name="fromEntryDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Transaction Date">
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="prodCatContentType">Gl Reconciliation ID</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearchTransaction.glReconciliationId" [ngModelOptions]="{standalone:true}" name="glReconciliationId" [options]="giTransArray" optionlabel="label" placeholder="Gl Reconciliation ID">
                                                                                        </p-dropdown>

                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="throughDate">Thru Transaction Date</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="date" [(ngModel)]="advanceSearchTransaction.thruTransactionDate" [ngModelOptions]="{standalone:true}" name="thruTransactionDate" style="width: 100%;" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Transaction Date">
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="throughDate">Thru Entry Date</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <input type="date" [(ngModel)]="advanceSearchTransaction.thruEntryDate" [ngModelOptions]="{standalone:true}" name="thruEntryDate" style="width: 100%;" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Transaction Date">

                                                                                    </div>


                                                                                </div>
                                                                            </div>

                                                                            <div class="col-md-12" style="margin-left: -21%;">
                                                                                <div class="main-submit-button">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="FindFinAccountTrans()">Find
                                        </button>

                                                                                    <button type="button" (click)="resetTransaction();" class="btn btn-danger ml-2">Reset</button>

                                                                                    <!-- <button class="account-button submit mr-2"
                                                            (click)="onSubmit()">{{editMode?'Update':'Submit'}}</button> -->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="finAccountTransData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Fin Account Trans ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Fin Account Trans Type ID
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <!--   <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">Created At<p-sortIcon
                                          field="name"></p-sortIcon>
                                        <div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </div>
                                      </th> -->
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Customer ID
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Gl Reconciliation Name
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Transaction Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Entry Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Amount
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Payment ID
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Payment Type
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Payment Method Type
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Status
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Comments
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Cancel Transaction Status
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span class="account-button"> {{product.finAccountTransId}}</span>
                                                                                       
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.FindFinAccountTrans.finAccountTransTypeId}} </td>
                                                                                <!--   <td [ngStyle]="{'width':'170px'}">
                                        {{product.createdStamp|date:'yyyy-MM-dd hh:mm:ss'}} </td> -->
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.FindFinAccountTrans.partyId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.FindFinAccountTrans.glReconciliationId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.FindFinAccountTrans.transactionDate|date:'yyyy-MM-dd'}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.FindFinAccountTrans.entryDate|date:'yyyy-MM-dd'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.FindFinAccountTrans.amount}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.FindFinAccountTrans.paymentId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.paymentDetails[0].paymentTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.paymentDetails[0].paymentMethodTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.FindFinAccountTrans.statusId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.FindFinAccountTrans.comments}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}" (click)="cancelFinancialAccountTransaction(product.FindFinAccountTrans.finAccountTransId)">
                                                                                    <a style="cursor: pointer;color: #0d3769 ;">Cancel</a>
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page
                                                                        <ng-container *ngIf="openNoRecordFoundRelatedAccount">
                                                                            <span style="margin-left: 400px;">No Record Found!....</span>
                                                                        </ng-container>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <form>
                                                                <div class="row">
                                                                    <div class="col-lg-6 col-12" *ngIf="this.grandTotal">
                                                                        <div class="form-group" *ngIf="this.searchedNumberOfRecords">
                                                                            <label for="contentId">Grand Total / Number of Transaction</label>
                                                                            <div>
                                                                                <label>{{ this.grandTotal}}/{{ this.searchedNumberOfRecords}}</label>

                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6" *ngIf="this.createdGrandTotal">
                                                                        <div class="form-group" *ngIf="this.totalCreatedTransactions">
                                                                            <label for="prodCatContentType"> Created Grand Total / Number of Transaction</label>
                                                                            <div>
                                                                                <label>{{ this.createdGrandTotal}}/{{ this.totalCreatedTransactions}}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6" *ngIf="this.approvedGrandTotal">
                                                                        <div class="form-group" *ngIf="this.totalApprovedTransactions">
                                                                            <div class="d-flex flex-column">
                                                                                <label for="fromDate">Approved Grand Total / Number of Transaction</label>
                                                                                <label>{{ this.approvedGrandTotal}}/{{ this.totalApprovedTransactions}}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6" *ngIf="this.createdApprovedGrandTotal">
                                                                        <div class="form-group" *ngIf="this.totalCreatedApprovedTransactions">
                                                                            <div class="d-flex flex-column">

                                                                                <label for="throughDate">Created/Approved Grand Total / Number of
                                        Transaction</label>
                                                                                <label>{{ this.createdApprovedGrandTotal}}/{{ this.totalCreatedApprovedTransactions}}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==5">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Financial Account Deposit
                                                        </h4>
                                                        <span class="edit-buttons ">
                            <a data-toggle="modal" data-target="#exampleModalDeposit"> Create New Deposit Payment
                            </a>
                            <!--  <a (click)="financialNewWithdrawPayment()">| Create New Withdrawal Payment

                            </a> -->
                          </span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="card mb-5">

                                                                <div class="card-body">
                                                                    <form>
                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                        <label for="contentId">Payment Method Type</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearchAccountDeposit.cardType" [ngModelOptions]="{standalone:true}" name="paymentMethodTypeArray" [options]="paymentMethodTypeArray" optionlabel="label" placeholder="Payment Method Type">
                                                                                        </p-dropdown>


                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="prodCatContentType">Card Type</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearchAccountDeposit.paymentMethodTypeId" [ngModelOptions]="{standalone:true}" name="cardTypeArray" [options]="cardTypeArray" optionlabel="label" placeholder="Card Type">
                                                                                        </p-dropdown>
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                        <label for="prodCatContentType">Customer ID From</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearchAccountDeposit.partyIdFrom" [ngModelOptions]="{standalone:true}" name="partyIdArray" [options]="partyIdArray" optionlabel="label" placeholder="Customer ID From">
                                                                                        </p-dropdown>


                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="fromDate">From Date</label>

                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="date" style="width: 100%;" [(ngModel)]="advanceSearchAccountDeposit.fromDate" [ngModelOptions]="{standalone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Transaction Date">
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                        <label for="throughDate">Through Date</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <input type="date" style="width: 100%;" [(ngModel)]="advanceSearchAccountDeposit.thruDate" [ngModelOptions]="{standalone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Transaction Date">


                                                                                    </div>


                                                                                </div>
                                                                            </div>



                                                                            <div class="col-md-12" style=" margin-left: -23%;">
                                                                                <div class="main-submit-button">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="findDepositOrWithdrawNew()">Find
                                        </button>

                                                                                    <button type="button" class="btn btn-danger ml-2" (click)="resetDiposit()">Reset</button>

                                                                                    <!-- <button class="account-button submit mr-2"
                                                            (click)="onSubmit()">{{editMode?'Update':'Submit'}}</button> -->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <form>
                                                                <div class="row">
                                                                    <div class="col-lg-6 col-12">
                                                                        <div class="form-group">
                                                                            <label for="contentId">Running Total : <span *ngIf="this.showAmount">{{this.product_Amount}}</span></label>

                                                                            <!-- <small
                                                        *ngIf="!!categoryContentForm.controls.contentId.invalid && !!categoryContentForm.controls.contentId.touched">Required</small> -->
                                                                        </div>
                                                                    </div>
                                                                    <br>
                                                                    <div class="col-md-6">
                                                                        <div class="form-group">
                                                                            <label for="prodCatContentType">Payment Party Group Name</label>
                                                                            <div>
                                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="paymentPartyGroup" [(ngModel)]="this.paymentPartyGroup" placeholder="Enter Payment Party Group Name">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="form-group">
                                                                            <label for="prodCatContentType">Group in one Transaction</label>
                                                                            <div>
                                                                                <div class="main-submit-button">
                                                                                    <p-checkbox (click)=groupInOne($event);></p-checkbox>
                                                                                    <button [disabled]="!payIdArray || payIdArray.length===0?true:false" (click)="batchDepositWithdrawPaymentsSubmit()" type="submit" class="btn btn-secondary submit-btn">Deposit
                                        </button>




                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </form>
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="getDepositData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Payment ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Payment Type
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> From Customer ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> To Customer ID
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Amount
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Select All
                                                                                        <p-checkbox (click)="selectAllDeposit($event)"></p-checkbox>
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span class="account-button"> {{product.payment.paymentId}}</span>
                                                                                       
                                                                                    </div>
                                                                                </td>
                                                                                <td> {{product.payment.paymentTypeId}} </td>
                                                                                <td> {{product.payment.partyIdFrom}} </td>
                                                                                <td> {{product.payment.partyIdTo}} </td>
                                                                                <td> {{product.payment.amount}} </td>
                                                                                <td> {{product.payment.effectiveDate|date:'yyyy-MM-dd'}} </td>

                                                                                <td (click)="depositCheckboxClick(product.payment.amount);">
                                                                                    <p-checkbox></p-checkbox>Deposit
                                                                                </td>



                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page
                                                                        <ng-container *ngIf="openNoRecordFoundRelatedAccount">
                                                                            <span style="margin-left: 400px;">No Record Found!....</span>
                                                                        </ng-container>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==8">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Financial Account Withdraw
                                                        </h4>
                                                        <span class="edit-buttons ">

                            <a data-toggle="modal" data-target="#exampleModalWithdraw">Create New Withdrawal Payment

                            </a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="card mb-5">

                                                                <div class="card-body">
                                                                    <form>
                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                        <label for="contentId">Payment Method Type</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearchAccountDeposit.cardType" [ngModelOptions]="{standalone:true}" name="paymentMethodTypeArray" [options]="paymentMethodTypeArray" optionlabel="label" placeholder="Payment Method Type">
                                                                                        </p-dropdown>


                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="prodCatContentType">Card Type</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearchAccountDeposit.paymentMethodTypeId" [ngModelOptions]="{standalone:true}" name="cardTypeArray" [options]="cardTypeArray" optionlabel="label" placeholder="Card Type">
                                                                                        </p-dropdown>
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                        <label for="prodCatContentType">Customer ID From</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearchAccountDeposit.partyIdFrom" [ngModelOptions]="{standalone:true}" name="partyIdArray" [options]="partyIdArray" optionlabel="label" placeholder="Customer ID From">
                                                                                        </p-dropdown>


                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="fromDate">From Date</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="date" style="width: 100%;" [(ngModel)]="advanceSearchAccountDeposit.fromDate" [ngModelOptions]="{standalone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Transaction Date">
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                        <label for="throughDate">Through Date</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <input type="date" style="width: 100%;" [(ngModel)]="advanceSearchAccountDeposit.thruDate" [ngModelOptions]="{standalone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Transaction Date">


                                                                                    </div>

                                                                                </div>
                                                                            </div>


                                                                            <div class="col-md-12" style=" margin-left: -23%;">
                                                                                <div class="main-submit-button">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="findDepositOrWithdrawNew()">Find
                                        </button>

                                                                                    <button type="button" class="btn btn-danger ml-2" (click)="resetWithdrawl()">Reset</button>

                                                                                    <!-- <button class="account-button submit mr-2"
                                                            (click)="onSubmit()">{{editMode?'Update':'Submit'}}</button> -->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <form>
                                                                <div class="row">
                                                                    <div class="col-lg-6 col-12">
                                                                        <div class="form-group">
                                                                            <label for="contentId">Running Total : <span *ngIf="this.showAmount">{{this.product_Amount_with}}</span></label>

                                                                            <!-- <small
                                                        *ngIf="!!categoryContentForm.controls.contentId.invalid && !!categoryContentForm.controls.contentId.touched">Required</small> -->
                                                                        </div>
                                                                    </div>
                                                                    <br>
                                                                    <div class="col-md-6">
                                                                        <div class="form-group">
                                                                            <label for="prodCatContentType">Payment Party Group Name</label>
                                                                            <div>
                                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="paymentPartyGroupWith" [(ngModel)]="this.paymentPartyGroupWith" placeholder="Enter Payment Party Group Name">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="form-group">
                                                                            <label for="prodCatContentType">Group in one Transaction</label>
                                                                            <div>
                                                                                <div class="main-submit-button">
                                                                                    <p-checkbox (click)=groupInOneWith($event);></p-checkbox>
                                                                                    <button (click)="batchDepositWithdrawPaymentsSubmitWith()" type="submit" [disabled]="!payIdArrayWith || payIdArrayWith.length===0?true:false" class="btn btn-secondary submit-btn">Withdraw
                                        </button>




                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </form>
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="getDepositData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Payment ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Payment Type
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> From Customer ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> To Customer ID
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Amount
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Select All
                                                                                        <p-checkbox (click)="selectAllDepositWith($event)"></p-checkbox>
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <!-- <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.paymentId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td> {{product.paymentTypeDesc}} </td>
                                                                                <td> {{product.partyIdFrom}} </td>
                                                                                <td> {{product.partyToGroupName}} </td>
                                                                                <td> {{product.amount}} </td>
                                                                                <td> {{product.effectiveDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>

                                                                                <td>
                                                                                    <p-checkbox (click)="depositCheckboxClickWith(product.paymentId);"></p-checkbox>
                                                                                    Withdraw
                                                                                </td> -->
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span class="account-button">{{product.payment.paymentId}}</span>
                                                                                        
                                                                                    </div>
                                                                                </td>
                                                                                <td> {{product.payment.paymentTypeId}} </td>
                                                                                <td> {{product.payment.partyIdFrom}} </td>
                                                                                <td> {{product.payment.partyIdTo}} </td>
                                                                                <td> {{product.payment.amount}} </td>
                                                                                <td> {{product.payment.effectiveDate|date:'yyyy-MM-dd'}} </td>

                                                                                <td (click)="depositCheckboxClickWith(product.payment.amount);">
                                                                                    <p-checkbox></p-checkbox> Withdraw
                                                                                </td>



                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page
                                                                        <ng-container *ngIf="openNoRecordFoundRelatedAccount">
                                                                            <span style="margin-left: 400px;">No Record Found!....</span>
                                                                        </ng-container>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==6">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Financial Account Deposit Slips
                                                        </h4>
                                                        <span class="edit-buttons ">
                            <a data-toggle="modal" data-target="#exampleModalSlip"> Create New Deposit Slips
                            </a>
                          </span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="card mb-5 w-100">

                                                                <div class="card-body">
                                                                    <form>
                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInputC">
                                                                                        <label for="contentId">Payment Group ID</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" name="paymentGroup" [(ngModel)]="advanceSearchDepostSlip.paymentGroupIdSearchType" [ngModelOptions]="{standalone:true}"></p-dropdown>


                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="paymentGroupId" aria-describedby="emailHelp" style="width: 55%;
                                                                 " placeholder="Enter Payment Group ID" [(ngModel)]="advanceSearchDepostSlip.paymentGroupId" [ngModelOptions]="{standalone:true}">


                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="prodCatContentType">Payment Group Name</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" [options]="opportunityIdArray" name="paymentGroupName" [(ngModel)]="advanceSearchDepostSlip.paymentGroupNameSearchType" [ngModelOptions]="{standalone:true}" optionlabel="label"></p-dropdown>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" style="width: 55%;
                                            " aria-describedby="emailHelp" name="paymentGroupName" [(ngModel)]="advanceSearchDepostSlip.paymentGroupName" [ngModelOptions]="{standalone:true}" placeholder="Enter Payment Group Name">


                                                                                    </div>


                                                                                </div>
                                                                            </div>




                                                                            <div class="col-md-12" style="    margin-right: 27%;">
                                                                                <div class="main-submit-button" style="    margin-right: 28%; ">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="findPaymentGroupById()">Find
                                        </button>

                                                                                    <button type="button" (click)=resetSlip(); class="btn btn-danger ml-2">Reset</button>

                                                                                    <!-- <button class="account-button submit mr-2"
                                                            (click)="onSubmit()">{{editMode?'Update':'Submit'}}</button> -->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>

                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="depositSlipsData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>



                                                                                        Payment Group ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Payment Group Type
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Payment Group Name
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td (click)="redirectPage(product.paymentGroupId)">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span class="account-button">{{product.paymentGroupId}}</span>
                                                                                        
                                                                                    </div>
                                                                                </td>
                                                                                <td> {{product.paymentGroupTypeId}}</td>
                                                                                <td> {{product.paymentGroupName}}</td>

                                                                                <td>
                                                                                    <div class="main-submit-button">
                                                                                        <!-- <a *ngIf="product.showCancelButton=='Y'">
                                                                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="downloadPDF(product.paymentGroupId)">PDF
                                            </button>
                                                                                        </a>
                                                                                        <a *ngIf="product.showPdfButton=='Y'"> <button type="submit" (click)="cancelBatchPayment(product.paymentGroupId)" class="btn btn-secondary submit-btn">Cancel
                                            </button></a> -->
                                                                                    <a>
                                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="downloadPDF(product.paymentGroupId)">PDF
                                                                                        </button>
                                                                                    </a>
                                                                                    <a> <button type="submit" (click)="cancelBatchPayment(product.paymentGroupId)" class="btn btn-secondary submit-btn">Cancel
                                                                                    </button></a>


                                                                                    </div>
                                                                                </td>




                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page
                                                                        <ng-container *ngIf="openNoRecordFoundRelatedAccount">
                                                                            <span style="margin-left: 400px;">No Record Found!....</span>
                                                                        </ng-container>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==7">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Financial Account Reconciliations
                                                        </h4>
                                                        <span class="edit-buttons ">
                            <a data-toggle="modal" data-target="#exampleModalReconciliation">Create New Financial Account Reconciliations
                            </a>
                          </span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="card mb-5">

                                                                <div class="card-body">
                                                                    <form>
                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInputC">
                                                                                        <label for="contentId">Gl Reconciliation ID
                                            </label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <!-- <p-dropdown filter="true" placeholder="Gl Reconciliation ID	" [options]="FinancialAccountReconciliationsArray" optionlabel="label" [(ngModel)]="advanceSearch.glReconciliationId" [ngModelOptions]="{standalone:true}" name="glReconciliationId"></p-dropdown> -->
                                                                                        <input type="text" style="width: 100%;" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Gl Reconciliation Id" [(ngModel)]="advanceSearch.glReconciliationId" [ngModelOptions]="{standalone:true}" name="glReconciliationId">


                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="prodCatContentType">Gl Reconciliation Name</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" style="width: 100%;" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Gl Reconciliation Name" [(ngModel)]="advanceSearch.glReconciliationName" [ngModelOptions]="{standalone:true}" name="glReconciliationName">
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInputC">
                                                                                        <label for="prodCatContentType">Status</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" [options]="FinancialAccountIntialDataArray" optionlabel="label" placeholder="Status" [(ngModel)]="advanceSearch.statusId" [ngModelOptions]="{standalone:true}" name="statusId"></p-dropdown>


                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="prodCatContentType">Organization Party ID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown placeholder="Organization Party ID" [options]="partyIdArray" [(ngModel)]="advanceSearch.organizationPartyId" [ngModelOptions]="{standalone:true}" name="organizationPartyId" optionlabel="label" filter="true">
                                                                                        </p-dropdown>
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-3 form-group classInputC">
                                                                                        <label for="prodCatContentType">Gl Account ID</label>
                                                                                    </div>

                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearch.glAccountId" [ngModelOptions]="{standalone:true}" name="glAccountId" [options]="giAccountArray" optionlabel="label" placeholder="Gl Account ID">
                                                                                        </p-dropdown>


                                                                                    </div>




                                                                                </div>
                                                                            </div>



                                                                            <div class="col-md-12" style="    margin-right: 27%;">
                                                                                <div class="main-submit-button" style="
                                      margin-right: 27%;
                                  ">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="findReconciliations()">Find
                                        </button>

                                                                                    <button type="button" class="btn btn-danger ml-2" (click)="reset();">Reset</button>

                                                                                    <!-- <button class="account-button submit mr-2"
                                                            (click)="onSubmit()">{{editMode?'Update':'Submit'}}</button> -->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>

                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="findReconciliationsNewData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>



                                                                                        Gl Reconciliation ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Gl Reconciliation Name
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Description
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Created Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Last Modified Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Gl Account ID
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Status
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Party
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Reconciled Balance
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Opening Balance
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Reconciled Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Cancel Bank Reconciliation
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-value>
                                                                            <tr>

                                                                                <td  class="account-button"  routerLinkActive="active" [ngStyle]="{'width':'170px'}" (click)="summaryReconciliation(value.glReconciliationId)">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span class="account-button"> {{value.glReconciliationId}}</span>
                                                                                       

                                                                                    </div>
                               


                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{value.glReconciliationName}}</td>

                                                                                <td [ngStyle]="{'width':'170px'}"> {{value.description}}</td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{value.createdDate |date:'yyyy-MM-dd'}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{value.lastModifiedDate |date:'yyyy-MM-dd'}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{value.glAccountId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{value.statusId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{value.organizationPartyId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{value.reconciledBalance}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{value.openingBalance}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{value.reconciledDate |date:'yyyy-MM-dd'}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}" (click)="cancelReconciliation(value.glReconciliationId);"><a *ngIf="value.showCancel=='Y'" style="cursor: pointer;">Cancel</a> </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg class="hover" (click)="onUpdateRecon(value.glReconciliationId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                          <g transform="translate(0 -0.004)">
                                            <g transform="translate(0 1.52)">
                                              <path
                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                transform="translate(0 -34.137)" />
                                            </g>
                                            <g transform="translate(4.548 0.004)">
                                              <g transform="translate(0 0)">
                                                <path
                                                  d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                  transform="translate(-102.409 -0.004)" />
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page
                                                                        <ng-container *ngIf="openNoRecordFoundRelatedAccount">
                                                                            <span style="margin-left: 400px;">No Record Found!....</span>
                                                                        </ng-container>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="main-submit-button">
                  <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
              </div> -->
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update New Financial Account</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                            Add New Financial Account</a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                Update New Financial Account</a></li>




                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createFinancial">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Financial Account Type<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="FinAccountTypeListArray" optionlabel="label" placeholder="Enter Financial Account Type" filter="true" formControlName="finAccountTypeId">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Status <span
                                                        style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="FinAccountStatusListArray" optionlabel="label" placeholder="Enter Status" filter="true" formControlName="statusId">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Financial Account Name
                                                   
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="finAccountName" placeholder="Enter Financial Account Name">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Financial Account Code </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="finAccountCode" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Financial Account Code">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Financial Account Pin</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="finAccountPin" aria-describedby="emailHelp" placeholder="Enter Financial Account Pin">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Currency<span
                                                        style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Currency" [options]="currencyArray" optionlabel="label" filter="true" formControlName="currencyUomId">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Organization Party ID<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Organization Party ID" filter="true" formControlName="organizationPartyId" [options]="partyIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Owner Party ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Owner Party ID" [options]="partyIdArray" optionlabel="label" filter="true" formControlName="ownerPartyId">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Post To Gl Account ID </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Post To Gl Account ID" filter="true" [options]="giAccountArray" optionlabel="label" formControlName="postToGlAccountId">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">From Date <span
                                                        style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Financial Account Pin" formControlName="fromDate">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Through Date </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Financial Account Pin" formControlName="thruDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Is Refundable<span
                                                        style="color:red">*</span> </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Is Refundable" [options]="refundable" optionlabel="label" filter="true" formControlName="isRefundable">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Replenish Payment ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="replenishPaymentId" placeholder="Enter Replenish Payment ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Replenish Level</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="replenishLevel" placeholder="Enter Replenish Level">
                                                    </div>



                                                </div>
                                            </div>



                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 84%;">
                                        <button type="submit"  (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                        <!-- <button type="submit" *ngIf="!show" (click)="submit()" class="btn btn-secondary submit-btn">Submit</button> -->


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalRole" tabindex="-1" role="dialog" aria-labelledby="exampleModalRoleTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create New Financial Role</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                        Add New Financial Role</a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                            Update New Financial Role</a></li>




                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createRoles">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Customer ID<span
                                                    style="color:red">*</span> </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Customer ID" filter="true" formControlName="partyId" [options]="partyIdArray" optionlabel="label" *ngIf="!show">

                                                        </p-dropdown>
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date" formControlName="partyId" *ngIf="show" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputZ">
                                                        <label for="exampleInputEmail1">Role Type ID <span
                                                    style="color:red">*</span> </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Role Type ID" filter="true" formControlName="roleTypeId" [options]="allroleArray" optionlabel="label" *ngIf="!show">

                                                        </p-dropdown>
                                                        <input type="email" *ngIf="show" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date" formControlName="roleTypeId" readonly>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">From Date	<span
                                                    style="color:red">*</span> 
                                                   
                                                </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" *ngIf="!show" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date" formControlName="fromDate">
                                                        <input type="date" *ngIf="show" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date" formControlName="fromDate" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputZ">
                                                        <label for="exampleInputEmail1">Through Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date" formControlName="thruDate">
                                                    </div>



                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 66%;">
                                        <button type="submit" *ngIf="show" (click)="onUpdateFinancial()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalTransaction" tabindex="-1" role="dialog" aria-labelledby="exampleModalTransactionTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create New Financial Transaction</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        Add New Financial Transaction</a></li>



                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createTransaction">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Fin Account Trans Type ID
                                                    <span
                                                    style="color:red">*</span>
                                                </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="finAccountTransTypeId" placeholder="Enter Fin Account Trans Type ID" filter="true" [options]="finTypeArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Customer ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="partyId" placeholder="Enter Customer ID" filter="true" [options]="partyIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Gl Reconciliation ID
                                               
                                                </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="glReconciliationId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Gl Reconciliation ID">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Transaction Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="transactionDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Transaction Date">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Entry Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="entryDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Entry Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Amount</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="amount" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Amount">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Payment ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="paymentId" placeholder="Enter Payment ID" filter="true">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Order ID
                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="orderIdArray" optionlabel="label" formControlName="orderId" placeholder="Enter Order ID" filter="true">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Order Item Seq ID </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="orderItemSeqId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Order Item Seq ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Reason Enum ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="reasonEnumId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reason Enum ID">

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Status
                                                    <span
                                                    style="color:red">*</span>
                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="statusId" [options]="stausIdArray" optionlabel="label" placeholder="Enter Status" filter="true">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">GL Account
                                                    <span
                                                    style="color:red">*</span>
                                                </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="glAccountId" [options]="giAccountArray" optionlabel="label" placeholder="Enter GL Account" filter="true">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>




                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 74%;">
                                        <button type="submit" *ngIf="show" (click)="onUpdateTransaction()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" (click)="onSubmitTransaction()" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalDeposit" tabindex="-1" role="dialog" aria-labelledby="exampleModalDepositTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create New Deposit Payment For Financial Account</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        Create New Deposit Payment For Financial Account</a></li>



                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createRecon">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Payment Type<span
                                                    style="color:red">*</span>  </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="depositPaymentTypeArray" optionlabel="label" formControlName="paymentTypeId" placeholder="Enter Payment Type" filter="true">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <label>Received</label>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Payment Method ID<span
                                                    style="color:red">*</span> </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="paymentMethodIdArray" optionlabel="label" formControlName="paymentMethodId" placeholder="Enter Payment Method ID" filter="true">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">From  Customer ID <span
                                                    style="color:red">*</span> </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="partyIdArray" optionlabel="label" formControlName="partyIdFrom" placeholder="Enter To Customer ID" filter="true">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">To Customer ID <span
                                                    style="color:red">*</span> </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="partyIdArray" optionlabel="label" formControlName="partyIdTo" placeholder="Enter To Customer ID" filter="true">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Amount
                                                    <span
                                                    style="color:red">*</span> 
                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="amount" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Amount">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Currency Amount</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="actualCurrencyAmount" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual Currency Amount">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Effective Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="effectiveDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Effective Date">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reference No</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="paymentRefNum" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reference No">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">	Actual Currency Uom ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="currencyArray" optionlabel="label" formControlName="actualCurrencyUomId" placeholder="Enter Actual Currency Uom ID" filter="true">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Fin Account Trans ID </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input formControlName="finAccountTransId" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fin Account Trans ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Comments </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input formControlName="comments" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Override Gl Account ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="giAccountArray" optionlabel="label" formControlName="overrideGlAccountId" placeholder="Enter Override Gl Account ID" filter="true">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Deposit Payment in:{{ this.finAccountId }}</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-checkbox (click)="selectDeposit($event);"></p-checkbox>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 69%">
                                        <button type="submit" *ngIf="show" (click)="onUpdateDeposit()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" (click)="onSubmitDeposit()" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>



            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalWithdraw" tabindex="-1" role="dialog" aria-labelledby="exampleModalWithdrawTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">    Create New Withdrawal Payment For Financial Account</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        Create New Withdrawal Payment For Financial Account</a></li>



                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createWithdraw">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Payment Type<span style="color:red">*</span>
                                                </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="depositPaymentTypeArray" optionlabel="label" formControlName="paymentTypeId" placeholder="Enter Payment Type" filter="true">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <label>Sent</label>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Payment Method ID<span
                                                    style="color:red">*</span> </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="paymentMethodIdArray" optionlabel="label" formControlName="paymentMethodId" placeholder="Enter Payment Method ID" filter="true">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">From Customer ID <span
                                                    style="color:red">*</span> </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="partyIdArray" optionlabel="label" formControlName="partyIdFrom" placeholder="Enter From Customer ID" filter="true">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">To Customer ID <span
                                                    style="color:red">*</span> </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="partyIdArray" optionlabel="label" formControlName="partyIdTo" placeholder="Enter To Customer ID" filter="true">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Amount
                                                    <span style="color:red">*</span>
                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="amount" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Amount">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Currency Amount</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="actualCurrencyAmount" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual Currency Amount">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Effective Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="effectiveDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Effective Date">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reference No</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="paymentRefNum" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reference No">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1"> Actual Currency Uom ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="currencyArray" optionlabel="label" formControlName="actualCurrencyUomId" placeholder="Enter Actual Currency Uom ID" filter="true">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Fin Account Trans ID </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input formControlName="finAccountTransId" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fin Account Trans ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Comments </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input formControlName="comments" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Override Gl Account ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="giAccountArray" optionlabel="label" formControlName="overrideGlAccountId" placeholder="Enter Override Gl Account ID" filter="true">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Withdrawal Payment from: {{
                                                    this.finAccountId }}</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-checkbox (click)="selectDeposit($event);"></p-checkbox>
                                                    </div>



                                                </div>
                                            </div>




                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 70%">
                                        <button type="submit" *ngIf="show" (click)="onUpdateWithdraw()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" (click)="onSubmitWithdraw()" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>



            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalSlip" tabindex="-1" role="dialog" aria-labelledby="exampleModalSlipTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">    Create New Deposit Slips</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        {{editMode ?'Update':'Create'}} Payment Group</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="paymentGroupForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput" style="margin-left: -2% !important;">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="paymentGroupTypes" placeholder="Select Type" optionlabel="label" formControlName="paymentGroupTypeId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA" *ngIf="editMode">
                                                        <label for="payment">Payment Group ID </label>
                                                    </div>

                                                    <div class="col-lg-2" *ngIf="editMode">
                                                        <input type="text" class="form-control" [value]="paymentGroupId" readOnly>
                                                    </div>




                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput" style="margin-left: -2% !important;">
                                                        <label for="exampleInputEmail1">Payment Group Name <span
                                                    class="text-danger">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" formControlName="paymentGroupName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Payment Group Name">

                                                        <small class="text-danger" *ngIf="!!paymentGroupForm.controls.paymentGroupName.invalid && !!paymentGroupForm.controls.paymentGroupName.touched">Payment
                                            Group Name is required</small>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -61%;">
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="submitSlip()">{{editMode ?'Update':'Create'}}</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalReconciliation" tabindex="-1" role="dialog" aria-labelledby="exampleModalReconciliationTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create New Add Financial Account Reconciliations</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                        Create New Add Financial Account Reconciliations</a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                            Update Financial Account Reconciliations</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="reconciliationForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                    Gl Reconciliation Name </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="glReconciliationName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Gl Reconciliation Name ">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="createdDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Created Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Last Modified Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="lastModifiedDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Modified Date">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Organization Party ID <span
                                                    style="color:red">*</span> </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="partyIdArray" optionlabel="label" formControlName="organizationPartyId" placeholder="Enter Organization Party ID" filter="true">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Opening Balance
                                               
                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="openingBalance" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Opening Balance">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reconciliation Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="reconciledDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reconciliation Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA" *ngIf="show">
                                                        <label for="exampleInputEmail1">Status </label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="show">
                                                        <p-dropdown [options]="FinancialAccountIntialDataArray" optionlabel="label" formControlName="statusId" placeholder="Enter Status Id" filter="true">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput" *ngIf="show">
                                                        <label for="exampleInputEmail1">Reconciled Balance </label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="show">
                                                        <label>{{this.reconciledBalance}}</label>
                                                    </div>



                                                </div>
                                            </div>



                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left:70%;">
                                        <button type="submit" *ngIf="show" (click)="onUpdateReconciliation()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!show" (click)="onSubmitReconciliation()" class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>


<ngx-spinner></ngx-spinner>