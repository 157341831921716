import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { EventEmitter, Output, } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';  
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { AddRelatedAccountComponent } from 'src/app/accounting/add-related-account/add-related-account.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MyContactsService } from "../../crm/contacts/my-contacts/my-contacts.service";
import { CRMAccountService } from 'src/app/services/crm-account.service';
import Swal from 'sweetalert2';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { EmployeeService } from 'src/app/human-resource/employees/employess.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { FormControl, Validators, } from '@angular/forms';
import * as moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { DetailedEmployeeSummary } from 'src/app/human-resource/detailed-employee-summary/detailed-employee-summary.service';
import { CreateAccountService } from 'src/app/crm/create-account/create-account.service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { truncateWithEllipsis } from '@amcharts/amcharts4/.internal/core/utils/Utils';

    
    import { Location } from '@angular/common';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  @ViewChild('closebutton12') closebutton12;
  @ViewChild('closebutton15') closebutton15;
  faEdit = faEdit;
  faTrash = faTrash;
  activeCategory = 8;
  activeCategoryA = 1;
  activeCategoryC = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  isJobDetail: boolean;
  Person: any;
  partyId: any;
  taxAuthPartyId: any;
  WasChangedList: any;
  relatedContactInfo: any;
  accountDetails: any;
  UserLoginData: any;
  contactInformation: any[] = [];
  emailId: any;
  phoneNumber: string;
  accountName: any;
  relatedContact: any[] = [];
  addr: string;
  PartyLoyaltyPoints: any;
  IdentificationList: any;
  PartyAttribute: any;
  accountData: any;
  VisitsList: any;
  contentList: any;
  PartyNotes: any;
  Visitors: any;
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  bsModalRef: any;
  ProductStore: any;
  fileUploadForm: any;
  accountArray: any[] = [];
  contactMechTypeId: any = '';
  groupName: any = '';
  partyIds: any = '';
  groupNameSearchType: any = 'Contains'
  partyIdSearchType: any = 'Contains';
  @Output() closeAccModal: EventEmitter<any> = new EventEmitter<any>();
  billingAccounts: any[];
  PartyIcsAvsOverRide: any;
  paymentMethods: any[];
  createClassification: FormGroup;
  giftForm: FormGroup;
  editMode: boolean;
  paymentMethodId: string;
  giftCard: any;
  creditCardForm: FormGroup;
  contactMechId: string;
  creditCardValue: any;
  prefixArray = [
    {
      label: 'Mr.',
      value: 'Mr.'
    },
    {
      label: 'Mrs.',
      value: 'Mrs.'
    },
    {
      label: 'Ms.',
      value: 'Ms.'
    },
    {
      label: 'Dr.',
      value: 'Dr.'
    }];
  suffixArray = [
    {
      label: 'Jr.',
      value: 'Jr.'
    },
    {
      label: 'Sr.',
      value: 'Sr.'
    },
    {
      label: 'I',
      value: 'I'
    },
    {
      label: 'II',
      value: 'II'
    },
    {
      label: 'III',
      value: 'III'
    },
    {
      label: 'IV',
      value: 'IV'
    },
    {
      label: 'V',
      value: 'V'
    },
  ];
  expirationMonth = [
    {
      label: '1 (Jan)',
      value: '1'
    },
    {
      label: '2 (Feb)',
      value: '2'
    },
    {
      label: '3 (Mar)',
      value: '3'
    },
    {
      label: '4 (Apr)',
      value: '4'
    },
    {
      label: '5 (May)',
      value: '5'
    },
    {
      label: '6 (Jun)',
      value: '6'
    },
    {
      label: '7 (Jul)',
      value: '7'
    },
    {
      label: '8 (Aug)',
      value: '8'
    },
    {
      label: '9 (Sep)',
      value: '9'
    },
    {
      label: '10 (Oct)',
      value: '10'
    },
    {
      label: '11 (Nov)',
      value: '11'
    },
    {
      label: '12 (Dec)',
      value: '12'
    }];
  expirationYear = [


    {
      label: '2020',
      value: '2020'
    },
    {
      label: '2021',
      value: '2021'
    },
    {
      label: '2022',
      value: '2022'
    },
    {
      label: '2023',
      value: '2023'
    },

    {
      label: '2024',
      value: '2024'
    },
    {
      label: '2025',
      value: '2025'
    },
    {
      label: '2026',
      value: '2026'
    },
    {
      label: '2027',
      value: '2027'
    },
    {
      label: '2028',
      value: '2028'
    },
    {
      label: '2029',
      value: '2029'
    },
    {
      label: '2030',
      value: '2030'
    },
    {
      label: '2031',
      value: '2031'
    },
    {
      label: '2032',
      value: '2032'
    }];
  genderArray = [
    {
      label: 'Male.',
      value: 'M.'
    },
    {
      label: 'Female.',
      value: 'F.'
    },
  ];
  cardTypes: any[];
  createUser: FormGroup;
  yesNOArray: { label: string; value: string; }[];
  createUserForm: FormGroup;
  createAttribue: FormGroup;
  contentIds: any[];
  show = false;
  showAttribute = false;
  eresumeDate: any;
  crmStatus: any;
  crmArray: any[] = [];
  currencyList: any[];
  contactType: any;
  contactTypeArray: any[] = [];
  ElectronicAddressForm: FormGroup;
  createIdentification: FormGroup;
  identification: any;
  identificationArray: any[] = [];
  accountTypesArray: { label: string; value: string; }[];
  paymentMethodTypes: any[];
  Editform: FormGroup;
  fileData: File;
  partyContentTypeId: any;
  partyContentData: any;
  partyContentArray: any[] = [];
  noteForm: FormGroup;
  roleList: any;
  actualfromdate: string;
  throughtDATE: string;
  RevisionsList: any;
  ResidenceStatusEnumIDArray: any[]=[];
  ResidenceStatusEnumID: any[]=[];
  EmploymentStatusEnumId: any;
  EmploymentStatusEnumIdArray: any[]=[];
  martialStatusArray: { label: string; value: string; }[];
  createParty: FormGroup;
  contact: any;
  PartyRate: any;
  contactForm: FormGroup;
  arrayfax = [
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }
  ];
  contactPurpose: any[];
  addressForm: FormGroup;
  solicitationArray = [
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }
  ];
  countryArray: any[];
  showContactModal: boolean = false;
  contactInformationList: any[];
  stateArray: any[]=[];
  accountInfo: any;
  emergencyContact: any;
  demoId: any;
  demoName: any;
  public employeeDetail: any;
  employeeDetailArray: any = [];
  employeeDetailArrayEmail: any = [];
  employeeDetailArrayPhone: any = [];
  contactInformations: any[] = [];
  EditListRelatedAccountList: any;

  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  HidebtnRoleView: boolean=false;
  HidebtnTaskCrud: boolean=false;
  HidebtnTimeCurd: boolean=false;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    @Inject(DOCUMENT) readonly document: Document,
    readonly employeeService: EmployeeService,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _DetailedEmployeeSummary: DetailedEmployeeSummary,
    readonly _FormBuilder: FormBuilder,
      private _location: Location,
      readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly createAccountService: CreateAccountService,
    readonly myContactsService: MyContactsService,
    readonly _Router: Router,
    readonly router: Router,
        readonly _WarehouseService: WarehouseService,
        readonly _CRMAccountService: CRMAccountService,
    readonly MyContactsService: MyContactsService,
    readonly _GlobalResourceService: GlobalResourceService,
    public modalService: BsModalService
  ) {



    this.roleForm = this._FormBuilder.group({
      RoleTypeID: [''],
    });
    this.AddTaskForm = this._FormBuilder.group({
      TaskName: [''],
    });
    this.noteForm = this._FormBuilder.group({
      NoteName: [''],
      Note: [''],
    });
    this.projectForm = this._FormBuilder.group({
      DefaultRate: [''],
      RateAmount: [''],
      ProductCurrencyUomId: [''],
      PercentageUsed: [''],
      PeriodTypeID: [''],
      RateType: [''],
    });
    this.ratesForm = this._FormBuilder.group({
      RoleType: [''],
      FromDate: [''],
      ThroughDate: [''],
    });
    this.createIdentification = this._FormBuilder.group({
      type: [''],
      idvalue: [''],
    });
    this.genderArray = [
      {
        label: 'Male',
        value: 'M'
      },
      {
        label: 'Female',
        value: 'F'
      },
    ];
    this.paymentMethodTypes = [];

    this.accountTypesArray = [
      {
        label: 'Checking',
        value: 'Checking'
      },
      {
        label: 'Savings',
        value: 'Savings'
      }];
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.martialStatusArray = [

      {
        label: 'Single []',
        value: 'SINGLE'
      },
      {
        label: 'Married []',
        value: 'MARRIED'
      },
    ];
    this.updateProfile = this._FormBuilder.group({
      partyID: [''],
      salutation: [''],
      firstName: [''],
      middleName: [''],
      lastName: [''],
      personalTitle: [''],
      suffix: [''],
      nickname: [''],
      firstNameLocal: [''],
      middleNameLocal: [''],
      lastNameLocal: [''],
      otherLocal: [''],
      memberID: [''],
      gender: [''],
      birthDate: [''],
      decreasedDate: [''],
      height: [''],
      weight: [''],
      mothersMaidenName: [''],
      oldMaritialStatus: [''],
      maritialStatusEnumID: [''],
      socialSecurityNumber: [''],
      passportNumber: [''],
      passportExpiryDate: [''],
      totalExperience: [''],
      comments: [''],
      employeeStatusEnumID: [''],
      residenceStatusEnumID: [''],
      occupation: [''],
      yearswithEmployer: [''],
      monthsWithEmployer: [''],
      existingCustomer: [''],
      cardID: [''],
      usCitizen: [''],
      ethnicity: [''],
      accountNo: [''],
      billingRateLevel: [''],
      preferredCurrencyUomID: [''],
      description: [''],
      externalID: [''],
      statusID: [''],
    });

    this.addRelatedAccount = this._FormBuilder.group({
      accountPartyID: [''],
      comments: [''],
    });
    this.createNewContactInformation = this._FormBuilder.group({
      selectContactType: [''],
    });
    this.partyAttributes = this._FormBuilder.group({
      attrName: [''],
      attrValue: [''],
      attrDescription: [''],
    });
    this.addCheckAccount = this._FormBuilder.group({
      nameOnAccount: [''],
      companyNameOnAccount: [''],
      bankName: [''],
      routingNumber: [''],
      paymentMethodTypeId: [''],
      accountType: [''],
      accountNumber: [''],
      description: [''],
    });
    this.createBillingAccount = this._FormBuilder.group({
      accountLimit: [''],
      accountCurrencyUomId: [''],
      contactMechId: [''],
      fromDate: [''],
      thruDate: [''],
      description: [''],
      externalAccountId: [''],
      partyId: [''],
      AvailableBalance: [''],
    });
    this.addNewCreditCard = this._FormBuilder.group({
      companyNameOnCard: [''],
      titleOnCard: [''],
      firstNameOnCard: [''],
      middleNameOnCard: [''],
      lastNameOnCard: [''],
      suffixOnCard: [''],
      cardType: [''],
      cardNumber: [''],
      expMonth: [''],
      expYear: [''],
      description: [''],
    });

    this.createUserForm = this._FormBuilder.group({
      loginId: [''],
      currentpass: [''],
      verifypass: [''],
      passHint: [''],
      passrequire: [''],
      extAuthId: [''],


    });
    this.createGiftCard = this._FormBuilder.group({
      cardNumber: [''],
      pinNumber: [''],
      expMonth: [''],
      expYear: [''],
      description: [''],
    });
    this.createEFTAcc = this._FormBuilder.group({
      nameOnAccount: [''],
      companyNameOnAccount: [''],
      bankName: [''],
      routingNumber: [''],
      accountType: [''],
      accountNumber: [''],
      description: [''],
    });
    this.fileUploadForm = this._FormBuilder.group({
      accountPartyId: [''],
      comments: [''],
      contactPartyId: [''],
      partyId: ['']
    });
    this.createClassification = this._FormBuilder.group({
      avs: [''],
    });
   
    this.ElectronicAddressForm = this._FormBuilder.group({
      ElectronicAddress: [''],
      AllowSolicitation: [''],
    });
    this.contentIds = [];
    this.contactPurpose = [];

    this.editMode = false;
    this.creditCardForm = this._FormBuilder.group({
      cardNumber: ['', [Validators.required]],
      cardType: ['', [Validators.required]],
      companyNameOnCard: [''],
      contactMechId: [''],
      description: [''],
      expMonth: ['', [Validators.required]],
      expYear: ['', [Validators.required]],
      firstNameOnCard: ['', [Validators.required]],
      lastNameOnCard: ['', [Validators.required]],
      middleNameOnCard: ['', [Validators.required]],
      suffixOnCard: [''],
      titleOnCard: ['']
    });

    this.contactForm = this._FormBuilder.group({
      allowSolicitation: [''],
      areaCode: [''],
      askForName: [''],
      contactMechId: [''],
      contactMechPurposeTypeId: ['', [Validators.required]],
      contactMechTypeId: [''],
      contactNumber: [''],
      countryCode: [''],
      extension: [''],
      partyId: [''],
      preContactMechTypeId: ['TELECOM_NUMBER']
    });
    this.addressForm = this._FormBuilder.group({
      address1: [''],
      address2: [''],
      allowSolicitation: [''],
      attnName: [''],
      city: [''],
      contactMechId: [''],
      contactMechPurposeTypeId: ['', [Validators.required]],
      contactMechTypeId: [''],
      countryGeoId: [''],
      directions: [''],
      partyId: [''],
      postalCode: [''],
      preContactMechTypeId: ['POSTAL_ADDRESS'],
      stateProvinceGeoId: [''],
      toName: ['']
    });
    this.cardTypes = [];
    this.countryArray = [];
    this.contactMechId = '';
    this.paymentMethodId = '';

  }

 

  //ngAfterViewInit(): void { this.spinner.show(); }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.Hidebtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.taxAuthPartyId = params["taxAuthPartyId"];
     // this.taxAuthPartyId = params["str"];
    });

    this.addressForm.controls.countryGeoId.valueChanges.subscribe(data => {
      if (data) {
        this.getStates(data);
      }
    });

    this.wasChanged();
    this.accountRelatedDetail();
    this.getUserName();
    this.getLoyaltyPoints();
    this.accountDetail();
    this.getIdentificationNumbersNew();
    this.getAttributeParty();
    this.getPartyNotes();
    this.getPartyVisitors();
    this.getStores();
    this.getPartyContents();
    this.getAccountList();
    this.getPartyIcsAvsOver();
    this.getPaymentMethods();
    this.getPartyIcsAvsOverRide();
    this.getCardTypes();
    this.getPerson();
    this.getProfileStatus();
    this.getCurrency();
    this.contactTypes();
    this.identificationProfileList();
    this.getPaymentMethodTypes();
    this.getAccountDetails();
    this.getPartyContent();
    this.getRevisions();
    this.getEmploymentStatusEnumId();
    this.getResidenceStatusEnumID();
    this.getContactPurpose();
    this.getCountries();
    this.getContactList();
    this.getPreContactMechIds();
    this.getEmployeeDetail();
    this.EditListRelatedAccounts();
    

    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
      partyContentTypeId: new FormControl('')
    });

    this.spinner.hide();
  }
  homeButton(){
    this.router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  onSubmitDoc() {
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();
      let formData = new FormData();
      formData.append('file', this.fileData);
      this.partyContentTypeId = this.Editform.get('partyContentTypeId').value;
  
      this.myContactsService.attachProfileContent(formData, this.partyContentTypeId, this.taxAuthPartyId).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.document.location.reload();
  
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
  
      });
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.toastr.error("Permission Denied"); 
    }


  }
  EditListRelatedAccounts() {
    this.spinner.show();
    this.myContactsService.EditListRelatedAccounts(this.taxAuthPartyId).subscribe(res => {
      this.EditListRelatedAccountList = res.data;
      this.spinner.hide();
    })
  
  }
  openModal(){
    this.showContactModal = true;
}
updateData(partyId: string, type: string) {
  switch (type) {
    case 'POSTAL_ADDRESS':
      this.router.navigate(['/psa/update-postal-address-profile'], { queryParams: { partyId: this.taxAuthPartyId, contactMechId: partyId } })
      break;

    case 'TELECOM_NUMBER':
      this.router.navigate(['/psa/update-contact-profile'], { queryParams: { partyId: this.taxAuthPartyId, contactMechId: partyId } })
  }


}
getPreContactMechIds(): void {
  this.spinner.show();
  this._CRMAccountService.getContactPurposes( this.taxAuthPartyId, 'POSTAL_ADDRESS')
    .then(data => {
      if (data.success) {
        this.contactMechTypeId = data.data.purposeTypes.map(value => {
          return {
            label: value.description,
            value: value.contactMechPurposeTypeId
          };
        });
      }
    });
  this.spinner.hide();

}

editContact(contactMechId: string): void {

  this._Router.navigate(['/facilities/warehouse/create-new-contact-information'], { queryParams: { facilityId: this.taxAuthPartyId, contactMechId } });
}
onModalClosed(): void {
    this.showContactModal = false;
}


getStates(countryId: string): void {
  this.spinner.show();
  this._GlobalResourceService.getStateList(countryId)
    .then(data => {
      if (data.success) {
        this.spinner.hide();
        this.stateArray = data.data.stateList.map(value => {
          return {
            label: value.split(':')[0].trim(),
            value: value.split(':')[1].trim()
          };
        });
      }
    });

}
submitPostal(): void {
  this.spinner.show();
  if (this.addressForm.valid) {
    this._CRMAccountService.createPostalAddress({
      ...this.addressForm.value, ...{
        partyId: this.taxAuthPartyId
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created');
          this.spinner.hide();
          this.closebutton6.nativeElement.click();
          this.getEmployeeDetail();
          this.addressForm.reset();

        }
      });
  }

}
updatePostal(): void {
  this.spinner.show();
  if (this.addressForm.valid) {
    this._CRMAccountService.updatePostalAddress({
      ...this.accountInfo[0], ...this.addressForm.value, ...{
        partyId: this.taxAuthPartyId
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Updated');
          this.spinner.hide();
          this.addressForm.controls.contactMechPurposeTypeId.enable();
          this.addressForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
          this.closebutton6.nativeElement.click();
          this.getEmployeeDetail();
          this.addressForm.reset();

        }
      });
  }

}

getContactList(): void {
  this.spinner.show();
  this._WarehouseService.getFacilityContactInformationList(this.taxAuthPartyId)
      .then(data => {
          this.spinner.hide();
          this.contactInformationList = data.data;
      });
     
}
  getPaymentMethods(): void {
    this.spinner.show();
    this._CRMAccountService.getAllPayment(this.taxAuthPartyId, false)
      .then(data => {
        if (data.success) {
          this.paymentMethods = data.data.map(value => {
            switch (value.paymentMethod.paymentMethodTypeId) {
              case 'GIFT_CARD':
                return {
                  ...value.giftCard, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Gift Card'
                  }
                };
                break;
              case 'EFT_ACCOUNT':
                return {
                  ...value.eftAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'EFT Account'
                  }
                };
                break;
              case 'CERTIFIED_CHECK':
                return {
                  ...value.certifiedCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Certified Check'
                  }
                };
                break;
              case 'CREDIT_CARD':
                return {
                  ...value.creditCard, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Credit Card'
                  }
                };
                break;
              case 'COMPANY_CHECK':
                return {
                  ...value.companyCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Company Check'
                  }
                }
                break;
              case 'PERSONAL_CHECK':
                return {
                  ...value.personalCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Personal Check'
                  }
                };
            }
          });
          this.getBillingAccounts();
          this.spinner.hide();
        }
      });

  }
  removePaymentMethod(type: string, paymentMethodId: string): void {
    if (type === 'BILLING') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this._CRMAccountService.removeBillingAccount(this.taxAuthPartyId, paymentMethodId)
            .then(data => {
              if (data.success) {
                this.getPaymentMethods();
              }
            });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })


    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this._CRMAccountService.removeGiftCard(paymentMethodId)
            .then(data => {
              if (data.success) {
                this.getPaymentMethods();
              }
            });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })


    }
  }

  creditCardPage() {
    this.router.navigate(['/crm/accounts/create-credit-card'], { queryParams: { partyId: this.taxAuthPartyId } });
  }
  giftCardPage() {
    this.router.navigate(['/crm/accounts/create-gift-card'], { queryParams: { partyId: this.taxAuthPartyId } });

  }

  EFTAccountPage() {
    this.router.navigate(['/crm/accounts/create-eft-account'], { queryParams: { partyId: this.taxAuthPartyId, isEftAccount: true } });

  }

  CheckAccountPage() {
    this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.taxAuthPartyId } });
  }
  BillingAccountPage() {
    this.router.navigate([`/crm/accounts/create-billing-account`], { queryParams: { partyId: this.taxAuthPartyId } });

  }


  paymentMethodNavigation(type: string, paymentMethodId: string): void {
    switch (type) {
      case 'GIFT_CARD':
        this.router.navigate(['/crm/accounts/create-gift-card'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId } });
        break;
      case 'EFT_ACCOUNT':
        this.router.navigate(['/crm/accounts/create-eft-account'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, isEftAccount: true } });
        break;
      case 'CERTIFIED_CHECK':
        this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, paymentMethodTypeId: 'CERTIFIED_CHECK' } });
        break;
      case 'CREDIT_CARD':
        this.router.navigate(['/crm/accounts/create-credit-card'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId } });
        break;
      case 'COMPANY_CHECK':
        this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, paymentMethodTypeId: 'COMPANY_CHECK' } });
        break;
      case 'BILLING':
        this.router.navigate([`/crm/accounts/create-billing-account`], { queryParams: { partyId: this.taxAuthPartyId, billingAccountId: paymentMethodId } });
        break;
      case 'PERSONAL_CHECK':
        this.router.navigate([`/crm/accounts/create-check-account`], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, paymentMethodTypeId: 'PERSONAL_CHECK' } });
        break;
    }
  }
  getCountries(): void {
    this.spinner.show();
    this._GlobalResourceService.getCountryList()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.countryArray = data.data.countryList.map(value => {
            return {
              label: value.split(':')[0].trim(),
              value: value.split(':')[1].trim()
            };
          });
        }
      });

  }
  updateContact(): void {
    this.spinner.show();
    if (this.contactForm.valid) {
      this._DetailedEmployeeSummary.updateEmployeeTelecomNumber({
        ...this.contact, ...this.contactForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.closebutton7.nativeElement.click();
            this.getEmployeeDetail();
            this.contactForm.reset();

          }
        });
    }

  }
  submitContact(): void {
    this.spinner.show();
    if (this.contactForm.valid) {
      this._DetailedEmployeeSummary.createEmployeeTelecomNumber({
        ...this.contactForm.value, ...{
          partyId: this.taxAuthPartyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton7.nativeElement.click();
            this.getEmployeeDetail();
            this.contactForm.reset();

          }
        });
    }

  }


  getContactPurpose(): void {
    this.spinner.show();
    this._CRMAccountService.getContactPurposes(this.taxAuthPartyId, 'TELECOM_NUMBER')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.contactPurpose = data.data.purposeTypes.map(value => {
            return {
              label: value.description,
              value: value.contactMechPurposeTypeId
            };
          });
        }
      });

  }
  getEmploymentStatusEnumId() {
    this.spinner.show();
    this.myContactsService.getEmploymentStatusEnumId().subscribe(res => {
      this.EmploymentStatusEnumId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.EmploymentStatusEnumId) {
        this.EmploymentStatusEnumIdArray.push({
          label: value.description,
          value: value.employmentStatusEnumId
        });
      }
    });
  }
  getResidenceStatusEnumID() {
    this.spinner.show();
    this.myContactsService.getResidenceStatusEnumID().subscribe(res => {
      this.ResidenceStatusEnumID = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ResidenceStatusEnumID) {
        this.ResidenceStatusEnumIDArray.push({
          label: value.description,
          value: value.residenceStatusEnumID
        });
      }
    });
  }

  getRevisions() {
    this.spinner.show();
    this.myContactsService.getRevisions().subscribe(res => {
      this.RevisionsList = res.data[0].data;
      this.spinner.hide();
    })
  }


  detailContentPage(partyId,contentId,partyContentTypeId,fromDate) {
    this._Router.navigate(["/psa/party-content-my-profile"], { queryParams: { partyId: partyId , contentId: contentId ,partyContentTypeId:partyContentTypeId,fromDate:fromDate } })
  }
  updateBillingAccount() {
    this.spinner.show();
    const fromActualdate = this.createBillingAccount.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    const actualthruDate = this.createBillingAccount.get('thruDate').value;
    this.throughtDATE = this.datePipe.transform(actualthruDate, "yyyy-MM-dd hh:mm:ss");
    const fromData = {

      "accountCurrencyUomId": this.createBillingAccount.value.accountCurrencyUomId,
      "accountLimit": this.createBillingAccount.value.accountLimit,
      "availableBalance": this.createBillingAccount.value.AvailableBalance,
      "contactMechId": this.createBillingAccount.value.contactMechId,
      "description": this.createBillingAccount.value.description,
      "externalAccountId": this.createBillingAccount.value.externalAccountId,
      "fromDate": this.actualfromdate,
      "partyId": this.taxAuthPartyId,
      "thruDate": this.throughtDATE
    }
    this.myContactsService.updateBillingAccount(fromData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.getPaymentMethods();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  createBillingAccounts() {
    this.spinner.show();
    const fromActualdate = this.createBillingAccount.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    const actualthruDate = this.createBillingAccount.get('thruDate').value;
    this.throughtDATE = this.datePipe.transform(actualthruDate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {
      "accountCurrencyUomId": this.createBillingAccount.value.accountCurrencyUomId,
      "accountLimit": this.createBillingAccount.value.accountLimit,
      "availableBalance": this.createBillingAccount.value.AvailableBalance,
      "contactMechId": this.createBillingAccount.value.contactMechId,
      "description": this.createBillingAccount.value.description,
      "externalAccountId": this.createBillingAccount.value.externalAccountId,
      "fromDate": this.actualfromdate,
      "partyId": this.taxAuthPartyId,
      "thruDate": this.throughtDATE

    }
    this.myContactsService.createBillingAccount(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.getPaymentMethods();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }

  createPartyNote() {
    this.spinner.show();
    const formData = {
      "note": this.noteForm.value.Note,
      "noteName": this.noteForm.value.NoteName,
    }
    this.myContactsService.createPartyNote(formData, this.taxAuthPartyId).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton10.nativeElement.click();
        this.getPartyNotes();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  onDeleteDoc(contentId, fromDate, partyContent, partyId): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.myContactsService.deleteProfileContent(contentId, moment(fromDate).format('yyyy-MM-DD HH:mm:ss.SSS'), partyContent, partyId).subscribe(res => {
          if (res['data'].responseMessage == 'success') {
            //this.toastr.success(res['data'].successMessage);
          }
          this.getPartyContents();
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  export(id: any, contentName: any) {
    this.spinner.show();
    this.myContactsService.downloadProfileContent(id).subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = contentName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }
 
  getPartyContent() {
    this.spinner.show();
    this.myContactsService.partyContentProfile().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        });
      }
    });

  }
  onpartyContent(event) {
    this.partyContentTypeId = event;
  }
  listAll() {
    this._Router.navigate(['psa/list-all-profile']);
  }

  detailSecurity(id) {
    this._Router.navigate(["/partyQuickLink/visitor-summary"], { queryParams: { visitId: id } })
  }
  createCheckAccount() {
    this.spinner.show();
    const requestData = {
      "accountNumber": this.addCheckAccount.value.accountNumber,
      "accountType": this.addCheckAccount.value.accountType,
      "bankName": this.addCheckAccount.value.bankName,
      "companyNameOnAccount": this.addCheckAccount.value.companyNameOnAccount,
      "contactMechId": "",
      "description": this.addCheckAccount.value.description,
      "nameOnAccount": this.addCheckAccount.value.nameOnAccount,
      "paymentMethodTypeId": this.addCheckAccount.value.paymentMethodTypeId,
      "routingNumber": this.addCheckAccount.value.routingNumber,

    }
    this.myContactsService.createCheckAccount(this.taxAuthPartyId, requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getPaymentMethods();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  createProfileGiftCard() {
    this.spinner.show();
    const requestData = {
      "cardNumber": this.createGiftCard.value.cardNumber,
      "description": this.createGiftCard.value.description,
      "expMonth": this.createGiftCard.value.cardNumber,
      "expYear": this.createGiftCard.value.expYear,
      "pinNumber": this.createGiftCard.value.pinNumber,


    }
    this.myContactsService.createProfileGiftCard(this.taxAuthPartyId, requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getPaymentMethods();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  createEFTAccount() {
    this.spinner.show();
    const requestData = {
      "accountNumber": this.createEFTAcc.value.accountNumber,
      "accountType": this.createEFTAcc.value.accountType,
      "bankName": this.createEFTAcc.value.bankName,
      "companyNameOnAccount": this.createEFTAcc.value.companyNameOnAccount,
      "contactMechId": this.createEFTAcc.value.accountNumber,
      "description": this.createEFTAcc.value.description,
      "nameOnAccount": this.createEFTAcc.value.nameOnAccount,
      "routingNumber": this.createEFTAcc.value.routingNumber,

    }
    this.myContactsService.createEFTAccount(this.taxAuthPartyId, requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getPaymentMethods();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  getAccountDetails(): void {
    this.spinner.show();
    this._CRMAccountService.getAccountById(this.taxAuthPartyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.accountData = data.data;
        }
      });

  }

  getPaymentMethodTypes(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodTypes()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.paymentMethodTypes = data.data.map(value => {
            return {
              label: value.description,
              value: value.paymentMethodTypeId
            };
          });
        }
      });

  }


  createCreditcard() {
    this.spinner.show();
    const requestData = {
      "cardNumber": this.createGiftCard.value.cardNumber,
      "cardType": "string",
      "companyNameOnCard": "string",
      "contactMechId": "string",
      "description": this.createGiftCard.value.description,
      "expMonth": this.createGiftCard.value.expMonth,
      "expYear": this.createGiftCard.value.expYear,
      "firstNameOnCard": "string",
      "lastNameOnCard": "string",
      "middleNameOnCard": "string",
      "suffixOnCard": "string",
      "titleOnCard": "string"



    }
    this.myContactsService.createGiftCard(this.taxAuthPartyId, requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getPaymentMethods();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }

  deleteProfileIdentification(partyTypeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.myContactsService.deleteProfileIdentification(this.taxAuthPartyId, partyTypeId).subscribe(res => {
          this.getIdentificationNumbersNew();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  listRelated(partyId) {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { partyIdFrom: partyId } })
  }
  identificationProfileList() {
    this.spinner.show();
    this.myContactsService.identificationProfileList().subscribe((res: any) => {

      this.identification = res.data;
      this.spinner.hide();
      for (const value of this.identification) {
        this.identificationArray.push({
          label: value.description,
          value: value.partyIdentificationTypeId
        })
      }

    })

  }
  updateIdentification(product) {
    if (product.identificationData.partyIdentificationTypeId) {
      this.show = true;
      setTimeout(() => {
        const formValue = this.createIdentification;
        formValue.patchValue({
          type: product.identificationData.partyIdentificationTypeId,
          idvalue: product.identificationData.idValue,
        })
      }, 3000);
    }
    else {
      this.show = false;
    }
  }

  onSubmitIdentificationNumber() {
    this.spinner.show();
    const requestData = {
      "idValue": this.createIdentification.value.idvalue,
      "partyId": this.taxAuthPartyId,
      "partyIdentificationTypeId": this.createIdentification.value.type,

    }
    this.myContactsService.createProfileIdentifications(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.getIdentificationNumbersNew();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  onUpdateIdentificationNumber() {
    this.spinner.show();
    const requestData = {
      "idValue": this.createIdentification.value.idvalue,
      "partyId": this.taxAuthPartyId,
      "partyIdentificationTypeId": this.createIdentification.value.type
    }
    this.myContactsService.updatePartyProfileIdentifications(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.getIdentificationNumbersNew();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  updateElectronicAddress(): void {
    this.spinner.show();


    this.myContactsService.updateElectronicAddress().subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  createElectronicAddress(): void {
    this.spinner.show();
    const requestData = {
      "contactMechPurposeTypeId": "ELECTRONIC_ADDRESS",
      "contactMechTypeId": this.createNewContactInformation.value.selectContactType,
      "facilityId": "string",
      "infoString": this.ElectronicAddressForm.value.ElectronicAddress

    }

    this.myContactsService.createElectronicAddressOrIPAddressOrDomainNameOrWebURLOrInternalPartyOrFileServerOrLdap(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        this.spinner.hide();
        this.currencyList = data.data.currencyList.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });
  }
  contactTypes() {
    this.spinner.show();
    this.myContactsService.contactTypes().subscribe(res => {
      this.contactType = res.data;
      this.spinner.hide();
      for (const value of this.contactType) {
        this.contactTypeArray.push({
          label: value.description,
          value: value.contactMechTypeId
        })
      }
    })
  }
  getProfileStatus() {
    this.spinner.show();
    this.myContactsService.getProfileStatus().subscribe(res => {
      this.crmStatus = res.data;
      this.spinner.hide();
      for (const value of this.crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })

  }
  getAddress(event: any, contactMechId: string): void {
    this.contactMechId = (event.target.checked) ? contactMechId : '';
  }
  contactsDetail(contactId) {
    this.router.navigate(['/crm/my-contacts/details-contact'], 
    { queryParams: { id: contactId } });
  }
  updateProfilePerson() {
    this.spinner.show();
    const requestData = {
      "accountNo": this.updateProfile.value.accountNo,
      "billingRateLevel": this.updateProfile.value.billingRateLevel,
      "birthDate": this.updateProfile.value.birthDate,
      "comments": this.updateProfile.value.comments,
      "description": this.updateProfile.value.description,
      "ethnicity": this.updateProfile.value.ethnicity,
      "externalId": this.updateProfile.value.externalID,
      "firstName": this.updateProfile.value.firstName,
      "gender": this.updateProfile.value.gender,
      "lastName": this.updateProfile.value.lastName,
      "maritalStatusEnumId": this.updateProfile.value.maritialStatusEnumID,
      "partyId": this.taxAuthPartyId,
      "preferredCurrencyUomId": this.updateProfile.value.preferredCurrencyUomID,
      "socialSecurityNumber": this.updateProfile.value.socialSecurityNumber,
      "statusId": this.updateProfile.value.statusID,
      "usCitizen": this.updateProfile.value.usCitizen,
    }
    this.accountsService.updateProfilePerson(this.taxAuthPartyId, requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.updateProfile.reset();


      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  updateAttribute(pro) {
    if (pro.attrName) {
      this.showAttribute = true;
      const fromdate = this.eresumeDate
      setTimeout(() => {
        const formValue = this.partyAttributes;
        formValue.patchValue({
          attrName: pro.attrName,
          attrDescription: pro.attrDescription,
          attrValue: pro.attrValue,
        })
      }, 2000);
    }
    else {
      this.showAttribute = false;
    }
  }

  onSubmitPartyAttribute() {
    
    this.spinner.show();

    const requestData = {
      "attrDescription": this.partyAttributes.value.attrDescription,
      "attrName": this.partyAttributes.value.attrName,
      "attrValue": this.partyAttributes.value.attrValue,
      "partyId": this.taxAuthPartyId


    }
    this.accountsService.createPartyAttribute(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        //this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  onUpdatePartyAttribute() {
    this.spinner.show();

    const requestData = {
      "attrDescription": this.partyAttributes.value.attrDescription,
      "attrName": this.partyAttributes.value.attrName,
      "attrValue": this.partyAttributes.value.attrValue,
      "partyId": this.taxAuthPartyId
    }
    this.accountsService.updatePartyProfileAttribute(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  onSubmitUserName() {
    this.spinner.show();
    const requestData = {
      "currentPassword": this.createUserForm.value.currentpass,
      "currentPasswordVerify": this.createUserForm.value.verifypass,
      "externalAuthId": this.createUserForm.value.extAuthId,
      "partyId": this.taxAuthPartyId,
      "passwordHint": this.createUserForm.value.passHint,
      "requirePasswordChange": this.createUserForm.value.passrequire,
      "userLoginId": this.createUserForm.value.loginId,

    }
    this.accountsService.createprofileUserLogin(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this.toastr.error(err.error.debugMessage);
      }
    });

  }


  getPaymentMethod(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodById(this.taxAuthPartyId, this.paymentMethodId, 'GIFT_CARD')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.giftCard = data.data.giftCardData;
          this.giftForm.patchValue({
            ...data.data.giftCardData, ...{
              description: data.data.paymentMethodData.description
            }
          });
          this.editMode = true;

        }
      });

  }
  getCardTypes(): void {
    this.spinner.show();
    this._CRMAccountService.getCreditCardTypes()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.cardTypes = data.data.map(value => {
            return {
              label: value.enumCode,
              value: value.enumId
            };
          });
        }
      });

  }

  submitCredit(): void {
    this.spinner.show();
    if (this.creditCardForm.valid) {
      this._CRMAccountService.createCreditCard(this.taxAuthPartyId, {
        ...this.creditCardForm.value, ...{
          contactMechId: this.contactMechId
        }
      })
        .then(data => {
          if (data.success) {
            this.toastr.success('Created');
            this.spinner.hide();
            this.closebutton8.nativeElement.click();
            this.creditCardForm.reset();
            this.getPaymentMethods();

          }
        });
    }

  }
  updateCredit(): void {
    this.spinner.show();
    if (this.creditCardForm.valid) {
      this._CRMAccountService.updateCreditCard(this.taxAuthPartyId, this.paymentMethodId, {
        ...this.creditCardValue.creditCardData, ...this.creditCardForm.value, ...{
          contactMechId: this.contactMechId,
          suffixOnCard: 'I'
        }
      })
        .then(data => {
          if (data.success) {
            this.toastr.success('Updated');
            this.spinner.hide();
            this.closebutton8.nativeElement.click();
            this.creditCardForm.reset();
            this.getPaymentMethods();

          }
        });
    }

  }

  resetAvsOverride() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.myContactsService.resetProfileAvsOverride(this.taxAuthPartyId).subscribe(res => {
          this.getPartyIcsAvsOverRide();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  getPartyIcsAvsOverRide() {
    this.spinner.show();
    this.accountsService.getPartyIcsAvsOverRide(this.taxAuthPartyId).subscribe(res => {
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.createClassification;
        formValue.patchValue({
          avs: res.data[0].avsDeclineStringList.avsDeclineString,

        })
      }, 500);

    })

  }

  onUpdate() {
    this.spinner.show();

    this.myContactsService.updateProfileOverride(this.createClassification.value.avs, this.taxAuthPartyId).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getBillingAccounts(): void {
    this.spinner.show();
    this._CRMAccountService.getBillingAccounts(this.taxAuthPartyId)
      .then(data => {
        if (data.success) {
          this.billingAccounts = data.data.map(value => {
            return {
              ...value, ...{ type: 'BILLING', paymentMethodTypeId: 'BILLING' }
            };
          });
          this.paymentMethods = [...this.paymentMethods, ...this.billingAccounts];
        }
        this.spinner.hide();
      });

  }

 

  getPartyIcsAvsOver() {
    this.spinner.show();
    this.createEventServices.getPartyIcsAvsOver(this.taxAuthPartyId).subscribe(res => {
      this.PartyIcsAvsOverRide = res.data[0];
      this.spinner.hide();
    })

  }
  getAccountList() {
    this.spinner.show();
    const searchAccountForm = {
      contactMechTypeId: this.contactMechTypeId,
      groupName: this.groupName,
      groupNameSearchType: this.groupNameSearchType,
      partyId: this.partyIds,
      partyIdSearchType: this.partyIdSearchType
    }
    const all = "true"
    this.createEventServices.getProfileAccountsList(searchAccountForm, all).subscribe(res => {
      this.accountData = res.data;
      this.spinner.hide();
      for (const value of this.accountData) {
        this.accountArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }

    })

  }

  onSubmitRelatedAccount() {
    this.spinner.show();
    this.fileUploadForm.get('contactPartyId').patchValue(this.taxAuthPartyId);
    this.fileUploadForm.get('partyId').patchValue(this.taxAuthPartyId);
    const formData = new FormData();
    const formdata = { ...this.fileUploadForm.value };
    this.createEventServices.RelatedAccount(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.accountRelatedDetail();
        this.closebutton5.nativeElement.click();


      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();

  }



  createAddRelatedAccount() {
    //this.router.navigate(["financial/add-related-account"],{queryParams:{party:this.taxAuthPartyId}});
    this._Router.navigate([], { queryParams: { party: this.taxAuthPartyId } });
    this.bsModalRef = this.modalService.show(AddRelatedAccountComponent, { class: 'modal-lg' });
    // this.showAccModal = true;
  }

  getStores() {
    this.spinner.show();
    this.accountsService.getStores(this.taxAuthPartyId).subscribe(res => {
      this.ProductStore = res.data;
      this.spinner.hide();
    })

  }

  getPartyVisitors() {
    this.spinner.show();

    this.accountsService.getPartyVisitors(this.finSize).subscribe(res => {
      this.Visitors = res.data;
      this.spinner.hide();
    })
  }


  getPartyContents() {
    this.spinner.show();
    this.accountsService.getPartyContentProfileList(this.taxAuthPartyId).subscribe(res => {
      this.contentList = res.data;
      this.spinner.hide();
    })

  }
  getPartyNotes() {
    this.spinner.show();
    this.accountsService.getPartyNotes(this.taxAuthPartyId).subscribe(res => {
      this.PartyNotes = res.data;
      this.spinner.hide();
    });
  }
  getAttributeParty() {
    this.spinner.show();
    this.accountsService.getAttributeParty(this.taxAuthPartyId).subscribe(res => {
      this.PartyAttribute = res.data;
      this.spinner.hide();
    })

  }

  getIdentificationNumbersNew() {
    this.spinner.show();
    this.accountsService.getIdentificationNumbersNew(this.taxAuthPartyId).subscribe(res => {
      this.IdentificationList = res.data;
      this.spinner.hide();
    })

  }
  getLoyaltyPoints() {
    this.spinner.show();
    this.accountsService.getLoyaltyPoints(this.taxAuthPartyId).subscribe(res => {
      this.PartyLoyaltyPoints = res.data;
      this.spinner.hide();
    })

  }
  getEmployeeDetail() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.taxAuthPartyId).subscribe(res => {
      this.employeeDetail = res.data;
      res.data.contactInformation.pop();
      this.contactInformations = res.data.contactInformation;

      this.emergencyContact = res.data.emergencyContacts;
      this.demoName = res.data.firstName + " " + res.data.lastName;
      this.demoId = res.data.partyId;
      this.employeeDetailArray.push({
        data: this.employeeDetail
      })
      this.employeeDetailArrayEmail.push({
        data: this.employeeDetail
      })
      this.employeeDetailArrayPhone.push({
        data: this.employeeDetail
      })

    })
    this.spinner.hide();
  }
  accountDetail() {
    this.spinner.show();
    this.accountsService.getAccountById(this.taxAuthPartyId).subscribe(res => {
      this.contactInformation = res.data.contactInformation;
      this.relatedContact = res.data.relatedContacts;
      this.accountName = res.data.groupName;
      this.addr = res.data.address.address1 + ' ' + res.data.address.city + ' ' + res.data.address.postalCode + ' ' + res.data.address.countryGeoId;
      this.emailId = res.data.email.infoString;
      this.phoneNumber = res.data.telenumber.countryCode + ' ' + res.data.telenumber.areaCode + ' ' + res.data.telenumber.contactNumber
    })
    this.spinner.hide();
  }
  getUserName() {
    this.spinner.show();
    this.accountsService.getUserName(this.taxAuthPartyId).subscribe(res => {
      this.UserLoginData = res.data;
      this.spinner.hide();
    })

  }
  updatePassword(loginId) {
    this._Router.navigate(["partyQuickLink/update-password"], { queryParams: { party: this.taxAuthPartyId, loginid: loginId } });
  }

  securityList(loginId) {
    this._Router.navigate(["psa/security-my-profile"], { queryParams: { party: this.taxAuthPartyId, loginid: loginId } });
  }
  getVisits() {
    this.spinner.show();
    this.accountsService.getVisits(this.taxAuthPartyId).subscribe(res => {
      this.VisitsList = res.data;
      this.spinner.hide();
    })
  }

  wasChanged() {
    this.spinner.show();
    this.accountsService.wasChanged(this.taxAuthPartyId).subscribe(res => {
      this.WasChangedList = res.data;
      this.spinner.hide();
    })
  }
  getPerson() {
    this.spinner.show();
    this.accountsService.getPerson(this.taxAuthPartyId).subscribe(res => {
      this.Person = res.data;
      this.spinner.hide();
    })

  }
  accountRelatedDetail() {
    this.spinner.show();
    this.accountsService.getAccountRelatedById(this.taxAuthPartyId).subscribe(res => {
      this.accountDetails = res.data;
      this.relatedContactInfo = res.data.relatedCompanies;

    })
    this.spinner.hide();
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  MyProfile() {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Preferences() {
    this._Router.navigate(["/psa/preferences-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Roles() {
    this._Router.navigate(["/psa/roles"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Project() {
    this._Router.navigate(["/psa/project-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  TasksProfile() {
    this._Router.navigate(["/psa/tasks-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Rates() {
    this._Router.navigate(["/psa/rates-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Revisions() {
    this._Router.navigate(["/psa/revisions-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }

  Backlog() {
    this._Router.navigate(["/psa/product-backlog"])
  }
  Sprint() {
    this._Router.navigate(["/psa/sprints"])
  }
  MyWork() {
    this._Router.navigate(["/psa/my-work"])
  }
  Tasks() {
    this._Router.navigate(["/psa/tasks"])
  }
  OpenTest() {
    this._Router.navigate(["/psa/open-test"])
  }
  Revision() {
    this._Router.navigate(["/psa/find-task-revision"])
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
