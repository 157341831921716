import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'; import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';


@Component({
  selector: 'app-image-management-main-detail',
  templateUrl: './image-management-main-detail.component.html',
  styleUrls: ['./image-management-main-detail.component.css']
})
export class ImageManagementMainDetailComponent implements OnInit {
  ImageUploadListMain: any;
  
  productId: any;

  constructor( 
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly headerService: HeaderService,
      private _location: Location,
      readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
    ) { }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=>{
      this.productId = params.productId;
    })
    this.getImageUploadListMain();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getImageUploadListMain() {
    this.spinner.show();
    this.myContactsService.getImageUploadList(this.productId).subscribe(res => {
      this.ImageUploadListMain = res.data[0].data;
      this.spinner.hide();
    })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
