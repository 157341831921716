import { Component, OnInit, ViewChild } from '@angular/core';
declare var $: any;
import { SortEvent } from 'primeng/api';
import { ShippingService } from '../shipping.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
  import { Location } from '@angular/common';

import { Router } from '@angular/router';
@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.css']
})
export class ShipmentComponent implements OnInit {
  activeCategory = 2;
  activeCategorytab = 2;
  activeCat=14;
  products3: any;
  @ViewChild('closebutton') closebutton;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  shipmentMethodType: any[];
  shipmentMethodTypeForm: FormGroup;
  editMode: boolean;
  shipmentMethodTypeId: string;
  shipmentTypeIds: any[];
  isShown=false;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    readonly router: Router,
    readonly _ShippingService: ShippingService,
    readonly _FormBuilder: FormBuilder,    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly _ToastrService: ToastrService
  ) {
    this.shipmentMethodType = [];
    this.shipmentMethodTypeForm = this._FormBuilder.group({
      description: [''],
      sequenceNum: [0],
      shipmentMethodTypeId: ['', [Validators.required]]
    });
    this.editMode = false;
    this.shipmentMethodTypeId = '';
    this.shipmentTypeIds = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    this.getShipmentMethodType();
   
  }

  getShipmentMethodType(): void {
    this.spinner.show();
    this._ShippingService.getShipmentMethodType({ pageNo: 1, pageSize: 200 })
      .then(data => {
        if (data.success) {
          this.shipmentMethodType = data.data;
          this.spinner.hide();
        }
      });
     
  }
  edit(shipmentMethodTypeId: string, description: string, sequenceNum: string): void {
    this.editMode = true;
    this.shipmentMethodTypeId = shipmentMethodTypeId;
    this.shipmentMethodTypeForm.patchValue({
      shipmentMethodTypeId, description, sequenceNum
    });
    this.shipmentMethodTypeForm.controls.shipmentMethodTypeId.disable();
    this.shipmentMethodTypeForm.controls.shipmentMethodTypeId.updateValueAndValidity();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  delete(shipmentMethodTypeId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._ShippingService.deleteShipmentMethodType(shipmentMethodTypeId)
        .then(data => {
          if (data.success) {
          
            this.getShipmentMethodType();
          }
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  reset(): void {
    this.shipmentMethodTypeForm.reset();
    document.getElementById('eventForm').scrollIntoView({ behavior: 'smooth' });
  }
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      if (this.shipmentMethodTypeForm.valid) {
        this._ShippingService.updateShipmentMethodType({
          shipmentMethodTypeId: this.shipmentMethodTypeId,
          ...this.shipmentMethodTypeForm.value
        }).then(data => {
            if (data.success) {
              this._ToastrService.success('Updated');
              this.spinner.hide();
              this.getShipmentMethodType();
              this.editMode = false;
              this.shipmentMethodTypeForm.reset();
              this.shipmentMethodTypeForm.controls.shipmentMethodTypeId.enable();
              this.shipmentMethodTypeForm.controls.shipmentMethodTypeId.updateValueAndValidity();
              document.getElementById('eventForm').scrollIntoView({ behavior: 'smooth' });
              this.closebutton.nativeElement.click();
            }
          });}
    } else {
      if (this.shipmentMethodTypeForm.valid) {
        this._ShippingService.createShipmentMethodType(this.shipmentMethodTypeForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.spinner.hide();
              this.shipmentMethodTypeForm.reset();
              this.getShipmentMethodType();
              document.getElementById('eventForm').scrollIntoView({ behavior: 'smooth' });
              this.closebutton.nativeElement.click();
            }});
      }} }
  feautureValue(){
    this.isShown = !this.isShown;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  detailPage(eid,edes,eseq) {
    this.router.navigate(["/facilities/catalog/shipment/detail-shipment-summary"], { queryParams: { id: eid,des:edes,seq:eseq} })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
