import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { FinancialService } from 'src/app/services/financial.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { SortEvent } from 'primeng/api';
import { AddEmployeePositionService } from 'src/app/human-resource/add-employee-position/add-employee-position.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-main-find-paycheck',
  templateUrl: './main-find-paycheck.component.html',
  styleUrls: ['./main-find-paycheck.component.css']
})
export class MainFindPaycheckComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  products3: any;
  activeCategory=1;
  @ViewChild("inputField") inputField;
  PayCheckTable:any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= "";
  PaymentMethod: any;
  PaymentMethodIDArray: any[]=[];
  PaytoPartyArray: any[]=[];
  PaytoParty: any; 
  PaycheckType: any;
  PaycheckTypeArray: any[]=[];
  advanceSearch: { 
    PaymentId: string;
     PaycheckType: string; 
     PaytoParty: string; 
     Status:   string; 
     FromDate: string; 
     ThruDate: string; 
     PaymentMethod: string; 
     RefNum: string; };

  status: { label: string; value: string; }[];
  statusArray: { label: string; value: string; }[];
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService, 
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,
    private _location: Location,
    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
   
    readonly _AccountingApService: AccountingApService,
    readonly _WarehouseService: WarehouseService,
    readonly addSkillService: AddSkillService,
  ) { 
    this.advanceSearch = {

      PaymentId: "",
      PaycheckType: "",
      PaytoParty: "",
      Status: "",
      FromDate: "",
      ThruDate: "",
      PaymentMethod: "",
      RefNum: "",
     

    };

    this.statusArray = [
      {
        label: '',
        value: 'NULL'
      },
      {
        label: 'Not Paid',
        value: 'PMNT_NOT_PAID'
      },
      {
        label: 'Sent',
        value: 'PMNT_SENT'
      },
      {
        label: 'Recieved',
        value: 'PMNT_RECEIVED'
      },
      {
        label: 'Confirmed',
        value: ' PMNT_CONFIRMED'
      },
      {
        label: 'Cancelled',
        value: 'PMNT_CANCELLED'
      },
      {
        label: 'Voided',
        value: 'PMNT_VOID'
      },
    ];
    
  }

  ngOnInit(): void {

    this.getPartyId();
    this.getPaycheckType();
    this.getPaymentMethod();
    this.getFindPayCheck();
  }
  focusOnInput(){
    this.inputField.nativeElement.focus();
  }
  reset(){
    this.spinner.show();
    this.advanceSearch.PaymentId="";
    this.advanceSearch.PaycheckType="";
    this.advanceSearch.PaytoParty="";
    this.advanceSearch.Status="";
    this.advanceSearch.FromDate="";
    this.advanceSearch.ThruDate="";
    this.advanceSearch.PaymentMethod="";
    this.advanceSearch.RefNum=""

    
   
      this.accountsService.getFindPayCheck().subscribe((res:any)=> {
        this.PayCheckTable = res.data;
        this.spinner.hide();
      
      
      }) 
  }

  getFindPayCheck() {
    this.accountsService.getFindPayCheck().subscribe((res: any) => {
      this.spinner.show();
      this.PayCheckTable = res.data;
      this.spinner.hide();
    })
  }

  FindPayCheck(){
    
    this.spinner.show();
    const req = {
      "paymentId": this.advanceSearch.PaymentId ? this.advanceSearch.PaymentId : '',
      "paycheckType": this.advanceSearch.PaycheckType ? this.advanceSearch.PaycheckType : '',
      "partyIdTo": this.advanceSearch.PaytoParty ? this.advanceSearch.PaytoParty : '',
      "statusId": this.advanceSearch.Status ? this.advanceSearch.Status : '',
      "effectiveDate": this.advanceSearch.FromDate ? this.advanceSearch.FromDate : '',
      "paymentMethodId": this.advanceSearch.PaymentMethod ? this.advanceSearch.PaymentMethod : '',
      "paymentRefNum": this.advanceSearch.RefNum ? this.advanceSearch.RefNum : '',
     
    }
      this.accountsService.FindPayCheck(req.paymentId,req.paycheckType,req.partyIdTo,req.statusId,req.effectiveDate,
        req.paymentMethodId, req.paymentRefNum).subscribe((res:any)=> {
        this.PayCheckTable = res.data;
        this.spinner.hide();
      
      
      })
     
     }
     backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/financial/main']);
  }
  

  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.PaytoParty = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.PaytoParty) {
        this.PaytoPartyArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
 getPaymentMethod() {
    this.spinner.show();
    this.accountsService.getPaymentMethod().subscribe(res => {
      this.PaymentMethod= res.data;
      this.spinner.hide();
      for (const value of this.PaymentMethod) {
        this.PaymentMethodIDArray.push({
          label: value.PaymentMethods[0].description,
          value: value.PaymentMethods[0].paymentMethodId
        })
      }
    })
  }

  
getPaycheckType() {
    this.spinner.show();
    this.accountsService.getPaycheckType().subscribe(res => {
      this.PaycheckType= res.data;
      this.spinner.hide();
      for (const value of this.PaycheckType) {
        this.PaycheckTypeArray.push({
          label: value.paycheckType,
          value: value.paycheckType
        })
      }
    })
  }

  detailedPage(paymentId){
    this.router.navigate(['/financial/detailed-paycheck'], { queryParams: { paymentId: paymentId } })
  }

  findPaycheck()
  {
    this.router.navigate(['financial/main-find-paycheck']);
  }
  createPaychecks()
  {
    this.router.navigate(['/financial/main-create-paycheck']);

  }
  paycheckConfiguration()
  {
    this.router.navigate(['/financial/main-configuration']);

  }
  employeess()
  {
    this.router.navigate(['/financial/employees-profile']);

  }
  reports()
  {
    this.router.navigate(['/financial/main-reports']);

  }

  contractReferencees()
  {
    this.router.navigate(['/financial/contractor']);

  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  

  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
