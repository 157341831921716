import { DatePipe, Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';

import { EmployementApplicationService } from 'src/app/employeeApplication/employee-application.service';
import { EmployeeApplicationService } from 'src/app/employeeApplication/employee-application/employee-application.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { ReallocationsService } from 'src/app/human-resource/reallocation/reallocations.service';
import { InternalJobService } from 'src/app/human-resource/recuitment/internal-job-posting/internal-job-posting.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-create-party-employe-application',
  templateUrl: './create-party-employe-application.component.html',
  styleUrls: ['./create-party-employe-application.component.css']
})
export class CreatePartyEmployeApplicationComponent implements OnInit {
  statusList: any;
  activeCategory = 2;
  appSource: any;
  statusArray: any=[];
  appSourceArray: any=[];
  updateId: any;
  show=false;
  pageNo=1;
  total=0;
  rows = 50;
  pageSize=100;
  readonly employessSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,

  }
  search="";
  public jobPost = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search

  }
  public applicationSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize
  }
  positionArray: any=[];
  positionType: any;
  personId: any;
  partyIdArray: any=[];
  internalJobPostList: any;
  appIdArray: any=[];
  updateethrdateStatus: any;
  party: any;
  @ViewChild('closebutton') closebutton;

  constructor(readonly employeeApplicationService:EmployeeApplicationService,
    public createEventFormService:CreateEventFormService,
    readonly toastr:ToastrService,
    readonly router:Router,
    readonly activatedRoute:ActivatedRoute,
    readonly reallocationsService:ReallocationsService,
    public addSkillService:AddSkillService,
    readonly internalJobService:InternalJobService,
    readonly datePipe: DatePipe,
    readonly employementApplicationService:EmployementApplicationService,
    readonly spinner:NgxSpinnerService,
    private _location: Location,
    public bsModalRef: BsModalRef
    ) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    
    
    this.createEventFormService.employee_application_form=null;
    this.createEventFormService.employeeApplicationForm(this.createEventFormService.employee_application_form);
    this.statusId();
    this.appSourceTypeList();
    this.getPositionType();
    this.getPartyId();
    this.getInternalJobDropdwon();
    this.activatedRoute.queryParams.subscribe(params=> {
      this.updateId = params["updateId"];
      this.party = params["party"];
      if(this.updateId){
        this.show=true;
        this.getEmployeeApplication();
      }
      else {
        this.show=false;
      }
    })
  }
  
  getInternalJobDropdwon() {
    this.spinner.show();
    const jobList ={
      applicationDateFrom: "",
      applicationDateTo: "",
      applicationId: "",
      applyingPartyId: "",
      approverPartyId: "",
      jobRequisitionId: "",
      statusId: "",
      applicationDateFromSearchType: "Equals",
      applicationDateToSearchType: "Equals"
    }
   this.internalJobService.getInternalJobList(this.jobPost,jobList).subscribe((res:any)=> {
     this.internalJobPostList = res.data;
     this.spinner.hide();
     for(const value of  this.internalJobPostList){
      this.appIdArray.push({
        label:value.applicationId,
        value:value.applicationId
      })
  }
   })
  
  }
  getPositionType(){
    this.spinner.show();
    const emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId:"",
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.reallocationsService.getEmployeePosition(this.employessSize,emplPosition).subscribe(res=> {
      
      this.positionType = res.data;
      this.spinner.hide();
      for(const value of this.positionType){
        this.positionArray.push({
          label:value.emplPositionId,
          value:value.emplPositionId
        })
      }
    })
   
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res=> {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.personId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }
   statusId(){
    this.spinner.show();
     this.employeeApplicationService.getStatusList().subscribe(res=> {
       this.statusList = res.data;
       this.spinner.hide();
       for(const value of this.statusList){
         this.statusArray.push({
           label:value.description,
           value:value.statusId
         })
       }
     })
   
   }
   getEmployeeApplication(){
  
    this.spinner.show();
    const data= {
       "applicationDateFrom": "",
       "applicationDateFromSearchType": "Contains",
       "applicationDateTo": "",
       "applicationDateToSearchType": "Contains",
       "applicationId":this.updateId,
       "applyingPartyId": "",
       "emplPositionId": "",
       "employmentAppSourceTypeId": "",
       "referredByPartyId": "",
       "statusID": [
         ""
       ]
     }
     this.employementApplicationService.getEmpJobList(this.applicationSize,data).subscribe(res=> {
      // this.employmentApplication=res.data;
      this.spinner.hide();
      setTimeout(() => {
        const formValue =  this.createEventFormService.employee_application_form;
        const date=this.datePipe.transform(res.data[0].applicationDate,"yyyy-MM-dd");
      formValue.patchValue({
        applicationId:res.data[0].applicationId,
        applicationDate:date,
        applyingPartyId:res.data[0].applyingPartyId,
        emplPositionId: res.data[0].emplPositionId,
        employmentAppSourceTypeId: res.data[0].employmentAppSourceTypeId,
        statusId:res.data[0].statusId,
        referredByPartyId:res.data[0].referredByPartyId
       
      })
      }, 3000);
     
     })
     
   }
   onSubmit(){
    this.spinner.show();
    const thrudate=this.createEventFormService.employee_application_form.get('applicationDate').value;
    this.updateethrdateStatus=this.datePipe.transform(thrudate,"yyyy-MM-dd hh:mm:ss");
     const formDate={
      applicationDate: this.updateethrdateStatus,
      applicationId: this.createEventFormService.employee_application_form.value.applicationId,
      applyingPartyId:this.party,
      emplPositionId: this.createEventFormService.employee_application_form.value.emplPositionId,
      employmentAppSourceTypeId: this.createEventFormService.employee_application_form.value.employmentAppSourceTypeId,
      referredByPartyId: this.createEventFormService.employee_application_form.value.referredByPartyId,
      statusId:this.createEventFormService.employee_application_form.value.statusId
     }
    
     this.employeeApplicationService.createEmploymentApplication(formDate).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.modalClose();
        this.spinner.hide();
        this._location.back();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this._location.back();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
   }
   onUpdate(){
    this.spinner.show();
    const thrudate=this.createEventFormService.employee_application_form.get('applicationDate').value;
    this.updateethrdateStatus=this.datePipe.transform(thrudate,"yyyy-MM-dd hh:mm:ss");
     const formDate={
      applicationDate: this.updateethrdateStatus,
      applicationId: this.createEventFormService.employee_application_form.value.applicationId,
      applyingPartyId:this.party,
      emplPositionId: this.createEventFormService.employee_application_form.value.emplPositionId,
      employmentAppSourceTypeId: this.createEventFormService.employee_application_form.value.employmentAppSourceTypeId,
      referredByPartyId: this.createEventFormService.employee_application_form.value.referredByPartyId,
      statusId:this.createEventFormService.employee_application_form.value.statusId
     }
  
this.employeeApplicationService.updateEmployee(formDate,this.updateId).subscribe((res:any)=> {
  if(res.success) {
    this.toastr.success(res.data.successMessage);
    this.modalClose();
    this.spinner.hide();
    this._location.back();
  }
  else if(res.success == false) {
    this.toastr.error("Error");
    this.spinner.hide();
  }
},(err) => {
  this.spinner.hide();
  for(const data of err.error.errors){
    this.toastr.error(data.fieldName + ' ' + data.fieldError)
  }
})

   }

   appSourceTypeList(){
    this.spinner.show();
  this.employeeApplicationService.getAppsourceList().subscribe(res=> {
    this.appSource = res.data;
    this.spinner.hide();
    for(const data of this.appSource){
      this.appSourceArray.push({
        label:data.description,
        value:data.employmentAppSourceTypeId
      })
    }
  })
 
   }
   modalClose(): void {
    this.closebutton.nativeElement.click();
    this.bsModalRef.hide();
  }
   cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

