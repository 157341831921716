import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { DropdownModule } from 'primeng/dropdown';
import { OrderManagementService } from '../order-management.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-purchase-payment-options',
  templateUrl: './purchase-payment-options.component.html',
  styleUrls: ['./purchase-payment-options.component.css']
})
export class PurchasePaymentOptionsComponent implements OnInit {

  checkOutPaymentId: string;
  paymentMethodTypes: any[];
  billingAccount: any[];
  paymentMethod: string;
  constructor(
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _OrderManagementService: OrderManagementService,
    readonly _ToastrService: ToastrService
  ) {
    this.paymentMethod = '';
    this.checkOutPaymentId = '';
    this.paymentMethodTypes = [];
    this.billingAccount = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.setBilling();
  }
  setBilling(): void {
    this.spinner.show();
    this._OrderManagementService.setBilling('N')
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Billing Initiated');
          this.paymentMethodTypes = data.data.paymentMethodTypeAndId;
          this.spinner.hide();
        }
      });
     
  }
  setPaymentMethod(event: any): void {
    this.paymentMethod = event;
  }
  finalizeOrder(): void {
    this.spinner.show();
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      finalizeMode: 'payment',
      billingAccountId: '',
      billingAccountAmount: '',
      checkOutPaymentId: this.paymentMethod
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.router.navigate(['/order/purchase-order-additional-party-entry']);
        }
      });
      this.spinner.hide();
  }
}
