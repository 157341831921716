import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe,Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-create-billing-account',
    templateUrl: './create-billing-account.component.html',
    styles: [`
    .border-card{
        border: 1px solid;
        border-color: #0d3769 !important;
        /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */
    }
`]
})
export class CreateBillingAccountComponent implements OnInit {
    currencyUomIds: any[];
    editMode: boolean;
    partyId: string;
    billingAccountForm: FormGroup;
    partyIds: any[];
    paymentMethodId: string;
    billingAccount: any;
    availableBalance: string;
    @Output() closeBillModal: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('closebutton') closebutton;
	
    constructor(
        readonly _CRMAccountService: CRMAccountService,
        readonly  _Router: Router,private _location: Location,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly spinner:NgxSpinnerService,
        readonly _FormBuilder: FormBuilder,
        readonly _GlobalResourceService: GlobalResourceService,
        readonly _ToastrService: ToastrService,
        public bsModalRef: BsModalRef
    ) {
        this.editMode = false;
        this.partyId = '';

        this.billingAccountForm = this._FormBuilder.group({
            accountCurrencyUomId: [''],
            accountLimit: [''],
            contactMechId: [''],
            description: [''],
            externalAccountId: [''],
            fromDate: [''],
            partyId: ['', [Validators.required]],
            thruDate: ['']
        });
        this.currencyUomIds = [];
        this.partyIds = [];
        this.paymentMethodId = '';
        this.availableBalance = '0';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
        this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.billingAccountId;
        this.getCurrencyList();
        this.getPartyIds();
        if (this.paymentMethodId) {
            this.getBillingAccountById();
        }
    }
    getBillingAccountById(): void {
        this.spinner.show();
        this._CRMAccountService.getBillingAccountById(this.paymentMethodId, this.partyId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.billingAccount = data.data;
                    this.availableBalance = data.data.availableBalance;
                    this.billingAccountForm.patchValue({
                        ...data.data
                    });
                    this.editMode = true;
                }
               
            });
           
    }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
           
    }
    getCurrencyList(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.currencyUomIds = data.data.currencyList.map(value => {
                        return {
                            label: value.uomId,
                            value: value.uomId
                        };
                    });
                }
            });
           
    }
    submit(): void {
        this.spinner.show();
        if (this.billingAccountForm.valid) {
            this._CRMAccountService.createBillingAccount(this.partyId, {
                ...this.billingAccountForm.value, ...{
                    fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
                    thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.modalClose();
                        this.spinner.hide();
                        this.cancelSubmit();
                    }
                });
        }
     
    }
    update(): void {
        this.spinner.show();
        if (this.billingAccountForm.valid) {
            this._CRMAccountService.updateBillingAccount(this.paymentMethodId, this.partyId, {
                ...this.billingAccount, ...this.billingAccountForm.value, ...{
                    fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
                    thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.modalClose();
                        this.spinner.hide();
                        this.cancelSubmit();
                    }
                });
        }
       
    }
    cancelSubmit(): void {
        this._location.back();
    }
    modalClose(): void {
        this.closebutton.nativeElement.click();
        this.closeBillModal.emit();
      }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}