import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class LabelTypesService {

  constructor(private http: HttpClient) { }

  getInventoryItemLabelTypeList(): Observable<any> {
    const getInventoryItemLabelTypeList = URLS.getInventoryItemLabelTypeList;
    return this.http.get(getInventoryItemLabelTypeList);
  }

  getParentTypeId(): Observable<any> {
    const getParentTypeId = URLS.getParentTypeId;
    return this.http.get(getParentTypeId);
  }

  createInventoryItemLabelType(formData): Observable<any> {
    const createInventoryItemLabelType = URLS.createInventoryItemLabelType;
    return this.http.post(createInventoryItemLabelType, formData);
  }

  updateInventoryItemLabelType(formData): Observable<any> {
    const updateInventoryItemLabelType = URLS.createInventoryItemLabelType;
    return this.http.put(updateInventoryItemLabelType, formData);
  }

  deleteInventoryItemLabelType(id) {
    const deleteInventoryItemLabelType = URLS.createInventoryItemLabelType + `/${id}`;
    return this.http.delete(deleteInventoryItemLabelType);
  }


}
