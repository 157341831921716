import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeePositionService } from '../employee-position/employee-position.service'
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AddEmployeePositionService } from '../add-employee-position/add-employee-position.service';
import { CreateEmployeePositionService } from '../add-employee-position/add-employee-position-service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-employee-position',
  templateUrl: './employee-position.component.html',
  styleUrls: ['./employee-position.component.css']
})
export class EmployeePositionComponent implements OnInit {
  show: boolean;
  @ViewChild('closebutton') closebutton;
  activeCategory=2;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  private employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: any = [];
  dateIdArray: any = []
  advanceSearch: {

    actualFromDateFrom: string,
    actualFromDateTo: string,
    actualThruDateFrom: string,
    actualThruDateTo: string,
    budgetId: string,
    budgetIdSearchType: string,
    budgetItemSeqId: string,
    budgetItemSeqIdSearchType: string,
    emplPositionId: string,
    emplPositionIdSearchType: string,
    emplPositionTypeId: string,
    emplPositionTypeIdSearchType: string,
    estimatedStartDateFrom: string,
    estimatedStartDateTo: string,
    estimatedThruDateFrom: string,
    estimatedThruDateTo: string,
    exemptFlag: string,
    fulltimeFlag: string,
    partyId: string,
    partyIdSearchType: string,
    salaryFlag: string,
    statusId: string,
    statusIdSearchType: string,
    temporaryFlag: string,
    actualThrDate: string,
    actualFromDAte: string,
    estimatedFromDate: string,
    estimatedThroughDate: string
  };
  employeePosition: any;
  Flag: any = [];
  personId: any;
  partyIdArray: any[]=[];
  internalOrganization: any;
  internalArray: any[]=[];
  positionType: any;
  positionArray: any[]=[];
  status: any;
  statusArray: any[]=[];
  estifromdate: string;
  estithroughdate: string;
  actualfromdate: string;
  actualthroughdate: string;
  solicitation: { label: string; value: string; }[];

  constructor(
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly createEmployeePositionService: CreateEmployeePositionService,
    readonly spinner: NgxSpinnerService,
    readonly toastr: ToastrService,
    readonly activatedRoute: ActivatedRoute,
    readonly employeePositionService: EmployeePositionService,
      private _location: Location,
      readonly datePipe: DatePipe,
    readonly router: Router
    ) 
    {
      this.solicitation = [
        {
          label: 'Yes',
          value: 'Y'
        },
        {
          label: 'No',
          value: 'N'
        }
  
      ];

    this.advanceSearch = {

      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId: "",
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: "",
      actualThrDate: "Equals",
      actualFromDAte: "Equals",
      estimatedFromDate: "Equals",
      estimatedThroughDate: "Equals"

    };
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.dateIdArray = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },

      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equal To',
        value: 'GreaterThanEqualTo'
      },
      {
        label: 'Less Than Equal To',
        value: 'LessThanEqualTo'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      }

    ];
    this.Flag = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      }

    ];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getStatus();
    this.getPartyId();
    this.getPositionType();
    this.getInternalOrganization();
   
    this.getEmployeePosition();
    this.createEmployeePositionService.addEmployee_position_form = null;
    this.createEmployeePositionService.addEmployeePositionForm(this.createEmployeePositionService.addEmployee_position_form);
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }

  getInternalOrganization() {
    this.spinner.show();
    this.addEmployeePositionService.getInternalOrganization().subscribe(res => {
      this.internalOrganization = res.data;
      this.spinner.hide();
      for (const value of this.internalOrganization) {
        this.internalArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }
     
    })
  }
  getStatus() {
    this.spinner.show();
    this.addEmployeePositionService.getStatus().subscribe(res => {
      this.status = res.data.emplPositionStatus;
      this.spinner.hide();
      for (const value of this.status) {
        this.statusArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  }
  getPositionType() {
    this.spinner.show();
    this.addEmployeePositionService.getPositionType().subscribe(res => {
      this.positionType = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.positionType) {
        this.positionArray.push({
          label: value.description,
          value: value.emplPositionTypeId
        })
      }
    })
  }
  onSubmit() {
    this.spinner.show();
    let partyid = this.createEmployeePositionService.addEmployee_position_form.get('internalOrganization').value;

    this.createEmployeePositionService.addEmployee_position_form.get('partyId').setValue(partyid);
    let fromdate = this.createEmployeePositionService.addEmployee_position_form.get('fromDate').value;
    this.estifromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");
    let throughdate = this.createEmployeePositionService.addEmployee_position_form.get('thruDate').value;
    this.estithroughdate = this.datePipe.transform(throughdate, "yyyy-MM-dd hh:mm:ss");

    let fromActualdate = this.createEmployeePositionService.addEmployee_position_form.get('actualFromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    let throughActualdate = this.createEmployeePositionService.addEmployee_position_form.get('actualThruDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");

    let formData = {
      "actualFromDate": this.actualfromdate,
      "actualThruDate": this.actualthroughdate,
      "budgetId": this.createEmployeePositionService.addEmployee_position_form.value.budgetId,
      "budgetItemSeqId": this.createEmployeePositionService.addEmployee_position_form.value.budgetItemSeqId,
      "empPositionId": this.createEmployeePositionService.addEmployee_position_form.value.empPositionId,
      "empPositionTypeId": this.createEmployeePositionService.addEmployee_position_form.value.empPositionTypeId,
      "exemptFlag": this.createEmployeePositionService.addEmployee_position_form.value.exemptFlag,
      "fromDate": this.estifromdate,
      "fulltimeFlag": this.createEmployeePositionService.addEmployee_position_form.value.fulltimeFlag,
      "partyId": this.createEmployeePositionService.addEmployee_position_form.value.partyId,
      "salaryFlag": this.createEmployeePositionService.addEmployee_position_form.value.salaryFlag,
      "statusId": this.createEmployeePositionService.addEmployee_position_form.value.statusId,
      "temporaryFlag": this.createEmployeePositionService.addEmployee_position_form.value.temporaryFlag,
      "thruDate": this.estithroughdate,
      "internalOrganization": this.createEmployeePositionService.addEmployee_position_form.value.internalOrganization
    }

  
    this.addEmployeePositionService.createEmployeePosition(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Form Submitted Successfully");
        this.getEmployeePosition();
        this.createEmployeePositionService.addEmployee_position_form.reset();
        this.closebutton.nativeElement.click();
        // this.router.navigate(['/hr/employee-position']);
      }

      else if (res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      this.spinner.hide();
    })


  }

  detail(id) {
    this.router.navigate(["/hr/employee-position/employee-position-details"], { queryParams: { positionId: id} })
  }

  onUpdate(id) {
    this.router.navigate(["hr/employee-position/add-employee-position"], { queryParams: { updateId: id } })
  }

  getEmployeePosition() {
    this.spinner.show();
    const emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId: "",
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.employeePositionService.getEmployeePosition(this.employessSize, emplPosition).subscribe((res: any) => {
      this.employeePosition = res.data;
      this.spinner.hide();
    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  reset() {

  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.employeePosition = [];
    
    const req = {
      "actualFromDateFrom": this.datePipe.transform(this.advanceSearch.actualFromDateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.actualFromDateFrom, "yyyy-MM-dd hh:mm:ss") : '',
      "actualFromDateTo": this.datePipe.transform(this.advanceSearch.actualFromDateTo, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.actualFromDateTo, "yyyy-MM-dd hh:mm:ss") : '',
      "actualThruDateFrom": this.datePipe.transform(this.advanceSearch.actualThruDateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.actualThruDateFrom, "yyyy-MM-dd hh:mm:ss") : '',
      "actualThruDateTo": this.datePipe.transform(this.advanceSearch.actualThruDateTo, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.actualThruDateTo, "yyyy-MM-dd hh:mm:ss") : '',
      "budgetId": this.advanceSearch.budgetId ? this.advanceSearch.budgetId : '',
      "budgetIdSearchType": this.advanceSearch.budgetIdSearchType ? this.advanceSearch.budgetIdSearchType : '',
      "budgetItemSeqId": this.advanceSearch.budgetItemSeqId ? this.advanceSearch.budgetItemSeqId : '',
      "budgetItemSeqIdSearchType": this.advanceSearch.budgetItemSeqIdSearchType ? this.advanceSearch.budgetItemSeqIdSearchType : '',
      "emplPositionId": this.advanceSearch.emplPositionId ? this.advanceSearch.emplPositionId : '',
      "emplPositionIdSearchType": this.advanceSearch.emplPositionIdSearchType ? this.advanceSearch.emplPositionIdSearchType : '',
      "emplPositionTypeId": this.advanceSearch.emplPositionTypeId ? this.advanceSearch.emplPositionTypeId : '',
      "emplPositionTypeIdSearchType": this.advanceSearch.emplPositionTypeIdSearchType ? this.advanceSearch.emplPositionTypeIdSearchType : '',
      "estimatedStartDateFrom": this.datePipe.transform(this.advanceSearch.estimatedStartDateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.estimatedStartDateFrom, "yyyy-MM-dd hh:mm:ss") : '',
      "estimatedStartDateTo": this.datePipe.transform(this.advanceSearch.estimatedStartDateTo, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.estimatedStartDateTo, "yyyy-MM-dd hh:mm:ss") : '',
      "estimatedThruDateFrom": this.datePipe.transform(this.advanceSearch.estimatedThruDateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.estimatedThruDateFrom, "yyyy-MM-dd hh:mm:ss") : '',
      "estimatedThruDateTo": this.datePipe.transform(this.advanceSearch.estimatedThruDateTo, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.estimatedThruDateTo, "yyyy-MM-dd hh:mm:ss") : '',
      "exemptFlag": this.advanceSearch.exemptFlag ? this.advanceSearch.exemptFlag : '',
      "fulltimeFlag": this.advanceSearch.fulltimeFlag ? this.advanceSearch.fulltimeFlag : '',
      "partyId": this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
      "partyIdSearchType": this.advanceSearch.partyIdSearchType ? this.advanceSearch.partyIdSearchType : '',
      "salaryFlag": this.advanceSearch.salaryFlag ? this.advanceSearch.salaryFlag : '',
      "statusId": this.advanceSearch.statusId ? this.advanceSearch.statusId : '',
      "statusIdSearchType": this.advanceSearch.statusIdSearchType ? this.advanceSearch.statusIdSearchType : '',
      "temporaryFlag": this.advanceSearch.temporaryFlag ? this.advanceSearch.temporaryFlag : '',



    }
    this.employeePositionService.getEmployeePosition(this.employessSize, req)
      .subscribe((res: any) => {
        if (res.success) {

          this.employeePosition = res.data;
          this.spinner.hide();

        } 
      });
     
  }
  resetFilter() {
    this.spinner.show();
    this.advanceSearch.actualFromDateFrom = "";
    this.advanceSearch.actualFromDateTo = "";
    this.advanceSearch.actualThruDateFrom = "";
    this.advanceSearch.actualThruDateTo = "";
    this.advanceSearch.budgetId = "";
    this.advanceSearch.budgetItemSeqId = "";
    this.advanceSearch.emplPositionId = "";
    this.advanceSearch.emplPositionTypeId = "";

    this.advanceSearch.estimatedStartDateFrom = "";
    this.advanceSearch.estimatedThroughDate = "";
    this.advanceSearch.estimatedThruDateFrom = "";
    this.advanceSearch.estimatedThruDateTo = "";
    this.advanceSearch.exemptFlag = "";
    this.advanceSearch.fulltimeFlag = "";
    this.advanceSearch.partyId = "";
    this.advanceSearch.salaryFlag = "";
    this.advanceSearch.statusId = "";
    this.advanceSearch.temporaryFlag = ""
    const emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId: "",
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.employeePositionService.getEmployeePosition(this.employessSize, emplPosition).subscribe((res: any) => {
      this.employeePosition = res.data;
      this.spinner.hide();
    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
