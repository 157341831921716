import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { WarehouseService } from '../warehouse/warehouse.service';
import * as moment from 'moment';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { faTrash, faEdit, faPrint } from '@fortawesome/free-solid-svg-icons';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { ProductService } from 'src/app/catalog-mgmt/new-products/product.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { WorkEffortService } from 'src/app/services/work-effort.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';

import Swal from 'sweetalert2'

declare const $;
@Component({
    selector: 'app-detailed-summary-warehouse',
    templateUrl: './detailed-summary-facilities.component.html',
    styleUrls: ['./detailed-summary-facilities.component.css']
})
export class DetailedSummaryFacilitiesComponent implements OnInit {
    @ViewChild('closebutton') closebutton;
    total=0;
    rowNumber=0;
    activeCategory:any = 1;
    pageSize = 10;
    rows = 50;
    faEdit = faEdit;
    faPrint = faPrint;
    faTrash = faTrash;
    facilityLocations: any[];
    facilityLocationForm: FormGroup;
    facilityId: string;
    receiveReturnNumber: string;
    facilityGroups: any[];
    facilityGroupForm: FormGroup;
    editModeGroupMember: boolean;
    facilityGroupMembers: any[];
    facilityGroupMember: any;
    facilityInventoryForm: FormGroup;
    statusIds: any[];
    categories: any[];
    products: any[];
    productsData: any[];
    productType: any[];
    inventoryTab: string;
    productIdName: string;
    inventories: any[];
    inventoryItems: any[];
    inventoryGrands: any[];
    inventoryAverageCosts: any[];
    opportunityIdArray = [
        {
            label: 'Contains',
            value: 'Contains'
        },
        {
            label: 'Equals',
            value: 'Equals'
        },
        {
            label: 'Is Empty',
            value: 'Is Empty'
        },
        {
            label: 'Begins With',
            value: 'Begins With'
        },
    ];
    greaterArray = [
        {
            label: 'Not Equal',
            text: 'NotEqual'
        }, {
            label: 'Equals',
            value: 'Equals'
        },
        {
            label: 'Same Day',
            value: 'SameDay'
        },
        {
            label: 'Greater Than From Day Start',
            value: 'GreaterThanFromDayStart'
        },
        {
            label: 'Greater Than',
            value: 'GreaterThan'
        }
    ];
    smallerArray = [
        {
            label: 'Less Than',
            value: 'LessThan'
        },
        {
            label: 'Up To Day',
            value: 'UpToDay'
        },
        {
            label: 'Up Through Day',
            value: 'UpThroughDay'
        },
        {
            label: 'Is Empty',
            value: 'IsEmpty'
        }
    ];
    partyIds: any[];
    facilityIds: any[];
    inventoryItemFindForm: FormGroup;
    inventoryItemsFind: any[];
    inventoryItemDetailsForm: FormGroup;
    workEfforts: any[];
    inventoryItemsDetails: any[];
    receiveInventoryForm: FormGroup;
    isProductIdSelected: boolean;
    receiveInventoryPreForm: FormGroup;
    suppliers: any[];
    facilityLocationBinding: any[];
    inventoryItemType: any[];
    physicalInventoryFind: {
        productId: string,
        internalName: string
    };
    physicalInventories: any[];
    inventoryTransferTab: string;
    inventoryTransfers: any;
    completeInventoryRequest: any[];
    orderIds: any[];
    shipments: any[];
    reasonEnumIds: any[];
    returnIds: any[];
    stockProcessForm: FormGroup;
    completeStockMoves: { facilityId: string, locationSeqId: string, productId: string, quantityMoved: string, targetLocationSeqId: string }[];
    stockMoveNeeded: any[];
    contactInformationList: any[];
    extraShipping = {
        handlingInstruction: '',
        additionalShipping: 0
    };
    isVerify: boolean;
    verifyPicks: any[];
    orderIdVerifyPick: string;
    Picklist: string;
    shipGroupSeqId: string;
    verifyProductId: string;
    quantity: string;
    bulkProductItem: any[];
    orderIdPacking: string;
    packingData: any;
    packItemProductId: string;
    quantityPack: string;
    isPack: boolean;
    quantityOnHandDiff: number = 0;
    productsID: any;
    dataProduct: any;
    productIdDescription: any;
    isInventoryPack: boolean;
    reportTypes = [
        {
            label: 'ByInventoryItem',
            value: 'BY_ITEM'
        },
        {
            label: 'ProductByProduct',
            value: 'BY_PRODUCT'
        },
        {
            label: 'ProductByDate',
            value: 'BY_DATE'
        }
    ];
    rowGroupMetaData: any;
    receiveProductsMasterValue: any[];
    orderForPicking: any[];
    searchPickingOrderForm: FormGroup;
    postalAddress: any;
    orderItemShipGroup: any;
    verifiedItems: any;
    verifiedNumber: any;
    isVerifyProduct: boolean;
    activePickList: any[];
    assignPickerList: any[];
    managePickList: any[];
    pickListStatusInfo: any[];
    pickListStatus: string;
    partyId: string;
    stockMove: any;
    stockComplete: any[] = [];
    packOrderValue = {
        orderId: '',
        shipGroupSeqId: ''
    };
    showContactModal: boolean = false;
    showInventoryModal: boolean = false;
    createFacilityLocationForm: FormGroup;
    createNewInventoryItemForm: FormGroup;
    inventoryItemId: any;
    LocationSeqId: any;
    LocationSeqIdArray: any[]=[];
    tabId: string;
    constructor(
        readonly _ActivatedRoute: ActivatedRoute,
        readonly spinner: NgxSpinnerService,
        readonly _FormBuilder: FormBuilder,
        readonly _WarehouseService: WarehouseService,
        readonly _ToastrService: ToastrService,
        readonly _MainCatalogService: MainCatalogService,
        readonly _CategoryService: CategoryService,
        readonly _ProductService: ProductService,
        readonly _GlobalResourceService: GlobalResourceService,
        readonly _WorkEffortService: WorkEffortService,
        readonly  _Router: Router,
        readonly accountsService: AccountsService,
    ) {
        this.facilityLocationForm = this._FormBuilder.group({
            aisle: [''],
            area: [''],
            level: [''],
            locationSeqId: [''],
            position: [''],
            section: ['']
        });

        this.createFacilityLocationForm = this._FormBuilder.group({
            aisle: [''],
            area: [''],
            level: [''],
            type: [''],
            position: [''],
            section: ['']
        });

        this.createNewInventoryItemForm = this._FormBuilder.group({
            inventoryItemID: [''],
            inventoryItemTypeID: [''],
            productID: [''],
            partyID: [''],
            ownerPartyID: [''],
            statusID: [''],
            dateTimeReceived: [''],
            dateTimeManufactured: [''],
            expireDate: [''],
            facilityId: [''],
            containerID: [''],
            lotID: [''],
            uomID: [''],
            binNumber: [''],
            facilityLocation: [''],
            comments: [''],
            accountingQuantityTotal: [''],
            serialNumber: [''],
            softIdentifier: [''],
            activationNumber: [''],
            activationValidThrough: [''],
            unitCost: [''],
            currency: [''],
            fixedAssetID: [''],
        });

        

        

        this.facilityGroupForm = this._FormBuilder.group({
            fromDate: [new Date()],
            sequenceNum: [''],
            thruDate: [new Date()],
            facilityGroupId: ['']
        });
        this.facilityId = '';
        this.facilityLocations = [];
        this.facilityGroups = [];
        this.facilityGroupMembers = [];
        this.facilityInventoryDefaultValue();
        this.inventoryItemDefaultValue();
        this.inventoryItemDetailsDefaultValue();
        this.receiveInventoryDefaultValue();
        this.physicalInventoryDefaultValue();
        this.inventoryTransferDefaultValue();
        this.stockInventoryDefaultValue();
        this.verifyPickDefaultValue();

        this.pickingDefaultValue();
    }
    facilityInventoryDefaultValue(): void {
        this.facilityInventoryForm = this._FormBuilder.group({
            facilityId: [''],
            fromDateSellThrough: [''],
            internalName: [''],
            monthsInPastLimit: [''],
            offsetATPQty: [''],
            offsetQOHQty: [''],
            productId: [''],
            productSupplierId: [''],
            productTypeId: [''],
            productsSoldThruTimestamp: [''],
            searchInProductCategoryId: [''],
            statusId: [''],
            thruDateSellThrough: [''],
            viewIndex: [0],
            viewSize: [20]
        });
        this.categories = [];
        this.products = [];
        this.productType = [];
        this.inventoryTab = 'inventory';
        this.inventories = [];
        this.inventoryItems = [];
        this.inventoryGrands = [];
        this.inventoryAverageCosts = [];
        this.partyIds = [];
        this.facilityIds = [];
    }
    inventoryItemDefaultValue(): void {
        this.inventoryItemFindForm = this._FormBuilder.group({
            datetimeReceivedFrom: [''],
            datetimeReceivedFromSearchType: ['GreaterThan'],
            datetimeReceivedTo: [''],
            datetimeReceivedToSearchType: ['LessThan'],
            facilityId: [''],
            internalName: [''],
            internalNameSearchType: ['Contains'],
            inventoryItemId: [''],
            inventoryItemIdSearchType: ['Contains'],
            lotId: [''],
            partyId: [''],
            productId: [''],
            productIdSearchType: ['Contains'],
            serialNumber: [''],
            serialNumberSearchType: ['Contains'],
            softIdentifier: [''],
            statusIds: [['']]
        });
        this.inventoryItemsFind = [];
    }
    inventoryItemDetailsDefaultValue(): void {
        this.inventoryItemDetailsForm = this._FormBuilder.group({
            effectiveDateFrom: [''],
            effectiveDateFromSearchType: ['Equals'],
            effectiveDateTo: [''],
            effectiveDateToSearchType: ['Equals'],
            facilityId: [''],
            inventoryItemId: [''],
            inventoryItemIdSearchType: ['Contains'],
            orderId: [''],
            productId: [''],
            productIdSearchType: ['Contains'],
            quantityOnHandDiff: [''],
            quantityOnHandDiffSearchType: ['NotEqual'],
            reasonEnumId: [''],
            returnId: [''],
            serialNumber: [''],
            serialNumberSearchType: ['Contains'],
            shipmentId: [''],
            softIdentifier: [''],
            workEffortId: ['']
        });
        this.inventoryItemsDetails = [];
        this.workEfforts = [];

    }
    receiveInventoryDefaultValue(): void {
        this.isProductIdSelected = false;
        this.receiveInventoryForm = this._FormBuilder.group({
            datetimeReceived: [new Date()],
            inventoryItemTypeId: ['NON_SERIAL_INV_ITEM'],
            itemDescription: [''],
            locationSeqId: [''],
            lotId: [''],
            ownerPartyId: [''],
            partyId: [''],
            productId: [''],
            purchaseOrderId: [''],
            quantityAccepted: 1,
            quantityRejected: 0,
            rejectionId: [''],
            unitCost: 0
        });
        this.receiveInventoryPreForm = this._FormBuilder.group({
            purchasedOrderNumber: [''],
            productId: ['']
        });
        this.suppliers = [];
        this.facilityLocationBinding = [];
        this.inventoryItemType = [];
        this.orderIds = [];
        this.shipments = [];
        this.reasonEnumIds = [];
        this.returnIds = [];
        this.receiveProductsMasterValue = [];
    }
    physicalInventoryDefaultValue(): void {
        this.physicalInventories = [];
        this.physicalInventoryFind = {
            productId: '',
            internalName: ''
        };
    }
    inventoryTransferDefaultValue(): void {
        this.inventoryTransferTab = '';
        this.inventoryTransfers = [];
        this.completeInventoryRequest = [];
    }
    stockInventoryDefaultValue(): void {
        this.stockProcessForm = this._FormBuilder.group({
            facilityId: [''],
            locationSeqId: [''],
            productId: [''],
            quantityMoved: [''],
            targetLocationSeqId: ['']
        });
        this.stockMoveNeeded = [];
        this.contactInformationList = [];
    }
    verifyPickDefaultValue(): void {
        this.bulkProductItem = [];
        this.isVerify = false;
        this.verifyPicks = [];
        this.orderIdVerifyPick = '';
        this.shipGroupSeqId = '';
        this.verifyProductId = '';
        this.quantity = '';
        this.orderIdPacking = '';
        this.packItemProductId = '';
        this.quantityPack = '';
        this.isPack = false;
    }
    pickingDefaultValue(): void {
        this.searchPickingOrderForm = this._FormBuilder.group({
            groupByNoOfOrderItems: [''],
            groupByShippingMethod: [''],
            groupByWarehouseArea: [''],
            maxNumberOfOrders: [50],
            orderId: ['']
        });
        this.activePickList = [];
        this.assignPickerList = [];
        this.managePickList = [];
        this.pickListStatusInfo = [];
        this.pickListStatus = '';
        this.partyId = '';
        this.isInventoryPack = false;
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.facilityId = this._ActivatedRoute.snapshot.queryParams.facilityId;
        this.tabId = this._ActivatedRoute.snapshot.queryParams.tabId;
        if(this.tabId == undefined){
            this.activeCategory=1;
        }
        else{
            this.activeCategory=this.tabId;
        }
        this._ActivatedRoute.queryParams.subscribe(value => {
            this.packOrderValue = {
                orderId: value.orderId,
                shipGroupSeqId: value.shipGroupSeqId
            };
            this.orderIdPacking = value.orderId;
            if (value.orderId) {
                this.packOrder();
                this.changeactiveCategoryFunction(14);
            }
        });
        this.getFacilityGroups();
        this.getFacilityLocation();
        this.getFacilityGroupMembers();
        this.getCategories();
        this.getProducts();
        this.getStatusIds();
        this.getProductType();
        this.getOrderIds();
        this.getShipmentIds();
        this.getReasonEnumIds();
        this.getReturnIds();
        this.getInventoryItemTotals();
        this.getInventoryItemGrands();
        this.getInventoryAverageCosts();
        this.getPartyIds();
        this.getFacilityIds();
        this.getWorkEfforts();
        this.getSuppliers();
        this.facilityLocationByFacility();
        this.defaultInventoryItemTypeId();
        this.getTransferInventory('activeOnly');
        this.getContactList();
        this.getstockMoves();
        this.getContactList();
        this.getManagePickList();
        this.getUpdateBinNumPickList();
        this.getAssignPickList();
        this.getLocationSeqId();

    }

    defaultInventoryItemTypeId(): void {
        this.spinner.show();
        this._WarehouseService.defaultInventory()
            .then(data => {
                this.spinner.hide();
                this.inventoryItemType = data.data;
            });
            
    }
    getPhysicalInventory(): void {
        this.spinner.show();
        this._WarehouseService.getPhysicalVarianceInventory(this.physicalInventoryFind.productId, this.physicalInventoryFind.internalName, this.facilityId)
            .then(data => {
                this.spinner.hide();
                this.physicalInventories = data.data.physicalInventory;
            });
           
    }
    createPhysical(inventoryItemId, facilityId, productId, internalName, availableToPromiseTotal, quantityOnHandTotal, productATP, productQOH) {
        this._Router.navigate(['/facilities/warehouse/update-inventory'], { queryParams: { inventoryItemId: inventoryItemId, facilityId: facilityId, productId: productId, internalName: internalName, availableToPromiseTotal: availableToPromiseTotal, quantityOnHandTotal: quantityOnHandTotal, productATP: productATP, productQOH: productQOH } });
    }
    getFacilityGroupMembers(): void {
        this.spinner.show();
        this._WarehouseService.getFacilityGroupMembers(this.facilityId, { pageNo: 1, pageSize: 200 })
            .then(data => {
                this.facilityGroupMembers = data.data;
                this.spinner.hide();
            });
            
    }
    getFacilityGroups(): void {
        this.spinner.show();
        this._WarehouseService.getFacilityGroups({ pageNo: 1, pageSize: 100 })
            .then(data => {
                this.facilityGroups = data.data;
                this.spinner.hide();
            });
           
    }
    getFacilityLocation(): void {
        this.spinner.show();
        this._WarehouseService.getFacilityLocations(this.facilityId, { pageNo: 1, pageSize: 100 }, this.facilityLocationForm.value)
            .then(data => {
                if (data.success) {
                    this.facilityLocations = data.data;
                    this.spinner.hide();
                }
            });
          
    }
    deleteFacilityLocation(locationSeqId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._WarehouseService.deleteFacilityLocation(this.facilityId, locationSeqId)
                .then(data => {
                    if (data.success) {
                        
                        this.getFacilityLocation();
                    }
                });
              
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
           
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })
      
           
    }

    // create inventory items code added by RA

    // createInventoryItem(): void {
    //     this.spinner.show();
    //     const requestData ={
    //         "accountingQuantityTotal": this.createNewInventoryItemForm.value.accountingQuantityTotal,
    //         "activationNumber": this.createNewInventoryItemForm.value.activationNumber,
    //         "activationValidThru": this.createNewInventoryItemForm.value.activationValidThrough,
    //         "binNumber": this.createNewInventoryItemForm.value.binNumber,
    //         "comments": this.createNewInventoryItemForm.value.comments,
    //         "containerId": this.createNewInventoryItemForm.value.containerID,
    //         "currencyUomId": this.createNewInventoryItemForm.value.currency,
    //         "datetimeManufactured": this.createNewInventoryItemForm.value.forumGroupDescription,
    //         "datetimeReceived":this.createNewInventoryItemForm.value.dateTimeReceived,
    //         "expireDate": this.createNewInventoryItemForm.value.expireDate,
    //         "facilityId": this.createNewInventoryItemForm.value.facilityId,
    //         "fixedAssetId": this.createNewInventoryItemForm.value.fixedAssetID,
    //         "inventoryItemTypeId":this.createNewInventoryItemForm.value.inventoryItemTypeID,
    //         "locationSeqId": this.createNewInventoryItemForm.value.facilityLocation,
    //         "lotId": this.createNewInventoryItemForm.value.lotID,
    //         "ownerPartyId": this.createNewInventoryItemForm.value.ownerPartyID,
    //         "partyId": this.createNewInventoryItemForm.value.partyID,
    //         "productId": this.createNewInventoryItemForm.value.productID,
    //         "serialNumber": this.createNewInventoryItemForm.value.serialNumber,
    //         "softIdentifier": this.createNewInventoryItemForm.value.softIdentifier,
    //         "statusId": this.createNewInventoryItemForm.value.statusID,
    //         "unitCost": this.createNewInventoryItemForm.value.unitCost,
    //         "uomId": this.createNewInventoryItemForm.value.uomID,
    //     }
    //      this.accountsService.createInventoryItem(requestData).subscribe((res: any) => { 
    //       if (res.success) {
    //         this._ToastrService.success("Create Successfully");
    //         this.spinner.hide();
    //         this.getInventoryItems();
    //         this.closebutton.nativeElement.click();
    //         this.createNewInventoryItemForm.reset();
    //       }
    //     }, (err) => {
    //       this.spinner.hide();
    //       if(err.error.errors){
    //       for (const value of err.error.errors) {
    //         this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
    //       }
    //       }
    //       else{
    //         this._ToastrService.error(err.error.message);
    //       }
    //     }); 
    //   }

    
    // createFacilityLocation(): void {
    //     this.spinner.show();
    //     const requestData ={
    //         "aisleId": this.createFacilityLocationForm.value.aisle,
    //         "areaId": this.createFacilityLocationForm.value.area,
    //         "levelId": this.createFacilityLocationForm.value.level,
    //         "locationSeqId": this.createFacilityLocationForm.value.type,
    //         "locationTypeEnumId": this.,
    //         "positionId": this.createFacilityLocationForm.value.position,
    //         "sectionId": this.createFacilityLocationForm.value.section,

    //     }
    //      this.accountsService.createFacilityLocation(this.facilityId,requestData,).subscribe((res: any) => { 
    //       if (res.success) {
    //         this._ToastrService.success("Create Successfully");
    //         this.spinner.hide();
    //         this.getInventoryItems();
    //         this.closebutton.nativeElement.click();
    //         this.createNewInventoryItemForm.reset();
    //       }
    //     }, (err) => {
    //       this.spinner.hide();
    //       if(err.error.errors){
    //       for (const value of err.error.errors) {
    //         this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
    //       }
    //       }
    //       else{
    //         this._ToastrService.error(err.error.message);
    //       }
    //     }); 
    //   }



      

    editableGroupMember(facilityGroupId: string, fromDate: string, thruDate: string, sequenceNum: string): void {
        this.editModeGroupMember = true;
        this.facilityGroupMember = { fromDate, sequenceNum, thruDate, facilityGroupId };
        this.facilityGroupForm.patchValue({
            ...this.facilityGroupMember,
            fromDate: new Date(fromDate),
            thruDate: thruDate ? new Date(thruDate) : new Date()
        });
        this.facilityGroupForm.controls.facilityGroupId.disable();
        this.facilityGroupForm.controls.facilityGroupId.updateValueAndValidity();
        this.facilityGroupForm.controls.fromDate.disable();
        this.facilityGroupForm.controls.fromDate.updateValueAndValidity();
    }
    deleteFacilityGroupMember(facilityGroupId: string, fromDate: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                const forDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');
                const newDate = encodeURIComponent(forDate);
                this._WarehouseService.deleteFacilityGroupMember(this.facilityId, facilityGroupId, newDate)
                    .then(data => {
                        if (data.success) {
                           
                            this.getFacilityGroupMembers();
                        }
                    });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
           
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })
        
            
    }
    submitFacilityGroupMember(): void {
        this.spinner.show();
        if (this.editModeGroupMember) {
            this._WarehouseService.updateFacilityGroupMember(this.facilityId, this.facilityGroupMember.facilityGroupId, {
                ...this.facilityGroupMember, ...{
                    fromDate: moment(this.facilityGroupMember.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                    thruDate: moment(this.facilityGroupForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss'),
                    sequenceNum: this.facilityGroupForm.value.sequenceNum
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.editModeGroupMember = false;
                        this.facilityGroupForm.controls.facilityGroupId.enable();
                        this.facilityGroupForm.controls.facilityGroupId.updateValueAndValidity();
                        this.facilityGroupForm.controls.fromDate.enable();
                        this.facilityGroupForm.controls.fromDate.updateValueAndValidity();
                        this.facilityGroupForm.reset();
                        this.getFacilityGroupMembers();
                    }
                });
               
        } else {
            this._WarehouseService.createFacilityGroupMember(this.facilityId, this.facilityGroupForm.value.facilityGroupId, {
                fromDate: moment(this.facilityGroupForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                thruDate: moment(this.facilityGroupForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss'),
                sequenceNum: this.facilityGroupForm.value.sequenceNum
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.facilityGroupForm.reset();
                        this.getFacilityGroupMembers();
                    }
                });
        }
    
    }
    cancelSubmit(){
        this._Router.navigate(["facilities/warehouse"])
      }
    onCancel() {
        this.facilityGroupForm.reset();
    }

    getCategories(): void {
        this.spinner.show();
        this._CategoryService.getCategories(
            { pageNo: 1, pageSize: 200 }, { categoryId: '', categoryIdSearchType: 'Contains', categoryName: '', categoryNameSearchType: 'Contains' })
            .then(data => {
                this.spinner.hide();
                this.categories = data.data.map(value => {
                    return {
                        label: value.categoryName,
                        value: value.productCategoryId
                    };
                });
            });
            
    }
    getProducts(): void {
        this.spinner.show();
        this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
            .then(data => {
                this.spinner.hide();
                this.receiveProductsMasterValue = data.data;
                this.products = data.data.map(value => {
                    return {
                        label: value.productId,
                        name: value.productName,
                        value: value.productId
                    };
                });
            });
           
    }
    getStatusIds(): void {
        this.spinner.show();
        this._WarehouseService.inventoryStatusId()
            .then(data => {
                this.spinner.hide();
                this.statusIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.statusId
                    };
                });
            });
        
    }
    getProductType(): void {
        this.spinner.show();
        this._ProductService.getProductMenu()
            .then(data => {
                this.spinner.hide();
                this.productType = data.data.productType.map(value => {
                    return {
                        label: value.description,
                        value: value.productTypeId
                    };
                });
            });
           
    }
    toggleInventoryTab(event: string): void {
        this.inventoryTab = event;
    }
    searchInventory(): void {
        this.spinner.show();
        this._WarehouseService.getFacilityInventories('Get', {
            ...this.facilityInventoryForm.value, ...{
                facilityId: this.facilityId,
                fromDateSellThrough: this.facilityInventoryForm.value.fromDateSellThrough ? moment(this.facilityInventoryForm.value.fromDateSellThrough).format('YYYY-MM-DD HH:mm:ss') : '',
                productsSoldThruTimestamp: this.facilityInventoryForm.value.productsSoldThruTimestamp ? moment(this.facilityInventoryForm.value.productsSoldThruTimestamp).format('YYYY-MM-DD HH:mm:ss') : '',
                thruDateSellThrough: this.facilityInventoryForm.value.thruDateSellThrough ? moment(this.facilityInventoryForm.value.thruDateSellThrough).format('YYYY-MM-DD HH:mm:ss') : '',
            }
        })
            .then(data => {
                if (data.data.results) {
                    this.spinner.hide();
                    this.inventories = data.data.results;
                }
            });
          
    }


    getInventoryItemTotals(): void {
        this.spinner.show();
        this._WarehouseService.getFacilityInventoryItem(this.facilityId, 'get')
            .then(data => {
                this.spinner.hide();
                this.inventoryItems = data.data.map(value => {
                    return {
                        ...value, ...{
                            costPrice: Number.parseFloat(value.costPrice).toFixed(2),
                            retailPrice: Number.parseFloat(value.retailPrice).toFixed(2),
                            totalCostPrice: Number.parseFloat(value.totalCostPrice).toFixed(2),
                            totalRetailPrice: Number.parseFloat(value.totalRetailPrice).toFixed(2)
                        }

                    };
                });
            });
           
    }

    dataChanged(e) {
        this.spinner.show();
        this.productsID = e;
        const eventus = e;
        this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
            .then(data => {
                this.dataProduct = data.data;
                this.spinner.hide();
                this.productsData = data.data.filter(eventus => {
                    return {
                        name: eventus
                    };
                });
                for (let i in this.dataProduct) {
                    if (this.dataProduct[i].productId == this.productsID) {
                        
                        this.productIdName = this.dataProduct[i].productName;
                        this.productIdDescription = this.dataProduct[i].description;
                    }
                }
            });
            
        console.clear();
        
    }
    getInventoryItemGrands(): void {
        this.spinner.show();
        this._WarehouseService.getFacilityGrandTotals(this.facilityId, 'get')
            .then(data => {
                this.inventoryGrands = data.data;
                this.spinner.hide();
            });
           
    }
    getInventoryAverageCosts(): void {
        this.spinner.show();
        this._WarehouseService.getInventoryAverageCosts(this.facilityId)
            .then(data => {
                this.inventoryAverageCosts = data.data;
                this.spinner.hide();
            });
           
    }

     generatePdfs(): void {
        this.spinner.show();
        this.accountsService.generatePdfs('Y',this.facilityId, ).subscribe(res => {
            const blob = new Blob([res], { type: 'application/application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
            link.download = `ViewFacilityInventoryByProductReport.pdf`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        }, (err) => {
            this.spinner.hide();
            if (err.error.errors) {
                err.error.errors.formEach(value => {
                    this._ToastrService.success(`${value.fieldName} ${value.fieldError}`)
                });
            }
        });
        
    }

    exportInventoryItems(): void {
        this.spinner.show();
        this._WarehouseService.exportInventoryItem(this.facilityId, 'Y').subscribe(res => {
            const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
            link.download = `${this.facilityId}.xlsx`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(function () {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        }, (err) => {
            this.spinner.hide();
            if (err.error.errors) {
                err.error.errors.formEach(value => {
                    this._ToastrService.success(`${value.fieldName} ${value.fieldError}`)
                });
            }
        });
        
    }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
            
    }
    getFacilityIds(): void {
        this.spinner.show();
        this._WarehouseService.getFacility()
            .then(data => {
                this.spinner.hide();
                this.facilityIds = data.data;
            });
           
    }
    navigateFormInventoryPage(productId: string): void {
        this.inventoryItemFindForm.patchValue({ productId });
        this.getInventoryItems();
        this.activeCategory = 4;
    }
    getInventoryItems(): void {
        this.spinner.show();
        this._WarehouseService.getInventoryItems({ pageNo: 1, pageSize: 200 }, {
            ...this.inventoryItemFindForm.value, ...{
                datetimeReceivedFrom: this.inventoryItemFindForm.value.datetimeReceivedFrom ? moment(this.inventoryItemFindForm.value.datetimeReceivedFrom).format('YYYY-MM-DD HH:mm:ss') : '',
                datetimeReceivedTo: this.inventoryItemFindForm.value.datetimeReceivedTo ? moment(this.inventoryItemFindForm.value.datetimeReceivedTo).format('YYYY-MM-DD HH:mm:ss') : '',
                facilityId: this.facilityId
            }
        })
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.inventoryItemsFind = data.data;
                }
            });
           
    }
    getWorkEfforts(): void {
        this.spinner.show();
        this._WorkEffortService.getWorkEfforts({ pageNo: 1, pageSize: 200 })
            .then(data => {
                this.spinner.hide();
                this.workEfforts = data.data;
            });
           
    }
    getInventoryItemDetails(): void {
        this.spinner.show();
        this._WarehouseService.getInventoryItemDetails({ pageNo: 1, pageSize: 100 }, {
            ...this.inventoryItemDetailsForm.value, ...{
                effectiveDateFrom: this.inventoryItemDetailsForm.value.effectiveDateFrom ? moment(this.inventoryItemDetailsForm.value.effectiveDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
                effectiveDateTo: this.inventoryItemDetailsForm.value.effectiveDateTo ? moment(this.inventoryItemDetailsForm.value.effectiveDateTo).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        })
            .then(data => {
                this.spinner.hide();
                this.inventoryItemsDetails = data.data;
                this.updateRowGroupMetaData();
            })
            .catch(error => {
                this.spinner.hide();
                if (error.errors) {
                    error.errors.forEach(value => {
                        this._ToastrService.success(value.fieldName);
                    });
                }
            });
           
    }
    updateRowGroupMetaData(): void {
        
        this.rowGroupMetaData = {};
        if (this.inventoryItemsDetails) {
            for (let i = 0; i < this.inventoryItemsDetails.length; i++) {
                const rowData = this.inventoryItemsDetails[i];
                const inventoryItemId = rowData.inventoryItemId;
                if (i === 0) {
                    this.rowGroupMetaData[inventoryItemId] = { index: 0, size: 1 };
                }
                else {
                    const previousRowData = this.inventoryItemsDetails[i - 1];
                    const previousRowGroup = previousRowData.inventoryItemId;
                    if (inventoryItemId === previousRowGroup) {
                        this.rowGroupMetaData[inventoryItemId].size++;
                    }
                    else {
                        this.rowGroupMetaData[inventoryItemId] = { index: i, size: 1 };
                    }
                }
            }
        }
    }
    scroll(el: HTMLElement, isShow: true, id: string): void {
        el.scrollIntoView({ behavior: 'smooth' });
        if (isShow) {
            $(`#${id}`).collapse('show');
            this.getInventoryItems();
        }
    }
    getOrderIds(): void {
        this.spinner.show();
        this._WarehouseService.getOrderIds()
            .then(data => {
                this.spinner.hide();
                this.orderIds = data.data.map(value => {
                    return {
                        label: value.orderId,
                        value: value.orderId
                    };
                });
            });
            
    }
    getShipmentIds(): void {
        this.spinner.show();
        this._WarehouseService.getShipmentIds()
            .then(data => {
                this.spinner.hide();
                this.shipments = data.data.map(value => {
                    return {
                        label: value.shipmentId,
                        value: value.shipmentId
                    };
                });
            });
          
    }
    getReasonEnumIds(): void {
        this.spinner.show();
        this._WarehouseService.getReasonEnumId()
            .then(data => {
                this.spinner.hide();
                this.reasonEnumIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.enumId
                    };
                });
            });
     
    }

    getLocationSeqId() {
        this.spinner.show();
        this.accountsService.getLocationSeqId().subscribe(res => {
          this.LocationSeqId = res.data.getFacilityId;
          this.spinner.hide();
          for (const value of this.LocationSeqId) {
            this.LocationSeqIdArray.push({
              label: value.locationSeqId,
              value: value.facilityId
            })
          }
        })
      }



    getReturnIds(): void {
        this.spinner.show();
        this._WarehouseService.getReturnIds()
            .then(data => {
                this.spinner.hide();
                this.returnIds = data.data.map(value => {
                    return {
                        label: value.returnHeaderTypeId,
                        value: value.returnId
                    };
                });
            });
          
    }
    receivingForm(): void {
        this.spinner.show();
        if (this.isProductIdSelected) {
            const productId = this.receiveInventoryForm.value.productId;
          
            this.receiveInventoryForm.patchValue({
                purchaseOrderId: this.receiveInventoryPreForm.value.purchasedOrderNumber
            });
          
            this._WarehouseService.receiveInventory(this.facilityId, {
                ...this.receiveInventoryForm.value, ...{
                    datetimeReceived: moment(this.receiveInventoryForm.value.datetimeReceived).format('YYYY-MM-DD HH:mm:ss')
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Received');
                        this.spinner.hide();
                        this.receiveInventoryForm.reset();
                        this._Router.navigate(['/facilities/warehouse/create-new-inventory-item'], { queryParams: { facilityId: this.facilityId, productId, inventoryItemId: data.data.inventoryItemId } });
                    }
                });
        } else {
            if (this.receiveInventoryPreForm.value.productId || this.receiveInventoryPreForm.value.purchasedOrderNumber) {
                this.receiveInventoryForm.patchValue({
                    productId: this.receiveInventoryPreForm.value.productId.productId,
                    itemDescription: this.receiveInventoryPreForm.value.productId.description ? this.receiveInventoryPreForm.value.productId.description : ''
                });
                this.spinner.hide();
                this.productIdName = this.receiveInventoryPreForm.value.productId.productName;
                this.isProductIdSelected = true;
            } else {
                this.spinner.hide();
                this._ToastrService.error('Please select productId or purchased order number');
            }
        }
       
    }
    getSuppliers(): void {

        this.spinner.show();
        this._WarehouseService.getSuppliers()
            .then(data => {
                this.spinner.hide();
                this.suppliers = data.data.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
            
    }
    facilityLocationByFacility(): void {
        this.spinner.show();
        this._WarehouseService.getFacilityLocations(this.facilityId, { pageNo: 1, pageSize: 100 }, {
            aisle: '',
            area: '',
            level: '',
            locationSeqId: '',
            position: '',
            section: ''
        })
            .then(data => {
                this.spinner.hide();
                this.facilityLocationBinding = data.data.map(value => {
                    return {
                        label: value.locationSeqId,
                        value: value.locationSeqId
                    };
                });
            });
          
    }
    getTransferInventory(event: any): void {
        this.spinner.show();
        this.inventoryTransferTab = event;
        if (event === 'activeOnly' || event === 'all') {
            this._WarehouseService.getInventoryItemTransfers(this.facilityId, event === 'activeOnly' ? true : false)
                .then(data => {
                    this.inventoryTransfers = data.data;
                });
        } else {
            this._WarehouseService.getCompleteInventoryItemTransfers(this.facilityId, event === 'activeOnly' ? false : true)
                .then(data => {
                    this.spinner.hide();
                    this.inventoryTransfers = data.data;
                });
        }
      
    }
    storeValueForCompleteRequest(inventoryItemId: string, inventoryTransferId: string, event: any): void {
        if (event.target.checked) {
            this.completeInventoryRequest.push({
                inventoryItemId,
                inventoryTransferId
            });
        } else {
            this.completeInventoryRequest.splice(this.completeInventoryRequest.findIndex(value => value.inventoryTransferId === inventoryTransferId));
        }
    }
    submitToCompleteTransfer(): void {
        this.spinner.show();
        this._WarehouseService.createCompleteTransfer(this.completeInventoryRequest)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.completeInventoryRequest = [];
                    this.getTransferInventory('activeOnly');
                }
            });
           
    }
    quickStockMove(): void {
        this.spinner.show();
        this.stockProcessForm.patchValue({
            facilityId: this.facilityId
        })
        this._WarehouseService.createQuickStockMove(this.stockProcessForm.value)
            .then(data => {
                if (data.success) {
                    if (data.data.warningMessageList.length != 0) {
                        this._ToastrService.error(data.data.warningMessageList[0]);
                        this.spinner.hide();
                    }
                    else {
                        this.spinner.hide();
                        this._ToastrService.success('Created')
                    }
                    this.stockProcessForm.reset();
                    this.getStockMoveNeeded();
                }
            });
           
    }
    getStockMoveNeeded(): void {
        this.spinner.show();
        this._WarehouseService.getStockMoveNeeded(this.facilityId)
            .then(data => {
                this.spinner.hide();
                this.stockMoveNeeded = [];
            });
           
    }
    initiateCompleteStockMoves(): void {

    }
    productValue(value) {

        let req = {
            facilityId: this.facilityId,
            locationSeqId: value.facilityLocationFrom.locationSeqId,
            productId: value.product.productId,
            quantityMoved: value.totalQuantity,
            targetLocationSeqId: value.targetProductFacilityLocation.locationSeqId
        }
        this.stockComplete.push(req)
    }
    productValueSelectAll(e) {
        this.stockComplete = []
        if (e.target.ariaChecked == true) {
            for (let r of this.stockMove) {
                let req = {
                    facilityId: this.facilityId,
                    locationSeqId: r.facilityLocationFrom.locationSeqId,
                    productId: r.product.productId,
                    quantityMoved: r.totalQuantity,
                    targetLocationSeqId: r.targetProductFacilityLocation.locationSeqId
                }
                this.stockComplete.push(req)
            }
        }
        else {
            this.stockComplete = []
        }
    }
    completeStockMove(): void {
        this.spinner.show();
        if (this.stockComplete.length) {

            this._WarehouseService.completeQuickStockMove(this.stockComplete)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Completed');
                        this.spinner.hide();
                        this.getstockMoves();
                        this.stockComplete = [];
                        this.completeStockMoves = [];
                    }
                });
        } else {
            this.spinner.hide();
            this._ToastrService.error('Select to proceed');
        }
       
    }
    expireContact(contactMechId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._WarehouseService.expireContactInformation(contactMechId, this.facilityId)
                .then(data => {
                    if (data.success) {
                      
                        this.getContactList();
                    }
                });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
           
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })
      
          
    }
    editContact(contactMechId: string): void {

        this._Router.navigate(['/facilities/warehouse/create-new-contact-information'], { queryParams: { facilityId: this.facilityId, contactMechId } });
    }
    getContactList(): void {
        this.spinner.show();
        this._WarehouseService.getFacilityContactInformationList(this.facilityId)
            .then(data => {
                this.spinner.hide();
                this.contactInformationList = data.data;
            });
           
    }
    verifyOrderNumber(): void {
        this.spinner.show();
        this._WarehouseService.verifyOrder(this.facilityId, this.orderIdVerifyPick, '')
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.verifyPicks = data.data.orderItems;
                    this.isVerify = true;
                    this.shipGroupSeqId = data.data.shipGroupSeqId;
                    this.postalAddress = data.data.postalAddress;
                    this.orderItemShipGroup = data.data.orderItemShipGroup
                }
            });
           
    }
    verifyOrderNumberPick(): void {
        this.spinner.show();
        this._WarehouseService.verifyOrderPick(this.facilityId, this.orderIdVerifyPick, '', this.Picklist)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.verifyPicks = data.data.orderItems;
                    this.isVerify = true;
                    this.shipGroupSeqId = data.data.shipGroupSeqId;
                    this.postalAddress = data.data.postalAddress;
                    this.orderItemShipGroup = data.data.orderItemShipGroup
                }
            });
           
    }
    verifySingleItem(): void {
        this.spinner.show();
        if (this.verifyProductId) {
            const data = {
                facilityId: this.facilityId,
                orderId: this.orderIdVerifyPick,
                productId: this.verifyProductId,
                quantity: this.quantity,
                shipGroupSeqId: this.shipGroupSeqId
            }
            this._WarehouseService.verifySingleItem(data)
                .then(data => {
                    if (data.success) {
                        this.isVerifyProduct = true;
                        this.verifiedItems = data.verifiedItems
                        this.verifiedNumber = data.totalVerifiedItems
                        this._ToastrService.success('verified');
                        this.spinner.hide();
                        this.verifyProductId = '';
                        this.quantity = '';
                    }
                })
                .catch(err => {
                    this.spinner.hide();
                    if (err.errors) {
                        err.errors.forEach(value => {
                            this._ToastrService.error(value.fieldName);
                        });
                    }
                });
        }
       
    }
    bulkItem(event: any, geoId: string, itemNumbr: string, productId: string, qtyToVerify: string): void {
        if (event.target.checked) {
            this.bulkProductItem.push({
                geoId,
                itemNumbr,
                productId,
                qtyToVerify
            });
        } else {
            this.bulkProductItem.splice(this.bulkProductItem.findIndex(value => value.itemNumbr === itemNumbr), 1);
        }
    }



    verifyBulkProductItem(): void {
        this.spinner.show();
        if (this.bulkProductItem.length) {
            this._WarehouseService.verifyBulkItem({
                bulkItems: this.bulkProductItem,
                facilityId: this.facilityId,
                orderId: this.orderIdVerifyPick,
                shipGroupSeqId: this.shipGroupSeqId

            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('verified');
                        this.spinner.hide();
                        this.bulkProductItem = [];
                    }
                })
                .catch(err => {
                    this.spinner.hide();
                    if (err.errors) {
                        err.errors.forEach(value => {
                            this._ToastrService.error(value.fieldName);
                        });
                    }
                });
        }
       
    }
    cancelBulkProductItem(): void {
        this.spinner.show();
        this._WarehouseService.cancelAllRows(this.facilityId, this.orderIdVerifyPick, this.shipGroupSeqId)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Cancelled');
                    this.spinner.hide();
                }
            })
            .catch(err => {
                this.spinner.hide();
                if (err.errors) {
                    err.errors.forEach(value => {
                        this._ToastrService.error(value.fieldName);
                    });
                }
            });
            
    }
    packOrder(): void {
        this.spinner.show();
        this._WarehouseService.packOrder(this.facilityId, this.orderIdPacking, this.packOrderValue.shipGroupSeqId)
            .then(data => {
                if (data.success) {
                    this.packingData = data.data;
                    if (data.data.packagesMap.packedLines) {
                        this.isInventoryPack = true;
                        this.spinner.hide();
                    }
                    this.isPack = true;
                }
            });

    }
    getstockMoves(): void {
        this.spinner.show();
        this._WarehouseService.getStockMove(this.facilityId)
            .then(data => {
                if (data.success) {
                    this.stockMove = data.data.moveByPflInfoList;
                    this.spinner.hide();

                }
            });
           
    }
    createPackItem(): void {
        this.spinner.show();
        this._WarehouseService.createPackItem(this.facilityId, {
            orderId: this.orderIdPacking,
            packageSeq: 0,
            productId: this.packItemProductId,
            quantity: this.quantityPack,
            shipGroupSeqId: this.packOrderValue.shipGroupSeqId
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Created');
                    this.spinner.hide();
                    this.packItemProductId = '';
                    this.quantityPack = '';
                    this.packOrder();
                }
            })
            .catch(err => {
                this.spinner.hide();
                if (err.errors) {
                    err.errors.forEach(value => {
                        this._ToastrService.error(value.fieldName);
                    });
                }
            });
          
    }
    bulkPackProcess(formData: any): void {
        this.spinner.show();
        this._WarehouseService.processBulkPack(this.facilityId, {
            orderId: this.packOrderValue.orderId,
            packBulkItemsForms: formData.map(value => {
                return {
                    iteToPack: value.orderItemSeqId,
                    numPackages: value.quantityOrdered,
                    pkgToPack: value.inputQty,
                    prdToPack: value.productId,
                    qtyToPack: value.inputQty,
                    sel: 'Y',
                    wgtToPack: ''
                };
            }),
            shipGroupSeqId: this.packOrderValue.shipGroupSeqId
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Bulk Data Process');
                    this.spinner.hide();
                    this.packOrder();
                }
            });
          
    }
    clearLinePack(inventoryItemId: string, orderItemSeqId: string, productId: string): void {
        this.spinner.show();
        this._WarehouseService.clearIndividualPack(this.facilityId, {
            inventoryItemId,
            orderId: this.packingData.orderHeader.orderId,
            orderItemSeqId,
            packageSeqId: this.packingData.packageSeq,
            productId,
            shipGroupSeqId: this.packOrderValue.shipGroupSeqId
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Bulk Data Process');
                    this.spinner.hide();
                    this.packOrder();
                }
            });
           
    }
    completePack(): void {
        this.spinner.show();
        this._WarehouseService.completePack(this.facilityId, {
            additionalShippingCharge: this.extraShipping.additionalShipping,
            carrierPartyId: this.packingData.orderItemShipGroup.carrierPartyId,
            carrierRoleTypeId: this.packingData.orderItemShipGroup.carrierRoleTypeId,
            facilityId: this.facilityId,
            forceComplete: false,
            handlingInstructions: this.extraShipping.handlingInstruction,
            orderId: this.packOrderValue.orderId,
            packageWeights: [],
            productStoreId: this.packingData.productStoreId,
            shipGroupSeqId: this.packOrderValue.shipGroupSeqId,
            shipmentMethodTypeId: this.packingData.orderItemShipGroup.shipmentMethodTypeId,
            shippingContactMechId: this.packingData.postalAddressMap.postalAddress.contactMechId,
            weightUomId: this.packingData.defaultWeightUomId
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Complete');
                    this.spinner.hide();
                    this.isPack = false;
                }
            });
         
    }
    clearAllPack(): void {
        this.spinner.show();
        this._WarehouseService.clearAllPack(this.facilityId, this.packOrderValue.orderId, this.packOrderValue.shipGroupSeqId)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Cleared');
                    this.spinner.hide();
                    this.packOrder();
                }
            });
          
    }
    searchOrderPicking(): void {
        this.spinner.show();
        this._WarehouseService.gteOrdersForPicking(this.facilityId, {
            ...this.searchPickingOrderForm.value, ...{
                groupByNoOfOrderItems: this.searchPickingOrderForm.value.groupByNoOfOrderItems ? this.searchPickingOrderForm.value.groupByNoOfOrderItems[0] : '',
                groupByShippingMethod: this.searchPickingOrderForm.value.groupByShippingMethod ? this.searchPickingOrderForm.value.groupByShippingMethod[0] : '',
                groupByWarehouseArea: this.searchPickingOrderForm.value.groupByWarehouseArea ? this.searchPickingOrderForm.value.groupByWarehouseArea[0] : ''
            }
        })
            .then(data => {
                this.orderForPicking = (typeof data.data.pickMoveInfoList === 'string') ? [] : data.data.pickMoveInfoList;
            });
            this.spinner.hide();
    }


    createPickList(orderId: string): void {
        this.spinner.show();
        if (orderId) {
            this._WarehouseService.createPickList(this.facilityId, {
                groupByNoOfOrderItems: '',
                groupByShippingMethod: '',
                groupByWarehouseArea: '',
                orderIdList: [
                    orderId
                ]
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Picking Created Successfully');
                        this.spinner.hide();
                        this.searchOrderPicking();
                    }
                });
        }
       
    }
    printPickSheet(orderId: string): void {
        this.spinner.show();
        this._WarehouseService.printPicking(this.facilityId,orderId, 1)
            .subscribe(value => {
                const blob = new Blob([value], { type: 'application/application/pdf' });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }
                const data = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = data;
                this.spinner.hide();
                link.download = `${orderId}.pdf`;
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                setTimeout(() => {
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
            }, (err) => {
                this.spinner.hide();
                if (err.error.errors) {
                    for (const value of err.error.errors) {
                        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
                    }
                }
            });
            
    }


    getManagePickList(): void {
        this.spinner.show();
        this._WarehouseService.managePickList(this.facilityId, { pageNo: 1, pageSize: 200 })
            .then(data => {
                if (data.data && data.data.picklistInfoList.length) {
                    this.managePickList = data.data.picklistInfoList;
                    this.pickListStatusInfo = data.data.picklistInfoList.map(value => {
                        return {
                            label: `[${value.picklist.picklistId}] ${moment(value.picklist.createdDate).format('YYYY-MM-DD HH:mm:ss')} ${value.picklist.statusId}`,
                            value: value.picklist.picklistId
                        }
                    });
                }
            });
            this.spinner.hide();
    }
    // PICKLIST_INPUT
    setStatus(event: any): void {
        this.pickListStatus = event.value.statusId;
    }
    printPickPackReport(pickListId: string): void {
        this.spinner.show();
        this._WarehouseService.pickListReport(pickListId)
            .subscribe(value => {
                const blob = new Blob([value], { type: 'application/application/pdf' });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }
                const data = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = data;
                this.spinner.hide();
                link.download = `${pickListId}.pdf`;
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                setTimeout(() => {
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
            }, (err) => {
                this.spinner.hide();
                if (err.error.errors) {
                    for (const value of err.error.errors) {
                        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
                    }
                }
            });
          
    }
    pickListStatusUpdate(pickListId: string): void {
        this.spinner.show();
        this._WarehouseService.updatePickListStatus({
            facilityId: this.facilityId,
            picklistId: pickListId,
            statusId: this.pickListStatus
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Status Updated');
                    this.spinner.hide();
                    this.getManagePickList();
                    this.pickListStatus = '';
                }
            });
           
    }
    setPartyId(event: any): void {
        this.partyId = event.value;
    }
    createAssignPickList(pickListId: string): void {
        this.spinner.show();
        this._WarehouseService.createAssignPicker({
            facilityId: this.facilityId,
            picklistId: pickListId,
            partyId: this.partyId,
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Added');
                    this.spinner.hide();
                    this.getManagePickList()
                    this.partyId = '';
                }
            });
         
    }


    deletePickListOrderId(inventoryItemId: string, orderId: string, orderItemSeqId: string, picklistBinId: string, shipGroupSeqId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._WarehouseService.deletePickList({
                    facilityId: this.facilityId,
                    inventoryItemId,
                    orderId,
                    orderItemSeqId,
                    picklistBinId,
                    shipGroupSeqId
                })
                    .then(data => {
                        if (data.success) {
                           
                            this.getManagePickList();
                        }
                    });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
           
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })
      
           
    }
    updatePickListBinItem(binLocationNumber: string, picklistBinId: string, picklistId: string): void {
        this.spinner.show();
        this._WarehouseService.updatePickListBin({
            binLocationNumber,
            facilityId: this.facilityId,
            picklistBinId,
            picklistId
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                }
            });
           
    }
    getUpdateBinNumPickList(): void {
        this.spinner.show();
        this._WarehouseService.getActivePickList(this.facilityId)
            .then(data => {
                this.spinner.hide();
                this.activePickList = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.picklistId
                    };
                });
            });
          
    }


    getAssignPickList(): void {
        this.spinner.show();
        this._WarehouseService.assignPicker()
            .then(data => {
                this.spinner.hide();
                this.assignPickerList = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.partyId
                    };
                });
            });
           
    }

    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    receiveReturn() {
        this._Router.navigate(['/facilities/warehouse/view-retuen'], { queryParams: { facilityId: this.facilityId, receiveReturnNumber: this.receiveReturnNumber, } });
    }

    detailInventoryItem(facilityId,inventoryItemId)
    {
        this._Router.navigate(['/facilities/warehouse/detail-inventory-page'], { queryParams: { facilityId:facilityId,inventoryItemId:inventoryItemId, } });

    }

    toSerachLabelPage()
    {
        this._Router.navigate(['/facilities/warehouse/search-by-label']);

    }
    changeactiveCategoryFunction(tab: number) {
        this.activeCategory = tab;
        if (tab === 3 || tab === 4) {
            this.inventoryItemFindForm.patchValue({ productId: '' });
            this.getInventoryItems();
        }
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
    openModal(){
        this.showContactModal = true;
    }
    InventoryModal(){
        this.showInventoryModal = true;
    }
    onModalClosed(): void {
        this.showContactModal = false;
    }
    InventoryModalClosed(){
        this.showInventoryModal = false;
    }
}
