<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Customer</span>
                <span class="color-black pl-1"> > Update</span>
                <button class="btn btn-danger" style="margin-left: 76%;" (click)="reset()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Update Customer Agreement</a></li>
                
                    </ul>
                  </div>

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="updateCustomerAgreements">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Agreement Id</label>
                                            <div class="">
                                                <input type="text" class="form-control" formControlName="agreementId"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Agreement Id" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Product ID</label>
                                            <div class="">
                                                <p-dropdown [options]="productList" optionlabel="productId"
                                                    filter="true" placeholder="Select" formControlName="productId"
                                                    placeholder="Select Product Id">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Party Id From</label>
                                            <div class="">
                                                <p-dropdown [options]="PersonsAndPartyGroupsList" optionlabel="partyId"
                                                    placeholder="Select Party Id" filter="true" placeholder="Select"
                                                    formControlName="partyIdFrom">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Party Id To</label>
                                            <div class="">
                                                <p-dropdown [options]="PersonsAndPartyGroupsList" optionlabel="partyId"
                                                    placeholder="Select party Id" filter="true" placeholder="Select"
                                                    formControlName="partyIdTo">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Role Type Id From</label>
                                            <div class="">
                                                <p-dropdown [options]="RoleTypesList" optionlabel="description"
                                                    placeholder="Select Role Type Id" filter="true" placeholder="Select"
                                                    formControlName="roleTypeIdFrom">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Role Type Id To</label>
                                            <div class="">
                                                <p-dropdown [options]="RoleTypesList" optionlabel="description"
                                                    placeholder="Select Role Type Id" filter="true" placeholder="Select"
                                                    formControlName="roleTypeIdTo">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Agreement Type ID</label>
                                            <div class="">
                                                <p-dropdown [options]="AgreementTypeList" optionlabel="agreementTypeId"
                                                    filter="true" placeholder="Select"
                                                    formControlName="agreementTypeId">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <div class="d-flex flex-column">
                                                <label for="exampleInputEmail1">Agreement Date</label>
                                                <p-calendar formControlName="agreementDate"></p-calendar>
                                              
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <div class="d-flex flex-column">
                                                <label for="exampleInputEmail1">From Date</label>
                                                <p-calendar formControlName="fromDate"></p-calendar>
                                               
                                            </div>
                                        </div>
                                    </div>



                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <div class="d-flex flex-column">
                                                <label for="exampleInputEmail1">Through Date</label>
                                                <p-calendar formControlName="thruDate"></p-calendar>
                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="examCreate Commission Agreement
                                            pleInputEmail1">Description</label>
                                            <input type="text" class="form-control" formControlName="description"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Description">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Text Data</label>
                                            <div>
                                                <textarea name="" id="exampleInputEmail1" formControlName="textData"
                                                    placeholder="Text Data" class="form-control" cols="30"
                                                    rows="10"></textarea>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button ">
                                    <button _ngcontent-cta-c128="" type="submit" (click)="submit()"
                                        class="btn btn-secondary submit-btn">Update</button>
                                   
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 