import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class CreatePaymentServices {

  constructor(private http: HttpClient) { }
 
  getPaymentTypeOutgoing(): Observable<any> {
    const getPaymentTypeOutgoing = URLS.getPaymentTypeOutgoing;
    return this.http.get(getPaymentTypeOutgoing);
  }
  getInvoiceTypeAndStatus(): Observable<any> {
    const getInvoiceTypeAndStatus = URLS.getInvoiceTypeAndStatus;
    return this.http.get(getInvoiceTypeAndStatus);
  }
  getTermTypeIds(): Observable<any> {
    const getTermTypeIds = URLS.getTermTypeIds;
    return this.http.get(getTermTypeIds);
  }
  updateBillingAccount(fromData): Observable<any> {
    const updateBillingAccount = URLS.updateBillingAccount;
    return this.http.put(updateBillingAccount, fromData);
  }
  getRoleList(invoiceId):Observable<any>{
    const getRoleList = URLS.getRoleList.replace(":invoiceId",invoiceId) ;
    return this.http.get(getRoleList);
  }
  getAppliedApplications(invoiceId):Observable<any>{
    const getAppliedApplications = URLS.getAppliedApplications.replace(":invoiceId",invoiceId) ;
    return this.http.get(getAppliedApplications);
  }
  getInvoiceTermById(invoiceTermId):Observable<any>{
    const getInvoiceTermById = URLS.getInvoiceTermById.replace(":invoiceTermId",invoiceTermId) ;
    return this.http.get(getInvoiceTermById);
  }
  getPaymentAccoundId(orgPartyId): Observable<any> {
    const paymentMethodType = URLS.getPaymentMethodType + "?organizationPartyId=" + orgPartyId;
    return this.http.get(paymentMethodType);
  }
  updatePaymentToInvoice(form,invoiceId,paymentApplicationId):Observable<any>{
    const updatePaymentToInvoice = URLS.updatePaymentToInvoice.replace(":invoiceId",invoiceId).replace(":paymentApplicationId",paymentApplicationId) ;
    return this.http.put(updatePaymentToInvoice,form);
  }
  updateInvoiceTerm(form,invoiceId):Observable<any>{
    const updateInvoiceTerm = URLS.updateInvoiceTerm.replace(":invoiceId",invoiceId) ;
    return this.http.put(updateInvoiceTerm,form);
  }
  getFromPartyId(): Observable<any> {
    const getpartyId = URLS.getPersonsAndPartyGroups;
    return this.http.get(getpartyId);
  }
  createInvoiceRole(form,invoiceId):Observable<any>{
    const createInvoiceRole = URLS.createInvoiceRole.replace(":invoiceId",invoiceId) ;
    return this.http.post(createInvoiceRole,form);
  }
  copyInvoice(form):Observable<any>{
    const copyInvoice = URLS.copyInvoice ;
    return this.http.post(copyInvoice,form);
  }
  createPaymentIncoming(form):Observable<any>{
    const createPaymentIncoming = URLS.createPaymentIncoming ;
    return this.http.post(createPaymentIncoming,form);
  }
  createSalesInvoice(formData): Observable<any> {
    const createSalesInvoice = URLS.createSalesInvoice ;
    return this.http.post(createSalesInvoice, formData);
  }
  copyInvoiceToTemplate(form):Observable<any>{
    const copyInvoiceToTemplate = URLS.copyInvoiceToTemplate;
    return this.http.post(copyInvoiceToTemplate,form);
  }
  getPaymentType(): Observable<any> {
    const paymentMode = URLS.getPaymentType;
    return this.http.get(paymentMode);
  }
  sendInvoicePerMail(form,invoiceId):Observable<any>{
    const sendInvoicePerMail = URLS.sendInvoicePerMail.replace(":invoiceId",invoiceId) ;
    return this.http.post(sendInvoicePerMail,form);
  }
  updateSalesInvoiceHeader(form,invoiceId):Observable<any>{
    const updateSalesInvoiceHeader = URLS.updateSalesInvoiceHeader.replace(":invoiceId",invoiceId) ;
    return this.http.put(updateSalesInvoiceHeader,form);
  }
  createInvoiceTerm(form,invoiceId):Observable<any>{
    const createInvoiceTerm = URLS.createInvoiceTerm.replace(":invoiceId",invoiceId) ;
    return this.http.post(createInvoiceTerm,form);
  }
  deleteInvoiceTerm(form,invoiceId):Observable<any>{
    const deleteInvoiceTerm = URLS.deleteInvoiceTerm.replace(":invoiceId",invoiceId) ;
    return this.http.post(deleteInvoiceTerm,form);
  }
  applyPaymentToInvoice(form,invoiceId):Observable<any>{
    const applyPaymentToInvoice = URLS.applyPaymentToInvoice.replace(":invoiceId",invoiceId) ;
    return this.http.post(applyPaymentToInvoice,form);
  }
  deleteInvoiceItem(invoiceId,invoiceItemSeqId):Observable<any>{
    const deleteInvoiceItem = URLS.deleteInvoiceItem.replace(":invoiceId",invoiceId).replace(":invoiceItemSeqId",invoiceItemSeqId) ;
    return this.http.delete(deleteInvoiceItem);
  }
  updateInvoiceItem(form,invoiceId,invoiceItemSeqId):Observable<any>{
    const updateInvoiceItem = URLS.updateInvoiceItem.replace(":invoiceId",invoiceId).replace(":invoiceItemSeqId",invoiceItemSeqId) ;
    return this.http.put(updateInvoiceItem,form);
  }
  createInvoiceItem(form,invoiceId):Observable<any>{
    const createInvoiceItem = URLS.createInvoiceItem.replace(":invoiceId",invoiceId) ;
    return this.http.post(createInvoiceItem,form);
  }
  updateBillingAccountMypayments(form,partyId, billingAccountId):Observable<any>{
    const updateBillingAccountMypayments = URLS.updateBillingAccountMypayments.replace(":partyId",partyId).replace(":billingAccountId",billingAccountId) ;
    return this.http.put(updateBillingAccountMypayments,form);
  }
 
  getInvoiceItemType():Observable<any>{
    const getInvoiceItemType = URLS.getInvoiceItemType;
    return this.http.get(getInvoiceItemType);
  }
  getInvoiceItems(invoiceId):Observable<any>{
    const getInvoiceItems = URLS.getInvoiceItems.replace(":invoiceId",invoiceId);
    return this.http.get(getInvoiceItems);
  }
  getInvoiceItemById(invoiceId,invoiceItemSeqId):Observable<any>{
    const getInvoiceItemById = URLS.getInvoiceItemById.replace(":invoiceId",invoiceId).replace(":invoiceItemSeqId",invoiceItemSeqId) ;
    return this.http.get(getInvoiceItemById);
  }
  getInvoiceTerms(invoiceId,pageSize,pageNo):Observable<any>{
    const getInvoiceTerms = URLS.getInvoiceTerms.replace(":invoiceId",invoiceId) + "?pageSize=" + pageSize + "&pageNo=" + pageNo;
    return this.http.get(getInvoiceTerms);
  }
  getRoleListMyPayment(invoiceId):Observable<any>{
    const getRoleListMyPayment = URLS.getRoleListMyPayment.replace(":invoiceId",invoiceId);
    return this.http.get(getRoleListMyPayment);
  }
  getInvoiceSummary(invoiceId):Observable<any>{
    const getInvoiceSummary = URLS.getInvoiceSummary.replace(":invoiceId",invoiceId);
    return this.http.get(getInvoiceSummary);
  }
  setInvoiceStatus(id,sid): Observable<any> {
    const setInvoiceStatus = URLS.setInvoiceStatus + "?invoiceId=" + id+ "&statusId=" + sid;
    return this.http.put(setInvoiceStatus,"");
  }
  EditBillingAccount(id): Observable<any> {
    const EditBillingAccount = URLS.EditBillingAccount + "?billingAccountId=" + id;
    return this.http.get(EditBillingAccount);
  }


  getPaymentMethodId(): Observable<any> {
    const getPaymentMethodId = URLS.getPaymentMethodId;
    return this.http.get(getPaymentMethodId);
  }
  getFinAccountId(): Observable<any> {
    const getFinAccountId = URLS.getFinAccountId;
    return this.http.get(getFinAccountId);
  }
  applyApplicationPayment(formData): Observable<any> {
    const applyApplicationPayment = URLS.applyApplicationPayment ;
    return this.http.post(applyApplicationPayment, formData);
  }
 
  removePaymentApplication(formData): Observable<any> {
    const removePaymentApplication = URLS.removePaymentApplication ;
    return this.http.post(removePaymentApplication, formData);
  }
  updateInvoiceApplication(formData): Observable<any> {
    const updateInvoiceApplication = URLS.updateInvoiceApplication ;
    return this.http.put(updateInvoiceApplication, formData);
  }
  findInvoices(formData): Observable<any> {
    const findInvoices = URLS.findInvoices ;
    return this.http.post(findInvoices, formData);
  }
  updatePayment(formData): Observable<any> {
    const updatePayment = URLS.updatePayment ;
    return this.http.post(updatePayment, formData);
  }
  getPaymentList(data, formData): Observable<any> {
    const getPaymentsList = URLS.getPayments + "?pageSize=" + data.pageSize + "&pageNo=" + data.pageNo;
    return this.http.post(getPaymentsList, formData);
  }
  EditBillingAccountDrop(): Observable<any> {
    const EditBillingAccountDrop = URLS.EditBillingAccountDrop;
    return this.http.get(EditBillingAccountDrop);
  }
  EditInvoice(id): Observable<any> {
    const EditInvoice = URLS.EditInvoice + "?invoiceId=" + id;
    return this.http.get(EditInvoice);
  }
 
  editInvoiceTimeEntries(id): Observable<any> {
    const editInvoiceTimeEntries = URLS.editInvoiceTimeEntries + "?invoiceId=" + id;
    return this.http.get(editInvoiceTimeEntries);
  }
  getapplyApplicationPayment(id): Observable<any> {
    const getapplyApplicationPayment = URLS.getapplyApplicationPayment + "?paymentId=" + id;
    return this.http.get(getapplyApplicationPayment);
  }
  unlinkInvoiceFromTimeEntrys(formData): Observable<any> {
    const unlinkInvoiceFromTimeEntry = URLS.unlinkInvoiceFromTimeEntry;
    return this.http.post(unlinkInvoiceFromTimeEntry,formData);
  }
  InvoiceAcctgTransEntriesPdf(id): Observable<any> {
    const InvoiceAcctgTransEntriesPdf = URLS.InvoiceAcctgTransEntriesPdf  + "?invoiceId=" + id   ;
    return this.http.post(InvoiceAcctgTransEntriesPdf, { responseType: "blob" });
  }
  generatePdfMyPortal(id): Observable<any> {
    const generatePdfMyPortal = URLS.generatePdfMyPortal  + "?invoiceId=" + id   ;
    return this.http.get(generatePdfMyPortal, { responseType: "blob" });
  }
  generatePdfInvoice(id): Observable<any> {
    const generatePdfInvoice = URLS.generatePdfInvoice  + "?invoiceId=" + id   ;
    return this.http.post(generatePdfInvoice, { responseType: "blob" });
  }
  generateChecksPDFNew(id): Observable<any> {
    const generateChecksPDFNew = URLS.generateChecksPDFNew  + "?paymentId=" + id   ;
    return this.http.post(generateChecksPDFNew, { responseType: "blob" });
  }
  getInvoiceId(): Observable<any> {
    const getInvoiceId = URLS.getInvoiceId;
    return this.http.get(getInvoiceId);
  }
  deletePaymentToInvoice(paymentApplicationId): Observable<any> {
    const deletePaymentToInvoice = URLS.deletePaymentToInvoice.replace(":paymentApplicationId",paymentApplicationId);
    return this.http.delete(deletePaymentToInvoice);
  }
  deleteInvoiceRole(roleTypeId,invoiceId,partyId): Observable<any> {
    const deleteInvoiceRole = URLS.deleteInvoiceRole.replace(":roleTypeId",roleTypeId).replace(":invoiceId",invoiceId).replace(":partyId",partyId) ;
    return this.http.delete(deleteInvoiceRole);
  }
  getPaymentApplicationList(paymentId): Observable<any> {
    const getPaymentApplicationList = URLS.getPaymentApplicationList.replace(":paymentId",paymentId);
    return this.http.get(getPaymentApplicationList);
  }
  getInvoiceApplicationList(paymentId): Observable<any> {
    const getInvoiceApplicationList = URLS.getInvoiceApplicationList.replace(":invoiceId",paymentId);
    return this.http.get(getInvoiceApplicationList);
  }
  getPaymentDetailsById(paymentId): Observable<any> {
    const getPaymentsById = URLS.getPaymentsById.replace(":paymentId",paymentId);
    return this.http.get(getPaymentsById);
  }
  createPayment(formValues): Observable<any> {
   
    const createIncomingPayment = URLS.createIncomingPayment;
    return this.http.post(createIncomingPayment,formValues);
  }

  getCurrency():Observable<any> {
    const getCurrency = URLS.getCurrency;
    return this.http.get(getCurrency);
  }

}
