<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Add New Employee</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <!-- <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;">Parties
                </span>
                <span class="color-black pl-1"> >Add New Employee
                </span>
                <button type="submit" class="btn btn-danger buttonclass" (click)="GoToQuickLink()">Cancel</button>&nbsp;
            </div> -->
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" style="margin-top: 1%;">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12 list_item_mobile">
                                <div class="list_item_mobile_div">
                                    <ul class="tabbing-section tabbing-accordians tabClass">
                                        <li [ngClass]="activeCategory==1?'active':''"
                                        (click)="changeactiveCategoryFunction(1)"><a>
                                            General Information
                                            </a></li>
                                        <li [ngClass]="activeCategory==2?'active':''" (click)="Address(this.createNewProspect.value.firstName, this.createNewProspect.value.lastName)"
                                        ><a>
                                                Mailing/Shipping Address
                                            </a></li>
                                        <li [ngClass]="activeCategory==3?'active':''" 
                                        (click)="mailAddress(this.createNewProspect.value.userAddress1
                                        ,this.createNewProspect.value.userCity,this.createNewProspect.value.userZip)"><a>
                                                Home phone
                                            </a></li>
                                        <li [ngClass]="activeCategory==4?'active':''"
                                        (click)="homeAddress(this.createNewProspect.value.homeCountry
                                        ,this.createNewProspect.value.homeArea,
                                        this.createNewProspect.value.homePhone,this.createNewProspect.value.homeSolic)"><a>
                                                Work Phone Number
                                            </a></li>
                                        <li [ngClass]="activeCategory==5?'active':''"
                                        (click)="workAddress(this.createNewProspect.value.workCountry
                                        ,this.createNewProspect.value.workArea,
                                        this.createNewProspect.value.workPhone,this.createNewProspect.value.workSolic)"><a>
                                                Fax Number
                                            </a></li>
                                        <li [ngClass]="activeCategory==6?'active':''"
                                        (click)="faxAddress(this.createNewProspect.value.faxCountry
                                        ,this.createNewProspect.value.faxArea,
                                        this.createNewProspect.value.faxPhone,this.createNewProspect.value.faxSolict)"><a>
                                                Mobile Phone Number
                                            </a></li>
                                        <li [ngClass]="activeCategory==7?'active':''"
                                        (click)="mobileAddress(this.createNewProspect.value.mobileCountry
                                        ,this.createNewProspect.value.mobileArea,
                                        this.createNewProspect.value.mobilePhone,this.createNewProspect.value.mobileSolict)"><a>
                                                E-Mail Address
                                            </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                    <div class="" style="margin-bottom: 11%;">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="createNewProspect">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                      
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">First name<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter First name"
                                                                    formControlName="firstName">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Middle Initial</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Middle Initial"
                                                                    formControlName="middleInitial">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Last Name<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Last Name"
                                                                    formControlName="lastName">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Suffix</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Suffix"
                                                                    formControlName="suffix">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">
                                                                        Party ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Party ID"
                                                                    formControlName="partyId">
                                                                    <br>
                                                                    <label style="    color: black;">ID sequence will be generated if empty</label>
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Title</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Title"
                                                                    formControlName="title">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                     
                                                    </div>
                                                    <div class="main-submit-button" style="margin-right: 33%;">
                                                        <button type="submit"
                                                            class="btn btn-secondary submit-btn" 
                                                            (click)="Address(this.createNewProspect.value.firstName, this.createNewProspect.value.lastName)">Continue</button>&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==2">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormC">
                                                                    <label for="exampleInputEmail1">Address 1<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Address 1"
                                                                    formControlName="userAddress1">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Address 2</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Address 2"
                                                                    formControlName="userAddress2">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormC">
                                                                    <label for="exampleInputEmail1">City<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter City"
                                                                    formControlName="userCity">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">State</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown styleClass="small-dropdown" [options]="stateArray" placeholder="State" filter="true"
                                                                formControlName="state">
                                                                </p-dropdown>
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormC">
                                                                    <label for="exampleInputEmail1">Zip/Postal Code<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Zip/Postal Code"
                                                                    formControlName="userZip">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Country</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown placeholder="Country" [options]="countryArray" (onChange)="onCountryChange($event.value)"
                                                                formControlName="country" filter="true" optionlabel="label">
                                                            </p-dropdown>
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormC">
                                                                    <label for="exampleInputEmail1">Allow Address
                                                                        Solicitation?</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="solict"  optionlabel="label" filter="true"
                                                                    placeholder="Enter Allow Address Solicitation?"
                                                                    formControlName="addSolic">
                                                                </p-dropdown>
                                                                </div>
                    
                    
                                                              
                                                            </div>
                                                        </div>
                                                        
                                                      
                                                    </div>
                                                    <div class="main-submit-button" style="margin-right: 24%;">
                                                        <button type="submit"
                                                            class="btn btn-secondary submit-btn" 
                                                            (click)="mailAddress(this.createNewProspect.value.userAddress1
                            ,this.createNewProspect.value.userCity,this.createNewProspect.value.userZip)">Continue</button>&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==3">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Country Code<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Country Code"
                                                                    formControlName="homeCountry">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Area Code<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Area Code"
                                                                    formControlName="homeArea">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Phone Number<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"  (keypress)="numberOnly($event)"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Phone Number"
                                                                    formControlName="homePhone">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Extension</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter ext"
                                                                    formControlName="homeExt">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Allow
                                                                        Solicitation?<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="solict"  optionlabel="label" filter="true"
                                                                    placeholder="Enter Allow Solicitation?" formControlName="homeSolic">
                                                                </p-dropdown>
                                                                </div>
                    
                    
                    
                                                              
                                                            </div>
                                                        </div>
                                                        
                                                     
                                                    </div>
                                                    <div class="main-submit-button" style="
                                                    margin-right: 29%;">
                                                        <button type="submit"
                                                            class="btn btn-secondary submit-btn" 
                                                            (click)="homeAddress(this.createNewProspect.value.homeCountry
                            ,this.createNewProspect.value.homeArea,
                            this.createNewProspect.value.homePhone,this.createNewProspect.value.homeSolic)">Continue</button>&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==4">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Country Code<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Country Code"
                                                                    formControlName="workCountry">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Area Code<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Area Code"
                                                                    formControlName="workArea">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Phone Number<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"  (keypress)="numberOnly($event)"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Phone Number"
                                                                    formControlName="workPhone">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Extension</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter ext"
                                                                    formControlName="workExt">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Allow
                                                                        Solicitation?<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="solict"  optionlabel="label" filter="true"
                                                                    placeholder="Enter Allow Solicitation?"
                                                                    formControlName="workSolic">
                                                                </p-dropdown>
                                                                </div>
                    
                    
                                                              
                    
                                                              
                                                            </div>
                                                        </div>
                                                      
                                                       
                                                    </div>
                                                    <div class="main-submit-button" style="
                                                    margin-right: 29%;">
                                                        <button type="submit"
                                                            class="btn btn-secondary submit-btn" 
                                                            (click)="workAddress(this.createNewProspect.value.workCountry
                                                            ,this.createNewProspect.value.workArea,
                                                            this.createNewProspect.value.workPhone,this.createNewProspect.value.workSolic)">Continue</button>&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==5">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Country Code<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Country Code"
                                                                    formControlName="faxCountry">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Area Code<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Area Code"
                                                                    formControlName="faxArea">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Phone Number<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"  (keypress)="numberOnly($event)"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Phone Number"
                                                                    formControlName="faxPhone">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Extension</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter ext"
                                                                    formControlName="faxExt">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Allow
                                                                        Solicitation?<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="solict"  optionlabel="label" filter="true"
                                                                    placeholder="Enter Allow Solicitation?"
                                                                    formControlName="faxSolict">
                                                                </p-dropdown>
                                                                </div>
                    
                    
                                                              
                                                            </div>
                                                        </div>
                                                       
                                                       
                                                    </div>
                                                    <div class="main-submit-button" style="    margin-right: 29%;">
                                                        <button type="submit"
                                                            class="btn btn-secondary submit-btn" 
                                                            (click)="faxAddress(this.createNewProspect.value.faxCountry
                            ,this.createNewProspect.value.faxArea,
                            this.createNewProspect.value.faxPhone,this.createNewProspect.value.faxSolict)">Continue</button>&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==6">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Country Code<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Country Code"
                                                                    formControlName="mobileCountry">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Area Code<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Area Code"
                                                                    formControlName="mobileArea">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Phone Number<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"  (keypress)="numberOnly($event)"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Phone Number"
                                                                    formControlName="mobilePhone">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Extension</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter ext"
                                                                    formControlName="mobileExt">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Allow
                                                                        Solicitation?<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="solict"  optionlabel="label" filter="true"
                                                                    placeholder="Enter Allow Solicitation?"
                                                                     formControlName="mobileSolict">
                                                                </p-dropdown>
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        
                                                       
                                                    </div>
                                                    <div class="main-submit-button" style="    margin-right: 29%;">
                                                        <button type="submit"
                                                            class="btn btn-secondary submit-btn" 
                                                            (click)="mobileAddress(this.createNewProspect.value.mobileCountry
                            ,this.createNewProspect.value.mobileArea,
                            this.createNewProspect.value.mobilePhone,this.createNewProspect.value.mobileSolict)">Continue</button>&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==7">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Email<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Country Code"
                                                                    formControlName="email">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Allow
                                                                        Solicitation?</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="solict"  optionlabel="label" filter="true"
                                                                    placeholder="Enter Allow Solicitation?"  formControlName="emailsolicitation">
                                                                </p-dropdown>
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">User Name<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter User Name"
                                                                    formControlName="userName">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Password<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Password"
                                                                    formControlName="pass">
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Confirm Password<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Password"
                                                                    formControlName="confirmPass">
                                                                </div>
                    
                    
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Product Store<span
                                                                        style="color: red !important;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="productStoresListArray" filter="true"
                                                                        placeholder="Enter Product Store"
                                                                        formControlName="productStore">
                                                                        </p-dropdown>
                                                                </div>
                    
                                                              
                                                            </div>
                                                        </div>
                                                       
                                                     
                                                    </div>
                                                    <div class="main-submit-button" style="
                                                    margin-right: 29%;
                                                ">
                                                        <button type="submit"
                                                            class="btn btn-secondary submit-btn" (click)="onSubmit()">Save</button>&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>