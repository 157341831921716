<div class="container-fluid main-container-wrapper" style="margin-top: 53px !important;">
    <!-- <div class="row " style="background-color: #0492C2; padding-top: 6px !important;">
        

        <div class="col-lg-1">
           <span class="color-black pl-1 titlePanels" style="cursor: pointer; color: white; font-weight: bold;">Find </span>
        </div>
            <div class="col-lg-2" style="margin-left: -4% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Work Order Number"
                >
                </p-dropdown>

            </div>

            <div class="col-lg-2" style="margin-left: -2% !important;">
                <input type="text" class="form-control" id=""  >
            </div>

            <div class="col-lg-1" style="margin-left: -2% !important;">
                <button type="submit" class="btn btn-secondary submit-btn">Search</button>

            </div>
        

        <div class="col-lg-4">
        </div>
            <div class="col-lg-2" style="margin-left: 7% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Recent Items"
                >
                </p-dropdown>

            </div>

        

    

</div> -->

<div class="row" style="background-color:  #0492C2; padding-bottom: 6px !important; padding-top: 4px !important;">

<div class="col-lg-3">
    <!-- <button type="submit" class="btn btn-secondary submit-btn" style="margin-left: -2%;">
        Dispatch Board
     </button> -->
</div>

<div class="col-lg-2 " style="margin-left: 23% !important;">
    <button (click)="toWorkOrder()" style="background-color: #1338BE; 
    color: white !important; font-weight: bold;"> Work Orders</button>
</div>

<div class="col-lg-2 " style="margin-left: -8% !important;">
    <button (click)="toCustomer()" style="background-color: #48AAAD; 
    color: white !important; font-weight: bold;">Customer</button>

</div>               
   
<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toInvoices()" style="background-color:#016064;
     color: white; font-weight: bold;">Invoices</button>

</div>                
    
<!-- <div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toReports()" style="background-color: #1520A6; 
    color: white; font-weight: bold;">Reports</button>

</div>               

<div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toPeople()" style="background-color:#59788E;
     color: white !important; font-weight: bold; padding-left: 12px; padding-right: 12px;">Staff</button>

</div> -->
<div class="col-lg-2 " style="margin-left: -3% !important;">
    <button (click)="toTimeSheet()" style="background-color: #1F456E;
     color: white !important; font-weight: bold;">Time Sheet</button>

</div>                 

<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toSetting()" style="background-color:#757c88 ; 
    color: white; font-weight: bold; ">Settings</button>

</div>



</div>

    <div  class="w3-card-4 classCssShadow" style="border:1px solid;border-color: #0d3769 !important; margin-top: 1%;">
        <div class="col-lg-12" style="background-color: hsl(60, 100%, 50%);  color: black !important;">
            <div class="row" style="padding: 4px;">
                <div class="" style="color: white; margin-left: 7px; font-weight: bold;" >
                    VIEW
                </div>
                <div class="col-lg-2" style="margin-top:-3px ;">
                    <p-dropdown filter="true"
                    [options]="" optionlabel="label"
                    placeholder="Recently Serviced"
                    >
                    </p-dropdown>
    
                </div>

            </div>
        </div>

        <div class="col-lg-12 " style="margin-top: 1%; margin-left: 1%;">
            <div class="d-flex justify-content-between">
                <div class="col-lg-8" style="margin-left: -3%;">
                    <button type="submit"  class="btn btn-secondary submit-btn ml-2">
                        New Person
                     </button>

                    <button type="submit" class="btn btn-secondary submit-btn ml-2">
                        Edit
                     </button>

                    <button type="submit" class="btn btn-secondary submit-btn ml-2">
                        Logout Person
                     </button>

                    <button type="submit" class="btn btn-secondary submit-btn ml-2">
                        Printer Friendly
                     </button>
                </div>

                <div class="col-lg-4" style="font-size: 12px; margin-left: 28%;">
                    <label>Filter Off</label>
                </div>
            </div>
                
        </div>       
             

        
        

        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
            <div class="col-lg-12 col-12">
                <div class="form-group">
                    <div class="card own-account-table">
                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                            <ng-template pTemplate="header">
                                <tr style="background-color: hsl(60, 100%, 50%);  color: black !important;">
                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                        <div class="checkbox-align" style="color: black;">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                    <div class="ui-helper-hidden-accessible">
                                                        <input type="checkbox" name="undefined" value="undefined">
                                                    </div>
                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                    </div>
                                                </div>
                                                <!---->
                                            </p-checkbox>

                                            Name
                                        </div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                    </th>
                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                        <div style="color: black;">
                                            Phone
                                        </div>
                                        <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                        </div>
                                    </th>
                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                        <div style="color: black">
                                            Role	</div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                    </th>
                                    
                                  
                                   

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product>
                                <tr>
                                    <td [ngStyle]="{'width':'170px'}">
                                        <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                    <div class="ui-helper-hidden-accessible">
                                                        <input type="checkbox" name="undefined" value="undefined">
                                                    </div>
                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                    </div>
                                                </div>
                                            </p-checkbox>
                                            <span  class="account-button"  >
                                                {{product.data.custRequestId}}</span>

                                        </div>
                                    </td>
                                    <td [ngStyle]="{'width':'170px'}">
                                        {{product.data.custRequestTypeId}}
                                    </td>
                                    <td [ngStyle]="{'width':'170px'}">
                                        {{product.data.productStoreId}}
                                    </td>
                                    <td [ngStyle]="{'width':'170px'}">
                                        {{product.data.custRequestName}}
                                    </td>
                                    <td [ngStyle]="{'width':'170px'}">
                                        {{product.data.priority}}
                                    </td>
                                    <td [ngStyle]="{'width':'170px'}">
                                        {{product.data.priority}}
                                    </td>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td>
                                        <span class="text-center">
                                            <h4 class="ml-2">No
                                                Record Found
                                            </h4>
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p class="paginate_data">
                            View per page

                        </p>
                    </div>
                </div>
            </div>
        </div>

      

        
       <div style="background-color: hsl(60, 100%, 50%);  color: black !important;">
        !
       </div>

       <div class="col-lg-12">
        <div class="row d-flex justify-content-around" style="font-size: 17px; margin-top: 1%;">
             <div class=""> 
                 <span class="fa-solid fa-user"></span>
             </div>
             <div class=""> 
                <span class="fa-solid fa-user" style="margin-left: 60%;"></span>

             </div>

             <div class=""> 
                <span class="fa-solid fa-user" style="margin-left: 68%;"></span>

             </div>
        </div>

    </div>
      
       <div class="col-lg-12">
           <div class="row d-flex justify-content-around" style="font-size: 10px;">
                <div class=""> 
                    <label >Logged In</label>

                </div>
                <div class=""> 
                    <label>Logged Out</label>
                </div>

                <div class=""> 
                    <label>Active</label>
                </div>
           </div>

       </div>

    </div>


    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            
                <div class="w3-card-4 classCard w-100">
                            <div class="row mb-5" style="width: 100%; margin-top: 1%;">
                                <div class="col-md-12">
                                    <div class="w3-card-4 classCard" style="margin-left: 12px;margin-right: -18px; width: 101%; height: 182%;">
                                        <div class="d-flex justify-content-between bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                         style="background-color: hsl(60, 100%, 50%);  color: black !important; margin-top: 0.5px;">
                                            <p class="h-text " style="margin: 0px 15px -1px -2px !important; color: black;">General Information</p>
                                            <p class="h-text " style="color: black;">Required Filed</p>

                                        </div>
                        
                                        <div class="">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <form class="w-100" >
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
    
                                                                    <div class="col-lg-2 form-group classInputC">
                                                                        <label for="exampleInputEmail1">Can this Person login
                                                                    </label>
                                                                    </div>
    
                                                                    <div class="col-lg-1 d-flex">
                                                                        <span><input type="radio"></span>
                                                                        <span> &nbsp; Yes</span>
                                                                    </div>

                                                                    <div class="col-lg-1 d-flex">
                                                                        <span><input type="radio"></span>
                                                                        <span> &nbsp; No</span>
                                                                    </div>

                                                                    <div class="col-lg-8">
                                                                        <label for="exampleInputEmail1">Choosing yes will allow user to login via  a desktop 
                                                                            browser and you use on desktop license. Choosing no one will allow a user to be
                                                                            created but no login and will use up one non login license

                                                                    </label>
                                                                    </div>
                
                                                                    
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
    
                                                                    <div class="col-lg-2 form-group classInputC">
                                                                        <label for="exampleInputEmail1">First Name
                                                                    </label>
                                                                    </div>
    
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1" >

                                                                    </div>

                                                                    <div class="col-lg-8">
                                                                        <label for="exampleInputEmail1">

                                                                    </label>
                                                                    </div>
                
                                                                    
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
    
                                                                    <div class="col-lg-2 form-group classInputC">
                                                                        <label for="exampleInputEmail1">Last Name
                                                                    </label>
                                                                    </div>
    
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1" >

                                                                    </div>

                                                                    <div class="col-lg-8">
                                                                        <label for="exampleInputEmail1">

                                                                    </label>
                                                                    </div>
                
                                                                    
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
    
                                                                    <div class="col-lg-2 form-group classInputC">
                                                                        <label for="exampleInputEmail1">Dispaly As
                                                                    </label>
                                                                    </div>
    
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown   placeholder="All" filter="true">
                                                                        </p-dropdown>

                                                                    </div>

                                                                    <div class="col-lg-8">
                                                                        <label for="exampleInputEmail1">
                                                                            This is how the user will addressed in the application.
                                                                    </label>
                                                                    </div>
                
                                                                    
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
    
                                                                    <div class="col-lg-2 form-group classInputC">
                                                                        <label for="exampleInputEmail1">Role
                                                                    </label>
                                                                    </div>
    
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown   placeholder="Dispathcer" filter="true">
                                                                        </p-dropdown>

                                                                    </div>

                                                                    <div class="col-lg-8">
                                                                        <label for="exampleInputEmail1">
                                                                            
                                                                    </label>
                                                                    </div>
                
                                                                    
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 mb-0">
                                                                <div class="row">
    
                                                                    <div class="col-lg-3 form-group classInputC">
                                                                        <label for="exampleInputEmail1">An work Order assigned to this person
                                                                    </label>
                                                                    </div>
    
                                                                    
                
                                                                    
                                                                </div>
                                                                
                                                            </div>

                                                            <div class="col-lg-12 mt-0">
                                                                <div class="row mt-0" style="margin-top: -2%;">

                                                                    <div class="col-lg-2 form-group classInputC">
                                                                    
                                                                    </div>

                                                                    <div class="col-lg-1 d-flex">
                                                                        <span><input type="radio"></span>
                                                                        <span>&nbsp; Yes</span>
                                                                    </div>

                                                                    <div class="col-lg-1 d-flex">
                                                                        <span><input type="radio"></span>
                                                                        <span> &nbsp; No</span>
                                                                    </div>

                                                                    <div class="col-lg-8">
                                                                        <label for="exampleInputEmail1">

                                                                    </label>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            

                                                            <div class="col-lg-12">
                                                                <div class="row d-flex">
    
                                                                    <div class="col-lg-2 form-group  classInputC">
                                                                        <label for="exampleInputEmail1">Mobile User
                                                                    </label>
                                                                    </div>
    
                                                                    <div class="col-lg-1 d-flex">
                                                                      <span><input type="radio"></span>
                                                                        <span>&nbsp; Yes</span>
                                                                    </div>

                                                                    <div class="col-lg-1 d-flex">
                                                                        <span><input type="radio"></span>
                                                                        <span>&nbsp; No</span>
                                                                    </div>

                                                                    <div class="col-lg-8">
                                                                        <label for="exampleInputEmail1">
                                                                            Choosing yes will allow a user to login the application via a
                                                                            mobile client and will use up one mobile license
                                                                    </label>
                                                                    </div>
                
                                                                    
                                                                </div>
                                                            </div>

                                                            
    
                                                           
                                                            
                
                
                                                        </div>
                                                    </form>
                                                    
                                                </div>
                                            </div>
                
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-5" style="width: 100%; margin-top: 1%;">
                                <div class="col-md-12">
                                    <div class="w3-card-4 classCard" style="margin-left: 12px;margin-right: -18px; width: 101%; margin-top: 18%;">
                                        <div class="d-flex justify-content-between bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                         style="background-color: hsl(60, 100%, 50%);  color: black !important; margin-top: 0.5px;">
                                            <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important; color: black">Contact Information</p>

                                        </div>
                        
                                        <div class="">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <form class="w-100" >
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
    
                                                                    <div class="col-lg-2 form-group classInputC">
                                                                        <label for="exampleInputEmail1">Primary Phone
                                                                    </label>
                                                                    </div>
    
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1" >

                                                                    </div>
                
                                                                    
                                                                </div>
                                                            </div>
    
                                                            <div class="col-lg-12">
                                                                <div class="row">
    
                                                                    <div class="col-lg-2 form-group classInputC">
                                                                        <label for="exampleInputEmail1">Alternate Phone
                                                                    </label>
                                                                    </div>
    
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1" >
                                                                        
                                                                    </div>
    
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
    
                                                                    <div class="col-lg-2 form-group classInputC">
                                                                        <label for="exampleInputEmail1">Contact Email
                                                                    </label>
                                                                    </div>
    
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1" >
                                                                        
                                                                    </div>

                                                                    <div class="col-lg-8">
                                                                             The email will be used for forgotten password and email Alerts
                                                                        
                                                                    </div>
    
                                                                </div>
                                                            </div>
                                                            
                
                
                                                        </div>
                                                    </form>
                                                    
                                                </div>
                                            </div>
                
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3" style="width: 100%; margin-top: 1%;">
                                <div class="col-md-12">
                                    <div class="w3-card-4 classCard" style="margin-left: 12px;margin-right: -18px; width: 101%; margin-top: -3%;">
                                        <div class="d-flex justify-content-between bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" 
                                        style="background-color: hsl(60, 100%, 50%);  color: black !important; margin-top: 0.5px;">
                                            <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important; color: black;">Login Information</p>

                                        </div>
                        
                                        <div class="">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <form class="w-100" >
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row ">
    
                                                                    <div class="col-lg-2 form-group classInputC">
                                                                        <label for="exampleInputEmail1">Login
                                                                    </label>
                                                                    </div>
    
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1" >

                                                                    </div>
                
                                                                    
                                                                </div>
                                                            </div>
    
                                                            <div class="col-lg-12">
                                                                <div class="row">
    
                                                                    <div class="col-lg-2 form-group classInputC">
                                                                        <label for="exampleInputEmail1">Password
                                                                    </label>
                                                                    </div>
    
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1" >
                                                                        
                                                                    </div>
    
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
    
                                                                    <div class="col-lg-2 form-group classInputC">
                                                                        <label for="exampleInputEmail1">Retype Password
                                                                    </label>
                                                                    </div>
    
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1" >
                                                                        
                                                                    </div>

                                                                    <div class="col-lg-8">
                                                                        
                                                                    </div>
    
                                                                </div>
                                                            </div>
                                                            
                
                
                                                        </div>
                                                    </form>
                                                    
                                                </div>
                                            </div>
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                  
                <div  class="w3-card-4 classCssShadow" style="border:1px solid;border-color: #0d3769 !important; margin-top: 1%;">
                    <div class="col-lg-12 mt-2">
                        <div class="row d-flex justify-content-end" >
                            <button type="submit"  class="btn btn-secondary submit-btn" style="margin-right: 1%;">
                                Unlock
                                </button>
                        </div>
                    </div>   
                    
                    <div class="col-lg-12 mt-2">
                        <div class="row">
                            <button type="submit"  class="btn btn-secondary submit-btn ml-2">
                                Send Email
                                </button>
            
                                <button type="submit"  class="btn btn-secondary submit-btn ml-2">
                                Show Map
                                </button>
            
                                <button type="submit"  class="btn btn-secondary submit-btn ml-2">
                                Printer Friendly
                                </button>
            
                                
                        </div>
                    </div> 
            
                    <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background-color: hsl(60, 100%, 50%);  color: black !important;">
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: black;">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
            
                                                        Day
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Work Order
                                                    </div>
                                                    <div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </div>
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                    <div style="color: black">
                                                        Start Time	</div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                    <div style="color: black">
                                                        End Time	</div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                    <div style="color: black">
                                                        Hours	</div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                
                                                
                                                
            
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.data.custRequestId}}</span>
            
                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.data.custRequestTypeId}}
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.data.productStoreId}}
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.data.custRequestName}}
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.data.priority}}
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.data.priority}}
                                                </td>
            
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No
                                                            Record Found
                                                        </h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page
            
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
                
            
        </div>

   
    </div>

   


</div>
