import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-visitor-detail-summary',
  templateUrl: './visitor-detail-summary.component.html',
  styleUrls: ['./visitor-detail-summary.component.css']
})
export class VisitorDetailSummaryComponent implements OnInit {
  activeCategory=1;
  products3: any;
  
  paymentPartyGroup: any;
  downloading: boolean;
  total=0;
  ReportTax:any[]=[];
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
   finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  FinancialTransaction:any[]=[];
  budgetId: any;
  budgetHeader: any;
  budgetStatus: any;
  budgetRole: any;
  budgetItem: any;
  budgetReview: any;
  budgetItemList: any;
  budgetRoleList: any;
  budgetReviewList: any;
  status: string;
  routingId: any;
  taskAssoc: any;
  productLink: any;
  inviteId: any;
  InvitationGroupAssocs: any;
  InvitationRoleAssociation: any;
  editData: any;
  visitId: any;
  VisitorsDetails: any;
  serverHitData: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly router: Router,
    private _location: Location,) { 
     
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.visitId = params["visitId"];
    });
   
    this.getVisitorsDetails();
    
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this.router.navigate(['/hub/Dashboard']);
}
  
 
  
  getVisitorsDetails() {
    this.spinner.show();
    this.accountsService.getVisitorsDetails(this.finSize,this.visitId).subscribe((res: any) => {
      this.VisitorsDetails = res.data.visitDetail;
      this.serverHitData = res.data.serverHitData;
      this.spinner.hide();
    })
  
  }
  GoToQuickLink(){
    this.router.navigate(['/security/visits']);
  } 
  
 
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  
 
 

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}



