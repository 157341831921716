import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import Swal from 'sweetalert2'
import { detailedContactsService } from 'src/app/crm/contacts/detailed-contacts-summary/detailed-contacts-summary.service';
import * as moment from 'moment';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SkillService } from 'src/app/human-resource/skills/skill.service';
import { EmployeeService } from 'src/app/human-resource/employees/employess.service';
import { EmployementApplicationService } from 'src/app/employeeApplication/employee-application.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { EmployeeApplicationService } from 'src/app/employeeApplication/employee-application/employee-application.service';
import { Location } from '@angular/common';
import { EmpPersonalFormService } from 'src/app/human-resource/update-employe-personalInfo/emp-personal-service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { MyContactsService } from "../../crm/contacts/my-contacts/my-contacts.service";
import { DetailedEmployeeSummary } from 'src/app/human-resource/detailed-employee-summary/detailed-employee-summary.service';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AddSkillsService } from 'src/app/human-resource/add-skills/add-skill-service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { ReallocationsService } from 'src/app/human-resource/reallocation/reallocations.service';
import { InternalJobService } from 'src/app/human-resource/recuitment/internal-job-posting/internal-job-posting.service';

@Component({
  selector: 'app-view-party-profile',
  templateUrl: './view-party-profile.component.html',
  styleUrls: ['./view-party-profile.component.css']
})
export class ViewPartyProfileComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  @ViewChild('closebutton12') closebutton12;
  @ViewChild('closebutton13') closebutton13;
  @ViewChild('closebutton14') closebutton14;
  @ViewChild('closebutton15') closebutton15;
  @ViewChild('closebutton16') closebutton16;
  @ViewChild('closebutton17') closebutton17;
  @ViewChild('closebutton18') closebutton18;
  @ViewChild('closebutton19') closebutton19;
  @ViewChild('closebutton20') closebutton20;
  @ViewChild('closebutton21') closebutton21;
  @ViewChild('closebutton22') closebutton22;
  @ViewChild('closebutton23') closebutton23;
  @ViewChild('closebutton24') closebutton24;
  @ViewChild('closebutton25') closebutton25;
  @ViewChild('closebutton26') closebutton26;
  @ViewChild('closebutton27') closebutton27;
  faEdit = faEdit;
  faTrash = faTrash;
  Editform: FormGroup;
  shoppingList: FormGroup;
  relatedContact: any[] = [];
  createJournal: FormGroup;
  createVendor: FormGroup;
  findPartyForm: FormGroup;
  activeCategory = 3;
  products3: any;
  paymentPartyGroup: any;
  downloading: boolean;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search = ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  FinancialTransaction: any[] = [];
  accountData: any;
  budgetId: any;
  budgetHeader: any;
  budgetStatus: any;
  budgetRole: any;
  budgetItem: any;
  budgetReview: any;
  budgetItemList: any;
  budgetRoleList: any;
  budgetReviewList: any;
  status: string;
  taxAuthPartyId: any;
  roleList: any;
  accountDetails: any;
  isAdditionalDetail: boolean;
  isJobDetail: boolean;
  contactInformation: any[] = [];
  accountName: any;
  addr: string;
  emailId: any;
  phoneNumber: string;
  contentList: any;
  billingAccounts: any[];
  paymentMethods: any[];
  skillList: any;
  public employeeDetails: any;
  public employeeDetail: any;
  employeeDetailArray: any = [];
  departmentList: any;
  departmantArray: any = [];
  departmentId: any;
  department: any;
  demoName: any;
  demoId: any;
  employeeDetailArrayEmail: any = [];
  employeeDetailArrayPhone: any = [];
  employeeDetailRelatedAccount: any;
  emergencyContact: any;
  personId: any;
  createLinkParty: FormGroup;
  partyIdArray: any[] = [];
  IdentificationList: any;
  PartyResume: any;
  SegmentRole: any;
  PartyClassification: any;
  PartyRate: any;
  UserLoginData: any;
  PartyAttribute: any;
  PartyContactList: any;
  employmentApplication: any;
  productStoresListArray: any[] = [];
  id: string;
  allrole: any;
  allroleArray: any[] = [];
  AllProductStoreRole: any;
  Visitors: any;
  PartyLoyaltyPoints: any;
  Person: any;
  ProductStore: any;
  Preferences: any;
  RelationshipPartyIdTo: any;
  AllFinanceHistory: any;
  ShoppingListArray: any[] = [];
  TaxInfo: any;
  PartyIcsAvsOverRide: any[] = [];
  PartyContentData: any;
  partyId: any;
  partyIds: any;
  crmStatus: any;
  crmArray: any[] = [];
  currencyList: any[];

  createUser: FormGroup;
  activeCategoryA = 2;
  jid: any;
  jname: any;
  show = false;
  party: any;
  user = [{
    "label": "Yes",
    "value": "Y"
  },
  {
    "label": "No",
    "value": "N"
  }]
  fileUploadForm: FormGroup;
  accountArray: any = [];
  activeCategoryAccount = 1;
  groupNameSearchType: any = 'Contains'
  contactMechTypeId: any = '';
  groupName: any = '';
  partyIdSearchType: any = 'Contains';

  createAttribue: FormGroup;
  identification: any;
  identificationArray: any[] = [];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  attName: any;
  attDes: any;
  attValue: any;


  contactForm: FormGroup;
  editMode: boolean;
  contact: any;
  array = [
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }
  ];
  contactPurpose: any[];
  contactMechId: string;

  addressForm: FormGroup;
  solicitationArray = [
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }
  ];
  contactMechTypeIdAdd: any[];

  countryArray: any[];
  stateArray: any[];
  accountInfo: any;

  noteForm: FormGroup;
  activeCategoryNote = 1;
  noteName: string;
  noteText: string;
  partyIdForCreate: any;
  showCreateBtn: boolean;
  noteId: any;
  note: any;
  showUpdateBtn: boolean;

  activeCategoryB = 2;
  selectedFileName: any;
  partyContentData: any = [];
  partyContentArray: any[] = [];
  fileData: File;
  partyContentTypeId: any;

  createIdentification: FormGroup;

  creditCardForm: FormGroup;
  cardTypes: any[];
  prefixArray = [
    {
      label: 'Mr.',
      value: 'Mr.'
    },
    {
      label: 'Mrs.',
      value: 'Mrs.'
    },
    {
      label: 'Ms.',
      value: 'Ms.'
    },
    {
      label: 'Dr.',
      value: 'Dr.'
    }];
  suffixArray = [
    {
      label: 'Jr.',
      value: 'Jr.'
    },
    {
      label: 'Sr.',
      value: 'Sr.'
    },
    {
      label: 'I',
      value: 'I'
    },
    {
      label: 'II',
      value: 'II'
    },
    {
      label: 'III',
      value: 'III'
    },
    {
      label: 'IV',
      value: 'IV'
    },
    {
      label: 'V',
      value: 'V'
    },
  ];
  expirationMonth = [
    {
      label: '1 (Jan)',
      value: '1'
    },
    {
      label: '2 (Feb)',
      value: '2'
    },
    {
      label: '3 (Mar)',
      value: '3'
    },
    {
      label: '4 (Apr)',
      value: '4'
    },
    {
      label: '5 (May)',
      value: '5'
    },
    {
      label: '6 (Jun)',
      value: '6'
    },
    {
      label: '7 (Jul)',
      value: '7'
    },
    {
      label: '8 (Aug)',
      value: '8'
    },
    {
      label: '9 (Sep)',
      value: '9'
    },
    {
      label: '10 (Oct)',
      value: '10'
    },
    {
      label: '11 (Nov)',
      value: '11'
    },
    {
      label: '12 (Dec)',
      value: '12'
    }];
  expirationYear = [


    {
      label: '2020',
      value: '2020'
    },
    {
      label: '2021',
      value: '2021'
    },
    {
      label: '2022',
      value: '2022'
    },
    {
      label: '2023',
      value: '2023'
    },

    {
      label: '2024',
      value: '2024'
    },
    {
      label: '2025',
      value: '2025'
    },
    {
      label: '2026',
      value: '2026'
    },
    {
      label: '2027',
      value: '2027'
    },
    {
      label: '2028',
      value: '2028'
    },
    {
      label: '2029',
      value: '2029'
    },
    {
      label: '2030',
      value: '2030'
    }];
  paymentMethodId: string;
  creditCardValue: any;

  giftForm: FormGroup;


  giftCard: any;


  eftAccountForm: FormGroup;
  isEftAccount: boolean;
  eftAccount: any;
  accountTypes = [
    {
      label: 'Checking',
      value: 'Checking'
    },
    {
      label: 'Savings',
      value: 'Savings'
    }];

  checkForm: FormGroup;

  paymentMethodTypes: any[];
  paymentMethodTypeId: string;
  paymentMethodValue: any;
  accountDataCredit: any;
  accountDataNote: any;

  currencyUomIds: any[];
  billingAccountForm: FormGroup;
  billingAccount: any;
  availableBalance: string;

  default = [{
    "label": "Y",
    "Value": "Y"
  },
  {
    "label": "N",
    "Value": "N"
  }]
  createParty: FormGroup;
  SegmentGroup: any;
  SegmentGroupArray: any[] = [];
  currencyArray: any[] = [];
  rateTypes: any;
  rateTypesArray: any[] = [];
  periodTypes: any;
  periodTypesArray: any[] = [];

  getSkillLists: any;
  skillArray: any = [];
  updateId: any;
  Skillid: any;
  rate: any;
  skill: any;
  year: any;

  createIdentificationNo: FormGroup;

  createSegment: FormGroup;

  createClassification: FormGroup;
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[] = [];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  efromDate: any;
  ethruDate: any;
  getthruDate: string;


  Attachform: FormGroup;
  DataCategoryIdList: any;
  DataCategoryIdListArray: any[] = [];
  isPublic = [{
    "label": "Yes",
    "value": "Y"
  },
  {
    "label": "No",
    "value": "N"
  }]
  PartyContentTypeIdList: any;
  PartyContentTypeIdListArray: any[] = [];
  PartyStatusIdList: any;
  PartyStatusIdListArray: any[] = [];
  actualfromdate: any;
  actualthroughdate: string;
  contentTypeId: any;
  dataResourceId: any;
//  statusId: any
  editgetEstimateFrom: string;
  egetEstimateTo: string;

  createResume: FormGroup;

  createContact: FormGroup;
  AllPartyContactListPartyStatus: any;
  AllPartyContactListPartyStatusArray: any[] = [];
  PartyInvitationStatusList: any;
  PartyInvitationStatusListArray: any[] = [];
  contactId: any;

  preferredContactMechId: any;
  PreferredContactMechId: any;
  PreferredContactMechIdArray: any[] = [];
  contactList: any;
  contactListArray: any[] = [];
  statusId: any;

  statusList: any;
  appSource: any;
  statusArray: any = [];
  appSourceArray: any = [];
  readonly employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  public jobPost = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search

  }
  public applicationSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  positionArray: any = [];
  positionType: any;
  internalJobPostList: any;
  appIdArray: any = [];
  updateethrdateStatus: any;

  createContactProduct:FormGroup;
  roleTypeId: any;
  sequenceNumber: any;
  productStoreId: any;

  constructor(readonly spinner: NgxSpinnerService,
    private _location: Location,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    readonly _detailedContactsService: detailedContactsService,
    readonly toastr: ToastrService,
    readonly skillService: SkillService,
    readonly employeeService: EmployeeService,
    readonly router: Router,
    readonly employementApplicationService: EmployementApplicationService,
    readonly addSkillService: AddSkillService,
    readonly _CRMAccountService: CRMAccountService,
    readonly employeeApplicationService: EmployeeApplicationService,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    public empPersonalFormService: EmpPersonalFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly _Router: Router,
    readonly _AccountingApService: AccountingApService,
    readonly _ToastrService: ToastrService,
    readonly formBuilder: FormBuilder,
    readonly MyContactsService: MyContactsService,
    readonly _DetailedEmployeeSummary: DetailedEmployeeSummary,
    readonly myContactsService: MyContactsService,
    public addSkillsService: AddSkillsService,
    readonly _CategoryService: CategoryService,
    public createCont: CreateContactsService,
    public createEventFormService: CreateEventFormService,
    readonly reallocationsService: ReallocationsService,
    readonly internalJobService: InternalJobService,
    readonly datePipe: DatePipe,

  ) {
    this.contentIds = [];
    this.createContactProduct = this._FormBuilder.group({
      RoleTypeID: [''],
      SequenceNum: [''],
      FromDate:[''],
      ThroughDate:[''],
      StoreID:['']
    
    
      
    });
  
    this.contentIds = [];
    this.createContact = this._FormBuilder.group({
      ContactListID: [''],
      StatusID: [''],
      FromDate: [''],
      ThroughDate: [''],
      PreferredContactMechID: [''],
      OptinCode: [''],
      StatusHistory: ['']
    });

    this.contentIds = [];
    this.createResume = this._FormBuilder.group({
      resumetxt: [''],
      resumeDate: [''],
      contentId: [''],
      resumeId: ['']
    });

    this.Attachform = this._FormBuilder.group({
      file: [''],
      partyContentTypeId: [''],
      fromDate: [''],
      throughDate: [''],
      ContentTypeID: [''],
      StatusID: [''],
      DataCategoryID: [''],
      IsPublic: ['']


    });

    this.contentIds = [];
    this.createClassification = this._FormBuilder.group({
      partyId: [''],
      ClassificationGroupID: [''],
      FromDate: [''],
      ThroughDate: ['']


    });

    this.createSegment = this._FormBuilder.group({
      segmentId: [''],
      roleId: ['']


    });

    this.createIdentificationNo = this._FormBuilder.group({
      type: [''],
      idvalue: [''],


    });


    this.createParty = this._FormBuilder.group({
      rateType: [''],
      PeriodTypeID: [''],
      rateAmount: [''],
      currency: [''],
      percentageUsed: [''],
      defaultRate: ['']


    });


    this.editMode = false;
    this.partyId = '';

    this.billingAccountForm = this._FormBuilder.group({
      accountCurrencyUomId: [''],
      accountLimit: [''],
      contactMechId: [''],
      description: [''],
      externalAccountId: [''],
      fromDate: [''],
      partyId: ['', [Validators.required]],
      thruDate: ['']
    });
    this.currencyUomIds = [];
    this.partyIds = [];
    this.paymentMethodId = '';
    this.availableBalance = '0';

    this.partyId = '';
    this.checkForm = this._FormBuilder.group({
      accountNumber: ['', [Validators.required]],
      accountType: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      companyNameOnAccount: [''],
      contactMechId: [''],
      description: [''],
      paymentMethodTypeId: ['', [Validators.required]],
      nameOnAccount: ['', [Validators.required]],
      routingNumber: ['', [Validators.required]]
    });
    this.editMode = false;
    this.contactMechId = '';
    this.paymentMethodTypes = [];
    this.paymentMethodId = '';
    this.paymentMethodTypeId = '';

    this.partyId = '';
    this.eftAccountForm = this._FormBuilder.group({
      accountNumber: ['', [Validators.required]],
      accountType: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      companyNameOnAccount: [''],
      contactMechId: [''],
      description: [''],
      nameOnAccount: ['', [Validators.required]],
      routingNumber: ['', [Validators.required]]
    });
    this.editMode = false;
    this.contactMechId = '';
    this.isEftAccount = false;


    this.editMode = false;
    this.giftForm = this._FormBuilder.group({
      cardNumber: ['', [Validators.required]],
      description: [''],
      expMonth: ['', Validators.required],
      expYear: ['', Validators.required],
      pinNumber: ['', Validators.required]
    });
    this.partyId = '';
    this.paymentMethodId = '';

    this.partyId = '';
    this.editMode = false;
    this.creditCardForm = this._FormBuilder.group({
      cardNumber: ['', [Validators.required]],
      cardType: ['', [Validators.required]],
      companyNameOnCard: [''],
      contactMechId: [''],
      description: [''],
      expMonth: ['', [Validators.required]],
      expYear: ['', [Validators.required]],
      firstNameOnCard: ['', [Validators.required]],
      lastNameOnCard: ['', [Validators.required]],
      middleNameOnCard: ['', [Validators.required]],
      suffixOnCard: [''],
      titleOnCard: ['']
    });
    this.cardTypes = [];
    this.contactMechId = '';
    this.paymentMethodId = '';


    this.createIdentification = this._FormBuilder.group({
      type: [''],
      idvalue: [''],


    });

    this.addressForm = this._FormBuilder.group({
      address1: [''],
      address2: [''],
      allowSolicitation: [''],
      attnName: [''],
      city: [''],
      contactMechId: [''],
      contactMechPurposeTypeId: ['', [Validators.required]],
      contactMechTypeIdAdd: [''],
      countryGeoId: [''],
      directions: [''],
      partyId: [''],
      postalCode: [''],
      precontactMechTypeIdAdd: ['POSTAL_ADDRESS'],
      stateProvinceGeoId: [''],
      toName: ['']
    });
    this.editMode = false;
    this.partyId = '';
    this.countryArray = [];
    this.stateArray = [];
    this.contactMechTypeIdAdd = [];
    this.contactMechId = '';

    this.partyId = '';
    this.editMode = false;
    this.contactForm = this._FormBuilder.group({
      allowSolicitation: [''],
      areaCode: [''],
      askForName: [''],
      contactMechId: [''],
      contactMechPurposeTypeId: ['', [Validators.required]],
      contactMechTypeId: [''],
      contactNumber: [''],
      countryCode: [''],
      extension: [''],
      partyId: [''],
      preContactMechTypeId: ['TELECOM_NUMBER']
    });
    this.contactPurpose = [];
    this.contactMechId = '';

    this.contentIds = [];
    this.createAttribue = this._FormBuilder.group({
      attrName: [''],
      attrDescription: [''],
      attrValue: [''],



    });

    this.createUser = this._FormBuilder.group({
      loginId: [''],
      currentpass: [''],
      verifypass: [''],
      passHint: [''],
      passrequire: [''],
      extAuthId: [''],


    });


    this.currencyList = [];


    this.shoppingList = this._FormBuilder.group({
      shoppingList: [''],



    });
    this.createVendor = this._FormBuilder.group({
      policies: [''],
      logo: [''],
      CompanyTitle: [''],
      ManifestCompany: [''],


    });
    this.createJournal = this._FormBuilder.group({
      journalName: [''],


    });
    this.createLinkParty = this._FormBuilder.group({
      partyIdTo: [''],
      partyIdFrom: ['']


    });
    this.findPartyForm = this._FormBuilder.group({
      StoreID: [''],
      RoleTypeID: [''],
      FromDate: [''],
      ThroughDate: [''],
      SequenceNum: ['']


    });
    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
      partyContentTypeId: new FormControl('')
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.taxAuthPartyId = params["party"];
    });
    this.getPartyRole();
    this.getAccountNote();
    this.accountDetail();
    this.getPartyContentsList();
    this.getPaymentMethods();
    this.editVendorsData();
    this.getSkillList();
    this.getEmployeeDetail();
    this.getPartyId();
    this.getIdentificationList();
    this.getPartyResume();
    this.getSegmentRole();
    this.getPartyClassification();
    this.getPartyRate();
    this.getUserLoginData();
    this.getPartyAttribute();
    this.getPartyContactList();
    this.getEmployeeApplication();
    this.getOrderListItems();
    this.getRoleAllType();
    this.getAllProductStoreRole();
    this.getVisitors();
    this.getPartyLoyaltyPoints();
    this.getPerson();
    this.getProductStore();
    this.getPreferences();
    this.getRelationshipPartyIdTo();
    this.getAllFinanceHistory();
    this.getShoppingList();
    this.getTaxInfo();
    this.getPartyIcsAvsOverRide();
    this.getPartyContent();


    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params['party'];

    })
    this.partyIds = this.partyId;
    this.empPersonalFormService.emp_personal_form = null;
    this.empPersonalFormService.createEventForm(this.empPersonalFormService.emp_personal_form);
    this.getCurrency();
    this.getEmpStatus();
    this.getEmployeeDetailInfo();



    this.fileUploadForm = this.formBuilder.group({
      accountPartyId: [''],
      comments: [''],
      contactPartyId: [''],
      partyId: ['']
    });
    this.getAccountList();



    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.attName = params["attName"];
      this.attValue = params["attValue"];
      this.attDes = params["attDes"];

    });

    if (this.attName) {
      this.show = true;
      const fromdate = this.eresumeDate

      setTimeout(() => {
        const formValue = this.createAttribue;
        formValue.patchValue({
          attrName: this.attName,
          attrDescription: this.attDes,
          attrValue: this.attValue,
        })
      }, 3000);
    }
    else {
      this.show = false;
    }

    this.contactMechId = this._ActivatedRoute.snapshot.queryParams.contactMechId;
    if (this.contactMechId) {
      this.editMode = true;
      this.getContactDetails();

    }
    this.getContactPurpose();

    if (this.contactMechId) {
      this.getAccountDetails();
    }
    this.addressForm.controls.countryGeoId.valueChanges.subscribe(data => {
      if (data) {
        this.getStates(data);
      }
    });
    this.getCountries();
    this.getPreContactMechIds();

    ///////NOTE///////

    this.noteForm = this._FormBuilder.group({
      noteName: [''],
      note: ['']
    });

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate = params["party"];
      const party = this.partyIdForCreate
      if (party) {
        this.showCreateBtn = true;
      }
    });


    this._ActivatedRoute.queryParams.subscribe(params => {
      this.noteId = params["noteId"];
      this.party = params["party"];
      this.noteName = params["noteName"];
      this.note = params["note"];
      if (this.noteId && this.party) {
        this.showUpdateBtn = true;
        this.showCreateBtn = false;
      }

    });

    /////////////////PARTY CONTENT////////////////////


    this.getPartyContentParty();
    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
      partyContentTypeId: new FormControl('')
    });


    ////////------IDENTIFICATION-----////////

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.partyTypeId = params["partyTypeId"];
      this.partyValue = params["partyValue"];
    });

    if (this.partyTypeId) {
      this.show = true;
      setTimeout(() => {
        const formValue = this.createIdentification;
        formValue.patchValue({
          type: this.partyTypeId,
          idvalue: this.partyValue,
        })
      }, 3000);
    }
    else {
      this.show = false;
    }
    this.identificationList();

    ///----CREDIT CARD ----////

    if (this.paymentMethodId) {
      this.spinner.show();
      this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'CREDIT_CARD')
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.creditCardValue = data.data;
            this.contactMechId = data.data.creditCardData.currentPostalAddress.contactMechId;
            this.creditCardForm.patchValue({
              ...data.data.creditCardData, ...{
                description: data.data.paymentMethodData.description
              }
            });
            this.editMode = true;

          }
        });

    }


    this.getCardTypes();
    this.getAccountDetailsCredit();

    //---GIFT CARD---/           

    if (this.paymentMethodId) {
      this.getPaymentMethod();
    }

    ///----EFT---////

    this.isEftAccount = this._ActivatedRoute.snapshot.queryParams.isEft === 'false' ? false : true;
    this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
    this.getAccountDetailsCredit();
    if (this.paymentMethodId) {
      this.getPaymentMethodEFT();
    }

    this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
    this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
    this.paymentMethodTypeId = this._ActivatedRoute.snapshot.queryParams.paymentMethodTypeId;
    this.getAccountDetailsCredit();
    this.getPaymentMethodTypes();
    if (this.paymentMethodId) {
      this.getPaymentMethodById();
    }

    ///---BILLING---////

    this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.billingAccountId;
    this.getCurrencyList();
    this.getPartyIds();
    if (this.paymentMethodId) {
      this.getBillingAccountById();
    }

    this.show = false;

    ///---RATES---///
    this.getCurrencyRate();
    this.getRatesInitialData();


    ////-----SKILL---///

    this.addSkillsService.add_skill_form = null;
    this.addSkillsService.addSkillForm(this.addSkillsService.add_skill_form);
    this.activatedRoute.queryParams.subscribe(params => [
      this.partyId = params["party"],
      this.Skillid = params["skid"],
      this.rate = params["rate"],
      this.skill = params["skill"],
      this.year = params["year"]
    ])
    if (this.Skillid) {
      this.show = true;
      setTimeout(() => {
        const formValue = this.addSkillsService.add_skill_form;
        formValue.patchValue({
          partyId: this.partyId, skillTypeId: this.Skillid, yearsExperience: this.year, rating: this.rate, skillLevel: this.skill,
        })
      }, 3000);
    }
    else {
      const formValue = this.addSkillsService.add_skill_form;
      formValue.patchValue({
        partyId: this.partyId,
      })
      this.show = false;
    }
    this.getPartyId();
    this.getSkillListSkill();

    ///---IDENTIFICATION NO---////
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.partyTypeId = params["partyTypeId"];
      this.partyValue = params["partyValue"];
    });
    if (this.partyTypeId) {
      this.show = true;
      setTimeout(() => {
        const formValue = this.createIdentificationNo;
        formValue.patchValue({
          type: this.partyTypeId,
          idvalue: this.partyValue,
        })
      }, 3000);
    }
    else {
      this.show = false;
    }
    this.identificationListID();

    /////----SEGMENTS---///

    this.show = false;
    this.getRoleAllType();
    this.getSegmentGroup();

    ///----CLASSIFICATION---////

    this.getClassificationGroupId();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.partyClassId = params["partyClassId"];
      this.efromDate = params["fromDate"];
      this.ethruDate = params["thruDate"];

    });

    if (this.partyClassId) {
      this.show = true;
      const fromdate = this.efromDate
      this.getEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");
      const ethruDate = this.ethruDate
      this.getthruDate = this.datePipe.transform(ethruDate, "yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.createClassification;
        formValue.patchValue({
          partyId: this.party,
          ClassificationGroupID: this.partyClassId,
          FromDate: this.getEstimateFrom,
          ThroughDate: this.getthruDate
        })
      }, 3000);
    }
    else {
      setTimeout(() => {
        const formValue = this.createClassification;
        formValue.patchValue({
          partyId: this.party,

        })
      }, 500);
      this.show = false;
    }

    ///---ATTACH----////

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params['party'];
      this.contentId = params['contentId'];
      this.partyContentTypeId = params['partyContentTypeId'];
      this.contentTypeId = params['contentTypeId'];
      this.fromDate = params['fromDate'];
      this.thruDate = params['thruDate'];
      this.statusId = params['statusId'];
      this.dataResourceId = params['dataResourceId']
    });
    this.getPartyContentA();
    this.getDataCategoryIdList();
    this.getPartyContentTypeIdList();
    this.getPartyStatusIdList();
    if (this.contentId) {
      this.show = true
      setTimeout(() => {
        const fromdate = this.fromDate
        this.editgetEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");
        const toEstdate = this.thruDate
        this.egetEstimateTo = this.datePipe.transform(toEstdate, "yyyy-MM-dd");
        const formValue = this.Attachform;
        formValue.patchValue({
          partyContentTypeId: this.partyContentTypeId,
          fromDate: this.editgetEstimateFrom,
          throughDate: this.egetEstimateTo,
          ContentTypeID: this.contentTypeId,
          StatusID: this.statusId

        })
      }, 3000);
    }
    else {
      this.show = false
    }

    ///---RESUMES---////
    this.getContentId();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.resumeId = params["resumeId"];
      this.contentId = params["contentId"];
      this.eresumeDate = params["resumeDate"];
      this.resumeText = params["resumeText"]
    });

    if (this.resumeId) {
      this.show = true;
      const fromdate = this.eresumeDate
      this.getEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.createResume;
        formValue.patchValue({
          resumetxt: this.resumeText,
          resumeDate: this.getEstimateFrom,
          contentId: this.contentId,
          resumeId: this.resumeId
        })
      }, 3000);
    }
    else {
      this.show = false;
    }


    ///----CONTACT---///

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.contactId = params["contactId"];
      this.preferredContactMechId = params["preferredContactMechId"];
      this.statusId = params["statusId"];
      this.efromDate = params["fromDate"];
      this.ethruDate = params["thruDate"];

    });
    this.getAllPartyContactListPartyStatus();
    this.getPartyInvitationStatusList();
    this.getPreferredContactMechId();
    this.getContactList();
    if (this.contactId) {
      this.show = true;
      const fromdate = this.efromDate
      this.getEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");
      const ethruDate = this.ethruDate
      this.getthruDate = this.datePipe.transform(ethruDate, "yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.createContact;
        formValue.patchValue({
          ContactListID: this.contactId,
          StatusID: this.statusId,
          FromDate: this.getEstimateFrom,
          ThroughDate: this.getthruDate,
          PreferredContactMechID: this.preferredContactMechId,
        })
      }, 3000);
    }
    else {

      this.show = false;
    }


    this.createEventFormService.employee_application_form = null;
    this.createEventFormService.employeeApplicationForm(this.createEventFormService.employee_application_form);
    this.statusIdEmployee();
    this.appSourceTypeList();
    this.getPositionType();
    this.getPartyId();
    this.getInternalJobDropdwon();
    this.activatedRoute.queryParams.subscribe(params => {
      this.updateId = params["updateId"];
      this.party = params["party"];
      if (this.updateId) {
        this.show = true;
        this.getEmployeeApplicationEmployee();
      }
      else {
        this.show = false;
      }
    })

    ///----Product--////

    this.getRoleAllType();
this.getOrderListItems();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.roleTypeId = params["roleTypeId"];
      this.sequenceNumber = params["sequenceNumber"];
    this.productStoreId=params["productStoreId"];
      this.efromDate = params["fromDate"];
      this.ethruDate = params["thruDate"];
      
    });
  
    if(this.roleTypeId) {
      this.show=true;
      const fromdate=this.efromDate
      this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
      const ethruDate=this.ethruDate
      this.getthruDate=this.datePipe.transform(ethruDate,"yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.createContactProduct;
      formValue.patchValue({
        RoleTypeID: this.roleTypeId,
        SequenceNum: this.sequenceNumber,
        FromDate: this.getEstimateFrom,
        ThroughDate: this.getthruDate,
        StoreID: this.productStoreId
      })
      }, 3000); 
    }
    else {
      
      this.show = false;
    }
 
  }



  ////////////////////////////////////FUNCTION//////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////////////////////

  getOrderListItems(): void {
    this.spinner.show();
    this.accountsService.getOrderList().subscribe((res) => {
      let productStores = res.data.productStores;
      this.spinner.hide();
      for (const value of productStores) {
        this.productStoresListArray.push({
          label: value.storeName,
          value: value.productStoreId,
        });
      }
    });
   
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
     
     onSubmitProduct() {
    this.spinner.show();
    const fromDate=this.createContactProduct.get('FromDate').value;
    this.fromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const thruDate=this.createContactProduct.get('ThroughDate').value;
    this.thruDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      
      "fromDate":  this.fromDate,
      "partyId":this.party,
   
      "thruDate": this.thruDate,
      "productStoreId": this.createContactProduct.value.StoreID,
      "roleTypeId": this.createContactProduct.value.RoleTypeID,
      "sequenceNum": this.createContactProduct.value.SequenceNum
     
     
  
     
    }
    this.accountsService.createStoreRole(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton24.nativeElement.click();
        this.getAllProductStoreRole();
        this.createContactProduct.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdateProduct() {
    this.spinner.show();
   
   
    const thruDate=this.createContactProduct.get('ThroughDate').value;
    this.thruDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
     
      "fromDate":   this.efromDate,
     
      "partyId":this.party,
   
      "thruDate": this.thruDate,
      "productStoreId": this.createContactProduct.value.StoreID,
      "roleTypeId": this.createContactProduct.value.RoleTypeID,
      "sequenceNum": this.createContactProduct.value.SequenceNum
  
     
    }
    this.accountsService.updateStoreRole(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton24.nativeElement.click();
        this.getAllProductStoreRole();
        this.createContactProduct.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }   
   

  getInternalJobDropdwon() {
    this.spinner.show();
    const jobList = {
      applicationDateFrom: "",
      applicationDateTo: "",
      applicationId: "",
      applyingPartyId: "",
      approverPartyId: "",
      jobRequisitionId: "",
      statusId: "",
      applicationDateFromSearchType: "Equals",
      applicationDateToSearchType: "Equals"
    }
    this.internalJobService.getInternalJobList(this.jobPost, jobList).subscribe((res: any) => {
      this.internalJobPostList = res.data;
      this.spinner.hide();
      for (const value of this.internalJobPostList) {
        this.appIdArray.push({
          label: value.applicationId,
          value: value.applicationId
        })
      }
    })

  }
  getPositionType() {
    this.spinner.show();
    const emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId: "",
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.reallocationsService.getEmployeePosition(this.employessSize, emplPosition).subscribe(res => {

      this.positionType = res.data;
      this.spinner.hide();
      for (const value of this.positionType) {
        this.positionArray.push({
          label: value.emplPositionId,
          value: value.emplPositionId
        })
      }
    })

  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  statusIdEmployee() {
    this.spinner.show();
    this.employeeApplicationService.getStatusList().subscribe(res => {
      this.statusList = res.data;
      this.spinner.hide();
      for (const value of this.statusList) {
        this.statusArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })

  }
  getEmployeeApplicationEmployee() {

    this.spinner.show();
    const data = {
      "applicationDateFrom": "",
      "applicationDateFromSearchType": "Contains",
      "applicationDateTo": "",
      "applicationDateToSearchType": "Contains",
      "applicationId": this.updateId,
      "applyingPartyId": "",
      "emplPositionId": "",
      "employmentAppSourceTypeId": "",
      "referredByPartyId": "",
      "statusID": [
        ""
      ]
    }
    this.employementApplicationService.getEmpJobList(this.applicationSize, data).subscribe(res => {
      // this.employmentApplication=res.data;
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.createEventFormService.employee_application_form;
        const date = this.datePipe.transform(res.data[0].applicationDate, "yyyy-MM-dd");
        formValue.patchValue({
          applicationId: res.data[0].applicationId,
          applicationDate: date,
          applyingPartyId: res.data[0].applyingPartyId,
          emplPositionId: res.data[0].emplPositionId,
          employmentAppSourceTypeId: res.data[0].employmentAppSourceTypeId,
          statusId: res.data[0].statusId,
          referredByPartyId: res.data[0].referredByPartyId

        })
      }, 3000);

    })

  }
  onSubmitEmployee() {
    this.spinner.show();
    const thrudate = this.createEventFormService.employee_application_form.get('applicationDate').value;
    this.updateethrdateStatus = this.datePipe.transform(thrudate, "yyyy-MM-dd hh:mm:ss");
    const formDate = {
      applicationDate: this.updateethrdateStatus,
      applicationId: this.createEventFormService.employee_application_form.value.applicationId,
      applyingPartyId: this.party,
      emplPositionId: this.createEventFormService.employee_application_form.value.emplPositionId,
      employmentAppSourceTypeId: this.createEventFormService.employee_application_form.value.employmentAppSourceTypeId,
      referredByPartyId: this.createEventFormService.employee_application_form.value.referredByPartyId,
      statusId: this.createEventFormService.employee_application_form.value.statusId
    }

    this.employeeApplicationService.createEmploymentApplication(formDate).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton23.nativeElement.click();
        this.getEmployeeApplication();
        this.createEventFormService.employee_application_form.reset();
      }
      else if (res.success == false) {
        this.toastr.error("Error");
        this.closebutton23.nativeElement.click();
        this.getEmployeeApplication();
        this.createEventFormService.employee_application_form.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const data of err.error.errors) {
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
    })

  }
  onUpdateEmployee() {
    this.spinner.show();
    const thrudate = this.createEventFormService.employee_application_form.get('applicationDate').value;
    this.updateethrdateStatus = this.datePipe.transform(thrudate, "yyyy-MM-dd hh:mm:ss");
    const formDate = {
      applicationDate: this.updateethrdateStatus,
      applicationId: this.createEventFormService.employee_application_form.value.applicationId,
      applyingPartyId: this.party,
      emplPositionId: this.createEventFormService.employee_application_form.value.emplPositionId,
      employmentAppSourceTypeId: this.createEventFormService.employee_application_form.value.employmentAppSourceTypeId,
      referredByPartyId: this.createEventFormService.employee_application_form.value.referredByPartyId,
      statusId: this.createEventFormService.employee_application_form.value.statusId
    }

    this.employeeApplicationService.updateEmployee(formDate, this.updateId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton23.nativeElement.click();
        this.getEmployeeApplication();
        this.createEventFormService.employee_application_form.reset();
      }
      else if (res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const data of err.error.errors) {
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
    })

  }

  appSourceTypeList() {
    this.spinner.show();
    this.employeeApplicationService.getAppsourceList().subscribe(res => {
      this.appSource = res.data;
      this.spinner.hide();
      for (const data of this.appSource) {
        this.appSourceArray.push({
          label: data.description,
          value: data.employmentAppSourceTypeId
        })
      }
    })

  }

  getAllPartyContactListPartyStatus() {
    this.spinner.show();

    this.accountsService.getAllPartyContactListPartyStatus(this.party).subscribe((res: any) => {
      this.AllPartyContactListPartyStatus = res.data;
      this.spinner.hide();
      for (const value of this.AllPartyContactListPartyStatus) {
        this.AllPartyContactListPartyStatusArray.push({
          label: value.data.description + '' + 'By' + value.data.setByUserLoginId + '' + 'Opt-in Code' + value.data.optInVerifyCode,
          value: value.data.statusId
        })
      }

    })

  }
  getContactList() {
    this.spinner.show();
    this.createCont.getContactList().subscribe(res => {
      this.contactList = res.data;
      this.spinner.hide();
      for (const value of this.contactList) {
        this.contactListArray.push({
          label: value.description,
          value: value.contactListId
        })
      }
    })

  }
  getPreferredContactMechId() {
    this.spinner.show();

    this.accountsService.getPreferredContactMechId(this.party).subscribe((res: any) => {
      this.PreferredContactMechId = res.data;
      this.spinner.hide();
      for (const value of this.PreferredContactMechId) {
        this.PreferredContactMechIdArray.push({
          label: value.contactMechId + '' + value.paAddress1 + ',' + value.paStateProvinceGeoId + ',' + value.paPostalCode + ',' + value.paCountryGeoId,
          value: value.contactMechId
        })
      }

    })

  }
  getPartyInvitationStatusList() {
    this.spinner.show();
    this.status = 'CONTACTLST_PARTY'
    this.accountsService.getPartyInvitationStatusList(this.status).subscribe((res: any) => {
      this.PartyInvitationStatusList = res.data;
      this.spinner.hide();
      for (const value of this.PartyInvitationStatusList) {
        this.PartyInvitationStatusListArray.push({
          label: value.data.description,
          value: value.data.statusId
        })
      }

    })

  }

  onSubmitContact() {
    this.spinner.show();
    const fromDate = this.createContact.get('FromDate').value;
    this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
    const thruDate = this.createContact.get('ThroughDate').value;
    this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "contactListId": this.createContact.value.ContactListID,
      "fromDate": this.fromDate,
      "partyId": this.party,
      "preferredContactMechId": this.createContact.value.PreferredContactMechID,
      "statusId": this.createContact.value.StatusID,
      "thruDate": this.thruDate



    }
    this.accountsService.createContactList(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton22.nativeElement.click();
        this.getPartyContactList();
        this.createContact.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  onUpdateContact() {
    this.spinner.show();


    const thruDate = this.createContact.get('ThroughDate').value;
    this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "contactListId": this.createContact.value.ContactListID,
      "fromDate": this.efromDate,
      "partyId": this.party,
      "preferredContactMechId": this.createContact.value.PreferredContactMechID,
      "statusId": this.createContact.value.StatusID,
      "thruDate": this.thruDate,

      "optInVerifyCode": this.createContact.value.OptinCode,
      "statusHistory": this.createContact.value.StatusHistory,


    }
    this.accountsService.updateContactList(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton22.nativeElement.click();
        this.getPartyContactList();
        this.createContact.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  getContentId(): void {
    this.spinner.show();
    this._CategoryService.getCatalogIdList()
      .then(data => {
        this.spinner.hide();
        if (data.success) {
          this.contentIds = data.data.map(content => {
            return {
              label: content.contentId,
              value: content.contentId
            };
          });
        }
      })

  }
  onSubmitResume() {
    this.spinner.show();
    const fromActualdate = this.createResume.get('resumeDate').value;
    this.resumeDate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "contentId": this.createResume.value.contentId,
      "partyId": this.party,
      "resumeDate": this.resumeDate,
      "resumeId": this.createResume.value.resumeId,
      "resumeText": this.createResume.value.resumetxt,


    }
    this.accountsService.createPartyResume(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton21.nativeElement.click();
        this.getPartyResume();
        this.createResume.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  onUpdateResume() {
    this.spinner.show();
    const fromActualdate = this.createResume.get('resumeDate').value;
    this.resumeDate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "contentId": this.createResume.value.contentId,
      "partyId": this.party,
      "resumeDate": this.resumeDate,
      "resumeId": this.createResume.value.resumeId,
      "resumeText": this.createResume.value.resumetxt,
    }
    this.accountsService.updatePartyResume(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  getPartyContentA() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        });
      }
    });

  }

  getPartyStatusIdList() {
    this.spinner.show();
    this.accountsService.getPartyStatusIdList().subscribe(res => {
      this.PartyStatusIdList = res.data;
      this.spinner.hide();
      for (const value of this.PartyStatusIdList) {
        this.PartyStatusIdListArray.push({
          label: value.description,
          value: value.statusId
        });
      }
    });

  }
  getPartyContentTypeIdList() {
    this.spinner.show();
    this.accountsService.getPartyContentTypeIdList().subscribe(res => {
      this.PartyContentTypeIdList = res.data[0].contentTypeIdList;
      this.spinner.hide();
      for (const value of this.PartyContentTypeIdList) {
        this.PartyContentTypeIdListArray.push({
          label: value.description,
          value: value.contentTypeId
        });
      }
    });

  }
  getDataCategoryIdList() {
    this.spinner.show();
    this.accountsService.getDataCategoryIdList().subscribe(res => {
      this.DataCategoryIdList = res.data[0].dataCategoryIdList;
      this.spinner.hide();
      for (const value of this.DataCategoryIdList) {
        this.DataCategoryIdListArray.push({
          label: value.categoryName,
          value: value.dataCategoryId
        });
      }
    });

  }

  onpartyContent(event) {
    this.partyContentTypeId = event;
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  onSubmitAttach() {
    this.spinner.show();
    let formData = new FormData();
    formData.append('file', this.fileData);
    const fromActualdate = this.Attachform.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.Attachform.get('throughDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "contentTypeId": this.Attachform.value.ContentTypeID,
      "dataCategoryId": this.Attachform.value.DataCategoryID,
      "fromDate": this.actualfromdate,
      "isPublic": this.Attachform.value.IsPublic,
      "partyContentTypeId": this.Attachform.value.partyContentTypeId,
      "partyId": this.partyId,
      "statusId": this.Attachform.value.StatusID,
      "thruDate": this.actualthroughdate
    }


    this.accountsService.createPartyContent(formData, encodeURIComponent(JSON.stringify(requestData))).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton20.nativeElement.click();
        this.getPartyContent();
        this.Attachform.reset();
      }
    }, (err) => {
      this.spinner.hide();
      this._ToastrService.error(err.message);

    });
  }
  onUpdateAttach() {
    this.spinner.show();
    let formData = new FormData();
    formData.append('file', this.fileData);
    const fromActualdate = this.Attachform.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.Attachform.get('throughDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "contentTypeId": this.Attachform.value.ContentTypeID,
      "dataCategoryId": this.Attachform.value.DataCategoryID,
      "fromDate": this.fromDate,
      "isPublic": this.Attachform.value.IsPublic,
      "partyContentTypeId": this.Attachform.value.partyContentTypeId,
      "partyId": this.partyId,
      "statusId": this.Attachform.value.StatusID,
      "thruDate": this.actualthroughdate
    }


    this.accountsService.updatePartyContent(formData, encodeURIComponent(JSON.stringify(requestData)), this.contentId, this.dataResourceId).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton20.nativeElement.click();
        this.getPartyContent();
        this.Attachform.reset();
      }
    }, (err) => {
      this.spinner.hide();
      this._ToastrService.error(err.message);

    });
  }

  getClassificationGroupId() {
    this.spinner.show();

    this.accountsService.getClassificationGroupId().subscribe((res: any) => {
      this.ClassificationGroupId = res.data;
      this.spinner.hide();
      for (const value of this.ClassificationGroupId) {
        this.ClassificationGroupIdArray.push({
          label: value.data.description,
          value: value.data.partyClassificationGroupId
        })
      }

    })

  }
  onSubmitClass() {
    this.spinner.show();
    const fromDate = this.createClassification.get('FromDate').value;
    this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
    const thruDate = this.createClassification.get('ThroughDate').value;
    this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate": this.fromDate,
      "partyClassificationGroupId": this.createClassification.value.ClassificationGroupID,
      "partyId": this.party,
      "thruDate": this.thruDate


    }
    this.accountsService.createPartyClassification(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton19.nativeElement.click();
        this.getPartyClassification();
        this.createClassification.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  onUpdateClass() {
    this.spinner.show();

    const thruDate = this.createClassification.get('ThroughDate').value;
    this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate": this.efromDate,
      "partyClassificationGroupId": this.createClassification.value.ClassificationGroupID,
      "partyId": this.party,
      "thruDate": this.thruDate


    }
    this.accountsService.updatePartyClassification(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton19.nativeElement.click();
        this.getPartyClassification();
        this.createClassification.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  getSegmentGroup() {
    this.spinner.show();

    this.accountsService.getSegmentGroup().subscribe((res: any) => {
      this.SegmentGroup = res.data;
      this.spinner.hide();
      for (const value of this.SegmentGroup) {
        this.SegmentGroupArray.push({
          label: value.segmentGroupId.description,
          value: value.segmentGroupId.segmentGroupId
        })
      }

    })

  }
 
  onSubmitSegments() {
    this.spinner.show();
    let requestData = {
      "partyId": this.party,
      "roleTypeId": this.createSegment.value.roleId,
      "segmentGroupId": this.createSegment.value.segmentId
    }

    this.accountsService.createPartySegmentRole(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton18.nativeElement.click();
        this.getSegmentRole();
        this.createSegment.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.debugMessage);
      }
    });

  }

  identificationListID() {
    this.spinner.show();
    this.accountsService.identificationList().subscribe((res: any) => {

      this.identification = res.data;
      this.spinner.hide();
      for (const value of this.identification) {
        this.identificationArray.push({
          label: value.description,
          value: value.partyIdentificationTypeId
        })
      }

    })

  }
  onSubmitIdentify() {
    this.spinner.show();
    const requestData = {
      "idValue": this.createIdentificationNo.value.idvalue,
      "partyId": this.party,
      "partyIdentificationTypeId": this.createIdentificationNo.value.type,

    }
    this.accountsService.createPartyIdentifications(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton17.nativeElement.click();
        this.getIdentificationList();
        this.createIdentificationNo.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  onUpdateIdentify() {
    this.spinner.show();
    const requestData = {
      "idValue": this.createIdentificationNo.value.idvalue,
      "partyId": this.party,
      "partyIdentificationTypeId": this.partyTypeId,
    }
    this.accountsService.updatePartyIdentifications(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton17.nativeElement.click();
        this.getIdentificationList();
        this.createIdentificationNo.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  getSkillListSkill() {
    this.spinner.show();
    this.addSkillService.getSkillTypeList().subscribe(res => {
      this.getSkillLists = res.data;
      this.spinner.hide();
      for (const value of this.getSkillLists) {
        this.skillArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }

    })

  }



  onSubmitSkill() {
    this.spinner.show();
    const formData = { ...this.addSkillsService.add_skill_form.value };
    this.addSkillService.createSkills(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Skill Subbmitted Successfully");
        this.closebutton16.nativeElement.click();
        this.getSkillList();
        this.addSkillsService.add_skill_form.reset();
      }

      else if (res.success === false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      this.spinner.hide();
    })

  }

  updateSkills() {
    this.spinner.show();
    const updateFormData = { ...this.addSkillsService.add_skill_form.value };
    this.addSkillService.updateSkills(updateFormData, this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Skill Updated Successfully");
        this.closebutton16.nativeElement.click();
        this.getSkillList();
        this.addSkillsService.add_skill_form.reset();
      }

      else if (res.success === false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      this.spinner.hide();
    })


  }


  getRatesInitialData() {
    this.spinner.show();
    this.accountsService.getRatesInitialData().subscribe(res => {

      this.periodTypes = res.data.periodTypes;
      this.rateTypes = res.data.rateTypes;

      this.spinner.hide();

      for (const value of this.periodTypes) {
        this.periodTypesArray.push({
          label: value.description,
          value: value.periodTypeId
        })
      }
      for (const value of this.rateTypes) {
        this.rateTypesArray.push({
          label: value.description,
          value: value.rateTypeId
        })
      }
    })
  }
  getCurrencyRate() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }

  onSubmitRate() {
    this.spinner.show();
    let requestData = {
      "partyId": this.party,
      "defaultRate": this.createParty.value.defaultRate,

      "percentageUsed": this.createParty.value.percentageUsed,
      "periodTypeId": "RATE_HOUR",
      "rateAmount": this.createParty.value.rateAmount,
      "rateCurrencyUomId": this.createParty.value.currency,
      "rateTypeId": this.createParty.value.rateType,
    }

    this.accountsService.createPartyRate(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton15.nativeElement.click();
        this.getPartyRate();
        this.createParty.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.debugMessage);
      }
    });

  }



  getBillingAccountById(): void {

    this.spinner.show();
    this._CRMAccountService.getBillingAccountById(this.paymentMethodId, this.partyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.billingAccount = data.data;
          this.availableBalance = data.data.availableBalance;
          this.billingAccountForm.patchValue({
            ...data.data
          });
          this.editMode = true;
        }

      });

  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });

  }
  getCurrencyList(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.currencyUomIds = data.data.currencyList.map(value => {
            return {
              label: value.uomId,
              value: value.uomId
            };
          });
        }
      });

  }
  submitBilling(): void {
    this.spinner.show();
    if (this.billingAccountForm.valid) {
      this._CRMAccountService.createBillingAccount(this.partyId, {
        ...this.billingAccountForm.value, ...{
          fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
          thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton14.nativeElement.click();
            this.getPaymentMethods();
            this.billingAccountForm.reset();
          }
        });
    }

  }
  updateBilling(): void {
    this.spinner.show();
    if (this.billingAccountForm.valid) {
      this._CRMAccountService.updateBillingAccount(this.paymentMethodId, this.partyId, {
        ...this.billingAccount, ...this.billingAccountForm.value, ...{
          fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
          thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.closebutton14.nativeElement.click();
            this.getPaymentMethods();
            this.billingAccountForm.reset();
          }
        });
    }

  }

  getAccountDetailsCredit(): void {
    this.spinner.show();
    this._CRMAccountService.getAccountById(this.partyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.accountDataCredit = data.data;
        }
      });

  }
  getPaymentMethodById(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'CHECK_ACCOUNT')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.paymentMethodValue = data.data.checkAccountData;
          if (data.data.checkAccountData.postalAddressList[0]) {
            this.contactMechId = data.data.checkAccountData.postalAddressList[0].contactMech.contactMechId;
          } else {
            this.contactMechId = data.data.checkAccountData.currentPostalAddress.contactMechId;
          }

          this.checkForm.patchValue({
            ...data.data.checkAccountData, ...{
              paymentMethodTypeId: this.paymentMethodTypeId, ...{
                description: data.data.paymentMethodData.description
              }
            }
          });

          this.editMode = true;
        }
      });

  }
  getPaymentMethodTypes(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodTypes()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.paymentMethodTypes = data.data.map(value => {
            return {
              label: value.description,
              value: value.paymentMethodTypeId
            };
          });
        }
      });

  }

  submitCheck(): void {
    this.spinner.show();
    if (this.checkForm.valid) {
      this._CRMAccountService.createCheckAccount(this.partyId, {
        ...this.checkForm.value, ...{
          contactMechId: this.contactMechId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton13.nativeElement.click();
            this.getPaymentMethods();
            this.checkForm.reset();
          }
        });
    }

  }

  updateCheck(): void {
    this.spinner.show();
    if (this.checkForm.valid) {
      this._CRMAccountService.updateCheckAccount(this.partyId, this.paymentMethodId, {
        ...this.paymentMethodValue, ...this.checkForm.value, ...{
          contactMechId: this.contactMechId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.cancelSubmit();
            this.editMode = false;
          }
        });
    }

  }

  getPaymentMethodEFT(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'EFT_ACCOUNT')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.eftAccount = data.data.eftAccountData;
          this.contactMechId = data.data.eftAccountData.postalAddressList[0].contactMech.contactMechId;
          this.eftAccountForm.patchValue({
            ...data.data.eftAccountData, ...{
              description: data.data.paymentMethodData.description
            }
          });
          this.editMode = true;

        }
      });

  }


  submitEFT(): void {
    this.spinner.show();
    if (this.isEftAccount) {
      if (this.eftAccountForm.valid) {
        this._CRMAccountService.createEftAccount(this.partyId, this.eftAccountForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.spinner.hide();
              this.closebutton12.nativeElement.click();
              this.getPaymentMethods();
              this.eftAccountForm.reset();
            }
          });
      }
    } else {
      if (this.eftAccountForm.valid) {
        this._CRMAccountService.createCheckAccount(this.partyId, this.eftAccountForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.spinner.hide();
              this.closebutton12.nativeElement.click();
              this.getPaymentMethods();
              this.cancelSubmit();
            }
          });
      }
    }

  }
  updateEFT(): void {
    this.spinner.show();
    if (this.eftAccountForm.valid) {
      this._CRMAccountService.updateEftAccount(this.partyId, this.paymentMethodId, {
        ...this.eftAccount, ...this.eftAccountForm.value, contactMechId: this.contactMechId
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.editMode = false;
            this.closebutton12.nativeElement.click();
            this.getPaymentMethods();
            this.cancelSubmit();
          }
        })
    }

  }


  getPaymentMethod(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'GIFT_CARD')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.giftCard = data.data.giftCardData;
          this.giftForm.patchValue({
            ...data.data.giftCardData, ...{
              description: data.data.paymentMethodData.description
            }
          });
          this.editMode = true;

        }
      });

  }
  submitGift(): void {
    this.spinner.show();
    if (this.giftForm.valid) {
      this._CRMAccountService.createGiftCard(this.partyId, this.giftForm.value)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton11.nativeElement.click();
            this.getPaymentMethods();
            this.giftForm.reset();

          }
        });
    }

  }
  updateGift(): void {
    this.spinner.show();
    if (this.giftForm.valid) {

      this._CRMAccountService.updateGiftCard(this.partyId, this.paymentMethodId, {
        ...this.giftCard, ...this.giftForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.closebutton11.nativeElement.click();
            this.getPaymentMethods();
            this.giftForm.reset();

          }
        });
    }

  }



  getCardTypes(): void {
    this.spinner.show();
    this._CRMAccountService.getCreditCardTypes()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.cardTypes = data.data.map(value => {
            return {
              label: value.enumCode,
              value: value.enumId
            };
          });
        }
      });

  }

  getAddress(event: any, contactMechId: string): void {
    this.contactMechId = (event.target.checked) ? contactMechId : '';
  }


  submitCredit(): void {
    this.spinner.show();
    if (this.creditCardForm.valid) {
      this._CRMAccountService.createCreditCard(this.partyId, {
        ...this.creditCardForm.value, ...{
          contactMechId: this.contactMechId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton10.nativeElement.click();
            this.getPaymentMethods();
            this.creditCardForm.reset();
          }
        });
    }

  }
  updateCredit(): void {
    this.spinner.show();
    if (this.creditCardForm.valid) {
      this._CRMAccountService.updateCreditCard(this.partyId, this.paymentMethodId, {
        ...this.creditCardValue.creditCardData, ...this.creditCardForm.value, ...{
          contactMechId: this.contactMechId,
          suffixOnCard: 'I'
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.closebutton10.nativeElement.click();
            this.getPaymentMethods();
            this.creditCardForm.reset();

          }
        });
    }

  }

  identificationList() {
    this.spinner.show();
    this.accountsService.identificationList().subscribe((res: any) => {

      this.identification = res.data;
      this.spinner.hide();
      for (const value of this.identification) {
        this.identificationArray.push({
          label: value.description,
          value: value.partyIdentificationTypeId
        })
      }

    })

  }
  onSubmitID() {
    this.spinner.show();
    const requestData = {
      "idValue": this.createIdentification.value.idvalue,
      "partyId": this.party,
      "partyIdentificationTypeId": this.createIdentification.value.type,

    }
    this.accountsService.createPartyIdentifications(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.getIdentificationList();
        this.createIdentification.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  onUpdateID() {
    this.spinner.show();
    const requestData = {
      "idValue": this.createIdentification.value.idvalue,
      "partyId": this.party,
      "partyIdentificationTypeId": this.partyTypeId,
    }
    this.accountsService.updatePartyIdentifications(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.getIdentificationList();
        this.createIdentification.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  getPartyContentParty() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        });
      }
    });

  }

  onSubmitContent() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.fileData);
    this.partyContentTypeId = this.Editform.get('partyContentTypeId').value;

    this.accountsService.attachPartyContent(formData, this.partyContentTypeId, this.partyId).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.getPartyContentsList();
        this.Editform.reset();

      }
    }, (err) => {
      this.spinner.hide();
      this._ToastrService.error(err.message);

    });
  }

  navigate() {
    this._location.back();
  }

  navigateFromCreate() {
    this._location.back();
  }
  onSubmitNote() {
    this.spinner.show();
    const form = { ...this.noteForm.value }
    this.myContactsService.createContactNote(form, this.partyIdForCreate).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getAccountNote();
        this.noteForm.reset();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )

  }

  onUpdateNote() {
    this.spinner.show();
    const form = { ...this.noteForm.value }
    this.myContactsService.updateContactNote(form, this.noteId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getAccountNote();
        this.noteForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }

  getAccountDetails(): void {
    this.spinner.show();
    this._CRMAccountService.getAccountById(this.partyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.accountInfo = data.data.contactInformation.filter(value => {
            if (value.contactMech && (value.contactMech.contactMechId === this.contactMechId)) {
              this.addressForm.patchValue({
                ...value.postalAddress, ...{
                  contactMechPurposeTypeId: value.partyContactMechPurposes[0].contactMechPurposeTypeId
                }
              })
              return value.postalAddress;
            }
          });
          this.addressForm.controls.contactMechPurposeTypeId.disable();
          this.addressForm.controls.contactMechPurposeTypeId.updateValueAndValidity();

        }
      });

  }
  getPreContactMechIds(): void {
    this.spinner.show();
    this._CRMAccountService.getContactPurposes(this.partyId, 'POSTAL_ADDRESS')
      .then(data => {
        if (data.success) {
          this.contactMechTypeIdAdd = data.data.purposeTypes.map(value => {
            return {
              label: value.description,
              value: value.contactMechPurposeTypeId
            };
          });
        }
      });
    this.spinner.hide();

  }
  getCountries(): void {
    this.spinner.show();
    this._GlobalResourceService.getCountryList()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.countryArray = data.data.countryList.map(value => {
            return {
              label: value.split(':')[0].trim(),
              value: value.split(':')[1].trim()
            };
          });
        }
      });

  }
  getStates(countryId: string): void {
    this.spinner.show();
    this._GlobalResourceService.getStateList(countryId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.stateArray = data.data.stateList.map(value => {
            return {
              label: value.split(':')[0].trim(),
              value: value.split(':')[1].trim()
            };
          });
        }
      });

  }
  submitAddress(): void {
    this.spinner.show();
    if (this.addressForm.valid) {
      this._CRMAccountService.createPostalAddress({
        ...this.addressForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton6.nativeElement.click();
            this.getEmployeeDetail();
            this.addressForm.reset();

          }
        });
    }

  }

  updateAddress(): void {
    this.spinner.show();
    if (this.addressForm.valid) {
      this._CRMAccountService.updatePostalAddress({
        ...this.accountInfo[0], ...this.addressForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.addressForm.controls.contactMechPurposeTypeId.enable();
            this.addressForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
            this.closebutton6.nativeElement.click();
            this.getEmployeeDetail();
            this.addressForm.reset();

          }
        });
    }

  }
  getContactDetails(): void {
    this.spinner.show();
    this._DetailedEmployeeSummary.getTelecomById(this.contactMechId, this.partyId)
      .then(data => {
        this.contact = {
          areaCode: data.areaCode,
          askForName: data.askForName,
          countryCode: data.countryCode,
          contactNumber: data.contactNumber,
          contactMechId: data.contactMechId,
          allowSolicitation: data.allowSolicitation,
          contactMechPurposeTypeId: data.contactMechPurposeTypeId,
          contactMechTypeId: data.contactMechTypeId,
          extension: data.extension,
          partyId: data.partyId,
          preContactMechTypeId: 'TELECOM_NUMBER'
        };
        this.contactForm.patchValue(this.contact);
        this.spinner.hide();
        this.contactForm.controls.contactMechPurposeTypeId.disable();
        this.contactForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
      });

  }
  getContactPurpose(): void {
    this.spinner.show();
    this._CRMAccountService.getContactPurposes(this.partyId, 'TELECOM_NUMBER')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.contactPurpose = data.data.purposeTypes.map(value => {
            return {
              label: value.description,
              value: value.contactMechPurposeTypeId
            };
          });
        }
      });

  }
  updateContact(): void {
    this.spinner.show();
    if (this.contactForm.valid) {
      this._DetailedEmployeeSummary.updateEmployeeTelecomNumber({
        ...this.contact, ...this.contactForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.closebutton5.nativeElement.click();
            this.getEmployeeDetail();
            this.contactForm.reset();

          }
        });
    }

  }
  submitContact(): void {
    this.spinner.show();
    if (this.contactForm.valid) {
      this._DetailedEmployeeSummary.createEmployeeTelecomNumber({
        ...this.contactForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton5.nativeElement.click();
            this.getEmployeeDetail();
            this.contactForm.reset();

          }
        });
    }

  }
  onSubmitParty() {
    this.spinner.show();

    const requestData = {
      "attrDescription": this.createAttribue.value.attrDescription,
      "attrName": this.createAttribue.value.attrName,
      "attrValue": this.createAttribue.value.attrValue,
      "partyId": this.party


    }
    this.accountsService.createPartyAttribute(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.getPartyAttribute();
        this.createAttribue.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  onUpdateParty() {
    this.spinner.show();

    const requestData = {
      "attrDescription": this.createAttribue.value.attrDescription,
      "attrName": this.createAttribue.value.attrName,
      "attrValue": this.createAttribue.value.attrValue,
      "partyId": this.party
    }
    this.accountsService.updatePartyAttribute(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.getPartyAttribute();
        this.createAttribue.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  getAccountList() {
    this.spinner.show();
    const searchAccountForm = {
      contactMechTypeId: this.contactMechTypeId,
      groupName: this.groupName,
      groupNameSearchType: this.groupNameSearchType,
      partyId: this.partyIds,
      partyIdSearchType: this.partyIdSearchType
    }
    const all = "true"
    this.accountsService.getAccountList(searchAccountForm, all).subscribe(res => {
      this.accountData = res.data;
      this.spinner.hide();
      for (const value of this.accountData) {
        this.accountArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }

    })

  }

  onSubmitAccount() {
    this.spinner.show();
    this.fileUploadForm.get('contactPartyId').patchValue(this.partyId);
    this.fileUploadForm.get('partyId').patchValue(this.partyId);
    const formData = new FormData();
    const formdata = { ...this.fileUploadForm.value };
    this.MyContactsService.RelatedAccount(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.accountDetail();
        this.fileUploadForm.reset();
      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();

  }

  onSubmitLogin() {
    this.spinner.show();
    const requestData = {
      "currentPassword": this.createUser.value.currentpass,
      "currentPasswordVerify": this.createUser.value.verifypass,
      "externalAuthId": this.createUser.value.extAuthId,
      "partyId": this.party,
      "passwordHint": this.createUser.value.passHint,
      "requirePasswordChange": this.createUser.value.passrequire,
      "userLoginId": this.createUser.value.loginId,

    }
    this.accountsService.createProfileUserLogin(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.getUserLoginData();
        this.createUser.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.debugMessage);
      }
    });

  }
  onUpdateInfo() {
    this.spinner.show();
    const formData = { ... this.empPersonalFormService.emp_personal_form.value };
    this.employeeService.updateEmpInfo(this.partyId, formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getPerson();
        this.empPersonalFormService.emp_personal_form.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })

  }
  getEmpStatus() {
    this.spinner.show();
    this.employeeService.getStatus().subscribe(res => {
      this.crmStatus = res.data;
      this.spinner.hide();
      for (const value of this.crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })

  }
  onCancel() {
    this.router.navigate(["/hr/employees/employee-details"], { queryParams: { employeeDetailId: this.partyId } });
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        this.spinner.hide();
        this.currencyList = data.data.currencyList.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });

  }
  getEmployeeDetailInfo() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.partyId).subscribe(res => {
      this.spinner.hide();
      const formValue = this.empPersonalFormService.emp_personal_form;
      formValue.patchValue({
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        partyId: res.data.partyId,
        statusId: res.data.statusId,
        description: res.data.comments,
        externalId: res.data.externalId,
        comments: res.data.comments,
        preferredCurrencyUomId: res.data.preferredCurrencyUomId
      })

    })

  }


  getPartyContent() {
    this.spinner.show();
    this.accountsService.getPartyContent(this.taxAuthPartyId).subscribe(res => {
      this.PartyContentData = res.data;
      this.spinner.hide();
    })

  }
  cancelSubmit() {
    this._location.back();
  }
  getPartyIcsAvsOverRide() {
    this.spinner.show();
    this.accountsService.getPartyIcsAvsOverRide(this.taxAuthPartyId).subscribe(res => {
      this.PartyIcsAvsOverRide = res.data;
      this.spinner.hide();
    })

  }
  updateAVS() {
    this.router.navigate(['partyQuickLink/create-avs-string'], { queryParams: { party: this.taxAuthPartyId } });
  }
  resetAvsOverride() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.accountsService.resetAvsOverride(this.taxAuthPartyId).subscribe(res => {
          this.getPartyIcsAvsOverRide();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  getTaxInfo() {
    this.spinner.show();
    this.accountsService.getTaxInfo(this.finSize, this.taxAuthPartyId).subscribe(res => {
      this.TaxInfo = res.data;
      this.spinner.hide();
    })

  }
  createTaxInfo() {
    this.router.navigate(['partyQuickLink/create-tax-info'], { queryParams: { party: this.taxAuthPartyId } });
  }
  editContent(contentId, partyContentTypeId, contentTypeId, fromDate, thruDate, statusId, dataResourceId) {
    this.router.navigate(["financial/add-party-content"], { queryParams: { party: this.taxAuthPartyId, contentId: contentId, partyContentTypeId: partyContentTypeId, contentTypeId: contentTypeId, fromDate: fromDate, thruDate: thruDate, statusId: statusId, dataResourceId: dataResourceId } });
  }
  updateTaxInfo(product) {
    this.router.navigate(['partyQuickLink/create-tax-info'], { queryParams: { party: this.taxAuthPartyId, taxAuthGeoId: product.getTaxInfo.taxAuthGeoId, taxAuthPartyId: product.getTaxInfo.taxAuthPartyId, fromDate: product.fromDate, thruDate: product.thruDate, partyTaxId: product.getTaxInfo.partyTaxId, isExempt: product.getTaxInfo.isExempt, isNexus: product.getTaxInfo.isNexus } });
  }

  deleteTaxInfo(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "fromDate": product.fromDate,
          "partyId": this.taxAuthPartyId,
          "taxAuthGeoId": product.getTaxInfo.taxAuthGeoId,
          "taxAuthPartyId": product.getTaxInfo.taxAuthPartyId,


        }
        this.accountsService.deleteTaxInfo(req).subscribe(res => {
          this.getTaxInfo();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  getShoppingList(): void {
    this.spinner.show();
    this.ShoppingListArray = []
    this.accountsService.getShoppingList(this.finSize, this.taxAuthPartyId).subscribe((res) => {
      let ShoppingList = res.data;
      this.spinner.hide();
      for (const value of ShoppingList) {
        this.ShoppingListArray.push({
          label: value.shoppingListWithListName.listName,
          value: value.shoppingListWithListName.shoppingListId,
        });
      }
    });

  }
  createPartyEmptyShopping() {
    this.spinner.show();

    this.accountsService.createPartyEmptyShopping(this.taxAuthPartyId).subscribe((res: any) => {

      if (res.success) {
        this.getShoppingList();
        this.toastr.success("Created Successfully");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getAllFinanceHistory() {
    this.spinner.show();
    this.accountsService.getAllFinanceHistory(this.finSize, this.taxAuthPartyId).subscribe(res => {
      this.AllFinanceHistory = res.data;
      this.spinner.hide();
    })

  }
  getRelationshipPartyIdTo() {
    this.spinner.show();
    this.accountsService.getRelationshipPartyIdTo(this.taxAuthPartyId).subscribe(res => {
      this.RelationshipPartyIdTo = res.data;
      this.spinner.hide();
    })

  }

  deleteOtherRelationship(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "comments": product.data.comments,
          "fromDate": product.fromDate,
          "partyId": this.taxAuthPartyId,
          "partyIdFrom": product.data.partyIdFrom,
          "partyIdTo": product.data.partyIdTo,
          "partyRelationshipTypeId": product.data.partyRelationshipTypeId,
          "roleTypeIdFrom": product.data.roleTypeIdFrom,
          "roleTypeIdTo": product.data.roleTypeIdTo,
          "securityGroupId": product.data.securityGroupId == null ? '' : product.data.securityGroupId,
          "statusId": product.data.statusId == null ? '' : product.data.statusId,
          "thruDate": product.thruDate == null ? '' : product.thruDate,
        }
        this.accountsService.deleteOtherRelationship(req).subscribe(res => {
          this.getRelationshipPartyIdTo();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  getPreferences() {
    this.spinner.show();
    this.accountsService.getPreferences(this.taxAuthPartyId).subscribe(res => {
      this.Preferences = res.data;
      this.spinner.hide();
    })

  }

 
  getEmployeeApplication() {
    this.spinner.show();
    const data = {
      "applicationDateFrom": "",
      "applicationDateFromSearchType": "Contains",
      "applicationDateTo": "",
      "applicationDateToSearchType": "Contains",
      "applicationId": "",
      "applyingPartyId": this.taxAuthPartyId,
      "emplPositionId": "",
      "employmentAppSourceTypeId": "",
      "referredByPartyId": "",
      "statusID": [
        ""
      ]
    }
    this.employementApplicationService.getEmpJobList(this.finSize, data).subscribe(res => {
      this.employmentApplication = res.data;
      this.spinner.hide();
    })
  }
  getVisitors() {
    this.spinner.show();

    this.accountsService.getVisitors(this.finSize).subscribe(res => {
      this.Visitors = res.data;
      this.spinner.hide();
    })
  }

  onDeleteEmp(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.employeeApplicationService.deleteEmployeeApp(id).subscribe((res: any) => {
          if (res.success) {
            this.getEmployeeApplication();
          }
          else if (res.success == false) {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  getAllProductStoreRole() {
    this.spinner.show();
    this.accountsService.getAllProductStoreRole(this.taxAuthPartyId).subscribe(res => {
      this.AllProductStoreRole = res.data;
      this.spinner.hide();
    })

  }
  getProductStore() {
    this.spinner.show();
    this.accountsService.getProductStore(this.taxAuthPartyId).subscribe(res => {
      this.ProductStore = res.data;
      this.spinner.hide();
    })

  }

  getPartyLoyaltyPoints() {
    this.spinner.show();
    this.accountsService.getPartyLoyaltyPoints(this.taxAuthPartyId).subscribe(res => {
      this.PartyLoyaltyPoints = res.data;
      this.spinner.hide();
    })

  }
  getPerson() {
    this.spinner.show();
    this.accountsService.getPerson(this.taxAuthPartyId).subscribe(res => {
      this.Person = res.data.personInformation;
      this.spinner.hide();
    })

  }


  getPartyContactList() {
    this.spinner.show();
    this.accountsService.getPartyContactList(this.taxAuthPartyId).subscribe(res => {
      this.PartyContactList = res.data;
      this.spinner.hide();
    })

  }
  getPartyAttribute() {
    this.spinner.show();
    this.accountsService.getPartyAttribute(this.taxAuthPartyId).subscribe(res => {
      this.PartyAttribute = res.data;
      this.spinner.hide();
    })

  }
  getUserLoginData() {
    this.spinner.show();
    this.accountsService.getUserLoginData(this.taxAuthPartyId).subscribe(res => {
      this.UserLoginData = res.data;
      this.spinner.hide();
    })

  }
  getPartyRate() {
    this.spinner.show();
    this.accountsService.getPartyRate(this.taxAuthPartyId).subscribe(res => {
      this.PartyRate = res.data;
      this.spinner.hide();
    })

  }
  getPartyClassification() {
    this.spinner.show();
    this.accountsService.getPartyClassificationByPartyId(this.taxAuthPartyId).subscribe(res => {
      this.PartyClassification = res.data;
      this.spinner.hide();
    })

  }

  editAddShoping() {
    this.router.navigate(['partyQuickLink/edit-shopping-list'], { queryParams: { party: this.taxAuthPartyId, shoppingListId: this.shoppingList.value.shoppingList } })
  }
  getIdentificationList() {
    this.spinner.show();
    this.accountsService.getIdentificationList(this.taxAuthPartyId).subscribe(res => {
      this.IdentificationList = res.data;
      this.spinner.hide();
    })

  }

  getSegmentRole() {
    this.spinner.show();
    this.accountsService.getSegmentRole(this.taxAuthPartyId).subscribe(res => {
      this.SegmentRole = res.data;
      this.spinner.hide();
    })

  }
  getPartyResume() {
    this.spinner.show();
    this.accountsService.getPartyResume(this.taxAuthPartyId).subscribe(res => {
      this.PartyResume = res.data;
      this.spinner.hide();
    })

  }

  updatePerson() {
    this.router.navigate(['/hr/employees/update-personal-info'], { queryParams: { personId: this.taxAuthPartyId } })
  }
  getEmployeeDetail() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.taxAuthPartyId).subscribe(res => {
      this.employeeDetail = res.data;
      res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;

      this.emergencyContact = res.data.emergencyContacts;
      this.demoName = res.data.firstName + " " + res.data.lastName;
      this.demoId = res.data.partyId;
      this.employeeDetailArray.push({
        data: this.employeeDetail
      })
      this.employeeDetailArrayEmail.push({
        data: this.employeeDetail
      })
      this.employeeDetailArrayPhone.push({
        data: this.employeeDetail
      })

    })
    this.spinner.hide();
  }

  deletePartyIdentification(partyTypeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deletePartyIdentification(this.taxAuthPartyId, partyTypeId).subscribe(res => {
          this.getIdentificationList();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }

  deleteStoreRole(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "fromDate": product.fromDate,
          "partyId": product.data.partyId,
          "productStoreId": product.data.productStoreId,
          "roleTypeId": product.data.roleTypeId,
          "sequenceNum": product.data.sequenceNum,
          "thruDate": product.thruDate,


        }
        this.accountsService.deleteStoreRole(req).subscribe(res => {
          this.getAllProductStoreRole();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  deleteContactList(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "contactListId": product.data.contactListId,
          "fromDate": product.fromDate,
          "partyId": product.data.partyId,
          "preferredContactMechId": product.data.preferredContactMechId,
          "statusId": product.data.statusId,
          "thruDate": product.thruDate,


        }
        this.accountsService.deleteContactList(req).subscribe(res => {
          this.getPartyContactList();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  deletePartyAttribute(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "attrDescription": product.attrDescription,
          "attrName": product.attrName,
          "attrValue": product.attrValue,
          "partyId": product.partyId,

        }
        this.accountsService.deletePartyAttribute(req).subscribe(res => {
          this.getPartyAttribute();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  deletePartyClassification(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "fromDate": product.fromDate,
          "partyClassificationGroupId": product.partyRateData.partyClassificationGroupId,
          "partyId": product.partyRateData.partyId,
          "thruDate": product.thruDate,
        }
        this.accountsService.deletePartyClassification(req).subscribe(res => {
          this.getPartyClassification();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  deletePartySegment(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "partyId": product.segmentGroupId.partyId,
          "roleTypeId": product.segmentGroupId.roleTypeId,
          "segmentGroupId": product.segmentGroupId.segmentGroupId,
        }
        this.accountsService.deletePartySegment(req).subscribe(res => {
          this.getSegmentRole();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }

  deletePartRate(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "defaultRate": product.partyRateData.defaultRate,
          "fromDate": product.fromDate,
          "partyId": product.partyRateData.partyId,
          "percentageUsed": product.partyRateData.percentageUsed,
          "periodTypeId": "RATE_HOUR",
          "rateAmountFromDate": product.fromDate,
          "rateCurrencyUomId": "USD",
          "rateTypeId": product.partyRateData.rateTypeId,
        }
        this.accountsService.deletePartRate(req).subscribe(res => {
          this.getPartyRate();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  deletePartyResume(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "contentId": product.data.contentId,
          "partyId": product.data.partyId,
          "resumeDate": product.data.resumeDate,
          "resumeId": product.data.resumeId,
          "resumeText": product.data.resumeText,
        }
        this.accountsService.deletePartyResume(req).subscribe(res => {
          this.getPartyResume();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  onDeleteSkill(partyId, skillTypeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.skillService.deleteSkills(partyId, skillTypeId).subscribe(res => {
          this.getSkillList();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  getSkillList() {
    this.spinner.show();
    const req = {
      "pageNo": this.pageNo,
      "pageSize": this.pageSize
    }
    const skillList = {
      partyId: this.taxAuthPartyId,
      rating: "",
      skillLevel: "",
      skillTypeId: "",
      yearsExperience: ""
    }


    this.skillService.getSkillList(req, skillList).subscribe(res => {
      this.skillList = res.data;
      this.spinner.hide();
    })

  }

  setPartyLink() {
    this.spinner.show();

    this.accountsService.setPartyLink(this.createLinkParty.value.partyIdTo, this.createLinkParty.value.partyIdFrom).subscribe((res: any) => {

      if (res.success) {

        this.toastr.success("Created Successfully");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdateVendor() {
    this.spinner.show();
    const requestData = {
      "manifestCompanyName": this.createVendor.value.ManifestCompany,
      "manifestCompanyTitle": this.createVendor.value.CompanyTitle,
      "manifestLogoUrl": this.createVendor.value.logo,
      "manifestPolicies": this.createVendor.value.policies,
      "partyId": this.taxAuthPartyId,

    }

    this.accountsService.updateVendors(requestData).subscribe((res: any) => {

      if (res.success) {
        this.editVendorsData();
        this.toastr.success("Updated Successfully");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  editVendorsData() {
    this.spinner.show();
    this.accountsService.getVendorListById(this.taxAuthPartyId).subscribe((res: any) => {

      setTimeout(() => {


        const formValue = this.createVendor;
        formValue.patchValue({
          ManifestCompany: res.data.manifestCompanyName,
          CompanyTitle: res.data.manifestCompanyTitle,
          logo: res.data.manifestLogoUrl,
          policies: res.data.manifestPolicies,




        })

      }, 3000);

      this.spinner.hide();
    })
  }
  accountDetail() {
    this.spinner.show();
    this.accountsService.getAccountById(this.taxAuthPartyId).subscribe(res => {
      this.accountDetails = res.data;
      //this.isAdditionalDetail = res.data.additionalInformation.infoType ? true : false;
      this.isJobDetail = res.data.jobInformation.jobStatus ? true : false;
      res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;
      this.relatedContact = res.data.relatedContacts;
      this.accountName = res.data.groupName;
      this.addr = res.data.address.address1 + ' ' + res.data.address.city + ' ' + res.data.address.postalCode + ' ' + res.data.address.countryGeoId;
      this.emailId = res.data.email.infoString;
      this.phoneNumber = res.data.telenumber.countryCode + ' ' + res.data.telenumber.areaCode + ' ' + res.data.telenumber.contactNumber
    })
    this.spinner.hide();
  }

  getPartyRole() {
    this.accountsService.getPartyRole(this.finSize, this.taxAuthPartyId).subscribe((res: any) => {
      this.roleList = res.data;
    })
  }

  getAccountNote() {
    this.spinner.show();
    this.accountsService.getContactNotes(this.taxAuthPartyId).subscribe(res => {
      this.accountDataNote = res.data;
      this.spinner.hide();
    });
  }

  deleteRoleTypeId(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteRoleTypeId(this.taxAuthPartyId, id).subscribe((res: any) => {
          this.toastr.success("Delete Successfully");
          this.getPartyRole();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  getPartyContentsList() {
    this.spinner.show();
    this.accountsService.getPartyContentList(this.taxAuthPartyId).subscribe(res => {
      this.contentList = res.data;
      this.spinner.hide();
    })

  }



  getBillingAccounts(): void {
    this.spinner.show();
    this._CRMAccountService.getBillingAccounts(this.taxAuthPartyId)
      .then(data => {
        if (data.success) {
          this.billingAccounts = data.data.map(value => {
            return {
              ...value, ...{ type: 'BILLING', paymentMethodTypeId: 'BILLING' }
            };
          });
          this.paymentMethods = [...this.paymentMethods, ...this.billingAccounts];
        }
        this.spinner.hide();
      });

  }

  getPaymentMethods(): void {
    this.spinner.show();
    this._CRMAccountService.getAllPayment(this.taxAuthPartyId, false)
      .then(data => {
        if (data.success) {
          this.paymentMethods = data.data.map(value => {
            switch (value.paymentMethod.paymentMethodTypeId) {
              case 'GIFT_CARD':
                return {
                  ...value.giftCard, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Gift Card'
                  }
                };
                break;
              case 'EFT_ACCOUNT':
                return {
                  ...value.eftAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'EFT Account'
                  }
                };
                break;
              case 'CERTIFIED_CHECK':
                return {
                  ...value.certifiedCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Certified Check'
                  }
                };
                break;
              case 'CREDIT_CARD':
                return {
                  ...value.creditCard, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Credit Card'
                  }
                };
                break;
              case 'COMPANY_CHECK':
                return {
                  ...value.companyCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Company Check'
                  }
                }
                break;
              case 'PERSONAL_CHECK':
                return {
                  ...value.personalCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Personal Check'
                  }
                };
            }
          });
          this.getBillingAccounts();
          this.spinner.hide();
        }
      });

  }

  removePaymentMethod(type: string, paymentMethodId: string): void {
    if (type === 'BILLING') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this._CRMAccountService.removeBillingAccount(this.taxAuthPartyId, paymentMethodId)
            .then(data => {
              if (data.success) {
                this.getPaymentMethods();
              }
            });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })


    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this._CRMAccountService.removeGiftCard(paymentMethodId)
            .then(data => {
              if (data.success) {
                this.getPaymentMethods();
              }
            });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })


    }
  }

  paymentMethodNavigation(type: string, paymentMethodId: string): void {
    switch (type) {
      case 'GIFT_CARD':
        this.router.navigate(['/crm/accounts/create-gift-card'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId } });
        break;
      case 'EFT_ACCOUNT':
        this.router.navigate(['/crm/accounts/create-eft-account'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, isEftAccount: true } });
        break;
      case 'CERTIFIED_CHECK':
        this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, paymentMethodTypeId: 'CERTIFIED_CHECK' } });
        break;
      case 'CREDIT_CARD':
        this.router.navigate(['/crm/accounts/create-credit-card'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId } });
        break;
      case 'COMPANY_CHECK':
        this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, paymentMethodTypeId: 'COMPANY_CHECK' } });
        break;
      case 'BILLING':
        this.router.navigate([`/crm/accounts/create-billing-account`], { queryParams: { partyId: this.taxAuthPartyId, billingAccountId: paymentMethodId } });
        break;
      case 'PERSONAL_CHECK':
        this.router.navigate([`/crm/accounts/create-check-account`], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, paymentMethodTypeId: 'PERSONAL_CHECK' } });
        break;
    }
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  export(id: any, contentName: any) {
    this.spinner.show();
    this.accountsService.downloadView(id).subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = contentName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }


  onDelete(contentId, fromDate, partyContent, partyId): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deletePartyContentData(contentId, fromDate, partyContent, partyId).subscribe(res => {
          if (res['data'].responseMessage === 'success') {
          }
          this.getPartyContent();
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  createRoleType() {
    this.router.navigate(["financial/party-role-type"], { queryParams: { party: this.taxAuthPartyId } });

  }

  createAddRole() {
    this.router.navigate(["financial/party-add-to-role"], { queryParams: { party: this.taxAuthPartyId } });

  }
  createIdentificationNumber() {
    this.router.navigate(["financial/party-create-identification-number"], { queryParams: { party: this.taxAuthPartyId } });
  }
  updatePartyIdentification(epartyTypeId, epartyValue) {
    this.router.navigate(["financial/party-create-identification-number"], { queryParams: { party: this.taxAuthPartyId, partyTypeId: epartyTypeId, partyValue: epartyValue } });
  }
  createNewRelationshipType() {
    this.router.navigate(["financial/new-relationship-type"], { queryParams: { party: this.taxAuthPartyId } });
  }
  createAddOtherpartyRelationship() {
    this.router.navigate(["financial/add-other-party-relationship"], { queryParams: { party: this.taxAuthPartyId } });
  }
  updateAddOtherpartyRelationship(activity) {
    this.router.navigate(["financial/add-other-party-relationship"], { queryParams: { party: this.taxAuthPartyId, fromDate: activity.fromDate, thruDate: activity.thruDate, comment: activity.data.comments, partyIdFrom: activity.data.partyIdFrom, isA: activity.isA, partyRelationshipTypeId: activity.data.partyRelationshipTypeId, roleTypeIdFrom: activity.data.roleTypeIdFrom, roleTypeIdTo: activity.data.roleTypeIdTo, securityGroupId: activity.data.securityGroupId, statusId: activity.data.statusId } });
  }

  createEditPartyRates() {
    this.router.navigate(["financial/edit-party-rates"], { queryParams: { party: this.taxAuthPartyId } });
  }
  createAddPartySkill() {
    this.router.navigate(["financial/add-party-skill"], { queryParams: { party: this.taxAuthPartyId } });
  }
  onUpdateSkill(eskid, erating, eskill, eyear) {
    this.router.navigate(["financial/add-party-skill"], { queryParams: { party: this.taxAuthPartyId, skid: eskid, rate: erating, skill: eskill, year: eyear } })
  }
  createAddPartyContent() {
    this.router.navigate(["financial/add-party-content"], { queryParams: { party: this.taxAuthPartyId } });
  }
  createAddPartySegmentRole() {
    this.router.navigate(["financial/add-party-segment-role"], { queryParams: { party: this.taxAuthPartyId } });
  }
  createAddPartyResumes() {
    this.router.navigate(["financial/add-party-resumes"], { queryParams: { party: this.taxAuthPartyId } });
  }
  createEmployeeAppilcation() {
    this.router.navigate(["partyQuickLink/create-party-employee-application"], { queryParams: { party: this.taxAuthPartyId } });
  }
  onUpdate(id) {
    this.router.navigate(["partyQuickLink/create-party-employee-application"], { queryParams: { party: this.taxAuthPartyId, updateId: id } });
  }

  updateResume(eresumeId, econtentId, eresumeDate, eresumeText) {
    this.router.navigate(["financial/add-party-resumes"], { queryParams: { party: this.taxAuthPartyId, resumeId: eresumeId, contentId: econtentId, resumeDate: eresumeDate, resumeText: eresumeText } });
  }
  updatePassword(loginId) {
    this.router.navigate(["partyQuickLink/update-password"], { queryParams: { party: this.taxAuthPartyId, loginid: loginId } });
  }
  securityList(loginId) {
    this.router.navigate(["partyQuickLink/party-security-group-list"], { queryParams: { party: this.taxAuthPartyId, loginid: loginId } });
  }

  createEditPersonalInformation() {
    this.router.navigate(["financial/edit-personal-information"]);
  }
  createAddRelatedAccount() {
    this.router.navigate(["financial/add-related-account"], { queryParams: { party: this.taxAuthPartyId } });
  }

  createAddPartyClassification() {
    this.router.navigate(["partyQuickLink/create-party-classification"], { queryParams: { party: this.taxAuthPartyId } });
  }

  createProductStore() {
    this.router.navigate(["partyQuickLink/create-product-store"], { queryParams: { party: this.taxAuthPartyId } });
  }
  onUpdateProductStore(product) {
    this.router.navigate(["partyQuickLink/create-product-store"], { queryParams: { party: this.taxAuthPartyId, fromDate: product.fromDate, thruDate: product.thruDate, roleTypeId: product.data.roleTypeId, sequenceNumber: product.data.sequenceNum, productStoreId: product.data.productStoreId } });
  }

  createPartyContact() {
    this.router.navigate(["partyQuickLink/create-party-contact"], { queryParams: { party: this.taxAuthPartyId } });
  }
  updateContactList(activity) {
    this.router.navigate(["partyQuickLink/create-party-contact"], { queryParams: { party: this.taxAuthPartyId, contactId: activity.data.contactListId, preferredContactMechId: activity.data.preferredContactMechId, statusId: activity.data.statusId, fromDate: activity.fromDate, thruDate: activity.thruDate } });
  }
  updatePartyClassification(epartyClassId, fromdate, thrudate) {
    this.router.navigate(["partyQuickLink/create-party-classification"], { queryParams: { party: this.taxAuthPartyId, partyClassId: epartyClassId, fromDate: fromdate, thruDate: thrudate } });
  }

  createAddNewContactInformation() {
    this.router.navigate(["financial/add-new-contact-information"], { queryParams: { party: this.taxAuthPartyId } });
  }
  updateData(partyId: string, type: string) {
    switch (type) {
      case 'POSTAL_ADDRESS':
        this.router.navigate(['/crm/accounts/create-postal-address'], { queryParams: { partyId: this.taxAuthPartyId, contactMechId: partyId } })
        break;

      case 'TELECOM_NUMBER':
        this.router.navigate(['/crm/accounts/create-contact'], { queryParams: { partyId: this.taxAuthPartyId, contactMechId: partyId } })
    }


  }
  createUserLogin() {
    this.router.navigate(["financial/create-user-login"], { queryParams: { party: this.taxAuthPartyId } });
  }
  createPartyAttribute() {
    this.router.navigate(["financial/create-party-attribute"], { queryParams: { party: this.taxAuthPartyId } });
  }

  updateAttribute(product) {
    this.router.navigate(["financial/create-party-attribute"], { queryParams: { party: this.taxAuthPartyId, attName: product.attrName, attValue: product.attrValue, attDes: product.attrDescription } });
  }
  createNewPartyNote() {
    this.router.navigate(["financial/new-party-note"], { queryParams: { party: this.taxAuthPartyId } });
  }
  onUpdateNotes(enoteId, enoteName, enote) {
    this.router.navigate(['financial/new-party-note'], { queryParams: { noteId: enoteId, party: this.taxAuthPartyId, noteName: enoteName, note: enote } })
  }
  onDeleteNotes(noteId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteNote(noteId).subscribe(res => {
          this.toastr.success("Delete Successfully");
          this.getAccountNote();
        }, (err) => {
          this.toastr.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  createAttachContent() {
    this.router.navigate(["financial/attach-content"], { queryParams: { party: this.taxAuthPartyId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
