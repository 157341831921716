import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-financial-new-reconciliation',
  templateUrl: './financial-new-reconciliation.component.html',
  styleUrls: ['./financial-new-reconciliation.component.css']
})
export class FinancialNewReconciliationComponent implements OnInit {
  show: boolean;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  activeCategory = 2;
  search = ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  createRecon: FormGroup;
  personId: any;
  partyIdArray: any[] = [];
  finAccountId: any;
  actualfromdate: string;
  lastModifiedDate: string;
  reconciledDate: string;
  gid: any;
  FinancialAccountIntialDataArray: any[] = [];
  getEstimateFrom: string;
  getEstimateTo: string;
  rDate: string;
  reconciledBalance: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,) {
      
    this.createRecon = this._FormBuilder.group({

      createdDate: [''],
      description: [''],
      glReconciliationName: [''],
      lastModifiedDate: [''],
      openingBalance: [''],
      organizationPartyId: ['', [Validators.required]],
      reconciledDate: [''],
      statusId: ['']



    });
  }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.finAccountId = params["finAccountId"];
      this.gid = params["gid"];

    });
    if (this.gid) {
      this.show = true;
      this.getFinancialAccountReconciliations();

    }
    else {
      this.show = false;
    }
    this.getPartyId();
    this.getFinancialAccountIntialData();
  }
  getFinancialAccountReconciliations() {
    this.spinner.show();
    const req = {
      "finAccountId": this.finAccountId,
      "glAccountId": "",
      "glReconciliationId": this.gid, 
      "glReconciliationName": "",
      "organizationPartyId": "", 
      "statusId": ""
    }
    this.accountsService.getFinancialAccountReconciliations(this.finSize, req).subscribe((res: any) => {
      this.spinner.hide();
      setTimeout(() => {
        const fromdate = res.data[0].findReconciliations[0].createdDate
        this.getEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");
        const toEstdate = res.data[0].findReconciliations[0].lastModifiedDate
        this.getEstimateTo = this.datePipe.transform(toEstdate, "yyyy-MM-dd");
        const reconciledDate = res.data[0].findReconciliations[0].reconciledDate
        this.rDate = this.datePipe.transform(reconciledDate, "yyyy-MM-dd");
        this.reconciledBalance = res.data[0].findReconciliations[0].reconciledBalance
        const formValue = this.createRecon;
        formValue.patchValue({
          createdDate: this.getEstimateFrom,
          description: res.data[0].findReconciliations[0].description,
          glReconciliationName: res.data[0].findReconciliations[0].glReconciliationName,
          lastModifiedDate: this.getEstimateTo,
          openingBalance: res.data[0].findReconciliations[0].openingBalance,
          organizationPartyId: res.data[0].findReconciliations[0].organizationPartyId,
          reconciledDate: this.rDate,
          statusId: res.data[0].findReconciliations[0].statusId
        })
      }, 3000);
    })
  }
  getFinancialAccountIntialData() {
    this.spinner.show();
    this.accountsService.getFinancialAccountIntialData().subscribe(res => {
      let FinancialAccountIntialData = res.data.reconciliationStatus;
      this.spinner.hide();
      for (const value of FinancialAccountIntialData) {
        this.FinancialAccountIntialDataArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })

  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }


  onSubmit(): void {
    this.spinner.show();
    const fromActualdate = this.createRecon.get('createdDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createRecon.get('lastModifiedDate').value;
    this.lastModifiedDate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const reconciledDateTo = this.createRecon.get('reconciledDate').value;
    this.reconciledDate = this.datePipe.transform(reconciledDateTo, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "createdDate": this.actualfromdate,
      "description": this.createRecon.value.description,
      "glReconciliationId": "",
      "glReconciliationName": this.createRecon.value.glReconciliationName,
      "lastModifiedDate": this.lastModifiedDate,
      "openingBalance": this.createRecon.value.openingBalance,
      "organizationPartyId": this.createRecon.value.organizationPartyId,
      "reconciledBalance": "",
      "reconciledDate": this.reconciledDate,
      "statusId": ""
    }
    this.accountsService.createReconciliation(requestData, this.finAccountId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/detail-financial-summary"], { queryParams: { finAccountId: this.finAccountId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  onUpdate(): void {
    this.spinner.show();
    const fromActualdate = this.createRecon.get('createdDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createRecon.get('lastModifiedDate').value;
    this.lastModifiedDate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const reconciledDateTo = this.createRecon.get('reconciledDate').value;
    this.reconciledDate = this.datePipe.transform(reconciledDateTo, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "createdDate": this.actualfromdate,
      "description": this.createRecon.value.description,
      "glReconciliationId": this.gid,
      "glReconciliationName": this.createRecon.value.glReconciliationName,
      "lastModifiedDate": this.lastModifiedDate,
      "openingBalance": this.createRecon.value.openingBalance,
      "organizationPartyId": this.createRecon.value.organizationPartyId,
      "reconciledBalance": this.reconciledBalance,
      "reconciledDate": this.reconciledDate,
      "statusId": this.createRecon.value.statusId,
    }
    this.accountsService.updateReconciliation(requestData, this.finAccountId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/detail-financial-summary"], { queryParams: { finAccountId: this.finAccountId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  cancelSubmit() {
    this.router.navigate(["financial/detail-financial-summary"], { queryParams: { finAccountId: this.finAccountId } })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}