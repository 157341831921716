<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div class="container-fluid">
          <ul class="tabbing-section  tabbing-accordians" style="padding-left: 9px;">
            <li [ngClass]="activeCategory==1?'active':''"><a>
              Purchase Order : Order Entry Ship-To Settings</a></li>
           
          
          </ul>
        </div>
        <div>
          <div class="container-fluid">
            <div class="row">
                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                  <div class="w-100">  
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                      <div class="panel panel-default">
                        <div *ngIf="activeCategory==1">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Purchase  Order : Order Entry Ship-To Settings
                            </h4>
                            <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse" href="#seo-collapse">
                            </span>
                            <span style="margin-left: 23%!important" class="edit-buttons" routerLinkActive="active"  routerLink="/order/create-purchase-continue"> Order Items</span>
                            <span class="edit-buttons" routerLinkActive="active"  routerLink="/order/purchase-finalize-order">Shipping</span>
                              <span class="edit-buttons">Options</span>
                            <span class="edit-buttons">Order Terms</span>
                            <span class="edit-buttons">Payment </span>
                            <span class="edit-buttons">Parties</span>
                            <span class="edit-buttons">Review Order</span>
                            <span class="edit-buttons"  routerLinkActive="active" routerLink="/order/purchase-order-options"> Continue</span>

              
                          
                          </div>
                          <div class="panel-body">
                            <div class="mt-2">
                              <div class="">
                                <div class="row">
                                  <form class="w-100">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">To Name
                                          </label>
                                          <input type="text"  class="form-control"
                                            placeholder="Enter Name">
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Attention Name
                                          </label>
                                          <input type="text"  class="form-control"
                                            placeholder="Enter Attention Name">
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Address Line 1
                                          </label>
                                          <input type="text"  class="form-control"
                                            placeholder="Enter Address Line 1">
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Address Line 2
                                          </label>
                                          <input type="text"  class="form-control"
                                            placeholder="Enter Address Line 2">
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">City
                                          </label>
                                          <input type="text"  class="form-control"
                                            placeholder="Enter City">
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">State/Province
                                          </label>
                                          <p-dropdown [options]="true" placeholder="Select State/Province" name="asdas" ></p-dropdown>
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Zip/Postal Code
                                          </label>
                                          <input type="text"  class="form-control"
                                            placeholder="Enter Zip/Postal Code">
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Country
                                          </label>
                                          <p-dropdown [options]="true" placeholder="Select Country" name="asdas" ></p-dropdown>
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Allow Solitication?
                                          </label>
                                          <p-dropdown [options]="true" placeholder="Select" name="asdas" ></p-dropdown>
                                        </div>
                                      </div> 
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                     
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
