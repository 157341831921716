<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of Mr. THE PRIVILEGED ADMINISTRATOR
                    [admin]</span>
                    <span class="">

                        <button type="submit" (click)="homeButton();"
                         class=" btn btn-outline-secondary mr-2">Home</button> 
             
                       <button type="submit" (click)="cancelSubmit();"
                         class="btn btn-danger buttonclass ">Back</button>
                    </span> </div>

            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="w3-card-4 classCardA ">


                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div class="create-new-leade ">
                                                    <div class="container-fluid">


                                                        <div class="panel-group">


                                                            <div class="divA">
                                                                <div class="w3-card-4 classCard mt-2">
                                                                    <div class="container-fluid">
                                                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                                                            <li [ngClass]="activeCategory==1?'active':''"><a>
                                                                                    Edit Task # Information
                                                                                    </a></li>

                                                                        </ul>
                                                                    </div>

                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100" [formGroup]="newTaskProject">
                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-5 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Task Name<span
                                                                                                            style="color: red;">*</span></label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <input type="text" class="form-control"
                                                                                                        formControlName="taskName" id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp" placeholder="Enter Task Name">
                                            
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-5 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Parent Phase</label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <p-dropdown filter="true" formControlName="parentPhase"
                                                                                                        [options]="ParentPhaseArray" optionlabel="label"
                                                                                                        placeholder="Enter Parent Phase">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-5 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Sequence Num</label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <input type="text" class="form-control"
                                                                                                        formControlName="sequenceNum" id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Sequence Num">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-5 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">My Role for This Week</label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <p-dropdown filter="true" formControlName="roleWeek"
                                                                                                        [options]="RoleResourceListArray" optionlabel="label"
                                                                                                        placeholder="Enter Role for This Week">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-5 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Estimated Hours</label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <input type="text" class="form-control"
                                                                                                        formControlName="estimatedHours" id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Estimated Hours">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-5 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Skill Type ID</label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <p-dropdown filter="true" formControlName="skillTypeID"
                                                                                                        [options]="SkillTypeIdArray" optionlabel="label"
                                                                                                        placeholder="Enter Skill Type ID">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-5 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Priority</label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <p-dropdown filter="true" formControlName="priority"
                                                                                                        [options]="PriorityListArray" optionlabel="label"
                                                                                                        placeholder="Enter Priority">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-5 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Type</label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <p-dropdown filter="true" formControlName="type"
                                                                                                        [options]="TypeListArray" optionlabel="label"
                                                                                                        placeholder="Enter Type">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-5 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Description</label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <input type="text" class="form-control"
                                                                                                        formControlName="description" id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Description">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-5 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Scope</label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <p-dropdown filter="true" formControlName="scope"
                                                                                                        [options]="ScopeListArray" optionlabel="label"
                                                                                                        placeholder="Enter Scope">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-5 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Estimated Start Date</label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <input type="date" class="form-control"
                                                                                                        formControlName="startDate" id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp" placeholder="Enter Start Date">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-5 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Estimated Completion
                                                                                                        Date</label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <input type="date" class="form-control"
                                                                                                        formControlName="endDate" id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Completion Date">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 19%;">
                                                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                                                            (click)="createNewTask()">Add</button>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br>


                                                            </div>

                                                            <div class="divB">
                                                                <div class="w3-card-4 classCard mt-2">
                                                                    <div class="container-fluid">
                                                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                                                            <li [ngClass]="activeCategory==1?'active':''"><a>
                                                                                    Edit Task # Information
                                                                                    </a></li>

                                                                        </ul>
                                                                    </div>

                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100" [formGroup]="existTaskProject">
                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
        
        
                                                                                                <div
                                                                                                    class="col-lg-5 form-group classInputC">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Parent
                                                                                                        Phase
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div class="col-lg-5">
        
                                                                                                    <p-dropdown filter="true"
                                                                                                        optionlabel="label"
                                                                                                        [options]="ParentPhaseArray"
                                                                                                        formControlName="ParentPhase">
        
                                                                                                    </p-dropdown>
        
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!-- <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Role Type ID</label>
                                                                                                </div>
                                                                                                <div class="col-lg-4 form-group">
                                                                                                    <p-dropdown filter="true" formControlName="roleTypeID"
                                                                                                        [options]="RoleResourceListArray" optionlabel="label"
                                                                                                        placeholder="Enter Role Type ID">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> -->
                                            
                                                                                        <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 32%;">
                                                                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                                                                (click)="updateTaskSave()">Add</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>






                                                            </div>





                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>