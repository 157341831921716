import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-customer-summary-page',
  templateUrl: './customer-summary-page.component.html',
  styleUrls: ['./customer-summary-page.component.css']
})
export class CustomerSummaryPageComponent implements OnInit {
  problemIdData: any;
  problemId: any;

  constructor(public router: Router,
    readonly _FormBuilder: FormBuilder, 
    readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService) { }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=>{
      this.problemId = params.problemId;
    })
    this.getFsmProblemByProblemId();
  }
  back(){
    this.router.navigate(["/fsm/fsm-customer"])
  }
  getFsmProblemByProblemId() {
    this.spinner.show();
    this.accountsService.getFsmProblemByProblemId(this.problemId).subscribe(res => {
      this.problemIdData = res.data[0].getFsmProblemByProblemId[0];
      this.spinner.hide();
    })
  }
}
