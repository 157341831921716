import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddEmployeePositionService } from 'src/app/human-resource/add-employee-position/add-employee-position.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-main-configuration',
  templateUrl: './main-configuration.component.html',
  styleUrls: ['./main-configuration.component.css']
})
export class MainConfigurationComponent implements OnInit {
  activeCategory =1;
  activeCategorys=3;

  total = 0;
  rowNumber = 0;
  pageSize = 100;
  rows = 50;
  orderItems: any[];
  pageNo = 1;
  isShown: boolean = false;
  paycheckType = "";
  PaycheckExpenses: any;
  PaycheckExpensesTable: any;
  PaycheckAndWithHoldingsTable: any;
  PaytoParty: any;
  PaytoPartyArray: any[] = [];
  currencyArray: any[] = [];
  serialNo = "";
  amount = "";
  LocalizationTable: any;
  payCheckform: FormGroup;
  paycheck: any;
  costingForm: FormGroup;
  localizationId = "";
  paycheckserialNo="";
  PayCheck: any;
  statusID: any;
  paymentId = "";
  serialNoWithHoldings: any;
  serial="";
  serialNos="";
  payCheckExpenseForm: FormGroup;
  PaycheckWithHoldingForm: FormGroup;
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,    
    readonly _Router: Router,
    readonly addSkillService: AddSkillService,
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly toastr: ToastrService,
    private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly router: Router
  ) {

    this.payCheckform = this._FormBuilder.group({
      payCheckType: [''],


    });
    this.costingForm = this._FormBuilder.group({
      onssCompanyId: [''],
      onssRegistrationNumber: [''],
      dmfaEmployerClass: [''],
      commisionOnTarget: [''],
      representationFees: [''],
      localizationId: [''],
      paidTimeOff: [''],
      echoVouchers: [''],
      mobile: [''],
      fuelCard: [''],
      mealVouchers: [''],
      internet: [''],


    });
    this.payCheckExpenseForm = this._FormBuilder.group({
      paycheckExpense: [''],
      payToParty: [''],
      amount: [''],


    });
    this.PaycheckWithHoldingForm = this._FormBuilder.group({
      PaycheckWitholdings: [''],
      payToParty: [''],
      Amount: [''],


    });
  }

  ngOnInit(): void {
    this.getPartyId();
    this.getConfigPagePaycheck();
    this.getCurrency();

  }
  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/financial/main']);
}


  onSubmitPaycheckWithHolding() {

    this.spinner.show();

    const formData = {
      
      "amount": this.PaycheckWithHoldingForm.value.Amount,
      "partyIdTo": this.PaycheckWithHoldingForm.value.payToParty,
      "paycheckAndWitholding": this.PaycheckWithHoldingForm.value.PaycheckWitholdings,
      "paycheckType": this.payCheckform.value.payCheckType,
      "serialNo": this.paycheckserialNo


    }
    this.accountsService.createPaycheckHolding(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getPaycheckAndWithHoldings() ; 
        this.payCheckExpenseForm.reset();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  
  onSubmitPaycheckExpence() {

    this.spinner.show();

    const formData = {
      
      "amount": this.payCheckExpenseForm.value.amount,
      "partyIdTo": this.payCheckExpenseForm.value.payToParty,
      "paycheckExpenses": this.payCheckExpenseForm.value.paycheckExpense,
      "paycheckType": this.payCheckform.value.payCheckType,
      "serialNo": this.paycheckserialNo


    }
    this.accountsService.createPaycheckExpenses(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.getPaycheckExpenses() ; 
        this.payCheckExpenseForm.reset();
        this.spinner.hide();



      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  onSubmit() {

    this.spinner.show();

    const formData = {

      "commisionOnTarget": this.costingForm.value.commisionOnTarget,
      "dmfaEmployerClass": this.costingForm.value.dmfaEmployerClass,
      "echoVouchers": this.costingForm.value.echoVouchers,
      "fuelCard": this.costingForm.value.fuelCard,
      "internet": this.costingForm.value.internet,
      "localizationId": this.localizationId,
      "mealVouchers": this.costingForm.value.mealVouchers,
      "mobile": this.costingForm.value.mobile,
      "onssCompanyId": this.costingForm.value.onssCompanyId,
      "onssRegistrationNumber": this.costingForm.value.onssRegistrationNumber,
      "paidTimeOff": this.costingForm.value.paidTimeOff,
      "paycheckType": this.payCheckform.value.payCheckType,
      "representationFees": this.costingForm.value.representationFees,


    }
    this.accountsService.createLocalization(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();



      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  toggleShow() {


    if (this.payCheckform.value.payCheckType) {

      this.isShown = true;

      this.getLocalizationByLocalization();
      this.getPaycheckAndWithHoldings();
      this.getPaycheckExpenses();
    }
    else {
      this.isShown = false;
    }


  }

  getLocalizationByLocalization() {
    this.accountsService.getLocalizationByLocalization(this.payCheckform.value.payCheckType).subscribe((res: any) => {
      this.spinner.show();
      this.LocalizationTable = res.data[0].Localizations;
      this.spinner.hide();
    })
  }
 
 
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const GrossAmount = res.data.currencyList;
      for (const value of GrossAmount) {
        this.currencyArray.push({
          label: value.uomId,
          value: value.uomId
        })
      }
    })
    this.spinner.hide();
  }
  updatePayCheck(PaycheckExpenses) {
    this.spinner.show();
    //this.resumeDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");

    const formdata = {

      "amount": PaycheckExpenses.PayChecks.amount,
      "currencyUomId": PaycheckExpenses.PayChecks.currencyUomId,
      "paymentId": this.paymentId

    }

    this.accountsService.updatePayCheck(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getConfigPagePaycheck();

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
  updatePaycheckExpenses(product) {
    this.spinner.show();
    //this.resumeDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");

    const formdata = {

      "amount": product.payCheckExpense.amount,
      "partyIdTo": product.payCheckExpense.partyIdTo,
      "serialNo": product.payCheckExpense.serialNo,

    }

    this.accountsService.updatePaycheckExpenses(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
       // this.getPaycheckExpenses();

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
  updatePaycheckHoldings(product) {


    this.spinner.show();
    //this.resumeDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");

    const formdata = {

      "amount": parseInt(product.amount),
      "partyIdTo": product.partyIdTo,
      "serialNo":product.serialNo

    }

    this.accountsService.updatePaycheckHolding(formdata, this.PaycheckExpenses.PayChecks.amount).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getConfigPagePaycheck();
       // this.PayCheck = res.data.netTotal;

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
 
  getPaycheckExpenses() {
    this.accountsService.getPaycheckExpenses(this.payCheckform.value.payCheckType).subscribe((res: any) => {
      this.spinner.show();
      this.PaycheckExpensesTable = res.data;
     // this.serialNo = res.data.payCheckExpense.serialNo;
      this.spinner.hide();
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.PaytoParty = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.PaytoParty) {
        this.PaytoPartyArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }

  getPaycheckAndWithHoldings() {
    this.accountsService.getPaycheckAndWithHoldings(this.payCheckform.value.payCheckType).subscribe((res: any) => {
      this.spinner.show();
      this.PaycheckAndWithHoldingsTable = res.data;
      this.serialNoWithHoldings = res.data.serialNo;
      this.spinner.hide();


    })
  }
  getConfigPagePaycheck() {
    this.accountsService.getConfigPagePaycheck(this.paymentId).subscribe((res: any) => {
      this.spinner.show();
      this.PaycheckExpenses = res.data[0];
      this.PayCheck = res.data[0].amount;
      this.statusID = res.data[0].StatusItem[0].statusId;
      this.spinner.hide();
      this.getPaycheckAndWithHoldings();
      this.getPaycheckExpenses() ; 

    })
  }

  findPaycheck()
  {
    this.router.navigate(['financial/main-find-paycheck']);
  }

  createPaychecks()
  {
    this.router.navigate(['/financial/main-create-paycheck']);

  }
  paycheckConfiguration()
  {
    this.router.navigate(['/financial/main-configuration']);

  }
  employeess()
  {
    this.router.navigate(['/financial/employees-profile']);

  }
  reports()
  {
    this.router.navigate(['/financial/main-reports']);

  }

  contractReferencees()
  {
    this.router.navigate(['/financial/contractor']);

  }


  changeactiveCategory(tab: number) {
    this.activeCategorys = tab;
  }


  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
