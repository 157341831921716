import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import { AddEmployeePositionService } from 'src/app/human-resource/add-employee-position/add-employee-position.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-revision-page',
  templateUrl: './revision-page.component.html',
  styleUrls: ['./revision-page.component.css']
})
export class RevisionPageComponent implements OnInit {
  activeCategory=4;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;

  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  contentId: any;
  contentRevisionSeqId: any;
  show: boolean;
  ListContentRevisionsAll: any;
  personId: any;
  partyIdArray: any[]=[];
  surveyIdValue=true;
  revisionListForm: FormGroup;
  valShow: boolean;
  revisionListItemForm: FormGroup;


  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly addEmployeePositionService: AddEmployeePositionService,
    private _location: Location,

  )
  {
    this.revisionListForm=this._FormBuilder.group({
      contentID:[''],
      contentRevisionSeqID:[''],
      committedByPartyID:[''],
      Comments:['']
    });
    this.revisionListItemForm=this._FormBuilder.group({
      contentID:[''],
      contentRevisionSeqID:[''],
      itemContentID:[''],
      oldDataResourceID:[''],
      newDataResourceID:[''],

    })

  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.contentId = params["contentId"];
      this.contentRevisionSeqId = params["contentRevisionSeqId"];

    });
    this.show=true;
    this.valShow=true;
    this.ListContentRevisions();
    this.getPartyId();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  updateContentRevisionItem(): void {
    this.spinner.show();
    const requestData ={
      "contentId": this.revisionListItemForm.value.contentID,
      "contentRevisionSeqId": this.revisionListItemForm.value.contentRevisionSeqID,
      "itemContentId": this.revisionListItemForm.value.contentID,
      "newDataResourceId": this.revisionListItemForm.value.newDataResourceID,
      "oldDataResourceId": this.revisionListItemForm.value.oldDataResourceID,
      "rootContentId": this.revisionListItemForm.value.contentID,
      "rootContentRevisionSeqId": this.revisionListItemForm.value.contentRevisionSeqID,
      }
     
    this.accountsService.updateContentRevisionItem(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.revisionListItemForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  changeTable(){
    this.valShow=false;
  }
  updateContentRevision(): void {
    this.spinner.show();
    const requestData ={
      "comments": this.revisionListForm.value.Comments,
      "committedByPartyId": this.revisionListForm.value.committedByPartyID,
      "contentId": this.revisionListForm.value.contentID,
      "contentRevisionSeqId": this.revisionListForm.value.contentRevisionSeqID,
      "rootContentId": this.revisionListForm.value.contentID,
      "rootContentRevisionSeqId": this.revisionListForm.value.contentRevisionSeqID,
      }
     
    this.accountsService.updateContentRevision(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.ListContentRevisions();
        this.closebutton2.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  revisionListPatchNew() {
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.revisionListItemForm;
        formValue.patchValue({
          contentID:this.contentId,
          contentRevisionSeqID:this.contentRevisionSeqId,
        })
      }, 2000);
      this.spinner.hide();
  }
  revisionListPatch() {
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.revisionListForm;
        formValue.patchValue({
          contentID:this.contentId,
          contentRevisionSeqID:this.contentRevisionSeqId,
        })
      }, 2000);
      this.spinner.hide();
  }
   getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  ListContentRevisions() {
    this.spinner.show();
    this.accountsService.ListContentRevisions(this.contentId,this.contentRevisionSeqId).subscribe(res => {
      this.ListContentRevisionsAll = res.data[0].ListContentRevisions;
      this.spinner.hide();
    })
  }

  openViewTree()
  {
    this._Router.navigate(['/content/compDoc/view-tree'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openEdit()
  {
    this._Router.navigate(['/content/compDoc/edit'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openApprovals()
  {
    this._Router.navigate(['/content/compDoc/approvals'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openRevisions()
  {
    this._Router.navigate(['/content/compDoc/revisions'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openCreateNewRoot()
  {
    this._Router.navigate(['/content/compDoc/new-root-compdoc-template'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openViewWaitingApprovals()
  {
    this._Router.navigate(['/content/compDoc/view-waiting-approvals']);
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

  changeactiveCategory(tab: number)
  {
    this.activeCategory = tab;
  
  }

}
