import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-view-data-source',
  templateUrl: './view-data-source.component.html',
  styleUrls: ['./view-data-source.component.css']
})
export class ViewDataSourceComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  CreateDataSourceForm: any;
  DataSourceValue: any;
  DataSourceTypeId: any;
  DataSourceTypeIdArray: any[]=[];
  show: boolean;
  DataSourceTypeValue: any;
  dataSourceID: any;
  dataSourceTypeById: any;
  dataSourceTypeId: any;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.CreateDataSourceForm = this._FormBuilder.group({
      DataSourceID: [''],
      DataSourceTypeIDValue: [''],
      Description: [''],
      
    });
   }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.dataSourceID = params.dataSourceID;
    })

    this.getDataSource();
    this.getDataSourceTypeId();
    this.getDataSourceByDataSourceId();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  getDataSourceByDataSourceId() {
    this.spinner.show();
    this.myContactsService.getDataSourceByDataSourceId(this.dataSourceID).subscribe(res => {
      this.dataSourceTypeId = res.data[0].node[0];
      setTimeout(() => {
      
        const formValue = this.CreateDataSourceForm;
        formValue.patchValue({
          DataSourceID: this.dataSourceTypeId.dataSourceId,
          DataSourceTypeIDValue:this.dataSourceTypeId.dataSourceTypeId,
          Description: this.dataSourceTypeId.description,
        })
      
      }, 2000);
      this.spinner.hide();
    })
  }

  updateDataSource(): void {
    this.spinner.show();
    const requestData ={
      "dataSourceId": this.CreateDataSourceForm.value.DataSourceID,
      "dataSourceTypeId": this.CreateDataSourceForm.value.DataSourceTypeIDValue,
      "description": this.CreateDataSourceForm.value.Description,
      }
     
    this.accountsService.updateDataSource(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getDataSource(); 
        this.getDataSourceByDataSourceId();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    getDataSource() {
      this.spinner.show();
      this.accountsService.getDataSource().subscribe(res => {
        this.DataSourceValue = res.data;
        this.spinner.hide();
    
      })
    }
    getDataSourceTypeId() {
      this.spinner.show();
      this.myContactsService.getDataSourceTypeId().subscribe(res => {
        this.DataSourceTypeId = res.data[0].data;
        this.spinner.hide();
        for (const value of this.DataSourceTypeId) {
          this.DataSourceTypeIdArray.push({
            label: value.description,
            value: value.dataSourceTypeId
          })
        }
      })
    }

    

cancelSubmit(){
      this._Router.navigate(['/marketing/data-source-marketing'])
}
  ngOnDestroy(): void {
    this.spinner.hide();
   
  }
}
