<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Financial Account</span>
                <span class="color-black pl-1" *ngIf="!show"> >Add New Financial Account </span>
                <span class="color-black pl-1" *ngIf="show"> >Update New Financial Account </span>
                <button type="submit" class="btn btn-danger buttonclass" 
                 (click)="cancelSubmit();">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                        Add New Financial Account</a></li>
                        <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                            Update New Financial Account</a></li>
                     
                     
          
          
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createFinancial">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Financial Account Type<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown   [options]="FinAccountTypeListArray" optionlabel="label"
                                             placeholder="Enter Financial Account Type" filter="true"
                                            formControlName="finAccountTypeId" >
                                                            
                                            </p-dropdown>

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Status <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown   [options]="FinAccountStatusListArray" optionlabel="label"
                                                placeholder="Enter Status"  filter="true"
                                               formControlName="statusId" >
                                                   
                                               </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Financial Account Name
                                               
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" formControlName="finAccountName"
                                                placeholder="Enter Financial Account Name">

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Financial Account Code </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"    formControlName="finAccountCode"
                                            class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Financial Account Code">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Financial Account Pin</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control"
                                             id="exampleInputEmail1" formControlName="finAccountPin"
                                                aria-describedby="emailHelp" placeholder="Enter Financial Account Pin">

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Currency<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Enter Currency"  [options]="currencyArray" optionlabel="label"
                                                filter="true"
                                              formControlName="currencyUomId">
                                                 
                                              </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Organization Party ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Enter Organization Party ID"  filter="true"
                                                formControlName="organizationPartyId"
                                                [options]="partyIdArray" optionlabel="label">
                                                       
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Owner Party ID
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Enter Owner Party ID" [options]="partyIdArray" optionlabel="label"
                                              filter="true"
                                            formControlName="ownerPartyId">
                                                   
                                            </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Post To Gl Account ID </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Post To Gl Account ID" filter="true"
                                                    [options]="giAccountArray" optionlabel="label"
                                                    formControlName="postToGlAccountId">
                                                   
                                                    </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">From Date <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Financial Account Pin"
                                            formControlName="fromDate">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Through Date </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Financial Account Pin"
                                                formControlName="thruDate">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Is Refundable<span
                                                    style="color:red">*</span> </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Is Refundable" [options]="refundable" optionlabel="label"
                                                filter="true" 
                                              formControlName="isRefundable">
                                             
                                              </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Replenish Payment ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" formControlName="replenishPaymentId"
                                                 placeholder="Enter Replenish Payment ID"> 
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Replenish Level</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="replenishLevel"
                                                             placeholder="Enter Replenish Level"> 
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                   
                                 
                                  
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 67%;">
                                <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" *ngIf="!show"  (click)="submit()" class="btn btn-secondary submit-btn">Submit</button>
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
