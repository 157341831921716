import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { ProductBacklogComponent } from 'src/app/scrum/product-backlog/product-backlog.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detail-task-project',
  templateUrl: './detail-task-project.component.html',
  styleUrls: ['./detail-task-project.component.css']
})
export class DetailTaskProjectComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  newForm: FormGroup;
  workEffortIdTo: any;
  DependencyToList: any;
  TaskInformationById: any;
  DependencyFromList: any;
  TaskNoteList: any;
  workEffortId: any;
  workEffortIdFrom: any;
  SurveyList: any;
  ParentPhase: any;
  ParentPhaseArray: any[] = [];
  RoleResourceList: any;
  RoleResourceListArray: any[] = [];
  SkillTypeIdArray: any[] = [];
  SkillTypeId: any;
  PriorityListArray: any[] = [];
  PriorityList: any;
  TypeList: any;
  TypeListArray: any[] = [];
  Scope: any;
  ScopeArray: any[] = [];
  newTaskProject: FormGroup;
  EditTaskForm: FormGroup;
  ContentList: any;
  contentID: any;
  internalNote: any;
  startdate: string;
  endate: string;
  SurveyData: any;
  RelatedTask: any;
  RelatedTaskArray: any[] = [];
  DependencyBeforeForm: FormGroup;
  DependencyAfterForm: FormGroup;
  ResourceList: any;
  RelatedTaskListArray: any[]=[];
  RelatedTaskList: any;
  TaskTeam: any;
  RequestList: any;
  projectId: any;
  addResourceForm: FormGroup;
  PartyId: any;
  PartyIdArray: any[]=[];
  FromDate: string;
  ThroughDate: string;
  actualFromDate: string;
  actualThroughDate: string;
  skillStandardForm: FormGroup;
  SkillList: any;
  showSkill: boolean;
  AddTimeEntryForm: FormGroup;
  fromDate: string;
  TimeSheetById: any;
  periodTypes: any;
  rateTypes: any;
  periodTypesArray: any;
  rateTypesArray: any[]=[];
  showTimeEntry: boolean;
  updateProduct: any;
  NoteList: any;
  AddTaskNotesForm: FormGroup;
  actualfromDate: string;
  surveyForm: FormGroup;
  actualThrough: string;
  showSurvey: boolean;
  updatesurvey: any;
  SurveyID: any;
  SurveyIDArray: any[]=[];
  yesNOArray: { label: string; value: string; }[];
  workEffortContentType: any;
  workEffortContentTypeArray: any[]=[];
  existingContentId: any;
  existingContentIdListArray:any[]=[];
  MimeTypeId: any;
  mimeTypeIdArray: any[]=[];
  AddTaskContentForm: FormGroup;
  parentContentId: any;
  parentContentIdArray: any[]=[];
  FindTaskContentById: any;
  TaskStatus: any;
  fileData: File;
  uploadId: any;
  fileURL: any;
  statusId: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  HidebtnRoleView: boolean=false;
  HidebtnTaskCrud: boolean=false;
  HidebtnTimeCurd: boolean=false;
  EmailsProductBacklogs: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
        private _location: Location,
        readonly datePipe: DatePipe,
    readonly myContactsService: MyContactsService,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly router: Router
  ) {
    this.newForm = this._FormBuilder.group({
      TaskName: [''],
      ParentPhase: [''],
      SequenceNum: [''],
      MyRoleForThisTask: [''],
      EstimatedHours: [''],
      SkillTypeID: [''],
      Priority: [''],
      Type: [''],
      Description: [''],
      Scope: [''],
      EstimatedStartDate: [''],
      EstimatedCompletionDate: [''],

    });
    this.newTaskProject = this._FormBuilder.group({
      taskName: "",
      parentPhase: "",
      sequenceNum: "",
      roleWeek: "",
      estimatedHours: "",
      skillTypeID: "",
      priority: "",
      type: "",
      description: "",
      scope: "",
      startDate: "",
      endDate: "",
    });
    this.EditTaskForm = this._FormBuilder.group({
      ParentPhase: "",

    });
    this.DependencyAfterForm = this._FormBuilder.group({
      RelatedTasks: "",

    });
    this.DependencyBeforeForm = this._FormBuilder.group({
      RelatedTasks: "",

    });
    this.addResourceForm = this._FormBuilder.group({
      PartyId: "",
      roleTypeID: "",
      FromDate: "",
      ThroughDate: "",

    });
    this.AddTaskNotesForm = this._FormBuilder.group({
      Note: "",
      InternalNote: "",

    });
    this.DependencyBeforeForm = this._FormBuilder.group({
      RelatedTasks: "",
    });
    this.skillStandardForm = this._FormBuilder.group({
      SkillTypeID: "",
      EstimatedPeople: "",
      EstimatedDuration: "",
      EstimatedCost: "",

    });

    this.AddTimeEntryForm = this._FormBuilder.group({
      Date: "",
      Hours: "",
      PartyID: "",
      RoleTypeID: "",
      RateType: "",
      Comments: "",

    });
    this.surveyForm = this._FormBuilder.group({
      SurveyID: "",
      FromDate: "",
      ThroughDate: "",

    });
    this.AddTaskContentForm = this._FormBuilder.group({
      ExistingContent: "",
      WorkEffortContentTypeID: "",
      Upload: "",
      MimeTypeID: "",
      ParentContent: "",

    });
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.Hidebtn=true;
    }
      else if(this.userPermissions.PROJECTMGR_ROLE_VIEW=="PROJECTMGR_ROLE_VIEW"){
      this.HidebtnRoleView=true;
    }
     else if(this.userPermissions.PROJECTMGR_ROLE_TASK_CREATE=="PROJECTMGR_ROLE_TASK_CREATE"){
      this.HidebtnTaskCrud=true;
    }
    else if(this.userPermissions.PROJECTMGR_ROLE_TIMESHEET_CREATE=="PROJECTMGR_ROLE_TIMESHEET_CREATE"){
      this.HidebtnTimeCurd=true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params.workEffortId;
      this.projectId = params.projectId;
      this.statusId = params.statusId;

      this.internalNote = "Y";

    })

    this.getFindTaskInformationById();
    this.getDependencyTo();
    this.getDependencyFrom();
    this.getTaskNoteList();
    this.getSurveyList();
    this.getPhaseList();
    this.getSkillTypeId();
    this.getPriorityList();
    this.getScopeList();
    this.getTypeList();
    this.getRoleResourceList();
    this.getSurveyData();
    this.getRelatedTask();
    this.getResourceList();
    this.getRelated();
    this.getTaskTeam();
    this.getTaskTeam();
    this.getRequestList();
    this.getPartyIdList();
    this.getSkillList();
    this.getTimeSheetById();
    this.getRatesInitialData();
    this.getSurveyID();
    this.getNoteLists();
    this.parentContentIdList();
    this.existingContentIdList();
    this.workEffortContentTypeList();
    this.getMimeTypeId();
    this.getFindTaskContentById();
    this.getEmailsProductBacklogs()
  }
  detailNote(noteId) {
    this.router.navigate(["/psa/project/detail-note"],
      { queryParams: { noteId: noteId } })
  }
  getEmailsProductBacklogs() {

    this.spinner.show();
    this.accountsService.getEmailsProductBacklogs().subscribe(res => {
      this.EmailsProductBacklogs = res.data.data;
      this.spinner.hide();
    })
  }

  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

 
  downloadFileTaskContent(fileUrl) {
    this.spinner.show();
    this.myContactsService.downloadFileTaskContent(fileUrl).subscribe(res => {
      const blob = new Blob([res], { type: 'document/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'ViewSimpleContent';
      this.spinner.hide();
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

  }

  deletedTaskContentById(contentId) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
      
        this.myContactsService.deletedTaskContentById(contentId).subscribe(res => {
          this.getFindTaskContentById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }

  homeButton(){
    this.router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  uploadFormContent(){
    this.onSubmitFile();

    setTimeout(() => {
      this.addTaskContent();
    }, 4000);
   
  }
  addTaskContent() {
    this.spinner.show();
    const formData = {
      
      "existingContentId":this.AddTaskContentForm.value.ExistingContent,
      "fileUrl":this.fileURL,
      "id": "",
      "mimeTypeId": this.AddTaskContentForm.value.MimeTypeID,
      "parentContentId":this.AddTaskContentForm.value.ParentContent,
      "workEffortContentTypeId":this.AddTaskContentForm.value.WorkEffortContentTypeID,
      "workEffortId":this.workEffortId
    }
    this.myContactsService.addTaskContent(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.AddTaskContentForm.reset();
        this.getFindTaskContentById();
        this.uploadId = res.data.id;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  onSubmitFile() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.attachFile(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.fileURL = res.data.URL;
      }
    }, (err) => {
      this.toastr.error(err.message);
      this.spinner.hide();
    })

  }
  uploadFileTaskContent() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.myContactsService.uploadFileTaskContent(formData,this.uploadId, this.AddTaskContentForm.value.Upload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getFindTaskContentById();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getFindTaskContentById() {
    this.spinner.show();
    this.myContactsService.getFindTaskContentById(this.workEffortId).subscribe(res => {
      this.FindTaskContentById = res.data.node;
      this.spinner.hide();
    })
  }
  getMimeTypeId() {
    this.spinner.show();
    this.accountsService.getMimeTypeId().subscribe(res => {
      this.MimeTypeId = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.MimeTypeId) {
        this.mimeTypeIdArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }
  parentContentIdList() {
    this.spinner.show();
    this.myContactsService.parentContentIdList().subscribe(res => {
      this.parentContentId = res.data;
      this.spinner.hide();
      for (const value of this.parentContentId) {
        this.parentContentIdArray.push({
          label: value.contentIdTo,
          value: value.contentId
        })
      }
    })
  }
  existingContentIdList() {
    this.spinner.show();
    this.myContactsService.existingContentIdList().subscribe(res => {
      this.existingContentId = res.data;
      this.spinner.hide();
      for (const value of this.existingContentId) {
        this.existingContentIdListArray.push({
          label: value.description,
          value: value.contentId
        })
      }
    })
  }
  workEffortContentTypeList() {
    this.spinner.show();
    this.myContactsService.workEffortContentTypeList().subscribe(res => {
      this.workEffortContentType = res.data.node;
      this.spinner.hide();
      for (const value of this.workEffortContentType) {
        this.workEffortContentTypeArray.push({
          label: value.description,
          value: value.workEffortContentTypeId
        })
      }
    })
  }
  deleteWorkEffortSurveyAppl(pro) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "fromDate": this.datePipe.transform(pro.survey.fromDate, "yyyy-MM-dd hh:mm:ss") ,
          "surveyId": pro.survey.surveyId,
          "workEffortId":this.workEffortId
        
        }
        this.myContactsService.deleteWorkEffortSurveyAppl(req).subscribe(res => {
          this.getTimeSheetById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }



  getSurveyID() {
    this.spinner.show();
    this.myContactsService.getSurveyID().subscribe(res => {
      this.SurveyID = res.data[0].surveyName;
      this.spinner.hide();
      for (const value of this.SurveyID) {
        this.SurveyIDArray.push({
          label: value.surveyName,
          value: value.surveyId
        })
      }
    })
  }
  updateSurvey(pro){
    this.showSurvey=true;
    this.updatesurvey = pro;
    this.spinner.show();
   
      setTimeout(() => {
        const formValue = this.surveyForm;
        formValue.patchValue({
          SurveyID: pro.survey.surveyId,
          FromDate: pro.survey.fromDate,
          ThroughDate: pro.survey.thruDate,
        })
      
      }, 2000);
      this.spinner.hide();
  }
  updateWorkEffortSurveyAppl() {
    this.spinner.show();
   
    const thruDateA = this.surveyForm.get('ThroughDate').value;
    this.actualThrough = this.datePipe.transform(thruDateA, "yyyy-MM-dd hh:mm:ss");

    const formData = {
      "fromDate": this.datePipe.transform(this.updatesurvey.survey.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "surveyId": this.updatesurvey.survey.surveyId,
      "thruDate": this.actualThrough,
      "workEffortId": this.workEffortId
    }
    this.myContactsService.updateWorkEffortSurveyAppl(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton10.nativeElement.click();
        this.surveyForm.reset();
        this.getSurveyList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  createWorkEffortSurveyAppl() {
    this.spinner.show();


    const fromdateA = this.surveyForm.get('FromDate').value;
    this.actualfromDate = this.datePipe.transform(fromdateA, "yyyy-MM-dd hh:mm:ss");


    const thruDateA = this.surveyForm.get('ThroughDate').value;
    this.actualThrough = this.datePipe.transform(thruDateA, "yyyy-MM-dd hh:mm:ss");

    const formData = {
      
      "fromDate": this.actualfromDate,
      "surveyId": this.surveyForm.value.SurveyID,
      "thruDate": this.actualThrough,
      "workEffortId": this.workEffortId
    }
    this.myContactsService.createWorkEffortSurveyAppl(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton10.nativeElement.click();
        this.surveyForm.reset();
        this.getSurveyList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  updateTaskNote(product) {
    this.spinner.show();

    const formData = {
      "internalNote": this.AddTaskNotesForm.value.InternalNote ,
      "noteInfo": product.list.noteInfo,
      "noteName": product.list.noteName,
      "noteParty": product.list.noteParty,
      "workEffortId": this.workEffortId

    }
    this.myContactsService.updateTaskNote(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.AddTaskNotesForm.reset();
        this.getNoteLists();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  addTaskNote() {
    this.spinner.show();

    const formData = {
      "internalNote": this.AddTaskNotesForm.value.InternalNote ,
      "noteInfo": this.AddTaskNotesForm.value.Note ,
      "noteName": "",
      "noteParty": "",
      "workEffortId": this.workEffortId

    }
    this.myContactsService.addTaskNote(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.AddTaskNotesForm.reset();
        this.getNoteLists();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getNoteLists() {
    this.spinner.show();
    this.myContactsService.getNoteList().subscribe(res => {
      this.NoteList = res.data;
      this.spinner.hide();
    })
  }
  deleteTimeEntry(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "timeEntryId": product.timeEntryId  ,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20",
          "workEffortId": product.workEffortId
        }
        this.myContactsService.deleteTimeEntry(req).subscribe(res => {
          this.getTimeSheetById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }

  getRatesInitialData() {
    this.spinner.show();
    this.myContactsService.getRatesData().subscribe(res => {
      this.rateTypes = res.data.rateTypes;
      this.spinner.hide();
    
      for (const value of this.rateTypes) {
        this.rateTypesArray.push({
          label: value.description,
          value: value.rateTypeId
        })
      }
    })
  }
  getTimeSheetById() {
    this.spinner.show();
    this.myContactsService.getTimeSheetById(this.workEffortId).subscribe(res => {
      this.TimeSheetById = res.data;
      this.spinner.hide();
    })
  }
  resetTimeEntry(){
    this.AddTimeEntryForm.reset();
    this.showTimeEntry=false;
  }
  updateTime(product){
    this.showTimeEntry=true;
    this.updateProduct = product;
    this.spinner.show();
   
      setTimeout(() => {
        const formValue = this.AddTimeEntryForm;
        formValue.patchValue({
          Date: product.fromDate,
          Hours: product.hours,
          PartyID: product.partyId,
          RateType:  product.rateTypeId,
          Comments:  product.comments,
        })
      
      }, 2000);
      this.spinner.hide();
  
  }
  updateTimeEntry() {
    this.spinner.show();

    const fromdate = this.AddTimeEntryForm.get('Date').value;
    this.fromDate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const formData = {
      "comments": this.AddTimeEntryForm.value.Comments ,
      "fromDate":  this.fromDate,
      "hours": this.AddTimeEntryForm.value.Hours ,
      "partyId": this.AddTimeEntryForm.value.PartyID ,
      "rateTypeId": this.AddTimeEntryForm.value.RateType ,
      "roleTypeId": this.AddTimeEntryForm.value.RoleTypeID ,
      "workEffortId": this.workEffortId,
      "fixedAssetId": "",
      "invoiceInfo": "",
      "maintHistSeqId": "",
      "planHours": this.updateProduct.planHours,
      "timeEntryId": this.updateProduct.timeEntryId,
      "timesheetId": this.updateProduct.timesheetId,
    }
    this.myContactsService.updateTimeEntry(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.AddTimeEntryForm.reset();
        this.getTimeSheetById();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  createTimeEntry() {
    this.spinner.show();

    const fromdate = this.AddTimeEntryForm.get('Date').value;
    this.fromDate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const formData = {
      "comments": this.AddTimeEntryForm.value.Comments ,
      "fromDate":  this.fromDate,
      "hours": this.AddTimeEntryForm.value.Hours ,
      "partyId": this.AddTimeEntryForm.value.PartyID ,
      "rateTypeId": this.AddTimeEntryForm.value.RateType ,
      "roleTypeId": this.AddTimeEntryForm.value.RoleTypeID ,
      "workEffortId": this.workEffortId
    }
    this.myContactsService.createTimeEntry(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.AddTimeEntryForm.reset();
        this.getTimeSheetById();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  updateSkill(product){
    this.showSkill=true;
    this.spinner.show();
   
      setTimeout(() => {
        const formValue = this.skillStandardForm;
        formValue.patchValue({
          SkillTypeID: product.data.skillTypeId,
          EstimatedPeople: product.data.estimatedNumPeople,
          EstimatedDuration: product.data.estimatedDuration,
          EstimatedCost:  product.data.estimatedCost,
        })
      
      }, 2000);
    this.spinner.hide();

  
  }
  resetSkill(){
    this.skillStandardForm.reset();
    this.showSkill=false;
  }
  updateTaskSkillStandard() {
    this.spinner.show();

    const formData = {
      "estimatedCost":  this.skillStandardForm.value.EstimatedCost ,
      "estimatedDuration":  this.skillStandardForm.value.EstimatedDuration ,
      "estimatedNumPeople":  this.skillStandardForm.value.EstimatedPeople ,
      "skillTypeId": this.skillStandardForm.value.SkillTypeID ,
      "workEffortId": this.workEffortId

    }
    this.myContactsService.updateTaskSkillStandard(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.skillStandardForm.reset();
        this.getSkillList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  createTaskSkillStandard() {
    this.spinner.show();

    const formData = {
      "estimatedCost":  this.skillStandardForm.value.EstimatedCost ,
      "estimatedDuration":  this.skillStandardForm.value.EstimatedDuration ,
      "estimatedNumPeople":  this.skillStandardForm.value.EstimatedPeople ,
      "skillTypeId": this.skillStandardForm.value.SkillTypeID ,
      "workEffortId": this.workEffortId

    }
    this.myContactsService.createTaskSkillStandard(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.skillStandardForm.reset();
        this.getSkillList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getSkillList() {
    this.spinner.show();
    this.myContactsService.getSkillList(this.workEffortId).subscribe(res => {
      this.SkillList = res.data;
      this.spinner.hide();
    })
  }
  updateTaskPartyAssign(product) {
    this.spinner.show();
    const formData = {
      "fromDate":this.datePipe.transform(product.data.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "partyId": product.data.partyId,
      "projectId": this.projectId,
      "roleTypeId": product.data.roleTypeId,
      "statusId": "PAS_COMPLETED",
      "thruDate":this.datePipe.transform(product.data.thruDate, "yyyy-MM-dd hh:mm:ss"),
      "view_INDEX_1": "0",
      "view_SIZE_1": "20",
      "workEffortId": this.workEffortId
    }
    this.myContactsService.updateTaskPartyAssign(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getResourceList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  createTaskPartyAssign() {
    this.spinner.show();

    const fromdate = this.addResourceForm.get('FromDate').value;
    this.FromDate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");
    const throughdate = this.addResourceForm.get('ThroughDate').value;
    this.ThroughDate = this.datePipe.transform(throughdate, "yyyy-MM-dd hh:mm:ss");

    const formData = {
      "fromDate":  this.FromDate,
  "partyId":this.addResourceForm.value.PartyId,
  "projectId": this.projectId ,
  "roleTypeId": this.addResourceForm.value.roleTypeID,
  "statusId": "PAS_ASSIGNED",
  "thruDate": this.ThroughDate,
  "workEffortId": this.workEffortId

    }
    this.myContactsService.createTaskPartyAssign(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.addResourceForm.reset();
        this.getResourceList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getPartyIdList() {
    this.spinner.show();
    this.myContactsService.getPartyIdList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  removeTaskPartyAssign(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "deleteButton": "",
          "fromDate": this.datePipe.transform(product.data.fromDate, "yyyy-MM-dd hh:mm:ss"),
          "partyId": product.data.partyId,
          "projectId": this.projectId,
          "roleTypeId": product.data.roleTypeId  ,
          "statusId":product.data.statusId,
          "thruDate": this.datePipe.transform(product.data.thruDate, "yyyy-MM-dd hh:mm:ss"),
          "workEffortId": this.workEffortId
        }
        this.myContactsService.removeTaskPartyAssign(req).subscribe(res => {
          this.getResourceList();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  getRequestList() {
    this.spinner.show();
    this.myContactsService.getRequestList(this.workEffortId).subscribe(res => {
      this.RequestList = res.data;
      this.spinner.hide();
    })
  }

  getTaskTeam() {
    this.spinner.show();
    this.myContactsService.getTaskTeam(this.workEffortId).subscribe(res => {
      this.TaskTeam = res.data;
      this.spinner.hide();
    })
  }

  updateTaskSave() {
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();
    const formData = {
      "workEffortParentId": this.EditTaskForm.value.ParentPhase,
      "workEffortId": this.workEffortId

    }
    this.myContactsService.updateTaskSave(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.TaskInformationById();
        this.activeCategory=1;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.toastr.error("Permission Denied"); 
    }
   
  }
  removeDependencyAfter(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {

          "actualCompletionDate": this.datePipe.transform(product.workeffortListTo[0].actualCompletionDate, "yyyy-MM-dd hh:mm:ss"),
          "actualStartDate": this.datePipe.transform(product.workeffortListTo[0].actualStartDate, "yyyy-MM-dd hh:mm:ss"),
          "currentStatusId": product.workeffortListTo[0].currentStatusId,
          "estimatedCompletionDate": this.datePipe.transform(product.workeffortListTo[0].estimatedCompletionDate, "yyyy-MM-dd hh:mm:ss"),
          "estimatedStartDate":this.datePipe.transform(product.workeffortListTo[0].estimatedStartDate, "yyyy-MM-dd hh:mm:ss"),
          "fromDate":  this.datePipe.transform(product.data.fromDate , "yyyy-MM-dd hh:mm:ss"),
          "workEffortAssocTypeId": "WORK_EFF_DEPENDENCY",
          "workEffortId": this.workEffortId,
          "workEffortIdFrom": this.workEffortId,
          "workEffortIdTo": product.data.workEffortIdTo,
          "workEffortParentId": product.workeffortListFrom[0].workEffortId
        }
        this.myContactsService.removeDependencyAfter(req).subscribe(res => {
          this.getDependencyTo();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  setCompleteTask() {
    this.spinner.show();
    const formData = {
      "currentStatusId": "STS_COMPLETED",
      "workEffortId": this.workEffortId

    }
    this.myContactsService.setCompleteTask(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.activeCategory=1;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getResourceList() {
    this.spinner.show();
    this.myContactsService.getResourceList(this.workEffortId).subscribe(res => {
      this.ResourceList = res.data;
      this.spinner.hide();
    })
  }
  myProfile(partyId) {
    this.router.navigate(["/psa/my-profile"], { queryParams: { partyId: partyId } })
  }

  getRelated() {
    this.spinner.show();
    this.myContactsService.getRelated().subscribe(res => {
      this.RelatedTaskList = res.data;
      this.spinner.hide();
      for (const value of this.RelatedTaskList) {
        this.RelatedTaskListArray.push({
          label: value.data.workEffortName + " -- " + value.phaseName ,
          value: value.data.workEffortId
        })
      }
    })
  }
  removeDependencyBefore(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {

          "actualCompletionDate":this.datePipe.transform(product.workeffortListFrom[0].actualCompletionDate ,"yyyy-MM-dd hh:mm:ss"),
          "actualStartDate": this.datePipe.transform(product.workeffortListFrom[0].actualStartDate, "yyyy-MM-dd hh:mm:ss"),
          "currentStatusId": product.workeffortListFrom[0].currentStatusId,
          "estimatedCompletionDate":this.datePipe.transform(product.workeffortListFrom[0].estimatedCompletionDate, "yyyy-MM-dd hh:mm:ss"), 
          "estimatedStartDate":this.datePipe.transform(product.workeffortListFrom[0].estimatedStartDate, "yyyy-MM-dd hh:mm:ss"),
          "fromDate": this.datePipe.transform(product.work.fromDate, "yyyy-MM-dd hh:mm:ss"),
          "workEffortAssocTypeId": "WORK_EFF_DEPENDENCY",
          "workEffortId": this.workEffortId,
          "workEffortIdFrom": product.work.workEffortIdFrom,
          "workEffortIdTo": this.workEffortId,
          "workEffortParentId": product.workeffortListFrom[0].workEffortId
        }
        this.myContactsService.removeDependencyBefore(req).subscribe(res => {
          this.getDependencyFrom();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  addDependencyTo() {
    this.spinner.show();
    const formData = {

      "workEffortAssocTypeId": "WORK_EFF_DEPENDENCY",
      "workEffortIdFrom": this.workEffortId,
      "workEffortIdTo": this.DependencyAfterForm.value.RelatedTasks,
      "workEffortId": this.workEffortId

    }
    this.myContactsService.addDependencyFrom(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getDependencyTo();
        this.closebutton4.nativeElement.click();
        this.DependencyAfterForm.reset();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  addDependencyFrom() {
    this.spinner.show();
    const formData = {
      "addButton": "",
      "workEffortAssocTypeId": "WORK_EFF_DEPENDENCY",
      "workEffortIdFrom": this.DependencyBeforeForm.value.RelatedTasks,
      "workEffortIdTo": this.workEffortId,
      "workEffortId": this.workEffortId

    }
    this.myContactsService.addDependencyFrom(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getDependencyFrom();
        this.closebutton3.nativeElement.click();
        this.DependencyBeforeForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  setTaskStatusRelease() {
    this.spinner.show();
    const formData = {
      "currentStatusId": "PTS_CREATED",
      "workEffortId": this.workEffortId

    }
    this.myContactsService.setTaskStatus(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  setTaskStatus() {
    this.spinner.show();
    const formData = {
      "currentStatusId": "PTS_ON_HOLD",
      "workEffortId": this.workEffortId

    }
    this.myContactsService.setTaskStatus(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  setTaskStatusCancel() {
    this.spinner.show();
    const formData = {
      "currentStatusId": "PTS_CANCELLED",
      "workEffortId": this.workEffortId

    }
    this.myContactsService.setTaskStatus(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  getRelatedTask() {
    this.spinner.show();
    this.myContactsService.getRelatedTask().subscribe(res => {
      this.RelatedTask = res.data[0];
      this.spinner.hide();
      for (const value of this.RelatedTask) {
        this.RelatedTaskArray.push({
          label: value.workEffortName,
          value: value.workEffortId
        })
      }
    })
  }

  getSurveyData() {
    this.spinner.show();
    this.myContactsService.getSurveyData(this.workEffortId).subscribe(res => {
      this.SurveyData = res.data;
      this.spinner.hide();
    })
  }
  createNewTask() {
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();

      const startDate = this.newTaskProject.get('startDate').value;
      this.startdate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
      const endDate = this.newTaskProject.get('endDate').value;
      this.endate = this.datePipe.transform(endDate, "yyyy-MM-dd hh:mm:ss");
  
      const formData = {
        "currentStatusId": "PTS_CREATED",
        "description": this.newTaskProject.value.description,
        "estimatedCompletionDate": this.endate,
        "estimatedHours": this.newTaskProject.value.estimatedHours,
        "estimatedStartDate": this.startdate,
        "partyId": "admin",
        "priority": this.newTaskProject.value.priority,
        "projectId": "",
        "roleTypeId": this.newTaskProject.value.roleWeek,
        "scopeEnumId": this.newTaskProject.value.scope,
        "sequenceNum": this.newTaskProject.value.sequenceNum,
        "skillTypeId": this.newTaskProject.value.skillTypeID,
        "statusId": "PAS_ASSIGNED",
        "workEffortName": this.newTaskProject.value.taskName,
        "workEffortParentId": this.newTaskProject.value.parentPhase,
        "workEffortTypeId": this.newTaskProject.value.type,
      }
      this.myContactsService.createNewTasks(formData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Success");
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.getFindTaskInformationById();
          this.router.navigate(["/psa/project/my-tasks"])
  
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.toastr.error("Permission Denied"); 
    }

  }

  makePrivateN(product) {
    this.spinner.show();
    const requestData = {
      "internalNote": "N",
      "noteId": product.list.noteId,
      "view_INDEX_1": "20",
      "view_SIZE_1": "0",
      "workEffortId": this.workEffortId
    }
    this.myContactsService.makePrivate(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getTaskNoteList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  makePrivate(product) {
    this.spinner.show();
    const requestData = {
      "internalNote": "Y",
      "noteId": product.list.noteId,
      "view_INDEX_1": "20",
      "view_SIZE_1": "0",
      "workEffortId": this.workEffortId
    }

    this.myContactsService.makePrivate(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getTaskNoteList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getScopeList() {
    this.spinner.show();
    this.myContactsService.getTaskScopeList().subscribe(res => {
      this.Scope = res.data;
      this.spinner.hide();
      for (const value of this.Scope) {
        this.ScopeArray.push({
          label: value.scopeData.description,
          value: value.scopeData.enumId
        })
      }
    })
  }
  detailNewTask() {
    this.router.navigate(["/psa/project/detail-new-task"])
  }
  getTypeList() {
    this.spinner.show();
    this.myContactsService.getTypeList().subscribe(res => {
      this.TypeList = res.data;
      this.spinner.hide();
      for (const value of this.TypeList) {
        this.TypeListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }
  getSkillTypeId() {
    this.spinner.show();
    this.myContactsService.getSkillTypeId().subscribe(res => {
      this.SkillTypeId = res.data;
      this.spinner.hide();
      for (const value of this.SkillTypeId) {
        this.SkillTypeIdArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }
    })
  }
  getPriorityList() {
    this.spinner.show();
    this.myContactsService.getPriorityList().subscribe(res => {
      this.PriorityList = res.data;
      this.spinner.hide();
      for (const value of this.PriorityList) {
        this.PriorityListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }

  getRoleResourceList() {
    this.spinner.show();
    this.myContactsService.getRoleTaskList().subscribe(res => {
      this.RoleResourceList = res.data;
      this.spinner.hide();
      for (const value of this.RoleResourceList) {
        this.RoleResourceListArray.push({
          label: value.roleTypeData.description,
          value: value.roleTypeData.roleTypeId
        })
      }
    })
  }
  getPhaseList() {
    this.spinner.show();
    this.myContactsService.getPhaseList().subscribe(res => {
      this.ParentPhase = res.data;
      this.spinner.hide();
      for (const value of this.ParentPhase) {
        this.ParentPhaseArray.push({
          label: value.getParentPhaseList.projectName + "--" + value.getParentPhaseList.phaseName,
          value: value.getParentPhaseList.phaseId
        })
      }
    })
  }
  getSurveyList() {
    this.spinner.show();
    this.myContactsService.getSurveyList().subscribe(res => {
      this.SurveyList = res.data;
      this.spinner.hide();
    })
  }
  getFindTaskInformationById() {
    this.spinner.show();
    this.myContactsService.getFindTaskInformationById(this.workEffortId).subscribe(res => {
      this.TaskInformationById = res.data[0];
      this.TaskStatus = res.data[0].projectStatusId;
      this.spinner.hide();
    })
  }
  getDependencyFrom() {
    this.spinner.show();
    this.myContactsService.getDependencyFrom(this.workEffortId).subscribe(res => {
      this.DependencyFromList = res.data;
      this.spinner.hide();
    })
  }
  getDependencyTo() {
    this.spinner.show();
    this.myContactsService.getDependencyTo(this.workEffortId).subscribe(res => {
      this.DependencyToList = res.data;
      this.spinner.hide();
    })
  }

  getTaskNoteList() {
    this.spinner.show();
    this.myContactsService.getTaskNoteList().subscribe(res => {
      this.TaskNoteList = res.data;
      this.spinner.hide();
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
