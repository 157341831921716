import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { HeaderService } from '../../../header/header.service';
import { Router } from '@angular/router';
import { AccountsService } from '../../accounts/accounts.service';
import { ActivatedRoute } from '@angular/router';
import { AccountLeadSourceService } from '../detailed-accounts-summary/detailed-account-service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { detailedContactsService } from '../../contacts/detailed-contacts-summary/detailed-contacts-summary.service';
import * as moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { DatePipe } from '@angular/common';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { GlobalResourceService } from 'src/app/services/global-resource.service';
//import { CreateAccountFormService } from '../../create-account/create-account-service';
import { CreateAccountService } from '../../create-account/create-account.service';
import { EditAccountServices } from 'src/app/editContactGroup/edit-contact-group/edit-contact-group.service';
import { CreateAccountFormService } from '../../create-account/create-account-service';
import { MyContactsService } from '../../contacts/my-contacts/my-contacts.service';
import { DetailedEmployeeSummary } from 'src/app/human-resource/detailed-employee-summary/detailed-employee-summary.service';
declare var $: any;
import { Location } from '@angular/common';

@Component({
  selector: 'app-detailed-accounts-summary',
  templateUrl: './detailed-accounts-summary.component.html',
  styleUrls: ['./detailed-accounts-summary.component.css'],
  providers: [DatePipe]
})
export class DetailedAccountsSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  @ViewChild('closebutton12') closebutton12;
  @ViewChild('closebutton13') closebutton13;
  @ViewChild('closebutton14') closebutton14;
  @ViewChild('closebutton15') closebutton15;
  @ViewChild('closebutton16') closebutton16;
  @ViewChild('closebutton17') closebutton17;
  @ViewChild('closebutton18') closebutton18;
  @ViewChild('closebutton19') closebutton19;
  @ViewChild('closebutton20') closebutton20;



  faEdit = faEdit;
  faTrash = faTrash;
  products3: any;
  activeCategory = 1;
  activeCategorymain = 2;
  total = 0;
  rowNumber = 0;
  pageSize = 10;
  rows = 50;
  partyId: any;
  accountDetails: any;
  accountData: any;
  accountDataNote: any;
  relatedContact: any;
  accountName: any;
  leadSourceData: any;
  leadSourcearray: any = [];
  isCreate: any = [];
  leadSourceList: any;
  contentList: any;
  Editform: FormGroup;
  fileData: File;
  partyContentTypeId: any;
  partyContentData: any;
  addr;
  emailId: any;
  phoneNumber: string;
  public selectedIsCreate = 'Y';
  partyContentArray: any = [];
  tableArray = [{}]
  paymentMethods: any[];
  billingAccounts: any[];
  isAdditionalDetail: boolean;
  isJobDetail: boolean;
  contactInformation: any[];
  category: any;
  imageData: any;
  imagePic: boolean;
  address1: any;
  address2: any;
  areaCode: any;
  city: any;
  contactNumber: any;
  countryCode: any;
  countryGeoId: any;
  emailAddress: any;
  extension: any;
  groupName: any;
  numEmployees: any;
  officeSiteName: any;
  postalCode: any;
  stateProvinceGeoId: any;
  show = false;
  array: any = [];
  stateData: any;
  createContact: any;
  countryId: any;
  stateArray: any = [];
  // currencyArray: any = [];
  // crmArray: any = [];
  accountForm: FormGroup;
  additionalDetailForm: FormGroup;
  jobInfoForm: FormGroup;
  customerType = [{

    label: 'Individual Customer',
    value: 'IndividualCustomer'
  },
  {
    label: 'Business Customer',
    value: 'BusinessCustomer'
  },
  ]
  salutationArray = [
    {
      label: 'Mr.',
      value: 'Mr.'
    },
    {
      label: 'Mrs.',
      value: 'Mrs.'
    },
    {
      label: 'Ms.',
      value: 'Ms.'
    }
  ];
  currencyList: any[];
  statusList: any[];
  id: any;
  editModeA = false;
  jobInfo: any;
  additionalInfo: any;


  editMode: boolean;

  fileUploadForm: FormGroup;
  partyIdForCreate: any;
  noteId: any;
  showUpdateBtn = false;
  showCreateBtn = false;
  noteName = '';
  note = '';

  fileUploadFormContact: FormGroup;

  contactId: any;
  contactArray: any = [];
  params: any;

  creditCardForm: FormGroup;
  cardTypes: any[];
  prefixArray = [
    {
      label: 'Mr.',
      value: 'Mr.'
    },
    {
      label: 'Mrs.',
      value: 'Mrs.'
    },
    {
      label: 'Ms.',
      value: 'Ms.'
    },
    {
      label: 'Dr.',
      value: 'Dr.'
    }];
  suffixArray = [
    {
      label: 'Jr.',
      value: 'Jr.'
    },
    {
      label: 'Sr.',
      value: 'Sr.'
    },
    {
      label: 'I',
      value: 'I'
    },
    {
      label: 'II',
      value: 'II'
    },
    {
      label: 'III',
      value: 'III'
    },
    {
      label: 'IV',
      value: 'IV'
    },
    {
      label: 'V',
      value: 'V'
    },
  ];
  expirationMonth = [
    {
      label: '1 (Jan)',
      value: '1'
    },
    {
      label: '2 (Feb)',
      value: '2'
    },
    {
      label: '3 (Mar)',
      value: '3'
    },
    {
      label: '4 (Apr)',
      value: '4'
    },
    {
      label: '5 (May)',
      value: '5'
    },
    {
      label: '6 (Jun)',
      value: '6'
    },
    {
      label: '7 (Jul)',
      value: '7'
    },
    {
      label: '8 (Aug)',
      value: '8'
    },
    {
      label: '9 (Sep)',
      value: '9'
    },
    {
      label: '10 (Oct)',
      value: '10'
    },
    {
      label: '11 (Nov)',
      value: '11'
    },
    {
      label: '12 (Dec)',
      value: '12'
    }];
  expirationYear = [


    {
      label: '2020',
      value: '2020'
    },
    {
      label: '2021',
      value: '2021'
    },
    {
      label: '2022',
      value: '2022'
    },
    {
      label: '2023',
      value: '2023'
    },

    {
      label: '2024',
      value: '2024'
    },
    {
      label: '2025',
      value: '2025'
    },
    {
      label: '2026',
      value: '2026'
    },
    {
      label: '2027',
      value: '2027'
    },
    {
      label: '2028',
      value: '2028'
    },
    {
      label: '2029',
      value: '2029'
    },
    {
      label: '2030',
      value: '2030'
    }];
  contactMechId: string;
  paymentMethodId: string;
  creditCardValue: any;


  giftForm: FormGroup;

  giftCard: any;

  //partyId: string;

  eftAccountForm: FormGroup;

  isEftAccount: boolean;
  eftAccount: any;
  accountTypes = [
    {
      label: 'Checking',
      value: 'Checking'
    },
    {
      label: 'Savings',
      value: 'Savings'
    }];


  paymentMethodTypes: any[];
  paymentMethodTypeId: string;
  paymentMethodValue: any;
  currencyUomIds: any[];
  billingAccountForm: FormGroup;
  partyIds: any[];
  billingAccount: any;
  availableBalance: string;


  addressForm: FormGroup;
  solicitationArray = [
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }
  ];
  contactMechTypeId: any[];
  countryArray: any[];
  accountInfo: any;

  contactForm: FormGroup;
  contact: any;
  arrayfax = [
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }
  ];
  contactPurpose: any[];
  AllContactRelatedAccount: any;
  createCasesForm: FormGroup;
  createAccountsForm: FormGroup;
  createNotesForm: FormGroup;
  teamMemberForm: FormGroup;
  productAcc: any;
  actualfromdate: string;
  allAccountData: any;
  productOpp: any;
  allOpportunityData: any;
  createOrderForm: FormGroup;
  allOrderByData: any;
  shipBeforeDate: string;
  productOrder: any;
  createCatalogForm: FormGroup;
  allCatalogRequestData: any;
  productCatalog: any;
  createCasesNewForm: any;
  allCasessData: any;
  productCasessNew: any;
  createNewTaskForm: FormGroup;
  allNewTaskData: any;
  dueDateVal: string;
  productNewTask: any;
  createActivitiesHistoryForm: FormGroup;
  allActivityHistoryData: any;
  productActivitiesHistory: any;
  createDocumentFilesForm: FormGroup;
  allBookMarkUrlByData: any;
  productDocumentFiles: any;

  constructor(
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    public accountLeadSourceService: AccountLeadSourceService,
    readonly toastr: ToastrService,
    @Inject(DOCUMENT) readonly document: Document,
    readonly activatedRoute: ActivatedRoute,
    readonly _detailedContactsService: detailedContactsService,
    readonly datePipe: DatePipe,
    readonly _CRMAccountService: CRMAccountService,
    readonly createAccountService: CreateAccountService,
    readonly _GlobalResourceService: GlobalResourceService,
    public createAccountFormService: CreateAccountFormService,
    readonly _FormBuilder: FormBuilder,
    readonly _EditAccountServices: EditAccountServices,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    readonly formBuilder: FormBuilder,
    readonly myContactsService: MyContactsService,
    readonly _DetailedEmployeeSummary: DetailedEmployeeSummary,
    private _location: Location,

  ) {
    this.paymentMethods = [];
    this.billingAccounts = [];
    this.contactInformation = [];
    this.isAdditionalDetail = false;
    this.isJobDetail = false;

    this.teamMemberForm=this._FormBuilder.group({
      newTeamMember:"",
      role:"",
    })
    this.createCatalogForm =this._FormBuilder.group({
      activity: "",
      address: "",
      date: "",
      fullFilled: "",
      takenBy: ""
    });
    this.createCasesNewForm= this._FormBuilder.group({
      priority: "",
      reason: "",
      status: "",
      subject: "",
      type: ""
    });
    this.createNewTaskForm= this._FormBuilder.group({
      activity: "",
      dueDate: "",
      purpose: "",
      scheduledDate: "",
      status: "",
      type: ""
    });
    this.createActivitiesHistoryForm=this._FormBuilder.group({
      activity: "",
      completionDate: "",
      purpose: "",
      startedDate: "",
      status: "",
      types: ""
    });
    this.createDocumentFilesForm=this._FormBuilder.group({
      classification: "",
      createdDate: "",
      description: "",
      name: "",
    });
    this.createOrderForm=this._FormBuilder.group({
      amount: "",
      customer: "",
      orderDate: "",
      orderName: "",
      po: "",
      shipBeforeDate: "",
      status: ""
    })

    this.createCasesForm=this._FormBuilder.group({
      opportunityName:"",
      stage:"",
      opportunityAmount:"",
      estimatedCloseDate:"",
    })

    this.createAccountsForm=this._FormBuilder.group({
      accountName:"",
      city:"",
      email:"",
      primaryPhone:"",
    })

    this.createNotesForm=this._FormBuilder.group({
      noteInfo:"",
      createdBy:"",
      noteDateTime:"",
    })
    

    this.jobInfoForm = this._FormBuilder.group({
      description: [''],
      endDate: [''],
      jobStatus: [''],
      jobType: [''],
      partyId: [''],
      productType: [''],
      projectedEndDate: [''],
      serviceType: [''],
      startDate: ['']
    });

    this.additionalDetailForm = this._FormBuilder.group({
      infoType: [''],
      partyId: [''],
      prefeferredSendMethod: [''],
      priceLevel: [''],
      rep: [''],
      resaleNo: [''],
      terms: ['']
    });
    this.accountForm = this._FormBuilder.group({
      address1: [''],
      address2: [''],
      areaCode: [''],
      city: [''],
      contactNumber: [''],
      countryCode: [''],
      countryGeoId: [''],
      emailAddress: [''],
      extension: [''],
      groupName: ['', [Validators.required]],
      numEmployees: [''],
      officeSiteName: [''],
      postalCode: [''],
      stateProvinceGeoId: [''],
      firstName: [''],
      lastName: [''],
      title: [''],
      customerType: [''],
      annualRevenue: [''],
      description: [''],
      externalId: [''],
      logoImageUrl: [''],
      preferredCurrencyUomId: [''],
      statusId: [''],
    });

    this.partyId = '';
    this.editMode = false;
    this.additionalDetailForm = this._FormBuilder.group({
      infoType: [''],
      partyId: [''],
      prefeferredSendMethod: [''],
      priceLevel: [''],
      rep: [''],
      resaleNo: [''],
      terms: ['']
    });

    this.editMode = false;
    this.partyId = '';
    this.jobInfoForm = this._FormBuilder.group({
      description: [''],
      endDate: [''],
      jobStatus: [''],
      jobType: [''],
      partyId: [''],
      productType: [''],
      projectedEndDate: [''],
      serviceType: [''],
      startDate: ['']
    });

    this.partyId = '';
    this.editMode = false;
    this.creditCardForm = this._FormBuilder.group({
      cardNumber: ['', [Validators.required]],
      cardType: ['', [Validators.required]],
      companyNameOnCard: [''],
      contactMechId: [''],
      description: [''],
      expMonth: ['', [Validators.required]],
      expYear: ['', [Validators.required]],
      firstNameOnCard: ['', [Validators.required]],
      lastNameOnCard: ['', [Validators.required]],
      middleNameOnCard: ['', [Validators.required]],
      suffixOnCard: [''],
      titleOnCard: ['']
    });
    this.cardTypes = [];
    this.contactMechId = '';
    this.paymentMethodId = '';

    this.editMode = false;
    this.giftForm = this._FormBuilder.group({
      cardNumber: ['', [Validators.required]],
      description: [''],
      expMonth: ['', Validators.required],
      expYear: ['', Validators.required],
      pinNumber: ['', Validators.required]
    });
    this.partyId = '';
    this.paymentMethodId = '';

    this.partyId = '';
    this.eftAccountForm = this._FormBuilder.group({
      accountNumber: ['', [Validators.required]],
      accountType: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      companyNameOnAccount: [''],
      contactMechId: [''],
      description: [''],
      nameOnAccount: ['', [Validators.required]],
      routingNumber: ['', [Validators.required]]
    });
    this.editMode = false;
    this.contactMechId = '';
    this.isEftAccount = false;

    this.partyId = '';
    this.eftAccountForm = this._FormBuilder.group({
      accountNumber: ['', [Validators.required]],
      accountType: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      companyNameOnAccount: [''],
      contactMechId: [''],
      description: [''],
      paymentMethodTypeId: ['', [Validators.required]],
      nameOnAccount: ['', [Validators.required]],
      routingNumber: ['', [Validators.required]]
    });
    this.editMode = false;
    this.contactMechId = '';
    this.paymentMethodTypes = [];
    this.paymentMethodId = '';
    this.paymentMethodTypeId = '';

    this.editMode = false;
    this.partyId = '';

    this.billingAccountForm = this._FormBuilder.group({
      accountCurrencyUomId: [''],
      accountLimit: [''],
      contactMechId: [''],
      description: [''],
      externalAccountId: [''],
      fromDate: [''],
      partyId: ['', [Validators.required]],
      thruDate: ['']
    });
    this.currencyUomIds = [];
    this.partyIds = [];
    this.paymentMethodId = '';
    this.availableBalance = '0';

    this.addressForm = this._FormBuilder.group({
      address1: [''],
      address2: [''],
      allowSolicitation: [''],
      attnName: [''],
      city: [''],
      contactMechId: [''],
      contactMechPurposeTypeId: ['', [Validators.required]],
      contactMechTypeId: [''],
      countryGeoId: [''],
      directions: [''],
      partyId: [''],
      postalCode: [''],
      preContactMechTypeId: ['POSTAL_ADDRESS'],
      stateProvinceGeoId: [''],
      toName: ['']
    });
    this.editMode = false;
    this.partyId = '';
    this.countryArray = [];
    this.stateArray = [];
    this.contactMechTypeId = [];
    this.contactMechId = '';

    this.partyId = '';
    this.editMode = false;
    this.contactForm = this._FormBuilder.group({
      allowSolicitation: [''],
      areaCode: [''],
      askForName: [''],
      contactMechId: [''],
      contactMechPurposeTypeId: ['', [Validators.required]],
      contactMechTypeId: [''],
      contactNumber: [''],
      countryCode: [''],
      extension: [''],
      partyId: [''],
      preContactMechTypeId: ['TELECOM_NUMBER']
    });
    this.contactPurpose = [];
    this.contactMechId = '';


  }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.accountLeadSourceService.lead_source_form = null;
    this.accountLeadSourceService.AccountleadSourceForm(this.accountLeadSourceService.lead_source_form);
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params['partyId']
    });

    this.accountDetail();


    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
      partyContentTypeId: new FormControl('')
    });

    this.accountDetail();
    this.getAccountNote();
    this.getLeadSourcedata();
    this.getLeadSourceList();
    this.getPartyContentsList();
    this.getPartyContent();
    this.getCustomerPersonalImage();
    this.getAccountByAccountId();
    this.getOpportunity();
    this.getOrderByPartyId();
    this.products3 = this.accountsService.customerDetails();
    this.isCreate.push({
      'label': 'Y',
      'value': 'Y'
    },
      {
        'label': 'N',
        'value': 'N'
      });
    this.getPaymentMethods();

    this.getCountryList();

    this.accountForm.controls.countryGeoId.valueChanges.subscribe(data => {
      if (data) {
        this.countryId = data;
        this.getStateList();
      }
    });

    this.createAccountFormService.create_account_form = null;
    this.createAccountFormService.prepareAccountForm(this.createAccountFormService.create_account_form);
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.partyId = params["partyId"];
    // })

    if (this.partyId) {
      this.show = true;
      this.editMode = true;
      this.getData();
      this._CRMAccountService.getAccountById(this.partyId)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.additionalInfo = data.data.additionalInformation;
            this.additionalDetailForm.patchValue({
              ...data.data.additionalInformation
            });
          }
        });
      this._CRMAccountService.getAccountById(this.partyId)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.jobInfo = data.data.jobInformation;
            this.jobInfoForm.patchValue({
              ...data.data.jobInformation,
              startDate: data.data.jobInformation.startDate ? moment(data.data.jobInformation.startDate).format('YYYY-MM-DD') : '',
              endDate: data.data.jobInformation.endDate ? moment(data.data.jobInformation.endDate).format('YYYY-MM-DD') : '',
              projectedEndDate: data.data.jobInformation.projectedEndDate ? moment(data.data.jobInformation.projectedEndDate).format('YYYY-MM-DD') : ''
            });

          }

        });
    }
    else {
      this.show = false;
    }
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });

    this.getCrmStatus();
    this.getCurrency();


    this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
    this.editMode = this._ActivatedRoute.snapshot.queryParams.isEditable === 'true' ? true : false;
    if (this.editMode) {
      this.spinner.show();
      this._CRMAccountService.getAccountById(this.partyId)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.additionalInfo = data.data.additionalInformation;
            this.additionalDetailForm.patchValue({
              ...data.data.additionalInformation
            });
          }
        });

    }
    this.fileUploadForm = this.formBuilder.group({
      noteName: [''],
      note: ['']
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate = params["partyId"];
      const partyId = this.partyIdForCreate
      if (partyId) {
        this.showCreateBtn = true;
      }
    });


    this.activatedRoute.queryParams.subscribe(params => {
      this.noteId = params["noteId"];
      this.partyId = params["leadId"];
      this.noteName = params["noteName"];
      this.note = params["note"];
      if (this.noteId && this.partyId) {
        this.showUpdateBtn = true;
      }
    });

    this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
    this.editMode = this._ActivatedRoute.snapshot.queryParams.isEditable === 'true' ? true : false;
    if (this.editMode) {
      this.spinner.show();
      this._CRMAccountService.getAccountById(this.partyId)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.jobInfo = data.data.jobInformation;
            this.jobInfoForm.patchValue({
              ...data.data.jobInformation,
              startDate: data.data.jobInformation.startDate ? moment(data.data.jobInformation.startDate).format('YYYY-MM-DD') : '',
              endDate: data.data.jobInformation.endDate ? moment(data.data.jobInformation.endDate).format('YYYY-MM-DD') : '',
              projectedEndDate: data.data.jobInformation.projectedEndDate ? moment(data.data.jobInformation.projectedEndDate).format('YYYY-MM-DD') : ''
            });

          }

        });

    }

    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"]
    });


    this.getContactList();
    this.fileUploadFormContact = this.formBuilder.group({
      accountPartyId: [''],
      comments: [''],
      contactPartyId: [''],
      partyId: ['']
    });

    this.getCardTypes();
    this.getAccountDetails();

    this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
    this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
    if (this.paymentMethodId) {
      this.getPaymentMethod();
    }

    this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
    this.isEftAccount = this._ActivatedRoute.snapshot.queryParams.isEft === 'false' ? false : true;
    this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
    this.getAccountDetails();
    if (this.paymentMethodId) {
      this.getPaymentMethodEFT();
    }

    this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
    this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
    this.paymentMethodTypeId = this._ActivatedRoute.snapshot.queryParams.paymentMethodTypeId;
    this.getAccountDetails();
    this.getPaymentMethodTypes();
    if (this.paymentMethodId) {
      this.getPaymentMethodById();
    }

    this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
    this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.billingAccountId;
    this.getCurrencyList();
    this.getPartyIds();
    if (this.paymentMethodId) {
      this.getBillingAccountById();
    }

    this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
    this.contactMechId = this._ActivatedRoute.snapshot.queryParams.contactMechId;
    if (this.contactMechId) {
      this.getAccountDetailsPostal();
    }
    this.addressForm.controls.countryGeoId.valueChanges.subscribe(data => {
      if (data) {
        this.getStates(data);
      }
    });
    this.getCountries();
    this.getPreContactMechIds();

    this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
    this.contactMechId = this._ActivatedRoute.snapshot.queryParams.contactMechId;
    if (this.contactMechId) {
      this.editMode = true;
      this.getContactDetails();

    }
    this.getContactPurpose();
    this.getCatalogRequestByPartyId();
    this.getCasessByPartyId();
    this.getPendingActivityNewTaskByTaskId();
    this.getActivityHistoryLogCallByPartyId();
    this.getBookMarkUrlByPartyId();
    this.getBookMarkUrlByPartyId();
  }
  resetFrom(){
    this.show=false;
    this.createAccountsForm.reset();
    this.createCasesForm.reset();
    this.createCatalogForm.reset();
    this.createCasesNewForm.reset();
    this.createNewTaskForm.reset();
    this.createActivitiesHistoryForm.reset();
    this.createDocumentFilesForm.reset();

  }
  deleteBookMarkUrl(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "bookMarkURLId": product.bookMarkURLId,
          "partyId": product.partyId
        }
        this.accountsService.deleteBookMarkUrl(formData).subscribe(res => {
        this.getBookMarkUrlByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateBookMarkUrl(){
    this.spinner.show();
    const fromActualdate=this.createDocumentFilesForm.get('createdDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    
    const requestData = {
      "bookMarkURLId": "",
      "classification":  this.createDocumentFilesForm.value.classification,
      "createdDate": this.actualfromdate,
      "description": this.createDocumentFilesForm.value.description,
      "name": this.createDocumentFilesForm.value.name,
      "partyId": this.partyId
    }
    this.accountsService.updateBookMarkUrl(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton20.nativeElement.click();
        this.createDocumentFilesForm.reset();
        this.getBookMarkUrlByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateBookMarkUrlPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productDocumentFiles = product ;
      setTimeout(() => {
        const formValue = this.createDocumentFilesForm;
        formValue.patchValue({
            classification: product.classification,
            createdDate: this.datePipe.transform(product.createdDate,"yyyy-MM-dd"),
            description: product.description,
            name: product.name,
        
        })
      }, 2000);
      this.spinner.hide();
  }
  createBookMarkUrl(){
    this.spinner.show();
    const fromActualdate=this.createDocumentFilesForm.get('createdDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    
    const requestData = {
      "bookMarkURLId": "",
      "classification":  this.createDocumentFilesForm.value.classification,
      "createdDate": this.actualfromdate,
      "description": this.createDocumentFilesForm.value.description,
      "name": this.createDocumentFilesForm.value.name,
      "partyId": this.partyId
    }
    this.accountsService.createBookMarkUrl(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton20.nativeElement.click();
        this.createDocumentFilesForm.reset();
        this.getBookMarkUrlByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  getBookMarkUrlByPartyId() {
    this.spinner.show();
    this.accountsService.getBookMarkUrlByPartyId(this.partyId).subscribe(res => {
      this.allBookMarkUrlByData = res.data[0].getBookMarkUrlByPartyId;
      this.spinner.hide();
    });
  }
  
  deleteActivityHistoryLogCall(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "logCallId": product.logCallId,
          "partyId": product.partyId
        }
        this.accountsService.deleteActivityHistoryLogCall(formData).subscribe(res => {
        this.getActivityHistoryLogCallByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateActivityHistoryLogCall(){
    this.spinner.show();
    const fromActualdate=this.createActivitiesHistoryForm.get('startedDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const dueDateVal=this.createActivitiesHistoryForm.get('completionDate').value;
    this.dueDateVal=this.datePipe.transform(dueDateVal,"yyyy-MM-dd hh:mm:ss");
    
    const requestData = {
      "activity": this.createActivitiesHistoryForm.value.activity,
      "completionDate": this.dueDateVal,
      "logCallId": this.productActivitiesHistory.logCallId,
      "partyId": this.productActivitiesHistory.partyId,
      "purpose": this.createActivitiesHistoryForm.value.purpose,
      "startedDate": this.actualfromdate,
      "status": this.createActivitiesHistoryForm.value.status,
      "types": this.createActivitiesHistoryForm.value.types,
    }
    this.accountsService.updateActivityHistoryLogCall(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton19.nativeElement.click();
        this.createActivitiesHistoryForm.reset();
        this.getActivityHistoryLogCallByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateActivityHistoryLogCallPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productActivitiesHistory = product ;
      setTimeout(() => {
        const formValue = this.createActivitiesHistoryForm;
        formValue.patchValue({
            activity: product.activity,
            completionDate: this.datePipe.transform(product.completionDate,"yyyy-MM-dd"),
            purpose: product.purpose,
            startedDate: this.datePipe.transform(product.startedDate,"yyyy-MM-dd"),
            status: product.status,
            types: product.types
          
        })
      }, 2000);
      this.spinner.hide();
  }
  createActivityHistoryLogCall(){
    this.spinner.show();
    const fromActualdate=this.createActivitiesHistoryForm.get('startedDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const dueDateVal=this.createActivitiesHistoryForm.get('completionDate').value;
    this.dueDateVal=this.datePipe.transform(dueDateVal,"yyyy-MM-dd hh:mm:ss");
    
    const requestData = {
      "activity": this.createActivitiesHistoryForm.value.activity,
      "completionDate": this.dueDateVal,
      "logCallId": "",
      "partyId": this.partyId,
      "purpose": this.createActivitiesHistoryForm.value.purpose,
      "startedDate": this.actualfromdate,
      "status": this.createActivitiesHistoryForm.value.status,
      "types": this.createActivitiesHistoryForm.value.types,
    }
    this.accountsService.createActivityHistoryLogCall(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton19.nativeElement.click();
        this.createActivitiesHistoryForm.reset();
        this.getActivityHistoryLogCallByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getActivityHistoryLogCallByPartyId() {
    this.spinner.show();
    this.accountsService.getActivityHistoryLogCallByPartyId(this.partyId).subscribe(res => {
      this.allActivityHistoryData = res.data[0].getActivityHistoryLogCallByPartyId;
      this.spinner.hide();
    });
  }
  deletePendingActivityTaskPartnerNew(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": product.partnerId,
          "pendingActivityTaskId": product.pendingActivityTaskId
         
        }
        this.accountsService.deletePendingActivityTaskPartnerNew(formData).subscribe(res => {
        this.getPendingActivityNewTaskByTaskId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updatePendingActivityNewTask(){
    this.spinner.show();
    const fromActualdate=this.createNewTaskForm.get('scheduledDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const dueDateVal=this.createNewTaskForm.get('dueDate').value;
    this.dueDateVal=this.datePipe.transform(dueDateVal,"yyyy-MM-dd hh:mm:ss");
    
    const requestData = {
      "activity": this.createNewTaskForm.value.activity,
      "dueDate": this.dueDateVal,
      "partyId": this.productNewTask.partyId,
      "purpose": this.createNewTaskForm.value.purpose,
      "scheduledDate": this.actualfromdate,
      "status": this.createNewTaskForm.value.status,
      "taskId": this.productNewTask.taskId,
      "type": this.createNewTaskForm.value.type
    }
    this.accountsService.updatePendingActivityNewTask(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton18.nativeElement.click();
        this.createNewTaskForm.reset();
        this.getPendingActivityNewTaskByTaskId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updatePendingActivityNewTaskPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productNewTask = product ;
      setTimeout(() => {
        const formValue = this.createNewTaskForm;
        formValue.patchValue({
            activity: product.activity,
            dueDate: this.datePipe.transform(product.dueDate,"yyyy-MM-dd"),
            purpose: product.purpose,
            scheduledDate: this.datePipe.transform(product.scheduledDate,"yyyy-MM-dd"),
            status: product.status,
            type: product.type
        })
      }, 2000);
      this.spinner.hide();
  }
  createPendingActivityNewTask(){
    this.spinner.show();
    const fromActualdate=this.createNewTaskForm.get('scheduledDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const dueDateVal=this.createNewTaskForm.get('dueDate').value;
    this.dueDateVal=this.datePipe.transform(dueDateVal,"yyyy-MM-dd hh:mm:ss");
    
    const requestData = {
      "activity": this.createNewTaskForm.value.activity,
      "dueDate": this.dueDateVal,
      "partyId": this.partyId,
      "purpose": this.createNewTaskForm.value.purpose,
      "scheduledDate": this.actualfromdate,
      "status": this.createNewTaskForm.value.status,
      "taskId": "",
      "type": this.createNewTaskForm.value.type
    }
    this.accountsService.createPendingActivityNewTask(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton18.nativeElement.click();
        this.createNewTaskForm.reset();
        this.getPendingActivityNewTaskByTaskId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  
  getPendingActivityNewTaskByTaskId() {
    this.spinner.show();
    this.accountsService.getPendingActivityNewTaskByTaskId(this.partyId).subscribe(res => {
      this.allNewTaskData = res.data[0].getPendingActivityNewTaskByPartyId;
      this.spinner.hide();
    });
  }
  deleteCasess(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "casesId": product.casesId,
          "partyId": product.partyId
        }
        this.accountsService.deleteCasess(formData).subscribe(res => {
        this.getCasessByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateCasess(){
    this.spinner.show();
    const requestData = {
      "casesId": this.productCasessNew.casesId,
      "partyId": this.productCasessNew.partyId,
      "priority": this.createCasesNewForm.value.priority,
      "reason": this.createCasesNewForm.value.reason,
      "status": this.createCasesNewForm.value.status,
      "subject": this.createCasesNewForm.value.subject,
      "type": this.createCasesNewForm.value.type
    }
    this.accountsService.updateCasess(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton17.nativeElement.click();
        this.createCasesNewForm.reset();
        this.getCasessByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateCasessPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productCasessNew = product ;
      setTimeout(() => {
        const formValue = this.createCasesNewForm;
        formValue.patchValue({
            priority: product.priority,
            reason: product.reason,
            status: product.status,
            subject: product.subject,
            type: product.type
        })
      }, 2000);
      this.spinner.hide();
  }
  createCasess(){
    this.spinner.show();
    const requestData = {
      "casesId": "",
      "partyId": this.partyId,
      "priority": this.createCasesNewForm.value.priority,
      "reason": this.createCasesNewForm.value.reason,
      "status": this.createCasesNewForm.value.status,
      "subject": this.createCasesNewForm.value.subject,
      "type": this.createCasesNewForm.value.type
    }
    this.accountsService.createCasess(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton17.nativeElement.click();
        this.createCasesNewForm.reset();
        this.getCasessByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCasessByPartyId() {
    this.spinner.show();
    this.accountsService.getCasessByPartyId(this.partyId).subscribe(res => {
      this.allCasessData = res.data[0].getCasessByPartyId;
      this.spinner.hide();
    });
  }
  deleteCatalogRequest(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "catalogId": product.catalogId,
          "partnerId": product.partnerId
         
        }
        this.accountsService.deleteCatalogRequest(formData).subscribe(res => {
        this.getCatalogRequestByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  UpdateCatalogRequest(){
    this.spinner.show();
    const fromActualdate=this.createCatalogForm.get('date').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "activity": this.createCatalogForm.value.activity,
      "address": this.createCatalogForm.value.address,
      "catalogId": this.productCatalog.catalogId,
      "date": this.actualfromdate,
      "fullFilled": this.createCatalogForm.value.fullFilled,
      "partnerId": this.productCatalog.partnerId,
      "takenBy": this.createCatalogForm.value.takenBy
    }
    this.accountsService.UpdateCatalogRequest(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton16.nativeElement.click();
        this.createCatalogForm.reset();
        this.getCatalogRequestByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  UpdateCatalogRequestPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productCatalog = product ;
      setTimeout(() => {
        const formValue = this.createCatalogForm;
        formValue.patchValue({
            activity: product.activity,
            address: product.address,
            date: this.datePipe.transform(product.date,"yyyy-MM-dd"),
            fullFilled: product.fullFilled,
            takenBy: product.takenBy
        })
      }, 2000);
      this.spinner.hide();
  }
  createCatalogRequest(){
    this.spinner.show();
    const fromActualdate=this.createCatalogForm.get('date').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "activity": this.createCatalogForm.value.activity,
      "address": this.createCatalogForm.value.address,
      "catalogId": "",
      "date": this.actualfromdate,
      "fullFilled": this.createCatalogForm.value.fullFilled,
      "partnerId": this.partyId,
      "takenBy": this.createCatalogForm.value.takenBy
    }
    this.accountsService.createCatalogRequest(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton16.nativeElement.click();
        this.createCatalogForm.reset();
        this.getCatalogRequestByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCatalogRequestByPartyId() {
    this.spinner.show();
    this.accountsService.getCatalogRequestByPartyId(this.partyId).subscribe(res => {
      this.allCatalogRequestData = res.data[0].getCatalogRequestById;
      this.spinner.hide();
    })
  }
  deleteOrder(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "orderId": product.orderId,
          "partnerId": product.partnerId
        }
        this.accountsService.deleteOrder(formData).subscribe(res => {
        this.getOrderByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateOrder(){
    this.spinner.show();
    const fromActualdate=this.createOrderForm.get('orderDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const shipBeforeDate=this.createOrderForm.get('shipBeforeDate').value;
    this.shipBeforeDate=this.datePipe.transform(shipBeforeDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "amount": this.createOrderForm.value.amount,
      "customer": this.createOrderForm.value.customer,
      "orderDate": this.actualfromdate,
      "orderId": this.productOrder.orderId,
      "orderName": this.createOrderForm.value.orderName,
      "partnerId": this.productOrder.partnerId,
      "po": this.createOrderForm.value.po,
      "shipBeforeDate": this.shipBeforeDate,
      "status": this.createOrderForm.value.status
    }
    this.accountsService.updateOrder(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton15.nativeElement.click();
        this.createOrderForm.reset();
        this.getOrderByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateOrderPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productOrder = product ;
      setTimeout(() => {
        const formValue = this.createOrderForm;
        formValue.patchValue({
            amount: product.amount,
            customer: product.customer,
            orderDate: this.datePipe.transform(product.orderDate,"yyyy-MM-dd"),
            orderName: product.orderName,
            po: product.po,
            shipBeforeDate: this.datePipe.transform(product.shipBeforeDate,"yyyy-MM-dd"),
            status: product.status
        })
      }, 2000);
      this.spinner.hide();
  }
  createOrder(){
    this.spinner.show();
    const fromActualdate=this.createOrderForm.get('orderDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const shipBeforeDate=this.createOrderForm.get('shipBeforeDate').value;
    this.shipBeforeDate=this.datePipe.transform(shipBeforeDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "amount": this.createOrderForm.value.amount,
      "customer": this.createOrderForm.value.customer,
      "orderDate": this.actualfromdate,
      "orderId": this.partyId,
      "orderName": this.createOrderForm.value.orderName,
      "partnerId": "",
      "po": this.createOrderForm.value.po,
      "shipBeforeDate": this.shipBeforeDate,
      "status": this.createOrderForm.value.status
    }
    this.accountsService.createOrder(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton15.nativeElement.click();
        this.createOrderForm.reset();
        this.getOrderByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getOrderByPartyId() {
    this.spinner.show();
    this.accountsService.getOrderByPartyId(this.partyId).subscribe(res => {
      this.allOrderByData = res.data[0].getOrderByPartyId;
      this.spinner.hide();
    })
  }
  deleteOpportunity(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "opportunityId": product.opportunityId,
          "partnerId": product.partnerId
        }
        this.myContactsService.deleteOpportunity(formData).subscribe(res => {
        this.getOpportunity();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateOpportunity(){
    this.spinner.show();
    const fromActualdate=this.createCasesForm.get('estimatedCloseDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "closeDate": this.actualfromdate,
      "opportunityAmount": this.createCasesForm.value.opportunityAmount,
      "opportunityId":this.productOpp.opportunityId,
      "opportunityName": this.createCasesForm.value.opportunityName,
      "partnerId": this.productOpp.partnerId,
      "stage": this.createCasesForm.value.stage
    }
    this.myContactsService.updateOpportunity(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.createCasesForm.reset();
        this.getOpportunity();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateOpportunityPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productOpp = product ;
      setTimeout(() => {
        const formValue = this.createCasesForm;
        formValue.patchValue({
          opportunityName:product.opportunityName,
          stage:product.stage,
          opportunityAmount:product.opportunityAmount,
          estimatedCloseDate:this.datePipe.transform(product.closeDate,"yyyy-MM-dd"),
        })
      }, 2000);
      this.spinner.hide();
  }
  createOpportunities(){
    this.spinner.show();
    const fromActualdate=this.createCasesForm.get('estimatedCloseDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "closeDate": this.actualfromdate,
      "opportunityAmount": this.createCasesForm.value.opportunityAmount,
      "opportunityId":this.partyId,
      "opportunityName": this.createCasesForm.value.opportunityName,
      "partnerId": "",
      "stage": this.createCasesForm.value.stage
    }
    this.myContactsService.createOpportunities(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.createCasesForm.reset();
        this.getOpportunity();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getOpportunity() {
    this.spinner.show();
    this.myContactsService.getOpportunity(this.partyId).subscribe(res => {
      this.allOpportunityData = res.data[0].getOpportunity;
      this.spinner.hide();
    })
  }

  deleteAccount(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": product.partnerId,
          "accountId":product.accountId,
         
        }
        this.myContactsService.deleteAccount(formData).subscribe(res => {
        this.getAccountByAccountId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateAccounts(){
    this.spinner.show();
    const requestData = {
      "partnerId": this.productAcc.accountId,
      "accountId": this.partyId,
      "accountName": this.createAccountsForm.value.accountName,
      "city": this.createAccountsForm.value.city,
      "primaryEmail": this.createAccountsForm.value.email,
      "primaryPhone": this.createAccountsForm.value.primaryPhone,

    }
    this.myContactsService.updateAccounts(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton13.nativeElement.click();
        this.createAccountsForm.reset();
        this.getAccountByAccountId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateAcc(product) {
    this.spinner.show();
    this.show = true ;
    this.productAcc = product ;
      setTimeout(() => {
        const formValue = this.createAccountsForm;
        formValue.patchValue({
          accountName: product.accountName ,
          city: product.city ,
          email: product.email ,
          primaryPhone: product.primaryPhone ,
        })
      }, 2000);
      this.spinner.hide();
  }
  createAccounts(){
    this.spinner.show();
    const requestData = {
      "partnerId": "",
      "accountId": this.partyId,
      "accountName": this.createAccountsForm.value.accountName,
      "city": this.createAccountsForm.value.city,
      "primaryEmail": this.createAccountsForm.value.email,
      "primaryPhone": this.createAccountsForm.value.primaryPhone,
    }
    this.myContactsService.createAccounts(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton13.nativeElement.click();
        this.createAccountsForm.reset();
        this.getAccountByAccountId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getAccountByAccountId() {
    this.spinner.show();
    this.accountsService.getAccountByAccountId(this.partyId).subscribe(res => {
      this.allAccountData = res.data[0].getAccount;
      this.spinner.hide();
    })
  }



  getAllContactRelatedAccount() {
    this.spinner.show();
    this.accountsService.getAllContactRelatedAccount().subscribe(res => {
      this.AllContactRelatedAccount = res.data[0].data;
      this.spinner.hide();
    })
  }



backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/crm/main-overview']);
}


  //////////////////////////////////////// FUNCTIONS ////////////////////////////////

  getContactDetails(): void {
    this.spinner.show();
    this._DetailedEmployeeSummary.getTelecomById(this.contactMechId, this.partyId)
      .then(data => {
        this.contact = {
          areaCode: data.areaCode,
          askForName: data.askForName,
          countryCode: data.countryCode,
          contactNumber: data.contactNumber,
          contactMechId: data.contactMechId,
          allowSolicitation: data.allowSolicitation,
          contactMechPurposeTypeId: data.contactMechPurposeTypeId,
          contactMechTypeId: data.contactMechTypeId,
          extension: data.extension,
          partyId: data.partyId,
          preContactMechTypeId: 'TELECOM_NUMBER'
        };
        this.contactForm.patchValue(this.contact);
        this.spinner.hide();
        this.contactForm.controls.contactMechPurposeTypeId.disable();
        this.contactForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
      });

  }

  

  getContactPurpose(): void {
    this.spinner.show();
    this._CRMAccountService.getContactPurposes(this.partyId, 'TELECOM_NUMBER')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.contactPurpose = data.data.purposeTypes.map(value => {
            return {
              label: value.description,
              value: value.contactMechPurposeTypeId
            };
          });
        }
      });

  }

  updateContact(): void {
    this.spinner.show();
    if (this.contactForm.valid) {
      this._DetailedEmployeeSummary.updateEmployeeTelecomNumber({
        ...this.contact, ...this.contactForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.closebutton7.nativeElement.click();
            this.accountDetail();
            this.contactForm.reset();

          }
        });
    }

  }

  submitContact(): void {
    this.spinner.show();
    if (this.contactForm.valid) {
      this._DetailedEmployeeSummary.createEmployeeTelecomNumber({
        ...this.contactForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton7.nativeElement.click();
            this.accountDetail();
            this.contactForm.reset();

          }
        });
    }

  }

  getAccountDetailsPostal(): void {
    this.spinner.show();
    this._CRMAccountService.getAccountById(this.partyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.accountInfo = data.data.contactInformation.filter(value => {
            if (value.contactMech && (value.contactMech.contactMechId === this.contactMechId)) {
              this.addressForm.patchValue({
                ...value.postalAddress, ...{
                  contactMechPurposeTypeId: value.partyContactMechPurposes[0].contactMechPurposeTypeId
                }
              })
              return value.postalAddress;
            }
          });
          this.addressForm.controls.contactMechPurposeTypeId.disable();
          this.addressForm.controls.contactMechPurposeTypeId.updateValueAndValidity();

        }
      });

  }
  getPreContactMechIds(): void {
    this.spinner.show();
    this._CRMAccountService.getContactPurposes(this.partyId, 'POSTAL_ADDRESS')
      .then(data => {
        if (data.success) {
          this.contactMechTypeId = data.data.purposeTypes.map(value => {
            return {
              label: value.description,
              value: value.contactMechPurposeTypeId
            };
          });
        }
      });
    this.spinner.hide();

  }
  getCountries(): void {
    this.spinner.show();
    this._GlobalResourceService.getCountryList()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.countryArray = data.data.countryList.map(value => {
            return {
              label: value.split(':')[0].trim(),
              value: value.split(':')[1].trim()
            };
          });
        }
      });

  }
  getStates(countryId: string): void {
    this.spinner.show();
    this._GlobalResourceService.getStateList(countryId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.stateArray = data.data.stateList.map(value => {
            return {
              label: value.split(':')[0].trim(),
              value: value.split(':')[1].trim()
            };
          });
        }
      });

  }



  submitPostal(): void {
    this.spinner.show();
    if (this.addressForm.valid) {
      this._CRMAccountService.createPostalAddress({
        ...this.addressForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton6.nativeElement.click();
            this.accountDetail();
            this.addressForm.reset();

          }
        });
    }

  }
  updatePostal(): void {
    this.spinner.show();
    if (this.addressForm.valid) {
      this._CRMAccountService.updatePostalAddress({
        ...this.accountInfo[0], ...this.addressForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.addressForm.controls.contactMechPurposeTypeId.enable();
            this.addressForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
            this.closebutton6.nativeElement.click();
            this.accountDetail();
            this.addressForm.reset();

          }
        });
    }

  }



  getBillingAccountById(): void {
    this.spinner.show();
    this._CRMAccountService.getBillingAccountById(this.paymentMethodId, this.partyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.billingAccount = data.data;
          this.availableBalance = data.data.availableBalance;
          this.billingAccountForm.patchValue({
            ...data.data
          });
          this.editMode = true;
        }

      });

  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });

  }
  getCurrencyList(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.currencyUomIds = data.data.currencyList.map(value => {
            return {
              label: value.uomId,
              value: value.uomId
            };
          });
        }
      });

  }
  submitBilling(): void {
    this.spinner.show();
    if (this.billingAccountForm.valid) {
      this._CRMAccountService.createBillingAccount(this.partyId, {
        ...this.billingAccountForm.value, ...{
          fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
          thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton12.nativeElement.click();
            this.getPaymentMethods();
            this.billingAccountForm.reset();

          }
        });
    }

  }
  updateBilling(): void {
    this.spinner.show();
    if (this.billingAccountForm.valid) {
      this._CRMAccountService.updateBillingAccount(this.paymentMethodId, this.partyId, {
        ...this.billingAccount, ...this.billingAccountForm.value, ...{
          fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
          thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.closebutton12.nativeElement.click();
            this.getPaymentMethods();
            this.billingAccountForm.reset();

          }
        });
    }

  }


  getPaymentMethodById(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'CHECK_ACCOUNT')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.paymentMethodValue = data.data.checkAccountData;
          if (data.data.checkAccountData.postalAddressList[0]) {
            this.contactMechId = data.data.checkAccountData.postalAddressList[0].contactMech.contactMechId;
          } else {
            this.contactMechId = data.data.checkAccountData.currentPostalAddress.contactMechId;
          }

          this.eftAccountForm.patchValue({
            ...data.data.checkAccountData, ...{
              paymentMethodTypeId: this.paymentMethodTypeId, ...{
                description: data.data.paymentMethodData.description
              }
            }
          });

          this.editMode = true;
        }
      });

  }
  getPaymentMethodTypes(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodTypes()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.paymentMethodTypes = data.data.map(value => {
            return {
              label: value.description,
              value: value.paymentMethodTypeId
            };
          });
        }
      });

  }
  getAccountDetails(): void {
    this.spinner.show();
    this._CRMAccountService.getAccountById(this.partyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.accountData = data.data;
        }
      });

  }
  getAddress(event: any, contactMechId: string): void {
    this.contactMechId = (event.target.checked) ? contactMechId : '';
  }

  submitCheck(): void {
    this.spinner.show();
    if (this.eftAccountForm.valid) {
      this._CRMAccountService.createCheckAccount(this.partyId, {
        ...this.eftAccountForm.value, ...{
          contactMechId: this.contactMechId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton11.nativeElement.click();
            this.getPaymentMethods();
            this.eftAccountForm.reset();

          }
        });
    }

  }
  updateCheck(): void {
    this.spinner.show();
    if (this.eftAccountForm.valid) {
      this._CRMAccountService.updateCheckAccount(this.partyId, this.paymentMethodId, {
        ...this.paymentMethodValue, ...this.eftAccountForm.value, ...{
          contactMechId: this.contactMechId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.editMode = false;
            this.closebutton11.nativeElement.click();
            this.getPaymentMethods();
            this.eftAccountForm.reset();

          }
        });
    }

  }

  getPaymentMethodEFT(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'EFT_ACCOUNT')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.eftAccount = data.data.eftAccountData;
          this.contactMechId = data.data.eftAccountData.postalAddressList[0].contactMech.contactMechId;
          this.eftAccountForm.patchValue({
            ...data.data.eftAccountData, ...{
              description: data.data.paymentMethodData.description
            }
          });
          this.editMode = true;

        }
      });




  }

  submitEFT(): void {
    this.spinner.show();
    if (this.isEftAccount) {
      if (this.eftAccountForm.valid) {
        this._CRMAccountService.createEftAccount(this.partyId, this.eftAccountForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.spinner.hide();
              this.closebutton10.nativeElement.click();
              this.eftAccountForm.reset();

              this.getPaymentMethods();


            }
          });
      }
    } else {
      if (this.eftAccountForm.valid) {
        this._CRMAccountService.createCheckAccount(this.partyId, this.eftAccountForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.spinner.hide();
              this.closebutton10.nativeElement.click();
              this.eftAccountForm.reset();
              this.getPaymentMethods();

            }
          });
      }
    }

  }
  updateEFT(): void {
    this.spinner.show();
    if (this.eftAccountForm.valid) {
      this._CRMAccountService.updateEftAccount(this.partyId, this.paymentMethodId, {
        ...this.eftAccount, ...this.eftAccountForm.value, contactMechId: this.contactMechId
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.editMode = false;
            this.eftAccountForm.reset();
            this.closebutton10.nativeElement.click();

            this.getPaymentMethods();
          }
        })
    }

  }
  getPaymentMethod(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'GIFT_CARD')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.giftCard = data.data.giftCardData;
          this.giftForm.patchValue({
            ...data.data.giftCardData, ...{
              description: data.data.paymentMethodData.description
            }
          });
          this.editMode = true;

        }
      });

  }
  submitGift(): void {
    this.spinner.show();
    if (this.giftForm.valid) {
      this._CRMAccountService.createGiftCard(this.partyId, this.giftForm.value)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton9.nativeElement.click();
            this.giftForm.reset();
            this.getPaymentMethods();

          }
        });
    }

  }
  updateGift(): void {
    this.spinner.show();
    if (this.giftForm.valid) {

      this._CRMAccountService.updateGiftCard(this.partyId, this.paymentMethodId, {
        ...this.giftCard, ...this.giftForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.closebutton9.nativeElement.click();
            this.giftForm.reset();
            this.getPaymentMethods();


          }
        });
    }

  }





  getCardTypes(): void {
    this.spinner.show();
    this._CRMAccountService.getCreditCardTypes()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.cardTypes = data.data.map(value => {
            return {
              label: value.enumCode,
              value: value.enumId
            };
          });
        }
      });

  }


  submitCredit(): void {
    this.spinner.show();
    if (this.creditCardForm.valid) {
      this._CRMAccountService.createCreditCard(this.partyId, {
        ...this.creditCardForm.value, ...{
          contactMechId: this.contactMechId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton8.nativeElement.click();
            this.creditCardForm.reset();
            this.getPaymentMethods();

          }
        });
    }

  }
  updateCredit(): void {
    this.spinner.show();
    if (this.creditCardForm.valid) {
      this._CRMAccountService.updateCreditCard(this.partyId, this.paymentMethodId, {
        ...this.creditCardValue.creditCardData, ...this.creditCardForm.value, ...{
          contactMechId: this.contactMechId,
          suffixOnCard: 'I'
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.closebutton8.nativeElement.click();
            this.creditCardForm.reset();
            this.getPaymentMethods();

          }
        });
    }

  }


  getContactList() {
    this.spinner.show();
    this.myContactsService.getContactsLists().subscribe(res => {
      this.contactId = res.data;
      this.spinner.hide();
      for (const value of this.contactId) {
        this.contactArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  onSubmitcontact() {
    this.spinner.show();
    this.fileUploadFormContact.get('accountPartyId').patchValue(this.partyId);
    this.fileUploadFormContact.get('partyId').patchValue(this.partyId);
    const formData = new FormData();
    const formdata = { ...this.fileUploadFormContact.value };
    this.myContactsService.RelatedAccount(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.closebutton5.nativeElement.click();
    this.spinner.hide();
    this.fileUploadFormContact.reset();
    this.getAllContactRelatedAccount();
  }



  updateForm(): void {
    this.spinner.show();
    this._CRMAccountService.updateJobInformation({
      ...this.jobInfo, ...this.jobInfoForm.value, ...{
        partyId: this.partyId,
        startDate: this.jobInfoForm.value.startDate ? moment(this.jobInfoForm.value.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
        endDate: this.jobInfoForm.value.endDate ? moment(this.jobInfoForm.value.endDate).format('YYYY-MM-DD HH:mm:ss') : '',
        projectedEndDate: this.jobInfoForm.value.projectedEndDate ? moment(this.jobInfoForm.value.projectedEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Updated');
          this.spinner.hide();
          this.closebutton4.nativeElement.click();
          this.jobInfoForm.reset();

        }
      });

  }
  submitJob(): void {
    this.spinner.show();
    this._CRMAccountService.createJobInformation({
      ...this.jobInfoForm.value, ...{
        partyId: this.partyId,
        startDate: this.jobInfoForm.value.startDate ? moment(this.jobInfoForm.value.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
        endDate: this.jobInfoForm.value.endDate ? moment(this.jobInfoForm.value.endDate).format('YYYY-MM-DD HH:mm:ss') : '',
        projectedEndDate: this.jobInfoForm.value.projectedEndDate ? moment(this.jobInfoForm.value.projectedEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created');
          this.spinner.hide();
          this.closebutton4.nativeElement.click();
          this.jobInfoForm.reset();

        }
      });

  }


  navigate() {
    this.router.navigate(["/crm/accounts/account-details"], { queryParams: { partyId: this.partyId } });
  }

  navigateFromCreate() {
    this.router.navigate(["/crm/accounts/account-details"], { queryParams: { partyId: this.partyIdForCreate } });
  }
  onSubmitbtn() {
    this.spinner.show();
    const form = { ...this.fileUploadForm.value }
    this.myContactsService.createContactNote(form, this.partyIdForCreate).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.fileUploadForm.reset();
        this.getAccountNote();
        // this.router.navigate(['/crm/accounts/account-details'],{queryParams:{partyId:this.partyIdForCreate}})

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )

  }

  onUpdate() {
    this.spinner.show();
    const form = { ...this.fileUploadForm.value }
    this.myContactsService.updateContactNote(form, this.noteId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.fileUploadForm.reset();
        this.getAccountNote();

        //this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }


  update(): void {
    this.spinner.show();
    this._CRMAccountService.updateAdditionalDetail({
      ...this.additionalInfo, ...this.additionalDetailForm.value, ...{
        partyId: this.partyId
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Updated');
          this.closebutton2.nativeElement.click();
          this.additionalDetailForm.reset();
          this.accountDetail();
          this.spinner.hide();

        }
      });

  }
  submitInfo(): void {
    this.spinner.show();
    this._CRMAccountService.createAdditionalDetail({
      ...this.additionalDetailForm.value, ...{
        partyId: this.partyId
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created');
          this.additionalDetailForm.reset();
          this.closebutton2.nativeElement.click();
          this.spinner.hide();
          this.accountDetail();
        }
      });


  }

  cancelSubmit() {
    this.router.navigate(['/crm/accounts'])
  }
  uploadImage() {
    this.router.navigate(['/crm/upload-customer-image'], { queryParams: { partyId: this.partyId } });
  }

  getCustomerPersonalImage() {
    this.spinner.show();
    this.accountsService.getCustomerPersonalImage(this.partyId).subscribe(res => {

      if (res.data.imageUrl == 'null') {
        this.imagePic = false;
        this.imageData = "";
      }
      else {
        this.imagePic = true;
        this.imageData = res.data;
        this.spinner.hide();
      }
    });

  }
  getAccountNote() {
    this.spinner.show();
    this.accountsService.getContactNotes(this.partyId).subscribe(res => {
      this.accountDataNote = res.data;
      this.spinner.hide();
    });


  }

  getPartyContent() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        });
      }
    });

  }

  onPartyGroup() {

    this.router.navigate(['/crm/accounts/create-accounts'], { queryParams: { partyId: this.partyId } });
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];

  }
  onpartyContent(event) {
    this.partyContentTypeId = event;
  }
  export(id: any, contentName: any) {
    this.spinner.show();
    this.accountsService.downloadView(id).subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = contentName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }
  onDelete(contentId, fromDate, partyContent, partyId): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._detailedContactsService.deleteContent(contentId, moment(fromDate).format('yyyy-MM-DD HH:mm:ss.SSS'), partyContent, partyId).subscribe(res => {
          if (res['data'].responseMessage == 'success') {
            //this.toastr.success(res['data'].successMessage);
          }
          this.getPartyContentsList();
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  onSubmit() {
    this.spinner.show();
    let formData = new FormData();
    formData.append('file', this.fileData);
    this.partyContentTypeId = this.Editform.get('partyContentTypeId').value;

    this.accountsService.attachPartyContent(formData, this.partyContentTypeId, this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.document.location.reload();

      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message);

    });

  }
  onCreate() {
    this.spinner.show();
    this.accountLeadSourceService.lead_source_form.patchValue({ partyId: this.partyId });
    this.accountLeadSourceService.lead_source_form.patchValue({ roleTypeId: this.partyId });
    const formData = { ...this.accountLeadSourceService.lead_source_form.value };
    this.accountsService.createLeadSource(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.getLeadSourceList();
        // this.document.location.reload();

      }

    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getPartyContentsList() {
    this.spinner.show();
    this.accountsService.getPartyContentList(this.partyId).subscribe(res => {
      this.contentList = res.data;
      this.spinner.hide();
    })

  }
  getLeadSourceList() {
    this.spinner.show();
    this.accountsService.getLeadSourceList(this.partyId).subscribe(res => {
      this.leadSourceList = res.data;
      this.spinner.hide();
    })

  }

  accountDetail() {
    this.spinner.show();
    this.accountsService.getAccountById(this.partyId).subscribe(res => {


      this.accountDetails = res.data;
      this.spinner.hide();
      this.isAdditionalDetail = res.data.additionalInformation.infoType ? true : false;
      this.isJobDetail = res.data.jobInformation.jobStatus ? true : false;
      res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;

      this.relatedContact = res.data.relatedContacts;
      this.accountName = res.data.groupName;

      this.addr = res.data.address.address1 + ' ' + res.data.address.city + ' ' + res.data.address.postalCode + ' ' + res.data.address.countryGeoId;
      this.emailId = res.data.email.infoString;
      this.phoneNumber = res.data.telenumber.countryCode + ' ' + res.data.telenumber.areaCode + ' ' + res.data.telenumber.contactNumber

    })

  }

  getLeadSourcedata() {
    this.spinner.show();
    this.accountsService.getLeadSourceData().subscribe(res => {
      this.leadSourceData = res.data.leadSourceList;
      this.spinner.hide();
      for (const value of this.leadSourceData) {
        this.leadSourcearray.push({
          label: value.description,
          value: value.dataSourceId
        })
      }
    })

  }
  updateData(partyId: string, type: string) {
    switch (type) {
      case 'POSTAL_ADDRESS':
        this.router.navigate(['/crm/accounts/create-postal-address'], { queryParams: { partyId: this.partyId, contactMechId: partyId } })
        break;

      case 'TELECOM_NUMBER':
        this.router.navigate(['/crm/accounts/create-contact'], { queryParams: { partyId: this.partyId, contactMechId: partyId } })
    }


  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  account() {
    this.router.navigate(['/crm/accounts/accountNotes'], { queryParams: { partyId: this.accountDetails.partyId } })
  }
  change() {
    this.router.navigate(['/crm/accounts/listRelatedAccount'], { queryParams: { partyId: this.accountDetails.partyId } })
  }


  onUpdateNotes(enoteId, enoteName, enote) {
    this.router.navigate(['/crm/accounts/accountNotes'], { queryParams: { noteId: enoteId, leadId: this.partyId, noteName: enoteName, note: enote } })
  }

  onDeleteNotes(noteId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteNote(noteId).subscribe(res => {
          if (res.success) {
            window.location.reload();
          }
        }, (err) => {
          this.toastr.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  getBillingAccounts(): void {
    this.spinner.show();
    this._CRMAccountService.getBillingAccounts(this.partyId)
      .then(data => {
        if (data.success) {
          this.billingAccounts = data.data.map(value => {
            return {
              ...value, ...{ type: 'BILLING', paymentMethodTypeId: 'BILLING' }
            };
          });
          this.paymentMethods = [...this.paymentMethods, ...this.billingAccounts];
        }
        this.spinner.hide();
      });

  }
  paymentMethodNavigation(type: string, paymentMethodId: string): void {
    switch (type) {
      case 'GIFT_CARD':
        this.router.navigate(['/crm/accounts/create-gift-card'], { queryParams: { partyId: this.partyId, paymentMethodId } });
        break;
      case 'EFT_ACCOUNT':
        this.router.navigate(['/crm/accounts/create-eft-account'], { queryParams: { partyId: this.partyId, paymentMethodId, isEftAccount: true } });
        break;
      case 'CERTIFIED_CHECK':
        this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.partyId, paymentMethodId, paymentMethodTypeId: 'CERTIFIED_CHECK' } });
        break;
      case 'CREDIT_CARD':
        this.router.navigate(['/crm/accounts/create-credit-card'], { queryParams: { partyId: this.partyId, paymentMethodId } });
        break;
      case 'COMPANY_CHECK':
        this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.partyId, paymentMethodId, paymentMethodTypeId: 'COMPANY_CHECK' } });
        break;
      case 'BILLING':
        this.router.navigate([`/crm/accounts/create-billing-account`], { queryParams: { partyId: this.partyId, billingAccountId: paymentMethodId } });
        break;
      case 'PERSONAL_CHECK':
        this.router.navigate([`/crm/accounts/create-check-account`], { queryParams: { partyId: this.partyId, paymentMethodId, paymentMethodTypeId: 'PERSONAL_CHECK' } });
        break;
    }
  }
  getPaymentMethods(): void {
    this.spinner.show();
    this._CRMAccountService.getAllPayment(this.partyId, false)
      .then(data => {
        if (data.success) {
          this.paymentMethods = data.data.map(value => {
            switch (value.paymentMethod.paymentMethodTypeId) {
              case 'GIFT_CARD':
                return {
                  ...value.giftCard, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Gift Card'
                  }
                };
                break;
              case 'EFT_ACCOUNT':
                return {
                  ...value.eftAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'EFT Account'
                  }
                };
                break;
              case 'CERTIFIED_CHECK':
                return {
                  ...value.certifiedCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Certified Check'
                  }
                };
                break;
              case 'CREDIT_CARD':
                return {
                  ...value.creditCard, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Credit Card'
                  }
                };
                break;
              case 'COMPANY_CHECK':
                return {
                  ...value.companyCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Company Check'
                  }
                }
                break;
              case 'PERSONAL_CHECK':
                return {
                  ...value.personalCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Personal Check'
                  }
                };
            }
          });
          this.getBillingAccounts();
          this.spinner.hide();
        }
      });

  }
  removePaymentMethod(type: string, paymentMethodId: string): void {
    if (type === 'BILLING') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this._CRMAccountService.removeBillingAccount(this.partyId, paymentMethodId)
            .then(data => {
              if (data.success) {
                this.getPaymentMethods();
              }
            });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })


    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this._CRMAccountService.removeGiftCard(paymentMethodId)
            .then(data => {
              if (data.success) {
                this.getPaymentMethods();
              }
            });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })


    }
  }
  getCrmStatus() {
    this.spinner.show();
    this._EditAccountServices.getCrmStatus().subscribe(res => {
      this.spinner.hide();
      this.statusList = res.data.map(value => {
        return {
          label: value.description,
          value: value.statusId
        };
      });
    })

  }

  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.currencyList = data.data.currencyList.map(value => {
            return {
              label: value.description,
              value: value.uomId
            };
          });
        }
      });

  }

  getData() {
    this.spinner.show();
    this.createAccountService.getAccountById(this.partyId).subscribe(res => {
      this.spinner.hide();
      const form = this.createAccountFormService.create_account_form;
      this.accountForm.patchValue({
        groupName: res.data.groupName !== null ? res.data.groupName : '',
        numEmployees: res.data.numEmployees !== null ? res.data.numEmployees : '',
        officeSiteName: res.data.officeSiteName !== null ? res.data.officeSiteName : '',
        emailAddress: res.data.email.infoString !== null ? res.data.email.infoString : '',
        address1: res.data.address.address1 !== null ? res.data.address.address1 : '',
        address2: res.data.address.address2 !== null ? res.data.address.address2 : '',
        city: res.data.address.city !== null ? res.data.address.city : '',
        postalCode: res.data.address.postalCode !== null ? res.data.address.postalCode : '',
        contactNumber: res.data.telenumber.contactNumber !== null ? res.data.telenumber.contactNumber : '',
        countryCode: res.data.telenumber.countryCode !== null ? res.data.telenumber.countryCode : '',
        areaCode: res.data.telenumber.areaCode !== null ? res.data.telenumber.areaCode : '',
        stateProvinceGeoId: res.data.address.stateId !== null ? res.data.address.stateId : '',
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        title: res.data.title,
        annualRevenue: res.data.annualRevenue,
        description: res.data.description,
        externalId: res.data.externalId,
        logoImageUrl: res.data.logoImageUrl,
        preferredCurrencyUomId: res.data.preferredCurrencyUomId,
        statusId: res.data.statusId,
        countryGeoId: res.data.address.countryId
      });

    })

  }


  createAccount() {
    this.spinner.show();
    const formValues = { ...this.createAccountFormService.create_account_form.value };


    const {
      address1,
      address2,
      areaCode,
      city,
      contactNumber,
      countryCode,
      countryGeoId,
      emailAddress,
      extension,
      groupName,
      numEmployees,
      officeSiteName,
      postalCode,
      stateProvinceGeoId,
      firstName,
      lastName,
      title } = this.accountForm.value;
    this._CRMAccountService.createAccount({
      address1,
      address2,
      areaCode,
      city,
      contactNumber,
      countryCode,
      countryGeoId,
      emailAddress,
      extension,
      groupName,
      numEmployees,
      officeSiteName,
      postalCode,
      stateProvinceGeoId,
      firstName,
      lastName,
      title
    })
      .then(data => {
        if (data.success) {
          this.id = data.data.partyId
          this.toastr.success('Customer created successfully');
          this.spinner.hide();
          this.activeCategory = 3;
          //  this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.id } });
          // this.router.navigate(['/crm/accounts'])
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    this.spinner.hide();


  }

  updateAccount() {

    this.spinner.show();

    this._CRMAccountService.updateAccount(this.partyId, this.accountForm.value)
      .then(data => {
        if (data.success) {
          this.toastr.success("Customer Updated Successfully");
          this.spinner.hide();
          this.activeCategorymain = 3;
          // this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId:  this.partyId } });
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    this.spinner.hide();



  }
  back() {
    this.router.navigate(['/crm/accounts']);
  }

  getCountryList() {
    this.spinner.show();
    this.createAccountService.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0],
          value: element.split(":").pop().trim()
        })

      })

    })

  }

  onCountryChange(event) {
    this.stateArray = [];
    this.countryId = event;
    this.getStateList();

  }

  onStateChange(event) {
    this.stateProvinceGeoId = event;
  }

  getStateList() {
    this.spinner.show();
    this.createAccountService.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })

    })


  }

  updateAdd(): void {
    this.spinner.show();
    this._CRMAccountService.updateAdditionalDetail({
      ...this.additionalInfo, ...this.additionalDetailForm.value, ...{
        partyId: this.partyId
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Updated');

          this.spinner.hide();
          this.activeCategorymain = 4;
        }
      });

  }
  updateJob(): void {
    this.spinner.show();
    this._CRMAccountService.updateJobInformation({
      ...this.jobInfo, ...this.jobInfoForm.value, ...{
        partyId: this.partyId,
        startDate: this.jobInfoForm.value.startDate ? moment(this.jobInfoForm.value.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
        endDate: this.jobInfoForm.value.endDate ? moment(this.jobInfoForm.value.endDate).format('YYYY-MM-DD HH:mm:ss') : '',
        projectedEndDate: this.jobInfoForm.value.projectedEndDate ? moment(this.jobInfoForm.value.projectedEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Updated');
          this.closebutton.nativeElement.click();
          this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.partyId } });
          this.spinner.hide();

        }
      });

  }
  submit(): void {
    this.spinner.show();
    this._CRMAccountService.createJobInformation({
      ...this.jobInfoForm.value, ...{
        partyId: this.id,
        startDate: this.jobInfoForm.value.startDate ? moment(this.jobInfoForm.value.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
        endDate: this.jobInfoForm.value.endDate ? moment(this.jobInfoForm.value.endDate).format('YYYY-MM-DD HH:mm:ss') : '',
        projectedEndDate: this.jobInfoForm.value.projectedEndDate ? moment(this.jobInfoForm.value.projectedEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Created');
          this.spinner.hide();
          this.closebutton.nativeElement.click();

          // this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.id } });

        }
      });

  }
  submitAdd(): void {
    this.spinner.show();
    this._CRMAccountService.createAdditionalDetail({
      ...this.additionalDetailForm.value, ...{
        partyId: this.id
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Created');
          this.spinner.hide();
          this.activeCategory = 4;
        }
      });


  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
