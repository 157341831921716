
<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="w3-card-4 classCard" >
        <div class="col-12">
           
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    Edit Personal Information
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                          
                            <form *ngIf="empPersonalFormService.emp_phone_form" [formGroup]="empPersonalFormService.emp_phone_form" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 form-group classInput">
                                                <label for="electronicAddress" >Party Id</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="text" class="form-control"
                                            [(ngModel)]="this.partyIds" [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 form-group classInput">
                                                <label for="electronicAddress">Area Code</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="text" class="form-control"
                                                formControlName="areaCode">
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 form-group classInput">
                                                <label for="electronicAddress">Contact
                                                    Number</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="text" class="form-control"
                                                formControlName="contactNumber">
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 form-group classInput">
                                                <label for="electronicAddress">Extension</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="text" class="form-control"
                                                formControlName="extension">
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 form-group classInput">
                                                <label for="exampleInputEmail1">Solicitation</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown [options]="solicitation" formControlName="allowSolicitation" filter="true" optionlabel="label">
                                                   
                                                </p-dropdown>
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 form-group classInput">
                                                <label for="electronicAddress">Country Code</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="text" class="form-control"
                                                formControlName="countryCode">
                                            </div>
                                        
                                        </div>
                                    </div>
                                   
                                    
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                         
                                            <input type="hidden" class="form-control"
                                                formControlName="askForName">
                                        </div>
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                    
                </div>
          
            </div>
            <div class="main-submit-button">
                <button type="submit" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button> 
                <button type="button" (click)="cancelSubmit()"  routerLinkActive="active"  class="btn btn-degault cncl-btn">Cancel</button>
            </div>
        </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner> 
