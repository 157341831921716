<div class="container-fluid main-container-wrapper">
    <div class="row">

        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Materials Required By The Running Task</span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"  style="cursor: pointer;">Materials Required By The Running Task


 
                </span>
                <span class="color-black pl-1" > >Update Materials Required By The Running Task

                </span>

            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''"  ><a>
                        Update Materials Required By The Running Task

                    </a></li>
                 
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="updateMaterial">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"  >
                                                <label for="exampleInputEmail1">
                                                    Routing Task Id</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter  Routing Task Id"
                                                formControlName="TaskId" >

                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Product Name</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter   Product Name"
                                            formControlName="productName" >
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"  >
                                                <label for="exampleInputEmail1">
                                                    Quantity</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                            formControlName="Quantity" >

                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Fall If Item Are Not Available</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Fall If Item Are Not Available" filter="true"
                                                formControlName="available"
                                                >
                                                                
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"  >
                                                <label for="exampleInputEmail1">
                                                    Location Sequence Id</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown  placeholder="Enter Location Sequence Id" filter="true"
                                            formControlName="LocationSequenceId"
                                           >
                                                            
                                            </p-dropdown>

                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Secondary Location Sequence Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Secondary Location Sequence Id" filter="true"
                                                formControlName="SecondaryLocationSequenceId"
                                               >
                                                                
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"  >
                                                <label for="exampleInputEmail1">
                                                    Lot Id</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Lot Id"
                                            formControlName="LotId" >

                                            </div>
                                            <div class="col-lg-2"></div>
                                           
                                        </div>
                                    </div>
                                 
                                   
                                   
                                 
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 66%;">
                               
                                <button type="submit"     class="btn btn-secondary submit-btn">Save</button>
                             
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
