import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
    import { Location } from '@angular/common';
    import { NgxSpinnerService } from "ngx-spinner";
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-bom-simulation-list',
  templateUrl: './bom-simulation-list.component.html',
  styleUrls: ['./bom-simulation-list.component.css']
})
export class BomSimulationListComponent implements OnInit {
  advanceSearch: {

    ProductID: string,
    ProductIDTo: string,
    BomType: string,



  };
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search = ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  financialList = [{
    "product": "1"
  }];
  constructor(
    readonly spinner: NgxSpinnerService, readonly accountsService: AccountsService, 
    private _location: Location,
    readonly productionService:ProductionService,
    readonly router: Router
  ) { }

  ngOnInit(): void {
  }
  createSimulation() {
    this.router.navigate(["production/create-bom-simulation"]);
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  createSimulationList() {
    this.router.navigate(["production/bom-simulation-list"]);
  }
  createManufacture() {
    this.router.navigate(["production/manufacturing-rules"])
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
