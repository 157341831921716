<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100">
                   
                    <div class="mt-2" style="margin-bottom: 10px !important; ">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 16px!important;">Landmarks</span>

                    </div>  
                    
                    <div class="col-lg-12">
                        <div class="row " style="margin-top: -7px !important; margin-bottom: -1% !important;margin-left: 0% !important; ">
                            <a class="" style="">Add a Landmarks </a>
                        </div>

                    </div>

                    
                    

                    <div class="row " style="width: 100%; margin-top: 2%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color: black;">
                                        Landmarks </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                        
                    </div>
                    

            
                    

                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


        

<!-- <ngx-spinner></ngx-spinner> -->







