import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-my-work-setting',
  templateUrl: './my-work-setting.component.html',
  styleUrls: ['./my-work-setting.component.css']
})
export class MyWorkSettingComponent implements OnInit {

  @ViewChild('closebutton') closebutton;

  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  
  myWorkCategories:any;
  addNewMyWorkCategoriesForm:FormGroup;
  rank:string;
  categoryName:string;
  FsmMyWorkSettingsMSAll: any;
  show: boolean;
  updateFsmMyWorkSettingsMSPat: any;

  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 

  {
    this.addNewMyWorkCategoriesForm = this._FormBuilder.group({
    categoryName:[''],
    });
    this.addNewMyWorkCategoriesForm = this._FormBuilder.group({
      categoryName:[''],
      });
    
  }
ngOnInit(): void {
  this.show = true;
  this.getFsmMyWorkSettingsMS();
  }
  deleteFsmMyWorkSettingsMS(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteFsmMyWorkSettingsMS(product.myWorkSettingsMSId).subscribe((res: any) => {
          if (res.success) {
            this.getFsmMyWorkSettingsMS()
            this.toastr.info("Delete Successfully");
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  updateFsmMyWorkSettingsMS(): void {
    this.spinner.show();
    const requestData = {
      "categoryName": this.addNewMyWorkCategoriesForm.value.categoryName,
      "myWorkSettingsMSId": this.updateFsmMyWorkSettingsMSPat.myWorkSettingsMSId
    }
    this.accountsService.updateFsmMyWorkSettingsMS(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getFsmMyWorkSettingsMS();
        this.addNewMyWorkCategoriesForm.reset();
        this.closebutton.nativeElement.click();
        this.show = true;
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateFsmMyWorkSettingsMSPatch(product) {
    this.show = false;
    this.updateFsmMyWorkSettingsMSPat = product ;
    this.spinner.show();

    setTimeout(() => {
      const formValue = this.addNewMyWorkCategoriesForm;
      formValue.patchValue({
          categoryName: this.updateFsmMyWorkSettingsMSPat.categoryName,
      })

    }, 2000);
    this.spinner.hide();
  }
  createFsmMyWorkSettingsMS(): void {
    this.spinner.show();
    const requestData = {
        "categoryName": this.addNewMyWorkCategoriesForm.value.categoryName,
        "myWorkSettingsMSId": ''
    }
    this.accountsService.createFsmMyWorkSettingsMS(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getFsmMyWorkSettingsMS();
        this.addNewMyWorkCategoriesForm.reset();
        this.closebutton.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFsmMyWorkSettingsMS() {
    this.spinner.show();
    this.accountsService.getFsmMyWorkSettingsMS().subscribe(res => {
      this.FsmMyWorkSettingsMSAll = res.data[0].getFsmMyWorkSettingsMS;
      this.spinner.hide();
    })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  

  ngOnDestroy(): void {
    this.spinner.hide();
  }



}
