import { Component, OnInit, OnDestroy } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderManagementService } from '../order-management.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-sales-order-entry-terms',
  templateUrl: './sales-order-entry-terms.component.html',
  styleUrls: ['./sales-order-entry-terms.component.css']
})
export class SalesOrderEntryTermsComponent implements OnInit {
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 5,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],
    }
  };
  activeCategory = 1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  pageNo = 1;
  faEdit = faEdit;
  faTrash = faTrash;
  orderTermType: any[];
  orderTermForm: FormGroup;
  editMode: boolean;
  isSearchPage: boolean;
  orderTerm: any;
  orderTerms: any[];
  constructor(
    readonly _FormBuilder: FormBuilder,
    readonly  _Router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _OrderManagementService: OrderManagementService,
    readonly _ToastrService: ToastrService
  ) {
    this.orderTermType = [];
    this.orderTermForm = this._FormBuilder.group({
      description: [''],
      termDays: [''],
      termIndex: [''],
      termTypeId: [''],
      termValue: [''],
      textValue: ['']
    });
    this.editMode = false;
    this.isSearchPage = false;
    this.orderTerms = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getOrderTermType();
    this.getOrderTerms();
  }
  getOrderTermType(): void {
    this.spinner.show();
    this._OrderManagementService.getOrderTermType()
      .then(data => {
        this.spinner.hide();
        this.orderTermType = data.data.map(value => {
          return {
            label: value.description,
            value: value.termTypeId
          };
        });
      })
     
  }
  getOrderTerms(): void {
    this.spinner.show();
    this._OrderManagementService.getOrderTerms()
      .then(data => {
        if (data.data) {
          this.spinner.hide();
          this.orderTerms = data.data.orderTerms;
          this.isSearchPage = true;
        }
      });
     
  }
  deleteOrderTerm(termIndex: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._OrderManagementService.removeCart(termIndex)
      .then(data => {
        if (data.success) {
         
          this.getOrderTerms();
        }
      });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
   
    
  }
  makeOrderTermEditable(termIndex: string): void {
    this.spinner.show();
    this._OrderManagementService.getOrderTermByIndex(termIndex)
      .then(data => {
        this.orderTerm = data.data;
        this.editMode = true;
      });
      this.spinner.hide();
  }
  update(): void {
    this.spinner.show();
    this._OrderManagementService.createOrderTerm({
      ...this.orderTerm, ...this.orderTermForm.value
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Order item updated successfully');
          this.orderTermForm.reset();
          this.spinner.hide();
          this.isSearchPage = true;
          this.getOrderTerms();
          this.editMode = false;
        }
      });
      this.spinner.hide();
  }

  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      this._OrderManagementService.createOrderTerm(this.orderTermForm.value)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Order item created successfully');
            this.orderTermForm.reset();
            this.spinner.hide();
            this.getOrderTerms();
            this.isSearchPage = true;
          }
        });
    }
    this.spinner.hide();
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  navigateToPayment(): void {
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      finalizeMode: 'term'
    })
      .then(data => {
        if (data.success) {
          switch (data.data.responseMessage) {
            case 'shipping': this._Router.navigate(['/order/sales-finalize-order']);
              break;
            case 'options': this._Router.navigate(['/order/sales-order-options']);
              break;
            case 'term': this._Router.navigate(['/order/sales-order-entry-terms']);
              break;
            case 'payment': this._Router.navigate(['/order/sales-payment-options']);
              break;
            case 'addparty': this._Router.navigate(['/order/sales-order-additional-party-entry']);
              break;
            case 'sales': this._Router.navigate(['/order/sales-order-confirmation']);
              break;
          }
      
        }
      });
  }
}
