import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentGroupService {
    constructor(
        readonly _ApiService: ApiService,
        readonly _HttpClient: HttpClient
    ) { }



    getPaymentGroup(groupType: string, formData: {
        paymentGroupId: string,
        paymentGroupIdSearchType: string,
        paymentGroupName: string,
        paymentGroupNameSearchType: string
    }): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/ar/paymentGroup/search?paymentGroupTypeId=${groupType}`, formData);
    }
    getPaymentGroupTypes(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/paymentGroup/types`);
    }
    createPaymentGroup(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/ar/paymentGroup`, formData);
    }
    updatePaymentGroup(paymentGroupId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/ar/paymentGroup/${paymentGroupId}`, formData);
    }
    getPayablePaymentById(paymentGroupId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accountingPayable/apPaymentGroup/${paymentGroupId}`);
    }
    getGroupMembers(paymentGroupId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/paymentGroup/paymentGroupMember/${paymentGroupId}`);
    }
    createGroupMember(paymentGroupId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/ar/paymentGroup/paymentGroupMember/${paymentGroupId}`, formData);
    }
    updateGroupMember(paymentGroupId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/ar/paymentGroup/paymentGroupMember/${paymentGroupId}`, formData);
    }
    cancelCheckRun(paymentGroupId: string): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accountingPayable/apPaymentGroup/cancelCheckRun?paymentGroupId=${paymentGroupId}`, {});
    }
    checksToPrint(paymentGroupId: string): Observable<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/accountingPayable/apPaymentGroup/checksToPrint?paymentGroupId=${paymentGroupId}`, { responseType: 'blob' });
    }
    depositSlip(paymentGroupId: string): Observable<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/ar/paymentGroup/depositSlipPdf?paymentGroupId=${paymentGroupId}`, { responseType: 'blob' });
    }
    cancelGroupMember(paymentGroupId: string, paymentId: string, fromDate: string): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/ar/paymentGroup/paymentGroupMember/${paymentGroupId}/${paymentId}/${fromDate}`, {});
    }
    cancelBatchPayment(paymentGroupId: string): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/ar/paymentGroup/paymentGroupMember/cancelPaymentGroup/${paymentGroupId}`, {});
    }
    getArPaymentGroup(paymentGroupId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/paymentGroup/${paymentGroupId}`);
    }
}