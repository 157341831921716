import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormBuilder} from '@angular/forms';
import { CRMAccountService } from 'src/app/services/crm-account.service'
import { GlobalResourceService } from 'src/app/services/global-resource.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { esLocale } from 'ngx-bootstrap/chronos';
import { Location } from '@angular/common';

@Component({
  selector: 'app-find-party',
  templateUrl: './find-party.component.html',
  styleUrls: ['./find-party.component.css']
})
export class FindPartyComponent implements OnInit {

  findPartyForm:FormGroup;
  activeCategory=0;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  stateArray: any[]=[];
  state: any;
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  userLogin: any;
  findPartyArray: any[]=[];
  identification: any;
  identificationArray: any[]=[];
  userPermissions: any;
  constructor(readonly router:Router,private form:FormBuilder,
    readonly accountsService: AccountsService,
    private crmaAccountService : CRMAccountService,
    private globalResourceService:GlobalResourceService,
    readonly spinner: NgxSpinnerService,
    readonly _ToastrService: ToastrService,
    private _location: Location,
    ) {
      this.findPartyForm=this.form.group(
        {
          "address1": [''],
          "address2": [''],
          "areaCode": [''],
          "city": [''],
          "contactNumber": [''],
          "countryCode": [''],
          "extInfo": [''],
          "externalId": [''],
          "firstName": [''],
          "groupName": [''],
          "idValue": [''],
          "infoString": [''],
          "inventoryItemId": [''],
          "lastName": [''],
          "partyId": [''],
          "partyIdentificationTypeId": [''],
          "partyTypeId": [''],
          "postalCode": [''],
          "roleTypeId": [''],
          "serialNumber": [''],
          "softIdentifier": [''],
          "stateProvinceGeoId": ['']
        }
      );
     }
     ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    this.getStateList();
    this.getUserLoginIds();
    this.getRoleTypeId();
    this.identificationList()
  
    this.onSubmit()
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/partyQuickLink/party-dashboard']);
  }
  
  identificationList() {
    this.spinner.show();
    this.accountsService.identificationList().subscribe((res: any) => {

      this.identification = res.data;
      this.spinner.hide();
      for (const value of this.identification) {
        this.identificationArray.push({
          label: value.description,
          value: value.partyIdentificationTypeId
        })
      }

    })
   
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  goToCreateEmployee(tab){
    // this.router.navigate(["/partyQuickLink/create-employee"])
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.activeCategory = tab;
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this._ToastrService.error("Permission Denied"); 
    }
    else{
      this.activeCategory = tab;
    }
  }
  goToCreateNewPartyGroup(tab){
    // this.router.navigate(["/partyQuickLink/create-new-party-group"])
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.activeCategory = tab;
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this._ToastrService.error("Permission Denied"); 
    }
    else{
      this.activeCategory = tab;
    }
  }
  goToCreateNewPerson(tab){
    // this.router.navigate(["/partyQuickLink/create-new-person"])
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.activeCategory = tab;
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this._ToastrService.error("Permission Denied"); 
    }
    else{
      this.activeCategory = tab;
    }
  }
  goToCreateCustomer(tab){
    // this.router.navigate(["/partyQuickLink/create-customer"])
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.activeCategory = tab;
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this._ToastrService.error("Permission Denied"); 
    }
    else{
      this.activeCategory = tab;
    }
  }
  goToCreateProspect(tab){
    // this.router.navigate(["/partyQuickLink/create-prospect"])
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.activeCategory = tab;
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this._ToastrService.error("Permission Denied"); 
    }
    else{
      this.activeCategory = tab;
    }
  }
  getStateList(){
    this.globalResourceService.getStateList('USA')
    .then(data=>{
      this.state=data.data.stateList;
      this.state.forEach((stateLists: any) => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
  }
  getRoleTypeId(){
    this.globalResourceService.getRoleTypeId()
    .then(data=>{
      this.roleTypeId=data.data;
      this.roleTypeId.forEach((roledata:any)=>{
        var roleTypeIdValue=roledata.description;
        var roleIdTypeIdLabel=roledata.roleTypeId;
        this.roleTypeIdArray.push({
          label:roleTypeIdValue,
          value:roleIdTypeIdLabel
        })
      })
    })
  }
  getUserLoginIds(){
    this.globalResourceService.getUserLoginIds()
    .then(data=>{
      this.userLogin=data.data;
      this.userLogin.forEach((logindata:any)=>{
        var userLoginIdValue=logindata.userLoginId;
        var userLoginIdLabel=logindata.userLoginId;
        this.userLoginIdArray.push({
          label:userLoginIdValue,
          value:userLoginIdLabel
        })
      })
    });
  }
  goToprofilePartyQuickLink(product){
    localStorage.setItem("findParty",product.partyTypeId)
    this.router.navigate(['/partyQuickLink/profile-party-quick-link'],{queryParams:{party:product.partyId}})
  }
  reset(){
    this.spinner.show();
    this.findPartyForm=this.form.group(
      {
        "address1": [''],
        "address2": [''],
        "areaCode": [''],
        "city": [''],
        "contactNumber": [''],
        "countryCode": [''],
        "extInfo": [''],
        "externalId": [''],
        "firstName": [''],
        "groupName": [''],
        "idValue": [''],
        "infoString": [''],
        "inventoryItemId": [''],
        "lastName": [''],
        "partyId": [''],
        "partyIdentificationTypeId": [''],
        "partyTypeId": [''],
        "postalCode": [''],
        "roleTypeId": [''],
        "serialNumber": [''],
        "softIdentifier": [''],
        "stateProvinceGeoId": ['']
      }
    );
    this.crmaAccountService.postFindParty(this.findPartyForm.value).subscribe(res=>{
      this.findPartyArray=res.data.partyMapList;
      this.spinner.hide();
    });
  }
  onSubmit(){
    this.spinner.show();
    this.crmaAccountService.postFindParty(this.findPartyForm.value).subscribe(res=>{
      this.findPartyArray=res.data.partyMapList;
      this.spinner.hide();
    });
  }

  toHub()
  {
    this.router.navigate(['/hub']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
