<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Teams</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCard w-100">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    <p class="h-text">Team Details</p>

                                    <span style="color: red;" *ngIf="this.Deactivated"> Team Deactivated </span>
                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div class="create-new-leade mt-2">
                                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                        <div class="divA"  style="margin-top: -17%!important;">
                                                        
                                                            <div class="w3-card-4 classCardView w-100">
                                                                <div class="header-tabs" >
                                                                    <h4 class="common-styling hleft">
                                                                        Team Summary
                                                                        <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#editTeamPopup">Edit
                                                                            Team</button>
                                                                        <button type="button" class="btn btn-secondary submit-btn ml-2" (click)="deactivatedTeamByPartyId()">Deactivate Team</button>
                                                                    </h4>
                                                                </div>
                                                                <form>
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-2">
                                                                                    <p style="color:#383139;">Name</p>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <span  style="color:#65787E;" *ngIf="TeamByPartyId">{{TeamByPartyId.name}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-2">
                                                                                    <p style="color:#383139;">Comments</p>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <span  style="color:#65787E;" *ngIf="TeamByPartyId">{{TeamByPartyId.comments}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>

                                                            <div class="w3-card-4 classCardView w-100 mt-2">
                                                                <div class="header-tabs" >
                                                                    <h4 class="common-styling hleft">
                                                                        Bookmarks and Files
                                                                        <button type="button"
                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                            (click)="resetBookUrl()" data-toggle="modal"
                                                                            data-target="#bookmarkPopup">Bookmark URL</button>
                                                                    </h4>
                                                                </div>
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12 col-12">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="bookMarksUrlPartner"
                                                                                    [paginator]="true" [rows]="rows"
                                                                                    scrollWidth="100%"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total" [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    Name
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Classification
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Description
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Created Date
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Update
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Delete
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    <span
                                                                                                         class="account-button"  >
                                                                                                        {{product.urlAddress}}
                                                                                                    </span>
            
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.classification}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.description}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.createdStamp |
                                                                                                date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                <button type="button"
                                                                                                    class="btn btn-secondary submit-btn "
                                                                                                    data-toggle="modal"
                                                                                                    data-target="#bookmarkPopup"
                                                                                                    data-dismiss="modal"
                                                                                                    (click)="updateBook(product)">Update
            
                                                                                                </button>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                <button type="button"
                                                                                                    class="btn btn-secondary submit-btn "
                                                                                                    data-dismiss="modal"
                                                                                                    (click)="deleteBookMarksUrlPartner(product)">Delete
            
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage"
                                                                                        let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
            
                                                                                <p class="paginate_data"> View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
            
                                                            <div class="w3-card-4 classCardView w-100 mt-2">
                                                                <div class="header-tabs" >
                                                                    <h4 class="common-styling hleft">
                                                                        Bookmarks and Files
                                                                        <button type="button"
                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                            (click)="resetUpload()" data-toggle="modal"
                                                                            data-target="#filePopup">Upload File</button>
                                                                    </h4>
                                                                </div>
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12 col-12">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="bookmarksUploadPartner"
                                                                                    [paginator]="true" [rows]="rows"
                                                                                    scrollWidth="100%"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total" [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'300px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    Name
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Classification
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Description
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Created Date
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Update
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Delete
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'300px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    <span
                                                                                                         class="account-button"  >
                                                                                                        {{product.url}} </span>
            
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.classification}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.description}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.createdStamp |
                                                                                                date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                <button type="button"
                                                                                                    class="btn btn-secondary submit-btn "
                                                                                                    data-toggle="modal"
                                                                                                    data-target="#filePopup"
                                                                                                    data-dismiss="modal"
                                                                                                    (click)="updateBookUpload(product)">Update
            
                                                                                                </button>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                <button type="button"
                                                                                                    class="btn btn-secondary submit-btn "
                                                                                                    data-dismiss="modal"
                                                                                                    (click)="deleteBookmarksUploadPartner(product.bookmarksUploadId)">Delete
            
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage"
                                                                                        let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
            
                                                                                <p class="paginate_data"> View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="w3-card-4 classCardView w-100 mt-2">
                                                                <div class="header-tabs" >
                                                                    <h4 class="common-styling hleft">
                                                                        Notes
                                                                        <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                                           (click)="resetNote()" data-toggle="modal" data-target="#notesPopup">Create
                                                                            New Note</button>
                                                                    </h4>
                                                                </div>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12 col-12">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="NotePartnerList" [paginator]="true"
                                                                                    [rows]="rows" scrollWidth="100%"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total" [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    Note Info
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Note Id
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"notesPopup
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Note Date Time
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Action
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    <span
                                                                                                        style="cursor: pointer;">
                                                                                                        {{product.noteInformation}} </span>
                    
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.noteId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.createdStamp | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                <button type="button"
                                                                                                    (click)="updateNotes(product)"
                                                                                                    data-toggle="modal"
                                                                                                    data-target="#notesPopup"
                                                                                                    class="btn btn-secondary submit-btn ml-2">Update
                                                                                                </button>
            
                                                                                                <button type="button"
                                                                                                    (click)="deleteNotePartner(product)"
                                                                                                    class="btn btn-danger  ml-2">Delete
                                                                                                </button>
            
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                    
                                                                                <p class="paginate_data"> View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                        

                                                            <div class="w3-card-4 classCard mt-3">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hCard">
                                                                        Catalog Requests
                                                                    </h4>
            
                                                                    <span class="edit-buttons hCreate"
                                                                        data-toggle="modal" data-target="#createCatalogRequestPop"
                                                                        (click)="resetFrom()"
                                                                        style="margin-left:40%!important;">Create New Catalog
                                                                        Request</span>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div
                                                                        class="all-users-infomation font-13 font-medium own-user-select notes">
                                                                        <div class="col-lg-12 col-12">
                                                                            <div class="form-group">
                                                                                <div class="card own-account-table ">
                                                                                    <p-table [value]="allCatalogRequestData"
                                                                                        [paginator]="false" scrollHeight="150px"
                                                                                        [rows]="rows" scrollWidth="100%"
                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                        [totalRecords]="total" [scrollable]="true"
                                                                                        (sortFunction)="customSort($event)"
                                                                                        [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th pSortableColumn="code"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div class="checkbox-align"
                                                                                                        style="color: white;">
                                                                                                        <p-checkbox
                                                                                                            _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox"
                                                                                                            binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div
                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="undefined"
                                                                                                                        value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </p-checkbox>
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="code"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="name"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Address
                                                                                                        <p-sortIcon
                                                                                                            field="name"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Activity
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
            
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Taken By
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Full Filled
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
            
            
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Action
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
            
            
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        <p-checkbox
                                                                                                            _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox"
                                                                                                            binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div
                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="undefined"
                                                                                                                        value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </p-checkbox>
                                                                                                        {{product.date |
                                                                                                        date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.address}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.activity}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.takenBy}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.fullFilled}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    <button type="submit"
                                                                                                        class="btn btn-secondary submit-btn"
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#createCatalogRequestPop"
                                                                                                        (click)="UpdateCatalogRequestPatch(product)">Update</button>
                                                                                                    <button type="submit"
                                                                                                        class="btn btn-secondary submit-btn"
                                                                                                        (click)="deleteCatalogRequest(product)">Delete</button>
                                                                                                </td>
            
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
            
            
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
            
                                                            </div>
                                                        </div>
                                                    
                                                        <div class="divB">
                                                            <div class="w3-card-4 classCardView w-100" >
                                                                <div class="header-tabs" >
                                                                    <h4 class="common-styling hleft">
                                                                        Team Members
                                                                        <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#TeamMemberPopup" (click)="reset()">Create New Team Members</button>
                                                                    </h4>
                                                                </div>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12 col-12">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="TeamMemberByPartyId" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Team Member Id
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Role
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Action
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    <span  class="account-button"  >
                                                                                                        {{product.teamMemberPartyId}}
                                                                                                    </span>

                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">{{product.securityGroupId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" (click)="update(product)" data-target="#TeamMemberPopup">Update</button>
                                                                                                <button type="button" class="btn btn-secondary submit-btn ml-2" (click)="delete(product.autoId)">Delete</button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>

                                                                                <p class="paginate_data"> View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <!-- <div class="w3-card-4 classCard mt-3">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hCard">
                                                                        Catalog Requests
                                                                    </h4>

                                                                    <span class="edit-buttons hCreate" data-toggle="modal" data-target="#" style="margin-left:54%!important;">Create New Catalog</span>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                                        <div class="col-lg-12 col-12">
                                                                            <div class="form-group">
                                                                                <div class="card own-account-table ">
                                                                                    <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th pSortableColumn="code">
                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                            </div>
                                                                                                        </p-checkbox>
                                                                                                        Date
                                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="name">
                                                                                                    <div style="color: white;">Address
                                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th pSortableColumn="category">
                                                                                                    <div style="color: white;">Activity
                                                                                                        <p-sortIcon field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                
                                                                                                <th pSortableColumn="category">
                                                                                                    <div style="color: white;">Taken By
                                                                                                        <p-sortIcon field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                
                                                                                                <th pSortableColumn="category">
                                                                                                    <div style="color: white;">FullFilled
                                                                                                        <p-sortIcon field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                
                                                                                                
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td >
                                                                                                    <div class="checkbox-align">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                            </div>
                                                                                                        </p-checkbox>
                                                                                                        {{product.partyIdFrom}}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>{{product.company}}</td>
                                                                                                <td>{{product.comments}}</td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                    <p class="paginate_data">
                                                                                        View per page
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                
                                                            </div> -->

                                                            <div class="w3-card-4 classCard mt-3">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hCard">
                                                                        Open Orders
                                                                    </h4>
                                                                    <span class="edit-buttons hCreate"
                                                                        data-toggle="modal" data-target="#createOrderPopup"
                                                                        style="margin-left:65%!important;">Orders </span>
            
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div
                                                                        class="all-users-infomation font-13 font-medium own-user-select notes">
                                                                        <div class="col-lg-12 col-12">
                                                                            <div class="form-group">
                                                                                <div class="card own-account-table ">
                                                                                    <p-table [value]="allOrderByData"
                                                                                        [paginator]="false" scrollHeight="150px"
                                                                                        [rows]="rows" scrollWidth="100%"
                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                        [totalRecords]="total" [scrollable]="true"
                                                                                        (sortFunction)="customSort($event)"
                                                                                        [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th pSortableColumn="code"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div class="checkbox-align"
                                                                                                        style="color: white;">
                                                                                                        <p-checkbox
                                                                                                            _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox"
                                                                                                            binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div
                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="undefined"
                                                                                                                        value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </p-checkbox>
                                                                                                        Order Date
                                                                                                        <p-sortIcon
                                                                                                            field="code"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="name"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Order Name and ID
                                                                                                        <p-sortIcon
                                                                                                            field="name"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        PO#
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
            
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Customer
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Ship Before Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Amount
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
            
            
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Action
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
            
            
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        <p-checkbox
                                                                                                            _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox"
                                                                                                            binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div
                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="undefined"
                                                                                                                        value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </p-checkbox>
                                                                                                        {{product.orderDate|date}}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.orderName}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.po}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.customer}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.status}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.shipBeforeDate|date}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.amount}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    <button type="submit"
                                                                                                        class="btn btn-secondary submit-btn"
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#createOrderPopup"
                                                                                                        (click)="updateOrderPatch(product)">Update</button>
                                                                                                    <button type="submit"
                                                                                                        class="btn btn-secondary submit-btn"
                                                                                                        (click)="deleteOrder(product)">Delete</button>
                                                                                                </td>
            
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
            
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
            
                                                            </div>
            
                                                            <div class="w3-card-4 classCard mt-3">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hCard">
                                                                        Opportunities
                                                                    </h4>
                                                                    <span class="edit-buttons hCreate"
                                                                        data-toggle="modal" data-target="#createOpportunityPopup"
                                                                        (click)="resetFrom()"
                                                                        style="margin-left:40%!important;">Create New
                                                                        Opportunity</span>
            
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div
                                                                        class="all-users-infomation font-13 font-medium own-user-select notes">
                                                                        <div class="col-lg-12 col-12">
                                                                            <div class="form-group">
                                                                                <div class="card own-account-table ">
                                                                                    <p-table [value]="allOpportunityData"
                                                                                        [paginator]="false" scrollHeight="150px"
                                                                                        [rows]="rows" scrollWidth="100%"
                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                        [totalRecords]="total" [scrollable]="true"
                                                                                        (sortFunction)="customSort($event)"
                                                                                        [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th pSortableColumn="code"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div class="checkbox-align"
                                                                                                        style="color: white;">
                                                                                                        <p-checkbox
                                                                                                            _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox"
                                                                                                            binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div
                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="undefined"
                                                                                                                        value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </p-checkbox>
                                                                                                        Opportunity Name
                                                                                                        <p-sortIcon
                                                                                                            field="code"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="name"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Stage
                                                                                                        <p-sortIcon
                                                                                                            field="name"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Opportunity Amount
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
            
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Estimated Close Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
            
            
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Action
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
            
            
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        <p-checkbox
                                                                                                            _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox"
                                                                                                            binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div
                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="undefined"
                                                                                                                        value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </p-checkbox>
                                                                                                        {{product.opportunityName}}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.stage}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.opportunityAmount}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.closeDate| date}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    <button type="submit"
                                                                                                        class="btn btn-secondary submit-btn"
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#createOpportunityPopup"
                                                                                                        (click)="updateOpportunityPatch(product)">Update</button>
                                                                                                    <button type="submit"
                                                                                                        class="btn btn-secondary submit-btn"
                                                                                                        (click)="deleteOpportunity(product)">Delete</button>
                                                                                                </td>
            
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
            
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
            
                                                            </div>
            

                                                            <div class="w3-card-4 classCard">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hCard">
                                                                        Cases
                                                                    </h4>
                                                                    <span class="edit-buttons hCreate"
                                                                        (click)="resetFrom()" data-toggle="modal"
                                                                        data-target="#createCasesNewPop"
                                                                        style="margin-left:65%!important;">Create New Cases</span>
            
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div
                                                                        class="all-users-infomation font-13 font-medium own-user-select notes">
                                                                        <div class="col-lg-12 col-12">
                                                                            <div class="form-group">
                                                                                <div class="card own-account-table ">
                                                                                    <p-table [value]="allCasessData"
                                                                                        [paginator]="false" scrollHeight="150px"
                                                                                        [rows]="rows" scrollWidth="100%"
                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                        [totalRecords]="total" [scrollable]="true"
                                                                                        (sortFunction)="customSort($event)"
                                                                                        [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th pSortableColumn="code"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div class="checkbox-align"
                                                                                                        style="color: white;">
                                                                                                        <p-checkbox
                                                                                                            _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox"
                                                                                                            binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div
                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="undefined"
                                                                                                                        value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </p-checkbox>
                                                                                                        Priority
                                                                                                        <p-sortIcon
                                                                                                            field="code"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="name"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Subject
                                                                                                        <p-sortIcon
                                                                                                            field="name"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
            
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Reason
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
            
            
                                                                                                <th pSortableColumn="category"
                                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                                    <div style="color: white;">
                                                                                                        Action
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </div>
                                                                                                </th>
            
            
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        <p-checkbox
                                                                                                            _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox"
                                                                                                            binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div
                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="undefined"
                                                                                                                        value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </p-checkbox>
                                                                                                        {{product.priority}}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.subject}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.status}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.type}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.reason}}</td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    <button type="submit"
                                                                                                        class="btn btn-secondary submit-btn"
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#createCasesNewPop"
                                                                                                        (click)="updateCasessPatch(product)">Update</button>
                                                                                                    <button type="submit"
                                                                                                        class="btn btn-secondary submit-btn"
                                                                                                        (click)="deleteCasess(product)">Delete</button>
                                                                                                </td>
            
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
            
            
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
            
                                                            </div>

                                                            <div class="w3-card-4 classCardView w-100 mt-2">
                                                                <div class="header-tabs" >
                                                                    <h4 class="common-styling hleft">
                                                                        Activities History
                                                                        <button type="button"
                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                            (click)="resetLogCall()" data-toggle="modal"
                                                                            data-target="#logTaskPopup">Log Call</button>
            
                                                                    </h4>
                                                                </div>
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12 col-12">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="logTaskCallPartnerId"
                                                                                    [paginator]="true" [rows]="rows"
                                                                                    scrollWidth="100%"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total" [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    Type
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Purpose
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Activity
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Status
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Started Date
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Completion Date
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Action
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    <span
                                                                                                         class="account-button"  >
                                                                                                        {{product.workEffortPurposeTypeId}}
                                                                                                    </span>
            
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.internalPartyId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.workEffortName}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.currentStatusId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.actualStartDateDate |
                                                                                                date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.completionStartDateDate |
                                                                                                date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                <button type="submit"
                                                                                                    class="btn btn-secondary submit-btn"
                                                                                                    data-toggle="modal"
                                                                                                    data-target="#logTaskPopup"
                                                                                                    (click)="updateLogCall(product)">Update</button>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                <button type="submit"
                                                                                                    class="btn btn-secondary submit-btn"
                                                                                                    (click)="deleteLogTaskCallPartner(product)">Delete</button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage"
                                                                                        let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
            
                                                                                <p class="paginate_data"> View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="w3-card-4 classCardView w-100 mt-2">
                                                                <div class="header-tabs" >
                                                                    <h4 class="common-styling hleft">
                                                                        Activities History
                                                                        <button type="button"
                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                            (click)="resetLogEmail()" data-toggle="modal"
                                                                            data-target="#logEmailPopup">Log Email</button>
                                                                    </h4>
                                                                </div>
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12 col-12">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="LogTaskEmail" [paginator]="true"
                                                                                    [rows]="rows" scrollWidth="100%"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total" [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    Type
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Purpose
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Activity
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Status
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Started Date
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Completion Date
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Action
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    <span
                                                                                                         class="account-button"  >
                                                                                                        {{product.workEffortPurposeTypeId}}
                                                                                                    </span>
            
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.internalPartyId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.workEffortName}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.currentStatusId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.actualStartDateDate |
                                                                                                date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.completionStartDateDate |
                                                                                                date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                <button type="submit"
                                                                                                    class="btn btn-secondary submit-btn"
                                                                                                    data-toggle="modal"
                                                                                                    data-target="#logEmailPopup"
                                                                                                    (click)="updateLogEmail(product)">Update</button>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                <button type="submit"
                                                                                                    class="btn btn-secondary submit-btn"
                                                                                                    (click)="deleteLogTaskEmailPartner(product)">Delete</button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage"
                                                                                        let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
            
                                                                                <p class="paginate_data"> View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="editTeamPopup" tabindex="-1" role="dialog" aria-labelledby="editTeamPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Edit/Update Team</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Edit/Update Team</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editTeam">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group  rightForm">
                                                        <label for="exampleInputEmail1">Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="name" placeholder="Enter Name">
                                                    </div>

                                                    <div class="col-lg-2 form-group  rightFormA">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="comments" placeholder="Enter Comments">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-4  main-submit-button" style="margin-left: 43%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="updateTeam()">Edit Team</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="TeamMemberPopup" tabindex="-1" role="dialog" aria-labelledby="TeamMemberPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Team Members</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Team Members</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addTeamMember">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">New Team Member</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="newTeamMember"  [options]="teamMemberArray"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                         placeholder="Enter New Team Member">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Role</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="RoleArray" formControlName="role" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Role">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 52%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" *ngIf="!this.show" (click)="addTeamMemberInviewTeam()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn" *ngIf="this.show" (click)="updateTeamMemberInviewTeam()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--newcode1 by RA-->

<div class="modal fade" id="createAccountsPopup" tabindex="-1" role="dialog" aria-labelledby="createAccountsPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Accounts
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Accounts</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createAccountsForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Account Name	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="accountName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Account Name">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">City</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="city" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter City">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Primary Eamil</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="email" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Primary Eamil">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Primary Phone</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="primaryPhone" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Primary Phone">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" >Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createOpportunityPopup" tabindex="-1" role="dialog" aria-labelledby="createOpportunityPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Opportunity
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Opportunity</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCasesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Opportunity Name	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="opportunityName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Opportunity Name">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Stage</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="stage" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Stage">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Opportunity Amount
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="opportunityAmount" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Opportunity Amount">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Estimated Close Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="estimatedCloseDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" >Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="notesPopup" tabindex="-1" role="dialog" aria-labelledby="notesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Note</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton10>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Note</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addNote">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Note</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="note"
                                                            placeholder="Enter Note">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                   *ngIf="!showNote" (click)="createNotePartner()">Create</button>

                                                   <button type="submit" class="btn btn-secondary submit-btn"
                                                   *ngIf="showNote" (click)="UpdateNotePartner()">Update</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createOpportunityPopup" tabindex="-1" role="dialog" aria-labelledby="createOpportunityPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!this.show" class="color-black pl-1">Create Opportunity
                </span>
                <span *ngIf="this.show" class="color-black pl-1">Update Opportunity
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton14>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="!this.show">
                                    <a>Create Opportunity</a>
                                </li>
                                <li *ngIf="this.show">
                                    <a>Update Opportunity</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCasesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Opportunity Name </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="opportunityName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Opportunity Name">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Stage</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="stage" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Stage">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Opportunity Amount
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="opportunityAmount"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Opportunity Amount">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Estimated Close Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="estimatedCloseDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button *ngIf="!this.show" type="submit"
                                                    class="btn btn-secondary submit-btn"
                                                    (click)="createOpportunities()">Create</button>
                                                <button *ngIf="this.show" type="submit"
                                                    class="btn btn-secondary submit-btn ml-2"
                                                    (click)="updateOpportunity()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createOrderPopup" tabindex="-1" role="dialog" aria-labelledby="createOrderPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!this.show" class="color-black pl-1">Create Order
                </span>
                <span *ngIf="this.show" class="color-black pl-1">Update Order
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton15>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="!this.show">
                                    <a>Create Order</a>
                                </li>
                                <li *ngIf="this.show">
                                    <a>Update Order</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createOrderForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Customer </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="customer"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Customer Name">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Amount</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="amount"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Amount">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Order Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="orderName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Order Name">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Order Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="orderDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Order Date">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">PO
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="po" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter PO">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Ship Before Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="shipBeforeDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Ship Before Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="status"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Status">


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button *ngIf="!this.show" type="submit"
                                                    class="btn btn-secondary submit-btn"
                                                    (click)="createOrder()">Create</button>
                                                <button *ngIf="this.show" type="submit"
                                                    class="btn btn-secondary submit-btn ml-2"
                                                    (click)="updateOrder()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="createCasesNewPop" tabindex="-1" role="dialog" aria-labelledby="createCasesNewPop"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!this.show" class="color-black pl-1">Create Cases
                </span>
                <span *ngIf="this.show" class="color-black pl-1">Update Cases
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton17>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="!this.show">
                                    <a>Create Catalog Request</a>
                                </li>
                                <li *ngIf="this.show">
                                    <a>Update Catalog Request</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCasesNewForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Priority </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="priority"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Priority">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Reason</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="reason"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Reason">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="status"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Status">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Subject</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="subject"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Subject">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="type" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Type">


                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button *ngIf="!this.show" type="submit"
                                                    class="btn btn-secondary submit-btn"
                                                    (click)="createCasess()">Create</button>
                                                <button *ngIf="this.show" type="submit"
                                                    class="btn btn-secondary submit-btn ml-2"
                                                    (click)="updateCasess()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="bookmarkPopup" tabindex="-1" role="dialog" aria-labelledby="bookmarkPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Bookmark URL</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Bookmark URL</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addBookmark">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Classification</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="ClassificationBookmarkArray"
                                                            formControlName="classification"
                                                            placeholder="Enter Classification">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">URL Address</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="url"
                                                            placeholder="Enter URL Address" value="http://">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="description"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showBookmark"
                                                    (click)="createBookMarksUrlPartner()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showBookmark"
                                                    (click)="UpdateBookMarksUrlPartner()">Upadte</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="filePopup" tabindex="-1" role="dialog" aria-labelledby="filePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Upload File</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton9>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Upload File</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="fileUpload">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Classification</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="ClassificationBookmarkArray"
                                                            formControlName="classification"
                                                            placeholder="Enter Classification">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Upload File</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="file" class="form-control" id="exampleInputEmail1"
                                                            (change)="fileProgressCom($event)"
                                                            aria-describedby="emailHelp" formControlName="file"
                                                            placeholder="Enter Upload File">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="description"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showUpload"
                                                    (click)="createBookmarkUpload()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showUpload"
                                                    (click)="updateBookmarkUpload()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="logEmailPopup" tabindex="-1" role="dialog" aria-labelledby="logEmailPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> {{showLogEmail ? 'Update Email' : 'Create Email'}} </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton12>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>
                                        {{showLogEmail ? 'Update Log Email' : 'Create Log Email'}}

                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="logEmailForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        Email
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Inbound/Outbound</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="OutboundArray" formControlName="Outbound"
                                                            placeholder="Enter Inbound/Outbound">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Internal Party</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="InternalParty"
                                                            placeholder="Enter Internal Party">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">External Party</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="ExternalParty"
                                                            placeholder="Enter External Party">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Opportunity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="SalesOpportunityArray"
                                                            formControlName="Opportunity"
                                                            placeholder="Enter Opportunity">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Case</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="CasesArray" formControlName="Case"
                                                            placeholder="Enter Case">
                                                        </p-dropdown>

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Subject </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Subject"
                                                            placeholder="Enter Subject	">

                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Message</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Message"
                                                            placeholder="Enter Message">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Start Date and Time</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="StartDateandTime"
                                                            placeholder="Enter Start Date and Time">

                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hourEnd"
                                                            optionlabel="label" formControlName="hourFrom"> :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minuteEnd"
                                                            optionlabel="label" formControlName="minuteFrom">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="amPmFrom"
                                                            [options]="amPM" name="amPmEnd" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Duration"
                                                            placeholder="Enter Duration">

                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!showLogEmail"
                                                    (click)="createLogTaskEmailPartner()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="showLogEmail"
                                                    (click)="UpdateLogTaskEmailPartner()">Update</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="logTaskPopup" tabindex="-1" role="dialog" aria-labelledby="logTaskPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">{{showLogCall ? 'Update Task ': 'Create Task'}} </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton11>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>{{showLogCall ? 'Update Log Task' : 'Create Log Task'}}

                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="logTaskForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        Phone Call
                                                        <!-- <input type="text" class="form-control" id="exampleInputEmail1"
                                                         aria-describedby="emailHelp" formControlName="Type" 
                                                         placeholder="Enter Type"> -->
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Inbound/Outbound</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="OutboundArray" formControlName="Outbound"
                                                            placeholder="Enter Inbound/Outbound">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Internal Party</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="InternalParty"
                                                            placeholder="Enter Internal Party">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">External Party</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="ExternalParty"
                                                            placeholder="Enter External Party">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Opportunity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="SalesOpportunityArray"
                                                            formControlName="Opportunity"
                                                            placeholder="Enter Opportunity">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Case</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="CasesArray" formControlName="Case"
                                                            placeholder="Enter Case">
                                                        </p-dropdown>

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Subject </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Subject"
                                                            placeholder="Enter Subject	">

                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Message</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Message"
                                                            placeholder="Enter Message">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Start Date &amp; Time</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="StartDateandTime"
                                                            placeholder="Enter Start Date and Time">
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hourFrom"
                                                            optionlabel="label" formControlName="hourFrom"> :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minuteFrom"
                                                            optionlabel="label" formControlName="minuteFrom">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="amPmFrom"
                                                            [options]="amPM" name="amPmFrom" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Duration"
                                                            placeholder="Enter Duration">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showLogCall"
                                                    (click)="createLogTaskCallPartner()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showLogCall"
                                                    (click)="UpdateLogTaskCallPartner()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createCatalogRequestPop" tabindex="-1" role="dialog"
    aria-labelledby="createCatalogRequestPop" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!this.show" class="color-black pl-1">Create Catalog Request
                </span>
                <span *ngIf="this.show" class="color-black pl-1">Update Catalog Request
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton16>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="!this.show">
                                    <a>Create Catalog Request</a>
                                </li>
                                <li *ngIf="this.show">
                                    <a>Update Catalog Request</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCatalogForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Activity </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="activity"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Activity">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Full Filled</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="fullFilled"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter FullFilled">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Taken By
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="takenBy"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Taken By">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="date" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Date">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Address
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="address"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Address">


                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button *ngIf="!this.show" type="submit"
                                                    class="btn btn-secondary submit-btn"
                                                    (click)="createCatalogRequest()">Create</button>
                                                <button *ngIf="this.show" type="submit"
                                                    class="btn btn-secondary submit-btn ml-2"
                                                    (click)="UpdateCatalogRequest()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>