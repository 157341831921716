import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2'
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-party-security-group-list',
  templateUrl: './party-security-group-list.component.html',
  styleUrls: ['./party-security-group-list.component.css']
})
export class PartySecurityGroupListComponent implements OnInit {
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  rows = 50;
  pageNo = 1;
  activeCategory = 1;
  search = "";
  public user = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search
  }

  userList: any;
  loginIdArray: any[] = [];
  partIdArray: any[] = [];
  status: string;
  adminSecurity: string;
  userEmail: string;
  personId: any;
  partyIdArray: any[] = [];
  catalogId: string;
  party: any;
  AddUserLoginToSecurityGroup: any;
  loginid: any;
  userLoginId: string;
  constructor(private accountsService: AccountsService,
    private headerService: HeaderService,
    readonly _ActivatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    private addSkillService: AddSkillService,
    readonly router: Router) {
      this.userLoginId=localStorage.getItem("userLoginId")

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.loginid=params["loginid"]
    })
    this.ListAddUserLoginToSecurityGroup();

  }






  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ListAddUserLoginToSecurityGroup() {
    this.spinner.show();

    this.accountsService.ListAddUserLoginToSecurityGroup(this.party,this.loginid).subscribe((res: any) => {
      this.AddUserLoginToSecurityGroup = res.data;
      this.spinner.hide();

    })

  }

  expireAddUserLoginToSecurityGroup(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "fromDate": product.fromDate,
          "groupId":  product.securityGroupId,
          "partyId":  product.partyId,
          "thruDate":  product.thruDate,
        }
        this.accountsService.expireAddUserLoginToSecurityGroup(req,this.loginid).subscribe(res => {
          this.ListAddUserLoginToSecurityGroup();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  createUser() {
    this.router.navigate(["/partyQuickLink/create-party-security-group"],{ queryParams: { party: this.party,login:this.loginid } });
  }
  updateUserLoginToSecurityGroup(product) {
    this.router.navigate(["/partyQuickLink/create-party-security-group"],{queryParams:{party:this.party,login:this.loginid,partyClassId:product.securityGroupId,fromDate:product.fromDate,thruDate:product.thruDate}});
  }





  ngOnDestroy(): void {
    this.spinner.hide();
  }
}

