import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-update-physical-inventory',
    templateUrl: './update-physical-inventory.component.html'
})
export class UpdatePhysicalInventoryComponent {
   
}
