import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
@Component({
  selector: 'app-meta-data-predicate',
  templateUrl: './meta-data-predicate.component.html',
  styleUrls: ['./meta-data-predicate.component.css']
})
export class MetaDataPredicateComponent implements OnInit {
  activeCategory=6;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  addMetaDataPredicateForm: FormGroup;
  show: boolean;
  metaDataPredicateDataList: any;

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly myContactsService: MyContactsService,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly toastr: ToastrService,
    private _location: Location,

  ) 
  {
    this.addMetaDataPredicateForm=this._FormBuilder.group({
      description:"",
      metaDataPredicateID:"",
    })
  }

  ngOnInit(): void {
    this.metaDataPredicateData();
  
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  
  reset(){
    this.addMetaDataPredicateForm.reset();
    this.show = false;
  }
  update(product) {
    this.show = true;
    setTimeout(() => {
      const form = this.addMetaDataPredicateForm;
      form.patchValue({
        description: product.description ,
        metaDataPredicateID: product.metaDataPredicateId
      })
    }, 2000);
  }
  metaDataPredicateData(){
    this.spinner.show();
    this.myContactsService.metaDataPredicateData().subscribe(res=>{
      this.metaDataPredicateDataList = res.data[0].metaDataPredicate;
      this.spinner.hide();
    })
  }
  removeMetaDataPredicate(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "metaDataPredicateId": product.metaDataPredicateId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.removeMetaDataPredicate(req).subscribe(res => {
          this.metaDataPredicateData();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  addMetaDataPredicate() {
    this.spinner.show();
    const requestData =
    {
      "description":  this.addMetaDataPredicateForm.value.description ,
      "metaDataPredicateId":  this.addMetaDataPredicateForm.value.metaDataPredicateID ,
    }
    this.myContactsService.addMetaDataPredicate(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addMetaDataPredicateForm.reset();
        this.metaDataPredicateData();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  updateMetaDataPredicate() {
    this.spinner.show();
    const requestData =
    {
      "description":  this.addMetaDataPredicateForm.value.description ,
      "metaDataPredicateId":  this.addMetaDataPredicateForm.value.metaDataPredicateID ,
    }
    this.myContactsService.updateMetaDataPredicate(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addMetaDataPredicateForm.reset();
        this.metaDataPredicateData();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  openType()
  {
    this._Router.navigate(['/content/data-resource-setup']);
  }
  openCharcterSet()
  {
    this._Router.navigate(['/content/data-resource-setup/character-set']);

  }
  openCategory()
  {
    this._Router.navigate(['/content/data-resource-setup/category']);

  }
  openTypeAttribute()
  {
    this._Router.navigate(['/content/data-resource-setup/type-attribute']);

  }
  openFileExt()
  {
    this._Router.navigate(['/content/data-resource-setup/file-ext']);

  }
  openMetaDataPredicate()
  {
    this._Router.navigate(['/content/data-resource-setup/metadata-predicate']);

  }
  openMimeType()
  {
    this._Router.navigate(['/content/data-resource-setup/mimetype']);

  }
  openMimeTypeTemplate()
  {
    this._Router.navigate(['/content/data-resource-setup/mimetype-template']);

  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;
 
        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
 
    
 }
 
 ngOnDestroy(): void {
  this.spinner.hide();
    }
 
  changeactiveCategory(tab: number)
  {
    this.activeCategory = tab;
  
  }




}
