<div class="container-fluid main-container-wrapper" style="margin-top: 53px !important;">
    <!-- <div class="row " style="background-color: #0492C2; padding-top: 6px !important;">
        

        <div class="col-lg-1">
           <span class="color-black pl-1 titlePanels" style="cursor: pointer; color: white; font-weight: bold;">Find </span>
        </div>
            <div class="col-lg-2" style="margin-left: -4% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Work Order Number"
                >
                </p-dropdown>

            </div>

            <div class="col-lg-2" style="margin-left: -2% !important;">
                <input type="text" class="form-control" id=""  >
            </div>

            <div class="col-lg-1" style="margin-left: -2% !important;">
                <button type="submit" class="btn btn-secondary submit-btn">Search</button>

            </div>
        

        <div class="col-lg-4">
        </div>
            <div class="col-lg-2" style="margin-left: 7% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Recent Items"
                >
                </p-dropdown>

            </div>

        

    

</div> -->

<div class="row" style="background-color:  #0492C2; padding-bottom: 6px !important; padding-top: 4px !important;">

<div class="col-lg-3   ">
    <!-- <button type="submit" class="btn btn-secondary submit-btn" style="margin-left: -2%;">
        Dispatch Board
     </button> -->
</div>

<div class="col-lg-2 " style="margin-left: 23% !important;">
    <button (click)="toWorkOrder()" style="background-color: #1338BE; 
    color: white !important; font-weight: bold;"> Work Orders</button>
</div>

<div class="col-lg-2 " style="margin-left: -8% !important;">
    <button (click)="toCustomer()" style="background-color: #48AAAD; 
    color: white !important; font-weight: bold;">Customer</button>

</div>               
   
<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toInvoices()" style="background-color:#016064;
     color: white; font-weight: bold;">Invoices</button>

</div>                
    
<!-- <div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toReports()" style="background-color: #1520A6; 
    color: white; font-weight: bold;">Reports</button>

</div>               

<div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toPeople()" style="background-color:#59788E;
     color: white !important; font-weight: bold; padding-left: 12px; padding-right: 12px;">Staff</button>

</div> -->
<div class="col-lg-2 " style="margin-left: -3% !important;">
    <button (click)="toTimeSheet()" style="background-color: #1F456E;
     color: white !important; font-weight: bold;">Time Sheet</button>

</div>                 

<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toSetting()" style="background-color:#757c88 ; 
    color: white; font-weight: bold; ">Settings</button>

</div>



</div>

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            <div  class="w3-card-4 classCssShadow" style="border:1px solid;border-color: #0d3769 !important; margin-top: 1%;">
                <div class="col-lg-12 " style="background-color:rgba(16, 78, 136, 0.89); color: white;">
                    <div class="row d-flex" style="padding: 4px;">
                        <div class="" style="color: white; margin-left: 7px; font-weight: bold;" >
                            VIEW
                        </div>
                        <div class="col-lg-2" style="margin-top:-3px ;">
                            <p-dropdown filter="true"
                            [options]="" optionlabel="label"
                            placeholder="Work Order"
                            >
                            </p-dropdown>
            
                        </div>
        
                    </div>
                </div>
        
                <div class="" style="margin-top: 1%; margin-left: 1%;">
                            <button type="submit" (click)="toNewWorkOrder()" class="btn btn-secondary submit-btn ml-2">
                                New Report
                             </button>
        
                            <button type="submit" class="btn btn-secondary submit-btn ml-2">
                                Edit
                             </button>
        
                            <button type="submit" class="btn btn-secondary submit-btn ml-2">
                                Delete
                             </button>
        
                            
                        
                </div>       
        
                <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                    <div class="col-lg-12 col-12">
                        <div class="form-group">
                            <div class="card own-account-table">
                                <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                        <tr style="background-color:rgba(52, 146, 235, 0.89);">
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                            <div class="ui-helper-hidden-accessible">
                                                                <input type="checkbox" name="undefined" value="undefined">
                                                            </div>
                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                            </div>
                                                        </div>
                                                        <!---->
                                                    </p-checkbox>
        
                                                    Name
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    Description
                                                </div>
                                                <div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </div>
                                            </th>
                                            
                                          
                                           
        
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                        <tr>
                                            <td [ngStyle]="{'width':'170px'}">
                                                <div class="checkbox-align">
                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                            <div class="ui-helper-hidden-accessible">
                                                                <input type="checkbox" name="undefined" value="undefined">
                                                            </div>
                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                            </div>
                                                        </div>
                                                    </p-checkbox>
                                                    <span  class="account-button"  >
                                                        {{product.data.custRequestId}}</span>
        
                                                </div>
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                                {{product.data.custRequestTypeId}}
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                                {{product.data.productStoreId}}
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                                {{product.data.custRequestName}}
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                                {{product.data.priority}}
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                                {{product.data.priority}}
                                            </td>
        
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                        <tr>
                                            <td>
                                                <span class="text-center">
                                                    <h4 class="ml-2">No
                                                        Record Found
                                                    </h4>
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <p class="paginate_data">
                                    View per page
        
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        
        
                <div style="background-color:rgba(52, 146, 235, 0.89); margin-top: 1%;">
                    !
                </div>

                <div class="col-lg-12">
                    <div class="row d-flex justify-content-around mt-3">
                        <span class="fa-solid fa-file-pen" style="margin-left: 1%; color: blue;"></span>
                        <span class="fa-solid fa-file" style="color: blue;"></span>
                        <span class="fa-solid fa-file" style="color: orange;"></span>
                    </div>

                </div>
        
                <div class="col-lg-12 " >
                   <div class="row d-flex justify-content-around" style="font-size: 12px;">
                        <a class="btn btn-link" style="font-size: 12px;">User Created</a>
                        <a class="btn btn-link" style="font-size: 12px;">Standard</a>
                        <a class="btn btn-link" style="font-size: 12px;">Premium</a>
                   </div>
                    
                </div>

                
                     
        
            </div>
        
            <div  class="w3-card-4 classCssShadow" style="border:1px solid;border-color: #0d3769 !important; margin-top: 1%;">
                <div class="col-lg-12" style="background-color:rgba(16, 78, 136, 0.89); color: white;">
                    <div class="row  d-flex justify-content-between">
                        <label style="margin: 1%;">Filter Set</label>
                        <a class="btn btn-link ">^</a>
        
                    </div>
                </div>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="col-lg-12" style="margin-left: -2%;">
                        <div class="row">
                            <div class="col-lg-2 form-group classInput">
                                <label for="exampleInputEmail1">Filter</label>
                            </div>
        
                            <div class="col-lg-2">
        
                            </div>
        
                            <div class="col-lg-2">
                                <p-dropdown filter="true"
                                [options]="" optionlabel="label"
                                placeholder="Filter Off"
                                >
                                </p-dropdown>
                            </div>
        
                            <div class="col-lg-2 form-group classInput">
                                <label for="exampleInputEmail1">Has Payment</label>
                            </div>
        
                            
                            
                            <div class="col-lg-2 form-group classInput">
                                    <label for="exampleInputEmail1">Equals</label>
                            </div>
                            
        
                            <div class="col-lg-2">
                                <p-dropdown filter="true"
                                [options]="" optionlabel="label"
                                placeholder="Filter Off"
                                >
                                </p-dropdown>
                            </div>
                            
                        </div>
                    </div>
        
                    <div class="col-lg-12" style="margin-left: -2%;">
                        <div class="row">
                            <div class="col-lg-2 form-group classInput">
                                <label for="exampleInputEmail1">Innvoice Last Action Date</label>
                            </div>
        
                            <div class="col-lg-2 form-group classInput">
                                <label for="exampleInputEmail1">Between</label>
                            </div>
        
                            <div class="col-lg-2">
                                <p-dropdown filter="true"
                                [options]="" optionlabel="label"
                                placeholder="Filter Off"
                                >
                                </p-dropdown>
                            </div>
        
                            <div class="col-lg-2 form-group classInput">
                                <label for="exampleInputEmail1">Payment Amount</label>
                            </div>
        
                            
                            
                            <div class="col-lg-2 form-group classInput">
                                    <label for="exampleInputEmail1">Equals</label>
                            </div>
                            
        
                            <div class="col-lg-2">
                                <p-dropdown filter="true"
                                [options]="" optionlabel="label"
                                placeholder="Filter Off"
                                >
                                </p-dropdown>
                            </div>
                            
                        </div>
                
                    </div>
        
                    <div class="col-lg-12" style="margin-left: -2%;">
                        <div class="row">
                            <div class="col-lg-2 form-group classInput">
                                <label for="exampleInputEmail1">Innvoice Status</label>
                            </div>
        
                            <div class="col-lg-2 form-group classInput">
                                <label for="exampleInputEmail1">Includes</label>
                            </div>
        
                            <div class="col-lg-2">
                                <p-dropdown filter="true"
                                [options]="" optionlabel="label"
                                placeholder="Filter Off"
                                >
                                </p-dropdown>
                            </div>
        
                            <div class="col-lg-2 form-group classInput">
                                <label for="exampleInputEmail1">Payment Method</label>
                            </div>
        
                            
                            
                            <div class="col-lg-2 form-group classInput">
                                    <label for="exampleInputEmail1">Includes</label>
                            </div>
                            
        
                            <div class="col-lg-2">
                                <p-dropdown filter="true"
                                [options]="" optionlabel="label"
                                placeholder="Filter Off"
                                >
                                </p-dropdown>
                            </div>
                            
                        </div>
                
                    </div>
        
                    <div class="col-lg-12" style="margin-left: -2%;">
                        <div class="row">
                            <div class="col-lg-2 form-group classInput">
                                <label for="exampleInputEmail1">WO Status</label>
                            </div>
        
                            <div class="col-lg-2 form-group classInput">
                                <label for="exampleInputEmail1">Includes</label>
                            </div>
        
                            <div class="col-lg-2">
                                <p-dropdown filter="true"
                                [options]="" optionlabel="label"
                                placeholder="Filter Off"
                                >
                                </p-dropdown>
                            </div>
        
                            
                            
                        </div>
                
                    </div>
        
        
                </div>
        
                <div style="background-color:rgba(52, 146, 235, 0.89); margin-top: 1%;">
                    <div class="d-flex col-lg-12 justify-content-end" style="padding: 3px;">
                        <div class="checkbox-align" style="color: white; font-size: 12px; font-weight: bold;">
                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                <div class="custom-checkbox ui-chkbox ui-widget">
                                    <div class="ui-helper-hidden-accessible">
                                        <input type="checkbox" name="undefined" value="undefined">
                                    </div>
                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                    </div>
                                </div>
                                <!---->
                            </p-checkbox>

                            Filter By Skill
                        </div>

                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>

