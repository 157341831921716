import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { AccountsService } from '../../accounts/accounts.service';
import { MyContactsService } from '../../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../../events/create-events/create-event-service';
import { CreateEventServices } from '../../events/create-events/create-event.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forecast-detail',
  templateUrl: './forecast-detail.component.html',
  styleUrls: ['./forecast-detail.component.css']
})
export class ForecastDetailComponent implements OnInit {
  @ViewChild('closebutton')closebutton;
  @ViewChild('closebutton2')closebutton2;
  @ViewChild('closebutton3')closebutton3;
  @ViewChild('closebutton4')closebutton4;
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  parentSalesForecastID: any;
  parentSalesForecastIDArray: any[]=[];
  PartyId: any;
  PartyIdArray: any[]=[];
  currencyArray: any[]=[];
  updateForecast: any;
  addSFADetail: any;
  salesForecastId: any;
  salesForecastList: any;
  updateForecastList: any;
  ProductId: any;
  ProductIdArray: any[]=[];
  UomId: any;
  UomIdArray: any[]=[];
  show: boolean;
  columnwidthForm: any;
  widthProduct: any;
  productupdate: any;
  productCategory: any;
  productCategoryArray: any[]=[];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService, 
    readonly headerService: HeaderService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) { 
    this.updateForecast = this._FormBuilder.group({
      parentForecastID: [''],
      organizationPartyID: [''],
      internalPartyID: [''],
      customTimeID: [''],
      currency: [''],
      quotaAmount: [''],
      forecastAmount: [''],
      bestCaseAmount: [''],
      closedAmount: [''],
      quotaPercent: [''],
      quotaClosed: [''],
      pipelineAmount: [''],
      changeNote: ['']
    });
    this.addSFADetail = this._FormBuilder.group({
      amount: [''],
      quantityUom: [''],
      quantity: [''],
      productID: [''],
      proCategoryID: [''],
    });
  
  }
  cancelSubmit(){
    this._Router.navigate(["/crm/Teams"])
  }


  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.salesForecastId = params.salesForecastId;
    })
    this.getPartyIdList();
    this.getCurrencyRate();
    this.ParentSalesForecastID();
    this.getSalesForecastDetailById();
    this.getUpdateSalesForecastById();
    this.getProductId();
    this.quantityUomId();
    this.getProductCategory();
  }

  generatePDFUpdateSalesForecast() {
    this.spinner.show();
    this.accountsService.generatePDFUpdateSalesForecast(this.salesForecastId).subscribe(res => {
      const blob = new Blob([res], { type: 'document/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'salesForecastReport';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  getProductCategory() {
    this.spinner.show();
    this.myContactsService.getProductCategory().subscribe(res => {
      this.productCategory = res.data;
      this.spinner.hide();
      for (const value of this.productCategory) {
        this.productCategoryArray.push({
          label: value.data.productCategoryTypeId,
          value: value.data.primaryParentCategoryId
        })
      }
    })
  }
  deleteSalesForecastDetail(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "salesForecastDetailId": product.data.salesForecastDetailId,
          "salesForecastId": this.salesForecastId,
          "view_INDEX_1": "20",
          "view_SIZE_1": "0"
        }
        this.myContactsService.deleteSalesForecastDetail(req).subscribe(res => {
          this.getSalesForecastDetailById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  updateSalesForecastDetail(): void {
    this.spinner.show();
    const requestData = {
      "amount": this.addSFADetail.value.amount,
      "productCategoryId":  this.addSFADetail.value.proCategoryID,
      "productId": this.addSFADetail.value.productID,
      "quantity":  this.addSFADetail.value.quantity,
      "quantityUomId": this.addSFADetail.value.quantityUom,
      "salesForecastDetailId":   this.productupdate.data.salesForecastDetailId,
      "salesForecastId": this.salesForecastId,
     
    }

    this.myContactsService.updateSalesForecastDetail(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addSFADetail.reset();
        this.getSalesForecastDetailById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  reset(){
    this.show = false ;
    this.addSFADetail.reset();
    
  }
  update(product) {
    this.spinner.show();
    this.show = true ;
    this.productupdate = product ;
   
      setTimeout(() => {
        const formValue = this.addSFADetail;
        formValue.patchValue({
          amount: product.data.amount,
          quantityUom: product.data.quantityUomId,
          quantity: product.data.quantity,
          productID:product.data.productId,
          proCategoryID: product.data.productCategoryId
        })
  
      }, 2000);
      this.spinner.hide();

    
  }
  quantityUomId() {
    this.spinner.show();
    this.myContactsService.quantityUomId().subscribe(res => {
      this.UomId = res.data;
      this.spinner.hide();
      for (const value of this.UomId) {
        this.UomIdArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
 
  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
   
  }
  getUpdateSalesForecastById() {
    this.spinner.show();
    this.myContactsService.getUpdateSalesForecastById(this.salesForecastId).subscribe(res => {
      this.updateForecastList = res.data[0].getSalesForecastById[0];
      setTimeout(() => {
        const formValue = this.updateForecast;
        formValue.patchValue({
          parentForecastID: this.updateForecastList.parentSalesForecastId ,
      organizationPartyID:  this.updateForecastList.organizationPartyId ,
      internalPartyID:  this.updateForecastList.internalPartyID ,
      customTimeID:  this.updateForecastList.customTimePeriodId ,
      currency:  this.updateForecastList.currencyUomId ,
      quotaAmount:  this.updateForecastList.quotaAmount ,
      forecastAmount:  this.updateForecastList.forecastAmount ,
      bestCaseAmount:  this.updateForecastList.bestCaseAmount ,
      closedAmount:  this.updateForecastList.closedAmount ,
      quotaPercent:  this.updateForecastList.percentOfQuotaForecast ,
      quotaClosed:  this.updateForecastList.percentOfQuotaClosed ,
      pipelineAmount:  this.updateForecastList.pipelineAmount ,
      changeNote:  "" ,
        })
  
      }, 2000);
      this.spinner.hide();

    })
  }
  createSalesForecastDetail(): void {
    this.spinner.show();
    const requestData = {
      "amount": this.addSFADetail.value.amount,
      "productCategoryId":  this.addSFADetail.value.proCategoryID,
      "productId": this.addSFADetail.value.productID,
      "quantity":  this.addSFADetail.value.quantity,
      "quantityUomId": this.addSFADetail.value.quantityUom,
      "salesForecastDetailId":  "",
      "salesForecastId": this.salesForecastId
    }
    this.myContactsService.createSalesForecastDetail(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addSFADetail.reset();
        this.getSalesForecastDetailById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getSalesForecastDetailById() {
    this.spinner.show();
    this.myContactsService.getSalesForecastDetailById(this.salesForecastId).subscribe(res => {
      this.salesForecastList = res.data;
      this.spinner.hide();

    })
  }
  getPartyIdList() {
    this.spinner.show();
    this.myContactsService.getPartyIdList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  getCurrencyRate() {
    this.spinner.show();
    this.myContactsService.getCurrencyDataList().subscribe(res => {
      const currencyList = res.data.currencyList;
      this.spinner.hide();
      for (const value of currencyList) {
        this.currencyArray.push({
          label: value.description + "-" + value.uomId,
          value: value.uomId
        })
      }
    })
  }

  ParentSalesForecastID() {
    this.spinner.show();
    this.myContactsService.ParentSalesForecastID().subscribe(res => {
      this.parentSalesForecastID = res.data[0].parentSalesForecastID;
      this.spinner.hide();
      for (const value of this.parentSalesForecastID) {
        this.parentSalesForecastIDArray.push({
          label: value.parentSalesForecastId,
          value: value.parentSalesForecastId
        })
      }
    })
  }
  updateSalesForecast(): void {
    this.spinner.show();
    const requestData = {
      "bestCaseAmount": this.updateForecast.value.bestCaseAmount  ,
      "changeNote": this.updateForecast.value.changeNote  ,
      "closedAmount": this.updateForecast.value.closedAmount  ,
      "createdByUserLoginId": "" ,
      "currencyUomId": this.updateForecast.value.currency  ,
      "customTimePeriodId": this.updateForecast.value.customTimeID  ,
      "forecastAmount": this.updateForecast.value.forecastAmount  ,
      "internalPartyId": this.updateForecast.value.internalPartyID  ,
      "modifiedByUserLoginId": ""  ,
      "organizationPartyId": this.updateForecast.value.organizationPartyID  ,
      "parentSalesForecastId": this.updateForecast.value.parentForecastID  ,
      "percentOfQuotaClosed": this.updateForecast.value.quotaClosed  ,
      "percentOfQuotaForecast": this.updateForecast.value.quotaPercent  ,
      "pipelineAmount": this.updateForecast.value.pipelineAmount  ,
      "quotaAmount": this.updateForecast.value.quotaAmount  ,
      "salesForecastId": this.salesForecastId
    }

    this.myContactsService.updateSalesForecast(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.updateForecast.reset();
        this.getSalesForecastDetailById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
