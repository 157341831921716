<div class="container-fluid main-container-wrapper">
    <br>


    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Approval</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
                <div>
                   <span class="color-black pl-1 titlePanels">
                        <div class="suppliers-wrapper">

                            <div class="dropdown internal-posting">

                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" routerLink="/internal-posting-applications"
                                        routerLinkActive="active"
                                        routerLink="/hr/job-requisition/internal-job-posting-app">Internal Job Posting
                                        Applications </a>
                                    <a class="dropdown-item" routerLinkActive="active"
                                        routerLink="/hr/job-requisition/job-interview">Job Interview</a>
                                    <a class="dropdown-item" routerLink="/approval">Approval</a>
                                    <a class="dropdown-item" routerLink="/reallocation">Reallocation</a>
                                </div>
                            </div>
                        </div>
                    </span>

                </div>


            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategoryMain==1?'active':''" (click)="JobRequisition()"><a>
                                Job Requisition</a></li>
                        <li [ngClass]="activeCategoryMain==2?'active':''" (click)="InternalJobPostingApplication()"><a>
                                Internal Job Posting Application</a></li>



                    </ul>
                </div>
                <div class="w3-card-4 classCard" style="margin: 11px">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCateg==1?'active':''" (click)="jobInterview()"><a>
                                    Job Interview</a></li>
                            <li [ngClass]="activeCateg==2?'active':''" (click)="approval()"><a>
                                    Approval</a></li>
                            <li [ngClass]="activeCateg==3?'active':''" (click)="Relocation()"><a>
                                    Relocation</a></li>



                        </ul>
                    </div>
                    <div class=" bg-white color-grey">

                        <div>
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" style="padding: 15px 15px;">

                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <span class="headingAccords">Search</span>

                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 88%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>	
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Application ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="appIdArray"
                                                                                [(ngModel)]="advanceSearch.applicationId"
                                                                                name="applicationId" [ngModelOptions]="{standlone:true}"
                                                                                aria-describedby="emailHelp" optionlabel="label"
                                                                                placeholder="Enter Application ID">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Internal Job Posting
                                                                                Status</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="status"
                                                                                [(ngModel)]="advanceSearch.statusId" name="statusId"
                                                                                [ngModelOptions]="{standlone:true}"
                                                                                aria-describedby="emailHelp" optionlabel="label"
                                                                                placeholder="Enter Internal Job Posting Status">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                    </div>
                                                                </div>
                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Application Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown
                                                                                [(ngModel)]="advanceSearch.applicationDateFromSearchType"
                                                                                [ngModelOptions]="{standlone:true}" filter="true"
                                                                                name="applicationDateFromSearchType"
                                                                                [options]="dateIdArray" optionlabel="label" checked>
                
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="date"
                                                                                [(ngModel)]="advanceSearch.applicationDateFrom"
                                                                                name="applicationDateFrom"
                                                                                [ngModelOptions]="{standlone:true}"
                                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                >
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="date"
                                                                                [(ngModel)]="advanceSearch.applicationDateTo"
                                                                                name="applicationDateTo"
                                                                                [ngModelOptions]="{standlone:true}"
                                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                >
                                                                        </div>
                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-lg-2 form-group classInput">
                                                                            <label for="exampleInputEmail1"></label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown
                                                                                [(ngModel)]="advanceSearch.applicationDateToSearchType"
                                                                                [ngModelOptions]="{standlone:true}" filter="true"
                                                                                name="applicationDateToSearchType"
                                                                                [options]="dateIdArray" optionlabel="label" checked>
                
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2 form-group classInput__new_changes">
                                                                            <input type="date"
                                                                                [(ngModel)]="advanceSearch.applicationDateTo"
                                                                                name="applicationDateTo"
                                                                                [ngModelOptions]="{standlone:true}"
                                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                >
                                                                        </div>
                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Approver Party</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                [(ngModel)]="advanceSearch.approverPartyId"
                                                                                name="approverPartyId"
                                                                                [ngModelOptions]="{standlone:true}"
                                                                                [options]="approveData" optionlabel="label"
                                                                                placeholder="Enter Approver Party">
                
                                                                            </p-dropdown>
                                                                        </div>
                
                
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Job Requisition ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="rIdArray"
                                                                                [(ngModel)]="advanceSearch.jobRequisitionId"
                                                                                name="jobRequisitionId"
                                                                                [ngModelOptions]="{standlone:true}"
                                                                                aria-describedby="emailHelp" optionlabel="label"
                                                                                placeholder="Enter Requisition ID">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                    </div>
                                                                </div>
                
                
                
                
                                                            </div>
        
                                                            <div class="col-lg-12 col-12 main-submit-button" style="
                                                                margin-left: -29%;">
                                                                <button _ngcontent-cta-c128="" type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                                                <button type="button" class="btn btn-danger ml-2"
                                                                    (click)="reset()">Reset</button>
                                                            </div>
        
        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <!-- <div class="w3-card-4 classCard">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Application ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" [options]="appIdArray"
                                                                [(ngModel)]="advanceSearch.applicationId"
                                                                name="applicationId" [ngModelOptions]="{standlone:true}"
                                                                aria-describedby="emailHelp" optionlabel="label"
                                                                placeholder="Enter Application ID">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Internal Job Posting
                                                                Status</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" [options]="status"
                                                                [(ngModel)]="advanceSearch.statusId" name="statusId"
                                                                [ngModelOptions]="{standlone:true}"
                                                                aria-describedby="emailHelp" optionlabel="label"
                                                                placeholder="Enter Internal Job Posting Status">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Application Date</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown
                                                                [(ngModel)]="advanceSearch.applicationDateFromSearchType"
                                                                [ngModelOptions]="{standlone:true}" filter="true"
                                                                name="applicationDateFromSearchType"
                                                                [options]="dateIdArray" optionlabel="label" checked>

                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-2 form-group">
                                                            <input type="date"
                                                                [(ngModel)]="advanceSearch.applicationDateFrom"
                                                                name="applicationDateFrom"
                                                                [ngModelOptions]="{standlone:true}"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                >
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input type="date"
                                                                [(ngModel)]="advanceSearch.applicationDateTo"
                                                                name="applicationDateTo"
                                                                [ngModelOptions]="{standlone:true}"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                >
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-lg-12" style="display: none;">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group classInput">
                                                            <label for="exampleInputEmail1"></label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown
                                                                [(ngModel)]="advanceSearch.applicationDateToSearchType"
                                                                [ngModelOptions]="{standlone:true}" filter="true"
                                                                name="applicationDateToSearchType"
                                                                [options]="dateIdArray" optionlabel="label" checked>

                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2"></div>
                                                        <div class="col-lg-2 form-group classInput__new_changes">
                                                            <input type="date"
                                                                [(ngModel)]="advanceSearch.applicationDateTo"
                                                                name="applicationDateTo"
                                                                [ngModelOptions]="{standlone:true}"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                >
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Approver Party</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true"
                                                                [(ngModel)]="advanceSearch.approverPartyId"
                                                                name="approverPartyId"
                                                                [ngModelOptions]="{standlone:true}"
                                                                [options]="approveData" optionlabel="label"
                                                                placeholder="Enter Approver Party">

                                                            </p-dropdown>
                                                        </div>


                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Job Requisition ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" [options]="rIdArray"
                                                                [(ngModel)]="advanceSearch.jobRequisitionId"
                                                                name="jobRequisitionId"
                                                                [ngModelOptions]="{standlone:true}"
                                                                aria-describedby="emailHelp" optionlabel="label"
                                                                placeholder="Enter Requisition ID">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>




                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" style="
                                                margin-left: -29%;">
                                                <button _ngcontent-cta-c128="" type="submit"
                                                    class="btn btn-secondary submit-btn"
                                                    (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                                <button type="button" class="btn btn-danger ml-2"
                                                    (click)="reset()">Reset</button>
                                            </div>
                                        </div> -->
                                        <br>
                                        <div class="w3-card-4 classCard">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Approval List
                                                    </h4>

                                                </div>
                                            </header>

                                            <div>

                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="approvalId" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Application ID <p-sortIcon
                                                                                        field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="name">
                                                                                <div style="color: white;">Status ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="category">
                                                                                <div style="color: white;">Applying
                                                                                    Party ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="quantity">
                                                                                <div style="color: white;">Application
                                                                                    Date
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="quantity">
                                                                                <div style="color: white;">Job
                                                                                    Requisition ID
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="price">
                                                                                <div style="color: white;">Approver
                                                                                    Party <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="price">
                                                                                <div style="color: white;">Action</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td>
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                class="account-button"
                                                                                        (click)="detailPage(product.applicationId)">
                                                                                        {{product.applicationId}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td>{{product.statusId}}</td>

                                                                            <td>{{product.applyingPartyId}}</td>
                                                                            <td>{{product.applicationDate | date:
                                                                                'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td>{{product.jobRequisitionId}}</td>
                                                                            <td>{{product.approverPartyId}}</td>
                                                                            <td>
                                                                                <button type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    routerLink="/hr/job-requisition/update-approval"
                                                                                    [queryParams]="{applicationId:product.applicationId,applicationDate:product.applicationDate,approverPartyId:product.approverPartyId,jobRequisitionId:product.jobRequisitionId,statusId:product.statusId}" 
                                                                                  
                                                                                  >Update</button>
   </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Approval</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                               
                                <li  [ngClass]="activeCategory==2?'active':''"><a>
                                    Update Approval </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateApprovalForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group" style="    text-align: right!important;
                                                    padding-right: 17px!important;
                                                    margin-left: -2%; ">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                                        font-size: 13px!important; font-weight:600!important;">Application Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" disabled placeholder="Application Id"
                                                         formControlName="applicationId">
                                                    </div>
                                                    <div class="col-lg-2 form-group" style="    text-align: right!important;
                                                    padding-right: 17px!important;
                                                     ">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                                        font-size: 13px!important; font-weight:600!important;">Approver Party Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text"  class="form-control" disabled
                                                        placeholder="Approver Party Id" formControlName="approverPartyId"
                                                      >
         
        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group " style="    text-align: right!important;
                                                    padding-right: 17px!important;
                                                    margin-left: -2%; ">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                                        font-size: 13px!important; font-weight:600!important;">Application Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" disabled
                                                        placeholder="Application Date" formControlName="applicationDate"
                                                    >
                                                    </div>
                                                    <div class="col-lg-2 form-group" style="    text-align: right!important;
                                                    padding-right: 17px!important;
                                                    ">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                                        font-size: 13px!important; font-weight:600!important;">Job Requisition Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"  disabled
                                                        placeholder="Job Requisition Id" formControlName="jobRequisitionId"
                                                     >
         
        
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group" style="    text-align: right!important;
                                                    padding-right: 17px!important;
                                                    margin-left: -2%; ">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                                        font-size: 13px!important; font-weight:600!important;">Status <span
                                                            class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Status" filter="true" [options]="statusIds"
                                                            formControlName="statusId">
                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!updateApprovalForm.controls.statusId.invalid && !!updateApprovalForm.controls.statusId.touched">Status
                                                            is required.</small>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                   
                                                </div>
                                            </div>
                                           
                                        
                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 22%;">
                                        <button type="submit" (click)="update()"
                                            class="btn btn-secondary submit-btn">Update</button>
                                      
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>