<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey breadcrumbb" (click)="back()">Shipment </span>
                <span class="color-black pl-1"> > Create New</span>
                <button type="submit" (click)="cancel()" class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey"> 
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li  [ngClass]="activeCategory==2?'active':''" ><a>
                        Create New Shipment</a></li>

                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="newWarehouseShipment">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Status ID<span style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="StatusIdList" optionLabel="description"
                                                    placeholder="Select Status ID" filter="true"
                                                    formControlName="statusId"></p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Primary Order ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="OrderIdsList" optionLabel="orderId"
                                                placeholder="Select Primary Order ID" filter="true"
                                                formControlName="primaryOrderId"></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Primary Return ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Primary Return ID"
                                                formControlName="primaryReturnId">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Primary Ship Group Seq ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Primary Ship Group Seq ID"
                                                formControlName="primaryShipGroupSeqId">
                                            </div>
                                        </div>
                                    </div>
                               
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Picklist Bin</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Picklist Bin"
                                                formControlName="picklistBinId">
                                        </div>
                                          
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Estimated Ready Date</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="date" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" 
                                            formControlName="estimatedReadyDate">
                                    </div>
                                    </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Estimated Ship Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                formControlName="estimatedShipDate">
                                        </div>
                                          
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Estimated Arrival Date</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="date" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" 
                                            formControlName="estimatedArrivalDate">
                                    </div>
                                    </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Latest Cancel Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                formControlName="latestCancelDate">
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Estimated Ship Cost</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="text" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Estimated Ship Cost"
                                            formControlName="estimatedShipCost">
                                    </div>
                                    </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Currency Uom ID<span style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="getCurrencyList" optionLabel="uomId"
                                                placeholder="Select Currency" filter="true"
                                                formControlName="currencyUomId" placeholder="Currency Uom ID"></p-dropdown>
                                        </div>

                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Handling Instructions</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Handling Instructions"
                                            formControlName="handlingInstructions">
                                    </div>
                                    </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Origin Facility</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="facilities" optionLabel="label" placeholder="Select Origin Facility"
                                                filter="true" formControlName="originFacilityId"></p-dropdown>
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Destination Facility</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown [options]="facilities" optionLabel="label"
                                            placeholder="Select Destination Facility" filter="true"
                                            formControlName="originFacility"></p-dropdown>
                                    </div>
                                    </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Origin Postal Address ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Origin Postal Address ID"
                                                formControlName="adress">
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Origin Phone Number ID</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Origin Phone Number ID"
                                            formControlName="originTelecomNumberId">
                                    </div>
                                    </div>
                                    </div>
                                
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Destination Postal Address ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Destination Postal Address ID"
                                                formControlName="destinationFacilityId">
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Destination Phone Number ID</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Destination Phone Number ID"
                                            formControlName="destinationTelecomNumberId">
                                    </div>
                                    </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">To Party</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="personsAndPartyGroupsList" optionLabel="partyId"
                                                filter="true" placeholder="Select To Party" formControlName="partyIdTo">
                                            </p-dropdown>
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">From Party</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown [options]="personsAndPartyGroupsList" optionLabel="partyId"
                                            filter="true" placeholder="Select From Party" formControlName="partyIdFrom">
                                        </p-dropdown>
                                    </div>
                                    </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Additional Shipping Charge</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Additional Shipping Charge"
                                                formControlName="additionalShippingCharge">
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Addtl Shipping Charge Desc</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Addtl Shipping Charge Desc"
                                            formControlName="addtlShippingChargeDesc">
                                    </div>
                                    </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Event Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"  formControlName="eventDate">
                                        </div>
                                         
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Shipment type ID<span style="color:red">*</span></label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown [options]="ShipmentTypeIdList" optionLabel="shipmentTypeId"
                                            filter="true" placeholder="Select Shipment type ID" formControlName="shipmentTypeId">
                                        </p-dropdown>
                                    </div>
                                    </div>
                                    </div>
                                  
                                  
                                </div>
                                <div class="main-submit-button" style="margin-right: 21%;">
                                    <button type="submit" (click)="submit()"
                                        class="btn btn-secondary submit-btn update-button">Submit</button>
                                   
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                </div>
            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 