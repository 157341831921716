<div class="container-fluid main-container-wrapper">
    <div class="row">

        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">MRP</span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
          
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                  <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                      <li [ngClass]="activeCategoryMain==1?'active':''" (click)="RunMRP()"><a>
                        Run MRP</a></li>
                      <li [ngClass]="activeCategoryMain==2?'active':''" (click)="MRPLog()"><a>
                        MRP Log</a></li>
                       
                     
                  </ul>
              </div>
            <div class=" bg-white color-grey" style="    padding: 15px 15px;">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''"  ><a>
                        Add Run MRP

                    </a></li>
                 
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createRunMrp">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRight"  >
                                                <label for="exampleInputEmail1">
                                                    Mrp Name</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Mrp Name"
                                            formControlName="MrpName" >

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Facility Group<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Facility Group" filter="true"
                                            formControlName="FacilityGroup"
                                            [options]="getFacilityGroupArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRight"  >
                                                <label for="exampleInputEmail1">
                                                    Facility<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown  placeholder="Enter Facility" filter="true"
                                            formControlName="Facility"
                                            [options]="MrpFacilitiesArray" optionlabel="label" >
                                                            
                                            </p-dropdown>

                                            </div>
                                            
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Default Years Offset</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Default Years Offset"
                                            formControlName="DefaultYearsOffset" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 59%;">
                               
                                <button type="submit" (click)="onSubmit()"    class="btn btn-secondary submit-btn">Save</button>
                             
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
