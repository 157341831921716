import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-receivable-sale-invoice',
  templateUrl: './receivable-sale-invoice.component.html',
  styleUrls: ['./receivable-sale-invoice.component.css']
})
export class ReceivableSaleInvoiceComponent implements OnInit {

  constructor(
    readonly router: Router
  ) { }

  ngOnInit(): void {
  }

  createsaleInvoice(){
    this.router.navigateByUrl('/create-sale-invoices');
  }
  serviceItems(){
    this.router.navigateByUrl('/service-items');
  }
  
}
