import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-repair-codes',
  templateUrl: './repair-codes.component.html',
  styleUrls: ['./repair-codes.component.css']
})
export class RepairCodesComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;

  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;

 
  editRepairCodeForm:FormGroup;
  editRepairCategoryForm:FormGroup;
  addRepairCategoryForm:FormGroup
  addRepairCodeForm:FormGroup;
  repairCodeAll: any;
  repairCodeById: any;

  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 
  {
   this.editRepairCodeForm = this._FormBuilder.group({
    displayAs:"",
      
    });

  this.editRepairCategoryForm = this._FormBuilder.group({
    displayAs:"",
      
    });

  this.addRepairCodeForm = this._FormBuilder.group({
    displayAs:"",
      
    });

  this.addRepairCategoryForm = this._FormBuilder.group({
    displayAs:"",
      
    });



   
  }


  ngOnInit(): void {
    this.getFsmRepair();
   }
   toSetRepairCode(repairCategoriesId) {
     this._Router.navigate(["/fsm/fsmSetting/reapir-codes/detail-repair-category"], 
     { queryParams: { repairCategoriesId: repairCategoriesId } })
   }
   deleteRepair(product) {
     Swal.fire({
       title: 'Are you sure?',
       text: 'You will not be able to recover this data.!',
       icon: 'warning',
       showCancelButton: true,
       confirmButtonText: 'Yes, delete it!',
       cancelButtonText: 'No, keep it'
     }).then((result) => {
       if (result.value) {
         const req = {
           "repairCategoriesId": product.repairCategoriesId
         }
         this.myContactsService.deleteRepair(req).subscribe(res => {
           this.getFsmRepair();
         })
         Swal.fire(
           'Deleted!',
           'Deleted Successfully.',
           'success'
         )
 
       } else if (result.dismiss === Swal.DismissReason.cancel) {
         Swal.fire(
           'Cancelled',
           'Your data is safe :)',
           'error'
         )
       }
     })
   }
   getFsmRepairById(product) {
     this.spinner.show();
     this.myContactsService.getFsmRepairById(product.repairCategoriesId).subscribe(res => {
       this.repairCodeById = res.data[0].getFsmRepair[0];
       this.spinner.hide();
       setTimeout(() => {
         const formValue = this.editRepairCodeForm;
         formValue.patchValue({
           displayAs: this.repairCodeById.repairCategories,
          
         })
 
       }, 1000);
     })
   }
   getFsmRepair() {
     this.spinner.show();
     this.myContactsService.getFsmRepair().subscribe(res => {
       this.repairCodeAll = res.data[0].getFsmRepair;
       this.spinner.hide();
     })
   }
   createRepair() {
     this.spinner.show();
 
     const requestData = {
      
         "repairCategories": this.addRepairCodeForm.value.displayAs,
         "repairCategoriesId": ""
       }
 
     this.myContactsService.createRepair(requestData).subscribe((res: any) => {
       if (res.success) {
         this.toastr.success("Created Successfully");
         this.spinner.hide();
         this.closebutton1.nativeElement.click();
         this.addRepairCodeForm.reset();
         this.getFsmRepair();
       }
     }, (err) => {
       this.spinner.hide();
       if (err.error.errors) {
         for (const value of err.error.errors) {
           this.toastr.error(value.fieldName + ' ' + value.fieldError);
         }
       }
       else {
         this.toastr.error(err.error.message);
       }
     });
   }
   updateRepair() {
     this.spinner.show();
     const requestData = {
      
       "repairCategories": this.editRepairCodeForm.value.displayAs,
       "repairCategoriesId": this.repairCodeById.repairCategoriesId
     }
 
     this.myContactsService.updateRepair(requestData).subscribe((res: any) => {
       if (res.success) {
         this.toastr.success("Updated Successfully");
         this.spinner.hide();
         this.closebutton2.nativeElement.click();
         this.editRepairCodeForm.reset();
         this.getFsmRepair();
       }
     }, (err) => {
       this.spinner.hide();
       if (err.error.errors) {
         for (const value of err.error.errors) {
           this.toastr.error(value.fieldName + ' ' + value.fieldError);
         }
       }
       else {
         this.toastr.error(err.error.message);
       }
     });
   }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  

  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
