import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from './contacts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateContactService } from 'src/app/crm/contacts/create-contacts/reactive-form-service/create-contact-service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
import { Location } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;

  public cols: any[];

  total=0;
  rowNumber=0;
  pageSize= 100;
  rows = 50;
  pageNo: number = 1
  leadsLists: any;
  contactMechId= '';
  firstNameSearchType = 'Contains';
  firstName = '';
  lastName = '';
  lastNameSearchType = 'Contains';
  partyId = '';
  partyIdSearchType = 'Contains';

  opportunityIdArray: any = [];
  getContacts = [];
  relatedCompany: any;

  advanceSearch: {
    contactMechId: string;
    firstName: string;
    firstNameSearchType: string;
    lastName: string;
    lastNameSearchType: string;
    partyId: string;
    partyIdSearchType: string;
  };
  createUpdate: any;


  address1: string;
  address2: string;
  areaCode: string;
  city: string;
  contactListId: string;
  contactNumber: string;
  countryCode: string;
  countryGeoId: string;
  emailAddress: string;
  extension: string;
  postalCode: string;
  stateProvinceGeoId: string;
  suffix: string;
  token: any;
  countryList: any;
  country: any = [];
  array: any = [];
  stateArray: any = [];
  public stateData: any = []
  str = '';
  selectedState: any;
  countryId: any;
  updateData: any = [];
  show: boolean = false;
  partyIds: any;
  loading = true;
  contactList: any;
  contactListArray: any = [];
  gender: any[] = [];
  crmStatus: any;
  crmArray: any = [];
  activeCategory = 2;
  public selectCountryGeoId = '';

  constructor(
    readonly contactService: ContactService,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    public createContact: CreateContactsService,
    readonly activatedRoute: ActivatedRoute, 
    readonly toastr: ToastrService,
    public createContactService: CreateContactService,
    private _location: Location,

  ) {
  }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.gender.push({
      "label": "Male",
      "value": "M"
    },
      {
        "label": "Female",
        "value": "F"
      })
      console.log(this.gender)
    this.getCountryList();

    this.createContactService.create_contact_form = null;
    this.createContactService.preparePresonalInfoForm(this.createContactService.create_contact_form);
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params["updateId"];
    })

    this.getContactList();

    if (this.partyId !== null && this.partyId !== undefined) {
      this.getContactDetail();
      this.show = true;
    }
    else {
      this.show = false;
    }


    this.getStatusCrm();
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });


    

    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.advanceSearch = {
      contactMechId: '',
      firstName: '',
      firstNameSearchType: 'Contains',
      lastName: '',
      lastNameSearchType: 'Contains',
      partyId: '',
      partyIdSearchType: 'Contains'
    };
    this.getSearchedContacts();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }
  

  onLastName(event): void {
    this.lastNameSearchType = event;
  }
  onFirstName(event): void {
    this.firstNameSearchType = event;
  }
  onPartyId(event): void {
    this.partyIdSearchType = event;
  }

  onStatusChange(event) {
    this.partyIdSearchType = event;
  }

  onNameChange(event) {
    this.firstNameSearchType = event;
  }

  onLastNameChange(event) {
    this.lastNameSearchType = event;
  }

  resetFilter(): void {
    this.spinner.show();
    this.advanceSearch = {
      contactMechId: '',
      firstName: '',
      firstNameSearchType: 'Contains',
      lastName: '',
      lastNameSearchType: 'Contains',
      partyId: '',
      partyIdSearchType: 'Contains'
    };
    this.spinner.hide();
  }
 
  customSort(event: SortEvent) {
   
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
    
  }

  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.contactService.getSearchedContacts(this.advanceSearch)
      .subscribe(data => {
        if (data.success) {
          if (isPopUp) {
            $('#exampleModal,#exampleModal2').modal('hide');
          }
          this.spinner.hide();
          this.getContacts = data.data;
        
        } else {
          this.spinner.hide();
          this._ToastrService.error(data.errors[0].fieldError);
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      });
     
  }

  contactsDetail(contactId) {
    this.router.navigate(['/crm/my-contacts/details-contact'], { queryParams: { id: contactId } });
  }

  getSearchedContacts() {
    this.spinner.show();
    let req = {
      'contactMechId': this.contactMechId ? this.contactMechId : '',
      'firstName': this.firstName ? this.firstName : '',
      'firstNameSearchType': this.firstNameSearchType ? this.firstNameSearchType : '',
      'lastName': this.lastName ? this.lastName : '',
      'lastNameSearchType': this.lastNameSearchType ? this.lastNameSearchType : '',
      'partyId': this.partyId ? this.partyId : '',
      'partyIdSearchType': this.partyIdSearchType ? this.partyIdSearchType : ''
    }
  
    this.contactService.getSearchedContacts(req).subscribe(res => {
     
      this.getContacts = res.data;
      this.spinner.hide();
    })
   
  }

  export() {
    this.spinner.show();
    this.contactService.exportContacts().subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'contacts.xlsx';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })
   
  }
 changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  getStatusCrm() {
    this.spinner.show();
    this.createContact.getStatusCrm().subscribe(res => {
      this.crmStatus = res.data;
      this.spinner.hide();
      for (const value of this.crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
    
  }


  getContactDetail() {
    this.spinner.show();
    this.createContact.getContacts(this.partyId).subscribe(res => {
      this.spinner.hide();
      const form = this.createContactService.create_contact_form;
      this.countryId = res.data.address.countryId;
      form.patchValue({
        address1: res.data.address.address1,
        address2: res.data.address.address2,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        suffix: res.data.suffix,
        emailAddress: res.data.email.infoString,
        contactNumber: res.data.telenumber.contactNumber,
        areaCode: res.data.telenumber.areaCode,
        countryCode: res.data.telenumber.countryCode,
        countryGeoId: res.data.address.countryId.trim(),
        comments: res.data.comments,
        gender: res.data.gender,
        statusId: res.data.statusId,
        externalId: res.data.externalId,
        city: res.data.address.city,
        postalCode: res.data.address.postalCode,
        stateProvinceGeoId: res.data.stateId.trim()
      });
     
    })
   
  }
  getCountryList() {
    this.spinner.show();
    this.createContact.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })
      })
    })
   
  }
  getContactList() {
    this.spinner.show();
    this.createContact.getContactList().subscribe(res => {
      this.contactList = res.data;
      this.spinner.hide();
      for (const value of this.contactList) {
        this.contactListArray.push({
          label: value.description,
          value: value.contactListId
        })
      }
    })
   
  }

  onCountryChange(event) {
    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }
  onStateChange(event) {
    this.stateProvinceGeoId = event
  }
  getStateList() {
    this.spinner.show();
    this.createContact.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
   
  }
  onSubmit() {
    this.spinner.show();
    this.createContactService.create_contact_form.markAllAsTouched();
    const formValues = { ...this.createContactService.create_contact_form.value };
    this.createContact.createContact(formValues).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.router.navigate(['/crm/my-contacts'])
      } else if (res.success == false) {
        this.toastr.error(res.data.message);
        this.spinner.hide();
      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {
          this.spinner.hide();
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {
          this.spinner.hide();
          this.toastr.error(err.error.message);
        }
      }
    })
   
  }
  onUpdate() {
    this.spinner.show();
    const formValues = { ...this.createContactService.create_contact_form.value };
    this.createContact.updateContacts(formValues, this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Form Updated Successfully");
        this.spinner.hide();
        this.router.navigate(['/crm/my-contacts'])
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.debugMessage);
    })
  
  }
  generalInformation(firstName,lastName){
  
    if(firstName && lastName){
      this.activeCategory=3
    }
    else{
      this.toastr.error("First Name and Last Name is required");
    }
  }
  addressInfo(address,country,city,state,postal){
    if(address && country  && city  && state && postal){
      this.activeCategory=4
    }
    else{
      this.toastr.error("Address1,Country,City,State and Postal Code is required");
    }
  }
  phoneInformation(phone){
    if(phone){
      this.activeCategory=5
    }
    else{
      this.toastr.error("Phone Number is required");
    }
  }
 
  emailInformation(email){
    if(email){
      this.activeCategory=8
    }
    else{
      this.toastr.error("Email is required");
    }
  }
  generalInfo(firstName,lastName){

    if(firstName && lastName){
      this.activeCategory=3
    }
    else{
      this.toastr.error("Fill General Information.");
    }
  }
  addressInformation(address,country,city,state,postal){
    if(address && country  && city  && state && postal){
      this.activeCategory=4
    }
    else{
      this.toastr.error("Fill Address.");
    }
  }
  phoneInfo(phone){
    if(phone){
      this.activeCategory=5
    }
    else{
      this.toastr.error("Fill Phone Information.");
    }
  }
  emailInformationAdded(email){
    if(email){
      this.activeCategory=8
    }
    else{
      this.toastr.error("Fill Email Information.");
    }
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
