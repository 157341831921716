import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-service-teams',
  templateUrl: './service-teams.component.html',
  styleUrls: ['./service-teams.component.css']
})
export class ServiceTeamsComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;

  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;

  addServiceTeamForm:FormGroup;
  editServiceTeamMemberForm:FormGroup;
  editServiceTeamForm:FormGroup;
  addServiceTeamMemberForm:FormGroup;
  editAreaServicedForm:FormGroup;
  editTeamProfileForm:FormGroup;
  fsmWorkZoneService: any;
  fsmWorkZoneServiceById: any;
  fsmWorkZone: any;
  workZoneId: any;
  workZoneIdArray: any[]=[];


  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 
  {
    this.addServiceTeamForm = this._FormBuilder.group({
      name:"",
      areaServiced:"",
      members:"",
      WorkZoneId :""
    });
    this.editServiceTeamForm = this._FormBuilder.group({
      name:"",
      areaServiced:"",
      members:"",
      WorkZoneId :""
    });

    this.editServiceTeamMemberForm = this._FormBuilder.group({
      displayAs:"",
      role:"",
      phone:"",
    });

    this.addServiceTeamMemberForm = this._FormBuilder.group({
      displayAs:"",
      role:"",
      phone:"",
    });
   
  }

  ngOnInit(): void {
    this.getFsmWorkZoneService();
    this.getWorkZoneId();
    
  }
  detailPage(workZoneServiceId,name,areaServiced){
    this._Router.navigate(['/fsm/fsmSetting/service-teams/default-service-team'],
    { queryParams: { workZoneServiceId:workZoneServiceId, name:name, areaServiced:areaServiced} })
  }
  getWorkZoneId() {
    this.spinner.show();
    this.myContactsService.getWorkZoneId().subscribe(res => {
      this.workZoneId = res.data[0].getFsmWorkZone;
      this.spinner.hide();
      for (const value of this.workZoneId) {
        this.workZoneIdArray.push({
          label: value.workZoneId,
          value: value.workZoneId
        })
      }
    })
   
  }
  deleteFsmWorkZoneService(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "workZoneServiceId": product.workZoneServiceId,
        }
        this.myContactsService.deleteFsmWorkZoneService(req).subscribe(res => {
          this.getFsmWorkZoneService();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getFsmWorkZoneServiceById(product) {
    this.spinner.show();
    this.myContactsService.getFsmWorkZoneServiceById(product.workZoneServiceId).subscribe(res => {
      this.fsmWorkZoneServiceById = res.data[0].getFsmWorkZoneServiceById[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.editServiceTeamForm;
        formValue.patchValue({
          name: this.fsmWorkZoneServiceById.name   ,
          areaServiced: this.fsmWorkZoneServiceById.areaServiced  ,
          members: this.fsmWorkZoneServiceById.members
        })
  
      }, 2000);
    })
  }
  getFsmWorkZoneService() {
    this.spinner.show();
    this.myContactsService.getFsmWorkZoneService().subscribe(res => {
      this.fsmWorkZoneService = res.data[0].getFsmWorkZoneService;
      this.spinner.hide();
    })
  }

  cancelSubmit(){
    this._Router.navigate(["/fsm/fsmSetting/work-order"])
  }

  createFsmWorkZoneService(){
    this.spinner.show();
    const requestData = {
      "areaServiced": this.addServiceTeamForm.value.areaServiced,
      "members":this.addServiceTeamForm.value.members,
      "name": this.addServiceTeamForm.value.name,
      "workZoneId": this.addServiceTeamForm.value.WorkZoneId,
      "workZoneServiceId": ""
    }
    this.myContactsService.createFsmWorkZoneService(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addServiceTeamForm.reset();
        this.getFsmWorkZoneService();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateFsmWorkZoneService(){
    this.spinner.show();
    const requestData = {
      "areaServiced": this.editServiceTeamForm.value.areaServiced,
      "members":this.editServiceTeamForm.value.members,
      "name": this.editServiceTeamForm.value.name,
      "workZoneId": this.editServiceTeamForm.value.WorkZoneId ,
      "workZoneServiceId":this.fsmWorkZoneServiceById.workZoneServiceId
    }
    this.myContactsService.updateFsmWorkZoneService(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.editServiceTeamForm.reset();
        this.getFsmWorkZoneService();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}