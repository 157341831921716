import { Component, OnInit } from '@angular/core';
import {EditContactFormService} from "./edit-contact-group-service";
import {EditAccountServices} from "./edit-contact-group.service";
import {ActivatedRoute} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-contact-group',
  templateUrl: './edit-contact-group.component.html',
  styleUrls: ['./edit-contact-group.component.css']
})
export class EditContactGroupComponent implements OnInit {
  partyId: any;
  partyIdValue:any;
  getCurrencyList: any;
  currencyArray: any=[];
  crmStatus: any;
  crmArray: any=[];
  groupName;
  officeSiteName;
  annualRevenue;
  numEmployees;
  logoImageUrl;
  description;
  externalId;
  statusId;
  groupLocalName;
  tickerSymbol;
  comments;
  preferredCurrencyUomId;

  constructor(public editContactFormService:EditContactFormService,
    readonly editAccountServices:EditAccountServices,
    readonly activatedRoute:ActivatedRoute,
    readonly toastr:ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly router:Router
    ) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.partyId = params["partyId"]
    });
    if(this.partyId){
      this.partyIdValue=this.partyId;
    }
    this.getCurrency();
    this.getCrmStatus();
    this.getAccountDataByPartyId();
    this.editContactFormService.edit_group_form=null;
    this.editContactFormService.editContactGroup(this.editContactFormService.edit_group_form);
   
  }
  getAccountDataByPartyId() {
    this.spinner.show();
    this.editAccountServices.getAccountByPartyId(this.partyId).subscribe(res => {
     

      const info=res.data;
      this.groupName=info.groupName
      this.groupLocalName=info.groupNameLocal
      this.tickerSymbol=info.tickerSymbol
      this.officeSiteName=info.officeSiteName
      this.annualRevenue=info.annualRevenue
      this.numEmployees=info.numEmployees
      this.logoImageUrl=info.logoImageUrl
      this.description=info.description
      this.externalId=info.externalId
      this.statusId=info.statusId,
      this.comments=info.relatedContacts[0].comments
      this.preferredCurrencyUomId= info.preferredCurrencyUomId
    })
    this.spinner.hide();
  }
  onUpdate(){
    this.spinner.show();
    const formData = {...this.editContactFormService.edit_group_form.value};
    this.editAccountServices.updatePartyGroup(this.partyId,formData).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
       this.router.navigate(['/crm/accounts/account-details'],{queryParams:{partyId:this.partyId}})
      }    },
      (err)=> {
       
        for(const value of err.error.errors){
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      })
      this.spinner.hide();
  }
  onCancel(){
    this.router.navigate(['/crm/accounts/account-details'],{queryParams:{partyId:this.partyId}})

  }
  getCurrency() {
    this.spinner.show();
    this.editAccountServices.getCurrency().subscribe(res=> {
      this.getCurrencyList = res.data.currencyList;
     this.getCurrencyList.forEach(currencyList=> {
       this.currencyArray.push({
         label:currencyList.description + "-" +  currencyList.uomId,
         value:currencyList.uomId
  
  
       })
     })
      })
      this.spinner.hide();
    }
    getCrmStatus(){
      this.spinner.show();
      this.editAccountServices.getCrmStatus().subscribe(res=> {
        this.crmStatus = res.data;
        for(const value of this.crmStatus){
          this.crmArray.push({
            label:value.description,
            value:value.statusId
          })
        }
      })
      this.spinner.hide();
    }
}
