import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../accounts/accounts.service';
import { CreateAccountFormService } from '../create-account/create-account-service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../../header/header.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { EditAccountServices } from 'src/app/editContactGroup/edit-contact-group/edit-contact-group.service';
import { Location } from '@angular/common';


declare var $: any;
@Component({
  selector: 'app-new-accounts',
  templateUrl: './new-accounts.component.html',
  styleUrls: ['./new-accounts.component.css']
})
export class NewAccountsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategories=1;
  products3: any;
  partyIdData='';
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  search = '';
  rows = 50;
  accountsList: any;
  public groupName = '';
  public groupNameSearchType: any = 'Contains';
  public partyId: string = '';
  public partyIdSearchType: any = 'Contains';
  contactMechTypeId = '';
  opportunityIdArray: any = [];
  valueData: any;
  relatedCompany;
  address1: any;
  address2: any;
  areaCode: any;
  city: any;
  activeCategory = 2;
  contactNumber: any;
  countryCode: any;
  countryGeoId: any;
  emailAddress: any;
  extension: any;
  numEmployees: any;
  officeSiteName: any;
  postalCode: any;
  stateProvinceGeoId: any;
  show = false;
  array: any = [];
  stateData: any;
  createContact: any;
  countryId: any;
  stateArray: any = [];
  accountForm: FormGroup;
  additionalDetailForm: FormGroup;
  jobInfoForm: FormGroup;
  customerType = [{

    label: 'Individual Customer',
    value: 'IndividualCustomer'
  },
  {
    label: 'Business Customer',
    value: 'BusinessCustomer'
  },
  ]
  salutationArray = [
    {
      label: 'Mr.',
      value: 'Mr.'
    },
    {
      label: 'Mrs.',
      value: 'Mrs.'
    },
    {
      label: 'Ms.',
      value: 'Ms.'
    }
  ];
  currencyList: any[];
  statusList: any[];
  id: any;
  editMode = false;
  jobInfo: any;
  additionalInfo: any;


  constructor(
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly HeaderService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly AccountsService: AccountsService,
    readonly _GlobalResourceService: GlobalResourceService,
    public createAccountFormService: CreateAccountFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly _CRMAccountService: CRMAccountService,
    readonly _EditAccountServices: EditAccountServices,
    private _location: Location,
  ) {
    this.jobInfoForm = this._FormBuilder.group({
      description: [''],
      endDate: [''],
      jobStatus: [''],
      jobType: [''],
      partyId: [''],
      productType: [''],
      projectedEndDate: [''],
      serviceType: [''],
      startDate: ['']
    });
    this.additionalDetailForm = this._FormBuilder.group({
      infoType: [''],
      partyId: [''],
      prefeferredSendMethod: [''],
      priceLevel: [''],
      rep: [''],
      resaleNo: [''],
      terms: ['']
    });
    this.accountForm = this._FormBuilder.group({
      address1: [''],
      address2: [''],
      areaCode: [''],
      city: [''],
      contactNumber: [''],
      countryCode: [''],
      countryGeoId: [''],
      emailAddress: [''],
      extension: [''],
      groupName: ['', [Validators.required]],
      numEmployees: [''],
      officeSiteName: [''],
      postalCode: [''],
      stateProvinceGeoId: [''],
      firstName: [''],
      lastName: [''],
      title: [''],
      customerType: [''],
      annualRevenue: [''],
      description: [''],
      externalId: [''],
      logoImageUrl: [''],
      preferredCurrencyUomId: [''],
      statusId: [''],
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getCountryList();

    this.accountForm.controls.countryGeoId.valueChanges.subscribe(data => {
      if (data) {
        this.countryId = data;
        this.getStateList();
      }
    });

    this.createAccountFormService.create_account_form = null;
    this.createAccountFormService.prepareAccountForm(this.createAccountFormService.create_account_form);
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
    })
    if (this.partyId) {
      this.show = true;
      this.editMode = true;
      this.getData();
      this._CRMAccountService.getAccountById(this.partyId)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.additionalInfo = data.data.additionalInformation;
            this.additionalDetailForm.patchValue({
              ...data.data.additionalInformation
            });
          }
        });
      this._CRMAccountService.getAccountById(this.partyId)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.jobInfo = data.data.jobInformation;
            this.jobInfoForm.patchValue({
              ...data.data.jobInformation,
              startDate: data.data.jobInformation.startDate ? moment(data.data.jobInformation.startDate).format('YYYY-MM-DD') : '',
              endDate: data.data.jobInformation.endDate ? moment(data.data.jobInformation.endDate).format('YYYY-MM-DD') : '',
              projectedEndDate: data.data.jobInformation.projectedEndDate ? moment(data.data.jobInformation.projectedEndDate).format('YYYY-MM-DD') : ''
            });

          }

        });
    }
    else {
      this.show = false;
    }
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });

    this.getCrmStatus();
    this.getCurrency();



    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Begins With',
        text: 'Begins With'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      }];




    this.getAccountList();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }

  resetFilter() {
    this.spinner.show();
    this.groupName = '';
    this.groupNameSearchType = 'Contains';
    this.partyId = '';
    this.partyIdData='';
    this.partyIdSearchType = 'Contains';
    this.getAccountList();
    this.spinner.hide();
  }
  cancelSubmit() {
    this.router.navigate(['/crm/accounts'])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  accountDetails(Id) {
    this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: Id } });
  }
  onGroupName(event) {
    this.groupNameSearchType = event;
  }
  onPartyId(event) {
    this.partyIdSearchType = event;
  }
  toForecast(){
    
    this.router.navigate(['/crm/sales-forecast']);

  }
  toEmails(){
    
    this.router.navigate(['/communication-overview/Dashboard']);

  }
  toDocuments(){
    
    this.router.navigate(['crm/sales-documents']);

  }
  
  toTasks(){
    this.router.navigate(['/crm/Tasks']);
  }
  toLead()
  {
    this.router.navigate(['/crm/leads']);

  }
  toAccount()
  {
    this.router.navigate(['/crm/accounts']);
  }
  toContact()
  {
    this.router.navigate(['/crm/my-contacts']);

  }
  toOpportunity()
  {
    this.router.navigate(['/crm/opportunities']);

  }
  
  toCases(){
    this.router.navigate(['/crm/Cases']);
  }
  toActivities()
  {
    this.router.navigate(['/crm/events']);

  }
  toQuotes()
  {
    this.router.navigate(['/order/quotations']);

  }
  toSalesOrder()
  {
    this.router.navigate(['/order/create-sales-order']);

  }
  toCalendar(){
    
    this.router.navigate(['crm/sales-calendar']);

  }
  toNotes(){
    this.router.navigate(['/crm/sales-notes']);
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  changeactiveCategoryAction(tab: number) {
    this.activeCategories = tab;
  }

  

  getAccountList(): void {
    this.spinner.show();
    const allReq = {
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      all: true
    };
    const searchAccountForm = {
      contactMechTypeId: this.contactMechTypeId,
      groupName: this.groupName,
      groupNameSearchType: this.groupNameSearchType,
      partyId: this.partyIdData,
      partyIdSearchType: this.partyIdSearchType
    };


    this.accountsService.accountList(allReq, searchAccountForm).subscribe(res => {
      this.spinner.hide();
      this.accountsList = res.data;

    });

  }
  updateJob(): void {
    this.spinner.show();
    this._CRMAccountService.updateJobInformation({
      ...this.jobInfo, ...this.jobInfoForm.value, ...{
        partyId: this.partyId,
        startDate: this.jobInfoForm.value.startDate ? moment(this.jobInfoForm.value.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
        endDate: this.jobInfoForm.value.endDate ? moment(this.jobInfoForm.value.endDate).format('YYYY-MM-DD HH:mm:ss') : '',
        projectedEndDate: this.jobInfoForm.value.projectedEndDate ? moment(this.jobInfoForm.value.projectedEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Updated');
          this.closebutton.nativeElement.click();
          this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.partyId } });
          this.spinner.hide();

        }
      });

  }
  submit(): void {
    this.spinner.show();
    this._CRMAccountService.createJobInformation({
      ...this.jobInfoForm.value, ...{
        partyId: this.id,
        startDate: this.jobInfoForm.value.startDate ? moment(this.jobInfoForm.value.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
        endDate: this.jobInfoForm.value.endDate ? moment(this.jobInfoForm.value.endDate).format('YYYY-MM-DD HH:mm:ss') : '',
        projectedEndDate: this.jobInfoForm.value.projectedEndDate ? moment(this.jobInfoForm.value.projectedEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Created');
          this.spinner.hide();
          this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.id } });

        }
      });

  }

  // updateAccount(partyId) {
  //   this.router.navigate(['/crm/accounts/account'], { queryParams: { accounts: partyId } });
  // }

  checkValidation(data): any {
    if (data !== null && data !== undefined) {
      return data;
    } else {
      return '';
    }
  }

  submitAdd(): void {
    this.spinner.show();
    this._CRMAccountService.createAdditionalDetail({
      ...this.additionalDetailForm.value, ...{
        partyId: this.id
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Created');
          this.spinner.hide();
          this.activeCategory = 4;
        }
      });


  }

  updateAccount() {

    this.spinner.show();

    this._CRMAccountService.updateAccount(this.partyId, this.accountForm.value)
      .then(data => {
        if (data.success) {
          this.toastr.success("Customer Updated Successfully");
          this.spinner.hide();
          this.activeCategory = 3;
          // this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId:  this.partyId } });
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    this.spinner.hide();



  }
  
   exportAccount() {
     this.spinner.show();
     this.accountsService.exportAccount().subscribe(res => {
       const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       if (window.navigator && window.navigator.msSaveOrOpenBlob) {
         window.navigator.msSaveOrOpenBlob(blob);
         return;
       }
       const data = window.URL.createObjectURL(blob);
       const link = document.createElement('a');
       link.href = data;
       this.spinner.hide();
      link.download = 'accounts.xlsx';
       link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
       setTimeout(function () {
         window.URL.revokeObjectURL(data);
        link.remove();
       }, 100);
     })

   }

  createAccount() {
    this.spinner.show();
    const formValues = { ...this.createAccountFormService.create_account_form.value };


    const {
      address1,
      address2,
      areaCode,
      city,
      contactNumber,
      countryCode,
      countryGeoId,
      emailAddress,
      extension,
      groupName,
      numEmployees,
      officeSiteName,
      postalCode,
      stateProvinceGeoId,
      firstName,
      lastName,
      title } = this.accountForm.value;
    this._CRMAccountService.createAccount({
      address1,
      address2,
      areaCode,
      city,
      contactNumber,
      countryCode,
      countryGeoId,
      emailAddress,
      extension,
      groupName,
      numEmployees,
      officeSiteName,
      postalCode,
      stateProvinceGeoId,
      firstName,
      lastName,
      title
    })
      .then(data => {
        if (data.success) {
          this.id = data.data.partyId
          this.toastr.success('Customer created successfully');
          this.spinner.hide();
          this.activeCategory = 3;
          //  this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.id } });
          // this.router.navigate(['/crm/accounts'])
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    this.spinner.hide();


  }

  

  getCrmStatus() {
    this.spinner.show();
    this._EditAccountServices.getCrmStatus().subscribe(res => {
      this.spinner.hide();
      this.statusList = res.data.map(value => {
        return {
          label: value.description,
          value: value.statusId
        };
      });
    })

  }

  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.currencyList = data.data.currencyList.map(value => {
            return {
              label: value.description,
              value: value.uomId
            };
          });
        }
      });

  }
  onCountryChange(event) {
    this.stateArray = [];
    this.countryId = event;
    this.getStateList();

  }

  onStateChange(event) {
    this.stateProvinceGeoId = event;
  }

  getStateList() {
    this.spinner.show();
    this.AccountsService.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })

    })


  }
  getCountryList() {
    this.spinner.show();
    this.AccountsService.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0],
          value: element.split(":").pop().trim()
        })

      })

    })
  }

  getData() {
    this.spinner.show();
    this.AccountsService.getAccountById(this.partyId).subscribe(res => {
      this.spinner.hide();
      const form = this.createAccountFormService.create_account_form;
      this.accountForm.patchValue({
        groupName: res.data.groupName !== null ? res.data.groupName : '',
        numEmployees: res.data.numEmployees !== null ? res.data.numEmployees : '',
        officeSiteName: res.data.officeSiteName !== null ? res.data.officeSiteName : '',
        emailAddress: res.data.email.infoString !== null ? res.data.email.infoString : '',
        address1: res.data.address.address1 !== null ? res.data.address.address1 : '',
        address2: res.data.address.address2 !== null ? res.data.address.address2 : '',
        city: res.data.address.city !== null ? res.data.address.city : '',
        postalCode: res.data.address.postalCode !== null ? res.data.address.postalCode : '',
        contactNumber: res.data.telenumber.contactNumber !== null ? res.data.telenumber.contactNumber : '',
        countryCode: res.data.telenumber.countryCode !== null ? res.data.telenumber.countryCode : '',
        areaCode: res.data.telenumber.areaCode !== null ? res.data.telenumber.areaCode : '',
        stateProvinceGeoId: res.data.address.stateId !== null ? res.data.address.stateId : '',
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        title: res.data.title,
        annualRevenue: res.data.annualRevenue,
        description: res.data.description,
        externalId: res.data.externalId,
        logoImageUrl: res.data.logoImageUrl,
        preferredCurrencyUomId: res.data.preferredCurrencyUomId,
        statusId: res.data.statusId,
        countryGeoId: res.data.address.countryId
      });

    })

  }


  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
