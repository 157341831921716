import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2'
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-gl-custom-time-period-list',
  templateUrl: './gl-custom-time-period-list.component.html',
  styleUrls: ['./gl-custom-time-period-list.component.css']
})
export class GlCustomTimePeriodListComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  createTimePeriod: FormGroup;
  show: boolean;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  timePeriodList: any[]=[];
  timeParentSetPeriodList: any[]=[];
  activeCategory=2;
  periodType: any;
  parentPeriodId: any;
  periodTypeArray: any[]=[];
  parentPeriodIdArray: any[]=[];
  actualfromdate: string;
  actualthroughdate: string;
  constructor( 
    readonly datePipe: DatePipe,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _FormBuilder: FormBuilder,
   ) {
    this.createTimePeriod = this._FormBuilder.group({
      fromDate: ['',[Validators.required]],
      isClosed: [''],
      organizationPartyId: ['',[Validators.required]],
      parentPeriodId: [''],
      periodName: [''],
      periodNum: [''],
      periodTypeId: ['',[Validators.required]],
      thruDate: ['',[Validators.required]],

    });
    }
    closed = [{
      label: 'Y',
      value: 'Y',
    },
    {
      label: 'N',
      value: 'N',
    }]
    getChild=[{
      value:'6011'
    }]
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activeCategory=2;
    this.getCustomTimePeriod();

    this.getCustomTimePeriodDrop();
  }
  getCustomTimePeriodDrop() {
    this.spinner.show();
    this.accountsService.getCustomTimePeriodDropdownValue().subscribe((res: any) => {
      this.periodType = res.data.periodType;
      this.parentPeriodId = res.data.parentPeriodId;
      this.spinner.hide();
      for (const value of this.periodType) {
        this.periodTypeArray.push({
          label: value.description,
          value: value.periodTypeId
        })
      }
      for (const value of this.parentPeriodId) {
        this.parentPeriodIdArray.push({
          label:value.organizationPartyId + ':' + value.parentPeriodId + ' ' + value.periodTypeId +':'+ value.periodNum + ' [' + value.customTimePeriodId + ']',
          value: value.customTimePeriodId
        })
      }
  

    })
   
  }
  onSubmit() {
    this.spinner.show();
    const fromActualdate = this.createTimePeriod.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createTimePeriod.get('thruDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "currentCustomTimePeriodId": "","customTimePeriodId": "","findOrganizationPartyId": "",
      "fromDate": this.actualfromdate ,
      "isClosed": this.createTimePeriod.value.isClosed,
      "organizationPartyId": this.createTimePeriod.value.organizationPartyId,
      "parentPeriodId": this.createTimePeriod.value.parentPeriodId,
      "periodName": this.createTimePeriod.value.periodName,
      "periodNum": this.createTimePeriod.value.periodNum,
      "periodTypeId": this.createTimePeriod.value.periodTypeId,
      "thruDate": this.actualthroughdate, "useValues": "true"
    }
    this.accountsService.postCustomTimePeriod(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getCustomTimePeriod();
        this.createTimePeriod.reset(); 
        this.closebutton.nativeElement.click();
        // this.router.navigate(["financial/gl-custom-time-period-list"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  chartAccount(){
    this.router.navigate(["/financial/global-gl-setting-list"])
  }
  timePeriod(){
    this.router.navigate(["/financial/gl-custom-time-period-list"])
  }
  cost(){
    this.router.navigate(["/financial/global-gl-cost"])
  }
  paymentMethod(){
    this.router.navigate(["/financial/gl-payment-method-type"])
  }
  invoiceMethod(){
    this.router.navigate(["/financial/gl-invoice-type-list"])
  }
  rate(){
    this.router.navigate(["/financial/global-gl-rate-list"])
  }
  foreignExc(){
    this.router.navigate(["/financial/gl-foreign-exchange-rate"])
  }
  accountCat(){
    this.router.navigate(["/financial/gl-account-category-list"])
  }
  costCenter(){
    this.router.navigate(["/financial/gl-cost-center"])
  }
  getCustomTimePeriod() {
    this.spinner.show();
    this.accountsService.getCustomTimePeriod(this.finSize).subscribe((res: any) => {
      this.timePeriodList = res.data.childPeriods;
      this.spinner.hide();
      if(res.data.currentCustomTimePeriod[0].customTimePeriodId){
        this.timeParentSetPeriodList = res.data.currentCustomTimePeriod;
      
      }else{
        this.timeParentSetPeriodList=[]
      }
   
    })
  
  }
  setCurrent(id){
    this.spinner.show();
    this.accountsService.getSetCurrentCustomTimePeriod(this.finSize,id).subscribe((res: any) => {
      this.timePeriodList = res.data.childPeriods;
      this.spinner.hide();
      if(res.data.currentCustomTimePeriod[0].customTimePeriodId){
        this.timeParentSetPeriodList = res.data.currentCustomTimePeriod;
      
      }else{
        this.timeParentSetPeriodList=[]
      }
   
    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  // createTimePeriod(){
  //   this.router.navigate(["financial/gl-create-custom-time-period"])
   
  // }
  updateTimePeriod(id){
    this.router.navigate(["financial/gl-create-custom-time-period"],{ queryParams: { customTimePeriodId: id } })
   
  }
  onPeriodUpdate(id){
    this.router.navigate(["financial/gl-update-custom-time-period"],{ queryParams: { customTimePeriodId: id  } })
  }
  
  onDelete(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteCustomTimePeriod(id).subscribe((res:any)=> {
          this.toastr.success("Delete Successfully");
          this.getCustomTimePeriod();
      },(err) => {
        this.toastr.error(err.error.message);
     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
}
ngOnDestroy(): void {
  this.spinner.hide();
    }
}
