<div class="container-fluid main-container-wrapper">
    <div class="create-new-leade">
        <div class="container-fluid">
            <div class="w-100">
                <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                   
                     <span class="color-black pl-1"> > </span>
     
     
                     <span class="d-flex">
     
                         <button type="submit" (click)="homeButton();"
                             class=" btn btn-outline-secondary mr-2">Home</button>
                 
                         <button type="submit" (click)="cancelSubmit();"
                             class="btn btn-danger buttonclass ">Back</button>
                     </span>
                 </div>
                <div class="container-fluid ">
                    <ul class="tabbing-section  tabbing-accordians" style="padding-left: 9px;">
                        <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                                Add Feature Price</a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                Feature Price</a></li>
                        <button type="submit" class="btn btn-danger"  (click)="toBack()" style="margin-left: 70%;
                        margin-top: 5px;">Back</button>
                    </ul>
                </div>
                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                            <div *ngIf="activeCategory==1">
                                <div class="header-tabs">
                                    <h4 class=" common-styling">
                                        Add Feature Price
                                    </h4>
                                </div>
                                <div class="panel-body">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <form [formGroup]="priceForm">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <div class="d-flex flex-column">
                                                            <label for="priceType">Price Type<span
                                                                    class="text-danger">*</span></label>
                                                            <p-dropdown filter="true" [options]="priceType"
                                                                formControlName="productPriceTypeId"
                                                                placeholder="Price Type" filter="true"></p-dropdown>
                                                            <small class="text-danger"
                                                                *ngIf="!!priceForm.controls.productPriceTypeId.invalid && !!priceForm.controls.productPriceTypeId.touched">Price
                                                                Type is required.</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="fromData">From Date</label>
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Through Date">

                                                       
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="thruDate">Through Date</label>
                                                        <input type="date" formControlName="thruDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Through Date">

                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="price">Price <span
                                                                class="text-danger">*</span></label>
                                                        <input type="text" class="form-control" formControlName="price">
                                                        <small class="text-danger"
                                                            *ngIf="!!priceForm.controls.price.invalid && !!priceForm.controls.price.touched">Price
                                                            is required.</small>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <div class="d-flex flex-column">
                                                            <label for="currency">Currency</label>
                                                            <p-dropdown filter="true" [options]="currency"
                                                                formControlName="currencyUomId" placeholder="Currency">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="col-lg-12 col-12 main-submit-button ">
                                            <button *ngIf="this.editMode==false" class="btn btn-secondary submit-btn"
                                                (click)="submit()" type="button">Create</button>&nbsp;
                                            <button *ngIf="this.editMode==true" class="btn btn-secondary submit-btn"
                                                (click)="update()" type="button">Update</button>&nbsp;
                                            <button class="btn btn-danger" (click)="reset()"
                                                type="button">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="activeCategory==2">
                                <div class="header-tabs">
                                    <h4 class=" common-styling">
                                        Feature Price
                                    </h4>
                                </div>
                                <div class="panel-body">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="card own-account-table">
                                            <p-table [value]="priceValue" [paginator]="true" [rows]="rows"
                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                [totalRecords]="total" [scrollable]="true"
                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                            <div class="checkbox-align">
                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                    styleclass="custom-checkbox" binary="true"
                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible">
                                                                            <input type="checkbox" name="undefined"
                                                                                value="undefined">
                                                                        </div>
                                                                        <div role="checkbox"
                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span
                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                </p-checkbox>
                                                                Price Type <p-sortIcon field="code">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                            Price<p-sortIcon field="name">
                                                            </p-sortIcon>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                            Currency<p-sortIcon field="name">
                                                            </p-sortIcon>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                            From Date
                                                            <p-sortIcon field="category"></p-sortIcon>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                            Through Date
                                                            <p-sortIcon field="category"></p-sortIcon>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">Last
                                                            Modified By
                                                            <div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    style="visibility:hidden;" value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">Action
                                                            <div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    style="visibility:hidden;" value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
                                                    <tr>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.productPriceTypeId}}</td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.price }}</td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.currencyUomId }}</td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.fromDate | date :'yyyy-MM-dd HH:mm:ss'}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.thruDate | date :'yyyy-MM-dd HH:mm:ss'}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.lastModifiedDate | date :'yyyy-MM-dd HH:mm:ss'}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            <div>
                                                                <fa-icon  class="account-button" 
                                                                    [icon]="faEdit" (click)="editForm(product)">
                                                                </fa-icon>
                                                                <fa-icon class="text-danger cursor-pointer ml-2"
                                                                    [icon]="faTrash"
                                                                    (click)="delete(product.fromDate,product.currencyUomId,product.productPriceTypeId)">
                                                                </fa-icon>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>