<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
        style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Detail Summary of {{this.visitId}}</span>
        <span>
          <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
          <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
        </span>
      </div>
      <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
          <div class="suppliers-wrapper">
          </div>
        </div>
        <div class="w3-card-4 classCard">
          <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass">
              <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                  Visit Summary</a></li>
            </ul>
          </div>
          <div class=" mt-2" style="overflow-x:clip;">
            <div class="container-fluid">
              <div class="row">
                <form class="w-100">
                  <div class="port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div *ngIf="activeCategory==1">
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="color-black container" *ngIf="VisitorsDetails">
                                <div class="article-container">
                                  <div class="article">
                                    <p class="col-lg-3 col-12">Visitor ID</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">
                                      {{this.VisitorsDetails.visitorId}}</span>
                                  </div>
                                  <div class="article">
                                    <p class="col-lg-3 col-12">VisitID / SessionID</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{ this.VisitorsDetails.visitId}}/{{
                                      this.VisitorsDetails.sessionId}}</span>
                                  </div>

                                  <div class="article">
                                    <p class="col-lg-3 col-12">PartyID/UserLoginID</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{ this.VisitorsDetails.partyId}}/{{
                                      this.VisitorsDetails.userLoginId}}</span>

                                  </div>

                                  <div class="article">
                                    <p class="col-lg-3 col-12">User Created</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{
                                      this.VisitorsDetails.userCreated}}</span>
                                  </div>

                                  <div class="article">
                                    <p class="col-lg-3 col-12">WebApp</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{
                                      this.VisitorsDetails.webappName}}</span>
                                  </div>

                                  <div class="article">
                                    <p class="col-lg-3 col-12">Server</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{
                                      this.VisitorsDetails.serverIpAddress}}/{{
                                      this.VisitorsDetails.serverHostName}}</span>
                                  </div>

                                  <div class="article">
                                    <p class="col-lg-3 col-12">Client</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{
                                      this.VisitorsDetails.clientIpAddress}}/{{
                                      this.VisitorsDetails.clientHostName}}</span>
                                  </div>

                                  <div class="article">
                                    <p class="col-lg-3 col-12">Client User</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{
                                      this.VisitorsDetails.clientUser}}</span>
                                  </div>

                                  <div class="article">
                                    <p class="col-lg-3 col-12">Initial Locale</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{
                                      this.VisitorsDetails.initialLocale}}</span>
                                  </div>
                                  <div class="article">
                                    <p class="col-lg-3 col-12">Initial Request</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{
                                      this.VisitorsDetails.initialRequest}}</span>
                                  </div>
                                  <div class="article">
                                    <p class="col-lg-3 col-12">From Date / Through Date</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{
                                      this.VisitorsDetails.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}/
                                      {{ this.VisitorsDetails.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                  </div>

                                  <div class="article">
                                    <p class="col-lg-3 col-12">Initial User Agent</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{
                                      this.VisitorsDetails.initialUserAgent}}</span>
                                  </div>
                                  <div class="article">
                                    <p class="col-lg-3 col-12">Cookie</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{
                                      this.VisitorsDetails.cookie}}</span>
                                  </div>
                                  <div class="article">
                                    <p class="col-lg-3 col-12">Initial Referer</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">{{
                                      this.VisitorsDetails.initialReferrer}}</span>
                                  </div>







                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="w3-card-4 classCard mt-2">
          <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass">
              <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                  Hit Tracker</a></li>
            </ul>
          </div>
          <div class="create-new-leade mt-2">
            <div class="container-fluid">
              <div class="row">
                <form class="w-100">
                  <div class="port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div *ngIf="activeCategory==1">
                          <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                            <div class="col-lg-12 col-12">
                              <div class="form-group">
                                <div class="card own-account-table">
                                  <p-table [value]="serverHitData" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'300px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                              binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                  <input type="checkbox" name="undefined" value="undefined">
                                                </div>
                                                <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                  <span class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                              </div>
                                              <!---->
                                            </p-checkbox>
                                            Content ID
                                            <p-sortIcon field="code">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                          <div style="color: white;">
                                            Type
                                            <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                          <div style="color: white;">
                                            Size
                                            <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                          <div style="color: white;">
                                            Start Time
                                            <p-sortIcon field="category">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                          <div style="color: white;">
                                            Time
                                            <p-sortIcon field="category">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                          <div style="color: white;">
                                            URI
                                            <p-sortIcon field="category">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td [ngStyle]="{'width':'300px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                              binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                  <input type="checkbox" name="undefined" value="undefined">
                                                </div>
                                                <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                  <span class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                              </div>
                                              <!---->
                                            </p-checkbox>
                                            <span class="account-button">{{product.contentId}}</span>
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          {{product.hitTypeId}}
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          ?
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          {{product.hitStartDateTime|date:'yyyy-MM-dd hh:mm:ss'}}
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          {{product.runningTimeMillis}}
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          {{product.requestUrl}}
                                        </td>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                      <tr>
                                        <td>
                                          <span class="text-center">
                                            <h4 class="ml-2">No
                                              Record Found</h4>
                                          </span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>

                                  <p class="paginate_data">
                                    View per page </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="main-submit-button">
                    <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
                </div> -->
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>