<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Skill of {{this.updateId}} </span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling h4Margin">
                                    Skill Information
                                </h4>
                                <span class="edit-buttons hCreate">

                              <a click="getdepartmentsListById()"  data-toggle="modal" 
                              data-target="#exampleModalCenter">Update</a>
                            </span>
                            </div>
                        </header>
                        <!-- <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px;">
                        <li [ngClass]="activeCategory==1?'active':''" ><a>
                                Skill Information</a></li>
                       
                    </ul> -->
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group">
                                                <div *ngIf="activeCategory==1">

                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Employee ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.updateId}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Skill Type</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.Skillid}}</span>

                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Rating</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.rate}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Skill Level</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.skill}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Year Experience</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.year}}</span>
                                                                        </div>





                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Party Skill</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true" #closebutton>&times;</span>
               </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==1?'active':''"><a>
                               Add Party Skill </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==1?'active':''"><a>
                                   Update Party Skill </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="addSkillsService.add_skill_form" [formGroup]="addSkillsService.add_skill_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Employee ID <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="partyId" placeholder="Select Employee ID" filter="true" [options]="partyIdArray" optionlabel="label">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!addSkillsService.add_skill_form.controls.partyId.invalid && !!addSkillsService.add_skill_form.controls.partyId.touched">  Employee ID   is required!</small>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Skill Type ID <span
                                                           style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="skillTypeId" filter="true" [options]="skillArray" optionlabel="label" placeholder="Select Skill Type Id">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!addSkillsService.add_skill_form.controls.skillTypeId.invalid && !!addSkillsService.add_skill_form.controls.skillTypeId.touched">  Skill Type ID  is required!</small>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Years Experience</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="yearsExperience" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter year of experience">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Rating </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="rating" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rating">


                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Skill Level</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="skillLevel" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter skill Level">

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="main-submit-button" style="margin-right: 13%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>
                                            <button type="submit" *ngIf="show" (click)="updateSkills()" class="btn btn-secondary submit-btn">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>