<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                                    Find User Login</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                    Find Security Group</a></li>
                               <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                                    Cert Issuers</a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                    <div class="panel-body">
                                                        <div>
                                                            <header class="w3-container">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Find User Login
                                                                    </h4>
                                                                    <span *ngIf="!this.Hidebtn" class="px-2 edit-buttons" style="float: left !important;cursor: pointer;" data-toggle="modal" data-target="#exampleModalCenter">Create New User Login</span>
                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="UserLogin" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                User Login ID
                                                                                                <p-sortIcon field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Enabled
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Has Logged Out
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                                                                            <div style="color: white;">
                                                                                                Disabled Date Time
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span (click)="updatePassword(product.data.userLoginId)" style="cursor: pointer;color: #0d3769;;" class="account-button">{{product.data.userLoginId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.data.enabled}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.data.hasLoggedOut}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.data.disabledDateTime|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">No
                                                                                                    Record Found</h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==2">
                                                    <div class="panel-body">
                                                        <div>
                                                            <header class="w3-container">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Find Security Group
                                                                    </h4>
                                                                    <span *ngIf="!this.Hidebtn" class="px-2 edit-buttons" style="float: left !important;" data-toggle="modal" data-target="#exampleModal">Create</span>
                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="securtiyList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Security Group ID
                                                                                                <p-sortIcon field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Description
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <!--  <th pSortableColumn="category"><div style="color: white;">Action<p-sortIcon
                                                                    field="category"></p-sortIcon></div>
                                                            </th> -->

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td style="cursor: pointer;">
                                                                                            <div class="checkbox-align" (click)="permisiionPage(product.groupId)">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <a  class="account-button"  (click)="detailSecurity(product.groupId,product.description)" style="cursor: pointer;color: #0d3769;" routerLinkActive="active">
                                                                                                    {{product.groupId}}</a>

                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{{product.description}}</td>
                                                                                        <!-- 
                                                            <td>
                                                                <div>
                                                                    <svg class="hover"
                                                                        (click)="editSecurity(product.groupId)"
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>

                                                                </div>
                                                            </td>
 -->
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>


                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Add Issuer Provisions
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Cert String</label>
                                                                    <textarea type="text" name="partyId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-12 main-submit-button ">
                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">View
                                                                Cert</button>&nbsp;&nbsp;
                                                        </div>
                                                        <div>
                                                            <header class="w3-container">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Cert Issuers
                                                                    </h4>
                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                        <div class="card own-account-table">
                                                                            <p-table [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span class="account-button">{{product.agreementId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.productId}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.partyIdFrom}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.partyIdTo}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'200px'}" width="11%">
                                                                                            {{product.roleTypeIdTo}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'200px'}" width="11%">
                                                                                            {{product.agreementTypeId}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.fromDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">No
                                                                                                    Record Found</h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create-User-Login</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategorymain==2?'active':''"><a>
                                        Create User Login
                                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            User Login ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="loginId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter User Login ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Current Password</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="currentpass" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Current Password Verify</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="verifypass" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Current Password Verify">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Password Hint</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="passHint" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Require Password Change</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="passrequire" placeholder="Require Password Change" [options]="userLabel" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            External Auth ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="extAuthId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                                    </div>

                                                </div>
                                            </div>



                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -9% !important;">

                                        <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>



                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create-Security-Group</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonA>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey create-new-table">
                    <div class="w3-card-4 classCardPopUp" style="width:100%!important ;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create Security Group</a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update Security Group</a></li>

                            </ul>

                        </div>
                        <div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" role="tablist" aria-multiselectable="true">
                                                <div class="panel panel-default">

                                                    <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                                                        <div class="panel-body">
                                                            <div class="mt-2">
                                                                <div class="">
                                                                    <div class="row">
                                                                        <form class="w-100" [formGroup]="createGroup">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                                            <label class="common-labels">
                                                                                                Security Group ID
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-3">
                                                                                            <input type="text" class="form-control" id="exampleInputEmail1" formControlName="groupId" placeholder="Enter Security Group ID">
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                            <label class="common-labels">Name</label>
                                                                                        </div>
                                                                                        <div class="col-lg-3">
                                                                                            <input type="text" id="exampleInputEmail1" class="form-control" formControlName="groupName" placeholder="Enter Name">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                                            <label class="common-labels">Description</label>
                                                                                        </div>
                                                                                        <div class="col-lg-3">
                                                                                            <input type="text" id="exampleInputEmail1" class="form-control" formControlName="description" placeholder="Enter Description">
                                                                                        </div>


                                                                                    </div>
                                                                                </div>



                                                                            </div>
                                                                            <div class="submit-btn-sales main-submit-button" style="padding-right: 151px;margin-top:-8px">
                                                                                <button *ngIf="!show" (click)="submit()" type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                                                                <button *ngIf="show" (click)="update()" type="submit" class="btn btn-secondary submit-btn">Update</button>

                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>