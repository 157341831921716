<div class="modal-header">
    <span class="color-black pl-1">Add Party Contact</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>
<div class="row p-15">
    <div class="col-12">
        <!-- <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
           <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Contact

            </span>
            <span class="color-black pl-1" > >Add Party Contact
            </span>

            <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
        </div> -->
        <div class=" bg-white color-grey">
            <div class="w3-card-4 classCard">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">
        
                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show" ><a>
                    Add Party Contact
                </a></li>
                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show" ><a>
                    Update Party Contact
                </a></li>
                    
                
                </ul>
                </div>
            
            
            <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form  class="w-100 ml-26" [formGroup]="createContact">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm">
                                            <label for="exampleInputEmail1">
                                                Contact List ID</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown *ngIf="!this.show" [options]="contactListArray" filter="true"
                                            formControlName="ContactListID"
                                            placeholder="Enter Contact List ID"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                            <input type="text"  *ngIf="this.show"  formControlName="ContactListID"  class="form-control" id="exampleInputEmail1"
                                            readonly aria-describedby="emailHelp" placeholder="Enter Party Id" >
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">
                                                From Date<span
                                                style="color:red">*</span></label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="date" *ngIf="!this.show"  formControlName="FromDate"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Party Id" >
                                            <input type="date" *ngIf="this.show" formControlName="FromDate"  class="form-control" id="exampleInputEmail1"
                                            readonly aria-describedby="emailHelp" placeholder="Enter Party Id" >
                                        </div>
                                        
                                    </div>
                                    </div>
                                    <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm">
                                            <label for="exampleInputEmail1">
                                                Through Date</label>
                                                
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="date"  formControlName="ThroughDate"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">
                                                Status ID</label>
                                                
                                        </div>
                                        <div class="col-lg-3">
                                                
                                            <p-dropdown [options]="PartyInvitationStatusListArray" filter="true"
                                            formControlName="StatusID"
                                            placeholder="Enter Status ID"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                        </div>
                                        
                                    </div>
                                    </div>
                                    <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm">
                                            <label for="exampleInputEmail1">
                                                Preferred Contact Mech ID</label>
                                                
                                                
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown [options]="PreferredContactMechIdArray" filter="true"
                                            formControlName="PreferredContactMechID"
                                            placeholder="Enter Preferred Contact Mech ID"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">
                                                Preferred Contact Mech ID</label>
                                                
                                        </div>
                                        <div class="col-lg-3">
                                            
                                            <p-dropdown [options]="PreferredContactMechIdArray" filter="true"
                                            formControlName="PreferredContactMechID"
                                            placeholder="Enter Preferred Contact Mech ID"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                        </div>
                                        
                                    </div>
                                    </div>
                                    <div class="col-lg-12" *ngIf="this.show">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm">
                                            <label for="exampleInputEmail1">
                                                Opt-in Code </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text"  formControlName="OptinCode"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Opt-in Code " >
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">
                                                Status History</label>
                                                
                                                
                                        </div>
                                        <div class="col-lg-3">
                                            
                                            <p-dropdown [options]="AllPartyContactListPartyStatusArray" filter="true"
                                            formControlName="StatusHistory"
                                            placeholder="Enter 	Status History"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                        </div>
                                        
                                    </div>
                                    </div>
                                
                                
                            </div>
                            

                        </form>
                        <div class="main-submit-button" style="margin-left: 78%;">
                            
                            <button type="submit" *ngIf="!this.show" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Create</button>
                                
                            <button type="submit" *ngIf="this.show" (click)="onUpdate();"  class="btn btn-secondary submit-btn">Update</button>
                            
                            
                            
                            
                        </div>
                    </div>
                </div>

            </div>
            </div>

        </div>
        
    </div>
</div>
