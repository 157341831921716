
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {AddEmployeePositionModel} from './add-employee-position-model'

@Injectable({
    providedIn: 'root'
  })


export class CreateEmployeePositionService {
    addEmployee_position_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    public addEmployeePositionModel: AddEmployeePositionModel
    ) { } 

  addEmployeePositionForm(data:any): void {
    if (!this.addEmployee_position_form) {
        this.addEmployee_position_form = this._formBuilder.group(this.addEmployeePositionModel.mapDataToModel(data));
    }
  }
}

