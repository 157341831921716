<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels" *ngIf="Person">The Profile of
                    {{Person.personInformation.personalTitle}}
                    {{Person.personInformation.firstName}}
                    {{Person.personInformation.middleName}}
                    {{Person.personInformation.lastName}}
                    {{"[" + this.taxAuthPartyId + "]"}}</span>

                <span class="d-flex">

                    <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
                </span>



            </div>
            <div class="w3-card-4 classCardA ">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategoryA==1?'active':''">
                            <a>
                                Profile
                            </a>
                        </li>
                        <li [ngClass]="activeCategoryA==2?'active':''" (click)="Preferences()">
                            <a>
                                Preferences
                            </a>
                        </li>
                        <li [ngClass]="activeCategoryA==3?'active':''" (click)="Roles()">
                            <a>
                                Role(s)</a>
                        </li>
                        <li [ngClass]="activeCategoryA==4?'active':''" (click)="Project()">
                            <a>
                                Projects
                            </a>
                        </li>
                        <li [ngClass]="activeCategoryA==5?'active':''" (click)="TasksProfile()">
                            <a>
                                Tasks
                            </a>
                        </li>
                        <li [ngClass]="activeCategoryA==6?'active':''" (click)="Rates()">
                            <a>
                                Rates
                            </a>
                        </li>
                        <li [ngClass]="activeCategoryA==7?'active':''" (click)="Revisions()">
                            <a>
                                Revisions
                            </a>
                        </li>


                    </ul>
                </div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                    <div class="create-new-leade ">
                        <div class="container-fluid">


                            <div class="panel-group">
                                <div>
                                    <div class="container-fluid">

                                    </div>
                                    <div class="divA">
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling hleft">
                                                    Personal Information
                                                </h4>
                                                <span *ngIf="!this.Hidebtn" data-toggle="modal" data-target="#profile1"
                                                    class="edit-buttons ml-2">Update</span>
                                            </div>
                                            <div class="panel-body">
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select pt-0">
                                                    <div class="color-black container">
                                                        <div class="row">

                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-8 col-12">
                                                                        Personal Image
                                                                    </p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                </div>

                                                                <div class="article">
                                                                    <p class="col-lg-6 col-12">
                                                                        Party ID</p>
                                                                    <span *ngIf="Person"
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{Person.personInformation.partyId}}
                                                                    </span>

                                                                </div>

                                                                <div class="article">
                                                                    <p class="col-lg-6 col-12">
                                                                        Name</p>
                                                                    <span *ngIf="Person"
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{Person.personInformation.firstName}}
                                                                        {{Person.personInformation.middleName}}
                                                                        {{Person.personInformation.lastName}}</span>
                                                                </div>

                                                                <div class="article">
                                                                    <p class="col-lg-6 col-12">
                                                                        Comments</p>
                                                                    <span *ngIf="Person"
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{Person.personInformation.comments}}</span>
                                                                </div>

                                                                <div class="article">
                                                                    <p class="col-lg-6 col-12">
                                                                        External ID</p>
                                                                    <span *ngIf="Person"
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{Person.externalId}}</span>
                                                                </div>

                                                                <div class="article">
                                                                    <p class="col-lg-6 col-12">
                                                                        Status ID</p>
                                                                    <span *ngIf="Person"
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{Person.statusId.description}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="panel-body allStyleUser">
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select pt-0">
                                                    <div class="form-group">
                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="WasChangedList" [paginator]="true"
                                                                [rows]="3" scrollHeight="150px" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769 !important;">
                                                                        <th [ngStyle]="{'width':'200px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                </p-checkbox>
                                                                                Was

                                                                            </div>

                                                                        </th>
                                                                        <th [ngStyle]="{'width':'120px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Changed

                                                                            </div>

                                                                        </th>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                </p-checkbox>
                                                                                <span
                                                                                    style="color: #383139 !important;cursor: pointer;">{{product.was}}</span>

                                                                            </div>





                                                                        </td>
                                                                        <td [ngStyle]="{'width':'120px'}">
                                                                            {{product.Changed}}
                                                                        </td>


                                                                    </tr>


                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">
                                                                                    No
                                                                                    Record
                                                                                    Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">

                                                            </p>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling hleft">
                                                    List Related Accounts
                                                </h4>
                                                <span *ngIf="!this.Hidebtn" style="margin-right: 2%;"
                                                    class="edit-buttons" data-toggle="modal"
                                                    data-target="#profileListRelatedAccounts">Create New Accounts</span>
                                            </div>

                                            <div class="panel-body allStyleUser">
                                                <div class="card own-account-table borderTable">
                                                    <p-table [value]="EditListRelatedAccountList" [paginator]="false"
                                                        [rows]="3" scrollHeight="100px" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </p-checkbox>
                                                                        To Party ID
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>

                                                                <th pSortableColumn="quantity">
                                                                    <div style="color: white;">
                                                                        Type
                                                                        <p-sortIcon field="quantity">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>

                                                                <th pSortableColumn="price">
                                                                    <div style="color: white;">
                                                                        Comment
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td style="padding-left:8% !important">
                                                                    <div class="checkbox-align">

                                                                        {{product.dataNode.partyIdTo}}
                                                                    </div>
                                                                </td>
                                                                <td>{{product.dataNode.partyRelationshipTypeId}}
                                                                </td>
                                                                <td>{{product.dataNode.comments}}
                                                                </td>


                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                </div>

                                            </div>

                                        </div>
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling hleft">
                                                    Contact Information

                                                </h4>
                                                <!-- <div class="d-flex flex-row hCreate">
                                                    <span class="edit-buttons" data-toggle="modal"
                                                      data-target="#exampleModalSixth" [queryParams]="{partyId:taxAuthPartyId}">Create Telecom</span>
                                                    <span class="edit-buttons ml-2" data-toggle="modal"
                                                      data-target="#exampleModalAddress" [queryParams]="{partyId:taxAuthPartyId}">Create
                                                      Address</span>
                                                  </div>  -->
                                                <div class="d-flex flex-row hCreate">
                                                    <span *ngIf="!this.Hidebtn" class="edit-buttons"
                                                        data-toggle="modal" data-target="#exampleModalSixth">Create
                                                        Telecom</span>
                                                    <span *ngIf="!this.Hidebtn" class="edit-buttons ml-2"
                                                        data-toggle="modal" data-target="#exampleModalAddress">Create
                                                        Address</span>
                                                </div>
                                                <!-- 
                                                <span class="edit-buttons"
                                                    data-toggle="modal"
                                                    (click)="openModal()"
                                                    data-target="#contactModalCenter">Create</span> -->

                                            </div>

                                            <div class="panel-body allStyleUser">
                                                <div class="card own-account-table borderTable">
                                                    <p-table [value]="contactInformations" [paginator]="false"
                                                        [rows]="3" scrollHeight="150px" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'100px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </p-checkbox>
                                                                        Contact Type
                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'139px'}"
                                                                    style="text-align: center!important;"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">Contact Information
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'120px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">Soliciting OK
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'115px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">Action
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'100px'}">
                                                                    {{product.contactMechType.description}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'139px'}">
                                                                    <div class="checkbox-align">

                                                                        <ng-container
                                                                            *ngIf="product.contactMech.contactMechTypeId==='POSTAL_ADDRESS'">
                                                                            {{product.postalAddress.address1}} ,
                                                                            {{product.postalAddress.address2}},
                                                                            <br>{{product.postalAddress.stateProvinceGeoId}}
                                                                            , {{product.postalAddress.city}},
                                                                            <br>{{product.postalAddress.postalCode}},{{product.postalAddress.countryGeoId}}
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="product.contactMech.contactMechTypeId==='EMAIL_ADDRESS'">
                                                                            {{product.contactMech.infoString}}
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="product.contactMech.contactMechTypeId==='TELECOM_NUMBER'">
                                                                            Area Code:
                                                                            {{product.telecomNumber.areaCode}}, Contact
                                                                            Number:
                                                                            {{product.telecomNumber.contactNumber}},
                                                                            Country Code:
                                                                            {{product.telecomNumber.countryCode}}
                                                                        </ng-container>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'120px'}"
                                                                    style="text-align: center!important;">
                                                                    {{product.partyContactMech.allowSolicitation
                                                                    ?product.partyContactMech.allowSolicitation :'N' }}
                                                                </td>
                                                                <td [ngStyle]="{'width':'115px'}">
                                                                    <fa-icon *ngIf="!this.Hidebtn" [icon]="faEdit"
                                                                        class="text-primary"
                                                                        (click)="updateData(product.contactMech.contactMechId ,product.contactMech.contactMechTypeId)">
                                                                    </fa-icon>

                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling hleft">
                                                    Identification Numbers
                                                </h4>
                                                <span *ngIf="!this.Hidebtn" data-toggle="modal"
                                                    data-target="#identificationID"
                                                    class="edit-buttons ml-2">New</span>

                                            </div>

                                            <div class="panel-body allStyleUser">
                                                <div class="card own-account-table borderTable">
                                                    <p-table [value]="IdentificationList" [paginator]="false" [rows]="3"
                                                        scrollHeight="100px" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'220px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>

                                                                        </p-checkbox>
                                                                        Identification Type
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        ID Value
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Action
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'220px'}">
                                                                    {{product.description}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.identificationData.idValue}}
                                                                </td>

                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <fa-icon *ngIf="!this.Hidebtn" [icon]="faEdit"
                                                                        class="text-primary" data-toggle="modal"
                                                                        data-target="#identificationID"
                                                                        (click)="updateIdentification(product)">
                                                                    </fa-icon>
                                                                    <!-- <fa-icon
                                                                        [icon]="faEdit"
                                                                        class="text-primary"

                                                                        (click)="updatePartyIdentification(product.identificationData.partyIdentificationTypeId,product.identificationData.idValue)">
                                                                    </fa-icon> -->
                                                                    <svg *ngIf="!this.Hidebtn"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        (click)="deleteProfileIdentification(product.identificationData.partyIdentificationTypeId)"
                                                                        class="ml-20" width="16" height="16"
                                                                        viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }

                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>

                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">
                                                                            No Record
                                                                            Found
                                                                        </h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                </div>

                                            </div>

                                        </div>
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling hleft">
                                                    Revisions
                                                </h4>

                                            </div>

                                            <div class="panel-body allStyleUser">
                                                <div class="card own-account-table borderTable">
                                                    <p-table [value]="RevisionsList" [paginator]="false" [rows]="3"
                                                        scrollHeight="150px" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </p-checkbox>
                                                                        Revision
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Message
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="category">
                                                                    <div style="color: white;">
                                                                        Committer</div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>




                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        {{product.revision}}
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.message}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.committer}}
                                                                </td>


                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">
                                                                            No
                                                                            Record
                                                                            Found
                                                                        </h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                </div>

                                            </div>

                                        </div>
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">

                                                <h4 class=" common-styling" style="margin-left: 7px;">
                                                    Loyalty Points
                                                </h4>
                                            </div>

                                            <div class="w3-container">
                                                <div class="panel-body" style="    background: white;">
                                                    <div style="padding: 15px 5px!important;"
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="form-group">
                                                            <div class="card own-account-table"
                                                                style="width: -3px!important;    margin-left: 5%!important;">
                                                                <span *ngIf="this.PartyLoyaltyPoints"
                                                                    style="color: #383139 !important;">
                                                                    You have
                                                                    {{this.PartyLoyaltyPoints.totalSubRemainingAmount}}
                                                                    points from
                                                                    {{this.PartyLoyaltyPoints.totalOrders}}
                                                                    order(s) in the last
                                                                    {{this.PartyLoyaltyPoints.monthsToInclude}}
                                                                    Months.
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling hleft">
                                                    Stores
                                                </h4>

                                            </div>

                                            <div class="panel-body allStyleUser">
                                                <div class="card own-account-table borderTable">
                                                    <p-table [value]="ProductStore" [paginator]="false" [rows]="3"
                                                        scrollHeight="150px" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">

                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </p-checkbox>
                                                                        Store Name [ID]
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Role Type
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>


                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'150px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </p-checkbox>
                                                                        <span
                                                                            class="account-button">{{product.productStore.storeName}}
                                                                            {{product.productStore.productStoreId}}</span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'150px'}">
                                                                    {{product.roleType.roleTypeId}}
                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">
                                                                            No
                                                                            Record Found
                                                                        </h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                </div>

                                            </div>

                                        </div>
                                        <div class="w3-card-4 classCardView mt-2" style="width:200% !important">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling pl-3">
                                                    Payment Method
                                                </h4>
                                                <div class="d-flex flex-row" *ngIf="!this.Hidebtn">
                                                    <span class="edit-buttons ml-2"
                                                        (click)="creditCardPage()">Create
                                                        Credit
                                                        Card</span>
                                                    <span class="edit-buttons ml-2"
                                                        (click)="giftCardPage()">Create
                                                        Gift
                                                        Card</span>
                                                    <span class="edit-buttons ml-2"
                                                        (click)="EFTAccountPage()">Create
                                                        EFT Account</span>
                                                    <span class="edit-buttons ml-2" (click)="
                                                        CheckAccountPage()">Add
                                                        Check Account</span>
                                                    <span class="edit-buttons ml-2"
                                                        (click)="BillingAccountPage()">Create
                                                        Billing Account</span> 
                                                </div>

                                            </div>

                                            <div class="panel-body allStyleUser">

                                                <div class="card own-account-table borderTable">
                                                    <p-table [value]="paymentMethods" [paginator]="false" [rows]="3"
                                                        scrollHeight="150px" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td>{{product.type}}
                                                                </td>
                                                                <td>
                                                                    <ng-container
                                                                        *ngIf="product.paymentMethodTypeId.includes('ACCOUNT') || product.paymentMethodTypeId.includes('CHECK')">
                                                                        <span>{{product.nameOnAccount}},{{product.bankName}}({{product.branchCode}}),A/C
                                                                            {{product.accountNumber}},
                                                                            (Type:
                                                                            {{product.accountType
                                                                            ?
                                                                            product.accountType
                                                                            :''}}),
                                                                            (Updated At:
                                                                            {{product.lastUpdatedStamp
                                                                            |date
                                                                            :'yyyy-MM-dd HH:mm:ss'}})</span>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="product.paymentMethodTypeId==='BILLING'">
                                                                        <span>({{product.billingAccountId}}),(Account
                                                                            Limit:${{product.accountLimit}}),(Available
                                                                            Balance:
                                                                            ${{product.accountBalance}}),
                                                                            (Updated At:
                                                                            {{product.lastUpdatedStamp
                                                                            |date
                                                                            :'yyyy-MM-dd HH:mm:ss'}})</span>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="product.paymentMethodTypeId.includes('CARD')">
                                                                        <span>{{product.cardNumber
                                                                            ?
                                                                            product.cardNumber
                                                                            :''}}</span>, Expire :
                                                                        <span>{{product.expireDate?
                                                                            product.expireDate:''}}</span>,
                                                                        <span>
                                                                            {{product.companyNameOnCard?
                                                                            product.companyNameOnCard:''}}</span>,
                                                                        <span> ,(
                                                                            {{product.firstNameOnCard
                                                                            ?
                                                                            product.firstNameOnCard:''}}
                                                                            {{product.middleNameOnCard
                                                                            ?
                                                                            product.middleNameOnCard:''}}
                                                                            {{product.lastNameOnCard
                                                                            ?
                                                                            product.lastNameOnCard
                                                                            : ''}}),(
                                                                            {{product.cardType}}),
                                                                            (Updated At:
                                                                            {{product.lastUpdatedStamp
                                                                            |date
                                                                            :'yyyy-MM-dd HH:mm:ss'}})</span>
                                                                    </ng-container>
                                                                </td>
                                                                <td>
                                                                    <fa-icon  class="account-button" 
                                                                        (click)="paymentMethodNavigation(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)"
                                                                        [icon]="faEdit">
                                                                    </fa-icon>
                                                                    <fa-icon class="text-danger cursor-pointer"
                                                                        (click)="removePaymentMethod(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)"
                                                                        [icon]="faTrash">
                                                                    </fa-icon>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                </div>

                                            </div>

                                        </div>

                                        <br>
                                        <br>
                                        <br>
                                    </div>

                                    <div class="divB">
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling hleft">
                                                    Party Attribute(s)

                                                </h4>
                                                <span *ngIf="!this.Hidebtn" data-toggle="modal"
                                                    data-target="#profilePartyAttribute"
                                                    class="edit-buttons ml-2">Create New Party Attribute</span>


                                            </div>
                                            <div class="panel-body">
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                    <div class="card own-account-table borderTable">
                                                        <p-table [value]="PartyAttribute" [paginator]="false" [rows]="3"
                                                            scrollHeight="100px" scrollWidth="100%"
                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                            [totalRecords]="total" [scrollable]="true"
                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        [ngStyle]="{'width':'100px'}"
                                                                        pSortableColumn="code">
                                                                        <div class="checkbox-align"
                                                                            style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </p-checkbox>
                                                                            Name
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>

                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        [ngStyle]="{'width':'100px'}"
                                                                        pSortableColumn="price">
                                                                        <div style="color: white;">
                                                                            Value
                                                                            <p-sortIcon field="price">
                                                                            </p-sortIcon>

                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        [ngStyle]="{'width':'120px'}"
                                                                        pSortableColumn="quantity">
                                                                        <div style="color: white;">
                                                                            Action
                                                                            <p-sortIcon field="quantity">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-pro>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                        <div class="checkbox-align">

                                                                            {{pro.attrName}}
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                        {{pro.attrValue}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                        <svg *ngIf="!this.Hidebtn" class="hover"
                                                                            data-toggle="modal"
                                                                            data-target="#profilePartyAttribute"
                                                                            (click)="updateAttribute(pro)"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16" height="16"
                                                                            viewBox="0 0 21.223 21.222">
                                                                            <g transform="translate(0 -0.004)">
                                                                                <g transform="translate(0 1.52)">
                                                                                    <path
                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                        transform="translate(0 -34.137)" />
                                                                                </g>
                                                                                <g transform="translate(4.548 0.004)">
                                                                                    <g transform="translate(0 0)">
                                                                                        <path
                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>

                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                            <h4 class="ml-2">
                                                                                No
                                                                                Record
                                                                                Found
                                                                            </h4>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling hleft">
                                                    User Name(s)

                                                </h4>
                                                <span *ngIf="!this.Hidebtn" data-toggle="modal"
                                                    data-target="#profileCreateUserLogIn"
                                                    class="edit-buttons ml-2">Create New User Name</span>
                                            </div>

                                            <div class="panel-body allStyleUser">
                                                <div class="card own-account-table borderTable">
                                                    <p-table [value]="UserLoginData" [paginator]="false" [rows]="3"
                                                        scrollHeight="150px" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'250px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </p-checkbox>
                                                                        User Login
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    [ngStyle]="{'width':'139px'}"
                                                                    style="text-align: center!important;"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Status
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>

                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    [ngStyle]="{'width':'115px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Action
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>

                                                                <td [ngStyle]="{'width':'250px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </p-checkbox>
                                                                        {{product.data.userLoginId}}
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'120px'}"
                                                                    style="text-align: center!important;">
                                                                    {{product.partyStatusId.statusId}}

                                                                </td>
                                                                <td [ngStyle]="{'width':'115px'}">
                                                                    <fa-icon *ngIf="!this.Hidebtn" [icon]="faEdit"
                                                                        class="text-primary"
                                                                        (click)="updatePassword(product.data.userLoginId)">
                                                                    </fa-icon>
                                                                    <span *ngIf="!this.Hidebtn" style="cursor: pointer;"
                                                                        (click)="securityList(product.data.userLoginId)">Security
                                                                        Group</span>


                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">
                                                                            No Record
                                                                            Found
                                                                        </h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <!-- <p class="paginate_data">
                                                        View per page

                                                    </p> -->
                                                </div>

                                            </div>

                                        </div>
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling hleft">
                                                    Visits
                                                </h4>
                                                <span *ngIf="!this.Hidebtn" data-toggle="modal" (click)="listAll()"
                                                    data-target="#profileVisits"
                                                    class="edit-buttons ml-2">List
                                                    All</span>


                                            </div>
                                            <div class="panel-body">
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                    <div class="card own-account-table borderTable">
                                                        <p-table [value]="Visitors" [paginator]="false" [rows]="3"
                                                            scrollHeight="100px" scrollWidth="100%"
                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                            [totalRecords]="total" [scrollable]="true"
                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="code">
                                                                        <div class="checkbox-align"
                                                                            style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </p-checkbox>
                                                                            Visit ID
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>

                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="price">
                                                                        <div style="color: white;">
                                                                            User Login
                                                                            <p-sortIcon field="price">
                                                                            </p-sortIcon>

                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="quantity">
                                                                        <div style="color: white;">
                                                                            user Impersonated
                                                                            <p-sortIcon field="quantity">
                                                                            </p-sortIcon>
                                                                        </div>

                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            New User
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>

                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="price">
                                                                        <div style="color: white;">
                                                                            Web App


                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="price">
                                                                        <div style="color: white;">
                                                                            Client IP


                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="price">
                                                                        <div style="color: white;">
                                                                            From Date


                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="price">
                                                                        <div style="color: white;">
                                                                            Through Date


                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <div (click)="detailSecurity(product.visitorData.visitId)"
                                                                        class="account-button">
                                                                            {{product.visitorData.visitId}}
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.visitorData.userLoginId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.userImpersonated}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.visitorData.userCreated}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.visitorData.webappName}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.clientIpAddress}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.visitorData.fromDate|date:'yyyy-MM-dd
                                                                        hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.visitorData.thruDate|date:'yyyy-MM-dd
                                                                        hh:mm:ss'}}
                                                                    </td>



                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                        <p class="paginate_dataa">
                                                            <!--  <ng-container *ngIf="openNoRecordFoundNote">
      <span style="margin-left: 450px;">No Record Found!....</span>
    </ng-container> -->
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling hleft">
                                                    Party Content
                                                </h4>

                                            </div>
                                            <div class="panel-body">
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                    <div class="card own-account-table borderTable">
                                                        <p-table [value]="contentList" [paginator]="false" [rows]="3"
                                                            scrollHeight="150px" scrollWidth="100%"
                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                            [totalRecords]="total" [scrollable]="true"
                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="code">
                                                                        <div class="checkbox-align"
                                                                            style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </p-checkbox>
                                                                            Content Id
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Content Name
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            Content Type
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="quantity">
                                                                        <div style="color: white;">
                                                                            Party Content Type
                                                                            <p-sortIcon field="quantity">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="price">
                                                                        <div style="color: white;">
                                                                            Status
                                                                            <p-sortIcon field="price">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="price">
                                                                        <div style="color: white;">
                                                                            From Date
                                                                            <p-sortIcon field="price">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="quantity">
                                                                        <div style="color: white;">
                                                                            Action
                                                                            <p-sortIcon field="quantity">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px'}"
                                                                       >
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </p-checkbox>
                                                                            <span  class="account-button"
                                                                                (click)="detailContentPage(this.taxAuthPartyId,product.contentId,product.partyContentTypeId,product.fromDate)">
                                                                                {{product.contentId}}</span>

                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.contentName}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.contentType}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.partyContentTypeId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.status}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.fromDate | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                        <svg *ngIf="!this.Hidebtn"
                                                                            (click)="export(product.contentId,product.contentName)"
                                                                            width="2em" height="2em" viewBox="0 0 16 16"
                                                                            class="bi bi-arrow-down-circle-fill"
                                                                            fill="currentColor"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd"
                                                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                                        </svg>
                                                                        <svg *ngIf="!this.Hidebtn"
                                                                            (click)="onDeleteDoc(product.contentId,product.fromDate,product.partyContentTypeId,product.partyId)"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            class="ml-20" width="16" height="16"
                                                                            viewBox="0 0 26 26">
                                                                            <defs>
                                                                                <style>
                                                                                    .a {
                                                                                        fill: #f44336;
                                                                                    }

                                                                                    .b {
                                                                                        fill: #fafafa;
                                                                                        margin-left: 20px;
                                                                                    }
                                                                                </style>
                                                                            </defs>
                                                                            <path class="a fill-color"
                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                            <path class="b"
                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                transform="translate(-147.576 -147.576)" />
                                                                        </svg>

                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                        <p class="paginate_dataa">
                                                            <ng-container>
                                                                <span style="margin-left: 450px;">No
                                                                    Record
                                                                    Found!....</span>
                                                            </ng-container>
                                                        </p>
                                                    </div>

                                                    <form [formGroup]="Editform">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <label for="exampleInputEmail1">Attach
                                                                        Document</label>
                                                                    <div class="suppliers-wrapper">
                                                                        <input type="file" class="inputField classFile"
                                                                            id="exampleInputEmail1"
                                                                            formControlName="file"
                                                                            aria-describedby="emailHelp" 
                                                                            (change)="fileProgress($event)" accept="">

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6 col-12">

                                                                <div class="form-group">
                                                                    <p-dropdown filter="true"
                                                                        formControlName="partyContentTypeId"
                                                                        [(ngModel)]="partyContentTypeId"
                                                                        name="partyContentTypeId"
                                                                        placeholder="Enter Party Content"
                                                                        (ngModelChange)="onpartyContent(partyContentTypeId)"
                                                                        [options]="partyContentArray"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="partyContentTypeId"
                                                                            [options]="yesNOArray" optionlabel="label">
                                                                        </p-dropdown>

                                                                    </div>

                                                                    <!-- <div class="col-lg-6" class="form-group">
                                                                        <p-dropdown filter="true" formControlName="partyContentTypeId" 
                                                                        [(ngModel)]="partyContentTypeId" name="partyContentTypeId" 
                                                                        placeholder="Enter Party Content" (ngModelChange)="onpartyContent(partyContentTypeId)" 
                                                                        [options]="partyContentArray" optionlabel="label">
                                                                        </p-dropdown>
                                                                    </div> -->

                                                                    <div class="col-lg-3">
                                                                        <button type="submit" (click)="onSubmitDoc()"
                                                                            class="btn btn-outline-secondary">Upload</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling hleft">
                                                    Notes

                                                </h4>
                                                <span *ngIf="!this.Hidebtn" data-toggle="modal" data-target="#notePopUp"
                                                    class="edit-buttons ml-2">Create New Notes</span>
                                            </div>

                                            <div class="panel-body allStyleUser">
                                                <div class="card own-account-table borderTable">
                                                    <p-table [value]="PartyNotes" [paginator]="false" [rows]="3"
                                                        scrollHeight="150px" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'100px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </p-checkbox>
                                                                        Note Name
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'100px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;">
                                                                        Note
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'120px'}"
                                                                    pSortableColumn="quantity">
                                                                    <div style="color: white;">
                                                                        By
                                                                        <p-sortIcon field="quantity">
                                                                        </p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'100px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Created At
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>

                                                                </th>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'100px'}">
                                                                    <div class="checkbox-align">
                                                                        {{product.noteName}}
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'100px'}">
                                                                    {{product.noteInfo}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'120px'}">
                                                                    {{product.firstName}} {{product.middleName}}
                                                                    {{product.lastName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'100px'}">
                                                                    {{product.createdStamp | date:'yyyy-MM-dd'}}
                                                                </td>


                                                                <!-- <td
                                                                    [ngStyle]="{'width':'100px'}">
                                                                    <svg class="hover"
                                                                        (click)="onUpdateNotes(product.noteId,product.noteName,product.noteInfo)"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16"
                                                                        height="16"
                                                                        viewBox="0 0 21.223 21.222">
                                                                        <g
                                                                            transform="translate(0 -0.004)">
                                                                            <g
                                                                                transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g
                                                                                transform="translate(4.548 0.004)">
                                                                                <g
                                                                                    transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        (click)="onDeleteNotes(product.noteId)"
                                                                        class="ml-20"
                                                                        width="16"
                                                                        height="16"
                                                                        viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }

                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path
                                                                            class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                </td> -->

                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <!-- <p class="paginate_data">
                                                        View per page

                                                    </p> -->
                                                </div>

                                            </div>

                                        </div>


                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling hleft">
                                                    AVS Override (CC Fraud Screening)
                                                </h4>
                                            </div>
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container">
                                                        <div class="row">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-8 col-12">
                                                                        AVS String </p>
                                                                    <span
                                                                        *ngIf="this.PartyIcsAvsOverRide">{{this.PartyIcsAvsOverRide.avsDeclineStringList.avsDeclineString}}</span>
                                                                    <span>

                                                                        <button *ngIf="!this.Hidebtn" type="submit"
                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                            data-toggle="modal"
                                                                            data-target="#updateAVSoverride">Edit
                                                                        </button>
                                                                    </span>
                                                                    <span>
                                                                        <button *ngIf="!this.Hidebtn" type="submit"
                                                                            class="btn btn-danger ml-2"
                                                                            (click)="resetAvsOverride()">Reset
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <br>
                                        <br>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profile1" tabindex="-1" role="dialog" aria-labelledby="profileUpdate" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a> Edit Personal Information</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateProfile">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" disabled
                                                            [ngModelOptions]="{standalone:true}"
                                                            *ngIf="this.taxAuthPartyId"
                                                            [(ngModel)]="this.taxAuthPartyId" formControlName="partyID"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Party ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Salutation
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.salutation"
                                                            formControlName="salutation" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Salutation">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">First Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.firstName"
                                                            formControlName="firstName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter First Name">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Middle Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.middleName"
                                                            formControlName="middleName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Middle Name">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Last Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.lastName"
                                                            formControlName="lastName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Last Name ">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Personal Title
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.personalTitle"
                                                            *ngIf="Person" formControlName="personalTitle"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            value="Mr. " placeholder="Enter Personal Title">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Suffix
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="suffix"
                                                            [ngModelOptions]="{standalone:true}" *ngIf="Person"
                                                            [(ngModel)]="Person.personInformation.suffix"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Suffix">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Nickname
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.nickname"
                                                            formControlName="nickname" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Nickname">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">First Name Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="firstNameLocal" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.firstNameLocal"
                                                            placeholder="Enter First Name Local">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Middle Name Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="middleNameLocal" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.middleNameLocal"
                                                            placeholder="Enter Middle Name Local">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Last Name Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="lastNameLocal" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.lastNameLocal"
                                                            placeholder="Enter Last Name Local">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Other Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="otherLocal" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.otherLocal"
                                                            placeholder="Enter Other Local">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Member ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            [ngModelOptions]="{standalone:true}" *ngIf="Person"
                                                            [(ngModel)]="Person.personInformation.memberId"
                                                            formControlName="memberID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Member ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Gender
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="gender"
                                                            [ngModelOptions]="{standalone:true}" [options]="genderArray"
                                                            *ngIf="Person" [(ngModel)]="Person.personInformation.gender"
                                                            optionlabel="label" placeholder="Enter Gender">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Birth Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control"
                                                            formControlName="birthDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Birth Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Decreased Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control"
                                                            formControlName="decreasedDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Decreased Date">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Height
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="height"
                                                            [ngModelOptions]="{standalone:true}" *ngIf="Person"
                                                            [(ngModel)]="Person.personInformation.height"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Height">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Weight
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="weight"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            *ngIf="Person" [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.weight"
                                                            placeholder="Enter Weight">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Mother's Maiden Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="mothersMaidenName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.mothersMaidenName"
                                                            placeholder="Enter Mother's Maiden Name">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Old Maritial Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="oldMaritialStatus"
                                                            [options]="yesNOArray" optionlabel="label"
                                                            placeholder="Enter Old Maritial Status">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Maritial Status Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="maritialStatusEnumID"
                                                            optionlabel="label" [options]="martialStatusArray"
                                                            placeholder="Enter Maritial Status Enum ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Social Security Number
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="socialSecurityNumber" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.socialSecurityNumber"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Social Securtiy Number">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Passport Number
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="passportNumber" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.passportNumber"
                                                            placeholder="Enter Passport Number">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Passport Expire Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control"
                                                            formControlName="passportExpiryDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Passport Expiry Date">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Total Years Work Experience
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="totalExperience" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" *ngIf="Person"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.totalYearsWorkExperience"
                                                            placeholder="Enter Total Years Work Experience">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.comments"
                                                            *ngIf="Person" formControlName="comments"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Comments">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Employee Status Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="employeeStatusEnumID"
                                                            optionlabel="label" [options]="EmploymentStatusEnumIdArray"
                                                            placeholder="Enter Employee Status Enum ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Residence Status Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" [options]="ResidenceStatusEnumIDArray"
                                                            formControlName="residenceStatusEnumID" optionlabel="label"
                                                            placeholder="Enter Residence Status Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Occupation
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            [ngModelOptions]="{standalone:true}" *ngIf="Person"
                                                            [(ngModel)]="Person.personInformation.occupation"
                                                            formControlName="occupation" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Occupation">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Years With Employer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            [ngModelOptions]="{standalone:true}" *ngIf="Person"
                                                            [(ngModel)]="Person.personInformation.yearsWithEmployer"
                                                            formControlName="yearswithEmployer" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Years With Employer">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Months With Employer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            [ngModelOptions]="{standalone:true}" *ngIf="Person"
                                                            [(ngModel)]="Person.personInformation.monthsWithEmployer"
                                                            formControlName="monthsWithEmployer" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Months With Employer">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Existing Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="existingCustomer"
                                                            [options]="yesNOArray" optionlabel="label"
                                                            placeholder="Enter Existing Customer">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Card ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="cardID"
                                                            [ngModelOptions]="{standalone:true}" *ngIf="Person"
                                                            [(ngModel)]="Person.personInformation.cardId"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Card ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">US Citizen
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.usCitizen"
                                                            *ngIf="Person" formControlName="usCitizen"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter US Citizenship">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Ethnicity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.ethnicity"
                                                            *ngIf="Person" formControlName="ethnicity"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Ethnicity">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Account No.
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.accountNo"
                                                            formControlName="accountNo" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" *ngIf="Person"
                                                            placeholder="Enter Account Number">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Billing Rate Level
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.personInformation.billingRateLevel"
                                                            formControlName="billingRateLevel" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" *ngIf="Person"
                                                            placeholder="Enter Billing Rate Level">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Preferred Currency Uom ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" [options]="currencyList"
                                                            formControlName="preferredCurrencyUomID" optionlabel="label"
                                                            placeholder="Enter Preferred Currency Uom ID">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="description" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Description">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">External ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="Person.externalId" *ngIf="Person"
                                                            formControlName="externalID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter External ID">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="statusID"
                                                            [options]="crmArray" optionlabel="label"
                                                            placeholder="Enter Status ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button1" style="margin-left: 42%;">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="updateProfilePerson()">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileCreateUserLogIn" tabindex="-1" role="dialog"
    aria-labelledby="profileCreateUserLogIntitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Create User Login</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-89" [formGroup]="createUserForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4 form-group rightFormG">
                                                        <label for="exampleInputEmail1">
                                                            User Login ID<span style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="loginId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter User Login ID">

                                                    </div>
                                                    <div class="col-lg-2 form-group rightFormH">
                                                        <label for="exampleInputEmail1">
                                                            Current Password<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="currentpass"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Current Password">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4 form-group rightFormG">
                                                        <label for="exampleInputEmail1">
                                                            Current Password Verify<span
                                                                style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="verifypass"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Current Password Verify">

                                                    </div>
                                                    <div class="col-lg-2 form-group rightFormH">
                                                        <label for="exampleInputEmail1">
                                                            Password Hint</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="passHint"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Password Hint">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4 form-group rightFormG">
                                                        <label for="exampleInputEmail1">
                                                            Require Password Change</label>
                                                    </div>

                                                    <div class="col-lg-3 custom-dropdown">
                                                        <p-dropdown filter="true" formControlName="passrequire"
                                                            placeholder="Require Password Change" [options]="yesNOArray"
                                                            optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group rightFormH">
                                                        <label for="exampleInputEmail1">
                                                            External Auth ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="extAuthId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter External Auth ID">
                                                    </div>



                                                </div>
                                            </div>



                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -8%;">

                                        <button type="submit" (click)="onSubmitUserName();"
                                            class="btn btn-secondary submit-btn">Save</button>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileListRelatedAccounts" tabindex="-1" role="dialog"
    aria-labelledby="profileListRelatedAccounts" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a> Add Related Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="fileUploadForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightFormI">
                                                        <label for="exampleInputEmail1">
                                                            Account Party ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="accountPartyId" optionlabel="label"
                                                            filter="true" placeholder="Enter Account Party ID"
                                                            name="Parent Type ID" [options]="accountArray">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group rightFormJ">
                                                        <label for="exampleInputEmail1">
                                                            Comments</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="comments"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>



                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 83%;">

                                        <button type="submit" (click)="onSubmitRelatedAccount()"
                                            class="btn btn-secondary submit-btn">Add</button>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileContactInformation" tabindex="-1" role="dialog" aria-labelledby="contactInformation"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create New Contact </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a> Create New Contact Information</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createNewContactInformation">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Select Contact Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="selectContactType"
                                                            [options]="contactTypeArray" optionlabel="label"
                                                            placeholder="Enter Contact Type">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-6 main-submit-button" style="margin-left: -11%;">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="profilePartyAttribute" tabindex="-1" role="dialog"
    aria-labelledby="profilePartyAttributeTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Scrum Members</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Party Attribute</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade ">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="partyAttributes">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Attribute name<span style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="attrName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Attr name">

                                                        <input type="text" *ngIf="show" formControlName="attrName"
                                                            class="form-control" id="exampleInputEmail1" readonly
                                                            aria-describedby="emailHelp" placeholder="Enter Attr name">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Attribute Value<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="attrValue"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Attr Value">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Attribute Description<span
                                                                style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="attrDescription"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Attr Description">
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 39%;">
                                            <button type="submit" *ngIf="!showAttribute"
                                                (click)="onSubmitPartyAttribute();"
                                                class="btn btn-secondary submit-btn">Save</button>
                                            <button type="submit" *ngIf="showAttribute"
                                                (click)="onUpdatePartyAttribute();"
                                                class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileAddCheckAcc" tabindex="-1" role="dialog" aria-labelledby="addCheckAcc"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Check Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addCheckAccount">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Name Account <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Name Account" id="exampleInputEmail1"
                                                            formControlName="nameOnAccount">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Company Name On Account</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Company Name On Account"
                                                            id="exampleInputEmail1"
                                                            formControlName="companyNameOnAccount">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Bank Name <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Bank Name"
                                                            id="exampleInputEmail1" formControlName="bankName">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Routing Number <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Routing Number" id="exampleInputEmail1"
                                                            formControlName="routingNumber">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Payment Method Type <span
                                                                class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Account Type" filter="true"
                                                            id="exampleInputEmail1" [options]="paymentMethodTypes"
                                                            formControlName="paymentMethodTypeId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Account Type <span
                                                                class="text-danger">*</span>
                                                        </label>

                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Account Type" filter="true"
                                                            [options]="accountTypesArray" id="exampleInputEmail1"
                                                            formControlName="accountType">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Account Number <span
                                                                class="text-danger">*</span></label>

                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Account Number" id="exampleInputEmail1"
                                                            formControlName="accountNumber">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Description <span
                                                                class="text-danger">*</span></label>

                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="description" aria-describedby="emailHelp"
                                                            placeholder="Description">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                                    padding-right: 17px!important;
                                                    margin-left: -7%;">
                                                        <label for="">Billing Address</label>

                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-container *ngIf="accountData?.address;else noData">
                                                            <input type="checkbox" class="form-check-input"
                                                                id="exampleCheck1"
                                                                (change)="getAddress($event,accountData?.address.contactMechId)">
                                                            <label class="form-check-label" for="exampleCheck1">
                                                                To:<span>{{accountData?.address.address1}}</span>,
                                                                <span>{{accountData?.address.address2}}</span>,
                                                                <span>{{accountData?.address.city}}</span>,
                                                                <span>{{accountData?.address.stateProvinceGeoId}}</span>,
                                                                <span>{{accountData?.address.countryId}}</span>,
                                                                <span>{{accountData?.address.postalCode}}</span>
                                                            </label>
                                                        </ng-container>
                                                        <ng-template #noData>
                                                            No contact information on file.
                                                        </ng-template>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 main-submit-button4">
                                            <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="createCheckAccount()">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileCreateBillingAcc" tabindex="-1" role="dialog" aria-labelledby="createBillingAcc"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton9>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Edit Billing Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createBillingAccount">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Account Limit</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Account Limit" id="exampleInputEmail1"
                                                            formControlName="accountLimit">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Account Currency Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Account Currency Uom ID" filter="true"
                                                            id="exampleInputEmail1"
                                                            formControlName="accountCurrencyUomId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Contact Mech ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Contact Mech ID" id="exampleInputEmail1"
                                                            formControlName="contactMechId">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" placeholder="From Date"
                                                            id="exampleInputEmail1" formControlName="fromDate">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Through Date </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Through Date" formControlName="thruDate">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Description" id="exampleInputEmail1"
                                                            formControlName="description">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">External Account ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="External Account ID" id="exampleInputEmail1"
                                                            formControlName="externalAccountId">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Party ID <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Party ID" filter="true"
                                                            id="exampleInputEmail1" formControlName="partyId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Available Balance</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="External Account ID" id="exampleInputEmail1"
                                                            formControlName="AvailableBalance">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 main-submit-button5">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="updateBillingAccount()">Update</button>
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="createBillingAccounts()">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="notePopUp" tabindex="-1" role="dialog" aria-labelledby="notePopUptitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Note </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton10>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> New Party Note</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="noteForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Note Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Create Note Name" id="exampleInputEmail1"
                                                            formControlName="NoteName">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Note</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Enter Note"
                                                            id="exampleInputEmail1" formControlName="Note">
                                                    </div>

                                                </div>
                                            </div>




                                        </div>
                                        <div class="col-lg-12 main-submit-button" style="margin-left: -23%;">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="createPartyNote()">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileCreateGiftCard" tabindex="-1" role="dialog" aria-labelledby="createGiftCard"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Create Gift Card</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createGiftCard">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Card Number <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="cardNumber" aria-describedby="emailHelp"
                                                            placeholder="Card Number">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">PIN number <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Pin Number"
                                                            id="exampleInputEmail1" formControlName="pinNumber">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Expiration Date <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Month" filter="true"
                                                            [options]="expirationMonth" id="exampleInputEmail1"
                                                            formControlName="expMonth">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown class="ml-2" placeholder="Year" filter="true"
                                                            [options]="expirationYear" id="exampleInputEmail1"
                                                            formControlName="expYear">
                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="description" aria-describedby="emailHelp"
                                                            placeholder="Description">
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 main-submit-button5">
                                            <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="createProfileGiftCard()">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileCreateEFTAccount" tabindex="-1" role="dialog" aria-labelledby="eftAccount"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add New EFT Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createEFTAcc">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Name Account <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Name Account" id="exampleInputEmail1"
                                                            formControlName="nameOnAccount">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Company Name On Account</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Company Name On Account"
                                                            id="exampleInputEmail1"
                                                            formControlName="companyNameOnAccount">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Bank Name <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Bank Name"
                                                            id="exampleInputEmail1" formControlName="bankName">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Routing Number <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Routing Number" id="exampleInputEmail1"
                                                            formControlName="routingNumber">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Account Type <span
                                                                class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Account Type" filter="true"
                                                            [options]="accountTypesArray" id="exampleInputEmail1"
                                                            formControlName="accountType">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Account Number <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Account Number" id="exampleInputEmail1"
                                                            formControlName="accountNumber">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Description <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="description" aria-describedby="emailHelp"
                                                            placeholder="Description">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="">Billing Address</label>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 main-submit-button6">
                                            <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="createEFTAccount()">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateAVSoverride" tabindex="-1" role="dialog" aria-labelledby="updateAVSoverridetitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Edit AVS Override</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createClassification">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            AVS String</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="avs" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter AVS">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 19%;">

                                        <button type="submit" (click)="onUpdate();"
                                            class="btn btn-secondary submit-btn">Save</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileCreateCreditCard" tabindex="-1" role="dialog"
    aria-labelledby="profileCreateCreditCardTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create-Credit-Card</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Create Credit Card</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="creditCardForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                    padding-right: 17px!important;
                                    margin-left:3%;">
                                                        <label for="exampleInputEmail1">Company Name Card</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                            placeholder="Company Name Card"
                                                            formControlName="companyNameOnCard">
                                                    </div>

                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                    padding-right: 17px!important;
                                    margin-left: 0%;">
                                                        <label for="exampleInputEmail1">Prefix Card</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Prefix Card" filter="true"
                                                            [options]="prefixArray" formControlName="titleOnCard">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                    padding-right: 17px!important;
                                    margin-left: 3%;">
                                                        <label for="exampleInputEmail1">First Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="First Name"
                                                            formControlName="firstNameOnCard">
                                                        <small class="text-danger"
                                                            *ngIf="!!creditCardForm.controls.firstNameOnCard.invalid && !!creditCardForm.controls.firstNameOnCard.touched">First
                                                            Name is Required</small>
                                                    </div>
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                    padding-right: 17px!important;
                                    margin-left: 0%;">
                                                        <label for="exampleInputEmail1">Middle Name Card</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                            placeholder="Middle Name Card"
                                                            formControlName="middleNameOnCard">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                    padding-right: 17px!important;
                                    margin-left: 3%;">
                                                        <label for="exampleInputEmail1">Last Name Card <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                            placeholder="Last Name Card"
                                                            formControlName="lastNameOnCard">
                                                        <small class="text-danger"
                                                            *ngIf="!!creditCardForm.controls.lastNameOnCard.invalid && !!creditCardForm.controls.lastNameOnCard.touched">Last
                                                            Name is Required</small>
                                                    </div>

                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                    padding-right: 17px!important;
                                    margin-left: 0%;">
                                                        <label for="exampleInputEmail1">Suffix On Card
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Suffix On Card" filter="true"
                                                            [options]="suffixArray" formControlName="suffixOnCard">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                    padding-right: 17px!important;
                                    margin-left: 3%;">
                                                        <label for="exampleInputEmail1">Card Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Card Type" filter="true"
                                                            [options]="cardTypes" formControlName="cardType">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                    padding-right: 17px!important;
                                    margin-left: 0%;">
                                                        <label for="exampleInputEmail1">Card Number</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="cardNumber" aria-describedby="emailHelp"
                                                            placeholder="Card Number">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                    padding-right: 17px!important;
                                    margin-left: 3%;">
                                                        <label for="exampleInputEmail1">Expiration Date <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Month" filter="true"
                                                            [options]="expirationMonth" formControlName="expMonth">
                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!creditCardForm.controls.expMonth.invalid && !!creditCardForm.controls.expMonth.touched">Expiration
                                                            Month is Required</small>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown class="ml-2" placeholder="Year" filter="true"
                                                            [options]="expirationYear" formControlName="expYear">
                                                        </p-dropdown>


                                                        <small class="text-danger"
                                                            *ngIf="!!creditCardForm.controls.expYear.invalid && !!creditCardForm.controls.expYear.touched">Expiration
                                                            Year is Required</small>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                    padding-right: 17px!important;
                                    margin-left: 3%;">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="description" aria-describedby="emailHelp"
                                                            placeholder="Description">
                                                    </div>
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                    padding-right: 17px!important;
                                    margin-left: 0%;">
                                                        <label for="">Billing Address</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <ng-container *ngIf="accountData?.address;else noData">
                                                            <input type="checkbox" class="form-check-input"
                                                                id="exampleCheck1"
                                                                (change)="getAddress($event,accountData?.address.contactMechId)">
                                                            <label class="form-check-label" for="exampleCheck1">
                                                                To:<span>{{accountData?.address.address1}}</span>,
                                                                <span>{{accountData?.address.address2}}</span>,
                                                                <span>{{accountData?.address.city}}</span>,
                                                                <span>{{accountData?.address.stateProvinceGeoId}}</span>,
                                                                <span>{{accountData?.address.countryId}}</span>,
                                                                <span>{{accountData?.address.postalCode}}</span>
                                                            </label>
                                                        </ng-container>
                                                        <ng-template #noData>
                                                            No contact information on file.
                                                        </ng-template>
                                                    </div>


                                                </div>
                                            </div>



                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 58%;">
                                        <button type="submit" *ngIf="editMode" (click)="updateCredit()"
                                            class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submitCredit()"
                                            class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="ElectronicAddress" tabindex="-1" role="dialog" aria-labelledby="ElectronicAddress"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> The Profile of Mr. THE PRIVILEGED ADMINISTRATOR Suffix
                    {{this.taxAuthPartyId}}</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Create New Contact Information </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="ElectronicAddressForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">
                                                            Electronic Address </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Name Account" id="exampleInputEmail1"
                                                            formControlName="ElectronicAddress">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Allow Solicitation?</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Company Name On Account"
                                                            id="exampleInputEmail1" formControlName="AllowSolicitation">
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12 main-submit-button4">
                                            <button type="submit" (click)="createElectronicAddress()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="identificationID" tabindex="-1" role="dialog" aria-labelledby="identificationID"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Identification
                    {{this.taxAuthPartyId}}</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Create Identification </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createIdentification">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4 form-group rightFormG">
                                                        <label for="exampleInputEmail1">
                                                            Identification Type</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" *ngIf="!this.show"
                                                            placeholder="Enter Identification Type"
                                                            [options]="identificationArray" formControlName="type"
                                                            [options]="identificationArray" optionlabel="label"
                                                            id="exampleInputEmail1">
                                                        </p-dropdown>
                                                        <input type="email" *ngIf="this.show" formControlName="type"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter ID Value"
                                                            readonly>
                                                    </div>
                                                    <div class="col-lg-2 form-group rightFormH">
                                                        <label for="exampleInputEmail1">
                                                            ID Value</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="idvalue"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter ID Value">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -8%;">

                                        <button type="submit" *ngIf="!this.show"
                                            (click)="onSubmitIdentificationNumber();"
                                            class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.show"
                                            (click)="onUpdateIdentificationNumber();"
                                            class="btn btn-secondary submit-btn">Update</button>





                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalSixth" tabindex="-1" role="dialog" aria-labelledby="exampleModalSixthTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create-Contact</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryC==2?'active':''"><a>
                                        Create Contact</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="contactForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: -8%;">
                                                        <label for="exampleInputEmail1">Allow Solicitation</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="arrayfax" placeholder="Select"
                                                            formControlName="allowSolicitation">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: 1%;">
                                                        <label for="exampleInputEmail1">Country Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                            placeholder="Country Code" formControlName="countryCode">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: -8%;">
                                                        <label for="exampleInputEmail1">Area Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Area Code"
                                                            formControlName="areaCode">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: 1%;">
                                                        <label for="exampleInputEmail1">Contact Number</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                            placeholder="Contact Number"
                                                            formControlName="contactNumber">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: -8%;">
                                                        <label for="exampleInputEmail1">Extension</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Extension"
                                                            formControlName="extension">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: 1%;">
                                                        <label for="exampleInputEmail1">Mech Purpose Type Id<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="contactPurpose"
                                                            placeholder="Mech Purpose Type Id"
                                                            formControlName="contactMechPurposeTypeId"></p-dropdown>
                                                        <small
                                                            *ngIf="!!contactForm.controls.contactMechPurposeTypeId.invalid && !!contactForm.controls.contactMechPurposeTypeId.touched"
                                                            class="text-danger">Mech Purpose Type Id is required</small>
                                                    </div>

                                                </div>
                                            </div>



                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: -25%;">

                                        <button type="submit" (click)="submitContact()"
                                            class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalAddress" tabindex="-1" role="dialog" aria-labelledby="exampleModalAddressTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create-Postal-Address</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryC==2?'active':''"><a>
                                        Create Postal Address</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addressForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -14%;">
                                                        <label for="exampleInputEmail1">Attn Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Attn Name"
                                                            formControlName="attnName">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: 8%;">
                                                        <label for="exampleInputEmail1">To Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="To Name"
                                                            formControlName="toName">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -14%;">
                                                        <label for="exampleInputEmail1">Allow Solicitation </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Allow Solicitation"
                                                            [options]="solicitationArray" filter="true"
                                                            formControlName="allowSolicitation"></p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: 8%;">
                                                        <label for="exampleInputEmail1">Address 1 </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Address 1"
                                                            formControlName="address1">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -14%;">
                                                        <label for="exampleInputEmail1">Address 2</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Address 2"
                                                            formControlName="address2">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: 8%;">
                                                        <label for="exampleInputEmail1">City </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="City"
                                                            formControlName="city">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -14%;">
                                                        <label for="exampleInputEmail1">Country </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="countryArray" placeholder="Country"
                                                            formControlName="countryGeoId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: 8%;">
                                                        <label for="exampleInputEmail1">State </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="State" filter="true"
                                                            [options]="stateArray" formControlName="stateProvinceGeoId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -14%;">
                                                        <label for="pinCode">Pin Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Pin Code"
                                                            formControlName="postalCode">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: 8%;">
                                                        <label for="mechId">Contact Mech Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                            placeholder="Contact Mech Id"
                                                            formControlName="contactMechId">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -14%;">
                                                        <label for="mechType">Contact Mech Purpose Type Id <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Contact Mech Purpose Type Id"
                                                            filter="true" [options]="contactMechTypeId"
                                                            formControlName="contactMechPurposeTypeId">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: -16%;">
                                        <button type="submit" *ngIf="editMode" (click)="updatePostal()"
                                            class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submitPostal()"
                                            class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-create-new-contact-information *ngIf="showContactModal" (modalClosed)="onModalClosed()">
</app-create-new-contact-information>

<ngx-spinner></ngx-spinner>