import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

      
import { Location } from '@angular/common';
@Component({
  selector: 'app-psa-dashboard',
  templateUrl: './psa-dashboard.component.html',
  styleUrls: ['./psa-dashboard.component.css']
})
export class PSADashboardComponent implements OnInit {
  userLoginId: string;

  constructor(
    readonly router: Router,
    private _location: Location,

  ) { }

  ngOnInit(): void {
  this.userLoginId = localStorage.getItem("userLoginId");

  }
  homeButton(){
    this.router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  

}
