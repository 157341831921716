<div class="container-fluid main-container-wrapper">
    <br>
  
    <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
           <span class="color-black pl-1 titlePanels">General Ledger Setting

            </span>
        </div>
        <div class="w3-card-4 classCard classCardType" >
        <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategory==1?'active':''" (click)="chartAccount()"><a>
                    Charts of Account</a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="timePeriod()"><a>
                    Custom Time Period</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="cost()"><a>
                        Cost</a></li>
                    <li [ngClass]="activeCategory==4?'active':''" (click)="paymentMethod()"><a>
                        Payment Method Type</a></li>
                        <li [ngClass]="activeCategory==5?'active':''" (click)="invoiceMethod()"><a>
                            Invoice Item Type</a></li>
                        <li [ngClass]="activeCategory==6?'active':''" (click)="rate()"><a>
                            Rates</a></li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="foreignExc()"><a>
                                Foreign Exchange Rates</a></li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="accountCat()"><a>
                                Gl Account Category</a></li>
                                <li [ngClass]="activeCategory==9?'active':''" (click)="costCenter()"><a>
                                    Cost Center</a></li>
               
            </ul>
        </div>

        <div class="create-new-leade mt-2">
            <div class="container-fluid">
              
                   
                                <div class="panel-group">
                                    <div *ngIf="activeCategory==9">
                                      
                                       
                                        <div class="panel-collapse">
                                            <br>
                                            <div class="create-new-leade mt-2">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <form class="w-100">
                                                            <div class="w3-card-4 classCard">
                                                                <header class="w3-container w3-blue">
                                                                  <div class="header-tabs">
                                                                    <h4 class=" common-styling h4Margin">
                                                                        Cost Centers List
                                                                    </h4>
                                                                 
                                                                  </div>
                                                                </header>
                                                            <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                      
                            
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="CostCenters" [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total" [scrollable]="true"
                                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                
                                                                                               
                                                                                       Organization Party ID<p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">  Gl Account ID<p-sortIcon field="name"></p-sortIcon></div>
                            
                                                                                        </th>
                                                                                     
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;">  Account Code<p-sortIcon field="price"></p-sortIcon></div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;">   Account Name<p-sortIcon field="price"></p-sortIcon>
                                                                                            
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"  *ngFor="let data of glAccountCategories;">
                                                                                            <div style="color: white;">   {{data.description}}<p-sortIcon field="price"></p-sortIcon>
                                                                                        
                                                                                            </div>
                                                                                        </th>
                                                                                      
                                                                                  
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'200px'}"
                                                                                           >
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                            {{product.organizationPartyId}}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.glAccountId}}
                                                                                        </td>
                                                                                      
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.accountCode}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.accountName}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}" 
                                                                                        *ngFor="let data of product.glCategory;"> 
                                                                                            <input type="email" [(ngModel)]="data.percentage"
                                                                                            [ngModelOptions]="{standalone: true}">%
                                                                                           </td>
                                                                                      
                                                                                       
                                                                                     
                                                                                      
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </form>
                                                        <div class="main-submit-button" style="margin-left: 0%;">
                                                           
                                                            <button type="submit" (click)="submit(CostCenters);"  class="btn btn-secondary submit-btn">Submit</button>
                                                            
                                                         
                                                        </div>
                                                       
                            
                                                    </div>
                                                </div>
                                            </div>
                                          
                                        </div>
                                    </div>
                                 


                                </div>
                          
            
            </div>
        </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 