import { Component, OnInit } from '@angular/core';
import { AddEmployeePositionService } from "./add-employee-position.service";
import { CreateEmployeePositionService } from "./add-employee-position-service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-add-employee-position',
  templateUrl: './add-employee-position.component.html',
  styleUrls: ['./add-employee-position.component.css']
})
export class AddEmployeePositionComponent implements OnInit {
  updateOppor: any;
  activeCategory = 2;
  show= false;
  personId: any;
  partyIdArray: any = [];
  internalOrganization: any;
  organizationArray: any = [];
  internalArray: any = [];
  status: any;
  statusArray: any = [];
  positionType: any;
  positionArray: any[]= [];
  solicitation: any = [];
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  private employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  employeePosition: any;
  estifromdate: string;
  estithroughdate: string;
  actualfromdate: string;
  actualthroughdate: string;
  getEstimateFrom: string;
  getEstimateTo: string;
  getActualFrom: string;
  getActualToFrom: string;
  updateestifromdate: string;
  updateestithroughdate: string;
  updateactualfromdate: string;
  updateactualthroughdate: string;

  constructor(readonly addEmployeePositionService: AddEmployeePositionService,
    readonly createEmployeePositionService: CreateEmployeePositionService,
    readonly spinner: NgxSpinnerService,
    readonly toastr: ToastrService,
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
  ) {
    this.solicitation = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      }

    ];
  }
  ngAfterViewInit(): void { }
  ngOnInit(): void {
    this.getStatus();
    this.getPartyId();
    this.getPositionType();
    this.getInternalOrganization();
  
    this.activatedRoute.queryParams.subscribe(params => {
      this.updateOppor = params["updateId"];
    });
    if (this.updateOppor) {
      this.show = true;
      this.getEmployeePosition();
    }
    else {
      this.show = false;
    }
    this.createEmployeePositionService.addEmployee_position_form = null;
    this.createEmployeePositionService.addEmployeePositionForm(this.createEmployeePositionService.addEmployee_position_form);
    this.spinner.hide();
  }
  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }

  getInternalOrganization() {
    this.spinner.show();
    this.addEmployeePositionService.getInternalOrganization().subscribe(res => {
      this.internalOrganization = res.data;
      this.spinner.hide();
      for (const value of this.internalOrganization) {
        this.internalArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }
     
    })
  }
  getStatus() {
    this.spinner.show();
    this.addEmployeePositionService.getStatus().subscribe(res => {
      this.status = res.data.emplPositionStatus;
      this.spinner.hide();
      for (const value of this.status) {
        this.statusArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  }
  getPositionType() {
    this.spinner.show();
    this.addEmployeePositionService.emplPosition().subscribe(res => {
      this.positionType = res.data.emplPositionType;
      this.spinner.hide();
      for (const value of this.positionType) {
        this.positionArray.push({
          label: value.description,
          value: value.emplPositionTypeId
        })
      }
    })
  }
  // getPositionType() {
  //   this.spinner.show();
  //   this.addEmployeePositionService.getPositionType().subscribe(res => {
  //     this.positionType = res.data.emplPositionType;
  //     this.spinner.hide();
  //     for (const value of this.positionType) {
  //       this.positionArray.push({
  //         label: value.description,
  //         value: value.emplPositionTypeId
  //       })
  //     }
  //   })
  // }
  onSubmit() {
    this.spinner.show();
    let partyid = this.createEmployeePositionService.addEmployee_position_form.get('internalOrganization').value;

    this.createEmployeePositionService.addEmployee_position_form.get('partyId').setValue(partyid);
    let fromdate = this.createEmployeePositionService.addEmployee_position_form.get('fromDate').value;
    this.estifromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");
    let throughdate = this.createEmployeePositionService.addEmployee_position_form.get('thruDate').value;
    this.estithroughdate = this.datePipe.transform(throughdate, "yyyy-MM-dd hh:mm:ss");

    let fromActualdate = this.createEmployeePositionService.addEmployee_position_form.get('actualFromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    let throughActualdate = this.createEmployeePositionService.addEmployee_position_form.get('actualThruDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");

    let formData = {
      "actualFromDate": this.actualfromdate,
      "actualThruDate": this.actualthroughdate,
      "budgetId": this.createEmployeePositionService.addEmployee_position_form.value.budgetId,
      "budgetItemSeqId": this.createEmployeePositionService.addEmployee_position_form.value.budgetItemSeqId,
      "empPositionId": this.createEmployeePositionService.addEmployee_position_form.value.empPositionId,
      "empPositionTypeId": this.createEmployeePositionService.addEmployee_position_form.value.empPositionTypeId,
      "exemptFlag": this.createEmployeePositionService.addEmployee_position_form.value.exemptFlag,
      "fromDate": this.estifromdate,
      "fulltimeFlag": this.createEmployeePositionService.addEmployee_position_form.value.fulltimeFlag,
      "partyId": this.createEmployeePositionService.addEmployee_position_form.value.partyId,
      "salaryFlag": this.createEmployeePositionService.addEmployee_position_form.value.salaryFlag,
      "statusId": this.createEmployeePositionService.addEmployee_position_form.value.statusId,
      "temporaryFlag": this.createEmployeePositionService.addEmployee_position_form.value.temporaryFlag,
      "thruDate": this.estithroughdate,
      "internalOrganization": this.createEmployeePositionService.addEmployee_position_form.value.internalOrganization
    }

  
    this.addEmployeePositionService.createEmployeePosition(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Form Submitted Successfully");
        this.router.navigate(['/hr/employee-position']);
      }

      else if (res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      this.spinner.hide();
    })


  }
  cancelSubmit() {
    this.router.navigate(['/hr/employee-position']);
  }
  getEmployeePosition() {
    this.spinner.show();
    let emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId: this.updateOppor,
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.addEmployeePositionService.getEmployeePositionById(this.updateOppor)
      .then(res => {
        this.spinner.show();
        setTimeout(() => {
          let fromdate = res.data.estimatedFromDate
          this.getEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");
          let toEstdate = res.data.estimatedThruDate
          this.getEstimateTo = this.datePipe.transform(toEstdate, "yyyy-MM-dd");
          let fromActualDate = res.data.actualFromDate
          this.getActualFrom = this.datePipe.transform(fromActualDate, "yyyy-MM-dd");
          let toActualDate = res.data.actualThruDate
          this.getActualToFrom = this.datePipe.transform(toActualDate, "yyyy-MM-dd");
          const formValue = this.createEmployeePositionService.addEmployee_position_form;
          formValue.patchValue({
            empPositionId: res.data.emplPositionId,
            statusId: res.data.statusId,
            internalOrganization: res.data.partyId,
            budgetId: res.data.budgetId,
            budgetItemSeqId: res.data.budgetItemSeqId,
            empPositionTypeId: res.data.emplPositionType,
            fromDate: this.getEstimateFrom,
            thruDate: this.getEstimateTo,
            salaryFlag: res.data.salaryFlag,
            exemptFlag: res.data.exemptFlag,
            fulltimeFlag: res.data.fulltimeFlag,
            temporaryFlag: res.data.temporaryFlag,
            actualFromDate: this.getActualFrom,
            actualThruDate: this.getActualToFrom,
            partyId: res.data.partyId
          })

        }, 3000);
       
      })
      this.spinner.hide();
  }
  onUpdate() {
    this.spinner.show();
    let fromdate = this.createEmployeePositionService.addEmployee_position_form.get('fromDate').value;
    this.updateestifromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");
    let throughdate = this.createEmployeePositionService.addEmployee_position_form.get('thruDate').value;
    this.updateestithroughdate = this.datePipe.transform(throughdate, "yyyy-MM-dd hh:mm:ss");

    let fromActualdate = this.createEmployeePositionService.addEmployee_position_form.get('actualFromDate').value;
    this.updateactualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    let throughActualdate = this.createEmployeePositionService.addEmployee_position_form.get('actualThruDate').value;
    this.updateactualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");

    let formData = {
      "actualFromDate": this.updateactualfromdate,
      "actualThruDate": this.updateactualthroughdate,
      "budgetId": this.createEmployeePositionService.addEmployee_position_form.value.budgetId,
      "budgetItemSeqId": this.createEmployeePositionService.addEmployee_position_form.value.budgetItemSeqId,
      "empPositionId": this.createEmployeePositionService.addEmployee_position_form.value.empPositionId,
      "empPositionTypeId": this.createEmployeePositionService.addEmployee_position_form.value.empPositionTypeId,
      "exemptFlag": this.createEmployeePositionService.addEmployee_position_form.value.exemptFlag,
      "fromDate": this.updateestifromdate,
      "fulltimeFlag": this.createEmployeePositionService.addEmployee_position_form.value.fulltimeFlag,
      "partyId": this.createEmployeePositionService.addEmployee_position_form.value.partyId,
      "salaryFlag": this.createEmployeePositionService.addEmployee_position_form.value.salaryFlag,
      "statusId": this.createEmployeePositionService.addEmployee_position_form.value.statusId,
      "temporaryFlag": this.createEmployeePositionService.addEmployee_position_form.value.temporaryFlag,
      "thruDate": this.updateestithroughdate,
      "internalOrganization": this.createEmployeePositionService.addEmployee_position_form.value.internalOrganization
    }

    
    this.addEmployeePositionService.updateEmployeePosition(formData, this.updateOppor).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Form Updated Successfully");
        this.router.navigate(['/hr/employee-position']);
      }

      else if (res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    })
  }

  ngOnDestroy(): void {
    this.spinner.hide();
      }

}
