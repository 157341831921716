import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-delete-confirmation',
    templateUrl: './delete-confirmation.component.html'
})
export class DeleteConfirmationComponent {
    @Output() confirm: EventEmitter<boolean>;
    constructor(readonly _NgbActiveModal: NgbActiveModal) {
        this.confirm = new EventEmitter();
    }
    submit(): void {
        this.confirm.emit(true);
        this.closeActiveTab();
    }
    closeActiveTab(): void {
        this._NgbActiveModal.close();
    }
}