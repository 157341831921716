<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="w3-card-4 classCard mt-4">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="justify-content:space-between;">
                        <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"
                            style="font-size: 14px; font-weight: bold;"><a>
                                Paycheck</a></li>
                        <li style="float: right !important;">
                            <span>
                                <button type="submit" (click)="createPage(this.PaycheckExpenses.PayChecks.paycheckType)"
                                    class="btn btn-secondary submit-btn" *ngIf="showStatus">Create New
                                </button>
                            </span>

                            <span>
                                <button type="submit" (click)="editData(this.paymentId)"
                                    class="btn btn-secondary submit-btn" *ngIf="showStatus">Edit
                                </button>
                            </span>

                            <span>
                                <button type="submit" class="btn btn-secondary submit-btn" *ngIf="showStatus"
                                    (click)="StatusToSend()">Mark
                                    as Sent
                                </button>
                            </span>

                            <span>
                                <button type="submit" class="btn btn-secondary submit-btn" *ngIf="showStatus"
                                    (click)="cancelPayment()">Cancel Payment
                                </button>
                            </span>

                            <span>
                                <button type="submit" class="btn btn-secondary submit-btn" *ngIf="showStatus"
                                    (click)="onSubmitPdf()">Print As Check
                                </button>
                            </span>

                            <span>
                                <button type="submit" class="btn btn-secondary submit-btn" *ngIf="!showStatus"
                                    (click)="voidPayment()">Void
                                </button>
                            </span>
                        </li>


                    </ul>
                </div>



                <div class=" font-13 font-medium own-user-select mb-3">
                    <div class="panel-body">
                        <div class="color-black container">
                            <div class="container" >
                                <!-- <div class="article">
                                          <p class="col-lg-6 col-12">
                                            Internal Organization</p>
                                          <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                        </div> -->
                                <div class="row">
                                    <div class="col-lg-3">Status</div>
                                    <div class="color-grey pl-20 col-lg-6">
                                        {{this.PaycheckExpenses.StatusItem[0].description}}</div>

                                </div>


                                <div class="row">
                                    <div class="col-lg-3"> Paycheck Type
                                    </div>
                                    <div class="color-grey pl-20 col-lg-6">
                                        {{this.PaycheckExpenses.PayChecks.paycheckType}}</div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-3">Pay to Party</div>
                                    <div class="color-grey pl-20 col-lg-6">{{this.PaycheckExpenses.PayChecks.partyIdTo}}
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-3">Payment Method</div>
                                    <div class="color-grey pl-20 col-lg-6">
                                        {{this.PaycheckExpenses.PaymentMethods[0].description}}</div>

                                </div>
                                <div class="row">
                                    <div class="col-lg-3">Effective Date</div>
                                    <div class="color-grey pl-20 col-lg-6">{{this.PaycheckExpenses.effectiveDate}}</div>

                                </div>
                                <div class="row">
                                    <div class="col-lg-3">Comments</div>
                                    <div class="color-grey pl-20 col-lg-6">{{this.PaycheckExpenses.PayChecks.comments}}
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-3">Check/Ref Num</div>
                                    <div class="color-grey pl-20 col-lg-6">
                                        {{this.PaycheckExpenses.PayChecks.paymentRefNum}}</div>

                                </div>


                            </div>

                        </div>
                    </div>

                </div>
                <!-- <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -74%;">
                    <button _ngcontent-cta-c128="" type="submit"
                        class="btn btn-secondary submit-btn">Submit</button>&nbsp;

                </div> -->
            </div>

            <div class="container-fluid m-0 p-0" *ngIf="PaycheckExpenses">
                <div class="row">
                    <form class="w-100">
                        <div class="port-header-contact create-lead font-medium mb-0 text-white p-0 m-1 mt-3">
                            <div class="w-100">
                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">


                                    <div class="w3-card-4 classCard" *ngIf="showStatus">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling">
                                                Deduction
                                            </h4>

                                        </div>

                                        <div class="panel-body panel">
                                            <form class="w-100">
                                                <div
                                                    class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group table-responsive">
                                                            <table class="table align-middle">
                                                                <thead>
                                                                    <tr class="table-rows" style="background: #0d3769;">
                                                                        <th
                                                                            style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                                                            S.No

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                            Paycheck and
                                                                            Witholdings

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Pay to Party

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Amount

                                                                        </th>



                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Action


                                                                        </th>



                                                                    </tr>

                                                                    <tr class="table-rows" style="background: #fff;">
                                                                        <th
                                                                            style="color: white;font-size: 12px;padding: 14px 20px !important;">


                                                                        </th>
                                                                        <th
                                                                            style="color: #000;font-size: 12px;min-width:155px;font-weight: bold;">
                                                                            Gross Amount

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">


                                                                        </th>
                                                                        <th style="padding: 0px !important;">
                                                                            <div class="row">
                                                                                <div class="col-lg-6">
                                                                                    <input type="text"
                                                                                        [(ngModel)]="PaycheckExpenses.PayChecks.amount"
                                                                                        class="form-control"
                                                                                        [ngModelOptions]="{standalone: true}">
                                                                                </div>

                                                                                <div class="col-lg-6"
                                                                                    style="margin-left: -22%;">
                                                                                    <p-dropdown
                                                                                        [options]="currencyArray"
                                                                                        filter="true"
                                                                                        [(ngModel)]="PaycheckExpenses.PayChecks.currencyUomId"
                                                                                        appendTo="body"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        class="dropdowns"
                                                                                        optionlabel="label">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>

                                                                        </th>



                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 76px;padding: 0px 20px !important;">
                                                                            <button type="submit"
                                                                                style="width: 61px !important;"
                                                                                (click)="updatePayCheck(PaycheckExpenses)"
                                                                                class="btn btn-secondary submit-btn">Update
                                                                            </button>


                                                                        </th>



                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <tr class="table-rows" class="align-bottom"
                                                                        *ngFor="let product of PaycheckAndWithHoldingsTable;let i = index;"
                                                                        style="height: 11px !important;">
                                                                        <td style="    color: black;
                                                                                            font-weight: 100;
                                                                                            font-size: 12px;
                                                                                          
                                                                                          ">

                                                                            {{i+1}}

                                                                        </td>
                                                                        <td style="    color: black;
                                                                                              font-weight: 100;
                                                                                              font-size: 12px;
                                                                                              min-width: 155px;">

                                                                            <span
                                                                                *ngIf="product.paycheckAndWitholding">{{product.paycheckAndWitholding}}</span>
                                                                            <span
                                                                                *ngIf="product.abc1">{{product.abc1}}</span>


                                                                        </td>
                                                                        <td style="min-width: 155px;">

                                                                            <p-dropdown filter="true" appendTo="body"
                                                                                [(ngModel)]="product.partyIdTo"
                                                                                class="dropdowns"
                                                                                [options]="PaytoPartyArray"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                optionlabel="label">
                                                                            </p-dropdown>

                                                                        </td>
                                                                        <td style="min-width: 155px;">
                                                                            <input type="text"
                                                                                [(ngModel)]="product.amount"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                class="form-control">

                                                                        </td>


                                                                        <td
                                                                            style="color: white;font-size: 12px;min-width: 76px;padding: 0px 20px !important;">
                                                                            <button type="submit"
                                                                                style="padding: 0px 12px !important;"
                                                                                (click)="updatePaycheckHoldings(product)"
                                                                                class="btn btn-secondary submit-btn">Update
                                                                            </button>
                                                                        </td>

                                                                    </tr>

                                                                    <tr class="table-rows" class="align-bottom"
                                                                        style="height: 11px !important;">
                                                                        <td>
                                                                        </td>

                                                                        <td style="min-width: 155px;">
                                                                            Net Pay
                                                                        </td>

                                                                        <td style="min-width: 155px;">
                                                                        </td>

                                                                        <td style="min-width: 155px;">
                                                                            <!-- <input type="text"
                                                                                            [(ngModel)]="this.PayCheck"
                                                                                            class="form-control"
                                                                                            [ngModelOptions]="{standalone: true}"> -->
                                                                            {{this.PayCheck}}
                                                                        </td>

                                                                        <td>

                                                                        </td>
                                                                    </tr>


                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <p class="paginate_data">
                                            View per page

                                        </p>

                                    </div>

                                    <div class="w3-card-4 classCard" *ngIf="!showStatus">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling">
                                                Deduction
                                            </h4>

                                        </div>

                                        <div class="panel-body panel">
                                            <form class="w-100">
                                                <div
                                                    class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group table-responsive">
                                                            <table class="table align-middle">
                                                                <thead>
                                                                    <tr class="table-rows" style="background: #0d3769;">
                                                                        <th
                                                                            style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                                                            S.No

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                            Paycheck and
                                                                            Witholdings

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Pay to Party

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Amount

                                                                        </th>







                                                                    </tr>

                                                                    <tr class="table-rows" style="background: #fff;">
                                                                        <th
                                                                            style="color: white;font-size: 12px;padding: 14px 20px !important;">


                                                                        </th>
                                                                        <th
                                                                            style="color: #000;font-size: 12px;min-width:155px;font-weight: bold;">
                                                                            Gross Amount

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">


                                                                        </th>
                                                                        <th style="padding: 0px !important;">
                                                                            <div class="row">
                                                                                <div class="col-lg-6">
                                                                                    <input type="text" disabled
                                                                                        [(ngModel)]="PaycheckExpenses.PayChecks.amount"
                                                                                        class="form-control"
                                                                                        [ngModelOptions]="{standalone: true}">
                                                                                </div>

                                                                                <div class="col-lg-6"
                                                                                    style="margin-left: -22%;">
                                                                                    <p-dropdown
                                                                                        [options]="currencyArray"
                                                                                        filter="true" [disabled]="true"
                                                                                        [(ngModel)]="PaycheckExpenses.PayChecks.currencyUomId"
                                                                                        appendTo="body"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        class="dropdowns"
                                                                                        optionlabel="label">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>

                                                                        </th>






                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <tr class="table-rows" class="align-bottom"
                                                                        *ngFor="let product of PaycheckAndWithHoldingsTable;let i = index;"
                                                                        style="height: 11px !important;">
                                                                        <td style="    color: black;
                                                                                            font-weight: 100;
                                                                                            font-size: 12px;
                                                                                          
                                                                                          ">

                                                                            {{i+1}}

                                                                        </td>
                                                                        <td style="    color: black;
                                                                                              font-weight: 100;
                                                                                              font-size: 12px;
                                                                                              min-width: 155px;">

                                                                            <span
                                                                                *ngIf="product.paycheckAndWitholding">{{product.paycheckAndWitholding}}</span>
                                                                            <span
                                                                                *ngIf="product.abc1">{{product.abc1}}</span>


                                                                        </td>
                                                                        <td style="min-width: 155px;">

                                                                            <p-dropdown filter="true" [disabled]="true"
                                                                                appendTo="body" [disabled]="true"
                                                                                [(ngModel)]="product.partyIdTo"
                                                                                class="dropdowns"
                                                                                [options]="PaytoPartyArray"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                optionlabel="label">
                                                                            </p-dropdown>

                                                                        </td>
                                                                        <td style="min-width: 155px;">
                                                                            <input type="text" disabled
                                                                                [(ngModel)]="product.amount"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                class="form-control">

                                                                        </td>



                                                                    </tr>

                                                                    <tr class="table-rows" class="align-bottom"
                                                                        style="height: 11px !important;">
                                                                        <td>
                                                                        </td>

                                                                        <td style="min-width: 155px;">
                                                                            Net Pay
                                                                        </td>

                                                                        <td style="min-width: 155px;">
                                                                        </td>

                                                                        <td style="min-width: 155px;">
                                                                            <!-- <input type="text"
                                                                                            [(ngModel)]="this.PayCheck"
                                                                                            class="form-control"
                                                                                            [ngModelOptions]="{standalone: true}"> -->
                                                                            {{this.PayCheck}}
                                                                        </td>

                                                                        <td>

                                                                        </td>
                                                                    </tr>


                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <p class="paginate_data">
                                            View per page

                                        </p>

                                    </div>

                                    <div class="w3-card-4 classCard mt-4" *ngIf="showStatus">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling">
                                                Allowance


                                            </h4>
                                            <!-- <span class="edit-buttons">
                                                            <a data-toggle="modal"
                                                                data-target="#exampleModalCenterpayRollPref">Create</a></span> -->

                                        </div>
                                        <br>
                                        <div>

                                        </div>
                                        <div class="panel-body panel">
                                            <form class="w-100">
                                                <div
                                                    class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group table-responsive">
                                                            <table class="table align-middle">
                                                                <thead>
                                                                    <tr class="table-rows"
                                                                        style="background: #0d3769;height: 12px !important;">
                                                                        <th
                                                                            style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                                                            S.No

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                            Paycheck
                                                                            Expenses

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Pay to Party

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Amount

                                                                        </th>



                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Action


                                                                        </th>



                                                                    </tr>
                                                                </thead>
                                                                <tbody>



                                                                    <tr class="align-bottom"
                                                                        style="height: 11px !important;"
                                                                        *ngFor="let product of PaycheckExpensesTable;let i = index;">
                                                                        <td style="    color: black;
                                                                                        font-weight: 100;
                                                                                        font-size: 12px;
                                                                                      
                                                                                      ">

                                                                            {{i+1}}

                                                                        </td>
                                                                        <td style="    color: black;
                                                                                              font-weight: 100;
                                                                                              font-size: 12px;
                                                                                              min-width: 155px;">

                                                                            {{product.payCheckExpense.paycheckExpenses}}

                                                                        </td>

                                                                        <td style="min-width: 155px;">
                                                                            <p-dropdown filter="true" appendTo="body"
                                                                                [(ngModel)]="product.payCheckExpense.partyIdTo"
                                                                                class="dropdowns"
                                                                                [options]="PaytoPartyArray"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                        </td>

                                                                        <td style="min-width: 155px;">
                                                                            <input type="text"
                                                                                [(ngModel)]="product.payCheckExpense.amount"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                class="form-control">
                                                                        </td>

                                                                        <td
                                                                            style="color: white;font-size: 12px;min-width: 76px;">
                                                                            <button type="submit"
                                                                                (click)="updatePaycheckExpenses(product)"
                                                                                class="btn btn-secondary submit-btn">Update
                                                                            </button>
                                                                        </td>
                                                                    </tr>


                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div class="w3-card-4 classCard mt-4" *ngIf="!showStatus">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling">
                                                Allowance


                                            </h4>


                                        </div>
                                        <br>
                                        <div>

                                        </div>
                                        <div class="panel-body panel">
                                            <form class="w-100">
                                                <div
                                                    class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group table-responsive">
                                                            <table class="table align-middle">
                                                                <thead>
                                                                    <tr class="table-rows"
                                                                        style="background: #0d3769;height: 12px !important;">
                                                                        <th
                                                                            style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                                                            S.No

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                            Paycheck
                                                                            Expenses

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Pay to Party

                                                                        </th>
                                                                        <th
                                                                            style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                            Amount

                                                                        </th>







                                                                    </tr>
                                                                </thead>
                                                                <tbody>



                                                                    <tr class="align-bottom"
                                                                        style="height: 11px !important;"
                                                                        *ngFor="let product of PaycheckExpensesTable;let i = index;">
                                                                        <td style="    color: black;
                                                                                        font-weight: 100;
                                                                                        font-size: 12px;
                                                                                      
                                                                                      ">

                                                                            {{i+1}}

                                                                        </td>
                                                                        <td style="    color: black;
                                                                                              font-weight: 100;
                                                                                              font-size: 12px;
                                                                                              min-width: 155px;">

                                                                            {{product.payCheckExpense.paycheckExpenses}}

                                                                        </td>

                                                                        <td style="min-width: 155px;">
                                                                            <p-dropdown filter="true" appendTo="body"
                                                                                [disabled]="true"
                                                                                [(ngModel)]="product.payCheckExpense.partyIdTo"
                                                                                class="dropdowns"
                                                                                [options]="PaytoPartyArray"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                        </td>

                                                                        <td style="min-width: 155px;">
                                                                            <input type="text" disabled
                                                                                [(ngModel)]="product.payCheckExpense.amount"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                class="form-control">
                                                                        </td>


                                                                    </tr>


                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>



        </div>
    </div>
</div>