<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Financial Account</span>
                <span class="color-black pl-1"> >Add New Withdrawal Payment For Financial Account </span>
                <button type="submit" (click)="cancelSubmit();"
                class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Create New Withdrawal Payment For Financial Account</a></li>
                      
          
          
                    </ul>
                  </div>
  
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="createRecon">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Payment Type<span style="color:red">*</span>
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="depositPaymentTypeArray" optionlabel="label"
                                                    formControlName="paymentTypeId" placeholder="Enter Payment Type"
                                                    filter="true">

                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Status</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <label>Sent</label>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Payment Method ID<span
                                                    style="color:red">*</span> </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="paymentMethodIdArray" optionlabel="label"
                                                formControlName="paymentMethodId"
                                                placeholder="Enter Payment Method ID" filter="true">

                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">From Customer ID <span
                                                    style="color:red">*</span> </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="partyIdArray" optionlabel="label"
                                                    formControlName="partyIdFrom" placeholder="Enter From Customer ID"
                                                    filter="true">

                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">To Customer ID <span
                                                    style="color:red">*</span> </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="partyIdArray" optionlabel="label"
                                                formControlName="partyIdTo" placeholder="Enter To Customer ID"
                                                filter="true">

                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Amount
                                                    <span style="color:red">*</span>
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="amount" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Amount">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Actual Currency Amount</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="actualCurrencyAmount"
                                                class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Actual Currency Amount">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Effective Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="effectiveDate" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Effective Date">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Reference No</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="paymentRefNum" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Reference No">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1"> Actual Currency Uom ID </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="currencyArray" optionlabel="label"
                                                formControlName="actualCurrencyUomId"
                                                placeholder="Enter Actual Currency Uom ID" filter="true">

                                            </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Fin Account Trans ID </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input formControlName="finAccountTransId" type="email" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Fin Account Trans ID">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Comments </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input formControlName="comments" type="email" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Comments">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Override Gl Account ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="giAccountArray" optionlabel="label"
                                                formControlName="overrideGlAccountId"
                                                placeholder="Enter Override Gl Account ID" filter="true">

                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Withdrawal Payment from: {{
                                                    this.finAccountId }}</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-checkbox (click)="selectDeposit($event);"></p-checkbox>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>


                                  

                                </div>


                            </form>
                            <div class="main-submit-button" style="margin-left: 67%">
                                <button type="submit" *ngIf="show" (click)="onUpdate()"
                                    class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" (click)="onSubmit()"
                                    class="btn btn-secondary submit-btn">Submit</button>

                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>