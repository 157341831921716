<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Invoices</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Invoices List
                                            </h4>

                                            <button type="submit" class="btn btn-secondary submit-btn mr-2"
                                                data-target="#invoicePopup" data-toggle="modal"
                                                (click)="createFsmInvoicePatch(); changeFormClick()">
                                                Add Invoice
                                            </button>

                                        </div>
                                    </header>

                                    <div class="all-users-infomation font-13 font-medium own-user-select"
                                        style="margin-top: 0%;">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">
                                                <div class="card own-account-table">
                                                    <p-table [value]="invoiceList" [paginator]="true" [rows]="rows"
                                                        scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>

                                                                        </p-checkbox>

                                                                        Invoice Id
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Customer Id
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Customer Name
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Technician Id
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Technician Name
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Technician Status
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'250px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Action
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>


                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        <span class="account-button"
                                                                            data-target="#invoiceidpopup"
                                                                            data-toggle="modal"
                                                                            (click)="getInvoiceById(product.getInvoice.invoiceId)">
                                                                            {{product.getInvoice.invoiceId}}</span>

                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.getInvoice.customerId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.getInvoice.customerName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.getInvoice.technicianId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.getInvoice.technicianName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.technicianStatus[0].technicianStatus}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'250px'}">
                                                                    <button type="submit"
                                                                        class="btn btn-secondary submit-btn ml-2"
                                                                        data-target="#invoicePopup" data-toggle="modal"
                                                                        (click)="updateFsmInvoicePatch(product)">Update</button>
                                                                    <button type="submit"
                                                                        class="btn btn-secondary submit-btn ml-2"
                                                                        (click)="deleteFsmInvoice(product)">Delete</button>
                                                                    <button type="submit"
                                                                        class="btn btn-secondary submit-btn ml-2"
                                                                        (click)="fsmInvoiceGenerate(product)">Download
                                                                        Invoice</button>

                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">No
                                                                            Record Found
                                                                        </h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
<div class="modal fade" id="invoicePopup" tabindex="-1" role="dialog" aria-labelledby="invoicePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="this.show" class="color-black pl-1">Add Invoice</span>
                <span *ngIf="!this.show" class="color-black pl-1">Update Invoice</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="this.show">
                                    <a>Add Invoice</a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>Update Invoice</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="invoiceForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select p-0 mb-3"
                                            style="border:1px solid grey;">
                                            <div class="col-lg-12"
                                                style="background:#015fa5!important;">
                                                <div class="row">
                                                    <div class="col-lg-6 form-group">
                                                        <h1 style="    color: #fff;
                                                        font-size: 33px;
                                                        padding-top: 60px;
                                                        padding-left: 30px;
                                                        padding-bottom: 35px;">{{ this.show ? "CREATE": "UPDATE"}} INVOICE</h1>
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Invoice ID"
                                                            formControlName="invoiceId" [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Primary Contact Email"
                                                            formControlName="primaryContactEmail"
                                                            [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Customer ID"
                                                            formControlName="customerId" [readonly]="readOnlyCode">

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Customer Name"
                                                            formControlName="customerName" [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Technician ID"
                                                            formControlName="technicianId" [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Technician Name"
                                                            formControlName="technicianName" [readonly]="readOnlyCode">

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Technician Status"
                                                            formControlName="technicianStatus"
                                                            [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Customer Problem Description"
                                                            formControlName="customerProblemDescription"
                                                            [readonly]="readOnlyCode">


                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Email"
                                                            formControlName="email" [readonly]="readOnlyCode">


                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Technician Number"
                                                            formControlName="technicianNumber"
                                                            [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Address 1"
                                                            formControlName="address1" [readonly]="readOnlyCode">


                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Address 2"
                                                            formControlName="address2" [readonly]="readOnlyCode">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter City"
                                                            formControlName="city" [readonly]="readOnlyCode">
                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Country Geo ID"
                                                            formControlName="countryGeoId" [readonly]="readOnlyCode">


                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter State Province Geo ID"
                                                            formControlName="stateProvinceGeoId"
                                                            [readonly]="readOnlyCode">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Postal Code"
                                                            formControlName="postalCode" [readonly]="readOnlyCode">
                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Equipment Required"
                                                            formControlName="equipmentRequired">


                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Equipment Required Price"
                                                            formControlName="equipmentRequiredPrice">

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Direct Labor Per Built"
                                                            formControlName="directLabourPerBuilt">




                                                    </div>

                                                    <div class="col-lg-4">

                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Equipment Parts Price"
                                                            formControlName="equipmentPartsPrice">


                                                    </div>

                                                    <div class="col-lg-4">

                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Sub Total"
                                                            formControlName="subTotal">

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Discounts"
                                                            formControlName="discounts">
                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Work Order ID"
                                                            formControlName="workOrderId" [readonly]="readOnlyCode">
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Tax"
                                                            formControlName="primaryContactPrimaryPhone"
                                                            [readonly]="readOnlyCode">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Tax"
                                                            formControlName="tax">

                                                          
                                                    </div>

                                                    <div class="col-lg-4">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Total"
                                                            formControlName="total">

                                                    </div>
                                                    <div class="col-lg-4 p-4">
                                                        <button *ngIf="this.show" type="submit"
                                                        class="btn btn-secondary submit-btn" (click)="createFsmInvoice()"
                                                        style="     background: #ffffff !important;
                                                        border: 0px solid white !important;
                                                        font-size: 13px !important;
                                                        font-weight: bold;
                                                        color: #495057 !important;">Create</button>


                                                    <button *ngIf="!this.show" type="submit"
                                                        class="btn btn-secondary submit-btn" (click)="updateFsmInvoice()"
                                                        style="     background: #ffffff !important;
                                                        border: 0px solid white !important;
                                                        font-size: 13px !important;
                                                        font-weight: bold;
                                                        color: #495057 !important;">Update</button>

                                                    </div>

                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Invoice ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Invoice ID"
                                                            formControlName="invoiceId" [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Primary Contact Email</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Primary Contact Email"
                                                            formControlName="primaryContactEmail"
                                                            [readonly]="readOnlyCode">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Customer ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Customer ID"
                                                            formControlName="customerId" [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Customer Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Customer Name"
                                                            formControlName="customerName" [readonly]="readOnlyCode">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Technician ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Technician ID"
                                                            formControlName="technicianId" [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Technician Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Technician Name"
                                                            formControlName="technicianName" [readonly]="readOnlyCode">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Technician Status</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Technician Status"
                                                            formControlName="technicianStatus"
                                                            [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Customer Problem
                                                            Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Customer Problem Description"
                                                            formControlName="customerProblemDescription"
                                                            [readonly]="readOnlyCode">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Email</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Email"
                                                            formControlName="email" [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Technician Number</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Technician Number"
                                                            formControlName="technicianNumber"
                                                            [readonly]="readOnlyCode">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Address 1</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Address 1"
                                                            formControlName="address1" [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Address 2</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Address 2"
                                                            formControlName="address2" [readonly]="readOnlyCode">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">City</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter City"
                                                            formControlName="city" [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Country Geo ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Country Geo ID"
                                                            formControlName="countryGeoId" [readonly]="readOnlyCode">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">State Province Geo ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter State Province Geo ID"
                                                            formControlName="stateProvinceGeoId"
                                                            [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Postal Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Postal Code"
                                                            formControlName="postalCode" [readonly]="readOnlyCode">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Equipment Required</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Equipment Required"
                                                            formControlName="equipmentRequired">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Equipment Required Price</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Equipment Required Price"
                                                            formControlName="equipmentRequiredPrice">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Direct Labor Per Built</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Direct Labor Per Built"
                                                            formControlName="directLabourPerBuilt">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Equipment Parts Price</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Equipment Parts Price"
                                                            formControlName="equipmentPartsPrice">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Sub Total</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Sub Total"
                                                            formControlName="subTotal">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Discounts</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Discounts"
                                                            formControlName="discounts">

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Work Order ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Work Order ID"
                                                            formControlName="workOrderId" [readonly]="readOnlyCode">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">PrimaryPhone</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Tax"
                                                            formControlName="primaryContactPrimaryPhone"
                                                            [readonly]="readOnlyCode">

                                                    </div>




                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Tax</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Tax"
                                                            formControlName="tax">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Total</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Total"
                                                            formControlName="total">

                                                    </div>


                                                </div>
                                            </div>
 -->

                                            <!-- <div class="col-lg-5 main-submit-button" style="margin-left: 46%;">
                                                <button *ngIf="this.show" type="submit"
                                                    class="btn btn-secondary submit-btn" (click)="createFsmInvoice()"
                                                    style="    background: #ffffff !important;
                              border: 0px solid white !important;
                              color: grey !important;">Create</button>
                                                <button *ngIf="!this.show" type="submit"
                                                    class="btn btn-secondary submit-btn" (click)="updateFsmInvoice()"
                                                    style="    background: #ffffff !important;
                                                    border: 0px solid white !important;
                                                    color: grey !important;">Update</button>

                                            </div> -->
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="invoiceidpopup" tabindex="-1" role="dialog" aria-labelledby="invoiceidpopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Invoice Summary</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonLL>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Invoice Summary</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row" *ngIf="this.invoiceByIdData">
                                                    <div class="col-lg-3 form-group classInputii">
                                                        <label for="exampleInputEmail1">Invoice Id :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{invoiceByIdData.invoiceId}}
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputii1">
                                                        <label for="exampleInputEmail1">Customer Id :</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        {{invoiceByIdData.customerId}}
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputii">
                                                        <label for="exampleInputEmail1">Customer Name :</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        {{invoiceByIdData.customerName}}
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputii1">
                                                        <label for="exampleInputEmail1">Technician Id :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{invoiceByIdData.technicianId}}


                                                    </div>
                                                    <div class="col-lg-3 form-group classInputii">
                                                        <label for="exampleInputEmail1">Technician Name :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{invoiceByIdData.technicianName}}


                                                    </div>
                                                    <div class="col-lg-3 form-group classInputii1">
                                                        <label for="exampleInputEmail1">Technician Status :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{invoiceByIdData.technicianStatus}}


                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal fade" id="invoiceItemSearch_Popup" tabindex="-1" role="dialog" aria-labelledby="invoiceItemSearch_Popup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Invoice Item Search</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCard" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategories==2?'active':''"><a>
                                    Invoice Item Search</a></li>


                            </ul>
                        </div>

                        <div class="row mb-3" style="width: 100%; margin-top: 1%;">
                            <div class="col-md-12">
                                <div class="w3-card-4 classCard" style="margin-left: 12px;margin-right: -18px; width: 101%;">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background-color: rgb(8, 165, 8);  color: white;">
                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important; color: white;">Please enter one or more search parameters:</p>
                                    </div>
                    
                                    <div class="">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100" >
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInputC">
                                                                    <label for="exampleInputEmail1">Category
                                                                </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <p-dropdown   placeholder="All" filter="true">
                                                                    </p-dropdown>
                                                                </div>
            
                                                                
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInputC">
                                                                    <label for="exampleInputEmail1">Item
                                                                </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Labor">
                                                                    
                                                                </div>

                                                                <button type="submit" class="btn btn-secondary submit-btn ml-2">
                                                                    Search
                                                                 </button>


            
                                                                
                                                            </div>
                                                        </div>
                                                        
            
            
                                                    </div>
                                                </form>
                                                
                                            </div>
                                        </div>
            
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3" style="width: 100%; margin-top: 1%;">
                            <div class="col-md-12">
                                <div class="w3-card-4 classCard" style="margin-left: 12px;margin-right: -18px;">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background-color: rgb(8, 165, 8);  color: white;">
                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important; color: white;">Search Results:</p>
                                    </div>
                    
                                    <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">
                                                <div class="card own-account-table">
                                                    <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background-color: rgb(97, 226, 97); ">
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </p-checkbox>
                            
                                                                        Category
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Item Name
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                            
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Description
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                            
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Price
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                            
                                                               
                                                                
                                                              
                                                               
                            
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        <span  class="account-button"  >
                                                                            {{product.data.custRequestId}}</span>
                            
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.data.custRequestTypeId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.data.productStoreId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.data.custRequestName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.data.priority}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.data.priority}}
                                                                </td>
                            
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">No
                                                                            Record Found
                                                                        </h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page
                            
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>

                </div>


            </div>

            <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin-right: 2%;margin-bottom: 2%;">
                    Close
                 </button>
            </div>

        </div>


    </div>
</div>  -->