
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AddNewModel } from './add-new-leaves-model'

@Injectable({
    providedIn: 'root'
  })


export class AddLeaveService {
  add_leave_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    private addNewModel: AddNewModel
    ) { } 

  addLeaveForm(data:any): void {
    if (!this.add_leave_form) {
        this.add_leave_form = this._formBuilder.group(this.addNewModel.mapDataToModel(data));
    }
  }
}

 

