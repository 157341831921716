import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder,FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';  import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-requirements-main',
  templateUrl: './requirements-main.component.html',
  styleUrls: ['./requirements-main.component.css']
})
export class RequirementsMainComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 3;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  openRequirementsForm: FormGroup;
  openRequirementList: any;
  statusId: { label: string; value: string; }[];
  findRequirementList: any;
  requirementTypeId: any;
  requirementTypeIdArray: any[]=[];
  facilityArray: any[]=[];
  facility: any;
  requirementStatusId: any;
  requirementStatusIdArray: any[]=[];
  ProductId: any;
  ProductIdArray: any[]=[];
  suppliers: any[];
  orderItemTypeId: any;
  orderItemTypeIdArray: any[]=[];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,    private _location: Location,
    readonly toastr: ToastrService,
    readonly mergeContactsService: MergeContactsService,
    readonly _WarehouseService: WarehouseService,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router
  ) 
  {
    this.openRequirementsForm=this._FormBuilder.group({
      requirementId:"",
      requirementTypeId:"",
      facilityId:"",
      productId:"",
      supplier:"",
      status:"",
      description:"",
      fromDate:"",
      thruDate:"",
    })

    this.statusId =[
      {
        label:'Created',
        value:'REQ_CREATED'
      },
      {
        label:'Proposed',
        value:'REQ_PROPOSED'
      }
    ]

  }

  ngOnInit(): void {
    
    this.getOpenRequirement();
    this.getFacilityId();
    this.getRequirementTypeId();
    this.getRequirementStatusId();
    this.getProductIds();
    this.getSuppliers();
    this.orderItemType();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  orderItemType() {
    this.spinner.show();
    this.myContactsService.orderItemType().subscribe(res => {
      this.orderItemTypeId = res.data[0].orderItemType;
      this.spinner.hide();
      for (const value of this.orderItemTypeId) {
        this.orderItemTypeIdArray.push({
          label: value.description,
          value: value.orderItemTypeId
        })
      }
    })
  }
  deleteRequirementPlanning(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "requirementId":product.data.requirementId 

        }
        this.myContactsService.deleteRequirementPlanning(req).subscribe((res: any) => {
          this.getOpenRequirement();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  detailPage(requirementId) {
    this._Router.navigate(['/facilities/Planning/open-requirements-detail'], { queryParams: { requirementId: requirementId } });
}
  getProductIds() {
    this.spinner.show();
    this.myContactsService.getProductIds().subscribe(res => {
      this.ProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName,
          value: value.productId
        })
      }
    })
  }
  getRequirementStatusId() {
    this.spinner.show();
    this.myContactsService.getRequirementStatusId().subscribe(res => {
      this.requirementStatusId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.requirementStatusId) {
        this.requirementStatusIdArray.push({
          label: value.description,
          value: value.statusValue
        })
      }
    })
   
  }
  getRequirementTypeId() {
    this.spinner.show();
    this.myContactsService.getRequirementTypeId().subscribe(res => {
      this.requirementTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.requirementTypeId) {
        this.requirementTypeIdArray.push({
          label: value.description,
          value: value.requirementTypeId
        })
      }
    })
   
  }
  getFacilityId() {
    this.spinner.show();
    this.myContactsService.getFacilityId().subscribe(res => {
      this.facility = res.data[0].getFacilityId;
      this.spinner.hide();
      for (const value of this.facility) {
        this.facilityArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
    })
   
  }
  getSuppliers(): void {

    this.spinner.show();
    this._WarehouseService.getSuppliers()
        .then(data => {
            this.spinner.hide();
            this.suppliers = data.data.map(value => {
                return {
                    label: value.partyId,
                    value: value.partyId
                };
            });
        });
        
}
  reset() {
    this.openRequirementsForm.reset();
    this.getOpenRequirement();

  }
  getOpenRequirement() {
    this.spinner.show();
    const formData = {
      "description": "",
      "facilityId": "",
      "groupName": "",
      "productId": "",
      "requiredByDate": "",
      "requirementId": "",
      "requirementTypeId": "",
      "statusId": "",
    }

    this.mergeContactsService.getOpenRequirement(formData).subscribe(res => {
      this.openRequirementList = res.data;
      this.spinner.hide();

    })
  } 
  findOpenRequirement() { 
    this.spinner.show();
    const formData = {
      "description": this.openRequirementsForm.value.description ? this.openRequirementsForm.value.description : '',
      "facilityId":this.openRequirementsForm.value.facilityId ? this.openRequirementsForm.value.facilityId : '',
      "groupName": this.openRequirementsForm.value.supplier ? this.openRequirementsForm.value.supplier : '',
      "productId":this.openRequirementsForm.value.productId ? this.openRequirementsForm.value.productId : '',
      "requiredByDate": this.openRequirementsForm.value.fromDate ? this.openRequirementsForm.value.fromDate : '',
      "requirementId": this.openRequirementsForm.value.requirementId ? this.openRequirementsForm.value.requirementId : '',
      "requirementTypeId": this.openRequirementsForm.value.requirementTypeId ? this.openRequirementsForm.value.requirementTypeId : '',
      "statusId":this.openRequirementsForm.value.status ? this.openRequirementsForm.value.status : '',
    }
    this.mergeContactsService.getOpenRequirement(formData).subscribe(res => {
      this.openRequirementList = res.data;
      this.spinner.hide();

    })
  }
  openInventory()
  {
    this._Router.navigate(['/facilities/Planning']);

  }
  openFindRequirements()
  {
    this._Router.navigate(['/facilities/Planning/find-requirements']);

  }
  openRequirements()
  {
    this._Router.navigate(['/facilities/Planning/open-requirements']);

  }
  openProductRequirements()
  {
    this._Router.navigate(['/facilities/Planning/product-requirements']);
    
  }
  openInternalRequirements()
  {
    this._Router.navigate(['/facilities/Planning/internal-requirements']);

  }
  openTransferRequirements()
  {
    this._Router.navigate(['/facilities/Planning/transfer-requirements']);

  }
  openSalesOrderHistory()
  {
    this._Router.navigate(['/facilities/Planning/upload-sales-order-history']);

  }
  openRunMRP()
  {
    this._Router.navigate(['/facilities/Planning/run-mrp']);

  }
  openViewMRP()
  {
    this._Router.navigate(['/facilities/Planning/view-mrp']);

  }
  openConfigureWarehouses()
  {
    this._Router.navigate(['/facilities/Planning/configure-warehouse']);

  } 
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
