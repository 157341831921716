import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UpdateEventModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        communicationEventId:(data && data.communicationEventId) ? data.communicationEventId : null,
        currentStatusId:(data && data.currentStatusId) ? data.currentStatusId : null,
        custRequestId:(data && data.custRequestId) ? data.custRequestId : null,
        description:(data && data.description) ? data.description : null,
        estimatedCompletionDate:(data && data.estimatedCompletionDate) ? data.estimatedCompletionDate : null,
        estimatedStartDate:(data && data.estimatedStartDate) ? data.estimatedStartDate : null,
        fromDate:(data && data.fromDate) ? data.fromDate : null,
        partyId:(data && data.partyId) ? data.partyId : null,
        priority:(data && data.priority) ? data.priority : null,
        quickAssignPartyId:(data && data.quickAssignPartyId) ? data.quickAssignPartyId : null,
        requirementId:(data && data.requirementId) ? data.requirementId : null,
        roleTypeId:(data && data.roleTypeId) ? data.roleTypeId : null,
        statusId:(data && data.statusId) ? data.statusId : null,
        thruDate:(data && data.thruDate) ? data.thruDate : null,
        webSiteId:(data && data.webSiteId) ? data.webSiteId : null,
        workEffortName:(data && data.workEffortName) ? data.workEffortName : null,
        workEffortPurposeTypeId:(data && data.workEffortPurposeTypeId) ? data.workEffortPurposeTypeId : null,
        workEffortTypeId:(data && data.workEffortTypeId) ? data.workEffortTypeId : null,

    });
   
    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    communicationEventId: new FormControl(data.communicationEventId),
    currentStatusId: new FormControl(data.currentStatusId),
    custRequestId: new FormControl(data.custRequestId),
    description: new FormControl(data.description),
    estimatedCompletionDate: new FormControl(data.estimatedCompletionDate),
    estimatedStartDate: new FormControl(data.estimatedStartDate),
    fromDate: new FormControl(data.fromDate),
    partyId: new FormControl(data.partyId),
    quickAssignPartyId: new FormControl(data.quickAssignPartyId),
    requirementId: new FormControl(data.requirementId),
    roleTypeId: new FormControl(data.roleTypeId),
    priority: new FormControl(data.priority),
    statusId: new FormControl(data.statusId),
    thruDate: new FormControl(data.thruDate),
    webSiteId: new FormControl(data.webSiteId),
    workEffortName: new FormControl(data.workEffortName),
    workEffortPurposeTypeId: new FormControl(data.workEffortPurposeTypeId),
    workEffortTypeId: new FormControl(data.workEffortTypeId),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
