import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from "./employess.service";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddEmployeeFormService } from '../add-new-employee/add-new-employment-service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css']
})
export class EmployeesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  array = [
    {
      label: 'Y',
      value: 'Y'
    },

    {
      label: 'N',
      value: 'N'
    }];
  gender = [
    {
      label: 'Male',
      value: 'M'
    },
    {
      label: 'Female',
      value: 'F'
    },
    {
      label: 'Transgender',
      value: 'T'
    }];
  activeCategory = 2;
  contactMechId: any;
  firstName: any;
  firstNameSearchType: any;
  lastName: any;
  lastNameSearchType: any;
  partyIds: any;
  partyIdSearchType: any;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  private employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search

  }
  employeeList: any;
  opportunityIdArray: any = [];
  advanceSearch: {

    firstName: string;
    firstNameSearchType: string;
    lastName: string;
    lastNameSearchType: string;
    partyId: string;
    partyIdSearchType: string;
    userLogin: string,
    login: string
  };
  countryArray: any[]=[];
  internalOrganization: any;
  organizationArray: any[]=[];
  status: any[];
  maritalStatus: any[];
  stateData: any;
  stateArray: any[]=[];
  employeeForm: FormGroup;
  editMode: boolean;
  id: any;
  constructor(readonly accountsService: AccountsService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly employeeService: EmployeeService,
    readonly _FormBuilder: FormBuilder,
        private _location: Location,
    
    public addEmployeeFormService: AddEmployeeFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    ) {
      this.employeeForm = this._FormBuilder.group({
        accountNo: [''],
        address1: ['', [Validators.required]],
        address2: [''],
        areaCode: [''],
        billingRateLevel: [''],
        birthDate: [''],
        city: ['', [Validators.required]],
        contactNumber: [''],
        countryCode: [''],
        countryGeoId: [''],
        description: [''],
        emailAddress: [''],
        ethnicity: [''],
        extension: [''],
        firstName: ['', [Validators.required]],
        fromDate: [''],
        gender: [''],
        internalOrganization: [''],
        lastName: ['', [Validators.required]],
        maritalStatusEnumId: [''],
        middleInitial: [''],
        postalCode: ['', [Validators.required]],
        socialSecurityNumber: [''],
        stateProvinceGeoId: [''],
        statusId: [''],
        title: [''],
        usCitizen: ['']
      });
      this.status = [];
      this.countryArray = [];
      this.maritalStatus = [];
      this.editMode = false;
    this.advanceSearch = {
      firstName: '',
      firstNameSearchType: 'Contains',
      lastName: '',
      lastNameSearchType: 'Contains',
      partyId: '',
      partyIdSearchType: 'Contains',
      userLogin: 'Contains',
      login: ''
    };
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
  
    this.getCountryList();
    this.getInternalOrganization();
    this.getEmployeeList();
    
    this.getPartyStatus();
    this.employeeForm.controls.countryGeoId.valueChanges.subscribe(value => {
      if (value) {
        this.getStateList(value);
      }
    });
    this.getMaritalStatus();
   
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getCountryList() {
    this.spinner.show();
    this.employeeService.getCountry().subscribe(res => {
  
      var country = res.data.countryList;
      this.spinner.hide();
      this.spinner.hide();
      country.forEach(element => {
        this.countryArray.push({
          label: element.split(':')[0].trim(),
          value: element.split(':').pop().trim()
        });

      })

    })

  }
  getInternalOrganization() {
    this.spinner.show();
    this.employeeService.internalOrganization().subscribe(res => {
      this.internalOrganization = res.data;
      this.spinner.hide();
      for (const value of this.internalOrganization) {
        this.organizationArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }
    })
  }
  getPartyStatus(): void {
    this.spinner.show();
    this.employeeService.getPartyStatus()
      .subscribe(data => {
        this.spinner.hide();
        if (data.success) {
          this.status = data.data.map(value => {
            return {
              label: value.description,
              value: value.statusCode
            }
          });
        }
      });
     
  }
  getMaritalStatus(): void {
    this.spinner.show();
    this.employeeService.getMaritalStatus()
      .subscribe(data => {
        this.spinner.hide();
        this.maritalStatus = data.data.map(value => {
          return {
            label: value.description,
            value: value.enumId
          }
        });

      });
      
  }
  getStateList(countryId: string) {
    this.spinner.show();
    this.employeeService.getState(countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(':');
        var stateIndex = splitState[0];
       
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(':').pop().trim()
        })
      })
    })

  }
  onSubmit(): void {

    if (this.employeeForm.valid) {
      this.spinner.show();
      this.employeeService.createEmployeeData({
        ...this.employeeForm.value, ...{
          birthDate: this.employeeForm.value.birthDate ? moment(this.employeeForm.value.birthDate).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          if (data.success) {
            this.id=data.data.partyId
            this.toastr.success('Created');
            this.spinner.hide();
            this.employeeForm.reset();
            this.getEmployeeList();
            this.closebutton.nativeElement.click();
          
              this.router.navigate(['/hr/employees/employee-details'], { queryParams: { employeeDetailId: this.id } })
           
          
            //this.cancelSubmit();
          }
        });
    }
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  getEmployeeList() {
    this.spinner.show();
    const req = {

      'firstName': this.advanceSearch.firstName ? this.advanceSearch.firstName : '',
      'firstNameSearchType': this.advanceSearch.firstNameSearchType ? this.advanceSearch.firstNameSearchType : '',
      'lastName': this.advanceSearch.lastName ? this.advanceSearch.lastName : '',
      'lastNameSearchType': this.advanceSearch.lastNameSearchType ? this.advanceSearch.lastNameSearchType : '',
      'partyId': this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
      'partyIdSearchType': this.advanceSearch.partyIdSearchType ? this.advanceSearch.partyIdSearchType : '',
      "userLogin": this.advanceSearch.login ? this.advanceSearch.login : '',
      "userLoginSearchType": this.advanceSearch.userLogin ? this.advanceSearch.userLogin : '',
    }
    this.employeeService.getListEmployee(this.employessSize, req).subscribe(res => {
      this.employeeList = res.data;
      this.spinner.hide();
    })
    
  }

  employeeDetail(id) {
    this.router.navigate(['/hr/employees/employee-details'], { queryParams: { employeeDetailId: id } })
  }


  advanceSearchContacts(): void {
    this.employeeList = [];
   this.spinner.show();
    const req = {

      'firstName': this.advanceSearch.firstName ? this.advanceSearch.firstName : '',
      'firstNameSearchType': this.advanceSearch.firstNameSearchType ? this.advanceSearch.firstNameSearchType : '',
      'lastName': this.advanceSearch.lastName ? this.advanceSearch.lastName : '',
      'lastNameSearchType': this.advanceSearch.lastNameSearchType ? this.advanceSearch.lastNameSearchType : '',
      'partyId': this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
      'partyIdSearchType': this.advanceSearch.partyIdSearchType ? this.advanceSearch.partyIdSearchType : '',
      "userLogin": this.advanceSearch.login ? this.advanceSearch.login : '',
      "userLoginSearchType": this.advanceSearch.userLogin ? this.advanceSearch.userLogin : '',
    }
    this.employeeService.getListEmployee(this.employessSize, req)
      .subscribe(resp => {
        if (resp.success) {
        
          this.employeeList = resp.data;
          this.spinner.hide();

        } 
      })
     
  }
  resetFilter() {
    this.spinner.show();
    this.employeeList = [];
    this.advanceSearch.firstName = '';
    this.advanceSearch.lastName = '';
    this.advanceSearch.partyId = '';
    this.advanceSearch.login = '';
    const req = {

      'firstName': '',
      'firstNameSearchType': this.advanceSearch.firstNameSearchType ? this.advanceSearch.firstNameSearchType : '',
      'lastName': '',
      'lastNameSearchType': this.advanceSearch.lastNameSearchType ? this.advanceSearch.lastNameSearchType : '',
      'partyId': '',
      'partyIdSearchType': this.advanceSearch.partyIdSearchType ? this.advanceSearch.partyIdSearchType : '',
      "userLogin": '',
      "userLoginSearchType": this.advanceSearch.userLogin ? this.advanceSearch.userLogin : '',
    }
    this.employeeService.getListEmployee(this.employessSize, req).subscribe(res => {
      this.employeeList = res.data;
      this.spinner.hide();
    })
   
  }
  export() {
    this.spinner.show();
    this.employeeService.getExportEmployee().subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'employee.xlsx';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }) }
  importPage() {
    this.router.navigate(['/hr/employees/employee-import'])
  }
  updateData(eupdateId) {
    this.router.navigate(['/hr/employees/add-employee'], { queryParams: { updateId: eupdateId } })
  }
  legalName(fname,lname){
    if(fname && lname){
      this.activeCategory=3;
    }
    else{
      this.toastr.error("First Name and Last Name is required");
    }
  }
  selectStatus(status){
    if(status){
      this.activeCategory=4;
    }
    else{
      this.toastr.error("Status is required");
    }
  
  }
  selectAddress(address,city,postal){
    if(address && city && postal){
      this.activeCategory=5;
    }
    else{
      this.toastr.error("Address,City and Postal is required");
    }
  }
  selectContact(contact){
    if(contact){
      this.activeCategory=6;
    }
    else{
      this.toastr.error("Contact Number is required");
    }
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  statusInfo(fname,lname){
    if(fname && lname){
      this.activeCategory=3;
    }
    else{
      this.toastr.error("Fill Legal Name.");
    }
  }
  addressValid(status){
    if(status){
      this.activeCategory=4;
    }
    else{
      this.toastr.error("Fill Status Form.");
    }
  
  }
  contcatValid(address,city,postal){
    if(address && city && postal){
      this.activeCategory=5;
    }
    else{
      this.toastr.error("Fill Address Form.");
    }
  }
  additionalValida(contact){
    if(contact){
      this.activeCategory=6;
    }
    else{
      this.toastr.error("Fill Contact Form.");
    }
  }
  ngOnDestroy(): void {
this.spinner.hide();
  }
}
