<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Sales</span>
               
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategoriesA==1?'active':''" (click)="toAccount()"><a>
                                Account</a></li>
                            <li [ngClass]="activeCategoriesA==2?'active':''" (click)="toContact()" ><a>
                                Contact</a></li>
                            
                            <li [ngClass]="activeCategoriesA==3?'active':''" (click)="toOpportunity()" ><a>
                                Opportunity</a></li>

                            <li [ngClass]="activeCategoriesA==4?'active':''" (click)="toActivities()" ><a>
                                Activities</a></li>
                            
                               
                            
                        </ul>
                    </div>

                  

                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategories==4">
                                        <div class="w3-card-4 classCard ">
                                            <div class="container-fluid">
                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                    style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                                                    <li [ngClass]="activeCategoies1==1?'active':''">
                                                        <a (click)="openEvents()">Events</a>
                                                    </li>
                                                    <li [ngClass]="activeCategoies1==2?'active':''">
                                                        <a (click)="openTasks()">Tasks</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                <div class="w3-card-4 classCardView w-100">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling hleft">
                                                            Pending Activities
                                                            <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                                data-toggle="modal" data-target="#taskPopup">New Task</button>
                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="pendingActivityTaskCrmAll" [paginator]="true"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'200px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Type
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'200px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Purpose
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Activity
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Status
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Scheduled Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Due Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                               
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'200px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span
                                                                                             class="account-button"  >
                                                                                            {{product.workEffortTypeId}}
                                                                                        </span>
                        
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'200px'}">
                                                                                    {{product.workEffortPurposeTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.pendingActivityTaskId}}
                                                                                   
                                                                                 
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.currentStatusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.estimatedStartDateDate
                                                                                    |date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.estimatedCompletionDate
                                                                                    |date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                             
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                        
                                                                    <p class="paginate_data"> View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Tasks &amp; Events</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">

                <div class="container-fluid">

                    <ul class="tabbing-section tabbing-accordians tabClass"
                        style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">

                        <li [ngClass]="activeCategories==8?'active':''" (click)="toLead()"><a>
                            Leads</a></li>
                    <li [ngClass]="activeCategories==2?'active':''" (click)="toContact()"><a>
                            Contact</a></li>
                    <li [ngClass]="activeCategories==1?'active':''" (click)="toAccount()"><a>
                            Account</a></li>
                    <li [ngClass]="activeCategories==3?'active':''" (click)="toOpportunity()"><a>
                            Opportunity</a></li>
                    <li [ngClass]="activeCategories==9?'active':''" (click)="toTasks()"><a>
                            Tasks</a></li>
                    <li [ngClass]="activeCategories==7?'active':''" (click)="toCases()"><a>
                            Cases</a></li>



                    <li [ngClass]="activeCategories==4?'active':''" (click)="toActivities()"><a>
                            Activities Campaign</a></li>

                    <li [ngClass]="activeCategories==10?'active':''" (click)="toForecast()"><a>
                            Forecast </a></li>
                    <li [ngClass]="activeCategories==11?'active':''" (click)="toDocuments()"><a>
                            Documents </a></li>
                    <li [ngClass]="activeCategories==12?'active':''" (click)="toNotes()"><a>
                            Notes </a></li>
                    <li [ngClass]="activeCategories==13?'active':''" (click)="toCalendar()"><a>
                            Calendar </a></li>
                            <li [ngClass]="activeCategories==14?'active':''" (click)="toEmails()"><a>
                                Emails </a></li>

                    <li [ngClass]="activeCategories==5?'active':''" (click)="toQuotes()"><a>
                            Quotes </a></li>

                    <li [ngClass]="activeCategories==6?'active':''" (click)="toSalesOrder()"><a>
                            Sales order</a></li> 




                    </ul>
                </div>

                <div class="w3-card-4 classCard m-3">

                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                            <!-- <li [ngClass]="activeCategory==1?'active':''">
                                <a (click)="openEvents()">Events</a>
                            </li> -->
                            <li [ngClass]="activeCategory==2?'active':''">
                                <a (click)="openTasks()">Tasks</a>
                            </li>
                        </ul>
                    </div>
                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                        <div class="w3-card-4 classCardView w-100">
                            <div class="header-tabs" >
                                <h4 class="common-styling hleft">
                                    Pending Activities
                                    <button type="button" class="btn btn-secondary submit-btn ml-2"
                                        data-toggle="modal" data-target="#taskPopup">New Task</button>
                                </h4>
                            </div>
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12 col-12">
                                    <div class="form-group">
                                        <div class="card own-account-table">
                                            <p-table [value]="pendingActivityTaskCrmAll" [paginator]="true"
                                                [rows]="rows" scrollWidth="100%"
                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                [totalRecords]="total" [scrollable]="true"
                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                    <tr style="background: #0d3769;">
                                                        <th [ngStyle]="{'width':'200px'}"
                                                            pSortableColumn="code">
                                                            <div class="checkbox-align"
                                                                style="color: white;">
                                                                <p-checkbox styleclass="custom-checkbox"
                                                                    binary="true"
                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div
                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div
                                                                            class="ui-helper-hidden-accessible">
                                                                            <input type="checkbox"
                                                                                name="undefined"
                                                                                value="undefined">
                                                                        </div>
                                                                        <div role="checkbox"
                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span
                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                    <!---->
                                                                </p-checkbox>
                                                                Type
                                                                <p-sortIcon field="code">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input
                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'200px'}"
                                                            pSortableColumn="name">
                                                            <div style="color: white;">
                                                                Purpose
                                                                <p-sortIcon field="name">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input
                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'180px'}"
                                                            pSortableColumn="name">
                                                            <div style="color: white;">
                                                                Activity
                                                                <p-sortIcon field="name">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input
                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'180px'}"
                                                            pSortableColumn="name">
                                                            <div style="color: white;">
                                                                Status
                                                                <p-sortIcon field="name">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input
                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'180px'}"
                                                            pSortableColumn="name">
                                                            <div style="color: white;">
                                                                Scheduled Date
                                                                <p-sortIcon field="name">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input
                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'180px'}"
                                                            pSortableColumn="name">
                                                            <div style="color: white;">
                                                                Due Date
                                                                <p-sortIcon field="name">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input
                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                       
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-product>
                                                    <tr>
                                                        <td [ngStyle]="{'width':'200px'}">
                                                            <div class="checkbox-align">
                                                                <p-checkbox styleclass="custom-checkbox"
                                                                    binary="true"
                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div
                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div
                                                                            class="ui-helper-hidden-accessible">
                                                                            <input type="checkbox"
                                                                                name="undefined"
                                                                                value="undefined">
                                                                        </div>
                                                                        <div role="checkbox"
                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span
                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                    <!---->
                                                                </p-checkbox>
                                                                <span
                                                                     class="account-button"  >
                                                                    {{product.workEffortTypeId}}
                                                                </span>

                                                            </div>
                                                        </td>
                                                        <td [ngStyle]="{'width':'200px'}">
                                                            {{product.workEffortPurposeTypeId}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'180px'}">
                                                            {{product.pendingActivityTaskId}}
                                                           
                                                         
                                                        </td>
                                                        <td [ngStyle]="{'width':'180px'}">
                                                            {{product.currentStatusId}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'180px'}">
                                                            {{product.estimatedStartDateDate
                                                            |date:'yyyy-MM-dd hh:mm:ss'}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'180px'}">
                                                            {{product.estimatedCompletionDate
                                                            |date:'yyyy-MM-dd hh:mm:ss'}}
                                                        </td>
                     
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage" let-columns>
                                                    <tr>
                                                        <td>
                                                            <span class="text-center">
                                                                <h4 class="ml-2">No Record Found</h4>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>

                                            <p class="paginate_data"> View per page </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
<div class="modal fade" id="taskPopup" tabindex="-1" role="dialog" aria-labelledby="taskPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Create Task</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Task
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="taskForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Subject</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Subject"
                                                            placeholder="Enter Subject">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Security</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="securityArray" formControlName="Security"
                                                            placeholder="Enter Security">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Related Party</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray" formControlName="RelatedParty"
                                                            placeholder="Enter Related Party">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Opportunity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="SalesOpportunityArray"
                                                            formControlName="Opportunity"
                                                            placeholder="Enter Opportunity">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Case</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="CasesArray" formControlName="Case"
                                                            placeholder="Enter Case">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Purpose</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="PurposeArray" formControlName="Purpose"
                                                            placeholder="Enter Purpose">
                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Start Date and Time</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="StartDateandTime"
                                                            placeholder="Enter Start Date and Time">
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hour"
                                                            optionlabel="label" formControlName="StartDateandHour">
                                                            :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minute"
                                                            optionlabel="label" formControlName="StartDateMinutes">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="StartDateAmPm"
                                                            [options]="amPM" name="amPm" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">End Date and Time</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="CompletionDateandTime"
                                                            placeholder="Enter End Date and Time">
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hour"
                                                            optionlabel="label" formControlName="CompletionDateHour"> :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minute"
                                                            optionlabel="label" formControlName="CompletionDateMinutes">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="CompletionAmPm"
                                                            [options]="amPM" name="amPm" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Duration"
                                                            placeholder="Enter Duration">

                                                    </div>

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Description"
                                                            placeholder="Enter Description">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Availability</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="AvailabilityArray" formControlName="Availability"
                                                            placeholder="Enter Availability">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Ignore Scheduling
                                                            Conflicts</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="yesNoArray"
                                                            formControlName="IgnoreSchedulingConflicts"
                                                            placeholder="Enter Ignore Scheduling Conflicts">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createPendingActivityTask()">Create</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>