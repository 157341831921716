import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from '../accounts/accounts.service';

@Component({
  selector: 'app-create-communication-order',
  templateUrl: './create-communication-order.component.html',
  styleUrls: ['./create-communication-order.component.css']
})
export class CreateCommunicationOrderComponent implements OnInit {
  activeCategory=2;
  createOrder:FormGroup
  commEventId: any;
  Products: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  search= '';
  accountsList:any[]=[]
  relatedContact:any[]=[]
  rows = 50;

  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  order: any;
  orderArray: any[]=[];
  constructor(readonly router: Router,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    
    readonly spinner: NgxSpinnerService,) { 
    this.createOrder = this._FormBuilder.group({
      orderId: ['']
    
     
 
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); } 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.commEventId = params['commEventId'];
     
    });
    this.findOrdersList();
  }
  findOrdersList() {
    this.spinner.show();
    const req = {
      "billingAccountId": "",
      "correspondingPoId": "",
      "countryGeoId": "",
      "createdBy": "",
      "externalId": "",
      "filterInventoryProblems": "",
      "filterPOsOpenPastTheirETA": "",
      "filterPOsWithRejectedItems": "",
      "filterPartiallyReceivedPOs": "",
      "gatewayAvsResult": "",
      "gatewayScoreResult": "",
      "goodIdentificationIdValue": "",
      "goodIdentificationTypeId": "",
      "hasBackOrders": "",
      "hideFields": "",
      "includeCountry": "",
      "internalCode": "",
      "inventoryItemId": "",
      "isViewed": "",
      "lookupFlag": "",
      "maxDate": "",
      "minDate": "",
      "orderId": "",
      "orderName": "",
      "orderStatusId": [
        ""
      ],
      "orderTypeId": "",
      "orderWebSiteId": "",
      "partyId": "",
      "paymentStatusId": "",
      "productId": "",
      "productStoreId": "",
      "roleTypeId": "",
      "salesChannelEnumId": "",
      "serialNumber": "",
      "shipmentMethod": "",
      "showAll": "",
      "softIdentifier": "",
      "userLoginId": ""
    }
    this.accountsService.findOrdersList(this.finSize, req).subscribe((res: any) => {

      this.order = res.data.listOrders;
      this.spinner.hide();

      for (const value of this.order) {
        this.orderArray.push({
          label: value.orderId,
          value: value.orderId
        })
      }


    })
   
  }
  
  cancelSubmit(){
    this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:this.commEventId} });
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "orderId": this.createOrder.value.orderId,
     
    }

    this.accountsService.postCrmEventOrderList(this.commEventId,requestData.orderId).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:this.commEventId} });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  ngOnDestroy(): void {

    this.spinner.hide();
      } 
}
