import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InterviewTypeModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        description:(data && data.description) ? data.description : null,
        jobInterviewTypeId:(data && data.jobInterviewTypeId) ? data.jobInterviewTypeId : null,
    });

    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    description: new FormControl(data.description,[Validators.required]),
    jobInterviewTypeId: new FormControl(data.jobInterviewTypeId),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
