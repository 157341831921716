import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { request } from 'http';
import { NgWizardService } from 'ng-wizard';
import { OrderManagementService } from 'src/app/order-management/order-management.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { HeaderService } from "src/app/header/header.service"
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
import { NgWizardConfig, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { of } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-main-warehouse',
  templateUrl: './main-warehouse.component.html',
  styleUrls: ['./main-warehouse.component.css']
})
export class MainWarehouseComponent implements OnInit {

  @ViewChild('closebutton1') closebutton1;
  activeCategories = 2;
  products3: any;
  isShown = false;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search = "";
  show: boolean;
  listVendorsIntial: any;
  listVendorsIntialArray: any[] = [];
  createVendor: FormGroup;
  partyId: any;
  activeCategory = 2;
  dataParty: any;
  firstName: any;
  lastName: any;
  resdataVendor: any;
  addressVendor: string;
  emailAddress: any;
  phoneNumber: any;
  faxNumber: any;
  advanceSearch: {

    manifestCompanyName: string,
    manifestCompanyNameSearchType: string,
    manifestCompanyTitle: string,
    manifestCompanyTitleSearchType: string,
    manifestLogoUrl: string,
    manifestLogoUrlSearchType: string,
    manifestPolicies: "",
    manifestPoliciesSearchType: string,
    partyId: string,
    partyIdSearchType: string


  };
  readonly employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: { label: string; value: string; }[];
  listVendors: any;
  agreementType: string;
  personId: any;
  partyIdArray: any[] = []

  ///////////////////

 
  activeCategoryValue=2;
  activeCategoryParty=1;
  activeCategoryPayment=1;
  checkOutPaymentId: string;
  paymentMethodTypes: any[];
  billingAccount: any[];
  paymentMethod: string;
  activeCategoryTerm=1;
  activeCategorySalesOrder=1;
  activeCategorySales=2
  activeCategoryPurchase=2;
  supplierList: any[];
  agreementList: any[];
  addressList: any[];
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],
    }
  };
  public cols: any[];
  getContacts: any = [];
  poContinue: any = [];
  currencyArray: any = [];
  catalogueArray: any = [];
  workEffortArray: any = [];
  partyIds: any[];
  isParty: boolean;
  isRole: boolean;
  additionalPartyForm: {
    additionalPartyId: string,
    additionalRoleTypeId: string
  };
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: "",
  };

  productStoresListArray: any = [];
  salesOrderListArray: any = [];
  userLoginArray: any = [];
  customerArray: any = [];
  supplierArray: any = [];
  createOrderSales: any = {};
  createOrderPurchase: any = {};
  organisationsArray: any = [];
 
  isPurchase: boolean;
  isSales: boolean;
  soContinue:any=[];
 
  productListArray: any = [];
  soFormData: any = {};
  orderItems: any[];
  shippingEstimate: number;
  totalAmount: any;
  promotions: any[];
  promoId: string;
  promotionValue: any[];
  activeCategoryOrderTab=1;
  activeCategoryShip=1;
  shippingAddress: any;
  activeCategoryValueMain=3;
  partyIdValue: string;
  carrierShipments: any[];
  shipmentOption: string;
  notes: {
    internalNote: string,
    shippingNote: string
  };
  shipmentCustom: {
    shipDateBefore: string,
    shipDateAfter: string,
    specialInstruction: string,
    isGift: boolean,
    giftMessage: string,
    shipAtOnce: boolean
  };
  showButton=false;
  faEdit = faEdit;
  faTrash = faTrash;
  orderTermType: any[];
  orderTermForm: FormGroup;
  editMode: boolean;
  isSearchPage: boolean;
  orderTerm: any;
  orderTerms: any[];
  activeCategoryComfirm=1;
  reviewOrderValue: any;
  roleValue = [
    {
      label: 'EMPLOYEE',
      value: 'EMPLOYEE'
    },
    {
      label: 'Not Applicable',
      value: '_NA_'
    }];
  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly myContactsService: MyContactsService,
    readonly router: Router,
private _location: Location,
readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _ToastrService: ToastrService,
    readonly ngWizardService: NgWizardService,
    readonly _OrderManagementService: OrderManagementService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly HeaderService: HeaderService,
  ) {
    this.advanceSearch = {
      manifestCompanyName: "",
      manifestCompanyNameSearchType: "Contains",
      manifestCompanyTitle: "",
      manifestCompanyTitleSearchType: "Contains",
      manifestLogoUrl: "",
      manifestLogoUrlSearchType: "Contains",
      manifestPolicies: "",
      manifestPoliciesSearchType: "Contains",
      partyId: "",
      partyIdSearchType: "Contains"
    };



    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      }, {
        label: 'Equals',
        value: 'Equals'
      }, {
        label: 'Is Empty',
        value: 'Is Empty'
      }, {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];

    this.createVendor = this._FormBuilder.group({
      partyId: [''],
      Name: [''],
      type: [''],
      DistributorName: [''],
      CompanyWebsite: [''],
      DistributorisActive: [''],
      individual: [''],
      DistributorForms: [''],
      Map: [''],
      manifestCompanyTitle: [''],
      manifestPolicies: [''],
      manifestCompanyName: [''],
      manifestLogoUrl: [''],
      Phone: [''],
      DistributorPhone: [''],
      DistributorFax: [''],
      DistributorEmail: [''],
      CreditLimit: [''],
      billingRateLevel: [''],
      company: [''],
      CompanyTaxID: [''],
      EligibleFor: [''],
      agreement: [''],
      PrintCheckAs: [''],
      accountNo: ['']




    });
    this.isParty = false;
    this.isRole = false;
    this.partyIds = [];
    this.additionalPartyForm = {
      additionalPartyId: '',
      additionalRoleTypeId: ''
    };
    this.paymentMethod = '';
    this.checkOutPaymentId = '';
    this.paymentMethodTypes = [];
    this.billingAccount = [];
    this.orderTermType = [];
    this.orderTermForm = this._FormBuilder.group({
      description: [''],
      termDays: [''],
      termIndex: [''],
      termTypeId: [''],
      termValue: [''],
      textValue: ['']
    });
    this.editMode = false;
    this.isSearchPage = false;
    this.orderTerms = [];
    this.soFormData = {
      productId: "",
      quantity: "",
      itemDesiredDeliveryDate: "",
      useAsDefaultDesiredDeliveryDate: true,
      shipAfterDate: "",
      shipBeforeDate: "",
      reserveAfterDate: "",
      itemComment: "",
      useAsDefaultComment: true
    };
    this.orderItems = [];
    this.promotions = [];
    this.promotionValue = [];
    this.promoId = '';
    this.poContinue = {
      hasAgreements: "N",
      orderName: "",
      correspondingPoId: "",
      currencyUomId: "",
      CURRENT_CATALOG_ID: "",
      workEffortId: "",
      shipAfterDate: "",
      shipBeforeDate: "",
      reserveAfterDate: "",
      itemtype: ""
    };
    this.notes = {
      internalNote: '',
      shippingNote: ''
    };
    this.carrierShipments = [];
    this.shipmentOption = '';
    this.shipmentCustom = {
      shipDateBefore: '',
      shipDateAfter: '',
      specialInstruction: '',
      isGift: false,
      giftMessage: '',
      shipAtOnce: false
    };
    this.shippingEstimate = 0;
    this.soContinue = {
      hasAgreements: "N",
      orderName: "",
      correspondingPoId: "",
      currencyUomId: "",
      CURRENT_CATALOG_ID: "",
      workEffortId: "",
      shipAfterDate: "",
      shipBeforeDate: "",
      reserveAfterDate: "",
    };
    this.isPurchase = false;
    this.isSales = false;
    this.createOrderSales = {
      orderMode: 'SALES_ORDER',
      productStoreId: "",
      salesChannelEnumId: "",
      userLoginId: "",
      partyId: "",
    };

    this.createOrderPurchase = {
      orderMode: "PURCHASE_ORDER",
      billToCustomerPartyId: "Company",
      userLoginId: "",
      supplierPartyId: "",
    };

  }
  
  financialList = [{
    value: '1'
  }]
  offerShowingLineColoumns = [
    { field: "status", header: "Order Id" },
    { field: "name", header: "Order Type" },
    { field: "email", header: "Order Name" },
    { field: "phone", header: "Name" },
    { field: "type", header: "Survey" },
    { field: "amount", header: "Items Ordered" },
    { field: "property", header: "Items Backordered" },
    { field: "assigned", header: "Items Returned" },
    { field: "created", header: "Remaining SubTotal" },
    { field: "updated", header: "Order Total" },
    { field: "comment", header: "Status" },
    { field: "notes", header: "Order Date" },
    { field: "edit", header: "Party ID" },
  ];
  orderItemsColumns = [
    { field: 'status', header: 'Product' },
    { field: 'name', header: 'Quantity' },
    { field: 'email', header: 'Unit Price' },
    { field: 'phone', header: 'Adjustments' },
    { field: 'type', header: 'Sub Total' },
  ];
  offerShowingLineColoumnsConfirm = [
    { field: 'Destination', header: 'Destination' },
    { field: 'Supplier', header: 'Supplier' },
    { field: 'ShipmentMethod', header: 'Shipment Method' },
    { field: 'Items', header: 'Items' },
    { field: 'Quantity', header: 'Quantity' },
  ];

  offerShowingLineData = [
    {
      status: "WSCO10140",
      name: "Sales Order",
      email: "Test Order",
      phone: "The Privileged Administrator",
      type: "0",
      amount: "2",
      property: "0",
      assigned: "0",
      created: "US$28.78	",
      updated: "US$28.78	",
      updatedTime: "1:26 PM",
      comment: "Created",
      notes: "2020-11-18 13:27:33.851	",
      edit: "admin",
    },
    {
      status: "WSCO10140",
      name: "Sales Order",
      email: "Test Order",
      phone: "The Privileged Administrator",
      type: "0",
      amount: "2",
      property: "0",
      assigned: "0",
      created: "US$28.78	",
      updated: "US$28.78	",
      updatedTime: "1:26 PM",
      comment: "Created",
      notes: "2020-11-18 13:27:33.851	",
      edit: "admin",
    },
    {
      status: "WSCO10140",
      name: "Sales Order",
      email: "Test Order",
      phone: "The Privileged Administrator",
      type: "0",
      amount: "2",
      property: "0",
      assigned: "0",
      created: "US$28.78	",
      updated: "US$28.78	",
      updatedTime: "1:26 PM",
      comment: "Created",
      notes: "2020-11-18 13:27:33.851	",
      edit: "admin",
    },
    
  ];
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this.getVendors();

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];


    });
    if (this.partyId) {
      this.show = true;
    }
    else {
      this.show = false;
    }
    this.getVendors();
    this.getPartyId();

    this.products3 = this.accountsService.customerDetails();



    if (!window.location.pathname.includes('purchase') && !window.location.pathname.includes('sales')) {
      this.isPurchase = true;
      this.isSales = true;
    } else {
      this.isSales = window.location.pathname.includes('sales');
      this.isPurchase = window.location.pathname.includes('purchase');
    }
    this.getOrderListItems();
    this.getSalesChannelList();
   // this.setBilling();
    
    this.getUserLoginId();
    this.getCustomer("CUSTOMER");
    this.getCustomer("SUPPLIER");
    this.getOrganistaionList();
   
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  


  deleteVendor(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "manifestCompanyName": product.manifestCompanyName,
  "manifestCompanyTitle":  product.manifestCompanyTitle,
  "manifestLogoUrl":  product.manifestLogoUrl,
  "partyId":  product.partyId,
        }
        this.myContactsService.deleteVendor(req).subscribe(res => {
    this.getVendors();

       
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }

  onSubmit() {
    this.spinner.show();
    const requestData = {
      "manifestCompanyName": this.createVendor.value.manifestCompanyName,
      "manifestCompanyTitle": this.createVendor.value.manifestCompanyTitle,
      "manifestLogoUrl": this.createVendor.value.manifestLogoUrl,
      "manifestPolicies": this.createVendor.value.manifestPolicies,
      "partyId": this.createVendor.value.partyId,
      "accountNo": this.createVendor.value.accountNo,
      "billingRateLevel": this.createVendor.value.billingRateLevel,
      "creditLimit": this.createVendor.value.CreditLimit,
      "eligibleFor": this.createVendor.value.EligibleFor,
      "printCheckAs": this.createVendor.value.PrintCheckAs,
      "taxId": this.createVendor.value.CompanyTaxID,
      "terms": this.createVendor.value.agreement,
      "type": this.createVendor.value.type,
      "company": this.createVendor.value.company,
      "companyWebsite": this.createVendor.value.CompanyWebsite,
      "individual": this.createVendor.value.individual,
      "isActive": this.createVendor.value.DistributorisActive,
      "map": this.createVendor.value.Map,
      "name": this.createVendor.value.Name,
      "phone": this.createVendor.value.Phone,
      "typeVendor": "distributor",
      "vendorEmail": this.createVendor.value.DistributorEmail,
      "vendorFax": this.createVendor.value.DistributorFax,
      "vendorForms": this.createVendor.value.DistributorForms,
      "vendorName": this.createVendor.value.DistributorName,
      "vendorPhone": this.createVendor.value.DistributorPhone,
    }
    this.accountsService.postVendors(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.closebutton1.nativeElement.click();
        this.createVendor.reset();
        this.getVendors();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  DistributorDetails() {
    this.router.navigate(["/facilities/Distribution"])
  }
  salesOrder() {
    this.router.navigate(["/facilities/sales-order-distribution"])
  }
  trackDistribution() {
    this.router.navigate(["/facilities/track-distribution"])
  }


  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this.router.navigate(['/facilities/Dashboard']);
}
  invoiceType() {
    this.isShown = !this.isShown;
  }
  getVendors() {
    this.spinner.show();
    const req = {
      "manifestCompanyName": "",
      "manifestCompanyTitle": "",
      "manifestLogoUrl": "",
      "manifestPolicies": "",
      "partyId": ""
    }
    this.myContactsService.findVDistributor(req).subscribe((res: any) => {
      this.listVendors = res.data[0].findVDistributor;
      this.spinner.hide();

    })

  }
  reset() {
    this.spinner.show();
    this.advanceSearch.manifestCompanyName = "";
    this.advanceSearch.manifestCompanyTitle = "";
    this.advanceSearch.manifestLogoUrl = "";
    this.advanceSearch.manifestPolicies = "";
    this.advanceSearch.partyId = "";
    const req = {
      "manifestCompanyName": "",
      "manifestCompanyTitle": "",
      "manifestLogoUrl": "",
      "manifestPolicies": "",
      "partyId": "",
    }
    this.myContactsService.findVDistributor(req).subscribe((res: any) => {
      this.listVendors = res.data[0].findVDistributor;
      this.spinner.hide();

    })

  }
  findVDistributor(): void {
    this.spinner.show();
    this.financialList = [];
    const req = {
      "manifestCompanyName": this.advanceSearch.manifestCompanyName ? this.advanceSearch.manifestCompanyName : '',
      "manifestCompanyTitle": this.advanceSearch.manifestCompanyTitle ? this.advanceSearch.manifestCompanyTitle : '',
      "manifestLogoUrl": this.advanceSearch.manifestLogoUrl ? this.advanceSearch.manifestLogoUrl : '',
      "manifestPolicies": this.advanceSearch.manifestPolicies ? this.advanceSearch.manifestPolicies : '',
      "partyId": this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
  
    }
    this.myContactsService.findVDistributor(req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.listVendors = resp.data[0].findVDistributor;
        }
      });
  }
  newVendor() {
    this.router.navigate(["/financial/payable/list-vendor/new-vendor-create"])

  }
 

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  detailPage(updateId) {
    this.router.navigate(["/facilities/Distribution/viewDistribution"],
      { queryParams: { partyId: updateId } })
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  changeactiveCategory(tab: number) {
    this.activeCategories = tab;
  }

 
 
  ngOnDestroy(): void {
    this.spinner.hide();
  }


  ///////////////////////////--------SALES ORDER------//////////////////////////
  getOrderListItems(): void {
    this.spinner.show();
    this.accountsService.getOrderList().subscribe((res) => {
      let productStores = res.data.productStores;
      this.spinner.hide();
      for (const value of productStores) {
        this.productStoresListArray.push({
          label: value.storeName,
          value: value.productStoreId,
        });
      }
    });
   
  }

  getSalesChannelList(): void {
    this.spinner.show();
    this.accountsService.getSalesOrderList().subscribe((res) => {
      let salesOrderList = res.data;
      this.spinner.hide();
      for (const value of salesOrderList) {
        this.salesOrderListArray.push({
          label: value.description,
          value: value.enumId,
        });
      }
    });
    
  }

  getUserLoginId(): void {
    this.spinner.show();
    this.accountsService.getUserLogin().subscribe((res) => {
      let userLogin = res.data;
      this.spinner.hide();
      for (const value of userLogin) {
        if (value["groupName"] === null) {
          value["groupName"] = "";
        }
        if (value["firstName"] === null) {
          value["firstName"] = "";
        }
        if (value["middleName"] === null) {
          value["middleName"] = "";
        }
        if (value["lastName"] === null) {
          value["lastName"] = "";
        }
        let name =
          value["userLoginId"] +
          " " +
          value["groupName"] +
          " [" +
          value["firstName"] +
          " " +
          value["middleName"] +
          " " +
          value["lastName"] +
          "]";
        this.userLoginArray.push({
          label: name,
          value: value.userLoginId,
        });
      }
    });
   
  }

  getCustomer(role): void {
    this.spinner.show();
    let roleTypeId = role;
    this.accountsService.getCustomerList(roleTypeId).subscribe((res) => {
      let customerList = res.data;
      this.spinner.hide();
      for (const value of customerList) {
        if (value["groupName"] === null) {
          value["groupName"] = "";
        }
        if (value["firstName"] === null) {
          value["firstName"] = "";
        }
        if (value["middleName"] === null) {
          value["middleName"] = "";
        }
        if (value["lastName"] === null) {
          value["lastName"] = "";
        }
        let name =
          value["partyId"] +
          " " +
          value["groupName"] +
          " [" +
          value["firstName"] +
          " " +
          value["middleName"] +
          " " +
          value["lastName"] +
          "]";

        if (role === "CUSTOMER") {
          this.customerArray.push({
            label: name,
            value: value.partyId,
          });
        }
        if (role === "SUPPLIER") {
          this.supplierArray.push({
            label: name,
            value: value.partyId,
          });
        }
      }
    });
   
  }

  continueSalesOrder(): void {
    this.spinner.show();
    this.showButton=true;
    this.accountsService.setSalesOrderData(this.createOrderSales);
    this.spinner.hide();
    this.activeCategoryValue=2;
    this.getCurrency();
    this.getCatalogue();
    this.getWorkEffortList();
   // this.router.navigate(["/order/sales-continue"]);
  }
  orderTabRefresh(){
    this.activeCategoryValue=2;
  }

  orderTabSalesFinal(){
    this.activeCategoryValueMain=4;
  }
  continuePurchaseOrder(): void {
    this.spinner.show();
    this.accountsService.setPurchaseOrderData(this.createOrderPurchase);
    this.spinner.hide();
    this.router.navigate(["/order/purchase-continue"]);
  }

  getOrganistaionList(): void {
    this.spinner.show();
    this.accountsService.getOrganisationList().subscribe((res) => {
      let organisationsList = res.data.internalOrganizations;
      this.spinner.hide();
      for (const value of organisationsList) {
        this.organisationsArray.push({
          label: value.partyId + '-' + value.groupName,
          value: value.partyId,
        });
      }
    });
   
  }
  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }
 
  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }
 
  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }
 
  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }
 
  stepChanged(args: StepChangedArgs) {
  
  }
 
  isValidTypeBoolean: boolean = true;
 
  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }
 
  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }
  getCurrency(): void {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe((res) => {
      let currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId,
        });
      }
    });
   
  }

  getCatalogue(): void {
    this.spinner.show();
    let data = {
      catalogName: "",
      catalogNameSearchType: "Contains",
      prodCatalogId: "",
      prodCatalogIdSearchType: "Contains",
    };
    this.accountsService.getCatalogue(data).subscribe((res) => {
      let catalogue = res.data;
      this.spinner.hide();
      for (const value of catalogue) {
        this.catalogueArray.push({
          label: value.catalogName + " [" + value.prodCatalogId + "]",
          value: value.prodCatalogId,
        });
      }
    });
  
  }

  getWorkEffortList(): void {
    this.spinner.show();
    this.accountsService.getWorkEfforts().subscribe((res) => {
      let workEfforts = res.data;
      this.spinner.hide();
      for (const value of workEfforts) {
        this.workEffortArray.push({
          label: value.workEffortName + " [" + value.workEffortId + "]",
          value: value.workEffortId,
        });
      }
    });
   
  }



  continueSalesOrderCreation(): void {
    this.spinner.show();
    let dataPrevious = this.accountsService.createSalesOrderData;
    let formData = this.soContinue;
    let mergedData = { ...dataPrevious, ...formData };
    mergedData.reserveAfterDate = mergedData.reserveAfterDate ? moment(mergedData.reserveAfterDate).format('YYYY-MM-DD HH:mm:ss') : '',
      mergedData.shipAfterDate = mergedData.shipAfterDate ? moment(mergedData.shipAfterDate).format('YYYY-MM-DD HH:mm:ss') : '',
      mergedData.shipBeforeDate = mergedData.shipBeforeDate ? moment(mergedData.shipBeforeDate).format('YYYY-MM-DD HH:mm:ss') : '',

      this.accountsService.initorderentry(mergedData).subscribe((res) => {
        this.spinner.hide();
        this.activeCategoryValueMain=3;
        this.getProducts();
        this.getPromotions();
      //  this.router.navigate(["/order/create-sales-continue"]);
      });
      this.spinner.hide();
  }

  changeactiveCategoryFunctionConfirm(tab: number) {
    this.activeCategoryComfirm = tab;
  }
  changeactiveCategoryFunctionOrderTab(tab: number) {
    this.activeCategoryOrderTab = tab;
  }
  changeactiveCategoryShiping(tab: number) {
    this.activeCategoryShip = tab;
  }
  getPromotions(): void {
    this.spinner.show();
    this._OrderManagementService.getAllPromotion()
      .then(data => {
        this.spinner.hide();
        this.promotions = data.data;
        this.promotionValue = data.data.map(value => {
          return {
            label: value.productPromoId,
            value: value.productPromoId
          };
        });
      });
     
  }
  addPromotion(): void {
    this.spinner.show();
    if (this.promoId) {
      this._OrderManagementService.addPromo(this.promoId)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Promotion added successfully');
            this.promoId = '';
          }
        });
    }
    this.spinner.hide();
  }
  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
   
    });
  
  }

  addToOrder(): void {
    this.spinner.show();
    this.orderItems=[];
    const data = this.soFormData;
    data.itemDesiredDeliveryDate = data.itemDesiredDeliveryDate ? moment(data.itemDesiredDeliveryDate).format('YYYY-MM-DD HH:mm:ss') : '';
    data.reserveAfterDate = data.reserveAfterDate ? moment(data.reserveAfterDate).format('YYYY-MM-DD HH:mm:ss') : '';
    data.shipAfterDate = data.shipAfterDate ? moment(data.shipAfterDate).format('YYYY-MM-DD HH:mm:ss') : '';
    data.shipBeforeDate = data.shipBeforeDate ? moment(data.shipBeforeDate).format('YYYY-MM-DD HH:mm:ss') : '';

    this.accountsService.addToOrder(data).subscribe((res) => {
      if (res.success) {
        if (res.data.errorMessage) {
          this._ToastrService.error(res.data.errorMessage);
        } else {
          this._ToastrService.success('Order added successfully');
          this.spinner.hide();
        }
        this.totalAmount = res.data.orderItems[res.data.orderItems.length - 1];
        for (var i = 0; i <= (res.data.orderItems.length - 2); i++) {

          this.orderItems.push(res.data.orderItems[i]);
        }
        this.soFormData = {
          productId: "",
          quantity: "",
          itemDesiredDeliveryDate: "",
          useAsDefaultDesiredDeliveryDate: true,
          shipAfterDate: "",
          shipBeforeDate: "",
          reserveAfterDate: "",
          itemComment: "",
          useAsDefaultComment: true,
        };
      }
    });
    this.spinner.hide();
  }

  clearOrder(): void {
    this.spinner.show();
    this._OrderManagementService.clearOrder()
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Order Cleared');
          this.spinner.hide();
          this.activeCategorySales=2;
          this.activeCategoryValue=2;
          this.activeCategoryValueMain=3;
          this.orderItems=[];
          this.soContinue = {
            hasAgreements: "N",
            orderName: "",
            correspondingPoId: "",
            currencyUomId: "",
            CURRENT_CATALOG_ID: "",
            workEffortId: "",
            shipAfterDate: "",
            shipBeforeDate: "",
            reserveAfterDate: "",
          };
          this.createOrderSales = {
            orderMode: 'SALES_ORDER',
            productStoreId: "",
            salesChannelEnumId: "",
            userLoginId: "",
            partyId: "",
          };
         // this.router.navigate(['/order/create-sales-order']);
        }
      });
      this.spinner.hide();
  }
  finalizeOrderWithDefault(): void {
    this.spinner.show();
    if (this.orderItems.length) {
      this._OrderManagementService.finalizeOrder('', 'default', '')
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Order finalization initiated');
            switch (data.data.responseMessage) {
              case 'shipping': this.activeCategoryValueMain=4;
              this.createNewShipGroup();
               this.getPartyIds();
                break;
              case 'options': this.activeCategoryValueMain=5;
              this.getShippingMethod();
                break;
              case 'term': this.activeCategoryValueMain=6;
              this.getOrderTermType();
              this.getOrderTerms();
                break;
              case 'payment': this.activeCategoryValueMain=7;
              this.setBilling();
                break;
              case 'addparty': this.activeCategoryValue=8;
              this.getPartyIds();
                break;
              case 'sales': this.activeCategoryValue=9;
              this.reviewOrder();
                break;
            }
          }
        });
    } else {
      this._ToastrService.error('Please add product');
    }
    this.spinner.hide();
  }
  finalizeOrder(): void {
    this.spinner.show();
    if (this.orderItems.length) {
      this._OrderManagementService.finalizeOrderWithDynamicBody({
        finalizeMode: 'init'
      })
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Order finalization initiated');
            switch (data.data.responseMessage) {
              case 'shipping': this.activeCategoryValueMain=4;
              this.createNewShipGroup();
               this.getPartyIds();
                break;
              case 'options': this.activeCategoryValueMain=5;
              this.getShippingMethod();
                break;
              case 'term': this.activeCategoryValueMain=6;
              this.getOrderTermType();
              this.getOrderTerms();
                break;
              case 'payment': this.activeCategoryValueMain=7;
              this.setBilling();
                break;
              case 'addparty': this.activeCategoryValue=8;
              this.getPartyIds();
                break;
              case 'sales': this.activeCategoryValue=9;
              this.reviewOrder();
                break;
            }
          }
        });
    } else {
      this._ToastrService.error('Please add product');
    }
    this.spinner.hide();
  }
  recalculateOrder(): void {
    this.spinner.show();
    if (this.orderItems.length) {
      this._OrderManagementService.recalculateOrder(
        this.orderItems.map(value => {
          return {
            description: value.description,
            gwall: '',
            price: value.itemTotal,
            shipAfterDate: '',
            shipBeforeDate: '',
            updateQty: value.quantity
          };
        }))
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Recalculated');
          }
        });
    }
    this.spinner.hide();
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
     
  }
  createNewShipGroup(): void {
    this.spinner.show();
    this._OrderManagementService.createNewShipGroup('N')
      .then(data => {
        this.spinner.hide();
        this.agreementList = data.data.agreementList.map(value => {
          return {
            label: value.description,
            value: value.agreementId
          };
        });
        this.supplierList = data.data.suppliersList.map(value => {
          return {
            label: value.partyName,
            value: value.partyId
          };
        });
        this.addressList = data.data.listPOShipGrp;
      });
     
  }
  setShipping(event: any, geoId: string): void {
   
    if (event) {
      this.shippingAddress = geoId;
    } else {
      this.shippingAddress = '';
    }
  }
  finalizeOrderShip(): void {
    this.spinner.show();
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      finalizeMode: 'ship',
      shipToPartyId: '',
      '0_supplierPartyId': '',
      '0_supplierAgreementId': '',
      '0_shipGroupFacilityId': '',
      '0_shipping_contact_mech_id': this.shippingAddress
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          switch (data.data.responseMessage) {
            case 'shipping': this.activeCategoryValueMain=4;
            this.createNewShipGroup();
             this.getPartyIds();
              break;
            case 'options': this.activeCategoryValueMain=5;
            this.getShippingMethod();
              break;
            case 'term': this.activeCategoryValueMain=6;
            this.getOrderTermType();
            this.getOrderTerms();
              break;
            case 'payment': this.activeCategoryValueMain=7;
            this.setBilling();
              break;
            case 'addparty': this.activeCategoryValue=8;
            this.getPartyIds();
              break;
            case 'sales': this.activeCategoryValue=9;
            this.reviewOrder();
              break;
          }
        }
      });
      this.spinner.hide();
  }
  getShippingMethod(): void {
    this.spinner.show();
    this._OrderManagementService.getOrderShippingMethod()
      .then(data => { 
        this.spinner.hide();
        this.carrierShipments = data.data.carrierShipmentMethodList;});
     
  }




  finalizeOrderOption(): void {
    this.spinner.show();
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      'internal_order_notes': this.notes.internalNote,
      'shippingNotes': this.notes.shippingNote,
      'finalizeMode': 'options',
      '0_shipping_method': this.shipmentOption,
      '0_may_split': this.shipmentCustom.shipAtOnce,
      'sgi0_shipBeforeDate_i18n': this.shipmentCustom.shipDateBefore ? moment(this.shipmentCustom.shipDateBefore).format('YYYY-MM-DD HH:mm:ss') : '',
      'sgi0_shipBeforeDate': this.shipmentCustom.shipDateBefore ? moment(this.shipmentCustom.shipDateBefore).format('YYYY-MM-DD HH:mm:ss') : '',
      'sgi0_shipAfterDate_i18n': this.shipmentCustom.shipDateAfter ? moment(this.shipmentCustom.shipDateAfter).format('YYYY-MM-DD HH:mm:ss') : '',
      'sgi0_shipAfterDate': this.shipmentCustom.shipDateAfter ? moment(this.shipmentCustom.shipDateAfter).format('YYYY-MM-DD HH:mm:ss') : '',
      '0_shipping_instructions': this.shipmentCustom.specialInstruction,
      '0_is_gift': this.shipmentCustom.isGift,
      '0_gift_message': this.shipmentCustom.giftMessage,
      '0_ship_estimate': this.shippingEstimate
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          switch (data.data.responseMessage) {
            case 'shipping': this.activeCategoryValueMain=4;
            this.createNewShipGroup();
             this.getPartyIds();
              break;
            case 'options': this.activeCategoryValueMain=5;
            this.getShippingMethod();
              break;
            case 'term': this.activeCategoryValueMain=6;
            this.getOrderTermType();
            this.getOrderTerms();
              break;
            case 'payment': this.activeCategoryValueMain=7;
            this.setBilling();
              break;
            case 'addparty': this.activeCategoryValue=8;
            this.getPartyIds();
              break;
            case 'sales': this.activeCategoryValue=9;
            this.reviewOrder();
              break;
          }
        }
      });
      this.spinner.hide();
  }
  setOption(event: any, partyId: string, shippingEstimate: number) {
    this.shipmentOption = `${event}@${partyId}`;
    this.shippingEstimate = shippingEstimate;
  }
  getOrderTermType(): void {
    this.spinner.show();
    this._OrderManagementService.getOrderTermType()
      .then(data => {
        this.spinner.hide();
        this.orderTermType = data.data.map(value => {
          return {
            label: value.description,
            value: value.termTypeId
          };
        });
      })
     
  }
  getOrderTerms(): void {
    this.spinner.show();
    this._OrderManagementService.getOrderTerms()
      .then(data => {
        if (data.data) {
          this.spinner.hide();
          this.orderTerms = data.data.orderTerms;
          this.isSearchPage = true;
        }
      });
     
  }
  deleteOrderTerm(termIndex: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._OrderManagementService.removeCart(termIndex)
      .then(data => {
        if (data.success) {
         
          this.getOrderTerms();
        }
      });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
   
    
  }
  makeOrderTermEditable(termIndex: string): void {
    this.spinner.show();
    this._OrderManagementService.getOrderTermByIndex(termIndex)
      .then(data => {
        this.orderTerm = data.data;
        this.editMode = true;
      });
      this.spinner.hide();
  }
  update(): void {
    this.spinner.show();
    this._OrderManagementService.createOrderTerm({
      ...this.orderTerm, ...this.orderTermForm.value
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Order item updated successfully');
          this.orderTermForm.reset();
          this.spinner.hide();
          this.isSearchPage = true;
          this.getOrderTerms();
          this.editMode = false;
        }
      });
      this.spinner.hide();
  }

  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      this._OrderManagementService.createOrderTerm(this.orderTermForm.value)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Order item created successfully');
            this.orderTermForm.reset();
            this.spinner.hide();
            this.getOrderTerms();
            this.isSearchPage = true;
          }
        });
    }
    this.spinner.hide();
  }
 
  navigateToPayment(): void {
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      finalizeMode: 'term'
    })
      .then(data => {
        if (data.success) {
          switch (data.data.responseMessage) {
            case 'shipping': this.activeCategoryValueMain=4;
            this.createNewShipGroup();
             this.getPartyIds();
              break;
            case 'options': this.activeCategoryValueMain=5;
            this.getShippingMethod();
              break;
            case 'term': this.activeCategoryValueMain=6;
            this.getOrderTermType();
            this.getOrderTerms();
              break;
            case 'payment': this.activeCategoryValueMain=7;
            this.setBilling();
              break;
            case 'addparty': this.activeCategoryValue=8;
            this.getPartyIds();
              break;
            case 'sales': this.activeCategoryValue=9;
            this.reviewOrder();
              break;
          }
        }
      });
  }
  setBilling(): void {
    this.spinner.show();
    this._OrderManagementService.setBilling('N')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this._ToastrService.success('Billing Initiated');
          this.paymentMethodTypes = data.data.paymentMethodTypeAndId;
        
        }
      });
     
  }
  setPaymentMethod(event: any): void {
    this.paymentMethod = event;
  }
  finalizeOrderPayment(): void {
    this.spinner.show();
    if (this.paymentMethod) {
      this._OrderManagementService.finalizeOrderWithDynamicBody({
        finalizeMode: 'payment',
        billingAccountId: '',
        billingAccountAmount: '',
        checkOutPaymentId: this.paymentMethod
      })
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            switch (data.data.responseMessage) {
          
              case 'shipping': this.activeCategoryValueMain=4;
              this.createNewShipGroup();
               this.getPartyIds();
                break;
              case 'options': this.activeCategoryValueMain=5;
              this.getShippingMethod();
                break;
              case 'term': this.activeCategoryValueMain=6;
              this.getOrderTermType();
              this.getOrderTerms();
                break;
              case 'payment': this.activeCategoryValueMain=7;
              this.setBilling();
                break;
              case 'addparty': this.activeCategoryValue=8;
              this.getPartyIds();
                break;
              case 'sales': this.activeCategoryValue=9;
              this.reviewOrder();
                break;
            }
          }
        });
    } else {
      this._ToastrService.error('Please select payment');
    }
    this.spinner.hide();
  }
  finalizeOrderAdditonal(): void {
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      finalizeReqAdditionalParty: false,
      finalizeMode: 'addpty'
    })


      .then(data => {
        if (data.success) {
          this.activeCategoryValue=9;
          this.reviewOrder();
         // this.router.navigate(['/order/sales-order-confirmation']);
        }
      });
  }
  setPartyId(event: any): void {
    this.isParty = event;
    this.isRole = event;
  }

  getPartyIdsSale(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
     
  }
  addAdditionalParty(): void {
    this.spinner.show();
    this._OrderManagementService.addRemoveAdditionalParty(true, {
      additionalPartyId: this.additionalPartyForm.additionalPartyId,
      additionalRoleTypeId: [this.additionalPartyForm.additionalRoleTypeId]
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created');
          this.spinner.hide();
        }
      });
      this.spinner.hide();
  }
  removeAdditionParty(additionalPartyId: string, additionalRoleTypeId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._OrderManagementService.addRemoveAdditionalParty(false, {
          additionalPartyId, additionalRoleTypeId: [additionalRoleTypeId]
        })
          .then(data => {
            if (data.success) {
            
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  
  }
  reviewOrder(): void {
    this.spinner.show();
    this._OrderManagementService.reviewOrder()
      .then(data => {
        this.spinner.hide();
        this.reviewOrderValue = data.data;
      });
     
  }

  processOrder(): void {
    this.spinner.show();
    this._OrderManagementService.processOrder()
      .then(data => {
        if (data.success) {
          if (data.data.errorMessage) {
            this._ToastrService.error(data.data.errorMessage);
          } else {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.router.navigate(['/order/sales-order-view-page'], { queryParams: { orderId: data.data.orderId } });
          }
        }
      });
      this.spinner.hide();
  }

  toCRM()
  {
    this.router.navigate(['/crm/main-overview'])
  }


}