import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AddLeaveEmpService {

  constructor(readonly http:HttpClient) { }
  AddLeave(formData):Observable<any> {
    const addEmpLeave = URLS.createEmployeeLeave;
    return this.http.post(addEmpLeave,formData)
  }
  update(formData,party,leaveType,fromDate):Observable<any> {
    const updateApi = URLS.updateEmployeeLeave.replace(":partyId",party).replace(":leaveTypeId",leaveType).replace(":fromDate",fromDate)

    return this.http.put(updateApi,formData)
  } 
  getLeaveById(leaves){
    const getLeaveId = URLS.particularEmployeeLeave.replace(":partyId",leaves.partyId).replace(":leaveTypeId",leaves.leaveTypeId).replace(":fromDate",leaves.fromDate);
    return this.http.get(getLeaveId)
  }
  getLeaveReasonType():Observable<any>{
    const leaveReasonType = URLS.leaveTypeId;
    return this.http.get(leaveReasonType)
  }
  getLeaveReason():Observable<any> {
    const leaveReason = URLS.leaveReasonType;
    return this.http.get(leaveReason);
  }
  getOrganizationPartyId():Observable<any> {
    const getOrganizationPartyId = URLS.getOrganizationPartyId;
    return this.http.get(getOrganizationPartyId);
  }
  getPartyIdLeave():Observable<any> {
    const getPartyId = URLS.getPersonsPartyId;
    return this.http.get(getPartyId);
  }
  getPartyId():Observable<any> {
    const getPartyId = URLS.getPersonsPartyId;
    return this.http.get(getPartyId);
  }
postLeaveStatus(formData):Observable<any>{
  const getLeaveStatus = URLS.getLeaveStatus;
  return this.http.post(getLeaveStatus,formData)
}



  
}
