<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Planning</span>


               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="openInventory()"><a>
                                    Inventory</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="openFindRequirements()"><a>
                                    Find Requirements</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="openRequirements()"><a>
                                    Open Requirements</a></li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="openProductRequirements()"><a>
                                    Approved Product Requirements</a></li>

                            <li [ngClass]="activeCategory==5?'active':''" (click)="openInternalRequirements()"><a>
                                    Approved Internal Requirements</a></li>

                            <li [ngClass]="activeCategory==6?'active':''" (click)="openTransferRequirements()"><a>
                                    Approved Transfer Requirements</a></li>

                            <!-- <li [ngClass]="activeCategory==7?'active':''" (click)="openSalesOrderHistory()"><a>
                                Upload Sales Order History</a></li> -->

                            <li [ngClass]="activeCategory==8?'active':''" (click)="openRunMRP()"><a>
                                    Run MRP</a></li>

                            <li [ngClass]="activeCategory==9?'active':''" (click)="openViewMRP()"><a>
                                    View MRP</a></li>

                            <li [ngClass]="activeCategory==10?'active':''" (click)="openConfigureWarehouses()"><a>
                                    Configure Warehouses</a></li>

                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategory==1">

                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <span class="headingAccords"> Planing</span>
                                                            
                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 88%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>									
                
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <form [formGroup]="facilityInventoryForm">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInputT">
                                                                                <label for="exampleInputEmail1">Facility
                                                                                    ID <span style="color:red;">*</span> </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" [options]="facilityArray"
                                                                                    optionlabel="label" formControlName="facilityId"
                                                                                    placeholder="Choose Facility Id">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputBA">
                                                                                <label for="exampleInputEmail1">Product
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" [options]="products"
                                                                                    optionlabel="label" formControlName="productId"
                                                                                    placeholder="Choose Product Id">
                                                                                </p-dropdown>
                                                                            </div>
                
                
                                                                            <!-- <div class="col-lg-3 form-group classInputBA">
                                                                                        <label for="exampleInputEmail1">Internal
                                                                                            Name</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        formControlName="internalName"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Enter Internal Name">
                                                                                    </div> -->
                                                                        </div>
                                                                    </div>
                
                                                                    <!-- <div class="col-lg-12">
                                                                                <div class="row">
                                                                                  
                                                                                    <div class="col-lg-3 form-group classInputT">
                                                                                        <label for="exampleInputEmail1">Product
                                                                                            Type</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true"
                                                                                            [options]="productType"
                                                                                            optionlabel="label"
                                                                                            formControlName="productTypeId"
                                                                                            placeholder="Choose Product Type">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Category</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true"
                                                                                        [options]="categories"
                                                                                        optionlabel="label"
                                                                                        formControlName="searchInProductCategoryId"
                                                                                        placeholder="Choose Category">
                                                                                    </p-dropdown>
                                                                                    </div>
                                                                                
                                                                                </div>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                  
                                                                                    <div class="col-lg-3 form-group classInputT">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Supplier</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true"
                                                                                            [options]="suppliers"
                                                                                            optionlabel="label"
                                                                                            formControlName="productSupplierId"
                                                                                            placeholder="Choose Supplier">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                   <div class="col-lg-3 form-group classInputBA">
                                                                                        <label for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true"
                                                                                            [options]="statusIds"
                                                                                            optionlabel="label"
                                                                                            formControlName="statusId"
                                                                                            placeholder="Choose Status Id">
                                                                                        </p-dropdown>
                                                                                    </div> 
                                                                                </div>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                  
                                                                                    <div class="col-lg-3 form-group classInputT">
                                                                                        <label for="exampleInputEmail1">QOH
                                                                                            minus Min
                                                                                            Stock
                                                                                            less than</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Enter QOH minus Min Stock less than"
                                                                                        formControlName="offsetQOHQty">
                                                                                    </div>
                                                                                      
                                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                                        <label for="exampleInputEmail1">ATP
                                                                                            minus Min
                                                                                            Stock
                                                                                            less than</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp"
                                                                                        formControlName="offsetATPQty"
                                                                                        placeholder="Enter ATP minus Min Stock less than">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                   
                                                                                    </div>
                                                                                  
                                                                                   <div class="col-lg-3 form-group classInputT">
                                                                                        <label for="exampleInputEmail1">Show
                                                                                            Products
                                                                                            Per
                                                                                            Page</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control"
                                                                                        formControlName="viewSize"
                                                                                        id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Enter Value">
                                                                                    </div> 
                
                                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                                        <label for="exampleInputEmail1">Months
                                                                                            In Past
                                                                                            Limit</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        formControlName="monthsInPastLimit"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Enter Value">
                                                                                    </div> 
                                                                                </div>
                                                                            </div>
                                                                          <div class="col-lg-12">
                                                                                <div class="row">
                                                                                  
                                                                                    <div class="col-lg-3 form-group classInputT">
                                                                                        <label for="exampleInputEmail1">From
                                                                                            Date Sell
                                                                                            Through</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-calendar placeholder="Select Date"
                                                                                            formControlName="fromDateSellThrough">
                                                                                        </p-calendar>
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                                        <label for="exampleInputEmail1">Through
                                                                                            Date
                                                                                            Sell
                                                                                            Through</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-calendar placeholder="Select Date"
                                                                                        formControlName="thruDateSellThrough">
                                                                                    </p-calendar>
                                                                                    </div>
                                                                                </div>
                                                                            </div> -->
                
                
                                                                </div>
                                                            </form>
    
                                                            <div class="col-lg-12 col-12 main-submit-button " style="
                                                                    margin-left: -20%;
                                                                ">
                                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                                    (click)="searchInventory()">Find</button>
                                                                <button type="submit" class="btn btn-danger ml-2"
                                                                    (click)="searchInventoryReset()">Reset</button>
                                                            </div>
    
    
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <!-- <div class="w3-card-4 classCardView">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                Planing

                                                 <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                    data-target="#createSurveyPopup" data-toggle="modal">Create Survey</button>
                                         
                                            </div>


                                            <form [formGroup]="facilityInventoryForm">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInputT">
                                                                <label for="exampleInputEmail1">Facility
                                                                    ID <span style="color:red;">*</span> </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" [options]="facilityArray"
                                                                    optionlabel="label" formControlName="facilityId"
                                                                    placeholder="Choose Facility Id">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputBA">
                                                                <label for="exampleInputEmail1">Product
                                                                    ID</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" [options]="products"
                                                                    optionlabel="label" formControlName="productId"
                                                                    placeholder="Choose Product Id">
                                                                </p-dropdown>
                                                            </div>


                                                            <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">Internal
                                                                            Name</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        formControlName="internalName"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Internal Name">
                                                                    </div> 
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputT">
                                                                        <label for="exampleInputEmail1">Product
                                                                            Type</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true"
                                                                            [options]="productType"
                                                                            optionlabel="label"
                                                                            formControlName="productTypeId"
                                                                            placeholder="Choose Product Type">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                        <label
                                                                            for="exampleInputEmail1">Category</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true"
                                                                        [options]="categories"
                                                                        optionlabel="label"
                                                                        formControlName="searchInProductCategoryId"
                                                                        placeholder="Choose Category">
                                                                    </p-dropdown>
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputT">
                                                                        <label
                                                                            for="exampleInputEmail1">Supplier</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true"
                                                                            [options]="suppliers"
                                                                            optionlabel="label"
                                                                            formControlName="productSupplierId"
                                                                            placeholder="Choose Supplier">
                                                                        </p-dropdown>
                                                                    </div>
                                                                   <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">Status
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true"
                                                                            [options]="statusIds"
                                                                            optionlabel="label"
                                                                            formControlName="statusId"
                                                                            placeholder="Choose Status Id">
                                                                        </p-dropdown>
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputT">
                                                                        <label for="exampleInputEmail1">QOH
                                                                            minus Min
                                                                            Stock
                                                                            less than</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter QOH minus Min Stock less than"
                                                                        formControlName="offsetQOHQty">
                                                                    </div>
                                                                      
                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">ATP
                                                                            minus Min
                                                                            Stock
                                                                            less than</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        formControlName="offsetATPQty"
                                                                        placeholder="Enter ATP minus Min Stock less than">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                   
                                                                    </div>
                                                                  
                                                                   <div class="col-lg-3 form-group classInputT">
                                                                        <label for="exampleInputEmail1">Show
                                                                            Products
                                                                            Per
                                                                            Page</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        formControlName="viewSize"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Value">
                                                                    </div> 

                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">Months
                                                                            In Past
                                                                            Limit</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        formControlName="monthsInPastLimit"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Value">
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                          <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputT">
                                                                        <label for="exampleInputEmail1">From
                                                                            Date Sell
                                                                            Through</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-calendar placeholder="Select Date"
                                                                            formControlName="fromDateSellThrough">
                                                                        </p-calendar>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">Through
                                                                            Date
                                                                            Sell
                                                                            Through</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-calendar placeholder="Select Date"
                                                                        formControlName="thruDateSellThrough">
                                                                    </p-calendar>
                                                                    </div>
                                                                </div>
                                                            </div> 


                                                </div>
                                            </form>
                                            <div class="col-lg-12 col-12 main-submit-button " style="
                                                    margin-left: -20%;
                                                ">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="searchInventory()">Find</button>
                                                <button type="submit" class="btn btn-danger ml-2"
                                                    (click)="searchInventoryReset()">Reset</button>
                                            </div>

                                        </div> -->

                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs" >
                                                <h4 class="common-styling h4Margin">
                                                    Inventory List

                                                </h4>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="inventories" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Product ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Description
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Total ATP</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Total QOH</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Ordered Quantity
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Minimum Stock
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Days To Ship
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> QOH minus Min
                                                                                Stock</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> ATP minus Min
                                                                                Stock</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Quantity UomId
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Usage</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Default Price
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> List Price</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Whole Sale Price
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> From Date Sell
                                                                                Through</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Sell Through
                                                                                Initial Inventory</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Sell Through
                                                                                Inventory Sold</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Sell Through
                                                                                Percentage</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'190px'}"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button" 
                                                                                    (click)="navToProdictConfiguration(product)">{{product.productId}}</span>
                                                                                <!-- (click)="scroll('inventoryItem',true,'collapseFive')" -->
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">

                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.totalAvailableToPromise}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.totalQuantityOnHand}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.quantityOnOrder}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.minimumStock}}
                                                                        </td>
                                                                        <!-- <td [ngStyle]="{'width':'190px'}" >
                                                                            {{product.reorderQuantity}}
                                                                        </td> -->
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.daysToShip}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.offsetQOHQtyAvailable}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.offsetATPQtyAvailable}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.quantityUom}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.usageQuantity}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.defaultPrice}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.listPrice}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.wholeSalePrice}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                        <h4
                                                                            class="ml-2">
                                                                            No Record
                                                                            Found</h4>
                                                                    </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>