import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { saveAs } from 'file-saver';

import { Location } from '@angular/common';

@Component({
  selector: 'app-issue-created-import',
  templateUrl: './issue-created-import.component.html',
  styleUrls: ['./issue-created-import.component.css']
})
export class IssueCreatedImportComponent implements OnInit {

  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  stateArray: any[]=[];
  state: any;
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  userLogin: any;
  findPartyArray: any[]=[];
  identification: any;
  identificationArray: any[]=[];
  fileData: File;
  setupUserList: any;

  @ViewChild('closebutton') closebutton;
  @ViewChild('fileUploader') fileUploader:ElementRef;
  
  constructor(readonly router: Router,
     private _location: Location,
     readonly spinner:NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly toastr:ToastrService,
    ) { }

  ngOnInit(): void {
    this.getSetupUserList();
  }

  backWindow(){
    this._location.back();
  }
    toDashboard(){
  this.router.navigate(['/hub/Dashboard']);
  }
  getSetupUserList(){
    this.spinner.show();
    
    this.accountsService.getSetupUserImportList().subscribe((res:any)=>{
      this.setupUserList = res.data;
      this.spinner.hide();
    })
  }

  onSubmit(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file",this.fileData);

    this.accountsService.createSetupUserImport(formData).subscribe((res:any)=>{
      if(res.success) { 
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.closebutton.nativeElement.click();
      }     
    },(err)=>{
        this.toastr.error(err.message);
        this.spinner.hide();
     })
  }
  homeButton(){
    this.router.navigate(['/users/company-information-import'])
  
  }
  cancelSubmit(){
   this._location.back();
  }
  
  downloadFile() {
   
    let data = "name,lastName,username,group,email,phonenumber"
    
   
   let stringResultUpload = data;
    var blob = new Blob([stringResultUpload], {type: "text/csv;charset=utf-8"});
      saveAs(blob, "SETUP USER IMPORTS.csv");
    
  }
  reset() { 
    this.fileUploader.nativeElement.value = null;
  }


  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  goToCompanyInfo(){
    this.router.navigate(['/users/fiscal-year-import']);
  }
  goToBalanceSheet(){
    this.router.navigate(['/content/security-group-import']);
  }

}
