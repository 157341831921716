import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-main-contract',
  templateUrl: './main-contract.component.html',
  styleUrls: ['./main-contract.component.css']
})
export class MainContractComponent implements OnInit {
  isShown = false; 
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  CustomerAgreements: FormGroup;
  PersonsAndPartyGroupsList: any = [];
  agreementType: string;
  productList: any = [];
  AgreementTypeList: any = [];
  RoleTypesList: any = [];
  editMode: boolean;
  opportunityIdArray: any = [];
  fromDateType: any = [];
  toDateType: any = [];
  agreementList: any = [];
  activeCategory=1;
  constructor(readonly router: Router,
    readonly spinner: NgxSpinnerService,
    private _location: Location,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly _Router: Router,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder) {
    this.CustomerAgreements = this._FormBuilder.group({
      agreementId: [''],
      agreementIdSearchType: ['Contains'],
      agreementTypeId: [''],
      fromDateFrom: [''],
      fromDateFromSearchType: ['Greater Than'],
      fromDateTo: [''],
      fromDateToSearchType: ['Less Than'],
      partyIdFrom: [''],
      partyIdTo: [''],
      productId: [''],
    });
    this.editMode = false;
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      }, {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Not Equal',
        vaLue: 'Not Equal'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.fromDateType = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Greater Than',
        value: 'Greater Than'
      },
      {
        label: 'Greater Than Equals',
        value: ' Greater Than Equal To'
      },

    ];
    this.agreementType = '';
    this.toDateType = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Less Than Equals',
        value: ' Less Than Equal To'
      },

    ];
  }

  ngOnInit(): void {
   
     this.activeCategory=3;
 
  }
  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/financial/main']);
}

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  Vendor(){
    this.router.navigate(["/financial/payable/customer-agreeement"])
  }
  main(){
    this.router.navigate(["/financial/main-contract"])
  }
 
  Customer(){
    this.router.navigate(["/financial/receiveable/customer-agreeement"])
  }

  toEmployeeContract()
  {
    this.router.navigate(["/financial/payable/employees-contracts-com"])
  }

}
