<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" [routerLink]="paymentType">Payment Groups </span>
                <span class="color-black pl-1"> > {{editMode ?'Update':'Create'}} Payment Group</span>
                <button class="btn btn-danger buttonclass"  (click)="reset()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        {{editMode ?'Update':'Create'}} Payment Group</a></li>
                 
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="paymentGroupForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Type</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" [options]="paymentGroupTypes"
                                                    placeholder="Select Type" optionlabel="label"
                                                    formControlName="paymentGroupTypeId">
                                                </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA"  *ngIf="editMode">
                                                <label for="payment">Payment Group ID </label>
                                            </div>
                                         
                                            <div class="col-lg-2"  *ngIf="editMode">
                                                <input type="text" class="form-control" [value]="paymentGroupId"
                                                    readOnly>
                                            </div>

                                           


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Payment Group Name <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" formControlName="paymentGroupName"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Payment Group Name">
                                       
                                        <small class="text-danger"
                                            *ngIf="!!paymentGroupForm.controls.paymentGroupName.invalid && !!paymentGroupForm.controls.paymentGroupName.touched">Payment
                                            Group Name is required</small>
                                            </div>

                                        </div>
                                    </div>
                                 
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -66%;">
                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn"
                                        (click)="submit()">{{editMode ?'Update':'Create'}}</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 