import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-problem-detail',
  templateUrl: './problem-detail.component.html',
  styleUrls: ['./problem-detail.component.css']
})
export class ProblemDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
